import * as React from 'react';
import { makeStyles, Table, TableBody, TableContainer, TableHead, Grid, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@swagup-com/components';
import debounce from 'lodash/debounce';
import sum from 'lodash/sum';

import Scrollbar from 'react-scrollbars-custom';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { useLocalPaginatedQuery, useShippingCutoffHour } from '../../../../hooks';
import { contactsApi } from '../../../../apis/swagup';
import { Pagination } from '../../../shared';
import { TableCell, TableRow, CheckBox } from '../../../pages/orders/requested/shipments/TableCommon';
import { useSelectNewContacts, useInSendSwagPath } from '../../../pages/orders/requested/hooks';
import Filter from '../../../pages/orders/requested/common/Filter';
import SortBy from '../../../pages/orders/requested/common/SortBy';
import Drawer from '../../../pages/orders/requested/common/Drawer';
import Groups from '../../../groups/Groups';
import styles from './styles/selectFromContactsDrawer';
import SearchBar from '../../../pages/orders/requested/common/SearchBar';
import ContactRow from '../../../pages/orders/requested/shipments/ContactRow';
import ArrowTooltip from '../../../pages/orders/requested/common/Tooltip';
import apiPaths from '../../../../helpers/apiPaths';
import log from '../../../../logger';
import { addShipmentGroupRecipientsToSelect, setCurrentContactGroup } from '../../../../actions/shipmentGroupActions';
import { getUsedQuantitiesBySize, isOneSize } from '../../../../helpers/utils';
import {
  getQuantitiesBreakdown,
  substractQuantitiesBreakdowns
} from '../../../pages/orders/requested/common/utilsOrder';
import { Img } from '../../../global/ImgUtils';
import { statuses } from '../../../../reducers/processReducer';
import useSelection from './hooks/useSelection';
import storefrontsServicesPaths from '../../../../helpers/storefrontsServicesPaths';
import { storeCustomers } from '../../../../apis/storefrontsServices';
import redeemServicesPaths from '../../../../helpers/redeemServicesPaths';
import { redemptions } from '../../../../apis/redeemServices';

const useStyles = makeStyles(styles);

const EmptyState = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.emptyContainer}>
      <Img src="/images/public/magnifier-180x180.png" className={classes.emptyImg} alt="No recipients found" />
      <span className={classes.title}>No recipients found</span>
      <span className={classes.subtitle}>Remove filters to see all recipients</span>
    </Grid>
  );
};

const getIsMissingStock = (products, selected, shipmentGroup, consistencyFeatureFlag) => {
  const unallocated = products.reduce(
    (unallocatedAcc, product) => ({
      ...unallocatedAcc,
      [product.id]: substractQuantitiesBreakdowns(
        getQuantitiesBreakdown(product.sizes),
        getUsedQuantitiesBySize(product.id, [shipmentGroup])
      )
    }),
    {}
  );
  const newQtyBySize = Array.from(selected.values()).reduce(
    (usedQuantity, recipient) =>
      recipient.size
        ? { ...usedQuantity, [recipient.size.id]: (usedQuantity[recipient.size.id] || 0) + 1 }
        : usedQuantity,
    {}
  );

  const { directoryOrders, recipients } = shipmentGroup;
  const dOrdersIds = new Set(directoryOrders.map(dOrder => dOrder.directory));
  const withoutQuantity = recipients.filter(r => !dOrdersIds.has(r.id)).length;

  return products.some(product => {
    const selectedMoreThanUnallocated = sum(Object.values(unallocated[product.id])) < selected.size + withoutQuantity;

    if (selectedMoreThanUnallocated) return true;
    if (consistencyFeatureFlag) return false;

    const missingStockForSomeSize = isOneSize(product.sizes)
      ? unallocated[product.id][product.sizes[0].size.id] < selected.size
      : Object.keys(newQtyBySize).some(
          size => !unallocated[product.id][size] || unallocated[product.id][size] < newQtyBySize[size]
        );

    return missingStockForSomeSize;
  });
};

const sortByOptions = [
  { value: 'full_name', label: 'Name' },
  { value: '-created_at', label: 'Date Added' },
  { value: 'size', label: 'Size' }
];
const perPageOptions = [12, 50, 100];

const SelectFromContacts = ({ page, onClose, onAddContacts, newCustomers, onAddNewCustomerDrawer }) => {
  const shipmentGroup = useSelector(state => state.shipmentGroup);
  const { products, recipients } = shipmentGroup;
  const [isSaving, setIsSaving] = React.useState(false);
  const [selectedRef, setSelected] = React.useState([new Map()]);
  const [selected] = selectedRef;
  const [isSearchOpen, toggleIsSearchOpen] = React.useReducer(prevOpen => !prevOpen, false);
  const [openCreateustomersModal, setOpenCreateustomersModal] = React.useState(false);

  const isStockLimited = useInSendSwagPath();

  const [search, setSearch] = React.useState('');
  const debouncedSetSearch = debounce(setSearch, 750);
  const [ordering, setOrdering] = React.useState('-created_at');
  const [filters, setFilters] = React.useState({});

  const { data: redemptionApi, isLoading: isLoadingRedemptions } = useQuery(
    [redeemServicesPaths.redemptions(page.id), search, ordering],
    () => redemptions.list(page.id, {}),
    {
      enabled: !isSaving
    }
  );

  const redemers = redemptionApi?.results || [];

  const { contactsFeGroupingTemp011921: grouping } = useFlags();
  const {
    areAllSelected,
    isPartSelected,
    excluded,
    totalSelected,
    toogleSelectionStatus,
    updateExcluded,
    isSelected,
    setScopeTotal,
    reset
  } = useSelection();

  const contactsPayload = {
    search,
    ordering,
    sizes: filters?.sizes
      ?.filter(size => size.checked)
      .map(size => size.value)
      .join(),
    shippingCountry: filters?.address,
    groupIds: filters?.group_ids
  };

  const { query, pagination } = useLocalPaginatedQuery({
    queryKey: [apiPaths.contacts, contactsPayload],
    perPageOptions,
    queryFn: (limit, offset) =>
      contactsApi.fetch({
        ...contactsPayload,
        limit,
        offset
      })
  });

  React.useEffect(() => {
    setScopeTotal(pagination.count);
  }, [pagination, setScopeTotal]);

  const contacts = query.data?.results ?? [];

  const classes = useStyles();

  const dispatch = useDispatch();
  const newContactsAdded = useSelectNewContacts();

  React.useEffect(() => {
    if (newContactsAdded.size > 0) {
      setSelected(prevSelected => [new Map([...prevSelected[0], ...newContactsAdded])]);
    }
  }, [newContactsAdded]);

  const { setPageIndex } = pagination;
  React.useEffect(() => {
    setPageIndex(0);
  }, [search, filters, setPageIndex]);

  const handleCheck = contact => {
    if (!contact) return;

    if (!selected.delete(contact.id)) {
      selected.set(contact.id, contact);
    }

    setSelected([selected]);
  };

  const disabledOnes = React.useMemo(() => new Set(recipients.map(r => r.id)), [recipients]);
  const allSelected = contacts.length > 0 && contacts.every(c => disabledOnes.has(c.id) || selected.has(c.id));
  const indeterminate = !allSelected && contacts.some(c => selected.has(c.id));

  const handleCheckAll = () => {
    contacts.forEach(c => {
      if (disabledOnes.has(c.id)) return;

      if (indeterminate || allSelected) selected.delete(c.id);
      else selected.set(c.id, c);
    });

    setSelected([selected]);
  };

  const handleOnClose = React.useCallback(() => {
    reset();
    setIsSaving(false);
    onClose();
  }, [onClose, reset]);

  const handleAddSelectedRecipients = () => {
    setIsSaving(true);
    if (areAllSelected) {
      const allExcluded = new Map();
      disabledOnes.forEach((value, key) => {
        allExcluded.set(key, value);
      });
      excluded.forEach((value, key) => {
        allExcluded.set(key, value);
      });
      dispatch(setCurrentContactGroup({ ...contactsPayload, excluded: allExcluded }));
      handleOnClose();
    } else {
      onAddContacts([...excluded.values()], 'add_existing');
      handleOnClose();
    }
  };

  // const handleAddSelectedRecipientsOld = () => {
  //   setIsSaving(true);
  //   dispatch(addRecipients([...selected.values()], isStockLimited, shippingCutoffHour)).then(onClose);
  // };

  const { shipAllowAddingRecipientsOnSizeMismatchTemp290821 } = useFlags();
  log.debug(
    'flag SHIP-allow-adding-recipients-on-size-mismatch-temp-290821 active?:',
    shipAllowAddingRecipientsOnSizeMismatchTemp290821
  );
  const isMissingStock = React.useMemo(
    () => getIsMissingStock(products, selectedRef[0], shipmentGroup, shipAllowAddingRecipientsOnSizeMismatchTemp290821),
    [shipAllowAddingRecipientsOnSizeMismatchTemp290821, products, selectedRef, shipmentGroup]
  );

  const isLoading = pagination.isLoading || isSaving;
  const disableSelect =
    (grouping ? totalSelected === 0 : selected.size === 0) || isLoading || (isStockLimited && isMissingStock);

  const onFilter = newFilter => {
    reset();
    setFilters(newFilter);
  };

  const handleSelectGroups = groupIds => {
    onFilter({
      address: filters?.address,
      sizes: filters?.sizes,
      group_ids: groupIds
    });
  };

  const handleSelecton = grouping ? updateExcluded : handleCheck;
  const handleIsSelcted = contact => (grouping ? isSelected(contact.id) : selected.has(contact.id));
  const tableClass = contacts.length === 0 ? classes.table : classes.tableFlex;
  const currentTotalSelected = areAllSelected ? totalSelected - disabledOnes.size : totalSelected;

  return (
    <>
      {newCustomers ? (
        <Grid container justifyContent="space-between" alignItems="center">
          <p className={classes.title}>Add from Contacts</p>
          <Button variant="primary" className={classes.addCutomersBtn} onClick={onAddNewCustomerDrawer}>
            Add new recipients <AddIcon />
          </Button>
        </Grid>
      ) : (
        <p className={classes.title}>Select Contacts</p>
      )}
      <Grid container alignItems="center" style={{ margin: '20px 0 8px' }}>
        <Grid container alignItems="center" className={classes.filters}>
          <SearchBar open={isSearchOpen} toggleOpen={toggleIsSearchOpen} onChange={debouncedSetSearch} />
          <div className={clsx(classes.hideableFilters, { hidden: isSearchOpen })}>
            <Divider orientation="vertical" className={classes.divider} />
            <Filter filters={filters} onFiltersSubmit={onFilter} />
            <Divider orientation="vertical" className={classes.divider} />
            <SortBy options={sortByOptions} selected={ordering} onChange={setOrdering} />
          </div>
        </Grid>
        {/* {!isSearchOpen && (
          <Button variant="text" className={classes.addButton} onClick={handleOpenAddNew}>
            <AddIcon /> Add new contact
          </Button>
        )} */}
      </Grid>
      {grouping && (
        <Grid style={{ marginBottom: 20 }}>
          <Groups onGroupClick={handleSelectGroups} />
        </Grid>
      )}
      <Grid container direction="column" className={classes.tableContainer}>
        <TableContainer component={Scrollbar} className={tableClass}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <CheckBox
                    color="primary"
                    checked={grouping ? areAllSelected : allSelected}
                    indeterminate={grouping ? isPartSelected : indeterminate}
                    onClick={grouping ? toogleSelectionStatus : handleCheckAll}
                    disabled={contacts.length === 0 || isLoading}
                  />
                </TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>ADDRESS</TableCell>
                <TableCell>SIZE</TableCell>
                <TableCell>{grouping ? 'GROUPS' : 'DATE ADDED'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.map(contact => {
                const disabled = disabledOnes.has(contact.id);
                return (
                  <ContactRow
                    key={contact.id}
                    contact={contact}
                    selected={handleIsSelcted(contact) || disabled}
                    disabled={
                      disabled ||
                      isLoading ||
                      redemers.find(r => r.emailAddress === contact.email) ||
                      isEmpty(contact.email)
                    }
                    highlightText={search}
                    showGroupNames={grouping}
                    onClick={disabled || isLoading ? undefined : () => handleSelecton(contact)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {contacts.length === 0 && query.status === statuses.success && <EmptyState />}
        <Grid container alignItems="center" className={classes.paginationContainer}>
          <Pagination {...pagination} startText="Show" endText="contacts" buttonClass={classes.paginationBtn} />
        </Grid>
      </Grid>
      <Grid className={classes.addButtonContainer}>
        <ArrowTooltip
          title={isStockLimited && isMissingStock ? 'You don’t have enough inventory for the selected contact(s)' : ''}
          style={{ display: 'inline-flex' }}
        >
          <Button
            variant="primary"
            className={classes.addRecipientsButton}
            fullWidth={false}
            disabled={disableSelect}
            onClick={handleAddSelectedRecipients}
            loading={isLoading}
          >
            Add Selected Contacts ({currentTotalSelected})
          </Button>
        </ArrowTooltip>
      </Grid>
      {/* <CreateNewCustomerModal open={openCreateustomersModal} onClose={() => setOpenCreateustomersModal(false)} /> */}
    </>
  );
};

const SelectFromContactsDrawer = ({
  open,
  page,
  onClose,
  onAddContacts,
  newCustomers,
  setOpenRecipientDrawer,
  onAddNewCustomerDrawer
}) => {
  const classes = useStyles(styles);

  return (
    <Drawer open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      {open && (
        <SelectFromContacts
          onClose={onClose}
          onAddContacts={onAddContacts}
          page={page}
          newCustomers={newCustomers}
          setOpenRecipientDrawer={setOpenRecipientDrawer}
          onAddNewCustomerDrawer={onAddNewCustomerDrawer}
        />
      )}
    </Drawer>
  );
};

export default SelectFromContactsDrawer;
