import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { fetchStoragePrices, fetchStorageCategories } from '../../actions/storageActions';
import { fetchIndividualPackPrices } from '../../actions/shippingActions';
import toErrorPage from '../../helpers/toErrorPage';
import Loader from '../global/Loader';
import StorageBreakdownModal from './StorageBreakdownModal';

const StorageBreakdownModalContainer = ({
  open,
  onClose,
  history,
  fetchStoragePricesAction,
  fetchIndividualPackPricesAction,
  fetchStorageCategoriesAction
}) => {
  const [storagePrices, setStoragePrice] = useState([]);
  const [individualPackPrices, setIndividualPackPrices] = useState({});
  const [storageCategories, setStorageCategories] = useState([]);

  const isDataLoaded = () => !isEmpty(storagePrices) && !isEmpty(storageCategories) && !isEmpty(individualPackPrices);

  useEffect(() => {
    const fetchStoragePricesData = async () => {
      const response = await fetchStoragePricesAction();
      if (response.result === 'ok') {
        setStoragePrice(response.data.results);
      } else {
        toErrorPage(response.result, history);
      }
    };

    const fetchIndividualPackPricesData = async () => {
      const response = await fetchIndividualPackPricesAction();
      if (response.result === 'ok') {
        setIndividualPackPrices(response.data.results[0]);
      } else {
        toErrorPage(response.result, history);
      }
    };

    const fetchStorageCategoriesData = async () => {
      const response = await fetchStorageCategoriesAction();
      if (response.result === 'ok') {
        setStorageCategories(response.data.results);
      } else {
        toErrorPage(response.result, history);
      }
    };

    fetchStoragePricesData();
    fetchIndividualPackPricesData();
    fetchStorageCategoriesData();
  }, []);

  return (
    <>
      {isDataLoaded() ? (
        <StorageBreakdownModal
          storageCategories={storageCategories}
          storagePrices={storagePrices}
          individualPackPrices={individualPackPrices}
          open={open}
          onClose={onClose}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default connect(null, {
  fetchStoragePricesAction: fetchStoragePrices,
  fetchIndividualPackPricesAction: fetchIndividualPackPrices,
  fetchStorageCategoriesAction: fetchStorageCategories
})(StorageBreakdownModalContainer);
