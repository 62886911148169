import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, Dialog, DialogTitle, DialogContent, Typography, IconButton, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Img } from '../global/ImgUtils';
import styles from './styles/AddContactOptions';

const Option = ({ classes, pathname, title, srcImg }) => {
  const location = useLocation();
  return (
    <Link className={classes.link} to={{ pathname, state: { from: `${location.pathname}${location.search}` } }}>
      <Img src={srcImg} width={156} height={156} style={{ marginBottom: 24 }} />
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
    </Link>
  );
};

const useStyles = makeStyles(styles);

const AddContactOptions = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.dialog }} open={open} onClose={onClose}>
      <DialogTitle disableTypography className={classes.titleSection}>
        <Typography variant="h5" className={classes.title}>
          Upload new contact
        </Typography>
        <Typography variant="subtitle1" className={classes.subTitle}>
          Add a single contact or bulk upload a CSV file
        </Typography>
        <IconButton classes={{ root: classes.iconButton }} onClick={onClose}>
          <Close className={classes.icon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentSection}>
        <Grid container justifyContent="space-evenly">
          <Option
            classes={classes}
            pathname="/add-employee"
            title="Individual Contact"
            srcImg="/images/addContact/contact-icon.png"
          />
          <Option
            classes={classes}
            pathname="/import-contacts/download-template"
            title={
              <>
                Upload
                <br />
                CSV
              </>
            }
            srcImg="/images/addContact/csv-icon-156.png"
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddContactOptions;
