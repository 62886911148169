import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { hiddenNavbarRoutes } from '../utils/constants';
import { useAuth } from '../components/global/Authentication/AuthProvider';

const LeftNavbarContext = React.createContext();

const LeftNavbarProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [leftNavOpen, setLeftNavOpen] = useState(true);
  const [hideLeftNav, setHideLeftNav] = useState(true);

  const toggleLeftNav = status => setLeftNavOpen(status);
  const showHideLeftNav = status => setHideLeftNav(status);

  const location = useLocation();

  React.useEffect(() => {
    const _hideLeftNav = hiddenNavbarRoutes.some(route =>
      route === '/dashboard/' || route === '/'
        ? !isAuthenticated && (location.pathname === route || location.pathname === '/')
        : route === '/preset-packs/'
        ? !isAuthenticated && location.pathname === route
        : location.pathname.startsWith(route)
    );
    setHideLeftNav(_hideLeftNav);
  }, [location.pathname, isAuthenticated]);

  return (
    <LeftNavbarContext.Provider
      value={{
        leftNavOpen,
        hideLeftNav,
        toggleLeftNav,
        showHideLeftNav
      }}
    >
      {children}
    </LeftNavbarContext.Provider>
  );
};

const useLeftNavbar = () => {
  const leftNavbar = React.useContext(LeftNavbarContext);
  return leftNavbar;
};

export { LeftNavbarProvider, useLeftNavbar };
