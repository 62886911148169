import { ellipsisStyles, scrollBar } from '../../../shared/styles/commonStyles';

const styles = theme => ({
  container: {
    borderRadius: 16,
    padding: '12px 8px',
    border: '1px solid #EBEDF0',
    marginBottom: 16
  },
  itemImageContainer: {
    position: 'relative',
    width: 42,
    height: 42
  },
  itemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },

  integrationSelectionImageWrapper: {
    height: 72,
    width: 72,
    position: 'relative',
    borderRadius: 10
  },
  integrationProductImageWrapper: {
    height: 64,
    width: 64,
    position: 'relative',
    border: '1px solid #E5E7E8',
    borderRadius: 10,
    overflow: 'hidden'
  },
  paginationButton: {
    height: 42,
    width: 42,
    cursor: 'pointer',
    color: '#4a505c',
    boxShadow: 'none !important',
    '&:hover': {
      color: '#3577D4'
    }
  },
  integrationItem: {
    height: 24,
    width: 24,
    position: 'relative'
  },
  integrationImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  integrationSelectionItem: {
    marginBottom: 16,
    borderRadius: 10,
    position: 'relative',
    padding: ({ product }) => (product ? '16px 14px' : '20px 30px'),
    background: ({ selected, alReadyConnected }) => (alReadyConnected ? '#F5F7F8' : selected ? '#3577d414' : '#ffffff'),
    border: ({ selected }) => (selected ? '1px solid  #3577D4' : '1px solid  #E5E7E8'),
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.07)',
      border: ({ selected }) => (selected ? '1px solid  #3577D4' : '1px solid  #E5E7E8')
    }
  },
  integrationProductsWrapper: {
    ...scrollBar,
    maxHeight: '432px',
    paddingBottom: 120
  },
  actionTitle: {
    textAlign: 'center',
    fontSize: 24,
    marginBottom: 16
  },
  actionTitleSmall: {
    textAlign: 'center',
    fontSize: 20,
    marginBottom: 16
  },
  actionSubTitle: {
    textAlign: 'center',
    fontSize: 20,
    marginBottom: 16
  },
  actionSubTitleDesc: {
    textAlign: 'center',
    color: '#4A4F54',
    fontSize: 16,
    marginBottom: 16,
    '& span': {
      fontWeight: 600
    }
  },
  customTimingSettings: {
    marginTop: 20,
    border: '1px solid #EBEDF0',
    padding: '8px 24px 16px 24px',
    borderRadius: 12
  },
  formControl: {
    marginLeft: 4
  },
  selectField: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    '& .MuiPaper-root': {
      height: '320px !important'
    },
    '& .MuiSelect-root': {
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    }
  },
  inputLabel: { marginBottom: 8, cursor: 'default' },
  actionFinishedSubTitle: {
    textAlign: 'center',
    fontSize: 16,
    marginBottom: 16
  },
  emptyProductsImageWrapper: {
    height: 164,
    width: 164,
    position: 'relative'
  },
  integrationSection: {
    width: '100% !important'
  },
  finishDivider: {
    marginTop: 16,
    marginBottom: 16,
    height: 1,
    background: '#EBEDF0'
  },
  stepContentMin: {
    width: '100%',
    maxHeight: 242,
    position: 'relative',
    ...scrollBar
  },
  emptyProductsTitle: {
    textAlign: 'center',
    marginBottom: 8
  },
  sectionTitle: {
    color: '#131415',
    fontSize: 16,
    marginBottom: 12
  },
  productName: {
    color: '#131415',
    maxWidth: 336,
    marginBottom: 8,
    ...ellipsisStyles,
    '& span': {
      color: '#4A4F54',
      fontWeight: 400
    }
  },
  productNameSummary: {
    color: '#131415',
    maxWidth: 216,
    marginBottom: 8,
    ...ellipsisStyles,
    '& span': {
      color: '#4A4F54',
      fontWeight: 400
    }
  },
  sizeTable: {
    // border: '1px solid #D6D8DB',
    borderRadius: '10px !important',
    padding: '24px 24px'
  },
  sizeItem: {
    width: '100%',
    border: '1px solid #D6D8DB',
    borderRadius: '10px !important',
    padding: '6px 12px',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.07)',
      background: 'rgba(53, 119, 212, 0.08)'
      // border: ({ selected }) => (selected ? '1px solid  #3577D4' : '1px solid  #E5E7E8')
    }
  },
  sizeItemSelected: {
    width: '100%',
    border: '1px solid #D6D8DB',
    borderRadius: '10px !important',
    padding: '6px 12px',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.05)',
    background: 'rgba(53, 119, 212, 0.1)',
    '&:hover': {
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.07)',
      background: 'rgba(53, 119, 212, 0.2)'
      // border: ({ selected }) => (selected ? '1px solid  #3577D4' : '1px solid  #E5E7E8')
    }
  },
  productTag: {
    background: '#D6D8DB',
    padding: '8px 16px',
    color: '#4A4F54',
    borderRadius: 16,
    width: 'fit-content'
  },
  emptyProductsText: {
    textAlign: 'center',
    width: 320,
    marginTop: 16,
    marginBottom: 16
  },
  appreciationTex: {
    color: '#989EA4'
  },
  inputTextMulti: {
    width: '100%',
    borderRadius: 10,
    border: '1px solid #CED1D6',
    '& .MuiInputBase-formControl': {
      height: 130,
      overflowY: 'scroll',
      alignItems: 'flex-start',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '&.Mui-focused fieldset': {
        border: 'none !important',
        borderRadius: 'none !important'
      }
    }
  },
  productDescription: {
    color: '#4A4F54',
    marginBottom: 8,
    '& span': {
      color: '#131415'
    },
    ...ellipsisStyles
  },
  integrationItemDescription: {
    maxWidth: '100%',
    maxHeight: 124,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: 12,
    color: '#4A4F54',
    ...ellipsisStyles,
    '& li': {
      fontSize: 14,
      fontWeight: 400,
      color: '#4A4F54',
      lineHeight: '20px'
    },
    '& ul': {
      marginBlockStart: '0px',
      marginBlockEnd: '0px',
      marginInlineStart: '0px',
      marginInlineEnd: '0px',
      paddingInlineStart: '14px'
    }
  },
  integrationItemTitle: ({ product }) => ({
    color: product ? '#4A4F54' : '#131415',
    fontSize: product ? 16 : 20,
    lineHeight: product ? '20px' : '28px',
    maxWidth: 300,
    ...(product ? ellipsisStyles : undefined)
  }),
  shippingMethodLabel: {
    paddingLeft: 24,
    '& p': {
      color: ' #4A4F54'
    },
    '& h6': {
      color: ' #4A4F54',
      fontSize: 16
    }
  },
  shippingMethod: {
    border: '1px solid #EBEDF0',
    borderRadius: 10,
    padding: '12px 24px',
    marginBottom: 12,
    '& h5': {
      color: ' #4A4F54',
      fontSize: 18,
      marginBottom: 4
    }
  },
  itemImageContainer: {
    position: 'relative',
    width: 68,
    height: 68
  },
  actionImageContainer: {
    position: 'relative',
    width: 68,
    height: 68
  },
  itemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  summaryContainer: {
    border: '1px solid #EBEDF0',
    borderRadius: 10,
    padding: '24px 32px'
  },
  notificationIcon: {
    color: '#9846dd',
    width: 32,
    height: 32
  },
  textForm: {
    color: '#0b1829',
    marginBottom: 14
  },
  amountSelectorText: {
    textAlign: 'left',
    color: '#0b1829'
  },
  amountSelector: {
    padding: '6px 8px 6px 16px',
    border: '1px solid #CED1D6',
    borderRadius: 10,
    width: '100%',
    height: 48,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      color: 'transparent',
      marginTop: 3,
      height: 16,
      width: 16
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  amountSelectorSelected: {
    padding: '6px 8px 6px 16px',
    border: '1px solid #3577D4',
    borderRadius: 10,
    cursor: 'pointer',
    width: '100%',
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      color: '#3577D4'
    },
    '& .MuiSvgIcon-root': {
      color: '#3577D4',
      marginTop: 3,
      height: 16,
      width: 16
    }
  },
  customMessageForm: {
    width: '100%',
    borderRadius: 10,
    border: '1px solid #CED1D6',
    padding: '18px 24px'
  },
  inputText: {
    height: 40,
    '& input': {
      padding: '10px 24px',
      borderRadius: 24
    }
  },
  selectPaper: {
    borderRadius: 12
  },
  selectionActionBox: {
    marginBottom: 16
  },
  employeeTypesPanel: {
    padding: '20px 12px 0px',
    borderRadius: 10,
    border: '1px solid #EBEDF0'
  }
});

export default styles;
