import React from 'react';
import round from 'lodash/round';
import { Typography, Grid, Box, makeStyles } from '@material-ui/core';
import PaymentSection from '../../../paymentSection/PaymentSection';
import { moneyStr } from '../../../../helpers/utils';
import styles from './styles/payment';

const useStyles = makeStyles(styles);

const Payment = ({
  credit,
  totalToPay,
  textToRefundCredit = 'Use available credits to pay for the warehouse',
  onValidityChange
}) => {
  const payWithCC = Math.max(0, round(totalToPay - credit, 2));

  const classes = useStyles();

  return (
    <Box mb={payWithCC > 0 ? -2 : 6}>
      <Typography variant="body1" className={classes.paymentText}>
        Payment method
      </Typography>
      <Box padding="20px" border="solid 1px #3577D4" borderRadius="15px">
        <Grid container item wrap="nowrap">
          <Grid item container direction="column" justifyContent="space-around" xs={6}>
            <Typography className={classes.creditTitle}>Use Available Credits</Typography>
            <Typography className={classes.creditSubtitle}>{textToRefundCredit}</Typography>
          </Grid>
          <Grid container item direction="column" alignItems="center" xs={3}>
            <Typography className={classes.creditSubtitle}>Total payable</Typography>
            <Typography className={classes.totalPayable}>{moneyStr(totalToPay)}</Typography>
          </Grid>
          <Grid container item direction="column" alignItems="center" xs={3}>
            <Typography className={classes.creditSubtitle}>Available credit</Typography>
            <Typography className={classes.availableCredit}>{moneyStr(credit)}</Typography>
          </Grid>
        </Grid>
      </Box>
      {payWithCC > 0 && (
        <>
          <Grid container wrap="nowrap" className={classes.payRemainingContainer}>
            <Typography className={classes.payRemaining}>
              Select a payment method to pay the remaining{' '}
              <span className={classes.blueText}>{moneyStr(payWithCC)}</span>
            </Typography>
          </Grid>
          <PaymentSection credit={credit} cost={payWithCC} onValidityChange={onValidityChange} simple />
        </>
      )}
    </Box>
  );
};

export default Payment;
