import React, { useEffect, useRef, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Alert } from '@material-ui/lab';
import shipmentsApi from '../../../../apis/swagup/shipments';
import { EmptyState, Helmet, Pagination } from '../../../shared';
import Loader from '../../../global/Loader';
import { usePagination, useQueryParams, useAsync } from '../../../../hooks';
import { convertFilterBySourceFromAPI, filterByStatusOptions, sortByPlacedOptions } from './TopFilters';
import ShipmentsGrid from './ShipmentsGrid';
import { deleteShipment, deleteSoftShipment, fetchShipments } from '../../../../actions';
import toErrorPage from '../../../../helpers/toErrorPage';
import tags from '../../../../apis/seoTags';
import log from '../../../../logger';
import styles from './styles/recentsShipments';
import apiPaths from '../../../../helpers/apiPaths';
import { shipmentStatus } from '../../../../apis/constants';
import SearchSortFilter from '../../../shared/SearchSortFilter';
import { convertArrToObject, generateFilterOptions } from '../../../../helpers/utils';
import { DownloadIcon } from '../../../icons';
import global from '../../../../apis/swagup/global';
import IconLabel from '../../../global/IconLabel';

const useStyles = makeStyles(styles);

const eoQueryKey = [apiPaths.employeeOrders, { limit: 4 }];

const perPageOptions = [12, 24, 36, 48];

const RecentShipments = ({ setExistShipments }) => {
  const [isSameFilter, setIsSameFilter] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isDeletingShipment, setIsDeletingShipment] = useState(false);
  const { items: shipments, loading, total } = useSelector(state => state.shipments);
  const [infoMessage, setInfoMessage] = React.useState();
  const [filters, setFilters] = React.useState([]);

  const isFirstRender = useRef(true);

  const dispatch = useDispatch();
  const memoizedFetchShipments = React.useCallback(options => dispatch(fetchShipments(options)), [dispatch]);
  const [paginate, response, isPending, hasFailed] = useAsync(memoizedFetchShipments, 250);

  const { data: shipmentSources } = useQuery(apiPaths.shipmentSources, () => shipmentsApi.shipmentSources());

  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    global
      .fetchFilters('shipments')
      .then(data => {
        setFilters(data);
      })
      .catch(error => {
        console.error('Error fetching filters:', error);
      });
  }, []);

  useEffect(() => {
    setExistShipments(false);
  }, [setExistShipments]);

  useEffect(() => {
    if (!isPending && !hasFailed && response > 0) setExistShipments(true);
  }, [isPending, hasFailed, response, setExistShipments]);

  useEffect(() => {
    if (hasFailed) toErrorPage(response, history);
  }, [hasFailed, response, history]);

  const query = useQueryParams();
  const search = query.get('search') || '';
  const ordering = query.get('ordering') || '-created_at';
  const placed = query.get('placed') || 'all';
  const statusIn = query.get('status_in') || '';
  const sourceIn = query.get('source_filter') || '';

  log.debug(
    'EmployeesOrders fetch, search:',
    search,
    total,
    'placed:',
    placed,
    'status_in:',
    statusIn,
    'source_filter:',
    sourceIn
  );

  const { nextPage, pageIndex, perPage, sizeOptions, previousPage, changeSize } = usePagination(total, perPageOptions);

  const options = useMemo(
    () => ({
      offset: pageIndex * perPage,
      limit: perPage,
      search,
      statusIn,
      source_filter: sourceIn,
      createdAt: placed,
      ordering,
      shipments: 'not_soft_deleted'
    }),
    [pageIndex, perPage, search, statusIn, sourceIn, placed, ordering]
  );

  useEffect(() => {
    if (isSameFilter) {
      setIsSameFilter(false);
    } else {
      paginate(options);
    }
    return () => paginate.cancel();
  }, [isSameFilter, options, paginate]);

  const refetch = () => {
    queryClient.invalidateQueries(eoQueryKey);
    paginate({ options });
  };

  const handleCancelShipment = async shipmentId => {
    try {
      setIsDeletingShipment(true);
      const error = await dispatch(deleteShipment(shipmentId, options));
      if (error) {
        setIsDeletingShipment(false);
        return setInfoMessage(`${error}`);
      }
      const status = shipments.find(s => s.id === shipmentId)?.status;
      if (status === shipmentStatus.invalidAddress) {
        queryClient.invalidateQueries(eoQueryKey);
      }
      setIsDeletingShipment(false);
    } catch (e) {
      toErrorPage(e, history);
    }
  };

  const handleSoftCancelShipment = async shipmentId => {
    try {
      setIsDeletingShipment(true);
      const error = await dispatch(deleteSoftShipment(shipmentId, options));
      if (error) {
        setIsDeletingShipment(false);
        return setInfoMessage(`${error}`);
      }
      const status = shipments.find(s => s.id === shipmentId)?.status;
      if (status === shipmentStatus.invalidAddress) {
        queryClient.invalidateQueries(eoQueryKey);
      }
      setIsDeletingShipment(false);
    } catch (e) {
      toErrorPage(e, history);
    }
  };
  const handleToastClose = () => {
    setInfoMessage();
    history.replace({ ...location, search: undefined });
  };

  const classes = useStyles();

  const { mutate: exportCSVData, isLoading: isCSVPending } = useMutation(
    // eslint-disable-next-line max-len
    () =>
      shipmentsApi.fetch({ search, createdAt: placed, statusIn, exportCSV: true, ordering, source_filter: sourceIn }),
    {
      useErrorBoundary: true,
      onSuccess: csvData => {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute(
          'download',
          `shipments-status_in=${statusIn}-placed=${placed}-search=${search}-source=${sourceIn}.csv`
        );
        link.style.visibility = 'hidden';
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
      }
    }
  );

  if (!isPending && response !== undefined) isFirstRender.current = false;
  if (isFirstRender.current && shipments.length === 0) {
    return <Loader absolute />;
  }

  const searchSortFilterConfig = {
    search: { placeholder: 'Search products or recipients' },
    sort: { options: { '-created_at': 'Most Recent', created_at: 'Less Recent' } },
    filters: [
      { label: 'Status', queryParam: 'status_in', options: generateFilterOptions(filters?.statuses) },
      {
        label: 'Source',
        queryParam: 'source_filter',
        options: convertArrToObject(convertFilterBySourceFromAPI(shipmentSources), 'value', 'text1')
      },
      {
        label: 'Placed',
        queryParam: 'placed',
        options: convertArrToObject(
          sortByPlacedOptions.filter(opt => opt.value !== 'all'),
          'value',
          'text'
        ),
        isSingleSelect: true
      }
    ],
    others: {
      component: (
        <IconLabel
          icon={<DownloadIcon fill="#125CFF" />}
          label="Export shipments"
          handleClick={exportCSVData}
          isHideLabel={!isFilterApplied}
          id="downloadShipmentsLabel"
        />
      )
    }
  };

  return (
    <>
      <Helmet tags={tags.recentShipments} />
      {total === -2 ? (
        <EmptyState
          title="No Shipments"
          image={{
            path: '/images/dashboard/empty-shipments.png',
            alt: 'No shipments',
            text: 'You have not sent any Swag yet.'
          }}
          button={{
            link: { pathname: '/send-swag/select-products', state: { from: location } },
            text: 'Send Swag'
          }}
          marginTop={0}
        />
      ) : (
        <>
          <SearchSortFilter config={searchSortFilterConfig} setIsFilterApplied={setIsFilterApplied} />
          <Grid container direction="column" spacing={4} alignItems="center" className={classes.root}>
            {infoMessage && (
              <Grid container justifyContent="center" style={{ position: 'relative' }}>
                <Alert
                  onClose={handleToastClose}
                  delayTime={5000}
                  className={classes.floatingInfoMessage}
                  fontStyles={{ fontSize: 12, padding: 0 }}
                  severity="error"
                >
                  {infoMessage}
                </Alert>
              </Grid>
            )}
            {(isDeletingShipment || isCSVPending) && <Loader absolute />}
            <Grid item className={classes.shipmentsContainer}>
              {total !== 0 && !isDeletingShipment && loading && <Loader absolute />}
              <ShipmentsGrid
                shipments={shipments}
                fetchShipments={refetch}
                onCancel={handleCancelShipment}
                onSoftCancel={handleSoftCancelShipment}
              />
            </Grid>
            <Grid item container justifyContent="center">
              {total === 0 ? (
                <>
                  {loading && <Loader absolute />}
                  <EmptyState
                    title="No Shipments found"
                    image={{
                      path: '/images/dashboard/empty-shipments-with-filter.png',
                      alt: 'No shipments found',
                      text: 'Remove filters to see all the shipments.'
                    }}
                    button={{
                      link: '/shipments',
                      text: 'Remove filters'
                    }}
                    marginTop={0}
                  />
                </>
              ) : (
                <Pagination
                  count={total}
                  endText="shipments"
                  perPage={perPage}
                  next={nextPage}
                  pageIndex={pageIndex}
                  previous={previousPage}
                  sizeOptions={sizeOptions}
                  onPerPageChange={changeSize}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default RecentShipments;
