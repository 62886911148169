import React, { forwardRef } from 'react';
import { TextField } from '@swagup-com/components';
import { withStyles, Tooltip as MuiTooltip } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RSC from 'react-scrollbars-custom';
import { ellipsisStyles } from '../shared/styles/commonStyles';

const Tooltip = withStyles({
  popper: {
    zIndex: 1200 // less than the select menu (1300)
  },
  tooltip: {
    borderRadius: 5,
    padding: '13px 20px',
    color: '#f44336',
    fontSize: 12,
    fontFamily: 'Gilroy',
    lineHeight: 'normal',
    boxShadow: '0 6px 36px 0 rgba(0, 0, 0, 0.15)',
    backgroundImage: 'unset'
  }
})(MuiTooltip);

const Wrapper = ({ children, withTooltip, error }) =>
  withTooltip ? (
    <Tooltip title={error || ''} placement="top" arrow>
      <div data-description="reference-holder">{children}</div>
    </Tooltip>
  ) : (
    children
  );

const SelectIcon = props => <KeyboardArrowDownIcon style={{ right: 25 }} {...props} />;

const styles = {
  labelInput: {
    ...ellipsisStyles,
    paddingTop: ({ withTooltip }) => (withTooltip ? 1 : 4),
    color: '#bdbdbd',
    width: '100%',
    paddingRight: 70
  },
  textInput: {
    paddingRight: '60px !important'
  },
  menuPaper: {
    marginTop: -8,
    paddingTop: 8,
    paddingBottom: 3,
    overflowY: 'hidden'
  }
};

const getScrollable = (totalItems, itemHeight) => {
  if (typeof window === 'undefined') return null;
  const windowsHeight = window.innerHeight - 96;
  const totalHeight = totalItems * itemHeight;
  const height = Math.min(windowsHeight, totalHeight);
  return forwardRef((props, ref) => <RSC ref={ref} {...props} style={{ height }} />, {
    displayName: 'Scrollbars'
  });
};

const Select = withStyles(styles)(
  ({
    control,
    name,
    error,
    defaultValue,
    withTooltip,
    totalItems,
    itemHeight = 45,
    hideLabel = true,
    value,
    handleSelect,
    onBlur,
    label,
    classes,
    id,
    ...props
  }) => (
    <Wrapper withTooltip={withTooltip} error={error}>
      <TextField
        {...props}
        label={label}
        select
        id={id}
        name={name}
        value={value}
        title={value}
        error={Boolean(error)}
        helperText={!withTooltip && error}
        SelectProps={{
          IconComponent: SelectIcon,
          MenuProps: {
            classes: { paper: classes.menuPaper },
            MenuListProps: {
              component: getScrollable(totalItems, itemHeight)
            }
          }
        }}
        InputProps={{ id, classes: { root: classes.root, input: classes.textInput } }}
        InputLabelProps={{
          shrink: hideLabel ? false : Boolean(value),
          className: classes.labelInput,
          style: { display: hideLabel && Boolean(value) ? 'none' : 'unset', color: '#bdbdbd' }
        }}
        style={!withTooltip ? { paddingTop: 5, paddingBottom: error ? 0 : 23 } : undefined}
        onChange={handleSelect}
        onBlur={onBlur}
      />
    </Wrapper>
  )
);

export default Select;
