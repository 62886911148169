import { ellipsisStyles, scrollBar } from '../../shared/styles/commonStyles';

const styles = theme => ({
  alertText: {
    fontFamily: 'Gilroy-Bold'
  },
  main: {
    paddingBottom: 42,
    [theme.breakpoints.down('sm')]: { paddingTop: 10 }
  },
  center: {
    paddingTop: '40px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 960,
      paddingLeft: '0 !important',
      minWidth: 320
    }
  },
  link: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 13,
    lineHeight: 0.77,
    color: '#3577d4',
    marginRight: 0
  },
  textTitle: {
    [theme.breakpoints.down('sm')]: { textAlign: 'left', marginBottom: 24, marginLeft: 10 }
  },
  textSubtitle: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 20,
    lineHeight: 'normal',
    color: '#0b1829',
    paddingTop: 10,
    textAlign: 'left'
  },
  subtitleContainer: {
    paddingBottom: 15
  },
  orderEmptyMain: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 12
  },
  teamLeaderName: {
    fontFamily: 'Gilroy', // 'Gilroy-Semibold'
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.6,
    textAlign: 'center',
    color: '#0b1829'
  },
  role: {
    fontFamily: 'Gilroy', // 'Gilroy-Medium'
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.57,
    textAlign: 'center',
    color: '#787b80'
  },
  proofContainer: {
    backgroundColor: theme.palette.common.white,
    border: 'solid 1px #ebeef2',
    height: 'fit-content',
    borderRadius: 15,
    padding: 20,
    marginTop: 25,
    [theme.breakpoints.down('sm')]: { marginLeft: 10, marginRight: 10 }
  },
  orderContainer: {
    backgroundColor: '#ffffff',
    border: 'solid 1px #ebeef2',
    borderRadius: 15,
    padding: 20,
    marginTop: 24,
    [theme.breakpoints.down('sm')]: { marginLeft: 10, marginRight: 10 }
  },
  releasesContainer: {
    backgroundColor: '#F5ECFC',
    border: 'solid 1px #E5E7E8',
    borderRadius: 16,
    width: '100%',
    marginTop: 25,
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: { marginLeft: 10, marginRight: 10, padding: 16 }
  },
  releasesImg: {
    width: '100%',
    [theme.breakpoints.down('sm')]: { width: 140 },
    [theme.breakpoints.down('xs')]: { width: 70 }
  },
  releasesContentContainer: {
    flex: 1,
    padding: '0 40px 20px',
    [theme.breakpoints.down('sm')]: { padding: 0, paddingLeft: 10 }
  },
  shipmentsContainer: {
    backgroundColor: '#ffffff',
    border: 'solid 1px #ebeef2',
    borderRadius: 15,
    padding: 20,
    paddingBottom: 12,
    marginTop: 24,
    [theme.breakpoints.down('sm')]: { marginLeft: 10, marginRight: 10 }
  },
  smallContainer: {
    flexDirection: 'row',
    position: 'relative',
    maxHeight: 104
  },
  orderGridList: {
    ...scrollBar,
    height: 460,
    width: '100%'
  },
  shipmentGridList: {
    ...scrollBar,
    height: 350,
    width: '100%',
    [theme.breakpoints.down('sm')]: { height: 380 },
    [theme.breakpoints.down('md')]: { height: 360 }
  },
  shipmentListES: {
    height: 390
  },
  proofItem: {
    width: '100%',
    [theme.breakpoints.down('xs')]: { height: '100%' }
  },
  orderListES: {
    height: 380
  },
  proofItemES: {
    [theme.breakpoints.down('md')]: { height: '100%' }
  },
  separateOrders: {
    paddingRight: 25,
    [theme.breakpoints.down('sm')]: { paddingRight: 0 }
  },
  separateShipments: {
    paddingRight: 25,
    [theme.breakpoints.down('sm')]: { paddingRight: 0 }
  },
  separateProofContainer: {
    paddingTop: 13
  },
  separateTeamLeaderNameText: {
    paddingBottom: 10
  },
  separateRoleText: {
    paddingBottom: 20
  },
  separateSendSwagButton: {
    marginRight: 25,
    [theme.breakpoints.down('sm')]: { marginLeft: 10 }
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: { paddingRight: 10, paddingLeft: 10, flexDirection: 'row', justifyContent: 'left' }
  },
  viewAll: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 13,
    lineHeight: 0.77,
    color: '#3577d4'
  },
  arrowIcon: {
    marginLeft: 29,
    marginRight: -14
  },
  addIcon: {
    fontSize: 30,
    marginLeft: 27,
    marginRight: -20
  },
  membershipImage: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 68,
    height: 68
  },
  yourRewards: {
    height: '100%'
  },
  rewardsDetails: {
    paddingLeft: '15px'
  },
  statusPanelTitle: { color: '#0B1829', marginBottom: 8 },
  statusPanelDescription: { color: '#868A8F', marginBottom: 16 },
  availableCredits: {
    fontFamily: 'Inter',
    fontSize: 32,
    fontWeight: 500,
    color: '#9846DD',
    lineHeight: '44px'
  },
  yourCreditsWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 5
  },
  yourCreditsText: {
    color: '#3577D4',
    textAlign: 'center',
    fontSize: 13,
    fontFamily: 'Inter',
    lineHeight: '12px',
    fontWeight: 600
  },
  availableCreditsPanel: {
    marginTop: ({ earlyBirdPromotion }) => (earlyBirdPromotion ? 54 : 44),
    border: '1px solid #EBEDF0',
    borderRadius: '16px',
    padding: '16px 6px 16px 12px'
  },
  availableCreditsPanelWithLeftNavBar: {
    marginTop: '0px',
    border: '1px solid #EBEDF0',
    borderRadius: '16px',
    padding: '16px 6px 16px 12px'
  },
  cardBalance: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  repAvatar: { width: 56, height: 56, borderRadius: 15, background: '#F2F6FA', color: '#3577D4' },
  repName: { color: '#1C4783', lineHeight: '24px', ...ellipsisStyles, maxWidth: 158 },
  contactIcon: { width: 48, height: 48, borderRadius: 15, background: '#F2F6FA', color: '#3577D4', padding: 12 }
});

export default styles;
