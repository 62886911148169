import React from 'react';
import { Grid, Select, FormControl, MenuItem, Checkbox, Typography, makeStyles } from '@material-ui/core';
import { IndeterminateCheckBox } from '@material-ui/icons';
import clsx from 'clsx';
import PaddingLessOutlinedInput from '../../../shared/inputs/PaddingLessOutlinedInput';

import styles from './styles/Actions';

const useActionsStyles = makeStyles(styles);

const Actions = ({ areAllSelected, isPartSelected, onAction, onCheck, allowSendSwag, allowRemoveAction }) => {
  const classes = useActionsStyles();

  return (
    <FormControl variant="outlined" style={{ width: 180 }}>
      <Select
        id="statusSelect"
        value={-1}
        onChange={onAction}
        input={<PaddingLessOutlinedInput labelWidth={0} name="action" />}
        inputProps={{ className: classes.input }}
        className={classes.select}
      >
        <MenuItem value={-1}>
          <Grid>
            <Checkbox
              checked={areAllSelected || isPartSelected}
              indeterminate={isPartSelected}
              onChange={onCheck}
              indeterminateIcon={<IndeterminateCheckBox className={classes.indeterminate} />}
              className={classes.checkbox}
              style={{ color: areAllSelected ? '#3577d4' : '#8d9299' }}
            />
            <Typography variant="button" className={classes.actionsText}>
              Actions
            </Typography>
          </Grid>
        </MenuItem>
        <MenuItem value="ship" disabled={!allowSendSwag}>
          <Typography variant="button" className={clsx(classes.actionsText, classes.optionContainer)}>
            <strong> Send Swag</strong>
          </Typography>
        </MenuItem>
        <MenuItem value="remove" disabled={!allowRemoveAction}>
          <Typography variant="button" className={clsx(classes.actionsText, classes.optionContainer)}>
            <strong> Remove Contacts </strong>
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default Actions;
