const sendPaymentDataToAnet = async data => {
  const secureData = {
    authData: {
      clientKey: process.env.REACT_APP_AUTHORIZE_API_PUBLIC_KEY,
      apiLoginID: process.env.REACT_APP_AUTHORIZE_API_LOGIN_KEY
    }
  };
  if (data.ccnumber !== undefined) {
    const [month, year] = data.exdate.replace(/ /g, '').split('/');
    secureData.cardData = {
      cardNumber: data.ccnumber.replace(/ /g, ''),
      fullName: data.fullName,
      cardCode: data.cvc,
      month,
      year
    };
  } else if (data.canumberc !== undefined && !data.canumberc.includes('XX'))
    secureData.bankData = {
      accountNumber: data.canumberc,
      routingNumber: data.rnumber,
      nameOnAccount: data.naccount,
      accountType: data.account_type
    };
  const result = await new Promise(resolve => {
    const responseHandler = ({ messages, opaqueData }) =>
      resolve(messages.resultCode === 'Error' ? messages : opaqueData);
    window.Accept.dispatchData(secureData, responseHandler);
  });
  return result;
};

export { sendPaymentDataToAnet };
