import { scrollBar } from '../../../../../shared/styles/commonStyles';

const styles = () => ({
  title: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 13,
    color: '#434c5f'
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    textAlign: 'center',
    marginBottom: 40,
    color: '#434c5f'
  },
  dialog: {
    maxWidth: 1080,
    maxHeight: 874
  },
  closeIcon: {
    color: '#434c5f',
    cursor: 'pointer'
  },
  orderContent: {
    ...scrollBar,
    paddingLeft: 10,
    paddingRight: 10,
    maxHeight: 350
  }
});

export default styles;
