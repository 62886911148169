import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: '#0f2440',
    marginLeft: -6,
    marginTop: -5
  },
  title: {
    paddingLeft: 10,
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 40,
    color: '#0f2440'
  }
});

const BackLinkPageTitle = props => {
  const classes = useStyles(props);
  const { link, title } = props;
  return (
    <>
      <Link to={link} className={classes.root}>
        <ArrowBack className={classes.icon} />
      </Link>
      <span className={classes.title}>{title}</span>
    </>
  );
};

export default BackLinkPageTitle;
