import React, { Component } from 'react';

class Loader extends Component {
  state = { items: [] };

  async componentDidMount() {
    const { loader, filterFunc } = this.props;
    const value = await loader();
    this.setState({ items: filterFunc ? filterFunc(value.data || []) : value.data });
  }

  render() {
    const { children, property } = this.props;
    const { state } = this;
    if (!children || !state.items || state.items.length < 1) return <div />;
    const component = { ...children, props: { ...children.props, [property]: state.items } };
    return <>{component}</>;
  }
}

export default Loader;
