import { FETCH_STORAGE_PRICES, FETCH_STORAGE_CATEGORIES } from './types';
import DashBoard from '../apis/DashBoard';
import log from '../logger';
import { okAndLog, errorAndLog, contentAppJSON } from '../helpers/utils';
import apiPaths from '../helpers/apiPaths';

const buildStorageCategoryPriceDict = storagePrices =>
  storagePrices.reduce(
    (r, storageCat) => ({
      ...r,
      [storageCat.storage_category]: storageCat.price
    }),
    {}
  );

export const fetchStoragePrices = () => async dispatch => {
  log.debug('fetchStoragePrices Action - Entering');
  try {
    const apiCall = await DashBoard.get(apiPaths.storagePrices, contentAppJSON);
    if (apiCall.status === 200) {
      dispatch({
        type: FETCH_STORAGE_PRICES,
        payload: buildStorageCategoryPriceDict(apiCall.data.results)
      });
      return okAndLog('fetchStoragePrices', apiCall.status, apiCall.data);
    }
    return errorAndLog('fetchStoragePrices', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('fetchStoragePrices', e.status, e.data);
  }
};

export const fetchStorageCategories = () => async dispatch => {
  log.debug('fetchStorageCategories Action - Entering');
  try {
    const apiCall = await DashBoard.get(apiPaths.storageCategories, contentAppJSON);
    if (apiCall.status === 200) {
      dispatch({
        type: FETCH_STORAGE_CATEGORIES,
        payload: apiCall.data.results
      });
      return okAndLog('fetchStorageCategories', apiCall.status, apiCall.data);
    }
    return errorAndLog('fetchStorageCategories', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('fetchStorageCategories', e.status, e.data);
  }
};
