import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, FormControlLabel, IconButton, Tooltip, Typography, Hidden, TextField } from '@material-ui/core';
import { DeleteOutline as DeleteIcon } from '@material-ui/icons/';
import { cardImg } from '../../helpers/utils';
import { BlueSwitch } from './AccountSharedComponents';
import { paymentMethods } from '../../apis/constants';
import { editPaymentProfileName, setDefaultPaymentProfile } from '../../actions';
import toErrorPage from '../../helpers/toErrorPage';

const RemoveIcon = ({ classes, tooltipTitle, disabled, onDelete }) => (
  <Tooltip title={tooltipTitle} arrow>
    <span className={classes.deleteButtonContainer}>
      <IconButton className={classes.deleteButton} disabled={disabled} onClick={onDelete}>
        <DeleteIcon className={classes.deleteIcon} />
      </IconButton>
    </span>
  </Tooltip>
);

const EditIcon = ({ classes, onEdit }) => (
  <span>
    <IconButton className={classes.editButton} onClick={onEdit}>
      <img src="/images/account/edit-icon.svg" alt="edit" />
    </IconButton>
  </span>
);

const DefaultSwitch = ({ classes, isDefault, onDefault }) => (
  <FormControlLabel
    control={
      <BlueSwitch checked={isDefault} disabled={isDefault} onChange={onDefault} value={isDefault} size="small" />
    }
    classes={{ label: classes.labelPaymentSwitch, disabled: classes.labelPaymentSwitchDisabled }}
    labelPlacement="start"
    label="Default"
  />
);

const PaymentName = ({ classes, currentName, onToggleEdit }) => (
  <>
    <Typography variant="body2" className={classes.paymentName} onClick={onToggleEdit}>
      {currentName || 'Name your card'}
    </Typography>
    <EditIcon classes={classes} onEdit={onToggleEdit} />
  </>
);

const PaymentEditInput = ({ classes, accountName, currentName, setCurrentName, onToggleEdit }) => {
  const handleChange = ({ target: { value } }) => setCurrentName(value);

  const handleEdit = () => {
    setCurrentName(currentName.trim());
    onToggleEdit(currentName.trim());
  };

  const handleKeyDown = event => {
    if (event.keyCode === 27) {
      setCurrentName(accountName);
      onToggleEdit(accountName);
    } else if (event.keyCode === 13) handleEdit();
  };

  return (
    <TextField
      type="text"
      value={currentName}
      autoFocus
      onChange={handleChange}
      onBlur={handleEdit}
      onKeyDown={handleKeyDown}
      InputProps={{ disableUnderline: true, inputProps: { maxLength: 50 }, classes: { input: classes.input } }}
    />
  );
};

const AccountInfo = ({ account, classes, isEdit, onEdit }) => {
  const [currentName, setCurrentName] = useState(account.payment_profile_name);
  const [paymentMehodImg, paymentMethodInfo] =
    account.payment_method === paymentMethods.creditCard
      ? [cardImg(account.credit_card.card_type), account.credit_card.card_number]
      : ['/images/account/ach.svg', account.bank_account.name_on_account];

  const PaymentField = isEdit ? PaymentEditInput : PaymentName;

  const b2 = account.bill_to;
  const billingAddr = `${b2.address1}${b2.address2 ? ` ${b2.address2}` : ''}, ${b2.state} ${b2.zip_code} ${b2.country}`;

  return (
    <Grid container alignItems="center">
      <Grid item>
        <div className={classes.cardImageContainer}>
          <img src={paymentMehodImg} alt={account.payment_method} className={classes.cardImage} />
        </div>
      </Grid>
      <Grid item xs className={classes.infoSection}>
        <Grid item container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
          <PaymentField
            classes={classes}
            accountName={account.payment_profile_name}
            currentName={currentName}
            setCurrentName={setCurrentName}
            onToggleEdit={onEdit}
          />
        </Grid>
        <Hidden xsDown>
          <Typography variant="body2" className={classes.infoAddress}>
            {paymentMethodInfo} <span style={{ marginLeft: 10 }}>{billingAddr}</span>
          </Typography>
        </Hidden>
      </Grid>
    </Grid>
  );
};

const AccountCard = ({ classes, account, company, deletePaymentProfile }) => {
  const [isEditView, setIsEditView] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { customer_payment_profile_id: paymentProfileId } = account;

  const handleEdit = async newPaymentName => {
    setIsEditView(isEdit => !isEdit);
    if (isEditView && account.payment_profile_name !== newPaymentName) {
      const response = await dispatch(editPaymentProfileName(company.id, paymentProfileId, newPaymentName));
      if (response.result === 'error') toErrorPage(response, history);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    const response = await dispatch(deletePaymentProfile(company.id, paymentProfileId));
    if (response.result === 'error') toErrorPage(response, history);
  };

  const handleDefault = async () => {
    const response = await dispatch(setDefaultPaymentProfile(company.id, paymentProfileId, !account.default));
    if (response.result === 'error') toErrorPage(response, history);
  };

  return (
    <Grid container className={deletePaymentProfile ? classes.containerGrey : classes.containerBlue}>
      <Grid item xs={9}>
        <AccountInfo account={account} classes={classes} isEdit={isEditView} onEdit={handleEdit} />
      </Grid>
      <Grid item container xs={3} justifyContent="flex-end">
        <RemoveIcon
          tooltipTitle={`${deletePaymentProfile ? 'Remove' : "Can't remove default"} ${account.payment_method}`}
          disabled={!deletePaymentProfile || isDeleting}
          onDelete={handleDelete}
          classes={classes}
        />
        <DefaultSwitch classes={classes} isDefault={account.default} onDefault={handleDefault} />
      </Grid>
    </Grid>
  );
};

export default AccountCard;
