export const commonText = {
  fontFamily: 'Inter',
  fontSize: 12,
  color: '#8d9299'
};

export default {
  subTitle: {
    fontFamily: 'Inter',
    fontSize: 20,
    color: '#434d5c',
    marginBottom: 16
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: 15,
    padding: '24px 24px',
    border: 'solid 1px #E5E7E8',
    marginBottom: 12
  },
  cardSection: {
    '&~&': {
      borderTop: 'solid 1px #ebecf5'
    }
  },
  commonSubTitle: {
    ...commonText,
    whiteSpace: 'nowrap',
    marginBottom: 4
  }
};
