import React from 'react';
import { Button, withStyles, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Helmet } from '../shared';
import tags from '../../apis/seoTags';
import styles from './styles/InventoryEmpty';

const InventoryEmpty = ({ classes }) => (
  <div className={classes.container}>
    <Helmet tags={tags.inventory} />
    <Grid container justifyContent="center" spacing={0}>
      <div className={classes.root}>
        <div className={classes.emptyInventoryCard}>
          <h1 className={classes.emptyInventoryHeader}>Manage your inventory</h1>
          <img src="/images/inventory/inventory.svg" alt="Confirmed" />
          <p className={classes.inventoryNotice}>
            Once you have packs, this is where you&apos;ll see when stock levels are low and reorder more.
          </p>
          <div className={classes.emptyInventoryActions}>
            <Link to="/product-onboarding">
              <Button className={classes.submitButton}>Order now</Button>
            </Link>
          </div>
        </div>
      </div>
    </Grid>
  </div>
);

export default withStyles(styles)(InventoryEmpty);
