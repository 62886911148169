import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, CenteredGrid, GoBack } from '../shared';
import accountProductsApi from '../../apis/swagup/accountProducts';
import tags from '../../apis/seoTags';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import apiPaths from '../../helpers/apiPaths';
import global from '../../apis/swagup/global';
import Loader from '../global/Loader';
import { creditApi } from '../../apis/swagup';
import { useCompany, useCreditSummary, useIsAchPrepaid, useQueryParams } from '../../hooks';
import CreditsSwitch from '../global/sendSwagReviewCommons/CreditsSwitch';
import { getPaymentProfiles, setDefaultPaymentProfile } from '../../actions';
import toErrorPage from '../../helpers/toErrorPage';
import { ProductColor } from './InventoryCommon';
import PaymentSection from '../global/sendSwagReviewCommons/PaymentSection';
import PaymentProfilesModal from '../global/sendSwagReviewCommons/PaymentProfilesModal';

const styles = theme => ({
  container: {
    marginTop: 0,
    paddingTop: 16,
    paddingBottom: 42,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  titleContainer: {
    [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
  },
  modalViewDetailsContainer: {
    borderRadius: 12,
    background: 'white',
    padding: '0px 1px 12px 1px'
  },
  automationModal: {
    height: 'auto',
    maxHeight: 'fit-content',
    padding: 0,
    marginTop: '112px',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: 'white',
    width: 1440,
    overflow: 'hidden',
    [theme.breakpoints.only('lg')]: {
      width: 1200
    },
    [theme.breakpoints.only('md')]: {
      width: 900
    },
    [theme.breakpoints.only('sm')]: {
      width: 600
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  automationModalHeader: {
    padding: '0px 4px 0px 32px',
    height: '52px',
    borderBottom: '1px solid #E5E7E8'
  },
  automationModalHeaderText: {
    ...ellipsisStyles,
    color: '#131415',
    fontSize: '20px',
    lineHeight: ' 28px',
    letterSpacing: '-0.03em'
  },
  modalBody: {
    minHeight: '80vh'
  },
  pictureContainer: {
    paddingRight: 12
  },
  imageContainer: {
    width: 42,
    height: 42,
    position: 'relative'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  storageItemsContainer: {
    paddingTop: '24px'
  },
  storageItemRow: {
    border: 'solid 1px #ebeef2',
    borderRadius: 15,
    backgroundColor: '#ffffff',
    padding: '12px 24px 12px 16px',
    marginBottom: 12,
    position: 'relative'
  },
  summaryContainer: {
    border: 'solid 1px #ebeef2',
    borderRadius: 15,
    backgroundColor: '#ffffff',
    padding: '24px 16px'
  }
});

const useStyles = makeStyles(styles);

const StoragePayment = () => {
  const classes = useStyles();

  const [showPaymentsModal, setShowPaymentsModal] = React.useState(false);
  const [useCreditsFirst, setUseCreditsFirst] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [absoluteStorageCost, setAbsoluteStorageCost] = React.useState(0);
  const [absoluteStorageQuantity, setAbsoluteStorageQuantity] = React.useState(0);
  const [stagnantInventory, setStagnantInventory] = React.useState([]);

  const { data: company } = useCompany();
  // const isAchPrepaid = useIsAchPrepaid();
  const history = useHistory();

  const location = useLocation();
  const query = useQueryParams();

  const { allow_negative_credit_balance: allowNegativeCreditBalance } = company;

  const paymentProfiles = useSelector(state => state.paymentProfiles);

  const paymentProfile = paymentProfiles.find(pm => pm.default);
  const { data, isLoading } = useQuery([apiPaths.accountProducts, { inventory: 'available' }], () =>
    accountProductsApi.fetch({
      ids: query
        .get('selected')
        .toString()
        .replace('selected=', '')
        .replaceAll('%2C', ',')
    })
  );

  const { results } = data || { results: [] };

  const dispatch = useDispatch();
  const paymentProfileQuery = useQuery(
    [apiPaths.paymentProfiles, company.id],
    () => dispatch(getPaymentProfiles(company.id)),
    {
      enabled: !isEmpty(company),
      onError: error => toErrorPage(error, history)
    }
  );

  const firstRender = React.useRef(true);
  React.useEffect(() => {
    firstRender.current = false;
  }, []);

  const { data: creditSummary } = useCreditSummary({ enabled: firstRender.current || Boolean(errorMessage) });
  const { current_balance: balance } = creditSummary;

  // const onToggleUseCredits = () => setUseCreditsFirst(prev => !prev);

  const { data: storagePrices, isLoading: storagePicesIsLoading } = useQuery(
    [apiPaths.storagePrices],
    global.fetchStoragePrices
  );
  const wrapItemPrice = React.useCallback(
    item => {
      if (!isEmpty(storagePrices?.results)) {
        const itemCategory = storagePrices.results.find(sp => sp.storage_category === item.storage_category);
        const storagePrice = parseFloat(itemCategory.price);
        const totalQuantity = item.stock.reduce((sum, s) => sum + s.quantity, 0);

        return { ...item, storageCost: (totalQuantity * storagePrice).toFixed(2), totalQuantity, storagePrice };
      }
      return { ...item, storageCost: 0, totalQuantity: 0, storagePrice: 0 };
    },
    [storagePrices]
  );

  React.useEffect(() => {
    if (!isEmpty(results)) {
      const wrapped = results.map(inv => wrapItemPrice(inv));
      setStagnantInventory(wrapped);
      setAbsoluteStorageCost(wrapped.reduce((sum, w) => sum + parseFloat(w.storageCost), 0));
      setAbsoluteStorageQuantity(wrapped.reduce((sum, w) => sum + parseInt(w.totalQuantity, 10), 0));
    }
  }, [storagePrices, results, wrapItemPrice]);

  const queryClient = useQueryClient();
  const storagePayment = useMutation(paymentInfo => creditApi.payment(paymentInfo), {
    onSuccess: res => {
      queryClient.invalidateQueries([apiPaths.transactions]);
      history.push({
        pathname: '/storage-payment-overview/',
        state: {
          from: location,
          data: res,
          products: stagnantInventory,
          totalQuantity: absoluteStorageQuantity,
          totalCost: absoluteStorageCost
        }
      });
    },
    onError: () => {
      setErrorMessage('Oops there was an error...');
    }
  });

  const onCheckout = () => {
    storagePayment.mutate({
      amount: parseFloat(absoluteStorageCost).toFixed(2),
      description: 'Storage fee payment',
      codes: stagnantInventory.map(si => si.id).join('-'),
      useCreditsFirst,
      reference_to: absoluteStorageQuantity
    });
  };

  const handleSelectDefaultPaymentProfile = paymentProfileId => {
    dispatch(setDefaultPaymentProfile(company.id, paymentProfileId, true));
    setShowPaymentsModal(false);
  };

  return (
    <>
      <Helmet tags={tags.integrations} />
      <CenteredGrid container justifyContent="center" className={classes.container}>
        <Grid container alignItems="center" style={{ marginBottom: 12 }}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <GoBack />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography variant="h2BoldInter">Storage Payment</Typography>
          </Grid>
          <Grid item>
            {/* <CreditsSwitch
              balance={balance}
              useCreditsFirst={useCreditsFirst}
              onToggleUseCredits={onToggleUseCredits}
              disabled={balance <= 0 && !allowNegativeCreditBalance}
              strict
            /> */}
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={8}>
            <Box className={classes.storageItemsContainer}>
              {query
                .toString()
                .replace('selected=', '')
                .split('%2C').length === stagnantInventory.length &&
                stagnantInventory.map(inv => (
                  <Grid key={inv.id} container alignItems="center" className={classes.storageItemRow}>
                    <Grid item>
                      <Box className={classes.pictureContainer}>
                        <Box className={classes.imageContainer}>
                          <img src={inv.image} alt={inv.name} className={classes.image} />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Typography component="p" variant="body4MediumInter">
                        {inv.name}
                      </Typography>
                      <Grid style={{ marginTop: 12, marginLeft: 2 }}>
                        <ProductColor item={inv} />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Typography component="p" variant="body5MediumInter">
                        Total Quantity
                      </Typography>
                      <Grid style={{ marginTop: 12, marginLeft: 2 }}>
                        <Typography component="p" variant="body4MediumInter">
                          {inv.totalQuantity}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography component="p" variant="body5MediumInter">
                        Storage Price
                      </Typography>
                      <Grid style={{ marginTop: 12, marginLeft: 2 }}>
                        <Typography component="p" variant="body4MediumInter">
                          ${inv.storagePrice}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Typography component="p" variant="body5MediumInter" style={{ textAlign: 'right' }}>
                        Storage Cost
                      </Typography>
                      <Grid style={{ marginTop: 12, marginLeft: 2 }}>
                        <Typography component="p" variant="body4MediumInter" style={{ textAlign: 'right' }}>
                          ${inv.storageCost}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.storageItemsContainer}>
              <Box className={classes.summaryContainer}>
                <Typography component="p" variant="body3MediumInter">
                  Summary
                </Typography>
                <Box style={{ marginTop: 12, paddingTop: 12, borderTop: 'solid 1px #ebeef2' }}>
                  <Grid container alignItems="flex-end" style={{ marginBottom: 12 }}>
                    <Grid item xs>
                      <Typography component="p" variant="body4RegularInter">
                        Total Quantity
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="p" variant="body3MediumInter">
                        {absoluteStorageQuantity}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="flex-end">
                    <Grid item xs>
                      <Typography component="p" variant="body4RegularInter">
                        Total Cost
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="p" variant="body3MediumInter">
                        ${absoluteStorageCost.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <PaymentSection
                      allowNegativeCreditBalance={allowNegativeCreditBalance}
                      useCreditsFirst={useCreditsFirst}
                      paymentProfiles={paymentProfiles}
                      totalItems={absoluteStorageQuantity}
                      total={absoluteStorageCost}
                      credits={balance}
                      onShowPaymentsProfiles={() => setShowPaymentsModal(true)}
                    />
                  </Grid>
                </Box>
              </Box>
              <Grid container justifyContent="flex-end" style={{ paddingTop: useCreditsFirst ? 32 : 93 }}>
                <Grid item>
                  <Button
                    variant="primary"
                    size="small"
                    onClick={onCheckout}
                    disabled={!paymentProfile}
                    style={{ width: 180 }}
                  >
                    Pay for Storage
                  </Button>
                  {errorMessage && (
                    <Typography
                      component="p"
                      variant="body4RegularInter"
                      style={{ marginTop: '12px', textAlign: 'center', color: '#f44336' }}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {(isLoading || storagePicesIsLoading || storagePayment.isLoading) && <Loader absolute />}

        <PaymentProfilesModal
          open={showPaymentsModal}
          onClose={() => setShowPaymentsModal(false)}
          paymentProfile={paymentProfile}
          paymentProfiles={paymentProfiles}
          onSelectDefaultPaymentProfile={handleSelectDefaultPaymentProfile}
        />
      </CenteredGrid>
    </>
  );
};

export default StoragePayment;
