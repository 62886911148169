import React, { useEffect } from 'react';
import { makeStyles, withStyles, Snackbar, Grid, LinearProgress, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Button as SwagButton } from '@swagup-com/components';
import { useSelector, useDispatch } from 'react-redux';
import { PROCESS_FAILED, PROCESS_IDLE, PROCESS_PENDING, PROCESS_SUCCESS } from '../../actions/types';
import { statuses } from '../../reducers/processReducer';
import { useAsync } from '../../hooks';
import csvProcessesApi from '../../apis/swagup/csvProcesses';

const commonText = {
  fontSize: 12,
  lineHeight: 'normal',
  letterSpacing: 'normal'
};

const useStyles = makeStyles(theme => ({
  commonText: {
    ...commonText,
    fontFamily: 'Gilroy-Medium',
    color: '#0b1829'
  },

  container: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 58
    }
  },

  waitingContainer: {
    width: 224,
    padding: '8px 12px 11px 12px',
    borderRadius: 10,
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff'
  },
  progress: {
    height: 3,
    borderRadius: 2,
    backgroundColor: '#f0f2f5',
    marginTop: 13
  },

  failedContainer: {
    width: 306,
    padding: '12px 15px 12px 14px',
    borderRadius: 10,
    backgroundColor: '#fff6ee'
  },

  successContainer: {
    width: 138,
    padding: '10px 10px 10px 14px',
    borderRadius: 10,
    backgroundColor: '#f2f6fd'
  }
}));

export const ProgressContent = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.waitingContainer}>
      <p className={classes.commonText}>{text}</p>
      <LinearProgress classes={{ root: classes.progress }} />
    </div>
  );
};

const Button = withStyles({
  root: {
    ...commonText,
    fontFamily: 'Gilroy-SemiBold',
    minWidth: 'unset',
    height: 'unset',
    padding: 0
  }
})(props => <SwagButton variant="text" {...props} />);

const FailedContent = ({ onRetry, onCancel }) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.failedContainer}>
      <p className={classes.commonText}>Saving failed!</p>
      <div>
        <Button onClick={onRetry}>Try again</Button>
        <Button onClick={onCancel} style={{ color: '#868a8f', marginLeft: 24 }}>
          Cancel
        </Button>
      </div>
    </Grid>
  );
};

const SuccessContent = ({ onClose }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.successContainer}>
      <p className={classes.commonText}>Contacts saved!</p>
      <IconButton onClick={onClose} style={{ padding: 0 }}>
        <CloseIcon style={{ width: 12, height: 12 }} />
      </IconButton>
    </Grid>
  );
};

const Content = ({ onClose }) => {
  const dispatch = useDispatch();
  const { id, status } = useSelector(state => state.process);
  const [fetchProcess, process, , processError] = useAsync(csvProcessesApi.fetchById);
  const [startProcessing, startResponse, , startError] = useAsync(csvProcessesApi.process);

  useEffect(() => {
    if (startError || processError) {
      dispatch({ type: PROCESS_FAILED });
    }
  }, [dispatch, startError, processError]);

  useEffect(() => {
    if (id) {
      startProcessing(id);
    }
  }, [id, startProcessing]);

  useEffect(() => {
    if (startResponse) {
      setTimeout(fetchProcess, 1000, id);
    }
  }, [startResponse, id, fetchProcess]);

  useEffect(() => {
    if (!process) return;

    const completed = process.data?.status === 'Completed';
    if (!completed) {
      setTimeout(fetchProcess, 1000, id);
      return;
    }

    dispatch({
      type: PROCESS_SUCCESS,
      payload: process.data
    });
  }, [process, id, dispatch, fetchProcess]);

  const handleRetry = () => {
    dispatch({ type: PROCESS_PENDING });
    startProcessing(id);
  };

  if ([statuses.success, statuses.successProcessed].includes(status)) {
    return <SuccessContent onClose={onClose} />;
  }

  if (status === statuses.error) {
    return <FailedContent onRetry={handleRetry} onCancel={onClose} />;
  }

  return <ProgressContent text="Saving contacts" />;
};

const SavingContacts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { status, autoHideDuration } = useSelector(state => state.process);

  const handleClose = (_, reason) => {
    if (reason !== 'clickaway') {
      dispatch({ type: PROCESS_IDLE });
    }
  };

  return (
    <Snackbar
      open={status !== statuses.idle}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      className={classes.container}
    >
      <Content onClose={handleClose} />
    </Snackbar>
  );
};

export default SavingContacts;
