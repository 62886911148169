import { ellipsisStyles } from '../../../../shared/styles/commonStyles';

const styles = () => ({
  img: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    margin: 'auto',
    marginLeft: 'center',
    marginRight: 'center'
  },
  title: {
    ...ellipsisStyles,
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  titleSmall: {
    ...ellipsisStyles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  secondaryText: {
    ...ellipsisStyles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    marginTop: 10
  },
  secondaryTextSmall: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    marginTop: 8
  },
  normalTextSmall: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    marginTop: 8
  },
  normalText: {
    ...ellipsisStyles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    marginTop: 6
  },
  linkText: {
    ...ellipsisStyles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4',
    marginTop: 6,
    cursor: 'pointer'
  }
});

export default styles;
