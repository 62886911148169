import {
  buttonLink,
  featuredProductCatalog,
  headerTitle,
  image,
  productCatalog,
  produtCatalogSectionText,
  subHeaderText,
  subtitleText
} from './components';

const heroSection = {
  height: '80vh',
  contentAlignment: 'center',
  templateName: 'hero-section',
  variant: 'hero-two-columns',
  padding: 'unset',
  verticalAlignment: 'unset',
  isWideScreen: false,
  columns: [
    {
      id: 1,
      width: '50%',
      padding: '0px 24px',
      components: [headerTitle, subtitleText, buttonLink]
    },
    {
      id: 2,
      width: '50%',
      padding: '0px 24px',
      components: [image]
    }
  ]
};

const heroSectionTwoColumns = {
  height: '80vh',
  contentAlignment: 'center',
  templateName: 'hero-section',
  variant: 'hero-two-columns',
  backgroundImage: 'unset',
  backgroundPosition: 'unset',
  backgroundSize: 'unset',
  overlayColor: 'unset',
  overlayOpacity: 0,
  padding: 'unset',
  borderRadius: '16px',
  verticalAlignment: 'unset',
  isWideScreen: false,
  columns: [
    {
      width: '50%',
      padding: '16px 24px',
      contentAlignment: 'left',
      verticalAlignment: 'center',
      components: [headerTitle, subtitleText, buttonLink]
    },
    {
      width: '50%',
      padding: '0px 0px',
      verticalAlignment: 'center',
      components: [image]
    }
  ]
};

const heroSectionLandscape = {
  height: '80vh',
  templateName: 'hero-section',
  variant: 'hero-landscape',
  contentAlignment: 'center',
  backgroundImage: '/images/storefront/storefront-hero.png',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  overlayColor: '#000000',
  overlayOpacity: 0.2,
  padding: 'unset',
  verticalAlignment: 'unset',
  isWideScreen: false,
  columns: [
    {
      width: '60%',
      padding: '15% 12px',
      verticalAlignment: 'center',
      components: [
        { ...headerTitle, contentAlignment: 'center' },
        { ...subtitleText, contentAlignment: 'center' },
        { ...buttonLink, contentAlignment: 'center' }
      ]
    }
  ]
};

const productCatalogSection = {
  height: 'auto',
  templateName: 'product-catalog-section',
  variant: 'minimal',
  contentAlignment: 'center',
  backgroundImage: 'unset',
  backgroundPosition: 'unset',
  backgroundSize: 'unset',
  overlayColor: 'unset',
  overlayOpacity: 0,
  padding: 'unset',
  verticalAlignment: 'unset',
  isWideScreen: false,
  columns: [
    {
      width: '100%',
      padding: '64px 12px',
      components: [subHeaderText, produtCatalogSectionText, productCatalog]
    }
  ]
};

const featuredProductCatalogSection = {
  height: 'auto',
  templateName: 'featured-product-catalog-section',
  variant: 'minimal',
  contentAlignment: 'center',
  backgroundImage: 'unset',
  backgroundPosition: 'unset',
  backgroundSize: 'unset',
  overlayColor: 'unset',
  overlayOpacity: 0,
  padding: 'unset',
  verticalAlignment: 'unset',
  isWideScreen: false,
  columns: [
    {
      width: '100%',
      padding: '64px 12px',
      components: [{ ...subHeaderText, value: 'Featured Products' }, featuredProductCatalog]
    }
  ]
};

export {
  heroSection,
  heroSectionTwoColumns,
  heroSectionLandscape,
  productCatalogSection,
  featuredProductCatalogSection
};
