import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Link } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Zoom from '@material-ui/core/Zoom';
import { useInfiniteQuery } from 'react-query';
import useMembershipCheck from '../../hooks/useMembershipCheck';

const textAlignment = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    margin: '30px 0px 20px 0px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
  },
  rootModal: {
    borderRadius: 20,
    margin: '10px 0px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
  },
  accordionGrid: {
    height: 510,
    marginBottom: 10
  },
  accordionGridModal: {
    height: 410,
    marginBottom: 10
  },
  accordion: {
    width: '500px',
    borderRadius: '10px !important',
    marginBottom: '10px !important',
    boxShadow: 'none',
    border: 'none',
    postion: 'static !important'
  },
  accordionGif: {
    ...textAlignment,
    width: 500,
    height: 500,
    backgroundColor: '#D7E4F6',
    borderRadius: '20px'
  },
  accordionGifModal: {
    ...textAlignment,
    width: 400,
    height: 400,
    backgroundColor: '#D7E4F6',
    borderRadius: '20px'
  },
  accordionGifImg: {
    width: 500,
    height: 500,
    borderRadius: 20,
    objectFit: 'contain'
  },
  accordionGifImgModal: {
    width: 400,
    height: 400,
    borderRadius: 20,
    objectFit: 'contain'
  },
  storeLaunch: {
    ...textAlignment,
    width: '100%',
    height: 150,
    margin: '30px 0px'
  },
  storeLaunchModal: {
    ...textAlignment,
    width: '100%',
    height: 150
  },
  storeLaunchImg: {
    width: 180,
    height: 180,
    objectFit: 'contain'
  },
  leftAccordion: {
    ...textAlignment,
    width: 500,
    flexDirection: 'Column',
    '& ::before': {
      backgroundColor: 'transparent'
    }
  },
  leftAccordionModal: {
    ...textAlignment,
    width: 400,
    flexDirection: 'Column',
    '& ::before': {
      backgroundColor: 'transparent'
    }
  },
  accordionHeading: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 30
  },
  accordionSubText: {
    ...textAlignment,
    width: 400,
    paddingBottom: 20
  },
  accordionBadge: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20
  },
  benefitBadge: {
    height: 26,
    width: 190,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: '#3577D4',
    color: '#FFF',
    '& .MuiChip-label': {
      padding: '0px !important'
    }
  }
}));

const StoreLaunch = ({ removeBtn, isInsideModal }) => {
  const classes = useStyles();
  const [activeAccordion, setActiveAccordion] = useState('panel1');
  const [isDetailsVisible, setIsDetailsVisible] = useState(true);

  const handleAccordionChange = panel => isExpanded => {
    setActiveAccordion(isExpanded ? panel : null);
    setIsDetailsVisible(isExpanded);
  };

  const cycleAccordions = () => {
    switch (activeAccordion) {
      case 'panel1':
        setActiveAccordion('panel2');
        setIsDetailsVisible(true);
        break;
      case 'panel2':
        setActiveAccordion('panel3');
        setIsDetailsVisible(true);
        break;
      default:
        setActiveAccordion('panel1');
        setIsDetailsVisible(true);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(cycleAccordions, 8000);
    return () => clearInterval(intervalId);
  }, [activeAccordion]);

  const AccordionComponent = ({ title, subtitle, accordionAction }) => {
    const isLastAccordion = `${accordionAction}` === 'panel3';
    const isAccordionExpanded = activeAccordion === `${accordionAction}`;

    const StoreBenefitBadge = () => {
      const StoreBenefitImage = () => {
        return (
          <img
            src="/images/storefront/store-benefit@4x.png"
            alt="store-benefit"
            width={20}
            height={20}
            style={{ width: '20px', height: '20px', objectFit: 'contain' }}
          />
        );
      };

      const StoreBenefitText = () => {
        return (
          <Typography variant="body4MediumInter" style={{ color: '#fff', padding: '0px 7px' }}>
            Platinum Member Benefit
          </Typography>
        );
      };

      return <Chip icon={<StoreBenefitImage />} label={<StoreBenefitText />} className={classes.benefitBadge} />;
    };

    return (
      <Accordion
        className={classes.accordion}
        expanded={activeAccordion === `${accordionAction}`}
        onChange={handleAccordionChange(`${accordionAction}`)}
        style={{
          backgroundColor: activeAccordion === `${accordionAction}` ? '#D7E4F6' : '#fff',
          transition: activeAccordion === `${accordionAction}` ? '2s ease-in' : null
        }}
      >
        <AccordionSummary expandIcon={null}>
          <Grid className={classes.accordionBadge}>
            <Typography variant="h5SemiBoldInter" className={classes.accordionHeading}>
              {title}
            </Typography>
            {isLastAccordion && isAccordionExpanded && <StoreBenefitBadge />}
          </Grid>
        </AccordionSummary>
        <Collapse in={isAccordionExpanded} timeout="auto" unmountOnExit>
          <AccordionDetails
            style={{ ...textAlignment, maxHeight: isDetailsVisible ? '500px' : '0', overflow: 'hidden' }}
          >
            <Fade in={isDetailsVisible} timeout={500}>
              <Slide
                in={isDetailsVisible}
                direction="up"
                timeout={{ enter: 500, exit: 500 }}
                mountOnEnter
                unmountOnExit
              >
                <Typography variant="body2MediumInter" className={classes.accordionSubText}>
                  {subtitle}
                </Typography>
              </Slide>
            </Fade>
          </AccordionDetails>
        </Collapse>
      </Accordion>
    );
  };

  const { shopsAllowed } = useMembershipCheck();

  return (
    <Grid container className={isInsideModal ? classes.rootModal : classes.root}>
      <Grid className={isInsideModal ? classes.storeLaunchModal : classes.storeLaunch}>
        <img
          alt="Storefront Launch"
          src="/images/storefront/swagup-shops.png"
          width={180}
          height={180}
          className={classes.storeLaunchImg}
        />
        <Grid>
          <Typography variant="h2BoldInter">Swag shops made simple</Typography>
          <Typography variant="h6SemiBoldInter" style={{ color: '#989EA4', padding: '15px 10px' }}>
            Exclusive access for Gold and Platinum members
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        className={isInsideModal ? classes.accordionGridModal : classes.accordionGrid}
        style={{ boxShadow: `${removeBtn}` ? null : 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}
      >
        <Grid item xs={12} sm={6} className={isInsideModal ? classes.leftAccordionModal : classes.leftAccordion}>
          <AccordionComponent
            title="⚡️ Up and Running in Minutes"
            subtitle="A robust swag shop ready to start selling in minutes, not weeks"
            accordionAction="panel1"
          />
          <AccordionComponent
            title="🎨 Simple, Beautiful Design"
            subtitle="Showcase your product in a way your proud of, without the need for a designer"
            accordionAction="panel2"
          />
          <AccordionComponent
            title="🏆 Automate Rewards"
            subtitle="Give credits to your audience to shop, supercharged by integrations"
            accordionAction="panel3"
          />
        </Grid>

        <Grid item xs={12} sm={6} style={{ width: '100%', ...textAlignment }}>
          {activeAccordion === 'panel1' && (
            <Grid container className={isInsideModal ? classes.accordionGifModal : classes.accordionGif}>
              <img
                src="/images/storefront/Storefronts_Gif_01.gif"
                alt="Accordion 1 Gif"
                width={500}
                height={500}
                className={isInsideModal ? classes.accordionGifImgModal : classes.accordionGifImg}
              />
            </Grid>
          )}

          {activeAccordion === 'panel2' && (
            <Grid container className={isInsideModal ? classes.accordionGifModal : classes.accordionGif}>
              <img
                src="/images/storefront/Storefronts_Gif_02.gif"
                alt="Accordion 2 Gif"
                width={500}
                height={500}
                className={isInsideModal ? classes.accordionGifImgModal : classes.accordionGifImg}
              />
            </Grid>
          )}

          {activeAccordion === 'panel3' && (
            <Grid container className={isInsideModal ? classes.accordionGifModal : classes.accordionGif}>
              <img
                src="/images/storefront/Storefronts_Gif_03.gif"
                alt="Accordion 3 Gif"
                width={500}
                height={500}
                className={isInsideModal ? classes.accordionGifImgModal : classes.accordionGifImg}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {!shopsAllowed && (
        <Grid container justifyContent="center" alignItems="center" style={{ padding: '22px 0px', gap: 40 }}>
          <Button variant="primary" component={Link} to="/membership/manage-membership-switch">
            <Typography variant="buttonMediumInter" style={{ color: '#fff' }}>
              Upgrade Membership
            </Typography>
          </Button>
          {!removeBtn && (
            <Button variant="secondary" component={Link} to="/shop-create">
              <Typography variant="buttonMediumInter" style={{ color: '#3577D4' }}>
                Build and Upgrade Later
              </Typography>
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default StoreLaunch;
