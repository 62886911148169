import { scrollBar } from '../../shared/styles/commonStyles';

const filtersText = {
  fontFamily: 'Gilroy-Regular',
  fontSize: 16,
  lineHeight: 0.75,
  color: '#8d9299'
};
const footerButtonCommon = {
  '&:hover': { background: 'none' },
  textTransform: 'none',
  fontFamily: 'Gilroy-Medium',
  fontSize: 14,
  lineHeight: 0.86
};

export default theme => ({
  // Footer
  container: {
    height: '100%',
    alignItems: 'center'
  },
  buttonsContainer: {
    maxWidth: 890,
    justifyContent: 'space-between',
    paddingRight: 50,
    alignItems: 'center'
  },
  selectAllButton: {
    ...footerButtonCommon,
    color: '#434d5c'
  },
  resetButton: {
    ...footerButtonCommon,
    color: '#3577d4'
  },
  continueButton: { width: 136 },
  // Filters
  SearchFieldContainer: { paddingRight: 24 },
  SearchField: {
    borderRadius: 32,
    '&.MuiOutlinedInput-adornedEnd': { paddingRight: 30 },
    '& > fieldset': { border: '1px solid #d3dbe5' },
    '& > .MuiOutlinedInput-input': {
      paddingLeft: 30,
      ...filtersText
    },
    '& .MuiInputAdornment-root svg': { ...filtersText }
  },
  FilterByContainer: { textAlign: 'end' },
  FilterBy: {
    borderRadius: 32,
    textAlign: 'left',
    width: 210,
    '& > .MuiSelect-select': {
      backgroundColor: 'transparent',
      paddingTop: 22,
      paddingBottom: 22
    },
    '& > fieldset': {
      border: '1px solid #d3dbe5'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
      '& > :first-child': { padding: '13.5px 6px' }
    }
  },
  FilterText: {
    ...filtersText,
    '& strong': { color: '#434c5f' },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  },
  // Title here erase before
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    color: '#0f2440'
  },
  RecipientActions: {
    paddingTop: 39.5
  },
  ShippingCalculation: {
    width: 364,
    borderRadius: 10,
    border: 'solid 1px #3577d4',
    backgroundColor: 'rgba(54, 118, 212, 0.08)',
    padding: 25
  },
  CalculationHeader: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    color: ' #8d9299',
    marginBottom: 16
  },
  CalculationHeaderDelivery: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    color: ' #8d9299'
  },
  ShippingMethod: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3676d4',
    marginBottom: 10
  },
  FeesNames: {
    opacity: 0.54,
    fontFamily: 'Roboto',
    fontSize: 12,
    color: '#434c5f',
    paddingBottom: 6
  },
  Fees: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#434c5f'
  },
  TotalFees: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3577d4'
  },
  Symbols: {
    width: 9,
    paddingTop: 12
  },
  SaveButton: {
    margin: '0 auto',
    width: 426,
    height: 56,
    borderRadius: 4,
    backgroundColor: '#3577d4',
    color: '#FFFFFF',
    fontFamily: 'Futura',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 0.75,
    boxShadow: 'none',
    textTransform: 'none',
    '&&:hover': {
      backgroundColor: '#3577d4'
    }
  },
  ButtonContainer: {
    padding: 40,
    textAlign: 'center',
    marginTop: 40.5,
    borderTop: 'solid 1px #ebecf5'
  },
  NotesButton: {
    margin: '0 auto',
    width: 180,
    height: 53,
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    border: '1px solid #3577d4',
    color: '#3577d4',
    fontFamily: 'Futura',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 0.75,
    boxShadow: 'none',
    textTransform: 'none',
    '&&:hover': {
      backgroundColor: '#FFFFFF'
    }
  },
  ShippingType: {
    width: 253,
    height: 80,
    borderRadius: 15,
    border: 'solid 1px #d3dbe5',
    backgroundColor: '#ffffff',
    padding: '18px 22px 0 22px',
    cursor: 'pointer',
    marginTop: 16
  },
  ShippingTypeSelected: {
    width: 253,
    height: 80,
    borderRadius: 15,
    border: 'solid 1px #3577d4',
    backgroundColor: '#ffffff',
    padding: '18px 22px 0 22px',
    cursor: 'pointer',
    marginTop: 16
  },
  MethodTitleSelected: {
    fontFamily: 'Gilroy',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3577d4'
  },
  MethodTitle: {
    fontFamily: 'Gilroy',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#434d5c'
  },
  DeliveryTime: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#8d9299',
    marginTop: 7
  },
  AddedNote: {
    width: 265,

    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    color: '#999ea9',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    '& p': {}
  },
  Note: {
    overflow: 'hidden',
    position: 'relative',
    lineHeight: '1.2em',
    maxHeight: '3.6em',
    textAlign: 'justify',
    paddingRight: '1em',
    paddingBottom: 10
  },
  DeleteNote: {
    width: 90,
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#d43535;',
    fontWeight: 500,
    textTransform: 'none',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#ffffff'
    }
  },
  UpdateNote: {
    width: 160,
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#3676d4',
    textTransform: 'none',
    backgroundColor: '#ffffff',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: '#ffffff'
    },
    '&:active': {
      backgroundColor: '#ffffff',
      boxShadow: 'none'
    }
  },
  EstimatedMessageContainer: {
    width: 216,
    padding: '0 5px'
  },
  EstimatedMessage: {
    margin: '10px auto',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#1db83e'
  },
  notesContainer: {
    width: 356,
    height: 194,
    borderRadius: 32,
    border: 'solid 1px #d3dbe5',
    backgroundColor: '#fff',
    position: 'relative'
  },
  textShippingNote: {
    width: 356,
    height: '100%',
    borderRadius: 32,
    padding: '21px 21px',
    '& div': {
      fontFamily: 'Gilroy-Medium !important',
      fontSize: '16px !important',
      fontWeight: '500 !important',
      lineHeight: '1.75 !important',
      letterSpacing: 'normal',
      color: '#434d5c !important'
    },
    '& :before': {
      border: '0px !important',
      borderBottom: '0px !important'
    },
    '& :after': {
      border: '0px !important',
      borderBottom: '0px !important',
      color: '#3577d4 !important'
    }
  },
  notesLength: {
    position: 'absolute',
    right: 31,
    bottom: 5,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#8d9299'
  },
  previousStepText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    color: '#8d9299',
    marginLeft: 16
  },
  subTextShippingNote: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#8d9299',
    marginTop: 21
  },
  textAreaResize: { ...scrollBar, paddingRight: 8 }
});
