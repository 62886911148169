/* eslint-disable no-nested-ternary */
import React from 'react';
import sumBy from 'lodash/sumBy';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import clsx from 'clsx';
import { includes, round } from 'lodash';
import { AccountBalance, Receipt } from '@material-ui/icons';
import { imageSrcSet, isPack, moneyStr, sumByQuantity } from '../../../../../../helpers/utils';
import { productionTime as productionSpeed } from '../../../../../../apis/constants';
import { ellipsisStyles } from '../../../../../shared/styles/commonStyles';
import StrikeOutText from '../../../../../global/StrikeOutCost';
import AppliedMembershipPanelStatic from '../../../../../global/AppliedMembershipPanelStatic';
import { DownloadIcon } from '../../../../../icons';
import { useMembership } from '../../../../../../contexts/membershipContext';

const commonSummaryText = { paddingTop: 10, color: '#4A4F54' };
const commonTotalItem = { marginTop: 20, borderTop: '1px solid #E5E7E8' };

const useStyles = makeStyles(() => ({
  summaryLabel: commonSummaryText,
  productLabel: { ...commonSummaryText, ...ellipsisStyles },
  rushLabel: { ...commonSummaryText },
  totalValue: { ...commonSummaryText, paddingTop: 20, color: '#000000' },
  totalLabel: { paddingTop: 20, paddingBottom: 20, color: '#000000' },
  subTotalItem: { ...commonTotalItem, paddingTop: 10 },
  totalItem: { ...commonTotalItem, borderBottom: '1px solid #E5E7E8', borderTop: 'none', marginTop: 10 },
  continueButton: { height: 56, fontSize: 16 },
  orderSummaryContainer: {
    border: '1px solid #EBEDF0',
    padding: '16px 24px',
    borderRadius: 15
  },
  paymentWrapper: {
    width: '100%'
  },
  heading: {
    padding: '24px 0px'
  },
  swagCard: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 20
  },
  newFunds: {
    color: '#9846DD'
  },
  downloadBtn: { color: '#3577D4', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }
}));

const ProductSummaryItem = ({ product, classes }) => {
  const showRushItem = product.production_time === productionSpeed.rush;
  const totalQty = sumByQuantity(product.quantities_per_size) || product.quantity || 0;
  const subtotal = totalQty * +(product.price ?? 0);
  const rushFee = totalQty * +(product.rush_fee ?? 0);
  const productTitle = product.product.name;

  const capitalizeFirstLetter = title => {
    return title.charAt(0).toUpperCase() + title.slice(1);
  };

  return (
    <Grid container>
      <Grid item xs={8} align="left">
        <Typography variant="body3RegularInter" className={classes.productLabel}>
          {capitalizeFirstLetter(productTitle)}
        </Typography>
        {showRushItem && (
          <Typography variant="body3RegularInter" className={classes.rushLabel}>
            Rush Production
          </Typography>
        )}
      </Grid>
      <Grid item xs={4} align="right">
        <Typography variant="body3RegularInter" className={classes.summaryLabel}>
          {moneyStr(subtotal)}
        </Typography>
        {showRushItem && (
          <Typography variant="body3RegularInter" className={classes.summaryLabel}>
            {moneyStr(rushFee)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const OrderSummaryItem = ({ label, value, isSubtotal, previous, isTotal, isFunds, classes }) => {
  const { currentMembership } = useMembership();
  const isFreeTier = currentMembership?.isFreeTier;

  return (
    <Grid container className={clsx({ [classes.totalItem]: isTotal }, { [classes.subTotalItem]: isSubtotal })}>
      <Grid item xs align="left">
        <Typography
          variant={isTotal ? 'h5SemiBoldInter' : 'body3RegularInter'}
          className={clsx(classes.summaryLabel, { [classes.totalLabel]: isTotal }, { [classes.newFunds]: isFunds })}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item align="right">
        <Typography
          variant={isTotal ? 'h5SemiBoldInter' : 'body3RegularInter'}
          className={clsx(classes.summaryLabel, { [classes.totalValue]: isTotal }, { [classes.newFunds]: isFunds })}
        >
          {isFreeTier
            ? null
            : value !== previous && (
                <StrikeOutText value={previous} fontSize={isTotal ? 16 : 12} color="#898C91" marginRight={8} />
              )}
          {moneyStr(value)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TransferIcon = ({ type }) =>
  type === 'ACH' ? (
    <AccountBalance style={{ width: 24, height: 16, marginBottom: -2, color: 'rgb(74, 80, 92)' }} />
  ) : (
    <Receipt style={{ width: 24, height: 16, marginBottom: -2, color: 'rgb(74, 80, 92)' }} />
  );

const PaymentItem = ({
  text,
  image = '/images/orders/swag_credit_card.png',
  noSrc,
  value,
  color = '#45AF5F',
  theme = '#9846DD',
  size = 28
}) => (
  <Grid container alignItems="center">
    <Grid item>
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography variant="body3RegularInter" style={{ color: theme }}>
            {text}
          </Typography>
        </Grid>
        <Grid item>
          <Box style={{ marginLeft: 12, width: size, height: size, position: 'relative' }}>
            {['ACH', 'WireTransfer'].includes(image) ? (
              <TransferIcon type={image} />
            ) : (
              <img
                srcSet={noSrc ? undefined : imageSrcSet(image)}
                src={noSrc ? image : undefined}
                alt="credit-card"
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs>
      <Typography variant="body3RegularInter" style={{ color, textAlign: 'right' }}>
        {moneyStr(value || 0)}
      </Typography>
    </Grid>
  </Grid>
);

const paymentIconImg = paymentType => {
  switch (paymentType) {
    case 'ACH':
      return 'ACH';
    case 'Wire Transfer':
      return 'WireTransfer';
    default:
      return '/images/account/new-card.svg';
  }
};

const FinalPaymentItem = ({ invoice }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.paymentWrapper}>
      <Grid className={classes.heading}>
        <Typography variant="body3SemiBoldInter" style={{ color: '#787B80' }}>
          Payment Method
        </Typography>
      </Grid>
      <Grid alignItems="center" className={classes.swagCard}>
        <PaymentItem text="Payment with Swag Card" value={invoice.credits_to_use} color="#45AF5F" />
      </Grid>
      <Grid alignItems="center" className={classes.swagCard} style={{ borderBottom: '1px solid #E5E7E8' }}>
        <PaymentItem
          text={`Payment with ${invoice.payment_method || 'card'}`}
          image={paymentIconImg(invoice.payment_method)}
          value={Number(invoice.total_plus_tax) - Number(invoice.credits_to_use)}
          noSrc
          color="#4A505C"
          theme="#787B80"
          size={20}
        />
      </Grid>
      {/* <Grid alignItems="center" className={classes.swagCard} style={{ padding: '15px 0px 5px 0px' }}>
        <PaymentItem text="New Swag Card Balance" value={finalBalance} color="#787B80" />
      </Grid> */}
    </Grid>
  );
};

const getShipmentCost = invoice => {
  const diors = invoice.directory_orders || [];
  const cost = diors.reduce((sum, dior) => sum + Number(dior.total_shipping), 0);
  const costBeforeDiscount = diors.reduce((sum, dior) => sum + Number(dior.total_shipping), 0);

  return [cost, costBeforeDiscount];
};

const InvoiceReviewSummary = ({ invoice, finalBalance }) => {
  const classes = useStyles();
  const products = invoice?.products ?? [];
  const subtotal = Number(invoice.amount);

  const amountWithoutDiscount = invoice.products?.reduce(
    (sum, p) =>
      round(
        sum +
          (isPack(p.record_type)
            ? p.items.reduce((acc, item) => acc + (Number(item.price_without_discount) || Number(item.price)), 0)
            : Number(p.price_without_discount) || Number(p.price)) *
            (sumByQuantity(p.quantities_per_size) || p.quantity),
        2
      ),
    0
  );
  const subtotalBeforeDiscount = Number(amountWithoutDiscount);

  const [shipmentCostCalc, shipmentCostBeforeDiscountCalc] = getShipmentCost(invoice);

  const extraShippingCredits = Number(invoice.shipping_credits) > Number(shipmentCostCalc);
  const shipmentCost = extraShippingCredits ? Number(invoice.shipping_credits) : Number(shipmentCostCalc);
  const shipmentCostBeforeDiscount = extraShippingCredits
    ? Number(invoice.shipping_credits)
    : Number(shipmentCostBeforeDiscountCalc);

  const warehouse = Number(invoice.total_fulfillment);
  const warehouseBeforeDiscount = Number(invoice.total_fulfillment_without_discount);
  const estimatedTax = Number(invoice.tax);
  const rushFee = sumBy(
    products,
    product => Number(+product.rush_fee || 0) * (sumByQuantity(product.quantities_per_size) || product.quantity)
  );
  const rushFeeBeforeDiscount = sumBy(
    products,
    product =>
      Number(+product.rush_fee_without_discount || 0) * (sumByQuantity(product.quantities_per_size) || product.quantity)
  );
  // const total = subtotal + rushFee + shipping + warehouse + estimatedTax;
  // const AddNewSwagFunds = invoice.funds || Number(invoice?.inputFunds || 0);
  const total = Number(invoice.total_plus_tax);

  const totalBeforeDiscount =
    subtotalBeforeDiscount +
    rushFeeBeforeDiscount +
    shipmentCostBeforeDiscount +
    warehouseBeforeDiscount +
    estimatedTax;

  const summaryRows = [
    { key: 1, label: 'Subtotal', value: subtotal, beforeDiscountValue: subtotalBeforeDiscount },
    {
      key: 2,
      label: 'Rush Production',
      value: rushFee,
      beforeDiscountValue: rushFeeBeforeDiscount
    },
    {
      key: 3,
      label: 'Funds for Future Shipments',
      value: shipmentCost,
      beforeDiscountValue: shipmentCostBeforeDiscount
    },
    { key: 4, label: 'Storage', value: warehouse, beforeDiscountValue: warehouseBeforeDiscount },
    { key: 5, label: 'Estimated Tax', value: estimatedTax },
    // {
    //   key: 6,
    //   label: 'Funds added to Swag Card',
    //   value: invoice.inputFunds || invoice.funds
    // },
    {
      key: 6,
      label: 'Total',
      value: total,
      beforeDiscountValue: totalBeforeDiscount || 0
    }
  ];

  const appliedDiscount = totalBeforeDiscount > 0 ? totalBeforeDiscount - total : 0;
  const appliedReward = Math.ceil(
    (Number(invoice.total) - Number(invoice.credits_to_use)) * invoice.reward_points_rate
  );

  return (
    <Grid container className={classes.orderSummaryContainer}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="h6SemiBoldInter">Order Summary</Typography>
          </Grid>
          <Grid item>
            <a target="_blank" rel="noopener noreferrer" href={invoice.invoice_pdf_url} download>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                  <Typography variant="body4RegularInter" style={{ color: '#3577D4' }}>
                    Download invoice
                  </Typography>
                </Grid>
                <Grid item>
                  <DownloadIcon fill="#3577D4" />
                </Grid>
              </Grid>
            </a>
          </Grid>
        </Grid>

        <Typography variant="body2MediumInter" style={{ marginTop: 12 }}>
          Products
        </Typography>
      </Grid>
      {products.map(product => (
        <ProductSummaryItem key={product.id} product={product} classes={classes} />
      ))}
      {summaryRows.map(row =>
        row.key === 6 ? (
          <>
            <AppliedMembershipPanelStatic
              appliedDiscount={appliedDiscount}
              appliedReward={appliedReward}
              fixedRate={invoice.reward_points_rate}
              fullWidth
              style={{ marginTop: 24, width: '100%', paddingTop: 24, borderTop: '1px solid #E5E7E8' }}
            />
            <OrderSummaryItem
              key={row.key}
              label={row.label}
              value={row.value}
              previous={row.beforeDiscountValue}
              isSubtotal={row.label === 'Subtotal'}
              isTotal={row.label === 'Total'}
              classes={classes}
            />
          </>
        ) : (
          <OrderSummaryItem
            key={row.key}
            label={row.label}
            value={row.value}
            previous={row.beforeDiscountValue}
            isSubtotal={row.label === 'Subtotal'}
            isTotal={row.label === 'Total'}
            classes={classes}
            isFunds={row.key === 6}
          />
        )
      )}
      <FinalPaymentItem invoice={invoice} finalBalance={finalBalance} />
    </Grid>
  );
};

export default InvoiceReviewSummary;
