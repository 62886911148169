import React, { useState } from 'react';
import '../../styles/Preset.css';
import { Grid, withStyles } from '@material-ui/core';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@material-ui/icons';
import styles from './styles/categoryItems';
import { Img } from '../global/ImgUtils';
import handleImg from '../../helpers/handleImgHelper';
import { StylessButton } from '../buttons';

const packPreviewId = 'pack-preview';

const ThumbnailImage = ({ item, classes, setMainImage }) => {
  const src = item.id === packPreviewId ? item.img : handleImg(item.img, 233, 257);

  return (
    <StylessButton onClick={() => setMainImage(item.img)}>
      <Grid className={classes.containerImage}>
        <Img src={src} alt={`thumbnail_${item.id}`} className={classes.thumbnailImage} />
      </Grid>
    </StylessButton>
  );
};

const Carousel = ({ classes, items, setMainImage }) => (
  <div className={classes.containerScrollMenu}>
    <div className="no-outline">
      <ScrollMenu
        data={items.map(item => (
          <ThumbnailImage key={item.item.id} item={item.item} classes={classes} setMainImage={setMainImage} />
        ))}
        arrowLeft={<KeyboardArrowLeftRounded className={classes.leftArrow} />}
        arrowRight={<KeyboardArrowRightRounded className={classes.rightArrow} />}
        alignCenter
        alignOnResize
        hideArrows
        wheel={false}
        itemsCount={items.length}
        selected={items[0].item.id}
        scrollBy={1}
        translate={15}
        itemClassActive={classes.selectedImage}
      />
    </div>
  </div>
);

const CategoryItems = ({ classes, selectedPack }) => {
  const [mainImage, setMainImage] = useState(selectedPack.img);

  if (!selectedPack.items || selectedPack.items.length === 0) return <div />;

  const previewItemMock = { item: { id: packPreviewId, img: selectedPack.img } };
  const packItems = [previewItemMock, ...selectedPack.items];

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12} sm={12} xs={12} container justifyContent="center">
          <div className={classes.mainImgContainer}>
            <Img src={handleImg(mainImage, 690, 690)} alt={selectedPack.name} className={classes.fullWidth} />
          </div>
        </Grid>
        <Grid item md={12} sm={12} xs={12} style={{ padding: 22 }}>
          <Carousel setMainImage={setMainImage} classes={classes} items={packItems} />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(CategoryItems);
