import React, { useReducer, useState } from 'react';
import { makeStyles, Grid, Typography, Modal, IconButton } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import Scrollbars from 'react-scrollbars-custom';
import { useHistory } from 'react-router-dom';
import { StylessButton } from '../buttons';
import InventoryPackItem from './InventoryPackItem';
import {
  ProductImage,
  productImageTitle,
  ProductNameInput,
  ProductColor,
  ProductQuantities,
  useReorderLink
} from './InventoryCommon';
import { isPack } from '../../helpers/utils';
import { ArrowIcon } from '../icons/inventory';
import IntegrationPanel from '../integrationsDev/IntegrationPanel';
import { useMembership } from '../../contexts/membershipContext';
import ArrowTooltip from '../pages/orders/requested/common/Tooltip';
import { useCompany } from '../../hooks';

const useStyles = makeStyles(theme => ({
  restock: {
    fontFamily: 'Gilroy-Medium',
    padding: 0,
    margin: 0,
    lineHeight: 'normal',
    color: '#3577d4',
    fontSize: 12
  },
  dataContainer: {
    border: 'solid 1px #ebeef2',
    borderRadius: 15,
    backgroundColor: '#ffffff',
    marginBottom: 20,
    position: 'relative'
  },
  productContainer: {
    [theme.breakpoints.down('xs')]: { justifyContent: 'center' }
  },
  imgColumn: {
    width: 'auto',
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 20,
    marginRight: 15,
    borderRadius: 10,
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: { padding: 0 }
  },
  packColumn: {
    paddingLeft: 24,
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  bulkColumn: {
    paddingLeft: 24,
    marginTop: 40
  },
  quantitiesColumn: {
    paddingLeft: 24,
    marginTop: 28,
    marginBottom: 44,
    [theme.breakpoints.down('sm')]: { padding: '13px 0px 20px 24px' },
    [theme.breakpoints.down('xs')]: { margin: 'auto' }
  },
  sendSwagContainer: {
    position: 'relative',
    paddingRight: 30,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0 !important',
      paddingBottom: 20,
      alignItems: 'center'
    }
  },
  integrationPanelContainer: {
    position: 'absolute',
    paddingTop: 8,
    paddingRight: 30,
    top: 0,
    right: 0
  },
  sendSwagBtn: {
    width: 150
  },
  inventoryModalDetail: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: '95%',
    borderRadius: 10,
    maxHeight: '90% !important',
    overflowY: 'hidden'
  }
}));

const CallPackDetails = ({ classes, showDetails, onToggleShow }) => (
  <Grid item xs={12}>
    <Grid container>
      <Grid item xs={12} style={{ cursor: 'pointer' }} role="button" onClick={onToggleShow}>
        <Grid container spacing={2}>
          <Grid item id="left">
            <StylessButton height="100%">
              <Typography className={classes.restock}>{showDetails ? 'Collapse' : 'See details'}</Typography>
            </StylessButton>
          </Grid>
          <Grid item id="right">
            <StylessButton>
              <ArrowIcon open={showDetails} />
            </StylessButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const PackDetails = ({ item }) => (
  <Grid item xs={12}>
    <Grid container>
      <Grid container item xs={12} style={{ padding: '9px 0px 17px 47px' }}>
        {item.items.map(i => (
          <InventoryPackItem key={i.product.id} item={i} />
        ))}
      </Grid>
    </Grid>
  </Grid>
);

const dueDateStatuses = {
  normal: 'normal',
  warning: 'warning',
  alert: 'alert'
};

const InventoryTimerIcon = ({ onClick, dueStatus }) => {
  const {
    currentMembership: { storage_discount: storageDiscount }
  } = useMembership();
  const FreeInventoryStorageFee = storageDiscount === Number(100);

  return (
    <IconButton onClick={onClick} style={{ height: 24, width: 24 }}>
      <AccessTimeIcon
        style={{
          height: 20,
          width: 20,
          color: dueStatus === dueDateStatuses.normal || FreeInventoryStorageFee ? '#125CFF' : '#C62828'
        }}
      />
    </IconButton>
  );
};

const InventoryListItem = ({ item, clearInventoryHistory }) => {
  const [showDetails, toggleShowDetails] = useReducer(prev => !prev, false);
  const [inventoryOpen, setInventoryOpen] = useState(false);

  const { storageFeeInventory } = useFlags();
  const history = useHistory();

  const {
    currentMembership: { storage_discount: storageDiscount }
  } = useMembership();
  const FreeInventoryStorageFee = storageDiscount === Number(100);

  const handleIconClick = () => {
    clearInventoryHistory();
    const pathname = `/inventory/inventory-histories/${item?.id}`;
    history.push({
      pathname,
      state: { item }
    });
  };

  const handleClose = () => {
    setInventoryOpen(false);
  };

  const classes = useStyles();

  const reorderLink = useReorderLink([item.id]);

  const currnetDate = dayjs().format('MM/DD/YYYY');
  const nextPaymentDate = dayjs(item?.nextPaymentDate, 'MM/DD/YYYY');
  const disableInventoryCTA =
    storageFeeInventory &&
    item.inventoryDueStatus !== dueDateStatuses.normal &&
    nextPaymentDate.diff(currnetDate, 'day') < 0;

  const { data: company } = useCompany();
  const negativeBalance = Number(company.balance) < 0;
  const allowNegativeBalance = company?.allow_negative_credit_balance;
  const noSendSwag = negativeBalance && !allowNegativeBalance;

  return (
    <Grid container className={classes.dataContainer}>
      <Grid container item xs={12} sm={8} md={8} lg={10}>
        <Grid container item xs={12} sm={12} lg={6} className={classes.productContainer}>
          <Grid container justifyContent="center" alignItems="center" item className={classes.imgColumn}>
            <ProductImage
              linkTo={reorderLink}
              item={item}
              name={item.name}
              imgSrc={item.image}
              title={productImageTitle(item.enabled)}
              width={102}
              height={102}
            />
          </Grid>
          <Grid item xs={12} sm={8} className={isPack(item.record_type) ? classes.packColumn : classes.bulkColumn}>
            <Grid container>
              <Grid item xs>
                <ProductNameInput item={item} />
              </Grid>
              <Grid item>
                {storageFeeInventory && item?.history.length !== 0 && (
                  <InventoryTimerIcon onClick={handleIconClick} dueStatus={item.inventoryDueStatus} />
                )}
              </Grid>
            </Grid>
            <Grid style={{ marginTop: 12, marginLeft: 2 }}>
              <ProductColor item={item} />
            </Grid>
            {isPack(item.record_type) && (
              <Grid style={{ marginTop: 16 }}>
                <CallPackDetails
                  item={item}
                  classes={classes}
                  showDetails={showDetails}
                  onToggleShow={toggleShowDetails}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6} className={classes.quantitiesColumn}>
          <ProductQuantities item={item} list />
        </Grid>
      </Grid>
      <Grid container item className={classes.sendSwagContainer} xs>
        <div className={classes.integrationPanelContainer}>
          <IntegrationPanel originalProduct={item} />
        </div>
        <ArrowTooltip
          variant="alert"
          title={
            (!FreeInventoryStorageFee || FreeInventoryStorageFee) &&
            (disableInventoryCTA || noSendSwag) &&
            'Your Swag Card Balance has a Negative Balance. Please add funds to your Swag Card Balance to resume shipments'
          }
          style={{ width: '100%' }}
          placement="top-center"
          arrow
        >
          <Button
            size="small"
            component={Link}
            disabled={
              item.stock.reduce((acc, size) => acc + size.quantity, 0) === 0 ||
              noSendSwag ||
              (!FreeInventoryStorageFee && disableInventoryCTA)
            }
            to={`/send-swag/select-recipients?product=${item.id}`}
            className={classes.sendSwagBtn}
          >
            Send Swag
          </Button>
        </ArrowTooltip>
      </Grid>
      {isPack(item.record_type) && showDetails && <PackDetails item={item} />}
    </Grid>
  );
};

export default InventoryListItem;
