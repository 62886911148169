import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Table, TableBody, TableContainer, Snackbar } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, Typography } from '@swagup-com/components';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import {
  SaveAlt,
  BlockOutlined,
  Block,
  Replay,
  ReplayOutlined,
  CancelRounded as CancelIcon,
  CheckCircle as CheckIcon
} from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import min from 'lodash/min';

import ShipmentAlert from '@material-ui/lab/Alert';
import { Alert, CenteredGrid, Helmet, Pagination } from '../shared';
import styles from './styles/redeemPagesHome';
import {
  RedemptionRow,
  TableEmptyState,
  RedeemPageShareModal,
  getStatus,
  PageRedemptionsBlock,
  PageInventoryBlock,
  AvailableCreditBlock,
  ShareStatusBlock,
  MIN_CREDIT_BALANCE,
  TableHeaderCollection,
  TableHeaderAutoShip,
  RedemptionDeleteModal
} from './redeemCommon';
import { useCreditSummary, usePaginatedQuery, useQueryParams } from '../../hooks';
import Loader from '../global/Loader';
import { redeemPages, redemptions } from '../../apis/redeemServices';
import redeemServicesPaths from '../../helpers/redeemServicesPaths';
import { downloadData, moneyStr, paginationRequestConverter } from '../../helpers/utils';
import { StylessButton } from '../buttons';
import seoTags from '../../apis/seoTags';
import RedeemFilterSection from './RedeemFilterSection';
import ProductSelectedDetails from './ProductSelectedDetails';
import { useQueryFilterValidated } from '../../hooks/useFilters';
import { sizes } from '../../__factory__';
import usePageProductBasedInfo from './hooks/usePageProductBasedInfo';
import CreditDrawer from './components/CreditCardDrawer/CreditDrawer';
import ActionBar from './components/ActionBar/ActionBar';
import { CustomTooltip } from '../products/commonProductsElements';
import apiPaths from '../../helpers/apiPaths';
import { shipmentsApi } from '../../apis/swagup';
import SpinningSnackBar from './components/SpinningSnackBar/SpinningSnackBar';
import ShipmetDetailsModal from './ShipmetDetailsModal';

const useStyles = makeStyles(styles);

const perPageOptions = [10, 20, 30, 40];

const sortSizes = summarySizes =>
  summarySizes
    .map(s => {
      const defaulSize = sizes.results.find(si => si.name === s.size);
      return { ...s, category: defaulSize?.category, idx: defaulSize?.name === 'XXS' ? 0 : defaulSize?.id };
    })
    .sort((sA, sB) => (sA.category > sB.category || sA.idx > sB.idx ? 1 : -1));

const sortSummarySizes = summary => ({
  ...summary,
  selectedProducts: summary.selectedProducts.map(sp => ({ ...sp, sizes: sortSizes(sp.sizes) })),
  totalSizes: sortSizes(summary.totalSizes)
});

const shipmentAPIStatuses = [
  'Scheduled',
  'On Its Way',
  'Delivered',
  'Failure',
  'Return To Sender',
  'Invalid Address',
  'Cancelled',
  'Pending Production',
  'Out Of Stock',
  'Insufficient Credit',
  'Unexpected Error'
];
const getCustomFilter = summary =>
  summary
    ? [
        {
          name: 'item',
          label: 'Product Selected',
          behavior: 'multiselect',
          options: summary.selectedProducts.reduce((sum, p) => ({ ...sum, [p.productId]: p.name }), {})
        },
        {
          name: 'size',
          label: 'Size',
          behavior: 'multiselect',
          options: summary.totalSizes.reduce((sum, s) => ({ ...sum, [s.size]: s.size }), {})
        },
        {
          name: 'shipment_status',
          label: 'Shipment Status',
          behavior: 'multiselect',
          options: shipmentAPIStatuses.reduce((sum, s) => ({ ...sum, [s]: s }), {})
        }
      ].filter(f => !isEmpty(f.options))
    : undefined;

const RedeemPageHistory = () => {
  const [page, setPage] = useState({});
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [openProductDrawer, setOpenProductDrawer] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(true);
  const [openCreditDrawer, setOpenCreditDrawer] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [shipmentDetailsId, setShipmentDetailsId] = useState(0);
  const [redemptionToDelete, setRedemptionToDelete] = useState();
  const [wasSuccessful, setWasSuccessful] = useState({ was: false, what: 'scheduled' });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [multipleRetries, setMultipleRetries] = useState(0);
  const [selectedShipmentStatus, setSelectedShipmentStatus] = useState([]);

  const classes = useStyles();

  const { id } = useParams();

  const history = useHistory();
  const location = useLocation();
  const { infoMessage } = location.state || {};

  const company = useSelector(state => state.company);

  const { data: redeemPageQuery, isLoading } = useQuery(redeemServicesPaths.redeemPage(id), () => redeemPages.get(id), {
    enabled: !!id
  });

  const { data: summaryCall, isLoadingSummary } = useQuery(
    redeemServicesPaths.summary(page?.id),
    () => redeemPages.summary(page?.id),
    {
      enabled: !!page?.id
    }
  );
  const summaryData = summaryCall?.data;
  const summary = summaryData ? sortSummarySizes(summaryData) : summaryData;
  const customFilters = getCustomFilter(summary);
  useEffect(() => {
    if (redeemPageQuery?.id) setPage(redeemPageQuery);
  }, [redeemPageQuery]);

  const query = useQueryParams();

  const search = query.get('search') || '';
  const isSearching = !!search;
  const item = useQueryFilterValidated('item');
  const size = useQueryFilterValidated('size', val =>
    ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', 'One Size', 'XXS'].includes(val)
  );
  const shipmentStatus = useQueryFilterValidated('shipment_status', val => shipmentAPIStatuses.includes(val));

  const ordering = useQueryFilterValidated(
    'ordering',
    (xid, value) => ['-created_at', 'created_at'].includes(xid) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const {
    query: { data: redemptionsResults, isFetching: loadingHistory },
    pagination
  } = usePaginatedQuery({
    queryKey: [redeemServicesPaths.redemptions(page.id), search, item, size, ordering, shipmentStatus],
    queryFn: (limit, offset) => {
      return page.id
        ? redemptions.list(
            page.id,
            paginationRequestConverter({ limit, offset, search, item, size, ordering, shipmentStatus })
          )
        : [];
    },
    perPageOptions
  });

  const redemptionItems = redemptionsResults?.results || [];
  const queryClient = useQueryClient();

  const contactExport = useMutation(() => redeemPages.export(page.id, { search, item, size, shipmentStatus }), {
    onSuccess: ({ data }) => {
      downloadData(`${page.projectName}.csv`, data);
    }
  });

  const activateRedeem = useMutation(isActive => redeemPages.update(page.id, { isActive }), {
    onSuccess: () => {
      queryClient.invalidateQueries([redeemServicesPaths.redeemPages, company.id]);
      setPage(prevPage => ({ ...prevPage, isActive: !prevPage.isActive }));
    }
  });
  const { data: creditSummary } = useCreditSummary();
  const { selectedProducts, recommendedCredits } = usePageProductBasedInfo(page);
  const exportContact = () => contactExport.mutate();
  const showPagination = pagination?.count > min(pagination?.sizeOptions);

  const handleClose = () => {
    setShowInfoMessage(false);
    history.replace({ ...location, state: { ...location.state, infoMessage: undefined } });
  };

  const toogleSelectedItems = sitem =>
    setSelectedItems(prev =>
      prev.find(it => it.contactId === sitem.contactId)
        ? prev.filter(it => it.contactId !== sitem.contactId)
        : [...prev, sitem]
    );

  const retryRedeemShipment = useMutation(
    redemptionIdList => redemptions.retry(page.id, { redemptions: redemptionIdList }),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(apiPaths.creditsSummaries);
        queryClient.invalidateQueries([redeemServicesPaths.redemptions(page.id)]);
        setSelectedItems([]);
        setShowSnackbar(true);
        if (res?.failedRedemptions?.length > 0) {
          setSelectedShipmentStatus(res.failedRedemptions);
          setWasSuccessful({ was: false, what: 'scheduled' });
        } else {
          setWasSuccessful({ was: true, what: 'scheduled' });
        }
      }
    }
  );

  const deleteRedeemShipment = useMutation(
    redemptionIdList => redemptions.delete(page.id, { redemptions: redemptionIdList }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([redeemServicesPaths.redemptions(page.id)]);
        setSelectedItems([]);
      }
    }
  );

  const cancelAPIShipments = useMutation(
    redemptionIdList => redemptions.cancel(page.id, { redemptions: redemptionIdList }),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(apiPaths.creditsSummaries);
        queryClient.invalidateQueries([redeemServicesPaths.redemptions(page.id)]);
        setSelectedItems([]);
        setShowSnackbar(true);
        if (res?.failedRedemptions?.length > 0) {
          setSelectedShipmentStatus(res.failedRedemptions);
          setWasSuccessful({ was: false, what: 'canceled' });
        } else {
          setWasSuccessful({ was: true, what: 'canceled' });
        }
      }
    }
  );

  const retryShipments = redemptionIdList => {
    setMultipleRetries(redemptionIdList.length);
    retryRedeemShipment.mutate(redemptionIdList);
  };

  const deleteRedemptions = redemptionIdList => {
    deleteRedeemShipment.mutate(redemptionIdList);
  };

  const cancelShipments = shipmentList => {
    cancelAPIShipments.mutate(shipmentList);
  };

  const showInventoryDrawer = () => {
    setShowSnackbar(false);
    setOpenProductDrawer(true);
  };

  const closeErrorMessageSnackbar = () => {
    setSelectedShipmentStatus([]);
    setShowSnackbar(false);
  };

  const showCreditReloadDrawer = () => {
    setShowSnackbar(false);
    setOpenCreditDrawer(true);
  };

  const redirectHelpCenter = () => {
    setShowSnackbar(false);
    window.open('https://support.swagup.com/en', '_blank');
  };

  const retryShipment = r => {
    if (r) return retryShipments([r.id]);
    const redemptionIdList = selectedItems.map(s => s.id);
    return retryShipments(redemptionIdList);
  };

  const onDeleteRedemption = r => {
    setRedemptionToDelete(r);
  };

  const onDeleteRedemptionClose = () => {
    setRedemptionToDelete();
  };

  const deleteRedemption = r => {
    onDeleteRedemptionClose();
    if (r) return deleteRedemptions([r.id]);
    const redemptionIdList = selectedItems.map(s => s.id);
    return deleteRedemptions(redemptionIdList);
  };

  const cancelShipment = r => {
    if (r) return cancelShipments([r.id]);
    const redemptionIdList = selectedItems.map(s => s.id);
    return cancelShipments(redemptionIdList);
  };

  useEffect(() => {
    queryClient.invalidateQueries(apiPaths.creditsSummaries);
    queryClient.invalidateQueries([redeemServicesPaths.redemptions(page.id)]);
  }, [page.id, queryClient]);

  const toogleSelectAll = () =>
    setSelectedItems(prev => (prev.length !== redemptionItems.length ? redemptionItems.map(r => r) : []));

  const getProcessingMessage = () => {
    switch (true) {
      case cancelAPIShipments.isLoading:
        return `Canceling your shipment${selectedItems.length > 0 ? 's' : ''}`;
      case retryRedeemShipment.isLoading:
        return multipleRetries === 1 ? 'Scheduling your shipment' : `Scheduling ${multipleRetries} shipments`;
      case deleteRedeemShipment.isLoading:
        return 'Deleting...';
      case isSearching:
        return 'Searching...';
      default:
        return 'Loading...';
    }
  };

  const getFailedRedemptionMessage = () => {
    switch (selectedShipmentStatus.length && selectedShipmentStatus[0].failedRedemptionStatus) {
      case 'Unexpected Error':
        return (
          <>
            Shipment failed. Contact{' '}
            <button type="button" onClick={() => redirectHelpCenter()} className={classes.drawerLink}>
              customer support
            </button>
            .
          </>
        );
      case 'Out Of Stock':
        return (
          <>
            Hmm, looks like you’re missing some{' '}
            <button type="button" onClick={() => showInventoryDrawer()} className={classes.drawerLink}>
              inventory
            </button>
            .
          </>
        );
      case 'Insufficient Credit':
        return (
          <>
            Oops, looks like you’re low on{' '}
            <button type="button" onClick={() => showCreditReloadDrawer()} className={classes.drawerLink}>
              credit
            </button>
            .
          </>
        );
      default:
        return null;
    }
  };

  const regularMessaging = () =>
    multipleRetries === 1 ? getFailedRedemptionMessage() : 'Please address the following';

  return (
    <>
      <Helmet tags={seoTags.redeem} />
      <CenteredGrid>
        <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 24, marginTop: 18 }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item>
                    <Link to="/redeem-pages">
                      <Typography variant="body4RegularInter" className={classes.goBack}>
                        <ArrowBackIcon className={classes.goBackIcon} />
                        Back to Redeem Pages
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs style={{ paddingLeft: 4 }}>
                    / {page.projectName}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                {infoMessage && showInfoMessage && (
                  <Grid container justifyContent="center" style={{ position: 'relative' }}>
                    <Alert
                      onClose={handleClose}
                      delayTime={10000}
                      className={classes.floatingInfoMessage}
                      fontStyles={{ fontSize: 12, padding: 0 }}
                    >
                      {infoMessage}
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Grid>

          <Grid item xs>
            <Typography variant="h2BoldInter" style={{ minWidth: 360 }}>
              {page.projectName}
            </Typography>
          </Grid>
          <Grid item style={{ paddingRight: 16 }}>
            <Button
              variant="text"
              component={Link}
              to={`/redeem-details/${page.urlSlug}`}
              style={{ minWidth: 110, height: 56 }}
            >
              Edit Page
            </Button>
          </Grid>
          <Grid item>
            <CustomTooltip
              title="A min. credit balance of $100 is required to activate an Autoship redeem page"
              arrow
              placement="top-start"
              disableHoverListener={
                page.collectionOnly || page.isActive || creditSummary.current_balance >= MIN_CREDIT_BALANCE
              }
            >
              <Button
                variant="primary"
                onClick={() => setShareModalOpen(true)}
                style={{ minWidth: 110, height: 56 }}
                disabled={!page.collectionOnly && !page.isActive && creditSummary.current_balance < MIN_CREDIT_BALANCE}
              >
                Share
              </Button>
            </CustomTooltip>
          </Grid>
        </Grid>
      </CenteredGrid>
      {!page.collectionOnly && (
        <div style={{ borderTop: '1px solid #E5E7E8', padding: '12px 0px' }}>
          <CenteredGrid>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <Grid container spacing={6}>
                  <Grid item xs={4}>
                    <PageRedemptionsBlock totalRedemptions={redemptionsResults?.count} classes={classes} />
                  </Grid>
                  <Grid item xs={4}>
                    <PageInventoryBlock
                      totalProducts={page.productOptions?.length}
                      onViewDetails={() => setOpenProductDrawer(true)}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AvailableCreditBlock
                      creditBalance={creditSummary.current_balance}
                      onReload={() => setOpenCreditDrawer(true)}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} style={{ paddingLeft: '18px' }}>
                <ShareStatusBlock isActive={page.isActive} classes={classes} />
              </Grid>
            </Grid>
          </CenteredGrid>
        </div>
      )}
      <div style={{ borderTop: '1px solid #E5E7E8', borderBottom: '1px solid #E5E7E8', padding: '4px 0px' }}>
        <Grid container item xs alignItems="center">
          <Grid item xs>
            <RedeemFilterSection
              searchPlaceholder="Search Redemptions"
              filters={customFilters?.filter(f =>
                page.collectionOnly ? f.name !== 'shipment_status' : f.name !== 'size'
              )}
              RigthSideComponent={() => (
                <StylessButton
                  className={classes.exportButtom}
                  disabled={redemptionItems?.length === 0}
                  onClick={exportContact}
                >
                  <Grid container alignItems="center" style={{ minWidth: 90 }}>
                    <Grid item style={{ paddingRight: 4, paddingTop: 0 }}>
                      <SaveAlt style={{ height: 18, width: 18 }} />
                    </Grid>
                    <Grid item xs>
                      Export CSV
                    </Grid>
                  </Grid>
                </StylessButton>
              )}
            />
          </Grid>
        </Grid>
      </div>
      <Snackbar
        className={classes.snackbarContainer}
        open={showSnackbar}
        autoHideDuration={wasSuccessful.was ? 3000 : null}
        onClose={() => {
          setShowSnackbar(false);
          if (!wasSuccessful.was) closeErrorMessageSnackbar();
        }}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        {wasSuccessful.was ? (
          <ShipmentAlert
            className={classes.successShipmentAlert}
            icon={<CheckIcon style={{ color: '#2E7D32', fontSize: '20px' }} />}
            variant="outlined"
            severity="success"
          >
            Shipment successfully {wasSuccessful.what}
          </ShipmentAlert>
        ) : (
          <ShipmentAlert
            onClose={() => {
              closeErrorMessageSnackbar();
            }}
            className={classes.failureShipmentAlert}
            icon={<CancelIcon style={{ color: '#C62828', fontSize: '20px' }} />}
            variant="outlined"
            severity="error"
          >
            {wasSuccessful.what === 'canceled'
              ? `Cancelation${multipleRetries > 1 ? '' : 's'} failed`
              : regularMessaging()}
          </ShipmentAlert>
        )}
      </Snackbar>
      <CenteredGrid>
        {redemptionItems?.length > 0 && (
          <Grid container className={classes.historyStatusContainer}>
            <Grid item>
              {page.collectionOnly ? (
                <Typography variant="body3RegularInter" className={classes.historyStatus}>
                  Page status:{' '}
                  <span style={{ color: page.isActive ? '#2E7D32' : '#989EA4' }}>{getStatus(page.isActive)}</span>
                </Typography>
              ) : (
                <Typography variant="body3RegularInter" className={classes.historyStatus}>
                  Total Count: {redemptionsResults?.count}
                </Typography>
              )}
            </Grid>
            <Grid xs item />
            <Grid item>
              {page.collectionOnly ? (
                <StylessButton onClick={() => setOpenProductDrawer(true)}>
                  <Typography
                    variant="body3RegularInter"
                    className={classes.historyStatus}
                    style={{ color: '#3577d4' }}
                  >
                    {redemptionsResults?.count} Recepients Redeemed
                  </Typography>
                </StylessButton>
              ) : (
                <StylessButton onClick={() => setOpenProductDrawer(true)}>
                  <Typography variant="body3RegularInter" className={classes.historyStatus}>
                    Total Shipping Cost: {moneyStr(summary?.totalShipment)}
                  </Typography>
                </StylessButton>
              )}
            </Grid>
          </Grid>
        )}
        <div className={page.collectionOnly ? classes.tableWrapper : classes.tableWrapperSmall}>
          <div className={classes.tableContainer} style={{ height: `calc(100% - ${showPagination ? 42 : 0}px)` }}>
            {redemptionItems?.length === 0 && !loadingHistory ? (
              <TableEmptyState isSearching={isSearching} />
            ) : (
              <TableContainer>
                <Table stickyHeader className={classes.redemptionTable}>
                  {page.collectionOnly ? (
                    <TableHeaderCollection classes={classes} />
                  ) : (
                    <TableHeaderAutoShip
                      classes={classes}
                      toogleSelectAll={toogleSelectAll}
                      allSelected={redemptionItems.length === selectedItems.length}
                    />
                  )}
                  <TableBody>
                    {redemptionItems?.map(redemption => (
                      <RedemptionRow
                        key={redemption.id}
                        redemption={redemption}
                        customAction={() => setOpenProductDrawer(true)}
                        isCollection={page.collectionOnly}
                        toogleSelectedItems={toogleSelectedItems}
                        onOpenShipmentDetails={setShipmentDetailsId}
                        onRetryShipment={retryShipment}
                        onDeleteRedemption={onDeleteRedemption}
                        onCancelShipment={cancelShipment}
                        selectedShipmentStatus={selectedShipmentStatus}
                        isSelected={!!selectedItems.find(s => s.contactId === redemption.contactId)}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          {showPagination && (
            <Grid container alignItems="center" className={classes.paginationContainer}>
              <Pagination {...pagination} startText="Show" endText="contacts" buttonClass={classes.paginationBtn} />
            </Grid>
          )}
        </div>

        <RedeemPageShareModal
          page={page}
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
          onSave={active => activateRedeem.mutate(active)}
          isSaving={activateRedeem.isLoading}
        />
        {summary && (
          <ProductSelectedDetails
            open={openProductDrawer && !isLoadingSummary}
            onClose={() => setOpenProductDrawer(false)}
            summary={summary}
            isCollection={page.collectionOnly}
            selectedProducts={selectedProducts}
          />
        )}
        <CreditDrawer
          open={openCreditDrawer}
          credit={creditSummary.current_balance}
          onClose={() => setOpenCreditDrawer(false)}
          company={company}
          history={history}
          recommendedCredits={recommendedCredits}
        />
        <ActionBar
          open={selectedItems.length > 0}
          totalItemSelected={selectedItems.length}
          handleClose={() => setSelectedItems([])}
          type="shipment"
          actions={[
            {
              text: 'Retry',
              tooltip: 'Only Insufficent Credit and Out Of Stock statuses can be retry for shipment.',
              action: () => retryShipment(),
              disabled: selectedItems.some(r => r.shipment.shipmentId > 0),
              baseIcon: ReplayOutlined,
              hoverIcon: Replay
            },
            {
              text: 'Cancel',
              tooltip: 'You can only cancel shipments in the Scheduled and Pending Production status',
              action: () => cancelShipment(),
              disabled: selectedItems.some(r => !['Pending Production', 'Scheduled'].includes(r.shipment.status)),
              baseIcon: BlockOutlined,
              hoverIcon: Block
            }
          ]}
        />
        <ShipmetDetailsModal shipmentId={shipmentDetailsId} onClose={() => setShipmentDetailsId(0)} />
        <RedemptionDeleteModal
          open={!isUndefined(redemptionToDelete)}
          onDelete={() => deleteRedemption(redemptionToDelete)}
          onClose={onDeleteRedemptionClose}
          redemption={redemptionToDelete}
        />
        <SpinningSnackBar
          open={
            cancelAPIShipments.isLoading ||
            retryRedeemShipment.isLoading ||
            deleteRedeemShipment.isLoading ||
            loadingHistory ||
            isSearching
          }
          message={getProcessingMessage()}
        />
        {isLoading && <Loader absolute />}
      </CenteredGrid>
    </>
  );
};

export default RedeemPageHistory;
