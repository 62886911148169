import isEmpty from 'lodash/isEmpty';
import usStreetApi from '../apis/smartyStreets';
import internationalStreetApi from '../apis/smartyStreetsInternational';
import log from '../logger';

const errors = {
  400: 'Bad Request (Malformed Payload): A GET request lacked a street field, or the request body of a POST request contained malformed JSON. (example: submitting an integer value in the ZIP Code field when a string is expected)',
  401: 'Unauthorized: The credentials were provided incorrectly or did not match any existing, active credentials.',
  402: 'Payment Required: There is no active subscription for the account associated with the credentials submitted with the request.',
  403: 'Forbidden: Because the international service is currently in a limited release phase, only approved accounts may access the service. Please contact us for your account to be granted access.',
  413: 'Request Entity Too Large: The maximum size for a request body to this API is 32K (32,768 bytes).',
  422: 'Unprocessable Entity: A GET request lacked required fields.',
  429: 'Too Many Requests: When using public website key authentication, we restrict the number of requests coming from a given source over too short of a time. If you use website key authentication, you can avoid this error by adding your IP address as an authorized host for the website key in question.',
  504: 'Gateway Timeout: Our own upstream data provider did not respond in a timely fashion and the request failed. A serious, yet rare occurrence indeed.'
};

const appendMessage = ({ result, ...rest }, message, prop = 'message') => ({
  ...rest,
  result,
  [prop]: message
});

const getMsgFromStatus = status =>
  errors[status] || "Smarty didn't provide any info for this error, maybe it's an undocumented status.";

const handleQueryError = status => {
  const errored = { result: 'error' };
  const result = appendMessage(
    errored,
    "Oops, something went wrong with the swaginator 3000. We couldn't verify your address with our system. Please, try again."
  );
  log.error('SmartyStreets query - Exiting ERROR: ', appendMessage(errored, getMsgFromStatus(status), 'error'));
  return result;
};

const queryCreator = api => async params => {
  log.debug('SmartyStreets query - Entering');

  try {
    const { status, data } = await api.get('', { params });
    if (status === 200) {
      const result = { result: 'ok', data };
      log.debug('SmartyStreets query - Exiting OK: ', result);
      return result;
    }
    return handleQueryError(status);
  } catch (e) {
    return handleQueryError(e.response?.status);
  }
};

const verifyUSAddress = async address => {
  log.debug('verifyUSAddress - Entering');
  const query = queryCreator(usStreetApi);
  const verificationResponse = await query(address);

  if (verificationResponse.result === 'error') return verificationResponse;

  const response = verificationResponse.data[0];
  if (!response) return { result: 'error' };

  const {
    analysis: { dpv_match_code: dpvMatchCode }
  } = response;

  log.debug('verifyUSAddress - verification result: ', response);

  if (dpvMatchCode === 'Y') return { result: 'ok', response };

  return { result: 'error' };
};

const rank = {
  DeliveryPoint: 6,
  Premise: 5,
  Thoroughfare: 4,
  Locality: 3,
  AdministrativeArea: 2,
  None: 1
};

const verifyInternationalAddress = async address => {
  log.debug('verifyInternationalAddress - Entering');
  const query = queryCreator(internationalStreetApi);
  const verificationResponse = await query({
    country: address.country,
    address1: address.street,
    address2: address.secondary,
    locality: address.city,
    administrative_area: address.state,
    postal_code: address.zipcode
  });

  if (verificationResponse.result === 'error') return verificationResponse;

  const response = verificationResponse.data[0];
  if (isEmpty(response)) return { result: 'error' };

  const { analysis } = response;

  log.debug('verifyInternationalAddress - verification result: ', response);

  const isAllowedAddress =
    (['Verified', 'Partial'].includes(analysis.verification_status) && rank[analysis.address_precision] >= 5) ||
    (analysis.verification_status === 'Verified' && analysis.address_precision === analysis.max_address_precision);

  if (isAllowedAddress) return { result: 'ok', response };

  return { result: 'error' };
};

export default async function verifyAddress(address) {
  log.debug('verifyAddress - Entering with address: ', address);

  const verification = await (address.country === 'US'
    ? verifyUSAddress(address)
    : verifyInternationalAddress(address));

  if (verification.message) return verification;
  const result = appendMessage(
    verification,
    verification.result === 'ok'
      ? 'Entire address was confirmed deliverable!'
      : 'We were unable to verify the address provided for this contact. Would you still like to proceed with this address? It may result in an unsuccessful delivery of swag to this recipient.'
  );

  log.debug('verifyAddress - exiting with verification: ', result);

  return result;
}
