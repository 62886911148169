import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { Box, Grid, makeStyles, Slider, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { Button } from '@swagup-com/components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isNumber, debounce, round } from 'lodash';
import PropTypes from 'prop-types';
import CategoriesSidebar from './CategoriesSidebar';
import Category from './Category';
import Loader from '../../global/Loader';
import styles from './Catalog.styles';
import { FilterBy, SearchField } from '../../products/commonProductsElements';
import { useQueryParams } from '../../../hooks';
import { moneyStr } from '../../../helpers/utils';
import useCatalogFilter from './useCatalogFilter';

const useStyles = makeStyles(styles);

const useFragment = () => {
  const location = useLocation();
  return location.hash.slice(1);
};

const getDefaultPriceRange = catalog => {
  if (isEmpty(catalog)) return [0, 100];
  const allPrices = catalog.reduce((acc, cat) => [...acc, ...cat.items.map(item => Number(item.price))], []);
  return [Math.min(...allPrices.map(p => Math.floor(p))), Math.max(...allPrices.map(p => Math.ceil(p)))];
};

const valuetext = value => {
  return moneyStr(value);
};

const ValueLabelComponent = props => {
  const { children, index, value } = props;
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          left: index ? '100%' : '0%',
          position: 'absolute'
        }}
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{
              marginLeft: '-50%',
              marginTop: -30,
              // backgroundColor: '#8D9299',
              width: 'fit-content',
              padding: '2px 4px',
              fontFamily: 'Inter',
              fontSize: 10,
              borderRadius: 4,
              boxShadow: 'none',
              color: '#4A4F54'
            }}
          >
            {moneyStr(value)}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

const Catalog = ({ nextStep, isBulk }) => {
  const classes = useStyles();
  const categoryName = useFragment();

  const { items: catalog, loading } = useSelector(state => state.catalog);
  const cartLoading = useSelector(state => state.cart.loading);

  const [selectedCategoryId, setSelectedCategoryId] = useState();

  const history = useHistory();
  const query = useQueryParams();
  const search = query.get('search') || '';
  const selectedCategories = query.get('categories');
  const defaultPriceRange = getDefaultPriceRange(catalog);
  const selectedPriceRange = query.get('price-range') || defaultPriceRange.join();

  const initialCategoryValues = selectedCategories?.split(',').filter(icat => isNumber(Number(icat)));
  const initialPriceRageValues = selectedPriceRange?.split(',').map(range => Number(range));

  const { filterCatalog, filterCatalogItems } = useCatalogFilter(
    initialCategoryValues,
    search,
    selectedPriceRange,
    defaultPriceRange,
    initialPriceRageValues
  );

  const catalogCategories = useMemo(
    () =>
      catalog?.filter(filterCatalog).map(cat => {
        const category = {
          ...cat,
          items: cat.items.filter(filterCatalogItems)
        };
        return category;
      }) || [],
    [catalog, filterCatalog, filterCatalogItems]
  );

  const refs = useMemo(() => catalogCategories?.map(() => React.createRef()), [catalogCategories]);
  const categoryContainerElementRef = useRef(null);

  const handleSelectedCategory = useCallback(
    categoryIdx => {
      if (!catalogCategories?.[categoryIdx]) return;
      const element = refs[categoryIdx].current;
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    [catalogCategories, refs]
  );

  const categories = useMemo(
    () => (
      <Grid container direction="column" alignItems="stretch">
        {catalogCategories.map((category, idx) => {
          return (
            <Grid item key={category.id} className={classes.categoryContainer}>
              <div id={category.CatName} className="category-scroll-padding" ref={refs[idx]} />
              <Category category={category} />
            </Grid>
          );
        })}
      </Grid>
    ),
    [catalogCategories, classes.categoryContainer, refs]
  );

  // useEffect(() => {
  //   if (!isEmpty(catalogCategories)) setSelectedCategoryId(catalogCategories[0].id);
  // }, [catalogCategories]);

  useEffect(() => {
    if (isEmpty(catalogCategories)) return () => {};

    const handleScroll = () => {
      const idx = refs.findIndex(
        ({ current }) => current?.parentNode.offsetTop >= categoryContainerElementRef.current.scrollTop + 178
      );
      setSelectedCategoryId(catalogCategories[(idx >= 0 ? idx : catalogCategories.length) - 1].id);
    };
    if (categoryContainerElementRef.current) {
      categoryContainerElementRef.current.addEventListener('scroll', handleScroll, false);
    }

    return () => {
      if (categoryContainerElementRef.current) {
        categoryContainerElementRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [catalogCategories, refs]);

  useEffect(() => {
    handleSelectedCategory(catalogCategories?.findIndex(c => c.CatName === categoryName));
  }, [catalogCategories, categoryName, handleSelectedCategory]);

  const searchByQueryParam = useCallback(
    value => {
      query.set('search', value);
      history.replace(`${history.location?.pathname}?${query.toString()}`);
    },
    [query, history]
  );

  const handleOnApply = React.useCallback(
    queryName => value => {
      if (!value) {
        query.delete(queryName);
      } else {
        query.set(queryName, value);
      }
      history.replace({ ...history.location, search: query.toString() });
    },
    [history, query]
  );

  const updatePriceRange = React.useCallback(
    (event, newValue) => {
      // scale={x => x * (x / defaultPriceRange[1])}
      const minVal = round(newValue[0] * (newValue[0] / defaultPriceRange[1]), 2);
      const maxVal = round(newValue[1] * (newValue[1] / defaultPriceRange[1]), 2);
      if (minVal === defaultPriceRange[0] && maxVal === defaultPriceRange[1]) {
        query.delete('price-range');
      } else {
        query.set('price-range', [minVal, maxVal].join());
      }
      history.replace({ ...history.location, search: query.toString() });
    },
    [history, defaultPriceRange, query]
  );

  const handlePriceRangeChange = debounce(updatePriceRange, 750);

  const categoryOptions = catalogCategories?.reduce((acc, cat) => ({ ...acc, [cat.id]: cat.CatName }), {});

  if (isEmpty(catalog) || loading) return <Loader />;

  const header = `Start Your ${isBulk ? 'Custom Swag' : 'Swag Pack'}`;

  const subHeader = `Get started on designing your custom swag ${
    isBulk ? '' : 'pack'
  } from our selection of curated picks in the
   ${isBulk ? 'hottest swag' : 'most popular swag'} categories
        today. ${
          isBulk
            ? "With individual items, you can skip the packaging (the 'pack') and just focus on the items you love."
            : ''
        } Choose from quality brands including North Face, Stickermule, Moleskine, and more. Then, add
        your swag project details (no credit card info required) to get your mockups!`;

  return (
    <div className={classes.root}>
      <CategoriesSidebar
        catalog={catalogCategories}
        activeCategoryId={selectedCategoryId}
        onSelectedCategory={handleSelectedCategory}
      />
      <Grid container alignItems="center" className={classes.headerBar}>
        <Grid item sm="auto" xs={12}>
          <Typography className={classes.topCatalogHeader} style={{ marginBottom: 0 }}>
            {header}
          </Typography>
        </Grid>
        <Grid item xs />
        <Grid item>
          <Box className={classes.filterContainer}>
            <Grid container spacing={4} alignItems="center">
              <Grid item>
                <SearchField
                  key="search"
                  placeholder="Search By Name"
                  onChange={searchByQueryParam}
                  defaultValue={search}
                  rounded
                  lean
                  inverseHover
                />
              </Grid>
              <Grid item>
                <FilterBy
                  key={`categories=${selectedCategories}`}
                  label="Category:"
                  options={categoryOptions}
                  initialValues={initialCategoryValues}
                  onApply={handleOnApply('categories')}
                />
              </Grid>
              <Grid item xs />
              <Grid item>
                <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: 16,
                        fontWeight: 400,
                        color: '#4A4F54',
                        lineHeight: '24px'
                      }}
                    >
                      Price range:
                    </Typography>
                  </Grid>
                  <Grid item style={{ minWidth: 124, paddingRight: 16 }}>
                    <Slider
                      // value={initialPriceRageValues}
                      defaultValue={initialPriceRageValues}
                      ValueLabelComponent={ValueLabelComponent}
                      onChange={handlePriceRangeChange}
                      valueLabelDisplay="on"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      min={defaultPriceRange[0]}
                      max={defaultPriceRange[1]}
                      scale={x => {
                        const minPrice = defaultPriceRange[0];
                        const calculatedScale = x * (x / defaultPriceRange[1]);
                        return calculatedScale < minPrice ? minPrice : calculatedScale;
                      }}
                      style={{ margin: '8px 0px 0px 6px' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Typography className={classes.subHeader}> {subHeader}</Typography>
      </Grid>
      <Box ref={categoryContainerElementRef} className={classes.categorySectionsContainer}>
        {categories}
      </Box>
      <div className={classes.nextBtnContainer}>
        {nextStep && (
          <Button
            variant="primary"
            loading={cartLoading}
            disabled={cartLoading}
            component={Link}
            to={nextStep}
            className={classes.nextBtn}
            fullWidth
          >
            Next: Add project details
          </Button>
        )}
      </div>
    </div>
  );
};

export default Catalog;
