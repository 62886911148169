import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography, Grid, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Scrollable from 'react-scrollbars-custom';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { usePaginatedQuery, useQueryParams, useShippingCutoffHour } from '../../../hooks';
import {
  cleanMultishipping,
  addMultishipping,
  addProductToShip,
  saveEmployeeDataToShip,
  addDeliveryMethod,
  getSinglePackDeliveryMethods,
  setAccountProduct
} from '../../../actions';
import accountProductsApi from '../../../apis/swagup/accountProducts';
import { isOneSize, sumByQuantity } from '../../../helpers/utils';
import apiPaths from '../../../helpers/apiPaths';
import Pagination from '../../shared/Pagination';
import Loader from '../../global/Loader';
import { OutlinedSearchField } from '../../global/Filters';
import { Container, Footer } from '../../layouts/FullscreenStepper';
import ErrorAlert from '../../shared/ErrorAlert';
import Product from './Product';
import SizelessContacts from './SizelessContacts';
import styles from './styles/SelectSwag';

const useStyles = makeStyles(styles);

const perPageOptions = [12, 24, 36, 48];
const SelectSwag = ({ contacts, onUpdateContacts, onNextStep, onPreviousStep }) => {
  const [containerRef, setContainerRef] = useState();
  const [containerWidth, setContainerWidth] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState();
  const tempSelectedProductRef = useRef();
  const [quantities, setQuantities] = useState({});
  const [openSizelessModal, setOpenSizelessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();

  const search = query.get('search') || '';

  const accountProductsParams = {
    visible_in_inventory: true,
    search,
    record_type: 'pack',
    inventory: 'all',
    ordering: '-created_at'
  };

  const { query: queryResult, pagination } = usePaginatedQuery({
    queryKey: [apiPaths.accountProducts, accountProductsParams],
    queryFn: (limit, offset) => {
      return accountProductsApi.fetch({ limit, offset, ...accountProductsParams });
    },
    perPageOptions
  });

  const inventory = queryResult.data?.results ?? [];
  const isInventoryPending = queryResult.isFetching;
  const inventoryError = queryResult.isError;
  const { count: total } = pagination;

  const perfectWidth = Math.floor((containerWidth - 80) / 264) * 264 + 11;
  const classes = useStyles({ perfectWidth });

  useEffect(() => {
    const handleResize = () => setContainerWidth(containerRef.clientWidth);
    if (containerRef) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef]);

  useEffect(() => {
    dispatch(cleanMultishipping());
  }, [dispatch]);

  useEffect(() => {
    const bySize = {};
    contacts.forEach(c => {
      if (c.size) {
        bySize[c.size.name] = (bySize[c.size.name] ?? 0) + 1;
      }
    });
    setQuantities(bySize);
  }, [contacts]);

  const hasOutOfStockSizes = useCallback(
    productQuantities => {
      if (sumByQuantity(productQuantities) < contacts.length) {
        return true;
      }
      if (isOneSize(productQuantities)) {
        return false;
      }
      return !Object.entries(quantities).every(([sizeName, qty]) => {
        const size = productQuantities.find(pq => pq.size.name === sizeName);
        return size?.quantity >= qty;
      });
    },
    [contacts.length, quantities]
  );

  useEffect(() => {
    const tempProduct = tempSelectedProductRef.current;
    if (tempProduct) {
      setSelectedProduct(prev => (hasOutOfStockSizes(tempProduct.stock) ? prev : tempProduct));
    }
  }, [hasOutOfStockSizes, quantities]);

  const navigateToFirstPage = () => {
    query.set('page', 1);
    history.replace(`${history.location.pathname}?${query.toString()}`, location.state);
  };

  const debouncedSearch = debounce(searchText => {
    query.set('search', searchText);
    navigateToFirstPage();
  }, 750);
  const handleSearchChange = e => debouncedSearch(e.target.value);

  const shippingCutoffHour = useShippingCutoffHour();

  const handleContinue = async () => {
    setIsLoading(true);
    const packDeliveryMethods = await getSinglePackDeliveryMethods(selectedProduct.id, 999);
    const { result, data } = packDeliveryMethods;
    if (result !== 'ok') return;

    dispatch(setAccountProduct(selectedProduct));

    contacts.forEach(contact => {
      const deliveryMethods = data.get(contact.shipping_country);
      if (isEmpty(deliveryMethods)) return;

      const id = dispatch(addMultishipping(shippingCutoffHour));
      dispatch(addProductToShip(id, { id: selectedProduct.product, ...selectedProduct }));
      dispatch(saveEmployeeDataToShip(contact, id));
      dispatch(addDeliveryMethod(id, deliveryMethods));
    });

    onNextStep();
  };

  const handleUpdateContacts = async newContacts => {
    const res = await onUpdateContacts(newContacts);
    if (res) {
      setOpenSizelessModal(false);
    }
  };

  const handleSelectProduct = prod => {
    const hasMissingSizes = !isOneSize(prod.stock) && contacts.some(c => !c.size);
    if (hasMissingSizes) {
      tempSelectedProductRef.current = prod;
      setOpenSizelessModal(true);
    } else {
      setSelectedProduct(prev => prev?.id !== prod?.id && prod);
    }
  };

  const hasInternational = contacts.some(c => c.shipping_country !== 'US');

  return (
    <>
      {(isInventoryPending || isLoading) && <Loader />}
      <Container ref={setContainerRef} direction="column">
        <Typography variant="h4">Select swag to ship</Typography>
        <Typography variant="subtitle1" style={{ marginTop: 12 }}>
          Pick a product from your inventory below
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.productHeader}>
          <Typography variant="h5">Products</Typography>
          <OutlinedSearchField
            value={search}
            className={classes.searchField}
            onChange={handleSearchChange}
            variant="outlined"
            placeholder="Search"
            disabled={Boolean(inventoryError)}
            startAdornment={
              <InputAdornment position="start" style={{ marginTop: 0 }}>
                <SearchIcon style={{ width: 18, height: 18, color: '#8D9299' }} />
              </InputAdornment>
            }
          />
        </Grid>
        <Scrollable className={classes.scrollable} style={{ width: perfectWidth }}>
          {inventoryError ? (
            <ErrorAlert error="An error ocurred retrieving the products" style={{ marginTop: 36 }} />
          ) : (
            <Grid container>
              {inventory.map(p => {
                const noInventory = hasOutOfStockSizes(p.stock) && 'Insufficient Inventory';
                const noInternational =
                  hasInternational && !p.can_ship_international && 'International shipping not available for this item';

                return (
                  <Product
                    key={p.id}
                    name={p.name}
                    image={p.image}
                    selected={selectedProduct?.id === p.id}
                    quantities={p.stock}
                    error={noInternational || noInventory}
                    onSelect={() => handleSelectProduct(p)}
                  />
                );
              })}
            </Grid>
          )}
        </Scrollable>
        {total > 0 && (
          <Grid container style={{ margin: '20px 0' }}>
            <Pagination {...pagination} endText="products" />
          </Grid>
        )}
        <SizelessContacts
          open={openSizelessModal}
          contacts={contacts}
          quantities={quantities}
          stock={tempSelectedProductRef.current?.stock}
          onUpdate={handleUpdateContacts}
          onClose={() => setOpenSizelessModal(false)}
        />
      </Container>
      <Footer onContinue={selectedProduct ? handleContinue : undefined} onPrevious={onPreviousStep} />
    </>
  );
};

export default SelectSwag;
