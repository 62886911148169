import React from 'react';
import { useRouter } from 'next/router';
import { Helmet as ReactHelmet } from 'react-helmet';
import log from '../../logger';

const getTags = (property, content) => {
  switch (property) {
    case 'title':
      return [{ property: 'og:title', content }];

    case 'description':
      return [
        { name: 'description', content },
        { property: 'og:description', content }
      ];

    case 'google-site-verification':
    case 'keywords':
    case 'twitter:card':
    case 'twitter:title':
    case 'twitter:description':
    case 'twitter:url':
    case 'twitter:image':
      return [{ name: property, content }];

    default:
      return [{ property, content }];
  }
};

const buildMeta = (tags, nofollow, article) => {
  const entries = Object.entries(tags);
  const tagsMeta = entries.reduce((acc, [property, content]) => acc.concat(getTags(property, content)), []);

  const commonMeta = [
    { property: 'og:type', content: article ? 'article' : 'website' },
    { name: 'robots', content: `index, ${nofollow ? 'nofollow' : 'follow'}` }
  ];

  return [...tagsMeta, ...commonMeta];
};

const pathsWithCanonical = ['/blog', '/landing', '/company'];
const includeCanonical = router => {
  const includeIt = router && (router.asPath === '/' || pathsWithCanonical.some(p => router.asPath.startsWith(p)));
  if (includeIt) {
    log.debug('about to include canonical link element in head');
  }
  return includeIt;
};

const hrefCanonical = path => `https://www.swagup.com${path === '/' ? '' : path}`;

const Helmet = ({ tags, nofollow, article, children }) => {
  const router = useRouter();

  return (
    <ReactHelmet title={tags.title} meta={buildMeta(tags, nofollow, article)}>
      {includeCanonical(router) && <link rel="canonical" href={hrefCanonical(router.asPath)} />}
      {children}
    </ReactHelmet>
  );
};

export default Helmet;
