import * as React from 'react';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState, useEffect } from 'react';
import { AddOutlined } from '@material-ui/icons';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import useIntegrations from '../../hooks/useIntegrations';
import { StylessButton } from '../buttons';
import { getIntegrationImageSrcSet } from './common';
import IntegrationModal from './IntegrationModal';
import SuccessModal from './SuccessModal';
import IntegratedProductsModal from './IntegratedProductsModal';
import Img from '../shared/Img';

const styles = theme => ({
  integrationItem: {
    height: 24,
    width: 24,
    position: 'relative'
  },
  integrationImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  connectedBadge: {
    background: '#E9F5EC',
    color: '#45AF5F',
    paddingLeft: 8,
    paddingRight: 8,
    height: 28,
    borderRadius: 14
  },
  connectButton: {
    borderWidth: 1,
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 12,
    width: 72,
    height: 28,
    borderRadius: 14
  },
  integrationItemsPanel: {
    height: 24,
    maxWidth: 52
  },
  addIconButton: {
    width: 24,
    height: 24,
    border: '2px solid #C8E6C9',
    padding: 4
  },
  addIcon: {
    width: 24,
    height: 24,
    color: '#C8E6C9'
  },
  integrationPanel: { maxWidth: 164 }
});

const useStyles = makeStyles(styles);

const ConnectedBadge = ({ classes }) => (
  <Box className={classes.connectedBadge}>
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      <Grid item>
        <span>Connected</span>
      </Grid>
    </Grid>
  </Box>
);

const getIsConnected = product => product.integrations?.length > 0;

const sampleInteations = product => {
  switch (true) {
    case product.in_shopify && product.in_amazon:
      return ['SHOPIFY', 'AMAZON'];
    case product.in_shopify:
      return ['SHOPIFY'];
    case product.in_amazon:
      return ['AMAZON'];
    default:
      return [];
  }
};

const IntegrationPanel = ({ originalProduct }) => {
  const [open, setOpen] = useState(false);
  const [smOpen, setSmOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [successData, setSuccessData] = useState();
  const product = { ...originalProduct, integrations: sampleInteations(originalProduct) };
  const { eCommerceIntegrations } = useFlags();
  const { integrations, getConnectedPlatform, onOpen } = useIntegrations();
  const isConnected = getIsConnected(product);

  useEffect(() => {
    if (successData) {
      setTimeout(() => {
        setSmOpen(true);
      }, 1000);
    }
  }, [successData]);

  useEffect(() => {
    if (open) {
      setSuccessData();
    }
  }, [open]);

  const onIntegrationClose = () => setSelectedPlatform();

  const productIntegrations = integrations.filter(
    integration => integration.active && product.integrations?.includes(integration.rutter_id)
  );
  const availableIntegrations = integrations.filter(
    integration => integration.active && !integration.url && integration.type !== 'hris'
  );
  const classes = useStyles();
  if (!eCommerceIntegrations) return null;

  return (
    <>
      <Box className={classes.integrationPanel}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            {isConnected ? (
              <ConnectedBadge classes={classes} />
            ) : (
              <Button variant="secondary" size="small" onClick={() => setOpen(true)} className={classes.connectButton}>
                Connect
              </Button>
            )}
          </Grid>
          {productIntegrations.length > 0 && (
            <Grid item xs>
              <div className={classes.integrationItemsPanel}>
                <ScrollMenu
                  data={productIntegrations.map((integration, index) => (
                    <Box item key={integration.rutter_id} style={{ paddingLeft: index ? 4 : 0 }}>
                      <StylessButton
                        onClick={() =>
                          getConnectedPlatform(integration.rutter_id)
                            ? setSelectedPlatform(integration.rutter_id)
                            : onOpen(integration.rutter_id)
                        }
                      >
                        <Box className={classes.integrationItem}>
                          <Img
                            src={integration.image_url}
                            srcSet={getIntegrationImageSrcSet(integration.rutter_id)}
                            alt={integration.name}
                            className={classes.integrationImage}
                          />
                        </Box>
                      </StylessButton>
                    </Box>
                  ))}
                />
              </div>
            </Grid>
          )}
          {isConnected && (
            <Grid item style={{ paddingRight: 0 }}>
              <IconButton onClick={() => setOpen(true)} className={classes.addIconButton}>
                <AddOutlined className={classes.addIcon} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <IntegrationModal
        isOpen={open}
        setOpen={setOpen}
        product={product}
        availableIntegrations={availableIntegrations}
        onIntegrationSuccess={data => setSuccessData(data)}
      />
      <SuccessModal isOpen={smOpen} onClose={() => setSmOpen(false)} successData={successData} />
      <IntegratedProductsModal platformName={selectedPlatform} onClose={onIntegrationClose} product={product} />
    </>
  );
};

export default IntegrationPanel;
