import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { CustomTextField, ImageSelector } from '../redeemCommon';
import styles from '../styles/redeemPagesHome';

const useStyles = makeStyles(styles);
const PageContent = ({ page, templateFields, artworkLoader, setPage, handleFileUpload, onChange }) => {
  const classes = useStyles();
  return (
    <Grid container>
      {templateFields.map(tf => (
        <Grid item xs={12} key={tf.name} className={classes.inputSection}>
          <Typography variant="body3RegularInter" className={classes.inputLabel}>
            {tf.label}
            {tf.required && <strong>*</strong>}
          </Typography>
          {tf.image ? (
            <ImageSelector
              imageName={page.clientImage}
              selected={!!page.clientImage}
              onSelect={() => setPage(p => ({ ...p, clientImage: '/images/redeem/rocket.png' }))}
              artworkLoader={artworkLoader}
              url={page.clientImage}
              property="clientImage"
              handleFileUpload={handleFileUpload}
            />
          ) : (
            <CustomTextField
              className={tf.multiline ? classes.inputTextMultiline : classes.inputText}
              placeholder={tf.placeholder}
              defaultValue={page[tf.name]}
              name={tf.name}
              onChange={onChange}
              fullWidth
              multiline={tf.multiline}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default PageContent;
