import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import OrderItem from './OrderItem';
import styles from './styles/DisplayTool';

const Orders = ({ classes, order, showLine, isEnterpriseFlowActive }) => (
  <Grid container className={classes.separateOrderContainer}>
    <Grid item container xs={12} className={classes.separateOrderNoContainer}>
      <Grid item sm={4} xs={4}>
        <p className={classes.infoOrderText}>Order#: {order.order_no}</p>
      </Grid>
      <Grid item sm={8} xs={8}>
        {showLine && <hr className={classes.orderLine} />}
      </Grid>
    </Grid>
    <Grid item xs={12}>
      {order.products.map(({ product, total, sizes }, index) => (
        <OrderItem
          key={product.id}
          order={order}
          product={product}
          total={total}
          sizes={sizes}
          showIcon={index === 0}
          isEnterpriseFlowActive={isEnterpriseFlowActive}
        />
      ))}
    </Grid>
  </Grid>
);

export default withStyles(styles)(Orders);
