import React from 'react';
import { Grid } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { moneyStr } from '../../helpers/utils';
import BenefitTab from './BenefitTab';
import { useMembership } from '../../contexts/membershipContext';

const AppliedMembershipPanelStatic = ({
  fullWidth,
  className,
  style,
  splitted,
  size = 'normal',
  appliedDiscount,
  appliedReward,
  fixedRate
}) => {
  const { currentMembership } = useMembership();

  const { swagupMembershipPlans, earlyBirdPromotion } = useFlags();

  return swagupMembershipPlans &&
    !isEmpty(currentMembership) &&
    (!currentMembership.isFreeTier || earlyBirdPromotion) ? (
    <div className={className} style={style}>
      <Grid container justifyContent="center">
        <Grid item xs={fullWidth ? 12 : 10} container>
          <Grid item xs={splitted ? 6 : 12}>
            <BenefitTab
              icon="/images/membership/rewardRocket.png"
              text="Membership Discounts"
              value={appliedDiscount && appliedDiscount > 0 ? `-${moneyStr(appliedDiscount)}` : '$0.00'}
              size={size}
            />
          </Grid>
          <Grid item xs={splitted ? 6 : 12}>
            <BenefitTab
              earlyBirdPromotion={earlyBirdPromotion ? currentMembership : undefined}
              icon="/images/membership/rewards.png"
              text={`Reward Points ${fixedRate || currentMembership.reward_points_rate}X`}
              value={appliedReward}
              type="reward"
              size={size}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  ) : null;
};

export default AppliedMembershipPanelStatic;
