import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { CenteredGrid } from '../../../shared';

import styles from './OurPhilosophy.styles';

const OurPhilosophy = ({ classes }) => (
  <CenteredGrid container justifyContent="center" className={classes.container}>
    <Grid item md={5} xs={12} className={classes.imgContainer}>
      <img
        alt="title"
        srcSet="/images/custom-pack-landing/philosophy.png 1x, /images/custom-pack-landing/philosophy@2x.png 2x, /images/custom-pack-landing/philosophy@3x.png 3x"
      />
    </Grid>
    <Grid item md={7} xs={12} className={classes.textContainer}>
      <span className={classes.miniTitle}>Philosophy</span>
      <h2 className={classes.title}>Our Swag Philosophy</h2>
      <p className={classes.text}>
        No one needs another crappy item they are going to throw away. We’re constantly on the hunt for high quality,
        useful items that people will love. With that in mind, if you don’t see what you are looking for, just let us
        know and we’ll source options.
      </p>
    </Grid>
  </CenteredGrid>
);

export default withStyles(styles)(OurPhilosophy);
