import * as React from 'react';
import {
  Box,
  Divider,
  Grid,
  Switch,
  Tab,
  Tabs,
  makeStyles,
  withStyles,
  FormControl,
  InputAdornment,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@material-ui/core';
import { Typography, Button } from '@swagup-com/components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowBack, ArrowForward, CheckCircle, ExpandLess, ExpandMore } from '@material-ui/icons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { partition, round, sumBy, isString, isArray, isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import ProductBreakdown from '../../common/ProductBreakdown';
import {
  addBusinessDaysToDate,
  buildUrlWithParam,
  cardImg,
  imageSrcSet,
  isPack,
  moneyStr,
  sumByQuantity
} from '../../../../../../helpers/utils';
import { useOrder } from '../../OrderContext';
import { useProduct } from '../../common/ProductContext';
import {
  invoiceStatus,
  paymentMethods,
  productionTime as productionSpeed,
  productionTime
} from '../../../../../../apis/constants';
import { useCreditSummary, useIsAchPrepaid, useQueryParams } from '../../../../../../hooks';
import ProductImage from '../../common/ProductImage';
import InvoiceProductSizes from './InvoiceProductSizes';
import styles from './styles/common';
import SizesConfiguration from './SizesConfiguration';
import { productImageBasedOnStatus } from '../../../../../global/proofsCommon';
import { Img } from '../../../../../global/ImgUtils';
import { ellipsisStyles, greyRoundBorder } from '../../../../../shared/styles/commonStyles';
import { StylessButton } from '../../../../../buttons';
import AddPaymentMethodDialog from '../../../../../account/AddPaymentMethodDialog';
import Tooltip from '../../../../../shared/Tooltip';
import StrikeOutText from '../../../../../global/StrikeOutCost';
import apiPaths from '../../../../../../helpers/apiPaths';

const useStyles = makeStyles(styles);

const Header = ({ header, subtitle, image = null }) => (
  <Box style={{ marginBottom: subtitle ? 12 : 24 }}>
    <Grid container alignItems="center">
      <Grid item>
        <Typography component="p" variant="h5SemiBoldInter">
          {header}
        </Typography>
      </Grid>
      <Grid item>{image}</Grid>
    </Grid>

    {subtitle && (
      <Typography component="p" variant="body4MediumInter" style={{ marginTop: image ? 0 : 12 }}>
        {subtitle}
      </Typography>
    )}
  </Box>
);

const ToogleButtom = ({ onClick, opened, style }) => {
  const classes = useStyles();
  return (
    <StylessButton onClick={onClick} className={classes.buttonOpenItem} style={style}>
      {opened ? <ExpandLess className={classes.arrowIcon} /> : <ExpandMore className={classes.arrowIcon} />}
    </StylessButton>
  );
};

const ProductInfo = ({ product, sizes, totalQuantity, urlToOpen, isStatic, toogleOpenItems, opened, classes }) => {
  const totalQty = sumByQuantity(sizes) || totalQuantity || 0;
  const subtotal = totalQty * +(product.price ?? 0);
  const productIsPack = isPack(product.product.record_type);
  return (
    <Box>
      <Grid container style={{ paddingLeft: '20px' }}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Typography variant="body1SemiBoldInter" className={classes.productName}>
              {product.product.name}
            </Typography>
            <Typography variant="body3RegularInter" className={classes.grey}>
              Type: {isPack(product.product.record_type) ? '📦 Pack' : '👕 Product'}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body3SemiBoldInter" className={classes.productNameText}>
              {' '}
            </Typography>
            <Typography variant="body3RegularInter" style={{ textAlign: 'center' }} className={classes.grey}>
              Item Count: {totalQty}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1SemiBoldInter" style={{ textAlign: 'right', marginBottom: 4 }}>
              {moneyStr(subtotal)}
            </Typography>
            <Typography variant="body3RegularInter" style={{ textAlign: 'right' }} className={classes.grey}>
              Cost: {moneyStr(product.price)} per/unit
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {productIsPack && (
        <Grid item xs={12} style={{ paddingLeft: '20px' }}>
          <Box className={classes.hideButtonBox}>
            <ToogleButtom
              onClick={toogleOpenItems}
              opened={opened}
              style={{
                position: 'absolute',
                right: 0,
                top: 10
              }}
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12} style={{ paddingLeft: '20px' }}>
        {isStatic ? (
          <InvoiceProductSizes
            product={{
              ...product,
              quantities_per_size: isEmpty(product.quantities_per_size)
                ? [
                    {
                      quantity: product.quantity,
                      size: {
                        category: 'Generic',
                        id: 9,
                        modified_on: '2022-09-25T20:40:06.585609Z',
                        name: 'One Size',
                        sort_order: 1
                      }
                    }
                  ]
                : product.quantities_per_size
            }}
            isOrderOverview
            urlToOpen={urlToOpen}
            isStatic={isStatic}
          />
        ) : (
          <SizesConfiguration isOrderOverview urlToOpen={urlToOpen} />
        )}
      </Grid>
    </Box>
  );
};

const ItemDetails = ({ item, headerItem }) => {
  const classes = useStyles();
  const itemIsPack = isPack(item.product.record_type);
  const price = item?.product.price ?? 0;

  return (
    <Grid item container alignItems="center" role="row" style={{ padding: '0px 24px' }}>
      <Grid item container xs style={{ flexWrap: 'nowrap' }}>
        <Img
          src={productImageBasedOnStatus(item.product, 78, 78)}
          alt={item.product.name}
          width={48}
          height={48}
          style={{ ...greyRoundBorder }}
        />
        <Grid item container direction="column" justifyContent="space-around" xs className={classes.productDescription}>
          <p className={classes.hightlightedText} style={{ ...ellipsisStyles, width: '100%' }}>
            {item.product.name}
          </p>
          <p className={classes.unhighlightedText}>
            {!headerItem ? (
              <>
                Color:{' '}
                <span style={{ color: headerItem ? '#787b80' : '#0b1829' }}>
                  {item.product.theme_color ?? 'Custom'}
                </span>
              </>
            ) : (
              !itemIsPack && (
                <>
                  Qty: <span>{sumByQuantity(item?.sizes || [])}</span>
                </>
              )
            )}
          </p>
        </Grid>
      </Grid>
      {!headerItem && (
        <>
          <Grid item xs={2} className={classes.quantityColumn}>
            <p className={classes.hightlightedText}>{item.units_per_pack}</p>
          </Grid>
          <Grid item xs={2} className={classes.priceColumn}>
            <p className={classes.hightlightedText}>{moneyStr(price)}</p>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={3}
        className={!headerItem ? classes.totalColumn : undefined}
        container
        direction="column"
        alignItems="flex-end"
      >
        {headerItem && !itemIsPack && (
          <>
            <p className={classes.unhighlightedText}>Price/Unit</p>
            <p className={classes.headerPrice} style={{ marginTop: 3 }}>
              {moneyStr(price)}
            </p>
          </>
        )}
        {!headerItem && <p className={classes.hightlightedText}>{moneyStr(round(item.units_per_pack * price, 2))}</p>}
      </Grid>
    </Grid>
  );
};

const PackItemsSummary = ({ product }) => {
  const classes = useStyles();
  return (
    <Box className={classes.itemsPanel}>
      <Grid container className={classes.headerRow}>
        <Grid item xs>
          <p className={classes.headerText} style={{ textAlign: 'left' }}>
            ITEM
          </p>
        </Grid>
        <Grid item xs={2} className={classes.quantityColumn}>
          <p className={classes.headerText}>QTY/PACK</p>
        </Grid>
        <Grid item xs={2} className={classes.priceColumn}>
          <p className={classes.headerText}>PRICE/UNIT</p>
        </Grid>
        <Grid item xs={3} className={classes.totalColumn}>
          <p className={classes.headerText}>TOTAL</p>
        </Grid>
      </Grid>

      <Divider className={classes.divider} style={{ marginTop: 15 }} />
      <Grid item container xs className={classes.productList}>
        <Grid container direction="column" style={{ marginBottom: 24 }}>
          {product.product.items.map(item => (
            <Grid key={item.product.id} item style={{ marginTop: 22 }}>
              <ItemDetails item={item} priceInfo={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />

      <Grid item container justifyContent="flex-end" alignItems="flex-end" style={{ padding: '16px 24px 0px' }}>
        <p className={classes.unhighlightedPriceText} style={{ marginRight: 10 }}>
          Price/Pack
        </p>
        <p className={classes.pricePerPack}>{moneyStr(product.price)}</p>
      </Grid>
    </Box>
  );
};

const ProductContainer = ({
  product,
  sizes,
  totalQty,
  isRush,
  rushFee,
  subtotal,
  rushFeeWithoutDiscount,
  isStatic
}) => {
  const [openItems, setOpenItems] = useState(false);
  const history = useHistory();
  const query = useQueryParams();
  const open = +query.get('productBreakdown') === product.id;
  const urlToOpen = buildUrlWithParam(history.location, 'productBreakdown', product.id);
  const removeOpenQueryParam = React.useCallback(() => {
    query.delete('productBreakdown');
    history.replace({ ...history.location, search: query.toString() });
  }, [query, history]);

  const productIsPack = isPack(product.product.record_type);
  const classes = useStyles();
  return (
    <Box className={classes.productContainer}>
      <Grid container>
        <Grid item xs>
          <ProductImage product={product.product} />
        </Grid>
        <Grid item xs={9} style={{ maxWidth: '80%' }}>
          <ProductInfo
            isStatic={isStatic}
            product={product}
            sizes={sizes}
            totalQuantity={totalQty}
            urlToOpen={urlToOpen}
            toogleOpenItems={() => setOpenItems(prev => !prev)}
            opened={openItems}
            classes={classes}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.subTotalItem}>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Typography variant="body3MediumInter">
              Estimated Production Time:
              <Typography component="span" variant="body4RegularInter" style={{ marginLeft: 10 }}>
                <span style={{ fontSize: 14 }}>
                  {isStatic
                    ? product.turnaround_time
                    : product.product[isRush ? 'rush_turnaround_time' : 'standard_turnaround_time']}
                </span>
                <span style={{ fontSize: 12 }}>{`${
                  (isStatic && product.turnaround_time > 1) ||
                  product.product[isRush ? 'rush_turnaround_time' : 'standard_turnaround_time'] > 1
                    ? ' days'
                    : ' day'
                }`}</span>
                {isRush && (
                  <img
                    src="/images/public/rush.svg"
                    alt="Lightning Icon"
                    style={{ marginLeft: 10, width: 8, marginBottom: -1 }}
                  />
                )}
              </Typography>
            </Typography>
            <Typography variant="body3MediumInter" style={{ marginLeft: 10 }}>
              {isRush && rushFeeWithoutDiscount !== rushFee && (
                <StrikeOutText value={rushFeeWithoutDiscount} fontSize={12} color="#898C91" marginRight={8} />
              )}
              {isRush ? moneyStr(rushFee) : undefined}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs />
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="baseline">
            <Typography variant="body2SemiBoldInter">Subtotal:</Typography>
            <Typography variant="body1SemiBoldInter" style={{ marginLeft: 10 }}>
              {moneyStr(rushFee + subtotal)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {productIsPack && openItems && <PackItemsSummary product={product} />}

      {!isStatic && <ProductBreakdown open={open} onClose={removeOpenQueryParam} />}
    </Box>
  );
};

const InvoiceProduct = ({ product }) => {
  const isRush = product.production_time === productionSpeed.rush;
  const totalQty = isPack(product) ? sumByQuantity(product.quantities_per_size) : product.quantity;
  const rushFee = totalQty * +(product.rush_fee ?? 0);
  const rushFeeWithoutDiscount = totalQty * +(product.rush_fee_without_discount ?? 0);
  const subtotal = totalQty * +(product.price ?? 0);
  return (
    <ProductContainer
      product={{ ...product, product: { ...product.product, items: product.items.map(i => ({ ...i, product: i })) } }}
      sizes={product.quantities_per_size}
      totalQty={totalQty}
      isRush={isRush}
      rushFee={rushFee}
      subtotal={subtotal}
      rushFeeWithoutDiscount={rushFeeWithoutDiscount}
      isStatic
    />
  );
};

const OrderProduct = () => {
  const order = useOrder();
  const { product } = useProduct();
  const history = useHistory();
  const query = useQueryParams();

  const isRush = product.production_time === productionSpeed.rush;
  const totalQty = sumByQuantity(product.sizes);
  const rushFee = totalQty * +(product.rush_fee ?? 0);
  const rushFeeWithoutDiscount = totalQty * +(product.rush_fee_without_discount ?? 0);
  const subtotal = totalQty * +(product.price ?? 0);

  const removeOpenQueryParam = React.useCallback(() => {
    query.delete('productBreakdown');
    history.replace({ ...history.location, search: query.toString() });
  }, [query, history]);

  const productIdToOpen = +query.get('productBreakdown');

  const { products } = order;
  React.useEffect(() => {
    const productToOpen = products.find(p => p.id === productIdToOpen);
    if (!productToOpen) removeOpenQueryParam();
  }, [products, productIdToOpen, removeOpenQueryParam]);

  return (
    <ProductContainer
      product={product}
      sizes={product.sizes}
      isRush={isRush}
      rushFee={rushFee}
      subtotal={subtotal}
      rushFeeWithoutDiscount={rushFeeWithoutDiscount}
    />
  );
};

const SmallSwitch = withStyles({
  root: {
    overflow: 'visible',
    height: 24,
    width: 36,
    padding: '6px 0px 6px 0px'
  },
  switchBase: {
    height: 24,
    padding: 0,
    color: '#B7BBBF',
    '&$checked': {
      color: '#4CAF50'
    },
    '&$checked + $track': {
      backgroundColor: '#4CAF50',
      opacity: 0.5
    },
    '& .MuiSwitch-thumb': {
      width: 18,
      height: 18
    }
  },
  checked: {},
  track: { backgroundColor: '#00000029', opacity: 0.8 },
  thumb: {
    boxShadow: 'none'
  }
})(Switch);

const InputComponent = ({
  handleInputChange,
  inputValue,
  type,
  currentBalance,
  handleKeyPress,
  isFocused,
  handleFocus,
  handleBlur
}) => {
  const classes = useStyles();

  return (
    <Grid alignItems="center" style={{ margin: '12px 0px 0px 6px', paddingBottom: 0 }}>
      <FormControl fullWidth>
        <TextField
          type={type}
          placeholder="Enter an amount"
          value={inputValue}
          onKeyPress={handleKeyPress}
          onChange={handleInputChange}
          currentBalance={currentBalance}
          className={classes.inputSwagFund}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="body1MediumInter" style={{ color: '#4A4F54' }}>
                  $
                </Typography>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="body3RegularInter" style={{ color: '#989EA4' }}>
                  <InputAdornment position="end">/ {currentBalance}</InputAdornment>
                </Typography>
              </InputAdornment>
            ),
            style: { color: inputValue || isFocused ? '#4CAF50' : '#989EA4' }
          }}
          classes={{ root: classes.creditInput }}
          onFocus={handleFocus}
          onInput={handleFocus}
          onBlur={handleBlur}
        />
      </FormControl>
    </Grid>
  );
};

const SwagCard = ({
  currentBalance = 0,
  inputValue,
  setInputValue,
  useCreditFirst,
  setUseCreditFirst,
  setInvalid,
  customOrder
}) => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [isFocused, setIsFocused] = React.useState(false);

  const order = useOrder() || customOrder;
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = e => {
    setUseCreditFirst(e.target.checked);
    if (!e.target.checked) return setInputValue(0);
    if (!inputValue && e.target.checked) {
      const maxCreditPayment = order?.currentTotal - order?.funds;
      const maxPossible = maxCreditPayment < currentBalance ? maxCreditPayment : currentBalance;
      setInvalid(false);
      setError(false);
      return setInputValue(round(maxPossible, 2));
    }
    return 0;
  };

  const handleInputChange = e => {
    setError(false);
    const positiveAmount = e.target.value < 0 ? (e.target.value = 0) : e.target.value;
    const amount = parseFloat(positiveAmount);
    setInputValue(amount);
    return 1;
  };

  const handleKeyPress = e => {
    setError(false);
    const inputFund = e.target.value;
    if (Number.isNaN(inputFund) && inputFund < 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!useCreditFirst) return;

    let hasError = false;

    if (inputValue > parseFloat(currentBalance)) {
      setErrorMsg(`Entered amount cannot be greater than your current Swag Card Balance: ${moneyStr(currentBalance)}`);
      hasError = true;
    }
    const maxCreditPayment = order?.currentTotal - order?.funds;
    if (inputValue > maxCreditPayment) {
      setErrorMsg(`Entered amount cannot be greater than your current order value: ${moneyStr(maxCreditPayment)}`);
      hasError = true;
    }
    setError(hasError);
    setInvalid(hasError);
  }, [inputValue, currentBalance, order, useCreditFirst]);

  const ErrorMessage = () => {
    return (
      <Typography
        variant="body4RegularInter"
        style={{ color: error ? '#F44336' : 'transparent', padding: '5px 5px 0px 30px' }}
      >
        {error ? errorMsg : 'no erros'}
      </Typography>
    );
  };

  return (
    <Box className={classes.creditPaymentPanel}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Box className={classes.cardImageWrapper}>
            <img
              srcSet={imageSrcSet('/images/swag-card/swag-card.png')}
              alt="swag-card"
              className={classes.cardImage}
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Box>
            <p className={classes.swagCardCurrentBalanceText}>
              Swag Card Current Balance: <span>{moneyStr(currentBalance)}</span>
            </p>
            <p className={classes.swagCardCurrentBalanceSubText}>Use Swag Card balance</p>
          </Box>
        </Grid>
        <Grid item>
          {Number(currentBalance || 0) === 0 && (
            <>
              <Tooltip
                title="Add funds to your Swag Card to use it on future orders"
                placement="right"
                width={180}
                color="blue"
              >
                <span style={{ cursor: 'pointer' }}>
                  <SmallSwitch
                    checked={useCreditFirst}
                    onChange={handleChange}
                    color="primary"
                    name="checkedBtn"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled
                  />
                </span>
              </Tooltip>
            </>
          )}
          {currentBalance > 0 && (
            <SmallSwitch
              checked={useCreditFirst}
              onChange={handleChange}
              color="primary"
              name="checkedBtn"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
        </Grid>
      </Grid>
      <Grid>
        {useCreditFirst && (
          <>
            <InputComponent
              type="number"
              currentBalance={currentBalance}
              handleInputChange={handleInputChange}
              handleKeyPress={handleKeyPress}
              inputValue={inputValue}
              isFocused={isFocused}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
            />
            <ErrorMessage />
          </>
        )}
      </Grid>
    </Box>
  );
};

const SwagCardModal = ({ open, handleClose, inputValue, handleAccept }) => {
  const classes = useStyles();
  const order = useOrder();
  const {
    data: { current_balance }
  } = useCreditSummary();

  const finalBalance = current_balance - inputValue + order?.funds;

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="SwagCardModal" style={{ borderRadius: 8 }}>
      <DialogTitle id="SwagCardModal" className={classes.swagCardModal}>
        <Grid className={classes.contentWrapper}>
          <DialogContent style={{ padding: 0 }}>
            <Typography variant="body2SemiBoldInter" style={{ paddingBottom: 16 }}>
              Are you sure that you want to pay the order total with your Swag Card
            </Typography>
            <Typography variant="body4RegularInter" style={{ padding: '12px 0px 15px 0px', color: '#4A4F54' }}>
              You currently have a balance of {`${moneyStr(current_balance)}`}, after paying this order your balance
              will be {moneyStr(finalBalance)}.
            </Typography>
          </DialogContent>
          <Divider variant="fullWidth" />
        </Grid>
        <DialogActions style={{ padding: 0, margin: '12px 0px' }}>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
              handleAccept();
            }}
            className={classes.modalPayBtn}
          >
            Yes, Pay and place Order
          </Button>
        </DialogActions>
      </DialogTitle>
    </Dialog>
  );
};

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 14,
    color: '#787B80',
    fontFamily: 'Gilroy',
    marginRight: 0,
    lineHeight: '22px',
    opacity: 0.6,
    width: 96,
    padding: '2px 4px',
    '&:focus': {
      opacity: 1
    },
    '&.Mui-selected': { color: '#0B1829' }
  }
}))(props => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#3577d4',
    marginLeft: '30px',
    marginRight: 'auto',
    maxWidth: 38
  },
  root: {
    borderBottom: '1px solid #EBEDF0'
  }
})(Tabs);

const CreditCard = ({ paymentProfileCard }) => {
  const classes = useStyles();
  return (
    <Box className={classes.creditCardPaymentPanel}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Box className={classes.creditCardImageWrapper}>
            <img src={cardImg(paymentProfileCard.card_type)} alt="swag-card" className={classes.creditCardImage} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box>
            <p className={classes.creditCardNumber}>{paymentProfileCard.card_number}</p>
            <p className={classes.creditCardNumberSubText}>{paymentProfileCard.expiration_date}</p>
          </Box>
        </Grid>
        <Grid item>
          <CheckCircle className={classes.checkedIcon} />
        </Grid>
      </Grid>
    </Box>
  );
};

const CreditCardBtnWrapper = ({ showChange, disabled }) => {
  const isAchPrepaid = useIsAchPrepaid();
  const paymentProfiles = useSelector(state => state.paymentProfiles);
  const defaultMethod = paymentProfiles.find(p => p.default)?.payment_method;
  const [paymentMethod, setPaymentMethod] = React.useState(defaultMethod || paymentMethods.creditCard);
  const [openAddPaymentMethod, setOpenAddPaymentMethod] = React.useState(false);

  const client = useQueryClient();
  const toggleOpenPaymentModal = () => {
    setOpenAddPaymentMethod(isOpen => !isOpen);
    client.invalidateQueries(apiPaths.account);
  };

  React.useEffect(() => {
    if (!isAchPrepaid) {
      setPaymentMethod(paymentMethods.creditCard);
    }
  }, [isAchPrepaid]);

  const classes = useStyles();
  return (
    <Grid container alignItems="center" className={classes.ccWrapper}>
      <Grid item>
        <Button
          variant="text"
          size="small"
          onClick={toggleOpenPaymentModal}
          className={classes.newCreditCard}
          disabled={disabled}
        >
          Add New
        </Button>
        <AddPaymentMethodDialog
          paymentMethod={paymentMethod}
          open={openAddPaymentMethod}
          onClose={toggleOpenPaymentModal}
        />
      </Grid>
      <Grid item>
        {showChange &&
          (!disabled ? (
            <Link to="/billing/accounts" disabled={disabled}>
              <Typography variant="body3SemiBoldInter" style={{ color: '#3577D4' }}>
                Change
              </Typography>
            </Link>
          ) : (
            <Typography variant="body3SemiBoldInter" style={{ color: 'rgba(0, 0, 0, 0.26)', cursor: 'default' }}>
              Change
            </Typography>
          ))}
      </Grid>
    </Grid>
  );
};

const AddNewCreditCard = ({ isShippingModal }) => {
  const isAchPrepaid = useIsAchPrepaid();
  const paymentProfiles = useSelector(state => state.paymentProfiles);
  const defaultMethod = paymentProfiles.find(p => p.default)?.payment_method;
  const [paymentMethod, setPaymentMethod] = React.useState(defaultMethod || paymentMethods.creditCard);
  const [openAddPaymentMethod, setOpenAddPaymentMethod] = React.useState(false);

  const client = useQueryClient();
  const handleClose = () => {
    setOpenAddPaymentMethod(isOpen => !isOpen);
    client.invalidateQueries(apiPaths.account);
  };

  React.useEffect(() => {
    if (!isAchPrepaid) {
      setPaymentMethod(paymentMethods.creditCard);
    }
  }, [isAchPrepaid]);

  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.containerCreditAvailable}
      style={isShippingModal ? { width: '100%', height: 90 } : undefined}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={1}>
        <StylessButton onClick={handleClose}>
          <img src="/images/shipping/add-CC.svg" className={classes.accountImg} alt="" />
        </StylessButton>
        <AddPaymentMethodDialog paymentMethod={paymentMethod} open={openAddPaymentMethod} onClose={handleClose} />
      </Grid>
      <Grid item xs={11} style={{ paddingLeft: 20 }}>
        <p className={classes.creditAvailable}>Add New Credit Card</p>
        <p className={classes.textCreditAvailable}>You can add new Credit Card</p>
      </Grid>
    </Grid>
  );
};

const DefaultPaymentProfile = ({ paymentProfile, disabled }) => {
  const classes = useStyles();
  const paymentProfileCard = paymentProfile?.credit_card;
  const creditCardAvailable = paymentProfileCard?.card_number;

  return (
    <Box className={classes.defaultPaymentProfileSection}>
      <Box>
        {paymentProfileCard ? (
          <CreditCard paymentProfileCard={paymentProfileCard} />
        ) : (
          <AddNewCreditCard isShippingModal />
        )}
        {creditCardAvailable ? <CreditCardBtnWrapper showChange={paymentProfileCard} disabled={disabled} /> : null}
      </Box>
    </Box>
  );
};

const infoItems = [
  'Our billing team receives your request.',
  'You will receive an ACH link. Keep an eye out for an email.',
  'Complete payment using the provided ACH link.'
];

const ACHPostPayment = () => {
  const classes = useStyles();
  return (
    <Box className={classes.achPostPaymentanel}>
      <Box className={classes.achImageWrapper}>
        <img srcSet={imageSrcSet('/images/account/ach-payment.png')} alt="ach-payment" className={classes.achImage} />
      </Box>
      <p className={classes.achTitle}>Request ACH Link</p>
      <p className={classes.achSubTitle}>
        To send an ACH payment, click &quot;Place Order&quot;. <br />
        Our billing team will send you a link to start an ACH transaction.
      </p>
      <p className={classes.achInfoHeader}>How ACH works</p>
      {infoItems.map(i => (
        <p key={i} className={classes.achInfo}>
          <span style={{ color: '#787b80' }}>&#8226;</span> {i}
        </p>
      ))}
    </Box>
  );
};

const WireTransfer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wireTransferPanel}>
      <p className={classes.wireTransferText}>To send payment you’ll need the following information:</p>
      <h3 className={classes.wireTransferTitle}>Bank information</h3>
      <p className={classes.wireTransferText}>Santander</p>
      <p className={classes.wireTransferText}>1322A Paterson Plank Rd</p>
      <p className={classes.wireTransferText}>Secaucus, NJ 07094</p>
      <h3 className={classes.wireTransferTitle}>Routing number</h3>
      <p className={classes.wireTransferText}>231372691</p>
      <h3 className={classes.wireTransferTitle}>Account number</h3>
      <p className={classes.wireTransferText}>8938327434</p>
      <h3 className={classes.wireTransferTitle}>Swift code</h3>
      <p className={classes.wireTransferText}>SVRNUS33</p>
      <div style={{ height: 1, margin: '16px 0px 12px 0px', background: '#EBEDF0' }} />
      <p className={classes.wireTransferText} style={{ textAlign: 'center' }}>
        Once payment is received we will start production of your order.
      </p>
    </Box>
  );
};

const SkipStep = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item className={classes.btnWrapperCTA}>
        <Typography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
          Skip Step
        </Typography>
        <ArrowForward viewBox="0 -3 30 32" style={{ marginLeft: 4, color: '#4A4F54' }} />
      </Grid>
    </Grid>
  );
};

const PreviousStep = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item className={classes.btnWrapperCTA}>
        <ArrowBack viewBox="0 -3 30 32" style={{ marginRight: 4, color: '#4A4F54' }} />
        <Typography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
          Previous Step
        </Typography>
      </Grid>
    </Grid>
  );
};

const getShipmentGroupsInfo = shipmentGroups => {
  const domesticPrice = sumBy(shipmentGroups, sg => Number(sg.domestic_price));
  const internationalPrice = sumBy(shipmentGroups, sg => Number(sg.international_price));
  const domesticPriceBeforeDiscount = sumBy(shipmentGroups, sg => Number(sg.domestic_price_without_discount || 0));
  const internationalPriceBeforeDiscount = sumBy(shipmentGroups, sg =>
    Number(sg.international_price_without_discount || 0)
  );

  const orders = shipmentGroups.reduce((ords, sg) => ords.concat(sg.directory_orders), []);
  const [domestic, international] = partition(orders, o => o.directory.shipping_country === 'US');
  const domesticRecipients = new Set(domestic.map(o => o.directory.id)).size;
  const internationalRecipients = new Set(international.map(o => o.directory.id)).size;

  return {
    domestic: {
      price: domesticPrice,
      price_without_discount: domesticPriceBeforeDiscount,
      recipients: domesticRecipients
    },
    international: {
      price: internationalPrice,
      price_without_discount: internationalPriceBeforeDiscount,
      recipients: internationalRecipients
    }
  };
};

const buildShippingOrderProofsOrAP = items => ({
  account_products: items.map(p => ({
    account_product: p.product.id,
    production_time: p.production_time,
    sizes: p.sizes
      .filter(s => s.quantity > 0)
      .map(s => ({
        size: s.size.id,
        quantity: s.quantity
      }))
  }))
});

const toWeek = date =>
  dayjs(date)
    .isoWeekday(1)
    .format('MMM DD, YYYY');

const SORDStatuses = {
  pendingFinalCheck: 'Pending Final Check',
  pendingPO: 'Pending PO',
  partialPO: 'Partial PO',
  POComplete: 'PO Complete',
  clientApprovedDelay: 'Client Approved Delay',
  QAComplete: 'QA Complete',
  readyToPack: 'Ready to Pack',
  missionComplete: 'Mission Complete',
  canceled: 'Canceled',
  exception: 'Exception'
};

const estimatedShippingDate = (order, isEnterpriseFlowActive, completed) => {
  const { sales_order } = order;

  if (!isEmpty(sales_order)) {
    // && (sales_order.date_estimated_ship || sales_order.date_ready_to_ship)

    if (completed && (sales_order.date_mission_complete || sales_order.date_estimated_mission_complete))
      return dayjs(sales_order.date_mission_complete || sales_order.date_estimated_mission_complete).format(
        'MM/DD/YYYY'
      );

    switch (sales_order.status) {
      case SORDStatuses.QAComplete: {
        const afterQADate = dayjs(addBusinessDaysToDate(sales_order.date_qa_complete, 3));
        if (afterQADate.isBefore(dayjs()))
          return dayjs(addBusinessDaysToDate(dayjs(), 1))
            .utc()
            .format('MM/DD/YYYY');
        return afterQADate.utc().format('MM/DD/YYYY');
      }
      default:
        return dayjs(
          sales_order.date_estimated_mission_complete ||
            sales_order.date_estimated_ship ||
            sales_order.date_ready_to_ship
        )
          .utc()
          .format('MM/DD/YYYY');
    }
  }

  if (isEnterpriseFlowActive) return 'See SwagUp Billing';

  const turnaroundTimes = order.products.map(p => p.turnaround_time);
  // const { invoice } = order;

  const minTime = Math.min(...turnaroundTimes);

  return dayjs(addBusinessDaysToDate(dayjs(), minTime))
    .utc()
    .format('MM/DD/YYYY');

  // let finalDate = dayjs();
  // if (invoice.status === invoiceStatus.paid) {
  //   const requiredDate = invoice.paid_date || invoice.due_date;
  //   if (requiredDate) {
  //     const invoiceDate = dayjs(requiredDate);
  //     finalDate = dayjs(addBusinessDaysToDate(invoiceDate, minTime)).format('MM/DD/YYYY');
  //   }

  //   const invoiceCreated = dayjs(invoice.created_at);

  //   const invoiceDate = addBusinessDaysToDate(invoiceCreated, 7);
  //   finalDate = dayjs(addBusinessDaysToDate(invoiceDate, minTime)).format('MM/DD/YYYY');
  // }
  // const invoiceDate = dayjs();
  // finalDate = dayjs(addBusinessDaysToDate(invoiceDate, minTime)).format('MM/DD/YYYY');

  // if (dayjs(finalDate).isBefore(dayjs().add(1, 'day')));
  // finalDate = dayjs()
  //   .add(1, 'day')
  //   .format('MM/DD/YYYY');
  // return finalDate;
};

const orderBeCompletedText = (invoice, isEnterpriseFlowActive) => {
  if (isEnterpriseFlowActive) return 'Keep an eye out for an invoice from SwagUp Billing.';

  const invoiceSalesOrders = invoice.orders?.filter(o => !isEmpty(o.sales_order)) || [];
  if (invoiceSalesOrders.length > 0) {
    const minResultDate = invoiceSalesOrders.reduce(
      (minDate, o) =>
        dayjs(estimatedShippingDate(o, isEnterpriseFlowActive)).isBefore(minDate)
          ? minDate
          : estimatedShippingDate(o, isEnterpriseFlowActive),
      estimatedShippingDate(invoiceSalesOrders[0], isEnterpriseFlowActive)
    );

    const maxResultDate = invoiceSalesOrders.reduce(
      (maxDate, o) =>
        dayjs(estimatedShippingDate(o, isEnterpriseFlowActive)).isAfter(maxDate)
          ? maxDate
          : estimatedShippingDate(o, isEnterpriseFlowActive),
      estimatedShippingDate(invoiceSalesOrders[0], isEnterpriseFlowActive)
    );

    const minWeek = toWeek(minResultDate);
    const maxWeek = toWeek(maxResultDate);

    if (minWeek === maxWeek) return `Your order is estimated to be ready to ship the week of ${minWeek}.`;

    return `Your order is estimated to be ready to ship between the weeks of ${minWeek} - ${maxWeek}.`;
  }

  const turnaroundTimes = invoice.products.map(p => p.turnaround_time);
  const minTime = Math.min(...turnaroundTimes);
  const maxTime = Math.max(...turnaroundTimes);
  const invoiceCreated = dayjs(invoice.created_at);
  const date =
    invoice.status === invoiceStatus.paid
      ? invoice.paid_date || invoice.due_date || addBusinessDaysToDate(invoiceCreated, 7)
      : undefined;
  const invoiceDate = dayjs(date);

  const minWeek = toWeek(addBusinessDaysToDate(invoiceDate, minTime));
  const maxWeek = toWeek(addBusinessDaysToDate(invoiceDate, maxTime));

  if (minWeek === maxWeek) return `Your order is estimated to be ready to ship the week of ${minWeek}.`;

  return `Your order is estimated to be ready to ship between the weeks of ${minWeek} - ${maxWeek}.`;
};

const isDomesticShipping = employee => employee.shipping_country.toLowerCase() === 'us';
const isInternationalShipping = employee => !isDomesticShipping(employee);

const totalStorageShippingPrice = (qty, price, isItAPack) => (isItAPack ? qty * price : price);
const recalculateRecommendedCredit = (
  currentShippingPrice,
  warehouseProof,
  individualPackPrices,
  bulkShippingPrice = 5
) =>
  warehouseProof
    .map(({ proof, sizes }) => {
      const isItPack = isPack(proof.product.record_type) || proof.product.record_type === 2;

      const shippingPrice = isItPack ? individualPackPrices.us_price : bulkShippingPrice;
      const quantities = sumByQuantity(sizes);
      return totalStorageShippingPrice(quantities, Number(shippingPrice) || 0, isItPack);
    })
    .reduce((total1, total2) => total1 + total2, currentShippingPrice);

const zeroItemsPrices = accountProduct => ({
  ...accountProduct,
  items: accountProduct.items.map(item => ({
    ...item,
    product: { ...item.product, price: 0, rush_fee: 0 }
  }))
});

const buildProofSimply = ({ product, sizes }) => ({
  id: product.id,
  product: zeroItemsPrices(product),
  sizes,
  production_time: productionTime.standard,
  price: product.price, // accountProduct.price, this one inside the AP is for 100 units, no use here
  rush_fee: 0,
  total: 0
});

const EditButton = ({ onClick, className, style, disabled }) => {
  return (
    <IconButton aria-label="Edit" onClick={onClick} className={className} style={style} disabled={disabled}>
      <EditOutlinedIcon disabled />
    </IconButton>
  );
};

const TotalRefundToSwagCard = () => {
  return (
    <Grid style={{ display: 'flex' }} alignItems="center">
      <Typography variant="body3MediumInter" style={{ color: '#0B1829' }}>
        Total Refund (Swag Card balance)
      </Typography>
      <img srcSet={imageSrcSet('/images/swag-card/swag-card.png')} alt="swag-card" width={40} height="auto" />
    </Grid>
  );
};

const Exception = {
  currently: {
    title: 'Exception',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: {} // 'Who is next? - Im assuming QA Complete'
};

const Canceled = {
  currently: {
    title: 'Canceled',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: {} // 'Who is next? - Im assuming QA Complete'
};

const clientApprovedDelay = {
  currently: {
    title: 'Client Approved Delay',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: {} // 'Who is next? - Im assuming QA Complete'
};

const missionComplete = {
  currently: {
    title: 'Mission Complete',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: {}
};

const readyToPack = {
  currently: {
    title: 'Ready to Pack',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: missionComplete.currently // 'Who is next? - Im assuming Mission Complete'
};

const QAComplete = {
  currently: {
    title: 'QA Complete',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: readyToPack.currently // 'Who is next? - Im assuming Ready to Pack'
};

const POComplete = {
  currently: {
    title: 'PO Complete',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: QAComplete.currently // 'Who is next? - Im assuming: QA Complete'
};

const partialPO = {
  currently: {
    title: 'Partial PO',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: POComplete.currently // 'Who is next? - Im assuming PO Complete'
};

const pendingPO = {
  currently: {
    title: 'Pending PO',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: partialPO.currently // 'Who is next? - Im assuming Partial PO'
};

const pendingFinalCheck = {
  currently: {
    title: 'Pending Final Check',
    description: 'Short explanation text in present tense.',
    futureDescription: 'Short explanation text in present tense.'
  },
  next: pendingPO.currently // 'Who is next? - Im assuming Pending PO'
};

const salesOrderStatuses = {
  pendingFinalCheck,
  pendingPO,
  partialPO,
  POComplete,
  QAComplete,
  readyToPack,
  missionComplete,
  clientApprovedDelay,
  Canceled,
  Exception
};

const canceled = {
  currently: {
    title: 'Canceled',
    description: 'The order was canceled.',
    futureDescription: 'The order will be canceled.'
  },
  next: {},
  completed: true,
  canceled: true
};

const completed = {
  currently: {
    title: 'Completed',
    description:
      'The production order has been successfully completed. The requested products have been shipped to recipients or safely stored in our facility, ready for future use.',
    futureDescription:
      'The production order will be successfully completed. The requested products will be shipped to recipients or safely stored in our facility, ready for future use.'
  },
  next: {},
  completed: true
};

const shippingSwag = {
  currently: {
    title: 'Ready for shipping',
    description:
      'The order is now prepared for shipping. It has passed all quality checks and is packed and ready to go to recipients.',
    futureDescription:
      'The order now prepared for shipping. It has passed all quality checks and is packed and ready to go to recipients.'
  },
  next: completed.currently
};

const qualityCheck = {
  currently: {
    title: 'Quality Check',
    description:
      'Your order is going through a thorough quality check. Our team is ensuring quality and specs are met.',
    futureDescription:
      'Your order will go through a thorough quality check. Our team will ensure quality and specs are met.'
  },
  next: completed.currently
};

const inProduction = {
  currently: {
    title: 'In Production',
    description:
      'The production process is currently underway. Our team is working hard to manufacture the product according to the order specifications.',
    futureDescription:
      'The production process will be underway. Our team will work hard to manufacture the product according to the order specifications.'
  },
  next: qualityCheck.currently
};

const orderPlaced = {
  currently: {
    title: 'Order Placed',
    description:
      'The order has been placed and is awaiting further processing. It will enter the production phase shortly.',
    futureDescription:
      'The order will be placed and will await further processing. It will enter the production phase shortly.'
  },
  next: inProduction.currently
};

const orderStatuses = {
  orderPlaced,
  inProduction,
  qualityCheck,
  shippingSwag,
  completed,
  canceled
};

const errorParser = data => {
  if (isString(data)) return data;
  const keys = Object.keys(data || {});
  let errorMessage = '';
  let index = 0;
  while (!errorMessage && index < keys.length) {
    const value = data[keys[index]];
    errorMessage = isArray(value) ? value.find(v => errorParser(v)) : errorParser(value);
    if (errorMessage) {
      errorMessage = `${keys[index].split('_').join(' ')}: ${errorMessage}`;
    }
    index += 1;
  }
  return errorMessage;
};

export {
  Header,
  OrderProduct,
  InvoiceProduct,
  SwagCard,
  DefaultPaymentProfile,
  ACHPostPayment,
  ToogleButtom,
  WireTransfer,
  StyledTab,
  StyledTabs,
  SkipStep,
  PreviousStep,
  buildShippingOrderProofsOrAP,
  getShipmentGroupsInfo,
  orderBeCompletedText,
  estimatedShippingDate,
  SwagCardModal,
  isDomesticShipping,
  isInternationalShipping,
  recalculateRecommendedCredit,
  buildProofSimply,
  EditButton,
  orderStatuses,
  errorParser,
  TotalRefundToSwagCard,
  AddNewCreditCard,
  SORDStatuses
};
