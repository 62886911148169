import React, { useState, useMemo, useEffect } from 'react';
import { withStyles, Grid, Paper, Button } from '@material-ui/core';
import round from 'lodash/round';
import useMutationObserver from '../../hooks/useMutationObserver';
import CreditsSwitch from '../global/sendSwagReviewCommons/CreditsSwitch';
import PaymentSection from '../global/sendSwagReviewCommons/PaymentSection';
import ShipmentSection from '../global/sendSwagReviewCommons/ShipmentSection';
import PaymentProfilesModal from '../global/sendSwagReviewCommons/PaymentProfilesModal';
import ErrorAlert from '../shared/ErrorAlert';
import { useIsAchPrepaid } from '../../hooks';
import { canSendSwag } from '../../helpers/utils';
import styles from './styles/ShippingSummary';

const ShippingSummary = ({
  classes,
  company,
  balance,
  paymentProfiles,
  domesticData,
  internationalData,
  shippingNotes,
  totalItems,
  useCreditsFirst,
  onToggleUseCredits,
  setDefaultPaymentProfile,
  onShippingSummaryHeightChange,
  onSendSwag,
  errorMessage,
  onError
}) => {
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [{ clientHeight }, setObserved] = useMutationObserver();

  const isAchPrepaid = useIsAchPrepaid();

  useEffect(() => {
    onShippingSummaryHeightChange(clientHeight);
  }, [onShippingSummaryHeightChange, clientHeight]);

  const paymentProfile = useMemo(() => paymentProfiles.find(pm => pm.default), [paymentProfiles]);

  const handleSelectDefaultPaymentProfile = paymentProfileId => {
    setDefaultPaymentProfile(company.id, paymentProfileId, true);
    setShowPaymentsModal(false);
  };

  const { allow_negative_credit_balance: allowNegativeCreditBalance } = company;

  const total = round(domesticData.totalPrice + internationalData.totalPrice, 2);
  return (
    <>
      <Grid container alignItems="flex-end" justifyContent="space-between" style={{ marginBottom: 16 }}>
        <p className={classes.subTitle}>Shipping summary</p>
        <CreditsSwitch
          balance={balance}
          useCreditsFirst={useCreditsFirst}
          onToggleUseCredits={onToggleUseCredits}
          disabled={balance <= 0 && !allowNegativeCreditBalance}
        />
      </Grid>
      <Paper elevation={0} className={classes.card} ref={setObserved}>
        {domesticData.employeesQty !== 0 && (
          <Grid container className={classes.cardSection}>
            <ShipmentSection
              title="DOMESTIC"
              deliveryMethod={domesticData.deliveryMethod}
              shippingNotes={shippingNotes}
              shippingDate={domesticData.shippingDate}
              employeesQty={domesticData.employeesQty}
              totalPrice={domesticData.totalPrice}
            />
          </Grid>
        )}
        {internationalData.employeesQty !== 0 && (
          <Grid container className={classes.cardSection}>
            <ShipmentSection
              title="INTERNATIONAL"
              deliveryMethod={internationalData.deliveryMethod}
              shippingNotes={shippingNotes}
              shippingDate={internationalData.shippingDate}
              employeesQty={internationalData.employeesQty}
              totalPrice={internationalData.totalPrice}
            />
          </Grid>
        )}
        <Grid container className={classes.cardSection}>
          <PaymentSection
            useCreditsFirst={useCreditsFirst}
            allowNegativeCreditBalance={allowNegativeCreditBalance}
            paymentProfiles={paymentProfiles}
            totalItems={totalItems}
            credits={balance}
            total={total}
            onShowPaymentsProfiles={() => setShowPaymentsModal(true)}
          />
        </Grid>
      </Paper>
      <Grid container justifyContent="center" style={{ marginTop: 30 }}>
        {errorMessage && <ErrorAlert error={errorMessage} onError={onError} className={classes.errorAlert} />}
        <Button
          className={classes.sendButton}
          onClick={() => onSendSwag(total)}
          disabled={!canSendSwag(company, total, paymentProfiles, useCreditsFirst, isAchPrepaid)}
        >
          Send Swag
        </Button>
      </Grid>
      <PaymentProfilesModal
        open={showPaymentsModal}
        onClose={() => setShowPaymentsModal(false)}
        paymentProfile={paymentProfile}
        paymentProfiles={paymentProfiles}
        onSelectDefaultPaymentProfile={handleSelectDefaultPaymentProfile}
      />
    </>
  );
};

export default withStyles(styles)(ShippingSummary);
