import * as React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { Button, Typography, Tabs, Tab } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Alert, Link, CenteredGrid } from '../../shared';
import CompletedOrders from './completed/CompletedOrders';
import RequestedOrders from './requested/RequestedOrders';
import gtm from '../../../utils/gtm';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: 20
    }
  },
  content: {
    paddingBottom: 32,
    position: 'relative',
    minHeight: 'calc(100vh - 389px)'
  },
  tabs: {
    marginLeft: -24,
    '& .MuiTab-root': {
      minWidth: 80,
      padding: '6px 24px'
    }
  }
}));

const OrdersHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname, search, state } = useLocation();
  const handleCloseAlert = () => history.replace(pathname + search);
  const { leftBarNavigation } = useFlags();

  return (
    <CenteredGrid className={classes.root} style={{ paddingTop: leftBarNavigation ? 0 : 40 }}>
      {state?.message && (
        <Grid className={classes.alertContainer}>
          <Alert
            onClose={handleCloseAlert}
            style={{ paddingBottom: 20, width: 'auto', maxWidth: 430, justifyContent: 'center' }}
          >
            {state.message}
          </Alert>
        </Grid>
      )}

      <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 24 }}>
        {!leftBarNavigation && (
          <>
            <Grid item container alignItems="center" style={{ height: 56, width: 'min-content' }}>
              <Typography variant="h2BoldInter">Orders</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="primary"
                component={Link}
                to="/product-onboarding"
                onClick={() => gtm.onClick('Start new order')}
                style={{ width: 186, height: 56 }}
              >
                Start new order
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Tabs value={pathname === '/orders-completed' ? 1 : 0} className={classes.tabs}>
        <Tab label="Requested" component={Link} to="/orders-requested" />
        <Tab label="Placed" component={Link} to="/orders-completed" />
      </Tabs>
      <div className={clsx(classes.content, 'fade-translate')} key={pathname}>
        <Switch>
          <Route path="/orders-requested" exact>
            <RequestedOrders />
          </Route>
          <Route path="/orders-completed" exact>
            <CompletedOrders />
          </Route>
          <Redirect to="/orders-requested" />
        </Switch>
      </div>
    </CenteredGrid>
  );
};

export default OrdersHome;
