import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Typography } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import CloseButton from '../../../../modals/CloseButton';
import { imageSrcSet } from '../../../../../helpers/utils';
import api from '../../../../../apis/swagup/order';
import { greyRoundBorder } from '../../../../shared/styles/commonStyles';
import apiPaths from '../../../../../helpers/apiPaths';

const useStyles = makeStyles({
  paper: {
    minWidth: 780,
    padding: '10px 0',
    overflowY: 'visible'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 24,
    color: '#0b1829',
    textAlign: 'center'
  },
  subtitle: {
    maxWidth: '80%',
    marginTop: 12,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    color: '#787b80',
    textAlign: 'center'
  },
  productDetails: {
    ...greyRoundBorder,
    padding: 16,
    maxWidth: '50%',
    marginTop: 30
  },
  productName: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    color: '#0b1829'
  },
  productQty: {
    marginTop: 4,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#787b80'
  },
  keepButton: {
    width: 221,
    height: 56,
    padding: '6px 0'
  },
  removeButton: {
    marginTop: 10,
    width: 208,
    height: 56,
    color: '#f44336',
    backgroundColor: 'transparent',
    '&:hover': {
      color: '#f44336',
      backgroundColor: '#ffe7e6'
    },
    '&.MuiButton-contained.Mui-disabled': {
      color: '#f44336',
      backgroundColor: '#fff'
    }
  }
});

const RemoveShipmentGroupModal = ({ isOpen, onClose, group }) => {
  const classes = useStyles();

  const id = +useParams().id;
  const queryClient = useQueryClient();
  const mutation = useMutation(api.deleteShipmentGroup, {
    onSettled: async () => {
      queryClient.cancelQueries([apiPaths.shipmentGroups(id)]);
      await queryClient.invalidateQueries(apiPaths.shipmentGroups(id));
    }
  });

  const handleDelete = () => mutation.mutate({ opportunityId: id, shipmentGroupId: group.id });

  return (
    <Dialog classes={{ paper: classes.paper }} open={isOpen} onClose={onClose}>
      <DialogTitle aria-labelledby="remove-contacts-dialog" style={{ minHeight: 110 }}>
        <Grid container justifyContent="flex-end">
          <CloseButton onClose={onClose} />
        </Grid>
        <Grid container justifyContent="center" style={{ marginTop: -135 }}>
          <img srcSet={imageSrcSet('/images/orders/warning.png')} alt="Warning" width="150" height="136" />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <h2 className={classes.title}>Are you sure you want to delete this shipment group?</h2>
          <p className={classes.subtitle}>
            All shipments in this group will be canceled and items will be returned to your unassigned amount.
          </p>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column" alignItems="center" style={{ marginTop: 20 }}>
          <Button variant="primary" disabled={mutation.isLoading} onClick={onClose} className={classes.keepButton}>
            <Typography variant="button">Keep Shipment Group</Typography>
          </Button>
          <Button
            variant="alert"
            disabled={mutation.isLoading}
            onClick={handleDelete}
            className={classes.removeButton}
            loading={mutation.isLoading}
          >
            <Typography variant="button">Remove</Typography>
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveShipmentGroupModal;
