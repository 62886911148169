import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Img } from '../../global/ImgUtils';
import Separator from './Separator';
import commonStyles from './styles/common';

const useStyles = makeStyles({
  ...commonStyles,
  productImage: {
    width: 70,
    height: 70,
    marginRight: 32,
    borderRadius: 10,
    border: 'solid 1px #f0f2f5',
    backgroundColor: '#ffffff'
  },
  nameText: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    color: '#0f2440',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: '-6px 0 12px'
  }
});

const ShipmentOverview = ({ product, sizes, domesticData, internationalData }) => {
  const classes = useStyles();
  return (
    <>
      <p className={classes.columnTitle}>Shipment overview</p>
      <Grid container direction="column" className={classes.card}>
        {product && (
          <>
            <Grid container style={{ padding: 24 }}>
              <p className={classes.smallText}>Item</p>
              <Grid container style={{ marginTop: 22 }}>
                <Img src={product.image} alt={product.name} width={78} height={78} className={classes.productImage} />
                <Grid item style={{ flex: 1, minWidth: 0 }}>
                  <p className={classes.nameText}>{product.name}</p>
                  <Grid container alignItems="center" style={{ marginBottom: 9 }}>
                    <p className={classes.smallText}>
                      Color: <span className="dark">{product.theme_color || 'Custom'}</span>
                    </p>
                    <div className={classes.colorCircle} style={{ backgroundColor: product.theme_color_hex }} />
                  </Grid>
                  <p className={classes.smallText}>
                    Size{sizes.length > 1 ? 's' : ''}: <span className="dark">{sizes.join(', ')}</span>
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Separator />
          </>
        )}
        <Grid container style={{ padding: 24 }}>
          <Grid container justifyContent="space-between" style={{ margin: '4px 0' }}>
            <p className={classes.mediumText}>Domestic Shipments:</p>
            <p className={classes.mediumText}>{domesticData?.employeesQty || 0}</p>
          </Grid>
          <Grid container justifyContent="space-between" style={{ margin: '4px 0 12px' }}>
            <p className={classes.mediumText}>International Shipments:</p>
            <p className={classes.mediumText}>{internationalData?.employeesQty || 0}</p>
          </Grid>
          <Separator light />
          <Grid container justifyContent="space-between" style={{ marginTop: 12 }}>
            <p className={classes.mediumText}>Total:</p>
            <p className={classes.mediumText}>
              {(domesticData?.employeesQty || 0) + (internationalData?.employeesQty || 0)}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ShipmentOverview;
