import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { OrderProduct, ColorIcon } from './CommonOrderElements';
import { ImgWithHandler } from '../../../global/ImgUtils';
import { getSortedBySize } from '../../../../helpers/utils';

const commonSemiBoldText = { fontFamily: 'Gilroy-SemiBold', fontSize: 16 };
const commonMediumText = { fontFamily: 'Gilroy-Medium', fontSize: 12 };

const useStyles = makeStyles({
  productName: { ...commonSemiBoldText, color: '#0b1829' },
  sizesText: { ...commonSemiBoldText, color: '#ced1d6', marginRight: 15, marginBottom: 24 },
  recordTypeIcon: { ...commonMediumText, color: '#0b1829', marginLeft: 30 },
  productPrice: { ...commonMediumText, color: '#787b80', marginLeft: 8 },
  productImg: {
    width: 56,
    height: 56,
    objectFit: 'contain',
    borderRadius: 12,
    boxShadow: '0 10px 20px 0px rgba(0, 0, 0, 0.04)'
  },
  divider: {
    borderTop: '1px solid #ebedf0',
    borderBottom: 'none',
    width: '100%'
  }
});

const PackItem = ({ item, classes }) => (
  <Grid container style={{ marginBottom: 20 }}>
    <Grid item container xs={1}>
      <ImgWithHandler
        src={item.product.image}
        alt={item.product.name}
        width={78}
        height={78}
        className={classes.productImg}
      />
    </Grid>
    <Grid item container xs={11} alignItems="center" style={{ paddingLeft: 20 }}>
      <Grid item>
        <p className={classes.productName}>{item.product.name}</p>
      </Grid>
      <Grid item container alignItems="flex-start">
        <ColorIcon product={item.product} />
        <p className={classes.productPrice}>{`${item.units_per_pack} per pack`}</p>
      </Grid>
    </Grid>
  </Grid>
);

const Sizes = ({ sizes, classes }) => (
  <Grid container>
    {sizes.map(size => (
      <span key={size.size.id} className={classes.sizesText}>{`${size.size.name}: ${size.quantity}`}</span>
    ))}
  </Grid>
);

const OrderContent = ({ orderProduct, showReorderItem, showDivider }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <OrderProduct orderProduct={orderProduct} showReorderItem={showReorderItem} isOrderDetails />
      <Grid item container alignItems="flex-start" xs={12} style={{ paddingLeft: 80, marginTop: -10 }}>
        {!orderProduct.incompatible_sizes && <Sizes sizes={getSortedBySize(orderProduct.sizes)} classes={classes} />}
        {orderProduct.product.items?.map(item => (
          <PackItem key={item.product.id} item={item} classes={classes} />
        ))}
      </Grid>
      {showDivider && <hr className={classes.divider} />}
    </Grid>
  );
};

export default OrderContent;
