import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import ContactForm from '../pages/orders/requested/shipments/ContactForm';
import Drawer from '../pages/orders/requested/common/Drawer';

const useStyles = makeStyles({
  root: { zIndex: '1100 !important' },
  paper: {
    maxWidth: 594,
    padding: '35px 20px 35px 45px'
  }
});

const EditOrderRecipient = ({ open, onClose, handleSubmit, onSuccess, defaultValues }) => {
  const classes = useStyles();
  return (
    <Drawer open={open} onClose={onClose} classes={classes}>
      <ContactForm
        title="Edit Order Recipient"
        submitText="Save Changes"
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        defaultValues={defaultValues}
        minimal
      />
    </Drawer>
  );
};

export default EditOrderRecipient;
