import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button, Tab, Tabs, Typography } from '@swagup-com/components';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { TabContext, TabPanel } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { ArrowBack, InfoRounded } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import CenteredGrid from '../../shared/CenteredGrid';
import { integrationsApi } from '../../../apis/swagup';
import tags from '../../../apis/seoTags';
import { useProfile, useQueryParams } from '../../../hooks';
import AutomationItem from './AutomationItem';
import AutomationSelectionModal from './AutomationActionSelectionModal';
import { Helmet, Link } from '../../shared';
import useIntegrations from '../../../hooks/useIntegrations';
import apiPaths from '../../../helpers/apiPaths';
import { apiDataConverter, apiDataConverterCreate, integrationsConverter } from './common';
import IntegrationOrders from './IntegrationOrders';
import SpinningSnackBar from '../../storefronts/components/SpinningSnackBar/SpinningSnackBar';
import { toSentenceCase } from '../../account/MembershipCommon';
import {
  IntegrationTrialExpiredExtendedModal,
  IntegrationTrialWarningBanner,
  IntegrationTrialWarningModal
} from '../common';
import useIntegrationFreeTrial from '../hooks/useIntegrationFreeTrial';
import { SET_INTEGRATIONS_TRIAL_MODAL_CLOSE } from '../../../actions/types';

const styles = theme => ({
  container: {
    marginTop: 0,
    paddingTop: 40,
    paddingBottom: 42,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  goBackContainer: { position: 'absolute', marginTop: -22 },
  needSyncBox: {
    maxWidth: 448,
    background: '#EBF1FB',
    border: '1px solid #3577D4',
    borderRadius: 4,
    padding: '8px 16px 8px 16px'
  },
  infoIcon: {
    width: 16,
    height: 16,
    color: '#3577D4'
  }
});

const useStyles = makeStyles(styles);

const IntegrationFlowHome = () => {
  const [currentTab, setCurrentTab] = useState();
  const [isError, setIsError] = useState();
  const [isAutomationSetupOpen, setIsAutomationSetupOpen] = useState(false);
  const [selectedAutomation, setSelectedAutomation] = useState();

  const { id: platform } = useParams();
  const history = useHistory();
  const location = useLocation();

  const { getConnectedPlatform, onReconnect } = useIntegrations();

  const selectedPlatform = getConnectedPlatform(platform);

  const { data: triggersData, isLoading: isLoadingReact } = useQuery(
    [
      apiPaths.integrationTriggers(selectedPlatform?.connectionAccess?.access_token),
      selectedPlatform?.connectedPlatform
    ],
    () =>
      integrationsApi.fetchIntegrationTriggers(selectedPlatform?.connectionAccess?.access_token, () =>
        setIsError(true)
      ),
    {
      enabled: platform && !!selectedPlatform?.connectionAccess?.access_token,
      onerror: () => {
        setIsError(true);
      }
    }
  );

  const { data: employeeTypesApi, isLoading: isLoadingEmployeetypes } = useQuery(
    [apiPaths.integrationEmployeeTypes, selectedPlatform?.connectedPlatform],
    () => integrationsApi.fetchIntegrationEmployeeTypes({ store_id: selectedPlatform.id }, () => setIsError(true)),
    {
      enabled: platform && !!selectedPlatform?.connectionAccess?.access_token,
      onerror: () => {
        setIsError(true);
      }
    }
  );

  const employeeTypes = employeeTypesApi?.results;

  const currentIntegration = useCallback(() => integrationsConverter(triggersData?.results, platform), [triggersData]);

  const integration = currentIntegration();
  // const { data: integration, isLoading } = useQuery(`integration-${id}`, () => integrationsApi.get(id), {
  //   enabled: !!id
  // });

  const queryClient = useQueryClient();

  const onAPICallSucess = (data, params) => {
    const { onSuccessAction } = params || {};
    if (onSuccessAction) onSuccessAction();

    queryClient.invalidateQueries([
      apiPaths.integrationTriggers(selectedPlatform?.connectionAccess?.access_token),
      selectedPlatform?.connectedPlatform
    ]);
  };

  const query = useQueryParams();

  const tab = query.get('tab') || 'automations';

  const triggerCreate = useMutation(
    data => integrationsApi.createIntegrationTrigger(selectedPlatform?.connectionAccess?.access_token, data.data),
    {
      onSuccess: onAPICallSucess
    }
  );

  const actionUpdate = useMutation(
    data =>
      integrationsApi.updateIntegrationAction(
        selectedPlatform?.connectionAccess?.access_token,
        data.actionData,
        data.actionId
      ),
    {
      onSuccess: onAPICallSucess
    }
  );

  const triggerDisconnect = useMutation(
    automationId =>
      integrationsApi.disconnectIntegrationTrigger(selectedPlatform?.connectionAccess?.access_token, automationId),
    {
      onSuccess: onAPICallSucess
    }
  );

  const triggerDisconnectBulk = useMutation(
    automationIds =>
      integrationsApi.disconnectIntegrationTriggerBulk(selectedPlatform?.connectionAccess?.access_token, automationIds),
    {
      onSuccess: onAPICallSucess
    }
  );

  const triggerUpdate = useMutation(
    data =>
      integrationsApi.updateIntegrationTrigger(selectedPlatform?.connectionAccess?.access_token, data.params, data.id),
    {
      onSuccess: onAPICallSucess
    }
  );

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  const handleTabChange = (event, newValue) => {
    query.set('tab', newValue);
    history.replace({ ...location, search: query.toString() });
  };

  const onAutomationSetup = automation => {
    setSelectedAutomation(automation);
    setIsAutomationSetupOpen(true);
  };

  const onAutomationUpdate = automation => {
    triggerUpdate.mutate({ id: automation.id, params: automation });
  };

  const onAutomationsDisconnect = automation => {
    if (!isEmpty(automation.years)) triggerDisconnectBulk.mutate(automation.years.map(y => `pk=${y.id}`).join('&'));
    else triggerDisconnect.mutate(automation.id);
  };

  const onIntegrationUpdate = (data, onSuccessAction = () => true) => {
    const joinData = { ...selectedAutomation, ...data, store: selectedPlatform.id };

    if (data.edit) {
      const parsedData = apiDataConverter(joinData);
      // console.log('xxx: ', parsedData, joinData);
      const actionData = parsedData.actions[0];
      const actionId = joinData.action.id;
      // console.log('xxx: ',selectedAutomation.employment_type !== parsedData.employment_type, selectedAutomation.employment_type, parsedData.employment_type);
      if (selectedAutomation.employment_type !== parsedData.employment_type)
        onAutomationUpdate({ id: selectedAutomation.id, employment_type: parsedData.employment_type });
      actionUpdate.mutate({ actionId, actionData, onSuccessAction });
    } else {
      const parsedData = apiDataConverterCreate(joinData);
      triggerCreate.mutate({ data: parsedData, onSuccessAction });
    }
  };

  const dispatch = useDispatch();
  const integrationFreeTrialModalStatus = useSelector(state => state.integrationFreeTrialModalStatus);
  const openExpired =
    integrationFreeTrialModalStatus.isOpen ||
    integrationFreeTrialModalStatus.lastOpenedDate !== dayjs().format('MM/DD/YYYY');

  const handleCloseTrialModal = () => {
    dispatch({ type: SET_INTEGRATIONS_TRIAL_MODAL_CLOSE });
  };

  const integrationFreeTrial = useIntegrationFreeTrial(selectedPlatform);
  const profileApi = useProfile();
  const { data: profile } = profileApi;

  const classes = useStyles();

  const integrationBanner = () => {
    switch (true) {
      case integrationFreeTrial.showExpired:
        return (
          <IntegrationTrialExpiredExtendedModal
            integrationFreeTrial={integrationFreeTrial}
            profile={profile}
            open={openExpired}
            handleOnClose={handleCloseTrialModal}
          />
        );
      case integrationFreeTrial.showWarning:
        return openExpired ? (
          <IntegrationTrialWarningModal
            integrationFreeTrial={integrationFreeTrial}
            profile={profile}
            open={openExpired}
            handleOnClose={handleCloseTrialModal}
          />
        ) : (
          <IntegrationTrialWarningBanner integrationFreeTrial={integrationFreeTrial} profile={profile} />
        );
      case selectedPlatform?.connectionAccess?.isReady === false:
        return (
          <Box className={classes.needSyncBox}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <InfoRounded className={classes.infoIcon} />
              </Grid>
              <Grid item xs>
                <Typography variant="body4RegularInter">
                  We've successfully connected with your {toSentenceCase(integration?.name)} account. We're still
                  configuring the integration which may take up to 24 hours. In the meantime, start by setting up your
                  automations!
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet tags={tags.integrationsFlow(integration?.name)} />
      <CenteredGrid container justifyContent="center" className={classes.container}>
        <Grid container>
          <Grid item>
            <Box className={classes.goBackContainer}>
              <Link to="/integrations" style={{ marginBottom: 12 }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <ArrowBack style={{ fontSize: 16, marginTop: 2 }} />
                  </Grid>
                  <Grid item>
                    <Typography component="p" variant="body3RegularInter" style={{ fontSize: 12, color: '#3577D4' }}>
                      Go back
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ marginBottom: 24 }}>
          <Grid item>
            <Typography variant="h2BoldInter">{toSentenceCase(integration?.name)}</Typography>
          </Grid>
          <Grid item xs>
            <Grid container justifyContent="center">
              <Grid item>{integrationBanner()}</Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h2BoldInter" style={{ color: 'transparent' }}>
              {toSentenceCase(integration?.name)}
            </Typography>
            {/* <Button variant="primary" style={{ minWidth: 180, height: 56 }}>
              Create an Automation
            </Button> */}
          </Grid>
        </Grid>
        <TabContext value={currentTab} style={{ width: '100%' }}>
          <Grid container>
            <Grid item={12} style={{ marginBottom: 12 }}>
              <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="#3577D4">
                <Tab label="Automations" value="automations" />
                <Tab label="Actions" value="actions" />
              </Tabs>
            </Grid>
          </Grid>
          <TabPanel value="automations" style={{ width: '100%' }}>
            <Typography variant="body3MediumInter" style={{ marginBottom: 16 }}>
              Trigger an automation when...
            </Typography>
            {integration?.automations.map(automation => (
              <AutomationItem
                key={automation.id}
                automation={automation}
                onSetup={a => onAutomationSetup(a)}
                onDisconnect={a => onAutomationsDisconnect(a)}
                onToggleActivate={onAutomationUpdate}
                disabled={integrationFreeTrial.showExpired || !selectedPlatform?.connected}
              />
            ))}
          </TabPanel>
          <TabPanel value="actions" style={{ width: '100%' }}>
            <IntegrationOrders selectedPlatform={selectedPlatform} disabled={integrationFreeTrial.showExpired} />
          </TabPanel>
        </TabContext>
        <AutomationSelectionModal
          isAutomationSetupOpen={isAutomationSetupOpen}
          automation={selectedAutomation}
          integrationName={integration?.name}
          setIsAutomationSetupOpen={setIsAutomationSetupOpen}
          onIntegrationUpdate={onIntegrationUpdate}
          onAutomationUpdate={onAutomationUpdate}
          onDisconnect={onAutomationsDisconnect}
          isLoading={triggerCreate.isLoading || actionUpdate.isLoading}
          selectedPlatform={selectedPlatform}
          employeeTypes={employeeTypes}
        />
        <SpinningSnackBar
          open={
            triggerCreate.isLoading ||
            actionUpdate.isLoading ||
            triggerDisconnect.isLoading ||
            triggerDisconnectBulk.isLoading ||
            triggerUpdate.isLoading
          }
          message="Loading..."
        />
      </CenteredGrid>
    </>
  );
};

export default IntegrationFlowHome;
