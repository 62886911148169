import partition from 'lodash/partition';
import sum from 'lodash/sum';
import {
  CREATE_REORDER_PRODUCTS,
  SET_REORDER_PRODUCTS_DATA,
  TOGGLE_REORDER_PRODUCT_DETAILS,
  REMOVE_REORDER_PRODUCTS
} from './types';
import DashBoard from '../apis/DashBoard';
import apiPaths from '../helpers/apiPaths';
import { productionTime } from '../apis/constants';
import {
  buildSizesApiCallData,
  buildIndexedSizes,
  buildUpdatedSizesQuantity,
  buildProductUpdatedItems
} from './actionsHelper';
import { okAndLog, errorAndLog } from '../helpers/utils';
import log from '../logger';

const sizesFromStock = stock =>
  stock.map(size => ({
    size: size.size,
    quantity: 0,
    active: size.active
    // price: not present in stock
  }));

const zeroItemsPrices = accountProduct => ({
  ...accountProduct,
  items: accountProduct.items.map(item => ({
    ...item,
    product: { ...item.product, price: 0, rush_fee: 0 }
  }))
});

const buildAccountProductPricesData = (reorderProducts, newSizes, newProdTimes) =>
  reorderProducts.map(p => ({
    product: p.product.id,
    sizes: buildSizesApiCallData(p.sizes, newSizes[p.id]).filter(s => s.quantity > 0),
    production_time: newProdTimes[p.id]
  }));

const buildProofAlikeObject = accountProduct => ({
  id: accountProduct.id,
  product: zeroItemsPrices(accountProduct),
  sizes: sizesFromStock(accountProduct.stock),
  production_time: productionTime.standard,
  price: 0, // accountProduct.price, this one inside the AP is for 100 units, no use here
  rush_fee: 0,
  total: 0
});

export const createReorderProducts = accountProducts => async dispatch =>
  dispatch({
    type: CREATE_REORDER_PRODUCTS,
    payload: accountProducts.map(buildProofAlikeObject)
  });

export const setReorderProducts = products => async dispatch =>
  dispatch({
    type: CREATE_REORDER_PRODUCTS,
    payload: products
  });

const getUpdatedSizes = (reorderProduct, ap) => {
  const sizesIndexed = buildIndexedSizes(ap.sizes);
  return reorderProduct.sizes.map(size => {
    const { id } = size.size;
    return sizesIndexed[id] === size.quantity || (!sizesIndexed[id] && size.quantity === 0)
      ? size
      : {
          ...size,
          quantity: sizesIndexed[id] || 0
        };
  });
};

const reorderProductDataCommon = (
  id,
  sizes,
  price,
  price_without_discount,
  rushFee,
  rush_fee_without_discount,
  prodTime,
  items
) => ({
  id,
  sizes,
  price,
  price_without_discount,
  rush_fee: rushFee,
  rush_fee_without_discount,
  production_time: prodTime,
  items
});

const buildProductsWithEnoughQty = (accountProducts, productsWithEnoughQty) =>
  accountProducts.map(ap => {
    const product = productsWithEnoughQty.find(p => p.id === ap.product);
    const sizes = getUpdatedSizes(product, ap);
    const items = buildProductUpdatedItems(product, ap);

    return reorderProductDataCommon(
      product.id,
      sizes,
      ap.price,
      ap.price_without_discount,
      ap.rush_fee,
      ap.rush_fee_without_discount,
      ap.production_time,
      items
    );
  });

const buildProductsWithItemsPricesZeroed = (reorderProducts, newSizes, newProdTimes) =>
  reorderProducts.map(rp => {
    const sizes = buildUpdatedSizesQuantity(rp.sizes, newSizes[rp.id]);
    const items = buildProductUpdatedItems(rp, null);

    return reorderProductDataCommon(rp.id, sizes, 0, 0, 0, 0, newProdTimes[rp.id], items);
  });

const updateReorderProducts = payload => ({ type: SET_REORDER_PRODUCTS_DATA, payload });

export const setReorderProductsData = (reorderProducts, newSizes, newProdTimes) => async dispatch => {
  log.debug('setReorderProductsData Action - Entering');
  log.debug('reorder prods:', reorderProducts, 'new sizes:', newSizes, 'prodTimes:', newProdTimes);

  const [productsWithLessThanMin, productsWithEnoughQty] = partition(
    reorderProducts,
    p => sum(Object.values(newSizes[p.id])) < Math.max(1, p.product.minimum_to_reorder)
  );

  if (productsWithEnoughQty.length === 0) {
    const productsZeroPrice = buildProductsWithItemsPricesZeroed(reorderProducts, newSizes, newProdTimes);
    dispatch(updateReorderProducts(productsZeroPrice));
    return okAndLog('setReorderProductsData', 'success', 'all qty below than minimum_to_reorder');
  }

  try {
    const apiCall = await DashBoard.post(apiPaths.accountProductPrices, {
      account_products: buildAccountProductPricesData(productsWithEnoughQty, newSizes, newProdTimes)
    });

    if (apiCall.status === 201) {
      const accountProducts = apiCall.data.account_products;

      if (accountProducts.length > 0) {
        const productsWithPrice = buildProductsWithEnoughQty(accountProducts, productsWithEnoughQty);
        const productsZeroPrice = buildProductsWithItemsPricesZeroed(productsWithLessThanMin, newSizes, newProdTimes);
        dispatch(updateReorderProducts([...productsWithPrice, ...productsZeroPrice]));
      } else {
        // there is a product config error when account_products === []
        const productsZeroPrice = buildProductsWithItemsPricesZeroed(reorderProducts, newSizes, newProdTimes);
        dispatch(updateReorderProducts(productsZeroPrice));
      }
      return okAndLog('setReorderProductsData', apiCall.status, apiCall.data);
    }
    return errorAndLog('setReorderProductsData', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('setReorderProductsData', e.status, e.data);
  }
};

export const toggleReorderProductDetails = id => async dispatch =>
  dispatch({
    type: TOGGLE_REORDER_PRODUCT_DETAILS,
    payload: id
  });

export const removeReorderProducts = ids => async dispatch =>
  dispatch({
    type: REMOVE_REORDER_PRODUCTS,
    payload: ids
  });
