import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Alert } from '@material-ui/lab';
import { useStripe } from '@stripe/react-stripe-js';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import stripeAPI from '../../apis/stripe';
import styles from './styles/membership';
import Loader from '../global/Loader';
import { CancelMembershipModal, Header } from './MembershipCommon';
import apiPaths from '../../helpers/apiPaths';
import { useMembership } from '../../contexts/membershipContext';
import MembershipManagement from './MembershipManagement';
import MembershipListPanel from './MembershipListPanel';
import MembershipCancellation from './MembershipCancellation';
import YourMembership from './YourMembership';
import MembershipChange from './MembershipChange';

const useStyles = makeStyles(styles);

const Membership = () => {
  const [selectedMembership, setSelectedMembership] = useState();
  const [currentStep, setCurrentStep] = React.useState(1);
  const [showWhatsNext, setShowWhatsNext] = React.useState(false);
  const [selectedItemId, setSelectedItemId] = React.useState();
  const [cancelMembershipModalOpen, setCancelMembershipModalOpen] = React.useState(false);
  const [infoMessage, setInfoMessage] = React.useState();
  const { leftBarNavigation } = useFlags();
  const stripe = useStripe();
  const location = useLocation();
  const history = useHistory();
  const qClient = useQueryClient();

  const { data: proratedAmountApi, isLoading: isProratedLoading } = useQuery(
    apiPaths.proratedAmount,
    () => stripeAPI.getProratedAmount({ newPlanId: selectedMembership?.external_id }),
    {
      enabled: !!(selectedMembership && location.pathname === '/membership/change-membership')
    }
  );

  const proratedAmount = proratedAmountApi?.prorated_amount || 0;
  const monthlyPayment = proratedAmountApi?.monthly_payment || 0;
  const endOfBillingCycle = proratedAmountApi?.billing_cycle_end || dayjs().format('MM/DD/YYYY');

  const {
    isLoading,
    company,
    memberships,
    currentMembership,
    setCurrentMembership,
    setLoadingMembership,
    currentEmail,
    currentAccount
  } = useMembership();
  const queryClient = useQueryClient();

  const updateSubscription = useMutation(params => stripeAPI.updateSubscription(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(apiPaths.accounts);
      queryClient.invalidateQueries(apiPaths.profiles);
      setCurrentMembership(selectedMembership);

      return history.push(
        `/membership/manage-membership-confirmation?membership=${selectedMembership.id}&prorated_amount=${proratedAmount}`
      );
    },
    onError: () => setInfoMessage('You new plan update failed. Please contact your AE.')
  });

  useEffect(() => {
    if (currentStep === 4) {
      setTimeout(() => setShowWhatsNext(true), 100);
    } else setShowWhatsNext(false);
  }, [currentStep]);

  const handleManage = useCallback(() => {
    history.push('/membership/manage-membership');
  }, [history]);

  const handleYourMembership = () => {
    history.push('/membership/manage-membership');
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/membership/your-membership':
        setCurrentStep(1);
        break;
      case '/settings/your-membership':
        setCurrentStep(1);
        break;
      case '/membership/manage-membership':
        setCurrentStep(2);
        break;
      case '/membership/manage-membership-switch':
        setCurrentStep(3);
        break;
      case '/membership/change-membership':
        if (selectedMembership) setCurrentStep(4);
        else handleManage();
        break;
      case '/membership/manage-membership-cancel':
        setCurrentStep(5);
        break;
      default:
        setCurrentStep(1);
        break;
    }
  }, [handleManage, location, selectedMembership]);

  const handleSubscribeFromScratch = async priceId => {
    await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: 'subscription',
      clientReferenceId: `${currentAccount}`, // Pass the Account ID as client reference ID
      customerEmail: currentEmail,
      successUrl: `${process.env.REACT_APP_PUBLIC_URL}membership/manage-membership-confirmation?membership=${selectedMembership.id}&prorated_amount=${proratedAmount}`,
      cancelUrl: `${process.env.REACT_APP_PUBLIC_URL}${
        leftBarNavigation ? 'settings/your-membership' : 'membership/your-membership'
      }`
    });
  };

  const handleSubscribe = async value => {
    if (currentMembership.isFreeTier) {
      handleSubscribeFromScratch(value);
      return 0;
    }
    await updateSubscription.mutate({
      paymentMethodId: value || 'none',
      newPlanId: selectedMembership.external_id,
      amount: proratedAmount
    });

    return 0;
  };

  const handleCancelSubscription = async (subsId, reason) => {
    // Pass the subscription ID to the cancel subscription method
    setCancelMembershipModalOpen(false);
    setLoadingMembership(true);
    try {
      await stripeAPI.cancelSubscription(subsId, reason);

      setTimeout(() => {
        const basicMembership = memberships.find(m => m.isFreeTier);
        setCurrentMembership(basicMembership);
        qClient.invalidateQueries(apiPaths.accounts);
        qClient.invalidateQueries(apiPaths.profiles);
        setLoadingMembership(false);
      }, 2000);
    } catch {
      setTimeout(() => {
        qClient.invalidateQueries(apiPaths.accounts);
        qClient.invalidateQueries(apiPaths.profiles);
        setLoadingMembership(false);
        history.push(
          leftBarNavigation
            ? '/settings/your-membership?error=AlreadyCanceled'
            : '/membership/your-membership?error=AlreadyCanceled'
        );
      }, 2000);
    }
  };
  const handleToastClose = () => {
    setInfoMessage();
  };

  const classes = useStyles();
  return (
    <>
      {isLoading || updateSubscription.isLoading ? (
        <Loader absolute />
      ) : (
        <Box className={leftBarNavigation ? classes.root : classes.newRoot}>
          {infoMessage && (
            <Grid container justifyContent="center" style={{ position: 'relative' }}>
              <Alert
                onClose={handleToastClose}
                delayTime={5000}
                className={classes.floatingInfoMessage}
                fontStyles={{ fontSize: 12, padding: 0 }}
                severity="error"
              >
                {infoMessage}
              </Alert>
            </Grid>
          )}
          <SwipeableViews
            axis="x"
            index={currentStep - 1}
            className={classes.swipeableViews}
            disabled
            style={{ paddingTop: '10px' }}
          >
            <Grid container className={classes.membershipStep} style={{ height: 'calc(100% - 82px)' }}>
              {!leftBarNavigation ? (
                <Header
                  isFreeTier={currentMembership?.default_price?.unit_amount === 0}
                  title="Your Membership"
                  actionText="Manage membership"
                  onAction={handleManage}
                />
              ) : null}
              <YourMembership />
            </Grid>
            <Box className={classes.membershipStep}>
              <Header title="Manage Membership" actionText="Your Membership" />
              <MembershipManagement currentMembership={currentMembership} />
            </Box>
            <Box className={classes.membershipStep} style={leftBarNavigation ? { paddingTop: '10px' } : {}}>
              {!leftBarNavigation ? (
                <Header title="Manage Membership" actionText="Your Membership" onAction={handleYourMembership} />
              ) : null}
              <MembershipListPanel
                memberships={memberships}
                currentMembership={currentMembership}
                selectedItemId={selectedItemId}
                setSelectedMembership={setSelectedMembership}
                setCancelMembershipModalOpen={setCancelMembershipModalOpen}
                setSelectedItemId={setSelectedItemId}
                selectedMembership={selectedMembership}
              />
            </Box>
            <Box className={classes.membershipStep}>
              <MembershipChange
                currentMembership={currentMembership}
                isProratedLoading={isProratedLoading}
                proratedAmount={proratedAmount}
                monthlyPayment={monthlyPayment}
                endOfBillingCycle={endOfBillingCycle}
                selectedMembership={selectedMembership}
                showWhatsNext={showWhatsNext}
                handleSubscribe={handleSubscribe}
                handleManage={handleManage}
                company={company}
              />
            </Box>

            <Box className={classes.membershipStep}>
              <Header title="Manage Membership" actionText="Your Membership" />
              <MembershipCancellation
                currentMembership={currentMembership}
                basicMembership={memberships.find(m => m.isFreeTier)}
                onAccept={reason => handleCancelSubscription(company.subscription_external_id, reason)}
                company={company}
              />
            </Box>
          </SwipeableViews>
          <CancelMembershipModal
            open={cancelMembershipModalOpen}
            onClose={() => setCancelMembershipModalOpen(false)}
            onAccept={() => handleCancelSubscription(company.subscription_external_id)}
          />
        </Box>
      )}
    </>
  );
};

export default Membership;
