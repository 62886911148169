export default () => ({
  cardSectionTitle: {
    fontFamily: 'Gilroy-SemiBold',
    color: '#434d5c',
    fontSize: 14
  },
  subTitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#8d9299',
    marginBottom: 12
  },
  commonSubTitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#8d9299',
    marginBottom: 10
  },
  deliveryMethod: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    color: '#0f2440',
    padding: '5px 8px',
    border: 'solid 1px #8d9299',
    borderRadius: 6,
    marginLeft: 12
  },
  shippingNotes: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    color: '#434d5c',
    lineHeight: 1.57,
    whiteSpace: 'pre-wrap'
  },
  shippingValues: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 14,
    color: '#0f2440'
  }
});
