import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import NativeIntegrationsHome from './NativeIntegrationsHome';
import IntegrationsOrdersHome from './IntegrationsOrdersHome';

const SwagUpIntegrationsHome = () => {
  return (
    <>
      <Switch>
        <Route path="/integrations" exact>
          <NativeIntegrationsHome />
        </Route>
        <Route path="/integrations/orders" exact>
          <IntegrationsOrdersHome />
        </Route>
        <Redirect to="/integrations" />
      </Switch>
    </>
  );
};

export default SwagUpIntegrationsHome;
