import React from 'react';
import '../../styles/Preset.css';
import { Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { usePresetPacks } from '../../hooks';
import { Helmet, CenteredGrid } from '../shared';
import Breadcrumbs from '../shared/Breadcrumbs';
import Loader from '../global/Loader';
import CategoryItems from './CategoryItems';
import PackOptions from './PackOptions';
import styles from './styles/starterPack';

const useStyles = makeStyles(styles);

const StarterPack = () => {
  const classes = useStyles();
  const { slug } = useParams();

  const { data: selectedPack } = usePresetPacks({ slug });

  if (!selectedPack) return <Loader />;

  const breadCrumbLinks = [
    { title: 'Home', to: '/', absolute: true },
    { title: 'Select Pack', to: '/preset-packs' },
    { title: selectedPack.name || '' }
  ];

  const tags = {
    title: `SwagUp - ${selectedPack.name}`,
    description: selectedPack.description,
    'og:image': selectedPack.img
  };

  return (
    <CenteredGrid container item justifyContent="center" className={classes.root}>
      <Helmet tags={tags} />
      <Grid className={classes.breadcrumbContainer}>
        <Breadcrumbs links={breadCrumbLinks} />
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={5}>
          <CategoryItems selectedPack={selectedPack} />
        </Grid>
        <Grid item xs={12} md={7} className={classes.packSidebar}>
          <PackOptions selectedPack={selectedPack} />
        </Grid>
      </Grid>
    </CenteredGrid>
  );
};

export default StarterPack;
