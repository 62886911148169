import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Modal,
  Radio,
  Tab
} from '@material-ui/core';
import debounce from 'lodash/debounce';
import { AlternateEmail, Close, FileCopyOutlined, Lock, Share } from '@material-ui/icons';
import { Button, TextField, Typography } from '@swagup-com/components';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useMutation } from 'react-query';
import { BlueSwitch } from '../account/AccountSharedComponents';
import { CustomTextField, getPageLink, getStatus, getStatusAllowed } from './redeemCommon';
import styles from './styles/redeemPageShareModal';
import { CustomTooltip } from '../products/commonProductsElements';
import { onlyNaturalNumberOrEmpty } from '../../helpers/utils';
import redeemServicesPaths from '../../helpers/redeemServicesPaths';
import { verifications } from '../../apis/redeemServices';

const useStyles = makeStyles(styles);

const CenteredBox = ({ children }) => (
  <Grid container justifyContent="center">
    <Grid item>{children}</Grid>
  </Grid>
);

const LinkOption = ({ title, description, selected, onSelect, classes, children, icon }) => (
  <Box className={selected ? classes.linkOptionBoxSelected : classes.linkOptionBox}>
    <Grid container alignItems="center">
      <Grid item>
        <Radio checked={!!selected} onChange={onSelect} className={classes.colorButton} style={{ color: '#3577D4' }} />
      </Grid>
      <Grid item xs>
        <Box className={classes.linkOptionBody}>
          <Grid container>
            <Grid item xs>
              <Typography variant="body2MediumInter" className={classes.linkOption}>
                {title}
              </Typography>
            </Grid>
            <Grid item>{icon}</Grid>
          </Grid>

          <Typography variant="body3RegularInter" className={classes.linkOptionDescription}>
            {description}
          </Typography>
          <Box className={classes.linkOptionContent}>{children}</Box>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

const ConfirmationModal = ({ open, featureName, classes, onClose, onConfirm }) => (
  <Modal open={open} onClose={onClose} className={classes.confirmModal}>
    <Box className={classes.shareModalContent}>
      <Grid container className={classes.confirmModalHeader} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Box className={classes.confirmModalMain}>
        <Box>
          <Typography variant="h6SemiBoldInter" className={classes.confirmModalTitle}>
            Are you sure you want to switch to {featureName} ?
          </Typography>
        </Box>
        <Box className={classes.confirmModalContent}>
          <Typography variant="body3RegularInter" className={classes.confirmModalText}>
            By switching the URL type, your links will no longer be redeemable. Future recipients will need to use the{' '}
            {featureName} to redeem.
          </Typography>
        </Box>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={4}>
          <Grid item>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button size="small" variant="primary" onClick={onConfirm} style={{ height: 40, width: 94 }}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Modal>
);

const RedeemPageShareModal = ({ open, page = {}, onClose, onSave, isSaving }) => {
  const [tabKey, setTabKey] = useState('1');
  const [activated, setActvated] = useState(page.isActive);
  const [allowMultipleRedemptions, setAllowMultipleRedemptions] = useState(page.allowMultipleRedemptions);
  const [emailRestriction, setEmailRestriction] = useState(page.emailRestriction);
  const [uniqueUrl, setUniqueUrl] = useState(page.uniqueUrl);
  const [uniqueUrlNumber, setUniqueUrlNumber] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [copyNotification, setCopyNotification] = useState('Click to copy');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [emailRestrictionError, setEmailRestrictionError] = useState();

  const emailValidation = useMutation(
    [redeemServicesPaths.verifyEmail, page.emailRestriction],
    email => verifications.email({ email, redeemPageId: page.id, emailOnly: true }),
    {
      onSuccess: data => {
        if (emailRestriction)
          setEmailRestrictionError(data?.isValid === false ? 'Must use valid email domain' : undefined);
      }
    }
  );
  const emailValidatingQuery = debounce(email => emailValidation.mutate(email), 750);
  const prevOpenStatusRef = useRef();

  prevOpenStatusRef.current = open;

  const triggerNotifications = message => {
    setNotificationMessage(message);
    setTimeout(() => {
      setNotificationMessage();
    }, 3000);
  };

  const triggerCopyNotifications = message => {
    setCopyNotification(message);
    setTimeout(() => {
      setCopyNotification('Click to copy');
    }, 3000);
  };

  useEffect(() => {
    setActvated(page.isActive);
    setUniqueUrl(page.uniqueUrl);
    setUniqueUrlNumber(page.uniqueUrlNumber);
    if (prevOpenStatusRef.current) triggerNotifications('All changes saved!');
  }, [page]);

  useEffect(() => {
    setActvated(page.isActive);
  }, [open, page.isActive]);

  useEffect(() => {
    setUniqueUrl(page.uniqueUrl);
  }, [open, page.uniqueUrl]);

  useEffect(() => {
    setAllowMultipleRedemptions(page.allowMultipleRedemptions);
  }, [open, page.allowMultipleRedemptions]);

  useEffect(() => {
    setEmailRestriction(page.emailRestriction);
  }, [open, page.emailRestriction]);

  useEffect(() => {
    setUniqueUrlNumber(page.uniqueUrlNumber);
  }, [open, page.uniqueUrlNumber]);

  useEffect(() => {
    if (!uniqueUrl) setUniqueUrlNumber(0);
  }, [uniqueUrl]);

  useEffect(() => {
    onSave({ isActive: activated });
  }, [activated]);

  useEffect(() => {
    if (isSaving) triggerNotifications('Updating...');
  }, [isSaving]);

  useEffect(() => {
    triggerNotifications();
  }, [open]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(getPageLink(page));
    triggerCopyNotifications('Link copied to clipboard');
  };

  const handleClose = () => {
    triggerNotifications();
    onClose();
  };

  const handleTabChange = (event, newValue) => {
    setTabKey(newValue);
  };

  const disabledDone =
    isSaving ||
    emailRestrictionError ||
    emailValidation.isLoading ||
    (uniqueUrl && (uniqueUrlNumber < 0 || uniqueUrlNumber > 10000));

  const wereChangesMade =
    activated !== page.isActive ||
    uniqueUrl !== page.uniqueUrl ||
    uniqueUrlNumber > 0 ||
    allowMultipleRedemptions !== page.allowMultipleRedemptions ||
    emailRestriction !== page.emailRestriction;

  const standarCTAText = () => {
    if (!wereChangesMade) return 'Done';
    const regularChanges =
      uniqueUrl !== page.uniqueUrl ||
      uniqueUrlNumber > 0 ||
      allowMultipleRedemptions !== page.allowMultipleRedemptions ||
      emailRestriction !== page.emailRestriction;
    const generate = uniqueUrl && uniqueUrlNumber > 0;
    const genText = regularChanges && generate ? 'Generate URLs & Save' : 'Generate URLs';
    return generate ? genText : 'Save';
  };

  const handleOnChangeURLNumber = e => {
    e.target.value = onlyNaturalNumberOrEmpty(e.target.value);
    const qty = parseInt(e.target.value, 10) || 0;
    setUniqueUrlNumber(qty);
  };

  const onEmailRestrictionChange = async ({ target: { value } }) => {
    setEmailRestriction(value);
    if (value) emailValidatingQuery(`joseph@${value}`);
    else setTimeout(() => setEmailRestrictionError(), 750);
  };

  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.shareModal }}>
      <Box className={classes.shareModalContent}>
        <Grid container alignItems="center" className={classes.shareModalHeader}>
          <Grid item xs>
            <Typography variant="h6SemiBoldInter" className={classes.shareModalHeaderText}>
              Share {page.projectName}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="stretch" className={classes.shareModalMain}>
          <Grid item>
            <Box className={classes.statusBox}>
              <CenteredBox>
                <Box className={activated ? classes.shareBoxActivated : classes.shareBoxDeactivated}>
                  <Share className={classes.shareIcon} />
                </Box>
              </CenteredBox>
              <CenteredBox>
                <Box>
                  <Typography variant="body3RegularInter" className={classes.shareStatusLabel}>
                    Status
                  </Typography>
                  <Typography
                    variant="body3MediumInter"
                    className={activated ? classes.shareStatusActivated : classes.shareStatusDeactivated}
                  >
                    {getStatus(activated)}
                  </Typography>
                </Box>
              </CenteredBox>
              <CenteredBox>
                <Box>
                  <FormControlLabel
                    labelPlacement="start"
                    className={classes.blueSwitch}
                    control={
                      <BlueSwitch
                        disabled={isSaving}
                        checked={activated}
                        onChange={({ target: { checked } }) => setActvated(checked)}
                      />
                    }
                  />
                </Box>
              </CenteredBox>
              <CenteredBox>
                <Typography variant="body3MediumInter" className={classes.shareStage}>
                  {notificationMessage}
                </Typography>
              </CenteredBox>
            </Box>
          </Grid>
          <Grid item xs>
            <Box>
              <TabContext value={tabKey}>
                <Box className={classes.tabs}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Redemption link" value="1" className={classes.tab} />
                    <Tab label="Email Restrictions" value="2" className={classes.tab} />
                  </TabList>
                </Box>
                <TabPanel value="1" className={classes.tabPanel}>
                  <Box className={classes.panelBox}>
                    <Typography variant="body2MediumInter" className={classes.linkSectionText}>
                      Which type of URL would you like to use?
                    </Typography>
                    <Box>
                      <LinkOption
                        title="Standard URL"
                        description="Anyone with this link can view and submit a redemption"
                        selected={!uniqueUrl}
                        onSelect={() => setConfirmationModalOpen(true)}
                        classes={classes}
                      >
                        <TextField
                          value={getPageLink(page)}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <CustomTooltip title={copyNotification} disableHoverListener={uniqueUrl}>
                                  <IconButton className={classes.button} onClick={copyToClipboard} disabled={uniqueUrl}>
                                    <FileCopyOutlined className={classes.copyIcon} />
                                  </IconButton>
                                </CustomTooltip>
                              </InputAdornment>
                            ),
                            disabled: uniqueUrl,
                            readOnly: true,
                            className: classes.input
                          }}
                        />
                      </LinkOption>
                      <LinkOption
                        title="Unique URL"
                        description="Generate links that can only be redeemed once"
                        selected={uniqueUrl}
                        onSelect={() => setConfirmationModalOpen(true)}
                        classes={classes}
                        icon={<Lock className={classes.shareIcon} />}
                      >
                        <TextField
                          onChange={handleOnChangeURLNumber}
                          fullWidth
                          InputProps={{
                            placeholder: `Enter amount of URL's`,
                            className: classes.input,
                            disabled: !uniqueUrl,
                            readOnly: !uniqueUrl
                          }}
                        />
                      </LinkOption>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="2" className={classes.tabPanel}>
                  <Box className={classes.panelBox}>
                    <Typography variant="body2MediumInter" className={classes.linkSectionText}>
                      Redemptions Email Settings
                    </Typography>
                    <Box>
                      <Grid container>
                        <Grid item xs={10}>
                          <Grid container alignItems="center">
                            <Grid item>
                              <Box>
                                <Typography variant="body3RegularInter" className={classes.linkOption}>
                                  Allow multiple redemptions per email:
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs>
                              <Box style={{ paddingLeft: '24px' }}>
                                <FormControlLabel
                                  labelPlacement="start"
                                  className={classes.blueSwitch}
                                  control={
                                    <BlueSwitch
                                      disabled={isSaving}
                                      checked={allowMultipleRedemptions}
                                      onChange={({ target: { checked } }) => setAllowMultipleRedemptions(checked)}
                                    />
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid item>
                              <Box>
                                <Typography
                                  variant="body3MediumInter"
                                  className={
                                    allowMultipleRedemptions ? classes.allowDeactivated : classes.shareStatusActivated
                                  }
                                >
                                  {getStatusAllowed(allowMultipleRedemptions)}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={10} style={{ marginTop: 32 }}>
                          <Typography variant="body3RegularInter" className={classes.linkOption}>
                            Specify the domain restriction
                            <span style={{ marginLeft: '24px', fontSize: '10px', fontWeight: 'lighter', opacity: 0.8 }}>
                              No restriction if left blank
                            </span>
                          </Typography>
                          <CustomTextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AlternateEmail
                                    className={classes.linkOption}
                                    style={{ margin: 0, opacity: 0.8, color: '#131415' }}
                                  />
                                </InputAdornment>
                              )
                            }}
                            className={classes.inputText}
                            placeholder="company.com"
                            value={emailRestriction}
                            name="emailRestriction"
                            onChange={onEmailRestrictionChange}
                            fullWidth
                            // disabled={page.hasBeenPublished}
                            error={emailRestrictionError}
                            msg
                          />
                          {emailRestrictionError && (
                            <Typography variant="body4RegularInter" style={{ color: '#F44336', marginTop: 4 }}>
                              {emailRestrictionError}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems="center" justifyContent="flex-end" className={classes.footer}>
          <Grid item>
            <Button
              size="small"
              variant="primary"
              onClick={() =>
                wereChangesMade
                  ? onSave({
                      isActive: activated,
                      uniqueUrl,
                      uniqueUrlNumber,
                      allowMultipleRedemptions,
                      emailRestriction
                    })
                  : handleClose()
              }
              style={{ height: 48, width: wereChangesMade && uniqueUrl ? 156 : 92 }}
              disabled={disabledDone}
            >
              {isSaving ? 'Saving...' : standarCTAText()}
            </Button>
          </Grid>
        </Grid>
        <ConfirmationModal
          open={confirmationModalOpen}
          classes={classes}
          featureName={uniqueUrl ? '"Standard Link"' : '"Unique URL"'}
          onClose={() => setConfirmationModalOpen(false)}
          onConfirm={() => {
            setUniqueUrl(!uniqueUrl);
            setConfirmationModalOpen(false);
          }}
        />
      </Box>
    </Dialog>
  );
};

export default RedeemPageShareModal;
