import React, { useState, createContext, useMemo } from 'react';
import { Grid, withStyles } from '@material-ui/core';

import styles from './styles/NewLoader';

export const LoaderContext = createContext();
const { Provider } = LoaderContext;

const Loader = ({ classes, width, isFullScreen }) => (
  <div className={isFullScreen ? classes.fullScreenLoader : classes.customWidthLoader}>
    <img src="/images/public/loader.png" alt="Loader" style={{ width }} />
  </div>
);

const NewLoader = ({ children, classes, isSmall, isFullScreen = false }) => {
  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(() => ({ isLoading, setIsLoading }), [isLoading, setIsLoading]);

  return (
    <Provider value={value}>
      <Grid container>
        <Grid item xs={12}>
          {isLoading && <Loader classes={classes} width={isSmall ? 50 : 100} isFullScreen={isFullScreen} />}
          {children}
        </Grid>
      </Grid>
    </Provider>
  );
};
export default withStyles(styles)(NewLoader);
