import React from 'react';
import { Grid } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import useDiscountsAndRewards from '../../hooks/useDiscountsAndRewards';
import { moneyStr } from '../../helpers/utils';
import BenefitTab from './BenefitTab';

const AppliedMembershipPanel = ({
  total,
  fullWidth,
  className,
  style,
  type = 'shipping',
  splitted,
  size = 'normal',
  multipleTotals,
  fromPayment,
  addedFunds,
  swagCardPayment,
  exceeds = 0,
  onlyDiscount,
  fixedDiscount
}) => {
  const { appliedDiscount, appliedReward, currentMembership } = useDiscountsAndRewards(
    total,
    type,
    multipleTotals,
    fromPayment,
    addedFunds,
    swagCardPayment,
    exceeds
  );

  const { swagupMembershipPlans, earlyBirdPromotion } = useFlags();

  const handleDiscountText = value => (value && value > 0 ? `-${moneyStr(value)}` : '$0.00');

  return swagupMembershipPlans &&
    !isEmpty(currentMembership) &&
    (!currentMembership.isFreeTier || earlyBirdPromotion) ? (
    <div className={className} style={style}>
      <Grid container justifyContent="center">
        <Grid item xs={fullWidth ? 12 : 10} container>
          <Grid item xs={splitted ? 6 : 12}>
            <BenefitTab
              icon="/images/membership/rewardRocket.png"
              text="Membership Discounts"
              value={handleDiscountText(fixedDiscount || appliedDiscount)}
              size={size}
            />
          </Grid>
          {!onlyDiscount && (
            <Grid item xs={splitted ? 6 : 12}>
              <BenefitTab
                earlyBirdPromotion={earlyBirdPromotion ? currentMembership : undefined}
                icon="/images/membership/rewards.png"
                text={`Reward Points ${currentMembership.reward_points_rate}X`}
                value={appliedReward}
                type="reward"
                size={size}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  ) : null;
};

export default AppliedMembershipPanel;
