import React, { useEffect, useState, useCallback } from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';

import CustomSelectField from '../global/Select';
import styles from './styles/onboarding';

const useStyle = makeStyles(styles);

const SelectorField = ({
  valueFieldName,
  items,
  textValue = '',
  onChange,
  property,
  errors,
  placeholder,
  excludes
}) => {
  const classes = useStyle();
  const [currentValue, setCurrentValue] = useState('');
  const innerItems = items.filter(item => !excludes || !excludes.includes(item[valueFieldName]));

  const updateOnContained = useCallback(
    value => {
      if (value !== currentValue) {
        const selected = innerItems.find(item => item[valueFieldName] === value);
        if (selected) setCurrentValue(selected[valueFieldName]);
        else setCurrentValue('');
      }
    },
    [currentValue, innerItems, valueFieldName]
  );

  const handleOnSelect = ({ target: { value } }) => {
    onChange({
      target: { id: property, value }
    });
    updateOnContained(value);
  };

  useEffect(() => updateOnContained(textValue), [textValue, updateOnContained]);

  return (
    <CustomSelectField
      id={property}
      name={property}
      label={placeholder}
      value={currentValue}
      totalItems={innerItems.length}
      handleSelect={handleOnSelect}
      onBlur={handleOnSelect}
      error={errors[property]}
      fullWidth
      withTooltip
    >
      {innerItems.map(item => (
        <MenuItem
          key={item.name}
          value={item[valueFieldName]}
          title={item[valueFieldName]}
          className={classes.listItem}
        >
          {item.name}
        </MenuItem>
      ))}
    </CustomSelectField>
  );
};

export default SelectorField;
