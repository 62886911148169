export default theme => ({
  root: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 200,
    cursor: 'pointer'
  },
  divider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(12)
  },
  icon: {
    left: 5
  },
  SidebarMenu: {},
  CategoriesTitle: {},
  fullList: {},
  collectionButtonWrapper: {
    margin: '10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 200,
    maxWidth: 200
  },
  selectAllButton: {
    color: '#125CFF',
    paddingLeft: '10px',
    cursor: 'pointer',
    width: '100%'
  },
  resetButtonText: {
    color: '#4A4F54',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '16px'
  },
  collectionWrapper: {
    width: 200,
    maxWidth: 200,
    position: 'relative',
    top: 15,
    left: 10
  },
  collectionCTA: {
    height: 'fit-content',
    marginLeft: '5px',
    marginTop: '15px',
    padding: '0px'
  }
});
