import React, { useState } from 'react';
import { Box, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CheckCircle } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import styles from './styles/addNewCustomersModal';
import { Modal } from '../../shared/Modal';
import { CustomSlimTextField, CustomSlimTextFieldThin } from '../storeCommon';
import { moneyStr } from '../../../helpers/utils';
import { StylessButton } from '../../buttons';
import { Img } from '../../global/ImgUtils';
import { GreenAntSwitch } from '../../account/AccountSharedComponents';

const useModalStyles = makeStyles(styles);

const starterAmounts = [10, 20, 50];

const AddFundsToCustomersModal = ({ open, onClose, customersCount, onAdd, processing, completed }) => {
  const [giftAmount, setGiftAmount] = useState(50);
  const [automaticallySendNotificationEmail, setAutomaticallySendNotificationEmail] = useState(true);
  const [showCustomMessage, setShowCustomMessage] = useState(false);
  const [customMessage, setCustomMessage] = useState();
  const [errorMsg, setErrorMsg] = useState('');

  const handleGiftAmountChange = event => {
    let { value } = event.target;
    const convertToNumber = Number(value);
    value = isNaN(convertToNumber) ? '' : convertToNumber;
    setGiftAmount(value || '');
  };
  const handleOnAddCustomersFunds = () => {
    if (completed) {
      onClose();
      setCustomMessage();
    } else
      onAdd({
        amount: giftAmount,
        automaticallySendNotificationEmail,
        customMessage
      });
  };

  const handleInputChange = ({ target }) => {
    const limitCharacters = target.value;
    if (limitCharacters.length > 500) {
      setErrorMsg('Cannot enter more than 500 characters');
    } else {
      setErrorMsg('');
    }
    setCustomMessage(limitCharacters.substring(0, 500));
  };

  const classes = useModalStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      onEnter={() => {
        setGiftAmount(50);
      }}
      titleClass={classes.title}
      subtitleClass={classes.subtitle}
      closeButtonClass={classes.closeButton}
      actions={
        <Grid container justifyContent="center" style={{ paddingBottom: 12 }}>
          <Button
            size="small"
            variant="primary"
            onClick={handleOnAddCustomersFunds}
            loading={processing}
            style={{ width: 256 }}
          >
            {completed ? 'Back to Customers' : 'Add Funds to Customers'}
          </Button>
        </Grid>
      }
    >
      <Grid style={{ width: 780 }}>
        <SwipeableViews
          axis="x"
          index={completed ? 1 : 0}
          style={{ height: completed ? 400 : '100%', overflowY: completed ? 'hidden' : 'auto' }}
          className={classes.swipeableViews}
          disabled
        >
          <Grid container justifyContent="center" className={classes.modalContent}>
            <Grid item style={{ paddingTop: 50 }}>
              <Typography variant="body3RegularInter" className={classes.text}>
                {customersCount === 1 ? `${customersCount} customer selected` : `${customersCount} customers selected`}
              </Typography>
              <Typography variant="h5SemiBoldInter" className={classes.textTitle}>
                Add funds to their Shop Credit Balance
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body3SemiBoldInter" className={classes.textForm}>
                Select the amount you want to gift
              </Typography>
              <Grid container spacing={3} alignItems="center">
                {starterAmounts.map(sa => (
                  <Grid key={sa} item xs={3}>
                    <StylessButton onClick={() => setGiftAmount(sa)} style={{ width: '100%' }}>
                      <Box
                        className={
                          starterAmounts.includes(giftAmount) && sa == giftAmount
                            ? classes.amountSelectorSelected
                            : classes.amountSelector
                        }
                      >
                        <Typography variant="body3SemiBoldInter" className={classes.amountSelectorText}>
                          {moneyStr(sa)}
                        </Typography>
                        <CheckCircle />
                      </Box>
                    </StylessButton>
                  </Grid>
                ))}
                <Grid item xs={3}>
                  <CustomSlimTextField
                    placeholder="0.00"
                    name="custom-amount"
                    onChange={handleGiftAmountChange}
                    fullWidth
                    value={!starterAmounts.includes(giftAmount) ? giftAmount : null}
                    InputProps={{
                      startAdornment: <span id="start-adornment">$</span>
                    }}
                  />
                </Grid>
              </Grid>
              <Box style={{ paddingTop: 28 }}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Box className={classes.customMessageForm}>
                      <Grid container>
                        <Grid item xs>
                          <Typography variant="subtitle3SemiBoldInter">Add custom message</Typography>
                        </Grid>
                        <Grid item>
                          <GreenAntSwitch
                            checked={showCustomMessage}
                            onChange={({ target: { checked } }) => setShowCustomMessage(checked)}
                            value={showCustomMessage}
                            color="primary"
                          />
                        </Grid>
                      </Grid>
                      <Box style={{ paddingTop: 8 }}>
                        <Typography variant="body3RegularInter">
                          Keep in mind that recipients will read this message when receiving their funds. It’s a nice
                          way to say “I appreciate you”!
                        </Typography>
                      </Box>
                      {showCustomMessage && (
                        <Box style={{ paddingTop: 12 }}>
                          <Typography variant="body3RegularInter" style={{ paddingBottom: 16 }}>
                            Message *
                          </Typography>
                          <CustomSlimTextFieldThin
                            className={classes.inputText}
                            placeholder="Add your message"
                            value={customMessage}
                            onChange={handleInputChange}
                            multiline
                            InputProps={{
                              endAdornment: (
                                <Typography
                                  variant="body3RegularInter"
                                  style={{ color: '#989EA4' }}
                                >{`${customMessage?.length || 0}/500`}</Typography>
                              )
                            }}
                          />
                          <Typography variant="body3RegularInter" style={{ paddingTop: '10px', color: '#F44336' }}>
                            {errorMsg}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid className={classes.activationEmail}>
                  <Grid item xs>
                    <Typography variant="subtitle3SemiBoldInter">E-Mail Notification</Typography>
                  </Grid>
                  <FormControlLabel
                    className={classes.formControl}
                    labelPlacement="start"
                    control={
                      <GreenAntSwitch
                        checked={automaticallySendNotificationEmail}
                        onChange={({ target: { checked } }) => setAutomaticallySendNotificationEmail(checked)}
                        value={automaticallySendNotificationEmail}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body3RegularInter" className={classes.inputLabel}>
                        {customersCount <= 1
                          ? 'Customer will receive an Email notification when funds are added to their Shop Credit Balance'
                          : 'Customers will receive an Email notification when funds are added to their Shop Credit Balance'}
                      </Typography>
                    }
                  />
                </Grid>
              </Box> */}
            </Grid>
          </Grid>
          <Box>
            <Grid container justifyContent="center">
              <Grid item>
                <Box className={classes.allGoodImageContainer}>
                  <Img src="/images/storefront/appreciation.png" alt="appreciation" className={classes.allGoodImage} />
                </Box>
              </Grid>
            </Grid>
            <Box style={{ padding: '12px 0px 32px 0px' }}>
              <Grid container justifyContent="center">
                <Grid item xs={10}>
                  <Typography variant="h5SemiBoldInter" style={{ textAlign: 'center' }}>
                    The funds have been added
                  </Typography>
                  <Typography variant="body3RegularInter" style={{ textAlign: 'center' }}>
                    An email confirmation has been sent to you and the users
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="center">
                <Grid item xs={10} style={{ display: 'flex' }} justifyContent="center" alignItems="center">
                  <Grid container className={classes.summaryTable}>
                    <Grid item xs={5}>
                      <Typography variant="body3RegularInter" style={{ marginBottom: 8 }}>
                        Funds per User
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body3RegularInter" style={{ marginBottom: 8, textAlign: 'center' }}>
                        Users
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body3RegularInter" style={{ marginBottom: 8, textAlign: 'right' }}>
                        Total
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="h5SemiBoldInter">{moneyStr(giftAmount)}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5SemiBoldInter" style={{ textAlign: 'center' }}>
                        {customersCount}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="h5SemiBoldInter" style={{ textAlign: 'right' }}>
                        {moneyStr(giftAmount * customersCount)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </SwipeableViews>
      </Grid>
    </Modal>
  );
};

export default AddFundsToCustomersModal;
