import * as React from 'react';
import { Tooltip, makeStyles, Zoom } from '@material-ui/core';

const useStyles = makeStyles({
  tooltip: {
    maxWidth: ({ maxWidth }) => maxWidth || 236,
    minWidth: ({ minWidth }) => minWidth,
    padding: ({ padding }) => padding || 8,
    boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#0b1829',
    textAlign: 'center',
    backgroundImage: 'none'
  },
  arrow: { color: '#ffffff' },
  childrenContainer: {
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto')
  }
});

const ArrowTooltip = ({ open, title, children, style, ...props }) => {
  const classes = useStyles(props);

  return (
    <Tooltip
      open={open}
      title={title}
      classes={{ tooltipArrow: classes.tooltip, arrow: classes.arrow }}
      placement="top"
      arrow
      TransitionComponent={Zoom}
      style={style}
      {...props}
    >
      <div className={classes.childrenContainer}>{children}</div>
    </Tooltip>
  );
};

export default ArrowTooltip;
