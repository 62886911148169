import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import { Img } from '../../global/ImgUtils';
import { useCircularIndex } from '../../../hooks';

const useStyles = makeStyles({
  buttonsContainer: {
    position: 'relative'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 15,
    border: '1px solid #ebeef2',
    overflow: 'hidden'
  },
  chevronButton: {
    padding: '2px',
    position: 'absolute',
    borderRadius: 5,
    boxShadow: '0 8px 24px 0 rgba(27, 28, 31, 0.05)',
    backgroundColor: '#fff',
    color: '#3577d4',
    top: '47%',
    '&:focus': {
      boxShadow: '0 0 0 2px #3577d4'
    }
  }
});

const ImageContainer = ({ classes, imageLinkTo, children, style }) => (
  <div
    className={clsx(classes.imageContainer, 'carousel-image')}
    style={{ ...style, cursor: imageLinkTo ? 'pointer' : 'default' }}
  >
    {imageLinkTo ? (
      <Link to={imageLinkTo} style={{ display: 'flex' }}>
        {children}
      </Link>
    ) : (
      children
    )}
  </div>
);

const CarouselButton = ({ len, children }) => (len > 1 ? children : null);

const Carousel = ({
  width = 180,
  height = 180,
  imageSources,
  alts,
  imageLinkTo,
  initialIndex,
  prevButton = <ChevronLeft fontSize="small" />,
  nextButton = <ChevronRight fontSize="small" />,
  leftButtonStyle = { left: '-6%' },
  rightButtonStyle = { right: '-6%' }
}) => {
  const classes = useStyles();

  const len = imageSources.length;
  const [current, handlePrevious, handleNext] = useCircularIndex(initialIndex, len);

  return (
    <div className={classes.buttonsContainer} style={{ width, height }}>
      <CarouselButton len={len}>
        <ButtonBase
          className={clsx(classes.chevronButton, 'carousel-button')}
          style={leftButtonStyle}
          onClick={handlePrevious}
        >
          {prevButton}
        </ButtonBase>
      </CarouselButton>
      <ImageContainer classes={classes} imageLinkTo={imageLinkTo} style={{ width, height }}>
        <Img
          src={imageSources[current]}
          alt={alts?.[current] ?? 'Current item'}
          width={width}
          height={height}
          style={{ borderRadius: 15 }}
        />
      </ImageContainer>
      <CarouselButton len={len}>
        <ButtonBase
          className={clsx(classes.chevronButton, 'carousel-button')}
          style={rightButtonStyle}
          onClick={handleNext}
        >
          {nextButton}
        </ButtonBase>
      </CarouselButton>
    </div>
  );
};

export default Carousel;
