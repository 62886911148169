import React, { useEffect, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Alert } from '@material-ui/lab';
import { EmptyState, Helmet, CenteredGrid, Pagination } from '../shared';
import { useQueryParams, usePaginatedQuery } from '../../hooks';
import tags from '../../apis/seoTags';
import InventoryHeader from './InventoryHeader';
import InventoryCardItem from './InventoryCardItemNew';
import InventoryItemDetailsModal from './InventoryItemDetailsModal';
import Loader from '../global/Loader';
import InventoryListItem from './InventoryListItem';
import styles from './styles/inventoryHome';
import accountProductsApi from '../../apis/swagup/accountProducts';
import apiPaths from '../../helpers/apiPaths';
import { useProductsFilters, useQueryFilterValidated } from '../../hooks/useFilters';
import useInventoryHistory from '../../hooks/useInventoryHistory';
import SearchSortFilter from '../shared/SearchSortFilter';
import useIntegrations from '../../hooks/useIntegrations';
import { useMembership } from '../../contexts/membershipContext';

const useStyles = makeStyles(styles);

const perPageOptions = [12, 24, 36, 48];
const InventoryHome = () => {
  const category = useQueryFilterValidated('category');
  const recordType = useQueryFilterValidated(
    'record_type',
    (id, value) => ['product', 'pack'].includes(id) && value.split(',').length === 1
  );
  const inventory = useQueryFilterValidated(
    'inventory',
    (id, value) => ['available', 'out_of_stock'].includes(id) && value.split(',').length === 1
  );
  const ordering = useQueryFilterValidated(
    'ordering',
    (id, value) => ['-created_at', 'created_at', 'name', '-name'].includes(id) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const integration = useQueryFilterValidated('integration', () => true);

  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();
  const query = useQueryParams();
  const queryClient = useQueryClient();

  const search = query.get('search') || '';
  const haveActiveFilters = [search, category, recordType, inventory, integration].some(e => e.length > 0);

  const [state, setState] = React.useState({ inventoryCount: -1, haveActiveFilters });
  const [isCardView, setIsCardView] = React.useState(true);

  let accountProductsParams = {
    visible_in_inventory: true,
    search,
    category,
    record_type: recordType,
    inventory,
    ordering,
    integration
  };
  accountProductsParams = integration?.includes('SHOPIFY')
    ? { ...accountProductsParams, in_shopify: true }
    : accountProductsParams;

  const { query: queryResult, pagination } = usePaginatedQuery({
    queryKey: [apiPaths.accountProducts, accountProductsParams],
    queryFn: (limit, offset) => {
      return accountProductsApi.fetch({ limit, offset, ...accountProductsParams });
    },
    perPageOptions
  });

  const { data, isPreviousData, isFetching } = queryResult;
  const [inventoryItemsAPI, inventoryCount] = data ? [data.results, data.count] : [[], state.inventoryCount];

  const {
    inventoryHistory,
    isLoading,
    getAccountProductInventoryDueInfo,
    getGroupByPaidDate,
    refetch,
    clearInventoryHistory
  } = useInventoryHistory(inventoryItemsAPI, accountProductsParams);

  const { data: productsData = {}, isLoading: isLoadingProducts } = useProductsFilters();
  const { product_categories: productCategories } = productsData;

  const categoryOptions = isLoadingProducts
    ? {}
    : Object.fromEntries(productCategories.slice().map(ps => [ps.id, ps.name]));

  const recordTypeOptions = { product: 'Products', pack: 'Packs' };

  const { integrations, isLoading: isLoadingIntegrations } = useIntegrations();
  const integrationOptions =
    isLoadingIntegrations && integrations.length > 0
      ? {}
      : Object.fromEntries(integrations?.slice().map(_integration => [_integration.rutter_id, _integration.name]));

  const searchSortFilterConfig = {
    search: { placeholder: 'Search Products' },
    filters: [
      { label: 'Category', queryParam: 'category', options: categoryOptions },
      { label: 'Type', queryParam: 'record_type', options: recordTypeOptions, isSingleSelect: true },
      { label: 'Integration', queryParam: 'integration', options: integrationOptions }
    ]
  };

  const inventoryItems = useMemo(() => {
    const inventoryAux = inventoryItemsAPI.map(i => ({
      ...i,
      history: getGroupByPaidDate(inventoryHistory.filter(inv => inv.account_product === i.id))
    }));
    return inventoryAux.map(i => ({ ...i, ...getAccountProductInventoryDueInfo(i.history) }));
  }, [inventoryItemsAPI, inventoryHistory]);

  const { alertMessage } = useMembership();

  React.useEffect(() => {
    if (inventoryCount < 0 || isPreviousData) return;

    setState({ inventoryCount, haveActiveFilters });
  }, [inventoryCount, haveActiveFilters, isPreviousData]);

  React.useEffect(() => {
    queryClient.invalidateQueries(apiPaths.accountProducts);
  }, [queryClient]);

  const navigateToFirstPage = React.useCallback(() => {
    query.set('page', 1);
    history.replace({ ...history.location, search: query.toString() });
  }, [query, history]);

  const handleInventoryChange = value => {
    if (!value) query.delete('inventory');
    else query.set('inventory', value);
    navigateToFirstPage();
  };

  const handleShowCardView = doShowCardView => setIsCardView(doShowCardView);

  const itemDetailsId = +(query.get('details') ?? -1);
  const selectedItem = inventoryItems?.find(item => item.id === itemDetailsId);
  React.useEffect(() => {
    if (!selectedItem && state.inventoryCount !== -1) {
      query.delete('details');
      history.replace({ ...history.location, search: query.toString() });
    }
  }, [history, query, selectedItem, state.inventoryCount]);

  if (state.inventoryCount === -1) return <Loader />;

  const InventoryItem = isCardView ? InventoryCardItem : InventoryListItem;

  const [link, text] = state.haveActiveFilters ? [path, 'Remove filters'] : ['/product-onboarding', 'Start new order'];
  const noInventory = state.inventoryCount <= 0;

  return (
    <>
      <Helmet tags={tags.inventory} />
      <CenteredGrid container justifyContent="center" className={classes.container}>
        {alertMessage?.type === 'info' && (
          <Grid container style={{ width: '100%', justifyContent: 'center' }}>
            <Alert
              severity="info"
              variant="outlined"
              style={{
                minWidth: '399px',
                width: 'fit-content',
                paddingRight: '16px',
                borderColor: '#3577D4',
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
                backgroundColor: '#EBF1FB'
              }}
            >
              {alertMessage.message}
            </Alert>
          </Grid>
        )}
        {alertMessage?.type === 'disabledSendSwag' && (
          <Grid
            container
            style={{ width: '100%', justifyContent: 'center', marginBottom: '24px', padding: '0px 52px' }}
          >
            <Alert
              severity="error"
              variant="outlined"
              style={{
                width: '100%',
                paddingRight: '16px',
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px'
              }}
            >
              {alertMessage.message}
            </Alert>
          </Grid>
        )}
        <InventoryHeader
          disabled={noInventory}
          isCardView={isCardView}
          inventoryValue={inventory}
          onShowCardView={handleShowCardView}
          onInventoryChange={handleInventoryChange}
        />
        <Grid container item xs={12} justifyContent="flex-start">
          <SearchSortFilter config={searchSortFilterConfig} />
        </Grid>
        <Grid item container justifyContent="center">
          {isFetching && <Loader absolute />}
          {inventoryCount === 0 ? (
            <EmptyState
              title="No Inventory"
              image={{
                path: '/images/inventory/empty-inventory.png',
                alt: 'No inventory',
                text: state.haveActiveFilters
                  ? 'Remove filters to see all inventory.'
                  : 'Start a new order or check back soon \nto see if your items are ready to ship!'
              }}
              button={{ link, text }}
            />
          ) : (
            <Grid container className={classes[isCardView ? 'grid' : 'list']}>
              {inventoryItems.map(item => (
                <InventoryItem
                  key={item.id}
                  item={item}
                  refetch={refetch}
                  clearInventoryHistory={clearInventoryHistory}
                />
              ))}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container justifyContent="center" style={{ paddingTop: 20, paddingBottom: 40 }}>
          <Pagination {...pagination} endText="products" />
        </Grid>
        <InventoryItemDetailsModal item={inventoryItems.find(item => item.id === itemDetailsId)} />
      </CenteredGrid>
    </>
  );
};

export default InventoryHome;
