import React from 'react';
import round from 'lodash/round';
import GenericSummaryBottom from './GenericSummaryBottom';
import { getProductPrice, sumByQuantity } from '../../helpers/utils';
import { productionTime as productionSpeed } from '../../apis/constants';

const reorderProductsTotals = products => {
  let totalQty = 0;
  let totalPrice = 0;
  products.forEach(p => {
    const sizesQty = sumByQuantity(p.sizes);
    const isRush = p.production_time === productionSpeed.rush;
    const rushFee = isRush ? getProductPrice(p, 'rush_fee') : 0;
    const productPrice = round(getProductPrice(p) + rushFee, 2);
    totalQty += sizesQty;
    totalPrice = round(totalPrice + round(productPrice * sizesQty, 2), 2);
  });
  return [totalQty, totalPrice];
};

const ReorderSummaryBottom = ({ reorderProducts }) => {
  if (reorderProducts.length === 0) return <div />;
  const [totalQty, totalPrice] = reorderProductsTotals(reorderProducts);
  return <GenericSummaryBottom totalQty={totalQty} total={totalPrice} />;
};

export default ReorderSummaryBottom;
