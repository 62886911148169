import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = () => ({
  shareModal: {
    height: 'auto',
    maxHeight: 'fit-content',
    width: 740,
    maxWidth: 740,
    margin: 'auto',
    padding: 0,
    outline: 'none'
  },
  shareModalContent: {
    borderRadius: '12px',
    background: '#ffffff',
    height: '100%',
    width: '100%',
    backgroundColor: '#ffffff',
    outline: 'none',
    border: 'transparent'
  },
  shareModalHeader: {
    padding: '0px 4px 0px 32px',
    height: '52px',
    borderBottom: '1px solid #E5E7E8'
  },
  shareModalHeaderText: {
    ...ellipsisStyles,
    color: '#131415',
    fontSize: '20px',
    lineHeight: ' 28px',
    letterSpacing: '-0.03em'
  },
  shareModalMain: { minHeight: '477px' },
  confirmModal: {
    height: 'auto',
    maxHeight: 'fit-content',
    width: 400,
    margin: 'auto',
    padding: 0,
    outline: 'none'
  },
  confirmModalHeader: { height: '32px' },
  confirmModalMain: { padding: '0px 32px' },
  confirmModalContent: { padding: '12px 0px' },
  statusBox: {
    paddingTop: '48px',
    borderRight: '1px solid #E5E7E8',
    width: '140px',
    height: '100%'
  },
  shareBoxActivated: {
    height: 48,
    width: 48,
    padding: '12px 16px 16px 12px',
    background: '#4CAF50',
    borderRadius: '4px'
  },
  shareBoxDeactivated: {
    height: 48,
    width: 48,
    padding: '12px 16px 16px 12px',
    background: '#E5E7E8',
    borderRadius: '4px'
  },
  shareIcon: {
    color: '#131415'
  },
  shareStatusLabel: { marginTop: 4, color: '#131415', textAlign: 'center' },
  shareStatusDeactivated: { color: '#989EA4', textAlign: 'center' },
  allowDeactivated: { color: '#FF6969', textAlign: 'center' },
  shareStatusActivated: { color: '#2E7D32', textAlign: 'center' },
  blueSwitch: {
    margin: 0
  },
  shareStage: { color: '#B7BBBF', maxWidth: 76, textAlign: 'center' },
  tabs: { borderBottom: '1px solid #E5E7E8', padding: '12px 16px 0px 16px' },
  tab: {
    color: '#131415 !impornant',
    fontFamily: 'Inter',
    fontSize: 16,
    '&.Mui-selected': { borderBottom: '2px solid #3577D4', paddingBottom: '4px' }
  },
  linkSectionText: { color: '#131415', marginBottom: '16px' },
  tabPanel: { padding: '16px 16px 0px 16px' },
  panelBox: { padding: '16px 16px 0px 16px' },
  linkOptionBox: {
    marginBottom: '24px',
    padding: '24px 24px 24px 12px',
    border: '1px solid #D6D8DB',
    borderRadius: '4px'
  },
  linkOptionBoxSelected: {
    marginBottom: '24px',
    padding: '23px 23px 23px 11px',
    border: '2px solid #3577D4',
    borderRadius: '4px'
  },
  linkOption: { color: '#131415', marginBottom: '4px' },
  linkOptionDescription: { color: '#989EA4', marginBottom: '12px' },
  linkOptionBody: { paddingLeft: '8px' },
  linkOptionContent: { width: '100%' },
  input: {
    width: '100%',
    '& .Mui-disabled': {
      color: '#989EA4 !important',
      '& .MuiOutlinedInput-notchedOutline': { borderColor: '#D6D8DB !important' }
    },
    '& .MuiInputBase-input': { ...ellipsisStyles, padding: '12px 0px 12px 16px', color: '#4A4F54' },
    '& .MuiInputBase-root': { paddingRight: 0 },
    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '12px' }
  },
  copyIcon: {
    width: 20,
    height: 22,
    '& .Mui-disabled': { '& .MuiSvgIcon-root': { color: '#989EA4 !important' } },
    '& .MuiSvgIcon-root': { color: '#4A4F54' }
  },
  footer: { height: '80px', borderTop: '1px solid #E5E7E8', paddingRight: '32px' }
});

export default styles;
