import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import styles from './BackgroundLight';

const BackgroundCircleCompany = ({
  classes,
  color,
  size,
  outlined,
  top,
  bottom,
  left,
  right,
  fixedClass,
  fixedStyle,
  mobile,
  invisible
}) => {
  const style = {
    backgroundColor: color || '#000033',
    width: size || 0,
    height: size ? size.replace('%', 'vw') : 0,
    borderRadius: size ? size.replace('%', 'vw') : 0,
    opacity: invisible ? 0.1 : 0.15,
    zIndex: -1
  };

  if (outlined) {
    style.border = `32px ${color || '#000033'} solid`;
    style.backgroundColor = 'transparent';
  }

  if (top) style.top = top;
  if (bottom) style.bottom = bottom;
  if (left) style.left = left;
  if (right) style.right = right;

  return (
    <div
      className={clsx(mobile ? classes.ovalPrimaryMobile : classes.ovalPrimary, fixedClass)}
      style={fixedClass || fixedStyle ? fixedStyle : style}
    />
  );
};

export default withStyles(styles)(BackgroundCircleCompany);
