import React from 'react';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { fullFormatDate, moneyStr } from '../../helpers/utils';
import { Img } from '../global/ImgUtils';

const useStyles = makeStyles({
  image: {
    width: 32,
    height: 32,
    borderRadius: 50,
    objectFit: 'cover'
  },
  name: {
    fontSize: 14,
    color: '#3577d4',
    paddingLeft: 6
  },
  tableItem: {
    fontSize: 14,
    color: '#434c5f'
  },
  tableHead: {
    fontSize: 12,
    color: '#434c5f'
  },
  tableRow: {
    height: 66,
    '&:hover': { backgroundColor: 'rgba(211, 227, 247, 0.25)' }
  }
});

const CellSpaceFill = ({ cellCount }) => [...Array(cellCount).keys()].map(index => <TableCell key={index} />);

const ApprovedSection = ({ classes, person }) => (
  <Grid container alignItems="center" style={{ flexWrap: 'nowrap' }}>
    <Img alt="employee" src={person.img} fallbackSrc="/images/public/UserDefaultIcon.svg" className={classes.image} />
    <Typography className={classes.name}>{person.name}</Typography>
  </Grid>
);

const TableRowItems = ({ classes, transaction, showBalance }) => (
  <TableRow className={classes.tableRown}>
    <TableCell>
      <Typography variant="body2" className={classes.tableItem}>
        {fullFormatDate(transaction.created_at)}
      </Typography>
    </TableCell>
    <TableCell>
      <ApprovedSection person={{ name: transaction.created_by }} classes={classes} />
    </TableCell>
    {!showBalance && <CellSpaceFill cellCount={3} />}
    <TableCell align="right">
      <Typography variant="body2" className={classes.tableItem}>
        {moneyStr(transaction.amount)}
      </Typography>
    </TableCell>
    <TableCell />
    {showBalance && (
      <TableCell>
        <Typography variant="body2" className={classes.tableItem} align="right">
          {moneyStr(transaction.balance)}
        </Typography>
      </TableCell>
    )}
    <CellSpaceFill cellCount={showBalance ? 2 : 1} />
    <TableCell align="left">
      <Typography variant="body2" className={classes.tableItem}>
        {transaction.description}
      </Typography>
    </TableCell>
    {!showBalance && (
      <TableCell align="right">
        <Button
          variant="text"
          style={{
            fontSize: '12px',
            fontFamily: 'Gilroy',
            fontWeight: 400,
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
          }}
          href={`${process.env.REACT_APP_API_ENDPOINT}/generic-invoices/${transaction.id}/?transaction=false`}
          target="_blank"
          endIcon={<OpenInNew />}
        >
          Invoice
        </Button>
      </TableCell>
    )}
  </TableRow>
);

const Transactions = ({ transactions, showBalance }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid className={classes.invoicesTablecontainer} item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Date</TableCell>
              <TableCell className={classes.tableHead}>Approved by</TableCell>
              {!showBalance && <CellSpaceFill cellCount={3} />}
              <TableCell className={classes.tableHead} align="right">
                Amount
              </TableCell>
              <TableCell />
              {showBalance && (
                <TableCell className={classes.tableHead} align="right">
                  Balance
                </TableCell>
              )}
              <CellSpaceFill cellCount={showBalance ? 2 : 1} />
              <TableCell className={classes.tableHead} align="left">
                Purpose
              </TableCell>
              {!showBalance && (
                <TableCell className={classes.tableHead} align="center">
                  Invoice PDF
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map(t => (
              <TableRowItems key={t.id} classes={classes} transaction={t} showBalance={showBalance} />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default Transactions;
