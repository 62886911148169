const commonContainerStyles = {
  width: 78,
  height: 78,
  borderRadius: 39
};

const styles = theme => ({
  button: {
    '&:focus': {
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)'
    }
  },
  container: ({ path }) =>
    path
      ? commonContainerStyles
      : {
          border: '1px solid #d4d9e2',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          backgroundColor: '#fafafa',
          ...commonContainerStyles
        },
  logo: ({ path }) => ({
    width: path ? 78 : 32,
    height: path ? 78 : 32,
    margin: path ? 0 : 'unset',
    borderRadius: 39,
    objectFit: 'contain'
  }),
  subTitle: {
    paddingTop: 15,
    paddingBottom: 15,
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 600
  },
  logoTitleContainer: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 16,
    color: '#434c5f',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  uploadButton: { width: 180 }
});
export default styles;
