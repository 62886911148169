import { changeColorLightness } from '../../shared/styles/utils';

const blueLink = {
  color: '#3577d4',
  cursor: 'pointer',
  '&:hover': {
    color: changeColorLightness('#3577d4'),
    backgroundColor: 'unset'
  }
};

export default theme => ({
  container: {
    marginTop: 0,
    paddingTop: 40,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  card: {
    padding: '23px 27px',
    border: selected => (selected ? '1px solid #3577d4' : '1px solid #ebeef2')
  },
  cardsContainer: {
    gridTemplateColumns: 'repeat(auto-fill, 276px)',
    transition: 'all 1s',
    marginTop: 0
  },
  searchInput: {
    minWidth: 244,
    '& .MuiInputAdornment-positionStart': {
      paddingLeft: 14,
      paddingRight: 0
    },
    '& input': {
      background: 'transparent',
      fontSize: 12,
      color: '#787b80',
      paddingLeft: 4
    }
  },
  select: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    marginLeft: 32,
    '& img': {
      marginLeft: 8
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      marginLeft: 24
    }
  },
  designType: {
    textAlign: 'right',
    lineHeight: '16px',
    color: '#0B1829',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12
  },
  imageContainer: {
    width: 220,
    height: 220
  },
  designImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  cardHeader: {
    paddingBottom: 26
  },
  link: {
    alignItems: 'center',
    color: '#3577d4',
    display: 'inline-flex',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    padding: '3px 8px',
    paddingLeft: 0,
    width: 'fit-content',
    letterSpacing: 'normal',
    height: 'auto',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  linkIcon: {
    cursor: 'pointer',
    fontSize: 16,
    marginLeft: 6
  },
  designName: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#0b1829',
    marginTop: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  designPrice: {
    // marginTop: 8,
    // marginBottom: 16,
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 500,
    height: 16,
    color: '#0b1829',
    '& span': {
      color: '#787b80'
    }
  },
  designPriceInfo: {
    // marginTop: 5,
    // marginBottom: 14,
    marginLeft: 6,
    height: 14
  },
  info: {
    height: 14,
    width: 14,
    ...blueLink
  },
  priceInfo: {
    fontFamily: 'Gilroy-Semibold',
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: 'center',
    color: '#0b1829'
  },
  priceInfoLink: blueLink,
  addButton: {
    width: 36,
    height: 36,
    '&.Mui-disabled': {
      filter: 'grayscale(1) brightness(1.5)'
    }
  },
  headerContainer: {
    marginBottom: 24,
    '& h2': { lineHeight: '40px' },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  filtersContainer: {
    marginTop: 12,
    minHeight: 56,
    borderTop: '1px solid #E5E7E8',
    borderBottom: '1px solid #E5E7E8',
    width: 'calc(100vw - 17px)',
    overflowX: 'hidden',
    marginRight: '50%',
    marginLeft: '50%',
    transform: 'translate(-50%)'
  },
  filterHeader: {
    color: '#989EA4',
    marginBottom: 6,
    width: '100%',
    opacity: '1 !important',
    padding: 0
  },
  label: {
    fontSize: '14px',
    color: '#4A4F54',
    whiteSpace: 'nowrap',
    '& span': {
      fontSize: '14px',
      color: '#125CFF'
    }
  },
  labelCounter: {
    color: '#3577D4',
    fontVariantNumeric: 'tabular-nums'
  },
  divider: {
    margin: '8px 0 14px',
    width: '100%'
  },
  clearButton: {
    color: '#989EA4',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px'
  },
  applyButton: {
    color: '#3577D4',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px'
  },
  menuList: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    maxWidth: 400
  },
  actionBarOpen: {
    paddingTop: 16,
    paddingBottom: 120
  },
  actionBarClose: {
    paddingTop: 16,
    paddingBottom: 40
  },
  productImageContainer: {
    borderRadius: 4,
    position: 'relative',
    '&:hover': {
      '& .zoom': {
        display: 'flex !important',
        cursor: 'pointer'
      }
    }
  },
  productZoomImage: {
    marginTop: 4,
    width: 28,
    height: 28
  }
});
