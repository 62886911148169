/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useProduct } from '../../common/ProductContext';
import { useOrder } from '../../OrderContext';
import { SizesListMultiSize, SizesListOneSize } from '../../common/SizesList';
import { getUsedQuantitiesBySize, isOneSize, onlyNaturalNumberOrEmpty } from '../../../../../../helpers/utils';
import { getQuantitiesBreakdown, joinQuantitiesBreakdowns } from '../../common/utilsOrder';
import { changeColorLightness } from '../../../../../shared/styles/utils';

const useStyles = makeStyles({
  sizesContainer: {
    columnGap: '16px',
    rowGap: '24px',
    position: 'relative'
  },
  hightlightedText: {
    color: '#0b1829',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    margin: '12px 0px 8px 0px'
  },
  orderOverviewMargin: { margin: '12px 0px 2px 0px' },
  editButton: { height: 20, marginLeft: -8, marginBottom: 1 },
  editText: {
    color: '#3577D4 !important',
    '&:hover': { color: `${changeColorLightness('#3577D4')} !important` }
  }
});

const OneSizeConfiguration = ({ quantities, isOrderOverview }) => {
  const { updatedProduct: product, updateProduct } = useProduct();

  const handleChangeQty = e => {
    e.target.value = onlyNaturalNumberOrEmpty(String(e.target.value));
    const quantity = +e.target.value || 0;

    updateProduct(oldProduct => ({
      ...oldProduct,
      sizes: [
        {
          ...oldProduct.sizes[0],
          quantity
        }
      ]
    }));
  };

  return (
    <SizesListOneSize
      currentQuantity={product.sizes[0].quantity}
      onQtyClick={value => handleChangeQty({ target: { value } })}
      customSizeInputProps={{
        minimumQuantity: quantities[product.sizes[0].size.id],
        onChange: handleChangeQty,
        placeholder: 'Custom qty'
      }}
      isOrderOverview={isOrderOverview}
    />
  );
};

const MultipleSizesConfiguration = ({ quantities, isOrderOverview }) => {
  const { updatedProduct: product, updateProduct, product: defaultProduct } = useProduct();
  const currentProduct = React.useRef(product);

  React.useEffect(() => {
    currentProduct.current = product;
  }, [product]);

  const handleChangeQty = (e, size) => {
    e.target.value = onlyNaturalNumberOrEmpty(e.target.value);
    const quantity = +e.target.value || 0;

    currentProduct.current = {
      ...currentProduct.current,
      sizes: currentProduct.current.sizes.map(sz =>
        sz.size.id === size.size.id
          ? {
              ...sz,
              quantity
            }
          : sz
      )
    };
    updateProduct(currentProduct.current);
  };

  return (
    <SizesListMultiSize
      sizes={product.sizes}
      idSelector={size => size.size.id}
      nameSelector={size => size.size.name}
      inputPropsPerSize={size => ({
        oldQuantity: defaultProduct.sizes.find(sz => sz.size.id === size.size.id).quantity,
        currentQuantity: size.quantity,
        minimumQuantity: quantities[size.size.id],
        defaultValue: size.quantity || '',
        onChange: e => handleChangeQty(e, size),
        placeholder: '0',
        disabled: isOrderOverview
      })}
    />
  );
};

const SizesConfiguration = ({ isOrderOverview, urlToOpen }) => {
  const classes = useStyles();
  const { updatedProduct: product } = useProduct();
  const { enableUnallocatedProductsManagementV2 } = useFlags();
  const { shipmentGroups, warehouseProofs } = useOrder();
  const quantities = React.useMemo(() => {
    const shipmentQuantities = getUsedQuantitiesBySize(product.id, shipmentGroups);
    const warehouseProofSizes = warehouseProofs.find(wp => wp.proof.id === product.id)?.sizes;
    return !enableUnallocatedProductsManagementV2 && warehouseProofSizes?.length > 0
      ? joinQuantitiesBreakdowns(shipmentQuantities, getQuantitiesBreakdown(warehouseProofSizes))
      : shipmentQuantities;
  }, [product.id, shipmentGroups, warehouseProofs, enableUnallocatedProductsManagementV2]);

  const Configuration = isOneSize(product.sizes) ? OneSizeConfiguration : MultipleSizesConfiguration;

  return (
    <>
      <Typography
        variant="body4RegularInter"
        className={isOrderOverview ? classes.orderOverviewMargin : classes.hightlightedText}
      >
        {`${product.product.record_type} Inventory`}
        {isOrderOverview && (
          <Button variant="text" component={Link} to={urlToOpen} replace className={classes.editButton}>
            <Typography variant="body3UnderlineInter" className={classes.editText}>
              Edit
            </Typography>
          </Button>
        )}
      </Typography>
      <Grid container className={classes.sizesContainer}>
        <Configuration quantities={quantities} isOrderOverview={isOrderOverview} />
      </Grid>
    </>
  );
};

export default SizesConfiguration;
