import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Route, Switch } from 'react-router-dom';
import RedeemPagesHomeStable from './RedeemPagesHome';
import RedeemPageHistoryStable from './RedeemPageHistory';
import RedeemPagesCreateStable from './RedeemPagesCreate';
import RedeemPagesHomeDev from '../redeemPagesDev/RedeemPagesHome';
import RedeemPageHistoryDev from '../redeemPagesDev/RedeemPageHistory';
import RedeemPagesCreateDev from '../redeemPagesDev/RedeemPagesCreate';

const RedeemPagesRouter = () => {
  const { enableRedeemPagesManagementV2 } = useFlags();
  const RedeemPagesHome = enableRedeemPagesManagementV2 ? RedeemPagesHomeDev : RedeemPagesHomeStable;
  const RedeemPagesCreate = enableRedeemPagesManagementV2 ? RedeemPagesCreateDev : RedeemPagesCreateStable;
  const RedeemPageHistory = enableRedeemPagesManagementV2 ? RedeemPageHistoryDev : RedeemPageHistoryStable;
  return (
    <Switch>
      <Route path="/redeem-pages" exact>
        <RedeemPagesHome />
      </Route>
      <Route path={['/redeem-pages-create', '/redeem-details/:id']} exact>
        <RedeemPagesCreate />
      </Route>
      <Route path="/redeem-history/:id" exact>
        <RedeemPageHistory />
      </Route>
    </Switch>
  );
};

export default RedeemPagesRouter;
