import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ToolTipIcon = ({ fontColor, ...props }) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={fontColor ?? '#3577D4'}
        fillRule="nonzero"
        d="M11 7h2v2h-2V7zm0 4h2v6h-2v-6zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      />
    </g>
  </SvgIcon>
);

export const CollapseExpandIcon = ({ collapsed }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8">
    <path
      fill="#3577D4"
      fillRule="nonzero"
      d={
        collapsed
          ? 'M10.161 6.757L5.995 2.591 1.828 6.757A1.07 1.07 0 1 1 .314 5.243L5.243.314a1.07 1.07 0 0 1 1.514 0l4.929 4.929a1.07 1.07 0 0 1 0 1.514 1.092 1.092 0 0 1-1.525 0z'
          : 'M10.161.314L5.995 4.481 1.828.314A1.07 1.07 0 1 0 .314 1.828l4.929 4.929a1.07 1.07 0 0 0 1.514 0l4.929-4.929a1.07 1.07 0 0 0 0-1.514 1.092 1.092 0 0 0-1.525 0z'
      }
    />
  </svg>
);
