import { scrollBarAutohide, ellipsisStyles } from '../../../../shared/styles/commonStyles';

export default {
  productQuantity: {
    width: '100%',
    boxShadow: 'none',
    '&::before': { display: 'none' },
    '&.Mui-expanded': { margin: '0 !important' }
  },
  productSummary: {
    padding: '0 20px',
    width: '100%'
  },
  productSummaryContent: {
    margin: '20px 0',
    width: 'calc(100% - 36px)'
  },
  expandIcon: {
    marginBottom: -22,
    marginRight: 0,
    padding: 0,
    color: '#0b1829'
  },
  productDescription: {
    marginLeft: 16,
    maxWidth: 'calc(100% - 64px)'
  },
  greyText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#787b80'
  },
  boldText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#0b1829'
  },
  productName: {
    ...ellipsisStyles,
    width: '100%',
    fontSize: 14
  },
  productSummaryDetails: {
    padding: '0 20px'
  },
  divider: {
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    height: 1
  },
  title: {
    padding: '0 20px',
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.63,
    color: '#0b1829'
  },
  productQuantities: {
    ...scrollBarAutohide,
    width: 'calc(100% - 8px)',
    height: '100%'
  },
  total: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.6,
    color: '#0b1829'
  }
};
