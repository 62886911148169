import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { Button, TextField } from '@swagup-com/components';
import { SaveAltOutlined, DeleteOutline, Search, NavigateNext } from '@material-ui/icons';
import clsx from 'clsx';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import InnerImageZoom from 'react-inner-image-zoom';
import { moneyStr, isPack, truncateFileName } from '../../../helpers/utils';
import {
  productImageBasedOnStatus,
  StatusChip,
  itemStatusStyles,
  textOnHover,
  getItemStatusText
} from '../../global/proofsCommon';
import { Img } from '../../global/ImgUtils';
import styles from './styles/ProofDetails';
import { productStatus } from '../../../apis/constants';
import { decorationName, decorationStatusText, getVisibleDecorationsKeys, hasExtension } from './common';
import { isHexColor } from '../../shared/styles/utils';
import CloseButton from '../../modals/CloseButton';
import { approveProductText } from '../../../helpers/productUtils';
import LimitedTextField from '../../global/LimitedTextField';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

const placeholderStyle = {
  fontFamily: 'Gilroy',
  color: '#787B80',
  fontSize: 12
};

const withPlaceholder = {
  '& input::placeholder': placeholderStyle,
  '& input:-ms-input-placeholder': placeholderStyle,
  '& input::-ms-input-placeholder': placeholderStyle,
  '& textarea::placeholder': placeholderStyle,
  '& textarea:-ms-input-placeholder': placeholderStyle,
  '& textarea::-ms-input-placeholder': placeholderStyle
};
const useTextFieldStyles = makeStyles(() => ({
  decorationEditSection: compactStyle => ({
    paddingTop: compactStyle ? 0 : 12,
    paddingBottom: 12,
    borderBottom: compactStyle ? 0 : '1px solid #E8E9EB',
    width: '100%',
    ...withPlaceholder
  }),
  tip: {
    ...placeholderStyle,
    marginLeft: 16
  },
  label: {
    fontFamily: 'Gilroy',
    color: '#787B80',
    fontSize: 14,
    marginBottom: compactStyle => (compactStyle ? 6 : 12)
  },
  inputText: {
    height: 40,
    '& input': {
      padding: '10px 24px',
      borderRadius: 24,
      minWidth: 216
    }
  },
  longInputText: {
    width: '100%',
    '& .MuiInputBase-formControl': {
      minHeight: 72,
      padding: '10px 22px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 15
    },
    '& textarea': {
      minHeight: 52
    }
  },
  addButton: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0
  },
  delete: {
    fontFamily: 'Gilroy',
    color: '#3577D4',
    fontSize: 24
  },
  valueText: {
    color: '#000000',
    fontFamily: 'Gilroy',
    fontSize: 16,
    marginTop: 8
  }
}));

const DecorationFieldEdit = ({
  onChange,
  decoration,
  name,
  placeholder,
  label,
  tip,
  multiline,
  editable,
  disabled,
  compactStyle,
  maxLength = 250
}) => {
  const classes = useTextFieldStyles(!!compactStyle);
  const textComponent = multiline ? (
    <LimitedTextField
      value={decoration?.[name] || ''}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={500}
      onChange={onChange}
      className={classes.longInputText}
    />
  ) : (
    <TextField
      className={classes.inputText}
      placeholder={placeholder}
      disabled={disabled}
      value={decoration?.[name] || ''}
      name={name}
      onChange={onChange}
      inputProps={{ maxLength }}
    />
  );
  return (
    <div className={classes.decorationEditSection}>
      <p className={classes.label}>
        {label || name}
        {tip && <span className={classes.tip}>{tip}</span>}
      </p>
      {editable ? textComponent : <p className={classes.valueText}>{decoration?.[name] || 'To be set...'}</p>}
    </div>
  );
};

const getNewProof = (proof, newIdWithStatus) =>
  isPack(proof.product.record_type)
    ? {
        ...proof,
        product: {
          ...proof.product,
          items: proof.product.items.map(item =>
            item.product.id === newIdWithStatus.id
              ? {
                  ...item,
                  product: {
                    ...item.product,
                    status: newIdWithStatus.newStatus
                  }
                }
              : item
          )
        }
      }
    : {
        ...proof,
        product: { ...proof.product, status: newIdWithStatus.newStatus }
      };

const useStyles = makeStyles(styles);

const DownloadLink = ({ link, disabled, classes }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <Grid container alignItems="center" className={classes.downloadLink}>
      <Grid item align="left" className={disabled ? classes.disabledLink : undefined}>
        Mockup PDF
      </Grid>
      <Grid item xs align="left">
        <SaveAltOutlined className={clsx(classes.downloadIcon, { [classes.disabledLink]: disabled })} />
      </Grid>
    </Grid>
  </a>
);

const RemoveIcon = ({ onDelete, disabled, classes }) => (
  <span className={classes.deleteButtonContainer}>
    <IconButton className={classes.deleteButton} disabled={disabled} onClick={onDelete}>
      <DeleteOutline className={classes.deleteIcon} />
    </IconButton>
  </span>
);

const Skeleton = withStyles(styles)(({ classes, style }) => (
  <MuiSkeleton variant="text" className={classes.skeleton} style={style} />
));

const ColorBubble = ({ themeColorHex }) => {
  const colorHex = themeColorHex?.replace('#', '');
  const classes = useStyles({ color: colorHex ? `#${colorHex}` : 'transparent' });

  return (
    <Grid item xs={1} container>
      {!isHexColor(colorHex) ? (
        <img src="/images/public/multicolor.png" alt="multicolor" style={{ height: 12, marginRight: 9 }} />
      ) : (
        <div className={classes.colorCircle} />
      )}
    </Grid>
  );
};

const ItemCard = ({ product, onDeleteProduct, selected, hideTrashCan, isLoading }) => {
  const themeColorHex = product.theme_color_hex?.replace('#', '');
  const classes = useStyles({ color: themeColorHex ? `#${themeColorHex}` : 'transparent' });

  const productImg = productImageBasedOnStatus(product, 78, 78);

  return (
    <Grid container className={selected ? classes.containerBlue : classes.containerGrey}>
      <Grid container>
        <Grid item container xs={3} alignItems="center" className={classes.thumbnailImage}>
          <Img src={productImg} alt="product" />
        </Grid>
        <Grid item xs={9} className={classes.infoSection}>
          <Grid item container xs={12} justifyContent="flex-start" alignItems="center">
            <Typography variant="body2" className={classes.packProductName}>
              {product.name}
            </Typography>
            <Grid item container direction="row" alignItems="center">
              <ColorBubble themeColorHex={product.theme_color_hex} />
              <Grid item container alignItems="center" justifyContent="space-between" xs={11}>
                <Typography variant="body2" className={classes.priceXItem}>
                  {isLoading || !Number.isFinite(product.price) ? <Skeleton /> : `${moneyStr(product.price)}/per item`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item className={classes.containerChip}>
              <Tooltip title={textOnHover[product.status]} arrow placement="top">
                <StatusChip label={getItemStatusText(product.status)} status={itemStatusStyles[product.status]} />
              </Tooltip>
            </Grid>
            {!hideTrashCan && productStatus.approved !== product.status && (
              <Grid item>
                <RemoveIcon onDelete={onDeleteProduct} classes={classes} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SummaryRow = ({ field, value, isColor, spaceLeft, spaceBottom, classes }) =>
  value ? (
    <Grid
      container
      spacing={3}
      style={{ paddingLeft: spaceLeft ? 12 : undefined, paddingBottom: spaceBottom ? 12 : undefined }}
      alignItems={isColor ? 'center' : 'flex-start'}
    >
      <Grid item>
        <p className={classes.changesDescriptionStrong}>{`${field}:`}</p>
      </Grid>
      {isColor ? (
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              {isHexColor(value?.theme_color_hex) ? (
                <div
                  className={classes.colorIcon}
                  style={{ background: `#${value?.theme_color_hex?.replace('#', '')}` }}
                />
              ) : (
                <img src="/images/public/multicolor.png" alt="multicolor" style={{ height: 12, marginTop: 5 }} />
              )}
            </Grid>
            <Grid item>
              <p className={classes.changesDescription}>{value?.theme_color_name}</p>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs>
          <p className={classes.changesDescription}>{value}</p>
        </Grid>
      )}
    </Grid>
  ) : null;

const DecorationInfo = ({ name, decoration = {}, expanded, onSelected, canBeApproved }) => {
  const classes = useStyles();

  const nonImage = hasExtension(decoration.Artwork1, ['.pdf', '.eps', '.ai']);

  return (
    <Accordion classes={{ root: classes.decorationInfo }} expanded={expanded} onChange={onSelected}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id={`panel-${name}`}>
        <Typography className={classes.changesTitle}>
          {canBeApproved ? name : `${name} (${decorationStatusText(decoration)})`}
        </Typography>
      </AccordionSummary>
      {!decoration.deleted && (
        <AccordionDetails>
          <div>
            <SummaryRow classes={classes} field="Imprint Type" value={decoration.Imprint_Type} />
            <SummaryRow classes={classes} field="Location" value={decoration.Location} />
            <SummaryRow classes={classes} field="Dimensions" value={decoration.Dimensions} />
            {decoration.artwork && (
              <Grid container spacing={3} alignItems="center" className={classes.changesDescription}>
                <Grid item>
                  <p className={classes.changesDescriptionStrong}>Artwork: </p>
                </Grid>
                <Grid item>
                  <div className={classes.logoContainer}>
                    <img
                      src={nonImage ? '/images/public/unknown-file.svg' : decoration.Artwork1_S3_Location}
                      alt="logo"
                      className={classes.logo}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <p className={classes.logoFileName}>{`${truncateFileName(decoration.Artwork1, 22)}`}</p>
                </Grid>
                {nonImage && (
                  <Grid item className={classes.newWindow}>
                    <a href={decoration.Artwork1_S3_Location} target="_blank" rel="noopener noreferrer">
                      <img src="/images/public/open-new-window.svg" alt="swagup" className={classes.logo} />
                    </a>
                  </Grid>
                )}
              </Grid>
            )}
            <SummaryRow classes={classes} field="Notes" value={decoration.Notes} />
          </div>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const UnsavedChangesModal = ({
  product,
  changes,
  isOpen,
  onClose,
  onContinue,
  discardChanges,
  forConfirmation,
  canBeApproved
}) => {
  const [selected, setSelected] = useState(-1);

  const { itemColor } = changes;
  const themeColorHex = itemColor ? itemColor.theme_color_hex?.replace('#', '') : undefined;

  const classes = useStyles({ color: themeColorHex ? `#${themeColorHex}` : 'transparent' });

  const regularTittle = () => (forConfirmation ? 'Before you send this request...' : 'This item has unsaved changes');
  const descriptionText = () => {
    if (canBeApproved)
      return (
        <>
          Please review your item’s design details below. Once you approve this design, it will be sent <br /> to
          production and you won’t be able to request further changes.
        </>
      );
    return forConfirmation ? (
      <>
        Our designers will start working on new mockups as soon as you submit these changes. <br /> Please include all
        changes you would like to implement during this step. <br /> *Items subject to changes in price depending on
        decoration type and color quantity
      </>
    ) : (
      <>
        Our designers will start working on new mockups as soon as you submit these changes. <br />
        Please include all changes you would like to implement during this step.
      </>
    );
  };
  const regularButtonText = () => (forConfirmation ? 'Yes! Send changes' : 'Send changes request');

  const decorationsToSend = canBeApproved
    ? changes.decorations?.map((d, i) => ({ id: i, ...d }))
    : getVisibleDecorationsKeys(changes.decorations || {}).map(key => changes.decorations[key]);

  return (
    <Dialog className={classes.unsavedChangeDialog} scroll="paper" open={isOpen} onClose={onClose}>
      {canBeApproved && (
        <Grid container justifyContent="center">
          <Grid item>
            <div className={classes.rocketImgContainer}>
              <img src="/images/public/loader.png" alt="Loader" className={classes.rocketImage} />
            </div>
          </Grid>
        </Grid>
      )}
      <DialogTitle aria-labelledby="remove-contacts-dialog">
        <Grid container justifyContent="flex-end">
          <CloseButton onClose={onClose} />
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} style={{ paddingLeft: 60, paddingRight: 60 }}>
            <p className={classes.unsavedChangeTitle}>{canBeApproved ? 'Just confirming' : regularTittle()}</p>
            <p className={classes.unsavedChangeInfo}>{descriptionText()}</p>
            {!canBeApproved && (
              <p className={classes.unsavedChangeProduct}>Are these all of the changes for {product?.name}?</p>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        className={classes.changesSection}
        style={{ overflowY: isEmpty(decorationsToSend) ? 'hidden' : 'auto' }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <SummaryRow classes={classes} field="Item Color" value={changes.itemColor} isColor spaceLeft spaceBottom />
            {decorationsToSend?.map(decoration => {
              const name = decorationName(decoration);
              return (
                <DecorationInfo
                  key={name}
                  name={name}
                  decoration={decoration}
                  expanded={selected === name}
                  onSelected={() => setSelected(selected === name ? undefined : name)}
                  canBeApproved={canBeApproved}
                />
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <div>
            {!canBeApproved && (
              <p className={classes.modalNote}>
                Note: Any decorations that have been added or deleted or product color change will not be immediately
                reflected. One of our designers will make these revisions and send them back for your approval.
              </p>
            )}
          </div>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={6}
            style={{ marginTop: 20, marginBottom: 40 }}
          >
            {!forConfirmation && !canBeApproved && (
              <Grid item>
                <Button variant="secondary" onClick={discardChanges} className={classes.discardChanges}>
                  Discard Changes
                </Button>
              </Grid>
            )}

            <Grid item>
              <Button variant="primary" onClick={onContinue} className={classes.sendChanges}>
                {canBeApproved ? approveProductText(product.status) : regularButtonText()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const IconSearch = ({ onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconSearchContainer}>
      <Search className={classes.searchButton} onClick={onClick}>
        <img src="/images/proofs/icon-search.svg" alt="search" />
      </Search>
    </div>
  );
};

const ImageDialog = ({ img, largeImageSrc, open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.modalDialog} scroll="paper">
      <DialogTitle className={classes.dialogTitle}>
        <CloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent className={classes.modalDialogContent}>
        <InnerImageZoom className="zoomImageConatiner" src={img} zoomSrc={largeImageSrc} zoomScale={1.5} hideHint />
      </DialogContent>
    </Dialog>
  );
};

const DecorationField = ({ decoration, linkPath, disabled, onEditNotes }) => {
  const classes = useStyles();
  return (
    <div>
      <Button
        variant="text"
        fullWidth
        component={Link}
        replace
        to={linkPath}
        disabled={disabled}
        className={classes.decorationButton}
      >
        {decorationName(decoration)}
        <NavigateNext
          className={clsx(classes.decorationCTA, {
            [classes.disabledCtaText]: disabled
          })}
        />
      </Button>
      {!disabled && (
        <DecorationFieldEdit
          multiline
          decoration={decoration}
          name="Notes"
          placeholder="Type your requests here"
          label="Requests, Comments, Changes?"
          onChange={event => onEditNotes(decoration, event)}
          editable
          compactStyle
          maxLength={500}
        />
      )}
    </div>
  );
};

export {
  DownloadLink,
  IconSearch,
  ImageDialog,
  Skeleton,
  ItemCard,
  ColorBubble,
  getNewProof,
  UnsavedChangesModal,
  DecorationFieldEdit,
  DecorationField
};
