import axios from '../DashBoard';
import apiPaths from '../../helpers/apiPaths';
import { errorAndLog, okAndLog } from '../../helpers/utils';
import { status200, status201, status204 } from './status.utils';

const handleOk = response => okAndLog('response ok', response.status, response.data);
const handleError = error => {
  if (error?.status) {
    return errorAndLog('response error', error.status, error.data);
  }
  return errorAndLog('request error', 'network error', error.request);
};
const handleResponse = apiCall => apiCall.then(handleOk).catch(handleError);

export default {
  async create(s3FilePath) {
    return handleResponse(axios.post(apiPaths.processes, { csv_file_path: s3FilePath }, status201));
  },

  async fetchById(id, retrieveHeaders = false) {
    const query = new URLSearchParams();
    if (retrieveHeaders) query.set('show_detected_translations', 1);
    return handleResponse(
      axios.get(apiPaths.process(id), {
        ...status200,
        params: query
      })
    );
  },

  async validate(processId, headersTraslation) {
    const data = {
      start_validating: true,
      headers_translation: headersTraslation
    };
    return handleResponse(axios.patch(apiPaths.process(processId), data, status200));
  },

  async process(process) {
    return handleResponse(axios.post(apiPaths.saveContacts, { process }, status201));
  },

  async fetchOutputs(processId, limit, offset, valid) {
    const validQuery = [0, 1].includes(valid) ? `&is_valid=${valid}` : '';
    const limitQuery = limit ? `&limit=${limit}` : '';
    const offsetQuery = offset || offset === 0 ? `&offset=${offset}` : '';
    return handleResponse(
      axios.get(
        `${apiPaths.processOutputs(processId)}?ordering=is_valid,row${limitQuery}${offsetQuery}${validQuery}`,
        status200
      )
    );
  },

  async updateOutput(processId, outputData) {
    const { id: outputId, ...data } = outputData;
    return handleResponse(
      axios.patch(apiPaths.processOutput(processId, outputId), { input_json: JSON.stringify(data) }, status200)
    );
  },

  async deleteOutputs(processId, outputIds) {
    return handleResponse(axios.delete(`${apiPaths.processOutputs(processId)}?contacts=${outputIds}`, status204));
  },

  async deleteAllOutputs(processId, validity) {
    return handleResponse(axios.delete(`${apiPaths.processOutputs(processId)}?valid=${validity}`, status204));
  },

  async fetchBadCSV(processId) {
    return handleResponse(axios.get(`${apiPaths.process(processId)}csvs/?valid=0`, status200));
  },

  async updateOutputsSize(data) {
    return handleResponse(axios.post(apiPaths.updateContactsSizes, data, status200));
  },

  async sendSwagToContacts(data) {
    return handleResponse(axios.post(apiPaths.swagContacts, data, status201));
  }
};
