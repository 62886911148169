import { ellipsisStyles } from '../../shared/styles/commonStyles';

const productNameCommon = {
  fontFamily: 'Gilroy',
  fontSize: 16,
  fontWeight: 500,
  borderRadius: 4,
  lineHeight: 'normal',
  width: '100%',
  color: ({ blue }) => (blue ? 'white' : '#0f2440')
};

const textAlignment = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const styles = theme => ({
  showProductName: {
    ...productNameCommon,
    ...ellipsisStyles,
    border: 'none',
    backgroundColor: ({ blue }) => (blue ? '#3577d4' : 'transparent')
  },
  editProductName: {
    ...productNameCommon,
    outline: 'none',
    color: '#0f2440',
    border: ({ blue }) => (blue ? '1px solid white' : '1px solid black'),
    '& focus': {
      border: '1px solid #3577d4'
    }
  },
  text: {
    marginTop: 1,
    lineHeight: 1,
    color: ({ blue }) => (blue ? 'white' : '#8D9299')
  },
  quantityText: {
    display: 'inline-flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    margin: '16px 2px 0',
    fontSize: ({ list }) => (list ? 14 : 12),
    height: 14,
    lineHeight: 1,
    color: ({ blue, newInventory }) => (blue ? 'white' : newInventory ? '#4A4F54' : '#8D9299'),
    [theme.breakpoints.between('sm', 'xl')]: {
      marginTop: 10
    }
  },
  colorText: {
    lineHeight: 1,
    marginLeft: 5,
    fontWeight: 500,
    color: ({ blue }) => (blue ? 'white' : '#0f2440')
  },
  colorCircle: {
    marginLeft: 5,
    borderRadius: 8,
    width: 12,
    height: 12,
    backgroundColor: ({ color }) => color
  },
  tableLinkText: {
    fontSize: 12,
    color: '#3577d4',
    cursor: 'pointer',
    border: 0,
    margin: 0,
    padding: 0,
    fontFamily: 'inherit',
    background: 'transparent'
  },
  detailsPackUnits: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 10,
    fontWeight: 500,
    color: '#0f2440',
    margin: 0,
    paddingBottom: 8,
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  detailsPackName: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 600,
    color: '#0f2440',
    margin: 0,
    paddingBottom: 8,
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  detailsPackColor: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    margin: 0,
    paddingBottom: 8,
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  paymentContainer: {
    backgroundColor: '#FEECEB',
    width: 250,
    height: 140,
    borderRadius: 5,
    boxShadow: 'rgba(99, 99, 99, 0.1) 0px 3px 10px 0px',
    padding: 10
  },
  container: {
    width: '250px',
    height: '200px',
    position: 'relative'
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'contain !important',
    border: '1px solid #D6D8DB',
    borderRadius: 5
  },
  backBtnRoot: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 12,
    color: '#4A4F54'
  },
  title: {
    cursor: 'pointer',
    color: '#4A4F54'
  },
  inventoryHistoryData: {
    alignItems: 'start',
    gap: '25px'
  },
  inventoryImage: {
    ...textAlignment,
    width: 250,
    height: 200,
    maxWidth: 250,
    maxHeight: 200,
    position: 'relative',
    borderRadius: 5,
    '& img': {
      width: '250px !important',
      height: '200px !important',
      objectFit: 'contain !important'
    }
  },
  dataColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    maxWidth: 200
  },
  secondColomnData: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    maxWidth: 300
  },
  item: {
    padding: '10px 0px',
    width: 'inherit !important',
    maxWidth: 'inherit !important'
  },
  currentInventory: {
    padding: '10px 0px',
    width: '400px !important',
    maxWidth: '400px !important'
  },
  mockupLink: {
    height: 'unset',
    padding: '10px 0px !important',
    '&:hover, &:focus': { textDecoration: 'underline !important' },
    '&:active': { color: '#125CFF' },
    '& p': { color: '#125CFF' },
    '&  svg': { fontSize: 20, marginRight: 8 }
  }
});
export default styles;
