import { SEND_DATA_ANET } from '../actions/types';

const CheckoutReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_DATA_ANET:
      return action.payload;

    default:
      return state;
  }
};

export default CheckoutReducer;
