import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@material-ui/icons';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import csvProcessesApi from '../../../apis/swagup/csvProcesses';
import toErrorPage from '../../../helpers/toErrorPage';
import { useAsync } from '../../../hooks';
import Loader from '../../global/Loader';
import { Container, Footer } from '../../layouts/FullscreenStepper';
import ConfirmModal from '../ConfirmModal';
import styles from './styles/ReviewColumns';
import ColumnCard from './ColumnCard';
import ProgressModal from './ProgressModal';

const columnHeaders = [
  { value: 'first_name', label: 'First Name', required: true },
  { value: 'last_name', label: 'Last Name', required: true },
  { value: 'email', label: 'Email', required: false },
  { value: 'phone_number', label: 'Phone Number', required: true },
  { value: 'company_name', label: 'Company', required: false },
  { value: 'title', label: 'Job Title', required: false },
  { value: 'size', label: 'Size', required: false },
  { value: 'shipping_address1', label: 'Street address', required: true },
  { value: 'shipping_address2', label: 'Floor, suite, unit', required: false },
  { value: 'shipping_city', label: 'City', required: true },
  { value: 'shipping_state', label: 'State', required: true },
  { value: 'shipping_zip', label: 'Zip Code', required: true },
  { value: 'shipping_country', label: 'Country', required: true }
];

const getStatusAndOrder = (match, userMatch) => {
  if (userMatch === 'ignored') return { order: 2, status: 'Ignored' };

  return userMatch
    ? { order: 1, status: match === userMatch ? 'Automatched' : 'Matched' }
    : { order: 0, status: 'Unmatched' };
};

const sortColumns = columns => columns.sort((a, b) => (a.order === b.order ? a.column - b.column : a.order - b.order));

const getColumns = headersTranslation =>
  sortColumns(
    headersTranslation.map(ht => {
      const { order, status } = getStatusAndOrder(ht.match, ht.match);
      const rows = ht.rows.length === 3 ? ht.rows : ht.rows.concat(Array(3 - ht.rows.length).fill(''));
      return {
        ...ht,
        order,
        status,
        rows,
        userMatch: ht.match === 'ignored' ? '' : ht.match
      };
    })
  );

const initialState = {
  status: 'idle',
  columns: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'started':
      return { ...state, status: 'loading' };
    case 'columnsFetched':
      return { ...state, status: 'success', columns: action.payload };
    case 'columnOptionChanged': {
      const { column, newMatch } = action.payload;
      const columns = sortColumns(
        state.columns.map(c =>
          c.column === column
            ? {
                ...c,
                ...getStatusAndOrder(c.match, newMatch),
                userMatch: newMatch
              }
            : (c.userMatch === newMatch && { ...c, userMatch: '' }) || c
        )
      );
      return { ...state, columns };
    }
    case 'columnIgnored':
      return {
        ...state,
        columns: sortColumns(
          state.columns.map(c =>
            c.column === action.payload
              ? {
                  ...c,
                  ...getStatusAndOrder(c.match, c.status === 'Ignored' ? c.userMatch : 'ignored')
                }
              : c
          )
        )
      };
    default:
      return state;
  }
};

const Reasons = ({ classes, missingColumns }) => (
  <Grid container className={classes.reasonsList}>
    <ul>
      <li>The following required columns are missing: {missingColumns.map(uh => uh.label).join(', ')}</li>
      <li>
        Please check that all column headers are present in the file. Columns may be left blank, but cannot be missing.
      </li>
      <li>Phone number is required for international addresses.</li>
      <li>Zip code and shipping state are required only for US, Mexico, Canada and India.</li>
    </ul>
  </Grid>
);

const useStyles = makeStyles(styles);

const ReviewColumns = ({ onNextStep, onPreviousStep }) => {
  const { fileId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [{ status, columns }, dispatch] = useReducer(reducer, initialState);
  const containerRef = useRef();
  const checkValidation = useRef(false);
  const [openProgress, setOpenProgress] = useState(false);

  const [fetchProcess, process, , processError] = useAsync(csvProcessesApi.fetchById);
  const [validate, validation, , validateError] = useAsync(csvProcessesApi.validate);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const error = processError || validateError;
    if (error) toErrorPage(error, history);
  }, [processError, validateError, history]);

  useEffect(() => {
    const headersTranslation = location.state?.headersTranslation;
    if (headersTranslation) {
      dispatch({
        type: 'columnsFetched',
        payload: getColumns(headersTranslation)
      });
    } else {
      fetchProcess(fileId, true);
    }
  }, [fileId, location, history, fetchProcess]);

  useEffect(() => {
    if (!process) return;

    if (!checkValidation.current) {
      const newColumns = getColumns(process.data.headers_translation);
      dispatch({ type: 'columnsFetched', payload: newColumns });
      return;
    }

    const newProgress =
      process.data.status === 'Validation Completed' ? 100 : process.data.validation_progress?.percent ?? 0;
    setProgress(newProgress);
    if (newProgress !== 100) {
      setTimeout(() => fetchProcess(fileId), 1000);
    }
  }, [process, fileId, onNextStep, fetchProcess]);

  useEffect(() => {
    if (validation) {
      fetchProcess(fileId);
    }
  }, [validation, fileId, fetchProcess]);

  const unmatchedCount = columns.filter(c => !c.userMatch && c.status !== 'Ignored').length;

  const getUnusedHeaders = (column, required) =>
    columnHeaders.filter(
      ch =>
        (!required || ch.required) &&
        !columns.some(c => c.column !== column && c.userMatch === ch.value && c.status !== 'Ignored')
    );

  const handleContinue = async () => {
    setOpenConfirmation(false);
    dispatch({ type: 'started' });
    const headersTranslation = columns.map(c => ({
      column: c.column,
      match: c.status === 'Ignored' ? 'ignored' : c.userMatch
    }));
    validate(fileId, headersTranslation);
    setOpenProgress(true);
    checkValidation.current = true;
  };

  const handleChangeOption = payload => dispatch({ type: 'columnOptionChanged', payload });

  const handleIgnore = column => dispatch({ type: 'columnIgnored', payload: column });

  const columnCards = columns.map(c => (
    <ColumnCard
      containerRef={containerRef}
      key={c.column}
      columnData={c}
      unusedHeaders={getUnusedHeaders(c.column)}
      onChangeOption={handleChangeOption}
      onIgnore={() => handleIgnore(c.column)}
    />
  ));

  const unusedRequiredHeaders = getUnusedHeaders(null, true);
  const continueFn = unusedRequiredHeaders.length > 0 ? () => setOpenConfirmation(true) : handleContinue;

  return (
    <>
      <Container direction="column">
        <Grid container direction="column" style={{ position: 'relative' }}>
          {status === 'loading' && <Loader />}
          <Typography ref={containerRef} variant="h4">
            Review Columns
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Make sure you have the correct data in the corresponding columns to avoid any failed shipments.
          </Typography>
          {unmatchedCount > 0 && (
            <span className={classes.unmatchedCount}>
              {unmatchedCount} unmatched column{unmatchedCount > 1 ? 's' : ''}
            </span>
          )}
          <div className={classes.horizontalScrollContainer}>
            <ScrollMenu
              data={columnCards}
              alignCenter={false}
              scrollBy={1}
              menuClass={classes.horizontalScrollMenu}
              innerWrapperClass={classes.horizontalScrollInnerWrapper}
              itemStyle={{ outline: 'none' }}
              hideArrows
              hideSingleArrow
              arrowLeft={<KeyboardArrowLeftRounded />}
              arrowRight={<KeyboardArrowRightRounded />}
              arrowClass={classes.arrow}
              arrowDisabledClass={classes.arrowDisabled}
            />
          </div>
          <ProgressModal open={openProgress} progress={progress} onContinue={() => onNextStep(fileId)} />
          <ConfirmModal
            open={openConfirmation}
            imgSrc="/images/addContact/warning.png"
            title="Error: There is at least one column missing in your CSV"
            description={<Reasons classes={classes} missingColumns={unusedRequiredHeaders} />}
            onClose={() => setOpenConfirmation(false)}
            secondaryAction={{ text: 'Reupload CSV', onClick: onPreviousStep }}
            primaryAction={{ text: 'Add values manually', onClick: handleContinue }}
          />
        </Grid>
      </Container>
      <Footer onContinue={unmatchedCount === 0 && continueFn} onPrevious={() => onPreviousStep()} />
    </>
  );
};

export default ReviewColumns;
