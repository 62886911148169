import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isEqual from 'lodash/isEqual';
import findLast from 'lodash/findLast';

import styles from './styles/FeedbackSection';
import ItemsDecorationPanel from './ItemsDecorationPanel';
import ItemsChangesHistory from './ItemsChangesHistory';
import { decorationName, formatColor } from './common';
import { productStatus } from '../../../apis/constants';
import { buildUrlWithParam } from '../../../helpers/utils';
import Drawer from '../../pages/orders/requested/common/Drawer';
import { DecorationField } from './ProofDetailsSectionsExtension';
import ColorSection from './ColorSection';

const useStyles = makeStyles(styles);

// Given two objects (a proof item) an a copy, it returns a object with the properties that are different
// it also preform some other taks like adding the API name (which not changes) for the case of decorations
const getDifferencesBetweenTwoObjects = (obj1, obj2 = {}, property) => {
  let diff = {};

  if (isArray(obj1)) {
    for (let i = 0; i < obj1.length; i++) {
      const deepUpdates = getDifferencesBetweenTwoObjects(obj1[i], obj2 ? obj2[i] : {}, property);
      diff = isEmpty(deepUpdates)
        ? diff
        : {
            ...diff,
            [`${property}_${i + 1}`]: { ...deepUpdates, Name: obj2[i]?.Name || obj1[i]?.Name } // propertyName_# = (differences, Name from API)
          };
    }
    return diff;
  }

  if (isPlainObject(obj1)) {
    diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
    let rslt = {};
    diff
      .filter(k => k !== 'created' && k !== 'updated' && k !== 'deleted') // these properties are left out the comparison
      .forEach(key => {
        const deepUpdates = getDifferencesBetweenTwoObjects(obj1[key], obj2[key], key);
        rslt =
          isEmpty(deepUpdates) && isPlainObject(obj1[key])
            ? rslt
            : {
                ...rslt,
                [key]: deepUpdates
              };
      });
    return isEmpty(rslt) && !obj1.deleted
      ? rslt
      : {
          ...rslt,
          ...diff
            .filter(k => k === 'created' || k === 'updated' || k === 'deleted') // these properties values are back included ...
            .reduce((s, p) => ({ ...s, [p]: obj1[p] }), {}) // ....................when a differences are found
        };
  }
  return isEqual(obj1, obj2) ? undefined : obj1;
};

const markItemsDecorations = item => ({
  ...item,
  decorations: item.decorations ? item.decorations.map((d, idx) => ({ ...d, id: idx })) : []
});

const parseChanges = (changes, item) => {
  const apiModel = {
    ...item,
    itemColor: { theme_color_hex: formatColor(item.theme_color_hex), theme_color_name: item.theme_color }
  };
  return { target: changes, source: apiModel };
};

const generateItemChangesRequest = (changes, item) => {
  const { target, source } = parseChanges(changes, item);
  return getDifferencesBetweenTwoObjects(target, source);
};

const getColorHexValue = value => (value ? `#${value?.replace('#', '')}` : undefined);

const FeedbackSection = ({ item, onChangesRequested, changesHistory }) => {
  const classes = useStyles();
  const history = useHistory();

  const [changes, setChanges] = useState({});

  useEffect(() => {
    setChanges(markItemsDecorations(item));
  }, [item]);

  useEffect(() => {
    onChangesRequested(generateItemChangesRequest(changes, markItemsDecorations(item)));
  }, [changes, item, onChangesRequested]);

  const query = new URLSearchParams(history.location.search);
  const openParam = query.get('open');

  const removeOpenQueryParam = useCallback(() => {
    query.delete('open');
    history.replace({ ...history.location, search: query.toString() });
  }, [query, history]);

  const localDecorations = changes.decorations?.filter(d => !d.deleted);
  const disableDecorationsChanges = productStatus.designReady !== item.status;
  const disableSendNotes = ![productStatus.designReady, productStatus.productionReady].includes(item.status);
  const disableDecorations = productStatus.changesRequested === item.status;
  const isColorSectionDisabled =
    !item.available_colors || item.available_colors.length === 0 || disableDecorations || disableDecorationsChanges;

  useEffect(() => {
    const canRemoveOpenParam = () => {
      switch (openParam) {
        case 'add_decoration':
          return disableDecorations || disableDecorationsChanges;
        case 'changes_history':
          return !changesHistory.results?.length;
        default: {
          // edit decoration
          const decorationId = +openParam;
          return !Number.isInteger(decorationId) || !localDecorations?.find(d => d.id === decorationId);
        }
      }
    };
    if (openParam && item.id) {
      if (canRemoveOpenParam()) removeOpenQueryParam();
    }
  }, [
    changesHistory,
    disableDecorations,
    disableDecorationsChanges,
    isColorSectionDisabled,
    item,
    localDecorations,
    openParam,
    removeOpenQueryParam
  ]);

  const handleDelete = id =>
    setChanges(prevChanges => ({
      ...prevChanges,
      decorations: prevChanges.decorations.map(d => (d.id === id ? { ...d, deleted: true } : d))
    }));

  const onClosePanelHandler = () => {
    if (openParam === 'add_decoration')
      setChanges(prevChanges => ({
        ...prevChanges,
        decorations: prevChanges.decorations?.map(d => (d.created ? { ...d, updated: true } : d))
      }));
    removeOpenQueryParam();
  };

  const handleEditDecoration = change =>
    setChanges(prev => ({
      ...prev,
      decorations: prev.decorations.map(d => (d.id === change.id ? { ...change, updated: true } : d))
    }));

  const getPanelContent = () => {
    if (openParam === 'changes_history')
      return (
        <ItemsChangesHistory
          changesHistory={changesHistory}
          onContinue={removeOpenQueryParam}
          productName={item.name}
        />
      );

    // When editing a decoration we set the open query param as
    // the decoration id.
    const decorationId = +openParam;
    // We need a better check here.
    if (openParam === 'add_decoration' || Number.isInteger(decorationId))
      return (
        <ItemsDecorationPanel
          item={changes}
          onContinue={onClosePanelHandler}
          decoration={
            openParam === 'add_decoration'
              ? findLast(changes.decorations, d => d.created && !d.updated) || {
                  id: isEmpty(changes.decorations) ? 1 : Math.max(...changes.decorations.map(d => d.id)) + 1,
                  created: true,
                  Name: `New Decoration ${(changes.decorations?.filter(d => d.created).length || 0) + 1}`
                }
              : localDecorations?.find(d => d.id === decorationId) || {}
          }
          getDecorationName={decorationName}
          lastOne={localDecorations?.length === 1}
          onChange={
            openParam === 'add_decoration'
              ? change => {
                  setChanges(prev => ({
                    ...prev,
                    decorations:
                      !isEmpty(prev.decorations) && prev.decorations.find(d => d.id === change.id)
                        ? prev.decorations.map(d => (d.id === change.id ? change : d))
                        : [...(prev.decorations || []), change]
                  }));
                }
              : handleEditDecoration
          }
          onDelete={handleDelete}
          disabled={disableDecorationsChanges}
          disableSendNotes={disableSendNotes}
        />
      );

    // We shouldn't fail silently
    return null;
  };

  const handleEditNotes = (prevDecoration, { target: { value } }) =>
    handleEditDecoration({ ...prevDecoration, Notes: value });

  const itemColor = changes?.itemColor;
  const [themeColorHex, themeColorName] = itemColor
    ? [getColorHexValue(itemColor.theme_color_hex), itemColor.theme_color_name]
    : [getColorHexValue(changes.theme_color_hex), changes.theme_color];

  const handleColorSelection = color =>
    setChanges({
      ...changes,
      itemColor: {
        theme_color_name: color.name,
        theme_color_hex: formatColor(getColorHexValue(color.hex))
      }
    });

  return (
    <Box height="100%">
      <Grid container>
        <Grid container className={classes.ctaWrapper}>
          <Grid item xs>
            <ColorSection
              key={item.id}
              colors={changes.available_colors}
              selectedColor={{
                name: themeColorName,
                hex: themeColorHex
              }}
              onClick={handleColorSelection}
              disabled={isColorSectionDisabled}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.ctaWrapper} style={{ paddingBottom: 12 }}>
          <Grid item xs>
            <p className={classes.optionTitle} style={{ marginBottom: 20 }}>
              Decoration details
            </p>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              component={Link}
              replace
              to={buildUrlWithParam(history.location, 'open', 'add_decoration')}
              disabled={disableDecorations || disableDecorationsChanges}
              className={classes.cta}
            >
              + Add
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div>
              {localDecorations?.map(decoration => (
                <DecorationField
                  key={decoration.id}
                  decoration={decoration}
                  linkPath={buildUrlWithParam(history.location, 'open', decoration.id)}
                  disabled={disableDecorations || disableDecorationsChanges}
                  onEditNotes={handleEditNotes}
                />
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="text"
              fullWidth
              component={Link}
              replace
              to={buildUrlWithParam(history.location, 'open', 'changes_history')}
              disabled={!changesHistory.results?.length}
              className={classes.cta}
            >
              Changes history
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Drawer
        open={Boolean(openParam)}
        onClose={onClosePanelHandler}
        classes={{ root: classes.drawerRoot, paper: classes.drawerPaper }}
      >
        {getPanelContent()}
      </Drawer>
    </Box>
  );
};

export default FeedbackSection;
