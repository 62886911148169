import Cookie from 'js-cookie';
import { isServer } from './utils';
import log from '../logger';

const mergeDataWithCookie = (data, cookieName = 'swagup') => {
  const cookieValue = Cookie.get(cookieName);
  const customUtmParameters = localStorage.getItem('custom_utm_parameter') || null;

  try {
    const mergedData = {
      ...data,
      ...(customUtmParameters && JSON.parse(customUtmParameters)),
      ...(cookieValue && JSON.parse(cookieValue))
    };
    log.debug(`merged data with cookie ${cookieName}:`, JSON.stringify(mergedData));
    return mergedData;
  } catch {
    return data;
  }
};

const mergeCampaignIdsWithData = data => {
  const msclkid = localStorage.getItem('_uetmsclkid');
  const gacid = Cookie.get('_ga');

  return mergeDataWithCookie({
    ...data,
    msclkid,
    gacid
  });
};

const getTrackingTemplate = (cookieName = 'swagup') => {
  if (isServer) return '';
  const cookieValue = Cookie.get(cookieName);
  const trackingTemplate = cookieValue && new URLSearchParams(JSON.parse(cookieValue)).toString();
  log.debug(`trackingTemplate detected: ${trackingTemplate}`);
  return trackingTemplate;
};

const getFieldFromCookie = (field, name = 'swagup') => {
  if (isServer) return '';
  const cookieValue = Cookie.get(name);

  try {
    const data = cookieValue && JSON.parse(cookieValue)[field];
    log.debug('cookie', name, 'found with a value of', data, 'for the field', field);
    return data;
  } catch (e) {
    log.error('Error', e, 'when trying to parse cookie', name);
  }

  return '';
};

export { mergeCampaignIdsWithData, mergeDataWithCookie, getTrackingTemplate, getFieldFromCookie };
