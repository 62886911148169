import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, Grid } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import toErrorPage from '../../helpers/toErrorPage';
import { setDefaultPaymentProfile, deleteShippingCard, getPaymentProfiles } from '../../actions';
import { getSelected, totalShippingPrice, isOneSize, getSortedBySize } from '../../helpers/utils';
import Loader from '../global/Loader';
import csvProcessesApi from '../../apis/swagup/csvProcesses';
import { useAsync, useCompany, useCreditActions, useCreditSummary } from '../../hooks';
import { GoBack } from '../shared';
import styles from './styles/SendSwagReview';
import OrderOverview from './OrderOverview';
import ShippingSummary from './ShippingSummary';

const buildShippingDate = unformatedDate => {
  const date = new Date(unformatedDate);
  const m = date.getMonth() + 1;
  return {
    day: date.getDate(),
    month: m < 10 ? `0${m}` : m,
    year: date.getFullYear()
  };
};

const getDataFromShippings = (shippings, isInternational = false) => {
  const filteredShippings = shippings.filter(sp => isInternational === (sp.employee.shipping_country !== 'US'));

  const employeesQty = filteredShippings.length;
  if (employeesQty === 0)
    return {
      employeesQty: 0,
      totalPrice: 0,
      deliveryMethod: '',
      shippingDate: ''
    };

  const totalPrice = filteredShippings.reduce((total, fs) => {
    const dm = getSelected(fs.deliveryMethods);
    return round(total + totalShippingPrice(dm.prices.breakdown), 2);
  }, 0);

  const dm = getSelected(filteredShippings[0].deliveryMethods);
  const deliveryMethod = dm.name;
  const { day, month, year } = buildShippingDate(filteredShippings[0].shippingDate);

  return {
    employeesQty,
    totalPrice,
    deliveryMethod,
    shippingDate: `${month}/${day}/${year}`
  };
};

const getCSVShippingInfo = shipping => {
  if (!shipping) return null;

  const { day, month, year } = buildShippingDate(shipping.shippingDate);
  return {
    delivery_method: getSelected(shipping.deliveryMethods)?.name,
    shipping_notes: shipping.shippingNote || '',
    shipping_date: `${year}-${month}-${day}`
  };
};

const SendSwagReview = ({
  classes,
  product,
  shippings,
  paymentProfiles,
  setDefaultPaymentProfileAction,
  deleteShippingCardAction,
  getPaymentProfilesAction
}) => {
  const { data: company } = useCompany();
  const { allow_negative_credit_balance: allowNegativeCreditBalance } = company;
  const history = useHistory();
  const location = useLocation();
  const [shippingSummaryHeight, setShippingSummaryHeight] = useState(0);
  const [useCreditsFirst, setUseCreditsFirst] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const process = location.state?.process;
  const [sendSwag, sendSwagResponse, , sendSwagError] = useAsync(csvProcessesApi.sendSwagToContacts);
  const [errorMessage, setErrorMessage] = useState();

  const employees = useMemo(() => {
    return shippings.map(s => ({ ...s.employee, shippingItemId: s.id }));
  }, [shippings]);

  useEffect(() => {
    if (isEmpty(shippings)) history.replace(`/send-swag?product=${product.id}`);
  }, [shippings, product, history]);

  useEffect(() => {
    getPaymentProfilesAction(company.id);
  }, [company, getPaymentProfilesAction]);

  const { data: creditSummary } = useCreditSummary({ enabled: !isLoading });
  const { current_balance: balance } = creditSummary;
  useEffect(() => {
    if (balance !== undefined || allowNegativeCreditBalance !== undefined)
      setUseCreditsFirst(balance > 0 || allowNegativeCreditBalance);
  }, [balance, allowNegativeCreditBalance]);

  const { invalidateCache } = useCreditActions();
  const domesticData = useMemo(() => getDataFromShippings(shippings), [shippings]);
  const internationalData = useMemo(() => getDataFromShippings(shippings, true), [shippings]);
  useEffect(() => {
    if (sendSwagResponse) {
      invalidateCache();
      const total = round(domesticData.totalPrice + internationalData.totalPrice, 2);
      const creditsAvailable = useCreditsFirst && balance > 0 ? Math.min(total, balance) : 0;
      const selectedPaymentProfile = paymentProfiles.find(pm => pm.default);
      const paymentProfile =
        !selectedPaymentProfile || creditsAvailable >= total
          ? undefined
          : {
              ...selectedPaymentProfile,
              amount: round(total - creditsAvailable, 2)
            };
      const sizes = isOneSize(product.stock)
        ? ['One Size']
        : [...new Set(getSortedBySize(shippings.map(sh => sh.employee)).map(e => e.size.name))];

      const credits = !paymentProfile ? total : creditsAvailable;
      history.push('/ship-swag-overview', {
        product,
        sizes,
        domesticData,
        internationalData,
        total,
        credits,
        paymentProfile
      });
    }
  }, [sendSwagResponse, product, shippings, balance, useCreditsFirst, paymentProfiles, history]);

  useEffect(() => {
    if (sendSwagError) {
      if (sendSwagError.status === 400) {
        setIsLoading(false);
        setErrorMessage('Payment failed, please update your payment method to continue shipping Swag.');
        return;
      }

      toErrorPage(sendSwagError, history);
    }
  }, [sendSwagError, history]);

  const handleToggleUseCredits = () => setUseCreditsFirst(prev => !prev);

  const handleRemoveEmployee = useCallback(
    shippingItemId => {
      deleteShippingCardAction(shippingItemId);
    },
    [deleteShippingCardAction]
  );

  const handleSendSwag = () => {
    setIsLoading(true);
    const domesticShipping = shippings.find(sp => sp.employee.shipping_country === 'US');
    const internationalShipping = shippings.find(sp => sp.employee.shipping_country !== 'US');

    sendSwag({
      process,
      product_id: product.id,
      credit_payment: useCreditsFirst,
      domestic: getCSVShippingInfo(domesticShipping),
      international: getCSVShippingInfo(internationalShipping)
    });
  };

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid container className={classes.center}>
        <GoBack />
        <Grid container>
          <Grid item xs={12}>
            <p className={classes.title}>Review your Swag shipment</p>
          </Grid>
          <Grid item xs={6} style={{ paddingRight: 16 }}>
            <OrderOverview
              employees={employees}
              product={product}
              removeEmployee={handleRemoveEmployee}
              cardHeight={shippingSummaryHeight}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 16 }}>
            <ShippingSummary
              company={company}
              balance={balance}
              paymentProfiles={paymentProfiles}
              useCreditsFirst={useCreditsFirst}
              domesticData={domesticData}
              internationalData={internationalData}
              shippingNotes={shippings?.[0]?.shippingNote}
              totalItems={shippings.length}
              onToggleUseCredits={handleToggleUseCredits}
              setDefaultPaymentProfile={setDefaultPaymentProfileAction}
              onShippingSummaryHeightChange={setShippingSummaryHeight}
              onSendSwag={handleSendSwag}
              errorMessage={errorMessage}
              onError={setErrorMessage}
            />
          </Grid>
        </Grid>
      </Grid>
      {isLoading && <Loader />}
    </Grid>
  );
};

const mapStateToProps = state => ({
  paymentProfiles: state.paymentProfiles,
  product: state.accountProduct,
  shippings: state.multishipping
});

export default connect(mapStateToProps, {
  setDefaultPaymentProfileAction: setDefaultPaymentProfile,
  deleteShippingCardAction: deleteShippingCard,
  getPaymentProfilesAction: getPaymentProfiles
})(withStyles(styles)(SendSwagReview));
