import React, { useCallback, useRef, useState } from 'react';
import { OutlinedInput, makeStyles } from '@material-ui/core';
import { debounce } from 'lodash';
import { Cancel, Search } from '@material-ui/icons';
import { ellipsisStyles } from '../../shared/styles/commonStyles';

const useSearchFieldStyles = makeStyles({
  root: {
    //   margin: ({ lean }) => (lean ? 0 : '0 16px'),
    borderRadius: ({ rounded }) => (rounded ? 22 : 12),
    //   width: ({ hasNoData }) => (hasNoData ? '100%' : 190),
    width: '100%',
    //   transition: 'width 250ms ease, background 250ms ease',
    '&.MuiOutlinedInput-root.Mui-focused': { background: '#FFFFFF' },
    borderColor: '#E6E8EB'
  },
  input: {
    borderRadius: ({ rounded }) => (rounded ? 22 : 12),
    padding: '12px 20px 12px 20px',
    background: 'transparent',
    ...ellipsisStyles
  },
  notchedOutline: {
    borderRadius: ({ rounded }) => (rounded ? 22 : 12),
    transition: 'border-color 250ms ease',
    borderWidth: ({ hasNoData }) => (hasNoData ? 1 : undefined),
    borderColor: '#E6E8EB',
    '.MuiOutlinedInput-root:hover input:not(:focus) + &': {
      borderColor: '#D6D8DB'
    },
    '.MuiOutlinedInput-root.Mui-focused &': {
      borderWidth: 1,
      borderColor: '#D6D8DB'
    }
  }
});

const CatalogSearchField = ({ placeholder, defaultValue, onChange, rounded, lean, inverseHover }) => {
  const [showCancel, setShowCancel] = useState(false);
  const classes = useSearchFieldStyles({ hasNoData: defaultValue !== '', rounded, lean, inverseHover });
  const ref = useRef(null);
  const debouncedSearch = useCallback(
    debounce(value => onChange(value), 750),
    [onChange]
  );
  const clearInput = () => {
    // 👇️ reset input field's value
    ref.current.value = '';
  };
  const iconStyle = { color: '#4A4F54', width: 20, height: 20 };
  return (
    <OutlinedInput
      inputRef={ref}
      defaultValue={defaultValue}
      onChange={e => {
        debouncedSearch(e.target.value);
        setShowCancel(true);
      }}
      // style={{ backgroundColor: '#F5F5F6' }}
      placeholder={placeholder}
      startAdornment={<Search style={iconStyle} />}
      endAdornment={
        defaultValue || showCancel ? (
          <Cancel
            onClick={() => {
              debouncedSearch('');
              setShowCancel(false);
              clearInput();
            }}
            style={{ ...iconStyle, cursor: 'pointer' }}
          />
        ) : null
      }
      classes={classes}
    />
  );
};

export { CatalogSearchField };
