import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntercom } from 'react-use-intercom';
import { Helmet } from '../../../shared';
import Brands from './Brands';
import FAQs from './FAQs';
import HaveAQuestion from './HaveAQuestion';
import OurPhilosophy from './OurPhilosophy';
import SignUpNewsLetter from './SignUpNewsLetter';
import Processes from './Processes';
import LetsGetStarted from './LetsGetStarted';
import BeforeGetStarted from './BeforeGetStarted';
import tags from '../../../../apis/seoTags';

// This page is no longer accessible by other links in the site, see https://swagup.atlassian.net/browse/UTT-768?focusedCommentId=22737
// TODO: This page should be removed sometime in the future

const launchingSwagpacks = [
  { src: '/images/custom-pack-landing/brands/Walmart.svg', alt: 'Walmart' },
  { src: '/images/custom-pack-landing/brands/Facebook.svg', alt: 'Facebook' },
  { src: '/images/custom-pack-landing/brands/Policygenius.svg', alt: 'Policygenius', style: { marginTop: 5 } },
  { src: '/images/custom-pack-landing/brands/Atlassian.svg', alt: 'Atlassian' },
  { src: '/images/custom-pack-landing/brands/Patreon.svg', alt: 'Patreon' },
  { src: '/images/custom-pack-landing/brands/Rippling.svg', alt: 'Rippling' },
  { src: '/images/custom-pack-landing/brands/Brex.svg', alt: 'Brex' },
  { src: '/images/custom-pack-landing/brands/Dropbox.svg', alt: 'Dropbox' }
];
const BrandsInOurPacks = [
  { src: '/images/custom-pack-landing/brands/Karst.svg', alt: 'Karst' },
  { src: '/images/custom-pack-landing/brands/Everlane.svg', alt: 'Everlane' },
  { src: '/images/custom-pack-landing/brands/Stickermule.svg', alt: 'Stickermule' },
  { src: '/images/custom-pack-landing/brands/Patagonia.svg', alt: 'Patagonia' },
  { src: '/images/custom-pack-landing/brands/Bellroy.svg', alt: 'Bellroy', style: { marginBottom: 10 } },
  { src: '/images/custom-pack-landing/brands/Corkcicle.svg', alt: 'Corkcicle' }
];

const PackHome = () => {
  const { show } = useIntercom();

  return (
    <Grid container direction="column">
      <Helmet tags={tags.customPacks} />
      <BeforeGetStarted />
      <Processes />
      <Brands title="Launching Swag Packs for" brands={launchingSwagpacks} />
      <FAQs />
      <HaveAQuestion onOpenModal={show} />
      <OurPhilosophy />
      <Brands title="Brands in our Packs" brands={BrandsInOurPacks} />
      <LetsGetStarted />
      <SignUpNewsLetter />
    </Grid>
  );
};

export default PackHome;
