import * as React from 'react';
import { useAuth } from '../global/Authentication/AuthProvider';
import axios from '../../apis/DashBoard';
import axiosVers101 from '../../apis/dashBoardv1.01';
import axiosForm from '../../apis/DashBoardForm';
import axiosRedeem from '../../apis/RedeemServiceAPI';
import axiosStorefronts from '../../apis/StorefrontsServiceAPI';
import axiosIntegrations from '../../apis/IntegrationsAPI';
import log from '../../logger';

const axiosInstances = [axios, axiosVers101, axiosForm, axiosRedeem, axiosIntegrations, axiosStorefronts];

const TokenInterceptor = ({ children }) => {
  const [tokenInInterceptors, setTokenInInterceptors] = React.useState(null);
  const { login, accessToken, isAuthenticated } = useAuth();

  React.useEffect(() => {
    const addResponseInterceptor = axiosInstance =>
      axiosInstance.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          log.debug('response interceptor, error:', error, 'error.response:', error.response);

          if (error.response?.status !== 401) {
            return Promise.reject(error.response || error);
          }
          log.debug('login & redirect to:', window.location.href);
          return login(window.location.href);
        }
      );

    log.debug('setting up response Interceptors');
    const errorInterceptors = axiosInstances.map(instance => addResponseInterceptor(instance));

    return () => {
      log.debug('ejecting response Interceptors');
      axiosInstances.forEach((instance, i) => instance.interceptors.response.eject(errorInterceptors[i]));
    };
  }, [login]);

  React.useEffect(() => {
    const addRequestInterceptor = axiosInstance =>
      axiosInstance.interceptors.request.use(
        config => {
          return accessToken?.accessToken
            ? {
                ...config,
                headers: {
                  ...config.headers,
                  Authorization: `Bearer ${accessToken.accessToken}`
                }
              }
            : config;
        },
        error => Promise.reject(error)
      );

    log.debug('request Interceptors, accessToken === tokenInInterceptors', accessToken === tokenInInterceptors);
    if (accessToken === tokenInInterceptors) return undefined;

    log.debug('setting up request Interceptors');
    const authInterceptors = axiosInstances.map(instance => addRequestInterceptor(instance));

    setTokenInInterceptors(accessToken);

    return () => {
      if (tokenInInterceptors) {
        log.debug('ejecting request Interceptors');
        axiosInstances.forEach((instance, i) => instance.interceptors.request.eject(authInterceptors[i]));
      }
    };
  }, [accessToken, tokenInInterceptors]);

  const canRender = !isAuthenticated || Boolean(tokenInInterceptors);

  log.debug('interceptors, isAuthenticated:', isAuthenticated, 'accessToken:', accessToken);
  log.debug('interceptors, rendering children:', canRender);

  return canRender ? children : null;
};

export default TokenInterceptor;
