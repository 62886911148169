const quantityText = {
  fontFamily: 'Gilroy',
  width: 52,
  fontWeight: 400,
  color: '#787b80',
  height: 36,
  padding: '9px 3px',
  border: '1px solid  #e1e3e5',
  borderRadius: 10,
  marginTop: 5
};

export default {
  paper: {
    width: 792,
    padding: '32px 50px'
  },
  button: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    height: '56px'
  },
  title: {
    marginTop: 12,
    marginBottom: 10
  },
  warehouseProduct: ({ showWarning }) => ({
    borderRadius: 10,
    border: `solid 1px ${showWarning ? '#fa902d' : '#D6DBD8'}`,
    marginTop: 12,
    padding: '19px 24px'
  }),
  quantitiesList: { marginTop: 24, display: 'flex', flexDirection: 'column' },
  quantityContainer: {
    textAlign: 'center',
    marginRight: 10,
    width: 52
  },
  quantity: {
    ...quantityText,
    color: '#0b1829'
  },
  quantityWarning: {
    ...quantityText,
    color: '#fa902d'
  },
  productWarehouseTotal: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#0b1829'
  },
  summaryInfo: {
    marginTop: 8,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 600,
    color: '#0b1829'
  },
  infoIcon: {
    marginLeft: 9,
    fontSize: 13
  },
  detail: {
    marginTop: 8,
    marginBottom: 14,
    display: 'inline-flex'
  },
  link: {
    paddingLeft: 5
  },
  priceToolTip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    color: '#4A4F54'
  }
};
