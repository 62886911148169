import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Modal,
  Radio,
  Tab
} from '@material-ui/core';
import debounce from 'lodash/debounce';
import { AlternateEmail, Close, FileCopyOutlined, Lock, Share } from '@material-ui/icons';
import { Button, TextField, Typography } from '@swagup-com/components';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useMutation } from 'react-query';
import { GreenAntSwitch } from '../account/AccountSharedComponents';
import { CustomTextField, getPageLink, getStatus, getStatusAllowed } from './storeCommon';
import styles from './styles/storefrontShareModal';
import { CustomTooltip } from '../products/commonProductsElements';
import { onlyNaturalNumberOrEmpty } from '../../helpers/utils';
import storefrontServicesPaths from '../../helpers/storefrontsServicesPaths';
import { verifications } from '../../apis/storefrontsServices';
import StoreLaunch from './StoreLaunch';
import { useMembership } from '../../contexts/membershipContext';
import useMembershipCheck from '../../hooks/useMembershipCheck';

const useStyles = makeStyles(styles);

const CenteredBox = ({ children }) => (
  <Grid container justifyContent="center">
    <Grid item>{children}</Grid>
  </Grid>
);

const LinkOption = ({ title, description, selected, onSelect, classes, children, icon }) => (
  <Box className={selected ? classes.linkOptionBoxSelected : classes.linkOptionBox}>
    <Grid container alignItems="center">
      <Grid item>
        <Radio checked={!!selected} onChange={onSelect} className={classes.colorButton} style={{ color: '#3577D4' }} />
      </Grid>
      <Grid item xs>
        <Box className={classes.linkOptionBody}>
          <Grid container>
            <Grid item xs>
              <Typography variant="body2MediumInter" className={classes.linkOption}>
                {title}
              </Typography>
            </Grid>
            <Grid item>{icon}</Grid>
          </Grid>

          <Typography variant="body3RegularInter" className={classes.linkOptionDescription}>
            {description}
          </Typography>
          <Box className={classes.linkOptionContent}>{children}</Box>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

const ConfirmationModal = ({ open, featureName, classes, onClose, onConfirm }) => (
  <Modal open={open} onClose={onClose} className={classes.confirmModal}>
    <Box className={classes.shareModalContent}>
      <Grid container className={classes.confirmModalHeader} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Box className={classes.confirmModalMain}>
        <Box>
          <Typography variant="h6SemiBoldInter" className={classes.confirmModalTitle}>
            Are you sure you want to switch to {featureName} ?
          </Typography>
        </Box>
        <Box className={classes.confirmModalContent}>
          <Typography variant="body3RegularInter" className={classes.confirmModalText}>
            By switching the URL type, your links will no longer be storefrontable. Future recipients will need to use
            the {featureName} to storefront.
          </Typography>
        </Box>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={4}>
          <Grid item>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button size="small" variant="primary" onClick={onConfirm} style={{ height: 40, width: 94 }}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Modal>
);

const StorefrontShareModal = ({ open, store = {}, onClose, onSave, isSaving }) => {
  const [tabKey, setTabKey] = useState('1');
  // const [activated, setActvated] = useState(store?.isActive);
  const [activated, setActvated] = useState(false);
  const [allowMultipleRedemptions, setAllowMultipleRedemptions] = useState(store?.allowMultipleRedemptions);
  const [emailRestriction, setEmailRestriction] = useState(store?.emailRestriction);
  const [uniqueUrl, setUniqueUrl] = useState(store?.uniqueUrl);
  const [uniqueUrlNumber, setUniqueUrlNumber] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [copyNotification, setCopyNotification] = useState('Click to copy');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [emailRestrictionError, setEmailRestrictionError] = useState();

  const emailValidation = useMutation(
    [storefrontServicesPaths.verifyEmail, store?.emailRestriction],
    email => verifications.email({ email, storefrontId: store?.id, emailOnly: true }),
    {
      onSuccess: data => {
        if (emailRestriction)
          setEmailRestrictionError(data?.isValid === false ? 'Must use valid email domain' : undefined);
      }
    }
  );
  const emailValidatingQuery = debounce(email => emailValidation.mutate(email), 750);
  const prevOpenStatusRef = useRef();

  prevOpenStatusRef.current = open;

  const triggerNotifications = message => {
    setNotificationMessage(message);
    setTimeout(() => {
      setNotificationMessage();
    }, 3000);
  };

  const triggerCopyNotifications = message => {
    setCopyNotification(message);
    setTimeout(() => {
      setCopyNotification('Click to copy');
    }, 3000);
  };

  useEffect(() => {
    setActvated(store?.isActive);
    setUniqueUrl(store?.uniqueUrl);
    setUniqueUrlNumber(store?.uniqueUrlNumber);
    if (prevOpenStatusRef.current) triggerNotifications('All changes saved!');
  }, [store]);

  useEffect(() => {
    setActvated(store?.isActive);
  }, [open, store?.isActive]);

  useEffect(() => {
    setUniqueUrl(store?.uniqueUrl);
  }, [open, store?.uniqueUrl]);

  useEffect(() => {
    setAllowMultipleRedemptions(store?.allowMultipleRedemptions);
  }, [open, store?.allowMultipleRedemptions]);

  useEffect(() => {
    setEmailRestriction(store?.emailRestriction);
  }, [open, store?.emailRestriction]);

  useEffect(() => {
    setUniqueUrlNumber(store?.uniqueUrlNumber);
  }, [open, store?.uniqueUrlNumber]);

  useEffect(() => {
    if (!uniqueUrl) setUniqueUrlNumber(0);
  }, [uniqueUrl]);

  const { shopsAllowed } = useMembershipCheck();

  const switchActivation = checked => {
    if (shopsAllowed) onSave({ isActive: checked });
  };

  useEffect(() => {
    if (isSaving) triggerNotifications('Updating...');
  }, [isSaving]);

  useEffect(() => {
    triggerNotifications();
  }, [open]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(getPageLink(store.accessSettings));
    triggerCopyNotifications('Link copied to clipboard');
  };

  const handleClose = () => {
    triggerNotifications();
    onClose();
  };

  const handleTabChange = (event, newValue) => {
    setTabKey(newValue);
  };

  const disabledDone =
    isSaving ||
    emailRestrictionError ||
    emailValidation.isLoading ||
    (uniqueUrl && (uniqueUrlNumber < 0 || uniqueUrlNumber > 10000));

  const wereChangesMade =
    activated !== store?.isActive ||
    uniqueUrl !== store?.uniqueUrl ||
    uniqueUrlNumber > 0 ||
    allowMultipleRedemptions !== store?.allowMultipleRedemptions ||
    emailRestriction !== store?.emailRestriction;

  const standarCTAText = () => {
    if (!wereChangesMade) return 'Done';
    const regularChanges =
      uniqueUrl !== store?.uniqueUrl ||
      uniqueUrlNumber > 0 ||
      allowMultipleRedemptions !== store?.allowMultipleRedemptions ||
      emailRestriction !== store?.emailRestriction;
    const generate = uniqueUrl && uniqueUrlNumber > 0;
    const genText = regularChanges && generate ? 'Generate URLs & Save' : 'Generate URLs';
    return generate ? genText : 'Save';
  };

  const handleOnChangeURLNumber = e => {
    e.target.value = onlyNaturalNumberOrEmpty(e.target.value);
    const qty = parseInt(e.target.value, 10) || 0;
    setUniqueUrlNumber(qty);
  };

  const onEmailRestrictionChange = async ({ target: { value } }) => {
    setEmailRestriction(value);
    if (value) emailValidatingQuery(`joseph@${value}`);
    else setTimeout(() => setEmailRestrictionError(), 750);
  };

  const classes = useStyles();

  const [storeLaunchModalOpen, setStoreLaunchModalOpen] = useState(false);

  const StoreLaunchModal = ({ open, onClose, removeBtn, isInsideModal }) => (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          padding: '12px',
          borderRadius: '20px',
          maxWidth: '1200px',
          maxHeight: '764px',
          width: '80%',
          height: '90%',
          overflowY: 'hidden'
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <StoreLaunch removeBtn={removeBtn} isInsideModal={isInsideModal} />
      </Box>
    </Modal>
  );

  const shopStatus = getStatus(activated) === 'Inactive';

  return (
    <>
      <Dialog open={open} onClose={handleClose} classes={{ paper: classes.shareModal }}>
        <Box className={classes.shareModalContent}>
          <Grid container alignItems="center" className={classes.shareModalHeader}>
            <Grid item xs>
              <Typography variant="h6SemiBoldInter" className={classes.shareModalHeaderText}>
                Share {store?.projectName}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container alignItems="stretch" className={classes.shareModalMain}>
            <Grid item>
              <Box className={classes.statusBox}>
                <CenteredBox>
                  <Typography variant="body3RegularInter">Status</Typography>
                  <Typography
                    variant="body3MediumInter"
                    className={activated ? classes.shareStatusActivated : classes.shareStatusDeactivated}
                  >
                    {getStatus(activated)}
                  </Typography>
                </CenteredBox>
                <CenteredBox>
                  <FormControlLabel
                    labelPlacement="start"
                    className={classes.blueSwitch}
                    style={{ marginTop: 10 }}
                    control={
                      <GreenAntSwitch
                        disabled={isSaving}
                        checked={activated}
                        onChange={({ target: { checked } }) => {
                          setActvated(shopsAllowed ? checked : false);
                          switchActivation(checked);
                          setStoreLaunchModalOpen(!shopsAllowed);
                        }}
                      />
                    }
                  />
                </CenteredBox>
                <CenteredBox>
                  <Typography variant="body3MediumInter" className={classes.shareStage}>
                    {notificationMessage}
                  </Typography>
                </CenteredBox>
              </Box>
            </Grid>
            <Grid item xs>
              <Box>
                <TabContext value={tabKey}>
                  <Box className={classes.tabs}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                      <Tab label="Shop Access" value="1" className={classes.tab} />
                      {/* <Tab label="Email Restrictions" value="2" className={classes.tab} /> */}
                    </TabList>
                  </Box>
                  <TabPanel value="1" className={classes.tabPanel}>
                    <Box className={classes.panelBox}>
                      <Typography variant="body2MediumInter" className={classes.linkSectionText}>
                        Your Shop URL
                      </Typography>
                      <Box>
                        <LinkOption
                          title="Standard URL"
                          description="Share this link with your customer, so they can visit your shop"
                          selected={!uniqueUrl}
                          onSelect={() => setConfirmationModalOpen(true)}
                          classes={classes}
                        >
                          <TextField
                            value={getPageLink(store.accessSettings || {})}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CustomTooltip title={copyNotification} disableHoverListener={uniqueUrl}>
                                    <IconButton
                                      className={classes.button}
                                      onClick={copyToClipboard}
                                      disabled={uniqueUrl}
                                    >
                                      <FileCopyOutlined className={classes.copyIcon} />
                                    </IconButton>
                                  </CustomTooltip>
                                </InputAdornment>
                              ),
                              disabled: uniqueUrl,
                              readOnly: true,
                              className: classes.input
                            }}
                          />
                        </LinkOption>
                      </Box>
                    </Box>
                  </TabPanel>
                  {/* <TabPanel value="2" className={classes.tabPanel}>
                    <Box className={classes.panelBox}>
                      <Typography variant="body2MediumInter" className={classes.linkSectionText}>
                        Place Order Email Settings
                      </Typography>
                      <Box>
                        <Grid container>
                          <Grid item xs={10}>
                            <Grid container alignItems="center">
                              <Grid item>
                                <Box>
                                  <Typography variant="body3RegularInter" className={classes.linkOption}>
                                    Allow multiple orders per email:
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs>
                                <Box style={{ paddingLeft: '24px' }}>
                                  <FormControlLabel
                                    labelPlacement="start"
                                    className={classes.blueSwitch}
                                    control={
                                      <BlueSwitch
                                        disabled={isSaving}
                                        checked={allowMultipleRedemptions}
                                        onChange={({ target: { checked } }) => setAllowMultipleRedemptions(checked)}
                                      />
                                    }
                                  />
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box>
                                  <Typography
                                    variant="body3MediumInter"
                                    className={
                                      allowMultipleRedemptions ? classes.allowDeactivated : classes.shareStatusActivated
                                    }
                                  >
                                    {getStatusAllowed(allowMultipleRedemptions)}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={10} style={{ marginTop: 32 }}>
                            <Typography variant="body3RegularInter" className={classes.linkOption}>
                              Specify the domain restriction
                              <span style={{ marginLeft: '24px', fontSize: '10px', fontWeight: 'lighter', opacity: 0.8 }}>
                                No restriction if left blank
                              </span>
                            </Typography>
                            <CustomTextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AlternateEmail
                                      className={classes.linkOption}
                                      style={{ margin: 0, opacity: 0.8, color: '#131415' }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              className={classes.inputText}
                              placeholder="company.com"
                              value={emailRestriction}
                              name="emailRestriction"
                              onChange={onEmailRestrictionChange}
                              fullWidth
                              // disabled={store?.hasBeenPublished}
                              error={emailRestrictionError}
                              msg
                            />
                            {emailRestrictionError && (
                              <Typography variant="body4RegularInter" style={{ color: '#F44336', marginTop: 4 }}>
                                {emailRestrictionError}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </TabPanel> */}
                </TabContext>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center" justifyContent="flex-end" className={classes.footer}>
            <Grid item>
              <Button
                size="small"
                variant="primary"
                onClick={() =>
                  wereChangesMade
                    ? onSave({
                        isActive: activated,
                        uniqueUrl,
                        uniqueUrlNumber,
                        allowMultipleRedemptions,
                        emailRestriction
                      })
                    : handleClose()
                }
                style={{ height: 48, width: wereChangesMade && uniqueUrl ? 156 : 92 }}
                disabled={disabledDone}
              >
                {isSaving ? 'Saving...' : standarCTAText()}
              </Button>
            </Grid>
          </Grid>
          <ConfirmationModal
            open={confirmationModalOpen}
            classes={classes}
            featureName={uniqueUrl ? '"Standard Link"' : '"Unique URL"'}
            onClose={() => setConfirmationModalOpen(false)}
            onConfirm={() => {
              setUniqueUrl(!uniqueUrl);
              setConfirmationModalOpen(false);
            }}
          />
        </Box>
      </Dialog>
      <StoreLaunchModal
        open={storeLaunchModalOpen}
        onClose={() => setStoreLaunchModalOpen(false)}
        removeBtn
        isInsideModal
      />
    </>
  );
};

export default StorefrontShareModal;
