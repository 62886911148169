import axios from '../DashBoard';
import apiPaths from '../../helpers/apiPaths';
import { status200, status200or201, status201, status204 } from './status.utils';

export default {
  createOpportunity: data => axios.post(apiPaths.opportunities, data, status201).then(response => response.data),

  patchOpportunity: (id, data) =>
    axios.patch(apiPaths.opportunity(id), data, status200).then(response => response.data),

  deleteOpportunity: id => axios.delete(apiPaths.opportunity(id), status204).then(response => response.data),

  fetchOpportunity: (id, cancelToken) =>
    axios.get(apiPaths.opportunity(id), { ...status200, cancelToken }).then(response => response.data),

  fetchShipmentGroups: (id, cancelToken) =>
    axios.get(apiPaths.shipmentGroups(id), { ...status200, cancelToken }).then(response => response.data),

  fetchWarehouseProofs: id => axios.get(apiPaths.warehouseProofs(id), status200).then(response => response.data),

  updateOpportunityProduct: async ({ id, products }) => {
    const payload = products.map(product => ({
      id: product.id,
      production_time: product.production_time,
      sizes: product.sizes.map(size => ({ size: size.size.id, quantity: size.quantity }))
    }));

    const response = await axios.put(apiPaths.opportunityProofs(id), payload, status200or201);

    return response.data;
  },

  createShipmentGroup: ({ id, shipmentGroup }) =>
    axios.post(apiPaths.shipmentGroups(id), shipmentGroup, status201).then(response => response.data),

  editShipmentGroup: ({ opportunityId, shipmentGroupId, data }) =>
    axios
      .patch(apiPaths.shipmentGroup(opportunityId, shipmentGroupId), data, status200)
      .then(response => response.data),

  deleteShipmentGroup: ({ opportunityId, shipmentGroupId }) =>
    axios.delete(apiPaths.shipmentGroup(opportunityId, shipmentGroupId), status204).then(response => response.data),

  saveWarehouseProofs: ({ id, proofs }) =>
    axios.put(apiPaths.warehouseProofs(id), proofs, status200or201).then(response => response.data),

  validateOpportunity: params =>
    axios.post(apiPaths.validateOpportunity, params, status200or201).then(response => response.data)
};
