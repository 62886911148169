import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ACHPostPayment,
  DefaultPaymentProfile,
  Header,
  PreviousStep,
  StyledTab,
  StyledTabs,
  SwagCard,
  WireTransfer
} from './common';
import { useCompany, useQueryParams } from '../../../../../../hooks';
import { useOrder } from '../../OrderContext';
import { paymentMethods } from '../../../../../../apis/constants';

const useStyles = makeStyles({
  paymentMethodsPanel: {},
  cardPaymentPanel: { paddingTop: 24 },
  swagCardPanel: {
    minHeight: 154
  }
});

const TabPanel = ({ selected, children }) => {
  return selected ? children : <div style={{ minHeight: 220 }} />;
};

const PaymentMethod = ({
  inputValue,
  currentBalance,
  setInputValue,
  useCreditFirst,
  setUseCreditFirst,
  setInvalid
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [method, setMethod] = useState(paymentMethods.creditCard);
  const classes = useStyles();

  const { data: company } = useCompany();
  const { payment_profile: defaultProfile } = company || {};
  const order = useOrder();

  const handlePaymentMethodChange = newMethod => {
    window.sessionStorage.setItem('method', newMethod);
    order.setPaymentMethod(newMethod);
  };

  useEffect(() => {
    handlePaymentMethodChange(method);
    return () => {
      window.sessionStorage.removeItem('method');
      window.sessionStorage.removeItem('updatePaymentProfile');
    };
  }, []);

  const handleChange = (event, newValue) => {
    const tabIndex = [paymentMethods.creditCard, paymentMethods.ach, paymentMethods.wireTransfer].indexOf(newValue);
    setCurrentTab(tabIndex);
    handlePaymentMethodChange(newValue);
    setMethod(newValue);
  };
  const query = useQueryParams();
  const { allowToPayWithCredits } = useFlags();

  return (
    <Box>
      <Header header="Choose a payment method" />
      <Box className={classes.paymentMethodsPanel}>
        {allowToPayWithCredits && (
          <Box className={classes.swagCardPanel}>
            <SwagCard
              currentBalance={currentBalance}
              inputValue={inputValue}
              setInputValue={setInputValue}
              useCreditFirst={useCreditFirst}
              setUseCreditFirst={setUseCreditFirst}
              setInvalid={setInvalid}
            />
          </Box>
        )}
        <Box className={classes.cardPaymentPanel} style={{ paddingTop: allowToPayWithCredits ? undefined : 0 }}>
          <StyledTabs value={method} onChange={handleChange}>
            <StyledTab label="Credit card" value={paymentMethods.creditCard} />
            <StyledTab label="ACH" value={paymentMethods.ach} />
            <StyledTab label="Wire Transfer" value={paymentMethods.wireTransfer} />
          </StyledTabs>
          <SwipeableViews axis="x" index={currentTab} disabled>
            <TabPanel selected={currentTab === 0}>
              <DefaultPaymentProfile paymentProfile={defaultProfile} />
            </TabPanel>
            <TabPanel selected={currentTab === 1}>
              <ACHPostPayment />
            </TabPanel>
            <TabPanel selected={currentTab === 2}>
              <WireTransfer />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Box>
      <Grid style={{ padding: '32px 0px 16px' }}>
        <Link to={{ pathname: `/orders-requested/${order.id}/add-funds`, search: query.toString() }}>
          <PreviousStep />
        </Link>
      </Grid>
    </Box>
  );
};

export default PaymentMethod;
