const commonText = {
  fontFamily: 'Gilroy-Medium',
  fontWeight: 500,
  fontSize: 12,
  color: '#787b80',
  letterSpacing: 'normal'
};

export default {
  image: {
    border: 'solid 1px #f0f2f5',
    borderRadius: 10,
    width: 48,
    height: 48
  },
  name: {
    ...commonText,
    fontSize: 14,
    color: '#0b1829',
    lineHeight: 'normal'
  },
  unallocated: {
    ...commonText,
    marginTop: 10,
    lineHeight: 'normal',
    '& .bold': {
      color: '#0b1829',
      marginLeft: 5
    }
  },
  summaryTitle: {
    ...commonText,
    lineHeight: 1.67,
    display: 'flex',
    alignItems: 'center'
  },
  summaryValue: {
    ...commonText,
    fontSize: 14,
    color: '#0b1829',
    lineHeight: 1.67
  },
  infoIcon: {
    fontSize: 10,
    marginRight: 6
  }
};
