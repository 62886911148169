import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

// Inspired by the former Facebook spinners.
const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: 16
  },
  bottom: {
    color: '#989EA4'
  },
  top: {
    color: '#FFFFFF',
    animationDuration: '750ms',
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  }
}));

const CustomSpiner = ({ size, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={size || 16}
        thickness={2}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle
        }}
        size={16}
        thickness={2}
        {...props}
      />
    </div>
  );
};

export default CustomSpiner;
