import { addBusinessDaysToDate } from '../../helpers/utils';

const formatDate = date => `${date.getMonth() + 1}/${date.getDate()}`;

export const getEstimatedDeliveryMsg = (shippingDate, minDeliveryTime, maxDeliveryTime, formatDateFn = formatDate) => {
  const date = shippingDate || new Date();
  const minDate = formatDateFn(addBusinessDaysToDate(date, minDeliveryTime));
  return minDeliveryTime === maxDeliveryTime
    ? `${minDate} `
    : `${minDate} - ${formatDateFn(addBusinessDaysToDate(date, maxDeliveryTime))}`;
};
