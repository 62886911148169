import log from '../logger';
import DashBoard from '../apis/DashBoard';
import {
  ADD_SHIPPMENT,
  ADD_PRODUCT_TO_SHIP,
  DELETE_SHIPPING_CARD,
  SAVE_DATA_TO_SHIP,
  SELECT_BATCH_DELIVERY_METHOD,
  ADD_BATCH_SHIPPING_DATE,
  ADD_BATCH_SHIPPING_NOTE,
  ADD_SHIPPING_TAX,
  ADD_BATCH_SHIPPING_TAX,
  ADD_SHIPPING_DELIVERY_METHOD,
  CLEAN_MULTISHIPPING,
  SAVE_CREDIT,
  CREATE_SHIPMENT
} from './types';
import apiPaths from '../helpers/apiPaths';
import { okAndLog, errorAndLog, contentAppJSON } from '../helpers/utils';
import { minimumShippingDate } from '../helpers/commonDateFunctions';

const ID = () =>
  `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

export const addMultishipping = shippingCutoffHour => dispatch => {
  const id = ID();
  dispatch({
    type: ADD_SHIPPMENT,
    payload: {
      id,
      employee: {},
      products: [],
      deliveryMethods: [],
      shippingDate: minimumShippingDate(new Date(), shippingCutoffHour)
    }
  });
  return id;
};

export const createShipment = (sizes, directoryOrders, shippingCutoffHour) => dispatch => {
  dispatch({
    type: CREATE_SHIPMENT,
    payload: directoryOrders.map(order => ({
      id: ID(),
      employee: order.directory,
      products: order.proofs.map(proof => ({
        id: proof.proof.id,
        price: proof.proof.price,
        production_time: proof.proof.production_time,
        product: proof.proof.product,
        productId: proof.proof.product.id,
        color: proof.proof.product.theme_color,
        description: proof.proof.product.description,
        name: proof.proof.product.name,
        image: proof.proof.product.image,
        storage_category: proof.proof.product.storage_category,
        sizes: sizes.map(size => {
          const sizeInfo = proof.sizes.find(s => s.size === size.id);
          return { ...size, quantity: sizeInfo ? sizeInfo.quantity : 0 };
        })
      })),
      deliveryMethods: [{ ...order.delivery_method, selected: true }],
      shippingDate: minimumShippingDate(new Date(), shippingCutoffHour)
    }))
  });
};

export const addProductToShip = (elemId, product) => dispatch => {
  const productToShip = { ...product, saved: false };
  log.debug(productToShip);
  dispatch({
    type: ADD_PRODUCT_TO_SHIP,
    payload: { elemId, product: productToShip }
  });
};

export const deleteShippingCard = cardId => dispatch => {
  dispatch({
    type: DELETE_SHIPPING_CARD,
    payload: { cardId }
  });
};

export const saveEmployeeDataToShip = (data, elemId) => dispatch => {
  const saveShipping = { ...data, save: false };
  log.debug(saveShipping);
  dispatch({
    type: SAVE_DATA_TO_SHIP,
    payload: { saveShipping, elemId }
  });
};

export const selectBatchDeliveryMethod = (elemIds, selectedMethod) => dispatch => {
  dispatch({
    type: SELECT_BATCH_DELIVERY_METHOD,
    payload: { elemIds, selectedMethod }
  });
};

const createSinglePackDeliveryMethods = (deliveryMethods, prices) => {
  const newDms = new Map();
  deliveryMethods.forEach(dm => {
    const prefix = dm.name === 'Standard' ? '' : 'express_';
    const price = dm.country === 'US' ? prices[`${prefix}us_price`] : prices[`${prefix}international_price`];

    const newDm = {
      ...dm,
      prices: {
        breakdown: [{ total_price: parseFloat(price) }]
      }
    };

    const countryDms = newDms.get(dm.country);
    newDms.set(dm.country, [...(countryDms || []), newDm]);
  });
  return newDms;
};

export const getSinglePackDeliveryMethods = async (packId, limit = 24, offset = 0, query) => {
  log.debug('getSinglePackDeliveryMethods Action - Entering');
  try {
    const fullQuery = `limit=${limit}&offset=${offset}${query ? `&${query}` : ''}`;
    const [deliveryMethodCall, pricesCall] = await Promise.all([
      DashBoard.get(`${apiPaths.deliveryMethods}?${fullQuery}`),
      DashBoard.get(`${apiPaths.individualPackPrices}${packId}/`)
    ]);

    const error = [deliveryMethodCall, pricesCall].find(c => c.status !== 200);
    if (error) return errorAndLog('getSinglePackDeliveryMethods', error.status, error.data);

    const deliveryMethods = deliveryMethodCall.data.results;
    const prices = pricesCall.data;
    return okAndLog('getSinglePackDeliveryMethods', 200, createSinglePackDeliveryMethods(deliveryMethods, prices));
  } catch (e) {
    return errorAndLog('getSinglePackDeliveryMethods', e.status, e.data);
  }
};

export const addBatchShippingDate = (ids, shippingDate) => dispatch => {
  log.debug('addBatchShippingDate Action, date: ', shippingDate);
  dispatch({
    type: ADD_BATCH_SHIPPING_DATE,
    payload: { ids, property: { shippingDate } }
  });
};

export const addBatchShippingNote = (ids, shippingNote) => dispatch => {
  dispatch({
    type: ADD_BATCH_SHIPPING_NOTE,
    payload: { ids, property: { shippingNote } }
  });
};

export const addShippingTax = (id, shippingTax) => dispatch => {
  if (shippingTax !== 0)
    dispatch({
      type: ADD_SHIPPING_TAX,
      payload: { id, shippingTax }
    });
};

export const addBatchShippingTax = (ids, shippingTax) => dispatch => {
  if (shippingTax !== 0)
    dispatch({
      type: ADD_BATCH_SHIPPING_TAX,
      payload: { ids, property: { shippingTax } }
    });
};

export const addDeliveryMethod = (id, deliveryMethod) => dispatch => {
  const [dmFirst, ...dmRest] = deliveryMethod;
  const deliveryMethods = [
    {
      ...dmFirst,
      selected: true
    },
    ...dmRest
  ];
  dispatch({
    type: ADD_SHIPPING_DELIVERY_METHOD,
    payload: { id, deliveryMethods }
  });
};

export const multiShippingOrder = payload => async () => {
  log.debug('multiShippingOrder Action - Entering');
  try {
    const apiCall = await DashBoard.post(apiPaths.accountOrders, payload, contentAppJSON);
    if (apiCall.status === 201) {
      return okAndLog('multiShippingOrder', apiCall.status, apiCall.data);
    }
    return errorAndLog('multiShippingOrder', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('multiShippingOrder', e.status, e.data);
  }
};

export const multiShippingAccountOrder = async payload => {
  log.debug('multiShippingAccountOrder Action - Entering');
  try {
    const apiCall = await DashBoard.post(apiPaths.accountOrders, payload, contentAppJSON);
    if (apiCall.status === 201) {
      return okAndLog('multiShippingAccountOrder', apiCall.status, apiCall.data);
    }
    return errorAndLog('multiShippingAccountOrder', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('multiShippingAccountOrder', e.status, e.data);
  }
};

export const cleanMultishipping = () => dispatch =>
  dispatch({
    type: CLEAN_MULTISHIPPING
  });

export const saveCredit = credit => dispatch =>
  dispatch({
    type: SAVE_CREDIT,
    payload: credit
  });
