import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { InfoOutlined } from '@material-ui/icons';
import { useMutation, useQueryClient } from 'react-query';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import { moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import { useOrder } from '../OrderContext';
import Drawer from '../common/Drawer';
import styles from './SendToWarehouse.styles';
import useStorageInfo from '../hooks/useStorageInfo';
import WarehousePriceTooltip from './WarehousePriceTooltip';
import apiPaths from '../../../../../helpers/apiPaths';
import WarehouseCard from './WarehouseCard';
import gtm from '../../../../../utils/gtm';

const useStyles = makeStyles(styles);

const getInitialSizes = (product, warehouseProof) =>
  product.sizes.map(s => {
    const quantityInWarehouse = warehouseProof?.sizes?.find(qw => qw.size === s.size.id)?.quantity;
    return { size: s.size.id, quantity: quantityInWarehouse ?? null };
  });

const buildWarehouseInfo = (order, storagePrices) =>
  mapValues(mapKeys(order.products, 'id'), product => {
    const warehouseProof = order.warehouseProofs.find(wp => wp.proof.id === product.id);
    const storageCategory = product.product.storage_category;

    return {
      category: storageCategory,
      sizes: getInitialSizes(product, warehouseProof),
      price: storagePrices[storageCategory]
    };
  });

const buildWarehouseProofs = warehouseInfo =>
  map(warehouseInfo, ({ sizes }, proof) => ({
    proof: Number(proof),
    sizes: sizes.filter(size => Boolean(size.quantity))
  }));

const getQuantityPerCategory = warehouseProofs =>
  mapValues(groupBy(warehouseProofs, 'category'), group => sumBy(group, wp => sumByQuantity(wp.sizes)));

const SendToWarehouse = ({ onSendQtyToWarehouse }) => {
  const classes = useStyles();

  const order = useOrder();
  const { storagePrices, storageCategoryNames } = useStorageInfo();
  const [warehouseInfo, setWarehouseInfo] = React.useState(() => buildWarehouseInfo(order, storagePrices));

  const { id } = useParams();
  const queryClient = useQueryClient();

  const total = sumBy(Object.values(warehouseInfo), info => sumByQuantity(info.sizes));
  const price = sumBy(Object.values(warehouseInfo), info => sumByQuantity(info.sizes) * info.price);

  const quantitiesChanged = order.products.some(
    p =>
      !isEqual(
        warehouseInfo[p.id].sizes.filter(sz => Boolean(sz.quantity)),
        order.warehouseProofs.find(wp => wp.proof.id === p.id)?.sizes || []
      )
  );

  const warehouseMutation = useMutation(apiPaths.warehouseProofs(order.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(apiPaths.warehouseProofs(order.id));
      queryClient.invalidateQueries(apiPaths.opportunities);
      onSendQtyToWarehouse({ quantitiesChanged });
    }
  });

  const warehouseMutationPayload = React.useMemo(() => ({ id, proofs: buildWarehouseProofs(warehouseInfo) }), [
    id,
    warehouseInfo
  ]);
  const handleSubmit = () => warehouseMutation.mutate(warehouseMutationPayload);

  const handleQuantitiesChange = product => info => {
    setWarehouseInfo(prevInfo => ({
      ...prevInfo,
      [product.id]: { ...prevInfo[product.id], ...info }
    }));
  };

  const quantityPerCategory = getQuantityPerCategory(
    Object.values(warehouseInfo).filter(wp => sumByQuantity(wp.sizes) > 0)
  );

  const storageCategories = Object.keys(quantityPerCategory).map(category => ({
    name: storageCategoryNames[category] === 'Pack' ? 'Pack' : `${storageCategoryNames[category]} item`,
    price: storagePrices[category],
    quantity: quantityPerCategory[category],
    totalPrice: quantityPerCategory[category] * storagePrices[category]
  }));

  const disabled = warehouseMutation.isLoading || !quantitiesChanged;

  return (
    <>
      <h2 className={classes.title}>SwagUp Warehouse</h2>
      <Grid container justifyContent="space-between" alignItems="center" style={{ margin: '7px 0 24px' }}>
        <p className={classes.subtitle}>Select what product to store in the SwagUp Warehouse</p>
        <a
          href={`${process.env.REACT_APP_MAIN_DOMAIN}pricing#fulfillment`}
          target="_blank"
          className={classes.infoAnchor}
          rel="noreferrer"
        >
          <InfoOutlined className={classes.infoAnchorIcon} />
          Why am I being charged?
        </a>
      </Grid>
      <Grid container direction="column" alignItems="center">
        {order.products.map(product => (
          <WarehouseCard
            key={product.id}
            product={product}
            warehouseInfo={warehouseInfo[product.id]}
            onSizesChange={handleQuantitiesChange(product)}
          />
        ))}
      </Grid>
      <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
        <p className={classes.subtitle}>Total Quantity</p>
        <WarehousePriceTooltip storageCategories={storageCategories} disabled={total === 0}>
          <Grid container alignItems="center">
            <InfoOutlined className={classes.infoIcon} style={{ fontSize: 14, marginRight: 6 }} />
            <p className={classes.subtitle}>Total Storage price</p>
          </Grid>
        </WarehousePriceTooltip>
      </Grid>
      <Grid container justifyContent="space-between">
        <p className={classes.number}>{total}</p>
        <p className={classes.number}>{moneyStr(price)}</p>
      </Grid>
      <Grid container style={{ marginTop: 18 }}>
        <Button
          variant="primary"
          onClick={() => {
            gtm.onClick('Store Selected items in Warehouse');
            handleSubmit();
          }}
          loading={warehouseMutation.isLoading}
          disabled={disabled}
          style={{ height: 56, letterSpacing: 'normal' }}
        >
          Store Selected items in Warehouse
        </Button>
      </Grid>
    </>
  );
};

const Wrapper = ({ open, onClose, onSendQtyToWarehouse }) => {
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={onClose} classes={{ paper: classes.drawerPaper }}>
      <SendToWarehouse onSendQtyToWarehouse={onSendQtyToWarehouse} />
    </Drawer>
  );
};
export default Wrapper;
