import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import { makeStyles, Grid } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useSelector } from 'react-redux';
import { useQueryParams } from '../../../hooks';
import styles from './CollectionSidebar.styles';
import { DEFAULT_COLLECTION_TO_BE_SELECTED } from '../../../utils/constants';

const useStyles = makeStyles(styles);

const CollectionButton = ({ collectionItems, handelShowCollection }) => {
  const query = useQueryParams();
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const getCollections = query.get('collections')?.split(',') || [];

  const { selectedCollections } = useSelector(state => state.catalog);

  const setSelectedItems = selectedCollections => {
    handelShowCollection(selectedCollections);
  };

  useEffect(() => {
    if (getCollections.length > 0) setSelectedItems(getCollections);
  }, []);

  const handleButtonClick = () => {
    setOpen(!open);
  };

  const handleSelectItem = item => {
    const index = selectedCollections.indexOf(item);
    if (index === -1) {
      setSelectedItems([...selectedCollections, item]);
    } else {
      const updatedItems = selectedCollections.filter(selectedItem => selectedItem !== item);
      setSelectedItems(updatedItems);
    }
  };

  const handelSelectAll = () => {
    setSelectedItems(collectionItems);
  };

  const handleClearAll = () => {
    setSelectedItems([DEFAULT_COLLECTION_TO_BE_SELECTED]);
  };

  function isSelected(item) {
    return selectedCollections?.includes(item);
  }

  const [collectionItemsToShow, setCollectionItemsToShow] = useState(10);

  const handleSeeMore = () => {
    setCollectionItemsToShow(collectionItems.length);
  };

  const handleSeeLess = () => {
    setCollectionItemsToShow(10);
  };

  return (
    <Grid container className={classes.collectionWrapper}>
      <Grid onClick={handleButtonClick} className={classes.header}>
        <Typography variant="body2SemiBoldInter" style={{ paddingLeft: 10 }}>
          Collections
        </Typography>
        <Grid style={{ marginRight: 18, position: 'relative', top: '4px' }}>
          {open ? (
            <KeyboardArrowUpIcon style={{ color: '#4A4F54' }} />
          ) : (
            <KeyboardArrowDownIcon style={{ color: '#4A4F54' }} />
          )}
        </Grid>
      </Grid>
      <Grid className={classes.collectionButtonWrapper}>
        <Button variant="text" size="small" style={{ height: 'fit-content', padding: 0 }} onClick={handelSelectAll}>
          <Typography variant="body4SemiBoldInter" className={classes.selectAllButton}>
            See all products
          </Typography>
        </Button>
        <Button variant="text" size="small" style={{ height: 'fit-content' }} onClick={handleClearAll}>
          <Typography variant="body4RegularInter" className={classes.resetButtonText}>
            Reset
          </Typography>
        </Button>
      </Grid>
      <Collapse in={open}>
        <List component="div" disablePadding style={{ display: 'flex', height: 'auto', flexWrap: 'wrap' }}>
          {collectionItems.slice(0, collectionItemsToShow).map((item, index) => (
            <ListItem
              button
              key={index}
              style={{
                width: 'fit-content',
                border: isSelected(item) ? '1px solid #FFFFFF' : '1px solid #D7E4F6',
                borderRadius: '10px',
                backgroundColor: isSelected(item) ? '#125CFF' : '#FFFFFF',
                color: isSelected(item) ? '#FFFFFF' : '#131415',
                margin: '5px',
                fontFamily: 'Inter',
                fontSize: 12,
                fontWeight: 400,
                lineHeight: '16px',
                height: '35px'
              }}
              onClick={() => handleSelectItem(item)}
            >
              {item}
            </ListItem>
          ))}
        </List>
        <Grid item>
          {collectionItems.length > collectionItemsToShow && (
            <Button variant="text" size="small" className={classes.collectionCTA} onClick={handleSeeMore}>
              <Typography variant="body4SemiBoldInter" style={{ color: '#125CFF' }}>
                See more
              </Typography>
            </Button>
          )}
          {collectionItemsToShow > 10 && (
            <Button variant="text" size="small" className={classes.collectionCTA} onClick={handleSeeLess}>
              <Typography variant="body4SemiBoldInter" style={{ color: '#125CFF' }}>
                See less
              </Typography>
            </Button>
          )}
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default CollectionButton;
