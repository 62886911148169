import React from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './styles/Loader';
import { useLeftNavbar } from '../../contexts/leftNavbar';

const useStyles = makeStyles(styles);

const Loader = ({ absolute, transparent, small }) => {
  const leftNavContext = useLeftNavbar();

  const leftNavOpen = !!leftNavContext?.leftNavOpen;

  const classes = useStyles({ absolute, transparent, leftNavOpen });

  return (
    <div className={classes.loading}>
      <img src="/images/public/loader.png" alt="Loader" style={{ width: small ? '42px' : '100px' }} />
    </div>
  );
};

export default Loader;
