import * as React from 'react';
import { Route, Switch, Redirect, useLocation, useParams, useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Grid, Hidden, makeStyles } from '@material-ui/core';
import MockupsGrid from './MockupsGrid';
import SelectedMockup from './SelectedMockup';
import UserInfo from './UserInfo';
import Steps from './Steps';
import BackgroundAdornments from './BackgroundAdornments';
import styles from './styles/onboardingMockupsHome';
import UploadLogo from './UploadLogo';
import { designStatus, getStepByRoute } from './common';
import { getEmployeeShipAddress, joinFields } from '../../../../helpers/utils';
import designsApi from '../../../../apis/swagup/designs';
import apiPaths from '../../../../helpers/apiPaths';
import Loader from '../../../global/Loader';

const refetchTime = 5000;
const steps = ['Upload Logo', 'Select Swag Pack', 'Submit Request', 'Review designs on your Dashboard'];

const useStyles = makeStyles(styles);

const OnboardingMockupsHome = () => {
  const classes = useStyles();
  const [refetchInterval, setRefetchInterval] = React.useState(refetchTime);

  const { designId } = useParams();
  const queryClient = useQueryClient();
  const storedDesign = queryClient.getQueryData([apiPaths.designs, +designId]);

  const history = useHistory();
  const location = useLocation();
  const samplePack = location.state?.samplePack;

  const { data: design, isLoading } = useQuery([apiPaths.designs, +designId], () => designsApi.fetch(designId), {
    enabled: (!storedDesign || storedDesign.status === designStatus.pending) && !!designId,
    refetchInterval,
    initialData: storedDesign,
    onSuccess: data => {
      if (data.status === designStatus.failure) {
        history.replace('/onboarding/mockups/upload-logo', {
          ...location.state,
          samplePack,
          logoWasUploading: true,
          designFailure: true
        });
      }
    }
  });

  const loadingMockups = isLoading || design?.status === designStatus.pending;
  React.useEffect(() => {
    setRefetchInterval(loadingMockups && refetchTime);
  }, [loadingMockups]);

  if (!samplePack) {
    return <Redirect to="/onboarding/welcome" />;
  }

  const designFailure = design?.status === designStatus.failure || location.state?.designFailure;
  const hasCustomLogo = design?.user_provided_logo || location.state?.hasCustomLogo;
  if (designFailure && !location.pathname.includes('upload-logo')) {
    return (
      <Redirect
        to={{ pathname: '/onboarding/mockups/upload-logo', state: { samplePack, designFailure, hasCustomLogo } }}
      />
    );
  }

  const step = getStepByRoute(location.pathname);
  const userInfo = {
    name: joinFields([samplePack.first_name, samplePack.last_name], ' '),
    address: getEmployeeShipAddress(samplePack ?? {}),
    email: samplePack.email
  };

  return (
    <Grid item className={classes.root}>
      <Hidden mdDown>
        <Grid item lg={4} className={classes.leftSide}>
          <BackgroundAdornments />
          <a color="inherit" href="/">
            <img src="/images/public/su-logo.svg" className={classes.logo} alt="logo" />
          </a>
          {(step === 1 || designFailure) && !hasCustomLogo ? (
            <UserInfo userInfo={userInfo} />
          ) : (
            <Steps selected={step} steps={step === 0 || hasCustomLogo ? steps : steps.slice(1)} />
          )}
        </Grid>
      </Hidden>
      <Grid item md={12} lg={8} className={classes.rightSide}>
        <Hidden lgUp>
          <a color="inherit" href="/">
            <img src="/images/public/su-logo.svg" className={classes.logo} alt="logo" />
          </a>
        </Hidden>
        <Switch>
          <Route path="/onboarding/mockups/upload-logo" exact>
            <UploadLogo />
          </Route>
          <Route path="/onboarding/mockups/:designId/:collageId" exact>
            {loadingMockups ? <Loader absolute /> : <SelectedMockup design={design} userName={samplePack.first_name} />}
          </Route>
          <Route path="/onboarding/mockups/:designId">
            <>
              <Hidden lgUp>
                <BackgroundAdornments isMd />
                {((step === 1 && !hasCustomLogo) || designFailure) && <UserInfo userInfo={userInfo} />}
              </Hidden>
              <MockupsGrid collages={design?.collages} loadingMockups={loadingMockups} />
            </>
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
};

export default OnboardingMockupsHome;
