import React, { useRef, useState, useEffect } from 'react';
import { Box, Grid, IconButton, LinearProgress, Snackbar, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { isEmpty } from 'lodash';
import {
  DeleteOutline,
  People,
  DragIndicator,
  RemoveRedEye,
  PhotoFilter,
  CropOriginal,
  CheckCircle
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { useMutation, useQuery } from 'react-query';
import InnerImageZoom from 'react-inner-image-zoom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Img } from '../../global/ImgUtils';
import CustomImageUpload from '../../global/CustomImageUpload';
import styles from './styles/variantsEdit';
import accountProducts from '../../../apis/swagup/accountProducts';
import { Modal } from '../../shared/Modal';
import { CustomTooltip } from '../../products/commonProductsElements';
import apiPaths from '../../../helpers/apiPaths';

const useStyles = makeStyles(styles);

const imageSlots = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

const LifeStyeImagesEdit = ({ images = [], productName, productImage, mainVariant, onChange, onChangeVariant }) => {
  const [imageError, setImageError] = useState();
  const [isDraggingFromIndicator, setIsDraggingFromIndicator] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [outputImage, setOutputImage] = useState();
  const [backgroundRemovalProgress, setBackgroundRemovalProgress] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showBackgroundRemovalProgress, setShowBackgroundRemovalProgress] = useState(false);

  const dragItem = useRef(0);
  const draggedOverItem = useRef(0);

  const handleOnImageUploadUpdate = (url, image) => {
    if (!isEmpty(image) && (image.id || image.idx)) {
      onChange({
        target: {
          name: 'images',
          value: images.map(i => ((i.id && i.id === image.id) || (i.idx && i.idx === image.idx) ? { ...i, url } : i))
        }
      });
    }
  };

  const handleOnImageUploadAdd = url => {
    if (images.length < 5)
      onChange({
        target: {
          name: 'images',
          value: [
            ...images,
            {
              idx: images.length + 1,
              name: `${productName}-variant-${images.length + 1}`,
              url
            }
          ]
        }
      });
  };

  const handleOnMainImageUpload = url => {
    onChange({
      target: {
        name: 'imageUrl',
        value: url
      }
    });

    onChangeVariant({
      ...mainVariant,
      imageUrl: url
    });
  };

  const handleRemoveImage = image => {
    if (!isEmpty(image) && (image.id || image.idx)) {
      onChange({
        target: {
          name: 'images',
          value: images.filter(i => (i.id && i.id !== image.id) || (i.idx && i.idx !== image.idx))
        }
      });
    }
  };

  // handle sort
  const handleSort = () => {
    if (images.length < 0) {
      return;
    }
    console.log('drag over index', productName, dragItem.current);
    if (draggedOverItem.current === 'mainImage' || dragItem.current === 'mainImage') {
      const imagesClone = JSON.parse(JSON.stringify(images));
      let isChange = false;
      if (!isNaN(draggedOverItem.current) && draggedOverItem.current < images.length) {
        [productImage, imagesClone[draggedOverItem.current].url] = [
          imagesClone[draggedOverItem.current].url,
          productImage
        ];
        [productName, imagesClone[draggedOverItem.current].name] = [
          imagesClone[draggedOverItem.current].name,
          productName
        ];
        isChange = true;
      }
      if (!isNaN(dragItem.current) && dragItem.current < images.length) {
        [productImage, imagesClone[dragItem.current].url] = [imagesClone[dragItem.current].url, productImage];
        [productName, imagesClone[dragItem.current].name] = [imagesClone[dragItem.current].name, productName];
        isChange = true;
      }
      if (isChange) {
        onChange({
          target: {
            name: 'images',
            value: [...imagesClone]
          }
        });
        onChange({
          target: {
            name: 'imageUrl',
            value: productImage
          }
        });
        onChangeVariant({
          ...mainVariant,
          imageUrl: productImage
        });
        onChange({
          target: {
            name: 'name',
            value: productName
          }
        });
      }
      return;
    }
    if (images[dragItem.current] && images[draggedOverItem.current]) {
      const imagesClone = JSON.parse(JSON.stringify(images));

      // if images shifted right to left
      if (dragItem.current > draggedOverItem.current) {
        const tempData = JSON.parse(JSON.stringify(imagesClone[dragItem.current]));
        for (let i = draggedOverItem.current; i < dragItem.current; i++) {
          imagesClone[i + 1].name = images[i].name;
          imagesClone[i + 1].url = images[i].url;
          imagesClone[i + 1].description = images[i].description;
        }
        imagesClone[draggedOverItem.current].name = tempData.name;
        imagesClone[draggedOverItem.current].url = tempData.url;
        imagesClone[draggedOverItem.current].description = tempData.description;
      }

      // if images shifted left to right
      if (dragItem.current < draggedOverItem.current) {
        const tempData = JSON.parse(JSON.stringify(imagesClone[dragItem.current]));
        for (let i = dragItem.current; i < draggedOverItem.current; i++) {
          imagesClone[i].name = images[i + 1].name;
          imagesClone[i].url = images[i + 1].url;
          imagesClone[i].description = images[i + 1].description;
        }
        imagesClone[draggedOverItem.current].name = tempData.name;
        imagesClone[draggedOverItem.current].url = tempData.url;
        imagesClone[draggedOverItem.current].description = tempData.description;
      }

      onChange({
        target: {
          name: 'images',
          value: [...imagesClone]
        }
      });
    }
  };

  const imageBackgroundRemovalAction = useMutation(
    ({ imagePath, bucket }) => accountProducts.imageBackgroundRemoval(imagePath, bucket),
    {
      onSuccess: data => {
        const { output_image } = data;
        const outputImage = `${process.env.REACT_APP_STATIC_RESOURCES_HOST}/${output_image}`;
        setOutputImage(outputImage);
      },
      onError: () => alert('Image Background Process Failed')
    }
  );

  const { imageBackgroundRemoval } = useFlags();
  const canRemoveBackground = imageBackgroundRemoval && productImage?.includes('https://swagup-static.swagup.com');

  const backgroundImageRollBack = canRemoveBackground && productImage?.includes('_without_back.');

  const { data } = useQuery(
    [apiPaths.accountProduct, mainVariant?.platformId],
    () => accountProducts.getProduct(mainVariant?.platformId),
    {
      enabled: !!mainVariant?.platformId && showBackgroundRemovalProgress && backgroundImageRollBack,
      onSuccess: data => {
        const { image } = data;
        setOutputImage(image);
      }
    }
  );

  const onConfirmBackgroundRemoval = () => {
    onChange({
      target: {
        name: 'imageUrl',
        value: outputImage
      }
    });

    onChangeVariant({
      ...mainVariant,
      imageUrl: outputImage
    });

    setIsConfirmationOpen(false);
    setShowSnackbar(true);
  };

  const onImageBackgroundRemoval = e => {
    e.stopPropagation();
    if (!backgroundImageRollBack)
      imageBackgroundRemovalAction.mutate({
        imagePath: productImage?.replace(`${process.env.REACT_APP_STATIC_RESOURCES_HOST}/`, ''),
        bucket: productImage.includes('swagup-resource-uploader.s3.amazonaws.com')
          ? process.env.REACT_APP_AWS_UPLOAD_RESOURCES_BUCKET
          : 'swagup-static'
      });
    setShowBackgroundRemovalProgress(true);
    handleBackgroundRemovalProgress(0);
  };

  const handleBackgroundRemovalProgress = progress => {
    if (progress <= 100) {
      setTimeout(() => {
        setBackgroundRemovalProgress(progress + 5);
        handleBackgroundRemovalProgress(progress + 5);
      }, 400);
    } else {
      setShowBackgroundRemovalProgress(false);
      setBackgroundRemovalProgress(0);
      setIsConfirmationOpen(true);
    }
  };

  const classes = useStyles();

  return (
    <Box>
      <Box style={{ paddingTop: 32, position: 'relative' }}>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setShowSnackbar(false);
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            icon={<CheckCircle style={{ color: '#2E7D32', fontSize: '20px' }} />}
            variant="outlined"
            severity="success"
          >
            Changes successfully applied
          </Alert>
        </Snackbar>
        <Typography variant="body3SemiBoldInter" style={{ marginBottom: 12 }}>
          Product life style images
        </Typography>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <CustomImageUpload onChange={handleOnMainImageUpload} className={classes.dragDropZoneMain}>
                <Box
                  className={classes.selectedImage}
                  draggable={!!isDraggingFromIndicator} // Only draggable when isDraggingFromIndicator is true
                  onDragStart={() => {
                    dragItem.current = 'mainImage';
                  }}
                  onDragEnd={() => {
                    handleSort();
                    setIsDraggingFromIndicator(false); // Reset isDraggingFromIndicator
                  }}
                  onDragOver={() => (draggedOverItem.current = 'mainImage')}
                >
                  <Box className="selected-image-overlay" />
                  <Grid container justifyContent="flex-end" className="selected-image-action" spacing={2}>
                    {canRemoveBackground && (
                      <Grid item>
                        <CustomTooltip
                          title={backgroundImageRollBack ? 'Revert to Original Image' : 'Remove Background'}
                          arrow
                          placement="top-start"
                          disableHoverListener={showBackgroundRemovalProgress}
                        >
                          <IconButton className={classes.selectedImageDrag} onClick={onImageBackgroundRemoval}>
                            {backgroundImageRollBack ? <CropOriginal /> : <PhotoFilter />}
                          </IconButton>
                        </CustomTooltip>
                      </Grid>
                    )}
                    <Grid item>
                      <IconButton
                        className={classes.selectedImageDrag}
                        onMouseEnter={() => setIsDraggingFromIndicator(true)}
                        onMouseLeave={() => setIsDraggingFromIndicator(false)}
                      >
                        <DragIndicator />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Img src={productImage} alt={productName} className={classes.image} />
                  {showBackgroundRemovalProgress && (
                    <Box style={{ position: 'relative', bottom: 0 }}>
                      <LinearProgress
                        variant="determinate"
                        value={backgroundRemovalProgress}
                        classes={{
                          colorPrimary: classes.linearColorPrimarySlim,
                          barColorPrimary: classes.linearBarColorPrimary
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </CustomImageUpload>
            </Grid>
            <Grid item xs={7}>
              <Box>
                <Grid container spacing={4}>
                  {imageSlots.map((imageSlot, index) => (
                    <Grid key={imageSlot.id} item xs={4}>
                      <CustomImageUpload
                        onChange={url => handleOnImageUploadUpdate(url, images[imageSlot.id])}
                        className={classes.dragDropZoneUpdate}
                        disabled={isEmpty(images[imageSlot.id])}
                      >
                        <Box
                          className={classes.liveImage}
                          draggable={!!isDraggingFromIndicator} // Only draggable when isDraggingFromIndicator is true
                          onDragStart={() => {
                            dragItem.current = index;
                          }}
                          onDragEnter={() => (draggedOverItem.current = index)}
                          onDragEnd={() => {
                            handleSort();
                            setIsDraggingFromIndicator(false); // Reset isDraggingFromIndicator
                          }}
                          onDragOver={e => e.preventDefault()}
                          style={{ cursor: isEmpty(images[imageSlot.id]?.url) ? 'default' : undefined }}
                        >
                          {!isEmpty(images[imageSlot.id]?.url) && (
                            <>
                              <Box className="selected-image-overlay" />
                              <Grid container justifyContent="flex-end" className="selected-image-action">
                                <Grid item>
                                  <IconButton
                                    className={classes.selectedImageDrag}
                                    onMouseEnter={() => setIsDraggingFromIndicator(true)}
                                    onMouseLeave={() => setIsDraggingFromIndicator(false)}
                                  >
                                    <DragIndicator />
                                  </IconButton>
                                  <IconButton
                                    onClick={e => {
                                      e.stopPropagation();
                                      handleRemoveImage(images[imageSlot.id]);
                                    }}
                                    className={classes.selectedImageDelete}
                                  >
                                    <DeleteOutline className={classes.selectedImageDeleteIcon} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </>
                          )}
                          <Img
                            src={images[imageSlot.id]?.url || '/images/public/nopic.jpg'}
                            alt={images[imageSlot.id]?.name}
                            className={classes.image}
                          />
                        </Box>
                      </CustomImageUpload>
                    </Grid>
                  ))}
                  <Grid item xs={4}>
                    <CustomImageUpload
                      onChange={handleOnImageUploadAdd}
                      disabled={images?.length >= 5}
                      className={classes.dragDropZone}
                    >
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <Box>
                            <Typography variant="body4SemiBoldInter" className={classes.dragDrop}>
                              Drag and drop to upload or <span>browse</span>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body4RegularInter" className={classes.preferedImageTypes}>
                              Accepted file types: .PNG, .GIF, .JPEG, .JPG, .SVG
                            </Typography>
                            <Typography variant="body4RegularInter" className={classes.preferedImageTypes}>
                              Max file size: 10MB
                            </Typography>
                            {imageError && (
                              <Typography variant="body4RegularInter" className={classes.preferedImageError}>
                                Your file could not be uploaded. Please check the accepted file types and file size and
                                try again
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </CustomImageUpload>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal
        open={isConfirmationOpen}
        title="Apply changes"
        onClose={() => setIsConfirmationOpen(false)}
        typeform={classes.modalContent}
        className={classes.modalView}
        actions={
          <Box style={{ padding: '0px 24px 18px 24px' }}>
            <Grid container spacing={3} justifyContent="flex-end" style={{ paddingTop: 32 }}>
              <Grid item>
                <Button size="small" variant="text" onClick={() => setIsConfirmationOpen(false)}>
                  Dismiss
                </Button>
              </Grid>
              <Grid item>
                <Button size="small" variant="primary" onClick={onConfirmBackgroundRemoval}>
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        }
      >
        <Box style={{ width: 400, height: 360 }}>
          <InnerImageZoom
            className="zoomImageConatiner"
            src={outputImage}
            zoomSrc={outputImage}
            zoomScale={1}
            hideHint
          />
          {/* <Img src={outputImage} alt={productName} className={classes.image} /> */}
        </Box>
      </Modal>
    </Box>
  );
};

export default LifeStyeImagesEdit;
