const styles = () => ({
  line: {
    width: '100%',
    height: 1,
    backgroundColor: '#ccc',
    marginTop: 40,
    border: 0
  },
  title: {
    fontFamily: 'Gilroy-bold',
    fontSize: 40,
    color: '#0f2440',
    margin: '40px 0px'
  },
  lazyLoading: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4'
  },
  loaderContainer: {
    position: 'relative',
    marginTop: 50,
    minHeight: 500
  }
});

export default styles;
