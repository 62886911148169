import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CustomTooltip } from '../../products/commonProductsElements';
import styles from './styles/sendSwagConfiguration';
import Img from '../../shared/Img';
import { ellipsisStyles } from '../../shared/styles/commonStyles';
import { SmallSwitch } from '../../pages/orders/requested/shipments/sendSwagFlow/common';

const useStyles = makeStyles(styles);

const IntegrationItem = ({ title, icon, small, selected, product, ellipsisStylesStyle, long, color, size }) => {
  const classes = useStyles({ selected, product });
  const longSize = long ? 9 : 8;
  return (
    <Grid container justifyContent="center" className={classes.integrationSection}>
      <Grid item xs={small ? 6 : longSize}>
        <Box className={classes.integrationSelectionItem}>
          <Grid container alignItems="center" spacing={6}>
            <Grid item>{icon}</Grid>
            <Grid item xs={small ? 6 : 8} style={{ textAlign: 'left', position: 'relative' }}>
              <CustomTooltip
                title={title}
                arrow
                placement="top-start"
                disableHoverListener={!ellipsisStylesStyle || !title || title.length < 17}
              >
                <Typography
                  variant="subtitle2SemiBoldInter"
                  className={classes.integrationItemTitle}
                  style={{ marginBottom: product ? 12 : 0 }}
                >
                  {title}
                </Typography>
              </CustomTooltip>

              <Box className={classes.productTag}>
                {color} / {size}
              </Box>
            </Grid>
            <Grid item style={{ paddingRight: 0, paddingLeft: 0 }}>
              {!product && <ChevronRight style={{ color: '#131415', marginTop: 4 }} />}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const ExecutionType = ({ executionType, selected, handleChange }) => {
  const classes = useStyles();

  return (
    <Box style={{ padding: '24px 36px', border: '1px solid #D6D8DB', borderRadius: 12 }}>
      <FormControlLabel
        labelPlacement="end"
        control={
          <SmallSwitch
            checked={selected}
            onChange={() => handleChange(executionType.type)}
            color="primary"
            name="checkedBtn"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
        label={
          <Box className={classes.shippingMethodLabel}>
            <Typography variant="h6SemiBoldInter">{executionType.title}</Typography>
            <Typography variant="body3RegularInter">{executionType.description}</Typography>
          </Box>
        }
      />
    </Box>
  );
};

const executionType = [
  { id: 1, title: 'Manual check', type: 'Manual', description: 'Ability to review before sending' },
  { id: 2, title: 'Automatic', type: 'Automatic', description: 'Triggers without manual intervention' }
];

const ActionConfigurationExecution = ({ action, onActionUpdate }) => {
  const selectedExecutionType = action.settings?.automatic ? 'Automatic' : 'Manual';

  const classes = useStyles();

  return (
    <Box>
      <Box>
        <Typography variant="h5SemiBoldInter" className={classes.actionTitle} style={{ marginBottom: 24 }}>
          Automation Settings
        </Typography>
        <Typography variant="h6SemiBoldInter" className={classes.actionSubTitle} style={{ marginBottom: 12 }}>
          How would you like to set this automation
        </Typography>
        <Typography variant="subtitle3RegularInter" className={classes.actionSubTitleDesc} style={{ marginBottom: 32 }}>
          Review requests or run on autopilot.
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Grid container spacing={6} justifyContent="space-between">
              {executionType.map(execution => (
                <Grid key={execution.id} item xs={6}>
                  <ExecutionType
                    key={execution.id}
                    executionType={execution}
                    selected={selectedExecutionType === execution.type}
                    handleChange={et =>
                      onActionUpdate({
                        ...action,
                        settings: {
                          ...action.settings,
                          automatic: et === 'Automatic'
                        }
                      })
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ActionConfigurationExecution;
