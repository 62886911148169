const styles = theme => ({
  CreditForShippingCard: {
    border: 'solid 1px #ebeef2',
    borderRadius: 15,
    padding: '30px 26px',
    boxShadow: '0 0px 100px 0 rgba(27, 28, 31, 0.03)'
  },
  StorageCardTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: '#0f2440',
    marginBottom: 10,
    marginLeft: 15,
    [theme.breakpoints.down('xs')]: { textAlign: 'center' }
  },
  StorageCardSubTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440'
  },
  StorageCardDescription: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: '#8d9299',
    marginLeft: 15,
    [theme.breakpoints.down('xs')]: { textAlign: 'center' }
  },
  EstimateCreditHeader: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#434c5e',
    paddingBottom: 20,
    [theme.breakpoints.down('xs')]: { textAlign: 'center' }
  },
  EstimateCreditLabel: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#8d9299',
    textAlign: 'right',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.67,
    letterSpacing: 'normal',
    [theme.breakpoints.down('xs')]: { textAlign: 'center' }
  },
  ShowBreakdown: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#3577d4',
    cursor: 'pointer',
    textAlign: 'right',
    paddingBottom: 7,
    [theme.breakpoints.down('xs')]: { textAlign: 'center' }
  },
  IconContainer: {
    width: 78,
    height: 78,
    borderRadius: 4,
    textAlign: 'left',
    [theme.breakpoints.only('sm')]: { padding: '0 20px 0 0' },
    [theme.breakpoints.down('xs')]: { margin: 'auto' }
  },
  StorageCardActions: {
    textAlign: 'right',
    paddingTop: 25
  },
  BreakdownTable: {
    paddingTop: 40
  },
  TableTitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#434c5e'
  },
  SubTitle: {
    opacity: 0.54,
    fontFamily: 'Roboto',
    fontSize: 11,
    color: '#434c5f',
    borderBottom: '1px solid #d4d9e2'
  },
  NoBorder: {
    border: 'none'
  },
  RightBorder: {
    borderRight: '1px solid #d4d9e2'
  },
  LeftBorder: {
    borderLeft: '1px solid #d4d9e2'
  },
  TopBorder: {
    borderTop: '1px solid #d4d9e2'
  },
  TableCell: {
    padding: '0px 4px'
  },
  ItemCell: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434c5f',
    borderRight: '1px solid #d4d9e2',
    padding: '24px 4px'
  },
  TotalCell: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434c5f',
    borderLeft: '1px solid #d4d9e2',
    lineHeight: 1.43,
    padding: '24px 4px'
  },
  WhyBeingCharged: {
    fontFamily: 'Roboto',
    paddingLeft: '24px !important',
    fontSize: 11,
    color: '#3577d4',
    cursor: 'pointer'
  },
  BoldText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434c5f',
    lineHeight: 1.43
  },
  RegularText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#434c5f',
    lineHeight: 1.43,
    padding: '24px 4px'
  },
  Notice: {
    fontFamily: 'Roboto',
    opacity: 0.54,
    fontSize: 12,
    textAlign: 'right',
    color: '#434c5f',
    paddingTop: 12
  },
  QuantityContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 40,
      justifyContent: 'center'
    }
  },
  closeButton: {
    borderRadius: 15,
    backgroundColor: '#ffffff',
    width: 14,
    height: 14,
    color: '#0f2440',
    '&&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  closeIcon: {
    marginTop: -12
  }
});

export default styles;
