import log from '../logger';

// localStorage state expires after 15 days (in milliseconds)
const validStateTimeLapse = 15 * 24 * 60 * 60 * 1000;

// appStateVersion must be incremented each time a breaking change is made
// to app state, compared to the one possibly found in localStorage
const appStateVersion = 1;

const daysHoursMinsSecs = milliseconds => {
  const s = {
    days: 60 * 60 * 24,
    hours: 60 * 60,
    mins: 60,
    secs: 1
  };
  let diff = Math.abs(milliseconds / 1000);
  const r = {};
  Object.entries(s).forEach(([key, val]) => {
    r[key] = Math.floor(diff / val);
    diff -= r[key] * val;
  });
  return r;
};

const logExpireTime = (timeLapse, validTimeLapse) => {
  const expireTime = daysHoursMinsSecs(timeLapse - validTimeLapse);
  if (timeLapse > validTimeLapse) {
    log.debug('localStorage state expired', expireTime, 'ago');
  } else {
    log.debug('localStorage state has not expired,', expireTime, 'left');
  }
};

const hasStateExpired = state => {
  try {
    const { timeStamp } = state;
    if (timeStamp) {
      log.debug('state timeStamp:', timeStamp);
      const timeLapse = new Date().getTime() - Date.parse(timeStamp);
      logExpireTime(timeLapse, validStateTimeLapse);
      return timeLapse > validStateTimeLapse;
    }
    log.debug('state timeStamp not found');
  } catch (err) {
    log.debug('hasStateExpired error:', err);
  }
  return true;
};

const isStateVersionOk = state => {
  const { version } = state;
  const isVersionOk = version === appStateVersion;
  log.debug(
    `localStorage state version: ${version}`,
    isVersionOk ? 'matches' : "doesn't match",
    `with app's one: ${appStateVersion}`
  );
  return isVersionOk;
};

export const loadState = () => {
  if (sessionStorage.getItem('loadStateFromLocalStorage')) {
    log.debug(
      'loadStateFromLocalStorage state: ',
      sessionStorage.getItem('loadStateFromLocalStorage'),
      process.env.NODE_ENV
    );
    sessionStorage.removeItem('loadStateFromLocalStorage');
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState) {
        const state = JSON.parse(serializedState);
        if (isStateVersionOk(state) && !hasStateExpired(state)) {
          return state;
        }
      } else {
        log.debug('localStorage state not found');
      }
    } catch (err) {
      log.debug('loadState error:', err);
    }
  }
  return undefined;
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify({
      ...state,
      timeStamp: new Date(),
      version: appStateVersion
    });
    localStorage.setItem('state', serializedState);
    log.debug('localStorage state updated');
  } catch (err) {
    log.debug('saveState error:', err);
  }
};
