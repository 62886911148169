import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = theme => ({
  orderImg: {
    objectFit: 'contain',
    borderRadius: 8,
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.04)'
  },
  packTitle: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    lineHeight: 1.4,
    color: '#0f2440'
  },
  infoOrderText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 1.3,
    color: '#8d9299',
    paddingRight: 5
  },
  qtyOrderText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 12,
    color: '#434d5c',
    paddingTop: 10
  },
  qtyX: {
    fontFamily: 'Gilroy-Medium',
    color: '#8d9299'
  },
  statusText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 0.92,
    color: '#8d9299',
    paddingTop: 10,
    width: 180,
    margin: 'auto',
    '& strong': {
      color: '#0b1829',
      marginLeft: 1
    }
  },
  orderLine: {
    height: 1,
    opacity: 0.3,
    border: 'solid 1px #8f8f8f',
    marginTop: 5,
    marginLeft: -80,
    width: '100%',
    [theme.breakpoints.between('md', 'lg')]: { marginLeft: -50 },
    [theme.breakpoints.between('sm', 'md')]: { marginLeft: -45 },
    [theme.breakpoints.down('xs')]: { marginLeft: 0, marginTop: 23 }
  },
  shipmentLine: {
    height: 1,
    opacity: 0.3,
    border: 'solid 1px #8f8f8f',
    marginLeft: 85,
    width: '90%',
    [theme.breakpoints.between('lg', 'xl')]: { marginLeft: 77 },
    [theme.breakpoints.down('lg')]: { marginLeft: 73 },
    [theme.breakpoints.down('md')]: { marginLeft: 85 },
    [theme.breakpoints.down('xs')]: { marginLeft: 73 }
  },
  proofDesignStatus: {
    padding: '6px',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: 14,
    width: 140,
    [theme.breakpoints.down('xs')]: { marginLeft: -13 }
  },
  proofImg: {
    width: 256,
    height: 256,
    objectFit: 'contain',
    textAlign: 'center',
    borderRadius: 15,
    [theme.breakpoints.between('md', 'lg')]: { width: 200, height: 200 },
    [theme.breakpoints.down('sm')]: { width: 256, height: 256 },
    [theme.breakpoints.down('xs')]: { width: 200, height: 200 }
  },
  carouselContainer: {
    width: '100%',
    height: '100%'
  },
  approveText: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    lineHeight: 1.2,
    color: '#3577d4',
    textTransform: 'none'
  },
  requestChangesLinkText: {
    fontFamily: 'Gilroy-bold',
    fontSize: 14,
    lineHeight: 1.2,
    color: '#3577d4',
    textTransform: 'none'
  },
  prevButtonClass: {
    cursor: 'pointer',
    position: 'absolute',
    top: '30%',
    fontSize: 18,
    transition: '0.6s ease',
    userSelect: 'none',
    left: 0,
    width: 24,
    height: 24,
    borderRadius: 5,
    backgroundColor: '#e9f0fa',
    padding: 0,
    color: '#3577d4',
    paddingTop: 1,
    transform: 'scale(-1, 1)',
    border: 'none'
  },
  prevButtonClassDisabled: {
    cursor: 'default',
    position: 'absolute',
    top: '30%',
    fontSize: 18,
    transition: '0.6s ease',
    userSelect: 'none',
    left: 0,
    width: 24,
    height: 24,
    borderRadius: 5,
    backgroundColor: '#e9f0fa',
    padding: 0,
    color: '#d2d9d9',
    paddingTop: 1,
    transform: 'scale(-1, 1)',
    border: 'none'
  },
  nextButtonClass: {
    cursor: 'pointer',
    position: 'absolute',
    top: '30%',
    fontSize: 18,
    transition: '0.6s ease',
    userSelect: 'none',
    right: 0,
    width: 24,
    height: 24,
    borderRadius: 5,
    padding: 0,
    backgroundColor: '#e9f0fa',
    color: '#3577d4',
    paddingTop: 1,
    border: 'none'
  },
  nextButtonClassDisabled: {
    cursor: 'default',
    position: 'absolute',
    top: '30%',
    fontSize: 18,
    transition: '0.6s ease',
    userSelect: 'none',
    right: 0,
    width: 24,
    height: 24,
    borderRadius: 5,
    padding: 0,
    backgroundColor: '#e9f0fa',
    color: '#d2d9d9',
    paddingTop: 1,
    border: 'none'
  },
  shipmentSeparator: {
    paddingLeft: 20
  },
  progressBarRoot: {
    height: 4,
    marginTop: 12,
    borderRadius: 20,
    backgroundColor: '#d2d9d9'
  },
  progressBar: {
    backgroundColor: '#9846dd'
  },
  svgShipmentImage: {
    [theme.breakpoints.between('md', 'lg')]: { width: '95%', height: '95%' },
    [theme.breakpoints.down('xs')]: { width: '100%', height: '100%' }
  },
  separateOrderContainer: {
    paddingTop: 10,
    paddingBottom: 5
  },
  separateOrderNoContainer: {
    paddingBottom: 5
  },
  separateOrderItemContainer: {
    marginLeft: 20,
    paddingTop: 10,
    [theme.breakpoints.between('md', 'lg')]: { marginLeft: 25 }
  },
  separateOrderItemInfo: {
    marginTop: 15
  },
  separateOrderItemIcon: {
    marginTop: 5,
    marginLeft: -3,
    [theme.breakpoints.between('md', 'lg')]: { width: '95%', height: '95%' },
    [theme.breakpoints.down('sm')]: { width: '80%', height: '80%' },
    [theme.breakpoints.down('xs')]: { width: '100%', height: '100%' }
  },
  separateOrderItemStatus: {
    paddingTop: 5
  },
  separateProofButtonsContainer: {
    marginTop: 21,
    marginBottom: 10
  },
  separateProofStatus: {
    paddingBottom: 12,
    marginTop: 25
  },
  separateProofProductName: {
    paddingBottom: 5
  },
  separateShipmentInfoContainer: {
    marginLeft: 20,
    paddingTop: 10,
    [theme.breakpoints.between('md', 'lg')]: { marginLeft: 25 }
  },
  separateInfo: {
    marginTop: 15
  },
  separateShipmentStatus: {
    paddingTop: 5,
    marginRight: -3
  }
});

export default styles;
