export default theme => ({
  text: {
    color: '#000',
    marginTop: 20,
    fontFamily: 'Gilroy-Medium'
  },
  imageContainer: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginBottom: 40
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start'
    }
  }
});
