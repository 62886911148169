import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Box, CircularProgress, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import { ChevronRight, EditOutlined, RemoveCircleOutline } from '@material-ui/icons';
import clsx from 'clsx';
import { Button, Typography } from '@swagup-com/components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { isPack } from '../../../../../helpers/utils';
import accountProductsApi from '../../../../../apis/swagup/accountProducts';
import { scrollBar, ellipsisStyles } from '../../../../shared/styles/commonStyles';
import { proofStatusStylesNew, StatusChip, getButtonText } from '../../../../global/proofsCommon';
import { useOrder } from '../OrderContext';
import { orderApi } from '../../../../../apis/swagup';
import { changeColorLightness } from '../../../../shared/styles/utils';
import gtm from '../../../../../utils/gtm';
import apiPaths from '../../../../../helpers/apiPaths';
import ProductImage from '../common/ProductImage';
import AddProductsDrawer from './AddProductsDrawer';
import { STATUS_CHANGED } from '../../../../../actions/types';
import { statuses } from '../../../../../reducers/globalNotificationReducer';
import RemoveProductModalSimple from './RemoveProductModalSimple';
import CancelOrderModal from '../summary/CancelOrderModal';

const name = {
  height: 26,
  fontFamily: 'Gilroy-SemiBold',
  fontSize: 16,
  lineHeight: 1.63,
  letterSpacing: 'normal',
  color: '#0b1829',
  ...ellipsisStyles
};

const useStyles = makeStyles({
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '34px',
    color: '#0b1829',
    margin: '0 0 24px 0'
  },
  container: {
    position: 'relative',
    marginBottom: 24,
    '& .carousel-button, & .carousel-image': {
      transition: 'all 0.3s ease-in'
    },
    '&:not(:hover) .carousel-button, &:not(:hover) .editBtn': {
      visibility: 'hidden',
      opacity: 0
    },
    '&:hover .carousel-image': {
      border: '0 solid #ffffff',
      boxShadow: '0 8px 24px 0 rgba(27, 28, 31, 0.05)'
    },
    '& .remove-button-container': {
      display: 'none'
    },
    '&:hover .remove-button-container': {
      display: 'block'
    }
  },
  chip: {
    padding: '6px 10px',
    '& > span': {
      fontSize: 10
    }
  },
  productsContainer: {
    ...scrollBar,
    overflow: 'auto',
    paddingRight: 10,
    paddingBottom: 10
  },
  name,
  inputName: {
    ...name,
    padding: 0,
    margin: 0
  },
  removeButton: {
    height: 'auto',
    padding: '6px 8px',
    fontSize: 8,
    color: '#f44336',
    '&:hover': { color: changeColorLightness('#f44336') }
  },
  type: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#0b1829',
    marginTop: 8
  },
  link: {
    display: 'inline-block',
    marginTop: 24,
    marginLeft: '-3px',
    padding: '3px 8px'
  },
  linkText: {
    fontFamily: 'Inter',
    fontSize: 12,
    letterSpacing: 'normal',
    display: 'flex',
    alignItems: 'center',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  editBtn: {
    padding: 0,
    transition: 'opacity 0.3s ease-out',
    backgroundColor: 'transparent !important'
  },
  editIcon: {
    width: 16,
    height: 16,
    color: '#0b1829'
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#f0f2f5',
    marginTop: 26,
    marginLeft: 12,
    marginRight: 12
  },
  addButton: {
    height: 36,
    fontSize: 12,
    color: '#3577d4',
    marginRight: 50
  },
  addProducts: {
    height: 50,
    width: 160,
    border: 'none',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  cancelOrder: {
    height: 50,
    width: 140,
    border: 'none',
    padding: 10
  }
});

export const Product = ({
  opportunityId,
  product,
  linkToDetails,
  showSeparator,
  fromProducts,
  onRemoveProduct,
  canRemoveProduct
}) => {
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const classes = useStyles();
  const [isEditing, setIsEditing] = React.useState(false);
  const [currentName, setCurrentName] = React.useState(product.name);

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(accountProductsApi.editName, {
    onSuccess: data => {
      queryClient.setQueryData([apiPaths.opportunities, opportunityId], old => ({
        ...old,
        products: old.products.map(op =>
          op.product.id === product.id
            ? {
                ...op,
                product: {
                  ...op.product,
                  name: data.name
                }
              }
            : op
        )
      }));
    },
    onError: () => setCurrentName(product.name)
  });

  const handleChange = e => setCurrentName(e.target.value);

  const handleEdit = () => {
    if (product.name !== currentName) {
      mutate({ prodId: product.id, name: currentName });
    }
    setIsEditing(false);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 27) {
      setCurrentName(product.name);
      setIsEditing(false);
    } else if (event.keyCode === 13) {
      handleEdit();
    }
  };

  const handleRemoveProduct = () => {
    onRemoveProduct(product);
    setRemoveModalOpen(false);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item style={{ width: 160 }}>
        <ProductImage product={product} />
      </Grid>
      <Grid item xs style={{ minWidth: 0, marginLeft: 8 }}>
        <StatusChip
          label={product.new_status}
          status={proofStatusStylesNew[product.new_status]}
          className={classes.chip}
        />
        <Grid container style={{ marginTop: 18 }}>
          <Grid item style={isEditing ? { width: '100%' } : { maxWidth: 'calc(100% - 26px)' }}>
            {isEditing ? (
              <TextField
                type="text"
                disabled={!isEditing}
                value={currentName}
                autoFocus
                onChange={handleChange}
                onBlur={handleEdit}
                onKeyDown={handleKeyDown}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  inputProps: { maxLength: 100 },
                  classes: { input: classes.inputName }
                }}
              />
            ) : (
              <p className={classes.name}>{currentName}</p>
            )}
          </Grid>
          {!isEditing && (
            <Grid item className={classes.editContainer}>
              {isLoading ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <IconButton
                  className={clsx(classes.editBtn, { editBtn: true })}
                  onClick={() => setIsEditing(true)}
                  disableRipple
                  disableFocusRipple
                >
                  <EditOutlined className={classes.editIcon} />
                </IconButton>
              )}
            </Grid>
          )}
        </Grid>
        <p className={classes.type}>{isPack(product.record_type) ? '📦 Pack' : '👕 Product'}</p>
        <Link
          to={{ pathname: linkToDetails, state: { fromProducts } }}
          onClick={() => gtm.onClick(getButtonText(product.status))}
          className={classes.link}
        >
          <span className={classes.linkText}>
            {getButtonText(product.status)} <ChevronRight style={{ width: 16, height: 16, marginLeft: 8 }} />
          </span>
        </Link>
      </Grid>
      <Grid item>
        {canRemoveProduct && (
          <Grid container direction="column" style={{ height: '100%' }}>
            <Grid item xs />
            <Grid item>
              <Box className="remove-button-container">
                <Button
                  variant="text"
                  size="small"
                  className={classes.removeButton}
                  onClick={() => setRemoveModalOpen(true)}
                >
                  Remove
                  <RemoveCircleOutline style={{ fontSize: 14, marginLeft: 4 }} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>

      {showSeparator && <div className={classes.separator} />}

      <RemoveProductModalSimple
        isOpen={removeModalOpen}
        product={product}
        onClose={() => setRemoveModalOpen(false)}
        onRemoveProduct={handleRemoveProduct}
      />
    </Grid>
  );
};

const ReviewProducts = ({ fromProducts }) => {
  const classes = useStyles();
  const order = useOrder();
  const history = useHistory();
  const { id } = useParams();
  const products = order?.products ?? [];
  const { addProductsCta, cancelOrderCta } = useFlags();

  const [openProductsDrawer, setOpenProductsDrawer] = useState(false);
  const [openCancelOrder, setOpenCancelOrder] = useState(false);

  const dispatch = useDispatch();

  const dispatchSuccess = message => dispatch({ type: STATUS_CHANGED, payload: { status: statuses.success, message } });
  const dispatchError = message => dispatch({ type: STATUS_CHANGED, payload: { status: statuses.error, message } });
  const dispatchLoading = message => dispatch({ type: STATUS_CHANGED, payload: { status: statuses.pending, message } });

  const queryClient = useQueryClient();

  const mutation = useMutation(orderApi.updateOpportunityProduct, {
    onMutate: () => {
      queryClient.cancelQueries([apiPaths.opportunities, order.id]);
    },
    onSuccess: async () => {
      dispatchSuccess('Product successfully removed');
      await Promise.allSettled([
        queryClient.invalidateQueries([apiPaths.opportunities, order.id]),
        queryClient.invalidateQueries(apiPaths.shipmentGroups(order.id)),
        queryClient.invalidateQueries(apiPaths.warehouseProofs(order.id))
      ]);
    },
    onError: () => {
      dispatchError('Product removal failed');
    }
  });

  const { mutate: deleteOpportunity, isLoading: isUpdatingOrder } = useMutation(
    data => orderApi.deleteOpportunity(id),
    {
      onMutate: () => {
        setOpenCancelOrder(false);
        dispatchLoading('Canceling Order');
      },
      onSuccess: () => {
        history.push('/orders-requested');
        queryClient.invalidateQueries([apiPaths.opportunities]);
        dispatchSuccess('Order successfully deleted');
      },
      onError: () => dispatchError('Failed to delete order')
    }
  );

  const handleRemoveProduct = product => {
    dispatchLoading('Updating order...');
    const restProducts = products.filter(p => p.product.id !== product.id);
    mutation.mutate({ id: order.id, products: restProducts });
  };

  const handelOpenCancelOrder = () => {
    setOpenCancelOrder(true);
  };

  return (
    <Grid container direction="column" data-testid="title-and-products" style={{ height: '100%' }}>
      <Grid>
        <Grid item container justifyContent="space-between" alignItems="center" style={{ marginBottom: 24 }}>
          <Grid item>
            <Typography variant="subtitle1SemiBoldInter" style={{ fontFamily: 'Gilroy-Bold', fontWeight: 500 }}>
              Products
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between" alignItems="center" style={{ width: 'auto', gap: 15 }}>
            {addProductsCta && (
              <Grid item style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', borderRadius: 10 }}>
                <Button className={classes.addProducts} onClick={() => setOpenProductsDrawer(true)}>
                  <AddCircleOutlineIcon />
                  <Typography variant="buttonSmallInter" style={{ color: '#3577D4' }}>
                    Add Products
                  </Typography>
                </Button>
              </Grid>
            )}
            {cancelOrderCta && (
              <Grid item>
                <Button className={classes.cancelOrder} onClick={handelOpenCancelOrder}>
                  <Typography variant="buttonSmallInter" style={{ color: '#F44336' }}>
                    Cancel Order
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs className={classes.productsContainer}>
          <Grid>
            {products.map((p, i) => (
              <Product
                key={p.id}
                opportunityId={p.opportunity}
                product={{ ...p.product, sizes: p.sizes }}
                linkToDetails={`/proof-details/${p.id}/${order.id}`}
                showSeparator={i !== products.length - 1}
                fromProducts={fromProducts}
                onRemoveProduct={handleRemoveProduct}
                canRemoveProduct={addProductsCta}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>

      <AddProductsDrawer
        open={openProductsDrawer}
        onClose={() => setOpenProductsDrawer(false)}
        excludedProducts={products.map(product => product.product.name)}
      />
      <CancelOrderModal
        isOpen={openCancelOrder}
        onClose={() => setOpenCancelOrder(false)}
        deleteOpportunity={() => deleteOpportunity()}
      />
    </Grid>
  );
};

export default ReviewProducts;
