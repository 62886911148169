import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { CenteredGrid, Helmet, Link } from '../shared';
import { imageSrcSet } from '../../helpers/utils';
import tags from '../../apis/seoTags';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: 110,
    maxWidth: '100vw',
    minHeight: 'calc(100vh - 122px)',
    [theme.breakpoints.down('sm')]: { minHeight: 'calc(100vh - 86px)' }
  },
  notFoundImage: {
    width: '100%',
    maxWidth: 525,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: { width: '75%' }
  },
  shadow: {
    marginTop: -10,
    width: 270,
    height: 7,
    opacity: 0.5,
    filter: 'blur(25px)',
    backgroundColor: '#000000'
  },
  headerText: {
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: 36,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440',
    margin: 0,
    [theme.breakpoints.down('xs')]: { fontSize: 20 }
  },
  needHelpText: {
    textAlign: 'center',
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#868a8f',
    margin: 0,
    [theme.breakpoints.down('xs')]: { fontSize: 12 }
  },
  email: {
    fontWeight: 600,
    color: '#3577d4'
  },
  backgroundCircle: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: { display: 'none' }
  }
}));

const BackgroundCircle = ({ color, size, top, bottom, left, right, opacity, outlined }) => {
  const style = {
    backgroundColor: outlined ? 'transparent' : color,
    width: size,
    height: size,
    borderRadius: size / 2,
    opacity,
    zIndex: -1
  };

  if (top) style.top = top;
  if (bottom) style.bottom = bottom;
  if (left) style.left = left;
  if (right) style.right = right;

  if (outlined) style.border = `32px ${color} solid`;

  const classes = useStyles();

  return <div className={classes.backgroundCircle} style={style} />;
};

const BackgroundAdornments = () => (
  <Grid
    container
    style={{
      zIndex: -1,
      position: 'absolute',
      width: '100%',
      overflow: 'hidden',
      top: 0,
      bottom: 0
    }}
  >
    <BackgroundCircle size={330} color="#9846dd" top={-223} left={110} opacity={0.25} />
    <BackgroundCircle size={570} color="#134283" top={-240} right={-200} opacity={0.03} outlined />
    <BackgroundCircle size={66} color="#134283" top={440} right={-15} opacity={0.03} />
    <BackgroundCircle size={170} color="#9846dd" top={495} right={196} opacity={0.03} />
    <BackgroundCircle size={36} color="#134283" top={650} left={97} opacity={0.03} />
    <BackgroundCircle size={170} color="#134283" top={480} left={-120} opacity={0.04} />
  </Grid>
);

const NotFoundPage = ({ backToDashboard }) => {
  const classes = useStyles();

  return (
    <>
      <Helmet tags={tags.notFoundPage} />
      <BackgroundAdornments />
      <CenteredGrid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.container}
      >
        <img srcSet={imageSrcSet('/images/notFoundPage/404.png')} alt="Error 404" className={classes.notFoundImage} />
        <Grid item className={classes.shadow} />

        <Grid item style={{ marginTop: 50 }}>
          <p className={classes.headerText}>Woah! You&apos;ve ventured into uncharted territory</p>
        </Grid>
        <Grid item container justifyContent="center" style={{ marginTop: 17 }}>
          <p className={classes.needHelpText}>
            If you need help, reach out to our support team&nbsp;
            <a href="https://support.swagup.com/en" target="_blank" rel="noreferrer">
              here
            </a>
          </p>
        </Grid>
        <Grid item style={{ marginTop: 41 }}>
          <Button variant="primary" component={Link} to="/" absolute={!backToDashboard} style={{ width: 192 }}>
            Back to Home
          </Button>
        </Grid>
      </CenteredGrid>
    </>
  );
};

export default NotFoundPage;
