import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Typography } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import CloseButton from '../../../../modals/CloseButton';
import { Img } from '../../../../global/ImgUtils';
import { productImageBasedOnStatus } from '../../../../global/proofsCommon';
import { imageSrcSet, sumByQuantity } from '../../../../../helpers/utils';
import { useProduct } from '../common/ProductContext';
import api from '../../../../../apis/swagup/order';
import { useOrder } from '../OrderContext';
import { greyRoundBorder } from '../../../../shared/styles/commonStyles';
import apiPaths from '../../../../../helpers/apiPaths';

const useStyles = makeStyles({
  paper: {
    minWidth: 780,
    padding: '10px 0',
    overflowY: 'visible'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: '#0b1829',
    textAlign: 'center'
  },
  subtitle: {
    maxWidth: '80%',
    marginTop: 12,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    color: '#787b80',
    textAlign: 'center'
  },
  productDetails: {
    ...greyRoundBorder,
    padding: 16,
    maxWidth: '50%',
    marginTop: 30
  },
  productName: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    color: '#0b1829'
  },
  productQty: {
    marginTop: 4,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#787b80'
  },
  keepButton: {
    width: 208,
    height: 56
  },
  removeButton: {
    marginTop: 10,
    width: 208,
    height: 56,
    color: '#f44336',
    backgroundColor: 'transparent',
    '&:hover': {
      color: '#f44336',
      backgroundColor: '#ffe7e6'
    },
    '&.MuiButton-contained.Mui-disabled': {
      color: '#f44336',
      backgroundColor: '#fff'
    }
  }
});

const RemoveProductModal = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { product, restProducts } = useProduct();
  const order = useOrder();
  const mutation = useMutation(api.updateOpportunityProduct, {
    onMutate: () => {
      queryClient.cancelQueries([apiPaths.opportunities, order.id]);
    },
    onSuccess: async () => {
      if (order.products.length === 1) {
        history.push('/orders-requested');
      } else {
        await Promise.allSettled([
          queryClient.invalidateQueries([apiPaths.opportunities, order.id]),
          queryClient.invalidateQueries(apiPaths.shipmentGroups(order.id)),
          queryClient.invalidateQueries(apiPaths.warehouseProofs(order.id))
        ]);
      }
    }
  });

  return (
    <Dialog classes={{ paper: classes.paper }} open={isOpen} onClose={onClose}>
      <DialogTitle aria-labelledby="remove-contacts-dialog" style={{ minHeight: 110 }}>
        <Grid container justifyContent="flex-end">
          <CloseButton onClose={onClose} />
        </Grid>
        <Grid container justifyContent="center" style={{ marginTop: -135 }}>
          <img srcSet={imageSrcSet('/images/orders/warning.png')} alt="Warning" width="150" height="136" />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <p className={classes.title}>Are you sure want to remove this product from the Order?</p>
          <p className={classes.subtitle}>
            This item may be associated with shipments you&apos;ve created in this order, deleting this item will also
            remove it from associated shipments.
          </p>
          <Grid item container alignItems="center" className={classes.productDetails}>
            <Img
              src={productImageBasedOnStatus(product.product, 78, 78)}
              alt={product.product.name}
              width={48}
              height={48}
              style={{ ...greyRoundBorder }}
            />
            <Grid item container direction="column" xs style={{ marginLeft: 16 }}>
              <p className={classes.productName}>{product.product.name}</p>
              <p className={classes.productQty}>
                Qty: <span style={{ marginLeft: 3 }}>{sumByQuantity(product.sizes)}</span>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column" alignItems="center" style={{ marginTop: 20 }}>
          <Button variant="primary" disabled={mutation.isLoading} onClick={onClose} className={classes.keepButton}>
            <Typography variant="button">Keep this product</Typography>
          </Button>
          <Button
            variant="alert"
            disabled={mutation.isLoading}
            onClick={() => mutation.mutate({ id: order.id, products: restProducts })}
            className={classes.removeButton}
            loading={mutation.isLoading}
          >
            <Typography variant="button">Remove</Typography>
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveProductModal;
