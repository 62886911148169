import React, { useEffect, useState } from 'react';
import { Grid, withStyles, Fab, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { connect, useDispatch } from 'react-redux';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import { useHistory, useParams } from 'react-router-dom';
import gtm from '../../utils/gtm';
import toErrorPage from '../../helpers/toErrorPage';
import { Breadcrumbs, Helmet } from '../shared';
import tags from '../../apis/seoTags';
import Loader from '../global/Loader';
import styles from './styles/MultishippingOverview';
import {
  fetchStorageCategories,
  fetchIndividualPackPrices,
  fetchStoragePrices,
  saveCredit,
  setReorderProducts,
  createShipment
} from '../../actions';
import MultishippingCardOverview, { ItemsDetailsList, AddedItem } from './MultishippingCardOverview';
import StorageBreakdownModal from './StorageBreakdownModal';
import CheckoutSideBar from './CheckoutSidebar';
import { sumByQuantityParseInt } from '../../helpers/utils';
import { updateProofQuantitiesByDirectory, calculateTotalShipment, getWarehouseProducts } from './MultishippingCommon';
import { getProductSizes, getStoragePrice } from './common';
import { useShippingCutoffHour, useSizes } from '../../hooks';
import { useOrder } from '../pages/orders/requested/OrderContext';
import { buildProofAlikeObject, getDirectoryOrders } from '../pages/orders/requested/common/utilsOrder';

export const ToWarehouseCard = ({
  classes,
  shippingElements,
  items,
  individualPackPrices,
  storagePrices,
  storageCategories
}) => {
  const [open, setOpen] = useState(false);
  const [openBreakdown, setOpenBreakdown] = useState(false);
  const toggleOpenModal = () => setOpenBreakdown(isOpen => !isOpen);
  const toggleOpenDropDown = () => setOpen(isOpen => !isOpen);

  const products = getWarehouseProducts(shippingElements, items);
  return (
    <div className={classes.MultiShippingCardOverview}>
      <Helmet tags={tags.multiShippingOverview} />
      <div className={open ? classes.CardHeaderBorder : classes.CardHeader}>
        <Grid container>
          <Grid item xs={11}>
            <p className={classes.HeaderTitle}>Address</p>
            <div>
              <img
                style={{ paddingRight: 16, verticalAlign: 'middle' }}
                src="/images/shipping/warehouseIcon.svg"
                alt="warehouse Icon"
              />
              <span className={classes.HeaderContent} style={{ verticalAlign: 'middle' }}>
                SwagUp warehouse{' '}
                <span className={classes.OpenBreakdown} role="presentation" onClick={toggleOpenModal}>
                  How will I be charged?
                </span>
              </span>
            </div>
          </Grid>
          <Grid item xs={1} align="right">
            <IconButton className={classes.button} aria-label="DropDown" onClick={toggleOpenDropDown}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div className={classes.CardContent}>
        {open ? (
          <ItemsDetailsList classes={classes} products={products} toWarehouse />
        ) : (
          <div className={classes.ColapseContentContainer}>
            <Grid container>
              {products &&
                products.map(prod => <AddedItem key={prod.id} classes={classes} product={prod} toWarehouse />)}
            </Grid>
          </div>
        )}
      </div>
      <StorageBreakdownModal
        open={openBreakdown}
        onClose={toggleOpenModal}
        individualPackPrices={individualPackPrices}
        storagePrices={storagePrices}
        storageCategories={storageCategories}
      />
    </div>
  );
};

const MultishippingOverview = props => {
  const { classes, shippingElements, items } = props;
  const [individualPackPrices, setIndividualPackPrices] = useState({});
  const [storagePrices, setStoragePrice] = useState([]);
  const [storageCategories, setStorageCategories] = useState([]);
  const [storageTotal, setStorageTotal] = useState(0);

  const history = useHistory();
  const fetchStoragePricesData = async () => {
    const response = await props.fetchStoragePrices();
    if (response.result === 'ok') {
      setStoragePrice(response.data.results);
    } else {
      toErrorPage(response.result, history);
    }
  };

  const fetchIndividualPackPricesData = async () => {
    const response = await props.fetchIndividualPackPrices();
    if (response.result === 'ok') {
      setIndividualPackPrices(response.data.results[0]);
    } else {
      toErrorPage(response.result, history);
    }
  };

  const fetchStorageCategoriesData = async () => {
    const response = await props.fetchStorageCategories();
    if (response.result === 'ok') {
      setStorageCategories(response.data.results);
    } else {
      toErrorPage(response.result, history);
    }
  };

  // const sendCredit = credit => {
  //   const newCredit = credit + calculateTotalShipment(shippingElements);
  //   setSelectedCredit(newCredit);
  //   props.saveCredit(newCredit);
  // };

  const shippingCutoffHour = useShippingCutoffHour();
  const order = useOrder();
  const dispatch = useDispatch();
  const { data: sizes } = useSizes();

  useEffect(() => {
    if (items.length === 0) {
      dispatch(setReorderProducts(order.products.map(buildProofAlikeObject(sizes))));
      dispatch(createShipment(sizes, getDirectoryOrders(order), shippingCutoffHour));
    }
  }, [items, order, shippingCutoffHour, sizes, dispatch]);

  const { id } = useParams();
  useEffect(() => {
    fetchIndividualPackPricesData();
    fetchStorageCategoriesData();
    fetchStoragePricesData();
    props.saveCredit(id ? 0 : calculateTotalShipment(shippingElements));
  }, [id]);

  useEffect(() => {
    const newProofs = updateProofQuantitiesByDirectory(items, shippingElements);
    if (!isEmpty(storagePrices)) {
      const totalStoragePrice = sumBy(newProofs, proof => {
        const productSizes = getProductSizes(proof);
        const quantity = sumByQuantityParseInt(productSizes);
        return getStoragePrice(proof.product, storagePrices) * quantity;
      });
      setStorageTotal(totalStoragePrice);
    }
  }, [items, shippingElements, storagePrices]);

  const products = getWarehouseProducts(shippingElements, items);
  const isDataLoaded = !isEmpty(storagePrices) && !isEmpty(storageCategories) && !isEmpty(individualPackPrices);

  return (
    <div className={classes.container}>
      {isDataLoaded ? (
        <Grid container justifyContent="center">
          <Grid item className={classes.center}>
            <Breadcrumbs links={[{ title: 'Review Order' }]} />
            <Grid container>
              <Grid item xs={12} sm={12} md={8}>
                <h1 className={classes.pPageTitle}>Review your order</h1>
                {shippingElements.map(shippingElement => (
                  <MultishippingCardOverview key={shippingElement.id} shippingElement={shippingElement} />
                ))}
                {products.length > 0 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <ToWarehouseCard
                        classes={classes}
                        shippingElements={shippingElements}
                        items={items}
                        individualPackPrices={individualPackPrices}
                        storagePrices={storagePrices}
                        storageCategories={storageCategories}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container className={classes.Buttons}>
                  <Grid item xs={6} className={classes.ButtonsInnerContainer}>
                    <div className={classes.GoBackOneContainer}>
                      <Fab className={classes.GoBack} onClick={history.goBack}>
                        <Grid container style={{ paddingTop: 10 }}>
                          <Grid item xs={2} style={{ paddingTop: 2 }}>
                            <KeyboardBackspaceIcon />
                          </Grid>
                          <Grid item xs={10} className={classes.GoBackOneText}>
                            Previous step
                          </Grid>
                        </Grid>
                      </Fab>
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.ContinueOuterContainer}>
                    <div className={classes.ContinueContainer}>
                      <Fab
                        className={classes.Continue}
                        onClick={() => {
                          history.push(`/orders-requested/${id}/order-${products.length > 0 ? 'storage' : 'payment'}`);
                          gtm.onClickContinue('Orders requested payment');
                        }}
                      >
                        {products.length > 0 ? 'Continue' : 'Continue to Payment'}
                      </Fab>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className={classes.checkoutSideBarContainer}>
                <CheckoutSideBar
                  individualPackPrices={individualPackPrices}
                  products={items}
                  selectedCredit={id ? 0 : calculateTotalShipment(shippingElements)}
                  selectedStorage={storageTotal}
                  shippingElements={shippingElements}
                  showCredit
                  showStorage={storageTotal > 0}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  shippingElements: state.multishipping,
  items: state.reorderProducts
});

export default connect(mapStateToProps, {
  fetchStorageCategories,
  fetchIndividualPackPrices,
  fetchStoragePrices,
  saveCredit
})(withStyles(styles)(MultishippingOverview));
