import * as React from 'react';
import { Box, Grid, IconButton, Link, makeStyles, Modal } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Close } from '@material-ui/icons';
import { useCompany } from '../../../hooks';

const styles = theme => ({
  logoImageContainer: {
    height: 42,
    width: 100
  },
  logoImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  emailPreviewModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    border: 'transparent',
    marginTop: 68,
    position: 'relative'
  },
  emailPreviewModalContent: {
    position: 'relative',
    borderRadius: 16,
    width: 768,
    backgroundColor: '#ffffff',
    outline: 'none',
    border: 'transparent',
    padding: '0px 24px 42px 24px'
  },
  textColor: {
    color: '#131415'
  },
  messageContent: {
    marginTop: 24,
    backgroundColor: 'rgba(114, 160, 225, 0.08)',
    padding: '16px',
    borderRadius: 8,
    minHeight: 108
  },
  emailPreviewModalHeader: {
    padding: '12px 0px 12px 24px'
  },
  iconButton: {
    height: 24,
    width: 24,
    padding: 6
  },
  icon: {
    fontSize: 16
  }
});

const useStyles = makeStyles(styles);

const loremImpsum =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

const EmailPreview = ({ open, message, onClose }) => {
  const { data: company } = useCompany();
  const classes = useStyles();
  return (
    <Modal open={open} className={classes.emailPreviewModal}>
      <Box className={classes.emailPreviewModalContent}>
        <Grid container justifyContent="flex-end" alignItems="center" className={classes.emailPreviewModalHeader}>
          <Grid item xs>
            <Typography variant="body4RegularInter" color="white">
              Preview
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} className={classes.iconButton}>
              <Close className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Box className={classes.logoImageContainer}>
                  <img
                    src={company?.logo || '/images/public/su-logo.svg'}
                    alt={company?.name}
                    className={classes.logoImage}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ paddingLeft: '20px', marginTop: '32px' }}>
              <Typography variant="body1SemiBoldInter" color="white">
                Hey *|NAME|*
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ padding: '20px' }}>
              <Typography variant="h5SemiBoldInter" className={classes.textColor} style={{ marginBottom: '8px' }}>
                {company?.name} has a gift for you!
              </Typography>
              <Typography variant="subtitle3RegularInter" className={classes.textColor}>
                Click on the button below to redeem it.
              </Typography>
              <Box className={classes.messageContent}>
                <Typography variant="body3MediumInter" style={{ marginBottom: '8px' }}>
                  Message from Sender
                </Typography>
                <Typography variant="body4RegularInter">{message || loremImpsum}</Typography>
              </Box>
              <Box style={{ marginTop: '32px' }}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button variant="primary" size="small">
                      Redeem gift
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ padding: '10px', textAlign: 'center' }}>
              <Typography variant="body4RegularInter">Cheers!</Typography>
              <Typography variant="body4RegularInter">The SwagUp Team</Typography>

              <Box style={{ marginTop: '32px' }}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Box className={classes.logoImageContainer}>
                      <img src="/images/public/su-logo-dark.svg" alt="SwagUp" className={classes.logoImage} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Typography variant="body4RegularInter" style={{ marginBottom: '14px' }}>
                © SwagUp Inc. All rights reserved
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EmailPreview;
