import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@swagup-com/components';
import { useHistory, useLocation } from 'react-router-dom';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useQuery } from 'react-query';
import { Grid } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import StoresOrdersTab from './StoresOrdersTab';
import { CenteredGrid } from '../../shared';
import { useQueryParams } from '../../../hooks';
import ProductsTab from './ProductsTab';
import StoreSettingsTab from './StoreSettingsTab';
import CustomersTab from './CustomersTab';
import PayoutsTab from './PayoutsTab';
import storefrontsServicesPaths from '../../../helpers/storefrontsServicesPaths';
import { StoreDashboardCards } from '../storeCommon';
import { moneyStr } from '../../../helpers/utils';
import { storefronts } from '../../../apis/storefrontsServices';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '& .MuiTabs-flexContainer': {
      gap: 25
    },
    margin: '20px 0px'
  },
  navbarOpen: {
    width: 'calc(calc(100% - 300px))',
    marginLeft: '260px'
  },
  navbarClose: {
    marginLeft: '110px',
    width: 'calc(100% - 150px)'
  },
  tabContent: {
    padding: theme.spacing(2)
  },
  storeDashboard: {
    display: 'flex',
    marginBottom: 12
  }
}));

const StoreDetailsTab = ({ store, setStore }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState();

  const { data: summaryCall, isLoadingSummary } = useQuery(
    storefrontsServicesPaths.summary(store?.id),
    () => storefronts.summary(store?.id),
    {
      enabled: !!store?.id
    }
  );

  const storeSummary = summaryCall?.data || { ordersFullfilled: 0, productsSold: 0, totalOrders: 0, totalSales: 0 };

  const query = useQueryParams();

  const tab = query.get('tab') || 'orders';

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  const handleTabChange = (event, newValue) => {
    history.replace({ ...location, search: `tab=${newValue}` });
  };

  const { leftBarNavigation } = useFlags();

  const { leftNavOpen } = useSelector(state => state.commonReducer);

  return (
    <div
      className={`${classes.root} ${leftBarNavigation ? (leftNavOpen ? classes.navbarOpen : classes.navbarClose) : {}}`}
    >
      <TabContext value={currentTab}>
        <CenteredGrid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            className={classes.storeDashboard}
          >
            <StoreDashboardCards isValidData title="Total Sales" value={moneyStr(storeSummary.totalSales)} />
            <StoreDashboardCards isValidData title="Total payouts" value={moneyStr(storeSummary.totalPayouts || 0)} />
            <StoreDashboardCards isValidData title="Number of Orders" value={storeSummary.totalOrders} />
            <StoreDashboardCards isValidData title="Number of Items Sold" value={storeSummary.productsSold} />
            <StoreDashboardCards isValidData title="Orders Fulfilled" value={storeSummary.ordersFullfilled} />
          </Grid>
        </CenteredGrid>

        <CenteredGrid>
          <Grid style={{ marginBottom: 12 }}>
            <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="#3577D4">
              <Tab label="Orders" value="orders" />
              <Tab label="Products" value="products" />
              <Tab label="Customers" value="customers" />
              <Tab label="Payouts" value="payouts" />
              {/* <Tab label="Settings" value="settings" /> */}
            </Tabs>
          </Grid>
        </CenteredGrid>

        <TabPanel value="orders">
          <StoresOrdersTab
            store={store}
            setStore={setStore}
            searchParam="search_orders"
            storeSummary={storeSummary}
            isLoadingSummary={isLoadingSummary}
          />
        </TabPanel>

        <TabPanel value="products">
          <ProductsTab store={store} searchParam="search_products" />
        </TabPanel>

        <TabPanel value="customers">
          <CustomersTab store={store} searchParam="search_customers" />
        </TabPanel>

        <TabPanel value="payouts">
          <PayoutsTab store={store} searchParam="search_payouts" />
        </TabPanel>

        {/* <TabPanel value="settings">
          <StoreSettingsTab />
        </TabPanel> */}
      </TabContext>
    </div>
  );
};

export default StoreDetailsTab;
