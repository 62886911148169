const styles = theme => ({
  tableContainer: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'scroll',
      overflowY: 'hidden',
      whiteSpace: 'nowrap'
    }
  },
  imgPack: {
    width: '100%',
    objectFit: 'cover'
  },
  closeButton: {
    marginTop: 1,
    marginRight: 1
  },
  modalText: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#434c5f',
    paddingBottom: 10
  },
  cellheader: {
    verticalAlign: 'bottom',
    paddingBottom: 10,
    textAlign: 'left',
    height: 15,
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#8d9299'
  },
  cell: {
    verticalAlign: 'middle',
    padding: 24,
    paddingLeft: 16,
    textAlign: 'left'
  },
  imageEmployee: {
    width: 48,
    height: 48,
    borderRadius: 24,
    objectFit: 'cover'
  },
  nameEmployee: {
    fontFamily: 'Inter',
    fontWeight: '600 !important',
    fontSize: '16px',
    color: '#0f2440'
  },
  titleEmployee: {
    fontFamily: 'Inter'
  },
  tableText: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '500 !important',
    color: '#787b80'
  },

  emailEmployee: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500 !important',
    color: '#0f2440'
  },
  staffCard: {
    marginBottom: 20,
    border: '1px solid #d4d9e2',
    borderRadius: 3,
    padding: 16
  },
  imgContainer: {
    position: 'relative'
  },
  nevermind: {
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: 16,
    textTransform: 'uppercase',
    width: 384,
    height: 24
  },
  paper: {
    borderRadius: 10,
    position: 'absolute',
    left: '50%',
    webkitTransform: 'translateX(-50%)',
    transform: 'translateX(-50%)',
    top: 180,
    width: 790,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none',
    textAlign: 'center'
  },
  orderStatusYellow: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#ffa926',
    backgroundColor: 'rgba(255, 169, 38, 0.1)',
    '& span': {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center'
    }
  },
  orderStatusGreen: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#45af5f',
    backgroundColor: 'rgba(69, 175, 95, 0.1)',
    '& span': {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center'
    }
  },
  orderStatusGray: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: '#dddddd',
    '& span': {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center'
    }
  },
  deleteItemList: {
    marginTop: 5
  },
  actionsCell: {
    paddingRight: 0
  },
  deleteButton: {
    padding: 0,
    marginLeft: 12,
    '&:hover': {
      background: 'transparent'
    }
  },
  tooltip: { maxWidth: 500 }
});

export default styles;
