import { scrollBar } from '../../shared/styles/commonStyles';

const styles = () => ({
  Dialog: {
    width: 724,
    maxWidth: 724,
    '& .MuiDialogContent-root': {
      ...scrollBar
    }
  },
  DialogTitle: {
    '& h2': {
      fontFamily: 'Gilroy-Medium',
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: '#434d5c',
      textAlign: 'center'
    }
  },
  DialogContent: {
    paddingLeft: 50,
    paddingRight: 50
  },
  DownloadLink: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 0.75,
    textAlign: 'center',
    color: '#3577d4',
    cursor: 'pointer'
  },
  TemplateExplanation: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#a2abb8',
    paddingTop: 23
  },
  DialogActions: {
    paddingTop: 20,
    paddingBottom: 45,
    textAlign: 'center'
  },
  EnterEmail: {
    width: 180,
    height: 56,
    borderRadius: 4,
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    boxShadow: 'none',
    color: '#1f1d1d',
    marginTop: 20,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  DropzoneArea: {
    width: 420,
    height: 136,
    borderRadius: 25,
    border: 'dashed 1px #a2abb8',
    backgroundColor: '#fafcff',
    margin: '0 auto',
    paddingTop: 36,
    paddingBottom: 36,
    marginBottom: 62
  },
  DropMainText: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#0f2440',
    marginBottom: 24
  },
  UploadLink: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 0.71,
    textAlign: 'center',
    color: '#1f1d1d',
    '& span': {
      color: '#3577d4'
    }
  },
  closeButton: {
    marginTop: 9,
    marginRight: 1
  },
  CardTextfield: {
    '& > :first-child': {
      height: 64
    },
    '& fieldset': {
      borderRadius: 32,
      border: 'solid 1px #d3dbe5'
    },

    '& input': {
      fontFamily: 'Gilroy-Medium',
      fontSize: 16,
      lineHeight: 0.75,
      padding: '18.5px 30px',
      color: '#8d9299',
      '&::placeholder': {
        opacity: 0.6
      },
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #FFFFFF inset',
        WebkitTextFillColor: '#8d9299'
      }
    }
  },
  FormSubTitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    lineHeight: 1.25,
    color: '#434d5c',
    paddingBottom: 10,
    paddingTop: 10
  },
  CardSelectfield: {
    marginBottom: 20,
    borderRadius: 32,
    fontFamily: 'Gilroy-Medium',
    height: 64,
    padding: '18.5px 15px',
    '& fieldset': {
      border: 'solid 1px #d3dbe5'
    },
    '& > :first-child': {
      '&:focus': { backgroundColor: 'transparent' }
    }
  },
  SelectLabel: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    lineHeight: 0.75,
    color: '#8d9299',
    opacity: 0.6
  },
  modalDialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paymentNameHeader: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    color: '#868a8f',
    marginTop: 7
  },
  errorAlert: {
    maxWidth: 'inherit',
    width: 'inherit',
    marginBottom: 20
  }
});

export default styles;
