import React, { useState } from 'react';
import { withStyles, InputBase, InputAdornment } from '@material-ui/core';
import dayjs from 'dayjs';
import { shouldDisableDate as disableDate } from '../../helpers/commonDateFunctions';
import CalendarModal from './CalendarModal';
import { useShippingCutoffHour } from '../../hooks';

const getChoice = (value, values) => values[['normal', 'rounded', 'flat', 'square'].indexOf(value)];

const AdornmentIcon = ({ variant = 'normal' }) => (
  <InputAdornment position={getChoice(variant, ['end', 'start', 'end', 'end'])}>
    <svg
      width="12"
      height="12"
      viewBox="0 0 18 18"
      fill="#1d1d1d"
      opacity="0.54"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        color: '#1d1d1d !important',
        opacity: 0.54
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 1.69412H16.65C17.1194 1.69412 17.5 2.07336 17.5 2.54118V17.1529C17.5 17.6208 17.1194 18 16.65 18H1.35C0.880558 18 0.5 17.6208 0.5 17.1529V2.54118C0.5 2.07336 0.880558 1.69412 1.35 1.69412H4.75V0H6.45V1.69412H11.55V0H13.25V1.69412ZM11.55 3.38833H6.44999V5.08245H4.74999V3.38833H2.19999V6.77656H15.8V3.38833H13.25V5.08245H11.55V3.38833ZM2.19999 8.47059H15.8V16.3059H2.19999V8.47059Z"
        fill="#0B1829"
      />
    </svg>
  </InputAdornment>
);

const DateTextFieldBase = withStyles({
  root: {
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
      position: 'absolute',
      paddingLeft: 16
    },
    '& .MuiInputAdornment-positionEnd': {
      marginTop: -1.5,
      position: 'absolute',
      right: 32
    }
  },
  input: {
    borderRadius: 32,
    position: 'relative',
    border: '1px solid #CED1D6',
    padding: '22px 32px',
    paddingRight: 52,
    fontSize: 14,
    width: 'auto',
    color: '#0B1829',
    cursor: 'pointer',
    // Use the system font instead of the default Roboto font.
    fontFamily: 'Gilroy',
    '&:focus': {
      borderColor: '#3577d4'
    }
  }
})(InputBase);

const DateTextField = props => {
  const { variant } = props;
  const currentProps = {
    ...props,
    [getChoice(variant, ['endAdornment', 'startAdornment', 'endAdornment', 'endAdornment'])]: (
      <AdornmentIcon variant={variant} />
    )
  };
  return <DateTextFieldBase {...currentProps} />;
};

const CalendarModalTool = ({
  selectedDate,
  onSubmit,
  minDate,
  shouldDisableDate = disableDate,
  disablePast = true,
  classes,
  variant = 'normal',
  submitText,
  modalOpen = false
}) => {
  const [open, setOpen] = useState(modalOpen);
  const shippingCutoffHour = useShippingCutoffHour();

  return (
    <>
      <DateTextField
        value={dayjs(selectedDate || new Date()).format('MMMM Do, YYYY')}
        readOnly
        onClick={() => setOpen(true)}
        className={getChoice(variant, [undefined, classes.rounded, classes.flat, classes.square])}
        variant={variant}
      />
      <CalendarModal
        key={open}
        open={open}
        onSubmit={onSubmit}
        onClose={() => setOpen(false)}
        minDate={minDate}
        disablePast={disablePast}
        shouldDisableDate={date => shouldDisableDate(date, shippingCutoffHour)}
        initialDate={selectedDate || new Date()}
        submitText={submitText}
        variant={variant}
      />
    </>
  );
};

const styles = () => ({
  rounded: {
    '& input': {
      backgroundColor: '#fff',
      height: 64,
      borderRadius: 32,
      fontFamily: 'Gilroy-SemiBold',
      border: 'solid 1px #d3dbe5',
      padding: '0px 56px',
      display: 'flex',
      alignContent: 'center',
      cursor: 'pointer',
      '& input': {
        cursor: 'pointer'
      }
    },
    '& svg': {
      zIndex: 2,
      marginLeft: 8
    }
  },
  square: {
    '& input': {
      backgroundColor: '#fff',
      minWidth: 206,
      height: 56,
      borderRadius: 10,
      fontFamily: 'Inter',
      border: 'solid 1px #EBEDF0',
      padding: '0px 24px',
      fontSize: 14,
      display: 'flex',
      alignContent: 'center',
      cursor: 'pointer',
      '& input': {
        cursor: 'pointer'
      }
    },
    '& svg': {
      zIndex: 2,
      marginLeft: 8
    }
  },
  flat: {
    '& input': {
      width: '100%',
      border: 0,
      borderRadius: 0,
      borderBottom: '1px solid #434c5f',
      padding: '6px 40px',
      paddingLeft: 0,
      color: '#434c5f',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      marginRight: -8,
      cursor: 'pointer',
      '& input': {
        cursor: 'pointer'
      }
    },
    '& svg': {
      marginLeft: -20
    }
  }
});

export default withStyles(styles)(CalendarModalTool);
