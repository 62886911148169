import * as React from 'react';
import { Grid, makeStyles, TableContainer, Table, TableBody, Box } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useState, useMemo, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { isEmpty, isNumber, min } from 'lodash';
import isUndefined from 'lodash/isUndefined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Delete from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SwipeableViews from 'react-swipeable-views';
import { ArrowBack, SaveAlt } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import StoreFilterSection from '../StoreFilterSection';
import { CustomerRow, RedemptionDeleteModal, TableEmptyState, TableHeaderCustomer } from '../storeCommon';
import styles from './styles/customersTab';
import { usePaginatedQuery, useQueryParams } from '../../../hooks';
import storefrontsServicesPaths from '../../../helpers/storefrontsServicesPaths';
import { downloadData, joinFields, paginationRequestConverter } from '../../../helpers/utils';
import { useQueryFilterValidated } from '../../../hooks/useFilters';
import { storeCustomers } from '../../../apis/storefrontsServices';
import { CenteredGrid, Pagination } from '../../shared';
import SpinningSnackBar from '../components/SpinningSnackBar/SpinningSnackBar';
import SelectFromExistingDrawer from '../components/SelectFromExistingDrawer/SelectFromExistingDrawer';
import { buildCustomerPayload } from './common';
import { useFetchContactsByQuery } from '../../pages/orders/requested/hooks';
import AddNewCustomersModal from './AddNewCustomersModal';
import ActionBar from '../components/ActionBar/ActionBar';
import { AllOrderDetails } from './OrderDetails';
import AddFundsToCustomersModal from './AddFundsToCustomersModal';
import AddContact from '../../pages/orders/requested/shipments/AddContact';
import { addShipmentGroupRecipientsToSelect } from '../../../actions/shipmentGroupActions';
import AddNewCustomer from '../components/CreateNewCustomer/AddNewCustomer';
import useMembershipCheck from '../../../hooks/useMembershipCheck';
import SearchSortFilter from '../../shared/SearchSortFilter';
import ExistingCustomersModal from './ExistingCustomersModal';
import { StylessButton } from '../../buttons';

const useStyles = makeStyles(styles);

const perPageOptions = [10, 20, 30, 40];

const CustomersTab = ({ store, searchParam }) => {
  const [openRecipientDrawer, setOpenRecipientDrawer] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [contactsToAdd, setContactsToAdd] = useState([]);
  const [openAddCustomersModal, setOpenAddCustomersModal] = useState(false);
  const [openExistingCustomersModal, setOpenExistingCustomersModal] = useState(false);
  const [openAddCustomersFundsModal, setOpenAddCustomersFundsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [addFundsCompleted, setAddFundsCompleted] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState();
  const [customerToFund, setCustomerToFund] = useState();
  const [customerId, setCustomerId] = useState(0);
  const [detailsIndex, setDetailsIndex] = useState();
  const [actionBarOpen, setActionBarOpen] = useState(false);
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
  const [isAddNewCustomerOpen, setIsAddNewCustomerOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState();
  const [csvImportError, setCsvImportError] = useState();

  useEffect(() => {
    setDetailsIndex(!customerId ? 0 : 1);
  }, [customerId]);

  const { data: customersHistoryAPI, isLoading: isLoadingDetails } = useQuery(
    [storefrontsServicesPaths.customerOrderHistory(store.id, customerId)],
    () => storeCustomers.customerOrderHistory(store.id, customerId),
    {
      enabled: !!customerId
    }
  );

  const customersHistory = customersHistoryAPI?.results || [];

  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const search = query.get(searchParam) || '';
  const isSearching = !!search;
  const ordering = useQueryFilterValidated(
    'ordering',
    (xid, value) => ['-created_at', 'created_at'].includes(xid) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const {
    query: { data: customersResults, isFetching },
    pagination
  } = usePaginatedQuery({
    queryKey: [storefrontsServicesPaths.customers(store.id), search, ordering],
    queryFn: (limit, offset) => {
      return store.id
        ? storeCustomers.list(store.id, paginationRequestConverter({ limit, offset, search, ordering }))
        : [];
    },
    perPageOptions
  });

  const storeRelatedCustomers = useMemo(() => customersResults?.results || [], [customersResults?.results]);

  const membershipCheck = useMembershipCheck();

  const showPagination = pagination?.count > min(pagination?.sizeOptions);

  const handleOnAddNewCustomersFromRecipient = contacts => {
    setUploadStatus();
    setContactsToAdd(contacts);
    // here
    if (contacts.every(c => isNumber(c.amount))) {
      setOpenExistingCustomersModal(true);
    } else {
      setOpenAddCustomersModal(true);
    }

    setIsAddNewCustomerOpen(false);
  };

  const addContactsFromQuery = React.useCallback(contacts => {
    handleOnAddNewCustomersFromRecipient(contacts);
  }, []);

  const { isLoading: isQueryLoading } = useFetchContactsByQuery(addContactsFromQuery);

  const onSuccessMutation = data => {
    queryClient.invalidateQueries([storefrontsServicesPaths.customers(store.id)]);
  };

  const customerAddBulk = useMutation(payload => storeCustomers.addBulk(store.id, payload), {
    onSuccess: ({ data }) => {
      onSuccessMutation(data);
    }
  });

  const customerAddByEmail = useMutation(payload => storeCustomers.addByEmail(store.id, payload), {
    onSuccess: ({ data }) => {
      onSuccessMutation(data);
      setUploadStatus('successful');
    }
  });

  const customerAddFundsBulk = useMutation(payload => storeCustomers.addMultipleFunds(store.id, payload), {
    onSuccess: ({ data }) => {
      onSuccessMutation(data);
      setAddFundsCompleted(true);
    }
  });

  const customerUpdateStatus = useMutation(payload => storeCustomers.update(store.id, payload.id, payload.params), {
    onSuccess: ({ data }) => {
      onSuccessMutation(data);
    }
  });

  const customersDelete = useMutation(params => storeCustomers.delete(store.id, params), {
    onSuccess: ({ data }) => {
      onSuccessMutation(data);
      setCustomerToDelete();
    }
  });

  const customerExport = useMutation(() => storeCustomers.export(store.id, { search, ordering }), {
    onSuccess: data => {
      downloadData(`${store?.generalSettings.customStoreName || store?.generalSettings.storeName}.csv`, data);
    }
  });

  const handleAddNewFromSelect = () => {
    setOpenRecipientDrawer(prev => !prev);
    setIsAddDrawerOpen(prev => !prev);
  };

  const handleAddNew = contact => {
    setIsAddDrawerOpen(false);
    queryClient.invalidateQueries(apiPaths.contacts);
    if (isSelectingFromExisting) {
      dispatch(addShipmentGroupRecipientsToSelect([contact.id]));
      setIsAddDrawerOpen(prev => !prev);
      setOpenRecipientDrawer(true);
    }
  };

  const handleOnOnAddCustomer = data => {
    const { storeCredits, automaticallySendActivationEmail } = data;
    const settings = {
      storeId: store.id,
      storeCredits
    };
    const payload = buildCustomerPayload(contactsToAdd, settings);
    console.log('playload: ', payload);
    if (payload.every(c => isNumber(c.amount))) {
      customerAddByEmail.mutate({ customers: payload });
    } else {
      customerAddBulk.mutate({ customers: payload, automaticallySendActivationEmail });
    }
  };

  const handleOnAddCustomerFunds = data => {
    const { amount, automaticallySendNotificationEmail, customMessage } = data;
    const payload = customerToFund ? [customerToFund.id] : selectedItems.map(c => c.id);
    customerAddFundsBulk.mutate({ customers: payload, amount, customMessage, automaticallySendNotificationEmail });
  };

  const toogleSelectAll = () => {
    setSelectedItems(prev => {
      const data = prev.length !== storeRelatedCustomers.length ? storeRelatedCustomers.map(r => r) : [];
      setActionBarOpen(data.length > 0);
      return data;
    });
  };

  const disableDelete = () => {
    for (const selectedItem of selectedItems) {
      if (selectedItem.totalOrders === 0) {
        return false;
      }
      if (selectedItem.totalOrders > 0 || selectedItems?.length > 1) {
        return true;
      }
    }
  };

  const toogleSelectedItems = sitem => {
    setSelectedItems(prev => {
      const data = prev.find(it => it.id === sitem.id) ? prev.filter(it => it.id !== sitem.id) : [...prev, sitem];
      setActionBarOpen(data.length > 0);
      return data;
    });
  };

  const handleOnAAddCustomerFunds = customer => {
    setCustomerToFund(customer);
    setOpenAddCustomersFundsModal(true);
  };

  const handleOnCustomerStatusChange = (customer, status) => {
    customerUpdateStatus.mutate({ id: customer.id, params: { status } });
  };

  const handleOnCustomerDelete = customer => {
    setCustomerToDelete(customer);
  };

  const onDeleteCustomer = r => {
    const customerIds = r ? [r] : selectedItems.map(c => c.id);
    customersDelete.mutate({ customerIds });
    setOpenDeleteModal(false);
  };

  const customer = useMemo(() => storeRelatedCustomers?.find(c => c.id === customerId) || {}, [
    storeRelatedCustomers,
    customerId
  ]);

  useEffect(() => {
    if (!isEmpty(csvImportError)) setUploadStatus('error');
  }, [csvImportError]);

  const exportCustomer = () => customerExport.mutate();

  const classes = useStyles();

  const searchSortFilterConfig = {
    search: { placeholder: 'User Name', queryParam: 'search_customers' },
    sort: { options: { '-created_at': 'Most Recent', created_at: 'Less Recent' } }
  };

  return (
    <div>
      <CenteredGrid>
        <Grid container alignItems="center" spacing={4}>
          <Grid item>
            {!detailsIndex ? (
              <Typography variant="h2BoldInter">Customers</Typography>
            ) : (
              <Typography variant="h2BoldInter" className={classes.tabTitle}>
                {joinFields([customer.firstName, customer.lastName])} / <span>Order history</span>
              </Typography>
            )}
          </Grid>
          <Grid item xs />
          <Grid item style={{ paddingRight: 16 }}>
            {detailsIndex === 0 && (
              <StylessButton className={classes.exportButtom} disabled={detailsIndex > 0} onClick={exportCustomer}>
                <Grid container alignItems="center" style={{ minWidth: 90 }}>
                  <Grid item style={{ paddingRight: 4, paddingTop: 0 }}>
                    <SaveAlt style={{ height: 18, width: 18 }} />
                  </Grid>
                  <Grid item xs>
                    Export customers
                  </Grid>
                </Grid>
              </StylessButton>
            )}
          </Grid>

          <Grid item>
            {detailsIndex > 0 ? (
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setDetailsIndex(0);
                  setCustomerId();
                }}
              >
                <Grid container style={{ paddingTop: 10 }}>
                  <Grid item>
                    <ArrowBack style={{ height: 16, width: 16, marginTop: -3, marginRight: 6 }} />
                  </Grid>
                  <Grid item>Back to Customers</Grid>
                </Grid>
              </Button>
            ) : (
              <Button variant="primary" size="small" onClick={() => setOpenRecipientDrawer(true)}>
                Add Customer
              </Button>
            )}
          </Grid>
        </Grid>
      </CenteredGrid>
      <SwipeableViews axis="x" index={detailsIndex} className={classes.swipeableViews} disabled>
        <Box>
          <CenteredGrid>
            <SearchSortFilter config={searchSortFilterConfig} />
            {storeRelatedCustomers?.length === 0 ? (
              <TableEmptyState isSearching={isSearching} type="customer" />
            ) : (
              <TableContainer>
                <Table stickyHeader className={classes.orderTable}>
                  <TableHeaderCustomer
                    classes={classes}
                    toogleSelectAll={toogleSelectAll}
                    allSelected={storeRelatedCustomers.length === selectedItems.length}
                  />
                  <TableBody>
                    {storeRelatedCustomers?.map(c => (
                      <CustomerRow
                        key={c.id}
                        customer={c}
                        toogleSelectedItems={toogleSelectedItems}
                        isSelected={!!selectedItems.find(s => s.id === c.id)}
                        onShowOrders={id => setCustomerId(id)}
                        onAddFunds={handleOnAAddCustomerFunds}
                        onChangeStatus={handleOnCustomerStatusChange}
                        onDelete={handleOnCustomerDelete}
                        isPlatinum={membershipCheck.shopCreditsAllowed}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {showPagination && (
              <Grid container justifyContent="center" alignItems="center" className={classes.paginationContainer}>
                <Grid item>
                  <Pagination
                    {...pagination}
                    startText="Show"
                    endText="customers"
                    buttonClass={classes.paginationBtn}
                  />
                </Grid>
              </Grid>
            )}
          </CenteredGrid>
        </Box>
        <AllOrderDetails customer={customer} orders={customersHistory} />
      </SwipeableViews>

      <AddNewCustomer
        open={isAddNewCustomerOpen}
        onClose={() => setIsAddNewCustomerOpen(false)}
        onSuccess={handleOnAddNewCustomersFromRecipient}
        setCsvImportError={setCsvImportError}
      />
      <AddContact open={isAddDrawerOpen} onClose={() => setIsAddDrawerOpen(false)} onSuccess={handleAddNew} />
      <SelectFromExistingDrawer
        open={openRecipientDrawer}
        onClose={() => setOpenRecipientDrawer(false)}
        onAddContacts={handleOnAddNewCustomersFromRecipient}
        onAddNew={handleAddNewFromSelect}
        store={store}
        newCustomers
        setOpenRecipientDrawer={setOpenRecipientDrawer}
        onAddNewCustomerDrawer={() => {
          setIsAddNewCustomerOpen(true);
          setOpenRecipientDrawer(false);
        }}
      />
      <AddNewCustomersModal
        open={openAddCustomersModal}
        newCustomersCount={contactsToAdd.length}
        onClose={() => setOpenAddCustomersModal(false)}
        onAdd={data => handleOnOnAddCustomer(data)}
      />
      <ExistingCustomersModal
        open={openExistingCustomersModal}
        storeId={store.id}
        contactsToAdd={contactsToAdd}
        onClose={param => {
          setOpenExistingCustomersModal(false);
          setIsAddNewCustomerOpen(param?.retry);
          setTimeout(() => setUploadStatus(), 500);
        }}
        onAdd={data => handleOnOnAddCustomer(data)}
        uploadStatus={uploadStatus}
        csvImportError={csvImportError}
      />
      <AddFundsToCustomersModal
        open={openAddCustomersFundsModal}
        customersCount={customerToFund ? 1 : selectedItems.length}
        onClose={() => {
          setOpenAddCustomersFundsModal(false);
          setAddFundsCompleted(false);
          setCustomerToFund();
        }}
        onAdd={data => handleOnAddCustomerFunds(data)}
        processing={customerAddFundsBulk.isLoading}
        completed={openAddCustomersFundsModal && addFundsCompleted}
      />
      <RedemptionDeleteModal
        open={openDeleteModal || !isUndefined(customerToDelete)}
        onDelete={() => onDeleteCustomer(customerToDelete?.id)}
        onClose={() => {
          setCustomerToDelete();
          setOpenDeleteModal(false);
        }}
        order={customerToDelete}
        type="customer"
      />
      <SpinningSnackBar
        open={
          isFetching ||
          customerAddBulk.isLoading ||
          isQueryLoading ||
          isLoadingDetails ||
          customerAddFundsBulk.isLoading
        }
        message="Loading..."
      />
      <ActionBar
        open={actionBarOpen}
        totalItemSelected={selectedItems.length}
        handleClose={() => {
          setSelectedItems([]);
          setActionBarOpen(false);
        }}
        type="Customer"
        actions={[
          {
            text: 'Add Funds',
            tooltip: membershipCheck.shopCreditsAllowed
              ? 'Add Funds'
              : 'You must upgrade to Platinum in order to use this feature',
            baseIcon: CreditCardOutlinedIcon,
            disabled: !membershipCheck.shopCreditsAllowed,
            hoverIcon: CreditCardIcon,
            action: () => {
              setOpenAddCustomersFundsModal(true);
              setActionBarOpen(false);
            }
          },
          {
            text: 'Delete',
            tooltip: 'You can only Customers with no associated orders',
            baseIcon: DeleteOutlineIcon,
            hoverIcon: Delete,
            disabled: disableDelete(),
            action: () => {
              setOpenDeleteModal(true);
              setActionBarOpen(false);
            }
          }
        ]}
      />
    </div>
  );
};

export default CustomersTab;
