import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import { useSizes } from '../../../../../hooks';

const useSortedSizes = sizes => {
  const { data } = useSizes();

  if (data === undefined) throw new Error('You should fetch sizes data before using useSortedSizes');

  const sizesMap = data.reduce((acc, size) => ({ ...acc, [size.id]: size }), {});
  return sortBy(
    sizes.map(size => ({ ...size, ...pick(sizesMap[size.size], ['name', 'sort_order']) })),
    'sort_order'
  );
};

export default useSortedSizes;
