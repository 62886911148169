import React from 'react';
import { Grid } from '@material-ui/core';
import { moneyStr } from '../../helpers/utils';

const InvoiceInfo = ({ title, description, classes, xs = 6 }) => (
  <Grid
    style={{ paddingBottom: 25 }}
    container
    direction="column"
    justifyContent="flex-start"
    alignItems="flex-start"
    item
    xs={xs}
  >
    <p className={classes.title}>{title}</p>
    <p className={classes.description}>{description}</p>
  </Grid>
);

const InvoiceBalance = ({ title, balance, classes }) => (
  <Grid container direction="column" justifyContent="flex-start" alignItems="flex-end" item xs={6}>
    <p className={classes.balanceTitle}>{title}</p>
    <p className={classes.balanceDescription}>{moneyStr(balance)}</p>
  </Grid>
);

export { InvoiceInfo, InvoiceBalance };
