import { useCallback, useEffect, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Documentation for the options object
// https://help.chilipiper.com/hc/en-us/articles/360053800373-Concierge-Snippet-and-API

const useChiliPiper = () => {
  const { leadsRouteUsingChiliPiperTemp091521 } = useFlags();
  const chiliPiper = useRef();

  useEffect(() => {
    chiliPiper.current = window.ChiliPiper;
  }, []);

  return useCallback(
    options => {
      if (leadsRouteUsingChiliPiperTemp091521) {
        chiliPiper.current.submit('swagup', 'main-swagup-router', options);
      } else if (options.onRouted) {
        options.onRouted();
      }
    },
    [leadsRouteUsingChiliPiperTemp091521]
  );
};

export default useChiliPiper;
