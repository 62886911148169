import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import { CheckBox, FullnameWithHighlights, TableCell, TableRow } from './TableCommon';
import { getEmployeeShipAddress, joinFields } from '../../../../../helpers/utils';

const useStyles = makeStyles({
  disabledRow: {
    boxShadow: 'none !important',
    background: '#ffffff !important',
    '& td': { borderRight: 'solid 1px #f0f2f5' },
    '& *': { color: '#ced1d6' }
  }
});

const ContactRow = ({ contact, selected, disabled, highlightText, showGroupNames, onClick }) => {
  const classes = useStyles();
  const [cellColor, cellValue] = showGroupNames
    ? ['#0b1829', contact.groups?.map(g => g.name).join(', ')]
    : ['#787b80', contact.created_at && dayjs(contact.created_at).format('MM/DD/YY')];

  return (
    <TableRow
      hover
      role="checkbox"
      onClick={!disabled && onClick}
      aria-checked={selected}
      selected={selected}
      className={disabled ? classes.disabledRow : undefined}
    >
      <TableCell padding="checkbox">
        <CheckBox color="primary" disabled={disabled} checked={selected} />
      </TableCell>
      <TableCell scope="row" width={224} style={{ maxWidth: 224, fontFamily: 'Gilroy-Medium' }}>
        <FullnameWithHighlights
          name={joinFields([contact.first_name, contact.last_name], ' ')}
          highlight={highlightText}
        />
      </TableCell>
      <TableCell width={379} style={{ maxWidth: 379 }}>
        {getEmployeeShipAddress(contact, false)}
      </TableCell>
      <TableCell width={89} style={{ maxWidth: 89 }}>
        {contact.size?.name}
      </TableCell>
      <TableCell width={279} style={{ maxWidth: 279, color: cellColor }}>
        {cellValue}
      </TableCell>
    </TableRow>
  );
};

export default ContactRow;
