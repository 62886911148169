import DashBoard from '../apis/DashBoard';
import { okAndLog, errorAndLog, calculateSignature } from '../helpers/utils';
import { mergeCampaignIdsWithData } from '../helpers/utilsCookies';
import apiPaths from '../helpers/apiPaths';
import gtm from '../utils/gtm';
import log from '../logger';
import {
  ONBOARDING_NEXT_STEP,
  ONBOARDING_PREVIOUS_STEP,
  ONBOARDING_UPDATE_INFO,
  ONBOARDING_RESET_STEPS,
  ONBOARDING_ONE_PAGE,
  ONBOARDING_TWO_PAGE
} from './types';

export const onboarding = data => async () => {
  log.debug('onboarding Action - Entering');
  try {
    const dataWithCampaignIds = mergeCampaignIdsWithData(data);
    const calculatedSignature = calculateSignature('jkjeyhzXGpvkXuAkbUFj56KbnNxuYWI1', dataWithCampaignIds);
    const apiCall = await DashBoard.post(
      `${apiPaths.samplePacks}?signature=${calculatedSignature}`,
      dataWithCampaignIds
    );

    gtm.submitSamplePackForm(data);

    if (apiCall.status === 201) {
      return okAndLog('onboarding', apiCall.status, apiCall.data);
    }
    return errorAndLog('onboarding', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('onboarding', e.status, e.data);
  }
};

export const onboardingNextStep = () => async dispatch => {
  dispatch({
    type: ONBOARDING_NEXT_STEP
  });
};

export const onboardingPreviousStep = () => async dispatch => {
  dispatch({
    type: ONBOARDING_PREVIOUS_STEP
  });
};

export const onboardingUpdateInfo = newInfo => async dispatch => {
  log.debug('onboarding UpdateInfo - Entering with info: ', newInfo);
  dispatch({
    type: ONBOARDING_UPDATE_INFO,
    payload: newInfo
  });
  log.debug('onboarding UpdateInfo - Exiting');
};

export const onboardingResetStep = () => async dispatch => {
  dispatch({
    type: ONBOARDING_RESET_STEPS
  });
};

export const onboardingOnePage = () => async dispatch => {
  dispatch({
    type: ONBOARDING_ONE_PAGE
  });
};

export const onboardingTwoPage = () => async dispatch => {
  dispatch({
    type: ONBOARDING_TWO_PAGE
  });
};

export const onboardingAuthorizeCreditCards = data => async () => {
  log.debug('onboardingAuthorizeCreditCards Action - Entering');
  try {
    const apiCall = await DashBoard.post(apiPaths.authorizeCreditCards, data);
    if (apiCall.status === 201) {
      return okAndLog('onboardingAuthorizeCreditCards', apiCall.status, apiCall.data);
    }
    return errorAndLog('onboardingAuthorizeCreditCards', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('onboardingAuthorizeCreditCards', e.status, e.data);
  }
};
