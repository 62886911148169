import React, { useEffect, useState } from 'react';
import { Dialog, Divider, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/storefrontsHome';
import { Alert, CenteredGrid, EmptyState, Helmet, Pagination } from '../shared';
import { CardsContainer } from '../shared/containers/Cards';
import { StoreDashboardCards, StorefrontCard } from './storeCommon';
import { usePaginatedQuery, usePerPageOptions, useQueryParams } from '../../hooks';
import Loader from '../global/Loader';
import { storefronts } from '../../apis/storefrontsServices';
import storefrontServicesPaths from '../../helpers/storefrontsServicesPaths';
import { moneyStr, paginationRequestConverter } from '../../helpers/utils';
import seoTags from '../../apis/seoTags';
// import RedeemFilterSection from './StoreFilterSection';
import { useQueryFilterValidated } from '../../hooks/useFilters';
import NewBadge from '../shared/NewBadge';
import { useMembership } from '../../contexts/membershipContext';
import SpinningSnackBar from './components/SpinningSnackBar/SpinningSnackBar';
import StoreLaunch from './StoreLaunch';

const useStyles = makeStyles(styles);

const DeletePageModal = ({ open, pageName, onClose, onAccept, classes }) => (
  <Dialog open={open} onClose={onClose} classes={{ paper: classes.deletePageModal }}>
    <Grid container>
      <div>
        <Typography variant="body2MediumInter" className={classes.notEnoughCreditsModalTitle}>
          {`Delete ${pageName}`}
        </Typography>
        <Typography variant="body3RegularInter">
          Deleting this storefront page will delete all order links for the page. Any shipments that have been created
          can still be found in the <Link to="/shipments">Shipments</Link> tab and any{' '}
          <Link to="/shipments/contacts">contacts</Link> created will be saved as well.
        </Typography>
        <Divider className={classes.notEnoughCreditsModalDivider} />
        <Grid container spacing={6} justifyContent="flex-end">
          <Grid item>
            <Button size="small" variant="text" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button size="small" variant="primary" onClick={onAccept}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  </Dialog>
);

const StorefrontsHome = () => {
  const [showInfoMessage, setShowInfoMessage] = useState(true);
  const [actionMessage, setActionoMessage] = useState();
  const [isFiltering, setIsFiltering] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editingPage, setEditingPage] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState();
  const { leftBarNavigation } = useFlags();

  // const profile = useSelector(state => state.profile);
  // const email = profile?.email;

  const history = useHistory();
  const location = useLocation();
  const { infoMessage } = location.state || {};

  const classes = useStyles();
  const perPageOptions = usePerPageOptions();
  const company = useSelector(state => state.company);

  const isActive = useQueryFilterValidated(
    'isActive',
    (id, value) => ['true', 'false'].includes(id) && value.split(',').length === 1
  );

  const collectionOnly = useQueryFilterValidated(
    'collectionOnly',
    (id, value) => ['true', 'false'].includes(id) && value.split(',').length === 1
  );

  const ordering = useQueryFilterValidated(
    'ordering',
    (id, value) => ['-created_at', 'created_at'].includes(id) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const query = useQueryParams();
  const search = query.get('search') || '';

  const haveActiveFilters = [search, isActive, collectionOnly].some(e => e.length > 0);

  const { query: queryResult, pagination } = usePaginatedQuery({
    queryKey: [storefrontServicesPaths.storefronts, company.id, search, isActive, ordering, collectionOnly],
    queryFn: (limit, offset) =>
      company.id &&
      storefronts
        .listByAccount(
          company.id,
          paginationRequestConverter({ limit, offset, search, isActive, ordering, collectionOnly })
        )
        .then(response => {
          setIsFiltering(false);
          return response;
        }),
    perPageOptions
  });

  const { data, isFetching, isFetched } = queryResult;
  const resultStores = data?.results || [];

  const queryClient = useQueryClient();

  const { data: summaryCall, isLoadingSummary } = useQuery(
    storefrontServicesPaths.summaryAll(company.id),
    () => storefronts.summaryAll(company.id),
    {
      enabled: !!company?.id
    }
  );

  const storeSummary = summaryCall?.data || {
    ordersFullfilled: 0,
    productsSold: 0,
    totalOrders: 0,
    totalSales: 0,
    totalPayouts: 0
  };

  const renamePage = useMutation(({ pageId, name }) => storefronts.update(pageId, { customProjectName: name }), {
    onSuccess: () => {
      queryClient.invalidateQueries([storefrontServicesPaths.storefronts, company.id]);
    }
  });

  const deletePage = useMutation(pageId => storefronts.update(pageId, { deleted: true }), {
    onSuccess: () => {
      queryClient.invalidateQueries([storefrontServicesPaths.storefronts, company.id]);
      setActionoMessage();
      setShowInfoMessage(false);
    }
  });

  useEffect(() => {
    if (!deleteOpen) {
      setTimeout(() => setPageToDelete(), 500);
    }
  }, [deleteOpen]);

  const onRenamePage = (pageId, name) => {
    setEditingPage(pageId);
    renamePage.mutate({ pageId, name });
  };

  const onDeleteAttempt = page => {
    setDeleteOpen(true);
    setPageToDelete(page);
  };

  const onDelete = page => {
    setDeleteOpen(false);
    if (page) {
      deletePage.mutate(page.id);
      setActionoMessage(`Deleting page...`);
      setShowInfoMessage(true);
    }
  };

  const handleClose = () => {
    setShowInfoMessage(false);
    history.replace({ ...location, state: { ...location.state, infoMessage: undefined } });
  };

  const EmptyMsg = () => {
    return (
      <Grid>
        <Typography variant="h5BoldInter">It’s pretty quiet in here.</Typography>
        <Typography variant="h5BoldInter">Why not create your first store?</Typography>
      </Grid>
    );
  };

  const isDisplayStore = resultStores?.length === 0;

  return (
    <div className={classes.root} style={{ paddingTop: leftBarNavigation ? 0 : 40 }}>
      <Helmet tags={seoTags.storefronts} />
      <CenteredGrid>
        {!leftBarNavigation && (
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 24 }}>
            <Grid item>
              <NewBadge fontColor="#2E7D32">
                <Typography variant="h2BoldInter">Shops</Typography>
              </NewBadge>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                variant="primary"
                onClick={() => setOpenDrawer(true)}
                to="/shop-create"
                style={{ minWidth: 180, height: 56 }}
              >
                Build Shop
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs>
            {(infoMessage || actionMessage) && showInfoMessage && (
              <Grid container justifyContent="center" style={{ position: 'relative' }}>
                <Alert
                  onClose={handleClose}
                  delayTime={10000}
                  className={classes.infoMessage}
                  fontStyles={{ fontSize: 12, padding: 0 }}
                >
                  <span dangerouslySetInnerHTML={{ __html: infoMessage || actionMessage }} />
                </Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CenteredGrid>
      <Divider />
      <CenteredGrid>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.storeDashboard}>
          <StoreDashboardCards isValidData title="Total Sales" value={moneyStr(storeSummary.totalSales)} />
          <StoreDashboardCards isValidData title="Total Payouts" value={moneyStr(storeSummary.totalPayouts || 0)} />
          <StoreDashboardCards isValidData title="Number of Orders" value={storeSummary.totalOrders} />
          <StoreDashboardCards isValidData title="Number of Items Sold" value={storeSummary.productsSold} />
          <StoreDashboardCards isValidData title="Orders Fulfilled" value={storeSummary.ordersFullfilled} />
        </Grid>
      </CenteredGrid>
      <Divider />
      {/* <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} style={{ position: 'relative' }}>
          <div className={classes.filtersWrapper}>
            <CenteredGrid>
              <RedeemFilterSection setIsFiltering={setIsFiltering} />
            </CenteredGrid>
          </div>
          {isFiltering && isFetching && (
            <Grid container justifyContent="center">
              <Typography variant="body4RegularInter" className={classes.isSearching}>
                Searching...
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid> */}
      <CenteredGrid>
        {isFetched && isDisplayStore && <StoreLaunch />}
        {!isDisplayStore && (
          <Grid container justifyContent="center" style={{ paddingTop: 12, paddingBottom: 12, minHeight: 80 }}>
            {!isFetching && resultStores.length === 0 ? (
              <Grid item>
                <EmptyState
                  title={haveActiveFilters ? `No Stores Found` : <EmptyMsg />}
                  image={{
                    path: haveActiveFilters ? '/images/storefront/search.png' : '/images/storefront/home-empty.png',
                    alt: 'No Stores',
                    text: haveActiveFilters
                      ? 'Remove the filters to see all storefront pages'
                      : 'Get started by clicking “Create Store"'
                  }}
                  maxWidth={400}
                  button={haveActiveFilters ? { link: '/shops', text: 'Remove filters' } : undefined}
                />
              </Grid>
            ) : (
              <CardsContainer className={classes.cardsContainer}>
                {resultStores.map(store => (
                  <StorefrontCard
                    key={store.id}
                    store={store}
                    onEdit={onRenamePage}
                    onDelete={() => onDeleteAttempt(store)}
                    isLoading={editingPage === store.id && renamePage.isLoading}
                  />
                ))}
              </CardsContainer>
            )}
          </Grid>
        )}
        <Grid container justifyContent="center" className={classes.paginationContainerHome}>
          <Grid item>
            <Pagination {...pagination} startText="Show" endText="Shops" buttonClass={classes.paginationBtn} />
          </Grid>
        </Grid>
        {/* <CreateRedeemOptionsDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} classes={classes} /> */}
        {isFetching && !isFiltering && isLoadingSummary && <Loader />}
      </CenteredGrid>
      <DeletePageModal
        open={deleteOpen}
        pageName={pageToDelete?.customProjectName || pageToDelete?.projectName}
        onClose={() => {
          setDeleteOpen(false);
        }}
        onAccept={() => onDelete(pageToDelete)}
        classes={classes}
      />
      <SpinningSnackBar open={isFetching || isLoadingSummary} message="Loading..." />
    </div>
  );
};

export default StorefrontsHome;
