import { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import stripeAPI from '../apis/stripe';
import apiPaths from '../helpers/apiPaths';
import accountProducts from '../apis/swagup/accountProducts';
import useMembershipCheck from './useMembershipCheck';

const dueDateStatuses = {
  normal: 'normal',
  warning: 'warning',
  alert: 'alert'
};

const getDueDateStatus = dueDate => {
  // Get the current date
  const currentDate = dayjs();

  // Calculate the date 6 months from the due date
  const sixMonthsFromDueDate = dayjs(dueDate).add(6, 'month');

  // Calculate the date 5 days before the due date
  const fiveDaysBeforeDueDate = dayjs(sixMonthsFromDueDate).subtract(5, 'day');

  // Calculate the date 30 days before 6 months from the due date
  const thirtyDaysBeforeSixMonths = sixMonthsFromDueDate.subtract(30, 'day');

  // Check the status
  switch (true) {
    case currentDate.isAfter(fiveDaysBeforeDueDate):
      return dueDateStatuses.alert;
    case currentDate.isAfter(thirtyDaysBeforeSixMonths):
      return dueDateStatuses.warning;
    default:
      return dueDateStatuses.normal;
  }
};

const getPaidDate = date =>
  dayjs(date)
    .add(180, 'days')
    .format('MM/DD/YYYY');

const getInventoryPaymentDate = history => {
  if (isEmpty(history)) return undefined;

  // Initialize with the first date in the array
  let oldestDate = dayjs(history[0].last_paid_date);

  // Iterate through the remaining dates
  for (let i = 1; i < history.length; i++) {
    const currentDate = dayjs(history[i].last_paid_date);
    if (currentDate.isBefore(oldestDate)) {
      oldestDate = currentDate; // Update oldestDate if currentDate is earlier
    }
  }

  return getPaidDate(oldestDate);
};

const getGroupByPaidDate = history => {
  const groupPaidDates = history.reduce((acc, h) => {
    if (acc.find(accH => accH.paidDate === h.paidDate))
      return acc.map(ah => (ah.paidDate === h.paidDate ? { ...ah, sizes: [...ah.sizes, h] } : ah));
    return [...acc, { ...h, sizes: [h] }];
  }, []);

  return groupPaidDates;
};

const getAccountProductInventoryDueInfoAction = (history, isPlatinum) => {
  let inventoryDueStatus = dueDateStatuses.normal;

  switch (true) {
    case isPlatinum:
      inventoryDueStatus = dueDateStatuses.normal;
      break;
    case history.some(h => h.dueStatus === dueDateStatuses.alert):
      inventoryDueStatus = dueDateStatuses.alert;
      break;
    case history.some(h => h.dueStatus === dueDateStatuses.warning):
      inventoryDueStatus = dueDateStatuses.warning;
      break;
    default:
      inventoryDueStatus = dueDateStatuses.normal;
  }

  const nextPaymentDate = getInventoryPaymentDate(history);

  return { inventoryDueStatus, nextPaymentDate, hasFailedPayments: history.find(h => !!h.failed_paid_message) };
};

const useInventoryHistory = (inventoryItems = [], accountProductsParams = {}, id = null) => {
  const [inventoryHistory, setInventoryHistory] = useState([]);
  const membershipCheck = useMembershipCheck();
  const { storageFeeInventory } = useFlags();

  const ids = id === null ? useMemo(() => inventoryItems.map(i => i.id) || [], [inventoryItems]) : [id];

  const { data, isLoading, refetch } = useQuery(
    [`${apiPaths.inventoryHistory}?account_products=${ids.join(',')}`, accountProductsParams],
    () =>
      accountProducts.inventoryHistory(
        `account_products=${ids.join(',')}&quantity__gte=1&histories=not_refund&remaining_inventory=true`
      ),
    {
      enabled: storageFeeInventory && inventoryItems.length > 0
    }
  );

  const useStorageTaxes = () => {
    const mutation = useMutation(stripeAPI.getStorageTaxes);

    const mutateWithPromise = async payload => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await mutation.mutateAsync(payload);
        return response;
      } catch (error) {
        throw error; // rethrow so it can be caught where the hook is used
      }
    };

    return mutateWithPromise;
  };

  useEffect(() => {
    if (!isEmpty(data?.results)) {
      const customiszedResults = data.results.map(ih => ({
        ...ih,
        dueStatus: getDueDateStatus(ih.last_paid_date),
        nextPaymentDate: getPaidDate(ih.last_paid_date),
        paidDate: dayjs(ih.last_paid_date).format('MM/DD/YY'),
        remainingQuantity: ih.quantity - ih.ship_quantity
      }));

      setInventoryHistory(customiszedResults);
    }
  }, [data]);

  const getAccountProductInventoryDueInfo = history =>
    getAccountProductInventoryDueInfoAction(history, membershipCheck.isPlatinum);

  const clearInventoryHistory = () => {
    setInventoryHistory([]);
  };

  return {
    inventoryHistory,
    isLoading,
    getAccountProductInventoryDueInfo,
    getGroupByPaidDate,
    refetch,
    clearInventoryHistory,
    useStorageTaxes
  };
};

export default useInventoryHistory;
