import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { CustomWidthButtonHolydays } from '../buttons';
import styles from './styles/onboarding';

const CentredContentContainer = ({ children, paddingTop = 0 }) => (
  <Grid container justifyContent="center" style={{ paddingTop }}>
    <Grid item>{children}</Grid>
  </Grid>
);

const WizardButtons = withStyles(styles)(({ classes, nextStep, previousStep, canNext, onKeyPress, canPrevious }) => (
  <Grid container className={classes.buttonsContainer}>
    <Grid item xs={6}>
      <CustomWidthButtonHolydays
        className={classes.previousButon}
        fontFamily="Gilroy"
        fontSize={16}
        onClick={previousStep}
        disabled={!canPrevious}
        type="button"
      >
        <div style={{ width: '100%', position: 'relative' }}>
          <ArrowBack className={classes.backArrow} /> Previous step
        </div>
      </CustomWidthButtonHolydays>
    </Grid>
    <Grid item xs={6}>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <CustomWidthButtonHolydays
            className={canNext ? classes.nextButon : classes.nextButonDisabled}
            fontFamily="Gilroy"
            fontSize={16}
            onClick={nextStep}
            onKeyPress={onKeyPress}
            disabled={!canNext}
          >
            <div style={{ width: '100%', position: 'relative' }}>Continue</div>
          </CustomWidthButtonHolydays>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
));
const mapStateToProps = state => ({
  currentStep: state.onboardingInfo.currentStep,
  maxSteps: state.onboardingInfo.paths.length
});
const StepHeader = connect(mapStateToProps)(
  withStyles(styles)(({ classes, currentStep, title, subTitle, maxSteps, smallTitle }) => (
    <div>
      <p className={classes.stepsText}>
        Step {currentStep}/{maxSteps - 1}
      </p>
      <p
        className={smallTitle || subTitle ? classes.stepTitleSmall : classes.stepTitle}
        style={{ marginBottom: subTitle ? 0 : undefined }}
      >
        {title}
      </p>
      <p className={classes.stepsSubTitleText}>{subTitle}</p>
    </div>
  ))
);

const InputAssigner = (inputs, Component, skips, skipsOnCondition, fields) => {
  Component.inputs = inputs;
  if (skips) Component.skips = skips;
  if (skipsOnCondition)
    Component.skipsOnCondition = {
      ...skipsOnCondition,
      condition: state => {
        const inquiry = skipsOnCondition.condition.values.includes(state[skipsOnCondition.condition.property]);
        return skipsOnCondition.reverse ? !inquiry : inquiry;
      }
    };
  if (fields) Component.fields = fields;
  return Component; // { ...Component, inputs, skips, clear };
};

export { CentredContentContainer, WizardButtons, StepHeader, InputAssigner };
