const buildCustomerPayload = (contacts, settings) => {
  const payload = contacts.map(c => ({
    platformId: c.id,
    firstName: c.first_name || undefined,
    lastName: c.last_name || undefined,
    companyName: c.company_name || undefined,
    email: c.email?.toLowerCase() || undefined,
    avatar: c.photo_path || undefined,
    title: c.title || undefined,
    amount: c.amount || undefined,
    ...settings
  }));
  return payload;
};

export { buildCustomerPayload };
