import {
  CATALOG_API_CALL_START,
  CATALOG_API_CALL_SUCCESS,
  CATALOG_API_CALL_ERROR,
  CATALOG_LOAD,
  CATALOG_PURCHASE,
  CATALOG_SET_SELECTED_COLLECTIONS,
  CATALOG_RESET_SELECTED_COLLECTIONS
} from '../actions/types';

const initialState = { items: undefined, selectedCollections: [], order: null, loading: false, error: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case CATALOG_API_CALL_START:
      return { ...state, loading: true, error: null };
    case CATALOG_API_CALL_SUCCESS:
      return { ...state, loading: false, error: null };
    case CATALOG_API_CALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CATALOG_LOAD:
      return {
        ...state,
        items: action.payload.catalog
      };

    case CATALOG_PURCHASE:
      return {
        ...state,
        order: action.payload.order
      };

    case CATALOG_SET_SELECTED_COLLECTIONS:
      return {
        ...state,
        selectedCollections: action.payload.selectedCollections
      };

    case CATALOG_RESET_SELECTED_COLLECTIONS:
      return {
        ...state,
        selectedCollections: []
      };

    default:
      return state;
  }
};
