import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { Button, Tabs, Typography } from '@swagup-com/components';
import CloseIcon from '@material-ui/icons/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ListViewIcon, CardViewIcon } from '../icons';
import { CustomTab } from '../products/commonProductsElements';
import Banner from '../shared/Banner';
import useMembershipCheck from '../../hooks/useMembershipCheck';
import SearchSortFilter from '../shared/SearchSortFilter';
import useIntegrations from '../../hooks/useIntegrations';
import { useProductsFilters } from '../../hooks/useFilters';
import { useMembership } from '../../contexts/membershipContext';

const useStyles = makeStyles(theme => ({
  reorderButton: {
    width: 190,
    height: 56
  },
  titleContainer: {
    [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
  },
  toggleButton: {
    boxSizing: 'border-box',
    minWidth: 36,
    height: 36,
    display: 'inline-flex',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 12,
    color: ({ active }) => (active ? '#3577d4' : '#d3dbe5'),
    border: 0,
    padding: 0
  },
  action: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      justifyContent: 'center'
    }
  },
  actionBar: {
    width: 'auto',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
      marginLeft: 10
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center'
    }
  },
  filtersContainer: {
    marginTop: 12,
    minHeight: 56,
    borderTop: '1px solid #E5E7E8',
    borderBottom: '1px solid #E5E7E8',
    width: 'calc(100vw - 17px)',
    overflowX: 'hidden',
    marginRight: '-50%',
    marginLeft: '-50%',
    transform: 'translate(0%)'
  },
  storageFeeBanner: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    position: 'relative',
    marginRight: 40
  },
  bannerCloseBtn: {
    position: 'absolute',
    top: 22,
    right: 8,
    color: '#131415'
  }
}));

const ToggleButton = ({ title, onClick, isCardView, grid }) => {
  const classes = useStyles({ active: isCardView });

  return (
    <Button
      aria-label={title}
      title={title}
      disableTouchRipple
      disableFocusRipple
      disableRipple
      onClick={onClick}
      className={classes.toggleButton}
      startIcon={grid ? <CardViewIcon /> : <ListViewIcon />}
    />
  );
};

const ViewToggle = ({ isCardView, onShowCardView }) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <ToggleButton title="Card view" onClick={() => onShowCardView(true)} isCardView={isCardView} grid />
      <ToggleButton title="List view" onClick={() => onShowCardView(false)} isCardView={!isCardView} />
    </Grid>
  );
};

const InventoryHeader = ({ disabled, isCardView, inventoryValue, onShowCardView, onInventoryChange }) => {
  const classes = useStyles();
  const { storageFeeInventory, leftBarNavigation } = useFlags();
  const membershipCheck = useMembershipCheck();
  const PlatinumMembershipTier = membershipCheck.isPlatinum;
  const [showMembershipBanner, setShowMembershipBanner] = useState(true);
  const {
    currentMembership: { storage_discount: storageDiscount }
  } = useMembership();
  const FreeInventoryStorageFee = storageDiscount === Number(100);

  const handleMembershipBannerClose = () => {
    setShowMembershipBanner(false);
  };

  return (
    <>
      {!leftBarNavigation && (
        <Grid container item xs={12} justifyContent="space-between" style={{ marginBottom: 24 }}>
          <Grid container item lg={5} alignItems="center" className={classes.titleContainer} style={{ height: 56 }}>
            <Typography variant="h2BoldInter">Inventory</Typography>
          </Grid>
          <Grid>
            <Grid item className={classes.action}>
              <Button
                component={Link}
                to={location => ({ pathname: '/send-swag/select-products', state: { from: location } })}
                variant="primary"
                disabled={disabled}
                className={classes.reorderButton}
              >
                Send Swag
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {showMembershipBanner && storageFeeInventory && !PlatinumMembershipTier && !FreeInventoryStorageFee && (
        <Grid container className={classes.storageFeeBanner}>
          <Banner
            image="/images/pricing/platinum2x.png"
            title="Upgrade to Platinum now and get FREE storage"
            subtitle="Tired of paying storage costs?"
            buttonLabel="Upgrade My Plan"
            buttonLink="/membership/manage-membership-switch"
            highlightedWord="Platinum"
          />
          <IconButton className={classes.bannerCloseBtn} onClick={handleMembershipBannerClose}>
            <CloseIcon style={{ fontSize: 20 }} />
          </IconButton>
        </Grid>
      )}
      <Grid container item xs={12} justifyContent="space-between" alignContent="center" alignItems="center">
        <Tabs value={inventoryValue} onChange={(e, newValue) => onInventoryChange(newValue)}>
          <CustomTab label="All" value="" />
          <CustomTab label="In Stock" value="available" />
          <CustomTab label="Out of Stock" value="out_of_stock" />
        </Tabs>
        <>
          {!disabled && (
            <Grid item className={classes.action}>
              <ViewToggle isCardView={isCardView} onShowCardView={onShowCardView} />
            </Grid>
          )}
        </>
      </Grid>
    </>
  );
};

export default InventoryHeader;
