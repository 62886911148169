import axios from 'axios';
import axiosInstance from '../DashBoard';
import publicInstance from '../PublicAPI';
import apiPaths from '../../helpers/apiPaths';
import { status200 } from './status.utils';

const fetchMetadata = () => axiosInstance.get(apiPaths.metadata, status200).then(response => response.data.results);

let cancelToken;
export default {
  fetchAccount: () => axiosInstance.get(apiPaths.accounts, status200).then(response => response.data.results[0]),

  fetchCountries: (active = true) =>
    axiosInstance.get(apiPaths.countries, { params: { active }, ...status200 }).then(response => response.data.results),

  fetchSizes: () => axiosInstance.get(apiPaths.sizes, status200).then(response => response.data.results),
  fetchStoragePrices: () => axiosInstance.get(apiPaths.storagePrices, status200).then(response => response.data),
  fetchStoragePricesWithStorage: () =>
    axiosInstance.get(apiPaths.storagePricesWithStorage, status200).then(response => response.data),

  fetchStorageCategories: () =>
    axiosInstance.get(apiPaths.storageCategories, status200).then(response => response.data.results),

  fetchMetadata,

  fetchMetadataAsObj: () =>
    fetchMetadata().then(results => {
      const metadata = {};
      results.forEach(r => {
        metadata[r.field] = r.value;
      });

      return metadata;
    }),

  fetchProfile: () => axiosInstance.get(apiPaths.profiles, status200).then(response => response.data.results[0]),

  fetchFilters: page => axiosInstance.get(apiPaths.filters(page), { ...status200 }).then(response => response.data),

  setAccount: (profileId, newCurrentAccount) =>
    axiosInstance.patch(apiPaths.profile(profileId), newCurrentAccount, status200).then(response => response.data),

  validatePhones: phoneNumbers => {
    if (cancelToken) cancelToken.cancel('Phones validation request canceled due to new request');

    cancelToken = axios.CancelToken.source();
    return publicInstance
      .post('/phone-numbers/', phoneNumbers, { ...status200, cancelToken: cancelToken.token })
      .finally(() => {
        cancelToken = null;
      });
  }
};
