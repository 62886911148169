import { PATCH_PROFILE } from './types';
import DashBoard from '../apis/DashBoard';
import { okAndLog, errorAndLog } from '../helpers/utils';
import apiPaths from '../helpers/apiPaths';
import { status200 } from '../apis/swagup/status.utils';

export const patchProfile = profile => dispatch =>
  DashBoard.put(apiPaths.profile(profile.id), profile, status200)
    .then(({ status, data }) => {
      dispatch({
        type: PATCH_PROFILE,
        payload: data
      });
      return okAndLog('patchProfile', status, data);
    })
    .catch(({ response }) => errorAndLog('patchProfile', response?.status, response?.data));
