import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, makeStyles, withStyles, Tooltip as MuiTooltip } from '@material-ui/core';
import { Button, TextField, Typography } from '@swagup-com/components';
import { CheckCircleRounded, ChevronRight, CloudUploadOutlined } from '@material-ui/icons';
import { debounce } from 'lodash';
import { StylessButton } from '../buttons';
import { FileUploadZone, prepareArtworksOnS3 } from '../redeemPages/redeemCommon';
import { imageSrcSet, lowerWithoutSpace, s3 } from '../../helpers/utils';
import { ellipsisStyles, scrollBar } from '../shared/styles/commonStyles';
import { SearchField } from '../products/commonProductsElements';
import Img from '../shared/Img';
import { Pagination } from '../shared';

const actions = { newProduct: 'new-product', connectExisting: 'connect-existing' };

const styles = theme => ({
  integrationSelectionImageWrapper: {
    height: 72,
    width: 72,
    position: 'relative',
    borderRadius: 10
  },
  integrationProductImageWrapper: {
    height: 100,
    width: 100,
    position: 'relative',
    border: '1px solid #E5E7E8',
    borderRadius: 10,
    overflow: 'hidden'
  },
  paginationButton: {
    height: 42,
    width: 42,
    cursor: 'pointer',
    color: '#4a505c',
    boxShadow: 'none !important',
    '&:hover': {
      color: '#3577D4'
    }
  },
  integrationItem: {
    height: 24,
    width: 24,
    position: 'relative'
  },
  integrationImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  integrationSelectionItem: {
    marginBottom: 30,
    borderRadius: 10,
    position: 'relative',
    padding: ({ product }) => (product ? '16px 14px' : '20px 30px'),
    background: ({ selected, alReadyConnected }) => (alReadyConnected ? '#F5F7F8' : selected ? '#3577d414' : '#ffffff'),
    border: ({ selected }) => (selected ? '1px solid  #3577D4' : '1px solid  #E5E7E8'),
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.07)',
      border: ({ selected }) => (selected ? '1px solid  #3577D4' : '1px solid  #E5E7E8')
    }
  },
  emptyProductsImageWrapper: {
    height: 164,
    width: 164,
    position: 'relative'
  },
  integrationSection: {
    width: '100% !important'
  },
  textField: {
    border: '1px solid  #E5E7E8',
    borderRadius: 10
  },
  mediaSection: {
    border: '1px solid  #E5E7E8',
    padding: 8,
    borderRadius: 10
  },
  mediaWrapper: {
    border: '1px solid  #E5E7E8',
    height: 248,
    width: 248,
    borderRadius: 10
  },
  imageUploadRegion: {
    border: '1px dotted  #E5E7E8',
    padding: 20
  },
  dragDrop: {
    textAlign: 'center',
    color: '#6F777E',
    '& span': {
      color: '#3577d4'
    },
    marginBottom: 12
  },
  acceptedFilesText: {
    textAlign: 'center',
    color: '#989EA4',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: ' 16px'
  },
  variant: {
    background: '#D6D8DB',
    height: 28,
    margin: '2px 0px 4px 0px',
    borderRadius: 14,
    padding: '0px 12px',
    maxWidth: '100%',
    '& p': {
      ...ellipsisStyles,
      color: '#4A4F54',
      fontSize: 10
    },
    width: 'fit-content'
  },
  modalIconImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  automationModalHeader: {
    paddingTop: 12,
    minHeight: ({ small }) => (small ? 64 : 112)
  },
  automationModalHeaderText: {
    ...ellipsisStyles,
    color: '#131415',
    fontSize: '20px',
    lineHeight: ' 28px',
    letterSpacing: '-0.03em'
  },
  connected: {
    height: 24,
    width: 24,
    position: 'absolute',
    top: -6,
    right: -6,
    background: '#4CAF50',
    borderRadius: 12,
    color: '#C8E6C9'
  },
  stepContent: {
    width: '100%',
    maxHeight: 400,
    position: 'relative',
    ...scrollBar
  },
  stepContentMin: {
    width: '100%',
    maxHeight: 242,
    position: 'relative',
    ...scrollBar
  },
  emptyProductsTitle: {
    textAlign: 'center',
    marginBottom: 16
  },
  emptyProductsText: {
    textAlign: 'center',
    width: 320,
    marginTop: 16,
    marginBottom: 24
  },
  integrationItemDescription: {
    maxWidth: '100%',
    maxHeight: 124,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: 12,
    color: '#4A4F54',
    ...ellipsisStyles,
    '& li': {
      fontSize: 14,
      fontWeight: 400,
      color: '#4A4F54',
      lineHeight: '20px'
    },
    '& ul': {
      marginBlockStart: '0px',
      marginBlockEnd: '0px',
      marginInlineStart: '0px',
      marginInlineEnd: '0px',
      paddingInlineStart: '14px'
    }
  },
  integrationItemTitle: ({ product }) => ({
    color: product ? '#4A4F54' : '#131415',
    fontSize: product ? 16 : 20,
    lineHeight: product ? '20px' : '28px',
    ...(product ? ellipsisStyles : undefined)
  })
});

const useStyles = makeStyles(styles);

const getItegrationImageUrl = platform => {
  switch (platform) {
    case 'SHOPIFY':
      return '/images/integrations/shopify.png';
    case 'AMAZON':
      return '/images/integrations/amazon.png';
    case 'RIPPLING':
      return '/images/integrations/rippling.png';
    case 'COMSOR':
      return '/images/integrations/comsor.png';
    case 'SALESFORCE':
      return '/images/integrations/salesforce.png';
    default:
      return false;
  }
};

const getItegrationLogoImageUrl = platform => {
  switch (platform) {
    case 'SHOPIFY':
      return '/images/integrations/shopify-logo.png';
    case 'AMAZON':
      return '/images/integrations/amazon-logo.png';
    case 'RIPPLING':
      return '/images/integrations/rippling-logo.png';
    case 'COMSOR':
      return '/images/integrations/comsor-logo.png';
    case 'SALESFORCE':
      return '/images/integrations/salesforce-logo.png';
    default:
      return '/images/integrations/shopify-logo.png';
  }
};

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#3B4048',
    maxWidth: 'unset',
    padding: 10,
    borderRadius: 4,
    '& > p': { color: '#FFF' }
  },
  arrow: {
    color: '#3B4048'
  }
})(({ children, title, ...props }) => (
  <MuiTooltip
    arrow
    title={title ? <Typography variant="body3RegularInter">{title}</Typography> : ''}
    placement="top"
    {...props}
  >
    <div>{children}</div>
  </MuiTooltip>
));

const IntegrationItem = ({
  onNext,
  title,
  description,
  variant,
  icon,
  alReadyConnected,
  small,
  selected,
  product,
  ellipsisStylesStyle,
  long,
  html
}) => {
  const classes = useStyles({ selected, product, alReadyConnected });
  const longSize = long ? 9 : 8;
  return (
    <StylessButton className={classes.integrationSection} onClick={onNext} disabled={alReadyConnected}>
      <Grid container justifyContent="center">
        <Grid item xs={small ? 6 : longSize}>
          <Box className={classes.integrationSelectionItem}>
            <Grid container alignItems="center" spacing={6}>
              <Grid item>{icon}</Grid>
              <Grid item xs={small ? 6 : 8} style={{ textAlign: 'left', position: 'relative' }}>
                <CustomTooltip
                  title={title}
                  arrow
                  placement="top-start"
                  disableHoverListener={!ellipsisStylesStyle || !title || title.length < 17}
                >
                  <Typography
                    variant="subtitle2SemiBoldInter"
                    className={classes.integrationItemTitle}
                    style={{ marginBottom: product ? 12 : 0 }}
                  >
                    {title}
                  </Typography>
                </CustomTooltip>

                {variant && (
                  <Grid container justifyContent="center" alignItems="center" className={classes.variant}>
                    <Grid item style={{ maxWidth: '100%' }}>
                      <Typography variant="body4RegularInter">{variant}</Typography>
                    </Grid>
                  </Grid>
                )}
                {description &&
                  (html ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: description }}
                      className={classes.integrationItemDescription}
                    />
                  ) : (
                    <Typography variant="body3RegularInter" className={classes.integrationItemDescription}>
                      {description}
                    </Typography>
                  ))}
              </Grid>
              <Grid item style={{ paddingRight: 0, paddingLeft: 0 }}>
                {!product && <ChevronRight style={{ color: '#131415', marginTop: 4 }} />}
              </Grid>
            </Grid>

            {alReadyConnected && <CheckCircleRounded className={classes.connected} />}
          </Box>
        </Grid>
      </Grid>
    </StylessButton>
  );
};

const ModalHeader = ({ icon, title, subText, small, additionalLayer }) => {
  const classes = useStyles({ small });
  return (
    <Box className={classes.automationModalHeader}>
      {icon && (
        <Grid container justifyContent="center">
          <Grid item>{icon}</Grid>
        </Grid>
      )}
      {title && (
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h5BoldInter" style={{ marginBottom: 20, textAlign: 'center' }}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      )}
      {subText && (
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="body1MediumInter">{subText}</Typography>
          </Grid>
        </Grid>
      )}
      {additionalLayer}
    </Box>
  );
};

const getIntegrationImageSrcSet = platform =>
  getItegrationImageUrl(platform) ? imageSrcSet(getItegrationImageUrl(platform)) : undefined;

const PlatformConnectionOptions = ({
  availableIntegrations,
  onSelectPlatform,
  getConnectedPlatform,
  selectedIntegration
}) => {
  const classes = useStyles();
  return (
    <Box>
      <ModalHeader
        title="Connect with your existing tools"
        subText="Simplify fulfillment of orders coming from external channels"
      />
      <Box className={classes.stepContent} style={{ paddingTop: 16, minHeight: 412 }}>
        {availableIntegrations.map(integration => (
          <IntegrationItem
            key={integration.rutter_id}
            onNext={() => onSelectPlatform(integration)}
            title={integration.name}
            icon={
              <Box className={classes.integrationSelectionImageWrapper}>
                <Img
                  src={integration.image_url}
                  srcSet={getIntegrationImageSrcSet(integration.rutter_id)}
                  alt={integration.name}
                  className={classes.integrationImage}
                />
                {!!getConnectedPlatform(integration.rutter_id) && <CheckCircleRounded className={classes.connected} />}
              </Box>
            }
            small
            selected={selectedIntegration?.rutter_id === integration.rutter_id}
          />
        ))}
      </Box>
    </Box>
  );
};

const ConnectionOptions = ({ onSelectAction, selectedAction }) => {
  const classes = useStyles();
  return (
    <Box>
      <ModalHeader />
      <Box className={classes.stepContent}>
        <IntegrationItem
          onNext={() => onSelectAction(actions.connectExisting)}
          title="Connect to existing products"
          icon={
            <Box className={classes.integrationSelectionImageWrapper}>
              <Img
                srcSet={imageSrcSet('/images/integrations/sync.png')}
                alt="Connect to existing products"
                className={classes.integrationImage}
              />
            </Box>
          }
          selected={selectedAction === actions.connectExisting}
        />
        <IntegrationItem
          onNext={() => onSelectAction(actions.newProduct)}
          title="Create new product"
          icon={
            <Box className={classes.integrationSelectionImageWrapper}>
              <Img
                srcSet={imageSrcSet('/images/integrations/new.png')}
                alt="Create new product"
                className={classes.integrationImage}
              />
            </Box>
          }
          selected={selectedAction === actions.newProduct}
        />
      </Box>
    </Box>
  );
};

const ConntecExistingForm = ({
  onUpdate,
  selectedProduct,
  integrationProducts = [],
  onBack,
  paginatedFlieds = {},
  setSearch,
  total = -1,
  isLoading
}) => {
  const { nextPage, pageIndex, perPage, sizeOptions, previousPage, changeSize } = paginatedFlieds;

  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value);
    }, 750),
    [setSearch]
  );

  const searchByName = value => {
    debouncedSearch(value);
  };

  const classes = useStyles();
  return (
    <Box>
      <ModalHeader
        title="Select which product you want to connect to "
        additionalLayer={
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container alignItems="center" style={{ marginBottom: 16 }}>
                <Grid item xs={6}>
                  <SearchField
                    key="search"
                    placeholder="Search By Name"
                    onChange={searchByName}
                    rounded
                    lean
                    inverseHover
                  />
                </Grid>
                <Grid item xs />
                <Grid item>
                  <Pagination
                    count={total}
                    endText="products"
                    perPage={perPage}
                    onNext={nextPage}
                    pageIndex={pageIndex}
                    onPrevious={previousPage}
                    sizeOptions={sizeOptions}
                    onPerPageChange={changeSize}
                    buttonClass={classes.paginationButton}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Box className={classes.stepContent}>
        {isLoading ? (
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="body3RegularInter" style={{ marginTop: 32 }}>
                Loading...
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <div>
            {integrationProducts.length ? (
              integrationProducts.map(product => (
                <IntegrationItem
                  key={product.id}
                  onNext={() => onUpdate(product)}
                  title={product.name}
                  description={product.sku ? `SKU: ${product.sku}` : product.description}
                  html
                  icon={
                    <Box className={classes.integrationProductImageWrapper}>
                      <Img
                        src={product.image || '/images/public/nopic.jpg'}
                        alt={product.name}
                        className={classes.integrationImage}
                      />
                    </Box>
                  }
                  selected={
                    selectedProduct?.rutter_id === product.rutter_id ||
                    !!product.variants.find(v => v.rutter_id === selectedProduct?.rutter_id)
                  }
                  ellipsisStylesStyle={ellipsisStyles}
                  product
                  long
                />
              ))
            ) : (
              <Box>
                <Typography variant="body1MediumInter" className={classes.emptyProductsTitle}>
                  You don’t have products to connect yet!
                </Typography>

                <Grid container justifyContent="center">
                  <Grid item>
                    <Box className={classes.emptyProductsImageWrapper}>
                      <Img
                        srcSet={imageSrcSet('/images/integrations/empty-products.png')}
                        className={classes.integrationImage}
                        alt="empty-products"
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="body3RegularInter" className={classes.emptyProductsText}>
                      Go back to start creating new products into your store.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button size="small" variant="primary" onClick={onBack}>
                      Go Back
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
};

const ConntecExistingVariantForm = ({ onUpdate, platformProduct, onBack }) => {
  const [selectedProduct, setSelectedProduct] = useState();
  useEffect(() => {
    if (platformProduct?.variants) setSelectedProduct(platformProduct);
  }, [platformProduct]);
  const classes = useStyles();
  return (
    <Box>
      <ModalHeader
        title="Select which variant you’d like to connect to"
        subText="You need to connect each variant one by one"
      />
      <Box className={classes.stepContent} style={{ paddingTop: 5 }}>
        {selectedProduct?.variants?.length > 0 ? (
          <>
            {selectedProduct?.variants?.map(product => (
              <IntegrationItem
                key={product.id}
                onNext={() => onUpdate({ ...product, parentName: platformProduct.name })}
                title={selectedProduct.name}
                variant={product.name}
                description={product.sku ? `SKU: ${product.sku}` : ''}
                ellipsisStylesStyle={ellipsisStyles}
                icon={
                  <Box className={classes.integrationProductImageWrapper}>
                    <Img
                      src={product.image || '/images/public/nopic.jpg'}
                      alt={product.name}
                      className={classes.integrationImage}
                    />
                  </Box>
                }
                selected={platformProduct?.rutter_id === product.rutter_id}
                product
                alReadyConnected={product.connected}
              />
            ))}
          </>
        ) : (
          <Box>
            <Typography variant="body1MediumInter" className={classes.emptyProductsTitle}>
              There are no variants connect yet!
            </Typography>

            <Grid container justifyContent="center">
              <Grid item>
                <Box className={classes.emptyProductsImageWrapper}>
                  <Img
                    srcSet={imageSrcSet('/images/integrations/empty-products.png')}
                    className={classes.integrationImage}
                    alt="empty-products"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="body3RegularInter" className={classes.emptyProductsText}>
                  Go back to select another product from your store.
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Button size="small" variant="primary" onClick={onBack}>
                  Go Back
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ConntecExistingVariantToSwagUPPorductForm = ({ onUpdate, platformProduct, swagupProduct }) => {
  const [selectedProduct, setSelectedProduct] = useState();
  useEffect(() => {
    if (selectedProduct && swagupProduct) onUpdate(selectedProduct);
  }, [onUpdate, platformProduct, selectedProduct, swagupProduct]);

  const onSelectSize = size => {
    // setSelectedProduct(prev =>
    //   prev
    //     ? {
    //         ...prev,
    //         variants: prev.variants.find(v => v.id === size.id)
    //           ? prev.variants.filter(v => v.id !== size.id)
    //           : [...prev.variants, size]
    //       }
    //     : { ...swagupProduct, variants: [size] }
    // );
    setSelectedProduct(prev =>
      prev
        ? {
            ...prev,
            variants: prev.variants.find(v => v.id === size.id) ? prev.variants.filter(v => v.id !== size.id) : [size]
          }
        : { ...swagupProduct, variants: [size] }
    );
  };

  useEffect(() => {
    if (swagupProduct && swagupProduct.stock.length === 1) onSelectSize(swagupProduct.stock[0].size);
  }, []);

  const classes = useStyles();
  return (
    <Box>
      <ModalHeader title="Match this variant in Shopify" small />
      <Box>
        <IntegrationItem
          title={platformProduct.parentName}
          description={platformProduct.sku ? `SKU: ${platformProduct.sku}` : ''}
          variant={platformProduct.name}
          icon={
            <Box className={classes.integrationProductImageWrapper}>
              <Img
                src={platformProduct.image || '/images/public/nopic.jpg'}
                alt={platformProduct.name}
                className={classes.integrationImage}
              />
            </Box>
          }
          selected
          ellipsisStylesStyle={ellipsisStyles}
          product
        />
      </Box>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="h5BoldInter" style={{ marginBottom: 12, textAlign: 'center' }}>
            To one of these SwagUp variants
          </Typography>
        </Grid>
      </Grid>
      <Box className={classes.stepContentMin}>
        {swagupProduct?.stock?.map(size => (
          <IntegrationItem
            key={size.id}
            onNext={() => onSelectSize(size.size)}
            title={swagupProduct.name}
            variant={`${swagupProduct.theme_color || 'Custom'}\\${size.size.name}`}
            description={swagupProduct.description}
            icon={
              <Box className={classes.integrationProductImageWrapper}>
                <Img
                  src={swagupProduct.image || '/images/public/nopic.jpg'}
                  alt={swagupProduct.name}
                  className={classes.integrationImage}
                />
              </Box>
            }
            selected={!!selectedProduct?.variants?.find(v => v.id === size.size.id)}
            ellipsisStylesStyle={ellipsisStyles}
            product
          />
        ))}
      </Box>
    </Box>
  );
};

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: '14px 16px',
        fontFamily: 'Helvetica'
      },
      '& fieldset': {
        border: '1px solid #D6D8DB',
        borderRadius: 10
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #3577D4'
      }
    },
    '& .MuiInputBase-multiline': {
      padding: '14px 16px'
    }
  }
})(TextField);

const NewProductForm = ({ swagupProduct, onUpdate }) => {
  const [product, setProduct] = useState(
    swagupProduct
      ? {
          id: swagupProduct.id,
          swagupId: swagupProduct.id,
          name: swagupProduct.name,
          description: swagupProduct.description,
          image: swagupProduct.image,
          price: swagupProduct.price_per_unit_based_on_100
        }
      : undefined
  );

  const onChange = ({ target: { name, value } }) => {
    setProduct({ ...product, [name]: value });
  };

  useEffect(() => {
    if (product) onUpdate(product);
  }, [onUpdate, product]);

  const handleFileUpload = async acceptedFiles => {
    const image = acceptedFiles[0];
    const objectWithS3Image = await prepareArtworksOnS3(image);
    onChange({ target: { name: 'image', value: objectWithS3Image.url } });
  };

  const classes = useStyles();
  return (
    <Box>
      <ModalHeader title="Create New Item" subText="Finish creating this product inside Shopify" />
      <Grid container justifyContent="center" className={classes.stepContent}>
        <Grid item xs={10} style={{ paddingBottom: 28 }}>
          <Typography variant="body4MediumInter" style={{ marginLeft: 4, marginBottom: 4 }}>
            Title
          </Typography>
          <CustomTextField
            name="name"
            placeholder="Title goes here"
            defaultValue={product.name}
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={10} style={{ paddingBottom: 28 }}>
          <Typography variant="body4MediumInter" style={{ marginLeft: 4, marginBottom: 4 }}>
            Description
          </Typography>
          <CustomTextField
            name="description"
            placeholder="Description goes here"
            multiline
            minRows={3}
            maxRows={5}
            defaultValue={product.description}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={10} style={{ paddingBottom: 28 }}>
          <Typography variant="body4MediumInter" style={{ marginLeft: 4, marginBottom: 4 }}>
            Price
          </Typography>
          <CustomTextField
            name="price"
            placeholder="You can change the price here"
            defaultValue={product.price}
            fullWidth
            type="number"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body4MediumInter" style={{ marginLeft: 4, marginBottom: 4 }}>
            Media
          </Typography>
          <Box className={classes.mediaSection}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item>
                <Box className={classes.mediaWrapper}>
                  <Img src={product.image} alt={product.name} className={classes.integrationImage} />
                </Box>
              </Grid>
              <Grid item xs>
                <FileUploadZone handleFileUpload={handleFileUpload}>
                  <Box className={classes.imageUploadRegion}>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <CloudUploadOutlined className={classes.dragDrop} />
                      </Grid>
                      <Grid item>
                        <Typography variant="body3MediumInter" className={classes.dragDrop}>
                          Drag and drop to upload or <br /> <span>choose file</span>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body4RegularInter" className={classes.acceptedFilesText}>
                          Accepted file types: .PNG, .GIF, .JPEG, .JPG, .SVG
                        </Typography>
                        <Typography variant="body4RegularInter" className={classes.acceptedFilesText}>
                          Max file size: 5MB
                        </Typography>
                        {/* {error && (
                            <Typography variant="body4RegularInter" className={classes.preferedImageError}>
                              Your file could not be uploaded. Please check the accepted file types and file size and
                              try again
                            </Typography>
                          )} */}
                      </Grid>
                    </Grid>
                  </Box>
                </FileUploadZone>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const prepareArtworksOnS3V2 = async artwork => {
  const s3Data = await s3
    .upload({
      Key: `${Date.now()}-${artwork.name.replaceAll(' ', '-').replaceAll('_', '-')}`,
      Body: artwork.imageBuffer,
      ContentType: artwork.type
    })
    .promise();
  return { name: artwork.name, url: s3Data.Location };
};

const getImageAttributes = imagePath => {
  const name =
    imagePath.substring(0, imagePath.lastIndexOf('/')) +
    lowerWithoutSpace(imagePath.substring(imagePath.lastIndexOf('/'), imagePath.lastIndexOf('.')));

  const extension = imagePath.lastIndexOf('.') > 0 ? imagePath.substring(imagePath.lastIndexOf('.') + 1) : undefined;
  return { name, type: `image/${extension}` };
};
const isNumberWithTwoDecimalPlaces = str => {
  const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
  return regex.test(str);
};

const noImageParameter = url => (url ? url.replace(/\?.+$/, '') : url);

export {
  getItegrationImageUrl,
  NewProductForm,
  ConntecExistingForm,
  ConntecExistingVariantForm,
  ConntecExistingVariantToSwagUPPorductForm,
  IntegrationItem,
  PlatformConnectionOptions,
  ConnectionOptions,
  prepareArtworksOnS3V2,
  getImageAttributes,
  actions,
  getItegrationLogoImageUrl,
  getIntegrationImageSrcSet,
  isNumberWithTwoDecimalPlaces,
  noImageParameter
};
