import * as React from 'react';
import { Tooltip, Typography } from '@swagup-com/components';

const CompanyWarningTooltip = ({ children }) => (
  <Tooltip
    title={
      <Typography variant="body4SemiBoldInter" style={{ textAlign: 'center' }}>
        For Company Addresses Only. Do not enter for residential addresses as this may result in a delivery failure
      </Typography>
    }
  >
    {children}
  </Tooltip>
);

export default CompanyWarningTooltip;
