import { SET_PROOF_DATA } from '../actions/types';

const ProofConfirmationReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PROOF_DATA: {
      const { items, ...payload } = action.payload;
      return state.map(proof =>
        proof.id === action.payload.id
          ? {
              ...proof,
              product: { ...proof.product, items },
              ...payload
            }
          : proof
      );
    }

    default:
      return state;
  }
};

export default ProofConfirmationReducer;
