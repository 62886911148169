import { SET_OPPORTUNITY } from '../actions/types';

const opportunityReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_OPPORTUNITY:
      return action.payload;

    default:
      return state;
  }
};

export default opportunityReducer;
