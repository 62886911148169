import { hideableFilters } from '../../../../pages/orders/requested/shipments/TableCommon';

export default {
  paper: {
    maxWidth: 1110,
    padding: '38px 50px 24px'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 24,
    color: '#0b1829',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  filters: {
    flex: 1,
    height: 50,
    overflow: 'hidden'
  },
  hideableFilters,
  addButton: {
    fontSize: 12,
    fontFamily: 'Gilroy-SemiBold',
    letterSpacing: 0,
    height: 'unset',
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 8
    }
  },
  addRecipientsButton: {
    height: 56,
    minWidth: 304,
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: '#fafafa',
      color: '#787b80'
    }
  },
  tableContainer: {
    border: 'solid 1px #f0f2f5',
    borderRadius: 10,
    height: '100%',
    overflow: 'hidden',
    marginBottom: 80
  },
  tableFlex: {
    flex: 1,
    minHeight: 44
  },
  table: {
    flex: 0,
    minHeight: 44
  },
  caption: {
    bottom: 0,
    left: 0,
    zIndex: 2,
    position: 'sticky',
    background: '#ffffff',
    padding: '8px 20px'
  },
  divider: {
    height: 12,
    backgroundColor: '#f0f2f5',
    margin: '0 14px'
  },
  paginationContainer: {
    minHeight: 64,
    borderTop: 'solid 1px #f0f2f5',
    padding: '8px 20px'
  },
  paginationBtn: {
    width: 36,
    minWidth: 36,
    height: 36,
    padding: 8,
    '& svg': {
      width: 20,
      height: 20
    }
  },
  addButtonContainer: {
    padding: '24px 0',
    position: 'absolute',
    bottom: 0,
    left: 50
  },
  emptyContainer: {
    flex: 1,
    marginTop: '-3%'
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: '#787b80',
    marginTop: 10
  },
  emptyImg: {
    marginBottom: 24,
    height: 180,
    width: 180
  },
  addCutomersBtn: {
    fontSize: 12,
    fontFamily: 'Gilroy-SemiBold',
    letterSpacing: 0,
    height: 'unset',
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 8
    },
    width: 200
  }
};
