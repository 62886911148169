import React from 'react';
import { Grid, withStyles } from '@material-ui/core';

import styles from './Brands.styles';

const Brands = ({ classes, title, brands }) => (
  <Grid container justifyContent="center">
    <Grid container justifyContent="center" className={classes.container}>
      <p className={classes.title}>{title}</p>
      <Grid container justifyContent="center">
        {brands.map(brand => (
          <Grid item key={brand.src} className={classes.imgContainer}>
            <img src={brand.src} alt={brand.alt} style={brand.style} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

export default withStyles(styles)(Brands);
