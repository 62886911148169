import throttle from 'lodash/throttle';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { saveState, loadState } from './apis/LocalStorage';
import reducers from './reducers';
import log from './logger';

const setupLogRocket = middleware => {
  log.debug('app version:', process.env.REACT_APP_VERSION);
  if (process.env.REACT_APP_LOG_ROCKET_PROJECT_ID && window.location.hostname !== 'localhost') {
    log.debug('setting up logrocket');
    // eslint-disable-next-line global-require
    const LogRocket = require('logrocket');
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_PROJECT_ID, {
      release: process.env.REACT_APP_VERSION
    });
    // eslint-disable-next-line global-require
    const setupLogRocketReact = require('logrocket-react');
    setupLogRocketReact(LogRocket);
    return [...middleware, LogRocket.reduxMiddleware()];
  }
  log.debug('not loading logrocket');
  return middleware;
};

const setupReduxDevTools = enhancer => {
  if (process.env.NODE_ENV !== 'production' || window.location.hostname === 'localhost') {
    log.debug('setting up Redux DevTools');
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return composeEnhancer(enhancer);
  }
  return enhancer;
};

const createReduxStore = () => {
  const middleware = setupLogRocket([thunk]);
  log.debug('applying middleware');
  const enhancer = setupReduxDevTools(applyMiddleware(...middleware));

  const initialState = loadState();
  log.debug(`about to create redux store, initial state will${initialState ? '' : ' not'} be loaded from localStorage`);
  const store = createStore(reducers, initialState, enhancer);

  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );

  return store;
};

const store = createReduxStore();

export default store;
