import { sumByQuantity, isPack } from '../../helpers/utils';
import log from '../../logger';

const getSizesTotalQtyErrorMsg = proof => {
  log.debug('getSizesTotalQtyErrorMsg, proof:', proof);
  const totalQty = sumByQuantity(proof.sizes);
  const min = proof.product.minimum_to_reorder > 0 ? proof.product.minimum_to_reorder : 1;
  if (totalQty < min) {
    return `Total ${isPack(proof.product.record_type) ? 'packs' : 'items'} must be ${min} or more`;
  }
  if (proof.price === 0) {
    return 'No price available. Try again or contact your Account Manager';
  }
  return '';
};

export default getSizesTotalQtyErrorMsg;
