const customScrollBar = {
  overflowY: 'auto',
  overflowX: 'hidden',
  width: 'auto',
  marginRight: -1,
  '&::-webkit-scrollbar': {
    backgroundColor: '#efeff2',
    width: 1,
    borderRadius: 1
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d3dbe5',
    width: 1,
    borderRadius: 1
  }
};

export default theme => ({
  root: {
    marginBottom: theme.spacing(9),
    position: 'relative',
    background: '#fff'
  },
  headerBar: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    background: '#fff'
    // left: 0,
    // right: 0
  },
  categorySectionsContainer: {
    ...customScrollBar,
    maxHeight: 'calc(100vh - 232px)'
  },
  filterContainer: {
    padding: '4px 0px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  categoryContainer: {
    margin: '20px 0',
    position: 'relative',
    '& > div.category-scroll-padding': {
      position: 'absolute',
      top: 0
    }
  },
  nextBtnContainer: {
    display: 'none',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 75,

    [theme.breakpoints.down('xs')]: {
      display: 'flex'
    }
  },
  nextBtn: {
    margin: 'auto',
    maxWidth: 280
  },
  topCatalogHeader: {
    fontFamily: 'Gilroy',
    fontSize: 36,
    fontWeight: 600,
    color: '#131415',
    marginBottom: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 34
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 27,
      marginBottom: 10
    }
  },
  subHeader: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    lineHeight: 1.43,
    fontWeight: 400,
    color: '#4A4F54',
    [theme.breakpoints.down(1522)]: {
      fontSize: 12
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  }
});
