import * as React from 'react';
import { Button } from '@swagup-com/components';
import { Divider, Grid, Box, makeStyles, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles({
  bottomMenu: {
    position: 'relative',
    height: 0,
    margin: '0px 80px',
    width: 'auto',
    transition: 'height 250ms ease-out',
    '&.open': {
      height: 86,
      transition: 'height 250ms ease-out'
    }
  },
  bottomMenuInner: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '19px 32px 24px',
    boxShadow: '0 0 84px 0 rgba(0, 0, 0, 0.05)',
    top: 86,
    bottom: 0,
    left: 0,
    right: 0,
    transition: 'top 250ms ease-out',
    '.open &': { top: 0 }
  },
  closeButton: {
    zIndex: 1,
    position: 'absolute',
    top: 10,
    right: -10,
    border: 'solid 1px #e8e9eb',
    background: '#ffffff',
    boxShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.03)',
    padding: 4,
    '.open &': { top: -10 },
    '& .MuiSvgIcon-root': { fontSize: 11 }
  },
  title: {
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#787b80',
    marginBottom: 10
  },
  count: {
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 'normal',
    color: '#0b1829'
  },
  button: {
    height: 'unset',
    padding: 0,
    letterSpacing: 'normal',
    '&.remove': {
      color: '#f44336'
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      marginLeft: 8
    }
  },
  divider: {
    height: 36,
    backgroundColor: '#e8e9eb',
    margin: '0 30px'
  }
});

const BottomMenu = ({ selectedCount, onChangeShippingMethod, onRemove, onClose }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="space-between"
      className={clsx(classes.bottomMenu, { open: Boolean(selectedCount) })}
    >
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Box className={classes.bottomMenuInner}>
        <Box>
          <p className={classes.title}>Selected recipients</p>
          <p className={classes.count}>{selectedCount}</p>
        </Box>
        <Box display="flex" alignItems="center">
          <Divider orientation="vertical" className={classes.divider} />
          <Box mr="30px">
            <p className={classes.title}>Shipping method</p>
            <Button variant="text" className={classes.button} onClick={onChangeShippingMethod}>
              Select Shipping Method <ExpandMoreIcon />
            </Button>
          </Box>
          <Divider orientation="vertical" className={classes.divider} />
          <Box>
            <p className={classes.title}>Remove from list</p>
            <Button variant="text" className={clsx(classes.button, 'remove')} onClick={onRemove}>
              Remove Selected <RemoveIcon />
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default BottomMenu;
