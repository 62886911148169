import React from 'react';
import { Box, Typography, Grid, Divider, makeStyles, withStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Modal } from '../../../shared/Modal';
import { InfoContainer } from './common';
import { moneyStr } from '../../../../helpers/utils';
import styles from './styles/refundCancelModal';

const useStyles = makeStyles(styles);

export const RefundSection = withStyles(styles)(({ classes, imageSrc, text, amount }) => (
  <Grid item container direction="row" wrap="nowrap" style={{ height: 80 }}>
    <Grid item className={classes.refundSectionIcon}>
      <img src={imageSrc} alt="icon" />
    </Grid>
    <Grid container direction="column" justifyContent="center">
      <Typography variant="subtitle1">{text}</Typography>
      <Typography variant="h5">{amount}</Typography>
    </Grid>
  </Grid>
));

const RefundCancelModal = ({ open, refundedCredit, refundedPackages, onCancel, onClose }) => {
  const classes = useStyles();

  return (
    <Modal
      title="Are you sure you want to cancel this shipment?"
      open={open}
      onClose={onClose}
      classes={{ title: classes.title }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1" align="center" style={{ lineHeight: '22px' }}>
            Canceling the shipment will return items to inventory <br /> and credit to your SwagUp account.
          </Typography>
        </Grid>
        <Box height="330px" width="100%" marginTop="30px" padding="0 30px">
          <InfoContainer>
            <RefundSection
              text="Refunded as Credit Balance"
              amount={moneyStr(refundedCredit)}
              imageSrc="/images/shipment/wallet.png"
            />
            <Divider style={{ margin: '10px 0' }} />
            <RefundSection
              text="Items to return to Inventory "
              amount={refundedPackages}
              imageSrc="/images/shipment/package.png"
            />
          </InfoContainer>
          <Box mt="50px">
            <Grid container item justifyContent="flex-end">
              <Button variant="text" onClick={onClose} className={classes.goBackButton}>
                Go Back
              </Button>
              <Button variant="text" onClick={onCancel} className={classes.cancelButton}>
                Cancel Shipment
              </Button>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Modal>
  );
};

export default RefundCancelModal;
