import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentContactGroup } from '../../../../../actions/shipmentGroupActions';
import { contactsApi } from '../../../../../apis/swagup';
import apiPaths from '../../../../../helpers/apiPaths';
import { commonSearchFields } from '../../../../../helpers/utils';

const limit = 1000;

const useFetchContactsByQuery = onFinish => {
  const [contacts, setContacts] = React.useState([]);
  const [offset, setOffset] = React.useState(0);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { isSelectingFromExisting, fetchQuery } = useSelector(state => state.shipmentGroup);

  const handleStatus = React.useCallback(
    (results, total) => {
      const nonExcluded = results.filter(c => !fetchQuery.excluded?.has(c.id));

      setContacts(c => [...c, ...nonExcluded]);

      if (offset + limit < total) {
        setOffset(offset + limit);
      } else {
        setOffset(0);
        dispatch(setCurrentContactGroup());
        if (onFinish) onFinish([...contacts, ...nonExcluded]);
        if (isSelectingFromExisting) queryClient.invalidateQueries(apiPaths.contacts);
        setContacts([]);
      }
    },
    [contacts, dispatch, fetchQuery, isSelectingFromExisting, offset, onFinish, queryClient]
  );

  const contactsPayload = {
    search: fetchQuery?.search,
    groupIds: fetchQuery?.groupIds,
    ordering: 'created_at',
    search_fields: commonSearchFields.join(),
    sizes: fetchQuery?.sizes,
    shippingCountry: fetchQuery?.shippingCountry,
    limit,
    offset
  };
  const { isFetching } = useQuery([apiPaths.contacts, contactsPayload], () => contactsApi.fetch(contactsPayload), {
    enabled: !!fetchQuery,
    onSuccess: ({ results, count }) => handleStatus(results, count)
  });

  return { isLoading: isFetching };
};

export default useFetchContactsByQuery;
