import * as React from 'react';
import { Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Close, Warning } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getEmployeeShipAddress, joinFields } from '../../../../../helpers/utils';
import { ImgWithHandler } from '../../../../global/ImgUtils';
import { TableCell, TableRow, FullnameWithHighlights, CheckBox } from './TableCommon';
import useProductsByRecipients from '../hooks/useProductsByRecipients';
import { shipmentGroupsErrorTypes } from '../../../../../helpers/helperConstants';

const ProofsInTooltip = ({ classes, proofs }) => (
  <Grid container>
    {proofs.map(p => (
      <div key={p.proof} style={{ margin: '0 8px' }}>
        <ImgWithHandler src={p.image} width={78} height={78} className={classes.image} />
        <p className={classes.imageQty}>{p.quantity}</p>
      </div>
    ))}
  </Grid>
);

const tooltip = {
  root: {
    boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    backgroundImage: 'none',
    color: '#0b1829',
    borderRadius: 10
  },
  text: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 'normal',
    textAlign: 'center'
  }
};

const useQuantityCellStyles = makeStyles({
  tooltip: {
    ...tooltip.root,
    padding: '22px 14px'
  },
  image: {
    width: 48,
    height: 48,
    background: '#ffffff',
    borderRadius: 10,
    border: 'solid 1px #e8e9eb'
  },
  imageQty: {
    ...tooltip.text,
    lineHeight: 'normal',
    marginTop: 10
  }
});

const QuantityCell = ({ recipientData = {} }) => {
  const classes = useQuantityCellStyles();
  const { total, proofs } = recipientData;
  return (
    <Tooltip
      title={proofs ? <ProofsInTooltip classes={classes} proofs={proofs} /> : ''}
      placement="top"
      classes={{ tooltip: classes.tooltip }}
      arrow
    >
      <TableCell
        width={89}
        style={{ maxWidth: 89 }}
        aria-owns={total > 0 ? 'products-info-popover' : undefined}
        aria-haspopup={total > 0}
      >
        {total || <span style={{ color: '#ced1d6' }}>0</span>}
      </TableCell>
    </Tooltip>
  );
};

const useWarningCellStyles = makeStyles({
  tooltip: {
    ...tooltip.root,
    ...tooltip.text,
    padding: '11px 14px 16px',
    lineHeight: 1.5,
    maxWidth: 228
  },
  cell: {
    maxWidth: 36,
    borderRight: 'none',
    '& .divCell': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center'
    },
    '& svg': {
      fontSize: 16,
      color: ({ type }) => (type === 'error' ? '#f44336' : '#fe8e26')
    }
  }
});

const WarningCell = ({ type, text }) => {
  const classes = useWarningCellStyles({ type });
  const [open, setOpen] = React.useState(false);

  return (
    <Tooltip open={open} title={text || ''} placement="top" classes={{ tooltip: classes.tooltip }} arrow>
      <TableCell
        width={36}
        className={classes.cell}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {text && <Warning style={{ marginTop: 4 }} />}
      </TableCell>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  nameCell: {
    fontFamily: 'Gilroy-Medium',
    '& .divCell': { padding: '0 20px 0 0' }
  },
  actionCell: {
    '& .divCell': {
      padding: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 10
    }
  },
  editShipmentButton: {
    height: 16,
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    fontWeight: 500,
    padding: 0,
    marginRight: 10,
    color: '#3577D4',
    'tr:hover &': {
      transition: 'color .25s',
      '&:hover': {
        color: 'rgb(37, 83, 148)',
        textDecoration: 'underline'
      }
    }
  },
  recipientMenuBtn: {
    width: 16,
    height: 16,
    color: '#787b80',
    transition: 'color .25s',
    'tr:hover &': {
      color: '#f44336',
      transition: 'color .25s'
    }
  }
});

const RecipientRow = ({
  recipient,
  isSelected,
  isMenuOpen,
  highlightText,
  onSelect,
  onEdit,
  onRemove,
  hideInactiveWarnings
}) => {
  const classes = useStyles();
  const errors = useSelector(state => state.shipmentGroup.errors);
  const productsByRecipients = useProductsByRecipients();
  const deliveryMethod = productsByRecipients[recipient.id]?.deliveryMethod;
  const error = errors[recipient.id];

  return (
    <TableRow
      hover
      key={recipient.id ?? recipient}
      role="checkbox"
      onClick={onSelect}
      aria-checked={isSelected}
      selected={isSelected}
      className={isMenuOpen === recipient.id ? 'hovered' : undefined}
    >
      <TableCell padding="checkbox">
        <CheckBox color="primary" disabled={recipient.id === undefined} checked={isSelected} />
      </TableCell>
      <WarningCell
        text={
          error?.hidden || (error?.type === shipmentGroupsErrorTypes.inactiveSizeWarning && hideInactiveWarnings)
            ? ''
            : error?.text
        }
        type={error?.type}
        onEdit={onEdit}
        onRemove={onRemove}
      />
      <TableCell scope="recipient" width={222} style={{ maxWidth: 222 }} className={classes.nameCell}>
        <FullnameWithHighlights
          name={joinFields([recipient.first_name, recipient.last_name], ' ')}
          highlight={highlightText}
        />
      </TableCell>
      <TableCell width={360} style={{ maxWidth: 360 }}>
        {getEmployeeShipAddress(recipient, false)}
      </TableCell>
      <QuantityCell key={recipient.id} recipientData={productsByRecipients[recipient.id]} />
      <TableCell width={117} style={{ maxWidth: 117, borderRight: 'none' }}>
        {deliveryMethod || <span style={{ color: '#ced1d6' }}>N/A</span>}
      </TableCell>
      <TableCell width={146} style={{ maxWidth: 146 }} className={classes.actionCell}>
        <Button variant="text" className={classes.editShipmentButton} onClick={onEdit}>
          Edit Shipment
        </Button>
        <IconButton disableRipple disableFocusRipple onClick={onRemove} style={{ padding: 8 }}>
          <Close className={classes.recipientMenuBtn} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default RecipientRow;
