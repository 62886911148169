import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ArrowBack, CheckCircle } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '@swagup-com/components';
import dayjs from 'dayjs';
import { round } from 'lodash';
import { useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/membership';
import { Header, MembershipItem } from './MembershipCommon';
import { useMembership } from '../../contexts/membershipContext';
import { useQueryParams } from '../../hooks';
import apiPaths from '../../helpers/apiPaths';
import { globalApi } from '../../apis/swagup';

const useStyles = makeStyles(styles);

const MembershipConfirmation = () => {
  const [loadCompany, setLoadCompany] = useState(false);
  const { currentMembership, setCurrentMembership, currentEmail, company, memberships } = useMembership();

  const { data: lateCompany } = useQuery(apiPaths.accounts, () => globalApi.fetchAccount(), {
    enabled: loadCompany
  });

  const companyValue = lateCompany || company;

  const query = useQueryParams();
  const membership = parseInt(query.get('membership') || '0', 10);
  const proratedAmount = round(parseFloat(query.get('prorated_amount') || '0', 2));

  useEffect(() => {
    if (company) {
      setTimeout(() => setLoadCompany(true), 2000);
    }
  }, [company]);

  useEffect(() => {
    if (membership && proratedAmount > 0) {
      const choosenMembership = memberships.find(m => m.id === membership);
      setCurrentMembership(choosenMembership);
    }
  }, [membership, setCurrentMembership, proratedAmount, memberships]);

  const isUpgrade = proratedAmount > 0;

  const { leftBarNavigation } = useFlags();
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box className={classes.membershipStep}>
      <Header title="Manage Membership" actionText="Your Membership" />
      <Grid container spacing={3} alignItems="stretch" className={classes.membershipManagementConfirmationPanel}>
        <Grid container alignItems="baseline">
          <Grid item>
            <Link to={leftBarNavigation ? '/settings/your-membership' : '/membership/your-membership'}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box style={{ maxHeight: 24, width: 32 }}>
                    <ArrowBack className={classes.backArrow} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography className={classes.backText}>Back</Typography>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid item xs>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <CheckCircle style={{ color: '#2BB572' }} />
              </Grid>
              <Grid item>
                <Typography className={classes.cancelMembershipTitle} style={{ textAlign: 'center', marginBottom: 32 }}>
                  {isUpgrade
                    ? `You are now subscribed to the ${currentMembership?.name} plan`
                    : `Your new ${currentMembership?.name} membership will begin ${dayjs(
                        companyValue.membership_expiration_date
                      ).format('MM/DD/YYYY')}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container justifyContent="center" spacing={10}>
          <Grid item xs={1} />
          <Grid item>
            <MembershipItem
              item={currentMembership}
              customButton={
                <Button
                  variant="primary"
                  size="small"
                  className={classes.manageMembership}
                  onClick={() => {
                    history.push('/membership/manage-membership-switch');
                  }}
                >
                  Switch Membership
                </Button>
              }
              innerHeight={242}
              candidate
              classes={classes}
            />
          </Grid>
          <Grid item>
            <Box className={classes.confirmationBox}>
              <Grid container direction="column" style={{ height: '100%' }}>
                <Grid item>
                  <Typography className={classes.confirmationBoxTitle}>Order summary</Typography>
                  <Typography className={classes.confirmationBoxText}>
                    We’ve sent a confirmation email to {currentEmail}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography
                    className={classes.confirmationBoxTitle}
                    style={{ fontSize: 14, fontWeight: 500, marginTop: 16 }}
                  >
                    Order #
                    {`${companyValue.id}${companyValue.billing_zip || companyValue.shipping_zip || companyValue.id}`}
                  </Typography>
                  <Box className={classes.orderBox}>
                    <Typography className={classes.confirmationBoxText} style={{ fontSize: 14, marginBottom: 8 }}>
                      {currentMembership?.name}
                    </Typography>
                    <Typography className={classes.confirmationBoxText}>{currentMembership?.priceText}</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box className={classes.orderDisclaimerBox}>
                    <Typography className={classes.orderDisclaimerText}>
                      Membership Expiration Date: {dayjs(companyValue.membership_expiration_date).format('MM/DD/YYYY')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MembershipConfirmation;
