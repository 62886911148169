import React, { useState } from 'react';
import dayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { TextField } from '@swagup-com/components';
import dayjs from 'dayjs';
import log from '../../logger';

const CustomTextField = props => <TextField {...props} variant="outlined" />;

const useStyle = makeStyles({
  helperText: {
    fontSize: 11
  }
});

const StandardKeyboardDatePicker = ({ format, minDate, emptyLabel, onChange, classes, ...props }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date, value) => {
    const d = dayjs(value, format, true);
    log.debug('StandardKeyboardDatePicker, date:', date, 'parsed:', d, 'value:', value);
    setSelectedDate(d);
    onChange(d);
  };

  const y = minDate
    .getFullYear()
    .toString()
    .substring(2);
  const m = minDate.getMonth();
  const minDateMessage = `Expiration date should be after ${m < 10 ? `0${m}` : m}/${y}`;

  log.debug('minDate:', minDate, 'minDateMessage:', minDateMessage);

  const textClasses = useStyle();

  return (
    <MuiPickersUtilsProvider utils={dayjsUtils}>
      <KeyboardDatePicker
        format={format}
        value={selectedDate}
        minDate={minDate}
        placeholder={emptyLabel}
        onChange={handleDateChange}
        className={clsx(Object.values(classes))}
        disableToolbar
        minDateMessage={minDateMessage}
        FormHelperTextProps={{
          className: textClasses.helperText
        }}
        TextFieldComponent={CustomTextField}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default StandardKeyboardDatePicker;
