import { getFilters } from '../helpers/utils';

const options = [
  { value: 'product', text: 'Products', type: 'productType' },
  { value: 'pack', text: 'Packs', type: 'productType' },
  { value: 'available', text: 'In Stock', type: 'availability' },
  { value: 'out_of_stock', text: 'Out of Stock', type: 'availability' }
];

const useInventoryMultifilters = query => {
  const recordTypeParams = query.get('product_type')?.split(',') ?? [];
  const recordType = recordTypeParams.length === 1 ? recordTypeParams[0] : 'all';
  const inventoryParams = query.get('inventory')?.split(',') ?? [];
  const inventory = inventoryParams.length === 1 ? inventoryParams[0] : 'all';

  const handleProductTypeChange = e => {
    const filters = e.target.value.split(',');

    query.delete('product_type');
    const typeFilters = getFilters({ options, selectedOptions: filters, type: 'productType' });
    if (typeFilters.length > 0) {
      query.set('product_type', typeFilters.join());
    }

    query.delete('inventory');
    const availabilityFilters = getFilters({ options, selectedOptions: filters, type: 'availability' });
    if (availabilityFilters.length > 0) {
      query.set('inventory', availabilityFilters.join());
    }
  };

  const selectedValues = getFilters({
    options,
    selectedOptions: [...recordTypeParams, ...inventoryParams],
    type: 'all',
    compareBy: 'value',
    returnField: 'text'
  }).join();

  return {
    options,
    handleProductTypeChange,
    selectedValues,
    recordTypeParams,
    recordType,
    inventoryParams,
    inventory
  };
};

export default useInventoryMultifilters;
