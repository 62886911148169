/* eslint-disable camelcase */
import * as React from 'react';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { CircularProgress, Divider, Grid, makeStyles, Slide, TextField, FormControl, Box } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Button, Typography } from '@swagup-com/components';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { CloudUpload, PostAdd, RemoveCircleOutline } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/editRecipientRow';
import EditContact from '../EditContact';
import QuantitiesSelection from './QuantitiesSelection';
import { useInSendSwagPath } from '../../hooks';
import {
  updateShipmentGroupDirectoryOrder,
  updateShipmentGroupDirectoryOrderLoadingStatus,
  updateShipmentGroupRecipient,
  updatepRecipientCollapsed,
  validateDirectoryOrders
} from '../../../../../../actions/shipmentGroupActions';
import {
  getUsedQuantitiesBySize,
  getEmployeeShipAddress,
  getFormattedPhoneNumber,
  joinFields,
  onlyNaturalNumberOrEmpty,
  sumByProperty,
  sumByQuantity
} from '../../../../../../helpers/utils';
import {
  addressFields,
  getQuantitiesBreakdown,
  substractQuantitiesBreakdowns,
  doesInactiveSizeHasQuantity,
  mapSizesWithActiveField
} from '../../common/utilsOrder';
import apiPaths from '../../../../../../helpers/apiPaths';
import CalendarModalTool from '../../../../../global/CalendarModalTool';
import { minimumShippingDate } from '../../../../../../helpers/commonDateFunctions';
import { useShippingCutoffHour, useSizes } from '../../../../../../hooks';
import InactiveSizesAlert from '../../common/InactiveSizesAlert';
import { useOrder } from '../../OrderContext';
import { useMembership } from '../../../../../../contexts/membershipContext';
import { CheckBox } from '../TableCommon';
import ShippingMethodButton from './ShippingMethodButton';
import { FileUploadZone, RemoveDialog, SmallSwitch, ToogleButtom, WarningIcon } from './common';
import { shipmentGroupsErrorTypes } from '../../../../../../helpers/helperConstants';
import useShippingPricesQueryCurated from '../../hooks/useShippingPricesQueryCurated';
import { prepareArtworksOnS3 } from '../../../../../redeemPages/redeemCommon';
import { StylessButton } from '../../../../../buttons';

const maxNoteLength = 500;

const useStyles = makeStyles(styles);

const getTotalQuantity = selectedQuantities =>
  sumBy(Array.from(selectedQuantities), ([, sizes]) => sumByQuantity(sizes));
const buildSelectedQuantities = (shipmentGroup, directoryOrder) =>
  shipmentGroup?.products.map(p => {
    const proof = directoryOrder?.proofs.find(pr => pr.proof === p.id);
    return proof ? [proof.proof, proof.sizes] : [p.id, []];
  });

const getSaveBtnTooltipText = (isLoading, totalQuantity, exceededStock, currentMethod, shippingMethods) => {
  if (!isLoading) {
    if (totalQuantity === 0) return 'Quantity selected must be greater than zero';
    if (!shippingMethods.find(Boolean)) return 'No shipping method available';
    if (exceededStock) return "One or more products don't have enough inventory to cover this recipient";
    if (currentMethod === -1 || !shippingMethods.find(method => method?.id === currentMethod))
      return 'A shipping method must be selected';
  }
  return '';
};

const RemoveShipmentSection = ({ onRemove, className, text = 'Remove Shipment', style, hidden }) => {
  const classes = useStyles();
  return (
    <Box className={className} style={{ width: '100%', position: 'relative', display: hidden ? 'none' : undefined }}>
      <Grid container justifyContent="flex-end" style={style || { position: 'absolute', width: '100%', bottom: -28 }}>
        <Grid item>
          <StylessButton onClick={onRemove} className={classes.removeShipmentButton}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="body4RegularInter" className={classes.remove}>
                  {text}
                </Typography>
              </Grid>
              <Grid item>
                <RemoveCircleOutline style={{ width: 16, height: 16, marginTop: 3 }} />
              </Grid>
            </Grid>
          </StylessButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const RecipientForm = ({
  recipientId,
  limitedStock,
  isSelected,
  onEditContact,
  onSelect,
  onEdit,
  onRemove,
  hideInactiveWarnings
}) => {
  const [uploading, setUploading] = useState(false);
  const storageShipmentGroup = useSelector(state => state.shipmentGroup);
  const shipmentGroup = React.useMemo(() => storageShipmentGroup, [storageShipmentGroup]);
  const recipient = shipmentGroup.recipients.find(r => r.id === recipientId);
  const directoryOrder = React.useMemo(
    () => shipmentGroup.directoryOrders.find(dOrder => dOrder.directory === recipient.id),
    [recipient.id, shipmentGroup.directoryOrders]
  );
  const [selectedQuantities, setSelectedQuantities] = React.useState(
    new Map(buildSelectedQuantities(shipmentGroup, directoryOrder))
  );

  const [collapsed, setCollapsed] = useState(recipient?.collapsed);
  const [currentMethod, setCurrentMethod] = React.useState(
    directoryOrder?.deliveryMethods?.find(dm => dm.selected)?.id ?? -1
  );
  const [shippingNotes, setShippingNotes] = React.useState(directoryOrder?.shippingNotes ?? '');

  const [selectedProductId, setSelectedProductId] = React.useState();
  const [openRemoveProductDialog, setOpenProductRemoveDialog] = React.useState(false);

  const isLimitedStock = useInSendSwagPath();
  const shippingCutoffHour = useShippingCutoffHour();
  const startingDate = isLimitedStock
    ? directoryOrder?.shippingDate ?? minimumShippingDate(new Date(), shippingCutoffHour)
    : undefined;
  const [shippingDate, setShippingDate] = React.useState(startingDate);
  const [documentation, setDocumentation] = useState(
    isEmpty(directoryOrder?.documentation) ? undefined : directoryOrder?.documentation
  );
  const [showDocumentation, setShowDocumentation] = useState(
    !isEmpty(directoryOrder?.documentation) || !isEmpty(directoryOrder?.shippingNotes)
  );

  const [deletedProducts, setDeletedProducts] = React.useState(directoryOrder?.deletedProducts ?? []);

  const { shipmentGroupId } = useParams();
  const areQuantitiesPristine = React.useRef(true);

  const showingProducts = React.useMemo(() => shipmentGroup.products.filter(p => !deletedProducts.includes(p.id)), [
    deletedProducts,
    shipmentGroup.products
  ]);

  const qtyLeftPerProduct = React.useMemo(
    () =>
      limitedStock &&
      showingProducts.map(product => {
        const otherShipments = shipmentGroup.directoryOrders.filter(dOrder => dOrder.directory !== recipient.id);
        return substractQuantitiesBreakdowns(
          getQuantitiesBreakdown(product.sizes),
          getUsedQuantitiesBySize(product.id, [{ directoryOrders: otherShipments }])
        );
      }),
    [limitedStock, showingProducts, shipmentGroup.directoryOrders, recipient.id]
  );
  const { shipmentGroups } = useOrder() || {};

  const permanentQtyLeftPerProduct = React.useMemo(
    () =>
      showingProducts.map(product => {
        const otherShipments = shipmentGroup.directoryOrders.filter(dOrder => dOrder.directory !== recipient.id);
        return substractQuantitiesBreakdowns(
          getQuantitiesBreakdown(product.sizes),
          getUsedQuantitiesBySize(product.id, limitedStock ? [{ directoryOrders: otherShipments }] : shipmentGroups)
        );
      }),
    [limitedStock, shipmentGroup, shipmentGroups, recipient]
  );

  const dispatch = useDispatch();

  const updateDirectoryOrderDeliveryMethods = dMs => {
    if (!isEmpty(directoryOrder)) {
      dispatch(updateShipmentGroupDirectoryOrder({ ...directoryOrder, deliveryMethods: dMs }, false));
      dispatch(validateDirectoryOrders([], isLimitedStock, false));
    }
  };

  const canUpdateOrderDeliveryMethods = useCallback(() => directoryOrder?.canUpdateDM, [directoryOrder]);

  const [standardMethod, expressMethod, isLoading, status] = useShippingPricesQueryCurated(
    showingProducts,
    recipient,
    [selectedQuantities],
    directoryOrder,
    updateDirectoryOrderDeliveryMethods,
    canUpdateOrderDeliveryMethods
  );

  useEffect(() => {
    if (canUpdateOrderDeliveryMethods()) dispatch(updateShipmentGroupDirectoryOrderLoadingStatus(isLoading));
  }, [canUpdateOrderDeliveryMethods, dispatch, isLoading]);

  useEffect(() => {
    const DMid = directoryOrder?.deliveryMethods?.find(dm => dm.selected)?.id;
    if (DMid) {
      setCurrentMethod(DMid);
    }
  }, [directoryOrder]);

  const handleChangeValues = React.useCallback(
    propsValues => {
      const {
        customQty,
        customMethod,
        customNotes,
        customDate,
        customDocumentation,
        customDeletedProducts,
        customCollapsed,
        canUpdateDM
      } = propsValues;
      const finalQty = customQty || selectedQuantities;
      const finalMethod = customMethod || currentMethod;
      const finalNotes = customNotes || shippingNotes;
      const finalDate = customDate || shippingDate;
      const finalDocumentation = customDocumentation || documentation;
      const finalDeletedProducts = customDeletedProducts || deletedProducts;
      const finalCollapsed = customCollapsed ?? collapsed;

      const proofs = Array.from(finalQty)
        .map(([proof, sizes]) => ({
          proof,
          sizes: sizes.filter(s => s.quantity > 0)
        }))
        .filter(proof => !isEmpty(proof.sizes));
      const deliveryMethods =
        finalMethod && (standardMethod || expressMethod)
          ? [
              { ...standardMethod, selected: standardMethod?.id === finalMethod },
              { ...expressMethod, selected: expressMethod?.id === finalMethod }
            ]
          : undefined;
      const newDirectoryOrder = {
        directory: recipient.id,
        proofs,
        deliveryMethods,
        shippingNotes: finalNotes,
        shippingDate: finalDate,
        documentation: finalDocumentation,
        deletedProducts: finalDeletedProducts,
        collapsed: finalCollapsed,
        canUpdateDM: !!canUpdateDM
      };

      dispatch(updateShipmentGroupDirectoryOrder(newDirectoryOrder, false));
      dispatch(validateDirectoryOrders([], isLimitedStock, false));
    },
    [
      dispatch,
      expressMethod,
      isLimitedStock,
      recipient.id,
      selectedQuantities,
      shippingDate,
      shippingNotes,
      standardMethod,
      deletedProducts,
      collapsed
    ]
  );

  const handleChangeQuantity = productId => sizeId => e => {
    const value = onlyNaturalNumberOrEmpty(String(e.target.value));
    const currentQuantities = selectedQuantities.get(productId);
    const newQuantity = value ? Number(value) : '';

    // const qtyLeft = limitedStock ? qtyLeftPerProduct[index] : undefined;
    // const quantities = selectedQuantities.get(productId);
    // const totalQtyLeft = sum(Object.values(qtyLeft || {})) - sumByQuantity(quantities);

    selectedQuantities.set(
      productId,
      currentQuantities.find(q => q.size === sizeId)
        ? currentQuantities.map(s => (s.size === sizeId ? { ...s, quantity: newQuantity } : s))
        : [...currentQuantities, { size: sizeId, quantity: newQuantity }]
    );
    setSelectedQuantities(selectedQuantities);
    areQuantitiesPristine.current = false;
    handleChangeValues({ customQty: selectedQuantities, canUpdateDM: true });
  };

  const handleResetQuantity = productId => () => {
    const currentQuantities = selectedQuantities.get(productId);
    if (!isEmpty(currentQuantities)) {
      selectedQuantities.set(productId, []);
      setSelectedQuantities(selectedQuantities);
      handleChangeValues({ customQty: selectedQuantities, canUpdateDM: true });
    }
  };

  const handleChangeShippingDate = date => {
    setShippingDate(date);
    handleChangeValues({ customDate: date });
  };

  const handleResetAllQuantities = () => {
    Array.from(selectedQuantities).forEach(([key]) => selectedQuantities.set(key, []));
    setSelectedQuantities(selectedQuantities);
  };

  const handleNoteChange = debounce(value => {
    setShippingNotes(value);
    handleChangeValues({ customNotes: value });
  }, 50);

  const handleDeliveryMethodChange = dmId => {
    setCurrentMethod(dmId);
    handleChangeValues({ customMethod: dmId });
  };

  const handleDocumentation = doc => {
    setDocumentation(doc);
    handleChangeValues({ customDocumentation: doc });
  };

  const handleToogleOpen = () => {
    setCollapsed(!collapsed);
    dispatch(updatepRecipientCollapsed(!collapsed));
  };

  useEffect(() => {
    if (directoryOrder?.shippingDate && directoryOrder?.shippingDate !== shippingDate)
      setShippingDate(directoryOrder?.shippingDate);
  }, [directoryOrder?.shippingDate]);

  // useEffect(() => {
  //   handleChangeValues({
  //     customMethod: currentMethod,
  //     customNotes: shippingNotes,
  //     customDate: shippingDate
  //   });
  // }, [currentMethod, shippingDate, shippingNotes]);

  const errors = useSelector(state => state.shipmentGroup.errors);
  // const productsByRecipients = useProductsByRecipients();
  // const deliveryMethod = productsByRecipients[recipient.id]?.deliveryMethod;
  const error = errors[recipient.id];

  React.useEffect(() => {
    if (directoryOrder?.documentation && !showDocumentation) {
      handleDocumentation();
    }
  }, [showDocumentation]);

  React.useEffect(() => {
    setCollapsed(recipient?.collapsed);
  }, [recipient?.collapsed]);

  // React.useEffect(() => {
  //   if (areQuantitiesPristine.current) {
  //     setSelectedQuantities(new Map(buildSelectedQuantities(shipmentGroup, directoryOrder)));
  //   }
  //   if (currentMethod === -1) {
  //     const currentDM = directoryOrder?.deliveryMethods?.find(dm => dm.selected)?.id ?? -1;
  //     handleDeliveryMethodChange(currentDM);
  //   }
  // }, [shipmentGroup, directoryOrder, currentMethod]);

  const totalQuantity = getTotalQuantity(selectedQuantities);
  const exceededStock =
    limitedStock &&
    showingProducts.some((product, index) => {
      const qtyLeft = qtyLeftPerProduct[index];
      return selectedQuantities.get(product.id).some(({ size, quantity }) => qtyLeft[size] < quantity);
    });
  const saveBtnTooltipMessage = getSaveBtnTooltipText(isLoading, totalQuantity, exceededStock, currentMethod, [
    standardMethod,
    expressMethod
  ]);
  const disabled = saveBtnTooltipMessage !== '' || isLoading;
  const classes = useStyles();

  const shipmentGroupHasInactiveSizes = showingProducts.some(p =>
    selectedQuantities.get(p.id).some(doesInactiveSizeHasQuantity(p.sizes))
  );

  const { data: apiSizes } = useSizes();

  const {
    currentMembership: { shipping_discount, isFreeTier }
  } = useMembership();

  const isEditingShipmentGroup = `${shipmentGroupId}` === `${shipmentGroup?.id}`;

  const addRemainingItems = () => {
    const isOneSize = sizes => sizes.length === 1 && sizes[0].size.name === 'One Size';
    showingProducts.forEach((p, index) => {
      const onChangeQty = handleChangeQuantity(p.id);
      const qtyLeft = permanentQtyLeftPerProduct[index];
      const quantities = selectedQuantities.get(p.id);
      if (isOneSize(p.sizes)) {
        const currentQuantity = isEditingShipmentGroup ? quantities[0]?.quantity : 0;
        const fullQuantity = currentQuantity + qtyLeft[p.sizes[0].size.id];
        onChangeQty(p.sizes[0].size.id)({ target: { value: fullQuantity } });
      } else {
        const sizes = mapSizesWithActiveField(apiSizes, p);
        sizes.forEach(size => {
          const currentQuantity = isEditingShipmentGroup ? quantities.find(s => s.size === size.id)?.quantity || 0 : 0;
          const fullQuantity = currentQuantity + qtyLeft[size.id];
          onChangeQty(size.id)({ target: { value: fullQuantity } });
        });
      }
    });
  };

  const handleFileUpload = async acceptedFiles => {
    const file = acceptedFiles[0];

    if (file) {
      setUploading(true);
      const uploaded = await prepareArtworksOnS3(file);
      handleDocumentation(uploaded);
      setUploading(false);
    }
  };

  // const hasDocumentation = !isEmpty(documentation) || shippingNotes;

  const handleRemoveAProduct = pId => {
    const dProducts = [...deletedProducts, pId];
    setDeletedProducts(dProducts);
    handleChangeValues({ customDeletedProducts: dProducts, canUpdateDM: true });
  };

  const handleRemoveProduct = pId => {
    setSelectedProductId(pId);
    setOpenProductRemoveDialog(true);
  };
  const onRemoveProductClose = () => setOpenProductRemoveDialog(false);

  const { includeShipmentDocumentation } = useFlags();

  return (
    <Box className={classes.recipientRow}>
      <Grid container spacing={2}>
        <Grid item>
          <CheckBox
            color="primary"
            disabled={recipient.id === undefined}
            checked={isSelected}
            onChange={({ target: { checked } }) => onSelect(checked)}
          />
          <WarningIcon
            text={
              error?.hidden || (error?.type === shipmentGroupsErrorTypes.inactiveSizeWarning && hideInactiveWarnings)
                ? ''
                : error?.text
            }
            type={error?.type}
            marginTop={12}
            onEdit={onEdit}
            onRemove={onRemove}
          />
        </Grid>
        <Grid item xs>
          <Grid container>
            <Grid item xs={6}>
              <p className={classes.title}>Shipment Details</p>
              <Grid container>
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Typography variant="body2SemiBoldInter" style={{ color: '#4A505C' }}>
                        {joinFields([recipient.first_name, recipient.last_name], ' ')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button variant="text" className={classes.button} onClick={onEditContact}>
                        <EditOutlinedIcon className={classes.editIcon} />
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography variant="body3RegularInter" style={{ marginTop: 4 }}>
                    {getEmployeeShipAddress(recipient)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 22 }}>
              <p className={classes.title}>Shipping Method</p>
              <Grid container justifyContent="space-between" style={{ marginTop: 12 }}>
                {isLoading ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <CircularProgress style={{ width: 42, height: 42 }} />
                  </Grid>
                ) : (
                  <Grid container spacing={4} justifyContent="flex-start">
                    <Grid item xs={6}>
                      <ShippingMethodButton
                        active={currentMethod === standardMethod?.id}
                        disabled={standardMethod === undefined}
                        showMessage={totalQuantity > 0 && status === 'success'}
                        price={sumByProperty(standardMethod?.prices.breakdown ?? [], 'total_price')}
                        originalPrice={
                          isFreeTier
                            ? 0
                            : sumByProperty(standardMethod?.prices.breakdown ?? [], 'total_price_without_discount')
                        }
                        discount={shipping_discount}
                        time={standardMethod?.turnaround_time}
                        name="Standard"
                        onClick={standardMethod ? () => handleDeliveryMethodChange(standardMethod?.id) : undefined}
                        style={{ minHeight: '90px', overflowY: 'auto' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ShippingMethodButton
                        active={currentMethod === expressMethod?.id}
                        disabled={expressMethod === undefined}
                        showMessage={totalQuantity > 0 && status === 'success'}
                        price={sumByProperty(expressMethod?.prices.breakdown ?? [], 'total_price')}
                        originalPrice={
                          isFreeTier
                            ? 0
                            : sumByProperty(expressMethod?.prices.breakdown ?? [], 'total_price_without_discount')
                        }
                        discount={shipping_discount}
                        time={expressMethod?.turnaround_time}
                        name="Express"
                        onClick={expressMethod ? () => handleDeliveryMethodChange(expressMethod?.id) : undefined}
                        style={{ minHeight: '90px', overflowY: 'auto' }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ToogleButtom opened={!collapsed} onClick={() => handleToogleOpen()} />
        </Grid>
      </Grid>
      {!collapsed ? (
        <>
          <Box className={classes.productShippingInfo} style={{ paddingTop: 32 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {includeShipmentDocumentation ? (
                  <>
                    <Typography variant="body4MediumInter" className={classes.miscInputTitle}>
                      Does this shipment require extra documentation?
                    </Typography>
                    <Box className={classes.documentBox}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography variant="body3MediumInter" className={classes.miscInputLowTitle}>
                            Upload documents
                          </Typography>
                        </Grid>
                        <Grid item>
                          <SmallSwitch
                            checked={showDocumentation}
                            onChange={() => setShowDocumentation(prev => !prev)}
                            color="primary"
                            name="checkedBtn"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                      </Grid>
                      {showDocumentation && (
                        <>
                          <FileUploadZone
                            handleFileUpload={handleFileUpload}
                            disabled={disabled}
                            renderComponent={props => (
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.ImageUploadContainer}
                                style={{ border: props?.isDragActive ? '1px dotted #3577d4' : undefined }}
                              >
                                <Grid item>
                                  {isEmpty(documentation) ? (
                                    <Box className={classes.imgContainer}>
                                      <div>
                                        <Grid container justifyContent="center">
                                          <Grid item>
                                            {uploading ? (
                                              <CircularProgress style={{ height: 24, width: 24 }} />
                                            ) : (
                                              <CloudUpload style={{ height: 24, width: 24, color: '#6F777E' }} />
                                            )}
                                          </Grid>
                                        </Grid>
                                      </div>
                                      <div>
                                        <p className={classes.uploadText}>
                                          Drag & Drop to upload or{' '}
                                          <span
                                            className={classes.link}
                                            style={{ color: disabled ? '#989EA4' : undefined }}
                                          >
                                            Choose files
                                          </span>
                                        </p>
                                        <p className={classes.fileFormats}>
                                          Accepted file types: TXT, DOC, PDF, PNG, JPEG
                                        </p>
                                      </div>
                                    </Box>
                                  ) : (
                                    <Box className={classes.docContainer}>
                                      <Grid container justifyContent="center">
                                        <Grid item>
                                          {uploading ? (
                                            <CircularProgress style={{ height: 24, width: 24 }} />
                                          ) : (
                                            <PostAdd style={{ height: 24, width: 24, color: '#6F777E' }} />
                                          )}
                                        </Grid>
                                      </Grid>
                                      <p className={classes.uploadText}>{documentation.name}</p>
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            )}
                          />

                          <Grid container>
                            <Typography variant="body3RegularInter" className={classes.miscInputSubTitle}>
                              Shipping Notes
                            </Typography>
                          </Grid>
                          <Grid container className={classes.shippingNoteContainer}>
                            <TextField
                              className={classes.shippingNote}
                              defaultValue={shippingNotes}
                              onChange={e => handleNoteChange(e.target.value)}
                              placeholder="Type here if you have any additional request"
                              minRows={5}
                              maxRows={5}
                              multiline
                              InputProps={{
                                disableUnderline: true,
                                classes: { input: classes.textAreaResize },
                                inputProps: { maxLength: maxNoteLength }
                              }}
                            />

                            <p className={classes.notesLengthCounter}>
                              {shippingNotes.length}/{maxNoteLength}
                            </p>
                          </Grid>
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid container>
                      <Typography variant="body4MediumInter" className={classes.miscInputTitle}>
                        Shipping Notes
                      </Typography>
                    </Grid>
                    <Grid container className={classes.shippingNoteContainer} style={{ marginTop: 0 }}>
                      <TextField
                        className={classes.shippingNote}
                        defaultValue={shippingNotes}
                        onChange={e => handleNoteChange(e.target.value)}
                        placeholder="Type here if you have any additional request"
                        minRows={5}
                        maxRows={5}
                        multiline
                        InputProps={{
                          disableUnderline: true,
                          classes: { input: classes.textAreaResize },
                          inputProps: { maxLength: maxNoteLength }
                        }}
                      />

                      <p className={classes.notesLengthCounter}>
                        {shippingNotes.length}/{maxNoteLength}
                      </p>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                {isLimitedStock && (
                  <>
                    <Grid container>
                      <Typography variant="body4MediumInter" className={classes.miscInputTitle}>
                        Select Shipping Date
                      </Typography>
                    </Grid>
                    <Grid container>
                      <FormControl>
                        <CalendarModalTool
                          selectedDate={shippingDate}
                          onSubmit={handleChangeShippingDate}
                          submitText="Select"
                          variant="square"
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <RemoveShipmentSection onRemove={onRemove} />
          </Box>
          <Divider className={classes.fullwidthDivider} />
          <Box className={classes.productShippingInfo}>
            <Grid container style={{ marginBottom: 24 }} alignItems="center">
              <Grid item xs>
                <p className={classes.title}>Selected Products</p>
              </Grid>
              <Grid item>
                <Button variant="text" className={classes.addAllRemaining} onClick={addRemainingItems}>
                  Add All Remaining Items
                </Button>
              </Grid>
            </Grid>
            {shipmentGroupHasInactiveSizes && !isLimitedStock && <InactiveSizesAlert />}
            {showingProducts.map((p, index) => (
              <Slide key={p.id} direction="right" in={selectedQuantities.has(p.id)} mountOnEnter unmountOnExit>
                <Grid
                  container
                  className={classes.productInfoPanel}
                  style={
                    index === showingProducts.length - 1
                      ? {
                          borderBottom: index === showingProducts.length - 1 ? 'none' : undefined,
                          marginBottom: 0
                        }
                      : {}
                  }
                >
                  <QuantitiesSelection
                    qtyLeft={limitedStock ? qtyLeftPerProduct[index] : undefined}
                    qtyLeftForAddItems={permanentQtyLeftPerProduct[index]}
                    recipient={recipientId}
                    errorMessage={
                      recipient.shipping_country !== 'US' && !p.product.can_ship_international ? (
                        <span>
                          International delivery is not available for this product. Please refer to our{' '}
                          <a
                            href="https://support.swagup.com/en/articles/6952397-international-shipments-restricted-items"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Shipping Policy*
                          </a>{' '}
                          for more information.
                        </span>
                      ) : (
                        ''
                      )
                    }
                    product={p}
                    quantities={selectedQuantities.get(p.id)}
                    onChangeQty={handleChangeQuantity(p.id)}
                    onReset={handleResetQuantity(p.id)}
                    hideInactiveWarnings={isLimitedStock}
                    isEditingShipmentGroup={isEditingShipmentGroup}
                    limitQty={isLimitedStock}
                    aditionalCTA={
                      <RemoveShipmentSection
                        className="remove-product-section"
                        text="Remove product"
                        onRemove={() => handleRemoveProduct(p.id)}
                        style={{ marginTop: 8 }}
                        hidden={showingProducts.length < 2}
                      />
                    }
                  />
                </Grid>
              </Slide>
            ))}
          </Box>
        </>
      ) : (
        <RemoveShipmentSection className="remove-shipment-section" onRemove={onRemove} />
      )}

      <RemoveDialog
        open={openRemoveProductDialog}
        title="Are you sure want to remove this product?"
        actioButtonText="Remove product"
        cancelButtonText="Keep product"
        titleSize={6}
        onRemove={e => {
          onRemoveProductClose();
          handleRemoveAProduct(selectedProductId);
          handleResetQuantity(selectedProductId)(e);
        }}
        onClose={onRemoveProductClose}
      />
    </Box>
  );
};

const EditRecipientRow = ({
  recipientId,
  limitedStock,
  isSelected,
  onSelect,
  onEdit,
  onRemove,
  hideInactiveWarnings,
  setIsLoading
}) => {
  const recipient = useSelector(state => state.shipmentGroup.recipients.find(r => r.id === recipientId));
  const [isEditDrawerOpen, setIsEditDrawerOpen] = React.useState(false);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const handleSaveContact = contact => {
    dispatch(updateShipmentGroupRecipient(contact));
    queryClient.invalidateQueries(apiPaths.contacts);
    setIsEditDrawerOpen(false);
  };

  const recipientData = recipient
    ? {
        ...pick(recipient, [...addressFields, 'id', 'first_name', 'last_name', 'company_name', 'title', 'email']),
        phone_number: getFormattedPhoneNumber(recipient.phone_number, recipient.shipping_country),
        size: recipient.size?.id ?? ''
      }
    : undefined;

  // const classes = useStyles();
  return (
    <Box>
      <RecipientForm
        recipientId={recipientId}
        limitedStock={limitedStock}
        onEditContact={() => setIsEditDrawerOpen(true)}
        isSelected={isSelected}
        onSelect={onSelect}
        onEdit={onEdit}
        onRemove={onRemove}
        hideInactiveWarnings={hideInactiveWarnings}
        setIsLoading={setIsLoading}
      />
      <EditContact
        open={isEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
        onSuccess={handleSaveContact}
        defaultValues={recipientData}
      />
    </Box>
  );
};

export default React.memo(EditRecipientRow);
