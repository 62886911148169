import React from 'react';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';
import { Switch, Route, useHistory, useParams, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Typography, Tabs, Tab } from '@swagup-com/components';
import { CenteredGrid, Helmet, Link, Alert } from '../../shared';
import Company from '../../account/CompanyContainer';
import Profile from '../../account/ProfileContainer';
import User from '../../account/UserContainer';
import Membership from '../../account/Membership';
import { SelectionProvider, SelectionContext } from '../contact/SelectionContext';
import tags from '../../../apis/seoTags';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 82px)',
    paddingTop: 100,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 100
    }
  },
  sidebar: {
    minWidth: 232,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      minWidth: 'auto'
    }
  },
  main: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: { padding: '40px 24px' }
  },
  tab: {
    fontFamily: 'Inter',
    fontWeight: 600,
    padding: '0px',
    marginRight: '32px',
    marginLeft: '-14px'
  }
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SettingsHome = () => {
  const classes = useStyles();
  const { view } = useParams();
  const location = useLocation();
  const getView = () => {
    switch (location.pathname) {
      case '/settings/profile':
        return 0;
      case '/settings/company':
        return 1;
      case '/settings/users':
        return 2;
      case '/settings/your-membership':
        return 3;
      default:
        return 3;
    }
  };
  return (
    <Grid>
      <Helmet tags={tags.settings} />
      <SelectionProvider>
        <CenteredGrid className={classes.root}>
          <Tabs value={getView()} className={classes.tabs} style={{ padding: '0px 0px' }}>
            <Tab label="Profile" component={Link} to="/settings/profile" className={classes.tab} />
            <Tab label="Company" component={Link} to="/settings/company" className={classes.tab} />
            <Tab label="Users" component={Link} to="/settings/users" className={classes.tab} />
            <Tab label="Membership" component={Link} to="/settings/your-membership" className={classes.tab} />
          </Tabs>

          <Grid className={clsx(classes.content, 'fade-translate')} key={view} style={{ marginTop: 20 }}>
            <Switch>
              <Route path="/settings/profile" exact>
                <Profile />
              </Route>
              <Route path="/settings/company" exact>
                <Company />
              </Route>
              <Route path="/settings/users" exact>
                <User />
              </Route>
              <Route path="/settings/your-membership" exact>
                <Elements stripe={stripePromise}>
                  <Membership />
                </Elements>
              </Route>
              <Route path="/membership/manage-membership" exact>
                <Elements stripe={stripePromise}>
                  <Membership />
                </Elements>
              </Route>
              <Route path="/membership/manage-membership-cancel" exact>
                <Elements stripe={stripePromise}>
                  <Membership />
                </Elements>
              </Route>
              <Route path="/membership/manage-membership-switch" exact>
                <Elements stripe={stripePromise}>
                  <Membership />
                </Elements>
              </Route>
              <Route path="/membership/manage-membership-confirmation" exact>
                <Elements stripe={stripePromise}>
                  <Membership />
                </Elements>
              </Route>
              <Route path="/membership/change-membership" exact>
                <Elements stripe={stripePromise}>
                  <Membership />
                </Elements>
              </Route>
            </Switch>
          </Grid>
        </CenteredGrid>
      </SelectionProvider>
    </Grid>
  );
};

export default SettingsHome;
