import React from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import styles from './styles/ProgressBar';

const ProgressBar = ({ progress, classes }) => (
  <>
    <LinearProgress
      variant="determinate"
      value={progress}
      classes={{
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary
      }}
    />
    <br />
    <p className={classes.progressText}>{progress < 100 ? `${Math.round(progress)}%` : 'Complete'}</p>
  </>
);

export default withStyles(styles)(ProgressBar);
