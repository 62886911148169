const styles = {
  sectionTitle: {
    fontFamily: 'Futura',
    fontSize: 24,
    fontWeight: 'bold',
    color: '#434c5f'
  },
  formContainer: {
    padding: '0px 0px 30px 15px',
    maxWidth: 690,
    borderRadius: 4
  },
  tabsRoot: { borderBottom: '1px solid #e8e8e8' },
  tabsIndicator: { backgroundColor: '#3577d4' },
  tabRoot: {
    textTransform: 'initial',
    fontFamily: 'Roboto',
    '&$tabSelected': { color: '#3577d4' },
    '&:focus': { color: '#3577d4' }
  },
  tabSelected: {},
  checkoutSubHeading: {
    fontFamily: 'Roboto',
    fontSize: 14,
    marginBottom: 24,
    lineHeight: 'normal',
    color: '#434c5f'
  },
  checkoutSection: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 'normal',
    color: '#434c5f',
    textTransform: 'uppercase'
  },
  formParagraph: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 'normal',
    color: '#434c5f'
  },
  pPaddingBottom: { paddingBottom: 15 },
  pPaddingTop: { paddingTop: 15 },
  tabTitle: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 'normal',
    color: '#434c5f',
    textTransform: 'uppercase'
  },
  checkoutActions: { paddingTop: 40 },
  bigSectionHeader: {
    fontFamily: 'Futura',
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 'normal',
    color: '#434c5f',
    padding: '40px 0 0 0',
    marginBottom: 0
  },
  submitButton: {
    width: 282,
    height: 56,
    '&:hover': { backgroundColor: '#3577d4' },
    '& span': {
      fontFamily: 'Futura',
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 'normal'
    },
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: 4,
    backgroundColor: '#3577d4'
  },
  disclaimer: {
    fontFamily: 'Roboto',
    marginTop: 15,
    fontSize: 12
  },
  formControl: { marginTop: '20px !important' },
  Loading: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 2,
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  achIcon: {
    width: 60,
    height: 60,
    marginBottom: 16
  },
  title: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    color: '#0b1829'
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    color: '#787b80',
    lineHeight: 1.57,
    marginTop: 10,
    marginBottom: 35
  },
  infoHeader: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#0b1829',
    marginBottom: 12
  },
  info: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    color: '#0b1829',
    lineHeight: 1.57,
    marginBottom: 8
  }
};

export default styles;
