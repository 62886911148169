const styles = theme => ({
  ContactHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#3577d4'
  },
  FormContainer: {
    width: '588px',
    paddingTop: 30,
    margin: '0 auto',

    [theme.breakpoints.down('xs')]: {
      width: '300px'
    }
  },
  label: {
    width: '100%',
    height: '25px',
    paddingTop: '15px',
    fontFamily: 'Futura',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    marginBottom: '16px',
    marginTop: '16px'
  },
  PlaceholderLabel: {
    width: '100%',
    height: '21px',
    opacity: '0.54',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  FormAction: {
    textAlign: 'center',
    padding: '40px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 0'
    }
  },
  SendBigBtn: {
    width: '384px',
    height: '64px',
    borderRadius: '4px',
    backgroundColor: '#3577d4',
    fontFamily: 'Futura',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.75',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '288px'
    },
    '&:hover': {
      backgroundColor: '#3577d4'
    }
  },
  SuccessMessage: {
    padding: '0 0 30px 0',
    maxWidth: 500,
    margin: '0 auto',
    textAlign: 'center',
    '& h3': {
      fontFamily: 'Roboto',
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#0f2440'
    },
    '& p': {
      paddingBottom: 20,
      opacity: 0.54,
      fontFamily: 'Roboto',
      fontSize: 14,
      lineHeight: 1.57,

      color: '#434c5f'
    }
  }
});

export default styles;
