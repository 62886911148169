import { LOAD_COMPANY, SAVE_COMPANY } from '../actions/types';

const companyReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_COMPANY:
      return action.payload;

    case SAVE_COMPANY: {
      const { changed } = action.payload;
      return {
        ...action.payload,
        changed: !changed
      };
    }

    default:
      return state;
  }
};

export default companyReducer;
