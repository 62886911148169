import integration from '../../__factory__/integration';
import apiPaths from '../../helpers/apiPaths';
import integrationsApi from '../IntegrationsAPI';
import rutterAPI from '../rutterAPI';
import { rutterHeader } from '../rutterUtils';
import { status200, status200or201 } from './status.utils';

const mergeLinkAPIUrl = 'https://api.merge.dev/api/integrations/create-link-token';
const mergeKey = 'qn-YoqGhvlyvHcMGi9d9iN53fO7lHXpE-8UhQW5DQ_en-HG1sfh-gg';

export default {
  fetch: (search = '', limit = '', offset = '', ordering = '') =>
    integrationsApi
      .get(`${apiPaths.integrations}?search=${search}&limit=${limit}&offset=${offset}&ordering=${ordering}`, status200)
      .then(response => response.data),
  get: id =>
    integrationsApi
      .get(apiPaths.integration(id), status200)
      .then(response => integration)
      .catch(e => integration), // response.data),
  fetchIntegrationOrders: (token, seachQuery, onError) =>
    integrationsApi
      .get(`${apiPaths.integrationOrders}?access_token=${token}${seachQuery ? `&${seachQuery}` : ''}`, status200)
      .then(response => response.data)
      .catch(onError),
  fetchIntegrationTriggers: (token, onError = () => true) =>
    integrationsApi
      .get(`${apiPaths.integrationTriggers(token)}`, status200)
      .then(response => response.data)
      .catch(onError),
  fetchIntegrationEmployeeTypes: (params, onError = () => true) =>
    integrationsApi
      .post(apiPaths.integrationEmployeeTypes, params, status200or201)
      .then(response => response.data)
      .catch(onError),
  fetchIntegrationActionOrders: (
    token,
    search,
    automation = '',
    approved = '',
    limit,
    offset,
    ordering = '',
    onError = () => true
  ) =>
    integrationsApi
      .get(
        `${apiPaths.integrationActionOrders(
          token
        )}&search=${search}&limit=${limit}&trigger_type=${automation}&offset=${offset}&approved=${approved}&ordering=${ordering}`,
        status200
      )
      .then(response => response.data)
      .catch(onError),
  fetchIntegrationActionOrdersExport: (token, search, automation = '', approved = '', onError = () => true) =>
    integrationsApi
      .get(
        `${apiPaths.integrationActionOrders(token)}&search=${search}&trigger_type=${automation}&approved=${approved}`,
        {
          status200,
          headers: { Accept: 'text/csv' }
        }
      )
      .then(response => response.data)
      .catch(onError),
  updateActionOrder: (token, params, id, onError = () => true) =>
    integrationsApi
      .patch(`${apiPaths.integrationActionOrder(id, token)}`, params, status200or201)
      .then(response => response.data)
      .catch(onError),
  createIntegrationTrigger: (token, params, onError = () => true) =>
    integrationsApi
      .post(`${apiPaths.integrationTriggers(token)}`, params, status200or201)
      .then(response => response.data)
      .catch(onError),
  updateIntegrationAction: (token, params, id, onError = () => true) =>
    integrationsApi
      .patch(`${apiPaths.integrationAction(id, token)}`, params, status200or201)
      .then(response => response.data)
      .catch(onError),
  disconnectIntegrationTrigger: (token, id, onError = () => true) =>
    integrationsApi
      .delete(`${apiPaths.integrationTrigger(id, token)}`, status200or201)
      .then(response => response.data)
      .catch(onError),
  disconnectIntegrationTriggerBulk: (token, ids, onError = () => true) =>
    integrationsApi
      .delete(`${apiPaths.integrationTriggers(token)}&${ids}`, status200or201)
      .then(response => response.data)
      .catch(onError),
  updateIntegrationTrigger: (token, params, id, onError = () => true) =>
    integrationsApi
      .patch(`${apiPaths.integrationTrigger(id, token)}`, params, status200or201)
      .then(response => response.data)
      .catch(onError),
  retryOrder: (params, token) =>
    integrationsApi
      .post(`${apiPaths.integrationOrders}?access_token=${token}`, params, status200)
      .then(response => response.data)
      .catch(data => data),
  updateOrderRecipient: (orderId, recipient, token) =>
    integrationsApi
      .patch(`${apiPaths.integrationOrders}${orderId}/?access_token=${token}`, recipient, status200or201)
      .then(response => response.data)
      .catch(data => data),
  fetchIntegrationProducts: (token, search = '', limit, offset) =>
    integrationsApi
      .get(
        `${apiPaths.integrationProducts}?access_token=${token}&search=${search}&limit=${limit}&offset=${offset}`,
        status200
      )
      .then(response => response.data),
  fetchIntegratedProducts: (
    token,
    prodId,
    platforms // ({ results: integratedProducts }),
  ) =>
    integrationsApi
      .get(
        `${apiPaths.integrationVariants}?access_token=${token}&swagup_products=${prodId}&platforms=${platforms}`,
        status200
      )
      .then(response => response.data),
  connectProduct: (data, token) =>
    integrationsApi
      .post(`${apiPaths.integrationVariants}?access_token=${token}`, data, status200or201)
      .then(response => response.data),
  createNewProduct: (params, token) =>
    integrationsApi.post(`${apiPaths.integrationProduct}?access_token=${token}`, params, status200or201),
  signalNewStore: params => integrationsApi.post(`${apiPaths.integrationStores}`, params, status200or201),
  disconnectProduct: (token, variant) =>
    integrationsApi
      .patch(`${apiPaths.integrationVariants}${variant}/?access_token=${token}`, { disconnect: true }, status200or201)
      .then(response => response.data),
  getStores: () => integrationsApi.get(apiPaths.integrationStores, status200or201).then(response => response.data),
  connectionAccess: params =>
    rutterAPI.post('/item/public_token/exchange', params, status200).then(response => response.data),
  connection: params =>
    rutterAPI
      .get('/connections/access_token', { ...status200, params, headers: rutterHeader })
      .then(response => response.data),
  automationSetUpCreate: (id, data) =>
    integrationsApi.post(apiPaths.integration(id), data, status200or201).then(response => response.data),
  automationSetUpUpdate: (id, data) =>
    integrationsApi.patch(apiPaths.integration(id), data, status200or201).then(response => response.data),
  createLinkToken: async integrationName => {
    const body = {
      integration_name: integrationName
    };

    return integrationsApi
      .post(apiPaths.integrationsGetMergeLink, body, status200or201)
      .then(response => response.data);
  }
};
