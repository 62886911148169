import * as React from 'react';
import difference from 'lodash/difference';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { createReorderProducts, removeReorderProducts } from '../../../actions';
import getSizesTotalQtyErrorMsg from '../../reorder/getSizesTotalQtyErrorMsg';
import ReorderProductsList from '../../reorder/ReorderProductsList';
import ReorderSummaryBottom from '../../reorder/ReorderSummaryBottom';
import { CenteredGrid, Helmet } from '../../shared';
import tags from '../../../apis/seoTags';
import accountProductsApi from '../../../apis/swagup/accountProducts';
import log from '../../../logger';
import apiPaths from '../../../helpers/apiPaths';
import Loader from '../../global/Loader';
import { orderApi } from '../../../apis/swagup';

const useProductsIds = () => {
  const { ids } = useParams();
  const reorderProducts = useSelector(state => state.reorderProducts);

  return React.useMemo(() => {
    const idsArr = ids.split(',').map(Number);
    const reorderIds = reorderProducts.map(p => p.id);

    const idsToFetch = difference(idsArr, reorderIds);
    const idsToRemove = difference(reorderIds, idsArr);

    return [idsToFetch, idsToRemove];
  }, [ids, reorderProducts]);
};

const useStyles = makeStyles({
  container: { margin: '0 auto', overflowX: 'hidden' },
  navbarOpen: {
    marginLeft: '60px',
    marginRight: '20px',
    maxWidth: 'calc(100% - 110px)',
    overflowX: 'hidden'
  },
  navbarClosed: {
    marginLeft: '80px',
    marginRight: '20px',
    maxWidth: 'calc(100% - 120px)',
    overflowX: 'hidden'
  },
  reorderContainer: { marginBottom: 100 },
  summaryContainer: { padding: '14px 40px' },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#ffffff',
    padding: '14px 0'
  },
  continueButton: {
    fontSize: 16,
    lineHeight: 0.75,
    width: 190,
    height: 56
  }
});

const buildOpportunityPayload = reorderProducts => ({
  account_products: reorderProducts.map(rp => ({
    account_product: rp.id,
    production_time: rp.production_time,
    quantities_per_size: rp.sizes.map(({ size, quantity }) => ({ size: size.id, quantity }))
  })),
  lead_source: 'Reorder'
});

const SelectQuantities = () => {
  const classes = useStyles();

  const [idsToFetch, idsToRemove] = useProductsIds();

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (idsToRemove.length > 0) {
      dispatch(removeReorderProducts(new Set(idsToRemove)));
    }
  }, [idsToRemove, dispatch]);

  const ids = idsToFetch.join(',');
  const { data, isLoading } = useQuery([apiPaths.accountProducts, { ids }], () => accountProductsApi.fetch({ ids }), {
    enabled: idsToFetch.length > 0
  });

  const history = useHistory();
  React.useEffect(() => {
    if (!data) return;

    const { results } = data;
    if (results.length > 0) {
      dispatch(createReorderProducts(results));
    } else {
      history.push(history.location.state?.from || '/inventory');
    }
  }, [data, history, dispatch]);

  const [inPricing, setInPricing] = React.useState(false);
  const reorderProducts = useSelector(state => state.reorderProducts);
  const canContinueToShipping = () => {
    const reorderProductsWithErrorMsg = reorderProducts.find(rp => getSizesTotalQtyErrorMsg(rp));
    const canContinue = !reorderProductsWithErrorMsg && !inPricing;
    log.debug('canContinueToShipping, with errors:', reorderProductsWithErrorMsg, 'in pricing:', inPricing);
    return canContinue;
  };

  const queryClient = useQueryClient();
  const { mutate: createOpportunity, isLoading: isCreatingOpportunity } = useMutation(orderApi.createOpportunity, {
    onSuccess: opportunity => {
      queryClient.fetchQuery(apiPaths.opportunity(opportunity.id), () => opportunity);
      history.push({ pathname: `/orders-requested/${opportunity.id}`, state: { sendProductsToWarehouse: true } });
    }
  });

  const { leftBarNavigation } = useFlags();

  const { leftNavOpen } = useSelector(state => state.commonReducer);

  return (
    <>
      <Helmet tags={tags.reorder} />
      {isLoading && <Loader />}
      {reorderProducts.length > 0 && (
        <>
          <CenteredGrid container item justifyContent="center" className={classes.container}>
            <Grid
              container
              justifyContent="center"
              className={`${classes.reorderContainer} ${
                leftBarNavigation ? (leftNavOpen ? classes.navbarOpen : classes.navbarClosed) : {}
              }`}
            >
              <ReorderProductsList setInPricing={setInPricing} />
              <Grid container justifyContent="flex-end" className={classes.summaryContainer}>
                <ReorderSummaryBottom reorderProducts={reorderProducts} />
              </Grid>
            </Grid>
          </CenteredGrid>
          <div className={classes.footer} style={{ marginRight: leftBarNavigation ? 14 : 0 }}>
            <CenteredGrid container justifyContent="flex-end">
              <Button
                variant="primary"
                className={classes.continueButton}
                onClick={() => createOpportunity(buildOpportunityPayload(reorderProducts))}
                disabled={!canContinueToShipping(inPricing) || isCreatingOpportunity}
                loading={inPricing || isCreatingOpportunity}
              >
                {inPricing ? 'Calculating...' : 'Review Order'}
              </Button>
            </CenteredGrid>
          </div>
        </>
      )}
    </>
  );
};

export default SelectQuantities;
