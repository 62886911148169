import * as React from 'react';
import clsx from 'clsx';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@swagup-com/components';
import { isPack } from '../../helpers/utils';
import { ImgWithHandler } from '../global/ImgUtils';
import { ellipsisStyles, scrollBar } from '../shared/styles/commonStyles';
import { ProductColorV2 } from './InventoryCommon';

const useStyles = makeStyles({
  dialog: {
    width: '100%',
    maxWidth: 800,
    borderRadius: 8
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 20px',
    borderBottom: '1px solid #E5E7E8'
  },
  closeLink: {
    marginLeft: 'auto',
    color: '#4A4F54',
    fontSize: 14,
    lineHeight: 0
  },
  divider: {
    width: 'calc(100% - 16px)',
    margin: '8px 0',
    border: '1px solid #E5E7E8'
  },
  productsContainer: {
    maxWidth: '100%',
    maxHeight: 410,
    padding: '0 14px 0 2px',
    ...scrollBar
  },
  productContainer: {
    height: 'unset',
    justifyContent: 'flex-start',
    width: '100%',
    margin: '4px 0',
    padding: '12px 16px',
    border: '1px solid #E5E7E8',
    borderRadius: 12,
    '&:hover, &:focus': {
      borderColor: '#989EA4'
    },
    '&.selected': {
      borderColor: '#3577D4',
      boxShadow: '0 0 0 1px #3577D4'
    }
  },
  productImage: {
    width: 48,
    height: 48,
    marginRight: 10
  },
  productName: {
    marginBottom: 4,
    ...ellipsisStyles
  },
  selectedItemImage: {
    width: 394,
    height: 394,
    border: '1px solid #D6D8DB',
    borderRadius: 12,
    marginBottom: 32
  },
  mockupLink: {
    height: 'unset',
    '&:hover, &:focus': { textDecoration: 'underline !important' },
    '&:active': { color: '#1C4783' },
    '& p': { color: 'inherit' },
    '&  svg': { fontSize: 20, marginRight: 8 }
  }
});

const InventoryItemDetailsModalContent = ({ item, closeUrlParams, hidePDFDownload, setViewedProduct }) => {
  const classes = useStyles();
  const location = useLocation();

  const products = isPack(item.record_type) ? item.items.map(i => i.product) : [item];
  const [selected, setSelected] = React.useState(products[0]);

  const itemMockupUrl = item.image?.replace('.png', '.pdf');

  return (
    <>
      <DialogTitle className={classes.title} disableTypography>
        <Link to={{ ...location, search: closeUrlParams.toString() }} className={classes.closeLink}>
          <CloseIcon onClick={setViewedProduct ? () => setViewedProduct('') : undefined} />
        </Link>
      </DialogTitle>
      <DialogContent style={{ padding: '28px 8px 28px 24px' }}>
        <Grid container>
          <Grid item style={{ flex: 1 }}>
            <ImgWithHandler
              src={selected?.image}
              width={690}
              height={690}
              alt={selected?.name}
              className={classes.selectedItemImage}
            />
            {!hidePDFDownload && (
              <Button
                variant="text"
                href={itemMockupUrl}
                disabled={!itemMockupUrl}
                target="_blank"
                rel="noreferrer"
                download
                className={classes.mockupLink}
              >
                <SaveAltOutlinedIcon />
                <Typography variant="body2SemiBoldInter">Mockup PDF</Typography>
              </Button>
            )}
          </Grid>
          <Grid container direction="column" style={{ maxWidth: 326 }}>
            <Typography variant="h6BoldInter">{item.name}</Typography>
            <Divider classes={{ root: classes.divider }} />
            <div className={classes.productsContainer}>
              {products.map(product => (
                <Button
                  key={product.id}
                  variant="text"
                  onClick={() => setSelected(product)}
                  className={clsx(classes.productContainer, { selected: product.id === selected.id })}
                >
                  <ImgWithHandler
                    src={product.image}
                    width={78}
                    height={78}
                    alt={product.name}
                    className={classes.productImage}
                  />
                  <Grid item style={{ overflow: 'hidden' }}>
                    <Typography variant="body2SemiBoldInter" className={classes.productName}>
                      {product.name}
                    </Typography>
                    <ProductColorV2 item={product} />
                  </Grid>
                </Button>
              ))}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

const InventoryItemDetailsModal = ({ item, hidePDFDownload, setViewedProduct }) => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const closeUrlParams = new URLSearchParams(location.search);
  closeUrlParams.delete('details');

  const handleOnCLose = () =>
    setViewedProduct ? setViewedProduct('') : history.replace({ ...location, search: closeUrlParams.toString() });

  return (
    <Dialog key={item?.id} open={Boolean(item)} onClose={handleOnCLose} classes={{ paper: classes.dialog }}>
      {item && (
        <InventoryItemDetailsModalContent
          item={item}
          closeUrlParams={closeUrlParams}
          hidePDFDownload={hidePDFDownload}
          setViewedProduct={setViewedProduct}
        />
      )}
    </Dialog>
  );
};

export default InventoryItemDetailsModal;
