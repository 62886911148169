import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import sumBy from 'lodash/sumBy';
import ShippingSummary from './ShippingSummary';
import CheckoutSideBar from './CheckoutSidebar';
import { Helmet, Link, CenteredGrid } from '../shared';
import { dayjs } from '../../helpers/dayjs';
import Loader from '../global/Loader';
import tags from '../../apis/seoTags';
import { useAsync, useCompany } from '../../hooks';
import { selectInvoice, cleanMultishipping } from '../../actions';
import { ImgWithHandler } from '../global/ImgUtils';
import { sizesWithQuantities, addBusinessDaysToDate } from '../../helpers/utils';
import toErrorPage from '../../helpers/toErrorPage';
import styles from './styles/ShippingOrderConfirmation';
import log from '../../logger';

const useStyles = makeStyles(styles);

const prepareDeliveryInfo = directoryOrder => {
  if (directoryOrder) {
    const dm = directoryOrder.delivery_method;
    return {
      deliveryMethod: dm.name,
      shippingNote: directoryOrder.shipping_notes
    };
  }
  return {};
};

const DirectoryOrders = ({ invoice }) => {
  const classes = useStyles();
  const last = invoice.directory_orders.length - 1;

  const invoiceProducts = invoice.products.reduce((acc, p) => {
    acc[p.product.id] = p;
    return acc;
  }, {});

  return invoice.directory_orders.map((order, index) => {
    const products = order.products.map(p => {
      const { product, quantity, price, total } = p;
      const invoiceProduct = invoiceProducts[product];
      const { size } = invoiceProduct.quantities_per_size.find(qps => qps.size.id === p.size);

      return {
        ...invoiceProduct.product,
        size,
        quantity,
        price,
        total
      };
    });

    return (
      <div
        key={order.id}
        className={classes.summary}
        style={{ borderBottom: `1px solid ${index === last ? 'transparent' : '#d4d9e2'}` }}
      >
        <ShippingSummary employeeInfo={order.employee} deliveryInfo={prepareDeliveryInfo(order)} products={products} />
      </div>
    );
  });
};

const quantitiesInDirectoryOrdersByProduct = directoryOrders =>
  directoryOrders.reduce((acc, order) => {
    order.products.forEach(p => {
      if (!acc[p.product]) {
        acc[p.product] = { qps: {}, quantity: 0 };
      }
      acc[p.product].qps[p.size] = (acc[p.product].qps[p.size] ?? 0) + p.quantity;
      acc[p.product].quantity += p.quantity;
    });
    return acc;
  }, {});

const quantitiesToWarehouseByProduct = invoice => {
  const inDirectoryOrders = quantitiesInDirectoryOrdersByProduct(invoice.directory_orders);

  return invoice.products.reduce((acc, p) => {
    const sizes = p.quantities_per_size.map(qps => ({
      ...qps,
      quantity: qps.quantity - (inDirectoryOrders[p.product.id]?.qps[qps.size.id] ?? 0)
    }));

    if (!acc[p.product.id]) {
      acc[p.product.id] = { sizes: [], quantity: 0 };
    }
    acc[p.product.id].sizes.push(...sizes);
    acc[p.product.id].quantity = p.quantity - (inDirectoryOrders[p.product.id]?.quantity ?? 0);

    return acc;
  }, {});
};

const ItemsToWarehouse = ({ invoice }) => {
  const classes = useStyles();

  const toWareHouse = quantitiesToWarehouseByProduct(invoice);
  const warehouseTotal = sumBy(Object.values(toWareHouse), 'quantity');
  log.debug('toWareHouse:', toWareHouse, 'warehouseTotal:', warehouseTotal);

  return (
    warehouseTotal > 0 && (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.subTitle}>
            SENDING TO WAREHOUSE
          </Typography>
        </Grid>
        {invoice.products.map(
          p =>
            toWareHouse[p.product.id].quantity > 0 && (
              <Grid item xs={4} key={p.id}>
                <Grid container>
                  <Grid item>
                    <div className={classes.imgContainer}>
                      <ImgWithHandler src={p.image} alt={p.name} width={78} height={78} />
                    </div>
                  </Grid>
                  <Grid item xs style={{ paddingLeft: 16 }}>
                    <Typography variant="subtitle2" style={{ color: '#0f2440' }}>
                      {p.name}
                    </Typography>
                    <Typography variant="body2">Color: {p.theme_color || p.color || 'Custom'}</Typography>
                    <Typography variant="body2">
                      Size: {sizesWithQuantities(toWareHouse[p.product.id].sizes)}
                    </Typography>
                    <Typography variant="body2">Qty: {toWareHouse[p.product.id].quantity}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
        )}
      </Grid>
    )
  );
};

const toWeek = date =>
  dayjs(date)
    .isoWeekday(1)
    .format('MMM DD, YYYY');

export const beReadyToShipText = (invoice, isEnterpriseFlowActive) => {
  if (isEnterpriseFlowActive) return 'Keep an eye out for an invoice from SwagUp Billing.';

  const turnaroundTimes = invoice.products.map(p => p.turnaround_time);
  const minTime = Math.min(...turnaroundTimes);
  const maxTime = Math.max(...turnaroundTimes);
  const invoiceDate = new Date(invoice.created_at);

  const minWeek = toWeek(addBusinessDaysToDate(invoiceDate, minTime));
  const maxWeek = toWeek(addBusinessDaysToDate(invoiceDate, maxTime));

  if (minWeek === maxWeek) return `Your order is estimated to be ready to ship the week of ${minWeek}.`;

  return `Your order is estimated to be ready to ship between the weeks of ${minWeek} - ${maxWeek}.`;
};

const ShippingOrderConfirmation = () => {
  const [invoice, setInvoice] = useState();

  const dispatch = useDispatch();
  const memoizedFetchInvoice = useCallback(id => dispatch(selectInvoice(id)), [dispatch]);
  const [fetchInvoice, response, isPending, hasFailed] = useAsync(memoizedFetchInvoice);

  const { enterpriseGlobalCheckoutFlowTemp031722: enterpriseCheckoutActive } = useFlags();
  const { data: company } = useCompany();
  const isEnterpriseAccount = company.enterprise;
  const isEnterpriseFlowActive = isEnterpriseAccount && enterpriseCheckoutActive;

  const history = useHistory();
  const { invoiceId } = useParams();

  useEffect(() => {
    if (hasFailed) toErrorPage(response, history);
  }, [hasFailed, response, history]);

  useEffect(() => {
    if (response) setInvoice(response.data);
  }, [response]);

  useEffect(() => {
    dispatch(cleanMultishipping());
    fetchInvoice(invoiceId);
  }, [invoiceId, dispatch, fetchInvoice]);

  const classes = useStyles();

  const individualPackPrices = useSelector(state => state.individualPackPrices);
  const affiliateId = company.affiliate_id;
  const isPostalReferral = affiliateId === 'postal.io';
  const postalLink = <Link to="https://postalio.app/integrations">here</Link>;

  if (isPending || !invoice) return <Loader />;

  const storagePrice = parseFloat(invoice.total_fulfillment);
  const selectedCredit = parseFloat(invoice.shipping_credits);
  const totalPlusTax = parseFloat(invoice.total_plus_tax);
  const tax = parseFloat(invoice.tax);

  log.debug('ShippingOrderConfirmation - invoice:', invoice);
  return (
    <CenteredGrid container style={{ paddingBottom: 42 }}>
      <Helmet tags={tags.shippingOrderConfirmation} />
      <Grid item xs={12} className={classes.header}>
        <Typography variant="h3" className={classes.title}>
          {isEnterpriseFlowActive ? 'Your order request has been received' : 'Thank you for your order!'}
        </Typography>
        <Typography variant="h5">{beReadyToShipText(invoice, isEnterpriseFlowActive)}</Typography>
        {isPostalReferral && (
          <Typography variant="h5">Go {postalLink} to connect SwagUp with Postal, if you haven’t done so.</Typography>
        )}
      </Grid>
      <Grid item xs={12} md={8}>
        <DirectoryOrders invoice={invoice} />
        <ItemsToWarehouse invoice={invoice} />
      </Grid>
      <Grid item xs={12} md={4}>
        {invoice.products && (
          <CheckoutSideBar
            individualPackPrices={individualPackPrices}
            paymentMethod={invoice.payment_method}
            products={invoice.products}
            selectedCredit={selectedCredit}
            selectedStorage={storagePrice}
            showCredit={selectedCredit > 0}
            showItems={false}
            showStorage={storagePrice > 0}
            tax={tax}
            totalPlusTax={totalPlusTax}
          />
        )}
      </Grid>
    </CenteredGrid>
  );
};

export default ShippingOrderConfirmation;
