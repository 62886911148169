import React, { useLayoutEffect } from 'react';
import { IconButton, InputAdornment, List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button } from '@swagup-com/components';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { moneyStr } from '../../../helpers/utils';
import styles from './QuantitySelect.styles';
import { TextField } from '../../global/reactHookFormFields';
import useDiscountPricing from '../../../hooks/useDiscountPricing';

const useStyles = makeStyles(styles);

const QuantitySelect = ({
  value,
  open,
  quantities,
  prices,
  minQuantity,
  maxQuantity = 500_000,
  onToggle,
  onChange,
  className
}) => {
  const classes = useStyles();

  const { register, handleSubmit, formState, setValue } = useForm({ shouldUnregister: true });
  const { errors } = formState;

  const ref = React.useRef();

  const onSubmit = data => onChange(+data.quantity);

  const handleToggle = () => onToggle(!open);

  useLayoutEffect(() => {
    if (open && ref.current.scrollIntoView) ref.current.scrollIntoView(false);
  }, [open]);

  const { getDiscountPrice } = useDiscountPricing();
  return (
    <div className={clsx(classes.root, open ? classes.expanded : classes.collapsed, className)} ref={ref}>
      {open ? (
        <>
          <div className={classes.header}>
            <Typography className={classes.label}>Select quantity</Typography>
            <IconButton onClick={handleToggle} className={clsx(classes.mla, classes.button)}>
              <ExpandLessIcon />
            </IconButton>
          </div>
          <Typography className={classes.caption}>The more you order, the more you save</Typography>
          <List disablePadding>
            {quantities.map(quantity => (
              <ListItem key={quantity} button className={classes.pack} onClick={() => onChange(quantity)}>
                <ListItemText primary={quantity} className={classes.packQuantity} />
                <ListItemText primary={moneyStr(getDiscountPrice(+prices[quantity]))} className={classes.packPrice} />
              </ListItem>
            ))}
          </List>
          <TextField
            type="number"
            placeholder="Custom quantity"
            error={errors.quantity?.message}
            {...register('quantity', {
              required: "Value can't be empty",
              min: { value: minQuantity, message: `Value can't be less than ${minQuantity}` },
              max: { value: maxQuantity, message: `Value can't be more than ${maxQuantity}` },
              valueAsNumber: true
            })}
            onChange={e => setValue('quantity', Math.max(parseInt(e.target.value, 10), 0), { shouldValidate: true })}
            fullWidth
            size="small"
            variant="outlined"
            className={classes.customQuantity}
            onKeyPress={e => e.key === 'Enter' && handleSubmit(onSubmit)()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="text" onClick={handleSubmit(onSubmit)}>
                    Enter
                  </Button>
                </InputAdornment>
              )
            }}
          />
        </>
      ) : (
        <div className={classes.header}>
          <Typography className={classes.label}>Quantity</Typography>
          <Typography className={classes.minimum}>(Minimum {minQuantity})</Typography>
          <Typography className={clsx(classes.mla, classes.value)}>{value}</Typography>
          <IconButton onClick={handleToggle} className={classes.button} aria-label="Select quantity">
            <ExpandMoreIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default QuantitySelect;
