import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 50
  },
  button: {
    width: 30,
    height: 30,
    padding: 0
  },
  icon: {}
});

const CloseButton = props => {
  const { onClose } = props;

  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <IconButton aria-label="close" className={classes.button} onClick={onClose}>
        <CloseIcon className={classes.icon} />
      </IconButton>
    </div>
  );
};

export default CloseButton;
