import React from 'react';
import { Grid, withStyles, Button } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import useDemoBookingUrl from '../../../../hooks/useDemoBookingUrl';

import styles from './BeforeGetStarted.styles';

const BeforeGetStarted = ({ classes }) => {
  const demoBookingUrl = useDemoBookingUrl();
  return (
    <Grid container justifyContent="center" id="getStarted">
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item md={7} xs={12} className={classes.textContainer}>
          <h2 className={classes.title}>Before you get started</h2>
          <p className={classes.subtitle}>Some things to know</p>
          <Grid container spacing={5}>
            <Grid item xs={6} className={classes.category}>
              <img alt="title" src="/images/custom-pack-landing/landing/icon_Category_1.svg" />
              <p>We offer 8-15 curated items per category</p>
            </Grid>
            <Grid item xs={6} className={classes.category}>
              <img alt="title" src="/images/custom-pack-landing/landing/icon_Category_2.svg" />
              <p>All pricing includes custom branding and any fees</p>
            </Grid>
            <Grid item xs={6} className={clsx(classes.category, 'mb-0')}>
              <img alt="title" src="/images/custom-pack-landing/landing/icon_Category_3.svg" />
              <p>By submitting you are not commiting to an order</p>
            </Grid>
            <Grid item xs={6} className={clsx(classes.category, 'mb-0')}>
              <img alt="title" src="/images/custom-pack-landing/landing/icon_Category_4.svg" />
              <p>Our design team will create mockups at no cost</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12} className={classes.imageOuterContainer}>
          <Grid container justifyContent="center" className={classes.imageContainer}>
            <img alt="title" src="/images/custom-pack-landing/getstarted.svg" className={classes.image} />
            <Link to="/custom-swag-packs/catalog">
              <Button variant="contained" color="primary" className={classes.button}>
                <span className={classes.buttonText}>Start Building</span>
              </Button>
            </Link>
            <Grid item xs={12} className={classes.link}>
              <MuiLink
                target="_blank"
                rel="noopener noreferrer"
                href={`${demoBookingUrl}?utm_source=swagup%20website&utm_medium=landing%20page&utm_campaign=before_you_get_started_lander&utm_content=before_you_get_started_lander`}
              >
                Speak with a rep
              </MuiLink>
            </Grid>
          </Grid>
          <div className={classes.imageBackCard}>&nbsp;</div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(BeforeGetStarted);
