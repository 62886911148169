import { useState, useEffect, useRef } from 'react';

const useMutationObserver = () => {
  const [attributes, setAttributes] = useState({ clientWidth: 0, clientHeight: 0 });
  const [observed, setObserved] = useState();
  const observer = useRef();

  useEffect(() => {
    const handleResize = () => {
      setAttributes({
        clientWidth: observed.clientWidth,
        clientHeight: observed.clientHeight
      });
    };
    if (observed) {
      handleResize();
      observer.current = new MutationObserver(handleResize);
      observer.current.observe(observed, {
        childList: true,
        attributes: true,
        subtree: true
      });
    }
    return () => {
      if (observed) observer.current.disconnect();
    };
  }, [observed]);

  return [attributes, setObserved];
};

export default useMutationObserver;
