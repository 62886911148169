export default theme => ({
  container: {
    paddingBottom: 42,
    minHeight: 'calc(100vh - 48px)',
    backgroundColor: '#fafafca',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: { paddingTop: 20 },
    [theme.breakpoints.down('xs')]: { paddingTop: 0 }
  },
  center: {
    paddingTop: 26,
    alignItems: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      minWidth: '1280px'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1120px',
      minWidth: '1120px'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '1024px',
      minWidth: '1024px',
      paddingLeft: 20,
      paddingRight: 20
    },
    [theme.breakpoints.down('sm')]: { minWidth: '340px' }
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    color: '#0f2440',
    marginBottom: 30
  }
});
