import React from 'react';
import { makeStyles, Grid, useMediaQuery } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useAuth } from '../global/Authentication/AuthProvider';
import { imageSrcSet } from '../../helpers/utils';
import BackgroundAdornments from './styles/BackgroundAdornments';
import styles from './styles/DashboardLoggedOut';

const useStyles = makeStyles(styles);

const DashboardLoggedOut = () => {
  const classes = useStyles();
  const isXS = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { login } = useAuth();
  return (
    <div className={classes.main}>
      <Grid container justifyContent="center">
        <Grid item xs={12} align="center" className={classes.logInText}>
          <h1>
            Log in to access your <br /> Dashboard
          </h1>
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            variant="primary"
            size={isXS ? 'small' : 'medium'}
            onClick={() => login()}
            style={{ width: isXS ? 220 : 280 }}
          >
            Log-in to your Account
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item>
              <img
                className={classes.imageDashboard}
                src="images/dashboard/log-out.png"
                srcSet={imageSrcSet('/images/dashboard/', 'log-out')}
                alt="dashboard"
              />
            </Grid>
          </Grid>
        </Grid>
        <BackgroundAdornments />
      </Grid>
    </div>
  );
};

export default DashboardLoggedOut;
