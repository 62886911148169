import React, { useState, useEffect } from 'react';
import { isNumber } from 'util';
import { Table, TableBody, TableRow, TableCell, withStyles } from '@material-ui/core';

import {
  thousandsSeparatedAndFixed,
  sumByQuantityParseInt,
  isPack,
  totalStorageShippingPrice,
  getShippingPrice
} from '../../helpers/utils';

import StorageBreakdownModalContainer from './StorageBreakdownModalContainer';
import { getProductSizes, getStoragePrice } from './common';
import styles from './styles/ShippingBreakDown';

const ProofDetails = ({ classes, proof, quantities, fixedStoragePrice }) => (
  <TableRow key={proof.id}>
    <TableCell style={{ paddingLeft: 0 }} className={classes.itemCellBold}>
      {proof.product.name}
    </TableCell>
    <TableCell className={classes.itemCellRegular}>{quantities}</TableCell>
    <TableCell style={{ paddingLeft: 0 }} className={classes.itemCellRegularPrice}>
      {`$${thousandsSeparatedAndFixed(fixedStoragePrice, 2)}`}
    </TableCell>
    <TableCell className={classes.emptyCell} />
    <TableCell className={classes.totalCell}>
      {`$${thousandsSeparatedAndFixed(fixedStoragePrice * quantities)}`}
    </TableCell>
  </TableRow>
);

const ShippingBreakDownHeader = ({ classes }) => (
  <>
    <TableRow style={{ height: 20 }}>
      <TableCell className={classes.firstHeaderTable}>Item</TableCell>
      <TableCell className={classes.headerTable}>Qty</TableCell>
      <TableCell style={{ paddingLeft: 0 }} className={classes.headerTableStorage}>
        Storage
      </TableCell>
      <TableCell className={classes.emptyCell} />
      <TableCell className={classes.lastHeaderTable}>Total</TableCell>
    </TableRow>
    <TableRow style={{ height: 20 }}>
      <TableCell className={classes.subtitle} />
      <TableCell className={classes.subtitle} />
      <TableCell className={classes.subtitle}>(One time fee per item)</TableCell>
      <TableCell className={classes.emptyCellBottomBorder} />
      <TableCell className={classes.subtitle} />
    </TableRow>
  </>
);

const ShippingBreakDownBody = props => {
  const { proofs, storagePrices, isInternational, storageType, individualPackPrices, classes } = props;

  return proofs.map(proof => {
    const sizes = getProductSizes(proof);
    const quantities = sumByQuantityParseInt(sizes);
    const storagePrice = getStoragePrice(proof.product, storagePrices);
    const fixedStoragePrice = thousandsSeparatedAndFixed(storagePrice);
    const shippingPrice =
      isPack(proof.product.record_type) && !storageType.isPayAsShip
        ? getShippingPrice(isInternational, individualPackPrices)
        : 'TBD';
    const total = totalStorageShippingPrice(quantities, storagePrice, isNumber(shippingPrice) ? shippingPrice : 0);

    return (
      <ProofDetails
        key={proof.id}
        classes={classes}
        proof={proof}
        quantities={quantities}
        fixedStoragePrice={fixedStoragePrice}
        shippingPrice={shippingPrice}
        total={total}
      />
    );
  });
};

const ShippingBreakDownFooter = ({ classes, storageTotal, onClose }) => (
  <TableRow>
    <TableCell className={classes.questionTex}>
      <div className={classes.whyBeingCharged} role="presentation" onClick={onClose}>
        Why am I being charged this?
      </div>
    </TableCell>
    <TableCell className={classes.subTotalLabel} />
    <TableCell className={classes.subTotalLabel}> Sub total</TableCell>
    <TableCell className={classes.emptyCellTopBorder} />
    <TableCell className={classes.subTotal}>{`$${thousandsSeparatedAndFixed(storageTotal)}`}</TableCell>
  </TableRow>
);

const ShippingBreakDown = ({
  classes,
  storageType,
  storagePrices,
  individualPackPrices,
  proofs,
  onClose,
  openModal,
  isInternational
}) => {
  const [subTotal, setSubTotal] = useState(0);
  const [storageTotal, setStorageTotal] = useState(0);
  const [shippingTotal, setShippingTotal] = useState(0);

  useEffect(() => {
    let subTotalTemp = 0;
    let storageTotalTemp = 0;
    let shippingTotalTemp = 0;

    proofs.forEach(proof => {
      const sizes = getProductSizes(proof);
      const quantities = sumByQuantityParseInt(sizes);
      const storagePrice = getStoragePrice(proof.product, storagePrices);
      const shippingPrice =
        isPack(proof.product.record_type) && !storageType.isPayAsShip
          ? getShippingPrice(isInternational, individualPackPrices)
          : 'TBD';
      const total = totalStorageShippingPrice(quantities, storagePrice, isNumber(shippingPrice) ? shippingPrice : 0);
      subTotalTemp += total;
      storageTotalTemp += storagePrice * quantities;
      shippingTotalTemp += shippingPrice * quantities;
    });

    setSubTotal(subTotalTemp);
    setStorageTotal(storageTotalTemp);
    setShippingTotal(shippingTotalTemp);
  }, [proofs]);

  return (
    <div className={classes.breakdownTable}>
      <Table>
        <TableBody>
          <ShippingBreakDownHeader classes={classes} isInternational={isInternational} />

          <ShippingBreakDownBody
            proofs={proofs}
            storagePrices={storagePrices}
            isInternational={isInternational}
            storageType={storageType}
            individualPackPrices={individualPackPrices}
            classes={classes}
          />

          <ShippingBreakDownFooter
            classes={classes}
            subTotal={subTotal}
            storageTotal={storageTotal}
            shippingTotal={shippingTotal}
            onClose={onClose}
          />
        </TableBody>
      </Table>
      <StorageBreakdownModalContainer open={openModal} onClose={onClose} />
    </div>
  );
};

export default withStyles(styles)(ShippingBreakDown);
