import { SELECT_ORDER } from '../actions/types';

const SelectedOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECT_ORDER:
      return action.payload;

    default:
      return state;
  }
};

export default SelectedOrderReducer;
