const styles = () => ({
  dialog: {
    maxWidth: 702,
    maxHeight: 500
  },
  Title: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#434c5f'
  },
  Question: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#434c5e',
    marginBottom: 25
  },
  ItemSizePrice: { fontFamily: 'Roboto', fontSize: 16, fontWeight: 'bold', color: '#434c5f' },
  RegularItemText: {
    opacity: 0.54,
    fontFamily: 'Roboto',
    fontSize: 11,
    color: '#434c5e'
  },
  ShippingTitle: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: '#434c5f',
    marginBottom: 13
  },
  Or: {
    opacity: 0.54,
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 'normal',
    margin: '30px auto'
  },
  Plus: {
    margin: '30px auto',
    color: '#1d1d1d',
    opacity: 0.54
  }
});

export default styles;
