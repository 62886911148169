const commonText = {
  fontWeight: 400,
  letterSpacing: 'normal'
};

export default {
  cardContainer: {
    padding: 6,
    width: 202
  },
  card: {
    width: '100%',
    border: 'solid 1px #f0f2f5',
    borderRadius: 15,
    height: 220,
    transition: 'all 0.2s ease-in',
    overflow: 'hidden',
    '&.unmatched': {
      border: 'solid 1px #f44336'
    },
    '&:hover': {
      boxShadow: '0 16px 36px 0 rgba(0, 0, 0, 0.05)',
      height: 264
    },
    '&:hover p[class*="rowText"]:last-of-type': {
      borderBottomColor: '#f0f2f5'
    }
  },
  statusContainer: {
    padding: 6,
    borderBottom: 'solid 1px #f0f2f5'
  },
  rowsContainer: {
    padding: '0 20px'
  },
  rowText: {
    ...commonText,
    color: '#0b1829',
    fontSize: 12,
    lineHeight: 0.83,
    padding: '16px 0',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    borderBottom: 'solid 1px #f0f2f5',
    whiteSpace: 'nowrap'
  },
  ignoreBtn: {
    ...commonText,
    color: '#868a8f',
    fontSize: 12,
    lineHeight: 0,
    padding: 0,
    height: 'unset',
    fontWeight: 'unset',
    '&:hover': {
      background: 'transparent'
    },
    '& svg': {
      fontSize: 14,
      marginLeft: 8
    }
  }
};
