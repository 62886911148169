import React from 'react';
import { makeStyles, Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Scrollable from 'react-scrollbars-custom';
import { ErrorCounter } from './ErrorCounter';
import styles from './styles/ContactsTable';
import { useSizes } from '../../../hooks';

const Header = ({ classes, amountOfContacts, amountSelectedInPage, onToggleSelectAll }) => (
  <TableHead>
    <TableRow>
      <TableCell className="checkboxCell">
        <Checkbox
          color="primary"
          classes={{ root: classes.checkbox }}
          checked={amountSelectedInPage > 0}
          indeterminate={amountSelectedInPage > 0 && amountSelectedInPage < amountOfContacts}
          onChange={onToggleSelectAll}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </TableCell>
      <TableCell className="statusCell" />
      <TableCell>FIRST NAME</TableCell>
      <TableCell>LAST NAME</TableCell>
      <TableCell>EMAIL</TableCell>
      <TableCell>PHONE NUMBER</TableCell>
      <TableCell>STREET ADDRESS</TableCell>
      <TableCell>CITY</TableCell>
      <TableCell>STATE</TableCell>
      <TableCell>ZIP CODE</TableCell>
      <TableCell>COUNTRY</TableCell>
      <TableCell>SHIRT SIZE</TableCell>
    </TableRow>
  </TableHead>
);

const getSizeId = (name, sizes) => sizes.find(size => size.name === name)?.id || '';

const ValueCell = ({ value, error }) => (
  <TableCell className={error ? 'error' : undefined}>{!value && error ? 'Required' : value}</TableCell>
);

const Body = ({ classes, contacts, selectedContacts, sizes, onEdit, onToggleSelect }) => (
  <TableBody>
    {contacts.map(c => {
      const data = JSON.parse(c.input_json);
      const errors = c.output_json ? JSON.parse(c.output_json) : {};
      const amountOfErrors = Object.keys(errors).length;
      const contactData = {
        ...data,
        id: c.id,
        size: getSizeId(data.size, sizes),
        errors
      };
      return (
        <TableRow key={c.id} hover onClick={e => onEdit(e, contactData)}>
          <TableCell className="checkboxCell">
            <Checkbox
              color="primary"
              classes={{ root: classes.checkbox }}
              name={c.id.toString()}
              checked={selectedContacts.has(c.id)}
              onChange={onToggleSelect}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </TableCell>
          <TableCell className="statusCell">
            {amountOfErrors ? (
              <ErrorCounter count={amountOfErrors} />
            ) : (
              <CheckCircleIcon viewBox="2 2 20 20" className={classes.okIndicator} />
            )}
          </TableCell>
          <ValueCell value={data.first_name} error={errors.first_name} />
          <ValueCell value={data.last_name} error={errors.last_name} />
          <ValueCell value={data.email} error={errors.email} />
          <ValueCell value={data.phone_number} error={errors.phone_number} />
          <ValueCell value={data.shipping_address1} error={errors.shipping_address1 || errors.address_validation} />
          <ValueCell value={data.shipping_city} error={errors.shipping_city} />
          <ValueCell value={data.shipping_state} error={errors.shipping_state} />
          <ValueCell value={data.shipping_zip} error={errors.shipping_zip} />
          <ValueCell value={data.shipping_country} error={errors.shipping_country} />
          <ValueCell value={data.size} error={errors.size} />
        </TableRow>
      );
    })}
  </TableBody>
);

const useStyles = makeStyles(styles);

const ContactsTable = ({
  contacts,
  amountSelectedInPage,
  selectedContacts,
  onEdit,
  onToggleSelect,
  onToggleSelectAll
}) => {
  const classes = useStyles();
  const { data: sizes } = useSizes();

  return (
    <Scrollable className={classes.scrollableSection}>
      <Table className={classes.table} stickyHeader>
        <Header
          classes={classes}
          amountOfContacts={contacts.length}
          amountSelectedInPage={amountSelectedInPage}
          onToggleSelectAll={onToggleSelectAll}
        />
        <Body
          classes={classes}
          contacts={contacts}
          selectedContacts={selectedContacts}
          sizes={sizes}
          onEdit={onEdit}
          onToggleSelect={onToggleSelect}
        />
      </Table>
    </Scrollable>
  );
};

export default ContactsTable;
