import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from './FAQs.styles';
import { CenteredGrid } from '../../../shared';

const questions = [
  {
    question: 'What’s the turnaround time for packs?',
    answer:
      'Please be aware, due to COVID-19 and the stress being placed on supply chains, we are unable to guarantee turnaround times at this moment. We estimate new orders will be ready to ship within 4 weeks. Thank you for your understanding.'
  },
  {
    question: 'What’s the minimum order?',
    answer: 'Typically 50 packs, however there are some items we can go lower (25 units)'
  },
  {
    question: 'Do you ship globally?',
    answer: 'Yes, we’ve shipped to over 100 countries'
  },
  {
    question: 'Do you offer warehousing and fulfillment?',
    answer: 'Yes, for a flat $4 per pack, one time fee'
  },
  {
    question: 'Do you do items outside the ones shown in the pack builder?',
    answer: 'Yes, our pack builder is meant to be curated but we have hundreds of more options'
  },
  {
    question: 'Can we send you our existing swag to include?',
    answer: 'Yes'
  },
  {
    question: 'Do you have an API?',
    answer:
      'Yes, we have an Open API as well as a Zapier and Shopify App. We are building out more integrations as well'
  }
];

const FAQs = ({ classes }) => (
  <CenteredGrid container justifyContent="center" className={classes.container}>
    <h2 className={classes.title}>Swag Pack FAQs</h2>
    <div className={classes.questions}>
      {questions.map(({ question, answer }, index) => (
        <React.Fragment key={question}>
          <Grid align="left" item xs={12} key={question}>
            <p className={classes.questionText}>{question}</p>
            <p>
              <span className={classes.questionText}>A: </span>
              <span className={classes.answerText}>{answer}</span>
            </p>
          </Grid>
          {index !== questions.length - 1 && <hr className={classes.separator} />}
        </React.Fragment>
      ))}
    </div>
  </CenteredGrid>
);

export default withStyles(styles)(FAQs);
