import { ellipsisStyles } from '../../../shared/styles/commonStyles';

const styles = theme => ({
  container: {
    borderRadius: 16,
    padding: '12px 16px',
    border: '1px solid #EBEDF0',
    marginBottom: 16
  },
  itemImageContainer: {
    position: 'relative',
    width: 62,
    height: 62,
    border: '1px solid #EBEDF0',
    borderRadius: 4,
    padding: 2
  },
  disconnectButton: {
    height: 42,
    '&:hover': {
      background: 'rgba(53, 119, 212, 0.08)'
    }
  },
  itemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  automationItemButton: {
    minWidth: 212
  },
  automationItemEditButton: {
    minWidth: 112
  },
  editIconButton: {
    fontSize: 16,
    color: '#3577d4',
    '& span': {
      marginRight: 8
    }
  },
  editIcon: {
    fontSize: 16,
    marginBottom: 3,
    marginLeft: 6
  },
  sectionTitle: {
    color: '#131415',
    fontSize: 16,
    marginBottom: 12
  },
  itemImageContainer: {
    position: 'relative',
    width: 68,
    height: 68
  },
  itemImageContainerSettings: {
    position: 'relative',
    width: 92,
    height: 92,
    border: '1px solid #EBEDF0',
    borderRadius: 4,
    padding: 2
  },
  actionImageContainer: {
    position: 'relative',
    width: 68,
    height: 68
  },
  itemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  settingsNameBox: {
    paddingLeft: 24
  },
  productName: {
    color: '#131415',
    maxWidth: 336,
    marginBottom: 8,
    ...ellipsisStyles,
    '& span': {
      color: '#4A4F54',
      fontWeight: 400
    }
  },
  productNameSummary: {
    color: '#131415',
    maxWidth: 216,
    marginBottom: 8,
    ...ellipsisStyles,
    '& span': {
      color: '#4A4F54',
      fontWeight: 400
    }
  },
  productTag: {
    background: '#D6D8DB',
    padding: '8px 16px',
    color: '#4A4F54',
    borderRadius: 16,
    width: 'fit-content'
  },
  finishDivider: {
    margin: '0px 12px 12px 12px',
    height: 1,
    background: '#EBEDF0'
  },
  sectionTitleBoxWrapper: {
    padding: '8px 12px',
    height: '100%'
  },
  sectionTitleBox: {
    border: '1px solid #EBEDF0',
    padding: '12px 16px',
    borderRadius: 12,
    height: '100%'
  }
});

export default styles;
