import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, makeStyles, Snackbar, Table, TableBody, TableContainer } from '@material-ui/core';
import ShipmentAlert from '@material-ui/lab/Alert';
import { Button, Typography } from '@swagup-com/components';
import {
  SaveAlt,
  CheckCircle as CheckIcon,
  CancelRounded as CancelIcon,
  ReplayOutlined,
  Replay,
  BlockOutlined,
  Block,
  Delete,
  DeleteOutline,
  ArrowBack
} from '@material-ui/icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import isUndefined from 'lodash/isUndefined';
import _, { isEmpty, min } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { CenteredGrid, Pagination } from '../../shared';
import styles from '../styles/storefrontsHome';
import {
  getPageLink,
  RedemptionDeleteModal,
  OrderRow,
  StoreDashboardCards,
  TableEmptyState,
  TableHeaderOrder
} from '../storeCommon';
import { downloadData, moneyStr, paginationRequestConverter } from '../../../helpers/utils';
import StoreFilterSection from '../StoreFilterSection';
import { StylessButton } from '../../buttons';
import storefrontServicesPaths from '../../../helpers/storefrontsServicesPaths';
import { storefronts, orders } from '../../../apis/storefrontsServices';
import { useCreditSummary, usePaginatedQuery, useQueryParams } from '../../../hooks';
import { useQueryFilterValidated } from '../../../hooks/useFilters';
import apiPaths from '../../../helpers/apiPaths';
import CreditDrawer from '../components/CreditCardDrawer/CreditDrawer';
import ActionBar from '../components/ActionBar/ActionBar';
import ShipmentDetailsModal from '../ShipmentDetailsModal';
import SpinningSnackBar from '../components/SpinningSnackBar/SpinningSnackBar';
import Loader from '../../global/Loader';
import usePageProductBasedInfo from '../hooks/usePageProductBasedInfo';
import { OrderDetails } from './OrderDetails';
import SearchSortFilter from '../../shared/SearchSortFilter';

const useStyles = makeStyles(styles);

const shipmentAPIStatuses = [
  'Scheduled',
  'On Its Way',
  'Delivered',
  'Failure',
  'Return To Sender',
  'Invalid Address',
  'Cancelled',
  'Pending Production',
  'Out Of Stock',
  'Insufficient Credit',
  'Unexpected Error'
];

const storefrontStatuses = ['Ordered', 'Declined'];

const StoresOrdersTab = ({ store, searchParam, storeSummary, isLoadingSummary }) => {
  const classes = useStyles();

  const perPageOptions = [10, 20, 30, 40];

  const [openCreditDrawer, setOpenCreditDrawer] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderToDelete, setRedemptionToDelete] = useState();
  const [wasSuccessful, setWasSuccessful] = useState({ was: false, what: 'scheduled', who: 'Shipment' });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [multipleRetries, setMultipleRetries] = useState(0);
  const [selectedShipmentStatus, setSelectedShipmentStatus] = useState([]);
  const [customProcessingMessage, setCustomProcessingMessage] = useState();
  const [orderDetailsId, setOrderDetailsId] = useState(0);
  const [detailsIndex, setDetailsIndex] = useState();

  useEffect(() => {
    setDetailsIndex(!orderDetailsId ? 0 : 1);
  }, [orderDetailsId]);

  const history = useHistory();
  const { id } = useParams();

  const company = useSelector(state => state.company);

  const { data: storefrontQuery, isLoading } = useQuery(
    storefrontServicesPaths.storefront(id),
    () => storefronts.get(id),
    {
      enabled: !!id
    }
  );

  const getCustomFilter = (summary, collectionOnly, uniqueUrl) => {
    let statuses = [];
    switch (true) {
      case !collectionOnly && uniqueUrl:
        statuses = ['Declined', ...shipmentAPIStatuses];
        break;
      case !collectionOnly && !uniqueUrl:
        statuses = shipmentAPIStatuses;
        break;
      case collectionOnly && uniqueUrl:
        statuses = storefrontStatuses;
        break;
      default:
        statuses = ['Ordered'];
    }
    return summary
      ? [
          {
            name: 'shipment_status',
            label: 'Shipping Status',
            behavior: 'multiselect',
            options: statuses.reduce((sum, s) => ({ ...sum, [s]: s }), {})
          },
          {
            name: 'item',
            label: 'Product Selected',
            behavior: 'multiselect',
            options: summary.selectedProducts.reduce((sum, p) => ({ ...sum, [p.productId]: p.name }), {})
          }
        ].filter(f => !isEmpty(f.options))
      : undefined;
  };

  // const summaryData = summaryCall?.data;
  // const summary = summaryData ? sortSummarySizes(summaryData) : summaryData;
  const customFilters = getCustomFilter(undefined, store.collectionOnly, store.uniqueUrl);
  const query = useQueryParams();
  const search = query.get(searchParam) || '';
  const isSearching = !!search;
  const item = useQueryFilterValidated('item');
  const size = useQueryFilterValidated('size', val =>
    ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', 'One Size', 'XXS'].includes(val)
  );
  const shipmentStatus = useQueryFilterValidated('shipment_status', val =>
    [...storefrontStatuses, ...shipmentAPIStatuses].includes(val)
  );

  const ordering = useQueryFilterValidated(
    'ordering',
    (xid, value) => ['-created_at', 'created_at'].includes(xid) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const {
    query: { data: ordersResults, isFetching: loadingHistory },
    pagination
  } = usePaginatedQuery({
    queryKey: [storefrontServicesPaths.orders(store.id), search, item, size, ordering, shipmentStatus],
    queryFn: (limit, offset) => {
      return store.id
        ? orders.list(
            store.id,
            paginationRequestConverter({ limit, offset, search, item, size, ordering, shipmentStatus })
          )
        : [];
    },
    perPageOptions
  });

  const storeOrderItems = ordersResults?.results || [];

  const queryClient = useQueryClient();

  const contactExport = useMutation(
    customQuery => storefronts.export(store.id, customQuery || { search, item, size, shipmentStatus }),
    {
      onSuccess: ({ data }) => {
        downloadData(`${store.projectName}.csv`, data);
      }
    }
  );

  const { data: orderDetailsAPI, isLoading: isLoadingDetails } = useQuery(
    [storefrontServicesPaths.order(store.id, orderDetailsId)],
    () => orders.get(store.id, orderDetailsId),
    {
      enabled: !!orderDetailsId
    }
  );

  const orderDetails = orderDetailsAPI || {};

  const exportContact = e => {
    e.stopPropagation();
    contactExport.mutate();
  };

  const showPagination = pagination?.count > min(pagination?.sizeOptions);

  const onReload = () => {
    queryClient.invalidateQueries(apiPaths.creditsSummaries);
    queryClient.invalidateQueries([storefrontServicesPaths.orders(store.id)]);
    queryClient.invalidateQueries([storefrontServicesPaths.summary(store?.id)]);
    const accountProductsParams = {
      ids: store.productOptions?.map(p => p.productId).join()
    };
    queryClient.invalidateQueries([apiPaths.accountProducts, accountProductsParams]);

    setSelectedItems([]);
    setShowSnackbar(true);
  };

  const retryStoreShipment = useMutation(orderIdList => orders.retry(store.id, { orders: orderIdList }), {
    onSuccess: res => {
      onReload();
      if (res?.failedRedemptions?.length > 0) {
        setSelectedShipmentStatus(res.failedRedemptions);
        setWasSuccessful({ was: false, what: 'scheduled', who: 'Shipment' });
      } else {
        setWasSuccessful({ was: true, what: 'scheduled', who: 'Shipment' });
      }
    }
  });

  const cancelAPIShipments = useMutation(orderIdList => orders.cancel(store.id, { orders: orderIdList }), {
    onSuccess: res => {
      onReload();
      if (res?.failedRedemptions?.length > 0) {
        setSelectedShipmentStatus(res.failedRedemptions);
        setWasSuccessful({ was: false, what: 'canceled', who: 'Shipment' });
      } else {
        setWasSuccessful({ was: true, what: 'canceled', who: 'Shipment' });
      }
    }
  });

  const toogleSelectedItems = sitem =>
    setSelectedItems(prev =>
      prev.find(it => it.id === sitem.id) ? prev.filter(it => it.id !== sitem.id) : [...prev, sitem]
    );

  const toogleSelectAll = () =>
    setSelectedItems(prev => (prev.length !== storeOrderItems.length ? storeOrderItems.map(r => r) : []));

  const retryShipments = orderIdList => {
    setMultipleRetries(orderIdList.length);
    retryStoreShipment.mutate(orderIdList);
  };

  const retryShipment = r => {
    if (r) return retryShipments([r.id]);
    const orderIdList = selectedItems.map(s => s.id);
    return retryShipments(orderIdList);
  };

  const onDeleteRedemption = r => {
    setRedemptionToDelete(r);
  };

  const cancelShipments = shipmentList => {
    cancelAPIShipments.mutate(shipmentList);
  };

  const cancelShipment = r => {
    if (r) return cancelShipments([r.id]);
    const orderIdList = selectedItems.map(s => s.id);
    return cancelShipments(orderIdList);
  };

  const triggerCopyNotifications = text => {
    setShowSnackbar(true);
    setWasSuccessful(prev => ({ ...prev, was: true, custom: text }));
  };

  const generateUniqueUrl = uniqueUrlId => (uniqueUrlId ? `${getPageLink(store)}/${uniqueUrlId}` : undefined);

  const exportUnstorefrontedContact = e => {
    e.stopPropagation();
    contactExport.mutate({ shipmentStatus: 'Unstorefronted' });
  };

  const closeErrorMessageSnackbar = () => {
    setSelectedShipmentStatus([]);
    setShowSnackbar(false);
  };

  const redirectHelpCenter = () => {
    setShowSnackbar(false);
    window.open('https://support.swagup.com/en', '_blank');
  };

  const showCreditReloadDrawer = () => {
    setShowSnackbar(false);
    setOpenCreditDrawer(true);
  };

  const getFailedRedemptionMessage = () => {
    switch (selectedShipmentStatus.length && selectedShipmentStatus[0].failedRedemptionStatus) {
      case 'Unexpected Error':
        return (
          <>
            Shipment failed. Contact{' '}
            <button type="button" onClick={() => redirectHelpCenter()} className={classes.drawerLink}>
              customer support
            </button>
            .
          </>
        );
      case 'Out Of Stock':
        return (
          <>
            Hmm, looks like you’re missing some{' '}
            <button type="button" onClick={() => false} className={classes.drawerLink}>
              inventory
            </button>
            .
          </>
        );
      case 'Insufficient Credit':
        return (
          <>
            Oops, looks like you’re low on{' '}
            <button type="button" onClick={() => showCreditReloadDrawer()} className={classes.drawerLink}>
              credit
            </button>
            .
          </>
        );
      default:
        return null;
    }
  };

  const regularMessaging = () =>
    multipleRetries === 1 ? getFailedRedemptionMessage() : 'Please address the following';

  const updateStore = useMutation(changes => storefronts.update(store.id, changes), {
    onSuccess: (req, param) => {
      queryClient.invalidateQueries([storefrontServicesPaths.storefronts, company.id]);
      queryClient.invalidateQueries([storefrontServicesPaths.orders(store.id)]);
      setStore(prevStore => ({ ...prevStore, ...param }));
      setCustomProcessingMessage();
      if (param.uniqueUrl && param.uniqueUrlNumber > 0) {
        setWasSuccessful({ was: true, what: 'created', who: `${param.uniqueUrlNumber} unique` });
        setShowSnackbar(true);
      }
    }
  });

  const { data: creditSummary } = useCreditSummary();
  const { selectedProducts, recommendedCredits } = usePageProductBasedInfo(store);

  const onDeleteRedemptionClose = () => {
    setRedemptionToDelete();
  };

  const deletePageRedemption = useMutation(orderIdList => orders.delete(store.id, { orders: orderIdList }), {
    onSuccess: () => {
      queryClient.invalidateQueries([storefrontServicesPaths.orders(store.id)]);
      if (store.collectionOnly) queryClient.invalidateQueries([storefrontServicesPaths.summary(store?.id)]);
      setSelectedItems([]);
    }
  });

  const deleteRedemptions = orderIdList => {
    deletePageRedemption.mutate(orderIdList);
  };

  const deleteRedemption = r => {
    onDeleteRedemptionClose();
    if (r && !_.isArray(r)) return deleteRedemptions([r.id]);
    const orderIdList = selectedItems.map(s => s.id);
    return deleteRedemptions(orderIdList);
  };

  const getProcessingMessage = () => {
    switch (true) {
      case cancelAPIShipments.isLoading:
        return `Canceling your shipment${selectedItems.length > 0 ? 's' : ''}`;
      case retryStoreShipment.isLoading:
        return multipleRetries === 1 ? 'Scheduling your shipment' : `Scheduling ${multipleRetries} shipments`;
      case deletePageRedemption.isLoading:
        return 'Deleting...';
      case isSearching:
        return 'Searching...';
      case !!customProcessingMessage:
        return customProcessingMessage;
      default:
        return 'Loading...';
    }
  };

  const searchSortFilterConfig = {
    search: { placeholder: 'Order', queryParam: 'search_orders' },
    sort: { options: { '-created_at': 'Most Recent', created_at: 'Less Recent' } }
  };

  return (
    <Box>
      <CenteredGrid>
        <Grid container alignItems="center">
          <Grid item>
            {!detailsIndex ? (
              <Typography variant="h2BoldInter">Orders</Typography>
            ) : (
              <Typography variant="h2BoldInter" className={classes.tabTitle}>
                Order / <span>{orderDetails.orderNumber}</span>
              </Typography>
            )}
          </Grid>
          <Grid item xs />
          {detailsIndex > 0 && (
            <Grid item>
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setDetailsIndex(0);
                  setOrderDetailsId();
                }}
              >
                <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <ArrowBack style={{ height: 16, width: 16, marginRight: 6 }} />
                  <Typography variant="body4RegularInter" style={{ color: '#3577D4', fontWeight: 600, fontSize: 16 }}>
                    Back to Orders
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          )}
        </Grid>
      </CenteredGrid>
      <SwipeableViews axis="x" index={detailsIndex} className={classes.swipeableViews} disabled>
        <Box>
          <SearchSortFilter config={searchSortFilterConfig} />
          <CenteredGrid>
            {storeOrderItems?.length > 0 && (
              <>
                <Grid container className={classes.historyStatusContainer}>
                  <Grid item>
                    <Typography variant="body3RegularInter" className={classes.historyStatus}>
                      Total Count: {storeSummary.totalOrders}
                    </Typography>
                  </Grid>
                  <Grid xs item />
                  <Grid item>
                    {!store.collectionOnly && (
                      <StylessButton>
                        <Typography variant="body3RegularInter" className={classes.historyStatus}>
                          Total Sales: {moneyStr(storeSummary.totalSales)}
                        </Typography>
                      </StylessButton>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            <div style={{ position: 'relative' }}>
              <div className={classes.tableContainer} style={{ height: `calc(100% - ${showPagination ? 42 : 0}px)` }}>
                {storeOrderItems?.length === 0 && !loadingHistory ? (
                  <TableEmptyState isSearching={isSearching} />
                ) : (
                  <TableContainer>
                    <Table stickyHeader className={classes.orderTable}>
                      <TableHeaderOrder
                        classes={classes}
                        toogleSelectAll={toogleSelectAll}
                        allSelected={storeOrderItems.length === selectedItems.length}
                        isCollection={store.collectionOnly}
                      />
                      <TableBody>
                        {storeOrderItems?.map(order => (
                          <OrderRow
                            key={order.id}
                            order={order}
                            customAction={() => false}
                            isCollection={store.collectionOnly}
                            toogleSelectedItems={toogleSelectedItems}
                            onShowOrderDetails={id => setOrderDetailsId(id)}
                            onRetryShipment={retryShipment}
                            onDeleteRedemption={onDeleteRedemption}
                            onCancelShipment={cancelShipment}
                            selectedShipmentStatus={selectedShipmentStatus}
                            isSelected={!!selectedItems.find(s => s.id === order.id)}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
              {showPagination && (
                <Grid container alignItems="center" className={classes.paginationContainer}>
                  <Pagination {...pagination} startText="Show" endText="orders" buttonClass={classes.paginationBtn} />
                </Grid>
              )}
            </div>
          </CenteredGrid>
        </Box>
        <OrderDetails
          orderId={orderDetailsId}
          orderDetails={orderDetails}
          isPreorder={!store?.generalSettings?.stopSellingOutOfStock}
        />
      </SwipeableViews>

      <Snackbar
        className={classes.snackbarContainer}
        open={showSnackbar}
        autoHideDuration={wasSuccessful.was ? 3000 : null}
        onClose={() => {
          setShowSnackbar(false);
          if (!wasSuccessful.was) closeErrorMessageSnackbar();
        }}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        {wasSuccessful.was ? (
          <ShipmentAlert
            className={classes.successShipmentAlert}
            icon={<CheckIcon style={{ color: '#2E7D32', fontSize: '20px' }} />}
            variant="outlined"
            severity="success"
          >
            {wasSuccessful.custom ? wasSuccessful.custom : `${wasSuccessful.who} successfully ${wasSuccessful.what}`}
          </ShipmentAlert>
        ) : (
          <ShipmentAlert
            onClose={() => {
              closeErrorMessageSnackbar();
            }}
            className={classes.failureShipmentAlert}
            icon={<CancelIcon style={{ color: '#C62828', fontSize: '20px' }} />}
            variant="outlined"
            severity="error"
          >
            {wasSuccessful.what === 'canceled'
              ? `Cancelation${multipleRetries > 1 ? '' : 's'} failed`
              : regularMessaging()}
          </ShipmentAlert>
        )}
      </Snackbar>
      <CenteredGrid>
        <CreditDrawer
          open={openCreditDrawer}
          credit={creditSummary.current_balance}
          onClose={() => setOpenCreditDrawer(false)}
          company={company}
          history={history}
          recommendedCredits={recommendedCredits}
        />
        <ActionBar
          open={selectedItems.length > 0 && isUndefined(orderToDelete)}
          totalItemSelected={selectedItems.length}
          handleClose={() => setSelectedItems([])}
          type="order"
          actions={[
            {
              text: 'Retry',
              tooltip: 'Only Insufficient Credit, Failure and Out Of Stock statuses can retry for shipment.',
              action: () => retryShipment(),
              disabled: selectedItems.some(
                r =>
                  r?.customerInformation?.id < 0 ||
                  !['Out Of Stock', 'Insufficient Credit', 'Failure'].includes(r.shippingInformation?.shipment?.status)
              ),
              baseIcon: ReplayOutlined,
              hoverIcon: Replay
            },
            {
              text: 'Cancel',
              tooltip: 'You can only Cancel Shipments in the Scheduled and Pending Production status',
              action: () => cancelShipment(),
              disabled: selectedItems.some(
                r =>
                  !['Pending Production', 'Scheduled', 'Out Of Stock', 'Insufficient Credit'].includes(
                    r.shippingInformation?.shipment?.status
                  ) ||
                  r.orderStatus === 'Refunded' ||
                  r.payoutId > 0
              ),
              baseIcon: BlockOutlined,
              hoverIcon: Block
            }
          ]}
        />
        <RedemptionDeleteModal
          open={!isUndefined(orderToDelete)}
          onDelete={() => deleteRedemption(orderToDelete)}
          onClose={onDeleteRedemptionClose}
          order={orderToDelete}
        />
        <SpinningSnackBar
          open={
            cancelAPIShipments.isLoading ||
            retryStoreShipment.isLoading ||
            deletePageRedemption.isLoading ||
            loadingHistory ||
            isLoadingDetails ||
            (updateStore.isLoading && customProcessingMessage)
          }
          message={getProcessingMessage(customProcessingMessage)}
        />
        {isLoading && isLoadingSummary && <Loader absolute />}
      </CenteredGrid>
    </Box>
  );
};

export default StoresOrdersTab;
