import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles/CardContainer';

const CardContainer = ({ classes, children }) => (
  <Grid container className={classes.orderCard} spacing={1} justifyContent="space-between">
    {children}
  </Grid>
);

export default withStyles(styles)(CardContainer);
