import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { StylessButton } from '../buttons';
import styles from './styles/onboarding';

class OnboardingSelector extends Component {
  state = { selected: undefined, text: '' };

  onActionHandler(item, target) {
    const { handleChange, property } = this.props;
    const trimmedValue = target.value?.trim();
    if (item.props.attachmentProperty)
      handleChange({
        target: {
          id: property,
          value: trimmedValue ? `${item.key}/${trimmedValue}` : undefined
        }
      });
    else handleChange({ target: { id: target.id, value: trimmedValue }, extraValidation: item.props.validation });
    this.setState({ text: trimmedValue });
  }

  getIfSmall = (small, aOption, bOption) => (small ? aOption : bOption);

  getShortText = text => (text ? text.substring(0, 12) : text);

  render() {
    const {
      classes,
      items,
      handleChange,
      property,
      spacing,
      xs,
      wide,
      attachmentProperty,
      attachmentPlaceholder,
      errors
    } = this.props;
    const { selected, text } = this.state;
    const itemStyle =
      wide || attachmentProperty || items.find(item => item.props.attachmentProperty)
        ? {
            height: 'auto',
            border: '1px solid #ebeef2',
            paddingBottom: attachmentProperty || items.find(item => item.props.attachmentProperty) ? 20 : 12,
            paddingTop: wide ? 16 : 0
          }
        : { paddingBottom: 16 };
    const containerStyle =
      selected && items.find(item => item.props.attachmentProperty && item.key === selected)
        ? undefined
        : { paddingBottom: 68 };
    const errorMessage = errors?.[attachmentProperty || property];
    return (
      <Grid
        container
        spacing={spacing || 4}
        className={classes.oSelectorContent}
        alignItems="stretch"
        alignContent="stretch"
        style={containerStyle}
      >
        {items.map(item => (
          <Grid key={item.key} item xs={xs || 3}>
            <StylessButton
              onClick={() => {
                if (!text || selected !== item.key) {
                  handleChange({
                    target: { id: property, value: item.props.attachmentProperty ? undefined : item.key }
                  });
                  this.setState({ selected: item.key });
                  if (attachmentProperty)
                    handleChange({
                      target: { id: attachmentProperty, value: undefined },
                      extraValidation: item.props.validation
                    });
                }
              }}
              height="100%"
              width="100%"
              className={classes.onboardingSelectorButton}
            >
              <div
                className={
                  selected === item.key ? classes.onboardingSelectorItemSelected : classes.onboardingSelectorItem
                }
                style={itemStyle}
              >
                {wide ? (
                  <Grid container alignItems="center" justifyContent="center" alignContent="center">
                    <Grid item md="auto" sm={12} xs={12}>
                      <div className={classes.onboardingSelectorImgWide}>{item}</div>
                    </Grid>
                    <Grid md sm={12} xs={12} item>
                      <p
                        className={
                          selected === item.key
                            ? this.getIfSmall(
                                item.props.small,
                                classes.onboardingSelectorItemSelectedTextSmallWide,
                                classes.onboardingSelectorItemSelectedTextWide
                              )
                            : this.getIfSmall(
                                item.props.small,
                                classes.onboardingSelectorItemTextSmallWide,
                                classes.onboardingSelectorItemTextWide
                              )
                        }
                        style={{
                          fontWeight: 'normal',
                          margin: 0,
                          paddingRight: 0
                        }}
                      >
                        {item.props.name || item.key}
                      </p>
                      {(attachmentProperty || item.props.attachmentProperty) && selected === item.key && (
                        <>
                          <input
                            id={attachmentProperty}
                            placeholder={attachmentPlaceholder || item.props.attachmentPlaceholder || 'Text here'}
                            className={clsx(classes.inputSearchSelector, errorMessage && classes.inputSearchError)}
                            onChange={({ target }) => this.onActionHandler(item, target)}
                            onBlur={({ target }) => this.onActionHandler(item, target)}
                            error={errorMessage}
                          />
                          {errorMessage && (
                            <p className={classes.selctorErrorText}>{this.getShortText(errorMessage)}</p>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <div className={classes.onboardingSelectorImg}>{item}</div>
                    <p
                      className={
                        selected === item.key
                          ? this.getIfSmall(
                              item.props.small,
                              classes.onboardingSelectorItemSelectedTextSmall,
                              classes.onboardingSelectorItemSelectedText
                            )
                          : this.getIfSmall(
                              item.props.small,
                              classes.onboardingSelectorItemTextSmall,
                              classes.onboardingSelectorItemText
                            )
                      }
                    >
                      {item.props.name || item.key}
                    </p>
                    {(attachmentProperty || item.props.attachmentProperty) && selected === item.key && (
                      <>
                        <input
                          id={attachmentProperty}
                          placeholder={attachmentPlaceholder || item.props.attachmentPlaceholder || 'Text here'}
                          className={clsx(classes.inputSearchSelector, errorMessage && classes.inputSearchError)}
                          onChange={({ target }) => this.onActionHandler(item, target)}
                          onBlur={({ target }) => this.onActionHandler(item, target)}
                          error={errorMessage}
                        />
                        {errorMessage && <p className={classes.selctorErrorText}>{this.getShortText(errorMessage)}</p>}
                      </>
                    )}
                  </>
                )}
              </div>
            </StylessButton>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(OnboardingSelector);
