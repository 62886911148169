import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { StylessButton } from '../buttons';
import styles from '../global/styles/PaymentSection';

const NewPaymentMethod = ({ classes, title, setOpenContactModal, setOpenPaymentOptionModal, isShippingModal }) => (
  <Grid
    container
    className={classes.containerCreditAvailable}
    style={isShippingModal ? { width: 660, height: 90 } : undefined}
    justifyContent="center"
    alignItems="center"
  >
    <Grid item xs={1}>
      <StylessButton
        onClick={() => {
          setOpenPaymentOptionModal(false);
          setOpenContactModal(true);
        }}
      >
        <img src="/images/shipping/add-CC.svg" className={classes.accountImg} alt="" />
      </StylessButton>
    </Grid>
    <Grid item xs={11} style={{ paddingLeft: 20 }}>
      <p className={classes.creditAvailable}>{`Add new ${title}`}</p>
      <p className={classes.textCreditAvailable}>{`You can add new ${title}`}</p>
    </Grid>
  </Grid>
);

export default withStyles(styles)(NewPaymentMethod);
