import { ellipsisStyles } from '../../shared/styles/commonStyles';

const productNameCommon = {
  fontFamily: 'Gilroy',
  fontSize: 16,
  fontWeight: 500,
  borderRadius: 4,
  lineHeight: 'normal',
  width: '100%',
  color: ({ blue }) => (blue ? 'white' : '#0f2440')
};

const styles = theme => ({
  showProductName: {
    ...productNameCommon,
    ...ellipsisStyles,
    border: 'none',
    backgroundColor: ({ blue }) => (blue ? '#3577d4' : 'transparent')
  },
  editProductName: {
    ...productNameCommon,
    outline: 'none',
    color: '#0f2440',
    border: ({ blue }) => (blue ? '1px solid white' : '1px solid black'),
    '& focus': {
      border: '1px solid #3577d4'
    }
  },
  text: {
    marginTop: 1,
    lineHeight: 1,
    color: ({ blue }) => (blue ? 'white' : '#8D9299')
  },
  quantityText: {
    display: 'inline-flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    fontSize: ({ list }) => (list ? 14 : 12),
    height: '32px',
    lineHeight: '32px',
    color: ({ blue }) => (blue ? 'white' : '#8D9299')
  },
  colorText: {
    lineHeight: 1,
    marginLeft: 5,
    fontWeight: 500,
    color: ({ blue }) => (blue ? 'white' : '#0f2440')
  },
  colorCircle: {
    marginLeft: 5,
    borderRadius: 8,
    width: 12,
    height: 12,
    backgroundColor: ({ color }) => color
  },
  tableLinkText: {
    fontSize: 12,
    color: '#3577d4',
    cursor: 'pointer',
    border: 0,
    margin: 0,
    padding: 0,
    fontFamily: 'inherit',
    background: 'transparent'
  },
  detailsPackUnits: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 10,
    fontWeight: 500,
    color: '#0f2440',
    margin: 0,
    paddingBottom: 8,
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  detailsPackName: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 600,
    color: '#0f2440',
    margin: 0,
    paddingBottom: 8,
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  detailsPackColor: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    margin: 0,
    paddingBottom: 8,
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  }
});
export default styles;
