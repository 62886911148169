import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Scrollbars from 'react-scrollbars-custom';
import verifyAddress from '../../../../../helpers/addressValidation';
import toErrorPage from '../../../../../helpers/toErrorPage';
import { getSchema, handleContactError } from '../../../../global/Forms/commonValidations';
import AddressConfirmation from '../../../../shared/AddressConfirmation';
import { PersonalInfo, ShippingAddress } from './FormSections';
import styles from './ContactForm.styles';

const useClasses = makeStyles(styles);

const fields = [
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'shipping_address1',
  'shipping_country',
  'shipping_state',
  'shipping_city',
  'shipping_zip'
];
const resolver = yupResolver(getSchema(fields));

const ContactForm = ({ children, title, submitText, onSubmit, onSuccess, defaultValues, minimal }) => {
  const classes = useClasses();
  const dispatch = useDispatch();
  const history = useHistory();
  const [addressVerification, setAddressVerification] = useState({ address: {} });
  const [isSaving, setIsSaving] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const formProps = useForm({
    mode: 'all',
    resolver,
    defaultValues
  });

  const { formState, handleSubmit, setError, trigger } = formProps;

  React.useEffect(() => {
    if (defaultValues) {
      trigger();
      setIsSaving(false);
    }
  }, [defaultValues, trigger]);

  const postContact = async data => {
    setIsSaving(true);
    try {
      const response = await dispatch(onSubmit(data));
      setIsSaving(false);
      if (response?.result === 'error') {
        handleContactError({
          data: response.data,
          setError,
          errorBehavior: () => toErrorPage(response, history),
          state: data.shipping_state
        });
        return;
      }
      onSuccess({
        ...response.data,
        size: response.data.size.id !== undefined ? response.data.size : null
      });
    } catch {
      setTimeout(() => {
        setIsSaving(false);
      }, 1000);
    }
  };

  const onSave = async data => {
    setIsSaving(true);

    const contactData = { ...data, force_address: true };
    const address = {
      ...contactData,
      street: data.shipping_address1,
      secondary: data.shipping_address2,
      city: data.shipping_city,
      state: data.shipping_state,
      zipcode: data.shipping_zip,
      country: data.shipping_country
    };

    const { result, message } = await verifyAddress(address);
    if (result === 'ok') {
      await postContact(contactData);
    } else {
      setAddressVerification({ address, message });
      setIsOpenConfirm(true);
    }
  };

  const handleCloseConfirm = confirmed => {
    if (confirmed !== 'confirmed') {
      setIsSaving(false);
    }
    setIsOpenConfirm(false);
  };

  return (
    <>
      <h2 className={classes.title}>{title}</h2>

      <form onSubmit={handleSubmit(onSave)} className={classes.form}>
        <Scrollbars className={classes.content}>
          {children}

          <div style={{ flex: 1 }}>
            <FormProvider {...formProps}>
              <PersonalInfo isSaving={isSaving} minimal={minimal} />
              <ShippingAddress isSaving={isSaving} />
            </FormProvider>
          </div>
        </Scrollbars>

        <div style={{ marginTop: 12 }}>
          <Button
            type="submit"
            variant="primary"
            disabled={!formState.isValid || isSaving}
            loading={isSaving}
            className={classes.addButton}
          >
            {submitText}
          </Button>
        </div>
      </form>

      <AddressConfirmation
        open={isOpenConfirm}
        onClose={handleCloseConfirm}
        address={addressVerification.address}
        message={addressVerification.message}
        callbackAction={postContact}
      />
    </>
  );
};

export default ContactForm;
