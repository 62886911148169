import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import DetailsModal from '../../../directory/staffOrders/DetailsModal';
import CancelModal from './CancelModal';
import InvalidAddressModal from './InvalidAddressModal';
import { ShipmentCard } from './ShipmentCard';
import { shipmentStatus } from '../../../../apis/constants';

const ShipmentsGrid = ({ shipments, fetchShipments, onCancel, onSoftCancel }) => {
  const [shipmentToDelete, setShipmentToDelete] = useState(null);
  const [shipmentToSoftDelete, setShipmentToSoftDelete] = useState(null);
  const [shipmentForDetails, setShipmentForDetails] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSoftDeleteModal, setOpenSoftDeleteModal] = useState(false);

  const selectShipmentToDelete = shipment => {
    setShipmentToSoftDelete();
    setOpenSoftDeleteModal(false);
    setShipmentToDelete(shipment);
    setOpenDeleteModal(Boolean(shipment));
  };

  const selectShipmentToSoftDelete = shipment => {
    setShipmentToDelete();
    setOpenDeleteModal(false);
    setShipmentToSoftDelete(shipment);
    setOpenSoftDeleteModal(Boolean(shipment));
  };

  const handleClose = () => {
    setShipmentForDetails(null);
  };

  return (
    <Grid container spacing={4}>
      {shipments.map(sh => (
        <Grid container item xs={12} sm={6} md={4} lg={3} key={sh.id} justifyContent="center">
          <ShipmentCard
            shipment={sh}
            setOrderForDetails={setShipmentForDetails}
            setOrderToDelete={selectShipmentToDelete}
            setOrderToSoftDelete={selectShipmentToSoftDelete}
          />
        </Grid>
      ))}
      <CancelModal
        open={openDeleteModal || openSoftDeleteModal}
        shipmentToDelete={shipmentToDelete || shipmentToSoftDelete}
        clearShipmentToDelete={() => (shipmentToDelete ? setShipmentToDelete(null) : setShipmentToSoftDelete())}
        onClose={() => (openDeleteModal ? setOpenDeleteModal(false) : setOpenSoftDeleteModal(false))}
        onCancel={openDeleteModal ? onCancel : onSoftCancel}
        actionType={openDeleteModal ? 'cancel' : 'remove'}
      />
      {shipmentForDetails?.status === shipmentStatus.invalidAddress ? (
        <InvalidAddressModal shipment={shipmentForDetails} fetchShipments={fetchShipments} onClose={handleClose} />
      ) : (
        <DetailsModal order={shipmentForDetails} onClose={handleClose} />
      )}
    </Grid>
  );
};

export default ShipmentsGrid;
