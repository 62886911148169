const styles = theme => ({
  root: {
    position: 'relative'
  },
  container: {
    backgroundColor: 'inherit'
  },
  artworkImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    zIndex: -1
  },
  coverHeader: {
    color: '#0B1829',
    fontWeight: 400,
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      fontSize: 32
    }
  },
  subTitle: {
    marginTop: 20,
    fontSize: 20,
    color: '#434c5f'
  },
  listTitle: {
    marginTop: 30,
    fontSize: 14,
    color: '#434c5f'
  },
  listToKnow: {
    fontSize: 20,
    color: '#434c5f',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  listToKnowContainer: {
    color: '#434c5f',
    paddingTop: 10,
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0',
      lineHeight: 1
    }
  },
  exploreSwagButton: {
    fontSize: 16,
    height: 56,
    width: 260
  }
});

export default styles;
