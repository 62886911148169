import React, { useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import ShipmentStatus from '../../../global/ShipmentStatus';
import TrackingInfo from './TrackingInfo';
import OrderSummaryModal from './OrderSummaryModal/OrderSummaryModal';
import styles from './styles/MultiItemsHeader';

const MultiItemsHeader = ({
  classes,
  date,
  orderNumber,
  itemsLength,
  trackingUrl,
  tracking,
  status,
  employee,
  products
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpenModal = () => setOpen(isOpen => !isOpen);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <p className={classes.title}>Multi-Item Order</p>
          <p className={classes.normalText}>{date}</p>
          <p className={classes.normalText}>{`Order #: ${orderNumber || '_'}`}</p>
          <p className={classes.normalText}>{`Items: ${itemsLength}`}</p>
          <TrackingInfo classes={classes} trackingUrl={trackingUrl} tracking={tracking} />
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
            <Grid item>
              <Button variant="primary" size="small" onClick={toggleOpenModal} style={{ width: 209 }}>
                View full Order
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: 15 }}>
              <ShipmentStatus status={status} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <OrderSummaryModal
        open={open}
        onClose={toggleOpenModal}
        trackingUrl={trackingUrl}
        products={products}
        employee={employee}
      />
    </>
  );
};

export default withStyles(styles)(MultiItemsHeader);
