import log from '../../logger';

const uploadStages = { stopped: 0, inProgress: 1, done: 2, failed: 3 };

const uploadInProgress = (event, setProgressStatus, setUploadStage, setTransferTime) => {
  setUploadStage(uploadStages.inProgress);
  setTransferTime(prevTime => (prevTime === 0 ? new Date().getTime() / 1000 : prevTime));
  if (event.total > 0) {
    const progress = (event.loaded * 100) / event.total;
    setProgressStatus(progress);
    log.debug(`uploaded :: ${progress.toFixed(2)}%`);
  }
};

const uploadOk = (data, setTransferTime, setFile, setProgressStatus, setUploadStage) => {
  setTransferTime(prevTime => parseInt(new Date().getTime() / 1000 - prevTime, 10));
  setFile(data.Location);
  setProgressStatus(100);
  setUploadStage(uploadStages.done);
};

const uploadError = (err, setProgressStatus, setUploadStage, setTransferTime) => {
  log.error('Upload failed, error:', err.message);
  setProgressStatus(0);
  setUploadStage(uploadStages.failed);
  setTransferTime(0);
};

const uploadDone = (err, data, setTransferTime, setFile, setProgressStatus, setUploadStage) => {
  return err
    ? uploadError(err, setProgressStatus, setUploadStage, setTransferTime)
    : uploadOk(data, setTransferTime, setFile, setProgressStatus, setUploadStage);
};

export { uploadDone, uploadInProgress, uploadStages };
