import * as React from 'react';
import { Box, CircularProgress, Divider, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Link, useParams } from 'react-router-dom';
import { EditOutlined } from '@material-ui/icons';
import { useMutation, useQueryClient } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import clsx from 'clsx';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';

import Drawer from '../common/Drawer';
import { useOrder } from '../OrderContext';
import Img from '../../../../shared/Img';
import { greyRoundBorder, ellipsisStyles } from '../../../../shared/styles/commonStyles';
import { getMatchAddress, imageSrcSet, moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import api from '../../../../../apis/swagup/order';
import { Pagination } from '../../../../shared';
import { useLocalPagination } from '../../../../../hooks';
import SearchBar from '../common/SearchBar';
import Filter from '../common/Filter';
import InactiveSizesAlert from '../common/InactiveSizesAlert';
import { changeColorLightness } from '../../../../shared/styles/utils';
import RemoveShipmentGroupModal from './RemoveShipmentGroupModal';
import RemoveShipmentGroupModalV2 from './RemoveShipmentGroupModalV2';
import apiPaths from '../../../../../helpers/apiPaths';

const name = {
  ...ellipsisStyles,
  color: '#0b1829',
  fontFamily: 'Gilroy-SemiBold',
  fontWeight: 500,
  fontSize: 16,
  letterSpacing: 0,
  lineHeight: '24px',
  marginLeft: 20,
  maxWidth: 260
};

const useCardStyles = makeStyles({
  card: {
    transition: 'box-shadow 300ms ease',
    '&:not(:hover) .editBtn, &:not(:hover) .removeBtn': {
      display: 'none'
    },
    '&:hover': {
      boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.05)'
    }
  },
  link: {
    height: 'fit-content',
    padding: '3px 8px',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    letterSpacing: 'normal',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  name,
  inputName: {
    ...name,
    padding: 0,
    margin: 0
  },
  subtitle: {
    color: '#787b80',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '20px'
  },
  number: {
    color: '#0b1829',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: '28px'
  },
  divider: {
    marginTop: 20,
    width: '100%',
    backgroundColor: '#f0f2f5'
  },
  editBtn: {
    padding: 0,
    marginLeft: 12,
    transition: 'opacity 0.3s ease-out',
    backgroundColor: 'transparent !important'
  },
  editIcon: {
    width: 16,
    height: 16,
    color: '#0b1829'
  },
  removeBtn: {
    height: 14,
    padding: 8,
    marginRight: -8,
    letterSpacing: 0,
    fontFamily: 'Gilroy-SemiBold',
    transition: 'opacity 0.3s ease-out',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 0.71,
    color: '#f44336',
    '&:hover': { color: changeColorLightness('#f44336') }
  },
  image: { width: 24 }
});

const ShipmentCard = ({ group, hasInactiveSize }) => {
  const classes = useCardStyles();
  const { enableUnallocatedProductsManagementV2 } = useFlags();

  const [currentName, setCurrentName] = React.useState(group.name);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isRemoveOpen, toggleIsRemoveOpen] = React.useReducer(prevOpen => !prevOpen, false);
  const [isHovered, toggleIsHovered] = React.useReducer(prevHovered => !prevHovered, false);

  const id = +useParams().id;
  const queryClient = useQueryClient();
  const editNameMutation = useMutation(api.editShipmentGroup, {
    onSuccess: updatedShipment => {
      queryClient.cancelQueries([apiPaths.shipmentGroups(id)]);
      queryClient.setQueryData([apiPaths.shipmentGroups(id)], prevData => ({
        ...prevData,
        results: prevData.results.filter(sg => (sg.id === updatedShipment.id ? updatedShipment : sg))
      }));
      queryClient.invalidateQueries([apiPaths.shipmentGroups(id)]);
    },
    onError: () => setCurrentName(group.name)
  });

  const handleEdit = () => {
    if (group.name !== currentName) {
      editNameMutation.mutate({ opportunityId: id, shipmentGroupId: group.id, data: { name: currentName } });
    }
    setIsEditing(false);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 27) {
      setCurrentName(group.name);
      setIsEditing(false);
    } else if (e.keyCode === 13) {
      handleEdit();
    }
  };

  const handleChange = e => setCurrentName(e.target.value);

  const totalQuantity = sumBy(group.directory_orders, d => sum(d.proofs.map(p => sumByQuantity(p.sizes))));
  const totalPrice = Number(group.domestic_price) + Number(group.international_price);

  const hasDomesticOrders = group.directory_orders.some(d => getMatchAddress('domestic', d.directory));
  const hasInternationalOrders = group.directory_orders.some(d => getMatchAddress('international', d.directory));

  const RemoveShipmentGroupModalComp = enableUnallocatedProductsManagementV2
    ? RemoveShipmentGroupModalV2
    : RemoveShipmentGroupModal;
  return (
    <Box
      component="section"
      border={`solid 1px ${hasInactiveSize ? '#fa902d' : '#f0f2f5'}`}
      bgcolor="white"
      borderRadius={10}
      minWidth={496}
      height={232}
      display="flex"
      justifyContent="space-between"
      padding="24px 32px 32px"
      flexWrap="wrap"
      className={classes.card}
      onMouseEnter={toggleIsHovered}
      onMouseLeave={toggleIsHovered}
    >
      <Grid container justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Img
            srcSet={imageSrcSet('/images/orders/delivery-truck.png')}
            alt="Swag Up Truck"
            width={48}
            height={48}
            style={{
              ...greyRoundBorder,
              objectFit: 'none'
            }}
          />
          {isEditing ? (
            <TextField
              type="text"
              disabled={!isEditing}
              value={currentName}
              autoFocus
              onChange={handleChange}
              onBlur={handleEdit}
              onKeyDown={handleKeyDown}
              fullWidth
              InputProps={{
                disableUnderline: true,
                inputProps: { maxLength: 100 },
                classes: { input: classes.inputName }
              }}
            />
          ) : (
            <h3 className={classes.name}>{currentName}</h3>
          )}
          {!isEditing && (
            <Grid item className={classes.editContainer}>
              {editNameMutation.isLoading ? (
                <CircularProgress size={16} color="inherit" style={{ marginLeft: 12 }} />
              ) : (
                <IconButton
                  className={clsx(classes.editBtn, { editBtn: true })}
                  onClick={() => setIsEditing(true)}
                  disableRipple
                  disableFocusRipple
                >
                  <EditOutlined aria-hidden={!isHovered} title="Pen Icon" className={classes.editIcon} />
                </IconButton>
              )}
            </Grid>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {hasInternationalOrders && (
            <Img srcSet={imageSrcSet('/images/orders/world.png')} alt="World Globe" width={24} height={24} />
          )}
          {hasDomesticOrders && (
            <Img
              srcSet={imageSrcSet('/images/orders/usa.png')}
              alt="USA"
              width={30}
              height={24}
              style={{ marginLeft: 16 }}
            />
          )}
        </Box>
      </Grid>
      <Grid container style={{ marginTop: 20 }}>
        <Grid container direction="column" item xs={4}>
          <p className={classes.subtitle}>Qty.</p>
          <p className={classes.number}>{totalQuantity}</p>
        </Grid>
        <Grid container direction="column" item xs={4}>
          <p className={classes.subtitle}>Recipients</p>
          <p className={classes.number}>{group.directory_orders.length}</p>
        </Grid>
        <Grid container direction="column" item xs={4} alignItems="flex-end" style={{ textAlign: 'left' }}>
          <p className={classes.subtitle}>Shipping price</p>
          <p className={classes.number}>{moneyStr(totalPrice)}</p>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: 20 }}>
        <Button
          component={Link}
          variant="text"
          size="small"
          className={classes.link}
          to={`${id}/select-recipients/${group.id}`}
          withIcon
        >
          View details
        </Button>
        <Button
          variant="text"
          size="small"
          className={clsx(classes.removeBtn, { removeBtn: true })}
          onClick={toggleIsRemoveOpen}
        >
          Delete Shipment
          <DeleteOutlineIcon title="Trash Icon" style={{ fontSize: 14, marginLeft: 4 }} />
        </Button>
        {isRemoveOpen && (
          <RemoveShipmentGroupModalComp isOpen={isRemoveOpen} onClose={toggleIsRemoveOpen} group={group} />
        )}
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles({
  drawer: {
    padding: '22px 50px',
    width: '42%'
  },
  title: {
    color: '#0b1829',
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '34px',
    margin: '10px 0 0 0'
  },
  paginationBtn: {
    width: 36,
    minWidth: 36,
    height: 36,
    padding: 8,
    '& svg': {
      width: 20,
      height: 20
    }
  },
  verticalDivider: {
    margin: '6px 8px',
    backgroundColor: 'rgba(0, 0, 0, 0.06)'
  },
  animatedBox: {
    transition: 'all',
    transitionDuration: '1s',
    transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)'
  }
});

const defaultPerPageOptions = [4, 6, 8, 10];

const ShipmentGroups = () => {
  const classes = useStyles();

  const [isSearchOpen, toggleIsSearchOpen] = React.useReducer(prevOpen => !prevOpen, false);

  const { shipmentGroups, shipmentGroupsWithInactiveSizes } = useOrder();

  const [filters, setFilters] = React.useState();
  const [search, setSearch] = React.useState('');
  const filteredShipmentGroups = shipmentGroups
    .filter(s => s.name.toLowerCase().includes(search.toLowerCase().trim()))
    .filter(s => s.directory_orders.some(d => getMatchAddress(filters?.address, d.directory)));

  const pagination = useLocalPagination(filteredShipmentGroups.length, defaultPerPageOptions);
  const offset = pagination.pageIndex * pagination.perPage;
  const paginatedShipmentGroups = filteredShipmentGroups.filter(
    (s, idx) => idx >= offset && idx < offset + pagination.perPage
  );

  return (
    <>
      <h2 className={classes.title}>Shipments Overview</h2>
      <Grid container style={{ margin: '30px 0 18px' }}>
        <Box display="flex" flex={isSearchOpen ? '1' : '0.2 10%'} className={classes.animatedBox}>
          <SearchBar open={isSearchOpen} toggleOpen={toggleIsSearchOpen} onChange={setSearch} />
          {!isSearchOpen && (
            <>
              <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
              <Filter filters={filters} onFiltersSubmit={setFilters} withSizes={false} />
            </>
          )}
        </Box>
      </Grid>
      {shipmentGroupsWithInactiveSizes.size > 0 && <InactiveSizesAlert />}
      <Grid container direction="column" style={{ rowGap: 24, marginTop: 20 }}>
        {paginatedShipmentGroups.map(sg => (
          <ShipmentCard key={sg.id} group={sg} hasInactiveSize={shipmentGroupsWithInactiveSizes.has(sg.id)} />
        ))}
      </Grid>
      <Grid container alignItems="center" style={{ marginTop: 24 }}>
        <Pagination
          count={filteredShipmentGroups.length}
          endText="shipment groups"
          startText="Show"
          perPage={pagination.perPage}
          onNext={pagination.onNext}
          pageIndex={pagination.pageIndex}
          onPrevious={pagination.onPrevious}
          sizeOptions={pagination.sizeOptions}
          onPerPageChange={pagination.changeSize}
          buttonClass={classes.paginationBtn}
        />
      </Grid>
    </>
  );
};

const Wrapper = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={onClose} classes={{ paper: classes.drawer }}>
      {open && <ShipmentGroups />}
    </Drawer>
  );
};

export default Wrapper;
