import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueries, useQueryClient } from 'react-query';
import { useErrorHandler } from 'react-error-boundary';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { sumBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import apiPaths from '../../../../helpers/apiPaths';
import api from '../../../../apis/swagup/order';
import globalApi from '../../../../apis/swagup/global';
import Loader from '../../../global/Loader';
import { fifteenMinutes, oneHour } from '../../../../helpers/helperConstants';
import {
  cancellableQuery,
  doesProductHasInactiveSizes,
  doesDirectoryHasInactiveSizes,
  isInactiveSize,
  doesProofHasInactiveSizes,
  getShipmentGroupsInfo
} from './common/utilsOrder';
import { useOpportunityRefetchInterval } from '../../../../hooks';
import log from '../../../../logger';
import { sumByQuantity } from '../../../../helpers/utils';
import useDiscountPricing from '../../../../hooks/useDiscountPricing';

const getProductStorage = (prices, category) => prices?.find(p => p.storage_category === category)?.price;
const getProductStorageBeforeDiscount = (prices, category) =>
  prices?.find(p => p.storage_category === category)?.price_without_discount;
const OrderContext = React.createContext();
OrderContext.displayName = 'OrderContext';

const OrderProvider = ({ children }) => {
  const { id } = useParams();
  if (id === undefined) {
    throw new Error(`OrderProvider must be used where an order id is available as a url param`);
  }
  const [paymentMethod, setPaymentMethod] = React.useState(window.sessionStorage.getItem('method'));
  const [funds, setFunds] = React.useState(0);
  const [inputFunds, setInputFunds] = React.useState(0);
  const [refetchInterval, setRefetchInterval] = React.useState();
  const [currentTotal, setCurrentTotal] = React.useState();

  const handleError = useErrorHandler();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { enableUnallocatedProductsManagementV2 } = useFlags();

  const standardQueryDefitions = [
    {
      queryKey: [apiPaths.opportunities, +id],
      queryFn: () => cancellableQuery(api.fetchOpportunity)(id),
      retry: false,
      refetchInterval,
      onError: error => {
        if (error.status === 404) history.replace('/orders-requested', { message: 'The order is no longer active' });
        else handleError(error.data);
      }
    },
    {
      queryKey: [apiPaths.shipmentGroups(id)],
      queryFn: () => cancellableQuery(api.fetchShipmentGroups)(id)
    },
    {
      queryKey: apiPaths.storagePrices,
      queryFn: globalApi.fetchStoragePrices,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: fifteenMinutes,
      cacheTime: oneHour
    },
    {
      queryKey: apiPaths.storageCategories,
      queryFn: globalApi.fetchStorageCategories,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: fifteenMinutes,
      cacheTime: oneHour
    },
    {
      queryKey: apiPaths.validateOpportunity,
      queryFn: () => api.validateOpportunity({ opportunity_id: +id }),
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: fifteenMinutes,
      cacheTime: oneHour
    }
  ];

  const warehouseQueryDefinitions = [
    {
      queryKey: [apiPaths.warehouseProofs(id)],
      queryFn: () => api.fetchWarehouseProofs(id),
      onSuccess: () =>
        queryClient.setMutationDefaults(apiPaths.warehouseProofs(id), { mutationFn: api.saveWarehouseProofs })
    }
  ];

  const queryDefinitions = [
    ...standardQueryDefitions,
    ...(enableUnallocatedProductsManagementV2 ? [] : warehouseQueryDefinitions)
  ];

  const queries = useQueries(queryDefinitions);

  const [order, shipmentGroups, storagePrices, , validateOppQuery, apiWarehouseProofs] = queries;

  const pricingError = {
    ...validateOppQuery?.data,
    hasError: validateOppQuery?.data?.msg !== 'Everything is good'
  };

  const { getOrderWithDiscount } = useDiscountPricing();
  const customizedOrder = React.useMemo(() => getOrderWithDiscount(order.data || {}), [
    getOrderWithDiscount,
    order.data
  ]);
  const { products } = customizedOrder;
  const { pathname } = history.location;

  const productPricesInProcess = products?.every(product => +product.price === 0);
  const isOrderView = `/orders-requested/${id}` === pathname;
  const { nextRefetchInterval, hasRefetchStarted } = useOpportunityRefetchInterval(
    isOrderView,
    productPricesInProcess,
    order.isFetching
  );

  const warehouseProofs = React.useMemo(() => {
    if (!enableUnallocatedProductsManagementV2) {
      return apiWarehouseProofs.data ? apiWarehouseProofs.data.results : [];
    }
    if (!products || !shipmentGroups?.data) return [];

    const currentWarehouseProofs = products.map(product => {
      const sizes = product.sizes.map(s => ({ size: s.size.id, quantity: s.quantity }));
      let curentProof = product;
      const updatedSizes = sizes.map(s => {
        const q = s.quantity;
        const sgQty = shipmentGroups.data.results.reduce((sum, sg) => {
          const doSizeQty = sg.directory_orders.reduce((addition, { proofs }) => {
            const qty = proofs.reduce((acc, proof) => {
              if (proof.proof.id !== product.id) return acc;
              curentProof = proof.proof;
              const size = proof.sizes?.find(sz => sz.size === s.size);
              if (!size) return acc;
              return acc + size.quantity;
            }, 0);
            return addition + qty;
          }, 0);
          return sum + doSizeQty;
        }, 0);
        return { ...s, quantity: q - sgQty };
      });
      return { id: product.id, opportunity: product.opportunity, proof: curentProof, sizes: updatedSizes };
    });
    return currentWarehouseProofs;
  }, [enableUnallocatedProductsManagementV2, apiWarehouseProofs, products, shipmentGroups.data]);

  log.debug('warehouseProofs -> ', warehouseProofs);

  React.useEffect(() => {
    log.debug('Opportunity nextRefetchInterval:', nextRefetchInterval);
    setRefetchInterval(nextRefetchInterval);
  }, [nextRefetchInterval]);

  const inactiveSizesProps = React.useMemo(() => {
    if (!products || !shipmentGroups.data || !warehouseProofs.data) {
      return {
        productsWithInactiveSizes: [],
        shipmentGroupsWithInactiveSizes: new Set(),
        warehouseProofsWithInactiveSizes: new Set()
      };
    }

    const productsWithInactiveSizes = products.filter(doesProductHasInactiveSizes);

    const mapProductsToInactiveSizes = Object.fromEntries(
      productsWithInactiveSizes.map(p => [p.id, Array.from(p.sizes.filter(isInactiveSize), s => s.size.id)])
    );

    const doesShipmentGroupHasInactiveSizes = sg =>
      sg.directory_orders.some(doesDirectoryHasInactiveSizes(mapProductsToInactiveSizes));

    const shipmentGroupsWithInactiveSizes = new Set(
      Array.from(shipmentGroups.data.results.filter(doesShipmentGroupHasInactiveSizes), sg => sg.id)
    );

    const warehouseProofsWithInactiveSizes = new Set(
      Array.from(warehouseProofs.filter(doesProofHasInactiveSizes(mapProductsToInactiveSizes)), wp => wp.id)
    );

    return {
      productsWithInactiveSizes,
      shipmentGroupsWithInactiveSizes,
      warehouseProofsWithInactiveSizes
    };
  }, [products, shipmentGroups, warehouseProofs]);

  const totalStorageCost = sumBy(
    warehouseProofs,
    wp => sumByQuantity(wp.sizes) * getProductStorage(storagePrices.data?.results, wp.proof.product.storage_category)
  );

  const totalStorageCostBeforeDiscount = sumBy(
    warehouseProofs,
    wp =>
      sumByQuantity(wp.sizes) *
      getProductStorageBeforeDiscount(storagePrices.data?.results, wp.proof.product.storage_category)
  );
  const shipmentGroupsCount = useMemo(() => {
    return shipmentGroups.data?.results ? shipmentGroups.data?.results.length : 0;
  }, [shipmentGroups.data?.results]);

  const shipping = useMemo(() => {
    const shippingInfo = getShipmentGroupsInfo(shipmentGroupsCount > 0 ? shipmentGroups.data?.results : []);
    return shippingInfo.international.price + shippingInfo.domestic.price;
  }, [shipmentGroups.data?.results, shipmentGroupsCount]);

  useEffect(() => {
    setFunds(shipping);
  }, [shipping]);

  const isLoading = queries.some(query => query.status === 'loading');
  if (isLoading) return <Loader />;

  return (
    <OrderContext.Provider
      value={{
        ...customizedOrder,
        total_shipping: shipping,
        total_warehouses: enableUnallocatedProductsManagementV2 ? totalStorageCost : customizedOrder?.total_warehouses,
        total_warehouses_before_discount: enableUnallocatedProductsManagementV2
          ? totalStorageCostBeforeDiscount
          : customizedOrder?.total_warehouses,
        shipmentGroups: shipmentGroups.data?.results ?? [],
        warehouseProofs: warehouseProofs ?? [],
        ...inactiveSizesProps,
        hasRefetchStarted,
        setFunds,
        funds: funds || customizedOrder.total_shipping,
        inputFunds,
        setInputFunds,
        setCurrentTotal,
        currentTotal,
        paymentMethod,
        setPaymentMethod,
        pricingError
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

const useOrder = () => {
  const order = React.useContext(OrderContext);
  return order;
};

export { OrderProvider, useOrder };
