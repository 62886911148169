import { SET_ACCOUNT_PRODUCT } from '../actions/types';

const AccountProductReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ACCOUNT_PRODUCT:
      return action.payload;

    default:
      return state;
  }
};

export default AccountProductReducer;
