import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import OrderContent from './OrderContent';
import OrderSummary from './OrderSummary';
import { Helmet, CenteredGrid } from '../../../shared';
import tags from '../../../../apis/seoTags';
import Loader from '../../../global/Loader';
import { StatusChip } from '../../../global/proofsCommon';
import { imageSrcSet, formatDate } from '../../../../helpers/utils';
import { invoiceStatusStyles } from '../../../account/Invoices';
import { ellipsisStyles } from '../../../shared/styles/commonStyles';
import { useQueryParams, useInvoice, useOrderRefetchInterval } from '../../../../hooks';
import { accountOrdersApi } from '../../../../apis/swagup';
import { isOrderProcessing } from './CommonOrderElements';
import { invoiceStatus } from '../../../../apis/constants';
import Breadcrumbs from '../../../shared/Breadcrumbs';
import InvoicePaymentDialog from '../../../account/InvoicePaymentDialog';
import toErrorPage from '../../../../helpers/toErrorPage';
import apiPaths from '../../../../helpers/apiPaths';
import log from '../../../../logger';

const commonText = { fontFamily: 'Gilroy-Medium', fontSize: 12 };

const useStyles = makeStyles({
  backTitle: { ...commonText, color: '#0b1829' },
  currentTitle: { ...commonText, color: '#787b80' },
  emailLink: { ...commonText, color: '#787b80', marginTop: 8 },
  createdHeader: { ...commonText, color: '#787b80', textAlign: 'left' },
  createdInfo: {
    ...ellipsisStyles,
    width: 'inherit',
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    color: '#0b1829',
    textAlign: 'left',
    marginTop: 4
  },
  container: {
    paddingTop: 42,
    paddingBottom: 42
  },
  arrowBack: {
    color: '#787b80',
    fontSize: 16,
    margin: '8px 7px 0px 0px'
  },
  separator: {
    fontSize: 13,
    marginTop: 2
  },
  emailContainer: {
    height: 156,
    paddingLeft: 24,
    marginTop: 15,
    borderRadius: 10,
    backgroundColor: '#f4f8ff'
  },
  emailQuestion: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    color: '#0b1829'
  },
  orderNo: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    color: '#0b1829',
    marginRight: 15
  }
});

const EmailUs = ({ classes }) => (
  <Grid container alignItems="center" className={classes.emailContainer}>
    <Grid item container xs={2}>
      <img srcSet={imageSrcSet('/images/orders/email-us.png')} alt="Email Us" />
    </Grid>
    <Grid item xs={10}>
      <p className={classes.emailQuestion}>Questions about your order?</p>
      <p className={classes.emailLink}>
        Reach out to our support team{' '}
        <a href="https://support.swagup.com/en" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </p>
    </Grid>
  </Grid>
);

const GoBack = ({ orderNo, classes }) => (
  <Grid container style={{ marginBottom: 20 }}>
    <Link to="/orders-completed">
      <ArrowBack className={classes.arrowBack} />
    </Link>
    <Breadcrumbs
      separator={<span className={classes.separator}>/</span>}
      links={[
        { title: 'Orders', to: '/orders-completed', styles: classes.backTitle },
        { title: `Order #${orderNo}`, styles: classes.currentTitle }
      ]}
    />
  </Grid>
);

const OrderHeader = ({ order, classes }) => (
  <Grid container alignItems="center" style={{ marginBottom: 40 }}>
    <Grid item container xs={8} alignItems="center">
      <p className={classes.orderNo}>{`Order #${order.order_no}`}</p>
      <StatusChip label={order.status} status={invoiceStatusStyles[order.status]} />
    </Grid>
    <Grid item xs={2} style={{ paddingLeft: 50 }}>
      <p className={classes.createdHeader}>Order placed</p>
      <p className={classes.createdInfo}>{formatDate(order.created_at)}</p>
    </Grid>
    <Grid item xs={2}>
      {order.created_by_fullname && (
        <>
          <p className={classes.createdHeader}>By</p>
          <p className={classes.createdInfo}>{order.created_by_fullname}</p>{' '}
        </>
      )}
    </Grid>
  </Grid>
);

const OrderDetails = () => {
  const [refetchInterval, setRefetchInterval] = React.useState();
  const [invoiceIdToOpen, setInvoiceIdToOpen] = React.useState();

  const { id } = useParams();
  const query = useQueryParams();
  const history = useHistory();
  const classes = useStyles();

  const { data: invoice, isLoading: isFetchingInvoice, isFetched: isInvoiceFetched } = useInvoice(invoiceIdToOpen);

  const { data: order, isFetching, refetch: refetchOrder } = useQuery(
    [apiPaths.accountOrders, +id],
    () => accountOrdersApi.fetch(id),
    {
      retry: false,
      refetchInterval,
      onError: error => {
        if (error.status === 404) history.replace('/orders-completed', { message: 'The order is no longer active' });
        else toErrorPage(error, history);
      }
    }
  );

  const orderInProcess = isOrderProcessing(order) ? order : undefined;
  const nextRefetchInterval = useOrderRefetchInterval(orderInProcess, isFetching);

  React.useEffect(() => {
    log.debug('Order Details nextRefetchInterval:', nextRefetchInterval);
    setRefetchInterval(nextRefetchInterval);
  }, [nextRefetchInterval]);

  const removeOpenQueryParam = React.useCallback(() => {
    query.delete('open');
    history.replace({ pathname: history.location.pathname, search: query.toString() });
  }, [query, history]);

  const invoiceNameToOpen = query.get('open');

  React.useEffect(() => {
    if (invoiceNameToOpen?.length === 0 || invoiceNameToOpen === 'Processing') {
      removeOpenQueryParam();
    } else if (order && invoiceNameToOpen) {
      if (order.invoice_name !== invoiceNameToOpen) {
        removeOpenQueryParam();
      } else {
        setInvoiceIdToOpen(order.invoice);
      }
    }
  }, [invoiceNameToOpen, order, removeOpenQueryParam]);

  if (!order) return <Loader />;

  return (
    <div className={classes.container}>
      <CenteredGrid container>
        <Helmet tags={tags.orderDetails} />
        <GoBack orderNo={order.order_no} classes={classes} />
        <OrderHeader order={order} classes={classes} />
        <Grid item md={8} sm={12}>
          <Grid item xs={12}>
            {order.products.map((product, index) => (
              <OrderContent
                key={product.id}
                orderProduct={product}
                showReorderItem={order.products.length > 1 && order.status === invoiceStatus.paid}
                showDivider={index + 1 !== order.products.length}
              />
            ))}
          </Grid>
          <EmailUs classes={classes} />
        </Grid>
        <Grid item md={4} sm={12}>
          <OrderSummary order={order} isLoading={isFetchingInvoice} />
        </Grid>
      </CenteredGrid>
      <InvoicePaymentDialog
        invoice={invoice}
        open={Boolean(invoiceNameToOpen) && isInvoiceFetched}
        onPaid={refetchOrder}
        onClose={removeOpenQueryParam}
      />
    </div>
  );
};

export default OrderDetails;
