import {
  GET_PAYMENT_PROFILES,
  ADD_PAYMENT_PROFILE,
  DELETE_PAYMENT_PROFILE,
  SET_DEFAULT_PAYMENT_PROFILE,
  EDIT_PAYMENT_PROFILE_NAME
} from '../actions/types';

const PaymentProfilesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PAYMENT_PROFILES:
      return action.payload;

    case ADD_PAYMENT_PROFILE:
      return action.payload.default
        ? [
            action.payload,
            ...state.map(profile =>
              profile.default
                ? {
                    ...profile,
                    default: false
                  }
                : profile
            )
          ]
        : [action.payload, ...state];

    case DELETE_PAYMENT_PROFILE:
      return state.filter(profile => profile.customer_payment_profile_id !== action.payload);

    case SET_DEFAULT_PAYMENT_PROFILE:
      return state.map(profile => {
        const newDefault = profile.customer_payment_profile_id === action.payload;
        return newDefault || profile.default
          ? {
              ...profile,
              default: newDefault
            }
          : profile;
      });

    case EDIT_PAYMENT_PROFILE_NAME:
      return state.map(profile =>
        profile.customer_payment_profile_id === action.payload.profileId
          ? {
              ...profile,
              payment_profile_name: action.payload.name
            }
          : profile
      );

    default:
      return state;
  }
};
export default PaymentProfilesReducer;
