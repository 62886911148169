import * as React from 'react';
import { Box, Grid, IconButton, makeStyles, Modal } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Close } from '@material-ui/icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SwipeableViews from 'react-swipeable-views';
import { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { ellipsisStyles, scrollBar } from '../shared/styles/commonStyles';
import apiPaths from '../../helpers/apiPaths';
import { integrationsApi } from '../../apis/swagup';
import useIntegrations from '../../hooks/useIntegrations';
import { imageSrcSet } from '../../helpers/utils';
import { getItegrationLogoImageUrl } from './common';
import Loader from '../global/Loader';
import { toSentenceCase } from '../account/MembershipCommon';
import Img from '../shared/Img';

const styles = theme => ({
  modalViewDetailsContainer: {
    borderRadius: 20,
    background: 'white',
    padding: 24,
    position: 'relative'
  },
  automationModal: {
    padding: 0,
    marginTop: '80px',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 680,
    overflow: 'hidden'
  },
  automationModalHeader: {
    padding: '12px 0px 34px 0px'
  },
  automationModalHeaderText: {
    ...ellipsisStyles,
    color: '#131415',
    fontSize: '20px',
    lineHeight: ' 28px',
    letterSpacing: '-0.03em'
  },
  modalHeaderBar: { marginBottom: 24 },
  modalBody: { width: '100%' },
  close: {
    width: 20,
    height: 20,
    fontSize: 16
  },
  cancel: {
    width: 16,
    height: 12,
    fontSize: 10,
    '& .MuiSvgIcon-root': {
      fontSize: 12
    }
  },
  successIcon: {
    width: 56,
    height: 56,
    color: 'rgb(152, 70, 221)'
  },
  sectionContainer: {
    marginBottom: 16
  },
  swagupImageWrapper: {
    height: 24,
    width: 24,
    position: 'relative'
  },
  platformImageWrapper: {
    height: 42,
    width: 92,
    position: 'relative'
  },
  swagupProductImageWrapper: {
    height: 42,
    width: 42,
    position: 'relative'
  },
  variantProductImageWrapper: {
    height: 56,
    width: 56,
    position: 'relative'
  },
  swagupProductImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  swagupProductName: {
    marginBottom: 4
  },
  poductContainer: {
    width: '100%',
    border: '1px solid #E5E7E8',
    borderRadius: 10,
    padding: '8px 12px'
  },
  integratedProductsPanel: {
    position: 'relative',
    minHeight: 240,
    paddingRight: 4,
    maxHeight: 464,
    ...scrollBar
  },
  variantContainer: {
    border: '1px solid #E5E7E8',
    borderRadius: 10,
    padding: '12px 12px',
    marginBottom: 12
  },
  disconnectCTA: {
    fontSize: 12,
    height: 32,
    width: 86,
    borderWidth: 1
  },
  viewCTA: {
    fontSize: 12,
    height: 32,
    paddingLeft: 8,
    paddingRight: 8,
    width: 132
  },
  titleText: {
    marginBottom: 8,
    color: '#4A4F54'
  },
  nameText: {
    ...ellipsisStyles,
    color: '#131415'
  },
  itemVariant: {
    maxWidth: '100%',
    '& p': {
      ...ellipsisStyles,
      color: '#4A4F54',
      fontFamily: 'Inter',
      textAlign: 'center',
      maxWidth: '90%',
      fontSize: 10,
      fontWeight: 500
    },
    margin: '6px 0px',
    width: 'fit-content',
    padding: '0px 6px',
    borderRadius: 12,
    background: '#E5E7E8'
  },
  swipeableViews: {
    height: '100%',
    width: '100%',
    // minHeight: 'calc(100vh - 202px)',
    '& .react-swipeable-view-container': {
      height: '100%'
    },
    '& > div > div': {
      overflowX: 'hidden !important',
      overflowY: 'hidden !important'
    }
  },
  floatingInfoMessage: {
    maxWidth: 464,
    zIndex: 99,
    width: 'auto',
    top: '32px',
    position: 'fixed'
  },
  emptyProductsImageWrapper: {
    height: 72,
    width: 72,
    position: 'relative'
  },
  emptyProductsTitle: {
    textAlign: 'center',
    marginBottom: 16
  },
  emptyProductsText: {
    textAlign: 'center',
    width: 320,
    marginTop: 16,
    marginBottom: 24
  },
  actionCTA: { minWidth: 124, height: 42 }
});

const useStyles = makeStyles(styles);

const CTAPanel = ({ storeDomain, platformName, productExternalId, onAction }) => {
  const [currentStep, setCurrentStep] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  return (
    <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
      <Grid container xs alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Button
            size="small"
            variant="text"
            className={classes.viewCTA}
            href={`https://admin.shopify.com/store/${storeDomain}/products/${productExternalId}`}
            target="_blank"
          >
            View in {platformName}
          </Button>
        </Grid>
        <Grid item>
          <Button size="small" variant="secondary" className={classes.disconnectCTA} onClick={() => setCurrentStep(2)}>
            Disconnect
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography variant="body4RegularInter">Are you sure?</Typography>
        </Grid>
        <Grid item xs={5}>
          <Button
            size="small"
            variant="primary"
            className={classes.disconnectCTA}
            onClick={() => {
              onAction();
              setIsLoading(true);
            }}
            loading={isLoading}
          >
            Yes
          </Button>
        </Grid>
        <Grid item>
          <IconButton className={classes.cancel} onClick={() => setCurrentStep(1)}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </SwipeableViews>
  );
};

const IntegratedProductsList = ({ integratedStore, onAction }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body3MediumInter" className={classes.titleText}>
              {integratedStore.store_name}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.sectionContainer}>
            {integratedStore.products.map(integratedProduct => (
              <Grid key={integratedProduct.id} container alignItems="center" className={classes.variantContainer}>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Box className={classes.variantProductImageWrapper}>
                        <Img
                          src={integratedProduct.image || '/images/public/nopic.jpg'}
                          alt={integratedProduct.name}
                          className={classes.swagupProductImage}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={5} style={{ paddingLeft: 16, position: 'relative' }}>
                      <Typography
                        variant="body3MediumInter"
                        title={integratedProduct.product_name}
                        className={classes.nameText}
                      >
                        {integratedProduct.product_name}
                      </Typography>
                      <Box className={classes.itemVariant}>
                        <Typography variant="body4MediumInter">{integratedProduct.title}</Typography>
                      </Box>
                      <Typography variant="body4RegularInter">SKU: {integratedProduct.sku}</Typography>
                    </Grid>
                    <Grid item xs />
                    <Grid item xs={5}>
                      <CTAPanel
                        storeDomain={integratedProduct.store_domain?.replace('.myshopify.com', '')}
                        platformName={integratedProduct.platform_name}
                        productExternalId={integratedProduct.product_external_platform_id}
                        onAction={() => onAction(integratedProduct.id)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const EmptyState = ({ title, subtitle, onAction }) => {
  const classes = useStyles();
  return (
    <Box style={{ paddingTop: 24, paddingBottom: 24 }}>
      <Typography variant="body3MediumInter" className={classes.emptyProductsTitle}>
        {title}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item>
          <Box className={classes.emptyProductsImageWrapper}>
            <Img
              srcSet={imageSrcSet('/images/integrations/empty-products.png')}
              className={classes.swagupProductImage}
              alt="empty-products"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="body4RegularInter" className={classes.emptyProductsText}>
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Button size="small" variant="primary" className={classes.actionCTA} onClick={onAction}>
            Close Back
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const groupByProperty = (arr, property) => {
  return arr.reduce((groups, item) => {
    const key = item[property];
    if (!groups[key]) {
      groups[key] = { store_name: key, products: [] };
    }
    groups[key].products.push(item);
    return groups;
  }, {});
};

const IntegratedProductsModal = ({ platformName, onClose, product }) => {
  const [infoMessage, setInfoMessage] = useState();
  const { getConnectedPlatform } = useIntegrations();
  const selectedPlatform = getConnectedPlatform(platformName);
  const { data: integratedProductsData, isLoading } = useQuery(
    [apiPaths.integrationProducts, product?.id, selectedPlatform?.connectionAccess?.access_token],
    () =>
      integrationsApi.fetchIntegratedProducts(
        selectedPlatform?.connectionAccess?.access_token,
        product?.id,
        platformName
      ),
    {
      enabled: !!(platformName && product?.id && selectedPlatform?.connectionAccess?.access_token)
    }
  );

  const integratedProducts = integratedProductsData?.results || [];
  const integratedProductsGroupByed = Object.values(groupByProperty(integratedProducts, 'store_name'));
  const queryClient = useQueryClient();
  const disconnectProduct = useMutation(
    variant => integrationsApi.disconnectProduct(selectedPlatform?.connectionAccess?.access_token, variant),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([apiPaths.integrationProducts]);
      },
      onError: () => {
        setInfoMessage('There was an error. Please contact your AE.');
      }
    }
  );

  const onDisconnectProduct = variant => {
    disconnectProduct.mutate(variant);
  };

  const handleToastClose = () => {
    setInfoMessage();
  };
  const handleOnClose = () => {
    if (isEmpty(integratedProductsGroupByed)) {
      queryClient.invalidateQueries(apiPaths.accountProducts);
    }
    queryClient.invalidateQueries([apiPaths.integrationProducts]);
    onClose();
  };

  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="integrated-products-title"
      aria-describedby="integrated-products-description"
      open={!!platformName}
      onClose={handleOnClose}
      className={classes.automationModal}
    >
      <Box className={classes.modalViewDetailsContainer}>
        {infoMessage && (
          <Grid container justifyContent="center" style={{ position: 'relative' }}>
            <Alert
              onClose={handleToastClose}
              delayTime={5000}
              className={classes.floatingInfoMessage}
              fontStyles={{ fontSize: 12, padding: 0 }}
              severity="error"
            >
              {infoMessage}
            </Alert>
          </Grid>
        )}
        <Grid container className={classes.modalBody}>
          <Grid container alignItems="center" className={classes.modalHeaderBar}>
            <Grid item>
              <Box className={classes.close} />
            </Grid>
            <Grid item xs>
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="body1SemiBoldInter">Integrated Products</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton className={classes.close} onClick={handleOnClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <Box className={classes.poductContainer}>
              <Grid container alignItems="center" spacing={4}>
                <Grid item>
                  <Box className={classes.swagupProductImageWrapper}>
                    <Img
                      src={product.image || '/images/public/nopic.jpg'}
                      alt={product.name}
                      className={classes.swagupProductImage}
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Typography variant="body3MediumInter" className={classes.swagupProductName}>
                    {product.name}
                  </Typography>
                  <Typography variant="body4RegularInter">{product.record_type}</Typography>
                </Grid>
                <Grid item>
                  <Box className={classes.swagupImageWrapper}>
                    <Img src="/images/public/mini-logo.svg" alt="SwagUp" className={classes.swagupProductImage} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid container justifyContent="center" style={{ marginTop: 16 }}>
              <Grid item>
                <Box className={classes.platformImageWrapper}>
                  <Img
                    srcSet={imageSrcSet(getItegrationLogoImageUrl(platformName))}
                    alt="platform icon"
                    className={classes.swagupProductImage}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" className={classes.integratedProductsPanel}>
              {isLoading ? (
                <Loader absolute transparent small />
              ) : isEmpty(integratedProductsGroupByed) ? (
                <EmptyState
                  title={`There are no ${toSentenceCase(platformName)}'s products connected to ${product.name}`}
                  subtitle="You can go back and start some new integrations"
                  onAction={handleOnClose}
                />
              ) : (
                integratedProductsGroupByed.map(integratedStore => (
                  <IntegratedProductsList
                    key={integratedStore.store_name}
                    integratedStore={integratedStore}
                    onAction={onDisconnectProduct}
                  />
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default IntegratedProductsModal;
