import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = withStyles({
  root: {
    maxWidth: 650,
    borderRadius: 10,
    padding: '4px 20px',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 'normal'
  },
  standardError: {
    color: '#f44336',
    backgroundColor: '#fef0ef'
  }
})(props => <MuiAlert icon={false} severity="error" {...props} />);

const ErrorAlert = ({ error, onError, delayTime = 30000, ...props }) => {
  useEffect(() => {
    const id = error && onError ? setTimeout(onError, delayTime) : null;
    return () => {
      if (id) clearTimeout(id);
    };
  }, [error, onError, delayTime]);

  return <Alert {...props}>{error}</Alert>;
};

export default ErrorAlert;
