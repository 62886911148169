export default theme => ({
  root: {
    paddingLeft: 20,
    '&:hover': {
      backgroundColor: '#E9F2FF'
    },
    '&:active': {
      // Define active styles here
      // For example:
      backgroundColor: '#E9F2FF',
      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)'
    }
  },
  icon: {
    minWidth: 48
  },
  text: {
    fontSize: 12,
    lineHeight: 'normal',
    fontFamily: 'Inter',
    color: ({ active, selected }) => (active && '#125CFF') || (selected && 'black') || theme.palette.neutral.darkBlue,
    opacity: ({ active, selected }) => (active || selected ? 1 : 0.5),
    fontWeight: ({ active }) => (active ? 600 : 400)
  }
});
