import { SET_ACCOUNT_PRODUCT } from './types';
import DashBoard from '../apis/DashBoard';
import apiPaths from '../helpers/apiPaths';
import { okAndLog, errorAndLog } from '../helpers/utils';
import log from '../logger';

// TODO this shouldn't be necessary in the near future
const patchNegativeStock = accountProduct => ({
  ...accountProduct,
  stock: accountProduct.stock.map(s => ({
    ...s,
    quantity: Math.max(0, s.quantity)
  }))
});

export const setAccountProduct = payload => dispatch => dispatch({ type: SET_ACCOUNT_PRODUCT, payload });

export const loadAccountProduct = pId => async dispatch => {
  log.debug('loadAccountProduct Action - Entering');
  try {
    const apiCall = await DashBoard.get(apiPaths.accountProduct(pId));
    if (apiCall.status === 200) {
      setAccountProduct(patchNegativeStock(apiCall.data))(dispatch);
      return okAndLog('loadAccountProduct', apiCall.status, apiCall.data);
    }
    return errorAndLog('loadAccountProduct', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('loadAccountProduct', e.status, e.data);
  }
};

export const setAccountProductStatus = ({ id, newStatus }) =>
  DashBoard.patch(
    apiPaths.accountProduct(id),
    { status: newStatus },
    { validateStatus: status => status === 200 }
  ).then(response => response.data);
