import React from 'react';
import { Grid, Table, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import styles from './styles/shippingSummary';
import AddressInfo from './AddressInfo';
import DeliveryInfo from './DeliveryInfo';
import { ImgWithHandler } from '../global/ImgUtils';
import { moneyStr } from '../../helpers/utils';
import log from '../../logger';

const useStyles = makeStyles(styles);

const ShippingSummary = ({ employeeInfo, deliveryInfo, products }) => {
  log.debug('employeeInfo:', employeeInfo, 'deliveryInfo:', deliveryInfo, 'products:', products);

  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={6} md={5}>
        <Grid container className={classes.infoContainer}>
          <Grid item xs={12}>
            <p className={classes.subTitle}>SHIPPING ADDRESS</p>
            <AddressInfo
              firstName={employeeInfo.first_name}
              lastName={employeeInfo.last_name}
              address1={employeeInfo.shipping_address1}
              address2={employeeInfo.shipping_address2}
              city={employeeInfo.shipping_city}
              state={employeeInfo.shipping_state}
              zip={employeeInfo.shipping_zip}
              country={employeeInfo.shipping_country}
            />
          </Grid>
          <Grid style={{ paddingTop: 40 }} item>
            <DeliveryInfo deliveryMethod={deliveryInfo.deliveryMethod} shippingNote={deliveryInfo.shippingNote} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5} md={7} className={classes.productsContainer}>
        <Table>
          <TableBody>
            {products.map(product => (
              <TableRow key={`${product.id}-${product.size.id}`}>
                <TableCell colSpan={2} className={classes.tableCell}>
                  <Grid container>
                    <Grid item className={classes.imgOuterContainer}>
                      <div className={classes.imgContainer}>
                        <ImgWithHandler src={product.image} alt={product.name} width={78} height={78} />
                      </div>
                    </Grid>
                    <Grid item xs style={{ paddingLeft: 16 }}>
                      <p className={classes.packTitle}>
                        <b>{product.name}</b>
                      </p>
                      <p className={classes.itemDesc}>Color: {product.theme_color || product.color || 'Custom'}</p>
                      <p className={classes.itemDesc}>Size: {product.size.name}</p>
                      <p className={classes.itemDesc}>Qty: {product.quantity}</p>
                      <p className={classes.itemDesc}>Price: {moneyStr(product.price)}</p>
                      <p className={classes.packTotal}>Total: {moneyStr(product.total)}</p>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default ShippingSummary;
