import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Img } from '../../../../global/ImgUtils';
import { productImageBasedOnStatus } from '../../../../global/proofsCommon';
import { moneyStr, isOneSize, sumByQuantity, onlyNaturalNumberOrEmpty } from '../../../../../helpers/utils';
import { useOrder } from '../OrderContext';
import { SizesListMultiSize, SizesListOneSize } from '../common/SizesList';
import styles from './WarehouseCard.styles';
import useStorageInfo from '../hooks/useStorageInfo';
import useUnallocatedPerProduct from '../hooks/useUnallocatedPerProduct';
import WarehousePriceTooltip from './WarehousePriceTooltip';
import { useSizes } from '../../../../../hooks';
import { mapSizesWithActiveField, doesInactiveSizeHasQuantity } from '../common/utilsOrder';

const useStyles = makeStyles(styles);

const WarehouseCard = ({ product, warehouseInfo, onSizesChange }) => {
  const classes = useStyles();

  const { sizes, price, category } = warehouseInfo;
  const { warehouseProofs } = useOrder();
  const warehouseProof = warehouseProofs.find(wp => wp.proof.id === product.id);
  const unallocated = useUnallocatedPerProduct()[product.id].total;

  const { data } = useSizes();

  const handleChangeQty = size => e => {
    e.target.value = onlyNaturalNumberOrEmpty(String(e.target.value));
    const quantity = e.target.value ? Number(e.target.value) : '';
    const newSizes = sizes.map(prevQuantity => (prevQuantity.size === size ? { size, quantity } : prevQuantity));

    onSizesChange({ sizes: newSizes });
  };

  const oldWarehouseQuantity = sumByQuantity(warehouseProof?.sizes ?? []);
  const newWarehouseQuantity = sumByQuantity(sizes);
  const realUnallocated = Math.max(unallocated + oldWarehouseQuantity - newWarehouseQuantity, 0);
  const totalPrice = newWarehouseQuantity * price;

  const { storageCategoryNames } = useStorageInfo();

  const categoryInfo = {
    name: storageCategoryNames[category] === 'Pack' ? 'Pack' : `${storageCategoryNames[category]} item`,
    price,
    quantity: newWarehouseQuantity,
    totalPrice
  };

  const allSizes = mapSizesWithActiveField(data, product);
  const hasInactiveSizes = sizes.some(doesInactiveSizeHasQuantity(allSizes));

  return (
    <Box
      key={product.id}
      width="100%"
      padding="20px 24px"
      margin="12px 0"
      border={`solid 1px ${hasInactiveSizes ? '#fa902d' : '#f0f2f5'}`}
      borderRadius="10px"
      role="row"
    >
      <Grid container>
        <Img
          src={productImageBasedOnStatus(product.product, 78, 78)}
          width={78}
          height={78}
          className={classes.image}
        />
        <Box flex={1} marginLeft="16px">
          <p className={classes.name}>{product.product.name}</p>
          <p className={classes.unallocated}>
            Qty left: <span className="bold">{realUnallocated}</span>
          </p>
        </Box>
      </Grid>
      <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
        {isOneSize(product.sizes) ? (
          <SizesListOneSize
            currentQuantity={sizes[0].quantity}
            sizes={product.sizes}
            onQtyClick={value => handleChangeQty(sizes[0].size)({ target: { value } })}
            customSizeInputProps={{
              onChange: handleChangeQty(sizes[0].size),
              placeholder: 'Custom qty'
            }}
          />
        ) : (
          <SizesListMultiSize
            sizes={allSizes}
            idSelector={size => size.id}
            nameSelector={size => size.name}
            inputPropsPerSize={size => {
              const sizeEnabled = product.sizes.find(s => s.size.id === size.id);
              const quantity = sizes.find(s => s.size === size.id);
              return {
                currentQuantity: quantity?.quantity,
                value: sizeEnabled && (quantity?.quantity ?? ''),
                placeholder: sizeEnabled && '0',
                disabled: !sizeEnabled,
                onChange: handleChangeQty(size.id),
                style: { width: 44 }
              };
            }}
          />
        )}
      </Grid>
      <Grid container justifyContent="space-between" style={{ marginTop: 42 }}>
        <Box>
          <p className={classes.summaryTitle}>Quantity Selected</p>
          <p className={classes.summaryValue}>{newWarehouseQuantity}</p>
        </Box>
        <Box textAlign="right">
          <WarehousePriceTooltip storageCategories={[categoryInfo]}>
            <Grid container alignItems="center">
              <InfoOutlined className={classes.infoIcon} />
              <p className={classes.summaryTitle}>Storage price</p>
            </Grid>
          </WarehousePriceTooltip>
          <p className={classes.summaryValue}>{moneyStr(totalPrice)}</p>
        </Box>
      </Grid>
    </Box>
  );
};

export default WarehouseCard;
