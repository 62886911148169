import React from 'react';
import { Grid, makeStyles, Snackbar } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import CustomSpiner from './CustomSpiner';

const useStyles = makeStyles(() => ({
  snackMessage: {
    width: 240,
    height: 48,
    padding: '0px 16px',
    background: '#3B4048',
    border: '1px solid #25282A',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px 2px rgba(0, 0, 0, 0.04)',
    borderRadius: 4
  },
  snackBar: { top: '112px !important', zIndex: 1 },
  snackMessageText: { color: '#ffffff' },
  snackProgress: {
    width: 16,
    height: 16,
    color: '#fffffff'
  }
}));

const SpinningSnackBar = ({ open, message, className }) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className={className || classes.snackBar}
      open={open}
    >
      <div className={classes.snackMessage}>
        <Grid container alignItems="center" style={{ height: '100%' }}>
          <Grid item xs>
            <Typography variant="body3MediumInter" className={classes.snackMessageText}>
              {message}
            </Typography>
          </Grid>
          <Grid item>
            <CustomSpiner />
          </Grid>
        </Grid>
      </div>
    </Snackbar>
  );
};

export default SpinningSnackBar;
