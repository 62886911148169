import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SearchBar from '../common/SearchBar';
import styles from './SelectRecipients.styles';

import { useLeftNavbar } from '../../../../../contexts/leftNavbar';

const useStyles = makeStyles(styles);

const SelectRecipientsHeader = ({ children, debouncedSetSearch, addRecipientsButton }) => {
  const leftNavBarContext = useLeftNavbar();

  const hideLeftNav = leftNavBarContext?.hideLeftNav || false;
  const { updatedSendSwagFlow, leftBarNavigation } = useFlags();
  const classes = useStyles({ slimHeader: updatedSendSwagFlow, leftBarNavigation, hideLeftNav });
  const { recipients } = useSelector(state => state.shipmentGroup);
  const [isSearchOpen, toggleIsSearchOpen] = React.useReducer(prevOpen => !prevOpen, false);

  return (
    <>
      {!isSearchOpen && (
        <p className={classes.title}>
          {recipients.length > 0 ? 'Selected' : 'Select'} Recipients
          {recipients.length > 0 && <span> ({recipients.length})</span>}
        </p>
      )}
      {recipients.length > 0 && (
        <>
          <Box
            display="flex"
            flex={leftBarNavigation ? 'unset' : isSearchOpen ? '1' : '0.2 10%'}
            className={classes.animatedBox}
          >
            <SearchBar open={isSearchOpen} toggleOpen={toggleIsSearchOpen} onChange={debouncedSetSearch} />
            <div
              className={clsx(classes.hideableFilters, {
                hidden: isSearchOpen
              })}
            >
              {children}
            </div>
          </Box>
          {!isSearchOpen && addRecipientsButton}
        </>
      )}
    </>
  );
};

export default SelectRecipientsHeader;
