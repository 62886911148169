import React from 'react';
import { Grid, InputAdornment, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { SortBy, OutlinedSearchField } from '../../../global/Filters';

const useTopFilterStyles = makeStyles(theme => ({
  sortBy: {
    width: 266,
    [theme.breakpoints.down('md')]: { width: 222 },
    [theme.breakpoints.down('sm')]: { width: '100%' }
  }
}));

const dateOptions = [
  { value: '-created_at', text: 'Newest first' },
  { value: 'created_at', text: 'Oldest first' }
];

const TopFilters = ({ search, sort, onChangeSearch, onChangeSort }) => {
  const { sortBy } = useTopFilterStyles();

  return (
    <Grid container item spacing={4}>
      <Grid item md={4} sm={6} xs={12}>
        <OutlinedSearchField
          value={search}
          placeholder="Search by order #"
          onChange={onChangeSearch}
          fullWidth
          startAdornment={
            <InputAdornment position="start" style={{ marginLeft: 6 }}>
              <Search style={{ color: '#aaaaaa' }} />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <SortBy title="Sort by:" value={sort} onChange={onChangeSort} items={dateOptions} classes={{ sortBy }} />
      </Grid>
    </Grid>
  );
};

export { TopFilters };
