import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@swagup-com/components';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#EBF1FB',
    borderRadius: 10,
    margin: '20px 0px',
    width: '100%',
    height: 100,
    maxHeight: 100,
    padding: '0px 65px 0px 20px'
  },
  bannerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#F2F6FF',
    borderRadius: 10,
    width: '760px',
    height: 60,
    maxHeight: 60,
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;'
  },
  image: {
    width: 70,
    height: 80,
    objectFit: 'contain'
  },
  imageModal: {
    width: 40,
    height: 50,
    objectFit: 'contain'
  },
  bannerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 25
  },
  highlighted: {
    color: '#125CFF'
  }
}));

const Banner = ({
  image,
  title,
  subtitle,
  buttonLabel,
  buttonLink,
  highlightedWord,
  isInsideModal,
  FreeInventoryStorageFee
}) => {
  const classes = useStyles();

  const highlightWord = (title, word) => {
    const parts = title.split(word);
    return (
      <>
        {parts[0]}
        <span className={classes.highlighted}>{word}</span>
        {parts[1]}
      </>
    );
  };

  return (
    <Grid container className={isInsideModal ? classes.bannerModal : classes.banner}>
      <Grid className={classes.bannerContent}>
        <Grid item>
          <img
            src={image}
            alt={title}
            className={isInsideModal ? classes.imageModal : classes.image}
            width={isInsideModal ? 40 : 70}
            height={isInsideModal ? 50 : 80}
          />
        </Grid>
        <Grid alignItems="center">
          <Typography variant="subtitle3SemiBoldInter" style={{ color: '#125CFF' }}>
            {subtitle}
          </Typography>
          <Typography variant={isInsideModal ? 'h6SemiBoldInter' : 'h4SemiBoldInter'}>
            {highlightWord(title, highlightedWord)}
          </Typography>
        </Grid>
      </Grid>
      {FreeInventoryStorageFee ? null : (
        <Grid>
          <Button variant="primary" component={Link} to={buttonLink} style={{ height: 50, backgroundColor: '#125CFF' }}>
            <Typography variant="subtitle3SemiBoldInter" style={{ color: '#ffffff' }}>
              {buttonLabel}
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Banner;
