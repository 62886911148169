import React, { useState } from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { isEmpty } from 'lodash';
import { CustomSlimTextField, CustomMinimalTableRow } from '../storeCommon';
import styles from './styles/variantsEdit';
import { BlueSwitch } from '../../account/AccountSharedComponents';

const useStyles = makeStyles(styles);

const getSortOrder = sizeName => {
  switch (sizeName) {
    case 'XXS':
    case 'One Size':
      return 1;
    case 'XS':
      return 2;
    case 'S':
      return 3;
    case 'M':
      return 4;
    case 'L':
      return 5;
    case 'XL':
      return 6;
    case '2XL':
      return 7;
    case '3XL':
      return 8;
    case '4XL':
      return 9;
    case '5XL':
      return 11;
    case '6XL':
      return 12;
    default:
      return 10;
  }
};

const VariantStockRow = ({ stock, classes, onVisibilityChange, onPriceChange, sellingOutOfStock }) => {
  return (
    <CustomMinimalTableRow>
      <TableCell align="center" className={classes.stockCell}>
        <BlueSwitch size="small" checked={stock.isVisible} onChange={onVisibilityChange} />
      </TableCell>
      <TableCell className={classes.stockCell} align="center">
        <Typography variant="body3MediumInter">{stock.sizeName}</Typography>
      </TableCell>
      <TableCell className={classes.stockCell} align="center">
        <Grid container justifyContent="center">
          <Grid item>
            <CustomSlimTextField
              value={stock.price}
              placeholder="Price"
              fullWidth
              onChange={onPriceChange}
              InputProps={{
                startAdornment: <span id="start-adornment">$</span>,
                className: classes.inputTex
              }}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={classes.stockCell} align="center">
        <Typography variant="body4MediumInter">{stock.quantity}</Typography>
      </TableCell>
      {sellingOutOfStock && (
        <TableCell className={classes.stockCell} align="center">
          <Typography variant="body4MediumInter">{stock.requestedOutOfStock}</Typography>
        </TableCell>
      )}
    </CustomMinimalTableRow>
  );
};
// imageUrl: '/images/public/nopic.jpg'
const imageSlots = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

const VariantsEdit = ({ variants = [], onVariantEdit, sellingOutOfStock }) => {
  const defaulVariant = variants?.[0] || {};
  const [selectedImage, setSelectedImage] = useState({});
  const [imageError, setImageError] = useState();
  const handleOnStockVisibilityChange = (stockId, visibility) => {
    onVariantEdit({
      id: defaulVariant.id,
      stock: defaulVariant.stock.map(s => (s.id === stockId ? { ...s, isVisible: visibility } : s))
    });
  };
  const handleOnStockPriceChange = (stockId, price) => {
    onVariantEdit({
      ...defaulVariant,
      stock: defaulVariant.stock.map(s => (s.id === stockId ? { ...s, price } : s))
    });
  };

  const handleOnImageUpload = url => {
    if (!isEmpty(defaulVariant)) {
      if (!isEmpty(selectedImage)) {
        onVariantEdit({
          ...defaulVariant,
          images: defaulVariant.images.map(i => (i.id === selectedImage.id ? { ...i, url } : i))
        });
      } else if (defaulVariant.images.length < 5)
        onVariantEdit({
          ...defaulVariant,
          images: [
            ...defaulVariant.images,
            {
              id: defaulVariant.images.length + 1,
              name: `${defaulVariant.name}-variant-${defaulVariant.images.length + 1}`,
              url
            }
          ]
        });
    }
  };

  const classes = useStyles();

  const images = defaulVariant.images || [];
  return (
    <Box>
      <Typography variant="body1SemiBoldInter" className={classes.variantSectionTitle}>
        Sizing
      </Typography>

      <TableContainer>
        <Table stickyHeader className={classes.orderTable}>
          <TableHead>
            <TableRow>
              <TableCell align="center">VISIBILITY</TableCell>
              <TableCell scope="row" width="25%" align="center">
                SIZE
              </TableCell>
              <TableCell className={classes.cellCentered} align="center">
                PRICE
              </TableCell>
              <TableCell className={classes.cellCentered} align="center">
                INVENTORY
              </TableCell>
              {sellingOutOfStock && (
                <TableCell className={classes.cellCentered} align="center">
                  BACKORDERS
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {defaulVariant.stock
              ?.sort((a, b) => getSortOrder(a.sizeName) - getSortOrder(b.sizeName))
              .map(stock => (
                <VariantStockRow
                  key={stock.id}
                  stock={stock}
                  onVisibilityChange={({ target }) => handleOnStockVisibilityChange(stock.id, target.checked)}
                  onPriceChange={({ target }) => handleOnStockPriceChange(stock.id, target.value)}
                  sellingOutOfStock={sellingOutOfStock}
                  classes={classes}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VariantsEdit;
