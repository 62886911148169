import { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMembership } from '../contexts/membershipContext';

const useIsEnterpriseFlowActive = () => {
  const [isEnterpriseFlowActive, setIsEnterpriseFlowActive] = useState(false);
  const { company } = useMembership();
  const { enterpriseGlobalCheckoutFlowTemp031722: enterpriseCheckoutActive } = useFlags();
  const isEnterpriseAccount = company.enterprise;
  useEffect(() => {
    setIsEnterpriseFlowActive(isEnterpriseAccount && enterpriseCheckoutActive);
  }, [isEnterpriseAccount, enterpriseCheckoutActive]);

  return isEnterpriseFlowActive;
};

export default useIsEnterpriseFlowActive;
