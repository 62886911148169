import { EXIT_INTEGRATION, CLEAR_INTEGRATION, SET_INTEGRATIONS } from '../actions/types';

const serializeInitialState = localStorage.getItem('swagupIntegrations');
const initialState = serializeInitialState ? JSON.parse(serializeInitialState) : [];

const IntegrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTEGRATIONS: {
      const nextState = [...action.payload];

      const serializedState = JSON.stringify(nextState);
      localStorage.setItem('swagupIntegrations', serializedState);
      return nextState;
    }
    case EXIT_INTEGRATION: {
      const nextState = [
        ...state.filter(integration => integration.connectedPlatform !== action.payload?.connectedPlatform)
      ];

      const serializedState = JSON.stringify(nextState);
      localStorage.setItem('swagupIntegrations', serializedState);
      return nextState;
    }
    case CLEAR_INTEGRATION: {
      const nextState = [];

      const serializedState = JSON.stringify(nextState);
      localStorage.setItem('swagupIntegrations', serializedState);
      return nextState;
    }
    default:
      return state;
  }
};

export default IntegrationReducer;
