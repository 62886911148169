import React, { useRef } from 'react';

const Img = ({ src, alt, fallback = '/images/public/nopic.jpg', ...props }) => {
  const imgRef = useRef();

  return (
    <img
      src={src || fallback}
      alt={alt}
      ref={imgRef}
      onError={() => {
        imgRef.current.src = fallback;
      }}
      {...props}
    />
  );
};

export default Img;
