import commonStyles from './commonStyles';

const { title, line } = commonStyles;

const invText = {
  fontWeight: 400,
  padding: 0,
  lineHeight: 1,
  height: 16
};

const styles = () => ({
  title,
  line,
  invNameDisabled: {
    ...invText,
    color: '#8D9299'
  },
  invName: {
    ...invText,
    color: '#3577D4',
    '&:hover': {
      fontWeight: 500,
      color: '#004ca2'
    }
  },
  tableHead: {
    '& .MuiTableCell-root': {
      fontSize: 12
    }
  },
  tableRow: {
    '& > .MuiTableCell-root': {
      fontSize: 14
    },
    '&:hover': {
      backgroundColor: 'rgba(211, 227, 247, 0.25)'
    }
  },
  invoiceRow: {
    height: '67px',
    '&:hover': { backgroundColor: 'rgba(211, 227, 247, 0.25)' },
    '& .MuiTableCell-root': {
      fontSize: 14,
      lineHeight: '10px'
    }
  }
});

export default styles;
