import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams, useLocation, Redirect } from 'react-router-dom';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import styles from './styles/selectedMockup';
import apiPaths from '../../../../helpers/apiPaths';
import designsApi from '../../../../apis/swagup/designs';
import templateApi from '../../../../apis/swagup/templates';
import Form from './Form';
import Mockup from './Mockup';
import GoBack from '../../../shared/GoBack';
import Loader from '../../../global/Loader';
import gtm from '../../../../utils/gtm';
import { formatDate } from '../../../../helpers/utils';
import { getAmount } from './common';

const useStyles = makeStyles(styles);

const SelectedMockup = ({ design, userName }) => {
  const history = useHistory();
  const location = useLocation();
  const { designId, collageId } = useParams();

  const {
    domain,
    company_name: companyName,
    pack_template: packTemplate,
    user_provided_logo: userProvidedLogo
  } = design;

  const { data: packTemplateMinimum, isLoading: isMinimumPending } = useQuery(
    [apiPaths.packTemplates, packTemplate, 'minimum'],
    () => templateApi.getMinimum(packTemplate)
  );

  const collage = design.collages.find(c => c.id === +collageId);

  const submitOrder = useMutation(data => designsApi.createOrder({ data, designId }), {
    onSuccess: () => {
      gtm.submitAIMockupOrderRequest(
        companyName,
        domain,
        packTemplate,
        designId,
        collage.id,
        collage.variant_no,
        userProvidedLogo
      );
      history.push({ pathname: '/onboarding/mockups/thanks', state: { userName } });
    }
  });

  const classes = useStyles();

  const samplePack = location.state?.samplePack;

  const submitRequest = data =>
    submitOrder.mutate({
      pack_quantity: parseInt(data.quantity, 10),
      inhands_date: formatDate(data.date, 'YYYY-MM-DD'),
      sample_pack: samplePack.id,
      collage: collage.id
    });

  if (history.action === 'POP') {
    return <Redirect to="/onboarding/welcome" />;
  }

  return (
    <div>
      {(submitOrder.isLoading || isMinimumPending) && <Loader />}
      <GoBack goBackText="Back" />
      <Box mt="10px">
        <Box mb="50px">
          <Typography variant="h5">Swag Pack #{collage.variant_no}</Typography>
          <Typography variant="body2" className={classes.text}>
            Love this design? Submit a design request to save it to your SwagUp Dashboard.
          </Typography>
        </Box>
        <Grid container item xs={12}>
          <Grid container item lg={6} xs={12} className={classes.imageContainer}>
            <Mockup image={collage.collage_url} variant={collage.variant_no} />
          </Grid>
          <Grid item lg={6} xs={12}>
            {!isMinimumPending && (
              <Form
                submitRequest={submitRequest}
                packsAmount={Math.max(packTemplateMinimum, getAmount[samplePack?.packs_per_year])}
                minQuantity={packTemplateMinimum}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SelectedMockup;
