import React, { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Helmet } from '../shared';
import tags from '../../apis/seoTags';
import { postSlackMessage } from '../../apis/slack';
import { useProfile } from '../../hooks';
import { joinFields } from '../../helpers/utils';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 80,
    paddingBottom: 140
  },
  error: { color: theme.palette.error.main },
  image: {
    width: 296
  },
  title: {
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 'normal'
  },
  subtitle: {
    fontSize: 14,
    color: '#787b80',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    marginTop: 8
  },
  backButton: {
    height: 'unset',
    padding: '22px 32px'
  }
}));

const getAccountName = profile => {
  if (profile?.related_accounts) {
    const account = profile?.related_accounts.find(ra => ra.id === profile.current_account);
    return `${account.name} - ${account.id}`;
  }
  return false;
};

const prepareSlackMessage = (error, profile, pathname, fullURL) => {
  let msg = '*SwagUp’s Dashboard*\n';
  msg += `*Type:* ERROR \n`;
  msg += `*Environment:* ${
    window.location.hostname?.includes('http://app.swagup.com') ? 'Production' : 'Staging/Development'
  } - ${window.location.hostname}\n`;
  msg += `*Date:* ${new Date().toString()}\n`;
  msg += '\n*User Info*\n';
  msg += profile?.first_name ? `*User Name:* ${joinFields([profile.first_name, profile.last_name], ' ')}\n` : '';
  msg += profile?.email ? `*User Email:* ${profile.email}\n` : '';
  msg += getAccountName(profile) ? `*Account Name:* ${getAccountName(profile)}\n` : 'Logged off User';

  msg += '\n*Error Info*\n';
  msg += `*Dashboard Path:* ${pathname || window.location.pathname}\n`;
  msg += `*Full URL:* ${fullURL || window.location.href}\n`;
  msg += `*User Error Message:* ${error?.message || 'none'}\n`;
  msg += `*Internal Stack:* ${error?.stack || 'none'}`;
  return msg;
};

const ErrorFound = ({ error, resetErrorBoundary }) => {
  const classes = useStyles();
  const { data: profile } = useProfile();

  const location = useLocation();
  const { targetError, pathname, fullURL } = location.state || {};

  useEffect(() => {
    if (!window.location.hostname.includes('localhost'))
      postSlackMessage(prepareSlackMessage(targetError || error, profile, pathname, fullURL));
  }, []);

  const history = useHistory();

  return (
    <div className={classes.root}>
      <Helmet tags={tags.error} />
      <Grid container direction="column" alignItems="center" spacing={4}>
        <Grid item>
          <img src="/images/error/group-137.png" alt="Confirmed" className={classes.image} />
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <Typography variant="body1" role="alert" className={classes.error}>
            {error?.message}
          </Typography>
          <Typography variant="h1" role="alert" className={classes.title}>
            Houston, we have a problem
          </Typography>
          <Typography variant="h2" className={classes.subtitle}>
            We&apos;ve logged this issue and are working on it
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="primary" onClick={resetErrorBoundary || history.goBack} className={classes.backButton}>
            Back to previous page
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorFound;
