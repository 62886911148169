const styles = theme => ({
  container: {
    padding: '64px 0 145px 0',
    textAlign: 'center'
  },
  externalContainer: { backgroundColor: '#e9f0fa' },
  title: {
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '3.5rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.75rem'
    },
    marginBottom: '0 !important'
  },
  subText: {
    color: '#0f2440',
    opacity: 0.54,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.25rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal'
  },
  button: {
    borderRadius: '32px',
    backgroundColor: '#3577d4',
    padding: '26px 84px',
    border: 'none',
    cursor: 'pointer',
    marginTop: '45px'
  },
  buttonText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.75,
    letterSpacing: 'normal',
    color: '#ffffff',
    textTransform: 'none'
  }
});

export default styles;
