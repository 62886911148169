import React, { useRef, useState, useEffect } from 'react';
import { Drawer, Grid, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from 'react-query';
import styles from './AddProductsDrawer.styles';
import { useQueryFilterValidated } from '../../../../../hooks/useFilters';
import { useQueryParams } from '../../../../../hooks';
import accountProductsApi from '../../../../../apis/swagup/accountProducts';
import ProductsHome from '../../../../products/ProductsHome';

const useStyles = makeStyles(styles);

const PRODUCT_CHUNK_SIZE = 32;

const AddProductsDrawer = ({ open, onClose, excludedProducts }) => {
  const classes = useStyles();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [limit, setLimit] = useState(PRODUCT_CHUNK_SIZE);

  const recordType = useQueryFilterValidated(
    'record_type',
    (id, value) => ['product', 'pack'].includes(id) && value.split(',').length === 1
  );

  const inventory = useQueryFilterValidated(
    'inventory',
    (id, value) => ['available', 'out_of_stock'].includes(id) && value.split(',').length === 1
  );

  const status = useQueryFilterValidated('status');
  const category = useQueryFilterValidated('category');
  const canShipInternational = useQueryFilterValidated(
    'can_ship_international',
    (id, value) => ['true', 'false'].includes(id) && value.split(',').length === 1
  );
  const ordering = useQueryFilterValidated(
    'ordering',
    (id, value) => ['-created_at', 'created_at', 'name', '-name'].includes(id) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const query = useQueryParams();
  const search = query.get('search') || '';
  const accountProductsParams = {
    search,
    record_type: recordType,
    inventory,
    status,
    category,
    can_ship_international: canShipInternational,
    ordering
  };

  const elementRef = useRef(null);

  const { data, isLoading } = useQuery(
    [accountProductsParams, limit],
    () => accountProductsApi.fetch({ ...accountProductsParams, limit }),
    {
      onSuccess: () => setIsLoadingMore(false)
    }
  );

  const handleLoadMore = () => {
    setLimit(l => l + PRODUCT_CHUNK_SIZE);
    setIsLoadingMore(true);
  };

  const listenToScroll = ({ target }) => {
    const scrolledBottom = target && target.scrollHeight - 32 < target.scrollTop + target.clientHeight;
    const canLoadMore = data?.count >= limit || (!data?.count && limit > PRODUCT_CHUNK_SIZE);
    if (scrolledBottom && canLoadMore) handleLoadMore();
  };

  useEffect(() => {
    setLimit(PRODUCT_CHUNK_SIZE);
  }, [search, category, recordType, status]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.addProductsDrawer }}
      PaperProps={{ id: 'product-selection-drawer', ref: elementRef, onScroll: listenToScroll }}
    >
      <Grid className={classes.addProductsContainer}>
        <Grid item>
          <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
        </Grid>
        <ProductsHome isInsideDrawer closeDrawer={onClose} excludedProducts={excludedProducts} />
      </Grid>
    </Drawer>
  );
};

export default AddProductsDrawer;
