import React from 'react';
import { Grid } from '@material-ui/core';

const SigleContentPage = ({ children }) => (
  <Grid container justifyContent="center" alignItems="center" style={{ height: '100%', width: '100%' }}>
    <Grid item>{children}</Grid>
  </Grid>
);

export default SigleContentPage;
