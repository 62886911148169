import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet, CenteredGrid } from '../shared';
import tags from '../../apis/seoTags';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import ZapierIntegration from './ZapierIntegration';
import SwagUpIntegrationsHome from './SwagUpIntegrationsHome';

const styles = theme => ({
  container: {
    marginTop: 0,
    paddingTop: 40,
    paddingBottom: 42,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  titleContainer: {
    [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
  },
  modalViewDetailsContainer: {
    borderRadius: 12,
    background: 'white',
    padding: '0px 1px 12px 1px'
  },
  automationModal: {
    height: 'auto',
    maxHeight: 'fit-content',
    padding: 0,
    marginTop: '112px',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: 'white',
    width: 1440,
    overflow: 'hidden',
    [theme.breakpoints.only('lg')]: {
      width: 1200
    },
    [theme.breakpoints.only('md')]: {
      width: 900
    },
    [theme.breakpoints.only('sm')]: {
      width: 600
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  automationModalHeader: {
    padding: '0px 4px 0px 32px',
    height: '52px',
    borderBottom: '1px solid #E5E7E8'
  },
  automationModalHeaderText: {
    ...ellipsisStyles,
    color: '#131415',
    fontSize: '20px',
    lineHeight: ' 28px',
    letterSpacing: '-0.03em'
  },
  modalBody: {
    minHeight: '80vh'
  },
  tabPanel: {
    paddingTop: 16,
    width: '100%'
  },
  swipeableViews: {
    height: '100%',
    width: '100%',
    // minHeight: 'calc(100vh - 202px)',
    '& .react-swipeable-view-container': {
      height: '100%'
    },
    '& > div > div': {
      overflowX: 'hidden !important',
      overflowY: 'hidden !important'
    }
  }
});

const useStyles = makeStyles(styles);

const IntegrationsHome = () => {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const { eCommerceIntegrations } = useFlags();

  return (
    <>
      <Helmet tags={tags.integrations} />
      <CenteredGrid container justifyContent="center" className={classes.container}>
        {eCommerceIntegrations ? (
          <SwagUpIntegrationsHome />
        ) : (
          <>
            <Grid container alignItems="center" style={{ marginBottom: 24 }}>
              <Grid item xs>
                <Typography variant="h2BoldInter">Integrations</Typography>
              </Grid>
              <Grid item>
                <Button variant="primary" style={{ minWidth: 180, height: 56 }} onClick={() => setOpen(true)}>
                  Create an Automation
                </Button>
              </Grid>
            </Grid>
            <ZapierIntegration open={open} setOpen={setOpen} />
          </>
        )}
      </CenteredGrid>
    </>
  );
};

export default IntegrationsHome;
