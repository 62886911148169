const styles = theme => ({
  container: {
    padding: '44px 0 90px 0'
  },
  questions: {
    width: '100%',
    boxShadow: '0 40px 140px 0 rgba(26, 58, 98, 0.05)',
    borderRadius: '25px 25px 125px 25px',
    [theme.breakpoints.up('lg')]: {
      padding: ' 100px 75px'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '100px 75px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 24px !important'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '50px 24px'
    }
  },
  title: {
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '3.5rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.75rem'
    }
  },
  questionText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.5rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440',
    marginBottom: 20
  },
  answerText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.25rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: '#0f2440',
    opacity: 0.54
  },
  separator: {
    margin: '50px 0',
    opacity: 0.54,
    border: 'solid 1px #d4d9e2'
  }
});

export default styles;
