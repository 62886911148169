import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import templateStyles from './styles/storefrontTemplates';
import SectionsGenerator from './SectionsGenerator';

const useTempletesStyles = makeStyles(templateStyles);

// const isHexColor = color => {
//   if (!color) return false;
//   return /^#?([0-9A-F]{6}|[0-9A-F]{3})$/i.test(color);
// };
const DefaultView = ({ visualSettings }) => {
  const { sections, accentColor, buttonStyle } = visualSettings;
  return <SectionsGenerator sections={sections} accentColor={accentColor} buttonStyle={buttonStyle} />;
};

const StoreTemplates = ({ store }) => {
  const { projectName, visualSettings, generalSettings } = store;

  const { headerComponent } = visualSettings;
  const { logo, backgroundColor, fontColor, accentColor, fontFamily, footer } = visualSettings;

  const classes = useTempletesStyles({ backgroundColor, fontColor, accentColor, fontFamily });

  return (
    <Box container className={classes.root}>
      {generalSettings.announcement && (
        <Grid item xs={12} style={{ background: footer }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <p
                style={{
                  color: footer && footer !== 'unset' ? backgroundColor : fontColor,
                  fontSize: 4,
                  lineHeight: '6px'
                }}
              >
                {generalSettings.announcement}
              </p>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Box className={classes.section}>
        <Grid container alignItems="center">
          <Grid item>
            <div className={classes.logoContainer}>
              {logo && <img src={logo} alt={projectName} className={classes.logo} />}
            </div>
          </Grid>

          <Grid item xs>
            <Grid container alignItems="center" spacing={2}>
              {headerComponent?.links?.map(link => (
                <Grid key={link.id || link.idx} item>
                  <p style={{ color: fontColor, fontSize: 6, lineHeight: '6px' }}>{link.text}</p>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.section} style={{ height: '-webkit-fill-available' }}>
        <DefaultView visualSettings={visualSettings} generalSettings={generalSettings} classes={classes} />
      </Box>
    </Box>
  );
};
export default StoreTemplates;
