import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import toErrorPage from '../../helpers/toErrorPage';
import { reorderDateWithSlicing } from '../../helpers/utils';
import PdfViewer from '../global/PdfViewer';
import { InvoiceInfo, InvoiceBalance } from './InvoicesSharedComponents';
import InvoicePaymentDialog from './InvoicePaymentDialog';
import { fetchPublicInvoice } from '../../actions';
import { isPendingPayment } from '../../apis/constants';
import useApiCall from '../../hooks/useApiCall';
import Loader from '../global/Loader';
import { CenteredGrid } from '../shared';
import styles from './styles/invoicePaymentDialog';

const useStyles = makeStyles(styles);

const PublicInvoice = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isInvoicePaid, setIsInvoicePaid] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const invoice = useSelector(state => state.selectedInvoice);

  const [{ response, isApiCallPending, isApiError }] = useApiCall(() => dispatch(fetchPublicInvoice(id)));

  useEffect(() => {
    if (isApiError) toErrorPage(response, history);
  }, [isApiError, response, history]);

  const toggleOpenModal = () => setOpenModal(prevOpen => !prevOpen);

  const handlePaid = () => setIsInvoicePaid(true);

  const handleClose = () => {
    if (isInvoicePaid) {
      setIsInvoicePaid(false);
      history.replace('/');
    }
    setOpenModal(false);
  };

  if (isApiCallPending || !response) return <Loader />;

  return (
    <div className={classes.container}>
      <Grid container justifyContent="center">
        <CenteredGrid container item className={classes.mainCenteredGrid}>
          <Grid container item xs={5}>
            <Grid container item xs={4}>
              <InvoiceInfo title="Invoice" description={invoice.name} classes={classes} />
            </Grid>
            <Grid item container justifyContent="flex-end" xs={4}>
              <InvoiceInfo
                title="Due Date"
                description={reorderDateWithSlicing(invoice.due_date, '/')}
                classes={classes}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" item xs={7}>
            <Grid item xs={6}>
              <InvoiceBalance
                title={invoice.status === isPendingPayment ? 'Balance Due' : 'Amount Paid'}
                balance={invoice.total_plus_tax}
                classes={classes}
              />
            </Grid>
            <Grid container justifyContent="flex-end" item xs={6}>
              {invoice.status === isPendingPayment && !invoice.processing_payment && (
                <Button variant="primary" disabled={openModal} onClick={toggleOpenModal} style={{ width: 245 }}>
                  Pay now
                </Button>
              )}
            </Grid>
          </Grid>
        </CenteredGrid>
        <CenteredGrid container>
          <Grid item xs={12}>
            {invoice.invoice_pdf_url && (
              <PdfViewer width={window.innerWidth * 0.5} file={{ url: invoice.invoice_pdf_url }} />
            )}
          </Grid>
        </CenteredGrid>
      </Grid>
      <InvoicePaymentDialog
        invoice={invoice}
        isPublicInvoice
        open={openModal}
        onPaid={handlePaid}
        onClose={handleClose}
      />
    </div>
  );
};

export default PublicInvoice;
