import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = () => ({
  productSelectionDrawer: {
    width: 1000,
    overflowX: 'hidden'
  },
  productsDrawerHeader: {
    padding: '0px 40px',
    paddingLeft: 0,
    paddingBottom: 8
  },
  productsDrawerContent: {
    padding: '0px 0px'
  },
  productTableHeader: {
    padding: '4px 0px',
    paddingBottom: 0,
    textAlign: 'center',
    borderBottom: '1px solid #D6D8DB'
  },
  closeDrawer: {
    height: 24,
    width: 24,
    color: '#989EA4',
    cursor: 'pointer',
    '&:hover': {
      color: '#787E84'
    }
  },
  contentSection: {
    padding: '4px 40px'
  },
  productImageContainer: {
    width: 60,
    height: 60,
    padding: 4,
    borderRadius: 4,
    border: '1px solid #D6D8DB',
    position: 'relative'
  },
  productsHeaderCenter: {
    textAlign: 'center',
    color: '#4A4F54'
  },
  productsHeader: {
    color: '#4A4F54',
    fontSize: '14px',
    lineHeight: '20px'
  },
  lightText: {
    color: '#989EA4'
  },
  productsTableHeader: {
    marginTop: 20,
    borderTop: '1px solid #E5E7E8',
    borderBottom: '1px solid #E5E7E8'
  },
  productRowContainer: {
    padding: '12px 0px',
    borderBottom: '1px solid #D6D8DB',
    marginBottom: 14,
    overflow: 'hidden',
    position: 'relative',
    '& .product-row-container-grayout': {
      display: 'none',
      position: 'absolute',
      background: 'rgba(0,0,0,0.1)',
      height: 0,
      width: 0
    }
  },
  productLastRowContainer: {
    padding: '12px 0px',
    marginBottom: 14,
    overflow: 'hidden',
    position: 'relative',
    '& .product-row-container-grayout': {
      display: 'none',
      position: 'absolute',
      background: 'rgba(0,0,0,0.1)',
      height: 0,
      width: 0
    }
  },
  productRowContainerDisabled: {
    padding: '14px 0px',
    borderBottom: '1px solid #D6D8DB',
    marginBottom: 14,
    overflow: 'hidden',
    position: 'relative',
    '& p': {
      opacity: 0.5
    },
    '& .product-row-container-grayout': {
      display: 'block',
      position: 'absolute',
      background: 'rgba(192,193,192,0.4)',
      height: '100%',
      borderRadius: 4,
      width: '100%',
      zIndex: 1,
      top: 0,
      left: 0
    }
  },
  combinedText: {
    color: '#4A4F54',
    '& span': {
      color: '#4A4F54'
    },
    ...ellipsisStyles
  },
  productImage: {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  },
  productInfoContainer: {
    paddingLeft: 24,
    textAlign: 'left',
    display: 'grid'
  },
  rowProductName: {
    ...ellipsisStyles
  },
  productRowsContainer: {
    paddingTop: 12
  },
  addButton: {
    height: 48,
    minWidth: 96,
    borderRadius: 32
  },
  headerTextLeft: {
    paddingLeft: 136,
    textAlign: 'left'
  },
  systemBar: {
    padding: '10px 20px'
  },
  productSelectionBar: {
    position: 'sticky',
    top: 0,
    background: '#ffffff',
    zIndex: 1
  }
});

export default styles;
