import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { templatesApi } from '../apis/swagup';
import { fifteenMinutes } from '../helpers/helperConstants';
import apiPaths from '../helpers/apiPaths';

const usePresetPacks = ({ slug, enabled = true }) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const queryKey = slug ? [apiPaths.packTemplates, slug] : [apiPaths.packTemplates];
  const queryFn = slug
    ? () => templatesApi.fetchBySlug(slug, () => history.push('/preset-packs'))
    : () => templatesApi.fetch();
  const query = useQuery(queryKey, queryFn, {
    enabled,
    staleTime: fifteenMinutes,
    select: data => (enabled ? data : undefined)
  });

  const { data } = query;
  React.useEffect(() => {
    if (Array.isArray(data)) {
      data.forEach(pack => {
        queryClient.setQueryData([apiPaths.packTemplates, pack.slug], pack);
      });
    }
  }, [data, queryClient]);

  return query;
};

export default usePresetPacks;
