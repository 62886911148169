import React from 'react';
import clsx from 'clsx';
import { Grid, withStyles } from '@material-ui/core';
import { moneyStr } from '../../helpers/utils';
import { BlueSwitch } from '../account/AccountSharedComponents';
import styles from '../global/styles/PaymentSection';

const AvailableCredits = ({
  classes,
  isUsingCreditAvailable,
  orderCost,
  currentBalance,
  toggleUsingCreditAvailable
}) => (
  <Grid
    item
    container
    className={clsx(
      classes.containerCreditAvailable,
      isUsingCreditAvailable && classes.containerCreditAvailableSelected
    )}
    justifyContent="center"
  >
    <Grid item sm={7}>
      <p className={classes.creditAvailable}>Use Available Credits</p>
      <p className={classes.textCreditAvailable}>Use Available Credits to pay for the entire order</p>
    </Grid>
    <Grid item sm={2}>
      <p className={classes.orderCost}>Order cost</p>
      <p className={classes.valueOrderCost}>{moneyStr(orderCost)}</p>
    </Grid>
    <Grid item sm={2}>
      <p className={classes.orderCost}>Available credits</p>
      <p className={classes.valueAvailableCredit}>{currentBalance && moneyStr(parseFloat(currentBalance))}</p>
    </Grid>
    <Grid item sm={1} style={{ paddingLeft: 18, paddingTop: 10 }}>
      <BlueSwitch
        checked={isUsingCreditAvailable}
        onChange={toggleUsingCreditAvailable}
        value={isUsingCreditAvailable}
        color="primary"
        size="small"
      />
    </Grid>
  </Grid>
);

export default withStyles(styles)(AvailableCredits);
