import React from 'react';
import { Grid, makeStyles, FormControlLabel } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import styles from '../styles/redeemPagesHome';
import { CustomTextField } from '../redeemCommon';
import { BlueSwitch } from '../../account/AccountSharedComponents';

const useStyles = makeStyles(styles);

const ConfirmationPage = ({ page, onChange }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} className={classes.inputSection}>
        <Typography variant="body3RegularInter" className={classes.inputLabel}>
          Heading text<strong>*</strong>
        </Typography>
        <CustomTextField
          className={classes.inputText}
          placeholder="Heading text"
          defaultValue={page.confirmationPageHeadline}
          name="confirmationPageHeadline"
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className={classes.inputSection}>
        <Typography variant="body3RegularInter" className={classes.inputLabel}>
          Subtitle text<strong>*</strong>
        </Typography>
        <CustomTextField
          className={classes.inputText}
          placeholder="Subtitle text"
          defaultValue={page.confirmationPageBody}
          name="confirmationPageBody"
          onChange={onChange}
          fullWidth
        />
      </Grid>
      {!page.collectionOnly && (
        <>
          <Grid item xs={12} className={classes.inputSectionHeader} style={{ paddingBottom: 0 }}>
            <Typography variant="body1MediumInter" className={classes.sectionLabel}>
              Image of Product
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.inputSection}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body3RegularInter" className={classes.inputLabel} style={{ marginBottom: 0 }}>
                  Do you want recipients to see the product they chose/are receiving?
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <BlueSwitch
                      checked={page.showSelectedProduct}
                      onChange={({ target: { checked } }) =>
                        onChange({ target: { value: checked, name: 'showSelectedProduct' } })
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ConfirmationPage;
