import * as React from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import pick from 'lodash/pick';
import dayjs from 'dayjs';
import {
  addShipmentGroupDirectoryOrders,
  selectShipmentGroupProducts,
  selectShipmentGroupRecipients,
  setShipmentGroupId,
  validateDirectoryOrders
} from '../../../../../actions/shipmentGroupActions';
import shippingPricesApi from '../../../../../apis/swagup/shippingPrices';
import { sumByProperty } from '../../../../../helpers/utils';
import { addressFields } from '../common/utilsOrder';
import { useOrder } from '../OrderContext';
import apiPaths from '../../../../../helpers/apiPaths';

const fiveMinutes = 300_000;
const useEditShipmentGroup = id => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { shipmentGroups, products } = useOrder();
  const editingId = useSelector(state => state.shipmentGroup.id);

  const group = React.useMemo(() => shipmentGroups?.find(sg => sg.id === id), [shipmentGroups, id]);
  const payload = React.useMemo(() => {
    if (!group) return [];

    return group.directory_orders.map(dOrder => ({
      quantities: dOrder.proofs.map(p => ({
        account_product: p.proof.product.id,
        quantity: sumByProperty(p.sizes, 'quantity')
      })),
      ...pick(dOrder.directory, addressFields)
    }));
  }, [group]);

  const { data } = useQuery(
    [apiPaths.shippingPrices, payload],
    async () => {
      setIsLoading(true);
      const response = await shippingPricesApi.fetch(payload);
      return response.data;
    },
    {
      enabled: payload.length !== 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: fiveMinutes
    }
  );

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!(data && group) || editingId === group.id) return;

    const recipients = [];
    const directoryOrders = [];
    const productsIds = new Set();

    group.directory_orders.forEach((dOrder, i) => {
      recipients.push(dOrder.directory);

      const deliveryMethods = data[i].delivery_methods.map(dm =>
        dm.name === dOrder.delivery_method.name ? { ...dm, selected: true } : dm
      );
      const shippingDate = dOrder.shipping_date ? dayjs(dOrder.shipping_date).format('YYYY-MM-DD') : undefined;
      directoryOrders.push({
        directory: dOrder.directory.id,
        proofs: dOrder.proofs.map(p => {
          productsIds.add(p.proof.id);

          return {
            proof: p.proof.id,
            sizes: p.sizes
          };
        }),
        shippingNotes: dOrder.shipping_notes,
        shippingDate,
        deliveryMethods
      });
    });

    const selectedProducts = products.filter(p => productsIds.has(p.id));

    Promise.all([
      dispatch(setShipmentGroupId(group.id)),
      dispatch(selectShipmentGroupProducts(selectedProducts)),
      dispatch(selectShipmentGroupRecipients(recipients)),
      dispatch(addShipmentGroupDirectoryOrders(directoryOrders))
    ]).finally(() => {
      dispatch(validateDirectoryOrders([], false, true));
      setIsLoading(false);
    });
  }, [data, group, products, editingId, dispatch]);

  return isLoading;
};

export default useEditShipmentGroup;
