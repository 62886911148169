import React from 'react';
import clsx from 'clsx';
import { Grid, withStyles } from '@material-ui/core';
import { StylessButton } from '../buttons';
import { BlueCheckbox, GrayCheckbox } from '../account/AccountSharedComponents';
import { paymentMethods } from '../../apis/constants';
import styles from '../global/styles/PaymentSection';

const ACHItem = ({
  classes,
  key,
  aCH,
  selectedBankAccount,
  toggleSelectedBankAccount,
  toggleDefaultProfile,
  isDefault
}) => (
  <StylessButton
    key={key}
    className={classes.buttonCreditCard}
    onClick={
      toggleDefaultProfile ||
      (toggleSelectedBankAccount && (() => toggleSelectedBankAccount(aCH.customerPaymentProfileId)))
    }
  >
    <Grid
      item
      container
      className={clsx(
        classes.containerCreditCard,
        (isDefault || selectedBankAccount === aCH.customerPaymentProfileId) && classes.containerCreditAvailableSelected
      )}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <img src="/images/account/ach.svg" className={classes.accountImg} alt="" />
      </Grid>
      <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 20 }}>
        <p className={classes.creditAvailable}>{aCH.account_number}</p>
        <p className={classes.textCreditAvailable}>{aCH.name_on_account}</p>
      </Grid>
      <Grid item container xs={1} justifyContent="flex-end" style={{ alignContent: 'center' }}>
        {isDefault || selectedBankAccount === aCH.customerPaymentProfileId ? (
          <BlueCheckbox checked onChange={() => {}} value={paymentMethods.ach} />
        ) : (
          <GrayCheckbox checked={false} onChange={() => {}} value={paymentMethods.ach} />
        )}
      </Grid>
    </Grid>
  </StylessButton>
);

export default withStyles(styles)(ACHItem);
