import React, { useState, useEffect } from 'react';
import { Grid, Radio, RadioGroup, Typography, makeStyles, Divider, Box } from '@material-ui/core';
import LensIcon from '@material-ui/icons/Lens';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRounded from '@material-ui/icons/KeyboardArrowRightRounded';
import { Button } from '@swagup-com/components';
import { useDispatch, useSelector } from 'react-redux';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PlayArrow } from '@material-ui/icons';
import { Modal } from '../../shared/Modal';
import Counter from '../../shared/Counter';
import Img from '../../shared/Img';
import QuantitySelect from './QuantitySelect';
import {
  addProductToCart,
  removeProductFromCart,
  updateProductInCart,
  changeNumberOfColors
} from '../../../actions/cart';
import catalogApi from '../../../apis/swagup/catalog';
import { isImageType, isVideoType, moneyStr, sortByType } from '../../../helpers/utils';
import { resize } from '../../../utils/images';
import styles, { availableColorsStyles } from './ProductDetailsModal.styles';
import { useAuth } from '../../global/Authentication/AuthProvider';
import gtm from '../../../utils/gtm';

const useStyles = makeStyles(styles);
const useAvailableColorsStyles = makeStyles(availableColorsStyles);

const AvailableColors = ({ availableColors, colorIdx, onChange }) => {
  const classes = useAvailableColorsStyles();

  const [open, setOpen] = useState(false);

  const handleChange = event => onChange(+event.target.value);

  return availableColors.length ? (
    <>
      <Typography className={classes.title}>Select color</Typography>
      <div>
        <RadioGroup aria-label="color" value={colorIdx} onChange={handleChange} className={classes.colors}>
          {availableColors.slice(0, open ? undefined : 6).map(({ color }, idx) => (
            <Radio
              key={color}
              value={idx}
              icon={<LensIcon />}
              className={classes.colorButton}
              style={{ color: `#${color}` }}
            />
          ))}
          {availableColors.length > 6 && (
            <Button variant="text" size="small" onClick={() => setOpen(!open)} className={classes.expandButton}>
              {open ? 'Collapse' : `+${availableColors.length - 6}`}
            </Button>
          )}
        </RadioGroup>
      </div>
    </>
  ) : (
    <Typography className={classes.title}>No colors available</Typography>
  );
};

const getVideoMimeType = fileExtension => {
  // Map file extensions to corresponding MIME types
  const mimeTypes = {
    mp4: 'video/mp4',
    webm: 'video/webm',
    ogg: 'video/ogg'
    // Add more file extensions and MIME types as needed
  };

  // Return the MIME type based on the file extension
  return mimeTypes[fileExtension] || 'video/mp4'; // Default to mp4 if the extension is not recognized
};

const MediaVissualizer = ({ media, onClick, className, videoId, noPlayer }) => {
  const { name, url, file_type: fileType, extension } = media;
  const mediaUrl = `${process.env.REACT_APP_STATIC_RESOURCES_HOST}/${url}`;
  switch (true) {
    case isVideoType(fileType): {
      const type = getVideoMimeType(extension);
      return noPlayer ? (
        <div style={{ position: 'relative', cursor: 'pointer' }}>
          <video src={mediaUrl} className={className} style={{ opacity: 0.5 }} controlsList="play" type={type} />
          <Grid
            container
            style={{ position: 'absolute', top: 0, height: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <PlayArrow style={{ color: '#ffffff', height: 20, width: 20 }} />
            </Grid>
          </Grid>
        </div>
      ) : (
        <video id={videoId} className={className} controls controlsList="nodownload">
          <source src={mediaUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    case isImageType(fileType):
      return <Img src={mediaUrl} alt={name} onClick={onClick} className={className} />;
    default:
      return <Img src="" alt={name} onClick={onClick} className={className} />;
  }
};

const ProductDetailsModal = ({ product, onClose }) => {
  const [quantitySelectOpen, setQuantitySelectOpen] = useState(false);

  const classes = useStyles();
  const { items: cart, pack, numberOfColors, presetQuantities, minQuantity } = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const cartItem = cart.find(i => i.product.id === product.id);

  const [state, setState] = useState(
    cartItem || {
      colorIdx: null,
      quantity: pack ? 1 : 100,
      prices: (pack ? [pack.quantity] : presetQuantities).reduce((acc, q) => ({ ...acc, [q]: 0 }), {})
    }
  );

  const { colorIdx, quantity, prices, lifeStyleMedia } = state;

  useEffect(() => {
    if (cartItem) return;

    catalogApi
      .getPricing(
        [{ product, quantity }],
        numberOfColors,
        pack ? [pack.quantity] : presetQuantities,
        !pack,
        'product-details'
      )
      .then(pricing => setState({ ...state, prices: pricing.prices[product.id] }))
      .catch(() => {});
  }, [quantity, numberOfColors]);

  useEffect(() => {
    if (!cartItem) return;
    setState(cartItem);
  }, [cartItem]);

  const handleProductUpdate = (newQuantity, newColorIdx, updatePrices = true, media) => {
    const productUpdate = {
      quantity: newQuantity,
      colorIdx: newColorIdx,
      prices,
      lifeStyleMedia: media
    };

    setState(productUpdate);

    if (cartItem) dispatch(updateProductInCart(product.id, productUpdate, updatePrices));
  };

  const handleQuantityChange = newQuantity => {
    setQuantitySelectOpen(false);
    handleProductUpdate(newQuantity, colorIdx);
  };

  const handleColorIdxChange = newColorIdx => handleProductUpdate(quantity, newColorIdx, false);

  const handleLifeStyleChange = media => {
    handleProductUpdate(quantity, colorIdx, false, media);
    setTimeout(() => {
      if (isVideoType(media.file_type)) {
        const video = document.getElementById('catalog-item-media');
        if (isVideoType(lifeStyleMedia?.file_type)) {
          if (video) {
            video.load();
          }
        }
        video.play();
      }
    }, 500);
  };

  const handleNumberOfColorsChange = newNumberOfColors => dispatch(changeNumberOfColors(newNumberOfColors));

  const handleAddProduct = () => {
    dispatch(addProductToCart(product, quantity, colorIdx, prices));
    onClose();
  };

  const handleRemoveProduct = () => dispatch(removeProductFromCart(product));

  const currentColor = product.available_colors[colorIdx] || { image: product.img, label: 'Designer' };
  const price = prices[pack?.quantity || quantity] ?? 0;

  const { isAuthenticated, isPending } = useAuth();
  const isLoggedIn = !isPending && isAuthenticated;
  const currentMinQuantity = minQuantity.get ? minQuantity.get(isLoggedIn) : 25;

  const viewItemModal = () => {
    gtm.viewItem(product);
  };

  const { lifestyleImagesInCatalog } = useFlags();

  return (
    <Modal open onEnter={viewItemModal()} onClose={onClose} classes={{ content: classes.root }}>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          {!isEmpty(lifeStyleMedia) ? (
            <MediaVissualizer
              media={lifeStyleMedia}
              className={classes.mainImageLifestyle}
              videoId="catalog-item-media"
            />
          ) : (
            <Img src={resize(currentColor.image, 690)} alt={product.name} className={classes.mainImage} />
          )}
          <ScrollMenu
            data={product.available_colors.map(({ image, label }, idx) => (
              <Img
                key={label}
                src={resize(image, 78)}
                onClick={() => handleColorIdxChange(idx)}
                className={
                  product.available_colors.length - 1 === idx ? classes.lastThumbnailImage : classes.thumbnailImage
                }
              />
            ))}
            arrowLeft={<KeyboardArrowLeftRounded style={{ left: 0, zIndex: 999, cursor: 'pointer' }} />}
            arrowRight={<KeyboardArrowRightRounded style={{ right: 0, cursor: 'pointer' }} />}
            itemsCount={product.available_colors.length}
            selected={currentColor.label}
            scrollToSelected
            alignOnResize
            hideArrows
            wheel={false}
            scrollBy={3}
            translate={15}
            menuClass={classes.hMenu}
            itemClass={classes.thumbnailImageWrapper}
          />
          {lifestyleImagesInCatalog && !isEmpty(product.catalog_files) && (
            <Box>
              <Typography className={classes.seeItInRealLife}>See it in Real Life</Typography>
              <ScrollMenu
                data={product.catalog_files.sort(sortByType).map((media, idx) => (
                  <MediaVissualizer
                    key={`${media.url}-${idx + 1}`}
                    media={media}
                    className={
                      product.catalog_files.length - 1 === idx
                        ? classes.lastThumbnailImageLifeStyle
                        : classes.thumbnailImageLifeStyle
                    }
                    onClick={() => handleLifeStyleChange(media)}
                    noPlayer
                  />
                ))}
                arrowLeft={<KeyboardArrowLeftRounded style={{ left: 0, zIndex: 999, cursor: 'pointer' }} />}
                arrowRight={<KeyboardArrowRightRounded style={{ right: 0, cursor: 'pointer' }} />}
                itemsCount={product.available_colors.length}
                selected={currentColor.label}
                scrollToSelected
                alignOnResize
                hideArrows
                wheel={false}
                scrollBy={3}
                translate={15}
                menuClass={classes.hMenu}
                itemClass={classes.thumbnailImageWrapper}
              />
            </Box>
          )}
        </Grid>
        <Grid item sm={6} xs={12} className={classes.info}>
          <div>
            <Typography className={classes.title}>{product.name}</Typography>
            <Typography className={classes.color}>{currentColor.label}</Typography>
          </div>
          <div>
            <AvailableColors
              availableColors={product.available_colors}
              colorIdx={colorIdx}
              onChange={handleColorIdxChange}
            />
          </div>
          {!pack && (
            <QuantitySelect
              value={quantity}
              quantities={presetQuantities}
              prices={prices}
              open={quantitySelectOpen}
              minQuantity={currentMinQuantity}
              onToggle={setQuantitySelectOpen}
              onChange={handleQuantityChange}
              className={classes.quantitySelect}
            />
          )}
          <div className={classes.quantities}>
            {pack && (
              <div>
                <div className={classes.quantityTitle}>
                  <Typography className={classes.label}>Quantity</Typography>
                  <Typography className={classes.labelMax}>(max 10)</Typography>
                </div>
                <Counter
                  value={quantity}
                  min={1}
                  max={product.hazardous_materials ? 1 : 10}
                  onChange={handleQuantityChange}
                  maxQuantityError={
                    product.hazardous_materials ? 'You cannot create a pack with more than 1 hazmat product' : undefined
                  }
                />
              </div>
            )}
            <div>
              <Typography className={classes.label}>Number of colors in Logo</Typography>
              <Counter value={numberOfColors} min={1} max={4} onChange={handleNumberOfColorsChange} />
            </div>
          </div>
          <div>
            <Typography className={classes.label}>Price</Typography>
            <div className={classes.prices}>
              <Typography className={classes.totalPrice}> {moneyStr(price)}/item</Typography>
              <Typography data-testid="product-cost">{moneyStr(price * quantity * (pack?.quantity || 1))}</Typography>
              <Typography>{pack ? `${pack.quantity} packs` : `${quantity} orders`}</Typography>
            </div>
          </div>
          {cartItem ? (
            <Button variant="secondary" onClick={handleRemoveProduct} className={classes.button}>
              Remove from cart
            </Button>
          ) : (
            <Button variant="primary" onClick={handleAddProduct} className={classes.button}>
              Add to {pack ? 'Pack' : 'Order'}
            </Button>
          )}
          <Divider className={classes.divider} />
          <div>
            <Typography className={classes.label}>Description</Typography>
            <Typography className={classes.description}>{product.description}</Typography>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ProductDetailsModal;
