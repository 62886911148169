import * as React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Grid, Typography } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { CustomIconButton, useStyles } from '../shared/Pagination';

const Pagination = ({ index, count, onNext, onPrevious }) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <Grid item>
        <CustomIconButton onClick={onPrevious} disabled={index === 1}>
          <KeyboardArrowLeft />
        </CustomIconButton>
      </Grid>
      <Grid item>
        <Typography variant="body2" classNames={classes.paginationLabel}>
          <span className={classes.darkGray}>{index}</span> of
          <span className={classes.darkGray}>{count}</span> pages
        </Typography>
      </Grid>
      <Grid item>
        <CustomIconButton onClick={onNext} disabled={index === count}>
          <KeyboardArrowRight />
        </CustomIconButton>
      </Grid>
    </Grid>
  );
};

const PdfViewer = ({ file = '', width }) => {
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageNumber, setPageNumber] = React.useState(1);

  const topRef = React.useRef(null);

  React.useEffect(() => {
    topRef.current.scrollIntoView();
  }, [pageNumber]);

  const onDocumentLoadSuccess = ({ numPages }) => setTotalPages(numPages);

  return (
    <Grid ref={topRef} container justifyContent="center" alignItems="center">
      <Grid item>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page width={width} scale={1.25} pageNumber={pageNumber} />
          {totalPages > 1 && (
            <Pagination
              index={pageNumber}
              count={totalPages}
              onNext={() => setPageNumber(prevState => prevState + 1)}
              onPrevious={() => setPageNumber(prevState => prevState - 1)}
            />
          )}
        </Document>
      </Grid>
    </Grid>
  );
};

export default PdfViewer;
