import { AUTHENTICATE } from '../actions/types';

const SignReducer = (state = { authenticated: false }, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return { ...state, authenticated: action.payload };
    default:
      return state;
  }
};

export default SignReducer;
