import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useDispatch, useSelector } from 'react-redux';
import max from 'lodash/max';
import sum from 'lodash/sum';
import isEmpty from 'lodash/isEmpty';

import ShippingMethodButton from './ShippingMethodButton';
import Drawer from '../common/Drawer';
import { sumByProperty } from '../../../../../helpers/utils';
import { updateSelectedRecipientsShippingMethod } from '../../../../../actions/shipmentGroupActions';

const useStyles = makeStyles({
  drawer: {
    padding: '26px 40px 35px',
    width: '27%'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: '#0b1829'
  }
});

const getMethodsByName = (directoryOrders, name) =>
  directoryOrders.map(d => d.deliveryMethods.find(dm => dm.name === name)).filter(Boolean);

const getTurnaroundTime = methods => {
  const minimumWait = max(methods.map(m => m.turnaround_time_min_days));
  const maximumDelay = max(methods.map(m => m.turnaround_time_max_days));
  return minimumWait !== maximumDelay
    ? `${minimumWait}-${maximumDelay} business days`
    : `${maximumDelay} business days`;
};

const getTotalPrice = methods =>
  sum(methods.map(method => sumByProperty(method?.prices.breakdown ?? [], 'total_price')));

const ShippingMethodDrawer = ({ open, onClose, selectedIdsSet }) => {
  const classes = useStyles();

  const directoryOrders = useSelector(state =>
    state.shipmentGroup.directoryOrders
      .filter(d => selectedIdsSet.has(d.directory))
      .filter(d => !isEmpty(d.deliveryMethods))
  );

  const standardMethods = React.useMemo(() => getMethodsByName(directoryOrders, 'Standard'), [directoryOrders]);
  const expressMethods = React.useMemo(() => getMethodsByName(directoryOrders, 'Express'), [directoryOrders]);

  const preselectShippingMethod = () => {
    if (directoryOrders.length > 0) {
      const selectedStandardCount = standardMethods.filter(sm => sm.selected).length;
      if (selectedStandardCount === 0) return 'Express';
      if (selectedStandardCount === directoryOrders.length) return 'Standard';
    }
    return null;
  };

  const [selected, setSelected] = React.useState(preselectShippingMethod);

  const handleSelect = methodName => setSelected(methodName);

  const dispatch = useDispatch();
  const handleSaveChanges = () => {
    dispatch(
      updateSelectedRecipientsShippingMethod({
        methodName: selected,
        selectedIdsSet
      })
    );
    onClose();
  };

  const maxStandardTime = React.useMemo(() => getTurnaroundTime(standardMethods), [standardMethods]);
  const maxExpressTime = React.useMemo(() => getTurnaroundTime(expressMethods), [expressMethods]);

  const standardPrice = React.useMemo(() => getTotalPrice(standardMethods), [standardMethods]);
  const expressPrice = React.useMemo(() => getTotalPrice(expressMethods), [expressMethods]);

  const noDirectoryOrders = directoryOrders.length === 0;
  const noStandardMethods = standardMethods.length === 0;
  const noExpressMethods = expressMethods.length === 0;

  return (
    <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper: classes.drawer }}>
      <Grid container direction="column" justifyContent="space-between" style={{ flex: '1' }}>
        <Box>
          <p className={classes.title}>Shipping method</p>
          <Box mt="36px">
            <ShippingMethodButton
              active={selected === 'Standard'}
              name="Standard"
              disabled={noDirectoryOrders || noStandardMethods}
              time={maxStandardTime}
              price={standardPrice}
              onClick={() => handleSelect('Standard')}
              style={{ width: '100%' }}
            />
            <ShippingMethodButton
              active={selected === 'Express'}
              name="Express"
              disabled={noDirectoryOrders || noExpressMethods}
              time={maxExpressTime}
              price={expressPrice}
              onClick={() => handleSelect('Express')}
              style={{ marginTop: 20, width: '100%' }}
            />
          </Box>
        </Box>
        <Button
          variant="primary"
          style={{ height: 56 }}
          onClick={handleSaveChanges}
          disabled={!selected || noDirectoryOrders}
        >
          Save changes
        </Button>
      </Grid>
    </Drawer>
  );
};

export default ShippingMethodDrawer;
