import { scrollBar } from '../../shared/styles/commonStyles';

const styles = theme => ({
  root: {
    backgroundColor: '#fafbfc',
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    zIndex: 0
  },
  center: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      paddingLeft: '0 !important',
      minWidth: '1280px'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1120px',
      paddingLeft: '0 !important',
      minWidth: '1120px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
      padding: '0px 5px !important',
      minWidth: '100%'
    }
  },
  wizardInnerContent: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100% !important',
      padding: '0px 124px'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important',
      padding: '0px 0px'
    }
  },
  swipeableViews: {
    height: '100%',
    width: '100%',
    '& > div': {
      height: '100%'
    }
  },
  startBuildingButtonContainer: {
    paddingTop: 56,
    fontFamily: 'Gilroy-Bold',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 42,
      justifyContent: 'center'
    }
  },
  finalName: {
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    marginBottom: 16,
    maxWidth: 364,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  addresContainer: {
    border: 'solid 1px #ebeef2',
    padding: '24px 32px',
    borderRadius: 15
  },
  finalAddress: {
    color: '#0f2440',
    fontWeight: 500,
    fontSize: 16,
    fontFamily: 'Gilroy-SemiBold',
    opacity: 0.56,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  finalAddressState: {
    color: '#0f2440',
    fontWeight: 500,
    fontFamily: 'Gilroy-SemiBold',
    opacity: 0.56,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  heyThere: {
    marginTop: 0,
    color: '#0f2440',
    fontFamily: 'Gilroy',
    fontSize: 48,
    textAlign: 'center',
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      marginBottom: 16,
      fontSize: 32
    }
  },
  behaviorText: {
    color: '#0f2440',
    fontFamily: 'Gilroy',
    fontSize: 48,
    textAlign: 'center',
    marginBottom: 24,
    maxWidth: 692,
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      marginBottom: 16,
      fontSize: 24
    }
  },
  backArrow: {
    height: 24,
    position: 'absolute',
    left: 0,
    fontSize: 16,
    color: '#9e9e9e !important',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 2,
      fontSize: 14,
      marginLeft: -10
    }
  },
  forwardArrow: {
    height: 24,
    fontSize: 16,
    marginRight: 32,
    position: 'absolute',
    right: 0,
    color: '#ffffff !important',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 2,
      fontSize: 14,
      marginRight: 4
    }
  },
  forwardArrowDisabled: {
    height: 24,
    position: 'absolute',
    right: 0,
    fontSize: 16,
    paddingTop: 4,
    marginRight: 16,
    color: '#9e9e9e !important',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 2,
      fontSize: 14,
      marginRight: 8
    }
  },
  thankYou: {
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    fontSize: 48,
    fontWeight: 500,
    margin: 0,
    paddingTop: 0,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      marginBottom: 16,
      fontSize: 32,
      textAlign: 'center'
    }
  },
  errorTextCustomize: {
    '& .MuiFormHelperText-root': {
      fontSize: 11,
      fontFamily: 'Gilroy-Light',
      color: '#f44336',
      width: '100%',
      padding: '0px 24px',
      minHeight: 20,
      paddingTop: 4,
      textAlign: 'left'
    }
  },
  errorText: {
    fontSize: 14,
    fontFamily: 'Gilroy',
    color: '#f44336',
    width: '100%',
    padding: '10px 14px 0px 0px',
    minHeight: 20,
    textAlign: 'right',
    fontWeight: 400
  },
  selctorErrorText: {
    fontSize: 11,
    fontFamily: 'Gilroy-Light !important',
    color: '#f44336',
    width: '100%',
    padding: '0px 24px',
    minHeight: 20,
    paddingTop: 4,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      textAlign: 'center'
    }
  },
  noticeText: {
    color: '#0f2440',
    fontFamily: 'Gilroy',
    opacity: 0.56,
    fontSize: 20,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      fontSize: 16
    }
  },
  propertyLabel: {
    color: '#0f2440',
    fontFamily: 'Gilroy-Medium',
    opacity: 0.56,
    fontSize: 12,
    marginBottom: 8
  },
  propertyValue: {
    color: '#0f2440',
    fontFamily: 'Gilroy-Light',
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 28
  },
  currentContent: {
    width: '100%',
    height: 12,
    borderRadius: 6,
    marginTop: 12,
    backgroundColor: '#cce1ff',
    marginBottom: 28
  },
  onboardingSelectorContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 12,
      paddingLeft: 12
    }
  },
  onboardingSelectorItem: {
    borderRadius: 15,
    height: '100% !important',
    width: '100%',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      paddingLeft: 2,
      paddingRight: 2,
      paddingBottom: 8
    }
  },
  oSelectorContent: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      width: '100%',
      margin: 0,
      '& .MuiGrid-item': {
        paddingLeft: 6,
        paddingRight: 6
      }
    }
  },
  imgUSAFlag: {
    width: '100%',
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 12
    }
  },
  imgSource: {
    maxWidth: 44,
    maxHeight: 44,
    objectFit: 'contain',
    padding: 6,
    [theme.breakpoints.down('sm')]: {
      padding: 8
    }
  },
  addressCountryInputContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '5px !important'
    }
  },
  addressInputContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '35px !important'
    }
  },
  addressWizardContent: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12
    }
  },
  sizesWizardContent: {
    paddingLeft: 16,
    paddingRight: 32,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 24,
      paddingRight: 56
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 56
    }
  },
  onboardingSelectorImgWide: {
    padding: '0px 18px',
    paddingRight: 12,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 8px',
      paddingBottom: '4px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 4px'
    }
  },
  onboardingSelectorImg: {
    padding: '32px 32px',
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 8px'
    }
  },
  onboardingSelectorButton: {
    '& > div': {
      height: '100% !important'
    }
  },
  onboardingSelectorItemSelected: {
    backgroundColor: '#ffffff',
    height: '100% !important',
    width: '100%',
    border: '1px solid #3577d4 !important',
    borderRadius: 15,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      paddingLeft: 2,
      paddingRight: 2,
      paddingBottom: 8
    }
  },
  pendingContent: {
    width: '100%',
    height: 12,
    borderRadius: 6,
    marginTop: 12,
    backgroundColor: '#d4d9e2',
    marginBottom: 28
  },
  onboardingSelectorItemText: {
    marginTop: 16,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#0f2440',
    fontFamily: 'Gilroy',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 300,
      paddingLeft: 2,
      paddingRight: 2
    }
  },
  onboardingSelectorItemTextWide: {
    marginTop: 16,
    fontSize: 16,
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    textAlign: 'left',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 300,
      paddingLeft: 2,
      paddingRight: 2
    }
  },
  onboardingSelectorItemTextSmall: {
    marginTop: 16,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#0f2440',
    fontFamily: 'Gilroy',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
      fontSize: 10,
      fontWeight: 300,
      textAlign: 'center',
      paddingLeft: 2,
      paddingRight: 2
    }
  },
  onboardingSelectorItemTextSmallWide: {
    marginTop: 16,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#0f2440',
    fontFamily: 'Gilroy',
    textAlign: 'left',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
      fontSize: 10,
      fontWeight: 300,
      textAlign: 'center',
      paddingLeft: 2,
      paddingRight: 2
    }
  },
  onboardingSelectorItemSelectedText: {
    marginTop: 16,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3577d4',
    fontFamily: 'Gilroy',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 300
    }
  },
  onboardingSelectorItemSelectedTextWide: {
    marginTop: 16,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3577d4',
    fontFamily: 'Gilroy-SemiBold',
    textAlign: 'left',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 300
    }
  },
  onboardingSelectorItemSelectedTextSmall: {
    marginTop: 16,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3577d4',
    fontFamily: 'Gilroy',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
      textAlign: 'center',
      fontSize: 10,
      fontWeight: 300
    }
  },
  onboardingSelectorItemSelectedTextSmallWide: {
    marginTop: 24,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3577d4',
    fontFamily: 'Gilroy',
    textAlign: 'left',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
      textAlign: 'center',
      fontSize: 10,
      fontWeight: 300
    }
  },
  onboardingSelector: {
    position: 'relative',
    maxWidth: 724,
    '& .menu-item-wrapper': {
      border: '1px solid #3577d4',
      borderRadius: 4,
      marginRight: 24,
      outline: 0
    },
    '& .active': {
      border: '2px solid #3577d4'
    }
  },
  prettoSliderContainer: {
    paddingTop: 104,
    paddingBottom: 24,
    paddingLeft: 72,
    paddingRight: 32,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 92,
      paddingBottom: 18,
      paddingRight: 0,
      paddingLeft: 42
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 104,
      paddingBottom: 12,
      paddingRight: 0,
      paddingLeft: 16
    }
  },
  pendingLabel: {
    width: 85,
    height: 8,
    borderRadius: 6,
    backgroundColor: '#d4d9e2',
    opacity: 0.56
  },
  noPic: {
    height: 164,
    width: 164
  },
  firstStepImg: {
    width: 284,
    height: 264,
    [theme.breakpoints.down('sm')]: {
      width: 172,
      height: 164
    }
  },
  behaviorImg: {
    width: 256,
    height: 204,
    [theme.breakpoints.down('sm')]: {
      width: 172,
      height: 164
    }
  },
  singleInputContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12
    }
  },
  startBuildingImg: {
    width: 572,
    height: 372,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: 124,
      height: 102
    }
  },
  selectorPic: {
    width: '100%'
  },
  summaryContent: {
    paddingLeft: 56
  },
  logoContainer: {
    position: 'absolute',
    top: 64,
    width: 172,
    marginLeft: -86,
    [theme.breakpoints.down('sm')]: {
      top: 8
    }
  },
  logoContainerTwo: {
    marginTop: 64,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      textAlign: 'center'
    }
  },
  logoContainerBuild: {
    position: 'absolute',
    top: 64,
    width: 172,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      top: 8,
      textAlign: 'center'
    }
  },
  wizardContent: {
    height: '100%',
    paddingRight: 56,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  stepsSubTitleText: {
    color: '#0f2440',
    fontFamily: 'Gilroy',
    fontSize: 16,
    textAlign: 'left',
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
      fontSize: 12,
      textAlign: 'center',
      paddingLeft: 12,
      paddingRight: 12
    }
  },
  stepTitleSmall: {
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    fontSize: 48,
    textAlign: 'left',
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      marginBottom: 24,
      fontSize: 24,
      textAlign: 'center',
      paddingLeft: 12,
      paddingRight: 12
    }
  },
  stepTitle: {
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    fontSize: 48,
    textAlign: 'left',
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      marginBottom: 32,
      fontSize: 32,
      textAlign: 'center',
      paddingLeft: 12,
      paddingRight: 12
    }
  },
  scrollArrow: {
    color: '#3577d4',
    position: 'absolute',
    fontSize: 60,
    width: 60,
    height: 60,
    boxShadow: theme.shadows[3],
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[8]
    }
  },
  sectionTagLine: {
    height: 2,
    border: 0,
    marginRight: 16,
    width: 64,
    [theme.breakpoints.down('sm')]: {
      height: 1,
      marginLeft: 16,
      width: 42
    }
  },
  sectionTagContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  noMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  onMobile: {
    display: 'none',
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  noMobileExact: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  onMobileExact: {
    display: 'none',
    zIndex: 999,
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  sectionTag: {
    color: '#3577d4',
    fontSize: 16,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontFamily: 'Gilroy-SemiBold',
    lineHeight: 1.5,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      margin: 0
    }
  },
  inputRight: {
    paddingLeft: 12,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 12
    }
  },
  inputLeft: {
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingBottom: `35px !important`
    }
  },

  inputSearchExpirationDate: {
    marginTop: -20,
    '&:hover:before': { border: '0 !important' },
    '&:before': { border: '0 !important' },
    '&:after': { border: '0 !important' },
    '&+p': { padding: '0 24px' }
  },
  inputSearchSelector: {
    height: 40,
    width: '80%',
    backgroundColor: 'transparent',
    borderRadius: 20,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #dedede',
    outline: 0,
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 'normal',
    fontFamily: 'Gilroy-Light',
    margin: '24px 16px',
    marginTop: 12,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      margin: '16px auto',
      width: '90%',
      paddingLeft: 4,
      paddingRight: 4,
      height: 32,
      paddingTop: 0,
      fontSize: 12,
      borderRadius: 14
    },
    [theme.breakpoints.down('xs')]: {
      margin: '12px auto',
      width: '96%',
      paddingLeft: 4,
      paddingRight: 4,
      height: 24,
      paddingTop: 0,
      fontSize: 8,
      borderRadius: 12
    }
  },
  inputSearchError: {
    border: '1px solid #f44336 !important'
  },
  input: {
    borderBottom: '0px !important',
    fontFamily: 'Gilroy-Light'
  },
  selectorItemsContainer: {
    padding: '12px 12px',
    paddingBottom: 24
  },
  selectorItem: {
    paddingLeft: 32,
    paddingRight: 32,
    height: 56,
    borderRadius: 28,
    fontSize: 20,
    fontWeight: 'normal',
    color: '#7e7e7e',
    fontFamily: 'Gilroy-Light',
    '& hover': {
      backgroundColor: '#dfdfdf'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    },
    '&.MuiMenuItem-root.Mui-selected': {
      color: '#7e7e7e'
    }
  },
  paperDropDown: {
    maxHeight: 224,
    overflowY: 'auto',
    borderRadius: '12px 12px 32px 32px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 1px 14px 0px rgba(0,0,0,0.12)',
    scrollBehavior: 'smooth',
    '&, & *': {
      ...scrollBar,
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: 4,
        borderRadius: 4
      },
      '&::-webkit-scrollbar-track-piece': {
        '&:start': {
          marginTop: 5,
          backgroundColor: '#ebeef2'
        },
        '&:end': {
          marginBottom: 20,
          backgroundColor: '#ebeef2'
        }
      }
    }
  },
  pageOne: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      maxWidth: '100% !important'
    }
  },
  pageTwo: {
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  },
  accentButon: {
    width: '332px !important',
    height: '64px !important',
    borderRadius: '32px !important',
    [theme.breakpoints.down('sm')]: {
      width: '172px !important',
      height: '48px !important',
      fontSize: '16px !important'
    }
  },
  buildingButon: {
    width: '217px !important',
    height: '64px !important',
    paddingTop: '5px !important',
    [theme.breakpoints.down('sm')]: {
      width: '50% !important',
      height: '48px !important',
      fontSize: '14px !important',
      marginLeft: '25%'
    }
  },
  stepsText: {
    opacity: 0.54,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    marginBottom: 24,
    color: '#0f2440',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  stepsSubText: {
    opacity: 0.54,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    marginBottom: 24,
    color: '#0f2440',
    marginTop: 42,
    maxWidth: 556,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  cardInfoCharge: {
    fontFamily: 'Gilroy-Light',
    fontSize: 16,
    color: '#3577d4',
    backgroundColor: '#ebf1fb',
    textAlign: 'center',
    display: 'table',
    height: 48,
    width: 112,
    borderRadius: 25,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      height: 38,
      width: 92,
      borderRadius: 19
    }
  },
  previousButon: {
    width: '232px !important',
    height: '64px !important',
    fontFamily: 'Gilroy-Medium !important',
    fontWeight: '500 !important',
    backgroundColor: 'transparent !important',
    color: '#9e9e9e !important',
    textTransform: 'none !important',
    [theme.breakpoints.down('sm')]: {
      width: '142px !important',
      height: '48px !important',
      fontSize: '16px !important'
    }
  },
  nextButon: {
    width: '232px !important',
    height: '64px !important',
    backgroundColor: '#3577d4 !important',
    borderRadius: '32px !important',
    fontWeight: '500 !important',
    textTransform: 'none !important',
    [theme.breakpoints.down('sm')]: {
      width: '142px !important',
      height: '48px !important',
      fontSize: '16px !important'
    }
  },
  nextButonDisabled: {
    width: '232px !important',
    height: '64px !important',
    borderRadius: '32px !important',
    fontWeight: '500 !important',
    backgroundColor: '#dcdcdc !important',
    color: '#9e9e9e !important',
    textTransform: 'none !important',
    [theme.breakpoints.down('sm')]: {
      width: '142px !important',
      height: '48px !important',
      fontSize: '16px !important'
    }
  },
  buttonsContainer: {
    padding: '32px 56px 32px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 124px 82px 124px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '16px 12px 82px 12px'
    }
  },
  cIcon: {
    color: '#8d9299 !important',
    opacity: 0.54,
    position: 'absolute',
    height: 16,
    width: 16,
    right: 12,
    top: '48%',
    transform: 'translate(0, -50%)'
  },
  sliderMarker: {
    [theme.breakpoints.down('sm')]: {
      width: '70% !important',
      height: '70% !important',
      marginTop: '-60% !important',
      marginLeft: '15% !important'
    }
  },
  startBuildingContent: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 64
    }
  },
  searchAdornmentStyle: {
    position: 'absolute',
    right: 14,
    color: ' rgb(170, 170, 170)'
  },
  muiEndAdornment: {
    paddingRight: 0
  },
  muiInput: {
    paddingLeft: 32,
    paddingRight: 42,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20
    }
  },
  expirationMonthPickerCorrector: {
    position: 'relative'
  }
});
export default styles;
