import axios from '../DashBoardForm';
import { dayjs } from '../../helpers/dayjs';
import apiPaths from '../../helpers/apiPaths';
import { CATALOG_PRODUCT_TYPE } from '../../utils/constants';
import { mergeCampaignIdsWithData } from '../../helpers/utilsCookies';

export default {
  async submit(products, info, isBulk) {
    const result = await axios.post(
      apiPaths.orders,
      mergeCampaignIdsWithData({
        form_type: isBulk ? CATALOG_PRODUCT_TYPE.PRODUCT : CATALOG_PRODUCT_TYPE.PACK,
        type: 2, // 1: 'quote', 2: 'order'
        full_name: info.name,
        work_email: info.email,
        company: info.companyName,
        coupon_code: info?.coupon_code || '',
        inhands_date: dayjs(info.date).format('YYYY-MM-DD'),
        firm_ihd: info?.firm_ihd || false,
        comments: info.comments,
        resources: [info.primaryLogo, info.secondaryLogo].filter(Boolean).map(p => ({ resource_path: p })),
        phone: info.phone,
        pack_quantity: info.quantity,
        cart_quantity: info.cartQuantity,
        pack_budget: info.budget,
        number_of_colors: info.numberOfColors,
        items: products.map(({ product, quantity, colorIdx }) => ({
          item: product.id,
          quantity,
          selected_color: colorIdx !== null ? product.available_colors[colorIdx].label : '',
          selected_color_hex: colorIdx !== null ? product.available_colors[colorIdx].color : ''
        }))
      })
    );

    const realData = result.data;
    const parsedData = {
      ...realData,
      items: realData.items.map(item => ({
        ...item,
        item_name: products.find(({ product }) => product.id === item.item)?.product.name,
        price: Number(products.find(({ product }) => product.id === item.item)?.product.price_without_discount)
      }))
    };

    return parsedData;
  }
};
