const styles = theme => ({
  externalContainer: { backgroundColor: '#f3f3f3' },
  title: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '3.6rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440',
    marginBottom: 0
  },
  subtitle: {
    opacity: 0.54,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.25rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#0f2440',
    marginTop: 10
  },
  processWrapper: {
    maxWidth: 580,
    minWidth: 580,
    minHeight: 700,
    width: '100%',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: { minWidth: 415 },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 360,
      display: 'flex',
      justifyContent: 'center',
      minHeight: 600
    },
    [theme.breakpoints.down(350)]: { minWidth: 320 }
  },
  processNumber: {
    padding: '10px 20px',
    backgroundColor: '#3577d4',
    borderRadius: '50%',
    border: '3px solid white',
    position: 'absolute',
    top: -30,
    left: 36,
    zIndex: 999,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#ffffff'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    width: '99%',
    maxWidth: 480,
    minWidth: 315,
    height: '100%',
    minHeight: 620,
    borderRadius: '25px 75px 25px 25px',
    padding: '20px 65px 25px 65px',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: { padding: '20px 25px 65px 25px', maxWidth: 315 },
    [theme.breakpoints.down('xs')]: { minHeight: 550 }
  },
  paperMedia: {
    width: '100%',
    alignItems: 'flex-end',
    '& img': { width: '50%' },
    [theme.breakpoints.down('xs')]: { minHeight: 200 }
  },
  paperContent: {
    '& h2': {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: '2.5rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.3,
      letterSpacing: 'normal',
      maxWidth: 270,
      opacity: '1 !important',
      whiteSpace: 'pre-wrap'
    },
    '& p': {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: '1.25rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.6,
      letterSpacing: 'normal',
      whiteSpace: 'pre-wrap'
    }
  },
  line: {
    borderTop: '2px solid #9846dd',
    width: 100,
    marginTop: 140,
    position: 'absolute',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: { display: 'none' }
  },
  scrollArrowRounded: {
    color: '#003366',
    position: 'absolute',
    zIndex: 999,
    fontSize: 42,
    width: 65,
    height: 65,
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    border: '3px solid #003366',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#114477'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
      width: 30,
      height: 30
    }
  },
  scrollArrow: {
    color: '#ffffff',
    position: 'absolute',
    zIndex: 999,
    opacity: 0.56,
    fontSize: 42,
    width: 56,
    height: 56,
    fontWeight: 'normal',
    backgroundColor: '#3577d4',
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[8],
      opacity: 1
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
      width: 42,
      height: 42
    }
  },
  scrollMenuContainer: {
    width: 'calc(100vw - 16px)',
    '& div': { outline: 'none !important' }
  }
});

export default styles;
