import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward, OutlinedFlag } from '@material-ui/icons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import {
  InvoiceProduct,
  SORDStatuses,
  ToogleButtom,
  estimatedShippingDate,
  isDomesticShipping,
  isInternationalShipping,
  orderBeCompletedText,
  orderStatuses
} from './common';
import { Breadcrumbs, CenteredGrid } from '../../../../../shared';
import seoTags from '../../../../../../apis/seoTags';
import CustomStepper from '../../../../../global/CustomStepper';
import {
  addBusinessDaysToDate,
  getStatusDescription,
  joinFields,
  maxTurnaround,
  moneyStr,
  sumByProperty,
  sumByQuantity
} from '../../../../../../helpers/utils';
import SimpleCustomStepper from '../../../../../global/SimpleCustomStepper';
import { shipmentStatus } from '../../../../../../apis/constants';
import ShipmentStatus from '../../../../../global/ShipmentStatus';
import { fetchInvoiceOrders } from '../../../../../../actions';
import { useCompany, useQueryParams } from '../../../../../../hooks';
import toErrorPage from '../../../../../../helpers/toErrorPage';
import Loader from '../../../../../global/Loader';
import InvoiceReviewSummary from './InvoiceReviewSummary';
import { CustomTooltip } from '../../../../../products/commonProductsElements';
import apiPaths from '../../../../../../helpers/apiPaths';
import { useReorderLink } from '../../../../../inventory/InventoryCommon';
import { StatusChip } from '../../../../../global/proofsCommon';
import { invoiceStatusStyles } from '../../../../../account/Invoices';
import { useMetaData } from '../../../../../../hooks/globalQueries';
import EditShippingRecipient from './EditShippingRecipient';
import { shipmentsApi } from '../../../../../../apis/swagup';
import shippingPricesApi from '../../../../../../apis/swagup/shippingPrices';
import CancelModal from '../../../../shipments/recentShipments/CancelModal';
import invoices from '../../../../../../apis/swagup/invoices';
import UpdateShippingModal from './UpdateShippingModal';

const commonText = { fontFamily: 'Inter', fontSize: 12 };
const styles = () => ({
  orderSummaryPageContainer: { paddingTop: ({ isSummary }) => (isSummary ? 32 : 0) },
  title: {
    marginBottom: 12,
    textAlign: 'center',
    color: '#131415'
  },
  subTitle: { marginBottom: 32, textAlign: 'center', fontWeight: 400 },
  orderStatusPanel: { border: '1px solid #EBEDF0', padding: '24px 30px', borderRadius: 10, marginBottom: 12 },
  statusTitle: { marginBottom: 12 },
  statusSubTitle: { marginBottom: 16 },
  statusText: { color: '#4A4F54', marginBottom: 6, fontSize: 14 },
  orderStatusBox: {},
  statusButtonLike: ({ isNext }) => ({
    width: 124,
    height: 28,
    borderRadius: 14,
    '& p': { color: '#3577D4', fontFamily: 'Inter' },
    background: isNext ? 'transparent' : '#F4F8FF',
    padding: '4px 10px'
  }),
  simpleText: { color: '#787B80', fontSize: 12, textAlign: 'right', '& span': { color: '#0B1829' } },
  orderStatusIconWrapper: {
    background: '#3577D4',
    height: 18,
    width: 18,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 9
  },
  orderStatusIcon: {
    background: '#3577D4',
    color: '#ffffff',
    height: '16px',
    width: '16px',
    marginTop: 1,
    marginLeft: 1
  },
  orderStatusIconWrapperNext: {
    background: '#F4F8FF',
    height: 18,
    width: 18,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 9
  },
  orderStatusIconNext: {
    background: '#F4F8FF',
    color: '#3577D4',
    height: '16px',
    width: '16px',
    marginTop: 1,
    marginLeft: 1
  },
  orderStatusVirtualBox: { borderLeft: '1px solid #EBEDF0' },
  statusDetail: { padding: '8px 0px 16px 44px' },
  statusDetailTitleOn: {
    color: '#4A505C',
    marginBottom: 2
  },
  statusDetailText: { color: '#787B80' },
  orderStatusItem: { marginLeft: -18 },
  orderProductsPanel: { paddingTop: 20 },
  subTotalItem: { paddingTop: 12, borderTop: '1px solid #D6D8DB' },
  backTitle: { ...commonText, color: '#0b1829' },
  currentTitle: { ...commonText, color: '#787b80' },
  separator: { fontSize: 13, marginTop: 2 },
  container: { padding: '24px 0px' },
  grey: { color: '#4A4F54' },
  arrowBack: {
    color: '#787b80',
    fontSize: 16,
    margin: '8px 7px 0px 0px'
  },
  productContainer: {
    borderRadius: 8,
    border: '1px solid #D6D8DB',
    padding: '20px 24px',
    marginBottom: 16
  },
  shipmentContainer: {
    borderRadius: 15,
    padding: '24px'
  },
  header: {},
  contentDivider: { height: '100%', width: 1, background: '#EBEDF0', margin: '6px 32px' },
  shippingSummary: {
    borderRadius: 15,
    border: '1px solid #EBEDF0',
    marginBottom: 124
  },
  statusMainTitle: {
    marginBottom: 30
  },
  statusTextTitle: {
    marginBottom: 8
  },
  editRecipientAddress: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  recipientAddress: {
    color: '#4A505C'
  },
  cancleBtn: ({ disabled }) => ({
    color: disabled ? '#A4A7AD' : '#C62828',
    height: '40px'
  }),
  floatingInfoMessage: {
    maxWidth: 464,
    zIndex: 1400,
    width: 'auto',
    top: '32px',
    position: 'fixed'
  }
});

const useStyles = makeStyles(styles);

const StatusBar = ({ icon, text, isNext, children }) => {
  const classes = useStyles({ isNext });
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Box className={classes.statusButtonLike}>
          <Grid container alignItems="center" style={{ height: '100%' }}>
            <Grid item>{icon}</Grid>
            <Grid item xs>
              <p style={{ textAlign: 'center' }}>{text}</p>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  );
};

const GoBack = ({ link, classes }) => (
  <Grid container>
    <Link to={link}>
      <ArrowBack className={classes.arrowBack} />
    </Link>
    <Breadcrumbs
      separator={<span className={classes.separator}>/</span>}
      links={[
        { title: 'Back', to: link, styles: classes.backTitle },
        { title: 'Order Review', styles: classes.currentTitle }
      ]}
    />
  </Grid>
);

// const isReachedDate = (first, second) => dayjs(first).isSame(second, 'day') || dayjs(first).isAfter(second, 'day');

// const itsAllShipped = (directoryOrders = [], orderProducts = []) => {
//   return orderProducts.every(({ product }) => {
//     const associatedDiors = directoryOrders.filter(dior => dior.products.find(p => p.product === product.id));
//     return associatedDiors.every(
//       dior => ![shipmentStatus.scheduled, shipmentStatus.pendingProduction].includes(dior.status)
//     );
//   });
// };

const OrderPanel = ({ order, isEnterpriseFlowActive }) => {
  // , directoryOrders }) => {
  const classes = useStyles();
  // const metaData = useMetaData();
  // const { BUSINESS_DAYS_TO_START_PRODUCTION, TURNAROUND_TIME_PACKING } = metaData.data || {};
  const statusDescription = getStatusDescription(order);
  //   order,
  //   BUSINESS_DAYS_TO_START_PRODUCTION,
  //   TURNAROUND_TIME_PACKING,
  //   directoryOrders
  // );

  return (
    <Box key={order.id} className={classes.orderStatusPanel}>
      <Grid container>
        <Grid item xs={6}>
          <Box className={classes.orderDetailsBox}>
            <Typography variant="h6SemiBoldInter" className={classes.statusTitle}>
              Order Details
            </Typography>
            <Typography variant="body2RegularInter" className={classes.statusSubTitle}>
              Order #{order.order_no}
            </Typography>
            <Typography variant="body3RegularInter" className={classes.statusText}>
              Placed on {dayjs(order.created_at).format('MM/DD/YYYY')}
            </Typography>
            <Typography variant="body3RegularInter" className={classes.statusText} style={{ marginBottom: 24 }}>
              By {order.created_by}
            </Typography>
            <StatusChip label={order.status} status={invoiceStatusStyles[order.status]} />
          </Box>
        </Grid>
        {order.status !== 'Canceled' && (
          <Grid item xs={6}>
            <Box className={classes.orderStatusBox}>
              <Typography variant="h6SemiBoldInter" className={classes.statusTitle} style={{ marginLeft: -16 }}>
                Order Status
              </Typography>
              <Box className={classes.orderStatusVirtualBox}>
                <Box className={classes.orderStatusItem}>
                  <StatusBar
                    icon={
                      <Box className={classes.orderStatusIconWrapper}>
                        <OutlinedFlag className={classes.orderStatusIcon} />
                      </Box>
                    }
                    text="Currently"
                  >
                    {!statusDescription.canceled && statusDescription.completed ? (
                      <Typography variant="body3RegularInter" className={classes.simpleText}>
                        Completion Date <span>{estimatedShippingDate(order, isEnterpriseFlowActive, true)}</span>
                      </Typography>
                    ) : (
                      <Typography variant="body3RegularInter" className={classes.simpleText}>
                        Est Completion Date <span>{estimatedShippingDate(order, isEnterpriseFlowActive)}</span>
                      </Typography>
                    )}
                  </StatusBar>
                  <Box className={classes.statusDetail}>
                    <Typography
                      variant="body3SemiBoldInter"
                      className={classes.statusDetailTitleOn}
                      style={{ color: statusDescription.completed ? '#4CAF50' : undefined }}
                    >
                      {statusDescription.currently.title}
                    </Typography>
                    <Typography variant="body4RegularInter" className={classes.statusDetailText}>
                      {statusDescription.currently.description}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.orderStatusItem}>
                  {statusDescription.completed ? (
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Button component={Link} to="/inventory" size="small" variant="primary" style={{ height: 40 }}>
                          View Inventory
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <StatusBar
                        icon={
                          <Box className={classes.orderStatusIconWrapperNext}>
                            <ArrowForward className={classes.orderStatusIconNext} />
                          </Box>
                        }
                        isNext
                        text="Up Next"
                      >
                        <div style={{ height: 1, background: '#EBEDF0' }} />
                      </StatusBar>
                      <Box className={classes.statusDetail}>
                        <Typography
                          variant="body3SemiBoldInter"
                          className={classes.statusDetailTitleOn}
                          style={{ color: '#787B80' }}
                        >
                          {statusDescription.next.title}
                        </Typography>
                        <Typography variant="body4RegularInter" className={classes.statusDetailText}>
                          {statusDescription.next.futureDescription}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const OrderSummaryPageStatusContent = ({ invoice, isEnterpriseFlowActive, orders = [], isSummary }) => {
  const classes = useStyles({ isSummary });
  return (
    <Box className={classes.orderSummaryPageContainer}>
      {isSummary && (
        <Box>
          <Typography variant="h5SemiBoldInter" className={classes.title}>
            Thank you for your order!
          </Typography>
          <Typography variant="body3RegularInter" className={classes.subTitle}>
            {orderBeCompletedText({ ...invoice, orders }, isEnterpriseFlowActive)}
          </Typography>
        </Box>
      )}
      {orders.map(order => (
        <OrderPanel
          key={order.id}
          order={{ ...order, invoice }}
          isEnterpriseFlowActive={isEnterpriseFlowActive}
          directoryOrders={invoice.directory_orders}
        />
      ))}

      <Box className={classes.orderProductsPanel}>
        <Box>
          {invoice.products?.map(product => (
            <InvoiceProduct key={product.id} product={product} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const ShippingKindSummary = ({ title, directoryOrders, left }) => {
  const diorKind = title === 'Domestic' ? isDomesticShipping : isInternationalShipping;
  const diors = directoryOrders.filter(dior => diorKind(dior));

  const recipients = diors.length;
  const items = diors.reduce((sum, dior) => sum + sumByQuantity(dior.products), 0);
  const cost = diors?.reduce((sum, dior) => sum + Number(dior.total_shipping), 0) || 0;
  const classes = useStyles();
  return (
    <Grid
      container
      style={{
        paddingRight: left ? 42 : undefined,
        paddingLeft: left ? undefined : 42,
        borderLeft: left ? undefined : '1px solid #EBEDF0'
      }}
    >
      <Grid item xs={12}>
        <Typography variant="body2MediumInter" className={classes.statusSubTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body3MediumInter" className={classes.statusTextTitle}>
          Total recipients
        </Typography>
        <Typography variant="body3RegularInter">{recipients}</Typography>
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'center' }}>
        <Typography variant="body3MediumInter" className={classes.statusTextTitle}>
          Total items
        </Typography>
        <Typography variant="body3RegularInter">{items}</Typography>
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'right' }}>
        <Typography variant="body3MediumInter" className={classes.statusTextTitle}>
          Total cost
        </Typography>
        <Typography variant="body3RegularInter">{moneyStr(cost)}</Typography>
      </Grid>
    </Grid>
  );
};

const DirectoryOrders = ({ directory, invoice, setInfoMessage, onSelected }) => {
  const classes = useStyles();
  const recipient = directory?.employee;
  const [diroID, setDiorID] = useState();
  const [directoryOrders, setDirectoryOrders] = useState({});
  const [cancelShipmentOpen, setCancelShipmentOpen] = useState(false);

  const recipientName = joinFields([recipient.first_name, recipient.last_name], ' ');
  const recipientAddress = joinFields(
    [
      directory.shipping_address1,
      directory.shipping_address2,
      directory.shipping_city,
      directory.shipping_state,
      directory.shipping_zip,
      directory.shipping_country
    ],
    ', '
  );

  const [editRecipient, setEditRecipient] = useState(false);
  const [recipientInfo, setRecipientInfo] = useState({});
  const queryClient = useQueryClient();

  const setDataToEdit = async () => {
    setEditRecipient(true);
    setDiorID(directory?.id);
  };

  const history = useHistory();
  const location = useLocation();

  const shipmentID = directory?.id;

  const recipientNewShippingPrices = useMutation(data => shippingPricesApi.fetch(data), {
    onSuccess: ({ data }, newone) => {
      const { delivery_methods } = data;

      if (!delivery_methods)
        return setInfoMessage(
          data.data?.message
            ? `${data.data?.message}. Please contact your AE.`
            : 'There was an error. Please contact your AE.'
        );

      const deliveryMethod = delivery_methods.find(dm => dm.name === directory.delivery_method.name);

      if (!deliveryMethod)
        return setInfoMessage(
          `The Selected address does not have ${directory.delivery_method?.name} Shipping method. Please contact your AE.`
        );

      const newTotalShipping = sumByProperty(deliveryMethod?.prices.breakdown ?? [], 'total_price');
      onSelected(directory);
      history.replace({
        pathname: `/order-completed-details/${invoice.id}`,
        search: '?update=true',
        state: { newone, oldone: directory, newTotalShipping, oldTotalShipping: directory.total_shipping }
      });
      return setEditRecipient(false);
    },
    onError: () => {
      setInfoMessage('There was an error. Please contact your AE.');
    }
  });

  const handleSubmit = data => {
    const accountProducts = directory.products.reduce(
      (acc, p) => ({ ...acc, [p.product]: (acc[p.product] ?? 0) + p.quantity }),
      {}
    );
    const payload = {
      ...data,
      quantities: Object.entries(accountProducts).map(([product, quantity]) => ({ account_product: product, quantity }))
    };
    recipientNewShippingPrices.mutate(payload);
  };

  useEffect(() => {
    if (directory)
      setRecipientInfo({
        first_name: directory?.shipping_first_name,
        last_name: directory?.shipping_last_name,
        email: directory?.employee.email,
        phone_number: directory.phone_number,
        shipping_address1: directory.shipping_address1,
        shipping_address2: directory.shipping_address2,
        shipping_state: directory.shipping_state,
        shipping_city: directory.shipping_city,
        shipping_zip: directory.shipping_zip,
        shipping_country: directory.shipping_country
      });
  }, [directory]);

  const estimatedDate = days => {
    const diorProducts = invoice.products.filter(({ product }) =>
      directory.products.find(diorP => diorP.product === product.id)
    );
    const maxTurnaroundTime = Math.max(diorProducts.map(p => p.turnaround_time));
    const notificationDate = dayjs(invoice.paid_date || undefined);
    const readyShippingDate = dayjs(addBusinessDaysToDate(notificationDate, maxTurnaroundTime));
    return readyShippingDate.addBusinessDays(days).format('MMM DD, YYYY');
  };

  const steps = ['Order Placed', 'Processing', 'Preparing to Ship', 'Shipped', 'Delivered'];

  const getStep = () => {
    switch (directory.status) {
      case shipmentStatus.pendingProduction:
        return 2;
      case shipmentStatus.scheduled:
        return 3;
      case shipmentStatus.onItsWay:
        return 4;
      case shipmentStatus.failure:
        return 4;
      case shipmentStatus.invalidAddress:
        return 4;
      case shipmentStatus.returnToSender:
        return 4;
      case shipmentStatus.delivered:
        return 5;
      default:
        return 1;
    }
  };

  const { id: invoiceId } = useParams();
  const cancelShipment = useMutation(() => shipmentsApi.delete(shipmentID), {
    onSuccess: data => {
      if (data) return setInfoMessage(`${data}`);
      queryClient.invalidateQueries([apiPaths.invoice(invoiceId)]);
      queryClient.invalidateQueries([apiPaths.accounts]);
      return 0;
    },
    onError: () => {
      setInfoMessage('There was an error. Please contact your AE.');
    }
  });

  const handleDeleteShipment = async () => {
    try {
      const shipmentData = {
        shimpentID: directory?.id
      };
      cancelShipment.mutate(shipmentData);
    } catch (error) {
      // console.log('----error', error);
    }
  };

  const onCloseHandler = () => {
    setCancelShipmentOpen(false);
  };

  const onCloseCancelHandler = () => {
    handleDeleteShipment(shipmentID);
    setCancelShipmentOpen(false);
  };

  const showIconPaths = ['/order-completed-details/'];
  const shouldShowIcon = showIconPaths.some(path => location.pathname.includes(path));

  const { editAndCancelShipment } = useFlags();

  return (
    <>
      <Grid
        container
        alignItems="stretch"
        style={{ padding: '16px 24px', margin: '16px 0px', borderTop: '1px solid #EBEDF0' }}
      >
        <Grid item xs={3}>
          <Box>
            <Typography variant="body3RegularInter" style={{ marginBottom: 4, color: '#787B80' }}>
              Recipient
            </Typography>
            <Grid className={classes.editRecipientAddress}>
              <Typography variant="body2RegularInter" style={{ margin: '17px 0px', color: '#0F2440' }}>
                {recipientName}
              </Typography>
              {editAndCancelShipment && shouldShowIcon && directory.status === 'Pending Production' && (
                <IconButton onClick={() => setDataToEdit()} className={classes.editIconButton}>
                  <EditOutlinedIcon className={classes.editIcon} />
                </IconButton>
              )}
            </Grid>
            <Typography variant="body3RegularInter" className={classes.recipientAddress}>
              {recipientAddress}
            </Typography>
            {directory.shipping_notes && (
              <Box textAlign="justify">
                <Typography variant="body3RegularInter" style={{ marginBottom: 6, marginTop: 16, color: '#787B80' }}>
                  Shipping Notes
                </Typography>
                <Typography variant="body4RegularInter" style={{ color: '#4A505C' }}>
                  {directory.shipping_notes}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box className={classes.contentDivider} />
        </Grid>
        <Grid item xs>
          <Box style={{ display: 'grid' }}>
            <Grid container alignItems="center" style={{ marginBottom: 16 }}>
              <Grid item>
                <CustomTooltip
                  title={directory.invalid_address_error_message}
                  arrow
                  placement="top-start"
                  disableHoverListener={!directory.invalid_address_error_message}
                >
                  <ShipmentStatus status={directory.status} />
                </CustomTooltip>
              </Grid>
              <Grid item xs />
              <Grid item>
                {directory.tracking && (
                  <Typography variant="body4SemiBoldInter">
                    Tracking number: <a href={directory.tracking_url}>{directory.tracking}</a>
                  </Typography>
                )}
              </Grid>
            </Grid>
            {directory.status !== 'Delivered' && (
              <Typography variant="body2SemiBoldInter">
                Estimated shipment {estimatedDate(directory.delivery_method.turnaround_time_min_days)} -{' '}
                {estimatedDate(directory.delivery_method.turnaround_time_max_days)}
              </Typography>
            )}
            <Box style={{ marginBottom: 12, marginTop: 12, marginRight: 20 }}>
              <SimpleCustomStepper currentStep={getStep()} steps={steps} />
            </Box>
            <Typography
              variant="body3RegularInter"
              style={{ color: '#A4A7AD', padding: '20px 40px 20px 0px', textAlign: 'justify' }}
            >
              We will be gathering everything your need to pack your order. We’ll update the delivery date when your
              order is preparing to ship.
            </Typography>
            {editAndCancelShipment &&
              shouldShowIcon &&
              (directory.status === 'Pending Production' || directory.status === 'Scheduled') && (
                <Box style={{ display: 'flex', justifySelf: 'end', marginRight: 25, cursor: 'pointer' }}>
                  <Button
                    variant="text"
                    id="cancel-shipment"
                    className={classes.cancleBtn}
                    onClick={() => {
                      setDirectoryOrders(directory);
                      setCancelShipmentOpen(true);
                    }}
                  >
                    Cancel Shipment
                  </Button>
                </Box>
              )}
          </Box>
        </Grid>
      </Grid>
      <EditShippingRecipient
        open={editRecipient}
        handleSubmit={handleSubmit}
        onClose={() => setEditRecipient(false)}
        defaultValues={recipientInfo}
      />
      {cancelShipmentOpen && (
        <CancelModal
          open={cancelShipmentOpen}
          shipmentToDelete={directoryOrders}
          onClose={onCloseHandler}
          onCancel={onCloseCancelHandler}
        />
      )}
    </>
  );
};

const OrderSummaryPage = () => {
  const [openDiors, setOpenDiors] = useState(true);
  const [retries, setRetries] = useState(0);
  const [invoice, setInvoice] = useState();
  const [infoMessage, setInfoMessage] = useState();
  const [selectedDirectoryOrder, setSelectedDirectoryOrder] = useState();
  const history = useHistory();

  const location = useLocation();
  const { state } = location;
  const query = useQueryParams();
  const update = query.get('update');

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOnClose = () => {
    query.delete('update');
    history.replace({ ...location, search: query.toString() });
  };

  const queryClient = useQueryClient();
  const editRecipientAddress = useMutation(
    data => {
      return shipmentsApi.updateAddressQuery(
        data?.shipmentID,
        { ...data, credits_to_use: undefined },
        data.credits_to_use
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([apiPaths.invoice(invoice?.id)]);
        queryClient.invalidateQueries([apiPaths.accounts]);
        // setEditRecipient(false);
        handleOnClose();
      }
    }
  );

  const handleUpdateShipment = data => {
    editRecipientAddress.mutate(data);
  };

  useEffect(() => {
    if (update === 'true') {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [update]);

  const handleToastClose = () => {
    setInfoMessage();
  };

  const { id: invoiceId } = useParams();

  const { isError, isLoading } = useQuery([apiPaths.invoice(invoiceId)], () => invoices.fetch(invoiceId), {
    onSuccess: response => {
      setInvoice(response.data);
    }
  });

  const { data } = useQuery([apiPaths.accountOrders, invoice?.id], () => fetchInvoiceOrders(invoice?.id), {
    enabled: !!invoice?.id
  });

  const orders = data?.data?.results;

  const { enterpriseGlobalCheckoutFlowTemp031722: enterpriseCheckoutActive } = useFlags();
  const { data: company } = useCompany();
  const isEnterpriseAccount = !!company?.enterprise;
  const isEnterpriseFlowActive = isEnterpriseAccount && enterpriseCheckoutActive;

  const finalBalance = company?.balance || 0;

  useEffect(() => {
    if (isError) toErrorPage(invoice, history);
  }, [isError, invoice, history]);

  useEffect(() => {
    if (retries < 5 && !isEmpty(orders) && orders.some(o => isEmpty(o.sales_order))) {
      setTimeout(() => {
        queryClient.invalidateQueries([apiPaths.accountOrders, invoice?.id]);
        setRetries(prev => prev + 1);
      }, 60000);
    }
  }, [invoice?.id, orders, queryClient]);

  // const individualPackPrices = useSelector(state => state.individualPackPrices);
  // const affiliateId = company.affiliate_id;
  // const isPostalReferral = affiliateId === 'postal.io';
  // const postalLink = <Link to="https://postalio.app/integrations">here</Link>;

  // const storagePrice = parseFloat(invoice.total_fulfillment);
  // const selectedCredit = parseFloat(invoice.shipping_credits);
  // const totalPlusTax = parseFloat(invoice.total_plus_tax);
  // const tax = parseFloat(invoice.tax);

  const classes = useStyles();

  const isSummary = !location.pathname.includes('/order-completed-details');

  const reorderLink = useReorderLink(invoice?.products?.map(({ product }) => product.id) || []);
  if (isLoading || !invoice) return <Loader />;

  return (
    <>
      <div className={classes.container}>
        <CenteredGrid container>
          <Helmet tags={seoTags.orderReview} />
          <Box style={{ paddingBottom: 16, width: '100%' }}>
            {infoMessage && (
              <Grid container justifyContent="center" style={{ position: 'relative' }}>
                <Alert
                  onClose={handleToastClose}
                  delayTime={5000}
                  className={classes.floatingInfoMessage}
                  fontStyles={{ fontSize: 12, padding: 0 }}
                  severity="error"
                >
                  {infoMessage}
                </Alert>
              </Grid>
            )}
            <Grid container alignItems="center">
              <Grid item>
                <GoBack link="/orders-completed" classes={classes} />
              </Grid>
              <Grid item xs />
              <Grid item>
                {!isSummary && (
                  <Button variant="primary" component={Link} size="small" to={reorderLink} style={{ height: 40 }}>
                    Reorder
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={8}>
            <Grid item md={8} sm={12}>
              <Box>
                {isSummary && (
                  <Box className={classes.header}>
                    <CustomStepper
                      currentStep={4}
                      steps={['Review Order', 'Add Funds', 'Payment Method', 'Order Summary']}
                      exactProgress={[4, 31, 57, 100]}
                    />
                  </Box>
                )}

                <OrderSummaryPageStatusContent
                  invoice={invoice}
                  isEnterpriseFlowActive={isEnterpriseFlowActive}
                  orders={orders}
                  isSummary={isSummary}
                />
              </Box>
            </Grid>
            <Grid item md={4} sm={12}>
              <InvoiceReviewSummary invoice={invoice} finalBalance={finalBalance} />
            </Grid>
            <Grid item xs={12}>
              {invoice.directory_orders?.length > 0 && (
                <Grid className={classes.shippingSummary}>
                  <Box className={classes.shipmentContainer}>
                    <Grid container alignItems="center" className={classes.statusMainTitle}>
                      <Grid item xs>
                        <Typography variant="h6SemiBoldInter">Shipping Summary</Typography>
                      </Grid>
                      <Grid item>
                        <ToogleButtom onClick={() => setOpenDiors(prev => !prev)} opened={openDiors} />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <ShippingKindSummary title="Domestic" directoryOrders={invoice.directory_orders} left />
                      </Grid>
                      <Grid item xs={6}>
                        <ShippingKindSummary title="International" directoryOrders={invoice.directory_orders} />
                      </Grid>
                    </Grid>
                  </Box>

                  {openDiors && (
                    <Box>
                      {invoice.directory_orders.map(dior => (
                        <DirectoryOrders
                          key={dior.id}
                          directory={dior}
                          invoice={invoice}
                          setInfoMessage={setInfoMessage}
                          onSelected={d => setSelectedDirectoryOrder(d)}
                        />
                      ))}
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </CenteredGrid>
        <UpdateShippingModal
          open={modalOpen}
          onClose={handleOnClose}
          directory={selectedDirectoryOrder}
          handleCloseModal={handleCloseModal}
          handleUpdateShipment={handleUpdateShipment}
          content={state}
        />
      </div>
    </>
  );
};

export default OrderSummaryPage;
