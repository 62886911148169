const styles = theme => ({
  summaryContainer: {
    maxWidth: '486px',
    borderRadius: '4px',
    backgroundColor: '#fafafa',
    padding: '15px',
    paddingTop: 8,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
    [theme.breakpoints.only('sm')]: { margin: 'auto' }
  },
  summaryTable: {
    borderTop: 'solid 2px #434c5f'
  },
  tableHeader: {
    fontFamily: 'Futura',
    fontSize: 24,
    fontWeight: 500,
    margin: 0,
    padding: 0,
    paddingBottom: 8,
    color: '#434c5f'
  },
  editLink: {
    fontSize: 16,
    color: '#3577d4',
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  tableHead: {
    borderBottom: 'solid 2px #434c5f',
    paddingLeft: 0,
    paddingRight: 0,
    '& tr': {
      height: '40px'
    }
  },
  tableRow: {
    paddingLeft: 0,
    paddingRight: 0,
    '& td': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  tableCell: {
    padding: '16px 0px 12px 0px'
  },
  packTitle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    lineHeight: 1.43,
    padding: 4
  },
  packTotal: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    lineHeight: 1.43,
    padding: 4
  },
  itemDesc: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#434c5f',
    padding: 4
  }
});

export default styles;
