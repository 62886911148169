export default {
  container: {
    width: 240,
    height: 305,
    margin: '0 24px 12px 0',
    '&.selectable:hover': {
      '& $selectBtn': {
        opacity: 1,
        transition: 'opacity 150ms ease'
      },
      '& $card': {
        padding: '17px 29px',
        backgroundColor: '#3577d4',
        transition: 'padding 250ms ease, background-color 150ms ease'
      },
      '& $image': {
        width: 96,
        height: 96,
        transition: 'all 250ms ease'
      },
      '& $quantities': {
        transform: 'translateY(0)',
        transition: 'opacity 250ms ease, transform 250ms ease'
      }
    }
  },
  card: {
    position: 'relative',
    height: 240,
    padding: ({ selected }) => (selected ? '17px 29px' : 24),
    borderRadius: 20,
    boxShadow: '0 16px 36px 0 rgba(0, 0, 0, 0.05)',
    border: 'solid 1px #f0f2f5',
    backgroundColor: ({ selected }) => (selected ? '#3577d4' : '#ffffff'),
    overflow: 'hidden',
    transition: 'padding 250ms ease, background-color 150ms ease'
  },
  image: {
    width: ({ selected }) => (selected ? 96 : 192),
    height: ({ selected }) => (selected ? 96 : 192),
    borderRadius: 15,
    backgroundColor: '#ffffff',
    transition: 'all 250ms ease'
  },
  quantities: {
    transform: ({ selected }) => (selected ? 'translateY(0)' : 'translateY(250%)'),
    transition: 'opacity 250ms ease, transform 250ms ease',
    '& p': {
      color: '#ffffff',
      fontSize: 14,
      lineHeight: 0.71
    }
  },
  productName: {
    color: '#0f2440',
    fontWeight: 700,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 'calc(100% - 42px)'
  },
  selectBtn: {
    padding: 8,
    backgroundColor: ({ selected }) => (selected ? '#3577d4' : '#f2f6fd'),
    opacity: ({ selected }) => (selected ? 1 : 0),
    transition: 'opacity 150ms ease',
    '&:hover': {
      backgroundColor: ({ selected }) => (selected ? '#3577d4' : '#f2f6fd')
    }
  },
  unselectable: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(11, 24, 41, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      color: '#ffffff',
      fontWeight: 700,
      textAlign: 'center'
    }
  }
};
