import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import { useLocation, Link } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import { useQueryClient } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { moneyStr, formatDate, buildUrlWithParam } from '../../../../helpers/utils';
import { invoiceStatusStyles } from '../../../account/Invoices';
import { invoiceStatus } from '../../../../apis/constants';
import { StatusChip } from '../../../global/proofsCommon';
import { OrderAgainButton, OrderProduct, isOrderProcessing } from './CommonOrderElements';
import { ellipsisStyles } from '../../../shared/styles/commonStyles';
import apiPaths from '../../../../helpers/apiPaths';
import SearchSortFilter from '../../../shared/SearchSortFilter';

const columnHeaderCommon = { fontFamily: 'Gilroy-Medium', fontSize: 12, color: '#787b80' };
const columnInfoCommon = { fontFamily: 'Gilroy-Medium', fontSize: 16, color: '#0b1829', marginTop: 4 };
const buttonCommon = { fontFamily: 'Gilroy-SemiBold', fontSize: 16, fontWeight: 500 };

const useStyles = makeStyles({
  columnHeader: { ...columnHeaderCommon },
  total: { ...columnInfoCommon },
  fullName: { ...ellipsisStyles, ...columnInfoCommon, maxWidth: 200 },
  createdAtHeader: { ...columnHeaderCommon, marginLeft: 15 },
  createdAtInfo: { ...columnInfoCommon, marginLeft: 15 },
  detailsBtn: { ...buttonCommon, '&:hover': { color: '#004ca2' } },
  toggleBtn: {
    ...buttonCommon,
    margin: '5px 0px 10px 17px',
    '&:hover': { color: '#004ca2' },
    '& svg': { marginLeft: 4 }
  },
  orderCard: {
    marginBottom: 25,
    borderRadius: 12,
    border: '1px solid #ebedf0',
    backgroundColor: '#fff'
  },
  orderHeader: {
    backgroundColor: '#f4f8ff',
    height: 72,
    padding: '5px 20px 0px 20px'
  },
  orderNo: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    color: '#0b1829'
  }
});

const OrderHeader = ({ order, isLoading, classes }) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { swagUpCredits } = useFlags();
  const products = order.products.map(p => p.product);
  const urlToOpen = buildUrlWithParam(location, 'open', order.invoice_name);

  const handleClick = () => queryClient.fetchQuery([apiPaths.accountOrders, order.id], () => order);

  return (
    <Grid container className={classes.orderHeader}>
      <Grid item container sm={9} alignItems="center">
        <Grid item xs={3}>
          <p className={classes.orderNo}>Order #{order.order_no}</p>
        </Grid>
        <Grid item container xs={2} justifyContent="center">
          <StatusChip label={order.status} status={invoiceStatusStyles[order.status]} />
        </Grid>
        <Grid item container xs={2} direction="column">
          <p className={classes.createdAtHeader}>Order placed</p>
          <p className={classes.createdAtInfo}>{formatDate(order.created_at)}</p>
        </Grid>
        <Grid item container xs={3} direction="column">
          {order.created_by_fullname && (
            <>
              <p className={classes.columnHeader}>By</p>
              <p className={classes.fullName}>{order.created_by_fullname}</p>
            </>
          )}
        </Grid>
        <Grid item container xs={2} direction="column">
          <p className={classes.columnHeader}>Total</p>
          <p className={classes.total}>{moneyStr(order.total_plus_tax)}</p>
        </Grid>
      </Grid>
      <Grid item container sm={3} alignItems="center">
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Button
            id="view-details"
            variant="text"
            component={Link}
            to={swagUpCredits ? `/order-completed-details/${order.invoice}` : `/order-details/${order.id}`}
            onClick={handleClick}
            className={classes.detailsBtn}
          >
            View details
          </Button>
        </Grid>
        <Grid item xs={6}>
          <OrderAgainButton
            products={products}
            urlToOpen={order.status === invoiceStatus.pendingPayment ? urlToOpen : undefined}
            isOrderProcessing={isOrderProcessing(order)}
            isLoading={isLoading}
            width={144}
            fontSize={14}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const Order = ({ order, isLoading }) => {
  const classes = useStyles();
  const [showAll, toggleShowAll] = React.useReducer(prevShowAll => !prevShowAll, false);

  const productCount = order.products.length;
  const viewToggleText = showAll ? 'Collapse' : `Show ${productCount - 3} more item${productCount > 4 ? 's' : ''}`;
  const products = order.products.slice(0, showAll ? productCount : 3);

  return (
    <Grid item container xs={12} className={classes.orderCard}>
      <Grid item xs={12}>
        <OrderHeader order={order} isLoading={isLoading} classes={classes} />
        {products.map((product, index) => (
          <OrderProduct
            key={product.id}
            orderProduct={product}
            showReorderItem={productCount > 1 && order.status === invoiceStatus.paid}
            showDivider={productCount !== index + 1 || productCount > 3}
          />
        ))}
      </Grid>
      {productCount > 3 && (
        <Button variant="text" onClick={toggleShowAll} className={classes.toggleBtn}>
          {viewToggleText}
          {showAll ? <ExpandLessIcon style={{ marginTop: 2 }} /> : <ExpandMoreIcon />}
        </Button>
      )}
    </Grid>
  );
};

export default Order;
