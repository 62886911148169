import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Dialog, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { Check, Close } from '@material-ui/icons';
import { isNumber } from 'lodash';
import styles from '../styles/storefrontsHome';
import { StylessButton } from '../../buttons';
import { moneyStr, creditColor } from '../../../helpers/utils';
import CreditDrawer from '../components/CreditCardDrawer/CreditDrawer';
import { CustomTooltip } from '../../products/commonProductsElements';
import usePageProductBasedInfo from '../hooks/usePageProductBasedInfo';
import { CustomSlimTextField, CustomTextField, MIN_CREDIT_BALANCE } from '../storeCommon';

const useStyles = makeStyles(styles);

const Section = ({ title, children, spacing = 10, infoIcon }) => {
  const classes = useStyles();
  return (
    <Grid container style={{ border: '1px solid #E5E7E8', borderRadius: 10, padding: 16, marginBottom: 24 }}>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="body1MediumInter" className={classes.sectionLabel} style={{ marginBottom: spacing }}>
              {title}
            </Typography>
          </Grid>
          <Grid item>{infoIcon}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

const CoolSwitch = ({ options, value, onChange = v => v }) => {
  const [selectedOption, setSelectedOption] = useState(value);

  const [firstOption, secondOption] = options;
  const handleOnSelect = option => {
    onChange(option);
    setSelectedOption(option);
  };
  const classes = useStyles();
  return (
    <Box>
      <Grid container>
        <Grid item>
          <StylessButton onClick={() => handleOnSelect(firstOption)}>
            <div
              style={{
                height: 24,
                padding: '0px 8px 0px 12px',
                borderRadius: '12px 0px 0px 12px',
                background: selectedOption === firstOption ? '#4CAF50' : 'transparent',
                border: `1px solid ${selectedOption === firstOption ? 'transparent' : 'rgba(0, 0, 0, 0.08)'}`
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  {selectedOption === firstOption ? (
                    <Check style={{ color: '#ffffff', fontSize: 18 }} />
                  ) : (
                    <Check style={{ color: '#000', fontSize: 18 }} />
                  )}
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body4SemiBoldInter"
                    style={{ color: selectedOption === firstOption ? '#ffffff' : '#000' }}
                  >
                    {firstOption}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </StylessButton>
        </Grid>
        <Grid item>
          <StylessButton onClick={() => handleOnSelect(secondOption)}>
            <div
              style={{
                height: 24,
                padding: '0px 12px 0px 4px',
                borderRadius: '0px 12px 12px 0px',
                background: selectedOption === secondOption ? '#4CAF50' : 'transparent',
                border: `1px solid ${selectedOption === secondOption ? 'transparent' : 'rgba(0, 0, 0, 0.08)'}`
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  {selectedOption === secondOption ? (
                    <Check style={{ color: '#ffffff', fontSize: 18 }} />
                  ) : (
                    <Check style={{ color: '#000', fontSize: 18 }} />
                  )}
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body4SemiBoldInter"
                    style={{ color: selectedOption === secondOption ? '#ffffff' : '#000' }}
                  >
                    {secondOption}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </StylessButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const shippingSections = [
  {
    name: 'US only',
    shippingRateFields: [
      {
        name: 'Standard Shipping',
        property: 'shippingUSStandardRate',
        businessDays: 19
      },
      {
        name: 'Rush Shipping',
        property: 'shippingUSSExpressRate',
        businessDays: 17
      }
    ]
  },
  {
    name: 'International',
    shippingRateFields: [
      {
        name: 'Standard Shipping',
        property: 'shippingInternationalStandardRate',
        businessDays: 19
      },
      {
        name: 'Rush Shipping',
        property: 'shippingInternationalExpressRate',
        businessDays: 17
      }
    ]
  }
];

const StoreSettings = ({ store, setStoreSettings, setStore }) => {
  const handleInputChange = event => {
    const newValue = event.target.value;
    // Check if the new value is numeric or a valid negative number
    if (/^-?\d*$/.test(newValue)) {
      return newValue;
    }

    return undefined;
  };

  const classes = useStyles();

  return (
    <Grid container>
      <Section title="General settings">
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography variant="body3RegularInter" style={{ marginBottom: 6 }}>
              Contact Email
            </Typography>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <CustomSlimTextField
                  placeholder="contact@company.com"
                  defaultValue={store.generalSettings.contactEmail}
                  value={store.generalSettings.contactEmail}
                  autoComplete="email"
                  name="additionalMargin"
                  onChange={event =>
                    setStoreSettings(
                      {
                        contactEmail: event.target.value
                      },
                      'generalSettings'
                    )
                  }
                  fullWidth
                />
              }
            />
            <Typography variant="body4RegularInter" style={{ marginTop: 12, fontSize: 10, marginBottom: 16 }}>
              Email customers can use to directly contact you.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs>
                <Typography variant="body3RegularInter">Stop selling a product once it is out of stock?</Typography>
              </Grid>
              <Grid item>
                <CoolSwitch
                  options={['Yes', 'No']}
                  value={store.generalSettings.stopSellingOutOfStock ? 'Yes' : 'No'}
                  onChange={value => setStoreSettings({ stopSellingOutOfStock: value === 'Yes' }, 'generalSettings')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <Section title="Shipping settings">
        <Grid container>
          <Grid item xs>
            <Typography variant="body3RegularInter">Charge SwagUp Shipping rates</Typography>
          </Grid>
          <Grid item>
            <CoolSwitch
              options={['SwagUp', 'Custom']}
              value={store.shippingSettings.isCustomRate ? 'Custom' : 'SwagUp'}
              onChange={value => setStoreSettings({ isCustomRate: value === 'Custom' }, 'shippingSettings')}
            />
          </Grid>
        </Grid>
        {!store.shippingSettings.isCustomRate && (
          <Box style={{ marginTop: 24 }}>
            <Typography variant="body4RegularInter" className={classes.inputLabel}>
              Add a a margin on top of our rates
            </Typography>
            <Box style={{ width: 84 }}>
              <FormControlLabel
                className={classes.formControl}
                labelPlacement="top"
                control={
                  <CustomSlimTextField
                    placeholder="0"
                    defaultValue={store.shippingSettings.additionalMargin}
                    value={store.shippingSettings.additionalMargin}
                    name="additionalMargin"
                    onChange={event =>
                      setStoreSettings(
                        {
                          additionalMargin: handleInputChange(event) || store.shippingSettings.additionalMargin
                        },
                        'shippingSettings'
                      )
                    }
                    fullWidth
                    InputProps={{
                      endAdornment: '%'
                    }}
                  />
                }
              />
            </Box>
          </Box>
        )}
        {store.shippingSettings.isCustomRate &&
          shippingSections.map(shs => (
            <Box key={shs.name} style={{ marginTop: 24 }}>
              <Typography variant="body3SemiBoldInter" style={{ marginBottom: 6 }}>
                {shs.name}
              </Typography>
              <div style={{ height: 1, background: '#E5E7E8', marginBottom: 6 }} />
              <Box>
                {shs.shippingRateFields.map(srf => (
                  <Grid key={srf.name} container alignItems="center" spacing={4} style={{ marginTop: 16 }}>
                    <Grid item xs>
                      <Typography variant="body3SemiBoldInter" style={{ marginBottom: 6, color: '#4A4F54' }}>
                        {srf.name}
                      </Typography>
                      <Typography
                        variant="body4RegularInter"
                        style={{ color: '#4A4F54' }}
                      >{`${srf.businessDays} Business Days`}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        className={classes.checkBoxFormControl}
                        labelPlacement="right"
                        control={
                          <Checkbox
                            color="primary"
                            checked={store.shippingSettings[srf.property] === 0}
                            onChange={event =>
                              setStoreSettings(
                                {
                                  [srf.property]: event.target.checked ? 0 : store.shippingSettings[srf.property]
                                },
                                'shippingSettings'
                              )
                            }
                            style={{ cursor: 'pointer' }}
                            className={classes.freeCheckbox}
                          />
                        }
                        label={
                          <Typography
                            variant="body4RegularInter"
                            className={classes.inputLabel}
                            style={{ marginBottom: 0 }}
                          >
                            Free
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body4RegularInter">or</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Box style={{ position: 'relative' }}>
                        <Typography variant="body4RegularInter" className={classes.inputLabelLight}>
                          Select Price
                        </Typography>
                        <FormControlLabel
                          className={classes.formControl}
                          labelPlacement="top"
                          control={
                            <CustomSlimTextField
                              placeholder="0"
                              defaultValue={store.shippingSettings[srf.property]}
                              value={store.shippingSettings[srf.property]}
                              name={srf.property}
                              onChange={event =>
                                setStoreSettings(
                                  {
                                    [srf.property]: isNumber(parseInt(event.target.value, 10))
                                      ? Number(event.target.value)
                                      : store.shippingSettings[srf.property]
                                  },
                                  'shippingSettings'
                                )
                              }
                              fullWidth
                              InputProps={{
                                startAdornment: <span id="start-adornment">$</span>
                              }}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Box>
          ))}

        {store.shippingSettings.isCustomRate && (
          <Typography variant="body4RegularInter" style={{ color: '#3577D4', marginTop: 32 }}>
            You will be charged SwagUp rates, regardless.
          </Typography>
        )}
      </Section>
    </Grid>
  );
};

export default StoreSettings;
