import axios from 'axios';
import chunk from 'lodash/chunk';
import { errorAndLog } from '../../helpers/utils';
import Dashboard from '../DashBoard';
import apiPaths from '../../helpers/apiPaths';
import { status201, status200 } from './status.utils';

// We should be able to avoid using a loop and change to
// a parallel approach after some changes in the api.
// For now, this works as expected without too much fuzz.
const sendSwag = async (orders, maxPayloadSize) => {
  const chunks = chunk(orders, maxPayloadSize);

  const results = [];

  try {
    // eslint-disable-next-line no-restricted-syntax
    for (const chk of chunks) {
      // eslint-disable-next-line no-await-in-loop
      const result = await Dashboard.post(apiPaths.employeeOrders, chk, status201);
      results.push(result.data);
    }
  } catch (e) {
    return errorAndLog('sendSwag', e.status, e.data);
  }

  return results;
};

// We should be able to avoid using a loop and change to
// a parallel approach after some changes in the api.
// For now, this works as expected without too much fuzz.
const sendSwagBulkEmployeeOrders = async payload => {
  try {
    const result = await Dashboard.post(apiPaths.bulkEmployeeOrders, payload, status201);
    return result;
  } catch (e) {
    return errorAndLog('sendSwag', e.status, e.data);
  }
};

let cancelTokenSource;
export default {
  async fetch({
    offset = 0,
    limit = 24,
    search = '',
    createdAt = 'all',
    statusIn,
    exportCSV,
    ordering,
    isCancellable = true,
    ...rest
  }) {
    if (cancelTokenSource) cancelTokenSource.cancel('Shipment request canceled due to new request');
    const commonParams = {
      search,
      created_at: createdAt,
      ordering: `is_valid_address,${ordering}`,
      ...rest
    };
    const params = statusIn
      ? {
          status__in: statusIn,
          ...commonParams
        }
      : commonParams;
    // If the order of the params changes or some param is added or deleted,
    // then check the tests of the components that use this api call
    const config = exportCSV
      ? {
          headers: { Accept: 'text/csv' },
          params
        }
      : {
          params: {
            ...params,
            offset,
            limit
          }
        };

    cancelTokenSource = isCancellable ? axios.CancelToken.source() : null;
    const cancelToken = cancelTokenSource?.token;
    const result = await Dashboard.get(apiPaths.employeeOrders, { cancelToken, status200, ...config });
    cancelTokenSource = null;

    return result.data;
  },

  async get(id) {
    const result = await Dashboard.get(apiPaths.employeeOrder(id));

    return result.data;
  },

  async delete(id) {
    const result = await Dashboard.delete(apiPaths.employeeOrder(id)).catch(err => err);
    return result.data;
  },

  async softDelete(id) {
    const result = await Dashboard.patch(apiPaths.employeeOrder(id), { delete_it: true }).catch(err => err);
    return !result.data;
  },

  async updateAddress(id, newAddress) {
    try {
      const response = await Dashboard.patch(apiPaths.employeeOrder(id), {
        ...newAddress,
        phone_number: newAddress.phone_number ?? null
      });
      return response;
    } catch (e) {
      return errorAndLog('errorInUpdateAddress', e?.status, e?.data);
    }
  },

  async updateAddressQuery(id, newAddress, creditsToUse) {
    try {
      const response = await Dashboard.patch(`${apiPaths.employeeOrder(id)}?credits_to_use=${creditsToUse}`, {
        ...newAddress,
        phone_number: newAddress.phone_number ?? null
      });
      return response;
    } catch (e) {
      return errorAndLog('errorInUpdateAddress', e?.status, e?.data);
    }
  },

  sendSwag,
  sendSwagBulkEmployeeOrders,
  shipmentSources: async () => {
    const result = await Dashboard.post(apiPaths.shipmentSources);

    return result.data;
  }
};
