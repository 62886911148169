import { SET_SHOW_DASHBOARD_MESSAGE } from './types';
import log from '../logger';

export const setShowDashboardMessage = show => dispatch => {
  log.debug('setShowDashboardMessage Action - Entering');
  dispatch({
    type: SET_SHOW_DASHBOARD_MESSAGE,
    payload: show
  });
};
