import * as React from 'react';
import { Box, Grid, IconButton, makeStyles, Modal } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { ArrowBack, Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useState, useEffect, useMemo } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { isEmpty } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import useIntegrations from '../../hooks/useIntegrations';
import { StylessButton } from '../buttons';
import { imageSrcSet } from '../../helpers/utils';
import {
  actions,
  ConnectionOptions,
  ConntecExistingForm,
  ConntecExistingVariantForm,
  ConntecExistingVariantToSwagUPPorductForm,
  getImageAttributes,
  getItegrationLogoImageUrl,
  isNumberWithTwoDecimalPlaces,
  NewProductForm,
  PlatformConnectionOptions,
  prepareArtworksOnS3V2
} from './common';
import { toSentenceCase } from '../account/MembershipCommon';
import { integrationsApi } from '../../apis/swagup';
import apiPaths from '../../helpers/apiPaths';
import Img from '../shared/Img';
import { usePagination } from '../../hooks';

const styles = theme => ({
  modalViewDetailsContainer: {
    borderRadius: 20,
    background: 'white',
    padding: 24,
    position: 'relative'
  },
  automationModal: {
    padding: 0,
    marginTop: '92px',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 680,
    overflow: 'hidden'
  },
  automationModalHeader: {
    padding: '12px 0px 34px 0px'
  },
  automationModalHeaderText: {
    ...ellipsisStyles,
    color: '#131415',
    fontSize: '20px',
    lineHeight: ' 28px',
    letterSpacing: '-0.03em'
  },
  modalIconWrapper: {
    width: 224,
    height: 112,
    position: 'relative'
  },
  modalIconWrapperSmall: {
    width: 124,
    height: 52,
    position: 'relative'
  },
  modalIconImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  modalHeaderBar: { height: 52 },
  modalBody: {},
  close: {
    width: 20,
    height: 20,
    fontSize: 12
  },
  swipeableViews: {
    height: '100%',
    width: '100%',
    // minHeight: 'calc(100vh - 202px)',
    '& .react-swipeable-view-container': {
      height: '100%'
    },
    '& > div > div': {
      overflowX: 'hidden !important',
      overflowY: 'hidden !important'
    }
  },
  footer: {
    width: '100%',
    height: 64,
    paddingTop: 16,
    background: '#ffffff',
    '& p': {
      color: '#3577D4',
      marginBottom: 4,
      marginLeft: 12
    }
  },
  storeFooterText: { color: '#989EA4 !important', margin: '4px 0px 0px 0px' },
  floatingInfoMessage: {
    maxWidth: 464,
    zIndex: 99,
    width: 'auto',
    top: '32px',
    position: 'fixed'
  }
});

const useStyles = makeStyles(styles);

const getImage = image => image || '/images/public/nopic.jpg';
const productConvertion = product => {
  return {
    ...product,
    image: getImage(product.image),
    variants: product.variants.map(v => ({ ...v, name: v.title, description: v.title, image: getImage(v.image) }))
  };
};

const perPageOptions = [8, 16, 24, 32];

const IntegrationModal = ({ isOpen, setOpen, product, availableIntegrations, onIntegrationSuccess }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [selectedAction, setSelectedAction] = useState('new-product');
  const [platformProduct, setPlatformProduct] = useState({});
  const [actionButtonText, setActionButtonText] = useState('Connnect');
  const [swagUpProduct, setSwagUpProduct] = useState();
  const [doShowSubmit, setDoShowSubmit] = useState(false);
  const [infoMessage, setInfoMessage] = useState();
  const [isLoadingImage, setisLoadingImage] = useState(false);
  const [search, setSearch] = useState();
  const [total, setTotal] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(12);

  const { getConnectedPlatform, onOpen } = useIntegrations();
  const pageIndex = offset / limit;
  const paginatedFlieds = useMemo(
    () => ({
      nextPage: () => setOffset(prev => (prev < total ? (pageIndex + 1) * limit : prev)),
      pageIndex,
      perPage: limit,
      sizeOptions: perPageOptions,
      previousPage: () => setOffset(prev => (prev > 0 ? (pageIndex - 1) * limit : prev)),
      changeSize: newSize => setLimit(newSize)
    }),
    [limit, pageIndex, total]
  );

  const { data: integrationProductsData, isLoading: integrationProductsLoading } = useQuery(
    [apiPaths.integrationProducts, selectedPlatform?.connectionAccess?.access_token, search, limit, offset],
    () =>
      integrationsApi.fetchIntegrationProducts(selectedPlatform?.connectionAccess?.access_token, search, limit, offset),
    {
      enabled: !!selectedPlatform?.connectionAccess?.access_token
    }
  );

  useEffect(() => {
    if (integrationProductsData) setTotal(integrationProductsData.count);
  }, [integrationProductsData]);

  const integrationProducts = integrationProductsData?.results.map(productConvertion) || [];
  const queryClient = useQueryClient();

  const createNewIntegrationProduct = useMutation(
    newProduct => integrationsApi.createNewProduct(newProduct, selectedPlatform?.connectionAccess?.access_token),
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(apiPaths.accountProducts);
        queryClient.invalidateQueries(apiPaths.integrationProducts);
        const storeName = selectedPlatform?.connection?.domain_name?.replace('.myshopify.com', '');
        const { data: createdProduct } = data;
        onIntegrationSuccess({
          title: 'Your product has been successfully created!',
          subtitle: `Finish setting up this product on ${toSentenceCase(selectedPlatform.connectedPlatform)}`,
          cta: {
            text: `View in ${toSentenceCase(selectedPlatform.connectedPlatform)}`,
            link: `https://admin.shopify.com/store/${storeName}/products/${createdProduct?.external_platform_id}`,
            external: true
          }
        });
        setCurrentStep(1);
        setOpen(false);
      },
      onError: () => {
        setInfoMessage('There was an error. Please contact your AE.');
      }
    }
  );

  const connectIntegrationProduct = useMutation(
    data => integrationsApi.connectProduct(data, selectedPlatform?.connectionAccess?.access_token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiPaths.accountProducts);
        queryClient.invalidateQueries(apiPaths.integrationProducts);
        queryClient.invalidateQueries(
          `${apiPaths.integrationProducts}-${selectedPlatform?.connectionAccess?.access_token}`
        );
        onIntegrationSuccess({
          title: ' Your products are now connected!',
          subtitle: `Check your tool to confirm.`,
          cta: {
            text: `View Orders`,
            link: `/integrations/orders?platform=${selectedPlatform.connectedPlatform}`
          }
        });
        setOpen(false);
        setCurrentStep(1);
      },
      onError: () => {
        setInfoMessage('There was an error. Please contact your AE.');
      }
    }
  );

  const onSelectPlatform = integration => {
    const connectedPlatform = getConnectedPlatform(integration.rutter_id);
    if (connectedPlatform) {
      if (connectedPlatform.connection?.isReady) {
        setSelectedPlatform(connectedPlatform);
        setCurrentStep(2);
      } else {
        setInfoMessage("We're still connecting your store. Check back soon.");
      }
    } else {
      onOpen(integration.rutter_id);
    }
  };

  const onUpdate = integrationProduct => {
    setPlatformProduct(integrationProduct);
  };

  const onUpdateSwagUpProduct = integrationProduct => {
    setSwagUpProduct(integrationProduct);
  };

  const onUpdateNext = integrationProduct => {
    setPlatformProduct(integrationProduct);
    setCurrentStep(prev => prev + 1);
  };

  useEffect(() => {
    setDoShowSubmit(currentStep > 4);
  }, [currentStep, product, swagUpProduct]);

  useEffect(() => {
    setInfoMessage();
  }, [isOpen]);

  useEffect(() => {
    if (!swagUpProduct) setSwagUpProduct(product);
  }, [product, swagUpProduct]);

  useEffect(() => {
    setActionButtonText(selectedAction === actions.connectExisting ? 'Connect' : 'Create product');
  }, [selectedAction]);

  const onConnect = () => {
    const syncIntegrationProduct = {
      swagup_product: swagUpProduct.id,
      size: swagUpProduct.variants[0].id,
      rutter_id: platformProduct.rutter_id
    };
    connectIntegrationProduct.mutate(syncIntegrationProduct);
  };

  const onCreate = async () => {
    let { image } = platformProduct;
    if (image.includes('swagup-static.swagup.com')) {
      setisLoadingImage(true);
      image = image.replace('swagup.com', 's3.amazonaws.com');
      // Download the image from the URL
      const response = await fetch(image);
      const imageBuffer = await response.arrayBuffer();
      const imageAttributes = getImageAttributes(image);
      const artwork = { imageBuffer, ...imageAttributes };
      const objectWithS3Image = await prepareArtworksOnS3V2(artwork);
      image = objectWithS3Image.url;
      setisLoadingImage(false);
    }
    const newIntegrationProduct = {
      product_id: platformProduct.id,
      name: platformProduct.name,
      price: platformProduct.price,
      description: platformProduct.description,
      image
    };
    createNewIntegrationProduct.mutate(newIntegrationProduct);
  };

  const onSubmit = selectedAction === actions.connectExisting ? onConnect : onCreate;

  const onSelectAction = action => {
    setSelectedAction(action);
    setCurrentStep(3);
  };

  const onClose = () => setOpen(false);

  const handleToastClose = () => {
    setInfoMessage();
  };
  const canSubmit =
    platformProduct?.name &&
    platformProduct?.description &&
    platformProduct?.price &&
    platformProduct?.price >= 0 &&
    isNumberWithTwoDecimalPlaces(platformProduct?.price) &&
    platformProduct?.image &&
    (selectedAction === actions.newProduct || !isEmpty(swagUpProduct?.variants));

  const showSubmit = currentStep > 2 && (selectedAction === actions.newProduct || doShowSubmit);
  const selectedIntegration = availableIntegrations.find(ai => ai.rutter_id === selectedPlatform?.connectedPlatform);
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="order-details-title"
      aria-describedby="order-details-description"
      open={isOpen}
      onClose={onClose}
      className={classes.automationModal}
    >
      <Box className={classes.modalViewDetailsContainer}>
        {infoMessage && (
          <Grid container justifyContent="center" style={{ position: 'relative' }}>
            <Alert
              onClose={handleToastClose}
              delayTime={5000}
              className={classes.floatingInfoMessage}
              fontStyles={{ fontSize: 12, padding: 0 }}
              severity={infoMessage.includes('Check back soon.') ? 'warning' : 'error'}
            >
              {infoMessage}
            </Alert>
          </Grid>
        )}
        <Grid container className={classes.modalBody}>
          <Grid container justifyContent="flex-end" className={classes.modalHeaderBar}>
            <Grid item>
              <Box className={classes.close} />
            </Grid>
            <Grid item xs>
              {selectedIntegration && currentStep > 1 && (
                <Grid container justifyContent="center">
                  <Grid item>
                    <Box className={currentStep > 2 ? classes.modalIconWrapperSmall : classes.modalIconWrapper}>
                      <Img
                        srcSet={imageSrcSet(getItegrationLogoImageUrl(selectedIntegration?.rutter_id))}
                        alt="platform icon"
                        className={classes.modalIconImage}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <IconButton className={classes.close} onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <SwipeableViews
            axis="x"
            index={currentStep - 1}
            className={classes.swipeableViews}
            onChange={(event, newValue) => (newValue ? setCurrentStep(newValue) : false)}
            disabled
          >
            <Box>
              <PlatformConnectionOptions
                availableIntegrations={availableIntegrations}
                getConnectedPlatform={getConnectedPlatform}
                onSelectPlatform={onSelectPlatform}
                selectedIntegration={selectedIntegration}
              />
            </Box>
            <Box>
              <ConnectionOptions onSelectAction={onSelectAction} selectedAction={selectedAction} />
            </Box>
            <Box>
              {selectedAction === actions.connectExisting ? (
                <ConntecExistingForm
                  onUpdate={onUpdateNext}
                  selectedProduct={platformProduct}
                  integrationProducts={integrationProducts}
                  onBack={() => setCurrentStep(2)}
                  setSearch={setSearch}
                  paginatedFlieds={paginatedFlieds}
                  total={total}
                  isLoading={integrationProductsLoading}
                />
              ) : (
                <NewProductForm swagupProduct={swagUpProduct} onUpdate={onUpdate} />
              )}
            </Box>
            <Box>
              <ConntecExistingVariantForm
                onUpdate={onUpdateNext}
                platformProduct={platformProduct}
                onBack={() => setCurrentStep(3)}
              />
            </Box>
            <Box>
              <ConntecExistingVariantToSwagUPPorductForm
                onUpdate={onUpdateSwagUpProduct}
                platformProduct={platformProduct}
                swagupProduct={swagUpProduct}
              />
            </Box>
          </SwipeableViews>
          <Box className={classes.footer}>
            {currentStep > 1 && (
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <StylessButton onClick={() => setCurrentStep(prev => prev - 1)} style={{ marginTop: 4 }}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <ArrowBack />
                      </Grid>
                      <Grid item>
                        <Typography component="p" variant="body3RegularInter">
                          Go back
                        </Typography>
                      </Grid>
                    </Grid>
                  </StylessButton>
                </Grid>
                <Grid item xs>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Typography variant="body4RegularInter" className={classes.storeFooterText}>
                        {selectedPlatform?.connection?.store_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Box style={{ height: 48 }}>
                        {showSubmit && (
                          <Button
                            variant="primary"
                            size="small"
                            onClick={onSubmit}
                            disabled={!canSubmit}
                            loading={
                              createNewIntegrationProduct.isLoading ||
                              connectIntegrationProduct.isLoading ||
                              isLoadingImage
                            }
                            style={{ width: createNewIntegrationProduct.isLoading || isLoadingImage ? 176 : undefined }}
                          >
                            {actionButtonText}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default IntegrationModal;
