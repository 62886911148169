const styles = theme => ({
  stepBlog: {
    textAlign: 'center'
  },
  contact: {
    padding: '96px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px'
    }
  }
});

export default styles;
