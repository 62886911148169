import React from 'react';
import { withStyles, Grid, Select, MenuItem, InputBase, Button, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import styles from './styles/ColumnCard';

const commonMenuText = {
  fontFamily: 'Gilroy-SemiBold',
  fontSize: 14,
  color: '#0b1829'
};

const Input = withStyles({
  root: {
    width: '100%',
    border: 'none',
    padding: '0 14px',
    marginTop: 12,
    '& > .MuiSvgIcon-root': {
      right: 14
    }
  },
  input: commonMenuText
})(InputBase);

const Option = withStyles({
  root: commonMenuText
})(MenuItem);

const statusStyles = {
  Unmatched: { color: '#f44336 !important', backgroundColor: '#fef0ef' },
  Automatched: { backgroundColor: '#f2f6fd' },
  Matched: { backgroundColor: '#f2f6fd' },
  Ignored: { backgroundColor: '#f0f2f5' }
};

const ColumnStatus = withStyles({
  match: ({ status }) => ({
    backgroundColor: statusStyles[status]?.backgroundColor,
    textAlign: 'center',
    borderRadius: 10,
    height: 36
  }),
  label: ({ status }) => ({
    color: statusStyles[status]?.color || '#868a8f',
    fontSize: 14
  })
})(({ classes, status }) => (
  <Grid container justifyContent="center" alignItems="center" className={classes.match}>
    <Typography variant="body2" className={classes.label}>
      {status}
    </Typography>
  </Grid>
));

const ColumnCard = ({ classes, columnData, unusedHeaders, onChangeOption, onIgnore, containerRef }) => {
  const currentProps = React.useRef();
  const handleOnChange = e => {
    const { name, value } = e.target;
    currentProps.current = { column: +name, newMatch: value === 'none' ? '' : value };
  };

  if (unusedHeaders.length === 0 && columnData.status !== 'Ignored') {
    onIgnore();
  }

  return (
    <div className={classes.cardContainer}>
      <div className={clsx(classes.card, { unmatched: columnData.status === 'Unmatched' })}>
        <div className={classes.statusContainer}>
          <ColumnStatus key={columnData.status} status={columnData.status} />
          <Select
            value={columnData.userMatch || 'none'}
            input={<Input />}
            name={columnData.column.toString()}
            IconComponent={KeyboardArrowDownIcon}
            onChange={handleOnChange}
            disabled={unusedHeaders.length === 0}
            MenuProps={{
              onExited: () => {
                // react-horizontal-scrolling-menu will jump to the focused input causing an scrolling bug
                // thats why another element has to be focused after the menu is closed
                if (currentProps.current) {
                  containerRef.current.focus();
                  onChangeOption(currentProps.current);
                  currentProps.current = undefined;
                }
              }
            }}
          >
            {unusedHeaders.length === 0 ? (
              <Option value="none">No options left</Option>
            ) : (
              <Option value="none">
                <span style={{ color: '#3577d4' }}>Choose an option</span>
              </Option>
            )}

            {unusedHeaders.map(ch => (
              <Option key={ch.value} value={ch.value}>
                {ch.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className={classes.rowsContainer}>
          {columnData.rows.map(row => (
            // eslint-disable-next-line react/jsx-key
            <Typography key={row} variant="body2" className={classes.rowText}>
              {row || 'No data'}
            </Typography>
          ))}
          <Grid container justifyContent="center" style={{ padding: '16px 0' }}>
            <Button variant="text" disableRipple disableTouchRipple className={classes.ignoreBtn} onClick={onIgnore}>
              Ignore
              {columnData.status === 'Ignored' ? (
                <CheckCircleIcon style={{ color: '#3577d4' }} />
              ) : (
                <CheckCircleOutlineIcon />
              )}
            </Button>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ColumnCard);
