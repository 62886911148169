import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = () => ({
  productSelectionDrawer: {
    width: '80vw'
  },
  productsDrawerHeader: {
    paddingBottom: 8
  },
  productsDrawerContent: {
    padding: '0px 0px'
  },
  productTableHeader: {
    padding: '8px 0px',
    textAlign: 'center',
    borderBottom: '1px solid #D6D8DB',
    '& p': {
      color: '#4a4f54'
    }
  },
  closeDrawer: {
    height: 24,
    width: 24,
    position: 'absolute',
    top: 16,
    left: 16,
    color: '#989EA4',
    cursor: 'pointer',
    '&:hover': {
      color: '#787E84'
    }
  },
  productFilterSection: {
    padding: '4px 0px',
    textAlign: 'center',
    borderTop: '1px solid #D6D8DB',
    borderBottom: '1px solid #D6D8DB'
  },
  productImageContainer: {
    width: 60,
    height: 60,
    padding: 4,
    borderRadius: 4,
    border: '1px solid #D6D8DB',
    position: 'relative',
    '&:hover': {
      '& .zoom': {
        display: 'flex !important'
      }
    }
  },
  productRowContainer: {
    padding: 8,
    borderBottom: '1px solid #D6D8DB',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#989EA4'
    }
  },
  inventoryText: {
    color: '#989EA4',
    marginBottom: 4
  },
  combinedText: {
    color: '#4A4F54',
    '& span': {
      color: '#2E7D32',
      fontWeight: 500
    },
    ...ellipsisStyles
  },
  productSelectionDrawerCentered: {
    width: '100%',
    marginRight: '8%',
    marginLeft: '8%'
  },
  productImage: {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  },
  productZoomImage: {
    marginTop: 4,
    width: 24,
    height: 24
  },
  productInfoContainer: {
    paddingLeft: 24,
    textAlign: 'left',
    display: 'grid'
  },
  rowProductName: {
    ...ellipsisStyles
  },
  productRowsContainer: {
    paddingTop: 12
  },
  addButton: {
    height: 48,
    minWidth: 96,
    borderRadius: 32
  },
  headerTextLeft: {
    marginLeft: '-134px',
    textAlign: 'left',
    color: '#989EA4',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 500
  },
  headerText: {
    textAlign: 'center',
    color: '#989EA4',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 500
  },
  systemBar: {
    height: 42
  },
  productSelectionBar: {
    position: 'sticky',
    top: 0,
    background: '#ffffff',
    zIndex: 1
  },
  productCheckbox: {
    color: '#D6D8DB',
    '&:hover': {
      color: '#989EA4'
    },
    '&:checked': {
      color: '#3577D4 !important'
    }
  }
});

export default styles;
