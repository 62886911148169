import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import StandardKeyboardDatePicker from './StandardKeyboardDatePicker';
import log from '../../logger';

const ExpirationMonthPicker = ({ onChange, ...props }) => {
  const handleChange = date => {
    if (!date?.isValid()) {
      log.debug('ExpirationMonthPicker, onChange("None")');
      return onChange('None');
    }

    const d = date?.toDate();
    const m = d.getMonth() + 1;
    const y = d
      .getFullYear()
      .toString()
      .substring(2);

    const mmyy = `${m < 10 ? `0${m}` : m}/${y}`;
    log.debug(`ExpirationMonthPicker, onChange("${mmyy}")`);
    return onChange(mmyy);
  };

  const d = new Date();
  const minDate = new Date(d.getFullYear(), d.getMonth(), 1);

  return (
    <StandardKeyboardDatePicker
      variant="inline"
      format="MM/YY"
      margin="normal"
      emptyLabel="MM/YY"
      minDate={minDate}
      views={['month', 'year']}
      onChange={handleChange}
      keyboardIcon={null}
      {...props}
    />
  );
};

const styles = {
  root: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    '& button': {
      display: 'none'
    },
    '& div': {
      marginLeft: 0,
      paddingRight: 0
    }
  }
};

export default withStyles(styles)(ExpirationMonthPicker);
