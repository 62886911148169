export default {
  unmatchedCount: {
    fontFamily: 'Gilroy-Medium',
    letterSpacing: 'normal',
    color: '#f44336',
    fontSize: 16,
    position: 'absolute',
    top: 67,
    left: 0
  },
  horizontalScrollContainer: {
    maxWidth: 'calc(100% + 42px)',
    overflowX: 'hidden',
    marginTop: 87,
    position: 'relative',
    marginLeft: -42
  },
  horizontalScrollMenu: {
    alignItems: 'flex-start !important'
  },
  horizontalScrollInnerWrapper: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    minWidth: 36,
    height: 36,
    borderRadius: 25,
    boxShadow: '0 8px 24px 0 rgba(27, 28, 31, 0.05)',
    backgroundColor: '#ffffff',
    color: '#3577d4',
    marginTop: 100,
    cursor: 'pointer'
  },
  arrowDisabled: {
    color: '#868a8f',
    backgroundColor: '#f0f2f5',
    boxShadow: 'none',
    cursor: 'default'
  },
  reasonsList: {
    padding: '0 100px',
    '& > ul': {
      paddingLeft: 18,
      lineHeight: 1.4,
      textAlign: 'justify'
    }
  }
};
