import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/membership';
import { moneyStr } from '../../helpers/utils';
import { getCycle } from './MembershipCommon';

const useStyles = makeStyles(styles);

const MembershipManagement = ({ currentMembership }) => {
  const classes = useStyles();
  const history = useHistory();
  const { leftBarNavigation } = useFlags();
  return (
    <Grid container spacing={3} alignItems="stretch" className={classes.membershipManagementPanel}>
      <Box className={classes.membershipManagementPanelBox}>
        <Grid container direction="column" style={{ height: '100%' }}>
          <Grid item xs>
            <Box>
              <Link to={leftBarNavigation ? '/settings/your-membership' : '/membership/your-membership'}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box style={{ maxHeight: 24, width: 32 }}>
                      <ArrowBack className={classes.backArrow} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.backText}>Back</Typography>
                  </Grid>
                </Grid>
              </Link>
            </Box>
            <Box className={classes.membershipBoxHeader}>
              <Typography className={classes.membershipName}>Manage Membership</Typography>
              <Typography className={classes.membershipDescription}>You are currently subscribed to</Typography>
            </Box>
            <Box>
              <Grid container alignItems="center" spacing={4}>
                <Grid item>
                  <Box className={classes.membershipImageWrapper}>
                    <img
                      src={currentMembership.image}
                      alt={currentMembership.name}
                      className={classes.membershipItemImage}
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Typography className={classes.membershipName}>{currentMembership.name}</Typography>
                  <Typography className={classes.membershipDescription}>{currentMembership.description}</Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={3} alignItems="center" style={{ paddingRight: 24 }}>
                    <Grid item>
                      <Typography className={classes.membershipItemPrice}>
                        {moneyStr(currentMembership.default_price?.unit_amount / 100)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.membershipItemDescription}>{`per ${
                        currentMembership.default_price?.recurring.interval
                      } · billed ${getCycle(currentMembership)}`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box className={classes.membershipBoxActions}>
              <Box>
                <Link to="/membership/manage-membership-switch">
                  <Typography className={classes.membershipActions}>Switch plans</Typography>
                </Link>
              </Box>
              <Box>
                <Button
                  onClick={() => history.push('/membership/manage-membership-cancel')}
                  className={classes.membershipActionsButton}
                  disabled={currentMembership?.isFreeTier}
                >
                  Cancel subscription
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default MembershipManagement;
