import { useCallback, useState, useLayoutEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import round from 'lodash/round';

const useStorage = (key, storage) => {
  const [value, setValue] = useState(storage.getItem(key));

  return [
    value,
    v => {
      if (v) storage.setItem(key, v);
      else storage.removeItem(key);

      setValue(v);
    }
  ];
};

const useSessionStorage = key => useStorage(key, sessionStorage);

const getHeaderBottom = () => {
  const header = document.getElementById('header');

  return header ? header.offsetTop - round(window.scrollY) + header.offsetHeight : 0;
};

const useHeaderBottomTracker = () => {
  const [bottom, setBottom] = useState(getHeaderBottom);

  const updateBottom = useCallback(() => setBottom(getHeaderBottom), []);

  useLayoutEffect(() => {
    updateBottom();
  });

  useLayoutEffect(() => {
    window.addEventListener('resize', updateBottom);

    return () => {
      window.removeEventListener('resize', updateBottom);
    };
  }, [updateBottom]);

  return bottom;
};

const useBreakpointDown = breakpoint => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(breakpoint));
};

const pageOptionsForBigScreens = [15, 30, 45, 60];
const pageOptionsForSmallScreens = [12, 24, 36, 48];

const usePerPageOptions = () => {
  const isUpXL = useMediaQuery(theme => theme.breakpoints.up('xl'), { noSsr: true });

  return isUpXL ? pageOptionsForBigScreens : pageOptionsForSmallScreens;
};

export { useSessionStorage, useHeaderBottomTracker, useBreakpointDown, usePerPageOptions };
