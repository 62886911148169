import { scrollBar, ellipsisStyles } from '../../shared/styles/commonStyles';

export default theme => ({
  container: {
    width: '100%',
    height: '100%',
    paddingLeft: 30,
    paddingTop: 20,
    [theme.breakpoints.down(1440)]: { paddingTop: 20 },
    [theme.breakpoints.down(1281)]: { paddingLeft: 20 }
  },
  innerContainer: { height: 'calc(100% - 48px)' },
  contactsSection: {
    height: '65%',
    [theme.breakpoints.down('lg')]: { height: '45%' }
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  title: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#8d9299'
  },
  totalCountContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    backgroundColor: '#ebf1fb',
    borderRadius: '50%',
    marginLeft: 18,
    marginTop: 3
  },
  totalCountText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 12,
    color: '#3577d4'
  },
  tooltip: {
    padding: 10,
    lineHeight: 1.5,
    fontSize: 12,
    textAlign: 'center'
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
    marginTop: 20,
    height: 'calc(100% - 57px)',
    width: 260,
    '& > div': scrollBar
  },
  photoContainer: {
    width: 32,
    height: 32,
    '& img': { borderRadius: '50%' }
  },
  employeeName: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    color: '#0f2440'
  },
  placeholder: {
    width: 260,
    height: 10,
    backgroundColor: '#cce1ff',
    borderRadius: 6,
    marginTop: 20
  },
  deleteButtonContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 5
  },
  deleteButton: {
    width: 24,
    minWidth: 24,
    height: 24,
    backgroundColor: 'rgba(244, 67, 54, 0.08)',
    borderRadius: 5,
    padding: 0,
    '&:hover': { backgroundColor: 'rgba(244, 67, 54, 0.2)' }
  },
  deleteButtonIcon: {
    color: '#f44336',
    fontSize: 12
  },
  productSection: {
    width: 260,
    maxWidth: 260,
    height: '35%',
    paddingTop: 24,
    borderTop: 'solid 1px #ebeef2',
    [theme.breakpoints.down('lg')]: { height: '55%' }
  },
  productSectionTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#8d9299',
    marginBottom: 16
  },
  productImage: {
    borderRadius: 10,
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    marginBottom: 16
  },
  productName: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-Bold',
    fontFize: 16,
    color: '#0f2440',
    marginBottom: 12,
    width: '100%'
  },
  productColor: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#8d9299',
    '& .bold': { color: '#0f2440' }
  },
  productColorSample: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginLeft: 5
  },
  productSize: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    color: '#8d9299'
  },
  productNoInv: { color: '#d3dbe5' },
  productSizeValue: { color: '#0f2440' }
});
