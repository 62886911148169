import React from 'react';
import round from 'lodash/round';
import ShipmentCancelModal from './ShipmentCancelModal';
import RefundCancelModal from './RefundCancelModal';
import RefundWithPaymentCancelModal from './RefundWithPaymentCancelModal';
import { shipmentStatus } from '../../../../apis/constants';
import { useCreditActions } from '../../../../hooks';

const CancelModal = ({ shipmentToDelete, open, onCancel, onClose, actionType }) => {
  const refundedPackages = shipmentToDelete?.products.reduce((acc, curr) => curr.quantity + acc, 0);

  const { invalidateCache } = useCreditActions();
  const handleCancel = () => {
    onCancel(shipmentToDelete.id);
    invalidateCache();
    onClose();
  };

  if (
    [shipmentStatus.scheduled, shipmentStatus.pendingProduction, shipmentStatus.invalidAddress].includes(
      shipmentToDelete?.status
    ) &&
    shipmentToDelete.total_fulfillment > 0 &&
    shipmentToDelete.is_paid
  )
    return (
      open && (
        <RefundWithPaymentCancelModal
          open={open}
          warehouseFee={round(shipmentToDelete.total_fulfillment, 2)}
          refundedPackages={refundedPackages}
          refundedCredit={round(+shipmentToDelete?.total_shipping, 2)}
          onClose={onClose}
          onCancel={handleCancel}
          pendingProductionStatus={shipmentToDelete?.status}
        />
      )
    );
  if (shipmentToDelete?.status === shipmentStatus.scheduled && shipmentToDelete.is_paid)
    return (
      open && (
        <RefundCancelModal
          open={open}
          refundedPackages={refundedPackages}
          refundedCredit={round(+shipmentToDelete?.total_shipping, 2)}
          onClose={onClose}
          onCancel={handleCancel}
        />
      )
    );

  return open && <ShipmentCancelModal open={open} onCancel={handleCancel} onClose={onClose} actionType={actionType} />;
};

export default CancelModal;
