import React, { useLayoutEffect, useState } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@material-ui/icons';
import { CenteredGrid } from '../../../shared';
import styles from './Processes.styles';

const processes = [
  {
    imgPath: '/images/custom-pack-landing/processes/Icon_Custom_Pack_Process_1.svg',
    title: 'Pick your swag',
    text: 'Use the pack builder to pick from our curated selection of quality items',
    styles: {
      boxShadow: '0 40px 140px 0 rgba(26, 58, 98, 0.12)',
      backgroundColor: '#9846dd',
      color: '#ffffff'
    },
    subTextStyles: { color: '#ffffff' }
  },
  {
    imgPath: '/images/custom-pack-landing/processes/Icon_Custom_Pack_Process_2.svg',
    title: 'Upload your assets',
    text: 'Send over your logo files for our design team to work with',
    styles: {
      boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
      backgroundColor: '#ffffff',
      color: '#0f2440'
    },
    subTextStyles: { opacity: 0.54, color: '#0f2440' }
  },
  {
    imgPath: '/images/custom-pack-landing/processes/Icon_Custom_Pack_Process_3.svg',
    title: 'Mockups within hours',
    text: 'Our design team will create custom mockups to review within 24 hours',
    styles: {
      boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
      backgroundColor: '#ffffff',
      color: '#0f2440'
    },
    subTextStyles: { opacity: 0.54, color: '#0f2440' }
  },
  {
    imgPath: '/images/custom-pack-landing/processes/Icon_Custom_Pack_Process_4.svg',
    title: 'Place Order',
    text: 'Once you’re happy with how the pack looks, complete your order and we’ll get started',
    styles: {
      boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
      backgroundColor: '#ffffff',
      color: '#0f2440'
    },
    subTextStyles: { opacity: 0.54, color: '#0f2440' }
  }
];

const Process = ({ classes, process, withLine, number }) => (
  <div className={classes.processWrapper}>
    <Paper className={classes.paper} style={{ ...process.styles }}>
      <span className={classes.processNumber}>{number}</span>
      <Grid container className={classes.paperMedia}>
        <Grid item xs={12}>
          <img alt="delivery" src={process.imgPath} />
        </Grid>
      </Grid>
      <Grid container className={classes.paperContent}>
        <Grid item xs={12}>
          <h2>{process.title}</h2>
          <p style={{ ...process.subTextStyles }}>{process.text}</p>
        </Grid>
      </Grid>
    </Paper>
    {withLine && <div className={classes.line}>&nbsp;</div>}
  </div>
);

const useStyles = makeStyles(styles);

const Processes = () => {
  const classes = useStyles();
  const [translate, setTranslate] = useState(300);

  useLayoutEffect(() => {
    if (window.innerWidth >= 960 && window.innerWidth <= 500) setTranslate(300);
    else if (window.innerWidth < 960 && window.innerWidth >= 500) setTranslate(150);
    else if (window.innerWidth < 500) setTranslate(0);
  }, []);

  return (
    <Grid container justifyContent="center">
      <CenteredGrid container justifyContent="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <h2 className={classes.title}>The Swag Pack Process</h2>
          <p className={classes.subtitle}>Let us do the heavy lifting</p>
        </Grid>
      </CenteredGrid>
      <Grid container justifyContent="center" style={{ marginTop: 120, marginBottom: 50 }}>
        <Grid item xs={12} className={classes.scrollMenuContainer}>
          <ScrollMenu
            data={processes.map((process, i) => (
              <Process
                key={process.title}
                number={i + 1}
                classes={classes}
                process={process}
                withLine={i !== processes.length - 1}
              />
            ))}
            inertiaScrolling
            arrowLeft={<KeyboardArrowLeftRounded className={classes.scrollArrow} style={{ left: 5 }} />}
            arrowRight={<KeyboardArrowRightRounded className={classes.scrollArrow} style={{ right: 5 }} />}
            alignCenter
            wheel={false}
            itemsCount={processes.length}
            hideSingleArrow
            translate={translate}
            alignOnResize
            hideArrows
            scrollToSelected
            rtl={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Processes;
