import axios from 'axios';

const hostAndPath = process.env.REACT_APP_SMARTYSTREETS_INTERNATIONAL;
const authId = `?auth-id=${process.env.REACT_APP_SMARTYSTREETS_WEBSITE_KEY}`;

const instance = axios.create({
  baseURL: `${hostAndPath}${authId}`
});

export default instance;
// export const validateAddress = adress => {};
