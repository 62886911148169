import React from 'react';
import { withStyles } from '@material-ui/core';
import BulkFrontText from './FrontText';
import BulkFeatures from './Features';
import { Helmet } from '../../../shared';
import tags from '../../../../apis/seoTags';
import ContactForm from '../../../UI/Forms/ContactForm';
import styles from './BulkHome.styles';

// This page is no longer accessible by other links in the site, see https://swagup.atlassian.net/browse/UTT-768?focusedCommentId=22737
// TODO: This page should be removed sometime in the future

const BulkHome = ({ classes }) => (
  <main className={classes.root}>
    <Helmet tags={tags.bulk} />
    <BulkFrontText />
    <BulkFeatures />
    <section className={classes.contact} id="contact">
      <ContactForm redirectRoute="/bulk/thanks" formOrigin="BulkContactForm" />
    </section>
  </main>
);
export default withStyles(styles)(BulkHome);
