import React from 'react';
import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Link, useLocation } from 'react-router-dom';

import ScrollMenu from 'react-horizontal-scrolling-menu';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@material-ui/icons';

import CustomPackOption from './CustomPackOption';
import Mockup from './Mockup';
import RobotLoader from './RobotLoader';
import styles from './styles/mockupsGrid';

const useStyles = makeStyles(styles);

const buildMockupCards = collages => [
  ...collages.map(c => (
    <Box key={c.variant_no} m="0 10px">
      <Mockup collageId={c.id} image={c.collage_url} variant={c.variant_no} selectable />
    </Box>
  )),
  <Box key="custom" m="0 10px">
    <CustomPackOption />
  </Box>
];

const MockupsGrid = ({ collages, loadingMockups }) => {
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.top}>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>Get Started with Your Own Custom Packs</h1>
          <p className={classes.subtitle}>
            Check out these cool Swag Packs we&apos;ve created specifically for you! Like what you see? Select a pack
            and get started.
          </p>
        </div>
        {!loadingMockups && (
          <Hidden mdDown>
            <Button
              component={Link}
              to={{ pathname: '/onboarding/mockups/upload-logo', state: location.state }}
              size="small"
              className={classes.uploadButton}
            >
              Try a new logo
            </Button>
          </Hidden>
        )}
      </div>
      {loadingMockups ? (
        <RobotLoader />
      ) : (
        <>
          <div className={classes.mockups}>
            <Hidden smDown>
              {collages.map(c => (
                <Mockup key={c.variant_no} collageId={c.id} image={c.collage_url} variant={c.variant_no} selectable />
              ))}
              <CustomPackOption />
            </Hidden>
            <Hidden mdUp>
              <Box width="94vw" m="auto" overflow="hidden" position="relative">
                <ScrollMenu
                  inertiaScrolling
                  data={buildMockupCards(collages)}
                  arrowLeft={<KeyboardArrowLeftRounded fontSize="large" />}
                  arrowRight={<KeyboardArrowRightRounded fontSize="large" />}
                  alignCenter
                  alignOnResize
                  hideArrows
                  wheel={false}
                  itemsCount={4}
                  scrollToSelected
                />
              </Box>
            </Hidden>
          </div>
          <Hidden lgUp>
            <Box mt="20px" display="flex" justifyContent="flex-start">
              <Button
                component={Link}
                to={{ pathname: '/onboarding/mockups/upload-logo', state: location.state }}
                className={classes.uploadButton}
              >
                Try a new logo
              </Button>
            </Box>
          </Hidden>
        </>
      )}
    </>
  );
};

export default MockupsGrid;
