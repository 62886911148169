import React, { useState } from 'react';
import {
  useMediaQuery,
  makeStyles,
  Grid,
  Typography,
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { Button } from '@swagup-com/components';
import clsx from 'clsx';
import { Container, Footer } from '../layouts/FullscreenStepper';
import styles from './styles/DownloadTemplate';

const Header = ({ classes }) => (
  <TableHead>
    <TableRow>
      <TableCell className={clsx(classes.headerCell, classes.firstCell)}>First Name</TableCell>
      <TableCell className={classes.headerCell}>Last Name</TableCell>
      <TableCell className={clsx(classes.headerCell, classes.addressCell)}>Street Address</TableCell>
      <TableCell className={classes.headerCell}>State</TableCell>
      <TableCell className={classes.headerCell}>Zip Code</TableCell>
      <TableCell className={clsx(classes.headerCell, classes.sizeCell)}>Shirt Size</TableCell>
    </TableRow>
  </TableHead>
);

const sample = [
  { firstName: 'Jane', lastName: 'Cooper', address: '2972 Westheimer...', state: 'TX', zip: '85486 ', size: 'S' },
  { firstName: 'Wade', lastName: 'Warren', address: '1901 Thornridge...', state: 'NY', zip: '10135 ', size: 'L' },
  { firstName: 'Esther', lastName: 'Howard', address: '4140 Parker Rd...', state: 'MI', zip: '31134 ', size: 'M' },
  { firstName: 'Cameron', lastName: 'William', address: '2118 Thornridge...', state: 'CA', zip: '65624 ', size: '2XL' }
];

const Body = ({ classes }) => (
  <TableBody>
    {sample.map(row => (
      <TableRow key={row.firstName}>
        <TableCell className={clsx(classes.bodyCell, classes.firstCell)}>{row.firstName}</TableCell>
        <TableCell className={classes.bodyCell}>{row.lastName}</TableCell>
        <TableCell className={clsx(classes.bodyCell, classes.addressCell)}>{row.address}</TableCell>
        <TableCell className={classes.bodyCell}>{row.state}</TableCell>
        <TableCell className={classes.bodyCell}>{row.zip}</TableCell>
        <TableCell className={clsx(classes.bodyCell, classes.sizeCell)}>{row.size}</TableCell>
      </TableRow>
    ))}
  </TableBody>
);

const commonErrors = [
  {
    emoji: '☎️',
    ariaLabel: 'internatinal-phone-required',
    description: 'Phone Number is required for international shipments. Customs will call to confirm package shipments.'
  },
  {
    emoji: '🏗',
    ariaLabel: 'is-address-correct',
    description:
      'Make sure the correct information is in the right cell. ie Suite/Apt numbers should be in Address 2 column.'
  },
  {
    emoji: '🗣',
    ariaLabel: 'only-english-characters',
    description: 'Use only characters from the English alphabet.'
  },
  {
    emoji: '🏢',
    ariaLabel: 'company-name',
    description:
      'Company addresses must have the Company Name in the shipment details. Please do not add a Company Name in the shipment details when shipping to a non-company address'
  },
  {
    emoji: '👾',
    ariaLabel: 'cells-without-spaces-and-multiple-lines',
    description: 'Double-check for spaces and multiple lines in cells — these will cause an error!'
  },
  {
    emoji: '✔',
    ariaLabel: 'fill-required-fields',
    description: 'Confirm you have information for all required fields.'
  }
];

const useStyles = makeStyles(styles);

const DownloadTemplate = ({ onNextStep, onPreviousStep }) => {
  const matches = useMediaQuery('(max-height: 768px)');
  const classes = useStyles({ matches });
  const [downloaded, setDownloaded] = useState(false);

  return (
    <>
      <Container direction="column">
        <Typography variant="h4">Download the CSV template</Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Having the right data in the right format will make uploading and shipping a breeze. Download our CSV template
          for a simple upload.
        </Typography>
        <Grid container className={classes.cardsContainer}>
          <Card className={classes.cardTemplate}>
            <Table className={classes.table}>
              <Header classes={classes} />
              <Body classes={classes} />
            </Table>
            <Button
              variant="primary"
              className={classes.downloadBtn}
              href={`${process.env.REACT_APP_STATIC_RESOURCES_HOST}/contacts-import-template.csv`}
              onClick={() => setDownloaded(true)}
            >
              Download CSV template
            </Button>
            <div className={classes.gradient} />
            <div className={classes.justWhite} />
          </Card>
          <Card className={classes.cardErrors}>
            <p className={classes.errorTitle}>Some Do’s and Don’ts</p>
            {commonErrors.map((ce, i) => (
              <React.Fragment key={ce.ariaLabel}>
                <p className={classes.errorText}>
                  <span role="img" aria-label={ce.ariaLabel}>
                    {ce.emoji}
                  </span>{' '}
                  {ce.description}
                </p>
                {i === 2 && (
                  <>
                    <br />
                    <p className={classes.proTip}>
                      <span>Pro tip:</span> Use Google Translate to get an address in English.
                    </p>
                  </>
                )}
                {i < commonErrors.length - 1 && <br />}
              </React.Fragment>
            ))}
          </Card>
        </Grid>
      </Container>
      <Footer onContinue={downloaded && (() => onNextStep())} onPrevious={() => onPreviousStep()}>
        {!downloaded && (
          <Grid container justifyContent="flex-end">
            <Button variant="text" onClick={() => onNextStep()} className={classes.skipBtn}>
              Skip
            </Button>
          </Grid>
        )}
      </Footer>
    </>
  );
};

export default DownloadTemplate;
