import { SET_PRODUCT_COMMENTS, ADD_PRODUCT_COMMENTS, CLEAN_PRODUCT_COMMENTS } from '../actions/types';

const productCommentsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PRODUCT_COMMENTS:
      return action.payload;

    case ADD_PRODUCT_COMMENTS:
      return [...state, action.payload];

    case CLEAN_PRODUCT_COMMENTS:
      return [];

    default:
      return state;
  }
};

export default productCommentsReducer;
