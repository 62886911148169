import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Button, Tab, Tabs, Typography } from '@swagup-com/components';
import { isEmpty } from 'lodash';
import { Edit } from '@material-ui/icons';
import { TabContext, TabPanel } from '@material-ui/lab';
import styles from './styles/automationItem.styles';
import { imageResolver, reverseTimingCoverter } from './common';
import { SmallSwitch } from '../../pages/orders/requested/shipments/sendSwagFlow/common';

const useStyles = makeStyles(styles);

const SendSwagConfiguration = ({ action }) => {
  const classes = useStyles();

  const currentTimingRaw = action.settings?.timing;

  const timingData = reverseTimingCoverter(currentTimingRaw);

  return (
    <Grid container alignItems="stretch">
      <Grid item xs={6}>
        <Box className={classes.sectionTitleBoxWrapper}>
          <Box className={classes.sectionTitleBox}>
            <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
              Send Swag
            </Typography>
            <Grid container spacing={3}>
              <Grid item>
                <Box className={classes.itemImageContainerSettings}>
                  <img
                    src={action.settings?.product?.image}
                    alt={action.settings?.product?.name}
                    className={classes.itemImage}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box className={classes.settingsNameBoxRight}>
                  <Typography variant="body3MediumInter" className={classes.productNameSummary}>
                    {action.settings?.product?.name}
                  </Typography>
                  <Typography variant="body4RegularInter" className={classes.productTag}>
                    {action.settings?.product?.color} / {action.settings?.product?.sizeName}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.sectionTitleBoxWrapper}>
          <Box className={classes.sectionTitleBox}>
            <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
              Preferences
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              US Shipments: <span>{action.settings?.deliveryMethods?.domestic}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              International: <span>{action.settings?.deliveryMethods?.international}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Employee Types:{' '}
              <span>{isEmpty(action.settings?.employeeTypes) ? 'All' : action.settings?.employeeTypes?.join()}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Execution: <span>{action.settings?.automatic ? 'Automatic' : 'Manual'}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Timing:{' '}
              <span>
                {action.settings?.timing === undefined || action.settings?.timing === 0
                  ? 'Exact date'
                  : `${timingData.inputNumber} ${timingData.inputTimeMeasure} ${timingData.inputTiming}`}
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const RedeemLikConfiguration = ({ action }) => {
  const classes = useStyles();

  const currentTimingRaw = action.settings?.timing;

  const timingData = reverseTimingCoverter(currentTimingRaw);

  return (
    <Grid container alignItems="stretch">
      <Grid item xs={6}>
        <Box className={classes.sectionTitleBoxWrapper}>
          <Box className={classes.sectionTitleBox}>
            <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
              Send Redeem Link
            </Typography>
            <Grid container spacing={3}>
              <Grid item>
                <Box className={classes.itemImageContainerSettings}>
                  <img
                    src={action.settings?.redeem_page?.image}
                    alt={action.settings?.redeem_page?.name}
                    className={classes.itemImage}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="body3MediumInter" className={classes.productNameSummary}>
                  {action.settings?.redeem_page?.name}
                </Typography>
                <Typography variant="body4RegularInter" className={classes.productTag}>
                  {action.settings?.redeem_page?.type}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} className={classes.sectionTitleBoxRight}>
        <Box className={classes.sectionTitleBoxWrapper}>
          <Box className={classes.sectionTitleBox}>
            <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
              Preferences
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Custom Subject: <span>{action.settings?.redeem_page?.customSubject || 'None'}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Custom Message: <span>{action.settings?.redeem_page?.customMessage || 'None'}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Employee Types:{' '}
              <span>{isEmpty(action.settings?.employeeTypes) ? 'All' : action.settings?.employeeTypes?.join()}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Execution: <span>{action.settings?.automatic ? 'Automatic' : 'Manual'}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Timing:{' '}
              <span>
                {action.settings?.timing === undefined || action.settings?.timing === 0
                  ? 'Exact date'
                  : `${timingData.inputNumber} ${timingData.inputTimeMeasure} ${timingData.inputTiming}`}
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const ShopCreditsConfiguration = ({ action }) => {
  const classes = useStyles();

  const currentTimingRaw = action.settings?.timing;

  const timingData = reverseTimingCoverter(currentTimingRaw);

  return (
    <Grid container alignItems="stretch">
      <Grid item xs={6}>
        <Box className={classes.sectionTitleBoxWrapper}>
          <Box className={classes.sectionTitleBox}>
            <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
              Send Shop Credits
            </Typography>
            <Grid container spacing={3}>
              <Grid item>
                <Box className={classes.itemImageContainerSettings}>
                  <img
                    src={action.settings?.store?.image}
                    alt={action.settings?.store?.name}
                    className={classes.itemImage}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="body3MediumInter" className={classes.productNameSummary}>
                  {action.settings?.store?.name}
                </Typography>
                <Typography variant="body4RegularInter" className={classes.productTag}>
                  {action.settings?.store?.type}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.sectionTitleBoxWrapper}>
          <Box className={classes.sectionTitleBox}>
            <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
              Preferences
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Shop Gift: <span>{action.settings?.store?.giftAmount}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Custom Subject: <span>{action.settings?.store?.customSubject || 'None'}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Custom Message: <span>{action.settings?.store?.customMessage || 'None'}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Employee Types:{' '}
              <span>{isEmpty(action.settings?.employeeTypes) ? 'All' : action.settings?.employeeTypes?.join()}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Execution: <span>{action.settings?.automatic ? 'Automatic' : 'Manual'}</span>
            </Typography>
            <Typography variant="body3MediumInter" className={classes.productName}>
              Timing:{' '}
              <span>
                {action.settings?.timing === undefined || action.settings?.timing === 0
                  ? 'Exact date'
                  : `${timingData.inputNumber} ${timingData.inputTimeMeasure} ${timingData.inputTiming}`}
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const AutomationItem = ({ automation, onSetup, onDisconnect, onToggleActivate, disabled }) => {
  const [currentTab, setCurrentTab] = useState('0');

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const actionResolver = (actionType, currentAction) => {
    const { action } = automation;
    switch (true) {
      case actionType === 'send_swag':
        return <SendSwagConfiguration action={currentAction || action} automation={automation} />;
      case actionType === 'redeem_link':
        return <RedeemLikConfiguration action={currentAction || action} automation={automation} />;
      case actionType === 'shop_credits':
        return <ShopCreditsConfiguration action={currentAction || action} automation={automation} />;
      default:
        return <ShopCreditsConfiguration action={currentAction || action} automation={automation} />;
    }
  };

  const hasAction =
    (automation.type !== 'anniversaries' && isEmpty(automation.action)) ||
    (automation.type === 'anniversaries' && isEmpty(automation.years));

  // const canDisconect = (automation.type === 'anniversaries' && !isEmpty(automation.years)) || automation.type !== 'anniversaries';

  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item>
        <Box className={classes.itemImageContainer}>
          <img src={imageResolver(automation.type)} alt={automation?.name} className={classes.itemImage} />
        </Box>
      </Grid>
      <Grid item xs style={{ paddingLeft: 24 }}>
        <Typography variant="h5SemiBoldInter">{automation?.name}</Typography>
      </Grid>
      {/* <Grid item xs>
        <Typography variant="body3MediumInter" style={{ color: '#4A4F54' }}>
          {automation?.action?.name}
        </Typography> 
      </Grid> */}
      <Grid item>
        {!isEmpty(automation.action) && (
          <FormControlLabel
            labelPlacement="start"
            control={
              <SmallSwitch
                checked={automation.active}
                onChange={({ target: { checked } }) => onToggleActivate({ id: automation.id, active: checked })}
                color="primary"
                name="checkedBtn"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disabled={disabled}
              />
            }
            label={
              <Typography variant="body4RegularInter" style={{ color: '#4A4F54', marginRight: 8 }}>
                {automation.active ? 'Pause' : 'Resume'}
              </Typography>
            }
          />
        )}
      </Grid>
      <Grid item style={{ padding: '0px 24px' }}>
        {!hasAction && (
          <Button
            variant="text"
            size="small"
            onClick={() => onSetup(automation)}
            className={classes.automationItemEditButton}
            disabled={disabled}
          >
            <span>Edit</span> <Edit className={classes.editIcon} />
          </Button>
        )}
      </Grid>
      <Grid item>
        {hasAction ? (
          <Button
            size="small"
            variant="primary"
            onClick={() => onSetup(automation)}
            className={classes.automationItemButton}
            disabled={disabled}
          >
            Set Up
          </Button>
        ) : (
          <Button
            size="small"
            variant="secondary"
            onClick={() => onDisconnect(automation)}
            className={classes.disconnectButton}
            disabled={disabled}
          >
            Disconnect
          </Button>
        )}
      </Grid>
      {(!isEmpty(automation.action) || !isEmpty(automation.years)) && (
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <Box className={classes.finishDivider} />
              {!isEmpty(automation.years) ? (
                <TabContext value={currentTab} style={{ width: '100%', marginTop: 12 }}>
                  <Grid container>
                    <Grid item={12} style={{ marginBottom: 12 }}>
                      <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="#3577D4">
                        {automation.years.map((year, index) => (
                          <Tab label={year.name} value={`${index}`} />
                        ))}
                      </Tabs>
                    </Grid>
                  </Grid>
                  {automation.years.map((year, index) => (
                    <TabPanel id={year.value} value={`${index}`} style={{ width: '100%', paddingTop: 0 }}>
                      {actionResolver(year.action?.type, year.action)}
                    </TabPanel>
                  ))}
                </TabContext>
              ) : (
                actionResolver(automation.action.type)
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AutomationItem;
