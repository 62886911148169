import storefrontsServicesPaths from '../../helpers/storefrontsServicesPaths';
import { paginationResponseConverter } from '../../helpers/utils';
import axios from '../StorefrontsServiceAPI';
import { status200, status200or201 } from '../swagup/status.utils';

export default {
  list: params => axios.get(storefrontsServicesPaths.storefronts, { status200, params }).then(rslt => rslt.data),

  listByAccount: (account, params) =>
    axios
      .get(storefrontsServicesPaths.storefrontsByAccount(account), { status200, params })
      .then(rslt => paginationResponseConverter(rslt.data)),

  get: slug => axios.get(storefrontsServicesPaths.storefront(slug), { status200 }).then(rslt => rslt.data),

  create: params => axios.post(storefrontsServicesPaths.storefronts, params, status200or201),

  update: (id, params) => axios.patch(storefrontsServicesPaths.storefront(id), params),

  export: (id, params) => axios.post(storefrontsServicesPaths.export(id), params, status200or201),

  summary: id => axios.get(storefrontsServicesPaths.summary(id)),

  summaryAll: id => axios.get(storefrontsServicesPaths.summaryAll(id))
};
