import { Box, Divider, Grid, Slide, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { ChevronRight } from '@material-ui/icons';
import { Button, Typography } from '@swagup-com/components';
import { Skeleton } from '@material-ui/lab';
import dayjs from 'dayjs';
import SwipeableViews from 'react-swipeable-views';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { round } from 'lodash';
import { moneyStr } from '../../helpers/utils';
import styles from './styles/membership';
import { Header, MembershipItem, getCycle } from './MembershipCommon';
import { useMembership } from '../../contexts/membershipContext';

const useStyles = makeStyles(styles);

const useStyleOptions = () => {
  const options = {
    style: {
      base: {
        color: '#131415',
        fontFamily: 'Gilroy',
        border: '1px solid #EBEDF0',
        borderRadius: 10,
        padding: '4px 8px',
        '::placeholder': {
          color: '#EBEDF0'
        }
      },
      invalid: {
        color: '#f44336'
      }
    },
    showIcon: true
  };

  return options;
};

const MembershipChange = ({
  selectedMembership,
  proratedAmount,
  currentMembership,
  showWhatsNext,
  isProratedLoading,
  handleSubscribe,
  endOfBillingCycle,
  handleManage
}) => {
  const [currentStep, setCurrentStep] = useState(1);

  const stripe = useStripe();
  const elements = useElements();

  const { currentEmail, currentName } = useMembership();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: currentName,
        email: currentEmail
      }
    });

    const cardErrors = document.getElementById('card-errors');
    if (error) {
      // Handle card errors and display to the user
      cardErrors.textContent = error.message;
    } else {
      handleSubscribe(paymentMethod.id);
      // Process paymentMethod.id on the backend
      cardErrors.textContent = undefined;
    }
  };
  const isUpgrade = proratedAmount > 0; // && !currentMembership.isFreeTier;
  const isMonthly = getCycle(selectedMembership) === 'monthly';
  const classes = useStyles();
  const options = useStyleOptions();
  return (
    <Grid container>
      <Header title="Change Membership" />
      <Grid container spacing={4} justifyContent="space-evenly" alignItems="stretch">
        <Grid item>
          {currentMembership.id && (
            <MembershipItem key={currentMembership.id} item={currentMembership} isCurrent classes={classes} />
          )}
        </Grid>
        <Grid item>
          <Grid container alignItems="center" style={{ height: '100%' }}>
            <Grid item>
              <ChevronRight className={classes.chevronRight} />
            </Grid>
          </Grid>
        </Grid>
        {selectedMembership && (
          <>
            <Grid item>
              <MembershipItem item={selectedMembership} classes={classes} candidate />
            </Grid>
            <Grid item xs style={{ paddingLeft: 32 }}>
              <Slide direction="left" in={showWhatsNext} mountOnEnter unmountOnExit>
                <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
                  <Box>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="body3SemiBoldInter" className={classes.whatHappensNext}>
                          What happens next?
                        </Typography>
                        <Box>
                          <ul className={classes.whatHappensNextItem}>
                            {isUpgrade ? (
                              <li>
                                Your membership will be immediately upgraded to <span>{selectedMembership.name}</span>.
                              </li>
                            ) : (
                              <li>
                                You will maintain your current membership until your next subscription cycle:{' '}
                                {dayjs(endOfBillingCycle || undefined).format('MM/DD/YYYY')}
                              </li>
                            )}
                            {isUpgrade ? (
                              <li>
                                {`You will be charged `}
                                <span>
                                  {isProratedLoading ? <Skeleton style={{ width: 42 }} /> : moneyStr(proratedAmount)}
                                </span>
                                {` based on the new plan cost of `}
                                <span>
                                  {isProratedLoading ? (
                                    <Skeleton style={{ width: 42 }} />
                                  ) : (
                                    moneyStr(selectedMembership.price)
                                  )}
                                </span>
                                {round(proratedAmount, 2) !== round(selectedMembership.price, 2) && (
                                  <>
                                    {` minus a `}
                                    <span>
                                      {isProratedLoading ? (
                                        <Skeleton style={{ width: 42 }} />
                                      ) : (
                                        moneyStr(selectedMembership.price - proratedAmount)
                                      )}
                                    </span>
                                    {` credit for the unused amount of your current membership.`}
                                  </>
                                )}
                              </li>
                            ) : (
                              <li>
                                Your new membership will be automatically updated on your next subscription cycle, along
                                your new pricing.
                              </li>
                            )}
                            {isUpgrade && (
                              <li>
                                {`Your new membership will renew in${isMonthly ? '' : ' 12 months'} on ${dayjs(
                                  endOfBillingCycle || undefined
                                ).format('MM/DD/YYYY')}`}
                              </li>
                            )}
                          </ul>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Box className={classes.whatHappensNextDivider} />
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item>
                        <Button
                          variant="secondary"
                          size="small"
                          className={classes.cancelMembership}
                          onClick={() => handleManage()}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs />
                      <Grid item>
                        <Button
                          variant="primary"
                          size="small"
                          className={classes.manageMembership}
                          onClick={() =>
                            isUpgrade && !currentMembership.isFreeTier
                              ? setCurrentStep(2)
                              : handleSubscribe(currentMembership.isFreeTier ? selectedMembership.priceId : undefined)
                          }
                        >
                          Switch plan
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ padding: '0px 4px ' }}>
                    <Typography variant="body3SemiBoldInter" className={classes.whatHappensNext}>
                      Proceed with your payment
                    </Typography>
                    <Typography variant="body3RegularInter" className={classes.youllBeCharged}>
                      You’ll be charged{' '}
                      <span>{isProratedLoading ? <Skeleton style={{ width: 42 }} /> : moneyStr(proratedAmount)}</span>
                    </Typography>
                    <form id="payment-form" className={classes.paymentForm} onSubmit={handleSubmit}>
                      <Grid container className={classes.paymentFormContent}>
                        <Grid item xs={12} style={{ marginBottom: 20 }}>
                          <Typography variant="body4RegularInter" className={classes.paymentFormLabel}>
                            Card Number
                          </Typography>
                          <CardNumberElement options={options} className={classes.paymentFormInput} />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body4RegularInter" className={classes.paymentFormLabel}>
                            Expiration date
                          </Typography>
                          <CardExpiryElement options={options} className={classes.paymentFormInput} />
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                          <Typography variant="body4RegularInter" className={classes.paymentFormLabel}>
                            CVC
                          </Typography>
                          <CardCvcElement options={options} className={classes.paymentFormInput} />
                        </Grid>
                      </Grid>

                      <Divider className={classes.whatHappensNextDivider} style={{ marginTop: 24, marginBottom: 16 }} />
                      {/* <div id="card-element" />  */}
                      <div id="card-errors" role="alert" className={classes.formErrors} />

                      <Grid container style={{ marginTop: 12 }}>
                        <Grid item>
                          <Button
                            variant="secondary"
                            size="small"
                            onClick={() => setCurrentStep(1)}
                            className={classes.cancelMembership}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                          <Button
                            id="submit-button"
                            variant="primary"
                            type="submit"
                            size="small"
                            className={classes.manageMembership}
                          >
                            Pay Now
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </SwipeableViews>
              </Slide>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default MembershipChange;
