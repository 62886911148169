import { ButtonBase, Grid, IconButton, makeStyles, Slide, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Typography } from '@swagup-com/components';
import React from 'react';
import { CustomTooltip } from '../../../products/commonProductsElements';

const useActionButtonStyles = makeStyles(theme => ({
  buttonRoot: {
    display: 'inline-block',
    margin: '0 20px',
    color: theme.palette.grey['900'],
    '&:hover, &:focus-visible': { color: theme.palette.primary.main },
    '&:active': { color: theme.palette.primary['800'] },
    '&.Mui-disabled, &.Mui-disabled > p': { color: theme.palette.grey['400'] }
  },
  iconsContainer: {
    position: 'relative',
    marginBottom: 4
  },
  baseIcon: {
    fontSize: 20,
    opacity: 1,
    'button:hover &, button:focus-visible &': { opacity: 0 }
  },
  hoverIcon: {
    fontSize: 20,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    opacity: 0,
    'button:hover &, button:focus-visible &': { opacity: 1 }
  }
}));

const ActionButton = ({ text, disabled, onClick, BaseIcon, HoverIcon, hide }) => {
  const classes = useActionButtonStyles();
  const DerivedHoverIcon = HoverIcon ?? BaseIcon;
  if (hide) return null;
  return (
    <ButtonBase onClick={onClick} disabled={disabled} disableRipple classes={{ root: classes.buttonRoot }}>
      <Grid container justifyContent="center" className={classes.iconsContainer}>
        <BaseIcon className={classes.baseIcon} />
        <DerivedHoverIcon className={classes.hoverIcon} />
      </Grid>
      <Typography variant="body3MediumInter">{text}</Typography>
    </ButtonBase>
  );
};

const useActionBarStyles = makeStyles(theme => ({
  snackbarContent: {
    minWidth: 'max-content',
    borderRadius: 8,
    padding: '14px 32px',
    background: '#FFFFFF',
    boxShadow: '0px 20px 40px 2px rgba(0, 0, 0, 0.12)'
  },
  itemsSelectedText: {
    margin: '0 10px',
    color: '#131415',
    minWidth: 116,
    fontVariantNumeric: 'tabular-nums'
  },
  actionsContainer: {
    display: 'flex',
    margin: '0 20px'
  },
  clearIcon: {
    fontSize: 24,
    color: '#131415',
    background: 'transparent !important',
    padding: 0,
    transition: 'opacity 150ms ease',
    '&:hover, &:focus-visible': { color: theme.palette.primary.main },
    '&:active': { color: theme.palette.primary['800'] }
  }
}));

const ActionBar = ({ open, totalItemSelected, handleClose, actions = [], type = 'item', typesPrural }) => {
  const classes = useActionBarStyles();
  return (
    <Snackbar open={open} TransitionComponent={Slide} transitionDuration={{ enter: 200, exit: 150 }}>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.snackbarContent}>
        <Typography variant="body3SemiBoldInter" className={classes.itemsSelectedText}>
          {totalItemSelected} {totalItemSelected === 1 ? type : typesPrural || `${type}s`} selected
        </Typography>
        <div className={classes.actionsContainer}>
          {actions.map(action => (
            <CustomTooltip key={action.text} title={action.tooltip} disableHoverListener={!action.disabled}>
              <ActionButton
                text={action.text}
                onClick={action.action}
                disabled={totalItemSelected === 0 || action.disabled}
                BaseIcon={action.baseIcon}
                HoverIcon={action.hoverIcon}
                hide={action.hide}
              />
            </CustomTooltip>
          ))}
        </div>
        <IconButton
          aria-label="cancel"
          disableRipple
          disabled={totalItemSelected === 0}
          onClick={handleClose}
          className={classes.clearIcon}
        >
          <Close />
        </IconButton>
      </Grid>
    </Snackbar>
  );
};

export default ActionBar;
