const styles = theme => ({
  bottomItems: {
    padding: 15
  },
  fullWidth: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      objectFit: 'contain'
    }
  },
  mainImgContainer: {
    height: '472px !important',
    width: '472px !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 'auto !important'
    }
  },
  containerImage: {
    height: 72,
    width: 72,
    borderRadius: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    marginRight: 12,
    marginLeft: 12,
    border: 'solid 1px #ebeef2'
  },
  selectedImage: {
    '& > button > div > div': {
      border: 'solid 1px #3577d4',
      opacity: 0.5
    }
  },
  thumbnailImage: {
    height: '100%',
    contain: 'content'
  },
  thumbnailNopic: {
    width: '100%'
  },
  leftArrow: {
    left: 0,
    zIndex: 999,
    cursor: 'pointer',
    width: 32,
    height: 32,
    borderRadius: 10,
    backgroundColor: '#ebf1fb',
    marginRight: 22
  },
  rightArrow: {
    right: 0,
    cursor: 'pointer',
    width: 32,
    height: 32,
    borderRadius: 10,
    backgroundColor: '#ebf1fb',
    marginLeft: 22
  }
});
export default styles;
