import React, { useState } from 'react';
import { Grid, Table, TableBody, TableContainer, makeStyles } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { Typography } from '@swagup-com/components';
import { isEmpty, min } from 'lodash';
import { PlayArrow, PlayArrowRounded, Refresh, RefreshRounded, SaveAlt } from '@material-ui/icons';
import apiPaths from '../../../helpers/apiPaths';
import { integrationsApi } from '../../../apis/swagup';
import { StylessButton } from '../../buttons';
import { TableEmptyState } from '../../storefronts/storeCommon';
import { CenteredGrid, Pagination } from '../../shared';
import { useQueryFilterValidated } from '../../../hooks/useFilters';
import { usePaginatedQuery, useQueryParams } from '../../../hooks';
import { moneyStr } from '../../../helpers/utils';
import Loader from '../../global/Loader';
import { orderItem } from './common';
import { ActionOrderRow, TableHeaderAutoShip } from './integrationOrdersExtended';
import styles from './styles/integrationOrders';
import ActionBar from '../../storefronts/components/ActionBar/ActionBar';
import StoreFilterSection from '../../storefronts/StoreFilterSection';
import RedeemFilterSection from '../../redeemPages/RedeemFilterSection';
import EditOrderRecipient from '../EditOrderRecipient';

const useStyles = makeStyles(styles);

const perPageOptions = [10, 20, 30, 40];

const IntegrationOrders = ({ selectedPlatform, disabled }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isError, setIsError] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState();

  const query = useQueryParams();

  const search = query.get('search') || '';
  const automation = query.get('type') || '';
  const approved = query.get('approved') || '';
  const isSearching = !!search;

  // const shipmentStatus = useQueryFilterValidated('shipment_status', val => shipmentAPIStatuses.includes(val));

  const ordering = useQueryFilterValidated(
    'ordering',
    (xid, value) => ['-created_at', 'created_at'].includes(xid) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const {
    query: { data: actionOrderItemResults, isFetching: loadingOrders },
    pagination
  } = usePaginatedQuery({
    queryKey: [
      apiPaths.integrationActionOrders(selectedPlatform?.connectionAccess?.access_token),
      selectedPlatform?.connectedPlatform,
      search,
      automation,
      approved,
      ordering
    ],
    queryFn: (limit, offset) => {
      return integrationsApi.fetchIntegrationActionOrders(
        selectedPlatform?.connectionAccess?.access_token,
        search,
        automation,
        approved,
        limit,
        offset,
        ordering,
        () => setIsError(true)
      );
    },
    perPageOptions
  });

  const queryClient = useQueryClient();

  const onAPICallSucess = () => {
    queryClient.invalidateQueries([
      apiPaths.integrationActionOrders(selectedPlatform?.connectionAccess?.access_token),
      selectedPlatform?.connectedPlatform
    ]);
  };

  const actionOrderApprove = useMutation(
    data => integrationsApi.updateActionOrder(selectedPlatform?.connectionAccess?.access_token, data.params, data.id),
    {
      onSuccess: onAPICallSucess
    }
  );

  // const actionOrderSimulate = useMutation(
  //   data => integrationsApi.updateActionOrder(selectedPlatform?.connectionAccess?.access_token, data.params, data.id),
  //   {
  //     onSuccess: onAPICallSucess
  //   }
  // );

  const actionOrderItems = actionOrderItemResults?.results || [];

  const showPagination = pagination?.count > min(pagination?.sizeOptions);

  // const { data: actionOrdersData, isLoading: isLoadingReact } = useQuery(
  //     [
  //       apiPaths.integrationActionOrders(selectedPlatform?.connectionAccess?.access_token),
  //       selectedPlatform?.connectedPlatform
  //     ],
  //     () =>
  //       integrationsApi.fetchIntegrationActionOrders(selectedPlatform?.connectionAccess?.access_token, () =>
  //         setIsError(true)
  //       ),
  //     {
  //       enabled: platform && !!selectedPlatform?.connectionAccess?.access_token,
  //       onerror: () => {
  //         setIsError(true);
  //       }
  //     }
  //   );

  const toogleSelectAll = () =>
    setSelectedItems(prev => (prev.length !== actionOrderItems?.length ? actionOrderItems.map(r => r) : []));

  const toogleSelectedItems = sitem =>
    setSelectedItems(prev =>
      prev.find(it => it.id === sitem.id) ? prev.filter(it => it.id !== sitem.id) : [...prev, sitem]
    );

  const onApprove = orderItem => {
    actionOrderApprove.mutate({ id: orderItem.id, params: { approved: true } });
  };

  const updateActionOrder = useMutation(
    data =>
      integrationsApi.updateOrderRecipient(
        selectedRecipient?.order_id,
        data,
        selectedPlatform?.connectionAccess?.access_token
      ),
    {
      onSuccess: () => {
        onAPICallSucess();
        setOpenRecipient(false);
      }
    }
  );

  const handleSubmit = data => {
    const recipient = {
      first_name: data.first_name,
      last_name: data.last_name,
      customer_email: data.email,
      phone: data.phone_number,
      address1: data.shipping_address1,
      address2: data.shipping_address2,
      region: data.shipping_state,
      city: data.shipping_city,
      postal_code: data.shipping_zip,
      country_code: data.shipping_country
    };
    updateActionOrder.mutate(recipient);
  };

  const onApproveBulk = actionList => {
    const list = actionList || selectedItems;
    const payload = {
      params: list.map(ar => ({ id: ar.id, approved: true }))
    };
    actionOrderApprove.mutate(payload);
  };

  const { mutate: exportCSVData, isLoading: isCSVPending } = useMutation(
    () =>
      integrationsApi.fetchIntegrationActionOrdersExport(
        selectedPlatform?.connectionAccess?.access_token,
        search,
        automation,
        approved,
        () => setIsError(true)
      ),
    {
      useErrorBoundary: true,
      onSuccess: csvData => {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `action-requests-search-${search}-all.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
      }
    }
  );

  const parseOrder = order => ({
    order_id: order.id,
    first_name: order.first_name,
    last_name: order.last_name,
    email: order.customer_email,
    phone_number: order.phone,
    shipping_address1: order.address1,
    shipping_address2: order.address2,
    shipping_state: order.region,
    shipping_city: order.city,
    shipping_zip: order.postal_code,
    shipping_country: order.country_code
  });

  const cleanOrder = order => ({ rutter_id: order.rutter_id });

  const retryOrder = useMutation(
    data => integrationsApi.retryOrder(cleanOrder(data), selectedPlatform?.connectionAccess?.access_token),
    {
      onSuccess: (data, param) => {
        onAPICallSucess();
        const [nextOrder, ...rest] = param?.orders || [];
        if (nextOrder) {
          retryOrder.mutate({ ...nextOrder, orders: rest });
        }
      }
    }
  );

  const retryOrders = () => {
    const [nextOrder, ...rest] = selectedItems.map(si => ({ ...si.int_order }));
    retryOrder.mutate({ ...nextOrder, orders: rest });
  };

  const onRetry = order => {
    const iOrder = cleanOrder(order);
    retryOrder.mutate(iOrder);
  };

  //   const queryClient = useQueryClient();
  const classes = useStyles();

  return (
    <CenteredGrid>
      <div style={{ borderTop: '1px solid #E5E7E8', borderBottom: '1px solid #E5E7E8', padding: '4px 0px' }}>
        <Grid container item xs alignItems="center">
          <Grid item xs>
            <RedeemFilterSection
              searchPlaceholder="Search by name"
              searchParam="search"
              filters={[
                {
                  name: 'approved',
                  label: 'Approval Status',
                  behavior: 'select',
                  options: {
                    true: 'Approved',
                    false: 'Pending Approval'
                  }
                },
                {
                  name: 'type',
                  label: 'Automation',
                  behavior: 'multiselect',
                  options: {
                    'New Hire': 'New Hire',
                    Birthday: 'Birthday',
                    'Employee Anniversary': 'Employee Anniversary'
                  }
                }
              ]}
            />
          </Grid>
        </Grid>
      </div>
      {actionOrderItems?.length > 0 && (
        <Grid container className={classes.historyStatusContainer}>
          <Grid item>
            <Typography variant="body3RegularInter" className={classes.historyStatus}>
              Total Count: {actionOrderItemResults?.count}
            </Typography>
          </Grid>
          <Grid xs item />
          <Grid item>
            <StylessButton
              className={classes.exportButtom}
              disabled={actionOrderItems?.length === 0}
              onClick={exportCSVData}
            >
              <Grid container alignItems="center" style={{ minWidth: 90, paddingRight: 12 }}>
                <Grid item style={{ paddingRight: 4, paddingTop: 0 }}>
                  <SaveAlt style={{ height: 18, width: 18 }} />
                </Grid>
                <Grid item xs>
                  Export CSV
                </Grid>
              </Grid>
            </StylessButton>
          </Grid>
        </Grid>
      )}
      <div className={classes.tableWrapper}>
        <div className={classes.tableContainer} style={{ height: `calc(100% - ${showPagination ? 42 : 0}px)` }}>
          {actionOrderItems?.length === 0 && !loadingOrders ? (
            <TableEmptyState
              isSearching={isSearching}
              customSubtext="As actions are being triggred by the system the list will be populated."
            />
          ) : (
            <TableContainer>
              <Table stickyHeader className={classes.redemptionTable}>
                <TableHeaderAutoShip
                  classes={classes}
                  toogleSelectAll={toogleSelectAll}
                  allSelected={actionOrderItems?.length === selectedItems.length}
                />
                <TableBody>
                  {actionOrderItems?.map(actionOrder => (
                    <ActionOrderRow
                      key={actionOrder.id}
                      actionOrder={actionOrder}
                      customAction={() => setOpenProductDrawer(true)}
                      toogleSelectedItems={toogleSelectedItems}
                      isSelected={!!selectedItems.find(i => i.id === actionOrder.id)}
                      onUpdateActionOrder={order => setSelectedRecipient(parseOrder(order))}
                      onApprove={onApprove}
                      onRetry={onRetry}
                      classes={classes}
                      disabled={disabled || actionOrderApprove.isLoading || retryOrder.isLoading}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        {showPagination && (
          <Grid container alignItems="center" className={classes.paginationContainer}>
            <Pagination {...pagination} startText="Show" endText="actions" buttonClass={classes.paginationBtn} />
          </Grid>
        )}
      </div>

      <EditOrderRecipient
        open={!isEmpty(selectedRecipient)}
        handleSubmit={handleSubmit}
        onClose={() => setSelectedRecipient({})}
        defaultValues={selectedRecipient}
      />

      <ActionBar
        open={selectedItems.length > 0}
        totalItemSelected={selectedItems.length}
        handleClose={() => setSelectedItems([])}
        type="action"
        actions={[
          {
            text: 'Approve',
            tooltip: 'Only Unapproved orders can be selected',
            action: () => onApproveBulk(),
            disabled: selectedItems.some(r => r?.approved),
            baseIcon: PlayArrow,
            hoverIcon: PlayArrowRounded
          },
          {
            text: 'Retry',
            tooltip: 'Only Failed orders can be selected',
            action: () => retryOrders(),
            disabled: selectedItems.some(r => !r?.int_order?.error_message),
            baseIcon: Refresh,
            hoverIcon: RefreshRounded
          }
        ]}
      />

      {(loadingOrders || isCSVPending) && <Loader absolute />}
    </CenteredGrid>
  );
};

export default IntegrationOrders;
