const styles = () => ({
  title: {
    fontFamily: 'Gilroy',
    color: '#434c5f',
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 15
  },
  deliveryText: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    color: '#434c5f',
    lineHeight: 1.7,
    overflowWrap: 'break-word',
    '& span': {
      color: '#45af5f'
    }
  },
  deliveryInfoMessage: {
    fontFamily: 'Gilroy',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontSize: 14,
    color: '#1db83e'
  }
});
export default styles;
