import axios from '../DashBoardForm';
import apiPaths from '../../helpers/apiPaths';

export default {
  fetch: () =>
    axios
      .get(apiPaths.packTemplates, {
        params: { limit: 48, ordering: 'sort_order' }
      })
      .then(response => response.data.results),
  getMinimum: templateId =>
    axios.get(`${apiPaths.packTemplates}${templateId}/?fields=minimum`).then(response => response.data.minimum),
  fetchBySlug: (slug, onError = err => err) =>
    axios
      .get(`${apiPaths.packTemplates}${slug}/`)
      .then(response => response.data)
      .catch(err => onError(err))
};
