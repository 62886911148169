import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Tooltip } from '@swagup-com/components';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Product from './Product';
import { useBreakpointDown } from '../../../hooks';
import styles from './Category.styles';

const tooltipMsg =
  'Keep in mind package sizing/components is based on item selection. SwagUp reserves the right where necessary to update package sizing and configurations to ensure safe transportation of items.';

const useStyles = makeStyles(styles);

const Category = ({ category }) => {
  const showPackagingMessage = category.CatName === 'Packaging';
  const showItemsMessage = category.CatName === 'Water bottles';

  const classes = useStyles();
  const isXs = useBreakpointDown('xs');

  const categories = category.items.map(product => (
    <Grid item key={product.id}>
      <Product product={product} />
    </Grid>
  ));

  return (
    <div>
      {showItemsMessage && <Typography className={classes.topCatalogHeader}>Next, choose your items</Typography>}
      {categories.length > 0 ? (
        <Typography variant="h2" className={classes.header} dangerouslySetInnerHTML={{ __html: category.label }} />
      ) : (
        <></>
      )}
      {showPackagingMessage && (
        <Tooltip placement="top-center" arrow title={tooltipMsg} classes={classes}>
          <HelpOutlineIcon className={classes.helpIcon} />
        </Tooltip>
      )}
      {isXs ? (
        <Grid container direction="column" justifyContent="center" spacing={3}>
          {categories}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          {categories}
        </Grid>
      )}
    </div>
  );
};

export default Category;
