import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import styles from './styles/earlyBirdTimer';

const useStyles = makeStyles(styles);

const TimeSection = ({ value, label }) => {
  const classes = useStyles();
  return (
    <Grid align="center">
      <Typography variant="body4SemiBoldInter" className={classes.timeText}>
        {value}
      </Typography>
      <Typography variant="body4SemiBoldInter" className={classes.timeTextLoose}>
        {label}
      </Typography>
    </Grid>
  );
};

const EarlyBirdTimer = () => {
  const classes = useStyles();
  const [countdown, setCountdown] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const targetDate = new Date('2023-09-15T23:59:59');
    const interval = setInterval(() => {
      const currentDate = new Date();
      const timeDifference = targetDate - currentDate;
      if (timeDifference <= 0) {
        clearInterval(interval);
        setExpired(true);
      } else {
        const days = String(Math.floor(timeDifference / (1000 * 60 * 60 * 24))).padStart(2, 0);
        const hours = String(Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, 0);
        const minutes = String(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, 0);
        const seconds = String(Math.floor((timeDifference % (1000 * 60)) / 1000)).padStart(2, 0);

        setCountdown({
          days,
          hours,
          minutes,
          seconds
        });
        setExpired(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Grid>
      {expired ? (
        <Typography variant="body4SemiBoldInter" className={classes.timerExpired}>
          Early Bird Offer Expired
        </Typography>
      ) : (
        <Grid>
          <Grid container className={classes.timer}>
            <TimeSection value={countdown.days} label="DAYS" />
            <TimeSection value={countdown.hours} label="HRS" />
            <TimeSection value={countdown.minutes} label="MIN" />
            <TimeSection value={countdown.seconds} label="SEC" />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default EarlyBirdTimer;
