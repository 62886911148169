import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import styles from './Features.styles';

const Features = ({ classes }) => (
  <section id="features" className={classes.brands}>
    <Grid container alignItems="center" spacing={3} justifyContent="center">
      <Grid item xs={12} sm={4}>
        <div className={classes.stepBlog}>
          <img src="/images/custom/assets/pointer.svg" alt="Pointer" />
          <Typography className={classes.stepHeader} variant="h6">
            MAKE PICKS
          </Typography>
          <Typography className={classes.stepDescription} variant="subtitle2">
            Use the form above to
            <br /> pick from our curated
            <br /> selection of items
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className={classes.stepBlog}>
          <img src="/images/custom/assets/computer.svg" alt="Computer" />
          <Typography className={classes.stepHeader} variant="h6">
            REVIEW MOCKUPS
          </Typography>
          <Typography className={classes.stepDescription} variant="subtitle2">
            We&apos;ll send you mockups
            <br /> based on your selections
            <br /> and logo to review
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className={classes.stepBlog}>
          <img src="/images/custom/assets/magnifying-g-lass.svg" alt="Magnifying glass" />
          <Typography className={classes.stepHeader} variant="h6">
            PLACE ORDER
          </Typography>
          <Typography className={classes.stepDescription} variant="subtitle2">
            Once you are happy with
            <br />
            the pack mockups, we&apos;ll
            <br />
            get started on your order
          </Typography>
        </div>
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Typography className={classes.brandsHeader} variant="subtitle2">
          Trusted by 100’s of the best brands
        </Typography>
        <Grid className={classes.logosContainer}>
          <img className={classes.logos} src="/images/custom/assets/logos-1.png" alt="Brands" />
          <img className={clsx(classes.logos, classes.mt18)} src="/images/custom/assets/logos-2.png" alt="Brands" />
        </Grid>
      </Grid>
    </Grid>
  </section>
);

export default withStyles(styles)(Features);
