import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';

const useStyles = makeStyles({
  container: { display: 'flex', justifyContent: 'center', marginTop: '40px' }
});

const UserContainer = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item>
        <img src="/images/public/nothing-found.svg" alt="nothing-found" />
        <Typography variant="h5SemiBoldInter">
          <span style={{ color: '#125CFF' }}>Users </span>page will be coming soon!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserContainer;
