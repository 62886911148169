export default {
  drawerPaper: {
    maxWidth: 792,
    padding: '30px 50px'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 24,
    color: '#0b1829',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    fontSize: 14,
    color: '#787b80',
    lineHeight: 1.57,
    letterSpacing: 'normal'
  },
  infoAnchor: {
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 600,
    fontSize: 12,
    color: '#787b80',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    display: 'flex',
    alignItems: 'center'
  },
  infoAnchorIcon: {
    fontSize: 14,
    color: '#787b80',
    marginRight: 6
  },
  number: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '32px',
    color: '#0b1829'
  }
};
