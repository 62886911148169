import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import { Button } from '@swagup-com/components';
import { TextField, PhoneField } from '../global/reactHookFormFields';
import { patchProfile } from '../../actions';
import toErrorPage from '../../helpers/toErrorPage';
import { getSchema } from '../global/Forms/commonValidations';
import { useProfile } from '../../hooks';
import apiPaths from '../../helpers/apiPaths';

const useStyles = makeStyles({
  button: {
    '&:focus': {
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)'
    }
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1
  }
});

const fieldsToValidate = ['first_name', 'last_name', 'email'];
const resolver = yupResolver(getSchema(fieldsToValidate));

const Profile = () => {
  const classes = useStyles();

  const [loading, toggleLoading] = useReducer(prevLoading => !prevLoading, false);

  const { data: profile } = useProfile();

  const { control, register, handleSubmit, reset, formState, setFocus } = useForm({
    mode: 'all',
    resolver,
    defaultValues: {
      email: profile.email,
      first_name: profile.first_name || '',
      last_name: profile.last_name || '',
      primary_phone: profile.primary_phone || ''
    }
  });

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const submitProfile = async data => {
    toggleLoading();
    const response = await dispatch(
      patchProfile({
        ...data,
        id: profile.id,
        username: profile.username,
        primary_phone: data.primary_phone || null,
        mobile_phone: undefined
      })
    );
    setFocus('first_name');
    reset(data, { keepDirty: false });
    toggleLoading();
    if (response.result === 'error') toErrorPage(response, history);
    else queryClient.invalidateQueries(apiPaths.profiles);
  };

  const { isValid, isDirty, errors } = formState;

  // This is due to React's SyntheticEvent
  // More info here: https://duncanleung.com/fixing-react-warning-synthetic-events-in-setstate/
  const handleReset = e => {
    e.persist();
    setFocus('first_name');
    reset();
  };

  return (
    <Grid component="form" onSubmit={handleSubmit(submitProfile)} container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.subTitle}>
          Basic information
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          autoFocus
          autoComplete="first_name"
          error={errors.first_name?.message}
          placeholder="First name"
          {...register('first_name', { deps: ['last_name'] })}
          defaultValue={profile.first_name}
          fullWidth
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          autoComplete="last_name"
          error={errors.last_name?.message}
          placeholder="Last name"
          {...register('last_name', { deps: ['first_name'] })}
          defaultValue={profile.last_name}
          fullWidth
        />
      </Grid>
      <Grid item sm={6} xs={12} style={{ paddingTop: 12 }}>
        <PhoneField
          name="primary_phone"
          error={errors.primary_phone?.message}
          control={control}
          placeholder="Phone number"
          variant="outlined"
          defaultValue={profile.primary_phone || ''}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          autoComplete="email"
          error={errors.email?.message}
          placeholder="Work email address"
          {...register('email')}
          defaultValue={profile.email}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item style={{ paddingTop: 20, paddingRight: 60 }} container justifyContent="flex-end" xs={6}>
        <Button className={classes.button} variant="text" onClick={handleReset} disabled={loading || !isDirty}>
          <Typography variant="button">Cancel</Typography>
        </Button>
      </Grid>
      <Grid style={{ paddingTop: 20 }} container justifyContent="flex-end" item xs={6}>
        <Grid item xs={11}>
          <Button
            fullWidth
            className={classes.button}
            variant="primary"
            type="submit"
            loading={loading}
            disabled={!isValid || loading || !isDirty}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
