export default theme => ({
  root: {},
  collapsed: {
    display: 'flex',
    gap: '5px'
  },
  expanded: {
    minHeight: 150,
    height: '100%',
    overflowY: 'auto'
  },
  header: {
    width: '100%',
    display: 'flex',
    gap: '6px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  button: {
    padding: 4
  },
  caption: {
    fontSize: 14,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  label: {
    color: '#131415'
  },
  minimum: {
    fontWeight: '300 !important'
  },
  value: {
    fontWeight: 500,
    color: '#131415'
  },
  pack: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  packQuantity: {
    fontSize: 16,
    color: theme.palette.neutral.grey36
  },
  packPrice: {
    textAlign: 'right'
  },
  customQuantity: {
    padding: theme.spacing(3),
    '& input': {
      lineHeight: 'normal',

      // Hide spinner
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield'
      }
    }
  },
  mla: {
    marginLeft: 'auto'
  }
});
