import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { moneyStr } from '../../../helpers/utils';
import { Img } from '../../global/ImgUtils';
import commonStyles from './styles/common';
import AppliedMembershipPanel from '../../global/AppliedMembershipPanel';
import useDiscountsAndRewards from '../../../hooks/useDiscountsAndRewards';
import StrikeOutText from '../../global/StrikeOutCost';

const styles = {
  ...commonStyles,
  colorCircle: {
    marginLeft: 5,
    borderRadius: 8,
    width: 10,
    height: 10
  },
  totalContainer: {
    margin: '17px 0',
    padding: '17px 0',
    borderTop: 'solid 1px #ced1d6',
    borderBottom: 'solid 1px #ced1d6'
  },
  totalText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    lineHeight: 1.6,
    color: '#4a505c'
  },
  totalValue: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 20,
    lineHeight: 1.6,
    color: '#0b1829'
  }
};

const useStyles = makeStyles(styles);

const PaymentSummary = ({ domesticData, internationalData, total, credits, paymentProfile }) => {
  const classes = useStyles();

  const { totalBeforeDiscount } = useDiscountsAndRewards(total);
  return (
    <>
      <p className={classes.columnTitle}>Payment summary</p>
      <Grid container className={classes.card} style={{ padding: '22px 31px' }}>
        <Grid container justifyContent="space-between" style={{ marginBottom: 13 }}>
          <p className={classes.mediumText}>Shipping - Domestic</p>
          <p className={clsx(classes.mediumText, 'bold')}>{moneyStr(domesticData?.totalPrice || 0)}</p>
        </Grid>
        <Grid container justifyContent="space-between">
          <p className={classes.mediumText}>Shipping - International</p>
          <p className={clsx(classes.mediumText, 'bold')}>{moneyStr(internationalData?.totalPrice || 0)}</p>
        </Grid>
        <AppliedMembershipPanel
          total={total}
          fromPayment={paymentProfile?.amount && paymentProfile?.amount > 0 ? paymentProfile?.amount : 0}
          fullWidth
          style={{ marginTop: 16, width: '100%' }}
        />
        <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.totalContainer}>
          <p className={classes.totalText}>Total</p>
          <p className={classes.totalValue}>
            <StrikeOutText value={totalBeforeDiscount} fontSize={14} />
            {moneyStr(total)}
          </p>
        </Grid>
        <Grid container justifyContent="space-between">
          <p className={classes.smallText}>Payment: </p>
          <Grid container direction="column" style={{ flex: 1, marginLeft: 4 }}>
            {paymentProfile && (
              <Grid container>
                <Grid container alignItems="center" style={{ flex: 1 }}>
                  <p className={clsx(classes.smallText, 'dark')}>
                    {paymentProfile.payment_method}{' '}
                    {paymentProfile?.credit_card?.card_number.slice(-4) ||
                      paymentProfile?.bank_account?.account_number.slice(-4)}
                  </p>
                  <Img
                    src={`/images/shipSwag/${paymentProfile.payment_method
                      .split(' ')
                      .join('-')
                      .toLowerCase()}.png`}
                    alt="credit-card"
                    width={22}
                    height={16}
                    style={{ marginLeft: 4 }}
                  />
                </Grid>
                <p className={clsx(classes.smallText, 'dark')}>{moneyStr(paymentProfile?.amount || 0)}</p>
              </Grid>
            )}
            {credits > 0 && (
              <Grid container style={{ marginTop: paymentProfile ? 10 : 0 }}>
                <Grid container alignItems="center" style={{ flex: 1 }}>
                  <p className={clsx(classes.smallText, 'dark')}>Credits</p>
                  <Img
                    src="/images/shipSwag/credits.png"
                    alt="credit-card"
                    width={22}
                    height={16}
                    style={{ marginLeft: 4 }}
                  />
                </Grid>
                <p className={clsx(classes.smallText, 'dark')}>{moneyStr(credits)}</p>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentSummary;
