// import { ellipsisStyles, scrollBar } from '../../shared/styles/commonStyles';
// import { changeColorLightness } from '../../shared/styles/utils';

const styles = theme => ({
  text: {
    marginBottom: 16,
    textAlign: 'center',
    color: '#0b1829',
    fontWeight: 400
  },
  textTitle: {
    marginBottom: 36,
    textAlign: 'center',
    color: '#0b1829',
    fontWeight: 500
  },
  formControl: {
    marginLeft: 4
  },
  textForm: {
    color: '#0b1829',
    marginBottom: 14
  },
  amountSelectorText: {
    textAlign: 'left',
    color: '#0b1829'
  },
  inputLabel: {
    marginLeft: 0,
    paddingTop: 8,
    width: 410,
    marginRight: 10
  },
  amountSelector: {
    padding: '6px 8px 6px 16px',
    border: '1px solid #CED1D6',
    borderRadius: 10,
    width: 150,
    height: 48,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      color: 'transparent',
      marginTop: 3,
      height: 16,
      width: 16
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  amountSelectorSelected: {
    padding: '6px 8px 6px 16px',
    border: '1px solid #3577D4',
    borderRadius: 10,
    cursor: 'pointer',
    width: 150,
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      color: '#3577D4'
    },
    '& .MuiSvgIcon-root': {
      color: '#3577D4',
      marginTop: 3,
      height: 16,
      width: 16
    }
  },
  title: {
    padding: 12,
    textAlign: 'center',
    '& > .MuiTypography-root': {
      fontSize: ({ disableTypography }) => (disableTypography ? null : 24),
      lineHeight: ({ disableTypography }) => (disableTypography ? null : '30px')
    }
  },
  closeButton: {
    position: 'absolute',
    right: 4,
    top: 4,
    [theme.breakpoints.down('xs')]: {
      right: 2,
      top: 17
    }
  },
  subtitle: {
    lineHeight: '22px',
    marginTop: 10,
    color: '#8D9299',
    fontSize: 16
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'transparent'
  },
  modalContent: {
    position: 'relative',
    padding: '0px 60px',
    borderRadius: 20,
    width: 780,
    outline: 'none',
    border: 'transparent'
  },
  deleteRedemptioModal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    border: 'transparent',
    padding: 0
  },
  deleteRedemptioModalContent: {
    position: 'relative',
    padding: 0,
    borderRadius: 8,
    width: 600,
    backgroundColor: '#ffffff',
    outline: 'none',
    border: 'transparent'
  },
  deleteRedemptioModalInfoSection: {
    padding: 24
  },
  deleteRedemptioModalActionSection: {
    padding: '12px 24px',
    borderTop: '1px solid #E5E7E8'
  },
  deleteRedemptioModalInfoText: { marginTop: 16 },
  shareModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    border: 'transparent',
    padding: 32,
    paddingTop: 212
  },
  customMessageForm: {
    width: 510,
    borderRadius: 10,
    border: '1px solid #CED1D6',
    padding: '18px 24px'
  },
  shareModalContent: {
    position: 'relative',
    padding: 0,
    borderRadius: 8,
    width: 488,
    backgroundColor: '#fafafa',
    outline: 'none',
    border: 'transparent',
    paddingBottom: 12
  },
  copyLink: {
    color: '#3577d4',
    paddingLeft: 0,
    height: 40,
    '& p': {
      color: '#3577d4',
      marginTop: 2,
      marginLeft: 4
    },
    '&:hover': {
      '& p': {
        textDecoration: 'underline'
      }
    },
    '&:focus': {
      '& p': {
        textDecoration: 'underline'
      }
    },
    '&:active': {
      color: '#1C4783',
      '& p': {
        color: '#1C4783'
      }
    },
    '&:disabled': {
      color: '#B7BBBF',
      '& p': {
        color: '#B7BBBF'
      }
    }
  },
  shareModalInfo: {
    width: '100%',
    padding: 24,
    paddingBottom: 32,
    borderBottom: '1px solid #E8EEF4'
  },
  shareModalActions: {
    width: '100%',
    padding: '12px 24px',
    paddingBottom: 2
  },
  shareModalProjectName: {
    paddingBottom: 2
  },
  notification: {
    border: '1px solid #4CAF50',
    background: '#E8F5E9',
    borderRadius: 4,
    padding: '6px 12px',
    paddingBottom: 2
  },
  notificationIcon: {
    color: '#2E7D32'
  },
  notificationText: {
    marginBottom: 4,
    marginLeft: 4
  },
  allGoodImageContainer: {
    width: 158,
    height: 183,
    position: 'relative'
  },
  allGoodImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  summaryTable: {
    borderRadius: 10,
    border: '1px solid #EBEDF0',
    padding: '12px 24px',
    width: 430
  },
  activationEmail: {
    width: 510,
    borderRadius: 10,
    border: '1px solid #CED1D6',
    padding: '18px 24px',
    margin: '20px 0px'
  },
  inputText: {
    width: '100%',
    borderRadius: 10,
    border: '1px solid #CED1D6',
    '& .MuiInputBase-formControl': {
      height: 130,
      overflowY: 'scroll',
      alignItems: 'flex-start',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '&.Mui-focused fieldset': {
        border: 'none !important',
        borderRadius: 'none !important'
      }
    }
  }
});
export default styles;
