import * as React from 'react';
import { Grid, makeStyles, Box, TableContainer, Table, TableBody } from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';
import { Button, Typography } from '@swagup-com/components';
import { useState, useMemo, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { min } from 'lodash';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import StoreFilterSection from '../StoreFilterSection';
import { StylessButton } from '../../buttons';
import {
  DeleteProductOptionModal,
  ProductOptionRow,
  TableEmptyState,
  TableHeaderProduct,
  productsCustomFilter
} from '../storeCommon';
import styles from './styles/productsTab';
import { usePaginatedQuery, useQueryParams } from '../../../hooks';
import storefrontsServicesPaths from '../../../helpers/storefrontsServicesPaths';
import { paginationRequestConverter } from '../../../helpers/utils';
import { useQueryFilterValidated } from '../../../hooks/useFilters';
import { storeProducts } from '../../../apis/storefrontsServices';
import { CenteredGrid, Pagination } from '../../shared';
import ProductSelectionDrawer from '../ProductSelectionDrawer';
import ProductOptionEditDrawer from './ProductOptionEditDrawer';
import SpinningSnackBar from '../components/SpinningSnackBar/SpinningSnackBar';
import ActionBar from '../components/ActionBar/ActionBar';
import SearchSortFilter from '../../shared/SearchSortFilter';

const useStyles = makeStyles(styles);

const perPageOptions = [10, 20, 30, 40];

const ProductsTab = ({ store, searchParam }) => {
  const [openProductDrawer, setOpenProductDrawer] = useState(false);
  const [editingProduct, setEditingProduct] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [actionBarOpen, setActionBarOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(-1);
  const [productQuery, setProductQuery] = useState('');

  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();
  const queryClient = useQueryClient();

  const editingProductId = query.get('edit-product');

  const search = query.get(searchParam) || '';
  const isSearching = !!search;
  const ordering = useQueryFilterValidated(
    'ordering',
    (xid, value) => ['-created_at', 'created_at'].includes(xid) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const {
    query: { data: productsResults, isFetching },
    pagination
  } = usePaginatedQuery({
    queryKey: [storefrontsServicesPaths.products(store.id), search, ordering],
    queryFn: (limit, offset) => {
      return store.id
        ? storeProducts.list(store.id, paginationRequestConverter({ limit, offset, search, ordering }))
        : [];
    },
    perPageOptions
  });

  const storeProductItems = useMemo(() => productsResults?.results || [], [productsResults?.results]);

  const showPagination = pagination?.count > min(pagination?.sizeOptions);

  const { id } = useParams();

  const onSuccessMutation = data => {
    queryClient.invalidateQueries([storefrontsServicesPaths.products(store.id)]);
    queryClient.invalidateQueries([storefrontsServicesPaths.storefront(id)]);
    query.delete('edit-product');
    history.replace({ ...location, search: query.toString() });
  };

  const productOptionSave = useMutation(
    ({ productId, payload }) => storeProducts.update(store.id, productId, payload),
    {
      onSuccess: ({ data }) => {
        onSuccessMutation(data);
      }
    }
  );
  const productOptionRemove = useMutation(productId => storeProducts.delete(store.id, productId), {
    onSuccess: ({ data }) => {
      onSuccessMutation(data);
      queryClient.cancelQueries([apiPaths.accountProducts]);
    }
  });

  useEffect(() => {
    const selected = storeProductItems.find(spo => `${spo.id}` === editingProductId);
    setEditingProduct(selected);
  }, [editingProductId, storeProductItems]);

  useEffect(() => {
    if (openProductDrawer) {
      setProductQuery(query.toString());
    }
  }, [openProductDrawer]);

  const handleOnEditClose = () => {
    query.delete('edit-product');
    history.replace({ ...location, search: query.toString() });
  };

  const addProducts = useMutation(params => storeProducts.addBulk(store.id, params), {
    onSuccess: ({ data }) => {
      onSuccessMutation(data);
      queryClient.invalidateQueries(storefrontsServicesPaths.products(store.id));
    }
  });

  const handleOnAdd = productOptions => {
    addProducts.mutate({ productOptions });
  };

  const handleOnSave = payloadData => {
    productOptionSave.mutate(payloadData);
  };
  const handleOnRemove = productId => {
    productOptionRemove.mutate(productId);
  };

  const handleOnVisibleChanged = (product, isVisible) => {
    productOptionSave.mutate({ productId: product.id, payload: { isVisible } });
  };

  const toogleSelectAll = () => {
    setSelectedItems(prev => {
      const data = prev.length !== storeProductItems.length ? storeProductItems.map(r => r) : [];
      setActionBarOpen(data.length > 0);
      return data;
    });
  };

  const toogleSelectedItems = sitem => {
    setSelectedItems(prev => {
      const data = prev.find(it => it.id === sitem.id) ? prev.filter(it => it.id !== sitem.id) : [...prev, sitem];
      setActionBarOpen(data.length > 0);
      return data;
    });
  };

  const onProductEdit = () => {
    setActionBarOpen(false);
    query.set('edit-product', selectedItems[0].id);
    history.replace({ ...location, search: query.toString() });
    setSelectedItems([]);
  };

  const searchSortFilterConfig = {
    search: { placeholder: 'Products', queryParam: 'search_products' },
    sort: { options: { '-created_at': 'Most Recent', created_at: 'Less Recent' } }
  };

  const classes = useStyles();
  return (
    <div>
      <CenteredGrid>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h2BoldInter">Products</Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button variant="primary" size="small" onClick={() => setOpenProductDrawer(true)}>
              Add Product
            </Button>
          </Grid>
        </Grid>
      </CenteredGrid>
      <SearchSortFilter config={searchSortFilterConfig} />
      <CenteredGrid>
        {storeProductItems?.length === 0 ? (
          <TableEmptyState isSearching={isSearching} type="product" />
        ) : (
          <TableContainer>
            <Table stickyHeader className={classes.orderTable}>
              <TableHeaderProduct
                classes={classes}
                toogleSelectAll={toogleSelectAll}
                allSelected={storeProductItems.length === selectedItems.length}
              />
              <TableBody>
                {storeProductItems?.map(product => (
                  <ProductOptionRow
                    key={product.id}
                    product={product}
                    toogleSelectedItems={toogleSelectedItems}
                    onVisibleChanged={checked => handleOnVisibleChanged(product, checked)}
                    isSelected={!!selectedItems.find(s => s.id === product.id)}
                    onDeleteProduct={setDeleteOpen}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {showPagination && (
          <Grid container justifyContent="center" alignItems="center" className={classes.paginationContainer}>
            <Grid item>
              <Pagination {...pagination} startText="Show" endText="products" buttonClass={classes.paginationBtn} />
            </Grid>
          </Grid>
        )}
      </CenteredGrid>

      <ProductSelectionDrawer
        open={openProductDrawer}
        products={store.productOptions}
        onClose={() => {
          history.replace({ ...location, search: productQuery });
          setOpenProductDrawer(false);
        }}
        onAdd={handleOnAdd}
        maxProducts={100}
        sellingOutOfStock={!store?.generalSettings?.stopSellingOutOfStock}
      />
      <ProductOptionEditDrawer
        open={!!editingProduct}
        onClose={handleOnEditClose}
        onSave={handleOnSave}
        onRemove={handleOnRemove}
        product={editingProduct}
        isLoading={productOptionSave.isLoading}
        isRemoving={productOptionRemove.isLoading}
        sellingOutOfStock={!store?.generalSettings?.stopSellingOutOfStock}
      />
      <SpinningSnackBar
        open={isFetching || productOptionSave.isLoading || productOptionRemove.isLoading}
        message="Loading..."
      />
      <ActionBar
        open={actionBarOpen}
        totalItemSelected={selectedItems.length}
        handleClose={() => {
          setSelectedItems([]);
          setActionBarOpen(false);
        }}
        type="Product"
        actions={[
          {
            text: 'Reorder',
            tooltip: 'Reorder one product at a time',
            baseIcon: ShoppingCartOutlinedIcon,
            hoverIcon: ShoppingCartIcon,
            disabled: selectedItems?.length > 1,
            action: () => {
              history.push(`/reorder/select-quantities/${selectedItems.at(0).variants[0]?.platformId}`);
            }
          },
          {
            text: 'Edit',
            tooltip: 'Only one product detail can be edited at a time',
            baseIcon: EditOutlinedIcon,
            hoverIcon: EditIcon,
            disabled: selectedItems?.length > 1,
            action: () => {
              onProductEdit();
            }
          }
          // {
          //   text: 'Remove',
          //   tooltip: 'Remove Product',
          //   baseIcon: RemoveCircleOutlineOutlinedIcon,
          //   hoverIcon: RemoveCircleIcon,
          //   action: () => {}
          // }
        ]}
      />

      <DeleteProductOptionModal
        open={deleteOpen > 0}
        onClose={() => setDeleteOpen(-1)}
        onDelete={() => {
          handleOnRemove(deleteOpen);
          setDeleteOpen(-1);
        }}
      />
    </div>
  );
};

export default ProductsTab;
