import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { CreateNewButton, sections } from './SharedComponents';
import styles from './styles/DashboardSummary';
import { imageSrcSet } from '../../helpers/utils';

const EmptyState = ({ classes, sectionName, title, description }) => (
  <div className={classes.orderEmptyMain}>
    <Grid container>
      <Grid item xs={12} align="center">
        <img src={sections[sectionName].image} alt="empty state" srcSet={imageSrcSet(sections[sectionName].image)} />
      </Grid>
      <Grid item xs={12} align="center" className={classes.separateTeamLeaderNameText}>
        <p className={classes.teamLeaderName}> {title} </p>
      </Grid>
      <Grid item xs={12} align="center" className={classes.separateRoleText}>
        <p className={classes.role}>{description}</p>
      </Grid>
      {sectionName !== 'proofs' && (
        <Grid item xs={12} align="center">
          <CreateNewButton sectionName={sectionName} variant="secondary" />
        </Grid>
      )}
    </Grid>
  </div>
);

export default withStyles(styles)(EmptyState);
