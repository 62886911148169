import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useQueryFilterValidated } from '../../../../hooks/useFilters';

const SelectionContext = React.createContext();
SelectionContext.displayName = 'SelectionContext';

const SelectionProvider = ({ children }) => {
  const [[excluded], setExcluded] = React.useState([new Map()]);
  const [areAllSelected, setAreAllSelected] = React.useState(false);
  const [scopeTotal, setScopeTotal] = React.useState(0);

  const { view } = useParams();
  const search = useQueryFilterValidated('search', () => true);
  const groupIds = useQueryFilterValidated('groups');

  const reset = () => {
    setAreAllSelected(false);
    setExcluded([new Map()]);
  };

  React.useEffect(() => {
    reset();
  }, [view]);

  React.useEffect(() => {
    if (areAllSelected) reset();
  }, [search, groupIds]);

  const toogleSelectionStatus = () => {
    setAreAllSelected(prevAllSelected => !prevAllSelected);
    setExcluded([new Map()]);
  };

  const updateExcluded = contact => {
    if (excluded.has(contact.id)) {
      excluded.delete(contact.id);
      setExcluded([excluded]);
    } else {
      excluded.set(contact.id, contact);
      setExcluded([excluded]);
    }
    if (excluded.size > 0 && scopeTotal === excluded.size) toogleSelectionStatus();
  };

  const isSelected = id => (areAllSelected && !excluded.has(id)) || (!areAllSelected && excluded.has(id));

  const isPartSelected = excluded.size > 0;
  const totalSelected = areAllSelected ? scopeTotal - excluded.size : excluded.size;
  const showSendSwag = view !== 'contacts' || areAllSelected || isPartSelected;
  const allowBulkRemove =
    areAllSelected ||
    (isPartSelected && [...excluded.values()].every(e => !e.has_pending_orders && !e.is_in_shipment_groups));

  return (
    <SelectionContext.Provider
      value={{
        excluded,
        areAllSelected,
        isPartSelected,
        totalSelected,
        showSendSwag,
        allowBulkRemove,
        toogleSelectionStatus,
        updateExcluded,
        isSelected,
        setScopeTotal,
        reset
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

const useSelection = () => {
  const selection = React.useContext(SelectionContext);
  return selection;
};

export { SelectionContext, SelectionProvider, useSelection };
