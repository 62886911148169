import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Grid, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { TextField, MenuItem, Button } from '@swagup-com/components';
import Scrollable from 'react-scrollbars-custom';
import Pagination from '../../shared/Pagination';
import { Modal } from '../../shared/Modal';
import styles from './styles/SizelessContacts';
import { useSizes } from '../../../hooks';

const useStyles = makeStyles(styles);

const perPage = 8;
const SizelessContacts = ({ open, contacts, onUpdate, onClose, quantities, stock }) => {
  const classes = useStyles();

  const { data: sizes } = useSizes();
  const [pageIndex, setPageIndex] = useState(0);
  const [tempContacts, setTempContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tempStock, setTempStock] = useState({});

  useEffect(() => {
    if (stock) {
      const remaining = {};
      stock.forEach(st => {
        remaining[st.size.name] = st.quantity - (quantities[st.size.name] || 0);
      });
      const bySize = {};
      sizes.forEach(s => {
        bySize[s.name] = remaining[s.name] || 0;
      });
      setTempStock(bySize);
    }
  }, [quantities, stock, sizes]);

  useEffect(() => {
    setTempContacts(open ? contacts.filter(c => !c.size) : []);
  }, [contacts, open]);

  const handleSizeChange = e => {
    const { name, value } = e.target;
    const newStock = { ...tempStock };
    const newContacts = tempContacts.map(c => {
      if (c.id !== +name) return c;

      const size = sizes.find(s => s.id === value);
      newStock[size.name] = tempStock[size.name] - 1;
      const oldSize = c?.size?.name;
      if (oldSize) {
        newStock[oldSize] = tempStock[oldSize] + 1;
      }
      return { ...c, size };
    });

    setTempContacts(newContacts);
    setTempStock(newStock);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    await onUpdate(tempContacts);
    setIsLoading(false);
  };

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const title = (
    <>
      <Typography variant="h5">Resolve missing size issue</Typography>
      <Typography variant="body1">Please select the sizes for the contacts below</Typography>
    </>
  );

  const disableUpdate = tempContacts.filter(e => !e.size).length > 0;
  const actions = (
    <Grid container justifyContent="space-between" alignItems="center">
      <Pagination
        count={tempContacts.length}
        pageIndex={pageIndex}
        perPage={perPage}
        onNext={() => setPageIndex(prev => prev + 1)}
        onPrevious={() => setPageIndex(prev => prev - 1)}
        sizeOptions={[perPage]}
        endText="contacts"
      />
      <Button
        variant="primary"
        style={{ width: 132, height: 56 }}
        disabled={disableUpdate || isLoading}
        loading={isLoading}
        onClick={handleUpdate}
      >
        Update
      </Button>
    </Grid>
  );

  return (
    <Modal
      open={open}
      title={title}
      actions={actions}
      onClose={handleClose}
      innerClasses={{ paper: classes.dialog }}
      disableTypography
    >
      <Scrollable style={{ width: '100%', height: '100%' }}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className="large">Name</TableCell>
              <TableCell className="large">Email</TableCell>
              <TableCell className="large">Street Address</TableCell>
              <TableCell className="small">State</TableCell>
              <TableCell className="small">Zip code</TableCell>
              <TableCell className="small">Shirt size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tempContacts
              .filter((tc, i) => i >= pageIndex * perPage && i < (pageIndex + 1) * perPage)
              .map(c => (
                <TableRow key={c.id}>
                  <TableCell className="large">
                    {c.first_name} {c.lastName}
                  </TableCell>
                  <TableCell className="large">{c.email}</TableCell>
                  <TableCell className="large">{c.shipping_address1}</TableCell>
                  <TableCell className="small">{c.shipping_state}</TableCell>
                  <TableCell className="small">{c.shipping_zip}</TableCell>
                  <TableCell className="small">
                    <TextField
                      select
                      name={`${c.id}`}
                      value={c.size?.id || 'none'}
                      SelectProps={{
                        IconComponent: KeyboardArrowDownIcon,
                        classes: { root: classes.selectRoot, icon: classes.selectIcon }
                      }}
                      classes={{ root: classes.select }}
                      onChange={handleSizeChange}
                    >
                      <MenuItem value="none" disabled>
                        <Typography variant="body2" style={{ color: '#3577d4' }}>
                          Select Size
                        </Typography>
                      </MenuItem>
                      {sizes
                        .filter(s => s.category === 'Apparel')
                        .map(s => (
                          <MenuItem key={s.id} value={s.id} disabled={tempStock[s.name] <= 0}>
                            <Typography variant="body2" style={{ color: '#0b1829' }}>
                              {s.name}
                            </Typography>
                          </MenuItem>
                        ))}
                    </TextField>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Scrollable>
    </Modal>
  );
};

export default SizelessContacts;
