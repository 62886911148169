import { scrollBar, ellipsisStyles } from '../../../../shared/styles/commonStyles';
import { changeColorLightness } from '../../../../shared/styles/utils';

const commonFontStyles = {
  cursorPointer: 'inherit',
  fontFamily: 'Gilroy-Medium',
  fontSize: 12,
  fontWeight: 500
};

export default {
  shippingLink: {
    lineHeight: 0,
    display: 'inline-flex',
    alignItems: 'center'
  },
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto minmax(116px, 1fr) auto',
    overflowY: 'auto'
  },
  title: {
    padding: '0 32px',
    paddingTop: 24,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 600,
    color: '#0b1829'
  },
  createdDate: {
    padding: '8px 32px 16px 32px',
    fontSize: 10,
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    lineHeight: 1.6,
    color: '#787b80'
  },
  itemListContainer: {
    ...scrollBar,
    width: 'calc(100% - 10px)',
    marginTop: 10
  },
  itemDivider: {
    width: '100%',
    height: 1,
    backgroundColor: '#f0f2f5'
  },
  itemName: {
    ...ellipsisStyles,
    width: '100%',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    color: '#0b1829'
  },
  itemPriceHovered: {
    color: '#0b1829 !important'
  },
  pricePerItem: {
    ...commonFontStyles,
    lineHeight: 1.33,
    color: '#787b80'
  },
  totalPriceItem: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#0b1829'
  },
  editButton: ({ showWarning }) => ({
    ...commonFontStyles,
    width: 'fit-content',
    height: 22,
    marginTop: 6,
    marginLeft: -8,
    padding: '3px 8px',
    lineHeight: 0.67,
    letterSpacing: 'normal',
    color: showWarning ? '#fa902d' : '#3577d4',
    '&:hover': { color: changeColorLightness(showWarning ? '#fa902d' : '#3577d4') }
  }),
  shippingInfoButton: {
    marginLeft: 5,
    color: '#3577d4',
    '&:hover': {
      color: changeColorLightness('#3577d4'),
      backgroundColor: 'unset'
    }
  },
  costSummaryText: {
    ...commonFontStyles,
    lineHeight: 1.67,
    color: '#787b80'
  },
  costSummaryValue: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.57
  },
  greyedOutText: {
    fontWeight: 500,
    color: '#7e8186'
  },
  totalText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: '#787b80'
  },
  totalValue: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.6,
    color: '#0b1829'
  }
};
