import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import styles from './styles/card';

const useStyles = makeStyles(styles);

const CustomPackOption = () => {
  const classes = useStyles();

  return (
    <Link to="/custom-swag-packs/catalog">
      <div className={classes.root}>
        <div>
          <img
            src="/images/custom-pack-landing/getstarted.svg"
            alt="Landing page mockup"
            height={324}
            width={300}
            className={classes.customPackImage}
          />
        </div>
        <div className={classes.actions}>
          <Box ml="auto">
            <Typography className={classes.selectText}>Build Your Own</Typography>
          </Box>
        </div>
      </div>
    </Link>
  );
};

export default CustomPackOption;
