import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@swagup-com/components';
import { scrollBar } from '../shared/styles/commonStyles';

const useStyles = makeStyles({
  notesContainer: { position: 'relative' },
  notesLength: {
    position: 'absolute',
    right: 12,
    bottom: 5,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#8d9299'
  },
  textArea: scrollBar
});
const LimitedTextField = ({ value, maxLength = 250, maxRows = 5, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.notesContainer}>
      <TextField
        value={value}
        multiline
        maxRows={maxRows}
        InputProps={{
          classes: { input: classes.textArea },
          inputProps: { maxLength }
        }}
        {...props}
      />
      <p className={classes.notesLength}>
        {value?.length || 0} / {maxLength}
      </p>
    </div>
  );
};

export default LimitedTextField;
