import {
  GET_PAYMENT_PROFILES,
  DELETE_PAYMENT_PROFILE,
  SET_DEFAULT_PAYMENT_PROFILE,
  ADD_PAYMENT_PROFILE,
  EDIT_PAYMENT_PROFILE_NAME
} from './types';
import DashBoard from '../apis/DashBoard';
import { okAndLog, errorAndLog } from '../helpers/utils';
import apiPaths from '../helpers/apiPaths';
import log from '../logger';

export const getPaymentProfiles = accountId => async dispatch => {
  log.debug('getPaymentProfiles Action - Entering');
  try {
    const apiCall = await DashBoard.get(apiPaths.accountPaymentProfiles(accountId));
    const paymentProfiles = apiCall.data.results;
    if (apiCall.status === 200) {
      dispatch({
        type: GET_PAYMENT_PROFILES,
        payload: paymentProfiles
      });
      return okAndLog('getPaymentProfiles', apiCall.status, paymentProfiles);
    }
    return errorAndLog('getPaymentProfiles', apiCall.status, paymentProfiles);
  } catch (e) {
    return errorAndLog('getPaymentProfiles', e.status, e.data);
  }
};

export const addNewPaymentProfile = (accountId, data) => async dispatch => {
  log.debug('addNewPaymentProfile Action - Entering');
  try {
    const apiCall = await DashBoard.post(apiPaths.accountPaymentProfiles(accountId), data);
    if (apiCall.status === 201) {
      dispatch({
        type: ADD_PAYMENT_PROFILE,
        payload: apiCall.data
      });
      return okAndLog('addNewPaymentProfile', apiCall.status, apiCall.data);
    }
    return errorAndLog('addNewPaymentProfile', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('addNewPaymentProfile', e.status, e.data);
  }
};

export const deletePaymentProfile = (accountId, profileId) => async dispatch => {
  log.debug('deletePaymentProfile Action - Entering');
  try {
    const apiCall = await DashBoard.delete(apiPaths.accountPaymentProfile(accountId, profileId));
    if (apiCall.status === 204) {
      dispatch({
        type: DELETE_PAYMENT_PROFILE,
        payload: profileId
      });
      return okAndLog('deletePaymentProfile', apiCall.status, apiCall.data);
    }
    return errorAndLog('deletePaymentProfile', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('deletePaymentProfile', e.status, e.data);
  }
};

export const setDefaultPaymentProfile = (accountId, profileId, isDefault) => async dispatch => {
  log.debug('setDefaultPaymentProfile Action - Entering');
  try {
    const apiCall = await DashBoard.patch(apiPaths.accountPaymentProfile(accountId, profileId), {
      default: isDefault
    });
    if (apiCall.status === 200) {
      dispatch({
        type: SET_DEFAULT_PAYMENT_PROFILE,
        payload: profileId
      });
      return okAndLog('setDefaultPaymentProfile', apiCall.status, apiCall.data);
    }
    return errorAndLog('setDefaultPaymentProfile', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('setDefaultPaymentProfile', e.status, e.data);
  }
};

export const editPaymentProfileName = (accountId, profileId, name) => async dispatch => {
  log.debug('editPaymentProfileName Action - Entering');
  try {
    const apiCall = await DashBoard.patch(apiPaths.accountPaymentProfile(accountId, profileId), {
      payment_profile_name: name
    });
    if (apiCall.status === 200) {
      dispatch({
        type: EDIT_PAYMENT_PROFILE_NAME,
        payload: { profileId, name }
      });
      return okAndLog('editPaymentProfileName', apiCall.status, apiCall.data);
    }
    return errorAndLog('editPaymentProfileName', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('editPaymentProfileName', e.status, e.data);
  }
};
