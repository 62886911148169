import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Button, Typography } from '@swagup-com/components';
import { Alert } from '@material-ui/lab';
import { useMembership } from '../../contexts/membershipContext';
import Loader from '../global/Loader';

const useStyles = makeStyles(() => ({
  root: {
    '& > .MuiDialog-container > .MuiDialog-paper': {
      maxWidth: 'unset',
      width: 800
    }
  },
  closeIconWrapper: {
    justifyContent: 'flex-end'
  },
  closeIcon: {
    fill: '#787B80',
    color: '#787B80',
    width: '18px',
    height: '18px',
    cursor: 'pointer'
  },
  titleWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  radioBtnWrapper: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  radioBtn: {
    '&.Mui-checked': {
      color: '#125CFF !important',
      fill: '#125CFF !important'
    }
  },
  confirmBtn: {
    backgroundColor: '#0065FF',
    '&:hover': {
      backgroundColor: '#0065FF'
    }
  }
}));

const ModalContent = ({ handleDiscardInventories = () => {}, handleOnCLose = () => {} }) => {
  const classes = useStyles();
  const [donation, setDonation] = React.useState('donate');

  const handleDonationChange = e => {
    const { value } = e.target;
    setDonation(value);
  };

  const { alertMessage } = useMembership();

  return (
    <>
      <DialogTitle disableTypography>
        <Grid container className={classes.closeIconWrapper}>
          <CloseIcon className={classes.closeIcon} onClick={handleOnCLose} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        {alertMessage?.type === 'error' && (
          <Grid
            container
            style={{ width: '100%', justifyContent: 'center', marginBottom: '24px', padding: '0px 52px' }}
          >
            <Alert
              severity="error"
              variant="outlined"
              style={{
                width: '100%',
                paddingRight: '16px',
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px'
              }}
            >
              {alertMessage.message}
            </Alert>
          </Grid>
        )}
        <Grid container className={classes.titleWrapper}>
          <Grid item>
            <Typography variant="h5SemiBoldInter">Do you want SwagUp to try and donate this inventory?</Typography>
          </Grid>
          <Grid item style={{ marginTop: '24px', padding: '0px 42px' }}>
            <Typography variant="body3RegularInter" style={{ textAlign: 'center' }}>
              SwagUp cares about the environment and we do our best to be sustainable and eco-friendly whenever
              possible. As a result, we will try to donate this inventory so it can be reused. Not all products can be
              donated, in which case we will recycle or trash this inventory in a responsible way.
            </Typography>
          </Grid>
          <RadioGroup
            aria-label="quiz"
            value={donation}
            className={classes.radioBtnWrapper}
            onChange={handleDonationChange}
          >
            <FormControlLabel
              value="donate"
              control={<Radio className={classes.radioBtn} />}
              label={
                <Typography variant="body3RegularInter">
                  <span style={{ fontWeight: 700 }}>Yes,</span> please try to donate this inventory!
                </Typography>
              }
            />
            <FormControlLabel
              value="discard"
              control={<Radio className={classes.radioBtn} />}
              label={
                <Typography variant="body3RegularInter">
                  <span style={{ fontWeight: 700 }}>No,</span> please recycle or trash this inventory
                </Typography>
              }
            />
          </RadioGroup>

          <Grid item style={{ marginTop: '24px' }}>
            <Button variant="primary" className={classes.confirmBtn} onClick={() => handleDiscardInventories(donation)}>
              Confirm
            </Button>
          </Grid>

          <Grid item style={{ margin: '24px 0px 38px 0px' }}>
            <Typography variant="body3RegularInter" style={{ textAlign: 'center', color: '#125CFF' }}>
              By selecting “Confirm” you are agreeing to the above and understand this action cannot be reversed.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

const DiscardInventoryModal = ({
  open,
  handleOnCLose = () => {},
  handleDiscardInventories = () => {},
  showLoader = false
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog open={open} onClose={handleOnCLose} className={classes.root}>
        {showLoader && <Loader />}
        <ModalContent handleDiscardInventories={handleDiscardInventories} handleOnCLose={handleOnCLose} />
      </Dialog>
    </>
  );
};

export default DiscardInventoryModal;
