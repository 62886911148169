/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { SizesListMultiSize, SizesListOneSize } from '../../common/SizesList';
import { isOneSize } from '../../../../../../helpers/utils';
import { changeColorLightness } from '../../../../../shared/styles/utils';

const useStyles = makeStyles({
  sizesContainer: {
    columnGap: '16px',
    rowGap: '24px',
    position: 'relative',
    paddingTop: 12
  },
  hightlightedText: {
    color: '#0b1829',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    margin: '36px 0px 20px 0px'
  },
  orderOverviewMargin: { margin: '12px 0px 10px 0px' },
  editButton: { height: 20, marginLeft: -8, marginBottom: 1 },
  editText: {
    color: '#3577D4 !important',
    '&:hover': { color: `${changeColorLightness('#3577D4')} !important` }
  }
});

const OneSizeConfiguration = ({ product, quantities, isOrderOverview }) => {
  return (
    <SizesListOneSize
      currentQuantity={product.quantities_per_size[0].quantity}
      customSizeInputProps={{
        minimumQuantity: quantities[product.quantities_per_size[0].size.id],
        placeholder: 'Custom qty',
        disabled: true
      }}
      isOrderOverview={isOrderOverview}
    />
  );
};

const MultipleSizesConfiguration = ({ product, quantities, isOrderOverview, isStatic }) => {
  return (
    <SizesListMultiSize
      sizes={product.quantities_per_size}
      idSelector={size => size.size.id}
      nameSelector={size => size.size.name}
      isStatic={isStatic}
      inputPropsPerSize={size => ({
        oldQuantity: product.quantities_per_size.find(sz => sz.size.id === size.size.id).quantity,
        currentQuantity: size.quantity,
        minimumQuantity: quantities[size.size.id],
        defaultValue: size.quantity || '',
        placeholder: '0',
        disabled: isOrderOverview
      })}
    />
  );
};

const InvoiceProductSizes = ({ product, isOrderOverview, isStatic }) => {
  const classes = useStyles();
  const quantities = product.quantities_per_size;

  const Configuration = isOneSize(product.quantities_per_size) ? OneSizeConfiguration : MultipleSizesConfiguration;

  return (
    <Grid container className={classes.sizesContainer}>
      <Configuration product={product} quantities={quantities} isOrderOverview={isOrderOverview} isStatic={isStatic} />
    </Grid>
  );
};

export default InvoiceProductSizes;
