import styles from './WarehouseOverview.styles';

export default {
  ...styles,
  paper: {
    padding: '32px 64px 32px 50px',
    width: 792
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: 'normal',
    color: '#0b1829'
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 'normal',
    color: '#787b80',
    maxWidth: 526,
    marginTop: 8
  },
  quantityContainer: {
    textAlign: 'center',
    marginRight: 10,
    minWidth: 50,
    borderRadius: 10,
    padding: 10,
    backgroundColor: '#fff7f0'
  },
  updateButton: {
    height: 12,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 'normal'
  }
};
