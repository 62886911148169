import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Button } from '@swagup-com/components';
import { imageSrcSet, moneyStr } from '../../helpers/utils';
import handleImg from '../../helpers/handleImgHelper';
import { Img } from '../global/ImgUtils';
import styles from './styles/packsTemplates';

const useStyles = makeStyles(styles);

const PackInfo = ({ classes, pack, isHovered }) => (
  <Paper
    elevation={2}
    style={{ padding: 32, textAlign: 'left' }}
    className={isHovered ? classes.hoveredButton : classes.simpleButton}
  >
    <Grid container direction="column" style={{ margin: 0, height: '100%' }}>
      <Grid item>
        <Img src={handleImg(pack.img, 288, 288)} className={classes.packImg} alt={pack.name} />
      </Grid>
      <Grid item xs className={classes.containerMinimumText}>
        <p className={classes.minimumText}>{`Min qty: ${pack.minimum}`}</p>
      </Grid>
      <Grid item>
        <Typography variant="h5" className={classes.packNameText}>
          {pack.name}
        </Typography>
        <Typography variant="body1" className={classes.packPriceText}>
          <span className={classes.packPriceTextBlack}>{moneyStr(pack.price_per_pack)}</span>
          /pack*
        </Typography>
      </Grid>
    </Grid>
  </Paper>
);

const createCustomImg = '/images/presets/create-custom.png';

const CreationPack = ({ classes, isHovered }) => (
  <Grid
    style={{ paddingBottom: 48, textAlign: 'center', height: '100% !important' }}
    className={isHovered ? classes.hoveredButton : classes.simpleButton}
    container
    direction="column"
    alignItems="center"
  >
    <Grid item xs className={classes.containerCustomImg}>
      <img
        src={createCustomImg}
        srcSet={imageSrcSet(createCustomImg)}
        className={clsx(classes.customImg)}
        alt="creation pack"
      />
    </Grid>
    <Typography variant="h5" className={classes.packNameText}>
      &quot;Create a custom pack!&quot;
    </Typography>
    <Typography variant="body1" className={classes.packNameSubText}>
      Build a pack that fits your <br />
      needs & budget
    </Typography>
  </Grid>
);

const CardPack = ({ pack, to, showCreationCard }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const classes = useStyles({ isHovered });

  return (
    <Grid item style={{ height: '100%' }}>
      <Button
        variant="text"
        component={Link}
        to={to}
        className={classes.stylessButton}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {showCreationCard ? (
          <CreationPack classes={classes} isHovered={isHovered} />
        ) : (
          <PackInfo pack={pack} classes={classes} isHovered={isHovered} />
        )}
      </Button>
    </Grid>
  );
};

const PacksTemplates = ({ packs }) => {
  const fromProducts = useLocation().state?.fromProducts;

  return (
    <main>
      <Grid container spacing={2} alignItems="stretch">
        {packs.map(pack => (
          <Grid key={pack.id} item sm={6} md={4} xs={12} style={{ paddingTop: 24 }}>
            <CardPack pack={pack} to={{ pathname: `/preset-packs/${pack.slug || pack.id}`, state: { fromProducts } }} />
          </Grid>
        ))}
        <Grid item sm={6} md={4} xs={12} style={{ paddingTop: 24 }}>
          <CardPack showCreationCard to={{ pathname: '/custom-swag-packs/catalog', state: { fromProducts } }} />
        </Grid>
      </Grid>
    </main>
  );
};

export default PacksTemplates;
