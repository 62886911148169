import React from 'react';
import { withStyles, Grid, Dialog, DialogContent, DialogTitle, GridList } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import CloseButton from '../modals/CloseButton';
import NewPaymentMethod from './NewPaymentMethod';
import gtm from '../../utils/gtm';
import styles from '../global/styles/PaymentSection';

const PaymentOptionsModal = ({
  classes,
  open,
  tabs,
  toggleOpenPaymentOption,
  title,
  subtitle,
  continuePaymentMethod,
  setDefaultPaymentProfile,
  setOpenContactModal,
  setOpenPaymentOptionModal,
  canAddPaymentProfiles
}) => (
  <Dialog
    open={open}
    onClose={toggleOpenPaymentOption}
    classes={{ paper: classes.Dialog }}
    className={classes.modalDialog}
  >
    <DialogTitle classes={{ root: classes.DialogTitle }}>
      <CloseButton onClose={toggleOpenPaymentOption} classes={{ container: classes.closeButton }} />
      <Grid item xs={12}>
        <p className={classes.modalTitle}> Change {title}</p>
        <p className={classes.modalSubtitle}>You can choose from your saved {subtitle} or add new</p>
      </Grid>
    </DialogTitle>
    <DialogContent>
      <p className={classes.modalText}>Saved {subtitle}</p>
      <GridList className={classes.gridList}>
        <Grid item xs={12} style={{ paddingRight: 12 }}>
          {tabs.activeTab().content()}
        </Grid>
      </GridList>
      <Grid item style={{ marginTop: 30 }}>
        {canAddPaymentProfiles && (
          <NewPaymentMethod
            title={title}
            setOpenContactModal={setOpenContactModal}
            setOpenPaymentOptionModal={setOpenPaymentOptionModal}
            isShippingModal
          />
        )}
      </Grid>
      <div align="center" style={{ marginTop: 40, marginBottom: 40 }}>
        <Button
          variant="primary"
          size="small"
          disabled={!continuePaymentMethod}
          onClick={() => {
            setDefaultPaymentProfile();
            gtm.onClickContinue('Payment option selected');
          }}
          style={{ width: 200 }}
        >
          Continue
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(PaymentOptionsModal);
