import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import SigleContentPage from './SigleContentPage';
import { CentredContentContainer } from './Common';
import { CustomWidthButtonHolydays } from '../buttons';
import styles from './styles/onboarding';

const FirstStep = ({ classes, nextStep }) => (
  <SigleContentPage>
    <Grid container direction="column">
      <CentredContentContainer>
        <div className={classes.logoContainer}>
          <a color="inherit" href="/">
            <img src="/images/public/su-logo.svg" className={classes.logo} alt="" />
          </a>
        </div>
      </CentredContentContainer>
      <CentredContentContainer>
        <img src="/images/onboarding/036-gift.svg" className={classes.firstStepImg} alt="" />
      </CentredContentContainer>
      <CentredContentContainer paddingTop={32}>
        <h2 className={classes.heyThere}>Hey there!</h2>
        <p className={classes.noticeText}>Ready to unbox a fully custom swag pack?</p>
      </CentredContentContainer>
      <CentredContentContainer paddingTop={72}>
        <CustomWidthButtonHolydays className={classes.accentButon} fontFamily="Gilroy" fontSize={18} onClick={nextStep}>
          Get started
        </CustomWidthButtonHolydays>
      </CentredContentContainer>
    </Grid>
  </SigleContentPage>
);

export default withStyles(styles)(FirstStep);
