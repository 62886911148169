import storefrontsServicesPaths from '../../helpers/storefrontsServicesPaths';
import axios from '../StorefrontsServiceAPI';
import { status200or201 } from '../swagup/status.utils';

export default {
  names: param => axios.post(storefrontsServicesPaths.verifyName, param, status200or201).then(rslt => rslt.data),

  email: param =>
    axios
      .post(storefrontsServicesPaths.verifyEmail, param, status200or201)
      .then(rslt => rslt.data)
      .catch(err => err.response?.data || err.message)
};
