import log from '../logger';
import { FETCH_ORDERS, SELECT_ORDER } from './types';
import DashBoard from '../apis/DashBoard';
import { errorAndLog, okAndLog } from '../helpers/utils';
import apiPaths from '../helpers/apiPaths';

export const fetchOrders = (ascendingSort = false, limit = 999, createdAt = 'all') => async dispatch => {
  log.debug('fetchOrders Action - Entering');
  try {
    const sign = ascendingSort ? '' : '-';
    const apiCall = await DashBoard.get(
      `${apiPaths.accountOrders}?created_at=${createdAt}&ordering=${sign}created_at&limit=${limit}`
    );
    if (apiCall.status === 200) {
      dispatch({
        type: FETCH_ORDERS,
        payload: apiCall.data.results
      });
      return okAndLog('fetchOrders', apiCall.status, apiCall.data);
    }
    return errorAndLog('fetchOrders', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('fetchOrders', e.status, e.data);
  }
};

export const selectOrder = order => async dispatch =>
  dispatch({
    type: SELECT_ORDER,
    payload: order
  });

export const fetchInvoiceOrders = async invoiceId => {
  log.debug('fetchInvoiceOrders Action - Entering');
  try {
    const apiCall = await DashBoard.get(`${apiPaths.accountOrders}?invoices=${invoiceId}`);
    if (apiCall.status === 200) {
      return okAndLog('fetchInvoiceOrders', apiCall.status, apiCall.data);
    }
    return errorAndLog('fetchInvoiceOrders', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('fetchInvoiceOrders', e.status, e.data);
  }
};
