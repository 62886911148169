import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onboardingResetStep } from '../../actions';
import { CustomWidthButtonOnboarding } from '../buttons';
import { joinFields } from '../../helpers/utils';
import SectionTag from './SectionTag';
import styles from './styles/onboarding';

const StartBuilding = ({ classes, currentInfo, onboardingResetStepAction }) => {
  const history = useHistory();
  return (
    <Grid container justifyContent="center" alignItems="stretch" style={{ height: '100%', width: '100%' }}>
      <Grid item style={{ height: '100%' }}>
        <div className={classes.logoContainerBuild}>
          <a color="inherit" href="/">
            <img src="/images/public/su-logo.svg" className={classes.logo} alt="" />
          </a>
        </div>
        <Grid container style={{ height: '100%' }} spacing={6}>
          <Grid item md={5} xs={12} style={{ height: '100%' }}>
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <Grid item className={classes.startBuildingContent}>
                <SectionTag tag="Onboarding" color="#9846dd" />
                <h2 className={classes.thankYou}>Thank you!</h2>
                <p className={classes.stepsText}>We are going to send it to this address below</p>
                <div className={classes.addresContainer}>
                  <p className={classes.finalName}>
                    {joinFields([currentInfo.first_name, currentInfo.last_name], ' ')}
                  </p>
                  <p className={classes.finalAddress}>
                    {joinFields([currentInfo.shipping_address1, currentInfo.shipping_address2], ', ')}
                  </p>
                  <p className={classes.finalAddressState}>
                    {joinFields(
                      [
                        currentInfo.shipping_city,
                        joinFields([currentInfo.shipping_state, currentInfo.shipping_zip], ' ')
                      ],
                      ', '
                    )}
                    , {currentInfo.shipping_country}
                  </p>
                </div>
                <div className={classes.startBuildingButtonContainer}>
                  <CustomWidthButtonOnboarding
                    className={classes.buildingButon}
                    fontFamily="Gilroy-Bold"
                    onClick={() => {
                      onboardingResetStepAction();
                      history.push('/preset-packs');
                    }}
                    color="#3577d4"
                  >
                    Start Building
                  </CustomWidthButtonOnboarding>
                  <div className={classes.onMobile} style={{ textAlign: 'center', marginTop: 32 }}>
                    <img src="/images/onboarding/group-91.svg" className={classes.startBuildingImg} alt="" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={7} xs={12} style={{ height: '100%' }} className={classes.noMobile}>
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <Grid item>
                <img src="/images/onboarding/group-91.svg" className={classes.startBuildingImg} alt="" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  currentInfo: state.onboardingInfo.currentInfo
});

export default connect(mapStateToProps, {
  onboardingResetStepAction: onboardingResetStep
})(withStyles(styles)(StartBuilding));
