export default theme => ({
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      marginTop: 20
    }
  },
  title: {
    color: '#000',
    marginBottom: 10,
    fontFamily: 'Gilroy-SemiBold'
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontHeight: 500,
    color: '#000'
  },
  titleContainer: {
    flex: 'auto'
  },
  mockups: {
    marginTop: 30,
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gap: '30px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      gap: 0
    }
  },
  uploadButton: {
    minWidth: 172
  }
});
