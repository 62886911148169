import React, { useState } from 'react';
import { Box, Typography, Divider, Grid, makeStyles, withStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import round from 'lodash/round';
import { useCreditActions, useCreditSummary } from '../../../../hooks';
import Loader from '../../../global/Loader';
import Tooltip from '../../../shared/Tooltip';
import { ToolTipIcon } from '../../../icons';
import gtm from '../../../../utils/gtm';
import { Modal } from '../../../shared/Modal';
import Payment from './Payment';
import { InfoContainer, ErrorBanner } from './common';
import { moneyStr } from '../../../../helpers/utils';
import styles from './styles/refundWithPaymentCancelModal';
import { TotalRefundToSwagCard } from '../../orders/requested/details/orderReview/common';
import { shipmentStatus } from '../../../../apis/constants';

const useStyles = makeStyles(styles);

const InfoItem = withStyles(styles)(({ text, icon, amount, isTotal, classes }) => (
  <Grid item container alignItems="center" wrap="nowrap" className={classes.infoItemContainer}>
    <Grid container alignItems="center">
      <Typography variant="body1" className={classes.infoItemText}>
        {text}
      </Typography>
      {icon}
    </Grid>
    <Typography variant={isTotal ? 'h6' : 'body1'} className={classes.infoItemAmount}>
      {amount}
    </Typography>
  </Grid>
));

const RefundWithPaymentCancelModal = ({
  open,
  refundedCredit,
  warehouseFee,
  refundedPackages,
  onCancel,
  onClose,
  pendingProductionStatus
}) => {
  const classes = useStyles();

  const [isValidPayment, setIsValidPayment] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const totalToPay =
    pendingProductionStatus === shipmentStatus.pendingProduction
      ? round(warehouseFee, 2)
      : round(warehouseFee - refundedCredit, 2);
  const haveToPay = totalToPay > 0;

  const { data: creditSummary, isLoading: creditsPending } = useCreditSummary({ enabled: haveToPay });
  const credit = creditSummary.current_balance;
  const payWithCC = Math.max(0, round(totalToPay - credit, 2) || 0);

  const handleError = message => {
    setErrorMessage(message);
    setIsCancelling(false);
  };

  const { addCredits, invalidateCache } = useCreditActions();

  const handleCancelPayment = () => {
    try {
      setIsCancelling(false);
      onCancel();
    } catch (e) {
      handleError('Error canceling shipment.');
    }
  };

  const handleCancelShipment = () => {
    if (payWithCC === 0) {
      invalidateCache();
      handleCancelPayment();
    } else {
      const creditInfo = { amount: Math.max(1, payWithCC) };
      addCredits.mutate(creditInfo, {
        onSuccess: handleCancelPayment,
        onError: () => handleError('Error in payment.')
      });
    }
  };

  return (
    <Modal
      title="Are you sure you want to cancel this shipment?"
      open={open}
      onClose={onClose}
      classes={{ title: classes.title }}
      innerClasses={{ paper: classes.paper }}
    >
      {(creditsPending || isCancelling) && <Loader absolute />}
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1" align="center" className={classes.subtitle} style={{ width: 500 }}>
            Items from this shipment will be stored in our warehouse once production has completed
          </Typography>
        </Grid>
        <Box width="100%" marginTop="30px" padding="0 30px">
          {errorMessage && (
            <Box p="0 11px">
              <ErrorBanner message={errorMessage} />
            </Box>
          )}
          <InfoContainer>
            <InfoItem text="No. of items to return" amount={refundedPackages} />
            <Divider style={{ margin: '10px 0' }} />
            {pendingProductionStatus === shipmentStatus.pendingProduction ? null : (
              <InfoItem text="Refund Shipment cost" amount={moneyStr(refundedCredit)} />
            )}
            <InfoItem
              text="Warehouse fee"
              icon={
                <Tooltip
                  title={
                    <Grid>
                      Refer to{' '}
                      <a href="https://www.swagup.com/pricing" target="_blank" rel="noreferrer">
                        swagup/pricing
                      </a>{' '}
                      for full warehouse pricing details
                    </Grid>
                  }
                  fontSize={12}
                  fontColor="#0b1829"
                  backgroundColor="#fff"
                  width={240}
                  interactive
                >
                  <div className={classes.tooltipContainer}>
                    <ToolTipIcon fontColor="#787b80" viewBox="-3 -3 32 32" />
                  </div>
                </Tooltip>
              }
              amount={
                pendingProductionStatus === shipmentStatus.pendingProduction
                  ? moneyStr(`${warehouseFee}`)
                  : moneyStr(`-${warehouseFee}`)
              }
            />
            <Divider style={{ margin: '10px 0' }} />
            <InfoItem
              text={haveToPay ? 'Total Payable' : <TotalRefundToSwagCard />}
              amount={moneyStr(Math.abs(totalToPay))}
              isTotal
            />
          </InfoContainer>
          {haveToPay && !creditsPending && credit && (
            <Payment credit={credit} totalToPay={totalToPay} onValidityChange={info => setIsValidPayment(info)} />
          )}
          <Grid item container justifyContent="flex-end" className={classes.buttonsContainer}>
            {!haveToPay && (
              <Button variant="text" onClick={onClose} className={classes.cancelButton}>
                Cancel
              </Button>
            )}
            <Button
              variant="primary"
              onClick={() => {
                handleCancelShipment();
                gtm.onClickContinue('Cancelled shipment');
              }}
              disabled={!isValidPayment}
              className={classes.continueButton}
            >
              {haveToPay ? `Pay ${moneyStr(totalToPay)} and continue` : 'Continue'}
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default RefundWithPaymentCancelModal;
