import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';

import styles from './styles/card';

const useStyles = makeStyles(styles);

const CardInfo = ({ text, variant, image, classes }) => (
  <div className={classes.root}>
    <img src={image} alt="mockup" className={classes.image} />
    <div className={classes.actions}>
      <p className={classes.title}>Swag Pack #{variant}</p>
      <Typography className={classes.selectText}>{text}</Typography>
    </div>
  </div>
);

const Mockup = ({ collageId, variant, image, selectable }) => {
  const { designId } = useParams();
  const location = useLocation();
  const classes = useStyles();

  return selectable ? (
    <Link to={{ pathname: `/onboarding/mockups/${designId}/${collageId}`, state: location.state }}>
      <CardInfo text="Select Pack" variant={variant} image={image} classes={classes} />
    </Link>
  ) : (
    <CardInfo text="Selected" variant={variant} image={image} classes={classes} />
  );
};

export default Mockup;
