import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@swagup-com/components';
import { Grid, InputAdornment, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import { useFlags } from 'launchdarkly-react-client-sdk';
import gtm from '../../../utils/gtm';
import { MultiselectFilter, OutlinedSearchField } from '../../global/Filters';
import { useInfiniteScroll, useInventoryMultifilters, useSelectedProducts } from '../../../hooks';
import toErrorPage from '../../../helpers/toErrorPage';
import accountProductsApi from '../../../apis/swagup/accountProducts';
import { errorAndLog, okAndLog } from '../../../helpers/utils';
import InventoryList from './InventoryList';
import InventorySelected from './InventorySelected';
import { createReorderProducts } from '../../../actions';

const filtersText = {
  fontFamily: 'Gilroy-Regular',
  fontSize: 16,
  color: '#8d9299'
};
const useFilterStyles = makeStyles({
  searchFieldContainer: { paddingRight: 24 },
  searchField: {
    borderRadius: 32,
    '&.MuiOutlinedInput-adornedEnd': { paddingRight: 30 },
    '& > fieldset': { border: '1px solid #d3dbe5' },
    '& > .MuiOutlinedInput-input': {
      paddingLeft: 30,
      ...filtersText
    },
    '& .MuiInputAdornment-root svg': { ...filtersText, fontSize: 24 }
  }
});

const Filters = ({ options, selectedValues, onApplyFilter, onSearchChange }) => {
  const classes = useFilterStyles();
  return (
    <>
      <Grid item xs={12} sm={8} className={classes.searchFieldContainer}>
        <OutlinedSearchField
          onChange={onSearchChange}
          variant="outlined"
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ color: '#aaaaaa' }} />
            </InputAdornment>
          }
          fullWidth
          className={classes.searchField}
        />
      </Grid>
      <Grid item xs={12} sm={4} style={{ paddingRight: 18 }}>
        <MultiselectFilter
          values={options}
          selected={selectedValues}
          onApply={onApplyFilter}
          title="Filter by:"
          defaultText="All Products"
          height={56}
        />
      </Grid>
    </>
  );
};

const footerButtonCommon = {
  '&:hover': { background: 'none' },
  textTransform: 'none',
  fontSize: 14
};
const useFooterStyles = makeStyles({
  container: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  selectAllButton: {
    ...footerButtonCommon,
    color: '#434d5c'
  },
  resetButton: {
    ...footerButtonCommon,
    color: '#3577d4'
  }
});

const Footer = ({ busy, onSelectAll, onReset, onContinue }) => {
  const classes = useFooterStyles();
  return (
    <Grid container className={classes.container}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Button
          variant="text"
          onClick={onSelectAll || onReset}
          className={onSelectAll ? classes.selectAllButton : classes.resetButton}
          disabled={busy}
        >
          {onSelectAll ? 'Select All' : 'Deselect All'}
        </Button>
        <Button
          variant="primary"
          size="small"
          loading={busy}
          disabled={busy || !onContinue}
          onClick={() => {
            onContinue();
            gtm.onClickContinue('Product selected');
          }}
          style={{ width: 139 }}
        >
          {busy ? 'Loading...' : 'Continue'}
        </Button>
      </Grid>
    </Grid>
  );
};

const mainAreaSize = 'minmax(276px, 930px)';
const sidebarAreaSize = 'minmax(0, 270px)';
const placeholderSize = 'minmax(24px, 1fr)';
const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplate: `
      ". main sidebar ." minmax(0, auto)
      "footer footer footer footer" 92px / ${placeholderSize} ${mainAreaSize} ${sidebarAreaSize} ${placeholderSize}
    `,
    overflowX: 'hidden'
  },
  navbarOpen: {
    marginLeft: '60px'
  },
  navbarClosed: {
    marginLeft: '0px'
  },
  main: {
    gridArea: 'main',
    display: 'grid',
    gridTemplate: `
      "title" max-content
      "filters" max-content
      "products" minmax(0, auto)
    `,
    paddingRight: 30
  },
  title: {
    gridArea: 'title',
    paddingTop: 40
  },
  titleText: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    color: '#0f2440'
  },
  filters: {
    gridArea: 'filters',
    paddingTop: 32
  },
  products: {
    gridArea: 'products',
    paddingTop: 24,
    '& *::-webkit-scrollbar': {
      backgroundColor: '#e9f0fa',
      width: 4,
      borderRadius: 4
    },
    '& *::-webkit-scrollbar-thumb': {
      backgroundColor: '#d4d9e2',
      width: 10,
      borderRadius: 10
    }
  },
  sidebar: {
    background: '#ffffff',
    gridRow: 'sidebar / span 2',
    gridColumn: 'sidebar / span 2',
    zIndex: 1
  },
  footer: {
    gridArea: 'footer',
    padding: '14px 0',
    background: '#ffffff',
    display: 'grid',
    gridTemplate: `". footer-content . ." / ${placeholderSize} ${mainAreaSize} ${sidebarAreaSize} ${placeholderSize}`,
    position: 'relative',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.12)'
  },
  footerContent: {
    gridArea: 'footer-content',
    paddingRight: 30
  }
});

const isProductAvailable = p => p.enabled;

const SelectProducts = () => {
  const classes = useStyles();
  const [{ response, isApiCallPending, isApiError }, setApiFunc, setContainer] = useInfiniteScroll();
  const products = response?.data.results ?? [];

  const previouslySelected = useSelector(state => state.reorderProducts).map(p => p.product);
  const { selectedProducts, isLoading, handleCheck, handleCheckAll, clearSelection } = useSelectedProducts({
    products,
    previouslySelected,
    isProductAvailable
  });

  const [[query], setQuery] = React.useState([new Map()]);
  const { options, selectedValues, recordType, inventory, handleProductTypeChange } = useInventoryMultifilters(query);

  const [search, setSearch] = React.useState('');
  const debouncedSetSearch = debounce(value => setSearch(value), 750);

  const history = useHistory();
  React.useEffect(() => {
    if (isApiError) toErrorPage(response, history);
  }, [isApiError, response, history]);

  React.useEffect(() => {
    setApiFunc(async limit => {
      try {
        const apiCall = await accountProductsApi.fetch({
          limit,
          visible_in_inventory: true,
          search,
          record_type: recordType,
          inventory,
          ordering: '-created_at'
        });
        return okAndLog('loadAccountProducts', apiCall.status, apiCall);
      } catch (e) {
        return errorAndLog('loadAccountProducts', e.status, e.data);
      }
    });
  }, [recordType, inventory, search, setApiFunc]);

  const handleApplyFilter = e => {
    handleProductTypeChange(e);
    setQuery([query]);
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const handleContinue = async () => {
    await dispatch(createReorderProducts(Array.from(selectedProducts.values())));
    history.push({
      pathname: `/reorder/select-quantities/${Array.from(selectedProducts.keys()).join()}`,
      state: { from: location }
    });
  };

  const inventoryHasSelections = selectedProducts.size > 0 && products.some(item => selectedProducts.has(item.id));

  const { leftBarNavigation } = useFlags();

  const { leftNavOpen } = useSelector(state => state.commonReducer);

  return (
    <div
      className={`${classes.root} ${
        leftBarNavigation ? (leftNavOpen ? classes.navbarOpen : classes.navbarClosed) : {}
      }`}
    >
      <div className={classes.main}>
        <div className={classes.title}>
          <span className={classes.titleText}>What do you want to reorder?</span>
        </div>
        <Grid container justifyContent="space-between" className={classes.filters}>
          <Filters
            options={options}
            selectedValues={selectedValues}
            onApplyFilter={handleApplyFilter}
            onSearchChange={e => debouncedSetSearch(e.target.value)}
          />
        </Grid>
        <div className={classes.products}>
          <InventoryList
            selectedInventory={selectedProducts}
            inventory={products}
            onToggleSelection={handleCheck}
            onContainerRefChange={setContainer}
          />
        </div>
      </div>
      <div className={classes.sidebar}>
        <InventorySelected inventory={selectedProducts} />
      </div>
      <div className={classes.footer}>
        <div className={classes.footerContent}>
          <Footer
            busy={isApiCallPending || isLoading}
            onSelectAll={inventoryHasSelections ? undefined : handleCheckAll}
            onReset={inventoryHasSelections ? clearSelection : undefined}
            onContinue={selectedProducts.size === 0 ? undefined : handleContinue}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectProducts;
