import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import memoize from 'lodash/memoize';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import styles from './styles/ReorderSizesProductionTimeElements';
import { sleep } from '../../helpers/utils';
import toErrorPage from '../../helpers/toErrorPage';
import log from '../../logger';

const getTimeAndMilliSecs = () => {
  const now = new Date();
  const time = now.toLocaleTimeString();
  const millisecs = now.getMilliseconds();
  return { time, millisecs };
};

const memoizeDebounce = (func, wait = 0, options = {}) => {
  const m = memoize(() => debounce(func, wait, options));
  return (param, resolver = param) => m(resolver)(param);
};

const useStyles = makeStyles(styles);

const ReorderSizesProductionTimeElements = props => {
  const { elements, setElementsData } = props;
  const prodTimes = useRef({});
  const sizes = useRef({});
  const inPricingRequest = useRef(false);
  const nextPricingData = useRef();
  const [[toBeRemoved], setToBeRemoved] = useState([new Set()]);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (isEmpty(sizes.current)) {
      log.debug('ReorderSizesProductionTimeElements useEffect, build sizes.current from elements:', elements);
      elements.forEach(element => {
        prodTimes.current[element.id] = element.production_time;
        sizes.current[element.id] = {};
        element.sizes.forEach(size => {
          sizes.current[element.id][size.size.id] = size.quantity;
        });
      });
      log.debug('sizes.current:', sizes.current, 'prodTimes.current:', prodTimes.current);
    }
  }, [elements]);

  const startPricing = (newSizes, newProdTimes) => {
    const { time, millisecs } = getTimeAndMilliSecs();
    log.debug(`dynamicPricing started at: ${time}:${millisecs}`);
    log.debug('elements:', elements, 'newSizes:', newSizes, 'newProdTimes:', newProdTimes);
    inPricingRequest.current = true;
  };

  const endPricing = () => {
    log.debug('dynamicPricing,', elements, 'no longer in pricing');
    inPricingRequest.current = false;
    props.setInPricing(false);
    Array.from(toBeRemoved).forEach(id => {
      delete sizes.current[id];
      delete prodTimes.current[id];
    });
  };

  const dynamicPricing = ({ newSizes, newProdTimes }, retries = 2) => {
    const nextPricing = pricingData => {
      log.debug('dynamicPricing, calling from inside with next pricingData:', pricingData);
      nextPricingData.current = undefined;
      dynamicPricing(pricingData);
    };
    const retryPricing = () => {
      log.error('dynamicPricing !!!! setElementsData response error, retrying .. (', retries - 1, 'left ) !!!');
      sleep(Math.random() * 100);
      dynamicPricing({ newSizes, newProdTimes }, retries - 1);
    };

    startPricing(newSizes, newProdTimes);
    setElementsData(toBeRemoved, newSizes, newProdTimes).then(({ response, action }) => {
      log.debug('dynamicPricing after API calls:', response, 'nextPricingData:', nextPricingData.current);
      if (response.result === 'ok') {
        log.debug('dynamicPricing success, called with elements, newSizes, newProdTimes:', newSizes, newProdTimes);
        if (action) {
          action();
        }
      } else if (!nextPricingData.current) {
        if (retries === 0) {
          toErrorPage(response, history);
        } else {
          retryPricing();
        }
      }
      const pricingData = nextPricingData.current;
      if (pricingData) {
        nextPricing(pricingData);
      } else {
        endPricing();
      }
    });
  };

  const debouncedDynamicPricing = useCallback(memoizeDebounce(dynamicPricing, 750), [setElementsData, toBeRemoved]);

  const handleDynamicPricing = () => {
    const pricingData = {
      newSizes: sizes.current,
      newProdTimes: prodTimes.current
    };
    log.debug('new pricingData:', pricingData, ', in pricing:', inPricingRequest.current);
    if (!inPricingRequest.current) {
      props.setInPricing(true);
      const { time, millisecs } = getTimeAndMilliSecs();
      log.debug(`debouncedDynamicPricing called at: ${time}:${millisecs}`);
      debouncedDynamicPricing(pricingData, elements);
    } else {
      nextPricingData.current = pricingData;
      log.debug('setting nextPricingData:', nextPricingData.current);
    }
  };

  const handleChangeProdTime = (element, prodTime) => {
    prodTimes.current[element.id] = prodTime;
    handleDynamicPricing();
  };

  const handleChangeSize = (qty, size, element) => {
    log.debug('handleChangeSize args, qty:', qty, 'size:', size, 'element:', element);
    const elemSizes = sizes.current[element.id];
    log.debug(`from: ${elemSizes[size.size.id]} to: ${qty}, size.quantity: ${size.quantity}`, 'sizes:', sizes.current);
    elemSizes[size.size.id] = qty;
    handleDynamicPricing();
  };

  const handleRemoveElement = async id => {
    setToBeRemoved([toBeRemoved.add(id)]);
    handleDynamicPricing();
  };

  const elementsToShow = elements.filter(e => !toBeRemoved.has(e.id));

  return (
    <Grid container spacing={0} style={{ marginTop: 20 }}>
      {elementsToShow.map(element => {
        const hasItems = element.product.items.length > 0;
        return (
          <Grid key={element.id} item xs={12} className={classes.ProofConfirmationCard}>
            <Grid container>
              {props.renderImageSizesSection(
                element,
                handleChangeSize,
                handleRemoveElement,
                hasItems && props.toggleElementDetails,
                classes
              )}
              {props.renderPriceSection(element, handleChangeProdTime, classes)}
            </Grid>
            {hasItems && props.renderDetailsSection(element, props.removeBagSubItem)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ReorderSizesProductionTimeElements;
