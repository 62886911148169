const styles = theme => ({
  container: {
    padding: '70px 0 70px 0',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      paddingLeft: '0px',
      minWidth: '1280px'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1120px',
      paddingLeft: '0px',
      minWidth: '1120px'
    },
    [theme.breakpoints.down(1025)]: {
      maxWidth: '1024px',
      minWidth: '1024px',
      paddingLeft: '24px !important',
      paddingRight: 24
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
      paddingRight: '24px !important',
      paddingLeft: '24px !important',
      minWidth: '10vw'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      paddingRight: '24px',
      paddingLeft: '24px',
      minWidth: '100%'
    }
  },
  title: {
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.25rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginBottom: 60
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    '& > img': { objectFit: 'contain' }
  }
});

export default styles;
