import commonStyles from './commonStyles';

export default theme => ({
  ...commonStyles,
  container: {
    paddingBottom: 42,
    minHeight: 'calc(100vh - 48px)',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0
    }
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    color: '#0f2440',
    marginBottom: 30
  },
  sendButton: {
    '&:hover': { backgroundColor: '#3577d4' },
    textTransform: 'none',
    width: 150,
    height: 56,
    backgroundColor: '#3577d4',
    borderRadius: 32,
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 16,
    color: '#ffffff',
    lineHeight: 'normal',
    '&.Mui-disabled': { backgroundColor: '#ecedef !important' }
  },
  stickyBar: {
    padding: '14px 0',
    width: '100%',
    position: 'fixed',
    bottom: '0',
    backgroundColor: '#fff'
  },
  errorAlert: {
    top: 124
  }
});
