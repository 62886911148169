import dayjs from 'dayjs';
import { SEND_SWAG_EXECUTION } from '../actions/types';

const ProcessSendSwagReducer = (state = { processing: false, time: dayjs() }, action) => {
  switch (action.type) {
    case SEND_SWAG_EXECUTION:
      return action.payload;
    default:
      return state;
  }
};

export default ProcessSendSwagReducer;
