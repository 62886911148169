import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = () => ({
  packImg: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: 78,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    left: 0
  },
  imgWrapper: {
    width: 78,
    height: 78,
    position: 'relative'
  },
  itemTitle: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    color: '#0f2440',
    marginBottom: 4,
    maxWidth: 280
  },
  itemColor: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#8d9299',
    marginBottom: 8
  },
  itemColorValue: {
    color: '#434d5c'
  },
  itemPriceText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 12,
    color: '#3577d4'
  },
  tableLinkText: {
    fontSize: 12,
    color: '#3577d4',
    cursor: 'pointer',
    border: 0,
    margin: 0,
    padding: 0,
    fontFamily: 'inherit',
    background: 'transparent'
  },
  qtyPerPackText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 10,
    color: '#0f2440',
    marginBottom: 10
  }
});
export default styles;
