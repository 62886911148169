const styles = theme => ({
  brands: {
    backgroundColor: '#fafafa',
    padding: '96px 0',
    maxWidth: 1280,
    margin: 'auto',
    [theme.breakpoints.only('lg')]: {
      maxWidth: 1120
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: 960
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: 640
    },
    [theme.breakpoints.only('xs')]: {
      padding: '20px 0'
    }
  },
  stepBlog: {
    textAlign: 'center'
  },
  stepHeader: {
    textAlign: 'center',
    color: '#3577d4',
    lineHeight: '48px'
  },
  stepDescription: {
    textAlign: 'center'
  },
  brandsHeader: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
    color: '#3577d4',
    textTransform: 'uppercase',
    marginBottom: 16,
    marginTop: 40,
    width: '100%'
  },
  logosContainer: {
    maxWidth: 640,
    margin: 'auto',
    [theme.breakpoints.only('xs')]: {
      maxWidth: 320
    }
  },
  logos: {
    objectContain: 'cover',
    width: 640,
    [theme.breakpoints.only('xs')]: {
      width: 320
    }
  },
  mt18: {
    marginTop: 18
  }
});

export default styles;
