import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, FormControlLabel, Grid, MenuItem, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { isEmpty, isNumber } from 'lodash';
import styles from './styles/sendSwagConfiguration';
import { SmallSwitch } from '../../pages/orders/requested/shipments/sendSwagFlow/common';
import { CustomSlimTextField } from '../../storefronts/storeCommon';
import { reverseTimingCoverter } from './common';
import { IntegrationCustomSelectField, IntegrationSlimTextField } from './commonComponents';

const useStyles = makeStyles(styles);

const TimingType = ({ timingType, selected, handleChange }) => {
  const classes = useStyles();

  return (
    <Box style={{ padding: '20px 36px', border: '1px solid #D6D8DB', borderRadius: 12 }}>
      <FormControlLabel
        labelPlacement="end"
        control={
          <SmallSwitch
            checked={selected}
            onChange={() => handleChange(timingType.type)}
            color="primary"
            name="checkedBtn"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
        label={
          <Box className={classes.shippingMethodLabel}>
            <Typography variant="h6SemiBoldInter">{timingType.title}</Typography>
            <Typography variant="body3RegularInter">{timingType.description}</Typography>
          </Box>
        }
      />
    </Box>
  );
};

const timingtype = [
  { id: 1, title: 'Event date', type: 'Exact', description: 'Exectutes at the extact date' },
  { id: 2, title: 'Custom', type: 'Custom', description: 'Exectutes at a previous or later date' }
];

const timeMeasures = [
  { id: 1, title: 'Days', value: 'days' },
  { id: 2, title: 'Hours', value: 'hours' }
];

const timings = [
  { id: 1, title: 'Before event', value: 'before' },
  { id: 2, title: 'After event', value: 'after' }
];

const ActionConfigurationTiming = ({ action, automation, onActionUpdate }) => {
  const selectedTimingType =
    action.settings?.timing === undefined || action.settings?.timing === 0 ? 'Exact' : 'Custom';
  const [inputNumber, setInputNumer] = useState('inputNumber');
  const [inputTimeMeasure, setInputTimeMeasure] = useState('inputTimeMeasure');
  const [inputTiming, setInputTiming] = useState('inputTiming');
  const [error, setError] = useState();

  const handleOnChange = event => {
    const {
      target: { name, value }
    } = event;

    if (name === 'inputNumber') {
      const intNumber = Number(parseInt(value, 10));
      if (!isEmpty(value) && isNumber(intNumber) && !isNaN(intNumber)) {
        setInputNumer(intNumber);
        setError();
        onActionUpdate({
          ...action,
          settings: {
            ...action.settings,
            error: false
          }
        });
      } else {
        setInputNumer(value);
        setError('Incorrect value');
        onActionUpdate({
          ...action,
          settings: {
            ...action.settings,
            error: true
          }
        });
      }
    }

    if (name === 'inputTimeMeasure') setInputTimeMeasure(value);

    if (name === 'inputTiming') setInputTiming(value);
  };

  useEffect(() => {
    const factor = inputTiming === 'before' ? -1 : 1;
    const rate = inputTimeMeasure === 'days' ? 24 : 1;
    const timing = inputNumber * rate * factor;

    onActionUpdate({
      ...action,
      settings: {
        ...action.settings,
        timing
      }
    });
  }, [inputNumber, inputTimeMeasure, inputTiming]);

  const currentTimingRaw = action.settings?.timing;

  const currentTimingRetriever = useCallback(() => currentTimingRaw, [currentTimingRaw]);

  useEffect(() => {
    const currentTiming = currentTimingRetriever();
    const timingData = reverseTimingCoverter(currentTiming);

    if (inputTimeMeasure === 'inputTimeMeasure') setInputTimeMeasure(timingData.inputTimeMeasure);

    if (inputTiming === 'inputTiming') setInputTiming(timingData.inputTiming);

    if (inputNumber === 'inputNumber') setInputNumer(timingData.inputNumber);
  }, [currentTimingRetriever, inputTimeMeasure, inputTiming, inputNumber]);

  const classes = useStyles();

  return (
    <Box>
      <Box>
        <Typography variant="h5SemiBoldInter" className={classes.actionTitle} style={{ marginBottom: 24 }}>
          Automation Settings
        </Typography>
        <Typography variant="h6SemiBoldInter" className={classes.actionSubTitle} style={{ marginBottom: 12 }}>
          Adjust the timing
        </Typography>
        <Typography
          variant="subtitle3RegularInter"
          className={classes.actionSubTitleDesc}
          style={{ margin: '0px 6% 24px 6%' }}
        >
          This event is triggered by <span>{automation?.name}</span> in your system. By default this automation will be
          triggered on the date of the event. Would you like to change it?
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Grid container spacing={6} justifyContent="space-between">
              {timingtype.map(timing => (
                <Grid key={timing.id} item xs={6}>
                  <TimingType
                    key={timing.id}
                    timingType={timing}
                    selected={selectedTimingType === timing.type}
                    handleChange={t => {
                      setError();
                      if (t === 'Exact') setInputNumer(0);
                      else {
                        setInputTimeMeasure('days');
                        setInputTiming('before');
                        setInputNumer(1);
                        if (action.settings.error) {
                          onActionUpdate({
                            ...action,
                            settings: {
                              ...action.settings,
                              error: false
                            }
                          });
                        }
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {selectedTimingType === 'Custom' && (
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <Box className={classes.customTimingSettings}>
                <Typography variant="subtitle3SemiBoldInter" style={{ marginBottom: 12 }}>
                  Customize trigger
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Typography variant="body4RegularInter" className={classes.inputLabel}>
                      Number of
                    </Typography>
                    <IntegrationSlimTextField
                      placeholder="Number of"
                      name="inputNumber"
                      type="number"
                      onChange={handleOnChange}
                      value={inputNumber}
                      error={error}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body4RegularInter" className={classes.inputLabel}>
                      Days or hours
                    </Typography>
                    <IntegrationCustomSelectField
                      id="inputTimeMeasure"
                      name="inputTimeMeasure"
                      value={inputTimeMeasure}
                      className={classes.selectField}
                      totalItems={timeMeasures.length}
                      handleSelect={handleOnChange}
                      fullWidth
                      withTooltip
                    >
                      {timeMeasures.map(tm => (
                        <MenuItem key={tm.id} value={tm.value} title={tm.title}>
                          <Typography
                            variant="body4RegularInter"
                            className={classes.inputLabel}
                            style={{ margin: '1px 0px 0px 0px' }}
                          >
                            {tm.title}
                          </Typography>
                        </MenuItem>
                      ))}
                    </IntegrationCustomSelectField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body4RegularInter" className={classes.inputLabel}>
                      Before of after the event date
                    </Typography>
                    <IntegrationCustomSelectField
                      id="inputTiming"
                      name="inputTiming"
                      value={inputTiming}
                      className={classes.selectField}
                      totalItems={timings.length}
                      handleSelect={handleOnChange}
                      fullWidth
                      withTooltip
                    >
                      {timings.map(t => (
                        <MenuItem key={t.id} value={t.value} title={t.title}>
                          <Typography
                            variant="body4RegularInter"
                            className={classes.inputLabel}
                            style={{ margin: '1px 0px 0px 0px' }}
                          >
                            {t.title}
                          </Typography>
                        </MenuItem>
                      ))}
                    </IntegrationCustomSelectField>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ActionConfigurationTiming;
