import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Img } from '../../../global/ImgUtils';
import { Link } from '../../../shared';
import gtm from '../../../../utils/gtm';

const subheaderCommon = {
  fontFamily: 'Gilroy-Medium',
  fontSize: 14,
  letterSpacing: 'normal',
  lineHeight: 1.57
};

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    minHeight: 378
  },
  infoContainer: {
    flex: 1,
    paddingTop: 24,
    whiteSpace: 'pre-line'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 14,
    color: '#3577d4',
    letterSpacing: 1.4,
    lineHeight: 1.43,
    marginBottom: 16
  },
  header: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    color: '#0b1829',
    letterSpacing: 'normal',
    lineHeight: 1.33,
    marginBottom: 8
  },
  subheader: {
    ...subheaderCommon,
    color: '#787b80',
    marginBottom: 16
  },
  subheaderList: {
    ...subheaderCommon,
    color: '#0b1829',
    paddingLeft: 16
  },
  button: {
    fontSize: 14,
    height: 48,
    marginTop: 32
  },
  image: {
    [theme.breakpoints.down('md')]: {
      width: 275,
      height: 234
    }
  }
}));

const Slide = ({ data }) => {
  const classes = useStyles();

  const handleClick = () => gtm.onClick(data.link.text);

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid item className={classes.infoContainer}>
        <Box component={Img} sx={{ display: { sm: 'none' } }} src={data.image} width={275} height={234} />
        <p className={classes.title}>{data.title}</p>
        <p className={classes.header}>{data.header}</p>
        <p className={classes.subheader}>{data.subheader}</p>
        {data.subheaderList && (
          <ul className={classes.subheaderList}>
            {data.subheaderList.map(item => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        )}
        {data.link && (
          <Button id={data.id} component={Link} to={data.link.to} onClick={handleClick} className={classes.button}>
            {data.link.text}
          </Button>
        )}
      </Grid>
      <Box
        component={Img}
        sx={{ display: { xs: 'none', sm: 'unset' } }}
        src={data.image}
        width={424}
        height={360}
        className={classes.image}
      />
    </Grid>
  );
};

export default Slide;
