import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import round from 'lodash/round';
import { Button } from '@swagup-com/components';
import { Divider, Grid, makeStyles, withStyles } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';
import RemoveProductModal from '../summary/RemoveProductModal';
import { useProduct } from './ProductContext';
import { isPack, moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import { Img } from '../../../../global/ImgUtils';
import { productImageBasedOnStatus } from '../../../../global/proofsCommon';
import Drawer from './Drawer';
import ProductionConfiguration from '../summary/ProductionConfiguration';
import api from '../../../../../apis/swagup/proofs';
import { ellipsisStyles, greyRoundBorder } from '../../../../shared/styles/commonStyles';
import styles from './ProductBreakdown.styles';
import { cancellableQuery } from './utilsOrder';
import apiPaths from '../../../../../helpers/apiPaths';

const useStyles = makeStyles(styles);

const Skeleton = withStyles(styles)(({ classes, style }) => (
  <MuiSkeleton variant="text" className={classes.skeleton} style={style} />
));

const ItemDetails = ({ isLoading, item, priceInfo, headerItem }) => {
  const classes = useStyles();
  const itemIsPack = isPack(item.product.record_type);
  const price = priceInfo?.price ?? 0;

  return (
    <Grid item container alignItems="center" role="row" style={{ height: 48 }}>
      <Grid item container xs={headerItem && itemIsPack ? 12 : 8} style={{ flexWrap: 'nowrap' }}>
        <Img
          src={productImageBasedOnStatus(item.product, 78, 78)}
          alt={item.product.name}
          width={48}
          height={48}
          style={{ ...greyRoundBorder }}
        />
        <Grid item container direction="column" justifyContent="space-around" xs className={classes.productDescription}>
          <p className={classes.hightlightedText} style={{ ...ellipsisStyles, width: '100%' }}>
            {item.product.name}
          </p>
          <p className={classes.unhighlightedText}>
            {!headerItem ? (
              <>
                Color:{' '}
                <span style={{ color: headerItem ? '#787b80' : '#0b1829' }}>
                  {item.product.theme_color ?? 'Custom'}
                </span>
              </>
            ) : (
              !itemIsPack && (
                <>
                  Qty: <span>{isLoading ? <Skeleton /> : sumByQuantity(priceInfo?.sizes || [])}</span>
                </>
              )
            )}
          </p>
        </Grid>
      </Grid>
      {!headerItem && (
        <>
          <Grid item className={classes.quantityColumn}>
            <p className={classes.hightlightedText}>{item.units_per_pack}</p>
          </Grid>
          <Grid item className={classes.priceColumn}>
            <p className={classes.hightlightedText}>{isLoading ? <Skeleton /> : moneyStr(price)}</p>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={headerItem ? true : undefined}
        className={!headerItem ? classes.totalColumn : undefined}
        container
        direction="column"
        alignItems="flex-end"
      >
        {headerItem && !itemIsPack && (
          <>
            <p className={classes.unhighlightedText}>Price/Unit</p>
            <p className={classes.headerPrice} style={{ marginTop: 3 }}>
              {isLoading ? <Skeleton /> : moneyStr(price)}
            </p>
          </>
        )}
        {!headerItem && (
          <p className={classes.hightlightedText} style={{ fontSize: 14 }}>
            {isLoading ? <Skeleton /> : moneyStr(round(item.units_per_pack * price, 2))}
          </p>
        )}
      </Grid>
    </Grid>
  );
};

const ProductBreakdown = ({ open, onClose }) => {
  const { updatedProduct: product, updateProduct, product: defaultProduct, restProducts } = useProduct();
  const productIsPack = isPack(product.product.record_type);
  const classes = useStyles({ isPack: productIsPack });
  const [isRemoveModalOpen, toggleIsRemoveModalOpen] = React.useReducer(prevOpen => !prevOpen, false);

  const queryClient = useQueryClient();
  const { data: priceInfo, isLoading } = useQuery(
    [apiPaths.proofPrices, [product, ...restProducts]],
    () => {
      queryClient.cancelQueries(apiPaths.proofPrices);
      return cancellableQuery(api.fetchPrices)([product, ...restProducts]);
    },
    { enabled: open }
  );

  const handleCancelChanges = () => {
    updateProduct(defaultProduct);
    onClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleCancelChanges} classes={{ paper: classes.drawer }}>
      <h2 className={classes.title}>Product breakdown</h2>

      <Grid container justifyContent="space-between" style={{ paddingTop: 20, height: '100%', minHeight: 800 }}>
        <Grid item xs={7}>
          <Grid container justifyContent="space-between">
            <p className={classes.hightlightedText}>
              Products {product && isPack(product.product.record_type) && 'inside the Pack'}
            </p>
            <Button variant="text" size="small" className={classes.removeButton} onClick={toggleIsRemoveModalOpen}>
              Remove product
              <RemoveCircleOutline style={{ fontSize: 14, marginLeft: 4 }} />
            </Button>
          </Grid>

          <Grid container direction="column" className={classes.productListContainer}>
            <Grid item style={{ padding: 24 }}>
              <ItemDetails isLoading={isLoading} item={product} priceInfo={priceInfo} headerItem />
            </Grid>

            {productIsPack && (
              <>
                <Grid container className={classes.headerRow}>
                  <Grid item xs={8}>
                    <p className={classes.headerText} style={{ textAlign: 'left' }}>
                      ITEM
                    </p>
                  </Grid>
                  <Grid item className={classes.quantityColumn}>
                    <p className={classes.headerText}>QTY/PACK</p>
                  </Grid>
                  <Grid item className={classes.priceColumn}>
                    <p className={classes.headerText}>PRICE/UNIT</p>
                  </Grid>
                  <Grid item className={classes.totalColumn}>
                    <p className={classes.headerText}>TOTAL</p>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} style={{ marginTop: 15 }} />
                <Grid item container xs className={classes.productList}>
                  <Grid container direction="column" style={{ marginBottom: 24 }}>
                    {product.product.items.map(item => (
                      <Grid key={item.product.id} item style={{ marginTop: 22 }}>
                        <ItemDetails
                          isLoading={isLoading}
                          item={item}
                          priceInfo={priceInfo?.items?.find(i => i.product === item.product.id)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />

                <Grid item container justifyContent="flex-end" alignItems="center" style={{ padding: 24 }}>
                  <p className={classes.unhighlightedText} style={{ fontSize: 14 }}>
                    Price/Pack
                  </p>
                  <p className={classes.pricePerPack}>
                    {isLoading ? <Skeleton style={{ width: 58 }} /> : moneyStr(priceInfo?.price)}
                  </p>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item style={{ width: 330 }}>
          <ProductionConfiguration canRush={product.product.rush_production} onClose={onClose} />
        </Grid>
      </Grid>
      {open && <RemoveProductModal isOpen={isRemoveModalOpen} onClose={toggleIsRemoveModalOpen} />}
    </Drawer>
  );
};

export default ProductBreakdown;
