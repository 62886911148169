import React, { useState } from 'react';
import { Grid, withStyles, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import isEmpty from 'lodash/isEmpty';
import styles from './styles/MultishippingCardOverview';
import { ImgWithHandler } from '../global/ImgUtils';
import { sizesWithQuantities } from './MultishippingCommon';
import { sumByQuantity, getSelected, getEmployeeShipAddress } from '../../helpers/utils';

export const AddedItem = ({ classes, product, toWarehouse }) => (
  <Grid item xs={3}>
    <div className={classes.SmallItem}>
      <div className={classes.SmallItemImg}>
        <ImgWithHandler src={product.image} alt={product.name} width={40} height={40} />
      </div>
      <div className={classes.AmountToShip}>x{toWarehouse ? product.quantity : sumByQuantity(product.sizes)}</div>
    </div>
  </Grid>
);

export const ItemsDetailsList = ({ classes, products, toWarehouse }) =>
  products.map(product => (
    <div key={product.id} className={classes.ItemContainer}>
      <Grid container>
        <Grid item xs={12} sm={2} md={2}>
          <ImgWithHandler src={product.image} alt={product.name} width={78} height={78} />
        </Grid>
        <Grid item xs={7}>
          <p className={classes.ItemDescription}>{product.name}</p>
          <Grid container>
            <Grid item xs={4}>
              <p className={classes.ItemSpecs}>Color: {product.color} </p>
              <p className={classes.ItemSpecs}>Size: {sizesWithQuantities(product.sizes)}</p>
            </Grid>
            <Grid item xs={8}>
              <p className={classes.ItemSpecs}>Qty: {toWarehouse ? product.quantity : sumByQuantity(product.sizes)}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.AmountToShip}>x{toWarehouse ? product.quantity : sumByQuantity(product.sizes)}</div>
        </Grid>
      </Grid>
    </div>
  ));

const MultiShippingCardOverview = ({ classes, shippingElement }) => {
  const [open, setOpen] = useState(false);
  const toggleOpenDropDown = () => setOpen(isOpen => !isOpen);
  const { employee, products, deliveryMethods } = shippingElement;
  const selectedMethod = getSelected(deliveryMethods);
  const employeeToShipStr = !isEmpty(employee) ? getEmployeeShipAddress(employee, true) : 'Select address';

  return (
    <div className={classes.MultiShippingCardOverview}>
      <div className={open ? classes.CardHeaderBorder : classes.CardHeader}>
        <Grid container>
          <Grid item xs={9}>
            <p className={classes.HeaderTitle}>Address</p>
            <p className={classes.HeaderContent}>{employeeToShipStr}</p>
          </Grid>
          <Grid item xs={2}>
            <p className={classes.HeaderTitle}>Delivery</p>
            <p className={classes.HeaderContent}>{selectedMethod.name}</p>
          </Grid>
          <Grid item xs={1} align="right">
            <IconButton className={classes.button} aria-label="DropDown" onClick={toggleOpenDropDown}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div className={classes.CardContent}>
        {open ? (
          <ItemsDetailsList classes={classes} products={products} />
        ) : (
          <div className={classes.ColapseContentContainer}>
            <Grid container spacing={1}>
              {products?.map(p => (
                <AddedItem key={p.id} classes={classes} product={p} />
              ))}
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(MultiShippingCardOverview);
