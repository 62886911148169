import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import styles from './styles/userInfo';

const UserInfo = ({ userInfo, classes }) => (
  <Box maxWidth="350px" mb="40px" className={classes.container}>
    <h1 className={classes.thankYou}>Thank you!</h1>
    <p className={classes.advice}>Your SwagUp sample pack is on its way to the following address:</p>
    <div className={classes.contact}>
      <p className={classes.name}>{userInfo.name}</p>
      <p className={classes.email}>{userInfo.email}</p>
      <p className={classes.address}>{userInfo.address}</p>
    </div>
  </Box>
);

export default withStyles(styles)(UserInfo);
