import React, { useEffect, useState } from 'react';
import { Grid, Drawer, List, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Scrollable from 'react-scrollbars-custom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import CategoriesSidebarItem from './CategoriesSidebarItem';
import { useHeaderBottomTracker } from '../../../hooks';
import styles from './CategoriesSidebar.styles';
import CollectionButton from './CollectionSidebar';

const useStyles = makeStyles(styles);

const CategoriesSidebar = ({ catalog, collections, handelShowCollection, activeCategoryId, onSelectedCategory }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { catalogCollections } = useFlags();
  const [openCategories, setOpenCategories] = useState(true);

  const handleCategoriesDropdown = () => {
    setOpenCategories(!openCategories);
  };

  const [open, setOpen] = useState(false);
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const top = useHeaderBottomTracker();

  useEffect(() => {
    setOpen(!isXs);
  }, [isXs]);

  const categoriesList = (
    <List className={classes.list}>
      {catalog.map((category, idx) => (
        <CategoriesSidebarItem
          key={category.id}
          category={category}
          active={category.id === activeCategoryId}
          onClick={() => onSelectedCategory(idx)}
        />
      ))}
    </List>
  );

  return (
    <Drawer
      anchor="left"
      open={open}
      variant="persistent"
      classes={{ paper: classes.root }}
      ModalProps={{
        keepMounted: true
      }}
      PaperProps={{ style: { top } }}
    >
      <Scrollable>
        {catalogCollections && (
          <CollectionButton collectionItems={collections} handelShowCollection={handelShowCollection} />
        )}
        {catalogCollections ? (
          <>
            <Grid container onClick={handleCategoriesDropdown} className={classes.header}>
              <Typography variant="body2SemiBoldInter">Categories</Typography>
              <Grid style={{ marginRight: 18, position: 'relative', top: '4px' }}>
                {openCategories ? (
                  <KeyboardArrowUpIcon style={{ color: '#4A4F54' }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ color: '#4A4F54' }} />
                )}
              </Grid>
            </Grid>
            <Collapse in={openCategories}>{categoriesList}</Collapse>
          </>
        ) : (
          <>
            <Typography variant="body3SemiBoldInter" className={classes.header}>
              Categories
            </Typography>
            {categoriesList}
          </>
        )}
      </Scrollable>
    </Drawer>
  );
};

export default React.memo(CategoriesSidebar);
