import React, { useReducer } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { StylessButton } from '../buttons';
import InventoryPackItem from './InventoryPackItem';
import {
  ProductImage,
  productImageTitle,
  ProductNameInput,
  ProductColor,
  ProductQuantities,
  SendSwagButton,
  useReorderLink,
  PayStorageButton
} from './InventoryCommon';
import { isPack } from '../../helpers/utils';
import { ArrowIcon } from '../icons/inventory';

const useStyles = makeStyles(theme => ({
  restock: {
    fontFamily: 'Inter',
    padding: 0,
    margin: 0,
    lineHeight: 'normal',
    color: '#3577d4',
    fontSize: 12
  },
  storageTitle: {
    lineHeight: '32px',
    height: '32px'
  },
  storageContent: {
    textAlign: 'right'
  },
  dataContainer: {
    border: 'solid 1px #ebeef2',
    borderRadius: 15,
    backgroundColor: '#ffffff',
    padding: '16px 0px',
    marginBottom: 20,
    position: 'relative'
  },
  productContainer: {
    [theme.breakpoints.down('xs')]: { justifyContent: 'center' }
  },
  imgColumn: {
    width: 'auto',
    marginTop: 0,
    marginLeft: 20,
    marginBottom: 20,
    marginRight: 15,
    borderRadius: 10,
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: { padding: 0 }
  },
  packColumn: {
    paddingLeft: 24,
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  bulkColumn: {
    paddingLeft: 24,
    marginTop: 0
  },
  quantitiesColumn: {
    marginTop: 0,
    marginBottom: 0
  },
  sendSwagContainer: {
    paddingRight: 16,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0 !important',
      paddingBottom: 20,
      alignItems: 'center'
    }
  },
  sendSwagBtn: {
    width: 142,
    height: 42,
    padding: '0px 12px'
  }
}));

const CallPackDetails = ({ classes, showDetails, onToggleShow }) => (
  <Grid item xs={12}>
    <Grid container>
      <Grid item xs={12} style={{ cursor: 'pointer' }} role="button" onClick={onToggleShow}>
        <Grid container spacing={2}>
          <Grid item id="left">
            <StylessButton height="100%">
              <Typography className={classes.restock}>{showDetails ? 'Collapse' : 'See details'}</Typography>
            </StylessButton>
          </Grid>
          <Grid item id="right">
            <StylessButton>
              <ArrowIcon open={showDetails} />
            </StylessButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const PackDetails = ({ item }) => (
  <Grid item xs={12}>
    <Grid container>
      <Grid container item xs={12} style={{ padding: '9px 0px 17px 47px' }}>
        {item.items.map(i => (
          <InventoryPackItem key={i.product.id} item={i} />
        ))}
      </Grid>
    </Grid>
  </Grid>
);

const StoragePriceItem = ({ title, content, classes }) => (
  <Grid item xs={12}>
    <Grid container justifyContent="center">
      <Grid item xs>
        <Typography component="p" variant="body5RegularInter" className={classes.storagetTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography component="p" variant="body4RegularInter" className={classes.storageContent}>
          {content}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

const InventoryListItem = ({ item, onSelect, selected }) => {
  const [showDetails, toggleShowDetails] = useReducer(prev => !prev, false);

  const classes = useStyles();

  const reorderLink = useReorderLink([item.id]);

  return (
    <Grid container className={classes.dataContainer} style={{ border: selected ? '1px solid #3577D4' : undefined }}>
      <Grid container item xs={10} onClick={onSelect} style={{ cursor: 'pointer' }}>
        <Grid container item xs={12} sm={12} lg={4} className={classes.productContainer}>
          <Grid container justifyContent="center" alignItems="center" item className={classes.imgColumn}>
            <ProductImage
              linkTo={reorderLink}
              item={item}
              name={item.name}
              imgSrc={item.image}
              title={productImageTitle(item.enabled)}
              width={102}
              height={102}
            />
          </Grid>
          <Grid item xs={12} className={isPack(item.record_type) ? classes.packColumn : classes.bulkColumn}>
            <ProductNameInput item={item} />
            <Grid style={{ marginTop: 12, marginLeft: 2 }}>
              <ProductColor item={item} />
            </Grid>
            {isPack(item.record_type) && (
              <Grid style={{ marginTop: 16 }}>
                <CallPackDetails
                  item={item}
                  classes={classes}
                  showDetails={showDetails}
                  onToggleShow={toggleShowDetails}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6} alignContent="center">
          <ProductQuantities item={item} list />
        </Grid>
        <Grid container item xs={12} lg={2} alignContent="center" style={{ paddingRight: '24px' }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body3RegularInter" style={{ marginBottom: '8px' }}>
                Storage Cost
              </Typography>
            </Grid>
            <StoragePriceItem title="Storage Quantity:" content={item.totalQuantity} classes={classes} />
            <StoragePriceItem title="Storage Price:" content={`$${item.storagePrice}`} classes={classes} />
            <StoragePriceItem title="Storage Cost:" content={`$${item.storageCost}`} classes={classes} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item className={classes.sendSwagContainer} xs>
        <SendSwagButton item={item} className={classes.sendSwagBtn} />
        <Box style={{ paddingTop: '8px' }}>
          <PayStorageButton item={item} className={classes.sendSwagBtn} />
        </Box>
      </Grid>
      {isPack(item.record_type) && showDetails && <PackDetails item={item} />}
    </Grid>
  );
};

export default InventoryListItem;
