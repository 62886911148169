const styles = theme => ({
  center: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      minWidth: '1280px',
      paddingLeft: '0 !important'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1120px',
      minWidth: '1120px',
      paddingLeft: '0 !important'
    }
  },
  noMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  },
  onMobile: {
    display: 'none !important',
    [theme.breakpoints.down('sm')]: {
      display: 'block !important'
    }
  },
  ovalContainer: {
    position: 'relative',
    width: '100%'
  },
  sectionBackground: {
    width: '100%',
    height: 0,
    opacity: 0.25,
    backgroundColor: '#0f2440',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  sectionBackgroundMobile: {
    width: '100%',
    height: 0,
    opacity: 0.25,
    backgroundColor: '#0f2440',
    position: 'absolute',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  figureBackground: {
    width: 300,
    height: 150,
    borderRadius: '0px 0px ',
    opacity: 0.05,
    backgroundColor: '#003399',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  figureBackgroundMobile: {
    width: 150,
    height: 74,
    borderRadius: '0px 0px ',
    opacity: 0.05,
    backgroundColor: '#003399',
    position: 'absolute',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  ovalPurpleTop: {
    width: 402,
    height: 402,
    borderRadius: 201,
    opacity: 0.25,
    backgroundColor: '#9846dd',
    position: 'absolute',
    top: -301,
    left: -72
  },
  ovalPurpleCustom: {
    width: 402,
    height: 402,
    borderRadius: 201,
    opacity: 0.25,
    backgroundColor: '#9846dd',
    position: 'absolute'
  },
  ovalBlueTop: {
    width: 256,
    height: 256,
    borderRadius: 201,
    backgroundBlendMode: 'soft-light',
    backgroundImage: 'linear-gradient(to bottom, #003399, #003399)',
    opacity: 0.25,
    position: 'absolute',
    top: -188,
    left: 194
  },
  ovalBlueCustom: {
    width: 256,
    height: 256,
    borderRadius: 201,
    position: 'absolute',
    backgroundColor: '#000033',
    opacity: 0.25
  },
  ovalBlueDeep206: {
    width: 206,
    height: 206,
    borderRadius: 103,
    position: 'absolute',
    backgroundColor: '#000033',
    opacity: 0.25
  },
  ovalPrimary: {
    width: 500,
    height: 500,
    opacity: 0.25,
    borderRadius: 250,
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  ovalPrimaryMobile: {
    width: 500,
    height: 500,
    opacity: 0.25,
    borderRadius: 250,
    position: 'absolute',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  ovalBlueEmptyTop: {
    width: 500,
    height: 500,
    opacity: 0.25,
    borderRadius: 250,
    border: 'solid 32px #134283',
    position: 'absolute',
    top: -230,
    right: -240
  },
  ovalBlueEmpty: {
    width: 340,
    height: 340,
    opacity: 0.25,
    borderRadius: 250,
    border: 'solid 32px #134283',
    position: 'absolute'
  },
  ovalPurpleOutlined360: {
    width: 360,
    height: 360,
    opacity: 0.25,
    borderRadius: 180,
    border: 'solid 32px #9846dd',
    position: 'absolute'
  },
  ovalBlueSixtySix: {
    width: 66,
    height: 66,
    borderRadius: 33,
    backgroundColor: '#134283',
    opacity: 0.25,
    position: 'absolute'
  },
  ovalBlue130: {
    width: 130,
    height: 130,
    borderRadius: 75,
    backgroundColor: '#134283',
    opacity: 0.25,
    position: 'absolute'
  },
  ovalBlue104: {
    width: 104,
    height: 104,
    borderRadius: 52,
    backgroundColor: '#134283',
    opacity: 0.25,
    position: 'absolute'
  },
  ovalBlue206: {
    width: 206,
    height: 206,
    borderRadius: 103,
    backgroundColor: '#134283',
    opacity: 0.25,
    position: 'absolute'
  },
  ovalBlueTitle: {
    width: 140,
    height: 140,
    borderRadius: 70,
    backgroundColor: '#000033',
    opacity: 0.55,
    position: 'absolute',
    top: 156,
    right: 44
  },
  ovalBlueAdapter: {
    width: 66,
    height: 66,
    borderRadius: 33,
    backgroundColor: '#134283',
    opacity: 0.25,
    position: 'absolute',
    top: 380,
    right: -24
  },
  ovalBlueAdapterLeft: {
    width: 66,
    height: 66,
    borderRadius: 33,
    backgroundColor: '#134283',
    opacity: 0.25,
    position: 'absolute',
    top: 1400,
    left: -74
  },
  ovalBlueShirt: {
    width: 170,
    height: 170,
    borderRadius: 85,
    backgroundColor: '#134283',
    opacity: 0.25,
    position: 'absolute',
    top: 600,
    left: -190
  },
  ovalBlueShirtRight: {
    width: 170,
    height: 170,
    borderRadius: 85,
    backgroundColor: '#134283',
    opacity: 0.25,
    position: 'absolute',
    top: 1700,
    right: -190
  },
  ovalPurpleTotte: {
    width: 90,
    height: 90,
    borderRadius: 45,
    backgroundColor: '#9846dd',
    opacity: 0.25,
    position: 'absolute',
    top: 860,
    right: 170
  }
});

export default styles;
