import { useFlags } from 'launchdarkly-react-client-sdk';

const defaultShippingCutoffHour = 10;

const useShippingCutoffHour = () => {
  const { shipmentsLimitBusinessHourPermanent092421: shippingCutoffHour } = useFlags();
  return shippingCutoffHour ?? defaultShippingCutoffHour;
};

export default useShippingCutoffHour;
