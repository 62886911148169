/* eslint-disable camelcase */
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import Scrollable from 'react-scrollbars-custom';
import { getCountryCallingCode, isSupportedCountry } from 'react-phone-number-input';
import csvProcessesApi from '../../../apis/swagup/csvProcesses';
import verifyAddress from '../../../helpers/addressValidation';
import AddressConfirmation from '../../shared/AddressConfirmation';
import ErrorAlert from '../../shared/ErrorAlert';
import styles from './styles/ContactForm';
import { ErrorCounter } from './ErrorCounter';
import ContactFields from './ContactFields';
import { handleContactError, getSchema } from '../../global/Forms/commonValidations';
import { getFormattedPhoneNumber } from '../../../helpers/utils';
import { useCountries } from '../../../hooks';

const useStyles = makeStyles(styles);

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  shipping_address1: '',
  shipping_country: '',
  shipping_state: '',
  shipping_city: '',
  shipping_zip: ''
};

const getDefaultValues = ({ contact, countries }) => {
  const isValidCountry = countries.some(c => c.iso2 === contact.shipping_country);

  return {
    ...contact,
    shipping_country: isValidCountry ? contact.shipping_country : '',
    shipping_state: isValidCountry ? contact.shipping_state : '',
    size: contact.size ?? '',
    phone_number: getFormattedPhoneNumber(contact?.phone_number, contact?.shipping_country)
  };
};

const resolver = yupResolver(getSchema(Object.keys(defaultValues)));

const ContactForm = ({ fileId, contact, onClose, onSave }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [addressVerification, setAddressVerification] = React.useState({ address: {} });
  const [hasfailed, setHasFailed] = React.useState(false);
  const [selectedSizeOption, setSelectedSizeOption] = React.useState('unselected'); // initial state
  const closing = React.useRef(false);

  const { data: countries } = useCountries();
  const formMethods = useForm({
    mode: 'all',
    resolver,
    defaultValues: getDefaultValues({ contact, countries })
  });

  const { formState, handleSubmit, trigger, setError, watch, clearErrors } = formMethods;
  const { errors } = formState;

  React.useEffect(() => {
    trigger();
  }, [trigger]);

  const postContact = React.useCallback(
    async data => {
      closing.current = true;
      const response = await csvProcessesApi.updateOutput(fileId, data);
      if (response.result === 'error') {
        setIsLoading(false);
        closing.current = false;
        handleContactError({
          data: response.data.input_json,
          setError,
          errorBehavior: () => setHasFailed(true),
          state: data.shipping_state
        });
      } else onSave();
    },
    [fileId, onSave, setError]
  );

  const onValidate = React.useCallback(
    async data => {
      setIsLoading(true);

      const contactData = {
        ...data,
        id: contact.id,
        force_address: true
      };
      const address = {
        ...contactData,
        street: data.shipping_address1,
        secondary: data.shipping_address2,
        city: data.shipping_city,
        state: data.shipping_state,
        zipcode: data.shipping_zip,
        country: data.shipping_country
      };

      const { result, message } = await verifyAddress(address);
      if (result === 'ok') await postContact(contactData);
      else {
        setAddressVerification({ address, message });
        setOpenConfirm(true);
      }
    },
    [contact, postContact]
  );

  React.useEffect(() => {
    if (Object.keys(contact.errors).length === 1 && contact.errors.address_validation) {
      handleSubmit(onValidate)();
    }
  }, [contact, handleSubmit, onValidate]);

  const watchFields = watch(['shipping_address1', 'shipping_state', 'shipping_city', 'shipping_zip']);

  React.useEffect(() => {
    const [shippingAddress, shippingState, shippingCity, shippingZip] = watchFields;
    const isZipInvalidAndPristine =
      !errors.shipping_zip &&
      contact?.shipping_zip === shippingZip &&
      contact?.shipping_state === shippingState &&
      contact?.shipping_city === shippingCity &&
      contact?.shipping_address1 === shippingAddress &&
      contact?.errors.shipping_zip?.some?.(m => m.toLowerCase().includes('invalid'));
    if (isZipInvalidAndPristine) {
      setError('shipping_zip', { type: 'manual', message: `Invalid Zip Code for ${contact.shipping_state}` });
    }

    const isInvalidSize = !errors.size && contact?.errors.size && selectedSizeOption === 'unselected';
    if (isInvalidSize) {
      setError('size', { type: 'warning', message: 'Size in CSV is not valid, please select correct size' });
    }
    if (selectedSizeOption === 'size_none' && errors.size) {
      clearErrors('size');
      setSelectedSizeOption(null);
    }
  }, [contact, errors, watchFields, setError, clearErrors, selectedSizeOption]);

  const phoneNumber = watch('phone_number');
  React.useEffect(() => {
    const possibleNumbers = [contact.phone_number, `+${contact.phone_number}`];

    if (isSupportedCountry(contact.shipping_country)) {
      possibleNumbers.push(`+${getCountryCallingCode(contact.shipping_country)}${contact.phone_number}`);
    }

    const isSamePhoneRegex = new RegExp(
      `^${Array.from(phoneNumber ?? '', c => (c === '+' ? `[\\+]{0,1}` : `(${c})`)).join('[- ()]*')}$`
    );
    const isPhoneInvalidAndPristine =
      !errors.phone_number &&
      contact?.errors.phone_number?.toLowerCase().includes('invalid') &&
      possibleNumbers.some(p => isSamePhoneRegex.test(p));
    if (isPhoneInvalidAndPristine) {
      setError('phone_number', { type: 'manual', message: `Invalid phone number format` });
    }
  }, [contact, errors, phoneNumber, setError]);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    if (!closing.current) setIsLoading(false);
  };

  const amountOfErrors = Object.keys(errors).length;

  return (
    <div className={clsx(classes.formContainer, { open: Boolean(contact) })}>
      <Scrollable style={{ flex: 1, width: '100%', height: '100%' }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onValidate)}>
            <Grid container direction="column" style={{ maxWidth: 824 }}>
              <Grid container alignItems="center" className={classes.formHeader}>
                <Typography variant="h5" style={{ marginRight: 16 }}>
                  {amountOfErrors === 0 ? 'Edit Contact' : 'Fix Contact issues'}
                </Typography>
                {amountOfErrors > 0 && <ErrorCounter count={amountOfErrors} />}
                {hasfailed && (
                  <ErrorAlert
                    error="An error ocurred saving the contact"
                    className={classes.errorAlert}
                    action={
                      <Button type="submit" variant="text">
                        Try again
                      </Button>
                    }
                  />
                )}
              </Grid>
              <ContactFields onSelectSize={setSelectedSizeOption} />
              <Grid container>
                <Button variant="text" className={classes.saveBtn} disabled={isLoading} onClick={() => onClose()}>
                  Discard changes
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className={classes.saveBtn}
                  loading={isLoading}
                  disabled={amountOfErrors !== 0 || hasfailed || isLoading}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Scrollable>
      <AddressConfirmation
        open={openConfirm}
        onClose={handleCloseConfirm}
        address={addressVerification.address}
        message={addressVerification.message}
        callbackAction={postContact}
      />
    </div>
  );
};

export default ContactForm;
