import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

// see example from https://material-ui.com/components/text-fields/
const NumberFormatCustom = ({ inputRef, onChange, ...props }) => (
  <NumberFormat
    {...props}
    decimalScale={2}
    getInputRef={inputRef}
    allowNegative={false}
    onValueChange={({ value }) => {
      onChange({ target: { value } });
    }}
    thousandSeparator
    isNumericString
    maxLength={11}
    prefix="$"
    isAllowed={({ value }) => value.charAt(0) !== '0' && value.charAt(0) !== '.'}
  />
);

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default NumberFormatCustom;
