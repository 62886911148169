import React, { useState } from 'react';
import { Popper, ClickAwayListener, MenuItem, makeStyles, Grid } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { StylessButton } from '../../buttons';

const commonFont = {
  fontFamily: 'Gilroy',
  fontSize: 14,
  textAlign: 'left'
};

const useStyles = makeStyles({
  selectedItemText: {
    ...commonFont,
    color: '#000000',
    fontSize: 15,
    fontWeight: 100,
    minWidth: 72
  },
  selectorItem: {
    ...commonFont,
    padding: '8px 16px',
    '&:hover': {
      background: '#eaeaea'
    }
  },
  dropDown: {
    borderRadius: 15,
    overflow: 'hidden',
    zIndex: 9999,
    background: '#ffffff',
    boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.05)',
    minWidth: 220
  },
  dropdownSign: {
    marginLeft: 16,
    height: 9,
    width: 10
  },
  selectedItemTextGray: {
    ...commonFont,
    color: '#787B80',
    minWidth: 72
  },
  container: { minWidth: 220 },
  root: { position: 'relative' }
});

const CustomizedSelect = ({ value, items, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const [src, alt] = isOpen ? ['/images/public/dropdown-up.svg', 'up'] : ['/images/public/dropdown-down.svg', 'down'];

  return (
    <div className={classes.root}>
      <StylessButton
        onClick={e => {
          setAnchorEl(e.currentTarget);
          setIsOpen(true);
        }}
        disabled={disabled || isEmpty(items)}
      >
        <Grid container alignItems="center" className={classes.container}>
          <Grid item xs>
            <p className={value ? classes.selectedItemText : classes.selectedItemTextGray}>
              {value || 'Choose imprint type (optional)'}
            </p>
          </Grid>
          <Grid item>{(isOpen || !disabled) && <img src={src} className={classes.dropdownSign} alt={alt} />}</Grid>
        </Grid>
      </StylessButton>
      <Popper
        id="render-users-menu"
        anchorEl={anchorEl}
        open={isOpen}
        className={classes.dropDown}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className={classes.selectorItemsContainer}>
            {items?.map(item => (
              <MenuItem
                className={classes.selectorItem}
                key={item}
                value={item}
                onClick={() => {
                  onChange(item);
                  setIsOpen(false);
                }}
              >
                {item}
              </MenuItem>
            ))}
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default CustomizedSelect;
