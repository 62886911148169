import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { KeyboardBackspace as BackIcon } from '@material-ui/icons';

const useStyles = makeStyles({
  backIcon: {
    marginRight: 10,
    fontSize: 18,
    color: '#434d5c',
    cursor: 'pointer'
  },
  backText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    color: '#8d9299'
  },
  backButton: {
    padding: 0
  }
});

const GoBack = ({ goBackText, onClick, customStyles }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container alignItems="center">
      <Button variant="text" onClick={onClick || history.goBack} className={classes.backButton} style={customStyles}>
        <BackIcon className={classes.backIcon} />
        <Typography variant="body1" className={classes.backText}>
          {goBackText || 'Go Back'}
        </Typography>
      </Button>
    </Grid>
  );
};

export default GoBack;
