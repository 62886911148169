/* eslint-disable camelcase */
import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { makeStyles, Grid } from '@material-ui/core';
import CenteredGrid from '../../shared/CenteredGrid';
import Header from '../../shipSwag/overview/Header';
import Help from '../../shipSwag/overview/Help';
import Separator from '../../shipSwag/overview/Separator';
import ShipmentOverview from '../../shipSwag/overview/ShipmentOverview';
import PaymentSummary from '../../shipSwag/overview/PaymentSummary';

const useStyles = makeStyles(theme => ({
  columnContainer: {
    padding: '24px 0 49px',
    '& .leftColumn': {
      paddingRight: 18
    },
    '& .rightColumn': {
      paddingLeft: 18
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      '& .leftColumn': {
        paddingRight: 0,
        paddingTop: 20
      },
      '& .rightColumn': {
        paddingLeft: 0
      }
    }
  }
}));

const ShipSwagOverview = () => {
  const { product, sizes, domesticData, internationalData, total, credits, paymentProfile } = useLocation().state || {};
  const classes = useStyles();

  const insufficientData = !((domesticData || internationalData) && (credits || paymentProfile) && total);
  if (insufficientData) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container>
      <CenteredGrid container direction="column" alignItems="center">
        <Header />
        <Separator light />
        <Grid container className={classes.columnContainer}>
          <Grid container direction="column" item xs={12} sm={6} className="leftColumn">
            <ShipmentOverview
              product={product}
              sizes={sizes}
              domesticData={domesticData}
              internationalData={internationalData}
            />
          </Grid>
          <Grid container direction="column" item xs={12} sm={6} className="rightColumn">
            <PaymentSummary
              total={total}
              credits={credits}
              paymentProfile={paymentProfile}
              domesticData={domesticData}
              internationalData={internationalData}
            />
          </Grid>
        </Grid>

        <Help />
      </CenteredGrid>
    </Grid>
  );
};

export default ShipSwagOverview;
