import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import styles from './styles/onboarding';

const SectionTag = ({ classes, tag, color = '#3577d4' }) => (
  <Grid container className={classes.sectionTagContainer} alignItems="center">
    <Grid item>
      <hr className={classes.sectionTagLine} style={{ background: color }} />
    </Grid>
    <Grid item>
      <h6 className={classes.sectionTag} style={{ color }}>
        {tag}
      </h6>
    </Grid>
    <Grid item className={classes.onMobile}>
      <hr className={classes.sectionTagLine} style={{ background: color }} />
    </Grid>
  </Grid>
);

export default withStyles(styles)(SectionTag);
