import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useQueryClient, useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { EmptyState, Helmet, CenteredGrid, Pagination } from '../../../shared';
import tags from '../../../../apis/seoTags';
import Loader from '../../../global/Loader';
import { usePaginatedQuery, usePerPageOptions, useQueryParams } from '../../../../hooks';
import RequestsList from './RequestedList';
import DashBoard from '../../../../apis/DashBoard';
import apiPaths from '../../../../helpers/apiPaths';
import { generateFilterOptions } from '../../../../helpers/utils';
import { status200 } from '../../../../apis/swagup/status.utils';
import { CardsContainer } from '../../../shared/containers/Cards';
import SearchSortFilter from '../../../shared/SearchSortFilter';
import global from '../../../../apis/swagup/global';

const fetchOpportunities = query =>
  DashBoard.get(`${apiPaths.opportunities}?proofs_only=1&${query}`, status200).then(response => response.data);

const useStyles = makeStyles(theme => ({
  container: props => ({
    display: 'grid',
    marginTop: '0px !important',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: props.leftBarNavigation ? '20px' : '32px',
    width: '100%',
    [theme.breakpoints.only('xl')]: {
      columnGap: '15px',
      gridTemplateColumns: 'repeat(5, 1fr)'
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: props.leftBarNavigation ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)'
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  }),
  standardCard: {
    border: '1px solid #e5e7e8',
    borderRadius: 20,
    backgroundColor: '#ffffff',
    padding: ({ leftBarNavigation }) => (leftBarNavigation ? '27px 24px 37px' : '27px 32px 37px'),
    maxHeight: 550,
    maxWidth: '100%',
    transition: 'all 0.2s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      justifySelf: 'center'
    },
    '&:hover': {
      boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.05)'
    }
  },
  paginationContainer: {
    paddingTop: 30,
    paddingBottom: 60
  }
}));

const RequestedOrders = () => {
  const { leftBarNavigation } = useFlags();
  const [filters, setFilters] = React.useState([]);

  React.useEffect(() => {
    global
      .fetchFilters('opportunities')
      .then(data => {
        setFilters(data);
      })
      .catch(error => {
        console.error('Error fetching filters:', error);
      });
  }, []);

  const { leftNavOpen } = useSelector(state => state.commonReducer);

  const classes = useStyles({ leftBarNavigation, leftNavOpen });

  const queryParams = useQueryParams();
  const sort = queryParams.get('ordering') || '-modified_on';
  const search = queryParams.get('search') || '';
  const status = queryParams.get('status') || '';
  const productType = queryParams.get('product_type') || '';

  const perPageOptions = usePerPageOptions();
  const { query, pagination } = usePaginatedQuery({
    queryKey: [apiPaths.opportunities, search, sort, status, productType],
    queryFn: (limit, offset) => {
      const apiQuery = new URLSearchParams();
      apiQuery.set('limit', limit);
      apiQuery.set('ordering', sort);
      if (search) apiQuery.set('search', search);
      if (status) apiQuery.set('status', status);
      if (productType) apiQuery.set('product_type', productType);
      if (offset > 0) apiQuery.set('offset', offset);
      return fetchOpportunities(apiQuery.toString());
    },
    perPageOptions
  });

  const queryClient = useQueryClient();
  React.useEffect(() => {
    queryClient.invalidateQueries([apiPaths.opportunities]);
  }, [queryClient]);

  if (query.data === undefined) return <Loader absolute />;

  const searchSortFilterConfig = {
    search: { placeholder: 'Search orders #' },
    sort: {
      options: {
        '-modified_on': 'Most Recent',
        modified_on: 'Less Recent'
      }
    },
    filters: [
      {
        label: 'Status',
        queryParam: 'status',
        options: generateFilterOptions(filters?.statuses)
      }
    ]
  };

  return (
    <>
      <Helmet tags={tags.ordersRequested} />
      <SearchSortFilter config={searchSortFilterConfig} />
      {pagination.count === 0 ? (
        <EmptyState
          title="No Proofs"
          image={{
            path: '/images/dashboard/empty-proofs.png',
            alt: 'No proofs',
            text: 'You’ve submitted no order requests yet.'
          }}
          button={{
            link: '/product-onboarding',
            text: 'Start new order'
          }}
        />
      ) : (
        <CenteredGrid>
          <CardsContainer className={classes.container}>
            <RequestsList opportunities={query.data.results} classes={classes} />
          </CardsContainer>
          <Grid container item xs={12} justifyContent="center" className={classes.paginationContainer}>
            <Pagination {...pagination} endText="requested" />
          </Grid>
        </CenteredGrid>
      )}
    </>
  );
};

export default RequestedOrders;
