import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, FormControlLabel, Box, MenuItem, IconButton } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { isEmpty } from 'lodash';
import { Add, Delete } from '@material-ui/icons';
import styles from '../styles/redeemPagesHome';
import { CustomTextField, RedeemCustomSelectField } from '../redeemCommon';
// import CheckBoxFormControl from '../../global/CheckBoxFormControl';
import { BlueSwitch } from '../../account/AccountSharedComponents';

const useStyles = makeStyles(styles);

const maxQuestions = 1;
const questionTypes = ['Text Field', 'Single choice', 'Multiple choice']; // 'Yes or No',

const QuestionOptionRow = ({ option, onAdd, onRemove, onUpdate, isOnlyOption, optionsSameAsLabel, classes }) => {
  const [updatedOption, setUpdatedOption] = useState({});

  useEffect(() => {
    if (isEmpty(updatedOption) && !isEmpty(option)) setUpdatedOption(option);
  }, [option]);

  useEffect(() => {
    if (!isEmpty(updatedOption) && !isEmpty(option)) onUpdate(updatedOption);
  }, [updatedOption]);

  const handleOnOptionLabelChange = ({ target: { value } }) => {
    if (optionsSameAsLabel) setUpdatedOption(prev => ({ ...prev, label: value, value }));
    else setUpdatedOption(prev => ({ ...prev, label: value }));
  };

  const handleOnOptionLabelBlur = () => {
    if (isEmpty(option) && !isEmpty(updatedOption.label)) {
      onAdd(updatedOption);
      setUpdatedOption({ label: '', value: '' });
    }
    if (!isEmpty(option) && !isEmpty(updatedOption) && isEmpty(updatedOption.label)) {
      onRemove(updatedOption);
    }
  };

  // const handleOnOptionValueChange = ({ target: { value } }) => {
  //   setUpdatedOption(prev => ({ ...prev, value }));
  // };

  const handleOnAdd = value => {
    onAdd(value);
    setUpdatedOption({ label: '', value: '' });
  };

  const Action = isEmpty(option) ? handleOnAdd : onRemove;
  const ActionIcon = isEmpty(option) ? Add : Delete;

  const isDisabled = () => {
    if (isEmpty(updatedOption)) return true;
    if (isEmpty(option) && (isEmpty(updatedOption.label) || isEmpty(updatedOption.value))) return true;
    if (!isEmpty(option) && isOnlyOption) return true;
    return false;
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={8}>
        <CustomTextField
          className={classes.inputText}
          placeholder="Option"
          value={updatedOption.label}
          name="label"
          onChange={handleOnOptionLabelChange}
          onBlur={handleOnOptionLabelBlur}
          fullWidth
        />
      </Grid>
      {/* <Grid item xs>
        <CustomTextField
          className={classes.inputText}
          placeholder="Option Value"
          value={updatedOption.value}
          name="value"
          onChange={handleOnOptionValueChange}
          InputProps={{ readOnly: optionsSameAsLabel }}
          fullWidth
        />
      </Grid> */}
      <Grid item>
        <IconButton onClick={() => Action(updatedOption)} disabled={isDisabled()} style={{ width: 24, height: 24 }}>
          <ActionIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const CustomQuestionRow = ({ customQuestion, onAdd, onRemove, onUpdate, isLast, classes }) => {
  const [updatedCustomQuestion, setUpdatedCustomQuestion] = useState({});
  const [optionsSameAsLabel, setOptionsSameAsLabel] = useState(true);

  useEffect(() => {
    if (isEmpty(updatedCustomQuestion) && !isEmpty(customQuestion)) setUpdatedCustomQuestion(customQuestion);
  }, [customQuestion]);

  useEffect(() => {
    if (!isEmpty(updatedCustomQuestion) && !isEmpty(customQuestion)) onUpdate(updatedCustomQuestion);
  }, [updatedCustomQuestion]);

  const handleOnQuestionChange = ({ target: { value } }) => {
    setUpdatedCustomQuestion(prev => ({ ...prev, question: value }));
  };

  const handleOnQuestionTypeChange = type => {
    setUpdatedCustomQuestion(prev => ({ ...prev, type }));
  };

  const handleOnOptionAdd = newOption => {
    setUpdatedCustomQuestion(prev => ({
      ...prev,
      options: [...(prev.options || []), { ...newOption, id: (prev.options?.length || 0) + 1 }]
    }));
  };

  const handleOnOptionRemove = option => {
    setUpdatedCustomQuestion(prev => ({ ...prev, options: prev.options.filter(o => o.id !== option.id) }));
  };

  const handleOnOptionUpdate = option => {
    setUpdatedCustomQuestion(prev => ({ ...prev, options: prev.options.map(o => (o.id === option.id ? option : o)) }));
  };

  const handleOnAdd = () => {
    onAdd(updatedCustomQuestion);
    setUpdatedCustomQuestion({});
  };

  const isDisabled = () => {
    if (isEmpty(updatedCustomQuestion)) return true;
    if (
      isEmpty(customQuestion) &&
      (isEmpty(updatedCustomQuestion.question) ||
        isEmpty(updatedCustomQuestion.type) ||
        (updatedCustomQuestion.type.includes('choice') && isEmpty(updatedCustomQuestion.options)))
    )
      return true;
    return false;
  };

  return (
    <Box style={{ marginBottom: 32, paddingBottom: 4, borderBottom: isLast ? undefined : '1px solid #EBEDF0' }}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <FormControlLabel
                className={classes.formControl}
                labelPlacement="top"
                control={
                  <CustomTextField
                    className={classes.inputText}
                    placeholder="Your Question"
                    value={updatedCustomQuestion.question || ''}
                    name="question"
                    onChange={handleOnQuestionChange}
                    fullWidth
                  />
                }
                label={
                  <Typography variant="body3RegularInter" className={classes.inputLabel}>
                    Your Question
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                className={classes.formControl}
                labelPlacement="top"
                control={
                  <RedeemCustomSelectField
                    id="type"
                    name="type"
                    label="Answer Type"
                    value={updatedCustomQuestion.type || 'Question Type'}
                    className={classes.selectField}
                    totalItems={questionTypes.length}
                    handleSelect={({ target: { value } }) => handleOnQuestionTypeChange(value)}
                    fullWidth
                    withTooltip
                  >
                    {questionTypes.map(qt => (
                      <MenuItem key={qt} value={qt} title={qt}>
                        {qt}
                      </MenuItem>
                    ))}
                  </RedeemCustomSelectField>
                }
                label={
                  <Typography variant="body3RegularInter" className={classes.inputLabel}>
                    Answer Type
                  </Typography>
                }
              />
            </Grid>
            {updatedCustomQuestion.type?.includes('choice') && (
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.formControl}
                  labelPlacement="top"
                  control={
                    <Box>
                      {updatedCustomQuestion.options?.map(option => (
                        <QuestionOptionRow
                          key={option.id || option.value}
                          option={option}
                          classes={classes}
                          isOnlyOption={updatedCustomQuestion.options.length === 1}
                          onRemove={handleOnOptionRemove}
                          onUpdate={handleOnOptionUpdate}
                          optionsSameAsLabel={optionsSameAsLabel}
                        />
                      ))}
                      <Box>
                        <QuestionOptionRow
                          onAdd={handleOnOptionAdd}
                          optionsSameAsLabel={optionsSameAsLabel}
                          classes={classes}
                        />
                      </Box>
                    </Box>
                  }
                  label={
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography variant="body3RegularInter" className={classes.inputLabel}>
                          Options
                        </Typography>
                      </Grid>
                      {/* <Grid item>
                        <CheckBoxFormControl
                          label="Same as label"
                          checked={optionsSameAsLabel}
                          rootClass={classes.sameAsLabelCheckBox}
                          onClick={v => setOptionsSameAsLabel(v)}
                        />
                      </Grid> */}
                    </Grid>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box className={classes.customQuestionAActionSection}>
                <Grid container justifyContent="flex-end" spacing={3}>
                  <Grid item>
                    {!isEmpty(customQuestion) ? (
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => onRemove(updatedCustomQuestion)}
                        disabled={isDisabled()}
                        className={classes.customQuestionRemove}
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        size="small"
                        onClick={handleOnAdd}
                        disabled={isDisabled()}
                        className={classes.customQuestionAdd}
                      >
                        Add
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const sampleQuestion = {
  id: 1,
  question: 'A Sample Question?',
  type: 'Text Field'
};

const CustomQuestions = ({ page, setPage }) => {
  const [addQuestionnaire, setAddQuestionnaire] = useState(page.customQuestions?.length > 0);

  useEffect(() => {
    const questions = page.customQuestions.length > 0 ? page.customQuestions : [sampleQuestion];
    setPage(prev => ({
      ...prev,
      customQuestions: addQuestionnaire ? questions : []
    }));
  }, [addQuestionnaire]);

  useEffect(() => {
    if (page.customQuestions.length === 0) setAddQuestionnaire(false);
  }, [page.customQuestions]);

  // const handleOnCustomQuestionAdd = newQuestion => {
  //   setPage(prev => ({
  //     ...prev,
  //     customQuestions: [
  //       ...(prev.customQuestions || []),
  //       { ...newQuestion, id: (prev.customQuestions?.length || 0) + 1 }
  //     ]
  //   }));
  // };

  const handleOnCustomQuestionRemove = question => {
    setPage(prev => ({ ...prev, customQuestions: prev.customQuestions.filter(q => q.id !== question.id) }));
  };

  const handleOnCustomQuestionUpdate = question => {
    setPage(prev => ({
      ...prev,
      customQuestions: prev.customQuestions.map(q => (q.id === question.id ? question : q))
    }));
  };

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" className={classes.questionnaireAsk}>
          <Grid item xs>
            <Typography variant="body2RegularInter">Would you like to add an additional question?</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              labelPlacement="start"
              control={
                <BlueSwitch
                  checked={addQuestionnaire}
                  onChange={({ target: { checked } }) => setAddQuestionnaire(checked)}
                />
              }
            />
          </Grid>
        </Grid>

        {page.customQuestions?.map((customQuestion, index) => (
          <CustomQuestionRow
            key={customQuestion.id}
            customQuestion={customQuestion}
            onRemove={handleOnCustomQuestionRemove}
            onUpdate={handleOnCustomQuestionUpdate}
            isLast={index === maxQuestions - 1}
            classes={classes}
          />
        ))}
      </Grid>
      {/* {page.customQuestions.length < maxQuestions && (
        <Grid item xs={12}>
          <CustomQuestionRow customQuestion={{}} isLast classes={classes} onAdd={handleOnCustomQuestionAdd} />
        </Grid>
      )} */}
    </Grid>
  );
};

export default CustomQuestions;
