import commonStyles from './commonStyles';

const { title, line } = commonStyles;

const styles = theme => ({
  addCreditsBtn: {
    width: 226,
    height: 56
  },
  titleContainer: {
    [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
  },
  title,
  line,
  centerInfoIcon: { marginTop: 12 },
  amountInput: {
    width: 190,
    '& input': {
      padding: '19px 32px 19px 30px',
      textAlign: 'center'
    },
    [theme.breakpoints.only('xl')]: {
      width: 230
    }
  },
  iconContainer: {
    width: 48,
    height: 48,
    borderRadius: 10,
    backgroundColor: ({ color }) => color
  },
  detailTitle: {
    marginBottom: 8,
    fontSize: 14,
    lineHeight: 1,
    color: '#8f8f8f'
  },
  detailContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '32%',
      flexBasis: '32%'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    }
  },
  detailCard: {
    padding: '16px 20px',
    boxShadow: '0 15px 45px 0 rgba(0, 0, 0, 0.03)',
    borderRadius: 16,
    backgroundColor: 'transparent'
  },
  tableTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1
  },
  buttonsContainer: {
    paddingTop: 16,
    [theme.breakpoints.down('md')]: { width: '100%' },
    [theme.breakpoints.down('xs')]: { textAlign: 'center', margin: 0 }
  },
  creditsContainer: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    }
  },
  tableTitleContainer: {
    paddingTop: 48,
    paddingLeft: 16
  },
  tableContainer: {
    paddingTop: 16,
    justifyContent: 'center'
  },
  noMobile: {
    [theme.breakpoints.down('xs')]: { display: 'none' }
  },
  infoIcon: {
    paddingLeft: 16,
    cursor: 'pointer'
  },
  emptyInfo: {
    width: 91,
    height: 8,
    borderRadius: 4,
    marginTop: 8,
    backgroundColor: '#ebedf0'
  }
});
export default styles;
