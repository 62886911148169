export default {
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    marginTop: 110,
    marginLeft: 10,
    marginBottom: 50
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  marker: {
    display: 'flex',
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#ebedf0'
  },
  check: {
    width: 12,
    height: 12,
    margin: 'auto',
    color: '#3577d4'
  },
  circle: {
    width: 6,
    height: 6,
    margin: 'auto',
    borderRadius: '50%',
    backgroundColor: 'white'
  },
  visitedText: {
    fontFamily: 'Gilroy-Medium',
    color: '#0b1829',
    fontSize: 12
  },
  unvisitedText: {
    fontFamily: 'Gilroy-Medium',
    color: '#787b80',
    fontSize: 12
  },
  previousMarker: {
    border: '1px solid #3577d4'
  },
  currentMarker: {
    boxShadow: 'rgba(100, 100, 111, 0.9) 0px 0px 9px 0px',
    backgroundColor: '#3577d4'
  },
  nextMarker: {},
  line: props => ({
    position: 'absolute',
    width: 3,
    height: (props.length - 1) * 60,
    top: 10,
    left: 9,
    backgroundColor: '#ebedf0',
    zIndex: -1
  })
};
