import { productStatus } from '../apis/constants';

const canApproveProductOld = status =>
  [
    productStatus.designReady,
    productStatus.productionReady,
    productStatus.proofReview,
    productStatus.readyToOrder
  ].includes(status);

const canApproveProduct = status =>
  [productStatus.designReady, productStatus.productionReady, productStatus.readyToOrder].includes(status);

const approveProductText = (status, areAllItemsApproved = false, redirectedFromOrdersPage = false) => {
  switch (status) {
    case productStatus.designReady:
      return 'Approve Design';
    case productStatus.changesRequested:
      return 'Changes Requested';
    case productStatus.productionRequest:
      return 'Finalizing Proof';
    case productStatus.productionReady:
      return 'Approve';
    case productStatus.approved:
      return 'Approved';
    case productStatus.inactive:
      return 'Inactive';
    case productStatus.mockupReview:
      return 'Approve mockup';
    case productStatus.proofDesign:
      return 'Approve';
    case productStatus.proofReview:
      return 'Approve';
    case productStatus.readyToOrder:
      // eslint-disable-next-line no-nested-ternary
      return areAllItemsApproved ? (redirectedFromOrdersPage ? 'Review Order' : 'Order Now') : 'Approve Next Item';
    default:
      return 'In Design';
  }
};

const canRequestProductChanges = status => ![productStatus.approved, productStatus.inactive].includes(status);

export { canApproveProductOld, canApproveProduct, approveProductText, canRequestProductChanges };
