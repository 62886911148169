const styles = theme => ({
  closeButton: {
    marginTop: 1,
    marginRight: 1
  },
  modalText: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#434c5f',
    paddingBottom: 10
  },
  modalContent: {
    textAlign: 'center'
  },
  checkbox: {
    padding: 0
  },
  nevermind: {
    fontWeight: 'bold',
    fontFamily: 'Futura',
    fontSize: 16,
    textTransform: 'uppercase',
    marginBottom: 50
  },
  paper: {
    position: 'absolute',
    left: '50%',
    webkitTransform: 'translateX(-50%)',
    transform: 'translateX(-50%)',
    top: 180,
    width: 790,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none'
  },
  addContactDiv: {
    width: 1,
    height: 32,
    backgroundColor: '#dddddd',
    opacity: 0.3,
    margin: 'auto',
    marginRight: 10
  },
  addContactIcon: {
    fontSize: 20,
    color: '#ffffff',
    marginLeft: 10
  },
  ImportCSV: {
    width: 120,
    height: 56,
    borderRadius: 4,
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    boxShadow: 'none',
    color: '#3577d4',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});
export default styles;
