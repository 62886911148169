import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useLocation, useHistory } from 'react-router-dom';
import designsApi from '../../../../apis/swagup/designs';
import { useMetaData } from '../../../../hooks/globalQueries';
import GoBack from '../../../shared/GoBack';
import ImageUpload from '../../../global/ImageUpload';
import { getDomain } from './common';
import styles from './styles/uploadLogo';
import gtm from '../../../../utils/gtm';
import apiPaths from '../../../../helpers/apiPaths';

const useStyles = makeStyles(styles);

const UploadLogo = () => {
  const history = useHistory();
  const location = useLocation();
  const metaData = useMetaData();
  const packTemplate = metaData?.data?.CURRENT_SAMPLE_PACK;
  const {
    samplePack: { email, company_name: companyName },
    designFailure,
    hasCustomLogo
  } = location.state;

  const domain = getDomain(email);

  const queryClient = useQueryClient();
  const uploadLogoMutation = useMutation(
    logo =>
      designsApi.create({
        logo,
        domain,
        pack_template: packTemplate,
        company_name: companyName
      }),
    {
      onSuccess: response => {
        queryClient.setQueryData([apiPaths.designs, response.data.id], response.data);
        const designId = response.data.id;
        gtm.onClickGenerateAIMockupsWithLogo(companyName, domain, packTemplate, designId);
        history.push(`/onboarding/mockups/${designId}`, {
          ...location.state,
          designFailure: false,
          hasCustomLogo: true
        });
      }
    }
  );

  const [logo, setLogo] = useState(null);
  const classes = useStyles();

  const handleContinue = () => {
    uploadLogoMutation.mutate(logo);
  };

  const handleChangeImage = file => setLogo(file);
  return (
    <>
      {!designFailure && <GoBack goBackText="Back" />}
      <Box mt="10px">
        <Typography variant="h5">
          {designFailure ? (
            <Grid container className={classes.warningTextContainer}>
              {hasCustomLogo ? (
                <>
                  <span role="img" aria-label="alert" className={classes.warningIcon}>
                    ⚠️
                  </span>
                  Oh no! something went wrong, try uploading another logo
                </>
              ) : (
                'While you wait for your Sample Pack, upload your logo to see personalized swag mockups'
              )}
            </Grid>
          ) : (
            <>
              <span role="img" aria-label="siren">
                🚨
              </span>
              Swag Pack Alert!
            </>
          )}
        </Typography>
        {!designFailure && (
          <Typography variant="body1" className={classes.subtitle}>
            We&apos;ve matched 3 potential packs to your brand. Upload a different logo to see 3 new swag pack designs.
          </Typography>
        )}
        <Typography variant="body1" className={classes.infoText}>
          We recommend using vector files: .svg, .eps - but we accept .jpeg, and .png as well.
        </Typography>
        <Box>
          <ImageUpload
            fileBefore="/images/custom/assets/icons/upload.svg"
            accept="image/*, application/postscript"
            onChange={handleChangeImage}
            classes={{ container: classes.dragAndDropContainer }}
          />
        </Box>
        <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
          <Button
            onClick={() => {
              handleContinue();
              gtm.onClickContinue('Upload logo clicked');
            }}
            variant="primary"
            size="small"
            disabled={!logo || uploadLogoMutation.isLoading}
            loading={uploadLogoMutation.isLoading}
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default UploadLogo;
