import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { joinFields } from '../../helpers/utils';
import styles from './styles/AddressInfo';

const AddressInfo = ({ firstName, lastName, address1, address2, city, state, zip, country, classes }) => (
  <Grid container>
    <Grid item xs={12}>
      <p className={classes.names}>{joinFields([firstName, lastName], ' ')}</p>
      <p className={classes.address}>{address1}</p>
      {address2 && <p className={classes.address}>{address2}</p>}
      <p className={classes.address}>{joinFields([city, joinFields([state, zip], ' ')], ', ')}</p>
      <p className={classes.address}>{country}</p>
    </Grid>
  </Grid>
);

export default withStyles(styles)(AddressInfo);
