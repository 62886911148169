import * as React from 'react';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Edit } from '@material-ui/icons';
import { useMutation, useQueryClient } from 'react-query';
import { joinFields, moneyStr } from '../../helpers/utils';
import { StylessButton } from '../buttons';
import { toSentenceCase } from '../account/MembershipCommon';
import ShipmentStatusRounded from '../global/ShipmentStatusRounded';
import EditOrderRecipient from './EditOrderRecipient';
import { integrationsApi } from '../../apis/swagup';
import { useQueryParams } from '../../hooks';
import useIntegrations from '../../hooks/useIntegrations';
import apiPaths from '../../helpers/apiPaths';
import Img from '../shared/Img';

const styles = theme => ({
  integrationImageWrapper: {
    height: 82,
    width: 82,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 10,
    border: '1px solid #EBEEF2'
  },
  integrationImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  integrationItem: {
    padding: '20px 24px  !important',
    border: '1px solid #EBEDF0 !important',
    width: '100%',
    textAlign: 'left !important',
    borderRadius: 10,
    background: ({ enabled }) => (enabled ? undefined : '#EBEDF0 !important')
  },
  tabPanel: {
    paddingTop: 16,
    width: '100%'
  },
  integrationName: { marginBottom: 8, textAlign: 'left' },
  integrationDescription: { marginBottom: 8, textAlign: 'left' },
  integrationCTAPanel: { textAlign: 'left', paddingTop: 8 },
  integrationCTA: {
    height: 30,
    padding: '0px 12px',
    borderWidth: 1,
    fontFamily: 'Inter',
    fontSize: 12,
    lineHeight: '12px',
    fontWeight: 400,
    background: ({ enabled }) => (enabled ? undefined : '#EBEDF0 !important')
  },
  swipeableViews: {
    height: '100%',
    width: '100%',
    // minHeight: 'calc(100vh - 202px)',
    '& .react-swipeable-view-container': {
      height: '100%'
    },
    '& > div > div': {
      overflowX: 'hidden !important',
      overflowY: 'hidden !important'
    }
  },
  platformTitle: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '52px',
    letterSpacing: '-1.2px'
  },
  paymentStatus: {
    color: ({ error }) => (error ? '#C62828' : '#45AF5F'),
    height: 28,
    minWidth: 64,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 14,
    background: ({ error }) => (error ? '#FFCDD2' : '#E9F5EC'),
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 600
  },
  paymentStatusRefunded: {
    color: '#787b80',
    height: 28,
    minWidth: 64,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 14,
    background: '#ebedf0',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 600
  },
  itemVariant: {
    color: '#4A4F54',
    height: 24,
    minWidth: 64,
    width: 'fit-content',
    padding: '0px 6px',
    borderRadius: 12,
    background: '#D6D8DB',
    fontFamily: 'Inter',
    margin: '10px 0px',
    fontSize: 10,
    fontWeight: 600
  },
  itemTitle: {
    color: '#4A4F54',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '16px'
  },
  itemSKU: {
    color: '#4A4F54',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '14px'
  },
  itemPricing: {
    color: '#4A4F54',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '14px',
    marginLeft: 1
  },
  integrationOrder: {
    border: '1px solid #EBEDF0',
    borderRadius: 10,
    padding: '24px 32px 12px 32px',
    marginBottom: 42
  },
  trackingNumber: {
    '& span': {
      color: '#3577D4'
    }
  },
  orderText: {
    color: '#4A4F54',
    '& span': {
      fontWeight: 300
    }
  },
  buttonIcon: {
    marginTop: 4,
    color: 'inherit',
    fontSize: 18,
    transform: ({ open }) => (open ? 'rotate(-180deg)' : '')
  },
  lightText: { color: '#4A4F54' },
  retryButton: { height: 42 },
  billingAddress: { position: 'relative' },
  editIconButton: {
    height: 28,
    width: 28,
    position: 'absolute',
    right: 24,
    top: -4
  },
  editIcon: {
    fontSize: 16,
    color: '#3577D4'
  }
});

const useStyles = makeStyles(styles);
const IntegrationOrderItem = ({ integrationOrder, onRetry }) => {
  const [open, setOpen] = useState(false);
  const [openRecipient, setOpenRecipient] = useState(false);
  const [recipientInfo, setRecipientInfo] = useState({});

  const query = useQueryParams();
  const platform = query.get('platform');
  const { getConnectedPlatform } = useIntegrations();
  const selectedPlatform = getConnectedPlatform(platform);

  const queryClient = useQueryClient();
  const retryOrder = useMutation(
    data =>
      integrationsApi.updateOrderRecipient(integrationOrder.id, data, selectedPlatform?.connectionAccess?.access_token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([apiPaths.integrationOrders]);
        setOpenRecipient(false);
      }
    }
  );

  const handleSubmit = data => {
    const recipient = {
      first_name: data.first_name,
      last_name: data.last_name,
      customer_email: data.email,
      phone: data.phone_number,
      address1: data.shipping_address1,
      address2: data.shipping_address2,
      region: data.shipping_state,
      city: data.shipping_city,
      postal_code: data.shipping_zip,
      country_code: data.shipping_country
    };
    retryOrder.mutate(recipient);
  };

  useEffect(() => {
    if (integrationOrder)
      setRecipientInfo({
        first_name: integrationOrder.first_name,
        last_name: integrationOrder.last_name,
        email: integrationOrder.customer_email,
        phone_number: integrationOrder.phone,
        shipping_address1: integrationOrder.address1,
        shipping_address2: integrationOrder.address2,
        shipping_state: integrationOrder.region,
        shipping_city: integrationOrder.city,
        shipping_zip: integrationOrder.postal_code,
        shipping_country: integrationOrder.country_code
      });
  }, [integrationOrder]);

  const classes = useStyles({ open, error: !!integrationOrder.error_message });
  const isRefunded = !integrationOrder.error_message && integrationOrder.payment_status === 'refunded';
  return (
    <Box key={integrationOrder.id} className={classes.integrationOrder}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={isRefunded ? classes.paymentStatusRefunded : classes.paymentStatus}
              >
                <Grid item>
                  {integrationOrder.error_message
                    ? `Failed: ${integrationOrder.error_message}`
                    : toSentenceCase(integrationOrder.payment_status || '')}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs />
            <Grid item>
              {integrationOrder.swagup_order?.tracking && (
                <a href={integrationOrder.swagup_order.tracking_url} target="_blank" rel="noreferrer">
                  <Typography variant="body3SemiBoldInter" className={classes.trackingNumber}>
                    Tracking number: <span>{integrationOrder.swagup_order.tracking}</span>
                  </Typography>
                </a>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body1SemiBoldInter"
                className={classes.trackingNumber}
                style={{ marginTop: 12, marginBottom: 18 }}
              >
                Order{' '}
                <a
                  href={`https://admin.shopify.com/store/${selectedPlatform?.connection?.domain_name?.replace(
                    '.myshopify.com',
                    ''
                  )}/orders/${integrationOrder.external_platform_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>#{integrationOrder.order_number}</span>
                </a>
              </Typography>
              <Typography variant="body4RegularInter" className={classes.orderText} style={{ marginBottom: 8 }}>
                Placed on:{' '}
                <span>
                  {dayjs(integrationOrder.swagup_order?.created_at || integrationOrder.created_at).format('MM/DD/YYYY')}
                </span>
              </Typography>
              <Typography variant="body4RegularInter" className={classes.orderText} style={{ marginBottom: 8 }}>
                Customer: <span>{integrationOrder.shop_name}</span>
              </Typography>
              <Typography variant="body4RegularInter" className={classes.orderText}>
                Items: <span>{integrationOrder.totalItems}</span>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1SemiBoldInter" style={{ marginTop: 12, marginBottom: 20 }}>
                Total: {moneyStr(integrationOrder.total_price)}
              </Typography>
              <Typography variant="body3MediumInter" style={{ marginTop: 12, marginBottom: 8 }}>
                Fullfilment status
              </Typography>
              <Box style={{ maxWidth: 164 }}>
                {integrationOrder.swagup_order && (
                  <ShipmentStatusRounded
                    status={integrationOrder.swagup_order.status}
                    rounded
                    withTooltip={integrationOrder.swagup_order.status?.length > 23}
                    statusTooltip
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body3MediumInter" style={{ marginTop: 18, marginBottom: 8 }}>
                Delivery status
              </Typography>
              <Typography variant="body4MediumInter" className={classes.lightText} style={{ marginBottom: 12 }}>
                {toSentenceCase(integrationOrder.status)}
              </Typography>

              <Typography variant="body3MediumInter" style={{ marginBottom: 8 }}>
                Delivery method
              </Typography>
              <Typography variant="body4MediumInter" className={classes.lightText}>
                {integrationOrder.swagup_order?.delivery_method?.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end" alignItems="center" style={{ height: '100%' }}>
                <Grid item>
                  {integrationOrder.error_message && (
                    <Button
                      variant="primary"
                      size="small"
                      onClick={() => onRetry(integrationOrder)}
                      className={classes.retryButton}
                    >
                      Retry
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3} />
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <StylessButton onClick={() => setOpen(prev => !prev)}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography component="p" variant="body3RegularInter" style={{ fontSize: 12, color: '#3577D4' }}>
                      Order details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <KeyboardArrowDownIcon className={classes.buttonIcon} />
                  </Grid>
                </Grid>
              </StylessButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {open && (
        <Box style={{ paddingTop: 24 }}>
          <Box style={{ borderTop: '1px solid #EBEDF0', paddingTop: 24, paddingBottom: 24 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body4RegularInter" className={classes.orderText} style={{ marginBottom: 16 }}>
                  Recipient
                </Typography>
                <Typography variant="body3RegularInter" style={{ marginBottom: 8 }}>
                  {joinFields([integrationOrder.first_name, integrationOrder.last_name], ' ')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body3RegularInter" style={{ marginBottom: 16 }}>
                  Contact information
                </Typography>
                <Typography variant="body4RegularInter" className={classes.orderText} style={{ marginBottom: 8 }}>
                  {integrationOrder.customer_email}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid container className={classes.billingAddress}>
                  <Grid item xs>
                    <Typography variant="body3RegularInter" style={{ marginBottom: 16 }}>
                      Shipping Address
                    </Typography>
                  </Grid>
                  <Grid item>
                    {integrationOrder.error_message && (
                      <IconButton onClick={() => setOpenRecipient(true)} className={classes.editIconButton}>
                        <Edit className={classes.editIcon} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Typography variant="body4RegularInter" className={classes.orderText} style={{ marginBottom: 8 }}>
                  {joinFields(
                    [
                      integrationOrder.address1,
                      integrationOrder.address2,
                      integrationOrder.city,
                      integrationOrder.region,
                      integrationOrder.postal_code,
                      integrationOrder.country_code
                    ],
                    ' '
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body3RegularInter" style={{ marginBottom: 16 }}>
                  Shipping Cost
                </Typography>
                <Typography variant="body4MediumInter" className={classes.orderText} style={{ marginBottom: 8 }}>
                  {moneyStr(integrationOrder.swagup_order?.total_shipping || 0)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ borderTop: '1px solid #EBEDF0', paddingTop: 16, paddingBottom: 24 }}>
            <Typography variant="body2RegularInter">Items</Typography>
            <Box style={{ paddingTop: 0 }}>
              {integrationOrder.line_items.map((item, index) => (
                <Box key={item.id} style={{ borderTop: index ? '1px solid #EAEEFA' : undefined, padding: '12px 0px' }}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Box className={classes.integrationImageWrapper}>
                        <Img src={item.variant_image} alt={item.product_name} className={classes.integrationImage} />
                      </Box>
                    </Grid>
                    <Grid item xs style={{ paddingLeft: 24 }}>
                      <Typography variant="body3SemiBoldInter" className={classes.itemTitle}>
                        {item.product_name || 'Something'}
                      </Typography>
                      <Grid container justifyContent="center" alignItems="center" className={classes.itemVariant}>
                        <Grid item>{item.variant_name}</Grid>
                      </Grid>
                      <Typography variant="body4SemiBoldInter" className={classes.itemSKU}>
                        SKU: <span>{item.variant_sku}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body3SemiBoldInter" className={classes.itemPricing}>
                        {moneyStr(item.variant_price)} X {item.quantity}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body3SemiBoldInter" className={classes.itemPricing}>
                        {moneyStr(parseFloat(item.variant_price) * item.quantity)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <EditOrderRecipient
        open={openRecipient}
        handleSubmit={handleSubmit}
        onClose={() => setOpenRecipient(false)}
        defaultValues={recipientInfo}
      />
    </Box>
  );
};

export default IntegrationOrderItem;
