import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Profile from './Profile';
import styles from './styles/commonStyles';

const useStyles = makeStyles(styles);

const ProfileContainer = () => {
  const classes = useStyles();

  return (
    <div style={{ maxWidth: '80%' }}>
      <Typography variant="h3" className={classes.title}>
        Profile
      </Typography>
      <hr className={classes.line} />
      <Profile />
    </div>
  );
};

export default ProfileContainer;
