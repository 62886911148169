import { useCallback } from 'react';
import { isEmpty, round } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMembership } from '../contexts/membershipContext';

const useDiscountPricing = () => {
  const { swagupMembershipPlans } = useFlags();
  const { currentMembership } = useMembership();

  const getDiscountPrice = useCallback(
    (price, discount = 'product') => {
      if (!swagupMembershipPlans) return price;
      const currentDicount = currentMembership[`${discount}_discount`];
      if (!currentDicount) return price;
      return round(price - (price * currentDicount) / 100, 2);
    },
    [currentMembership, swagupMembershipPlans]
  );

  const orderProductPrice = useCallback(
    product =>
      product.price_with_discount !== undefined ? product.price_with_discount : getDiscountPrice(+product.price),
    [getDiscountPrice]
  );

  const getOrderWithDiscount = useCallback(
    order => {
      if (isEmpty(order) || !swagupMembershipPlans) return order;

      const discountedOrder = {
        ...order,
        products: order.products.map(product => ({
          ...product,
          price_without_discount: product.price,
          price: orderProductPrice(product),
          rush_fee_without_discount: +product.rush_fee,
          rush_fee: +product.rush_fee_with_discount,
          product: {
            ...product.product,
            price_without_discount: product.product.price,
            price: orderProductPrice(product),
            items: product.product.items.map(item => ({
              ...item,
              price_per_unit_based_on_100_without_discount: item.price_per_unit_based_on_100,
              price_per_unit_based_on_100: getDiscountPrice(+item.price_per_unit_based_on_100).toFixed(2),
              product: {
                ...item.product,
                price_without_discount: item.product.price,
                price: getDiscountPrice(+item.product.price)
              }
            }))
          }
        }))
      };
      return discountedOrder;
    },
    [getDiscountPrice, orderProductPrice, swagupMembershipPlans]
  );

  const getProductWithDiscount = product =>
    swagupMembershipPlans
      ? {
          ...product,
          price_per_unit_based_on_100: getDiscountPrice(+product.price_per_unit_based_on_100),
          price_per_unit_based_on_100_without_discount: +product.price_per_unit_based_on_100,
          items: product.items.map(i => ({
            ...i,
            price_per_unit_based_on_100: getDiscountPrice(+i.price_per_unit_based_on_100),
            price_per_unit_based_on_100_without_discount: +i.price_per_unit_based_on_100,
            product: {
              ...i.product,
              price: getDiscountPrice(+i.price_per_unit_based_on_100),
              price_without_discount: +i.price_per_unit_based_on_100
            }
          }))
        }
      : product;

  const getProductsWithDiscount = products =>
    swagupMembershipPlans ? products.map(product => getProductWithDiscount(product)) : products;

  const getCatalogWithDiscount = useCallback(
    catalog => {
      if (!swagupMembershipPlans) return catalog;
      return catalog.map(category => ({
        ...category,
        items: category.items.map(item => ({
          ...item,
          price: getDiscountPrice(+item.price).toFixed(2),
          price_without_discount: round(+item.price, 2).toFixed(2)
        }))
      }));
    },
    [getDiscountPrice, swagupMembershipPlans]
  );
  return {
    getOrderWithDiscount,
    getDiscountPrice,
    getProductWithDiscount,
    getProductsWithDiscount,
    getCatalogWithDiscount
  };
};

export default useDiscountPricing;
