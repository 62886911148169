import * as React from 'react';
import { makeStyles, Grid, Paper, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { round } from 'lodash';
import { setDefaultPaymentProfile } from '../../../../../../actions';
import PaymentProfilesModal from '../../../../../global/sendSwagReviewCommons/PaymentProfilesModal';
import ErrorAlert from '../../../../../shared/ErrorAlert';
import { useCompany, useIsAchPrepaid } from '../../../../../../hooks';
import { getShipmentSummary, canSendSwag } from '../../../../../../helpers/utils';
import styles from './styles/ShippingSummary';
import { CustomTooltip, FinalPaymentItem, ShipmentSection } from './common';
import PaymentSection from './PaymentSection';

const useStyles = makeStyles(styles);

const ShippingSummary = ({
  balance,
  paymentProfiles,
  useCreditsFirst,
  errorMessage,
  onError,
  onContinue,
  canPlaceOrder,
  inputValue,
  invalid,
  hasSomeInvalidShippingDate,
  processSendSwag,
  preventFurtherActions = false
}) => {
  const classes = useStyles();

  const isAchPrepaid = useIsAchPrepaid();
  const { data: company } = useCompany();
  const { allow_negative_credit_balance: allowNegativeCreditBalance } = company || {};
  const [showPaymentsModal, setShowPaymentsModal] = React.useState(false);

  const shipment = useSelector(state => state.shipmentGroup);
  const paymentProfile = paymentProfiles.find(pm => pm.default);

  const dispatch = useDispatch();
  const handleSelectDefaultPaymentProfile = paymentProfileId => {
    dispatch(setDefaultPaymentProfile(company.id, paymentProfileId, true));
    setShowPaymentsModal(false);
  };

  const summary = React.useMemo(() => getShipmentSummary(shipment), [shipment]);
  const totalItems = summary.domestic.numberOfItems + summary.international.numberOfItems;
  const total = summary.domestic.total + summary.international.total;
  const totalBeforeDiscount = summary.domestic.totalBeforeDiscount + summary.international.totalBeforeDiscount;

  const creditCardIssue =
    canPlaceOrder && !canSendSwag(company, total, paymentProfiles, useCreditsFirst, isAchPrepaid, inputValue);
  const disableContinue =
    creditCardIssue || hasSomeInvalidShippingDate || processSendSwag || invalid || preventFurtherActions;

  const getTooltipErrorText = () => {
    switch (true) {
      case creditCardIssue:
        return 'Please add a credit card or add funds to your swag card balance to continue.';
      case hasSomeInvalidShippingDate:
        return 'Some shipping dates are invalid. Please go back and reschedule the shipments in order to continue.';
      case processSendSwag:
        return 'There is a pending Send Swag request being processed, this could take up to 10 mins to complete.';
      case invalid:
        return 'The use Swag Card credits you are attempting is not valid.';
      case preventFurtherActions:
        return 'Sending Swag is not available either due to a negative Swag Card Balance or if the product selected has a payment due.';
      default:
        return 'There is some general error. Please contact you AE.';
    }
  };

  if (!company) return null;

  return (
    <>
      <Paper elevation={0} className={classes.card}>
        <p className={classes.subTitle}>Shipping summary</p>
        {summary.domestic.numberOfRecipients > 0 && (
          <Grid container className={classes.summarySection}>
            <ShipmentSection
              title="Domestic"
              employeesQty={summary.domestic.numberOfRecipients}
              itemsQty={summary.domestic.numberOfItems}
              totalPrice={summary.domestic.total}
            />
          </Grid>
        )}
        {summary.international.numberOfRecipients > 0 && (
          <Grid container className={classes.summarySection}>
            <ShipmentSection
              title="International"
              employeesQty={summary.international.numberOfRecipients}
              itemsQty={summary.international.numberOfItems}
              totalPrice={summary.international.total}
            />
          </Grid>
        )}
        <Grid container>
          <PaymentSection
            allowNegativeCreditBalance={allowNegativeCreditBalance}
            useCreditsFirst={useCreditsFirst}
            paymentProfiles={paymentProfiles}
            totalItems={totalItems}
            total={total}
            totalWithoutDiscount={totalBeforeDiscount}
            usedCredits={inputValue}
            balance={balance}
            onShowPaymentsProfiles={() => setShowPaymentsModal(true)}
          />
        </Grid>
        {canPlaceOrder && (
          <FinalPaymentItem
            allowToPayWithCredits={useCreditsFirst}
            swagCardPayment={inputValue}
            creditCardPayment={round(total - inputValue, 2)}
          />
        )}
        <Grid container justifyContent="center" style={{ marginTop: 12, position: 'relative' }}>
          {errorMessage && <ErrorAlert error={errorMessage} onError={onError} className={classes.errorAlert} />}
          <CustomTooltip
            title={<div dangerouslySetInnerHTML={{ __html: getTooltipErrorText() }} />}
            disableHoverListener={!disableContinue}
            fullWidth
          >
            <div style={{ width: '100%' }}>
              <Button
                className={classes.sendButton}
                onClick={() => onContinue(total)}
                disabled={disableContinue}
                loading={processSendSwag}
                fullWidth
              >
                {canPlaceOrder ? 'Pay & Send Swag' : 'Continue'}
              </Button>
            </div>
          </CustomTooltip>
        </Grid>
      </Paper>
      <PaymentProfilesModal
        open={showPaymentsModal}
        onClose={() => setShowPaymentsModal(false)}
        paymentProfile={paymentProfile}
        paymentProfiles={paymentProfiles}
        onSelectDefaultPaymentProfile={handleSelectDefaultPaymentProfile}
      />
    </>
  );
};

export default ShippingSummary;
