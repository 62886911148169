import axios from 'axios';
import gtm from '../utils/gtm';
import catalogApi from '../apis/swagup/catalog';
import {
  CART_API_CALL_START,
  CART_API_CALL_SUCCESS,
  CART_API_CALL_ERROR,
  CART_ADD_PRODUCT,
  CART_REMOVE_PRODUCT,
  CART_UPDATE_PRODUCT,
  CART_CHANGE_QUANTITY,
  CART_CHANGE_NUMBER_OF_COLORS,
  CART_REFRESH_PRICES,
  CART_CLEAR
} from './types';
import { concatIfNotExists } from '../utils/array';

export const refreshPrices = () => async (dispatch, getState) => {
  const { items, numberOfColors, pack, presetQuantities } = getState().cart;

  if (!items.length) return;

  const quantities = pack ? concatIfNotExists(presetQuantities, pack.quantity) : presetQuantities;

  try {
    dispatch({ type: CART_API_CALL_START });

    const prices = await catalogApi.getPricing(items, numberOfColors, quantities, pack === null, 'cart');

    dispatch({ type: CART_API_CALL_SUCCESS });
    dispatch({ type: CART_REFRESH_PRICES, payload: prices });
  } catch (error) {
    if (!axios.isCancel(error))
      dispatch({
        type: CART_API_CALL_ERROR,
        payload: error
      });
  }
};

export const addPresetPackToCart = (product, quantity = undefined, colorIdx = null, prices = undefined) => (
  dispatch,
  getState
) => {
  const { pack } = getState().cart;
  dispatch({
    type: CART_ADD_PRODUCT,
    payload: { product, quantity: quantity || (pack ? 1 : 100), colorIdx, prices }
  });
  dispatch(refreshPrices());
  gtm.addToCart(product);
};

export const addProductToCart = (product, quantity = undefined, colorIdx = null, prices = undefined) => (
  dispatch,
  getState
) => {
  const { pack } = getState().cart;
  quantity = quantity || (pack ? 1 : 100);
  product = { ...product, quantity };
  const commonData = { product, quantity };

  dispatch({
    type: CART_ADD_PRODUCT,
    payload: { ...commonData, colorIdx, prices }
  });
  dispatch(refreshPrices());
  gtm.addToCart(commonData);
};

export const removeProductFromCart = product => dispatch => {
  dispatch({ type: CART_REMOVE_PRODUCT, payload: product });
  dispatch(refreshPrices());
  gtm.removeFromCart(product);
};

export const updateProductInCart = (id, product, updatePrices = true) => dispatch => {
  dispatch({ type: CART_UPDATE_PRODUCT, payload: { id, product } });
  if (updatePrices) dispatch(refreshPrices());
};

export const changeQuantity = quantity => dispatch => {
  dispatch({ type: CART_CHANGE_QUANTITY, payload: quantity });
  dispatch(refreshPrices());
};

export const changeNumberOfColors = numberOfColors => dispatch => {
  dispatch({ type: CART_CHANGE_NUMBER_OF_COLORS, payload: numberOfColors });
  dispatch(refreshPrices());
};

export const clearCart = () => ({ type: CART_CLEAR });
