import React, { Component } from 'react';
import isNaN from 'lodash/isNaN';
import { connect } from 'react-redux';
import { Grid, withStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@swagup-com/components';
import { SubmitButton } from '../shared';
import styles from './styles/StorageCard';
import ShippingBreakDown from './ShippingBreakDown';
import ShippingCreditOptions from './ShippingCreditOptions';
import { storageTypes } from './storageTypes';
import {
  sumByQuantityParseInt,
  isPack,
  totalStorageShippingPrice,
  getShippingPrice,
  moneyStr
} from '../../helpers/utils';
import { getProductSizes, getStoragePrice } from './common';
import { updateProofQuantitiesByDirectory } from './MultishippingCommon';
import PaymentSection from '../paymentSection/PaymentSection';

const recalculateSelectedCredit = (proofs, storageType, storagePrices, isInternational, individualPackPrices) =>
  proofs
    .map(proof => {
      const sizes = getProductSizes(proof);
      const quantities = sumByQuantityParseInt(sizes);
      const storagePrice = parseFloat(storagePrices[proof.product.storage_category]);
      const shippingPrice =
        isPack(proof.product.record_type) && !storageType.isPayAsShip
          ? getShippingPrice(isInternational, individualPackPrices)
          : 'TBD';
      return totalStorageShippingPrice(
        quantities,
        storageType.isPayAsShip ? storagePrice : 0,
        Number(shippingPrice) || 0
      );
    })
    .reduce((total1, total2) => total1 + total2, 0);

class StorageCard extends Component {
  state = {
    creditContinueOpen: false,
    openModal: false,
    recommendedCredit: 0,
    selectedCredit: 0,
    storageTotal: 0,
    customCreditChange: false
  };

  componentDidMount() {
    const { props } = this;
    const {
      proofs,
      storageType,
      storagePrices,
      individualPackPrices,
      isInternational,
      sendCredit,
      multishipping
    } = props;

    const finalProofs = updateProofQuantitiesByDirectory(proofs, multishipping);

    const total = recalculateSelectedCredit(
      finalProofs,
      storageType,
      storagePrices,
      isInternational,
      individualPackPrices
    );
    if (total) {
      this.setState({ storageTotal: total, recommendedCredit: total }, () => sendCredit(0));
    } else if (storageType === storageTypes.creditForShipping) {
      sendCredit(0);
    } else {
      let storageTotalTemp = 0;
      finalProofs.forEach(proof => {
        const sizes = getProductSizes(proof);
        const quantities = sumByQuantityParseInt(sizes);
        storageTotalTemp += getStoragePrice(proof.product, storagePrices) * quantities;
      });
      this.setState({ storageTotal: storageTotalTemp });
      this.setState(
        { recommendedCredit: storageTotalTemp, selectedCredit: storageTotalTemp },
        () => total && sendCredit(total)
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const {
      proofs,
      storageType,
      storagePrices,
      individualPackPrices,
      isInternational,
      sendCredit,
      multishipping
    } = props;

    if (prevProps.isInternational !== isInternational) {
      const finalProofs = updateProofQuantitiesByDirectory(proofs, multishipping);

      const total = recalculateSelectedCredit(
        finalProofs,
        storageType,
        storagePrices,
        isInternational,
        individualPackPrices
      );
      if (total) {
        this.setState({ recommendedCredit: total, selectedCredit: total }, () => sendCredit(total));
      } else {
        let storageTotalTemp = 0;
        proofs.forEach(proof => {
          const sizes = getProductSizes(proof);
          const quantities = sumByQuantityParseInt(sizes);
          storageTotalTemp += getStoragePrice(proof.product, storagePrices) * quantities;
        });
        this.setState({ storageTotal: storageTotalTemp });
        this.setState(
          { recommendedCredit: storageTotalTemp, selectedCredit: storageTotalTemp },
          () => total && sendCredit(total)
        );
      }
    }
  }

  handleSelectedCredit = credit => {
    const { sendCredit } = this.props;
    sendCredit(credit);
  };

  toggleOpenModal = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal
    }));
  };

  render() {
    const {
      creditContinueOpen,
      openModal,
      recommendedCredit,
      selectedCredit,
      storageTotal,
      customCredit,
      customCreditChange
    } = this.state;
    const {
      classes,
      storageType,
      setStorageType,
      setStorage,
      buttonText,
      storagePrices,
      individualPackPrices,
      sendCredit,
      isInternational,
      proofs,
      multishipping,
      onValidityChange,
      handleBeforeSubmit,
      handleAfterPayConfirmation,
      handleErrorDuringConfirmation,
      isValidCreditCard,
      setIsOpen,
      isOpen,
      setClose,
      // handleUseCreditFirst,
      isBusy
    } = this.props;
    const finalProofs = updateProofQuantitiesByDirectory(proofs, multishipping);

    const handleAddCreditButtonClick = () => {
      sendCredit(selectedCredit || storageTotal);
      if (storageType === storageTypes.creditForShipping) {
        this.setState({ creditContinueOpen: false });
      } else {
        setStorage();
      }
      setIsOpen(true);
    };

    const handleAddPaymentButtonClick = () => {
      if (setStorageType) setStorageType(storageType);
      setStorage();
      this.setState({ creditContinueOpen: true });
    };

    return (
      <div className={classes.CreditForShippingCard}>
        <Grid container>
          <Grid item xs={12} sm={1} md={1}>
            <div className={classes.IconContainer}>
              <img src={storageType.icon} alt={storageType.title} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            <Grid container style={{ paddingTop: 7 }}>
              <Grid item>
                <p className={classes.StorageCardTitle}>{storageType.title}</p>
                <p className={classes.StorageCardDescription}>{storageType.description}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Grid container className={classes.QuantityContainer}>
              {storageType !== storageTypes.swagupWareHouse && (
                <Grid item>
                  {isOpen ? (
                    <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={() => {
                        if (storageType === storageTypes.creditForShipping) sendCredit(0);
                        setClose();
                      }}
                    >
                      <CloseIcon classes={{ root: classes.closeIcon }} />
                    </IconButton>
                  ) : (
                    <Grid item>
                      <Button size="small" onClick={handleAddCreditButtonClick}>
                        {storageType === storageTypes.creditForShipping ? 'Add Credit' : 'Add Payment'}
                      </Button>
                      {storageType === storageTypes.creditForShipping && (
                        <Grid item align="center" style={{ marginTop: 30 }}>
                          <p className={classes.EstimateCreditLabel}>
                            <font color="#8d9299">Estimated cost</font>
                            <font color="#0f2440">{` ${moneyStr(selectedCredit || storageTotal, 0)}`}</font>
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          {storageType === storageTypes.swagupWareHouse && (
            <Grid item xs={12}>
              <p className={classes.StorageCardSubTitle}>While in the warehouse we charge a one time fee for storage</p>
            </Grid>
          )}

          <Grid item xs={12} style={{ overflowX: 'auto' }}>
            {storageType === storageTypes.swagupWareHouse && (
              <ShippingBreakDown
                storageType={storageType}
                onClose={this.toggleOpenModal}
                openModal={openModal}
                storagePrices={storagePrices}
                individualPackPrices={individualPackPrices}
                isInternational={isInternational}
                proofs={finalProofs}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {isOpen && (
              <Grid container>
                {storageType === storageTypes.creditForShipping ? (
                  <Grid container>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={11} lg={9}>
                        <ShippingCreditOptions
                          recommendedCredit={recommendedCredit}
                          handleCredit={this.handleSelectedCredit}
                          customCreditEmpty={isNaN(customCredit)}
                          creditChanges={customCreditChange}
                        />
                      </Grid>
                      {!creditContinueOpen && (
                        <Grid container style={{ marginTop: 26 }}>
                          <Button variant="primary" onClick={handleAddPaymentButtonClick}>
                            {buttonText}
                          </Button>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {creditContinueOpen && (
                        <Grid container style={{ marginTop: 26, width: '100%' }}>
                          <PaymentSection
                            onValidityChange={onValidityChange}
                            handleBeforeSubmit={handleBeforeSubmit}
                            handleAfterPayConfirmation={handleAfterPayConfirmation}
                            handleErrorDuringConfirmation={handleErrorDuringConfirmation}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <SubmitButton
                                variant="primary"
                                formName="paymentSectionForm"
                                disabled={!isValidCreditCard || isBusy}
                              >
                                PLACE ORDER
                              </SubmitButton>
                            </Grid>
                          </PaymentSection>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    {storageType === storageTypes.payAsIShip && (
                      <Grid container>
                        <Grid item xs={12} style={{ marginTop: 24 }}>
                          <Grid container style={{ width: '100%' }}>
                            <PaymentSection
                              onValidityChange={onValidityChange}
                              handleBeforeSubmit={handleBeforeSubmit}
                              handleAfterPayConfirmation={handleAfterPayConfirmation}
                              handleErrorDuringConfirmation={handleErrorDuringConfirmation}
                              // Remove the ability to pay for products with credit.
                              // This might be turned on again in the future.
                              // handleUseCreditFirst={handleUseCreditFirst}
                            >
                              <Grid item xs={12} sm={6} md={6}>
                                <SubmitButton
                                  variant="primary"
                                  formName="paymentSectionForm"
                                  disabled={!isValidCreditCard || isBusy}
                                >
                                  PLACE ORDER
                                </SubmitButton>
                              </Grid>
                            </PaymentSection>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  proofs: state.reorderProducts,
  multishipping: state.multishipping
});

export default connect(mapStateToProps)(withStyles(styles)(StorageCard));
