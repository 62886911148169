import * as React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Button } from '@swagup-com/components';
import debounce from 'lodash/debounce';
import { useIntercom } from 'react-use-intercom';
import { EmptyState, Helmet, CenteredGrid, Pagination, Alert } from '../../../shared';
import { useInvoice, useOrderRefetchInterval, usePaginatedQuery, useQueryParams } from '../../../../hooks';
import Order from './Order';
import InvoicePaymentDialog from '../../../account/InvoicePaymentDialog';
import tags from '../../../../apis/seoTags';
import Loader from '../../../global/Loader';
import apiPaths from '../../../../helpers/apiPaths';
import { accountOrdersApi } from '../../../../apis/swagup';
import { TopFilters } from './TopFilters';
import { isOrderProcessing } from './CommonOrderElements';
import log from '../../../../logger';
import SearchSortFilter from '../../../shared/SearchSortFilter';

const useStyles = makeStyles(theme => ({
  container: { paddingBottom: 42 },
  title: {
    lineHeight: 1,
    fontSize: 40,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  ordersContainer: { marginBottom: 20 },
  alert: { '& div': { width: '100%' } },
  text: {
    padding: 0,
    color: '#3577d4',
    fontWeight: 600,
    fontSize: 14,
    height: 17,
    marginTop: 5,
    float: 'right'
  }
}));

const OrdersEmptyState = ({ classes }) => {
  const { show } = useIntercom();

  return (
    <Grid item xs={12} className={classes.ordersContainer}>
      <div className={classes.alert}>
        <Alert>
          <Grid container alignItems="center" style={{ fontSize: 14, color: '#0b1829' }}>
            <Grid item lg={11} md={10} sm={9}>
              If you placed orders outside the Dashboard you will not see those orders here. We are working to update
              this! In the meantime, feel free to contact us for any questions.
            </Grid>
            <Grid item lg={1} md={2} sm={3}>
              <Button variant="text" onClick={show} className={classes.text}>
                Contact us
              </Button>
            </Grid>
          </Grid>
        </Alert>
      </div>
      <EmptyState
        title="No Orders Placed"
        image={{
          path: '/images/dashboard/empty-orders.png',
          alt: 'No orders',
          text: "You've placed no Swag orders yet."
        }}
        button={{
          link: '/product-onboarding',
          text: 'Start new order'
        }}
      />
    </Grid>
  );
};

const perPageOptions = [12, 24, 36, 48];

const CompletedOrders = () => {
  const [refetchInterval, setRefetchInterval] = React.useState();
  const [invoiceIdToOpen, setInvoiceIdToOpen] = React.useState();

  const classes = useStyles();
  const history = useHistory();

  const query = useQueryParams();
  const sort = query.get('sort') || '-created_at';
  const search = query.get('search') || '';
  const invoiceNameToOpen = query.get('open');

  const { data: invoice, isLoading: isFetchingInvoice, isFetched: isInvoiceFetched } = useInvoice(invoiceIdToOpen);

  const { query: queryResult, pagination } = usePaginatedQuery({
    queryKey: [apiPaths.accountOrders, sort, search],
    queryFn: (limit, offset) => {
      const apiQuery = new URLSearchParams();
      apiQuery.set('limit', limit);
      apiQuery.set('ordering', sort);
      if (search) apiQuery.set('search', search);
      if (offset > 0) apiQuery.set('offset', offset);
      return accountOrdersApi.fetchOrders(apiQuery.toString());
    },
    queryOptions: { refetchInterval },
    perPageOptions
  });

  const queryClient = useQueryClient();
  React.useEffect(() => {
    queryClient.invalidateQueries(apiPaths.accountOrders);
  }, [queryClient]);

  const { data, isFetching, isPreviousData } = queryResult;
  const orders = data?.results;

  const orderInProcess = orders
    ?.filter(isOrderProcessing)
    ?.reduce((latest, o) => (latest?.created_at > o.created_at ? latest : o), undefined);
  const nextRefetchInterval = useOrderRefetchInterval(orderInProcess, isFetching);

  React.useEffect(() => {
    log.debug('Completed orders nextRefetchInterval:', nextRefetchInterval);
    setRefetchInterval(nextRefetchInterval);
  }, [nextRefetchInterval]);

  const removeOpenQueryParam = React.useCallback(() => {
    query.delete('open');
    history.replace({ pathname: history.location.pathname, search: query.toString() });
  }, [query, history]);

  React.useEffect(() => {
    if (invoiceNameToOpen?.length === 0 || invoiceNameToOpen === 'Processing') {
      removeOpenQueryParam();
    } else if (orders && invoiceNameToOpen) {
      const order = orders.find(o => o.invoice_name === invoiceNameToOpen);
      if (!order) {
        removeOpenQueryParam();
      } else {
        setInvoiceIdToOpen(order.invoice);
      }
    }
  }, [invoiceNameToOpen, orders, removeOpenQueryParam]);

  const navigateToFirstPage = React.useCallback(() => {
    query.set('page', 1);
    history.replace({ pathname: history.location.pathname, search: query.toString() });
  }, [query, history]);

  const { count, ...paginationProps } = pagination;

  const isEmptyState = count === 0 && search === '' && !isPreviousData;

  if (!data) return <Loader absolute />;

  const searchSortFilterConfig = {
    search: { placeholder: 'Search Order' },
    sort: { queryParam: 'sort', options: { '-created_at': 'Most Recent', created_at: 'Less Recent' } }
  };

  return (
    <CenteredGrid container justifyContent="center" className={classes.container}>
      <Helmet tags={tags.ordersCompleted} />
      {isEmptyState ? (
        <OrdersEmptyState classes={classes} />
      ) : (
        <>
          <Grid item container xs={12}>
            <SearchSortFilter config={searchSortFilterConfig} />
          </Grid>
          <Box position="relative" width="100%">
            <Grid item container justifyContent="center" xs={12}>
              {count === 0 ? (
                <EmptyState
                  title="No Orders found"
                  image={{
                    path: '/images/orders/empty-contacts.png',
                    alt: 'No orders found',
                    text: 'Remove filters to see all the orders.'
                  }}
                  button={{
                    link: '/orders-completed',
                    text: 'Remove filters'
                  }}
                />
              ) : (
                <>
                  <Grid item xs={12} className={classes.ordersContainer}>
                    {orders.map(order => (
                      <Order
                        key={order.id}
                        order={order}
                        isLoading={isFetchingInvoice && order.invoice_name === invoiceNameToOpen}
                      />
                    ))}
                  </Grid>
                  <Pagination {...paginationProps} count={count} endText="completed" />
                </>
              )}
            </Grid>
          </Box>
        </>
      )}
      <InvoicePaymentDialog
        invoice={invoice}
        open={Boolean(invoiceNameToOpen) && isInvoiceFetched}
        onClose={removeOpenQueryParam}
      />
    </CenteredGrid>
  );
};

export default CompletedOrders;
