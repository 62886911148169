import * as React from 'react';
import { Box, Grid, Snackbar, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography, Button } from '@swagup-com/components';
import { ActionCardV2, InfoCard } from './Cards';
import OrderSummaryV2 from '../summary/OrderSummaryV2';
import ReviewProducts from './ReviewProducts';
import { useOrder } from '../OrderContext';
import { changeColorLightness } from '../../../../shared/styles/utils';
import WarehouseOverview from './WarehouseOverview';
import WarehouseOverviewV2 from './WarehouseOverviewV2';
import SendToWarehouse from './SendToWarehouse';
// import SendToWarehouseV2 from './SendToWarehouseV2';
import { Alert } from '../../../../shared';
import { opportunityStatus } from '../../../../../apis/constants';
import ArrowTooltip from '../common/Tooltip';
import gtm from '../../../../../utils/gtm';
import { cannotContinueMessage } from '../common/utilsOrder';
import apiPaths from '../../../../../helpers/apiPaths';
import { useLeftNavbar } from '../../../../../contexts/leftNavbar';
import { FileDownloadIcon } from '../../../../icons';

const commonLink = {
  alignItems: 'center',
  display: 'inline-flex',
  fontFamily: 'Gilroy-SemiBold',
  fontSize: 14,
  padding: '3px 8px',
  width: 'fit-content',
  letterSpacing: 'normal',
  height: 'auto'
};

const useStyles = makeStyles(theme => ({
  continueButton: {
    height: 56,
    width: 236,
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#fafafa',
      color: '#787b80'
    }
  },
  goBack: {
    alignItems: 'center',
    color: '#787b80',
    display: 'inline-flex',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    marginLeft: 0,
    marginTop: 8,
    paddingRight: 10,
    '&:hover': { color: changeColorLightness('#787b80') }
  },
  goBackIcon: {
    cursor: 'pointer',
    fontSize: 16,
    height: 16,
    marginRight: 10
  },
  shipmentLink: {
    ...commonLink,
    color: '#3577d4',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  warehouseLink: ({ showWarning }) => ({
    ...commonLink,
    color: showWarning ? '#fa902d' : '#3577d4',
    '&:hover': { color: changeColorLightness(showWarning ? '#fa902d' : '#3577d4') }
  }),
  linkIcon: {
    cursor: 'pointer',
    fontSize: 16,
    marginLeft: 6
  },
  title: {
    color: '#0b1829',
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '34px',
    margin: '0 0 4px 0'
  },
  cardsContainer: {
    position: 'sticky',
    height: '100%',
    top: 162
  },
  alert: {
    top: 104
  },
  reviewProducts: {
    paddingRight: ({ leftBarNavigation }) => (leftBarNavigation ? 0 : 20),
    maxWidth: ({ leftBarNavigation, leftNavOpen }) =>
      leftBarNavigation && leftNavOpen ? 'calc(100% - 440px)' : '100%',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '350px !important'
    }
  },
  fileDownload: {
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer'
  },
  fileDownloadLabel: {
    color: '#125CFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px'
  }
}));

const mapOrderProductsToWarehouseProofs = orderProducts =>
  orderProducts.map(({ id, sizes }) => ({
    proof: Number(id),
    sizes: sizes.filter(size => size.quantity > 0).map(s => ({ size: s.size.id, quantity: s.quantity }))
  }));

export const InactiveCard = () => {
  return (
    <Box
      border="solid 1px #FF9800"
      bgcolor="#FFE0B2"
      borderRadius={4}
      minWidth={450}
      height={94}
      display="flex"
      pt="10px"
      pr="0px"
      pb="12px"
      pl="10px"
      mt="20px"
    >
      <Grid container item xs={12} direction="column" justifyContent="space-between">
        <Grid item container alignItems="center">
          <WarningIcon style={{ color: '#EF6C00' }} />
          <Typography variant="body3SemiBoldInter" style={{ marginLeft: 8 }}>
            Size no longer available
          </Typography>
        </Grid>

        <Typography variant="body3RegularInter" style={{ marginLeft: 30 }}>
          You have sizes in your order that are currently out of stock. Please update the quantities in your order
          accordingly.
        </Typography>
      </Grid>
    </Box>
  );
};

const RequestDetailsV2 = () => {
  const { enableUnallocatedProductsManagementV2, swagUpCredits, leftBarNavigation, downloadQuote } = useFlags();
  const queryClient = useQueryClient();
  const order = useOrder();

  const { productsWithInactiveSizes, warehouseProofsWithInactiveSizes, products } = order;

  const orderHasInactiveSizes = productsWithInactiveSizes.length > 0;

  const leftNavBarContext = useLeftNavbar();

  const leftNavOpen = leftNavBarContext?.leftNavOpen || false;

  const classes = useStyles({ showWarning: warehouseProofsWithInactiveSizes.size > 0, leftBarNavigation, leftNavOpen });

  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [showUpdateAlert, setShowUpdateAlert] = React.useState(false);

  const noWarehouseShipment =
    order.warehouseProofs?.length === 0 || order.warehouseProofs.every(wp => wp.sizes.length === 0);
  const [isWarehouseOverviewOpen, toggleIsWarehouseOverviewOpen] = React.useReducer(
    prev => !prev,
    location.state?.showWarehouse
  );
  const [isSendToWarehouseOpen, toggleIsSendToWarehouseOpen] = React.useReducer(prev => !prev, false);
  const warehouseToggle = noWarehouseShipment ? toggleIsSendToWarehouseOpen : toggleIsWarehouseOverviewOpen;

  const warehouseMutation = useMutation(apiPaths.warehouseProofs(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(apiPaths.opportunities);
      queryClient.invalidateQueries(apiPaths.opportunity(id));
      queryClient.invalidateQueries(apiPaths.warehouseProofs(id));
    }
  });

  const warehouseMutationPayload = React.useMemo(() => ({ id, proofs: mapOrderProductsToWarehouseProofs(products) }), [
    id,
    products
  ]);

  React.useEffect(() => {
    if (!enableUnallocatedProductsManagementV2) return;

    if (location.state?.sendProductsToWarehouse) {
      warehouseMutation.mutate(warehouseMutationPayload);
      history.replace({ ...location, state: { sendProductsToWarehouse: false } });
    }
  }, [history, location, warehouseMutation, warehouseMutationPayload, enableUnallocatedProductsManagementV2]);

  React.useEffect(() => {
    if (location.state?.showWarehouse) {
      if (!isWarehouseOverviewOpen) toggleIsSendToWarehouseOpen();
      history.replace({ ...location, state: { showWarehouse: false } });
    }
  }, [isWarehouseOverviewOpen, location, history, toggleIsSendToWarehouseOpen]);

  const handleCloseSendToWarehouse = ({ quantitiesChanged }) => {
    toggleIsSendToWarehouseOpen();
    if (quantitiesChanged) {
      setShowUpdateAlert(true);
    }
  };

  const orderHasProductsUnapproved = ![opportunityStatus.proofsApproved, opportunityStatus.proofsApprovedOld].includes(
    order.status
  );
  const orderInProductionRequest = [opportunityStatus.finalizingProofs, opportunityStatus.finalizingProofsOld].includes(
    order.status
  );

  const hasPricingError = order.pricingError?.hasError;

  const handleUpdateQuantities = () => {
    toggleIsSendToWarehouseOpen();
  };

  const fromProducts = location.state?.fromProducts;
  const [to, backToText] = fromProducts ? ['/products', 'Back to Products'] : ['/orders-requested', 'Back to Orders'];

  // const SendToWarehouseNewDesign = () => {
  //   return (
  //     <SendToWarehouseV2
  //       open={isSendToWarehouseOpen}
  //       onClose={toggleIsSendToWarehouseOpen}
  //       onSendQtyToWarehouse={handleCloseSendToWarehouse}
  //     />
  //   );
  // };

  const SendToWarehouseOldDesign = () => {
    return (
      <SendToWarehouse
        open={isSendToWarehouseOpen}
        onClose={toggleIsSendToWarehouseOpen}
        onSendQtyToWarehouse={handleCloseSendToWarehouse}
      />
    );
  };

  const WarehouseOverviewNewDesign = () => {
    return (
      <WarehouseOverviewV2
        open={isWarehouseOverviewOpen}
        onClose={toggleIsWarehouseOverviewOpen}
        onUpdateQuantities={handleUpdateQuantities}
      />
    );
  };

  const WarehouseOverviewOldDesign = () => {
    return (
      <WarehouseOverview
        open={isWarehouseOverviewOpen}
        onClose={toggleIsWarehouseOverviewOpen}
        onUpdateQuantities={handleUpdateQuantities}
      />
    );
  };

  const downloadOrderQuote = () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}${apiPaths.downloadOrderQuote(id)}`;
    window.open(url, '_blank').focus();
  };

  return (
    <Grid container style={{ gap: 14 }}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid>
          <Link to={to} className={classes.goBack}>
            <ArrowBackIcon className={classes.goBackIcon} />
            {backToText}
          </Link>
        </Grid>
        {downloadQuote && (
          <Grid>
            <div className={classes.fileDownload} onClick={() => downloadOrderQuote(id)}>
              <FileDownloadIcon />
              <span className={classes.fileDownloadLabel}>Download order quote</span>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container direction="column" item xs style={{ minWidth: 0 }}>
        <Box display="flex" width="100%" flexGrow="1" p="24px 0px" position="relative">
          <Grid item xs={6} className={classes.reviewProducts}>
            <ReviewProducts fromProducts={fromProducts} />
          </Grid>
          <Grid container direction="column" item xs={6} className={classes.cardsContainer}>
            <h2 className={classes.title}>Shipping & Storage</h2>
            {orderHasInactiveSizes && <InactiveCard />}
            <ActionCardV2
              title="Ship to Recipients"
              image={<img src="/images/shipments/truck@3x.png" alt="Swagup Truck" width={108} height={96} />}
              linkText="View Shipments"
              createdText="Shipment Qty"
              totalText="Shipment Cost"
            >
              <Button
                component={Link}
                variant="primary"
                to={`/orders-requested/${id}/select-products`}
                onClick={() => gtm.onClick('Create Shipment')}
                style={{ height: 40, width: 148, fontSize: 13, padding: 0 }}
              >
                Create Shipment
              </Button>
            </ActionCardV2>
            <ActionCardV2
              title="Store in Warehouse"
              image={<img src="/images/shipments/warehouse@3x.png" alt="Swagup Storage" width={108} height={96} />}
              createdText="Storage Qty"
              totalText="Storage Cost"
              isStorage
            >
              {SendToWarehouseOldDesign()}
              {enableUnallocatedProductsManagementV2 ? WarehouseOverviewNewDesign() : WarehouseOverviewOldDesign()}
              <Button
                variant="primary"
                onClick={warehouseToggle}
                style={{ height: 40, width: 148, fontSize: 13, padding: 0 }}
              >
                View Storage
              </Button>
            </ActionCardV2>
            {!enableUnallocatedProductsManagementV2 && <InfoCard />}
          </Grid>
        </Box>
      </Grid>
      <Grid container item style={{ width: 312 }}>
        <OrderSummaryV2>
          <ArrowTooltip
            variant="primary"
            title={cannotContinueMessage(
              orderHasProductsUnapproved,
              orderInProductionRequest,
              orderHasInactiveSizes,
              hasPricingError
            )}
          >
            <Button
              variant="primary"
              component={Link}
              to={`/orders-requested/${id}/${swagUpCredits ? 'review-order' : 'order-overview'}`}
              onClick={() => {
                gtm.onClickContinue('View order overview clicked');
              }}
              disabled={orderHasProductsUnapproved || orderHasInactiveSizes || hasPricingError}
              className={classes.continueButton}
              withIcon
            >
              Continue
            </Button>
          </ArrowTooltip>
        </OrderSummaryV2>
      </Grid>
      <Snackbar
        open={showUpdateAlert}
        classes={{ anchorOriginTopCenter: classes.alert }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowUpdateAlert(false)}
      >
        <Alert
          showIcon={<CheckIcon fontSize="small" style={{ alignSelf: 'center' }} />}
          width={270}
          style={{ paddingBottom: 20, justifyContent: 'center' }}
          onClose={() => setShowUpdateAlert(false)}
        >
          Update successful
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default RequestDetailsV2;
