import React from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import CloseButton from '../../../../modals/CloseButton';
import { imageSrcSet } from '../../../../../helpers/utils';

const useStyles = makeStyles({
  paper: {
    minWidth: 780,
    minHeight: 360,
    padding: '10px 0',
    overflowY: 'visible'
  },
  keepButton: {
    width: 208,
    height: 56,
    background: '#C62828',
    '&:hover': {
      backgroundColor: '#f44336'
    }
  },
  removeButton: {
    margin: '10px 0px',
    width: 208,
    height: 56,
    backgroundColor: 'transparent',
    '&:hover': {
      color: '#606266',
      backgroundColor: 'transparent'
    },
    '&.MuiButton-contained.Mui-disabled': {
      color: '#606266',
      backgroundColor: '#fff'
    }
  }
});

const CancelOrderModal = ({ isOpen, onClose, deleteOpportunity }) => {
  const classes = useStyles();
  return (
    <Dialog classes={{ paper: classes.paper }} open={isOpen} onClose={onClose}>
      <DialogTitle aria-labelledby="remove-contacts-dialog" style={{ minHeight: 110 }}>
        <Grid container justifyContent="flex-end">
          <CloseButton onClose={onClose} />
        </Grid>
        <Grid container justifyContent="center" style={{ marginTop: -135 }}>
          <img srcSet={imageSrcSet('/images/orders/warning.png')} alt="Warning" width="150" height="136" />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Typography variant="h5SemiBoldInter">Are you sure want to cancel this Order?</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column" alignItems="center" style={{ marginTop: 20 }}>
          <Button variant="primary" onClick={() => deleteOpportunity()} className={classes.keepButton}>
            <Typography variant="subtitle3SemiBoldInter" style={{ color: '#ffffff' }}>
              Yes, Cancel Order
            </Typography>
          </Button>
          <Button variant="alert" onClick={() => onClose()} className={classes.removeButton}>
            <Typography variant="subtitle3SemiBoldInter" style={{ color: '#606266' }}>
              No, Keep It
            </Typography>
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default CancelOrderModal;
