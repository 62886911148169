const styles = theme => ({
  container: { paddingBottom: 42 },
  formContainer: { paddingTop: 20 },
  actionsContainer: { maxWidth: 120 },
  formActionsContainer: { paddingTop: 20 },
  formFieldsContainer: { paddingLeft: 20 },
  tooltip: { maxWidth: 500 },
  center: {
    paddingTop: '40px',
    maxWidth: 1280,
    paddingLeft: 0,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 1120,
      paddingLeft: 0
    }
  },
  deleteBtn: {
    margin: 0,
    padding: 0,
    border: 'none',
    height: 48,
    minWidth: 0
  }
});

export default styles;
