import * as React from 'react';
import sumBy from 'lodash/sumBy';
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Skeleton } from '@material-ui/lab';
import { ChevronRight } from '@material-ui/icons';
import { useIsFetching } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { changeColorLightness } from '../../../../shared/styles/utils';
import { useOrder } from '../OrderContext';
import ShipmentGroups from './ShipmentGroups';
import ShipmentGroupsV2 from './ShipmentGroupsV2';
import UnallocatedDrawer from './UnallocatedDrawer';
import useUnallocatedPerProduct from '../hooks/useUnallocatedPerProduct';
import apiPaths from '../../../../../helpers/apiPaths';
import { getShipmentGroupsInfo, moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import { useStorageInfo } from '../hooks';
import { getShipmentsQty } from '../common/utilsOrder';
import useDiscountsAndRewards from '../../../../../hooks/useDiscountsAndRewards';

const useStyles = makeStyles({
  button: {
    height: 20,
    width: 130,
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    fontSize: 12,
    padding: '3px 8px',
    '&:hover': { color: changeColorLightness('#3577d4') },
    '& .MuiButton-iconSizeSmall > *:first-child': { fontSize: 14 },
    '& span > .MuiButton-endIcon.MuiButton-iconSizeSmall': {
      marginLeft: 0,
      marginRight: 0
    }
  },
  prose: {
    color: '#787b80',
    fontFamily: 'Gilroy-Medium',
    lineHeight: '20px',
    margin: 0,
    maxWidth: 256,
    paddingLeft: 8
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: '20px',
    color: '#787b80',
    margin: 0
  },
  title: {
    fontFamily: ({ info }) => (info ? 'Gilroy-Medium' : 'Gilroy-SemiBold'),
    fontSize: 16,
    lineHeight: '26px',
    color: '#0b1829',
    margin: 0,
    paddingLeft: 8
  },
  count: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    lineHeight: '28px',
    color: '#0b1829',
    margin: 0
  }
});

export const ActionCard = ({ children, image, title }) => {
  const classes = useStyles();

  return (
    <Box
      border="solid 1px #f0f2f5"
      bgcolor="white"
      borderRadius={10}
      minWidth={450}
      height={96}
      display="flex"
      mt="20px"
      data-testid={title}
    >
      <Grid
        container
        item
        xs={8}
        direction="column"
        justifyContent="space-between"
        style={{ padding: '20px 0 17px 24px' }}
      >
        <p className={classes.title}>{title}</p>
        {children}
      </Grid>
      <Grid container item xs={4} justifyContent="flex-end">
        {image}
      </Grid>
    </Box>
  );
};

export const ActionCardV2 = ({ children, image, title, linkText, createdText, totalText, isStorage, onToggleOpen }) => {
  const classes = useStyles();
  const { id, shipmentGroups, warehouseProofs, shipmentGroupsWithInactiveSizes } = useOrder();
  const isLoading = useIsFetching([apiPaths.shipmentGroups(id)]);
  const [open, toggleOpen] = React.useReducer(prevOpen => !prevOpen, false);
  const { international, domestic } = getShipmentGroupsInfo(shipmentGroups);
  const totalShipments = getShipmentsQty(shipmentGroups);
  const totalShipmentsCost = international.price + domestic.price;
  const totalShipmentsCostBeforeDiscount = international.price_without_discount + domestic.price_without_discount;
  const shipmentWithInactiveSize = shipmentGroupsWithInactiveSizes.size > 0;

  const { storagePrices, storagePricesBeforeDiscount } = useStorageInfo();
  const totalStorage = sumBy(warehouseProofs, wp => sumByQuantity(wp.sizes));
  const totalStorageCost = sumBy(
    warehouseProofs,
    wp => sumByQuantity(wp.sizes) * storagePrices[wp.proof.product.storage_category]
  );
  const totalStorageCostBeforeDiscount = sumBy(
    warehouseProofs,
    wp => sumByQuantity(wp.sizes) * storagePricesBeforeDiscount[wp.proof.product.storage_category]
  );
  const { swagupMembershipPlans } = useFlags();
  const { totalBeforeDiscount } = useDiscountsAndRewards(
    isStorage
      ? totalStorageCost || totalStorageCostBeforeDiscount
      : totalShipmentsCost || totalShipmentsCostBeforeDiscount,
    isStorage ? 'storage' : 'shipping'
  );
  return (
    <Box
      border="solid 1px #E5E7E8"
      bgcolor="white"
      borderRadius={4}
      minWidth={450}
      heigh={200}
      display="flex"
      mt="20px"
      data-testid={title}
    >
      <Grid container item xs={12} justifyContent="space-between" style={{ padding: '0px' }}>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          style={{ borderBottom: '1px solid #E5E7E8', padding: '10px 17px' }}
        >
          <Grid item xs>
            <p className={classes.title}>{title}</p>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              className={classes.button}
              onClick={onToggleOpen || toggleOpen}
              disabled={Boolean(isLoading)}
              style={{
                width: 160,
                color: shipmentWithInactiveSize ? '#EF6C00' : '#3577D4',
                justifyContent: 'flex-end'
              }}
            >
              {linkText}
              {isLoading ? (
                <CircularProgress size={18} style={{ backgroundColor: '#fff', marginLeft: 8 }} />
              ) : (
                <>
                  {!isStorage && (
                    <p
                      style={{
                        marginLeft: '5px',
                        color: shipmentWithInactiveSize ? '#EF6C00' : '#3577D4'
                      }}
                    >
                      {shipmentGroups.length > 0 ? `(${shipmentGroups.length})` : ''}
                    </p>
                  )}
                  {linkText && <ChevronRight style={{ width: 16, height: 16, marginLeft: 4 }} />}
                </>
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          style={{ paddingLeft: 24, paddingTop: 12 }}
        >
          <Grid container item>
            <Grid container item xs={3} direction="column">
              <Typography variant="body3RegularInter" style={{ color: '#989EA4' }}>
                {createdText}
              </Typography>
              <Typography variant="body4SemiBoldInter" style={{ marginTop: 10, fontSize: '20px' }}>
                {isStorage ? totalStorage : totalShipments}
              </Typography>
            </Grid>
            <Grid container item xs={9}>
              <Grid item xs={8}>
                <Typography variant="body3RegularInter" style={{ color: '#989EA4', textAlign: 'right' }}>
                  {totalText}
                </Typography>
              </Grid>
              <Grid item xs={4} />
              <Grid item container xs={8} alignItems="center" style={{ marginTop: 10 }}>
                <Grid item xs>
                  {swagupMembershipPlans && totalBeforeDiscount > 0 && (
                    <Typography
                      variant="body3SemiBoldInter"
                      style={{ textAlign: 'right', color: '#787B80', textDecoration: 'line-through' }}
                    >
                      {moneyStr(totalBeforeDiscount)}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography
                    variant="body4SemiBoldInter"
                    style={{ textAlign: 'right', fontSize: '20px', paddingLeft: 12 }}
                  >
                    {moneyStr(isStorage ? totalStorageCost : totalShipmentsCost)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>{children}</Grid>
          <Grid item> {image}</Grid>
        </Grid>
      </Grid>
      <ShipmentGroupsV2 open={open} onClose={toggleOpen} />
    </Box>
  );
};

export const InfoCard = () => {
  const classes = useStyles({ info: true });

  const [open, toggleOpen] = React.useReducer(prevOpen => !prevOpen, false);

  const unallocated = useUnallocatedPerProduct();
  const unallocatedTotal = sumBy(Object.values(unallocated), 'total');

  const order = useOrder();
  const isFetchingShipments = useIsFetching([apiPaths.shipmentGroups(order.id)]);
  const isFetchingWarehouse = useIsFetching([apiPaths.warehouseProofs(order.id)]);
  const isLoading = isFetchingShipments || isFetchingWarehouse;

  return unallocatedTotal > 0 ? (
    <Box
      border="solid 1px #f0f2f5"
      bgcolor="#f4f8ff"
      borderRadius={10}
      minWidth={450}
      height={184}
      display="flex"
      pt="20px"
      pr="32px"
      pb="17px"
      pl="24px"
      mt="20px"
    >
      <Grid container item xs={8} direction="column" justifyContent="space-between">
        <p className={classes.title}>Unassigned</p>
        <p className={classes.prose}>
          You haven&apos;t decided what to do with these items yet. Unassigned items will be stored in the warehouse if
          no action is taken before completing an order.
        </p>
        <Button
          disabled={Boolean(isLoading)}
          size="small"
          className={classes.button}
          onClick={toggleOpen}
          variant="text"
          withIcon
          style={{ letterSpacing: 'normal' }}
        >
          View Breakdown
        </Button>
      </Grid>
      <Grid container item xs={4} direction="column" alignItems="flex-end">
        <p className={classes.subtitle}>Items left</p>
        <p className={classes.count}>{isLoading ? <Skeleton style={{ width: 48 }} /> : unallocatedTotal}</p>
      </Grid>
      <UnallocatedDrawer open={open} onClose={toggleOpen} />
    </Box>
  ) : null;
};

const useShipmentStyles = makeStyles({
  text: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    lineHeight: '22px',
    color: '#0b1829'
  },
  button: ({ showWarning }) => ({
    height: 20,
    alignItems: 'center',
    color: showWarning ? '#fa902d' : '#3577d4',
    display: 'inline-flex',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    width: 'fit-content',
    letterSpacing: 'normal',
    '&:hover': {
      color: changeColorLightness(showWarning ? '#fa902d' : '#3577d4'),
      '& div': {
        backgroundColor: changeColorLightness(showWarning ? '#fa902d' : '#3577d4')
      }
    }
  }),
  countIcon: ({ showWarning }) => ({
    marginLeft: 8,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    borderRadius: 99,
    color: 'white',
    border: 'solid 0.4px transparent',
    backgroundColor: showWarning ? '#fa902d' : '#3577d4'
  }),
  count: {
    fontFamily: 'Gilroy-SemiBold',
    margin: 0,
    color: 'inherit',
    fontSize: 12
  }
});

export const ShipmentsCard = () => {
  const { shipmentGroups, id, shipmentGroupsWithInactiveSizes } = useOrder();
  const classes = useShipmentStyles({ showWarning: shipmentGroupsWithInactiveSizes.size > 0 });

  const [open, toggleOpen] = React.useReducer(prevOpen => !prevOpen, false);

  const isLoading = useIsFetching([apiPaths.shipmentGroups(id)]);

  return (
    shipmentGroups.length > 0 && (
      <Box
        border="solid 1px #f0f2f5"
        bgcolor="white"
        borderRadius={10}
        minWidth={450}
        height={60}
        display="flex"
        justifyContent="space-between"
        mt="20px"
        padding="20px 32px"
        data-testid="saved-shipments"
        className={classes.text}
      >
        <p className={classes.text}>Saved Shipments</p>
        <Button variant="text" className={classes.button} onClick={toggleOpen} disabled={Boolean(isLoading)}>
          View All
          {isLoading ? (
            <CircularProgress size={18} style={{ backgroundColor: '#fff', marginLeft: 8 }} />
          ) : (
            <div className={classes.countIcon}>
              <p className={classes.count}>{shipmentGroups.length}</p>
            </div>
          )}
        </Button>
        <ShipmentGroups open={open} onClose={toggleOpen} />
      </Box>
    )
  );
};

export const ShipmentsCardV2 = () => {
  const { shipmentGroups, id, shipmentGroupsWithInactiveSizes } = useOrder();
  const classes = useShipmentStyles({ showWarning: shipmentGroupsWithInactiveSizes.size > 0 });

  // const [open, toggleOpen] = React.useReducer(prevOpen => !prevOpen, false);
  const [open, toggleOpen] = React.useState(false);

  const isLoading = useIsFetching([apiPaths.shipmentGroups(id)]);

  return (
    shipmentGroups.length > 0 && (
      <Box
        border="solid 1px #f0f2f5"
        bgcolor="white"
        borderRadius={10}
        minWidth={450}
        height={60}
        display="flex"
        justifyContent="space-between"
        mt="20px"
        padding="20px 32px"
        data-testid="saved-shipments"
        className={classes.text}
      >
        <p className={classes.text}>Saved Shipments</p>
        <Button
          variant="text"
          className={classes.button}
          onClick={() => {
            toggleOpen(!open);
          }}
          disabled={Boolean(isLoading)}
        >
          View All
          {isLoading ? (
            <CircularProgress size={18} style={{ backgroundColor: '#fff', marginLeft: 8 }} />
          ) : (
            <div className={classes.countIcon}>
              <p className={classes.count}>{shipmentGroups.length}</p>
            </div>
          )}
        </Button>
        <ShipmentGroupsV2
          open={open}
          onClose={() => {
            toggleOpen(!open);
          }}
        />
      </Box>
    )
  );
};
