import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Box, Grid, makeStyles } from '@material-ui/core';
import styles from './styles/automationActionSelectionModal';
import SendSwagConfigurationDM from './SendSwagConfigurationDM';
import StoreConfigurationFinish from './StoreConfigurationFinish';
import RedeemLinkConfigurationFinish from './RedeemLinkConfigurationFinish';
import ActionConfigurationExecution from './ActionConfigurationExecution';
import SendSwagConfigurationEmployee from './SendSwagConfigurationEmployee';

const useStyles = makeStyles(styles);

const ShopCreditsConfiguration = ({ action, onActionUpdate }) => {
  return (
    <Box>
      <Typography variant="body3SemiBoldInter">Select the shop you want to send credit for</Typography>
    </Box>
  );
};

const AutomationConfigurationThird = ({ action, onActionUpdate, employeeTypes, isEmployeeTypesEmpty }) => {
  const thirdActionResolver = actionType => {
    switch (true) {
      case actionType === 'send_swag':
        return <SendSwagConfigurationDM action={action} onActionUpdate={onActionUpdate} />;
      case actionType === 'redeem_link':
        return isEmployeeTypesEmpty ? (
          <ActionConfigurationExecution action={action} onActionUpdate={onActionUpdate} employeeTypes={employeeTypes} />
        ) : (
          <SendSwagConfigurationEmployee
            action={action}
            onActionUpdate={onActionUpdate}
            employeeTypes={employeeTypes}
          />
        );
      case actionType === 'shop_credits':
        return isEmployeeTypesEmpty ? (
          <ActionConfigurationExecution action={action} onActionUpdate={onActionUpdate} employeeTypes={employeeTypes} />
        ) : (
          <SendSwagConfigurationEmployee
            action={action}
            onActionUpdate={onActionUpdate}
            employeeTypes={employeeTypes}
          />
        );
      default:
        return <ShopCreditsConfiguration action={action} onActionUpdate={onActionUpdate} />;
    }
  };

  const classes = useStyles();

  return (
    <Box>
      <Box>{thirdActionResolver(action?.type)}</Box>
    </Box>
  );
};

export default AutomationConfigurationThird;
