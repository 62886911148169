import React from 'react';
import { Button } from '@swagup-com/components';
import { withStyles, Grid, Tooltip } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { moneyStr } from '../../helpers/utils';
import { Img } from '../global/ImgUtils';
import {
  productImageBasedOnStatus,
  proofStatusStyles,
  proofStatusStylesNew,
  StatusChip,
  textOnHover
} from '../global/proofsCommon';
import styles from './styles/DisplayTool';
import { useCircularIndex } from '../../hooks';
import { PRODUCT_STATUS_OPTIONS } from '../../utils/constants';

const arrowStyles = {
  button: {
    cursor: 'pointer',
    transition: '0.6s ease',
    userSelect: 'none',
    width: 24,
    height: 24,
    borderRadius: 5,
    backgroundColor: '#e9f0fa',
    padding: 0,
    color: '#3577d4',
    paddingTop: 1,
    border: 'none'
  },
  icon: {
    fontSize: 12,
    marginTop: 2
  }
};

const ArrowButton = withStyles(arrowStyles)(({ rotated, icon: Icon, classes, ...props }) => (
  <button type="button" {...props} className={classes.button}>
    <Icon className={classes.icon} />
  </button>
));

const Proofs = ({ classes, proofs }) => {
  const [current, handlePrevious, handleNext] = useCircularIndex(0, proofs.length);
  const proof = proofs[current];
  const proofDetailsUrl = `/proof-details/${proof.id}`;

  return (
    <Grid container>
      <Grid item xs={12} align="center">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid container justifyContent="space-between" alignItems="center" item xs={12}>
            <ArrowButton rotated icon={ArrowBack} onClick={handlePrevious} />
            <Link to={`/proof-details/${proof.id}`}>
              <Img
                src={productImageBasedOnStatus(proof.product, 256, 256)}
                className={classes.proofImg}
                alt="Product"
              />
            </Link>
            <ArrowButton icon={ArrowForward} onClick={handleNext} />
          </Grid>
          <Grid item style={{ marginBottom: 14 }}>
            <Tooltip title={textOnHover[proof.product.status]} arrow placement="top">
              <StatusChip
                label={PRODUCT_STATUS_OPTIONS[proof.product.new_status]}
                status={proofStatusStylesNew[proof.product.new_status]}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} className={classes.separateProofProductName}>
            <p className={classes.packTitle}>{proof.product.name}</p>
            <p className={classes.statusText}>{moneyStr(proof.total)}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" className={classes.separateProofButtonsContainer}>
        <Button size="small" component={Link} to={proofDetailsUrl} style={{ width: 148 }}>
          View Proof
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Proofs);
