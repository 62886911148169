const styles = () => ({
  panel: {
    padding: '0px 12px 0px 0xp'
  },
  iconWrapper: { width: 24, height: 30, position: 'relative' },
  icon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  benefitTab: {
    padding: '0px 8px 0px 4px',
    marginBottom: ({ alone }) => (alone ? 0 : 8),
    borderRadius: 8,
    background: ({ discount, earlyBirdPromotion }) => {
      if (earlyBirdPromotion) return '#6AC6DE';
      return discount ? 'rgba(53, 119, 212, 0.20)' : '#E9F5EC';
    }
  },
  benefitText: {
    color: ({ discount, earlyBirdPromotion }) => {
      if (earlyBirdPromotion) return '#FFFFFF';
      return discount ? '#3577D4' : '#2BB572';
    },
    fontSize: 12,
    marginLeft: ({ earlyBirdPromotion }) => (earlyBirdPromotion ? 4 : 8),
    fontFamily: 'Gilroy-Medium',
    lineHeight: '12px',
    margin: 0
  }
});

export default styles;
