import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Link } from 'react-router-dom';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { useState, useCallback, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { isEmpty, min } from 'lodash';
import {
  CancelOutlined,
  CheckCircleOutline,
  CheckCircleRounded,
  CheckOutlined,
  CheckRounded,
  LinkOffRounded,
  LinkOffSharp,
  StopOutlined
} from '@material-ui/icons';
import { StylessButton } from '../buttons';
import useIntegrations from '../../hooks/useIntegrations';
import { getIntegrationImageSrcSet } from './common';
import Img from '../shared/Img';
import { integrationsApi } from '../../apis/swagup';
import { useCompany, useProfile } from '../../hooks';
import apiPaths from '../../helpers/apiPaths';
import { Pagination } from '../shared';
import useMembershipCheck from '../../hooks/useMembershipCheck';

const styles = theme => ({
  integrationImageWrapper: {
    height: 72,
    width: 72,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 10
  },
  integrationImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  integrationItem: {
    padding: '20px 24px  !important',
    border: '1px solid #EBEDF0 !important',
    width: '100% !important',
    textAlign: 'left !important',
    position: 'relative',
    borderRadius: 10,
    background: ({ enabled }) => (enabled ? undefined : '#F2F2F2 !important')
  },
  tabPanel: {
    paddingTop: 16,
    width: '100%'
  },
  integrationName: { marginBottom: 8, textAlign: 'left' },
  integrationDescription: { marginBottom: 8, textAlign: 'left' },
  integrationCTAPanel: { textAlign: 'left', paddingTop: 8 },
  integrationCTA: {
    height: 30,
    padding: '0px 12px',
    borderWidth: 1,
    minWidth: 72,
    fontFamily: 'Inter',
    fontSize: 12,
    lineHeight: '12px',
    fontWeight: 400,
    background: ({ enabled }) => (enabled ? undefined : '#EBEDF0 !important')
  },
  comingSoon: {
    position: 'absolute',
    right: 12,
    bottom: 6
  },
  paginationContainer: {
    marginTop: 16,
    backgroundColor: '#ffffff',
    borderRadius: 12,
    // borderTop: 'solid 1px #f0f2f5',
    paddingTop: '8px'
  },
  statusIconConnected: {
    position: 'absolute',
    right: 8,
    top: 6,
    color: '#4CAF50'
  },
  statusIconDisconnected: {
    position: 'absolute',
    right: 8,
    top: 6,
    color: '#989EA4',
    border: '1px solid #989EA4',
    width: 16,
    height: 16,
    borderRadius: 8
  }
});

const useStyles = makeStyles(styles);

const IntegrationItem = ({ item, onNext, isConnected, integrationsAllowed }) => {
  const classes = useStyles({ enabled: item.active, connected: item.connected });
  const linkUrl =
    item.type === 'hris'
      ? `/integration-details/${item.rutter_id}`
      : `/integrations/orders/?platform=${item.rutter_id}`;

  const StatusIcon = () =>
    item.reconnect ? (
      <LinkOffSharp className={classes.statusIconDisconnected} />
    ) : (
      <CheckCircleOutline className={classes.statusIconConnected} />
    );

  return (
    <Grid item xs={4}>
      <StylessButton
        onClick={isConnected ? undefined : () => onNext(item)}
        disabled={!item.active}
        className={classes.integrationItem}
      >
        <Grid container spacing={4}>
          <Grid item>
            <Box className={classes.integrationImageWrapper}>
              <Img
                src={item.image_url}
                srcSet={getIntegrationImageSrcSet(item.rutter_id)}
                alt={item.name}
                className={classes.integrationImage}
              />
            </Box>
          </Grid>
          <Grid item xs>
            {isConnected ? <StatusIcon /> : null}
            <Typography variant="body3MediumInter" className={classes.integrationName}>
              {item.name}
            </Typography>
            <Typography variant="body4RegularInter" className={classes.integrationDescription}>
              {item.description}
            </Typography>
            <div className={classes.integrationCTAPanel}>
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    size="small"
                    component={isConnected ? Link : undefined}
                    variant={isConnected ? 'primary' : 'secondary'}
                    onClick={isConnected ? undefined : () => onNext(item)}
                    to={isConnected ? linkUrl : undefined}
                    rel={isConnected ? 'noreferrer' : undefined}
                    disabled={!item.active}
                    className={classes.integrationCTA}
                  >
                    {isConnected ? 'View' : !item.active ? 'Coming soon' : 'Connect'}
                  </Button>
                </Grid>
                {integrationsAllowed && item.reconnect && isConnected && (
                  <Grid item>
                    <Button
                      size="small"
                      variant="secondary"
                      onClick={() => onNext(item)}
                      className={classes.integrationCTA}
                    >
                      Re-Connect
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          {/* {!item.active && (
            <Grid container>
              <Grid item>
                <Typography variant="body4MediumInter" className={classes.comingSoon}>
                  Coming soon
                </Typography>
              </Grid>
            </Grid>
          )} */}
        </Grid>
      </StylessButton>
    </Grid>
  );
};

const NativeIntegrations = ({ integrations, pagination }) => {
  const [integrationLinkToken, setIntegrationLinkToken] = useState();
  const [selectedPlatform, setSelectedPlatform] = useState();
  const { onOpen, createNewStore } = useIntegrations();

  const { data: company } = useCompany();

  const onSuccess = useCallback(
    public_token => {
      // Send public_token to server (Step 3)
      console.log('useMergeLink onSuccess: public_token', public_token);
      const newStore = {
        name: `${company.name}-${selectedPlatform.name}`,
        platform: selectedPlatform.rutter_id,
        access_token: public_token
      };
      createNewStore(newStore);
    },
    [createNewStore]
  );

  const { open, isReady } = useMergeLink({
    linkToken: integrationLinkToken, // Replace ADD_GENERATED_LINK_TOKEN with the token retrieved from your backend (Step 1)
    onSuccess
    // tenantConfig: {
    // apiBaseURL: "https://api-eu.merge.dev" /* OR your specified single tenant API base URL */
    // },
  });

  const integrationLink = useMutation(integrationName => integrationsApi.createLinkToken(integrationName), {
    onSuccess: data => {
      setIntegrationLinkToken(data?.link_token);
    }
  });

  useEffect(() => {
    if (!isEmpty(integrationLinkToken)) {
      open();
    }
  }, [integrationLinkToken]);

  const showPagination = pagination?.count > 16;

  // const { data: company } = useCompany();
  // const { data: profile } = useProfile();

  const membershipCheck = useMembershipCheck();

  const onOpenRutter = platform => {
    if (!isEmpty(platform.url)) {
      window.open(platform.url, '_blank');
      return;
    }

    if (platform.type === 'hris') {
      setSelectedPlatform(platform);
      integrationLink.mutate(platform.name);
    } else onOpen(platform.rutter_id);
  };

  const classes = useStyles();
  return (
    <Box className={classes.tabPanel}>
      <Grid container spacing={6}>
        {integrations.map(item => (
          <IntegrationItem
            key={item.key}
            item={item}
            onNext={onOpenRutter}
            isConnected={item.connected}
            integrationsAllowed={membershipCheck.integrationsAllowed}
          />
        ))}
      </Grid>

      {showPagination && (
        <Grid container justifyContent="center" alignItems="center" className={classes.paginationContainer}>
          <Grid item>
            <Pagination {...pagination} startText="Show" endText="integrations" buttonClass={classes.paginationBtn} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default NativeIntegrations;
