import { CATALOG_CART_WIDTH } from '../../../utils/constants';

export default theme => ({
  root: {
    bottom: 0,
    width: CATALOG_CART_WIDTH,
    height: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    border: '1px solid #D4D9E2',

    [theme.breakpoints.only('sm')]: {
      top: 64
    },
    [theme.breakpoints.only('xs')]: {
      top: 0,
      width: '100%'
    }
  },
  header: {
    fontSize: 14,
    lineHeight: 'normal',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  headerDivider: {
    minHeight: 4
  },
  quantitySelect: {
    marginTop: theme.spacing(1.5)
  },
  price: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  priceLabel: {
    fontWeight: 500,
    marginBottom: 10,
    color: '#131415'
  },
  priceValue: {
    fontWeight: 500,
    color: '#0B1829',
    marginLeft: 'auto'
  },
  total: {
    display: 'flex',
    margin: theme.spacing(3),
    marginTop: 10
  },
  totalLabel: {
    fontWeight: 500,
    color: '#0B1829'
  },
  totalValue: {
    fontWeight: 500,
    color: '#0B1829',
    marginLeft: 'auto'
  },
  notice: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 400,
    color: '#4A4F54',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  noticeAlign: {
    margin: 0
  },
  btnContainer: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  btn: {
    minHeight: 64,
    marginTop: 'auto',
    fontSize: 15
  },
  infoIcon: {
    height: 15,
    width: 15,
    color: '#989EA4',
    position: 'absolute',
    marginLeft: 5,
    marginTop: 10,
    cursor: 'pointer'
  },
  tooltip: {
    fontSize: 12,
    textAlign: 'center',
    width: 240
  }
});
