const styles = theme => ({
  center: props => ({
    textAlign: 'center',
    paddingBottom: 24,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 24px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0
    },
    ...(props.leftBarNavigation ? { maxWidth: '100%' } : {})
  }),
  bottomText: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#434c5f',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 24,
      fontSize: 12
    }
  },
  breadcrumbContainer: {
    padding: '23px 0 25px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 24
    }
  },
  presetHomeTitle: {
    lineHeight: '1.43',
    fontSize: 36,
    color: '#0f2440',
    marginBottom: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22
    }
  },
  presetHomeSubTitle: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Gilroy-Medium',
    color: '#8d9299',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  }
});

export default styles;
