import { scrollBar } from '../../shared/styles/commonStyles';

export default theme => ({
  root: {
    width: 215,
    bottom: 0,
    height: 'auto',
    top: 10,
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
      border: 'none'
    },
    [theme.breakpoints.only('xs')]: {
      width: '60%'
    }
  },
  header: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 190,
    cursor: 'pointer',
    top: '30px',
    left: '20px'
  },
  divider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(12)
  },
  list: {
    ...scrollBar,
    top: 20
  },
  drawerCatHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0 0 0',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    top: 10
  },
  SidebarMenu: {
    position: 'fixed',
    height: '100%',
    width: '150px',
    top: 10
  },
  CategoriesTitle: {
    paddingLeft: 20,
    '& p': {
      textAlign: 'center',
      textTransform: 'uppercase',
      width: 80,
      height: 18,
      fontFamily: 'Futura',
      fontSize: 12,
      fontWeight: 500,
      color: '#434c5f'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0'
    }
  },
  fullList: {
    width: 'auto',
    top: 10
  },
  CloseButton: {
    width: 25,
    height: 25,
    padding: 1
  }
});
