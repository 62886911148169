import * as React from 'react';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';

import { useQuery, useQueryClient } from 'react-query';
import shippingPricesApi from '../../../../../apis/swagup/shippingPrices';
import { sumByQuantity } from '../../../../../helpers/utils';
import { addressFields, cancellableQuery } from '../common/utilsOrder';
import apiPaths from '../../../../../helpers/apiPaths';

const useShippingPricesQuery = (products, recipient, selectedQuantitiesRef) => {
  const [selectedQuantities] = selectedQuantitiesRef;
  const [shippingPricePayload, setShippingPricePayload] = React.useState({});
  const debouncedSetShippingPricePayload = React.useCallback(debounce(setShippingPricePayload, 350), [
    setShippingPricePayload
  ]);

  React.useEffect(() => {
    debouncedSetShippingPricePayload({
      quantities: products
        .filter(p => selectedQuantities.has(p.id) && sumByQuantity(selectedQuantities.get(p.id)))
        .map(p => ({
          account_product: p.product.id,
          quantity: sumByQuantity(selectedQuantities.get(p.id))
        })),
      ...pick(recipient, addressFields)
    });
  }, [debouncedSetShippingPricePayload, recipient, products, selectedQuantities, selectedQuantitiesRef]);

  const enabled = sumByQuantity(shippingPricePayload.quantities || []) > 0;
  const queryClient = useQueryClient();
  const query = useQuery(
    [apiPaths.shippingPrices, shippingPricePayload],
    () => {
      queryClient.cancelQueries(apiPaths.shippingPrices);
      return cancellableQuery(shippingPricesApi.fetch)(shippingPricePayload).then(response => response.data);
    },
    { enabled }
  );

  return query;
};

export default useShippingPricesQuery;
