import React from 'react';

const TrackingInfo = ({ classes, trackingUrl, tracking }) => (
  <p className={classes.normalText}>
    Tracking:
    {trackingUrl ? (
      <a className={classes.linkText} href={trackingUrl} rel="noopener noreferrer" target="_blank">
        {` ${tracking}`}
      </a>
    ) : (
      tracking || ' -'
    )}
  </p>
);

export default TrackingInfo;
