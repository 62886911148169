import React, { useEffect, useState } from 'react';
import { Dialog, Divider, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import styles from './styles/redeemPagesHome';
import { Alert, CenteredGrid, EmptyState, Helmet, Pagination } from '../shared';
import { CardsContainer } from '../shared/containers/Cards';
import { CreateRedeemOptionsDrawer, RedeemPageCard } from './redeemCommon';
import { usePaginatedQuery, usePerPageOptions, useQueryParams } from '../../hooks';
import Loader from '../global/Loader';
import { redeemPages } from '../../apis/redeemServices';
import redeemServicesPaths from '../../helpers/redeemServicesPaths';
import { paginationRequestConverter } from '../../helpers/utils';
import seoTags from '../../apis/seoTags';
import RedeemFilterSection from './RedeemFilterSection';
import { useQueryFilterValidated } from '../../hooks/useFilters';
import SearchSortFilter from '../shared/SearchSortFilter';

const useStyles = makeStyles(styles);

const DeletePageModal = ({ open, pageName, onClose, onAccept, classes }) => (
  <Dialog open={open} onClose={onClose} classes={{ paper: classes.deletePageModal }}>
    <Grid container>
      <div>
        <Typography variant="body2MediumInter" className={classes.notEnoughCreditsModalTitle}>
          {`Delete ${pageName}`}
        </Typography>
        <Typography variant="body3RegularInter">
          Deleting this redeem page will delete all redemption links for the page. Any shipments that have been created
          can still be found in the <Link to="/shipments">Shipments</Link> tab and any{' '}
          <Link to="/shipments/contacts">contacts</Link> created will be saved as well.
        </Typography>
        <Divider className={classes.notEnoughCreditsModalDivider} />
        <Grid container spacing={6} justifyContent="flex-end">
          <Grid item>
            <Button size="small" variant="text" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button size="small" variant="primary" onClick={onAccept}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  </Dialog>
);

const RedeemPagesHome = () => {
  const [showInfoMessage, setShowInfoMessage] = useState(true);
  const [actionMessage, setActionoMessage] = useState();
  const [isFiltering, setIsFiltering] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editingPage, setEditingPage] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState();
  const openRedeemDrawer = useSelector(state => state.redeem);
  const { leftBarNavigation } = useFlags();
  const dispatch = useDispatch();

  // const profile = useSelector(state => state.profile);
  // const email = profile?.email;

  const history = useHistory();
  const location = useLocation();
  const { infoMessage } = location.state || {};

  const classes = useStyles();
  const perPageOptions = usePerPageOptions();
  const company = useSelector(state => state.company);

  const isActive = useQueryFilterValidated(
    'isActive',
    (id, value) => ['true', 'false'].includes(id) && value.split(',').length === 1
  );

  const collectionOnly = useQueryFilterValidated(
    'collectionOnly',
    (id, value) => ['true', 'false'].includes(id) && value.split(',').length === 1
  );

  const ordering = useQueryFilterValidated(
    'ordering',
    (id, value) => ['-created_at', 'created_at'].includes(id) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const query = useQueryParams();
  const search = query.get('search') || '';

  const haveActiveFilters = [search, isActive, collectionOnly].some(e => e.length > 0);

  const { query: queryResult, pagination } = usePaginatedQuery({
    queryKey: [redeemServicesPaths.redeemPages, company.id, search, isActive, ordering, collectionOnly],
    queryFn: (limit, offset) =>
      company.id &&
      redeemPages
        .listByAccount(
          company.id,
          paginationRequestConverter({ limit, offset, search, isActive, ordering, collectionOnly })
        )
        .then(response => {
          setIsFiltering(false);
          return response;
        }),
    perPageOptions
  });

  const { data, isFetching } = queryResult;
  const resultPages = data?.results || [];

  const queryClient = useQueryClient();
  const renamePage = useMutation(({ pageId, name }) => redeemPages.update(pageId, { customProjectName: name }), {
    onSuccess: () => {
      queryClient.invalidateQueries([redeemServicesPaths.redeemPages, company.id]);
    }
  });

  const deletePage = useMutation(pageId => redeemPages.update(pageId, { deleted: true }), {
    onSuccess: () => {
      queryClient.invalidateQueries([redeemServicesPaths.redeemPages, company.id]);
      setActionoMessage();
      setShowInfoMessage(false);
    }
  });

  useEffect(() => {
    if (!deleteOpen) {
      setTimeout(() => setPageToDelete(), 500);
    }
  }, [deleteOpen]);

  const onRenamePage = (pageId, name) => {
    setEditingPage(pageId);
    renamePage.mutate({ pageId, name });
  };

  const onDeleteAttempt = page => {
    setDeleteOpen(true);
    setPageToDelete(page);
  };

  const onDelete = page => {
    setDeleteOpen(false);
    if (page) {
      deletePage.mutate(page.id);
      setActionoMessage(`Deleting page...`);
      setShowInfoMessage(true);
    }
  };

  const handleClose = () => {
    setShowInfoMessage(false);
    history.replace({ ...location, state: { ...location.state, infoMessage: undefined } });
  };

  const searchSortFilterConfig = {
    search: { placeholder: 'Search Redeem Pages' },
    sort: {
      options: {
        '-created_at': 'Most Recent',
        created_at: 'Less Recent'
      }
    },
    filters: [
      {
        label: 'Status',
        queryParam: 'isActive',
        options: {
          true: 'Active',
          false: 'Inactive'
        },
        isSingleSelect: true
      },
      {
        label: 'Redeem Type',
        queryParam: 'collectionOnly',
        options: {
          true: 'Collection',
          false: 'Autoship'
        },
        isSingleSelect: true
      }
    ]
  };

  return (
    <div className={classes.root} style={{ paddingTop: leftBarNavigation ? 0 : 40 }}>
      <Helmet tags={seoTags.redeem} />
      <CenteredGrid>
        {!leftBarNavigation && (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2BoldInter">Redeem</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="primary"
                onClick={() => setOpenDrawer(true)}
                to="/redeem-pages-create"
                style={{ minWidth: 180, height: 56 }}
              >
                Create Redeem Page
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 24 }}>
          <Grid item xs>
            {(infoMessage || actionMessage) && showInfoMessage && (
              <Grid container justifyContent="center" style={{ position: 'relative' }}>
                <Alert
                  onClose={handleClose}
                  delayTime={10000}
                  className={classes.infoMessage}
                  fontStyles={{ fontSize: 12, padding: 0 }}
                >
                  <span dangerouslySetInnerHTML={{ __html: infoMessage || actionMessage }} />
                </Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
        <SearchSortFilter config={searchSortFilterConfig} />
      </CenteredGrid>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} style={{ position: 'relative' }}>
          {isFiltering && isFetching && (
            <Grid container justifyContent="center">
              <Typography variant="body4RegularInter" className={classes.isSearching}>
                Searching...
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <CenteredGrid>
        <Grid container justifyContent="center" alignItems="center" style={{ paddingBottom: 32 }}>
          {!isFetching && resultPages.length === 0 ? (
            <Grid item>
              <EmptyState
                title={
                  haveActiveFilters
                    ? 'No Redeem Pages Found'
                    : 'It’s pretty quiet in here. Why not launch a Redeem Page?'
                }
                image={{
                  path: haveActiveFilters ? '/images/redeem/search.png' : '/images/redeem/home-empty.png',
                  alt: 'No Redeem Pages',
                  text: haveActiveFilters
                    ? 'Remove the filters to see all redeem pages'
                    : 'Click “Create a Redeem Page" to get started'
                }}
                maxWidth={360}
                button={haveActiveFilters ? { link: '/redeem-pages', text: 'Remove filters' } : undefined}
              />
            </Grid>
          ) : (
            <CardsContainer className={classes.cardsContainer}>
              {resultPages.map(page => (
                <RedeemPageCard
                  key={page.id}
                  page={page}
                  onEdit={onRenamePage}
                  onDelete={() => onDeleteAttempt(page)}
                  isLoading={editingPage === page.id && renamePage.isLoading}
                />
              ))}
            </CardsContainer>
          )}
        </Grid>
        <Grid container justifyContent="center" className={classes.paginationContainerHome}>
          <Grid item>
            <Pagination {...pagination} startText="Show" endText="pages" buttonClass={classes.paginationBtn} />
          </Grid>
        </Grid>
        {leftBarNavigation ? (
          <CreateRedeemOptionsDrawer
            open={openRedeemDrawer}
            onClose={() => dispatch({ type: 'Redeem-toggle' })}
            classes={classes}
          />
        ) : (
          <CreateRedeemOptionsDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} classes={classes} />
        )}
        {isFetching && !isFiltering && <Loader />}
      </CenteredGrid>
      <DeletePageModal
        open={deleteOpen}
        pageName={pageToDelete?.customProjectName || pageToDelete?.projectName}
        onClose={() => {
          setDeleteOpen(false);
        }}
        onAccept={() => onDelete(pageToDelete)}
        classes={classes}
      />
    </div>
  );
};

export default RedeemPagesHome;
