/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Grid, Snackbar, Box } from '@material-ui/core';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import dayjs from 'dayjs';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Warning } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getPaymentProfiles } from '../../../../../../actions';
import toErrorPage from '../../../../../../helpers/toErrorPage';
import Loader from '../../../../../global/Loader';
import OrderOverview from './OrderOverview';
import styles from './styles/ShipmentsReview';
import ShippingSummary from './ShippingSummary';
import { Pagination, ErrorAlert } from '../../../../../shared';
import {
  useCompany,
  useCreditActions,
  useCreditSummary,
  useLocalPagination,
  useIsAchPrepaid,
  useShippingCutoffHour,
  useQueryParams
} from '../../../../../../hooks';
import apiPaths from '../../../../../../helpers/apiPaths';
import { getSelected, totalShippingPrice, getShipmentSummary } from '../../../../../../helpers/utils';
import { shipmentsApi } from '../../../../../../apis/swagup';
import { shouldDisableDate } from '../../../../../../helpers/commonDateFunctions';
import { SEND_SWAG_EXECUTION } from '../../../../../../actions/types';
import { cleanShipmentGroup } from '../../../../../../actions/shipmentGroupActions';
import WizardStepper from '../../../../../global/WizardStepper';
import PaymentMethod from './PaymentMethod';
import { useFilterAndSortRecipients } from '../../hooks';
import { useLeftNavbar } from '../../../../../../contexts/leftNavbar';

const useStyles = makeStyles(styles);

const doesShipmentHasSomeInvalidDates = (shipment, shippingCutoffHour) =>
  shipment.directoryOrders.some(order => shouldDisableDate(new Date(order.shippingDate), shippingCutoffHour));

const getEmployeeOrders = shipment =>
  shipment.directoryOrders.map(order => {
    const products = order.proofs.map(proof => {
      // We know at this point that the products in the directory order
      // *are* in the shipment. If this line fails it's
      // a bug in the shipment group creation page.
      const product = shipment.products.find(p => p.id === proof.proof).id;
      return {
        product,
        sizes: proof.sizes
      };
    });
    const recipient = shipment.recipients.find(rp => rp.id === order.directory);
    return {
      employee: order.directory,
      delivery_method: order.deliveryMethods.find(dm => dm.selected).id,
      products,
      documentation: order.documentation,
      shipping_date: dayjs(order.shippingDate).format('YYYY-MM-DD'),
      shipping_notes: isEmpty(order.shippingNotes) ? undefined : order.shippingNotes,
      shipping_address1: recipient.shipping_address1,
      shipping_address2: recipient.shipping_address2,
      shipping_city: recipient.shipping_city,
      shipping_state: recipient.shipping_state,
      shipping_zip: recipient.shipping_zip,
      shipping_country: recipient.shipping_country,
      express: !!order.deliveryMethods.find(dm => dm.selected && dm.name === 'Express')
    };
  });

const getDataFromShipment = (shipment, isInternational = false) => {
  const employees = shipment.recipients
    .filter(recipient => isInternational === (recipient.shipping_country !== 'US'))
    .map(recipient => recipient.id);

  const directoryOrders = employees.map(employee =>
    shipment.directoryOrders.find(dOrder => dOrder.directory === employee)
  );

  const totalPrice = sum(
    directoryOrders.map(dOrder => {
      const selectedDm = getSelected(dOrder.deliveryMethods);
      // TODO sometimes this is reaching this point with no DM or no selected DM
      return selectedDm ? totalShippingPrice(selectedDm.prices.breakdown) : 0;
    })
  );

  return {
    employeesQty: employees.length,
    totalPrice
  };
};

const sortDirectoryOrders = recipients => (orderA, orderB) =>
  recipients[orderB.directory].created_at - recipients[orderA.directory].created_at;

const paginateDirectoryOrders = (offset, limit) => (_s, idx) => idx >= offset && idx < offset + limit;

const useSortedAndPaginatedDirectoryOrders = (offset, limit) => {
  const { recipients, directoryOrders } = useSelector(state => state.shipmentGroup);

  const recipientsMap = React.useMemo(() => Object.fromEntries(recipients.map(r => [r.id, r])), [recipients]);

  const sortedDirectoryOrders = React.useMemo(() => directoryOrders.slice().sort(sortDirectoryOrders(recipientsMap)), [
    recipientsMap,
    directoryOrders
  ]);

  const paginatedOrders = React.useMemo(() => sortedDirectoryOrders.filter(paginateDirectoryOrders(offset, limit)), [
    sortedDirectoryOrders,
    offset,
    limit
  ]);

  return paginatedOrders;
};

const defaultPerPageOptions = [6, 10, 16, 24];

const wizardSteps = [
  { name: 'Review Shipment', path: 'review-order' },
  { name: 'Payment Method', path: 'payment-method' },
  { name: 'Place Shipment', path: 'place-shipment' }
];

const ShipmentsReview = ({ preventFurtherActions }) => {
  const [inputValue, setInputValue] = useState(0);
  const [invalid, setInvalid] = useState(false);
  const { leftBarNavigation } = useFlags();
  const [currentStep, setCurrentStep] = useState(1);

  const leftNavbarContext = useLeftNavbar();

  const leftNavOpen = !!leftNavbarContext?.leftNavOpen;

  const classes = useStyles({ leftBarNavigation, leftNavOpen });

  const location = useLocation();
  const { data: companyApi } = useCompany();
  const company = useMemo(() => companyApi || {}, [companyApi]);

  const shipment = useSelector(state => state.shipmentGroup);
  const paymentProfiles = useSelector(state => state.paymentProfiles);
  const summary = React.useMemo(() => getShipmentSummary(shipment), [shipment]);

  const history = useHistory();
  const [useCreditsFirst, setUseCreditsFirst] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const paymentProfileQuery = useQuery(
    [apiPaths.paymentProfiles, company.id],
    () => dispatch(getPaymentProfiles(company.id)),
    {
      enabled: !isEmpty(company),
      onError: error => toErrorPage(error, history)
    }
  );

  const firstRender = React.useRef(true);
  React.useEffect(() => {
    firstRender.current = false;
  }, []);

  const { data: creditSummary } = useCreditSummary({ enabled: firstRender.current || Boolean(errorMessage) });
  const { current_balance: balance } = creditSummary;

  const shippingCutoffHour = useShippingCutoffHour();

  const queryClient = useQueryClient();
  // const maxOrders = +queryClient.getQueryData(apiPaths.metadata)?.MAX_BULK_DIRECTORY_ORDERS || 100;
  const { addCredits, invalidateCache } = useCreditActions();
  const sendSwagMutation = useMutation(
    [apiPaths.employeeOrders],
    payload => shipmentsApi.sendSwagBulkEmployeeOrders(payload),
    {
      onSuccess: result => {
        dispatch({
          type: SEND_SWAG_EXECUTION,
          payload: { processing: false, time: dayjs() }
        });
        invalidateCache();
        if (result.status === 400) {
          setErrorMessage(result?.data);
          return;
        }

        if (result.status > 210) {
          setErrorMessage(`Error ${result.status}`);
          return;
        }

        const domesticData = getDataFromShipment(shipment);
        const internationalData = getDataFromShipment(shipment, true);
        const totalSP = round(domesticData.totalPrice + internationalData.totalPrice, 2);

        const selectedPaymentProfile = paymentProfiles.find(pm => pm.default);
        const swagCredits = round(useCreditsFirst ? inputValue : 0, 2);
        const solidPayment = totalSP - swagCredits;
        const paymentProfile = !selectedPaymentProfile
          ? undefined
          : {
              ...selectedPaymentProfile,
              amount: round(solidPayment, 2)
            };

        queryClient.invalidateQueries(apiPaths.accountProducts);

        history.push('/ship-swag-overview', {
          domesticData,
          internationalData,
          total: totalSP,
          credits: inputValue,
          paymentProfile,
          isFromSendSwag: true
        });

        dispatch(cleanShipmentGroup);
      },
      onError: error => {
        dispatch({
          type: SEND_SWAG_EXECUTION,
          payload: { processing: false, time: dayjs() }
        });
        toErrorPage(error, history);
      }
    }
  );

  const { processSendSwag } = useSelector(state => state);
  useEffect(() => {
    if (processSendSwag?.processing) {
      if (dayjs().diff(processSendSwag?.time, 'M') > 10) {
        // above 10 mins
        dispatch({
          type: SEND_SWAG_EXECUTION,
          payload: { processing: false, time: dayjs() }
        });
      }
    }
  }, [dispatch, processSendSwag]);

  const pagination = useLocalPagination(shipment.directoryOrders.length, defaultPerPageOptions);
  const offset = pagination.pageIndex * pagination.perPage;
  const paginatedDirectoryOrders = useSortedAndPaginatedDirectoryOrders(offset, pagination.perPage);

  const credUsage = useMemo(() => {
    if (!isEmpty(company)) {
      return balance > 0 || allowNegativeCreditBalance || isEmpty(paymentProfileQuery?.data?.data);
    }
  }, [company, balance, allowNegativeCreditBalance, paymentProfileQuery]);

  const { allow_negative_credit_balance: allowNegativeCreditBalance } = company;

  useEffect(() => {
    setUseCreditsFirst(!!credUsage);
  }, [credUsage]);

  const { totalWarnings } = useFilterAndSortRecipients({
    search: ''
  });

  const { state } = useLocation();
  if (isEmpty(shipment))
    return <Redirect to={state?.from ?? (leftBarNavigation ? '/contacts' : '/shipments/contacts')} />;

  const handleSendSwag = async () => {
    dispatch({
      type: SEND_SWAG_EXECUTION,
      payload: { processing: true, time: dayjs() }
    });
    const employeeOrders = getEmployeeOrders(shipment);
    const payload = {
      only_quote: false,
      credits_to_use: inputValue,
      employee_orders: employeeOrders
    };
    sendSwagMutation.mutate(payload);
  };

  const query = useQueryParams();

  useEffect(() => {
    switch (location.pathname) {
      case '/send-swag/payment/review-shipment':
        setCurrentStep(1);
        break;
      case '/send-swag/payment/payment-method':
        setCurrentStep(2);
        break;
      case '/send-swag/payment/place-shipment':
        setCurrentStep(3);
        break;
      default: {
        history.replace({ pathname: '/send-swag/payment/review-shipment', search: query.toString() });
        setCurrentStep(1);
      }
    }
  }, [history, location.pathname, query]);

  const handleContinue = async totalValue => {
    switch (location.pathname) {
      case '/send-swag/payment/review-shipment':
        history.replace({ pathname: '/send-swag/payment/payment-method', search: query.toString() });
        break;
      case '/send-swag/payment/payment-method':
        handleSendSwag(totalValue);
        break;
      default:
        history.push('/send-swag/payment/review-shipment');
    }
  };

  const queries = [paymentProfileQuery, sendSwagMutation, addCredits];
  const isLoading = queries.some(q => q.isLoading);
  const total = round(summary.domestic.total + summary.international.total, 2);

  useEffect(() => {
    const maxCredit = total <= balance ? total : balance;
    setInputValue(useCreditsFirst ? maxCredit : 0);
  }, [useCreditsFirst, total, balance]);

  if (shipment.directoryOrders.length === 0 && !sendSwagMutation.isLoading && !sendSwagMutation.isSuccess)
    return <Redirect to="/send-swag" />;

  const hasSomeInvalidShippingDate = doesShipmentHasSomeInvalidDates(shipment, shippingCutoffHour);

  return (
    <Grid
      container
      justifyContent="center"
      className={classes.container}
    >
      {isLoading && <Loader />}
      <Snackbar
        open={Boolean(errorMessage)}
        classes={{ anchorOriginTopCenter: classes.errorAlert }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={8000}
        onClose={() => setErrorMessage('')}
      >
        <ErrorAlert error={errorMessage} action={() => setErrorMessage('')} style={{ marginTop: 36 }} />
      </Snackbar>

      <Box container alignItems="flex-start" style={{ padding: '32px 80px 0px' }}>
        {/* <GoBack /> */}
        <Grid container spacing={10}>
          <Grid item xs style={{ paddingRight: 40 }}>
            <WizardStepper
              currentStep={currentStep}
              steps={wizardSteps}
              exactProgress={[3, 46, 85]}
              basePath="/send-swag/payment"
              paddingRight={26}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={4} alignItems="center" style={{ marginBottom: 18 }}>
                    <Grid item>
                      <p className={classes.title}>Review your shipment</p>
                    </Grid>
                    <Grid item>
                      {totalWarnings > 0 && (
                        <Grid item>
                          <Grid container className={classes.warningsBadge}>
                            <Warning style={{ width: 12, height: 12 }} />
                            <span style={{ marginLeft: 4 }}>
                              {totalWarnings} {`Warning${totalWarnings > 1 ? 's' : ''}`}
                            </span>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.recipientsContainer}>
                    <OrderOverview paginatedDirectoryOrders={paginatedDirectoryOrders} />
                  </Box>
                </Grid>
              </Grid>
              <PaymentMethod
                inputValue={inputValue}
                currentBalance={balance}
                setInputValue={setInputValue}
                useCreditFirst={useCreditsFirst}
                setUseCreditFirst={setUseCreditsFirst}
                setInvalid={setInvalid}
                total={total}
              />
              <p>Place Shipment</p>
            </WizardStepper>
          </Grid>
          <Grid item xs={4}>
            <ShippingSummary
              balance={balance}
              paymentProfiles={paymentProfiles}
              useCreditsFirst={useCreditsFirst}
              errorMessage={errorMessage}
              onError={setErrorMessage}
              onContinue={handleContinue}
              canPlaceOrder={currentStep === 2}
              inputValue={inputValue}
              invalid={invalid && currentStep === 2}
              hasSomeInvalidShippingDate={hasSomeInvalidShippingDate}
              processSendSwag={processSendSwag.processing}
              preventFurtherActions={preventFurtherActions}
            />
          </Grid>
        </Grid>
      </Box>
      {currentStep === 1 && (
        <Grid className={classes.stickyBar}>
          <Box style={{ padding: '0px 80px 0px' }}>
            <Grid container spacing={10} style={{ paddingRight: 80 }}>
              <Grid item xs>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Pagination
                      count={shipment.directoryOrders.length}
                      endText="shipments"
                      perPage={pagination.perPage}
                      onNext={pagination.onNext}
                      pageIndex={pagination.pageIndex}
                      onPrevious={pagination.onPrevious}
                      sizeOptions={pagination.sizeOptions}
                      onPerPageChange={pagination.changeSize}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4} />
            </Grid>
          </Box>
        </Grid>
      )}
      {/* <Grid className={classes.stickyBar}>
        <CenteredGrid container justifyContent="space-between" alignItems="center">
          <Pagination
            count={shipment.directoryOrders.length}
            endText="shipments"
            perPage={pagination.perPage}
            onNext={pagination.onNext}
            pageIndex={pagination.pageIndex}
            onPrevious={pagination.onPrevious}
            sizeOptions={pagination.sizeOptions}
            onPerPageChange={pagination.changeSize}
          />
          <CustomTooltip
            title={
              processSendSwag?.processing
                ? 'There is a pending Send Swag request being processed, this could take up to 10 mins to complete.'
                : 'Some shipping dates are invalid. Please go back and reschedule the shipments in order to continue.'
            }
            disableHoverListener={!hasSomeInvalidShippingDate || processSendSwag?.processing}
          >
            <Button
              className={classes.sendButton}
              onClick={() => handleSendSwag(total)}
              disabled={
                !canSendSwag(company, total, paymentProfiles, useCreditsFirst, isAchPrepaid) ||
                hasSomeInvalidShippingDate ||
                processSendSwag?.processing
              }
            >
              Send Swag
            </Button>
          </CustomTooltip>
        </CenteredGrid>
      </Grid> */}
    </Grid>
  );
};

export default ShipmentsReview;
