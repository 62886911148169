import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { Link } from 'react-router-dom';
import { ArrowForward } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import styles from './styles/membershipPanel';
import { useMembership } from '../../contexts/membershipContext';
import { intStr } from '../../helpers/utils';
import EarlyBirdTimer from './EarlyBirdTimer';

const useStyles = makeStyles(styles);

const ManageMembership = ({ classes, upgradeNow }) => (
  <Link to="/membership/manage-membership">
    <Grid container spacing={2}>
      <Grid item className={classes.cardMembershipText}>
        <Typography variant="body4RegularInter" className={classes.yourMembershipText}>
          {upgradeNow ? 'Upgrade' : 'Manage'}
        </Typography>
        <ArrowForward viewBox="0 -3 30 32" style={{ marginLeft: 4, color: '#3577D4' }} />
      </Grid>
    </Grid>
  </Link>
);

const ViewPoints = ({ classes }) => {
  const { leftBarNavigation } = useFlags();
  return (
    <Link to={leftBarNavigation ? '/settings/your-membership?tab=rewards' : '/membership/your-membership?tab=rewards'}>
      <Grid container spacing={2}>
        <Grid item className={classes.cardMembershipText}>
          <Typography variant="body4RegularInter" className={classes.yourMembershipText}>
            View
          </Typography>
          <ArrowForward viewBox="0 -3 30 32" style={{ marginLeft: 4, color: '#3577D4' }} />
        </Grid>
      </Grid>
    </Link>
  );
};

const YourMembership = ({ membership, classes }) => {
  //  TODO Update Free Tier Subscription Name in the below line when going Live
  const { image, isFreeTier } = membership;
  return (
    <Grid container spacing={6} alignItems="center" className={classes.yourMembership}>
      <Grid item>
        <Box className={classes.membershipImageWrapper}>
          <img className={classes.membershipImage} src={image} alt="membership" />
        </Box>
      </Grid>
      <Grid item xs className={classes.rewardsDetails}>
        <Grid className={classes.cardMembership}>
          <Typography variant="body2MediumInter" style={{ color: '#0B1829' }}>
            Membership
          </Typography>
          <ManageMembership classes={classes} upgradeNow={isFreeTier} />
        </Grid>
        <Typography variant="h3BoldInter" className={classes.membershipItemName}>
          {membership.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

const YourRewards = ({ account, classes }) => {
  return (
    <Grid container alignItems="center" spacing={4} className={classes.yourRewards}>
      <Grid item>
        <Box className={classes.membershipImageWrapper}>
          <img className={classes.membershipImage} src="/images/membership/rewards.png" alt="rewards" />
        </Box>
      </Grid>
      <Grid item xs className={classes.rewardsDetails}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="body2MediumInter" style={{ color: '#0B1829' }}>
              Rewards Points Balance
            </Typography>
          </Grid>
          <Grid item>
            <ViewPoints classes={classes} />
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs>
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item>
                <Typography variant="h5BoldInter" className={classes.rewardsPoint}>
                  {intStr(parseInt(account.rewards_points_balance, 10))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Expired = ({ classes }) => (
  <Grid container justifyContent="center" alignItems="center" className={classes.expired}>
    <Grid item>
      <Typography className={classes.expiredText}>Early Bird Offer Expired</Typography>
    </Grid>
  </Grid>
);

const EarlyBirdTop = ({ classes, isFreeTier }) => {
  const isExpired = dayjs().isAfter('09/15/2023');
  const ForMembers = isExpired ? Expired : EarlyBirdTimer;
  // eslint-disable-next-line prettier/prettier
  const finalText = isFreeTier ? <span>You <strong>could</strong> get 2X the points until Sept 15</span> : <Grid container alignItems="center"><Grid item xs>Get 2X the points</Grid> <Grid item><ForMembers classes={classes} /></Grid></Grid>;
  return (
    <Grid container alignItems="center" className={classes.yourRewardsOnBird}>
      <Grid item>
        <Box className={classes.birdImageWrapper}>
          <img className={classes.membershipImage} src="/images/public/early-bird/early-bird.png" alt="early bird" />
        </Box>
      </Grid>
      <Grid item xs>
        <Typography variant="body3MediumInter" className={classes.membershipBidText}>
          {finalText}
        </Typography>
      </Grid>
    </Grid>
  );
};

const MembershipPanel = () => {
  const { currentMembership, company } = useMembership();
  const { earlyBirdPromotion, leftBarNavigation } = useFlags();
  const classes = useStyles({ upgradeNow: currentMembership.isFreeTier, earlyBirdPromotion });
  return (
    <Grid container lg={8} md={8} sm={12} xs={12} className={classes.separateOrders}>
      {!earlyBirdPromotion && !leftBarNavigation && (
        <Grid container style={{ marginBottom: 0, marginTop: 6 }}>
          <Grid item xs>
            <Typography variant="h5SemiBoldInter" className={classes.membershipTitle}>
              Your Membership
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container alignItems="flex-end">
        <Grid item xs className={classes.subContainer}>
          <YourMembership membership={currentMembership || {}} classes={classes} />
        </Grid>
        <Grid item xs className={classes.subContainerRight}>
          {earlyBirdPromotion && <EarlyBirdTop classes={classes} isFreeTier={currentMembership.isFreeTier} />}
          <YourRewards account={company || {}} classes={classes} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MembershipPanel;
