import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = theme => ({
  orderImg: {
    objectFit: 'contain',
    borderRadius: 8,
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.04)'
  },
  membershipTitle: {
    color: '#0B1829',
    fontSize: '20px',
    fontFamily: 'Gilroy',
    fontWeight: 600,
    letterSpacing: '0.129px',
    marginLeft: 20
  },
  yourMembership: {
    height: '100%'
  },
  divider: { background: '#E5E7E8' },
  yourMembershipWrapper: ({ upgradeNow }) =>
    upgradeNow ? { background: '#FE8E26', borderRadius: 32, height: 25, width: 100 } : { padding: '4px 0px' },
  yourMembershipText: ({ upgradeNow }) =>
    upgradeNow
      ? {
          fontWeight: 600,
          color: '#3577D4',
          fontSize: 12,
          fontFamily: 'Inter',
          fontWight: 600,
          lineHeight: '12px'
        }
      : {
          fontWeight: 600,
          color: '#3577D4',
          textAlign: 'center',
          fontSize: 12,
          fontFamily: 'Inter',
          lineHeight: '12px',
          fontWight: 600
        },
  yourPointsWrapper: { border: '1px solid #2BB572', borderRadius: 12, padding: '4px 8px' },
  yourPointsText: {
    color: '#2BB572',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Inter',
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  subContainer: {
    border: '1px solid #EBEDF0',
    borderRadius: '16px',
    padding: '16px 6px 16px 12px',
    marginRight: 12,
    marginTop: ({ earlyBirdPromotion }) => (earlyBirdPromotion ? 38 : 0)
  },
  birdImageWrapper: {
    width: '50px',
    height: '38px',
    position: 'relative'
  },
  membershipBidText: { color: '#FFFFFF' },
  yourRewardsOnBird: {
    bordeRadius: '16px 16px 0px 0px',
    background: '#6AC6DE',
    paddingRight: 6,
    marginBottom: 16
  },
  subContainerRight: {
    border: ({ earlyBirdPromotion }) => (earlyBirdPromotion ? '1px solid #6AC6DE' : '1px solid #EBEDF0'),
    borderRadius: '16px',
    overflow: 'hidden',
    padding: ({ earlyBirdPromotion }) => (earlyBirdPromotion ? '0px 0px 16px 0px' : '16px 6px 16px 12px'),
    marginLeft: 12
  },
  statusPanelTitle: { color: '#0B1829', marginBottom: 8 },
  statusPanelDescription: { color: '#868A8F', marginBottom: 4 },
  yourRewards: {
    height: '100%',
    paddingLeft: ({ earlyBirdPromotion }) => (earlyBirdPromotion ? 4 : 0)
  },
  expired: {
    padding: '2px 16px',
    background: '#F44336',
    height: 30,
    borderRadius: 15
  },
  expiredText: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px'
  },
  membershipImageWrapper: {
    position: 'relative',
    width: 56,
    height: 56
  },
  membershipImage: {
    width: '100%',
    height: '100%'
  },
  rewardsDetails: {
    paddingLeft: '8px'
  },
  membershipItemName: {
    color: '#3577D4',
    fontFamily: 'Inter',
    fontSize: 32,
    fontWeight: 500,
    lineHeight: '44px',
    textWrap: 'wrap',
    marginBottom: 0,
    maxWidth: 272,
    ...ellipsisStyles
  },
  membershipItemDescription: {
    color: '#808080',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    textWrap: 'wrap'
  },
  membershipItemPrice: {
    color: '#125CFF',
    lineHeight: '32px',
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 4
  },
  rewardsPoint: {
    marginTop: 8,
    fontFamily: 'Inter',
    fontSize: 32,
    fontWeight: 500,
    color: '#4CAF50',
    lineHeight: '44px'
  },
  separateOrders: {
    paddingRight: 25,
    [theme.breakpoints.down('sm')]: { paddingRight: 0 }
  },
  freePlanChip: {
    width: '60px',
    height: '18px',
    backgroundColor: '#FE8E26',
    color: '#FFFFFF',
    marginLeft: 8,
    borderRadius: '2px',
    '&:hover, &:focus': {
      boxShadow: 'none',
      backgroundColor: '#FE8E26'
    }
  },
  cardMembership: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  cardMembershipText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

export default styles;
