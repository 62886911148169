import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, MenuItem } from '@material-ui/core';
import { zipCodeText, normalizeUSZip } from '../../../helpers/utils';
import { CompanyWarningTooltip } from '../../shared';
import { TextField, SelectField, PhoneField } from '../../global/reactHookFormFields';
import { useCountries, useMemoizeStateFields, useSizes } from '../../../hooks';

const ContactFields = ({ onSelectSize }) => {
  const { data: sizes } = useSizes({ select: sizesData => sizesData.filter(s => s.category === 'Apparel') });
  const { data: countries } = useCountries();
  const { formState, control, register, setValue, watch, trigger } = useFormContext();
  const { errors } = formState;

  const [country, state] = watch(['shipping_country', 'shipping_state']);

  useEffect(() => {
    if (country) trigger();
  }, [country, trigger]);
  const [onCountryChange, onStateChange] = useMemoizeStateFields({ country, change: setValue, initialState: state });

  const isInternational = country !== 'US';

  const handleChangeZip = e => {
    if (!isInternational) {
      const { value } = e.target;
      e.target.value = normalizeUSZip(value);
    }
  };

  const provincesDictionary = useMemo(() => {
    const provinces = {};
    countries.forEach(c => {
      provinces[c.iso2] = c.provinces;
    });
    return provinces;
  }, [countries]);

  const provinces = provincesDictionary[country] ?? [];

  useEffect(() => {
    const isInvalidState = provinces.length > 0 && !provinces.some(p => p.code === state);
    if (isInvalidState) {
      setValue('shipping_state', '');
    }
  }, [provinces, state, setValue]);

  const handleStateChange = e => {
    trigger('shipping_zip');
    onStateChange(e);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SelectField
          name="shipping_country"
          error={errors.shipping_country?.message}
          totalItems={countries.length}
          onSelectChange={onCountryChange}
          control={control}
          label="Country"
          hideLabel
          defaultValue=""
          fullWidth
          withTooltip
        >
          {countries?.map(c => (
            <MenuItem key={c.iso2} value={c.iso2}>
              {c.name}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid style={{ paddingRight: 12 }} item xs={6}>
        <TextField
          autoFocus
          autoComplete="first_name"
          error={errors.first_name?.message}
          placeholder="First name"
          {...register('first_name', { deps: ['last_name'] })}
          fullWidth
          withTooltip
        />
      </Grid>
      <Grid item style={{ paddingLeft: 12 }} xs={6}>
        <TextField
          autoComplete="last_name"
          error={errors.last_name?.message}
          placeholder="Last name"
          {...register('last_name', { deps: ['first_name'] })}
          fullWidth
          withTooltip
        />
      </Grid>
      <Grid item style={{ paddingRight: 12 }} xs={6}>
        <CompanyWarningTooltip>
          <TextField
            autoComplete="company_name"
            error={errors.company?.message}
            placeholder="Company"
            {...register('company_name')}
            fullWidth
            withTooltip
          />
        </CompanyWarningTooltip>
      </Grid>
      <Grid style={{ paddingLeft: 12 }} item xs={6}>
        <SelectField
          name="size"
          error={errors.size?.message}
          totalItems={sizes.length}
          control={control}
          label="Shirt Size"
          hideLabel
          defaultValue=""
          fullWidth
          withTooltip
          errorType="warning"
        >
          <MenuItem value="" onClick={() => onSelectSize('size_none')}>
            <i>None</i>
          </MenuItem>
          {sizes.map(size => (
            <MenuItem key={size.id} value={size.id} onClick={() => onSelectSize(null)}>
              {size.name}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item style={{ paddingRight: 12 }} xs={6}>
        <PhoneField
          name="phone_number"
          error={errors.phone_number?.message}
          country={country}
          control={control}
          defaultValue=""
          placeholder="Phone number"
          variant="outlined"
          withTooltip
        />
      </Grid>
      <Grid item style={{ paddingLeft: 12 }} xs={6}>
        <TextField
          autoComplete="email"
          error={errors.email?.message}
          placeholder="Email"
          {...register('email')}
          fullWidth
          withTooltip
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="shipping_address1"
          error={errors.shipping_address1?.message}
          placeholder="Address"
          {...register('shipping_address1', { deps: ['shipping_zip'] })}
          fullWidth
          withTooltip
        />
      </Grid>
      <Grid item style={{ paddingRight: 12 }} xs={6}>
        <TextField
          error={errors.shipping_address2?.message}
          autoComplete="shipping_address2"
          placeholder="Floor, suite, unit (optional)"
          {...register('shipping_address2')}
          fullWidth
          withTooltip
        />
      </Grid>
      <Grid item style={{ paddingLeft: 12 }} xs={6}>
        <TextField
          autoComplete="shipping_city"
          error={errors.shipping_city?.message}
          placeholder="City"
          {...register('shipping_city', { deps: ['shipping_zip'] })}
          fullWidth
          withTooltip
        />
      </Grid>
      <Grid item style={{ paddingRight: 12 }} xs={6}>
        {provinces.length === 0 ? (
          <TextField
            autoComplete="shipping_state"
            error={errors.shipping_state?.message}
            placeholder="State / Province / Region"
            {...register('shipping_state')}
            onChange={handleStateChange}
            fullWidth
            withTooltip
          />
        ) : (
          <SelectField
            name="shipping_state"
            error={errors.shipping_state?.message}
            onSelectChange={handleStateChange}
            totalItems={provinces.length}
            label="State"
            hideLabel
            defaultValue=""
            control={control}
            fullWidth
            withTooltip
          >
            {provinces.map(p => (
              <MenuItem key={p.code} value={p.code}>
                {p.name}
              </MenuItem>
            ))}
          </SelectField>
        )}
      </Grid>
      <Grid item style={{ paddingLeft: 12 }} xs={6}>
        <TextField
          error={errors.shipping_zip?.message}
          autoComplete="shipping_zip"
          placeholder={zipCodeText(isInternational)}
          {...register('shipping_zip')}
          onInput={handleChangeZip}
          fullWidth
          withTooltip
        />
      </Grid>
    </Grid>
  );
};

export default ContactFields;
