import { FETCH_METADATA } from '../actions/types';

const MetadataReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_METADATA:
      return action.payload;
    default:
      return state;
  }
};

export default MetadataReducer;
