import React from 'react';
import { Grid } from '@material-ui/core';
import ImageWithDescription from './ImageWithDescription';
import { getSortedBySize } from '../../../../helpers/utils';

const MultiItemProducts = ({ products }) => (
  <Grid style={{ paddingTop: 38 }} container>
    {getSortedBySize(products).map(({ product, quantity, size }) => (
      <Grid key={`${product.id}-${size.id}`} item xs={4} style={{ marginBottom: 24 }}>
        <ImageWithDescription
          width={102}
          height={102}
          name={product.name}
          color={product.theme_color}
          quantity={quantity}
          extraValue={`Size: ${size.name}`}
          img={product.image}
          showTrackingUrl={false}
        />
      </Grid>
    ))}
  </Grid>
);
export default MultiItemProducts;
