const styles = theme => ({
  subscriberContainer: { margin: '90px 0' },
  container: {
    padding: '64px 0 100px 0',
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: '75px 5px 75px 5px',
    backgroundColor: '#9846dd',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      paddingLeft: '0px',
      minWidth: '1280px'
    }
  },
  center: {
    [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
  },
  miniTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#ffffff',
    textTransform: 'uppercase',
    '&::before, &::after': {
      display: 'inline-block',
      content: "''",
      borderTop: '2px solid white',
      width: '4rem',
      margin: '0 40px',
      transform: 'translateY(-0.3rem)',
      [theme.breakpoints.only('sm')]: { margin: '0 30px' },
      [theme.breakpoints.down('xs')]: { margin: '0 10px' }
    }
  },
  title: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '3.5rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.14,
    letterSpacing: 'normal',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: { fontSize: '2.75rem' }
  },
  subText: {
    opacity: 0.8,
    color: '#ffffff',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.25rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    marginLeft: 15,
    marginRight: 15
  },
  emailForm: { marginTop: 50, minHeight: 100 },
  inputContainer: {
    width: '100%',
    maxWidth: '400px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 25,
      paddingRight: 25
    }
  },
  input: {
    color: '#ffffff',
    fontSize: '1.25rem',
    minWidth: '280px !important',
    maxWidth: '400px !important',
    width: '100%',
    marginTop: 10
  },
  subscribeButtonContainer: {
    paddingLeft: 30,
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingTop: 20
    }
  },
  subscribeButton: {
    padding: '20px 40px',
    borderRadius: 32,
    backgroundColor: '#ffffff',
    border: 'none',
    cursor: 'pointer',
    '& span': {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: '1rem',
      fontWeight: 600,
      color: '#9846dd',
      textTransform: 'none'
    },
    '&:hover': { backgroundColor: '#ececec' }
  }
});

export default styles;
