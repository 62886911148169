import { FETCH_EMPLOYEES, DELETE_EMPLOYEE, SAVE_EMPLOYEE, ADD_EMPLOYEE, DELETE_EMPLOYEES } from '../actions/types';

const EmployeeReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES:
      return action.payload;

    case DELETE_EMPLOYEE:
      return state.filter(employee => employee.id !== action.payload);

    case DELETE_EMPLOYEES: {
      const ids = new Set(action.payload);
      return state.filter(employee => !ids.has(employee.id));
    }

    case ADD_EMPLOYEE:
      return [action.payload, ...state];

    case SAVE_EMPLOYEE:
      return state.map(employee => (employee.id === action.payload.id ? action.payload : employee));

    default:
      return state;
  }
};

export default EmployeeReducer;
