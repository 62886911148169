import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Box, FormControlLabel, Grid, IconButton, MenuItem, Slider, makeStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { debounce, isEmpty } from 'lodash';
import { AddCircle, AddCircleOutlineRounded, Delete, Edit, NewReleases, Settings, Update } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal } from '../../shared/Modal';
import { actionImageResolver, actionTextResolver, imageResolver } from './common';
import styles from './styles/automationActionSelectionModal';
import AutomationConfigurationFirst from './AutomationConfigurationFirst';
import AutomationConfigurationSecond from './AutomationConfigurationSecond';
import AutomationConfigurationThird from './AutomationConfigurationThird';
import AutomationConfigurationFourth from './AutomationConfigurationFourth';
import { CustomSlimTextFieldThin, CustomTextField } from '../../storefronts/storeCommon';
import { StylessButton } from '../../buttons';
import AutomationConfigurationSixth from './AutomationConfigurationSixth';
import AutomationConfigurationFifth from './AutomationConfigurationFifth';
import { SmallSwitch } from '../../pages/orders/requested/shipments/sendSwagFlow/common';
import useMembershipCheck from '../../../hooks/useMembershipCheck';
import { CustomTooltip } from '../../products/commonProductsElements';
import { IntegrationCustomSelectField, IntegrationSlimTextField } from './commonComponents';
import AutomationConfigurationSeventh from './AutomationConfigurationSeventh';

const useStyles = makeStyles(styles);

const defaultSettings = {
  automatic: true
};

const sendSwagDefaultSettings = {
  automatic: true,
  deliveryMethods: { domestic: 'Standard', international: 'Standard' }
};

const AutomatationOptions = ({ action, onActionUpdate, selectedAction, shopCreditsAllowed }) => {
  const actionText = actionTextResolver(action.type);
  const classes = useStyles({ shopCreditsNotAllowed: shopCreditsAllowed === false });
  return (
    <CustomTooltip
      title="This feature is only available for Platinum Members"
      disableHoverListener={shopCreditsAllowed !== false}
    >
      <Grid container alignItems="center" className={classes.automatationOptions}>
        <Grid item>
          <Box className={classes.actionImageContainer}>
            <img src={actionImageResolver(action.type)} alt={action?.name} className={classes.itemImage} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box className={classes.actionText}>
            <Typography variant="body2SemiBoldInter">{actionText.title}</Typography>
            <Typography variant="body4RegularInter">{actionText.subtitle}</Typography>
          </Box>
        </Grid>
        <Grid item>
          {shopCreditsAllowed === false ? (
            <Button
              size="small"
              variant="secondary"
              component={Link}
              to="/membership/manage-membership-switch"
              className={classes.automatationOptionButton}
            >
              Upgrade to Platinum
            </Button>
          ) : (
            <>
              {action.type === selectedAction?.type ? (
                <Button
                  size="small"
                  variant="secondary"
                  onClick={() => onActionUpdate(selectedAction)}
                  className={classes.automatationOptionButton}
                  disabled={shopCreditsAllowed === false}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="primary"
                  onClick={() => onActionUpdate({ ...action, id: selectedAction?.id })}
                  className={classes.automatationOptionButton}
                  disabled={shopCreditsAllowed === false}
                >
                  Setup
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </CustomTooltip>
  );
};

const timeMeasures = [
  { id: 1, title: 'Years', value: 'years' },
  { id: 2, title: 'Days', value: 'days' }
];

const YearAutomationItem = ({ year, onAnniversaryYearDelete, onYearChange, onAnniversaryYearEdit }) => {
  const [inputValue, setInputValue] = useState(inputValue % 365 === 0 ? parseInt(inputValue / 365, 10) : inputValue);
  const [timeMeasure, setTimeMeasure] = useState('years');

  useEffect(() => {
    const isExactYear = year.value % 365 === 0;
    if (isExactYear) {
      if (timeMeasure !== 'years') setTimeMeasure('years');

      const exactYears = parseInt(year.value / 365, 10);
      if (inputValue !== exactYears) setInputValue(exactYears);
    } else {
      if (timeMeasure !== 'days') setTimeMeasure('days');

      if (inputValue !== year.value) setInputValue(year.value);
    }
  }, [year]);

  const handleOnChange = event => {
    const {
      target: { name, value }
    } = event;
    if (name === 'inputTimeMeasure') {
      setTimeMeasure(value);
      onYearChange({ ...year, value: value === 'years' ? parseInt(inputValue, 10) * 365 : parseInt(inputValue, 10) });
    }

    // if (name === 'inputNumber' && value) {
    //   // setInputValue(parseInt(value, 10));
    //   onYearChange({ ...year, value: timeMeasure === 'years' ? parseInt(value, 10) * 365 : parseInt(value, 10) });
    // }
  };

  const debouncedHandleOnChange = debounce(value => {
    if (!isEmpty(value)) {
      setInputValue(parseInt(value, 10));
      onYearChange({ ...year, value: timeMeasure === 'years' ? parseInt(value, 10) * 365 : parseInt(value, 10) });
    }
  }, 100);

  const classes = useStyles();

  return (
    <Box key={year.value} className={classes.automatationOptions}>
      <Grid container alignItems="center" spacing={4}>
        <Grid item>
          <Typography variant="subtitle2SemiBoldInter">Milestone</Typography>
        </Grid>
        <Grid item>
          <Box>
            <IntegrationSlimTextField
              placeholder={year.value % 365 === 0 ? 'Years' : 'Days'}
              name="inputNumber"
              type="number"
              onChange={event => debouncedHandleOnChange(event.target.value)}
              autoFocus
              style={{ width: 68 }}
              value={inputValue}
              // error={error}
            />
            {/* <CustomSlimTextFieldThin value={year.value} readOnly fullWidth /> */}
          </Box>
        </Grid>
        <Grid item>
          <IntegrationCustomSelectField
            id="inputTimeMeasure"
            name="inputTimeMeasure"
            value={timeMeasure}
            totalItems={timeMeasures.length}
            handleSelect={handleOnChange}
            fullWidth
            withTooltip
          >
            {timeMeasures.map(tm => (
              <MenuItem key={tm.id} value={tm.value} title={tm.title}>
                <Typography
                  variant="body4RegularInter"
                  className={classes.inputLabel}
                  style={{ margin: '1px 0px 0px 0px' }}
                >
                  {tm.title}
                </Typography>
              </MenuItem>
            ))}
          </IntegrationCustomSelectField>
          {/* <Box style={{ width: 172 }}>
          <Slider
            value={year.value}
            min={1}
            max={20}
            step={1}
            onChange={(event, newValue) => onYearChange({ ...year, value: newValue })}
            aria-labelledby="input-slider"
          />
        </Box> */}
        </Grid>
        <Grid item xs />
        <Grid item style={{ padding: '0px 16px' }}>
          {!isEmpty(year.action) && (
            <FormControlLabel
              labelPlacement="start"
              control={
                <SmallSwitch
                  checked={year.active}
                  onChange={({ target: { checked } }) => onYearChange({ ...year, active: checked })}
                  color="primary"
                  name="checkedBtn"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={
                <Typography variant="body4RegularInter" style={{ color: '#4A4F54', marginRight: 8 }}>
                  {year.active ? 'Pause' : 'Resume'}
                </Typography>
              }
            />
          )}
        </Grid>
        <Grid item>
          <Button variant="text" onClick={() => onAnniversaryYearEdit(year)} className={classes.editIconButton}>
            {isEmpty(year.action) ? (
              <>
                <span>Setup</span> <Settings className={classes.editIcon} style={{ marginBottom: 0 }} />
              </>
            ) : (
              <>
                <span>Edit</span> <Edit className={classes.editIcon} />
              </>
            )}
          </Button>
        </Grid>
        <Grid item>
          <IconButton variant="primary" size="small" onClick={() => onAnniversaryYearDelete(year)}>
            <Delete style={{ color: '#f44336' }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const AutomationSelectionModal = ({
  isAutomationSetupOpen,
  automation,
  integrationName,
  setIsAutomationSetupOpen,
  onIntegrationUpdate,
  onAutomationUpdate,
  onDisconnect,
  isLoading,
  selectedPlatform,
  employeeTypes
}) => {
  const [selectedAction, setSelectedAction] = useState({});

  const [selectedAutomation, setSelectedAutomation] = useState();

  const [selectedYear, setSelectedYear] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentInnerStep, setCurrentInnerStep] = useState(1);

  const handelOnCommitChanges = () => {
    if (automation?.type === 'anniversaries') {
      onIntegrationUpdate({ ...selectedYear, action: selectedAction }, () => setCurrentStep(prev => prev + 1));
    } else
      onIntegrationUpdate({ ...selectedAutomation, action: selectedAction }, () => setCurrentStep(prev => prev + 1));
  };

  // useEffect(() => {
  //   if (
  //     (selectedAction?.type === 'send_swag' && currentStep === 5) ||
  //     (selectedAction?.type === 'redeem_link' && currentStep === 4) ||
  //     (selectedAction?.type === 'shop_credits' && currentStep === 4)
  //   ) {
  //     if (automation?.type === 'anniversaries') {
  //       onIntegrationUpdate({ ...selectedYear, action: selectedAction });
  //     } else onIntegrationUpdate({ ...selectedAutomation, action: selectedAction });
  //   }
  // }, [selectedAction, currentStep]);

  useEffect(() => {
    if (automation?.type === 'anniversaries') {
      setSelectedAction(automation?.years?.[0]?.action);
    } else setSelectedAction(automation?.action);
  }, [automation]);

  const debouncedYearChange = debounce(year => onAutomationUpdate({ ...year, anniversary_days: year.value }), 1000);

  useEffect(() => {
    if (!isEmpty(selectedYear)) {
      setSelectedAutomation(prev => ({
        ...prev,
        years: prev.years.map(year => (year.id === selectedYear.id ? selectedYear : year))
      }));
      if (!isEmpty(selectedYear.action) && selectedYear.edit)
        debouncedYearChange({ id: selectedYear.id, value: selectedYear.value, active: selectedYear.active });
    }
  }, [selectedYear]);

  useEffect(() => {
    setSelectedAutomation(automation);
  }, [automation]);

  useEffect(() => {
    if (selectedAutomation?.type === 'anniversaries') {
      if (isEmpty(selectedAutomation.years)) {
        setSelectedAutomation(prev => ({
          ...prev,
          years: [{ id: 1, value: 365, name: 'Year 1', action: {} }]
        }));
      }
    }
  }, [selectedAutomation]);

  const onActionUpdateNext = a => {
    setSelectedAction(a);
    setCurrentStep(prev => prev + 1);
  };

  const isEmployeeTypesEmpty = isEmpty(employeeTypes);

  const canContinue = () => {
    switch (true) {
      case currentStep === 1:
        return true;
      case currentStep === 2:
        switch (true) {
          case selectedAction?.type === 'send_swag':
            return !isEmpty(selectedAction?.settings?.product);
          case selectedAction?.type === 'redeem_link':
            return !isEmpty(selectedAction?.settings?.redeem_page);
          case selectedAction?.type === 'shop_credits':
            return !isEmpty(selectedAction?.settings?.store);
          default:
            return false;
        }
      case currentStep === 3:
        switch (true) {
          case selectedAction?.type === 'send_swag':
            return selectedAction?.settings?.product?.size > 0;
          case selectedAction?.type === 'redeem_link':
            const redeemPage = selectedAction?.settings?.redeem_page;
            return (
              !redeemPage?.showCustomMessage ||
              (!isEmpty(redeemPage?.customMessage) && !isEmpty(redeemPage?.customSubject))
            );
          case selectedAction?.type === 'shop_credits':
            const store = selectedAction?.settings?.store;
            return (
              store?.giftAmount > 0 &&
              (!store?.showCustomMessage || (!isEmpty(store?.customMessage) && !isEmpty(store?.customSubject)))
            );
          default:
            return false;
        }
      case currentStep === 4:
        return selectedAction?.type === 'send_swag'
          ? !isEmpty(selectedAction?.settings?.deliveryMethods?.domestic) &&
              !isEmpty(selectedAction?.settings?.deliveryMethods?.international)
          : isEmployeeTypesEmpty
          ? !selectedAction?.settings?.error
          : selectedAction?.settings?.employeeTypes?.length > 0;
      case currentStep === 5:
        return selectedAction?.type === 'send_swag' && !isEmployeeTypesEmpty
          ? selectedAction?.settings?.employeeTypes?.length > 0
          : !selectedAction?.settings?.error;
      case currentStep === 6:
        return !selectedAction?.settings?.error;
      case currentStep === 7:
        return !selectedAction?.settings?.error;
      default:
        return false;
    }
  };

  const onActionUpdate = a => setSelectedAction(a);

  const onYearChange = year => setSelectedYear(year);

  const onAnniversaryYearEdit = year => {
    onYearChange(year);
    setSelectedAction(year.action);
    setCurrentInnerStep(2);
  };

  const onAnniversaryYearDelete = year => {
    onDisconnect(year);
    setSelectedAutomation(prev => ({ ...prev, years: prev.years.filter(y => y.id !== year.id) }));
  };

  const membershipCheck = useMembershipCheck();

  const isCommitStep =
    !!selectedAction &&
    ((selectedAction.type === 'send_swag' && currentStep === (isEmployeeTypesEmpty ? 6 : 7)) ||
      (selectedAction.type === 'redeem_link' && currentStep === (isEmployeeTypesEmpty ? 5 : 6)) ||
      (selectedAction.type === 'shop_credits' && currentStep === (isEmployeeTypesEmpty ? 5 : 6)));

  const continueText = isCommitStep ? 'Finish' : 'Continue';

  const classes = useStyles();

  return (
    <Modal
      open={isAutomationSetupOpen}
      closeButtonClass={classes.closeButton}
      title={
        <Box className={classes.modalHeader}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Box className={classes.flowImageContainer}>
                    <img src={imageResolver(automation?.type)} alt={automation?.name} className={classes.itemImage} />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Typography variant="body3MediumInter" className={classes.modalHeaderTextLeft}>
                    {automation?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Box className={classes.integrationImageContainer}>
                    <img
                      src={selectedPlatform?.connectionAccess?.image_url}
                      alt={automation?.name}
                      className={classes.itemImage}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                  <Typography variant="body3MediumInter" className={classes.modalHeaderTextRight}>
                    {selectedAction?.name || selectedYear?.action?.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box className={classes.flowImageContainer}>
                    <img
                      src={actionImageResolver(selectedAction?.type)}
                      alt={selectedAction?.name}
                      className={classes.itemImage}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
      onClose={() => {
        setIsAutomationSetupOpen(false);
        setCurrentStep(1);
        setCurrentInnerStep(1);
      }}
      typeform={classes.modalContent}
      className={classes.modalView}
      titleClass={classes.customTitle}
      actions={
        currentStep !== 1 &&
        ((selectedAction?.type === 'send_swag' && currentStep !== (isEmployeeTypesEmpty ? 7 : 8)) ||
          (selectedAction?.type === 'redeem_link' && currentStep !== (isEmployeeTypesEmpty ? 6 : 7)) ||
          (selectedAction?.type === 'shop_credits' && currentStep !== (isEmployeeTypesEmpty ? 6 : 7))) && (
          <Box style={{ padding: '0px 24px 18px 24px', width: '100%' }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Button size="small" variant="text" onClick={() => setCurrentStep(prev => prev - 1)}>
                  Previous
                </Button>
              </Grid>
              <Grid item xs />
              <Grid item>
                <Button
                  size="small"
                  variant="primary"
                  disabled={!canContinue()}
                  onClick={() => (isCommitStep ? handelOnCommitChanges() : setCurrentStep(prev => prev + 1))}
                  loading={isLoading}
                >
                  {continueText}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )
      }
    >
      <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
        {selectedAutomation?.type === 'anniversaries' ? (
          <Box>
            <SwipeableViews axis="x" index={currentInnerStep - 1} className={classes.swipeableViews} disabled>
              <Box className={classes.automatationOptionsContainer}>
                {selectedAutomation.years.map(year => (
                  <YearAutomationItem
                    key={year.id}
                    year={year}
                    onAnniversaryYearDelete={onAnniversaryYearDelete}
                    onYearChange={onYearChange}
                    onAnniversaryYearEdit={onAnniversaryYearEdit}
                  />
                ))}

                <StylessButton
                  onClick={() => {
                    const lastYear = selectedAutomation?.years[selectedAutomation?.years.length - 1];
                    const value = lastYear.value % 365 === 0 ? lastYear.value + 365 : lastYear.value + 1;
                    setSelectedAutomation(prev => ({
                      ...prev,
                      years: [
                        ...prev.years,
                        {
                          id: lastYear.id + 1,
                          value,
                          name: value % 365 === 0 ? `Year ${parseInt(value / 365)}` : `Day ${value}`,
                          action: {}
                        }
                      ]
                    }));
                  }}
                >
                  <Box className={classes.addYear}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <AddCircleOutlineRounded />
                      </Grid>
                      <Grid item>
                        <Typography variant="body3MediumInter" style={{ marginBottom: 3, color: '#3577d4' }}>
                          Add another milestone
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </StylessButton>
              </Box>
              <Box>
                <Typography variant="h5BoldInter" className={classes.automatationOptionsTitle}>
                  How would you like to set up this automation?
                </Typography>
                <Box className={classes.automatationOptionsContainer}>
                  <AutomatationOptions
                    action={{ type: 'send_swag', name: 'Send Swag', settings: sendSwagDefaultSettings }}
                    selectedAction={selectedAction}
                    onActionUpdate={onActionUpdateNext}
                  />
                  <AutomatationOptions
                    action={{ type: 'redeem_link', name: 'Send Redeem Link', settings: defaultSettings }}
                    selectedAction={selectedAction}
                    onActionUpdate={onActionUpdateNext}
                  />
                  <AutomatationOptions
                    action={{ type: 'shop_credits', name: 'Send Shop Credits', settings: defaultSettings }}
                    selectedAction={selectedAction}
                    onActionUpdate={onActionUpdateNext}
                    shopCreditsAllowed={membershipCheck.shopCreditsAllowed}
                  />
                </Box>
              </Box>
            </SwipeableViews>
          </Box>
        ) : (
          <Box>
            <Typography variant="h5BoldInter" className={classes.automatationOptionsTitle}>
              How would you like to set up this automation?
            </Typography>
            <Box className={classes.automatationOptionsContainer}>
              <AutomatationOptions
                action={{ type: 'send_swag', name: 'Send Swag', settings: sendSwagDefaultSettings }}
                selectedAction={selectedAction}
                onActionUpdate={onActionUpdateNext}
              />
              <AutomatationOptions
                action={{ type: 'redeem_link', name: 'Send Redeem Link', settings: defaultSettings }}
                selectedAction={selectedAction}
                onActionUpdate={onActionUpdateNext}
              />
              <AutomatationOptions
                action={{ type: 'shop_credits', name: 'Send Shop Credits', settings: defaultSettings }}
                selectedAction={selectedAction}
                onActionUpdate={onActionUpdateNext}
                shopCreditsAllowed={membershipCheck.shopCreditsAllowed}
              />
            </Box>
          </Box>
        )}
        <AutomationConfigurationFirst action={selectedAction} onActionUpdate={onActionUpdate} />
        <AutomationConfigurationSecond action={selectedAction} onActionUpdate={onActionUpdate} />
        <AutomationConfigurationThird
          action={selectedAction}
          onActionUpdate={onActionUpdate}
          employeeTypes={employeeTypes}
          isEmployeeTypesEmpty={isEmployeeTypesEmpty}
        />
        <AutomationConfigurationFourth
          action={selectedAction}
          onActionUpdate={onActionUpdate}
          automation={automation}
          employeeTypes={employeeTypes}
          isEmployeeTypesEmpty={isEmployeeTypesEmpty}
        />
        <AutomationConfigurationFifth
          action={selectedAction}
          onActionUpdate={onActionUpdate}
          automation={automation}
          isEmployeeTypesEmpty={isEmployeeTypesEmpty}
        />
        <AutomationConfigurationSixth
          action={selectedAction}
          automation={automation}
          onActionUpdate={onActionUpdate}
          isEmployeeTypesEmpty={isEmployeeTypesEmpty}
        />
        {!isEmployeeTypesEmpty && (
          <AutomationConfigurationSeventh
            action={selectedAction}
            automation={automation}
            onActionUpdate={onActionUpdate}
          />
        )}
      </SwipeableViews>
    </Modal>
  );
};

export default AutomationSelectionModal;
