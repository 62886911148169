export default () => ({
  paymentLabel: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    color: '#8d9299',
    display: 'flex',
    alignItems: 'center'
  },
  paymentCommonValue: {
    fontFamily: 'Gilroy-SemiBold',
    color: '#434d5c',
    fontSize: 16
  },
  totalContainer: {
    padding: '20px 0',
    borderTop: 'solid 1px #8d9299',
    borderBottom: 'solid 1px #8d9299'
  },
  paymentTotalValue: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: '#0f2440'
  },
  paymentCreditValue: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#45af5f'
  },
  creditsLeftContainer: {
    paddingTop: 21,
    borderTop: 'solid 1px #ebecf5'
  },
  cardSelect: {
    height: 'unset',
    padding: 0,
    '&:hover': { backgroundColor: 'transparent' },
    '& .MuiButton-label > span': {
      margin: '0 4px',
      color: '#434c5f',
      fontSize: 12
    }
  }
});
