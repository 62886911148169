import { LEFT_NAV_CLOSE, LEFT_NAV_OPEN, HIDE_LEFT_NAV } from '../actions/types';

const initialState = {
  leftNavOpen: true,
  hideLeftNav: false
};

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEFT_NAV_OPEN:
      return { ...state, leftNavOpen: true };
    case LEFT_NAV_CLOSE:
      return { ...state, leftNavOpen: false };
    case HIDE_LEFT_NAV:
      return { ...state, hideLeftNav: action.payload.hideLeftNav };
    default:
      return state;
  }
};

export default CommonReducer;
