import { loremIpsum } from '../helpers/helperConstants';

export const paymentProfiles = [
  {
    default: false,
    customer_profile_id: '1510556325',
    customer_payment_profile_id: '1513119778',
    payment_method: 'Credit Card',
    bank_account: null,
    credit_card: { card_number: 'XXXX8888', expiration_date: 'XXXX', card_type: 'Visa' },
    bill_to: {
      first_name: 'daher',
      last_name: 'Meow',
      company: 'Swag Up Demo Account',
      address1: '1919 se 10 th ave',
      address2: '6108',
      city: 'fort lauderdale',
      state: 'FL',
      zip_code: '33316',
      country: 'US',
      phone_number: ''
    },
    payment_profile_name: 'hola'
  },
  {
    default: false,
    customer_profile_id: '1510556325',
    customer_payment_profile_id: '1513119739',
    payment_method: 'ACH',
    bank_account: {
      name_on_account: 'daher Meow',
      account_type: 'checking',
      account_number: 'XXXX1533',
      routing_number: 'XXXX0021'
    },
    credit_card: null,
    bill_to: {
      first_name: 'daher',
      last_name: 'Meow',
      company: 'Swag Up Demo Account',
      address1: '1919 se 10 th ave',
      address2: '6108',
      city: 'fort lauderdale',
      state: 'FL',
      zip_code: '33316',
      country: 'US',
      phone_number: ''
    },
    payment_profile_name: ''
  },
  {
    default: true,
    customer_profile_id: '1510556325',
    customer_payment_profile_id: '1513119728',
    payment_method: 'Credit Card',
    bank_account: null,
    credit_card: { card_number: 'XXXX0015', expiration_date: 'XXXX', card_type: 'MasterCard' },
    bill_to: {
      first_name: 'daher',
      last_name: 'Meow',
      company: 'Swag Up Demo Account',
      address1: '1919 se 10 th ave',
      address2: '6108',
      city: 'fort lauderdale',
      state: 'FL',
      zip_code: '33316',
      country: 'US',
      phone_number: ''
    },
    payment_profile_name: ''
  },
  {
    default: false,
    customer_profile_id: '1510556325',
    customer_payment_profile_id: '1513119716',
    payment_method: 'Credit Card',
    bank_account: null,
    credit_card: { card_number: 'XXXX0012', expiration_date: 'XXXX', card_type: 'Discover' },
    bill_to: {
      first_name: 'daher',
      last_name: 'Meow',
      company: 'Swag Up Demo Account',
      address1: '1919 se 10 th ave',
      address2: '6108',
      city: 'fort lauderdale',
      state: 'FL',
      zip_code: '33316',
      country: 'US',
      phone_number: ''
    },
    payment_profile_name: ''
  },
  {
    default: false,
    customer_profile_id: '1510556325',
    customer_payment_profile_id: '1513049770',
    payment_method: 'Credit Card',
    bank_account: null,
    credit_card: { card_number: 'XXXX0015', expiration_date: 'XXXX', card_type: 'MasterCard' },
    bill_to: {
      first_name: 'Someone',
      last_name: 'Like Me',
      company: 'Swag Up Demo Account',
      address1: '45 Wyllys Ave',
      address2: 'WesBox 91923',
      city: 'Middletown',
      state: 'CT',
      zip_code: '06459',
      country: 'US',
      phone_number: ''
    },
    payment_profile_name: 'my master'
  },
  {
    default: false,
    customer_profile_id: '1510556325',
    customer_payment_profile_id: '1513049700',
    payment_method: 'Credit Card',
    bank_account: null,
    credit_card: { card_number: 'XXXX1111', expiration_date: 'XXXX', card_type: 'Visa' },
    bill_to: {
      first_name: 'Arturo',
      last_name: 'Vidal',
      company: 'Swag Up Demo Account',
      address1: '2607 New York Ave',
      address2: null,
      city: 'Union City',
      state: 'NJ',
      zip_code: '07087',
      country: 'US',
      phone_number: ''
    },
    payment_profile_name: loremIpsum
  }
];
