import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { moneyStr } from '../../../helpers/utils';
import styles from './styles/ShipmentSection';

const useStyles = makeStyles(styles);

const ShipmentSection = ({
  title,
  deliveryMethod,
  shippingNotes,
  shippingDate,
  employeesQty,
  totalPrice,
  itemsQty
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="center" style={{ marginBottom: 24 }}>
        <p className={classes.cardSectionTitle}>{title}</p>
        {deliveryMethod && <p className={classes.deliveryMethod}>{deliveryMethod} Delivery</p>}
      </Grid>
      {shippingNotes !== undefined && (
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <p className={classes.commonSubTitle}>Shipping Notes</p>
          <p className={classes.shippingNotes}>{shippingNotes}</p>
        </Grid>
      )}
      <Grid container justifyContent="space-between">
        {shippingDate !== undefined && (
          <Grid item>
            <p className={classes.commonSubTitle}>Shipping Date</p>
            <p className={classes.shippingValues}>{shippingDate}</p>
          </Grid>
        )}
        <Grid item>
          <p className={classes.commonSubTitle}>Total recipients</p>
          <p className={classes.shippingValues}>{employeesQty}</p>
        </Grid>
        {itemsQty && (
          <Grid item>
            <p className={classes.commonSubTitle}>Total items</p>
            <p className={classes.shippingValues}>{itemsQty}</p>
          </Grid>
        )}
        <Grid item style={{ textAlign: 'right' }}>
          <p className={classes.commonSubTitle}>Amount</p>
          <p className={classes.shippingValues}>{moneyStr(totalPrice)}</p>
        </Grid>
      </Grid>
    </>
  );
};

export default ShipmentSection;
