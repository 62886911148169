import React from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
  IconButton
} from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';
import { Button } from '@swagup-com/components';
import { imageSrcSet, joinFields } from '../../../../helpers/utils';

const styles = theme => ({
  iconClose: {
    color: '#434c5f',
    cursor: 'pointer',
    padding: 4
  },
  paper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 646,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 8,
    margin: 0,
    outline: 'none',
    borderRadius: 10
  },
  titleContainer: {
    paddingTop: 40,
    textAlign: 'center'
  },
  actionsContainer: {
    paddingTop: 36,
    paddingBottom: 16
  },
  title: {
    maxWidth: 510,
    lineHeight: '36px',
    fontFamily: 'Gilroy-Medium'
  },
  subtitle: {
    maxWidth: 439,
    paddingTop: 16
  },
  removeButton: {
    height: 56,
    maxWidth: 198,
    paddingLeft: 10,
    paddingRight: 10,
    '&:focus': { boxShadow: '0 0 0 4px rgba(244, 67, 54, 0.5)' }
  },
  cancelButton: {
    height: 56,
    width: 198,
    backgroundColor: 'transparent',
    marginTop: 12,
    '&:hover': { backgroundColor: 'rgb(0, 0, 0, 0.05)' },
    '&:focus': { boxShadow: '0 0 0 4px rgba(141, 146, 153, 0.5)' }
  },
  cancelButtonText: {
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    lineHeight: '12px',
    color: '#8d9299'
  }
});

const getModalSubtitle = selectedEmployees =>
  joinFields(
    [
      selectedEmployees.some(e => e.has_orders && !e.has_pending_orders) &&
        'Personal data will be redacted in the associated shipments.',
      selectedEmployees.some(e => e.has_pending_orders) &&
        `Some contacts can't be removed, they have pending shipments.`,
      "This action can't be undone."
    ],
    ' '
  );

const useStyles = makeStyles(styles);

const RemoveContactsModal = ({ contacts, isOpen, onDelete, onClose }) => {
  const classes = useStyles();
  if (!isOpen) return null;

  const message =
    contacts.length === 1
      ? ` ${joinFields([contacts[0].first_name, contacts[0].last_name], ' ')}`
      : ' the selected contacts';

  return (
    <Dialog classes={{ paper: classes.paper }} open={isOpen} onClose={onClose}>
      <DialogTitle aria-labelledby="remove-contacts-dialog">
        <Grid container justifyContent="flex-end">
          <IconButton aria-label="close" className={classes.iconClose} onClick={onClose}>
            <IconClose />
          </IconButton>
        </Grid>
        <Grid container justifyContent="center">
          <img
            src="/images/public/delete-employee.png"
            srcSet={imageSrcSet('/images/public/delete-employee.png')}
            className={classes.image}
            alt="Delete Employee"
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" className={classes.titleContainer}>
          <Typography variant="h5" className={classes.title}>
            Are you sure you want to remove
            <strong>{message}</strong> from your directory?
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {getModalSubtitle(contacts)}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container className={classes.actionsContainer}>
          <Grid container justifyContent="center" item xs={12}>
            <Button fullWidth variant="alert" type="submit" onClick={onDelete} className={classes.removeButton}>
              <Typography variant="button">Remove Contact{contacts.length === 1 ? '' : 's'}</Typography>
            </Button>
          </Grid>
          <Grid container justifyContent="center" item xs={12}>
            <Button variant="primary" onClick={onClose} className={classes.cancelButton}>
              <span className={classes.cancelButtonText}>Cancel</span>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveContactsModal;
