import React from 'react';
import { Grid, withStyles, LinearProgress, Hidden, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { dayjs } from '../../helpers/dayjs';
import {
  moneyStr,
  sumByQuantity,
  formatDate,
  addBusinessDaysToDate,
  maxTurnaround,
  getStatusDescription
} from '../../helpers/utils';
import { ProductImage60x60 } from './SharedComponents';
import { invoiceStatus } from '../../apis/constants';
import styles from './styles/DisplayTool';
import { estimatedShippingDate } from '../pages/orders/requested/details/orderReview/common';

const getOrderProgress = (order, isEnterpriseFlowActive, completed) => {
  if (order.status === invoiceStatus.canceled) return { percent: 0, message: invoiceStatus.canceled };

  const estimatedCompletedDate = estimatedShippingDate(order, isEnterpriseFlowActive, completed);

  const created = dayjs(order.date);
  const complete = dayjs(estimatedCompletedDate);

  const daysFromToday = complete.diff(dayjs(), 'days');
  const daysFromCreated = complete.diff(created, 'days');

  const percent = Math.min(Math.round(100 - (daysFromToday * 100) / daysFromCreated), 100);

  if (percent === 100) return { percent, message: 'Order completed', completeWeek: complete.format('MM/DD/YYYY') };

  return {
    percent,
    message: 'Est. completion date: ',
    completeWeek: complete.format('MM/DD/YYYY')
  };
};

const OrderItem = ({ classes, order, product, total, sizes, showIcon, isEnterpriseFlowActive }) => {
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const statusDescription = getStatusDescription(order);
  const completed = !statusDescription.canceled && statusDescription.completed;

  const orderProgress = getOrderProgress(order, isEnterpriseFlowActive, completed);

  return (
    <Grid item container sm={12}>
      <Grid item sm={1} xs={2}>
        {isDownSm ? (
          <Link to={{ pathname: `/order-completed-details/${order.invoice}`, state: { order } }}>
            <ProductImage60x60 product={product} classes={classes} />
          </Link>
        ) : (
          <ProductImage60x60 product={product} classes={classes} />
        )}
      </Grid>
      <Grid item sm={5} xs={10}>
        <Grid item container sm={12} xs={12} className={classes.separateOrderItemContainer}>
          <Grid item sm={11} xs={11}>
            <p className={classes.packTitle}>{product.name}</p>
          </Grid>
          <Grid item sm={6} xs={6} className={classes.separateOrderItemInfo}>
            <p className={classes.infoOrderText}>{moneyStr(total)}</p>
          </Grid>
          <Grid item sm={6} xs={6} className={classes.separateOrderItemInfo}>
            <p className={classes.infoOrderText}>{formatDate(order.created_at, 'YYYY-MM-DD')}</p>
          </Grid>
        </Grid>
      </Grid>
      <Hidden only="xs">
        <Grid item sm={1} align="center">
          <p className={classes.qtyOrderText}>
            <span className={classes.qtyX}>x</span>
            <span> {` ${sumByQuantity(sizes)}`}</span>
          </p>
        </Grid>
        <Grid item sm={4} align="center" className={classes.separateOrderItemStatus}>
          <div className={classes.statusText} align="left">
            <Grid container>
              <Grid item xs>
                {statusDescription.currently.title}
              </Grid>
              <Grid item>
                {orderProgress.completeWeek && (
                  <strong style={{ textAlign: 'right' }}>{orderProgress.completeWeek}</strong>
                )}
              </Grid>
            </Grid>
            <LinearProgress
              classes={{ root: classes.progressBarRoot, bar: classes.progressBar }}
              variant="determinate"
              value={completed ? 100 : orderProgress.percent}
            />
          </div>
        </Grid>
        <Grid item sm={1}>
          {showIcon && (
            <Link to={{ pathname: `/order-completed-details/${order.invoice}`, state: { order } }}>
              <img src="/images/dashboard/order.svg" alt="Order Img" className={classes.separateOrderItemIcon} />
            </Link>
          )}
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default withStyles(styles)(OrderItem);
