import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Box, Grid, makeStyles } from '@material-ui/core';
import SendSwagConfigurationSize from './SendSwagConfigurationSize';
import SendSwagConfigurationProduct from './SendSwagConfigurationProduct';
import styles from './styles/automationActionSelectionModal';
import RedeemLinkConfigurationFinish from './RedeemLinkConfigurationFinish';
import StoreConfigurationCredits from './StoreConfigurationCredits';
import RedeemConfigurationMessage from './RedeemConfigurationMessage';

const useStyles = makeStyles(styles);

const RedeemLikConfiguration = ({ action, onActionUpdate }) => {
  return (
    <Box>
      <Typography variant="body3SemiBoldInter">Select the page you want to send a redeem link for</Typography>
    </Box>
  );
};

const ShopCreditsConfiguration = ({ action, onActionUpdate }) => {
  return (
    <Box>
      <Typography variant="body3SemiBoldInter">Select the shop you want to send credit for</Typography>
    </Box>
  );
};

const AutomationConfigurationSecond = ({ action, onActionUpdate }) => {
  //   const [selectedAction, setSelectedAction] = useState();
  //   const [currentStep, setCurrentStep] = useState(1);

  //   useEffect(() => {
  //     setSelectedAction(automation?.action);
  //   }, [automation]);

  //   const onAutomationSelected = a => {
  //     setSelectedAction(a);
  //     setCurrentStep(prev => prev + 1);
  //   };

  const secondActionResolver = actionType => {
    switch (true) {
      case actionType === 'send_swag':
        return <SendSwagConfigurationSize action={action} onActionUpdate={onActionUpdate} />;
      case actionType === 'redeem_link':
        return <RedeemConfigurationMessage action={action} onActionUpdate={onActionUpdate} />;
      case actionType === 'shop_credits':
        return <StoreConfigurationCredits action={action} onActionUpdate={onActionUpdate} />;
      default:
        return <ShopCreditsConfiguration action={action} onActionUpdate={onActionUpdate} />;
    }
  };

  const classes = useStyles();

  return (
    <Box>
      <Box>{secondActionResolver(action?.type)}</Box>
    </Box>
  );
};

export default AutomationConfigurationSecond;
