const styles = theme => ({
  center: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      paddingLeft: '0 !important',
      minWidth: '1280px'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1120px',
      paddingLeft: '0 !important',
      minWidth: '1120px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingLeft: '0 !important',
      minWidth: '100%'
    }
  },
  subcribeContent: {
    paddingTop: 164,
    paddingBottom: 56,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 56,
      paddingBottom: 102
    }
  },
  subscribeTitle: {
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    lineHeight: 1.1,
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      textAlign: 'left',
      marginBottom: 8
    }
  },
  subscribeContent: {
    paddingTop: 125,
    paddingBottom: 72,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 32,
      paddingBottom: 0
    }
  },
  regularText: {
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    textAlign: 'left',
    fontWeight: 'bold',
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  subscribeInnerContent: {
    [theme.breakpoints.down('sm')]: {
      padding: '72px 56px',
      paddingBottom: 102
    }
  },
  againButton: {
    fontSize: 20,
    paddingLeft: 2,
    color: '#3577d4',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

export default styles;
