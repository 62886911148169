const styles = theme => ({
  container: {
    marginTop: 0,
    paddingTop: 40,
    paddingBottom: 42,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  marginES: {
    '& >div:first-child': {
      marginBottom: 36
    },
    [theme.breakpoints.down('sm')]: {
      '& >div:first-child': {
        marginBottom: 0
      }
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '20px',
    width: '100%',
    [theme.breakpoints.only('xl')]: {
      columnGap: '52px'
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  },
  list: {
    display: 'grid',
    gridTemplateRows: '1fr',
    paddingTop: 26,
    width: '100%'
  }
});

export default styles;
