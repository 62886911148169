import * as React from 'react';
import { ButtonBase, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { SearchIcon } from '../../../../icons';

const useSearchStyles = makeStyles({
  container: {
    position: 'relative',
    width: 74,
    transition: 'width 500ms ease',
    '&.open': {
      width: '100%',
      transition: 'width 500ms ease'
    }
  },
  startIconContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    zIndex: 10,
    transition: 'all 500ms ease',
    '.open &': {
      left: 15,
      transition: 'all 500ms ease'
    }
  },
  searchIcon: { color: '#787b80' },
  endTextContainer: {
    display: 'none',
    fontFamily: 'Gilroy-Medium',
    color: '#787b80',
    position: 'absolute',
    right: 20,
    top: 0,
    bottom: 0,
    placeItems: 'center',
    zIndex: 10,
    '.open &': { display: 'grid' }
  },
  input: {
    width: '100%',
    fontFamily: 'Gilroy-Medium',
    lineHeight: '18px',
    color: '#787b80',
    fontSize: 12,
    border: 'none',
    padding: '0 0 0 23px',
    cursor: 'pointer',
    transition: 'all 500ms ease',
    '.open &': {
      fontFamily: 'Gilroy-Regular',
      padding: '15px 40px 15px 38px',
      borderRadius: 28,
      border: 'solid 1px #f0f2f5',
      cursor: 'text',
      transition: 'all 500ms ease',
      '&:hover': { outline: 'none' }
    },
    '&:focus': { outline: 'none' },
    ':not(.open) > &:focus, :not(.open) > &:hover': {
      '&::placeholder, &+div > svg': {
        color: '#0b1829'
      }
    }
  }
});

const SearchBar = ({ open, toggleOpen, onChange }) => {
  const classes = useSearchStyles();
  const [search, setSearch] = React.useState('');

  const handleChange = e => {
    const { value } = e.target;
    setSearch(value);
    onChange(value);
  };

  const handleEscapeKeyDown = e => {
    if (e.key === 'Escape') {
      if (open) e.stopPropagation();
      toggleOpen();
    }
  };

  return (
    <Grid
      container
      className={clsx(classes.container, { open })}
      onKeyDown={handleEscapeKeyDown}
      onClick={!open ? toggleOpen : undefined}
    >
      <input className={classes.input} placeholder="Search" value={open ? search : ''} onChange={handleChange} />

      <div className={classes.startIconContainer}>
        <SearchIcon className={classes.searchIcon} />
      </div>
      {open && (
        <ButtonBase disableRipple disableTouchRipple className={classes.endTextContainer} onClick={toggleOpen}>
          Collapse
        </ButtonBase>
      )}
    </Grid>
  );
};

export default SearchBar;
