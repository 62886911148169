import { scrollBar } from '../../shared/styles/commonStyles';
import { changeColorLightness, cssHexToRgb, isHexColor } from '../../shared/styles/utils';

const getRGBValues = hex => {
  const customHex = hex.replace('#', '');
  const hexValues = customHex.match(/.{1,2}/g);

  return [parseInt(hexValues[0], 16), parseInt(hexValues[1], 16), parseInt(hexValues[2], 16)];
};

const fontColorContrast = (hex, threshold = 0.5) => {
  if (!isHexColor(hex)) return 'rgba(0,0,0, 1)';

  const hexValues = getRGBValues(hex);

  const red = hexValues[0];
  const green = hexValues[1];
  const blue = hexValues[2];

  const pRed = 0.299;
  const pGreen = 0.587;
  const pBlue = 0.114;

  const contrast = Math.sqrt(pRed * (red / 255) ** 2 + pGreen * (green / 255) ** 2 + pBlue * (blue / 255) ** 2);

  return contrast > threshold ? '#0b1829' : '#ffffff';
};

const defaultText = ({ fontColor, fontFamily }) => ({
  fontFamily: fontFamily || 'Gilroy-SemiBold',
  color: fontColor || '#0b1829'
});

const templateStyles = () => ({
  root: {
    height: 380,
    position: 'relative',
    background: ({ backgroundColor }) => backgroundColor || 'transparent',
    padding: '0px 0px',
    boxShadow: '0 24px 56px 0 rgba(27, 28, 31, 0.2)',
    borderRadius: 2,
    color: ({ fontColor }) => fontColor || 'inherit',
    fontFamily: ({ fontFamily }) => fontFamily || 'inherit'
  },
  section: {
    padding: '0px 32px'
  },
  logoContainer: {
    height: 32,
    width: 72,
    position: 'relative'
  },
  logo: {
    height: '100%',
    maxWidth: '100%',
    objectFit: 'scale-down'
  },
  productSelectionArea: {
    height: 196,
    '& .product-list': {
      ...scrollBar,
      maxHeight: 200,
      overflowY: 'auto !important',
      overflowX: 'hidden !important',
      padding: '0px 0px'
    }
  },
  headerText: props => ({ ...defaultText(props), fontSize: 24, marginTop: 0, lineHeight: '24px' }),
  subtitle: props => ({ ...defaultText(props), fontSize: 8, marginTop: 6, marginBottom: 12, paddingRight: 14 }),
  advisory: props => ({ ...defaultText(props), fontSize: 6, marginTop: 10, marginBottom: 0 }),
  productContainer: {
    width: '100%',
    height: 200
  },
  product: {
    height: '100%',
    width: '100%',
    objectFit: 'scale-down'
  },
  button: ({ accentColor }) => ({
    padding: '5px 12px',
    fontSize: 6,
    cursor: 'pointer',
    width: 'fit-content',
    minWidth: 54,
    maxWidth: 80,
    textAlign: 'center',
    background: accentColor || '#3577d4',
    color: fontColorContrast(accentColor || '#3577d4'),
    borderRadius: 16
  }),
  done: props => ({
    ...defaultText(props),
    fontSize: 6,
    color: `${props.accentColor || '#0B1829'} !important`,
    marginTop: -56
  }),
  congratulation: props => ({
    ...defaultText(props),
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 6
  }),
  doneNote: props => ({
    ...defaultText(props),
    fontSize: 8,
    marginBottom: 12,
    opacity: 0.8,
    textAlign: 'center'
  }),
  orderNote: props => ({
    ...defaultText(props),
    fontSize: 8,
    opacity: 0.85,
    lineHeight: '12px'
  }),
  orderInfo: props => ({
    ...defaultText(props),
    fontSize: 8,
    lineHeight: '12px',
    marginBottom: 2,
    opacity: 0.7
  }),
  orderInfoContainer: {
    padding: '6px 8px',
    borderRadius: 4
  },
  swipeableViews: {
    height: '100%',
    overflowX: 'visible !important',
    '& div': {
      overflowX: 'visible !important',
      overflow: 'visible !important'
    }
  },
  productCheckbox: {
    backgroundColor: 'red'
  },
  productImageContainer: {
    width: 84,
    height: 84,
    padding: 4,
    borderRadius: 4,
    border: ({ accentColor }) => `1px solid ${accentColor || '#E5E7E8'}`,
    background: '#ffffff'
  },
  productImageContainerLarge: {
    width: 128,
    height: 128,
    padding: 4,
    borderRadius: 4,
    border: ({ accentColor }) => `1px solid ${accentColor || '#E5E7E8'}`,
    background: '#ffffff'
  },
  productImage: {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  },
  productQuestion: props => ({
    ...defaultText(props),
    marginBottom: 4,
    textAlign: 'center'
  }),
  productViewDetail: props => ({
    ...defaultText(props),
    fontSize: 6,
    marginTop: 2,
    opacity: 0.7,
    cursor: 'pointer',
    textDecoration: 'underline',
    lineHeight: '6px',
    textAlign: 'center'
  }),
  productQuestionCTAText: props => ({
    ...defaultText(props),
    fontSize: 8,
    marginBottom: 10,
    lineHeight: '8px',
    opacity: 0.8,
    textAlign: 'center'
  }),
  wizardButtonSection: {
    paddingTop: 24
  },
  previousIcon: {
    marginRight: 6,
    height: 10,
    width: 10
  },
  previous: props => ({
    ...defaultText(props),
    fontSize: 8,
    cursor: 'pointer'
  }),
  formLabel: props => ({
    ...defaultText(props),
    fontSize: 6,
    fontWeight: 400,
    lineHeight: '6px',
    marginBottom: 2
  }),
  formInput: ({ backgroundColor, accentColor }) => ({
    height: 16,
    background: `${changeColorLightness(backgroundColor, 12)}`,
    border: `0.5px solid ${cssHexToRgb(accentColor, 0.8)}`,
    borderRadius: 2,
    cursor: 'text',
    position: 'relative',
    '& svg': {
      fill: cssHexToRgb(accentColor, 0.8)
    }
  }),
  formInputIcon: {
    position: 'absolute',
    height: 8,
    width: 8,
    top: 4,
    right: 4
  },
  formInfoContent: {
    height: 184
  }
});

export default templateStyles;
