import { LOAD_COMPANY, SAVE_COMPANY } from './types';
import DashBoard from '../apis/DashBoard';
import { okAndLog, errorAndLog } from '../helpers/utils';
import apiPaths from '../helpers/apiPaths';
import { status200 } from '../apis/swagup/status.utils';

export const loadCompany = () => dispatch =>
  DashBoard.get(apiPaths.accounts, status200)
    .then(({ status, data }) => {
      dispatch({
        type: LOAD_COMPANY,
        payload: data.results[0]
      });
      return okAndLog('loadCompany', status, data);
    })
    .catch(({ response }) => errorAndLog('loadCompany', response?.status, response?.data));

export const saveCompany = company => dispatch =>
  DashBoard.patch(apiPaths.account(company.id), company, status200)
    .then(({ status, data }) => {
      dispatch({
        type: SAVE_COMPANY,
        payload: data
      });
      return okAndLog('saveCompany', status, data);
    })
    .catch(e => {
      const { status, data } = e.response || { status: e.status, data: e.data || e.request };

      return errorAndLog('saveCompany ', status, data);
    });

export const allowAutomaticCharges = (accountId, allowed) => dispatch =>
  DashBoard.patch(apiPaths.account(accountId), { allow_automatic_charges: allowed }, status200)
    .then(({ status, data }) => {
      dispatch({
        type: LOAD_COMPANY,
        payload: data
      });
      return okAndLog('allowAutomaticCharges', status, data);
    })
    .catch(({ response }) => errorAndLog('allowAutomaticCharges', response?.status, response?.data));
