import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import CardContainer from './CardContainer';
import ImageWithDescription from './ImageWithDescription';
import ShipmentStatus from '../../../global/ShipmentStatus';
import styles from './styles/ShippingHistoryItem';

const ShippingHistoryItem = props => {
  const { classes, status, date, orderNumber, img, trackingUrl, color, quantity, extraValue, name, tracking } = props;
  return (
    <CardContainer>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <ImageWithDescription
            width={180}
            height={180}
            classes={classes}
            name={name}
            tracking={tracking}
            trackingUrl={trackingUrl}
            color={color}
            quantity={quantity}
            extraValue={extraValue}
            img={img}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <ShipmentStatus status={status} />
            </Grid>
            <Grid item>
              <p className={classes.normalText}>{date}</p>
            </Grid>
            <Grid item>
              <p className={classes.normalText}>{`Order #: ${orderNumber || '_'}`}</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContainer>
  );
};

export default withStyles(styles)(ShippingHistoryItem);
