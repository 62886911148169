import redeemServicesPaths from '../../helpers/redeemServicesPaths';
import axios from '../RedeemServiceAPI';
import { status200or201 } from '../swagup/status.utils';

export default {
  names: param => axios.post(redeemServicesPaths.verifyName, param, status200or201).then(rslt => rslt.data),

  email: param =>
    axios
      .post(redeemServicesPaths.verifyEmail, param, status200or201)
      .then(rslt => rslt.data)
      .catch(err => err.response?.data || err.message)
};
