const styles = theme => ({
  root: {
    maxWidth: 1048,
    display: 'flex',
    gap: 24,
    marginBottom: theme.spacing(12)
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 30,
    color: '#000000',
    lineHeight: 1
  },
  color: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 4,
    color: '#787B80'
  },
  label: {
    fontWeight: 500,
    color: '#131415'
  },
  labelMax: {
    fontWeight: 400,
    fontSize: 12,
    color: '#787B80'
  },
  quantityTitle: {
    display: 'flex',
    gap: '6px'
  },
  quantitySelect: {
    width: 300,
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
    overflowY: 'visible'
  },
  mainImage: {
    width: '100%',
    height: 400,
    objectFit: 'contain',
    marginBottom: theme.spacing(6)
  },
  mainImageLifestyle: {
    width: '100%',
    minWidth: 400,
    height: 400,
    objectFit: 'contain',
    borderRadius: 8,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      minWidth: 0
    }
  },
  thumbnailImage: {
    width: 72,
    height: 72,
    objectFit: 'contain',
    cursor: 'pointer',
    padding: theme.spacing(1.5),
    margin: theme.spacing(1.5),
    border: `1px solid ${theme.palette.neutral.grey90}`,
    borderRadius: 10,
    '.active &': {
      borderColor: '#3577D4'
    }
  },
  lastThumbnailImage: {
    width: 72,
    height: 72,
    objectFit: 'contain',
    cursor: 'pointer',
    padding: theme.spacing(1.5),
    margin: theme.spacing(1.5),
    border: `1px solid ${theme.palette.neutral.grey90}`,
    borderRadius: 10,
    '.active &': {
      borderColor: '#3577D4'
    },
    marginRight: 32
  },
  thumbnailImageLifeStyle: {
    width: 72,
    height: 72,
    objectFit: 'cover',
    cursor: 'pointer',
    padding: theme.spacing(1.5),
    margin: theme.spacing(1.5),
    border: `1px solid ${theme.palette.neutral.grey90}`,
    borderRadius: 10,
    '.active &': {
      borderColor: '#3577D4'
    }
  },
  lastThumbnailImageLifeStyle: {
    width: 72,
    height: 72,
    objectFit: 'cover',
    cursor: 'pointer',
    padding: theme.spacing(1.5),
    margin: theme.spacing(1.5),
    border: `1px solid ${theme.palette.neutral.grey90}`,
    borderRadius: 10,
    '.active &': {
      borderColor: '#3577D4'
    },
    marginRight: 32
  },
  thumbnailImageWrapper: {
    outline: 'none'
  },
  seeItInRealLife: {
    color: '#131415',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    margin: '12px 0px 6px 6px'
  },
  hMenu: {
    '& .menu-wrapper': {
      width: 'calc(min-content + 12px)'
    }
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 28
  },
  quantities: {
    display: 'flex',
    gap: '60px'
  },
  prices: {
    display: 'flex',
    gap: '24px'
  },
  totalPrice: {
    fontSize: 28,
    fontWeight: 500,
    color: '#0B1829'
  },
  button: {
    width: 210,
    flex: '0 0 auto'
  },
  description: {
    whiteSpace: 'break-spaces',
    color: '#787B80'
  },
  divider: {
    backgroundColor: '#EBEDF0',
    margin: '15px 40px 0px 0px'
  }
});

export const availableColorsStyles = {
  title: {
    fontWeight: 500,
    color: '#0B1829'
  },
  colors: {
    flexDirection: 'row',
    marginLeft: -3,
    gap: '8px'
  },
  colorButton: {
    padding: 0
  },
  expandButton: {
    height: 24,
    minWidth: 24,
    padding: 0,
    marginLeft: 3
  }
};

export default styles;
