const styles = theme => ({
  main: {
    flex: 1,
    width: '100%',
    padding: '32px 0 176px 0',
    '& h1, & h2, & h5, & h6': {
      letterSpacing: 'normal'
    }
  },
  container: {
    margin: '0 auto'
  },
  h1: {
    color: '#0b1829',
    fontSize: 48,
    lineHeight: 1.17
  },
  h2: {
    color: '#0b1829',
    fontSize: 36,
    lineHeight: 1.33
  },
  subtitle: {
    color: '#787b80',
    fontSize: 14,
    lineHeight: 1.57,
    marginTop: 8
  },
  headerContainer: {
    paddingBottom: 32,
    borderBottom: '1px solid #ebedf0'
  },
  headerTextContainer: {
    marginLeft: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      marginLeft: 0
    }
  },
  servicesContainer: {
    marginTop: 176,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  serviceCardsContainer: {
    margin: '72px 0'
  },
  servicesBtn: {
    height: 56
  },
  slideshowSection: {
    marginTop: 72,
    '& > h1': {
      marginBottom: 64,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    }
  }
});

export default styles;
