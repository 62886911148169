import redeemServicesPaths from '../../helpers/redeemServicesPaths';
import { paginationResponseConverter } from '../../helpers/utils';
import axios from '../RedeemServiceAPI';
import { status200, status200or201 } from '../swagup/status.utils';

export default {
  list: params => axios.get(redeemServicesPaths.redeemPages, { status200, params }).then(rslt => rslt.data),

  listByAccount: (account, params) =>
    axios
      .get(redeemServicesPaths.redeemPagesByAccount(account), { status200, params })
      .then(rslt => paginationResponseConverter(rslt.data)),

  get: slug => axios.get(redeemServicesPaths.redeemPage(slug), { status200 }).then(rslt => rslt.data),

  create: params => axios.post(redeemServicesPaths.redeemPages, params, status200or201),

  update: (id, params) => axios.patch(redeemServicesPaths.redeemPage(id), params),

  export: (id, params) => axios.post(redeemServicesPaths.export(id), params, status200or201),

  summary: id => axios.get(redeemServicesPaths.summary(id)),

  createUniqueUrlBulk: (id, params) => axios.post(redeemServicesPaths.createUniqueUrlBulk(id), params, status200or201),

  sendEmailBulk: (id, params) => axios.post(redeemServicesPaths.sendEmailBulk(id), params, status200or201)
};
