import React from 'react';
import { useQuery } from 'react-query';
import DetailsModal from '../directory/staffOrders/DetailsModal';
import shipmentsApi from '../../apis/swagup/shipments';
import apiPaths from '../../helpers/apiPaths';
import Loader from '../global/Loader';

const ShipmetDetailsModal = ({ shipmentId, onClose }) => {
  const { data: shipment, isLoading } = useQuery(
    apiPaths.employeeOrder(shipmentId),
    () => shipmentsApi.get(shipmentId),
    {
      enabled: Boolean(shipmentId)
    }
  );

  return <>{isLoading ? <Loader /> : <DetailsModal order={shipment} onClose={onClose} />}</>;
};

export default ShipmetDetailsModal;
