import sumBy from 'lodash/sumBy';
import pick from 'lodash/pick';
import shippingPricesApi from '../apis/swagup/shippingPrices';
import {
  addressFields,
  getQuantitiesBreakdown,
  isSameAddress,
  substractQuantitiesBreakdowns
} from '../components/pages/orders/requested/common/utilsOrder';
import { minimumShippingDate } from '../helpers/commonDateFunctions';
import { getUsedQuantitiesBySize, isOneSize, isProductAvailable } from '../helpers/utils';
import {
  ADD_SHIPMENT_GROUP_DIRECTORY_ORDERS,
  ADD_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT,
  CLEAN_SHIPMENT_GROUP,
  DELETE_SHIPMENT_GROUP_DIRECTORY_ORDER,
  DELETE_SHIPMENT_GROUP_RECIPIENTS,
  DELETE_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT,
  CREATED_SHIPMENT_GROUP_DIRECTORY_ORDERS,
  SELECT_SHIPMENT_GROUP_PRODUCTS,
  SELECT_SHIPMENT_GROUP_RECIPIENTS,
  SET_SHIPMENT_GROUP_ID,
  ADD_SHIPMENT_GROUP_RECIPIENTS_START,
  ADD_SHIPMENT_GROUP_RECIPIENTS_SUCCESS,
  UPDATE_SHIPMENT_GROUP_LOADING_STATUS,
  TOGGLE_SELECT_FROM_EXISTING,
  UPDATE_SELECTED_RECIPIENTS_SHIPPING_METHOD,
  UPDATE_SHIPMENT_GROUP_RECIPIENT,
  UPDATE_SHIPMENT_GROUP_DIRECTORY_ORDER,
  VALIDATE_SHIPMENT_GROUP_DIRECTORY_ORDERS,
  SET_CONTACT_FETCH_QUERY,
  UPDATE_SELECTED_RECIPIENTS_SHIPPING_DATE,
  TOOGLE_ALL_SHIPMENT_GROUP_RECIPIENTS_COLLAPSED,
  UPDATE_SHIPMENT_GROUP_RECIPIENT_COLLAPSED
} from './types';

const buildPayloadAndNewProofs = (products, directoryOrder, recipient, sizeUnchanged, errors) => {
  const newProofs = products.filter(isProductAvailable(recipient)).map(product => {
    const size = isOneSize(product.sizes) ? product.sizes[0].size.id : recipient.size.id;
    return { product, sizes: [{ size, quantity: 1 }] };
  });
  const keepCurrentQuantities = directoryOrder && (sizeUnchanged || errors?.[recipient.id]?.type === 'warning');

  return {
    payload: {
      quantities: keepCurrentQuantities
        ? directoryOrder.proofs.map(p => ({
            account_product: products.find(product => product.id === p.proof).product.id,
            quantity: sumBy(p.sizes, 'quantity')
          }))
        : newProofs.map(p => ({
            account_product: p.product.product.id,
            quantity: sumBy(p.sizes, 'quantity')
          })),
      ...pick(recipient, addressFields)
    },
    proofs: keepCurrentQuantities
      ? directoryOrder.proofs
      : newProofs?.map(p => ({ proof: p.product.id, sizes: p.sizes }))
  };
};

const buildRemainingStock = (products, currentOrders) => {
  const remainingStock = {};
  products.forEach(p => {
    const usedQuantities = getUsedQuantitiesBySize(p.id, [{ directoryOrders: currentOrders }]);
    remainingStock[p.id] = substractQuantitiesBreakdowns(getQuantitiesBreakdown(p.sizes), usedQuantities);
  });
  return remainingStock;
};

export const addShipmentGroupDirectoryOrders = directoryOrders => dispatch =>
  dispatch({
    type: ADD_SHIPMENT_GROUP_DIRECTORY_ORDERS,
    payload: directoryOrders
  });

export const validateDirectoryOrders = (recipientsMissingStock, isStockLimited, hideError = false) => dispatch =>
  dispatch({
    type: VALIDATE_SHIPMENT_GROUP_DIRECTORY_ORDERS,
    payload: { recipientsMissingStock, isStockLimited, hideError }
  });

export const addRecipients = (recipients, isStockLimited, shippingCutoffHour) => async (dispatch, getState) => {
  const { products, directoryOrders: currentOrders } = getState().shipmentGroup;

  dispatch({ type: ADD_SHIPMENT_GROUP_RECIPIENTS_START });

  const remainingStock = isStockLimited ? buildRemainingStock(products, currentOrders) : undefined;
  const recipientsMissingStock = {};
  const recipientsWithProducts = [];
  recipients.forEach(recipient => {
    const proofs = [];
    products.forEach(p => {
      if (!isProductAvailable(recipient)(p)) return;

      const size = isOneSize(p.sizes) ? p.sizes[0].size.id : recipient.size?.id;
      if (remainingStock && [0, undefined].includes(remainingStock[p.id][size])) {
        recipientsMissingStock[recipient.id] = true;
        return;
      }

      if (remainingStock) {
        remainingStock[p.id][size] -= 1;
      }

      proofs.push({
        product: p,
        sizes: [{ size, quantity: 1 }],
        quantity: 1
      });
    });

    if (proofs.length > 0) {
      recipientsWithProducts.push({ recipient, proofs });
    }
  });

  const shippingPricePayload = recipientsWithProducts.map(rp => ({
    quantities: rp.proofs.map(p => ({
      account_product: p.product.product.id,
      quantity: p.quantity
    })),
    ...pick(rp.recipient, addressFields)
  }));

  if (shippingPricePayload.length > 0) {
    const { data } = await shippingPricesApi.fetch(shippingPricePayload);

    const priceData = data.data || data;
    if (!data) {
      dispatch({ type: ADD_SHIPMENT_GROUP_RECIPIENTS_SUCCESS });
      return;
    }
    const directoryOrders = recipientsWithProducts.map((rp, i) => {
      // eslint-disable-next-line camelcase
      const deliveryMethods = priceData[i]?.delivery_methods?.map((dm, j) =>
        j === 0 ? { ...dm, selected: true } : dm
      );

      return {
        directory: rp.recipient.id,
        proofs: rp.proofs.map(p => ({ proof: p.product.id, sizes: p.sizes })),
        shippingDate: isStockLimited ? minimumShippingDate(new Date(), shippingCutoffHour) : undefined,
        deliveryMethods
      };
    });

    dispatch({
      type: ADD_SHIPMENT_GROUP_DIRECTORY_ORDERS,
      payload: directoryOrders
    });
  }

  dispatch({
    type: SELECT_SHIPMENT_GROUP_RECIPIENTS,
    payload: recipients
  });
  dispatch(validateDirectoryOrders(recipientsMissingStock, isStockLimited, false));
  dispatch({ type: ADD_SHIPMENT_GROUP_RECIPIENTS_SUCCESS });
};

export const createdDirectoryOrders = () => dispatch => dispatch({ type: CREATED_SHIPMENT_GROUP_DIRECTORY_ORDERS });

export const addShipmentGroupRecipientsToSelect = ids => dispatch =>
  dispatch({
    type: ADD_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT,
    payload: ids
  });

export const cleanShipmentGroup = dispatch => dispatch({ type: CLEAN_SHIPMENT_GROUP });

export const deleteShipmentGroupRecipients = ids => dispatch =>
  dispatch({
    type: DELETE_SHIPMENT_GROUP_RECIPIENTS,
    payload: ids
  });

export const toogleAllShipmentGroupRecipientsCollapsed = value => dispatch =>
  dispatch({
    type: TOOGLE_ALL_SHIPMENT_GROUP_RECIPIENTS_COLLAPSED,
    payload: value
  });

export const deleteShipmentGroupRecipientsToSelect = ids => dispatch =>
  dispatch({
    type: DELETE_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT,
    payload: ids
  });

export const updateSelectedRecipientsShippingMethod = payload => dispatch =>
  dispatch({
    type: UPDATE_SELECTED_RECIPIENTS_SHIPPING_METHOD,
    payload
  });

export const updateSelectedRecipientsShippingDate = payload => dispatch =>
  dispatch({
    type: UPDATE_SELECTED_RECIPIENTS_SHIPPING_DATE,
    payload
  });

export const updateShipmentGroupDirectoryOrder = (directoryOrder, removeError, isStockLimited) => dispatch =>
  dispatch({
    type: UPDATE_SHIPMENT_GROUP_DIRECTORY_ORDER,
    payload: { directoryOrder, removeError, isStockLimited }
  });

export const updateShipmentGroupDirectoryOrderLoadingStatus = isLoading => dispatch =>
  dispatch({
    type: UPDATE_SHIPMENT_GROUP_LOADING_STATUS,
    payload: isLoading
  });

export const updatepRecipientCollapsed = recipient => dispatch => {
  dispatch({
    type: UPDATE_SHIPMENT_GROUP_RECIPIENT_COLLAPSED,
    payload: recipient
  });
};

export const updateShipmentGroupRecipient = recipient => (dispatch, getState) => {
  const { products, recipients, directoryOrders, errors } = getState().shipmentGroup;
  const oldRecipient = recipients.find(r => r.id === recipient.id);

  dispatch({
    type: UPDATE_SHIPMENT_GROUP_RECIPIENT,
    payload: recipient
  });

  const sizeUnchanged = oldRecipient.size?.id === recipient.size?.id;
  if (sizeUnchanged && isSameAddress(recipient, oldRecipient)) return;

  const directoryOrder = directoryOrders.find(dOrder => dOrder.directory === recipient.id);
  const { payload, proofs } = buildPayloadAndNewProofs(products, directoryOrder, recipient, sizeUnchanged, errors);

  shippingPricesApi
    .fetch(payload)
    .then(response => response.delivery_methods?.map((dm, i) => (i === 0 ? { ...dm, selected: true } : dm)))
    .then(
      deliveryMethods =>
        deliveryMethods?.length > 0 &&
        dispatch(
          updateShipmentGroupDirectoryOrder({
            ...directoryOrder,
            directory: recipient.id,
            proofs,
            deliveryMethods
          })
        )
    )
    .catch(() =>
      dispatch({
        type: DELETE_SHIPMENT_GROUP_DIRECTORY_ORDER,
        payload: recipient.id
      })
    );
};

export const selectShipmentGroupProducts = products => dispatch =>
  dispatch({
    type: SELECT_SHIPMENT_GROUP_PRODUCTS,
    payload: products
  });

export const selectShipmentGroupRecipients = recipients => dispatch =>
  dispatch({
    type: SELECT_SHIPMENT_GROUP_RECIPIENTS,
    payload: recipients
  });

export const setShipmentGroupId = id => dispatch =>
  dispatch({
    type: SET_SHIPMENT_GROUP_ID,
    payload: id
  });

export const toggleSelectFromExisting = () => dispatch => dispatch({ type: TOGGLE_SELECT_FROM_EXISTING });

export const setCurrentContactGroup = query => dispatch => dispatch({ type: SET_CONTACT_FETCH_QUERY, payload: query });
