import React, { useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  withStyles,
  Stepper as MuiStepper,
  StepConnector as MuiStepConnector,
  StepIcon as MuiStepIcon,
  StepLabel as MuiStepLabel,
  Step,
  Grid,
  Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button } from '@swagup-com/components';
import Scrollable from 'react-scrollbars-custom';
import { useIntercom } from 'react-use-intercom';
import { Img } from '../global/ImgUtils';
import styles from './styles/FullscreenStepper';

const StepLabel = withStyles({
  label: {
    fontSize: 14,
    color: '#868a8f',
    fontFamily: 'Gilroy-Medium',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  active: {
    '&$label': {
      color: '#3577d4',
      fontFamily: 'Gilroy-SemiBold'
    }
  },
  completed: {
    '&$label': { color: '#0b1829' }
  }
})(MuiStepLabel);

const StepIcon = withStyles({
  root: {
    width: 28,
    height: 28,
    border: '6px solid transparent',
    borderRadius: '50%',
    color: '#f2f6fd'
  },
  active: { borderColor: '#f2f6fd' },
  text: { display: 'none' }
})(MuiStepIcon);

const StepConnector = withStyles({
  root: { padding: 0 },
  vertical: { margin: '-6px 0 -6px 13.5px' },
  lineVertical: {
    height: 40,
    borderLeftWidth: 1
  },
  active: {
    '& $line': {
      borderColor: '#3577d4'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#3577d4'
    }
  },
  line: { borderColor: '#f2f6fd' }
})(MuiStepConnector);

const Stepper = withStyles({
  root: {
    backgroundColor: 'transparent',
    padding: '24px 0',
    marginTop: 70
  }
})(MuiStepper);

const useStyles = makeStyles(styles);

const FullscreenStepper = ({ children, steps, activeStep }) => {
  const classes = useStyles();
  const { update } = useIntercom();

  useEffect(() => {
    const changeZIndex = zIndex =>
      setTimeout(() => {
        const intercomEL = document.getElementsByClassName('intercom-lightweight-app')[0];
        if (intercomEL) intercomEL.style = zIndex ? `z-index: ${zIndex}` : null;
        else changeZIndex(zIndex);
      }, 500);

    update({
      alignment: 'left',
      horizontalPadding: 104,
      verticalPadding: 72
    });
    changeZIndex(1);

    return () => {
      update({
        alignment: 'right',
        horizontalPadding: 20,
        verticalPadding: 20
      });
      changeZIndex();
    };
  }, [update]);

  return (
    <Grid container className={classes.container}>
      <Grid container alignItems="center" direction="column" className={classes.sidebar}>
        <Link to="/">
          <Img src="/images/public/su-logo.svg" width={142} height={32} />
        </Link>

        <Stepper activeStep={activeStep} orientation="vertical" connector={<StepConnector />}>
          {steps.map(({ label }) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Grid item className={classes.helpSection}>
          <Typography variant="body2" style={{ color: '#868a8f' }}>
            Need any help?
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" className={classes.main}>
        <Typography variant="subtitle1" className={classes.stepCounter}>
          Step {activeStep + 1}/{steps.length}
        </Typography>
        {children}
      </Grid>
    </Grid>
  );
};

const Container = forwardRef(
  ({ children, style, ...props }, ref) => {
    const classes = useStyles();
    return (
      <Scrollable className={classes.scrollable}>
        <Grid ref={ref} container {...props} style={{ flex: 1, paddingLeft: 80, ...style }}>
          {children}
        </Grid>
      </Scrollable>
    );
  },
  { displayName: 'Container' }
);

const Footer = ({ children, onContinue, onPrevious, continueButtonType = 'button' }) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.footer}>
      <Button variant="text" onClick={onPrevious} className={classes.previousButton}>
        <KeyboardBackspaceIcon />
        Previous step
      </Button>
      <Grid item style={{ flex: 1 }}>
        {children}
      </Grid>
      <Button
        variant="primary"
        size="small"
        className={classes.continueBtn}
        type={continueButtonType}
        onClick={onContinue || undefined}
        disabled={!onContinue}
      >
        Continue
      </Button>
    </Grid>
  );
};

export { FullscreenStepper, Container, Footer };
