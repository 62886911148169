import React from 'react';
import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';

import styles from './styles/steps';

const useStyles = makeStyles(styles);

const Marker = ({ distanceToSelected, classes }) => (
  <div
    className={clsx(
      classes.marker,
      distanceToSelected < 0
        ? classes.previousMarker
        : distanceToSelected > 0
        ? classes.nextMarker
        : classes.currentMarker
    )}
  >
    {distanceToSelected < 0 ? (
      <CheckIcon className={classes.check} />
    ) : distanceToSelected === 0 ? (
      <div className={classes.circle} />
    ) : null}
  </div>
);

const Step = ({ text, distanceToSelected, classes }) => {
  return (
    <div className={classes.step}>
      <Marker distanceToSelected={distanceToSelected} classes={classes} />
      <p className={distanceToSelected <= 0 ? classes.visitedText : classes.unvisitedText}>{text}</p>
    </div>
  );
};

const Steps = ({ steps, selected }) => {
  const classes = useStyles({ length: steps.length });
  const selectedStep = steps.length === 3 ? selected - 1 : selected;

  return (
    <div className={classes.root}>
      {steps.filter(Boolean).map((step, i) => (
        <Step key={step} text={step} distanceToSelected={i - selectedStep} classes={classes} />
      ))}
      <div className={classes.line} />
    </div>
  );
};

export default Steps;
