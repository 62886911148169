const textAlignment = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const styles = theme => ({
  modalContainer: {
    width: '100%',
    padding: '0px 50px 0px 5px'
  },
  bannerModal: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
  },
  inventoryModalHeader: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    position: 'relative'
  },
  bannerCloseBtn: {
    position: 'absolute',
    top: 22,
    right: 22,
    color: '#131415'
  },
  inventoryModalBody: {
    overflowY: 'hidden',
    maxHeight: '100%',
    height: '100%',
    width: '100%',
    margin: '20px 0px'
  },
  paymentFailedAlertWrapper: {
    marginTop: 24,
    backgroundColor: '#FFF3E0',
    borderRadius: 5,
    padding: 16,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paymentFailedAlertTitle: {
    color: '#C62828'
  },
  paymentFailedAlertMessage: {
    color: '#131415'
  },
  paymentFailedAlertButton: {
    height: '48px',
    borderRadius: '56px',
    padding: '14px 24px',
    borderWidth: 1
  },
  paymentFailedAlertUpgradeMembershipBtn: {
    backgroundColor: '#125CFF',
    '& > span > p': {
      color: '#fff'
    }
  },
  paymentFailedAlertSwagCardBtn: {
    borderColor: '#131415',
    color: '#131415'
  },
  inventoryCTA: {
    ...textAlignment,
    justifyContent: 'space-between !important',
    gap: 14
  },
  tooltip: {
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 350
  },
  reorderLink: ({ disableSendSwagBtn }) => ({
    height: 'unset',
    padding: disableSendSwagBtn ? '14px 24px' : 0,
    cursor: 'pointer',
    ...(disableSendSwagBtn
      ? {
          borderRadius: '24px',
          backgroundColor: '#125CFF',
          '&:hover': {
            backgroundColor: '#125CFF'
          }
        }
      : {
          '&:hover': {
            textDecoration: 'underline !important'
          }
        })
  }),
  orderTable: {
    '& .MuiTableCell-root': {
      borderRight: '0px !important',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& .divContent': theme.typography.body4RegularInter
    },
    '& th.MuiTableCell-root .divContent': {
      color: '#989EA4'
    }
  },
  cellCenter: {
    textAlign: 'center',
    color: '#989EA4',
    '& .divContent': {
      width: '100%',
      textAlign: 'center'
    }
  }
});

export default styles;
