const sideBarWidth = 272;
export default {
  container: {
    height: '100vh',
    backgroundColor: '#fff'
  },
  sidebar: {
    maxWidth: sideBarWidth,
    height: '100%',
    backgroundColor: '#fcfcfc',
    padding: 36,
    position: 'relative'
  },
  helpSection: {
    position: 'absolute',
    bottom: 36,
    width: '100%',
    textAlign: 'center'
  },
  main: {
    height: '100%',
    width: `calc(100% - ${sideBarWidth}px)`,
    paddingTop: 50,
    position: 'relative',
    overflow: 'hidden'
  },
  stepCounter: {
    color: '#868a8f',
    marginLeft: 80,
    marginBottom: 10
  },
  footer: {
    height: 96,
    padding: '0 36px',
    boxShadow: '0 0 24px 0 rgba(0, 0, 0, 0.03)',
    backgroundColor: '#ffffff'
  },
  previousButton: {
    color: '#4a505c',
    fontSize: 14,
    '& svg': {
      marginRight: 16,
      fontSize: 16
    }
  },
  continueBtn: {
    width: 124,
    height: 56,
    fontSize: 14
  },
  scrollable: {
    flex: 1,
    '& .ScrollbarsCustom-Content': {
      display: 'flex',
      flexDirection: 'column'
    }
  }
};
