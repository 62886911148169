import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks';
import { useQueryFilterValidated } from '../../hooks/useFilters';
import { StylessButton } from '../buttons';

const TableSortBy = ({ options = ['-created_at', 'created_at'], defaultValue = '-created_at', style = {} }) => {
  const ordering = useQueryFilterValidated(
    'ordering',
    (id, value) => options.includes(id) && value.split(',').length === 1,
    false,
    defaultValue
  );
  const isFirstOptionSelected = () => ordering === options[0];
  const query = useQueryParams();
  const history = useHistory();
  const toogleSort = () => {
    query.set('ordering', isFirstOptionSelected() ? options[1] : options[0]);
    history.replace({ ...history.location, search: query.toString() });
  };

  const Arrow = isFirstOptionSelected() ? ArrowDownward : ArrowUpward;
  return (
    <StylessButton onClick={toogleSort}>
      <Arrow style={{ height: 12, width: 12, color: '#131415', marginTop: 4, ...style }} />
    </StylessButton>
  );
};

export default TableSortBy;
