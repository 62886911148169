import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { moneyStr } from '../../../../../helpers/utils';
import { useInSendSwagPath } from '../hooks';
import ArrowTooltip from '../common/Tooltip';

const useStyles = makeStyles({
  shippingMethodButton: ({ active }) => ({
    height: 84,
    width: 334,
    padding: 20,
    borderRadius: 10,
    border: active ? 'solid 1px #3577d4' : 'solid 1px #f0f2f5',
    backgroundColor: active ? '#f4f8ff' : 'white',
    '&:hover': {
      border: 'solid 1px #3577d4',
      backgroundColor: '#f4f8ff',
      cursor: 'pointer'
    },
    '&[disabled]': {
      backgroundColor: '#f8f8f8',
      filter: 'grayscale(100%)'
    }
  }),
  shippingMethodTitle: ({ active }) => ({
    color: active ? '#3577d4' : '#0b1829',
    cursor: 'inherit',
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: 0,
    'button[disabled] &': { color: '#787b80' }
  }),
  shippingMethodButtonLabel: {
    height: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  shippingMethodSubtitle: ({ active }) => ({
    color: active ? '#0b1829' : '#787b80',
    cursor: 'inherit',
    fontFamily: 'Gilroy-Medium',
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: 0
  }),
  discountText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  discountBadge: { background: '#90BEFF', borderRadius: 16, padding: '4px 10px 4px 6px' },
  discountImageWrapper: { width: 16, height: 16, position: 'relative' },
  discountImage: { width: '100%', height: '100%', objectFit: 'contain' }
});

const expressDisableText =
  'Due to increased demand, we are temporarily restricting express shipping in order to balance our focus on service and safety.';

const ShippingMethodButton = ({
  name,
  price,
  originalPrice,
  discount,
  time,
  active,
  disabled,
  showMessage,
  onClick,
  ...props
}) => {
  const classes = useStyles({ active });
  const { disableExpressShipmentsGlobalPermanent062422: expressShipments, swagupMembershipPlans } = useFlags();
  const comesFromSendSwag = useInSendSwagPath();
  const estimatedDurationPrefix = comesFromSendSwag ? 'Shipping date' : 'Production time';

  const disableOnlyExpress = name === 'Express' && !expressShipments;
  const disableBtn = disabled || disableOnlyExpress;

  return (
    <ArrowTooltip
      padding="10px 14px"
      maxWidth={300}
      variant="primary"
      title={!disabled && disableOnlyExpress ? expressDisableText : ''}
    >
      <Button
        disabled={disableBtn}
        onClick={onClick}
        // onKeyDown={handleKeyDown}
        tabIndex="0"
        aria-pressed={active}
        classes={{ root: classes.shippingMethodButton, label: classes.shippingMethodButtonLabel }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <Grid container alignItems="center" spacing={3} className={classes.shippingMethodTitle}>
          <Grid item xs>
            <p style={{ textAlign: 'left', margin: 0 }}>
              {name}
              {name === 'Express' && <> 🚀</>}
            </p>
          </Grid>
          {swagupMembershipPlans && originalPrice > 0 && (
            <Grid item>
              <span style={{ color: '#787B80', textDecoration: 'line-through' }}>
                {moneyStr(disableBtn ? 0 : originalPrice)}
              </span>
            </Grid>
          )}
          <Grid item>
            <span>{moneyStr(disableBtn ? 0 : price)}</span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ marginTop: 12 }}>
          <Grid item xs>
            <p className={classes.shippingMethodSubtitle}>
              {disableBtn
                ? showMessage && 'This shipping method is not available'
                : `${estimatedDurationPrefix} + ${time}`}
            </p>
          </Grid>
          {swagupMembershipPlans && discount > 0 && (
            <Grid item>
              <Box className={classes.discountBadge}>
                <Grid container>
                  <Grid item>
                    <Box className={classes.discountImageWrapper}>
                      <img src="/images/membership/rewardRocket.png" className={classes.discountImage} alt="discount" />
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <span className={classes.discountText}>-{discount}%</span>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Button>
    </ArrowTooltip>
  );
};

export default ShippingMethodButton;
