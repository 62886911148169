export default theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  leftSide: {
    paddingTop: '4rem',
    paddingLeft: '8rem',
    paddingRight: '6rem',
    width: '35vw',
    [theme.breakpoints.down('lg')]: {
      paddingRight: '3rem',
      paddingLeft: '4rem'
    }
  },
  rightSide: {
    flex: 'auto',
    backgroundColor: theme.palette.common.white,
    paddingTop: '4em',
    paddingBottom: '2rem',
    paddingLeft: '7rem',
    paddingRight: '12rem',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '3rem',
      paddingRight: '4rem'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 900,
      justifyContent: 'center',
      textAlign: 'center',
      margin: '0px auto',
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      paddingLeft: 20,
      paddingRight: 20,
      width: '90%'
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    }
  },
  logo: {
    margin: 'auto'
  },
  viewDashboardButton: {
    width: 250,
    marginBottom: 20
  }
});
