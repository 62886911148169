import React from 'react';
import { withStyles, Grid } from '@material-ui/core';

import { moneyStr } from '../../../helpers/utils';
import { BlueSwitch } from '../../account/AccountSharedComponents';

const styles = () => ({
  creditsContainer: {
    borderRadius: 12,
    border: 'solid 1px #3577d4',
    backgroundColor: '#ffffff',
    padding: '16px 21px',
    width: 218,
    minWidth: 218,
    maxWidth: 218,
    marginTop: -49
  },
  creditsTitle: {
    fontFamily: 'Gilroy-SemiBold',
    color: '#434d5c',
    fontSize: 12,
    marginBottom: 5
  },
  creditsValue: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#45af5f'
  }
});

const CreditsSwitch = ({ classes, balance, useCreditsFirst, disabled, onToggleUseCredits, strict }) => (
  <Grid
    container
    alignItems="center"
    justifyContent="space-between"
    className={classes.creditsContainer}
    style={{ marginTop: strict ? 0 : undefined }}
  >
    <Grid item>
      <p className={classes.creditsTitle}>Use Available Credits</p>
      <p className={classes.creditsValue}>{moneyStr(balance)}</p>
    </Grid>
    <BlueSwitch
      value={useCreditsFirst}
      checked={useCreditsFirst}
      onChange={onToggleUseCredits}
      size="small"
      disabled={disabled}
    />
  </Grid>
);

export default withStyles(styles)(CreditsSwitch);
