import { STATUS_CHANGED } from '../actions/types';

export const statuses = {
  idle: 'idle',
  pending: 'pending',
  success: 'success',
  error: 'error'
};

const GlobalNotificationReducer = (state = { status: statuses.idle, message: '' }, action) => {
  const { type, payload } = action;
  if (type === STATUS_CHANGED) {
    return payload;
  }

  return state;
};

export default GlobalNotificationReducer;
