import React from 'react';
import { Badge, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';

const styles = () => ({
  newBadgeContainer: ({ size, fontColor }) => ({
    height: 'auto',
    background: '#C8E6C9',
    position: 'absolute',
    borderRadius: size === 'small' ? '2px' : '4px',
    padding: size === 'small' ? '2px 3px' : '2px 6px',
    // right: size === 'small' ? -14 : -18,
    right: -20,
    color: fontColor || 'inherit',
    fontSize: size === 'small' ? '11px' : '12px',
    '& span': {
      color: '#2E7D32',
      fontWeight: '500',
      fontSize: size === 'small' ? '6px' : '12px',
      lineHeight: size === 'small' ? '10px' : '16px'
    }
  })
});

const useStyles = makeStyles(styles);
const NewBadge = ({ size, children, fontColor }) => {
  const classes = useStyles({ size, fontColor });

  return (
    <Badge
      overlap="rectangular"
      badgeContent="New"
      style={{ position: 'relative' }}
      classes={{ badge: classes.newBadgeContainer }}
    >
      <Typography variant="body4MediumInter">{children}</Typography>
    </Badge>
  );
};

export default NewBadge;
