import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import { paymentMethods } from '../../apis/constants';
import { moneyStr, imageSrcSet, cardImg } from '../../helpers/utils';
import { CenteredGrid } from '../shared';

const styles = {
  container: { paddingBottom: 42 },
  saveHeader: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: '#0b1829'
  },
  goBackBtn: {
    width: 185,
    height: 56,
    marginTop: 45
  },
  emailNotice: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    color: '#787b80',
    marginTop: 5,
    marginBottom: 28
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#787b80'
  },
  amount: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    color: '#0b1829',
    marginTop: -7
  },
  img: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 290,
    height: 290
  },
  box: {
    width: 396,
    height: 110,
    borderRadius: 15,
    padding: '10px 0px 20px 20px',
    border: 'solid 1px #ebedf0',
    backgroundColor: '#ffffff'
  },
  icon: {
    width: 22,
    marginRight: 10
  }
};

const ConfirmationInfo = ({ classes, amountAdded, currentBalance, defaultMehodImg, defaultMethodInfo }) => (
  <Grid container justifyContent="space-around" alignItems="flex-start" className={classes.box}>
    <Grid container direction="column" item xs={6}>
      <Typography variant="body1" className={classes.subtitle}>
        Amount added
      </Typography>
      <Typography variant="body1" className={classes.amount}>
        {moneyStr(amountAdded)}
      </Typography>
      <Grid container alignItems="center">
        <img src={defaultMehodImg} alt="Payment Icon" className={classes.icon} />
        <Typography variant="body1" className={classes.subtitle}>
          {defaultMethodInfo}
        </Typography>
      </Grid>
    </Grid>
    <Grid container direction="column" item xs={6} style={{ paddingLeft: 35 }}>
      <Typography variant="body1" className={classes.subtitle}>
        Current Balance
      </Typography>
      <Typography variant="body1" className={classes.amount} style={{ color: '#45af5f' }}>
        {moneyStr(currentBalance)}
      </Typography>
    </Grid>
  </Grid>
);

const useStyles = makeStyles(styles);

const CreditConfirmation = () => {
  const classes = useStyles();
  const location = useLocation();
  const amountAdded = location.state?.amount || 0;
  const currentBalance = location.state?.currentBalance || 0;
  const defaultMethod = location.state?.method || {};

  const [defaultMehodImg, defaultMethodInfo] =
    defaultMethod.payment_method === paymentMethods.creditCard
      ? [cardImg(defaultMethod.credit_card.card_type), defaultMethod.credit_card.card_number]
      : ['/images/account/ach.svg', defaultMethod.bank_account.name_on_account];

  return (
    <CenteredGrid container justifyContent="center" className={classes.container}>
      <Grid container justifyContent="center" item xs={12}>
        <img srcSet={imageSrcSet('/images/account/credit-confirmed.png')} alt="Confirmed" className={classes.img} />
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant="body1" className={classes.saveHeader}>
          Your funds have been added
        </Typography>
        <Typography variant="body1" className={classes.emailNotice}>
          An email confirmation has also been sent to you.
        </Typography>
      </Grid>
      <ConfirmationInfo
        classes={classes}
        amountAdded={amountAdded}
        currentBalance={currentBalance}
        defaultMehodImg={defaultMehodImg}
        defaultMethodInfo={defaultMethodInfo}
      />
      <Grid container justifyContent="center" item xs={12}>
        <Button variant="secondary" component={Link} to="/billing/credits" className={classes.goBackBtn}>
          Back to Credits
        </Button>
      </Grid>
    </CenteredGrid>
  );
};

export default CreditConfirmation;
