import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Grid, Table, TableBody, TableCell, TableHead, TableRow, makeStyles, Box } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import InvoicePaymentDialog from './InvoicePaymentDialog';
import { fullFormatDate, moneyStr, buildUrlWithParam } from '../../helpers/utils';
import { invoiceStatus } from '../../apis/constants';
import styles from './styles/invoices';
import { DownloadIcon } from '../icons';
import Loader from '../global/Loader';

export const invoiceStatusStyles = {
  [invoiceStatus.pendingPayment]: { color: '#3577d4', backgroundColor: 'rgba(53, 119, 212, 0.1)' },
  [invoiceStatus.paid]: { color: '#45af5f', backgroundColor: 'rgba(69, 175, 95, 0.1)' },
  [invoiceStatus.canceled]: { color: '#ff1818', backgroundColor: 'rgba(255, 24, 24, 0.1)' },
  [invoiceStatus.refunded]: { color: '#ff1818', backgroundColor: 'rgba(255, 24, 24, 0.1)' },
  [invoiceStatus.processingError]: { color: '#8d9299', backgroundColor: 'rgba(67, 76, 95, 0.1)' },
  [invoiceStatus.processingPayment]: { color: '#8d9299', backgroundColor: 'rgba(67, 76, 95, 0.1)' },
  [invoiceStatus.creditExchange]: { color: '#45af5f', backgroundColor: 'rgba(69, 175, 95, 0.1)' },
  overdue: { color: '#ff1818', backgroundColor: 'rgba(255, 24, 24, 0.1)' }
};

const useButtonStyles = makeStyles({
  statusBtn: ({ onlyPendingPayment, status }) => ({
    height: 30,
    padding: '6px 15px 4px 15px',
    width: 'auto',
    fontSize: onlyPendingPayment ? 14 : 12,
    color: onlyPendingPayment ? '#3577d4' : status?.color || '#8d9299',
    backgroundColor: onlyPendingPayment ? 'none' : status?.backgroundColor || '#ebeef2',
    '&:hover': { color: onlyPendingPayment ? '#004ca2' : 'none' }
  })
});

const InvoiceStatusButton = ({ invoice, to }) => {
  const isPending = invoice.status === invoiceStatus.pendingPayment;
  const isOverdue = isPending && new Date(invoice.due_date) < new Date();

  const classes = useButtonStyles({
    onlyPendingPayment: isPending && !isOverdue,
    status: invoiceStatusStyles[isOverdue ? 'overdue' : invoice.status]
  });

  return (
    <Button
      variant="text"
      component={Link}
      replace
      to={to}
      disabled={invoice.status === invoiceStatus.processingError}
      className={classes.statusBtn}
    >
      {isPending ? (isOverdue && 'Overdue') || 'Pay Now' : invoice.status}
    </Button>
  );
};

const InvoiceRow = ({ invoice, classes }) => {
  const isInvoiceReady = invoice.name !== 'Processing' && invoice.status !== invoiceStatus.processingPayment;
  const shouldLinkToInvoice = invoice.name !== 'Processing' && invoice.status !== invoiceStatus.processingError;

  const { location } = useHistory();
  const urlToOpen = buildUrlWithParam(location, 'open', invoice.name);

  return (
    <TableRow className={classes.tableRow}>
      <TableCell>{fullFormatDate(invoice.created_at)}</TableCell>
      <TableCell>
        {shouldLinkToInvoice ? (
          <Link replace to={urlToOpen} className={classes.invName}>
            {invoice.name}
          </Link>
        ) : (
          <p className={classes.invNameDisabled}>{invoice.name}</p>
        )}
      </TableCell>
      <TableCell align="right">{moneyStr(invoice.total_plus_tax)}</TableCell>
      <TableCell align="center">{isInvoiceReady && <InvoiceStatusButton invoice={invoice} to={urlToOpen} />}</TableCell>
      <TableCell align="right">
        {isInvoiceReady && invoice.invoice_pdf_url && (
          <a target="_blank" rel="noopener noreferrer" href={invoice.invoice_pdf_url} download>
            <DownloadIcon />
          </a>
        )}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(styles);

const Invoices = ({ children, invoices, onFetchInvoices, isPending }) => {
  const [isInvoicePaid, setIsInvoicePaid] = useState(false);
  const [invoiceToOpen, setInvoiceToOpen] = useState(null);
  const { leftBarNavigation } = useFlags();
  const classes = useStyles();
  const history = useHistory();

  const query = new URLSearchParams(history.location.search);
  const invoiceNameToOpen = query.get('open');

  const removeOpenQueryParam = useCallback(() => {
    query.delete('open');
    history.replace(`${history.location.pathname}?${query.toString()}`);
  }, [query, history]);

  useEffect(() => {
    if (invoiceNameToOpen?.length === 0 || invoiceNameToOpen === 'Processing') {
      removeOpenQueryParam();
    } else if (invoiceNameToOpen) {
      const invoice = invoices.find(i => i.name === invoiceNameToOpen);

      if (!invoice) {
        removeOpenQueryParam();
      } else {
        setInvoiceToOpen(invoice);
      }
    }
  }, [invoiceNameToOpen, invoices, removeOpenQueryParam]);

  const handlePaid = () => setIsInvoicePaid(true);

  const handleClose = () => {
    if (isInvoicePaid) {
      setIsInvoicePaid(false);
      onFetchInvoices();
    }
    removeOpenQueryParam();
  };

  return (
    <Grid container direction="column">
      {!leftBarNavigation ? (
        <Typography variant="h3" className={classes.title}>
          Invoices
        </Typography>
      ) : null}
      {children}
      <Box position="relative">
        {isPending && <Loader absolute />}
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Invoice</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map(invoice => (
              <InvoiceRow key={invoice.id} invoice={invoice} classes={classes} />
            ))}
          </TableBody>
        </Table>
      </Box>
      <InvoicePaymentDialog
        invoice={invoiceToOpen}
        open={Boolean(invoiceNameToOpen) && invoiceToOpen != null}
        onPaid={handlePaid}
        onClose={handleClose}
      />
    </Grid>
  );
};

export default Invoices;
