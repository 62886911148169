import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { Img } from '../../global/ImgUtils';

const Help = ({ classes }) => (
  <Grid container justifyContent="center" className={classes.help}>
    <Img src="/images/shipSwag/help.png" alt="help" width={196} height={196} />
    <Grid container direction="column" justifyContent="center" style={{ marginLeft: 36, maxWidth: 383 }}>
      <Typography variant="h5">Questions about your shipment?</Typography>
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={6}>
          <Grid container alignItems="center">
            <Img src="/images/shipSwag/phone.png" alt="phone" width={16} height={16} />
            <Typography variant="body1" className={classes.contact}>
              Call Us
            </Typography>
          </Grid>
          <a href="tel:646-494-0520">
            <Typography variant="h6" style={{ lineHeight: 1.6 }}>
              646-494-0520
            </Typography>
          </a>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container alignItems="center">
            <Img src="/images/shipSwag/email.png" alt="email" width={16} height={16} />
            <Typography variant="body1" className={classes.contact}>
              Send Email
            </Typography>
          </Grid>
          <a href="mailto:team@swagup.com">
            <Typography variant="h6" style={{ lineHeight: 1.6 }}>
              team@swagup.com
            </Typography>
          </a>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const styles = {
  help: {
    padding: '36px 0 76px'
  },
  contact: {
    letterSpacing: 0,
    marginLeft: 12
  }
};

export default withStyles(styles)(Help);
