import * as React from 'react';
import clsx from 'clsx';
import { Button, Grid, InputBase, makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import ArrowTooltip from './Tooltip';
import { isOneSize } from '../../../../../helpers/utils';
import styles from './SizesList.styles';

const useStyles = makeStyles(styles);

export const presetQuantities = [50, 100, 200, 250, 500, 1000];

const notEnoughQuantityMsg =
  'Your order must contain enough inventory to fulfill created shipments and warehouse storage.';
const outOfStockMsg = 'Quantity not sufficient.';
const inactiveSizeMsg =
  'This size is Out of Stock. Please adjust order and shipments to accommodate for available sizes.';

const validateQuantities = ({ currentQuantity, oldQuantity, minimumQuantity, maximumQuantity }) => {
  if (minimumQuantity && currentQuantity < minimumQuantity) {
    return {
      validationData: { min: minimumQuantity },
      errorMessage: notEnoughQuantityMsg
    };
  }
  if (maximumQuantity && currentQuantity > maximumQuantity) {
    return {
      validationData: { max: maximumQuantity },
      errorMessage: outOfStockMsg
    };
  }
  if (oldQuantity && oldQuantity < currentQuantity) {
    return {
      validationData: { new: currentQuantity - oldQuantity }
    };
  }
  if (maximumQuantity && maximumQuantity >= currentQuantity) {
    return {
      validationData: { left: maximumQuantity - currentQuantity }
    };
  }

  return {};
};

const ValidationMessage = ({ validationData = {}, offset }) => {
  const classes = useStyles({ offset });

  if (validationData.min) return <p className={classes.limitError}> min {validationData.min}</p>;
  if (validationData.max) return <p className={classes.limitError}> max {validationData.max}</p>;
  if (validationData.new) return <p className={classes.newQuantity}> {validationData.new} new</p>;
  if (validationData.left) return <p className={classes.remainingQuantity}>{validationData.left} left</p>;
  return null;
};

const CustomButton = ({ active, ...props }) => {
  const classes = useStyles({ active });
  return <Button className={classes.quantityButton} aria-pressed={active} {...props} />;
};

export const SizesListOneSize = ({ currentQuantity, customSizeInputProps, disabled, isOrderOverview, onQtyClick }) => {
  const [quantityValue, setQuantityValue] = useState();
  const disabledInput = disabled || isOrderOverview;

  const inputRef = React.useRef(null);
  React.useEffect(() => {
    if (inputRef.current && !presetQuantities.includes(currentQuantity)) {
      inputRef.current.value = currentQuantity;
    }
  }, [currentQuantity]);

  const [initialQuantity, setInitialQuantity] = React.useState(null);
  React.useEffect(() => {
    if (!presetQuantities.includes(currentQuantity)) setInitialQuantity(currentQuantity);
  }, [currentQuantity]);

  const handleQtyClick = value => {
    onQtyClick(currentQuantity === value ? initialQuantity || '' : value);
    inputRef.current.value = '';
  };

  const { minimumQuantity, maximumQuantity, onChange, limitQty, ...customSizeProps } = customSizeInputProps;
  const customActive = !presetQuantities.includes(currentQuantity) && currentQuantity;
  const classes = useStyles();
  const { validationData, errorMessage } = validateQuantities({
    currentQuantity,
    minimumQuantity,
    maximumQuantity
  });

  const debouncedQtyChanged = debounce(value => onChange({ target: { value } }), 50);
  useEffect(() => {
    if (quantityValue !== undefined && Number(currentQuantity) !== Number(quantityValue))
      debouncedQtyChanged(quantityValue);
  }, [quantityValue]);

  const handleOnChage = e => {
    const {
      target: { value }
    } = e;
    if (limitQty && Number(value) > maximumQuantity) return;
    setQuantityValue(value);
  };

  if (isOrderOverview) {
    return (
      <Grid item style={{ position: 'relative', textAlign: 'center' }}>
        <p className={classes.disabledSizeName} style={{ marginBottom: 5 }}>
          One Size
        </p>
        <InputBase
          key={currentQuantity}
          className={clsx(classes.customQuantity, classes.customQtyDisabled)}
          inputRef={inputRef}
          defaultValue={currentQuantity || ''}
          disabled={disabledInput}
          onChange={handleOnChage}
          {...customSizeProps}
        />
      </Grid>
    );
  }

  return (
    <>
      {presetQuantities.map(value => (
        <CustomButton
          key={value}
          active={value === currentQuantity}
          disabled={value < minimumQuantity || (maximumQuantity && value > maximumQuantity) || disabledInput}
          variant="outlined"
          onClick={() => handleQtyClick(value)}
        >
          {value}
        </CustomButton>
      ))}
      <ArrowTooltip variant="alert" title={errorMessage || ''} style={{ flexBasis: 144, position: 'relative' }}>
        <InputBase
          key={customActive}
          className={clsx(classes.customQuantity, {
            [classes.invalidSizeInput]: errorMessage
          })}
          inputRef={inputRef}
          defaultValue={customActive || ''}
          disabled={disabledInput}
          onChange={e => handleOnChage(e)}
          {...customSizeProps}
        />
        <ValidationMessage validationData={validationData} offset={48} />
      </ArrowTooltip>
    </>
  );
};

export const SizesListMultiSize = ({
  sizes,
  idSelector,
  nameSelector,
  inputPropsPerSize,
  hideInactiveWarnings,
  isStatic
}) => {
  const classes = useStyles();

  if (sizes && isOneSize(sizes)) throw new Error(`SizesListMultiSize must be used with a multi-size product`);

  return sizes.map(size => {
    const {
      minimumQuantity,
      maximumQuantity,
      currentQuantity,
      oldQuantity,
      onChange,
      limitQty,
      ...props
    } = inputPropsPerSize(size);
    const { validationData, errorMessage } = validateQuantities({
      currentQuantity,
      oldQuantity,
      minimumQuantity,
      maximumQuantity
    });

    const [quantityValue, setQuantityValue] = useState();

    const debouncedQtyChanged = debounce(value => onChange({ target: { value } }), 50);
    useEffect(() => {
      if (quantityValue !== undefined && Number(currentQuantity) !== Number(quantityValue))
        debouncedQtyChanged(quantityValue);
    }, [quantityValue]);

    const handleOnChage = e => {
      e.stopPropagation();
      const {
        target: { value }
      } = e;
      if (limitQty && Number(value) > maximumQuantity) return;
      setQuantityValue(value);
    };

    const isInactive = !size.active && !hideInactiveWarnings;
    const isInactiveAndHasQty = isInactive && currentQuantity > 0;
    const title = isInactive && !errorMessage ? inactiveSizeMsg : errorMessage || '';

    return isStatic ? (
      <Grid item className={classes.sizeInputContainer} style={{ position: 'relative' }}>
        <p className={props.disabled ? classes.disabledSizeName : classes.sizeName}>{nameSelector(size)}</p>
        <InputBase key={props.defaultValue} className={classes.sizeInput} onChange={handleOnChage} {...props} />
      </Grid>
    ) : (
      <ArrowTooltip key={idSelector(size)} variant="alert" title={title}>
        <Grid item className={classes.sizeInputContainer} style={{ position: 'relative' }}>
          <p className={props.disabled ? classes.disabledSizeName : classes.sizeName}>
            {nameSelector(size)}
            {(isInactive || errorMessage) && (
              <InfoOutlinedIcon
                viewBox="-3 -9 25 31"
                className={isInactive && !errorMessage ? classes.warningIcon : classes.errorIcon}
              />
            )}
          </p>
          <InputBase
            key={props.defaultValue}
            className={clsx(classes.sizeInput, {
              [classes.warningSizeInput]: isInactiveAndHasQty && !errorMessage,
              [classes.errorSizeInput]: errorMessage
            })}
            onChange={handleOnChage}
            {...props}
          />
          {!props.disabled && <ValidationMessage validationData={validationData} offset={68} />}
        </Grid>
      </ArrowTooltip>
    );
  });
};
