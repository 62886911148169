import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles, useMediaQuery, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import csvProcessesApi from '../../apis/swagup/csvProcesses';
import { PROCESS_START } from '../../actions/types';
import { useAsync } from '../../hooks';
import Loader from '../global/Loader';
import { Img } from '../global/ImgUtils';
import { Container, Footer } from '../layouts/FullscreenStepper';
import ErrorAlert from '../shared/ErrorAlert';
import styles from './styles/SelectAction';

const Option = ({ classes, title, srcImg, selected, onClick }) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    className={clsx(classes.option, { selected })}
    onClick={onClick}
  >
    <Img src={srcImg} width={210} height={210} />
    <Typography variant="h6" className={classes.optionText}>
      {title}
    </Typography>
  </Grid>
);

const OptionInfo = ({ classes, title, description, selected }) => (
  <div className={clsx(classes.infoContainer, { selected })}>
    <Typography variant="body1" className={classes.infoText}>
      <span className={clsx({ title: true, selected })}>{title}:</span> {description}
    </Typography>
  </div>
);

const useStyles = makeStyles(styles);

const options = { saveContacts: 'saveContacts', shipSwag: 'shipSwag' };

const SelectAction = ({ from, onPreviousStep }) => {
  const matchesHeight = useMediaQuery('(max-height: 768px)');
  const classes = useStyles({ matchesHeight });
  const history = useHistory();
  const { fileId } = useParams();
  const { leftBarNavigation } = useFlags();

  const showShipSwag = ['/orders-requested', '/send-swag'].every(p => !from?.startsWith(p));
  const [selectedOption, setSelectedOption] = useState(showShipSwag ? undefined : options.saveContacts);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [fetchProcess, process, isProcessPending, processError] = useAsync(csvProcessesApi.fetchById);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fileId) history.replace('/');
  }, [fileId, history]);

  useEffect(() => {
    fetchProcess(fileId);
  }, [fileId, fetchProcess]);

  useEffect(() => {
    if (processError) {
      setError('There was an error while processing the data. Please, try again');
      setIsLoading(false);
    }
  }, [processError]);

  useEffect(() => {
    if (process?.result === 'ok' && !process.data.is_valid)
      setError('There are invalid contacts remaining. Please go to the previous step and fix them');
  }, [process]);

  useEffect(() => {
    if (process?.result === 'ok' && process.data.status === 'Completed') {
      history.replace(from || (leftBarNavigation ? '/contacts' : '/shipments/contacts'));
    }
  }, [process, from, history]);

  const continueFn = React.useCallback(async () => {
    setError(undefined);

    if (selectedOption === options.saveContacts) {
      if (from) {
        history.goBack();
      } else {
        history.replace('/shipments');
      }
      dispatch({ type: PROCESS_START, payload: fileId });
      return;
    }

    setIsLoading(true);
    const response = await csvProcessesApi.fetchOutputs(fileId, 999999, 0);
    if (response.result === 'error') {
      setError('There was an error while processing the data. Please, try again');
      setIsLoading(false);
      return;
    }

    const contacts = response.data.results.map(output => ({ id: output.id, ...JSON.parse(output.input_json) }));
    history.replace('/ship-swag', { from, contacts, fileId });
  }, [fileId, selectedOption, from, history, dispatch]);

  useEffect(() => {
    if (!showShipSwag) continueFn();
  }, [showShipSwag, continueFn]);

  const canContinue = !error?.includes('invalid') && selectedOption;

  if (!showShipSwag) return null;

  return (
    <>
      <Container direction="column">
        {(isProcessPending || isLoading) && <Loader />}
        <Typography variant="h4">Save contacts or ship swag now?</Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Feel free to save these contacts and ship later or ship swag now
        </Typography>
        <Grid container justifyContent="space-between" className={classes.optionsContainer}>
          <Grid item style={{ display: 'flex', margin: '10px 0' }}>
            <Option
              classes={classes}
              title="Save contacts"
              srcImg="/images/addContact/attachment.png"
              selected={selectedOption === options.saveContacts}
              onClick={() => setSelectedOption(options.saveContacts)}
            />
            {showShipSwag && (
              <Option
                classes={classes}
                title="Ship swag"
                srcImg="/images/addContact/envelops.png"
                selected={selectedOption === options.shipSwag}
                onClick={() => setSelectedOption(options.shipSwag)}
              />
            )}
          </Grid>
          <Grid item className={classes.optionsInfoContainer}>
            <OptionInfo
              classes={classes}
              title="Save Contacts"
              description="I want to automatically save my contacts to ship swag at a later time."
              selected={selectedOption === options.saveContacts}
            />
            {showShipSwag && (
              <OptionInfo
                classes={classes}
                title="Ship Swag"
                description={
                  <>
                    I want to send swag to recipients on this contact list.
                    <br />
                    💡 <span style={{ color: '#0b1829', fontWeight: 500 }}>Pro tip:</span> If you have multiple pack
                    options, you would have to create a separate contact list. The ship swag option will only allow you
                    to send the same swag option to this contact list.
                  </>
                }
                selected={selectedOption === options.shipSwag}
              />
            )}
          </Grid>
        </Grid>
        {error && <ErrorAlert error={error} className={classes.errorAlert} />}
      </Container>
      <Footer onContinue={canContinue && continueFn} onPrevious={() => onPreviousStep(fileId)} />
    </>
  );
};

export default SelectAction;
