const cellCommons = {
  width: '17%',
  padding: '10px 0 10px 20px',
  fontFamily: 'Gilroy-Medium',
  fontSize: 10,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  borderColor: '#f0f2f5',
  borderLeft: '1px solid #f0f2f5'
};

export default theme => ({
  subtitle: {
    maxWidth: 650,
    marginTop: 16
  },
  cardTemplate: {
    width: 650,
    height: 272,
    marginTop: ({ matches }) => (matches ? 26 : 56),
    padding: '19px 22px',
    border: 'solid 1px #f0f2f5',
    boxShadow: '0 24px 56px 0 rgba(0, 0, 0, 0.05)',
    position: 'relative'
  },
  gradient: {
    position: 'absolute',
    left: 0,
    bottom: 86,
    width: '100%',
    height: 70,
    backgroundImage: 'linear-gradient(to top, #fff, rgba(255, 255, 255, 0))'
  },
  justWhite: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: 86,
    backgroundColor: ' #fff'
  },
  downloadBtn: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 25,
    margin: 'auto',
    width: 273,
    height: 56,
    zIndex: 1
  },
  headerCell: {
    ...cellCommons,
    paddingTop: 0,
    color: '#868a8f'
  },
  bodyCell: {
    ...cellCommons,
    color: '#0b1829'
  },
  firstCell: {
    paddingLeft: 0,
    borderLeft: 0
  },
  addressCell: {
    width: '20%'
  },
  sizeCell: {
    width: '12%'
  },
  lastRow: {
    borderBottom: 0
  },
  skipBtn: {
    marginRight: 50,
    fontSize: 14
  },
  cardsContainer: {
    // first card width + max space between + second card width
    maxWidth: 650 + 112 + 344,
    justifyContent: 'space-between',
    [theme.breakpoints.down(1367)]: {
      maxWidth: 650,
      justifyContent: 'center'
    }
  },
  cardErrors: {
    width: 344,
    margin: '0 30px 30px 0',
    padding: '13px 22px',
    border: 'solid 1px #f0f2f5',
    boxShadow: 'none',
    [theme.breakpoints.down(1367)]: {
      marginTop: 30
    }
  },
  errorTitle: {
    marginBottom: 12,
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: 1.57,
    letterSpacing: 'normal',
    color: '#0b1829'
  },
  errorText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#0b1829',
    lineHeight: 1.67,
    letterSpacing: 'normal'
  },
  proTip: {
    padding: '10px 14px',
    borderRadius: 10,
    backgroundColor: '#f4f8ff',
    fontFamily: 'Gilroy-Regular',
    fontSize: 12,
    color: '#0b1829',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    '& > span': { fontFamily: 'Gilroy-Medium' }
  }
});
