import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import styles from './Counter.styles';
import minimalStyles from './Counter.minimal.styles';
import { CustomTooltip } from '../products/commonProductsElements';

const Counter = ({ value, min, max, onChange, minimal, maxQuantityError }) => {
  const classes = minimal ? minimalStyles() : styles();

  return (
    <TextField
      value={value}
      className={classes.root}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton className={classes.button} disabled={value <= min} onClick={() => onChange(value - 1)}>
              <RemoveIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <CustomTooltip title={maxQuantityError || `You cannot exceed ${max}`} disableHoverListener={value < max}>
              <IconButton className={classes.button} disabled={value >= max} onClick={() => onChange(value + 1)}>
                <AddIcon />
              </IconButton>
            </CustomTooltip>
          </InputAdornment>
        ),
        readOnly: true,
        className: classes.input
      }}
    />
  );
};

export default Counter;
