import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import styles from './styles/ShippingHistoryList';
import Loader from '../../../global/Loader';
import ShippingHistoryItem from './ShippingHistoryItem';
import ShippingHistoryMultiItems from './ShippingHistoryMultiItems';
import { EmptyState } from '../../../shared';

const useStyles = makeStyles(styles);

const ShippingHistoryList = ({ employee, shippingHistory, isLoading }) => {
  const [visibleItems, setVisibleItems] = useState(3);
  const location = useLocation();
  const classes = useStyles();

  //! The designer will create a component with animation to unify all the scrolls of the views
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const viewMoreElements = () => setVisibleItems(perviousValue => perviousValue + 3);

  const lazyBehavior = isEnd => {
    const action = isEnd ? scrollToTop : viewMoreElements;
    const text = isEnd ? 'Scroll up!' : 'Load more orders';

    return (
      <div role="presentation" onClick={action} style={{ textAlign: 'center', cursor: 'pointer' }}>
        <p className={classes.lazyLoading}>{text}</p>
      </div>
    );
  };

  return (
    <div>
      <hr className={classes.line} />
      <p className={classes.title}>Shipping history</p>
      <div className={classes.loaderContainer}>
        {isLoading && <Loader absolute />}
        {shippingHistory?.length > 0 ? (
          <>
            <Grid container>
              <Grid item xs={12}>
                {shippingHistory.slice(0, visibleItems).map(order => {
                  if (order.products.length === 1) {
                    const { product: p, quantity } = order.products[0];
                    return (
                      <ShippingHistoryItem
                        key={order.id}
                        status={order.status}
                        date={order.date}
                        orderNumber={order.order_no}
                        tracking={order.tracking}
                        trackingUrl={order.tracking_url}
                        img={p.image}
                        color={p.theme_color}
                        quantity={quantity}
                        name={p.name}
                        extraValue={p.primary_color_requirement}
                      />
                    );
                  }
                  return (
                    <ShippingHistoryMultiItems
                      key={order.id}
                      status={order.status}
                      date={order.date}
                      orderNumber={order.order_no}
                      tracking={order.tracking}
                      trackingUrl={order.tracking_url}
                      products={order.products}
                      employee={order.employee}
                    />
                  );
                })}
              </Grid>
            </Grid>
            {lazyBehavior(shippingHistory.length <= visibleItems)}
          </>
        ) : (
          shippingHistory && (
            <>
              <EmptyState
                title="No shipment history"
                image={{
                  path: '/images/dashboard/empty-shipments.png',
                  alt: 'No shipment history',
                  text: 'This contact has not been sent any shipments yet.'
                }}
                button={{
                  link: { pathname: `/send-swag/select-products`, state: { contacts: [employee], from: location } },
                  text: 'Send Swag'
                }}
                marginTop={0}
              />
            </>
          )
        )}
      </div>
    </div>
  );
};

export default ShippingHistoryList;
