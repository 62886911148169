import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import Loader from '../../../global/Loader';
import { Modal } from '../../../shared/Modal';
import { toSentenceCase } from '../../../account/MembershipCommon';

const useStyles = makeStyles({
  text: {
    width: 400,
    margin: '20px 50px 50px',
    textAlign: 'center',
    fontSize: 24
  }
});

const ShipmentCancelModal = ({ open, onClose, onCancel, actionType = 'cancel' }) => {
  const classes = useStyles();

  const { loading } = useSelector(state => state.shipments);

  return (
    <Modal title={`${toSentenceCase(actionType)} Shipment`} open={open} onClose={onClose}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography className={classes.text}>Are you sure you want to {actionType} your shipment?</Typography>
        </Grid>
        <Grid item>
          <Button variant="primary" onClick={onCancel} style={{ width: 300 }}>
            {actionType.toUpperCase()} SHIPMENT
          </Button>
        </Grid>
      </Grid>
      {loading && <Loader />}
    </Modal>
  );
};

export default ShipmentCancelModal;
