import {
  PROCESS_FAILED,
  PROCESS_IDLE,
  PROCESS_PENDING,
  PROCESS_START,
  PROCESS_SUCCESS,
  PROCESS_SUCCESS_PROCESSED
} from '../actions/types';

export const statuses = {
  idle: 'idle',
  pending: 'pending',
  success: 'success',
  successProcessed: 'success-processed',
  error: 'error'
};

const fiveSeconds = 5000;
const ProcessReducer = (
  state = { id: null, status: statuses.idle, data: null, autoHideDuration: undefined },
  action
) => {
  switch (action.type) {
    case PROCESS_IDLE:
      return {
        ...state,
        status: statuses.idle
      };
    case PROCESS_PENDING:
      return {
        ...state,
        status: statuses.pending,
        autoHideDuration: undefined
      };
    case PROCESS_SUCCESS:
      return {
        ...state,
        status: statuses.success,
        data: action.payload,
        autoHideDuration: fiveSeconds
      };
    case PROCESS_SUCCESS_PROCESSED:
      return {
        ...state,
        status: statuses.successProcessed
      };
    case PROCESS_FAILED:
      return {
        ...state,
        status: statuses.error
      };
    case PROCESS_START:
      return {
        id: action.payload,
        status: statuses.pending,
        data: null
      };
    default:
      return state;
  }
};

export default ProcessReducer;
