import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ProofDetailsV2 from './proofDetailsV2/ProofDetails';
import ProofDetails from './proofDetails/ProofDetails';

const MockUpApprovalVersionSwitcher = () => {
  const { mockUpApproval } = useFlags();
  return mockUpApproval ? <ProofDetailsV2 /> : <ProofDetails />;
};

export default MockUpApprovalVersionSwitcher;
