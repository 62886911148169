import * as React from 'react';
import { Collapse, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Button } from '@swagup-com/components';
import { scrollBarAutohide } from '../../shared/styles/commonStyles';
import { formatColor } from './common';
import { changeColorLightness, isHexColor, isLightColor } from '../../shared/styles/utils';

const useStyles = makeStyles({
  colorLabel: {
    fontFamily: 'Gilroy',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.57,
    color: '#787b80'
  },
  selectedColor: { color: '#0b1829' },
  colorContainer: {
    display: 'flex',
    gap: 8
  },
  colorName: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: '#0b1829'
  },
  colorList: {
    maxHeight: 253,
    overflowY: 'scroll',
    ...scrollBarAutohide
  },
  colorsSet: {
    paddingTop: 8,
    paddingBottom: 18,
    paddingLeft: 5,
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 20,
    rowGap: 8
  },
  showMoreButton: {
    fontSize: 14,
    height: 24
  },
  labelContainer: {
    display: 'flex',
    columnGap: 8
  }
});

const useDotStyles = makeStyles({
  dotContainer: {
    height: 22, // add border size
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    columnGap: ({ selected }) => (selected ? 9 : 8),

    '&:hover': {
      cursor: 'pointer',
      '& p': {
        color: changeColorLightness('#3577d4')
      }
    }
  },
  dot: {
    height: ({ selected }) => (selected ? 15 : 16),
    width: ({ selected }) => (selected ? 15 : 16),
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: '50%',
    display: 'inline-block',
    paddingRight: 8,
    verticalAlign: 'sub'
  },
  selectedDot: {
    boxShadow: ({ backgroundColor }) =>
      `0px 0px 0px 1px ${
        isLightColor(backgroundColor) ? '#ced1d6' : backgroundColor
      }, 0px 0px 0px 3px white, 0px 0px 0px 5px #3577d4`
  },
  selected: {
    color: '#3577d4'
  },
  border: {
    border: ({ backgroundColor }) => `1px solid ${isLightColor(backgroundColor) ? '#ced1d6' : backgroundColor}`
  },
  colorIcon: {
    width: 16,
    height: 16,
    borderRadius: '50%'
  },
  colorIconSelected: {
    boxShadow: ' 0px 0px 0px 3px white, 0px 0px 0px 5px #3577d4'
  },
  disabled: {
    pointerEvents: 'none'
  }
});

const ColorDot = ({ backgroundColor, colorName, selected, onClick, className, disabled }) => {
  const classes = useDotStyles({ backgroundColor, selected });

  const isValidColor = isHexColor(backgroundColor);
  return (
    <div
      role="presentation"
      onClick={onClick}
      className={clsx(className, classes.dotContainer, disabled && classes.disabled)}
    >
      {isValidColor ? (
        <div className={clsx(classes.dot, selected ? classes.selectedDot : classes.border)} />
      ) : (
        <img
          src="/images/public/multicolor.png"
          alt="multicolor"
          className={clsx(selected && classes.colorIconSelected, classes.colorIcon)}
        />
      )}
      <p className={clsx(selected && classes.selected)}>{colorName}</p>
    </div>
  );
};

const numberOfColorsToFit = colors => {
  const [MAX_LINE_WIDTH, MAX_LINE_WIDTH_WITH_BUTTON, COLOR_DOT_WIDTH, MAX_LINES] = [378, 328, 44, 3];

  let [currentLineWidth, currentLines, index] = [0, 1, 0];
  while (MAX_LINES >= currentLines && index < colors.length) {
    const color = colors[index];
    const colorWidth = color.name.length * 6.4 + COLOR_DOT_WIDTH;
    const newLineWidth = currentLineWidth + colorWidth;
    if ((currentLines === MAX_LINES && newLineWidth > MAX_LINE_WIDTH_WITH_BUTTON) || newLineWidth > MAX_LINE_WIDTH) {
      [currentLineWidth, currentLines] = [colorWidth, currentLines + 1];
    } else {
      currentLineWidth = newLineWidth;
    }
    index += 1;
  }
  return (MAX_LINES < currentLines ? index - 1 : colors.length) || MAX_LINES;
};

const ColorPicker = ({ colors = [], onClick, selectedColor, disabled }) => {
  const [open, setOpen] = React.useState(false);
  const colorsToFit = numberOfColorsToFit(colors);
  const [sliceCount, setSliceCount] = React.useState(colorsToFit);

  const classes = useStyles();

  const toggleOpen = () => setOpen(prevOpen => !prevOpen);

  React.useEffect(() => {
    if (open) setSliceCount(colors.length);
    else setTimeout(setSliceCount, 200, colorsToFit);
  }, [open, colors.length, colorsToFit]);

  const remainingColors = colors.length - colorsToFit;

  return (
    <Grid item xs className={classes.colorList}>
      <Collapse in={open} collapsedSize={121}>
        <div className={classes.colorsSet}>
          {colors.slice(0, sliceCount).map(color => (
            <ColorDot
              key={color.id}
              selected={color.name === selectedColor.name}
              colorName={color.name}
              onClick={() => onClick({ name: color.name, hex: formatColor(color.hex_color) })}
              backgroundColor={formatColor(color.hex_color)}
              className={classes.colorName}
              disabled={disabled}
            />
          ))}
          {remainingColors > 0 && (
            <Button onClick={toggleOpen} variant="text" className={classes.showMoreButton}>
              {sliceCount === colors.length ? 'Show less' : `+${remainingColors}`}
            </Button>
          )}
        </div>
      </Collapse>
    </Grid>
  );
};

const ColorSection = ({ colors, selectedColor, onClick, disabled }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.labelContainer}>
        <p className={classes.colorLabel}>Item color:</p>
        <ColorDot
          colorName={selectedColor.name}
          backgroundColor={selectedColor.hex}
          disabled
          className={classes.colorName}
        />
      </div>
      <ColorPicker colors={colors} selectedColor={selectedColor} onClick={onClick} disabled={disabled} />
    </>
  );
};

export default ColorSection;
