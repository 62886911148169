export default {
  columnTitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    color: '#4a505c',
    lineHeight: 1.6,
    marginBottom: 16
  },
  card: {
    borderRadius: 15,
    border: 'solid 1px #f0f2f5',
    backgroundColor: '#ffffff'
  },
  smallText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#868a8f',
    '&.dark, & .dark': {
      color: '#0b1829'
    }
  },
  mediumText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    color: '#0b1829',
    '&.bold, & .bold': {
      fontFamily: 'Gilroy-SemiBold'
    }
  }
};
