import * as React from 'react';
import { Box } from '@material-ui/core';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ErrorBoundary } from 'react-error-boundary';
import tags from '../../../../../apis/seoTags';
import ShipmentCreation from '../shipments/ShipmentCreation';
import ShipmentCreationV2 from '../shipments/ShipmentCreationV2';
import { OrderProvider } from '../OrderContext';
import RequestDetails from './RequestDetails';
import RequestDetailsV2 from './RequestDetailsV2';
import RequestOverview from './RequestOverview';
// TODO remove this file when the entire flow will be changed
// import RequestOverview from '../../../../shipping/MultishippingOverview';
import RequestPayment from '../../../../shipping/MultiShippingPayment';
import RequestStorageOptions from '../../../../shipping/MultishippingStorageOptions';
import TopBar from '../../../../shared/TopBar';
import log from '../../../../../logger';
import { Helmet } from '../../../../shared';
import RequestOverviewOld from './RequestOverviewOld';

// This is a hack so we redirect when the user reloads
// the page in the checkout flow. Hopefully this will be replaced
// soon.
const Fallback = ({ error, resetErrorBoundary }) => {
  log.debug(error?.message);

  React.useLayoutEffect(() => {
    resetErrorBoundary();
  }, [resetErrorBoundary]);

  return null;
};

const RequestedDetailsRoot = () => {
  const { enableUnallocatedProductsManagementV2 } = useFlags();
  const history = useHistory();
  const { id } = useParams();
  const handleReset = () => history.push(`/orders-requested/${id}`);

  const { swagUpCredits } = useFlags();

  const paths = swagUpCredits
    ? [
        `/orders-requested/${id}/review-order`,
        `/orders-requested/${id}/add-funds`,
        `/orders-requested/${id}/payment-method`
      ]
    : [
        `/orders-requested/${id}/order-overview`,
        `/orders-requested/${id}/order-storage`,
        `/orders-requested/${id}/order-payment`
      ];

  const showTopBar = paths.includes(history.location.pathname);

  const ShipmentCreationComp = enableUnallocatedProductsManagementV2 ? ShipmentCreationV2 : ShipmentCreation;
  const RequestDetailsComp = enableUnallocatedProductsManagementV2 ? RequestDetailsV2 : RequestDetails;

  const routes = swagUpCredits
    ? [
        {
          id: 'review',
          component: (
            <Route
              path={[
                '/orders-requested/:id/review-order',
                '/orders-requested/:id/add-funds',
                '/orders-requested/:id/payment-method'
              ]}
              exact
            >
              <RequestOverview />
            </Route>
          )
        }
      ]
    : [
        {
          id: 'overview',
          component: (
            <Route path="/orders-requested/:id/order-overview">
              <RequestOverviewOld />
            </Route>
          )
        },
        {
          id: 'storage',
          component: (
            <Route path="/orders-requested/:id/order-storage">
              <RequestStorageOptions />
            </Route>
          )
        },
        {
          id: 'payment',
          component: (
            <Route path="/orders-requested/:id/order-payment">
              <RequestPayment />
            </Route>
          )
        }
      ];

  const { leftBarNavigation } = useFlags();

  return (
    <ErrorBoundary FallbackComponent={Fallback} onReset={handleReset}>
      <Helmet tags={tags.request} />
      <OrderProvider>
        <Box height="100%" bgcolor="#fff" {...(!leftBarNavigation ? { margin: '0px 80px' } : {})}>
          {showTopBar && <TopBar />}
          <Switch>
            {routes.map(route => route.component)}
            <Route path="/orders-requested/:id/:step/:shipmentGroupId?">
              <ShipmentCreationComp />
            </Route>
            <Route path="/orders-requested/:id">
              <RequestDetailsComp />
            </Route>
            <Redirect to="/orders-requested" />
          </Switch>
        </Box>
      </OrderProvider>
    </ErrorBoundary>
  );
};

export default RequestedDetailsRoot;
