import React from 'react';
import { FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { BlueSwitch } from '../../account/AccountSharedComponents';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CustomTextField,
  PresetTemplate,
  ProductsSelectionPanel
} from '../redeemCommon';
import styles from '../styles/redeemPagesHome';

const useStyles = makeStyles(styles);

const CollectionOnly = ({ page, setPage, onChange, setOpenProductDrawer, handleDeleteProduct, classes }) => (
  <Grid container style={{ paddingBottom: 8 }}>
    <Grid item xs={12}>
      <Accordion expanded={page.selectProduct ?? true}>
        <AccordionSummary>
          <PresetTemplate
            onSelect={() => setPage(p => ({ ...p, selectProduct: true, requireSizeSelection: true }))}
            selected={page.selectProduct}
            name="Product Select"
            subtext="Collect recipients' contact info and the product they choose."
            image="product-select"
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} className={classes.inputSection}>
              <Typography variant="body3RegularInter" className={classes.inputLabel} style={{ marginTop: 4 }}>
                Product Question<strong>*</strong>
              </Typography>
              <CustomTextField
                className={classes.inputText}
                placeholder="Product Question"
                defaultValue={page.productQuestion}
                name="productQuestion"
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body3RegularInter" className={classes.selectProductsText}>
                Select your products <span>(Limit to 4)</span>
              </Typography>
              <ProductsSelectionPanel
                products={page.productOptions}
                onAdd={page.selectProduct ? () => setOpenProductDrawer(true) : () => 1}
                onDelete={page.selectProduct ? handleDeleteProduct : () => 1}
                classes={classes}
                limit={4}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
    <Grid item xs={12} style={{ paddingTop: 12 }}>
      <Accordion expanded={!page.selectProduct}>
        <AccordionSummary>
          <PresetTemplate
            onSelect={() => setPage(p => ({ ...p, selectProduct: false }))}
            selected={!page.selectProduct}
            name="Contact Collection Only"
            subtext="Collect my recipients' contact information only."
            image="contact-collection-only"
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} className={classes.inputSectionHeader}>
              <Typography variant="body2RegularInter" className={classes.sectionLabel}>
                Form Questions:
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.inputSection} style={{ paddingRight: 28 }}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body3RegularInter" className={classes.inputLabel}>
                    Do you want to ask your recipients for their apparel size?
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <BlueSwitch
                        checked={page.requireSizeSelection}
                        onChange={({ target: { checked } }) =>
                          onChange({ target: { value: checked, name: 'requireSizeSelection' } })
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  </Grid>
);

const AutoShip = ({ page, onChange, setOpenProductDrawer, handleDeleteProduct, classes }) => (
  <Grid container style={{ paddingTop: 0 }}>
    <Grid item xs={12} className={classes.inputSection} style={{ paddingTop: 0 }}>
      <Typography variant="body3RegularInter" className={classes.inputLabel}>
        Product Question<strong>*</strong>
      </Typography>
      <CustomTextField
        className={classes.inputText}
        placeholder="Product Question"
        defaultValue={page.productQuestion}
        name="productQuestion"
        onChange={onChange}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} style={{ marginTop: 4 }}>
      <Typography variant="body3RegularInter" className={classes.selectProductsText}>
        Select your products to be auto-shipped (limit to 4)
      </Typography>
      <ProductsSelectionPanel
        products={page.productOptions}
        onAdd={() => setOpenProductDrawer(true)}
        onDelete={page.selectProduct ? handleDeleteProduct : () => 1}
        classes={classes}
        limit={4}
      />
    </Grid>
  </Grid>
);

const ProductSelection = ({ page, setPage, onChange, setOpenProductDrawer, handleDeleteProduct }) => {
  const classes = useStyles();
  return page.collectionOnly ? (
    <CollectionOnly
      page={page}
      setPage={setPage}
      onChange={onChange}
      setOpenProductDrawer={setOpenProductDrawer}
      handleDeleteProduct={handleDeleteProduct}
      classes={classes}
    />
  ) : (
    <AutoShip
      page={page}
      onChange={onChange}
      setOpenProductDrawer={setOpenProductDrawer}
      handleDeleteProduct={handleDeleteProduct}
      classes={classes}
    />
  );
};

export default ProductSelection;
