import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import toErrorPage from '../../helpers/toErrorPage';
import Transactions from './Transactions';
import { Pagination, EmptyState } from '../shared';
import { useAsync, usePagination } from '../../hooks';
import Loader from '../global/Loader';
import DashBoard from '../../apis/DashBoard';
import log from '../../logger';
import { okAndLog, errorAndLog } from '../../helpers/utils';
import apiPaths from '../../helpers/apiPaths';
import styles from './styles/commonStyles';

const useStyles = makeStyles(styles);

export const fetchTransactions = apiPath => async query => {
  log.debug('fetchTransactions Action for apiPath:', apiPath, '- Entering');

  try {
    const apiCall = await DashBoard.get(`${apiPath}?${query}`);

    const logger = apiCall.status === 200 ? okAndLog : errorAndLog;
    return logger('fetchTransactions', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('fetchTransactions', e.status, e.data);
  }
};

const perPageOptions = [12, 24, 36, 48];
const TransactionsContainer = ({
  showTitle = true,
  showLoader = true,
  type = 'transactions',
  setShowCreditsHistory
}) => {
  const classes = useStyles();
  const [total, setTotal] = useState(-1);
  const [transactions, setTransactions] = useState([]);
  const { leftBarNavigation } = useFlags();
  const { nextPage, pageIndex, perPage, sizeOptions, previousPage, changeSize } = usePagination(total, perPageOptions);
  const memoizedFetchTransactions = React.useCallback(
    fetchTransactions(type === 'transactions' ? apiPaths.transactions : apiPaths.credits),
    [type]
  );
  const [paginate, response, isPending, hasFailed] = useAsync(memoizedFetchTransactions, 250);

  const history = useHistory();

  useEffect(() => {
    if (hasFailed) toErrorPage(response, history);
  }, [hasFailed, response, history]);

  useEffect(() => {
    if (response) setTransactions(response.data.results);
  }, [response]);

  useEffect(() => {
    if (response) {
      const newTotal = response.data.count;
      if (total <= -1 && newTotal === 0) setTotal(-2);
      else if (response && newTotal !== total) setTotal(newTotal);
    }
  }, [response, total]);

  useEffect(() => {
    const query = new URLSearchParams();
    query.set('limit', perPage);
    query.set('ordering', '-created_at');

    const offset = pageIndex * perPage;
    if (offset > 0) query.set('offset', offset);

    paginate(query.toString());

    return () => paginate.cancel();
  }, [pageIndex, perPage, paginate]);

  useEffect(() => {
    if (total > 0 && type === 'credits') setShowCreditsHistory(true);
  }, [total, type, setShowCreditsHistory]);

  if (total === -1) return showLoader ? <Loader absolute /> : null;

  const [title, path, alt, text] =
    type === 'transactions'
      ? [
          'No Transactions',
          '/images/account/empty-transaction.png',
          'No transactions',
          'You don’t have any transactions.'
        ]
      : [
          'No Credits',
          '/images/account/empty-credit.png',
          'No credits',
          'Add SwagUp Credits to cover future \nSwag costs!'
        ];

  return total === -2 ? (
    <>
      {type === 'transactions' && (
        <Typography variant="h3" className={classes.title}>
          Transactions
        </Typography>
      )}
      <EmptyState
        title={title}
        image={{ path, alt, text }}
        marginTop={0}
        button={
          type === 'transactions'
            ? undefined
            : {
                link: '/billing/add-credits',
                text: 'Add Credits'
              }
        }
      />
    </>
  ) : (
    <>
      {showLoader && isPending && <Loader absolute />}
      {showTitle && !leftBarNavigation && (
        <>
          <Typography variant="h3" className={classes.title}>
            Transactions
          </Typography>
          <hr className={classes.line} />
        </>
      )}
      <Transactions transactions={transactions} showBalance={type === 'transactions'} />
      <Grid container justifyContent="center" style={{ paddingTop: 20 }}>
        <Pagination
          count={total}
          endText={type}
          perPage={perPage}
          next={nextPage}
          pageIndex={pageIndex}
          previous={previousPage}
          sizeOptions={sizeOptions}
          onPerPageChange={changeSize}
        />
      </Grid>
    </>
  );
};

export default TransactionsContainer;
