import React from 'react';

export const ProfileIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 18">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 0c1.302 0 2.401.838 2.815 2H18c1.103 0 2 .896 2 2v12c0 1.103-.897 2-2 2H2c-1.103 0-2-.897-2-2V4c0-1.104.897-2 2-2h5.185A2.993 2.993 0 0 1 10 0zM7.185 4H2v12h16.001L18 4h-5.185A2.993 2.993 0 0 1 10 6a2.993 2.993 0 0 1-2.815-2zM6 11c1.841 0 3 1.159 3 3H3c0-1.841 1.159-3 3-3zm10.01 1c.546 0 .99.443.99 1a1 1 0 0 1-.99 1h-4.02a.992.992 0 0 1-.983-.883L11 12.999a1 1 0 0 1 .99-1h4.02zM6 6a2 2 0 1 1-.001 4.001A2 2 0 0 1 6 6zm10.01 2c.546 0 .99.443.99 1a1 1 0 0 1-.99 1h-4.02a.992.992 0 0 1-.983-.883L11 8.999a1 1 0 0 1 .99-1h4.02zM10 2c-.551 0-1 .449-1 1 0 .551.449 1 1 1 .551 0 1-.449 1-1 0-.551-.449-1-1-1z"
    />
  </svg>
);

export const CompanyIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 21">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0a1 1 0 0 1 1 1v1h2a1 1 0 0 1 1 1v8h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1h2V1a1 1 0 0 1 1-1zm-1 2H9v1a1 1 0 0 1-1 1H6v8a1 1 0 0 1-1 1H2v6h6v-2h4v2h6v-6h-3a1 1 0 0 1-1-1V4h-2a1 1 0 0 1-1-1V2zM5 14v2H3v-2h2zm4 0v2H7v-2h2zm4 0v2h-2v-2h2zm4 0v2h-2v-2h2zm-8-4v2H7v-2h2zm4 0v2h-2v-2h2zM9 6v2H7V6h2zm4 0v2h-2V6h2z"
    />
  </svg>
);

export const BillingIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.628.072a1.001 1.001 0 0 1 1.267.48L15.618 4H16c1.103 0 2 .897 2 2v2h1a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-1v2c0 1.103-.897 2-2 2H2c-1.103 0-2-.897-2-2V6c0-1.103.897-2 2-2h.807zM16 6H2v12h13.997l.001-2H13c-.265 0-.52-.106-.707-.292l-3-3a1 1 0 0 1 0-1.415l3-3C12.48 8.106 12.735 8 13 8h3V6zm2 4h-4.586l-2 2 2 2H18v-4zm-4 1a1.001 1.001 0 1 1-.002 2.002A1.001 1.001 0 0 1 14 11zm-1.483-8.73L8.193 4h5.189l-.865-1.73z"
    />
  </svg>
);

export const BalanceCreditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#3577D4"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09v.58c0 .73-.6 1.33-1.33 1.33h-.01c-.73 0-1.33-.6-1.33-1.33v-.6c-1.33-.28-2.51-1.01-3.01-2.24-.23-.55.2-1.16.8-1.16h.24c.37 0 .67.25.81.6.29.75 1.05 1.27 2.51 1.27 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21v-.6c0-.73.6-1.33 1.33-1.33h.01c.73 0 1.33.6 1.33 1.33v.62c1.38.34 2.25 1.2 2.63 2.26.2.55-.22 1.13-.81 1.13h-.26c-.37 0-.67-.26-.77-.62-.23-.76-.86-1.25-2.12-1.25-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.02 1.83-1.39 2.83-3.13 3.16z"
      />
      <circle cx="20" cy="20" r="6" fill="#FFF" />
      <path fill="#3577D4" d="M23 21v1h-6v-1h6zm0-3v1h-6v-1h6z" />
    </g>
  </svg>
);

export const DownloadIcon = ({ fill = '#1D1D1D', width = '24', height = '24', viewBox = '0 0 24 24' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={fill}
        d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6-.33l2.59-2.58L17 10.5l-5 5-5-5 1.41-1.41L11 11.67V3h2v8.67z"
      />
    </g>
  </svg>
);

export const FileDownloadIcon = ({ fill = '#125CFF', width = '16', height = '17', viewBox = '0 0 16 17' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} fill="none">
    <path
      d="M12.6673 6.83334H10.0007V2.83334H6.00065V6.83334H3.33398L8.00065 11.5L12.6673 6.83334ZM3.33398 12.8333V14.1667H12.6673V12.8333H3.33398Z"
      fill={fill}
    />
  </svg>
);

export const MembershipIcon = () => (
  <svg width="20" height="20" viewBox="0 -960 960 960">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M524-274q6 0 11-2t10-7l119-119q11-11 16-24.5t5-28.5q0-32-22.5-54.5T608-532q-20 0-40 13t-44 42q-24-29-44-42t-40-13q-32 0-54.5 22.5T363-455q0 15 5 28.5t16 24.5l119 119q5 5 10 7t11 2Zm-8 194q-12 0-23-4t-21-13L97-472q-9-10-13-21t-4-23v-304q0-26 17-43t43-17h304q12 0 24 3.5t22 13.5l373 373q20 20 20 44.5T863-401L559-97q-8 8-19.5 12.5T516-80Zm0-60 304-304-376-376H140v304l376 376ZM245-664q21 0 36.5-15.5T297-716q0-21-15.5-36.5T245-768q-21 0-36.5 15.5T193-716q0 21 15.5 36.5T245-664ZM140-820Z"
    />
  </svg>
);

export const RewardArrow = () => (
  <svg width="10" height="10" viewBox="0 0 8 7">
    <path
      fill="#2BB572"
      fillRule="evenodd"
      d="M3.13397 0.500001C3.51887 -0.166666 4.48113 -0.166667 4.86603 0.5L7.4641 5C7.849 5.66667 7.36788 6.5 6.59808 6.5H1.40192C0.632124 6.5 0.150998 5.66667 0.535898 5L3.13397 0.500001Z"
    />
  </svg>
);
