import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link, useHistory } from 'react-router-dom';
import { Helmet, CenteredGrid } from '../../../../shared';
import tags from '../../../../../apis/seoTags';
import Breadcrumbs from '../../../../shared/Breadcrumbs';
import OrderSummary from './OrderSummary';
import { useOrder } from '../OrderContext';
import { buildUrlWithParam, isPack, moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import { ProductProvider, useProduct } from '../common/ProductContext';
import { productionTime as productionSpeed } from '../../../../../apis/constants';
import SizesConfiguration from '../summary/SizesConfiguration';
import ProductImage from '../common/ProductImage';
import { useQueryParams } from '../../../../../hooks';
import ProductBreakdown from '../common/ProductBreakdown';

const commonText = { fontFamily: 'Gilroy-Medium', fontSize: 12 };

const useStyles = makeStyles({
  subTotalItem: { paddingTop: 12, borderTop: '1px solid #D6D8DB' },
  backTitle: { ...commonText, color: '#0b1829' },
  currentTitle: { ...commonText, color: '#787b80' },
  separator: { fontSize: 13, marginTop: 2 },
  container: { padding: '32px 0px' },
  grey: { color: '#4A4F54' },
  arrowBack: {
    color: '#787b80',
    fontSize: 16,
    margin: '8px 7px 0px 0px'
  },
  productContainer: {
    borderRadius: 8,
    border: '1px solid #D6D8DB',
    padding: '20px 24px',
    marginBottom: 16
  }
});

const GoBack = ({ orderNo, classes }) => (
  <Grid container>
    <Link to={`/orders-requested/${orderNo}`}>
      <ArrowBack className={classes.arrowBack} />
    </Link>
    <Breadcrumbs
      separator={<span className={classes.separator}>/</span>}
      links={[
        { title: 'Back', to: `/orders-requested/${orderNo}`, styles: classes.backTitle },
        { title: `Order #${orderNo}`, to: `/orders-requested/${orderNo}`, styles: classes.currentTitle },
        { title: 'Order Review', styles: classes.currentTitle }
      ]}
    />
  </Grid>
);

const Header = () => (
  <Grid item container alignItems="center" style={{ marginBottom: 24 }}>
    <Typography variant="h5SemiBoldInter">Review your order</Typography>
  </Grid>
);

const ProductInfo = ({ product, urlToOpen, classes }) => {
  const totalQty = sumByQuantity(product.sizes);
  const subtotal = totalQty * +(product.price ?? 0);

  return (
    <>
      <Grid item container xs={12} justifyContent="space-between">
        <Typography variant="body1SemiBoldInter">{product.product.name}</Typography>
        <Typography variant="body1SemiBoldInter">{moneyStr(subtotal)}</Typography>
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" style={{ marginTop: 5 }}>
        <Typography variant="body3RegularInter" className={classes.grey}>
          Type {isPack(product.product.record_type) ? '📦 Pack' : '👕 Product'}
        </Typography>
        <Typography variant="body3RegularInter" className={classes.grey}>
          Item Count: {sumByQuantity(product.sizes)}
        </Typography>
        <Typography variant="body3RegularInter" className={classes.grey}>
          Cost: {moneyStr(product.price)} per/unit
        </Typography>
      </Grid>
      <SizesConfiguration isOrderOverview urlToOpen={urlToOpen} />
    </>
  );
};

const Product = ({ classes }) => {
  const order = useOrder();
  const { product } = useProduct();
  const history = useHistory();
  const query = useQueryParams();
  const open = +query.get('productBreakdown') === product.id;

  const urlToOpen = buildUrlWithParam(history.location, 'productBreakdown', product.id);

  const isRush = product.production_time === productionSpeed.rush;
  const totalQty = sumByQuantity(product.sizes);
  const rushFee = totalQty * +(product.rush_fee ?? 0);
  const subtotal = totalQty * +(product.price ?? 0);

  const removeOpenQueryParam = React.useCallback(() => {
    query.delete('productBreakdown');
    history.replace({ ...history.location, search: query.toString() });
  }, [query, history]);

  const productIdToOpen = +query.get('productBreakdown');

  const { products } = order;
  React.useEffect(() => {
    const productToOpen = products.find(p => p.id === productIdToOpen);
    if (!productToOpen) removeOpenQueryParam();
  }, [products, productIdToOpen, removeOpenQueryParam]);

  return (
    <Grid item container display="flex" className={classes.productContainer}>
      <Grid item style={{ width: 170 }}>
        <ProductImage product={product.product} />
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <ProductInfo product={product} urlToOpen={urlToOpen} classes={classes} />
      </Grid>
      <Grid container justifyContent="space-between" style={{ margin: '15px 0px' }}>
        <Typography variant="body2MediumInter">
          Production Time
          <Typography component="span" variant="body3RegularInter" style={{ marginLeft: 10 }}>
            {product.production_time}
            {isRush && <img src="/images/public/rush.svg" alt="Lightning Icon" style={{ marginLeft: 6, width: 8 }} />}
          </Typography>
        </Typography>
        <Typography variant="body2MediumInter">{moneyStr(isRush ? rushFee : 0)}</Typography>
      </Grid>
      <Grid container justifyContent="space-between" className={classes.subTotalItem}>
        <Typography variant="h5SemiBoldInter">Subtotal</Typography>
        <Typography variant="h5SemiBoldInter">{moneyStr(rushFee + subtotal)}</Typography>
      </Grid>
      <ProductBreakdown open={open} onClose={removeOpenQueryParam} />
    </Grid>
  );
};

const RequestOverviewOld = () => {
  const classes = useStyles();
  const order = useOrder();
  const products = order?.products ?? [];

  return (
    <div className={classes.container}>
      <CenteredGrid container>
        <Helmet tags={tags.orderReview} />
        <Grid item md={8} sm={12}>
          <GoBack orderNo={order.id} classes={classes} />
          <Header />
          <Grid item>
            {products.map(product => (
              <ProductProvider key={product.id} product={product}>
                <Product classes={classes} />
              </ProductProvider>
            ))}
          </Grid>
        </Grid>
        <Grid item md={4} sm={12}>
          <OrderSummary />
        </Grid>
      </CenteredGrid>
    </div>
  );
};

export default RequestOverviewOld;
