import React, { useState, useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { paymentMethods } from '../../apis/constants';
import { imageSrcSet } from '../../helpers/utils';
import { useIsAchPrepaid } from '../../hooks';
import ACHItem from './ACHItem';
import styles from './styles/PaymentSection';

const infoItems = [
  'Our billing team receives your request.',
  'You will receive an ACH link. Keep an eye out for an email.',
  'Complete payment using the provided ACH link.'
];

const ACHPostPayment = withStyles(styles)(({ classes }) => (
  <Grid container>
    <Grid item xs={12}>
      <img srcSet={imageSrcSet('/images/account/ach-payment.png')} alt="ach-payment" className={classes.achIcon} />
      <p className={classes.title}>Request ACH Link</p>
      <p className={classes.subtitle}>
        To send an ACH payment, click &quot;Place Order&quot;. <br />
        Our billing team will send you a link to start an ACH transaction.
      </p>
      <p className={classes.infoHeader}>How ACH works</p>
      {infoItems.map(i => (
        <p key={i} className={classes.info}>
          <span style={{ color: '#787b80' }}>&#8226;</span> {i}
        </p>
      ))}
    </Grid>
  </Grid>
));

const ACHPrePayment = ({ setContinuePaymentMethod, handleACHInfoChange, setPaymentProfileSelected }) => {
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [aCHs, setACHs] = useState(null);

  const paymentProfiles = useSelector(state => state.paymentProfiles);

  const setACHFieldState = () => handleACHInfoChange(true);

  const paymentProfileById = achId =>
    paymentProfiles.find(p => p.payment_method === paymentMethods.ach && p.customer_payment_profile_id === achId);

  const toggleSelectedBankAccount = (value, initial = false) => {
    if (selectedBankAccount === value && !initial) {
      setSelectedBankAccount(null);
      setContinuePaymentMethod(false);
    } else {
      setSelectedBankAccount(value);
      setPaymentProfileSelected(paymentProfileById(value));
      setContinuePaymentMethod(true);
    }
    setACHFieldState();
  };

  useEffect(() => {
    setContinuePaymentMethod(false);
    if (!isEmpty(paymentProfiles)) {
      const bankAccounts = paymentProfiles
        .filter(p => p.payment_method === paymentMethods.ach)
        .map(p => ({
          ...p.bank_account,
          ...{ customerPaymentProfileId: p.customer_payment_profile_id }
        }));
      setACHs(bankAccounts);
      const defaultACH = paymentProfiles.find(p => p.payment_method === paymentMethods.ach && p.default);
      if (defaultACH) toggleSelectedBankAccount(defaultACH.customer_payment_profile_id, true);
    }
    return () => setContinuePaymentMethod(true);
  }, [paymentProfiles]);

  return (
    <Grid container>
      {aCHs &&
        aCHs.map(aCH => (
          <ACHItem
            key={aCH.customerPaymentProfileId}
            aCH={aCH}
            selectedBankAccount={selectedBankAccount}
            toggleSelectedBankAccount={toggleSelectedBankAccount}
          />
        ))}
    </Grid>
  );
};

const ACH = ({ setContinuePaymentMethod, handleACHInfoChange, setPaymentProfileSelected }) => {
  const isAchPrepaid = useIsAchPrepaid();

  return isAchPrepaid ? (
    <ACHPrePayment
      setContinuePaymentMethod={setContinuePaymentMethod}
      handleACHInfoChange={handleACHInfoChange}
      setPaymentProfileSelected={setPaymentProfileSelected}
    />
  ) : (
    <ACHPostPayment />
  );
};

export default ACH;
