import React, { useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { Animated } from 'react-animated-css';
import { Button } from '@swagup-com/components';
import isEmpty from 'lodash/isEmpty';
import gtm from '../../../utils/gtm';
import { DecorationFieldEdit } from './ProofDetailsSectionsExtension';
import CustomizedSelect from './CustomizedSelect';
import { hasExtension } from './common';
import styles from './styles/itemsDecorationPanel';
import { truncateFileName } from '../../../helpers/utils';

const useStyles = makeStyles(styles);

const hasArtwork = decoration => decoration.Artwork1 && decoration.Artwork1_S3_Location;

const ActionSection = ({
  children,
  onAccept,
  onDecline,
  acceptText,
  declineText,
  declineVariant,
  oneButton,
  join,
  disabled
}) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.continueSection}>
      {!oneButton && (
        <Grid item>
          <Button
            variant={declineVariant}
            size="small"
            onClick={() => {
              onDecline();
              gtm.onClickContinue('Delete decoration clicked');
            }}
            className={classes.wizardButtons}
            disabled={disabled}
          >
            {declineText}
          </Button>
        </Grid>
      )}
      {!join && <Grid item xs />}
      <Grid item>
        {children || (
          <Button variant="primary" size="small" className={classes.wizardButtons} onClick={onAccept}>
            {acceptText}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const FileUploadZone = ({ handleFileUpload, children, disabled, renderComponent }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileUpload,
    accept: 'image/*, application/pdf, application/illustrator, application/postscript'
  });
  return (
    <Grid container {...getRootProps()} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
      <input {...getInputProps()} disabled={disabled} />
      {renderComponent ? renderComponent({ isDragActive }) : children}
    </Grid>
  );
};

const AnimatedZone = ({ animate, children, base, reverse }) => {
  const classes = useStyles();
  return (
    <div className={classes.slideInView} style={{ display: animate || base ? 'inherit' : 'none' }}>
      <Animated
        animateOnMount={false}
        animationIn={reverse ? 'slideInLeft' : 'slideInRight'}
        animationOut={reverse ? 'slideOutRight' : 'slideOutLeft'}
        isVisible={animate}
        animationInDelay={50}
        animationOutDelay={100}
        animationInDuration={200}
        animationOutDuration={150}
        className={classes.fullArea}
      >
        <div className={classes.fullArea}>{children}</div>
      </Animated>
    </div>
  );
};

const ArtworkDisplay = ({ decoration }) => {
  const classes = useStyles();
  const nonImage = hasExtension(decoration.Artwork1, ['.pdf', '.eps', '.ai']);
  return (
    <Grid container alignItems="center">
      <Grid item>
        <div className={classes.logoContainer}>
          <img
            src={nonImage ? '/images/public/unknown-file.svg' : decoration.Artwork1_S3_Location}
            alt="swagup"
            className={classes.logo}
          />
        </div>
      </Grid>
      <Grid item xs>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <p className={classes.logoFileName}>{`${truncateFileName(decoration.Artwork1, 22)}  `}</p>
          </Grid>
          <Grid item className={classes.newWindow}>
            <a href={decoration.Artwork1_S3_Location} target="_blank" rel="noopener noreferrer">
              <img src="/images/public/open-new-window.svg" alt="swagup" className={classes.logo} />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ItemsDecorationPanel = ({
  item,
  decoration,
  onChange,
  onContinue,
  onDelete,
  getDecorationName,
  lastOne,
  disabled
}) => {
  const [uploadWarning, setUploadWarning] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [localDecoration, setLocalDecoration] = useState(decoration);

  const classes = useStyles({ disabled, uploadWarning, deleteWarning });

  const handleFileUpload = acceptedFiles => {
    const image = acceptedFiles[0];
    const filePath = URL.createObjectURL(image);

    if (image) {
      const name = image.name.replace(/\s+/g, '-');
      setLocalDecoration(prev => ({
        ...prev,
        artwork: { image, fileName: name },
        Artwork1: name,
        Artwork1_S3_Location: filePath
      }));
    }

    setUploadWarning(!hasExtension(image.name, ['.svg', '.eps', '.ai']));
  };

  const handleTextChange = ({ target: { name, value } }) => setLocalDecoration(prev => ({ ...prev, [name]: value }));

  return (
    <div className={classes.panelBody}>
      {!deleteWarning && (
        <div>
          <p className={classes.header}>{getDecorationName(localDecoration)}</p>
        </div>
      )}
      <AnimatedZone animate={!uploadWarning && !deleteWarning} base>
        <>
          <Grid container alignItems="center" style={{ height: '100%' }}>
            {hasArtwork(localDecoration) ? (
              <>
                <Grid item xs>
                  <p className={classes.logoName}>
                    Artwork for {truncateFileName(item ? item.name : 'Product', 38, true)}
                  </p>
                </Grid>
                <Grid item>
                  <FileUploadZone handleFileUpload={handleFileUpload} disabled={disabled}>
                    <p className={classes.replace}>Replace</p>
                  </FileUploadZone>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <ArtworkDisplay decoration={localDecoration} />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container>
                <p className={classes.logoName} style={{ marginBottom: 8 }}>
                  Artwork for {item ? item.name : 'Product'}
                </p>
                <FileUploadZone
                  handleFileUpload={handleFileUpload}
                  disabled={disabled}
                  renderComponent={props => (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={classes.ImageUploadContainer}
                      style={{ border: props?.isDragActive ? '1px dotted #3577d4' : undefined }}
                    >
                      <Grid item>
                        <Grid container justifyContent="center" className={classes.iconWrapper}>
                          <img src="/images/public/file-upload.svg" alt="upload" className={classes.icon} />
                        </Grid>
                        <p className={classes.uploadText}>
                          Drag & Drop to upload or{' '}
                          <span className={classes.link} style={{ color: disabled ? '#787b80' : undefined }}>
                            Choose a file
                          </span>
                        </p>
                        <p className={classes.fileFormats}>The file should end in: .svg, .eps, .ai, .pdf.</p>
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
            )}
            {!isEmpty(item.available_imprint_options) && (
              <div className={classes.decorationEditSection}>
                <p className={classes.imprintType}>Imprint Type</p>
                <CustomizedSelect
                  value={localDecoration.Imprint_Type}
                  items={item.available_imprint_options}
                  onChange={value => setLocalDecoration(prev => ({ ...prev, Imprint_Type: value }))}
                  disabled={disabled}
                />
              </div>
            )}
            {!isEmpty(localDecoration?.Colors) && (
              <div className={classes.decorationEditSection}>
                <p className={classes.imprintType}>Colors Used In This Decoration</p>
                <div className={classes.colorList}>
                  {localDecoration?.Colors?.map(color => (
                    <p key={color}>{color}</p>
                  ))}
                </div>
              </div>
            )}

            <DecorationFieldEdit
              decoration={localDecoration}
              name="Location"
              placeholder="Specify the placement of your artwork"
              onChange={handleTextChange}
              editable={localDecoration.created}
            />
            <DecorationFieldEdit
              decoration={localDecoration}
              name="Dimensions"
              placeholder="W 2.3’ x H 4.5’"
              tip={localDecoration.created && 'Don’t worry if you don’t know exact dimensions'}
              onChange={handleTextChange}
              editable={localDecoration.created}
            />
            {decoration.created && (
              <div className={classes.notesWrapper}>
                <DecorationFieldEdit
                  multiline
                  decoration={localDecoration}
                  name="Notes"
                  placeholder="Notes"
                  label="Additional Notes"
                  onChange={handleTextChange}
                  editable
                  compactStyle
                  maxLength={500}
                />
              </div>
            )}
            <Box width="100%" height={56} />
            <ActionSection
              classes={classes}
              oneButton={Object.keys(decoration).length === 3 || lastOne}
              onAccept={() => {
                onChange(localDecoration);
                onContinue();
              }}
              onDecline={() => setDeleteWarning(true)}
              declineText="Delete Decoration"
              acceptText="Continue"
              disabled={disabled}
            />
          </Grid>
        </>
      </AnimatedZone>
      <AnimatedZone animate={uploadWarning}>
        <div>
          <p className={classes.uploadNonSvgTitle}>You’ve uploaded a non-vector art file.</p>
          <p className={classes.uploadNonSvgExcerpt} style={{ marginBottom: 8 }}>
            Please upload a vector file (AI, SVG or EPS) instead.
          </p>
          <p className={classes.uploadNonSvgExcerpt}>
            If you don’t have one, our designers can convert your current file (results may vary).
          </p>
          <ActionSection onDecline={() => setUploadWarning(false)} declineText="Continue with this file">
            <FileUploadZone handleFileUpload={handleFileUpload}>
              <Button variant="primary" size="small" className={classes.wizardButtons}>
                Upload a vector file
              </Button>
            </FileUploadZone>
          </ActionSection>
        </div>
      </AnimatedZone>
      <AnimatedZone animate={deleteWarning}>
        <div>
          <p className={classes.deleteWarningTitle}>{`Are you sure you want to delete ${getDecorationName(
            localDecoration
          )}?`}</p>
          <p className={classes.uploadNonSvgExcerpt}>
            Deleting this decoration will send our designers a notification to review this item and create a replacement
            design if necessary.
          </p>
          <ActionSection
            onAccept={() => {
              onDelete(localDecoration.id);
              onContinue();
            }}
            onDecline={() => setDeleteWarning(false)}
            declineVariant="text"
            declineText="Cancel"
            acceptText="Yes, Delete"
            join
          />
        </div>
      </AnimatedZone>
    </div>
  );
};

export default ItemsDecorationPanel;
