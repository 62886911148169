import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Box, Grid, makeStyles } from '@material-ui/core';
import SendSwagConfigurationSize from './SendSwagConfigurationSize';
import SendSwagConfigurationProduct from './SendSwagConfigurationProduct';
import styles from './styles/automationActionSelectionModal';
import SendSwagConfigurationDM from './SendSwagConfigurationDM';
import SendSwagConfigurationFinish from './SendSwagConfigurationFinish';
import StoreConfigurationFinish from './StoreConfigurationFinish';
import RedeemLinkConfigurationFinish from './RedeemLinkConfigurationFinish';
import ActionConfigurationTiming from './ActionConfigurationTiming';

const useStyles = makeStyles(styles);

const RedeemLikConfiguration = ({ action, onActionUpdate }) => {
  return (
    <Box>
      <Typography variant="body3SemiBoldInter">Select the page you want to send a redeem link for</Typography>
    </Box>
  );
};

const ShopCreditsConfiguration = ({ action, onActionUpdate }) => {
  return (
    <Box>
      <Typography variant="body3SemiBoldInter">Select the shop you want to send credit for</Typography>
    </Box>
  );
};

const AutomationConfigurationSixth = ({ action, automation, onActionUpdate, isEmployeeTypesEmpty }) => {
  const sixthActionResolver = actionType => {
    switch (true) {
      case actionType === 'send_swag':
        return isEmployeeTypesEmpty ? (
          <SendSwagConfigurationFinish action={action} automation={automation} />
        ) : (
          <ActionConfigurationTiming action={action} automation={automation} onActionUpdate={onActionUpdate} />
        );
      case actionType === 'redeem_link':
        return <RedeemLinkConfigurationFinish action={action} automation={automation} />;
      case actionType === 'shop_credits':
        return <StoreConfigurationFinish action={action} automation={automation} />;
      default:
        return <ShopCreditsConfiguration action={action} automation={automation} />;
    }
  };

  const classes = useStyles();

  return (
    <Box>
      <Box>{sixthActionResolver(action?.type)}</Box>
    </Box>
  );
};

export default AutomationConfigurationSixth;
