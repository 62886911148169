import React from 'react';
import BackgroundCircleCompany from './BackgroundCircleCompany';

const BackgroundLight = () => {
  return (
    <div>
      <BackgroundCircleCompany size="30%" color="#134283" top="-10%" left="80%" outlined invisible />
      <BackgroundCircleCompany size="11%" color="#9846dd" top="50%" right="12%" invisible />
      <BackgroundCircleCompany size="5%" color="#134283" top="48%" right="-3%" invisible />
      <BackgroundCircleCompany size="11%" color="#134283" top="63%" left="-8%" invisible />
      <BackgroundCircleCompany size="3%" color="#134283" top="82%" left="6%" invisible />
    </div>
  );
};

export default BackgroundLight;
