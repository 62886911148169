import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory, useParams, useLocation } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, Typography, Tabs, Tab } from '@swagup-com/components';
import { Link, CenteredGrid, Alert } from '../../shared';
import RecentShipments from './recentShipments/RecentShipments';
import { InvalidShipmentNotification } from '../../icons/shipments';
import { SelectionProvider, SelectionContext } from './contacts/SelectionContext';
import { setCurrentContactGroup, toggleSelectFromExisting } from '../../../actions/shipmentGroupActions';
import { useQueryFilterValidated } from '../../../hooks/useFilters';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: 20
    }
  },
  content: {
    paddingBottom: 32,
    position: 'relative',
    minHeight: 'calc(100vh - 389px)'
  },
  tabs: {
    height: 36.5,
    marginLeft: -24,
    '& .MuiTab-root': {
      minWidth: 80,
      padding: '6px 19px'
    }
  },
  tab: {
    maxHeight: 24.49,
    minHeight: 24.49
  },
  infoMessage: {
    position: 'absolute',
    width: 230,
    height: 44,
    padding: '12px 20px',
    '& .MuiAlert-message': { padding: 0 },
    '& .MuiAlert-action': { paddingLeft: 0 }
  }
}));

const ShipmentsHome = () => {
  const [[selectedContacts], setSelectedContacts] = useState([new Map()]);
  const [showSendSwag, setShowSendSwag] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(true);
  const { haveInvalidAddress } = useSelector(state => state.shipments);
  const { isSelectingFromExisting } = useSelector(state => state.shipmentGroup);

  const { view } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { infoMessage } = location.state || {};
  const classes = useStyles();

  const dispatch = useDispatch();
  const search = useQueryFilterValidated('search', () => true);
  const groupIds = useQueryFilterValidated('groups');
  const sendSwag = (excluded, areAllSelected) => {
    if (!isSelectingFromExisting) dispatch(toggleSelectFromExisting());
    if (areAllSelected) dispatch(setCurrentContactGroup({ search, groupIds, excluded }));
    history.push({
      pathname: `/send-swag/select-products`,
      state: {
        contacts: !areAllSelected ? [...excluded.values()] : undefined,
        from: location
      }
    });
  };

  const sendSwagOld = () =>
    history.push({
      pathname: `/send-swag/select-products`,
      state: {
        contacts: [...selectedContacts.values()],
        from: location
      }
    });

  const handleClose = () => {
    setShowInfoMessage(false);
    history.replace({ ...location, state: { ...location.state, infoMessage: undefined } });
  };

  const sendSwagText = num => `Send Swag${num > 0 ? ` (${num})` : ''}`;
  const { leftBarNavigation } = useFlags();

  return (
    <>
      {infoMessage && showInfoMessage && (
        <Grid container justifyContent="center">
          <Alert
            onClose={handleClose}
            delayTime={10000}
            className={classes.infoMessage}
            fontStyles={{ fontSize: 14, padding: 0 }}
          >
            {infoMessage}
          </Alert>
        </Grid>
      )}
      <SelectionProvider>
        <CenteredGrid className={classes.root} style={{ paddingTop: leftBarNavigation ? 0 : 40 }}>
          {/* <Tabs value={view === 'contacts' ? 1 : 0} className={classes.tabs}>
            {!leftBarNavigation && (
              <Tab
                label="Shipments"
                component={Link}
                to="/shipments"
                icon={haveInvalidAddress && <InvalidShipmentNotification />}
                className={classes.tab}
              />
            )}
          </Tabs> */}
          <div className={clsx(classes.content, 'fade-translate')} key={view}>
            <Switch>
              <Route path="/shipments" exact>
                <RecentShipments setExistShipments={setShowSendSwag} />
              </Route>
            </Switch>
          </div>
        </CenteredGrid>
      </SelectionProvider>
    </>
  );
};

export default ShipmentsHome;
