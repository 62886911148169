import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, InputAdornment, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Button, MenuItem, Typography } from '@swagup-com/components';
import { AttachMoney, CancelRounded as CancelIcon, CheckCircle as CheckIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import { isNumber } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/creditDrawer';
import { cardImg, moneyStr, creditColor } from '../../../../helpers/utils';
import { CustomTextField, NumberFormatCustom, RedeemCustomSelectField } from '../../redeemCommon';
import apiPaths from '../../../../helpers/apiPaths';
import { getPaymentProfiles, setDefaultPaymentProfile } from '../../../../actions';
import toErrorPage from '../../../../helpers/toErrorPage';
import { useCreditActions, useCreditSummary } from '../../../../hooks';
import { defaultErrorMsg } from '../../../../utils/errors';
import BenefitTab from '../../../global/BenefitTab';
import { useMembership } from '../../../../contexts/membershipContext';

const useStyles = makeStyles(styles);

const CardItem = ({ card, classes, isDefault }) => (
  <Grid container alignItems="center" spacing={6}>
    <Grid item>
      <div className={classes.cardImageWrapper}>
        <img src={cardImg(card.card_type)} alt="card" className={classes.cardImage} />
      </div>
    </Grid>
    <Grid item>
      <Typography variant="body2RegularInter" className={classes.cardNumber}>
        <span>{'•••• '}</span>
        {card.card_number?.replaceAll('X', '')}
      </Typography>
    </Grid>
    <Grid item>
      {isDefault && (
        <Typography variant="body2RegularInter" className={classes.isDefault}>
          default
        </Typography>
      )}
    </Grid>
  </Grid>
);

const CreditDrawerAddCredits = ({ company, history, currentStep, recommendedCredits, onAddPaymentMethod }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('add');
  const [selectedCredit, setSelectedCredit] = useState();
  const [isValidForm, setIsValidForm] = useState(false);
  const { data: creditSummary, isLoading } = useCreditSummary();
  const { addCredits } = useCreditActions();
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const queryClient = useQueryClient();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const dispatch = useDispatch();
  const { data } = useQuery(
    [apiPaths.paymentProfiles, company?.id, currentStep],
    () => dispatch(getPaymentProfiles(company?.id)),
    {
      enabled: !isEmpty(company),
      onError: error => toErrorPage(error, history)
    }
  );

  const paymentProfiles = data?.data || [];

  const defaultPaymentProfile = useMutation(
    ({ accountId, profileId, isDefault = true }) => dispatch(setDefaultPaymentProfile(accountId, profileId, isDefault)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([apiPaths.paymentProfiles, company?.id]);
      },
      onError: () => setIsValidForm(false)
    }
  );

  useEffect(() => {
    const defaultPM = paymentProfiles.find(p => p.default)?.customer_payment_profile_id;
    if (defaultPM) setSelectedPaymentMethod(defaultPM);
  }, [paymentProfiles]);

  useEffect(() => {
    setIsValidForm(selectedCredit > 0 && selectedPaymentMethod && selectedPaymentMethod !== 'add');
  }, [selectedCredit, selectedPaymentMethod]);

  const handlePaymentMethodSelection = async value => {
    if (value === 'add') return onAddPaymentMethod();
    return setSelectedPaymentMethod(value);
  };

  const handleAddCredit = async () => {
    const amount = selectedCredit;
    try {
      defaultPaymentProfile.mutateAsync({ accountId: company.id, profileId: selectedPaymentMethod }).then(() =>
        addCredits.mutateAsync({ amount }).then(() => {
          setSelectedCredit();
          setShowSnackbar(true);
          setWasSuccessful(true);
        })
      );

      return undefined;
    } catch (e) {
      setShowSnackbar(true);
      setWasSuccessful(false);
      return e.message || defaultErrorMsg(e.status);
    }
  };

  const classes = useStyles();
  const { menuPaper, menuItem } = classes;
  const selectClasses = { menuPaper, menuItem };
  const { currentMembership } = useMembership();
  const { earlyBirdPromotion } = useFlags();
  return (
    <Grid container direction="column" className={classes.viewContent}>
      <Grid item className={classes.titleContainer}>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setShowSnackbar(false);
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {wasSuccessful ? (
            <Alert
              icon={<CheckIcon style={{ color: '#2E7D32', fontSize: '20px' }} />}
              variant="outlined"
              severity="success"
            >
              Credit successfully added
            </Alert>
          ) : (
            <Alert
              icon={<CancelIcon style={{ color: '#C62828', fontSize: '20px' }} />}
              variant="outlined"
              severity="error"
            >
              Credit card failed to be verified
            </Alert>
          )}
        </Snackbar>
        <Typography variant="h5SemiBoldInter" className={classes.cardTitle}>
          Reload Credit Balance
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid container alignItems="center" className={classes.summaryContainer}>
          <Grid item>
            <Typography variant="body2RegularInter" className={classes.creditBalanceWarning}>
              Current Credit Balance
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Typography
              variant="h4SemiBoldInter"
              className={classes.creditBalance}
              style={{ color: creditColor(creditSummary.current_balance) }}
            >
              {moneyStr(creditSummary.current_balance)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Typography variant="body2SemiBoldInter" className={classes.addMoneyTitle}>
            Add credits to your Account
          </Typography>
          <Typography variant="body3RegularInter" className={classes.addMoneyDescription}>
            Set budgets for your shipping costs and make future shipments seamless with credits. Tired of managing
            credits? Sign up{' '}
            <a href="https://www.promotions.swagup.com/topup-submission-form" target="_blank" rel="noreferrer">
              here
            </a>
            .
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.inputSection}>
            <Grid container alignItems="flex-end">
              <Grid item xs>
                <Typography variant="body3RegularInter" className={classes.inputLabel}>
                  Amount to add
                </Typography>
              </Grid>
              <Grid item style={{ paddingBottom: 4 }}>
                {(!currentMembership?.isFreeTier || earlyBirdPromotion) && (
                  <BenefitTab
                    earlyBirdPromotion={earlyBirdPromotion ? currentMembership : undefined}
                    icon="/images/membership/rewards.png"
                    text={`Reward Points ${currentMembership.reward_points_rate}X`}
                    value={Math.ceil(
                      (parseFloat(selectedCredit) > 0 ? parseFloat(selectedCredit) : 0) *
                        parseInt(currentMembership.reward_points_rate, 10)
                    )}
                    type="reward"
                    alone
                  />
                )}
              </Grid>
            </Grid>
            <CustomTextField
              placeholder="Enter credit amount"
              name="amount"
              fullWidth
              value={selectedCredit}
              onChange={e => {
                return !isEmpty(e.target.value) && isNumber(parseFloat(e.target.value))
                  ? setSelectedCredit(parseFloat(e.target.value).toFixed(2))
                  : setSelectedCredit();
              }}
              onBlur={e => (e.target.value = selectedCredit)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoney />
                  </InputAdornment>
                ),
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body4RegularInter" className={classes.recommendedCreditText}>
              Recommended credits based on selected inventory: {moneyStr(recommendedCredits)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.paymentMethodSection}>
          <Grid item xs={12}>
            <Typography variant="body3RegularInter" className={classes.inputLabel}>
              Payment Method
            </Typography>
            <RedeemCustomSelectField
              value={selectedPaymentMethod}
              className={classes.selectField}
              classes={selectClasses}
              totalItems={(paymentProfiles.length || 0) + 1}
              handleSelect={({ target: { value } }) => handlePaymentMethodSelection(value)}
              fullWidth
              withTooltip
              native
            >
              <MenuItem
                key="add"
                value="add"
                title="Add"
                onClick={paymentProfiles.length < 1 ? () => handlePaymentMethodSelection('add') : undefined}
              >
                <CardItem card={{ card_number: 'Add credit or debit card' }} classes={classes} />
              </MenuItem>
              {paymentProfiles
                .filter(pp => pp.credit_card)
                .map(pp => {
                  const { customer_payment_profile_id: customerPaymentProfileId, credit_card: creditCard } = pp;
                  return (
                    <MenuItem
                      key={customerPaymentProfileId}
                      value={customerPaymentProfileId}
                      title={customerPaymentProfileId}
                    >
                      <CardItem card={creditCard} isDefault={pp.default} classes={classes} />
                    </MenuItem>
                  );
                })}
            </RedeemCustomSelectField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            <Button
              type="submit"
              variant="primary"
              size="small"
              disabled={selectedCredit < 1 || !isValidForm || isLoading || defaultPaymentProfile.isLoading}
              onClick={handleAddCredit}
              loading={addCredits.isLoading || defaultPaymentProfile.isLoading}
            >
              Add Credit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreditDrawerAddCredits;
