import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = theme => ({
  container: {
    // background: '#131415',
    overflow: 'hidden',
    borderRadius: 6,
    marginBottom: 24,
    height: 100,
    [theme.breakpoints.down('xs')]: {
      height: 200
    }
  },
  birdImageWrapper: {
    height: 100,
    width: 100,
    overflow: 'visible',
    position: 'relative'
  },
  birdImg: {
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  },
  christmasPromo: {
    paddingLeft: 32,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16
    }
  },
  christmasPromoText: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: ({ catalog }) => (catalog ? 42 : 56),
    textTransform: 'uppercase',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '62px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px !important',
      fontWeight: 500,
      lineHeight: '36px'
    }
  },
  christmasPromoSubText: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: ({ catalog }) => (catalog ? 22 : 32),
    textTransform: 'uppercase',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '44px',
    marginLeft: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px !important',
      fontWeight: 500,
      lineHeight: '28px',
      marginLeft: 0
    }
  },
  christmasPromoDealText: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    textAlign: 'center',
    fontSize: 16,
    textTransform: 'uppercase',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px'
    }
  },
  membershipCTA: {
    background: '#131415',
    height: '36px',
    border: 'none',
    fontFamily: 'Inter',
    '& span': {
      color: '#ffffff'
    },
    '&:hover': {
      background: '#434445'
    },
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  noMembershipCTA: {
    height: ' 50px',
    background: '#125CFF',
    border: 'none',
    fontFamily: 'Inter',
    ffontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  dealBox: {
    height: 100
  },
  snowflakeWrapper: {
    position: 'absolute'
  },
  snowflakeImage: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
    display: ({ noDesktop }) => (noDesktop ? 'none' : 'inherit'),
    [theme.breakpoints.down('xs')]: {
      display: 'inherit !important'
    }
  },
  snowflakeImageNoMobile: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  closeIconButtonOnMobile: {
    padding: 6,
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inherit'
    }
  },
  closeIconButton: {
    padding: 6,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  closeIcon: {
    color: '#FFFFFF',
    height: 18,
    width: 18
  },
  greenBox: {
    background: '#78C480',
    position: 'relative'
  },
  blueBox: {
    background: '#2663FF',
    paddingLeft: 24,
    position: 'relative'
  }
});

export default styles;
