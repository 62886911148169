/* eslint-disable camelcase */
import React, { useReducer, useRef, useState, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  IconButton,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  TableHead,
  TableCell,
  withStyles,
  TableRow
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import { Button, Tooltip, Typography as CustomTypography } from '@swagup-com/components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import dayjs from 'dayjs';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { CheckedIcon, UncheckedIcon } from '../icons';
import { calculateTimeRemaining, changeInputValue, formatDate, isPack, moneyStr } from '../../helpers/utils';
import { reorderProductDisabledCopy } from '../../helpers/helperConstants';
import apiPaths from '../../helpers/apiPaths';
import accountProductsApi from '../../apis/swagup/accountProducts';
import log from '../../logger';
import styles from './styles/InventoryDisplayTool';
import { ImgWithHandler } from '../global/ImgUtils';
import { useQueryParams } from '../../hooks';
import { useMembership } from '../../contexts/membershipContext';
import DiscountBagde from '../global/DiscountBadge';
import StrikeOutText from '../global/StrikeOutCost';
import useMembershipCheck from '../../hooks/useMembershipCheck';
import { ImageDialog } from '../opportunities/proofDetailsV2/ProofDetailsSectionsExtension';

const productImageTitle = enabled => (enabled ? 'Order again' : reorderProductDisabledCopy);

const useStyles = makeStyles(styles);

const ProductImage = ({ item, name, imgSrc, title, width, height, linkTo }) => {
  const img = <ImgWithHandler src={imgSrc} alt={name} title={title} width={width} height={height} />;

  return (
    <div style={{ width, height }}>
      {item?.enabled && linkTo ? (
        <Link to={linkTo} style={{ display: 'inline-flex' }}>
          {img}
        </Link>
      ) : (
        img
      )}
    </div>
  );
};

const ProductNameInput = ({ item, blue, readOnly }) => {
  const classes = useStyles({ blue });
  const [newProductName, setNewProductName] = useState('');
  const [isEditing, toggleIsEditing] = useReducer(prev => !prev, false);
  const shouldUpdateProductName = useRef(true);

  const queryClient = useQueryClient();
  const { mutate: editName } = useMutation(
    [apiPaths.accountProducts, { ids: item.id }],
    name => {
      if (name !== item.name) accountProductsApi.editName({ prodId: item.id, name });
    },
    { onSuccess: () => queryClient.invalidateQueries([apiPaths.accountProducts]) }
  );

  const handleChangeProductName = ({ target: { value } }) => setNewProductName(value);

  const handleKeyDownProductName = event => {
    if ([27, 13].includes(event.keyCode)) {
      shouldUpdateProductName.current = event.keyCode === 13;
      event.target.blur();
    }
  };

  const handleOnBlur = event => {
    const trimmedName = newProductName.trim();
    const mustUpdateProductName =
      shouldUpdateProductName.current && trimmedName.length > 0 && trimmedName !== item.name;

    if (mustUpdateProductName) {
      log.debug(`about to update product name to: ${trimmedName}`);
      editName(trimmedName);
    } else {
      shouldUpdateProductName.current = true;
    }
    const newInputValue = mustUpdateProductName ? trimmedName : item.name;
    if (event.target.value !== newInputValue) {
      changeInputValue(event.target, newInputValue);
    }
    toggleIsEditing();
  };

  return readOnly ? (
    <p className={classes.showProductName}>{item.name}</p>
  ) : (
    <input
      type="text"
      id={item.id}
      defaultValue={item.name}
      className={isEditing ? classes.editProductName : classes.showProductName}
      onChange={handleChangeProductName}
      onKeyDown={handleKeyDownProductName}
      onFocus={toggleIsEditing}
      onBlur={handleOnBlur}
      maxLength={80}
    />
  );
};

const ProductColor = ({ item, blue }) => {
  const classes = useStyles({ blue, color: item.theme_color_hex ? `#${item.theme_color_hex}` : 'transparent' });

  return (
    <Grid container alignItems="center">
      <Typography variant="subtitle2" className={classes.text}>
        Color:
      </Typography>
      <span className={classes.colorText}>{item.theme_color || 'Custom'} </span>
      <div className={classes.colorCircle} />
    </Grid>
  );
};

const ProductColorV2 = ({ item }) => {
  const classes = useStyles({ color: `#${item?.theme_color_hex}` });

  return (
    <Grid container alignItems="center">
      <CustomTypography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
        Color: {item.theme_color || 'Custom'}
      </CustomTypography>
      {item.theme_color_hex && <div className={classes.colorCircle} style={{ border: '1px solid #B7BBBF' }} />}
    </Grid>
  );
};

const selectionMarkTitle = selected => (selected ? null : 'Select to REORDER');

const SelectionMark = ({ selected, title, onClick }) => (
  <IconButton onClick={onClick} title={title} size="small">
    {selected ? <CheckedIcon /> : <UncheckedIcon />}
  </IconButton>
);

const getSizeName = (name, quantity) => {
  if (name === 'One Size') return quantity === 0 ? 'Inventory' : 'One Size';
  return name;
};

const ProductQuantities = ({ item, blue, list, newInventory = false }) => {
  const classes = useStyles({ blue, list, newInventory });
  const isBetweenSmAndLg = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));
  const bigCardsWidth = isBetweenSmAndLg ? 63 : 58;
  const listWidth = list ? 80 : bigCardsWidth;

  return (
    item?.stock?.map(qps => {
      const sizeNameValue = getSizeName(qps.size.name, qps.quantity);
      const width = ['One Size', 'Inventory'].includes(sizeNameValue) ? '100%' : listWidth;
      const [sizeNameColor, quantityColor] =
        qps.quantity === 0 ? ['#989EA4', '#989EA4'] : ['inherit', blue ? 'white' : '#0f2440'];

      const sizeName = <span style={{ color: sizeNameColor }}>{sizeNameValue}</span>;
      const quantity = <span style={{ color: quantityColor, marginLeft: 2 }}>{qps.quantity}</span>;

      return (
        <Typography
          key={qps.size.id}
          variant={newInventory ? 'body3RegularInter' : 'subtitle2'}
          className={classes.quantityText}
          style={{ width }}
        >
          {sizeName}:{quantity}
          {!newInventory && list && qps.quantity < qps.minimum && <ArrowDropDownIcon style={{ marginBottom: 2 }} />}
        </Typography>
      );
    }) || <></>
  );
};

const SendSwagButton = ({ item, className, href }) => {
  const location = useLocation();
  const isDisabled = item.stock.reduce((acc, size) => acc + size.quantity, 0) === 0;
  const query = useQueryParams();

  React.useEffect(() => {
    query.set('selected', item.id);
  }, [item, query]);

  return (
    <Button
      size="small"
      component={Link}
      disabled={isDisabled}
      to={{
        pathname: href || '/send-swag/select-products',
        search: href ? undefined : query.toString(),
        state: href ? {} : { from: location, item }
      }}
      className={className}
    >
      Send Swag
    </Button>
  );
};

const getSelectedSearchParam = ids => {
  const query = new URLSearchParams();
  query.set('selected', ids);
  return query.toString();
};

const useReorderLink = ids => {
  const location = useLocation();
  return {
    pathname: '/reorder/select-products',
    search: getSelectedSearchParam(ids),
    state: { from: location }
  };
};

const CustomTableRow = withStyles({
  root: {
    borderBottom: '1px solid #E5E7E8',
    position: 'relative',
    backgroundColor: '#ffffff',
    '&:hover': { backgroundColor: 'transparent' },
    '&.Mui-selected, &.Mui-selected:hover': { backgroundColor: 'rgba(53, 119, 212, 0.08) !important' }
  }
})(TableRow);

const StorageChargesTableHeader = ({ classes, item }) => {
  const isPackOrProduct = isPack(item?.record_type) ? 'Pack' : 'Product';
  return (
    <TableHead>
      <CustomTableRow>
        <TableCell className={classes.cellCenter} scope="row">
          <CustomTypography variant="body4MediumInter" style={{ color: '#989EA4' }}>
            ADDED ON
          </CustomTypography>
        </TableCell>
        <TableCell scope="row" className={classes.cellCenter}>
          <CustomTypography variant="body4MediumInter" style={{ color: '#989EA4' }}>
            LAST PAYMENT DATE
          </CustomTypography>
        </TableCell>
        <TableCell scope="row" className={classes.cellCenter}>
          <CustomTypography variant="body4MediumInter" style={{ color: '#989EA4' }}>
            NEXT PAYMENT DATE
          </CustomTypography>
        </TableCell>
        <TableCell scope="row" width="35%" className={classes.cellCenter}>
          <CustomTypography variant="body4MediumInter" style={{ color: '#989EA4' }}>
            INVENTORY LEFT
          </CustomTypography>
        </TableCell>
        <TableCell scope="row" width="16%" className={classes.cellCenter}>
          <Grid container justifyContent="center" alignItems="center" style={{ gap: 5 }}>
            <Grid>
              <CustomTypography variant="body4MediumInter" style={{ color: '#989EA4' }}>
                PENDING STORAGE COST
              </CustomTypography>
            </Grid>
            <Grid style={{ color: '#989EA4', cursor: 'pointer', marginTop: 5 }}>
              <Tooltip
                placement="top-center"
                arrow
                title={`This is the pending storage cost for this ${isPackOrProduct}, incl sales tax. When due, we'll charge your Swag Card Balance for the storage cost.`}
                classes={classes}
              >
                <InfoOutlinedIcon style={{ width: 13, height: 13 }} />
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell scope="row" className={classes.cellCenter}>
          <CustomTypography variant="body4MediumInter" style={{ color: '#989EA4' }}>
            TIME BEFORE CHARGE
          </CustomTypography>
        </TableCell>
      </CustomTableRow>
    </TableHead>
  );
};

const dueDateStatuses = {
  normal: 'normal',
  warning: 'warning',
  alert: 'alert'
};

const StorageInventoryLeft = ({ item, product, classes }) => {
  const getRemainingQuantity = name =>
    item.sizes.reduce((sum, s) => sum + (s.size === name ? s.remainingQuantity : 0), 0);

  return (
    <>
      {product.stock.map(({ size }) => (
        <Grid key={size.id} item xs={size.name === 'One Size' ? 6 : 2}>
          <CustomTypography
            variant="body3RegularInter"
            className={classes.combinedText}
            style={{
              textAlign: 'center',
              color: !item.sizes.find(s => s.size === size.name) ? '#B7BBBF' : '#4A4F54'
            }}
          >
            {size.name}: <span>{getRemainingQuantity(size.name)}</span>
          </CustomTypography>
        </Grid>
      ))}
    </>
  );
};

const StorageChargesTableData = ({
  classes,
  item,
  product,
  storagePrice,
  storagePriceBeforeDiscount,
  getStorageFeesWithTaxes
}) => {
  const getPendingStorageItemsQuantity = history =>
    // history.reduce((sum, h) => sum + (h.dueStatus === dueDateStatuses.alert ? h.remainingQuantity : 0), 0);
    history.reduce((sum, h) => sum + h.remainingQuantity, 0);

  const [pendingStorageItemsPrice, setPendingStorageItemsPrice] = useState(0);
  const [pendingStorageItemsPriceBeforeDiscount, setPendingStorageItemsPriceBeforeDiscount] = useState(0);

  const pendingStorageItemsQuantity = useMemo(() => getPendingStorageItemsQuantity(item.sizes), [item.sizes]);

  useEffect(() => {
    if (!pendingStorageItemsQuantity || !storagePrice) return;
    const amount_without_discount = pendingStorageItemsQuantity * (storagePriceBeforeDiscount || 0);
    const amount_with_discount = pendingStorageItemsQuantity * (storagePrice || 0);
    (async () =>
      getStorageFeesWithTaxes(
        amount_without_discount,
        amount_with_discount,
        pendingStorageItemsQuantity,
        setPendingStorageItemsPriceBeforeDiscount,
        setPendingStorageItemsPrice
      ))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingStorageItemsQuantity, storagePrice]);

  const getRemainingQuantity = name =>
    item.sizes.reduce((sum, s) => sum + (s.size === name ? s.remainingQuantity : 0), 0);

  const noPendingStorageDiscountDifference = pendingStorageItemsPriceBeforeDiscount === pendingStorageItemsPrice;

  const AlertMessage = () => {
    return (
      <Grid container alignItems="center" style={{ gap: 8 }}>
        <WarningIcon style={{ color: '#C62828' }} />
        <CustomTypography
          variant="body4RegularInter"
          style={{ color: item.dueStatus === dueDateStatuses.alert ? '#C62828' : '#EF6C00' }}
        >
          {item?.failed_paid_message === '' ||
          item?.failed_paid_message === null ||
          item?.last_failed_paid_date === '' ||
          item?.last_failed_paid_date === null
            ? 'Please add funds to your Swag Card.'
            : item?.failed_paid_message}
        </CustomTypography>
      </Grid>
    );
  };

  const WarningMessage = () => {
    return (
      <Grid container alignItems="center" style={{ gap: 8 }}>
        <WarningIcon style={{ color: '#EF6C00' }} />
        <CustomTypography variant="body4RegularInter" style={{ color: '#EF6C00' }}>
          {item?.failed_paid_message !== null
            ? item?.failed_paid_message
            : 'Ensure your Swag Card has sufficient funds to cover the charge.'}
        </CustomTypography>
      </Grid>
    );
  };

  const StorageMembershipChargeMessage = () => {
    return (
      <>
        <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, color: '#4A4F54' }}>
          {moneyStr(pendingStorageItemsPrice)}
        </CustomTypography>
      </>
    );
  };

  const {
    currentMembership: { storage_discount: storageDiscount }
  } = useMembership();
  const membershipCheck = useMembershipCheck();

  const SilverMembershipTier = membershipCheck.isSilver;
  const GoldMembershipTier = membershipCheck.isGold;
  const BasicMembershipTier = membershipCheck.isBasic;
  const FreeInventoryStorageFee = storageDiscount === Number(100);
  const ZeroInventoryStorageBadge = storageDiscount === Number(0);

  const SilverDiscountBadge = () => {
    return (
      <>
        <Grid container justifyContent="center" alignItems="center">
          <DiscountBagde discount={storageDiscount} />
        </Grid>
        <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 5 }}>
          <StrikeOutText value={pendingStorageItemsPriceBeforeDiscount} fontSize={14} color="#898C91" />
          <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, color: '#4A4F54' }}>
            {moneyStr(pendingStorageItemsPrice)}
          </CustomTypography>
        </Grid>
      </>
    );
  };

  const GoldDiscountBadge = () => {
    return (
      <>
        <Grid container justifyContent="center" alignItems="center">
          <DiscountBagde discount={storageDiscount} />
        </Grid>
        <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 5 }}>
          <StrikeOutText value={pendingStorageItemsPriceBeforeDiscount} fontSize={14} color="#898C91" />
          <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, color: '#4A4F54' }}>
            {moneyStr(pendingStorageItemsPrice)}
          </CustomTypography>
        </Grid>
      </>
    );
  };

  const CustomMembershipDiscount = () => {
    return (
      <>
        {!ZeroInventoryStorageBadge && (
          <Grid container justifyContent="center" alignItems="center">
            <DiscountBagde discount={storageDiscount} />
          </Grid>
        )}
        <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 5 }}>
          {!membershipCheck.isBasic && !noPendingStorageDiscountDifference && (
            <StrikeOutText value={pendingStorageItemsPriceBeforeDiscount} fontSize={14} color="#898C91" />
          )}
          <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, color: '#4A4F54' }}>
            {moneyStr(pendingStorageItemsPrice)}
          </CustomTypography>
        </Grid>
      </>
    );
  };

  return (
    <TableHead
      style={{
        height: item.dueStatus === dueDateStatuses.alert || dueDateStatuses.warning ? '100px' : '60px',
        position: 'relative'
      }}
    >
      <TableCell className={classes.cellCenter} scope="row">
        <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, color: '#4A4F54' }}>
          {item.created_at ? formatDate(item.created_at) : 'XX/XX/XX'}
        </CustomTypography>
      </TableCell>
      <TableCell scope="row" className={classes.cellCenter}>
        <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, color: '#4A4F54' }}>
          {item.last_paid_date ? formatDate(item.last_paid_date) : 'XX/XX/XX'}
        </CustomTypography>
      </TableCell>
      <TableCell scope="row" className={classes.cellCenter}>
        <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, color: '#4A4F54' }}>
          {item.nextPaymentDate ? formatDate(item.nextPaymentDate) : 'XX/XX/XX'}
        </CustomTypography>
      </TableCell>
      <TableCell scope="row" width="30%" className={classes.cellCenter}>
        <Grid container spacing={4} justifyContent={product.stock.length === 1 ? 'center' : 'flex-start'}>
          <StorageInventoryLeft item={item} product={product} classes={classes} />
        </Grid>
      </TableCell>
      <TableCell scope="row" className={classes.cellCenter}>
        <Grid container justifyContent="center">
          {BasicMembershipTier && <StorageMembershipChargeMessage />}
          {SilverMembershipTier && <SilverDiscountBadge />}
          {GoldMembershipTier && <GoldDiscountBadge />}
          {!FreeInventoryStorageFee && !GoldMembershipTier && !SilverMembershipTier && !BasicMembershipTier && (
            <CustomMembershipDiscount />
          )}
        </Grid>
      </TableCell>
      <TableCell scope="row" width="15%" className={classes.cellCenter}>
        <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, fontSize: 13 }}>
          <InventoryProductsTimer endDate={item.nextPaymentDate} />
        </CustomTypography>
      </TableCell>
      <Grid container style={{ position: 'absolute', bottom: 6, left: 0, gap: 10, alignItems: 'center' }}>
        <Grid>{item.dueStatus === dueDateStatuses.alert && <AlertMessage />}</Grid>
        <Grid>{item.dueStatus === dueDateStatuses.warning && <WarningMessage />}</Grid>
      </Grid>
    </TableHead>
  );
};

const InventoryProductsTimer = ({ endDate }) => {
  const [remainingTime, setRemainingTime] = useState(calculateTimeRemaining(endDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = timeInSeconds => {
    const days = Math.floor(timeInSeconds / (24 * 3600));
    const hours = String(Math.floor((timeInSeconds % (24 * 3600)) / 3600)).padStart(2, 0);
    const minutes = String(Math.floor((timeInSeconds % 3600) / 60)).padStart(2, 0);
    const seconds = String(timeInSeconds % 60).padStart(2, 0);

    switch (true) {
      case days > 5:
        return `${days} DAYS`;
      case days < 1:
        return `${hours}:${minutes}:${seconds} HOURS`;
      case days === 1:
        return `${days} DAY & ${hours}:${minutes}:${seconds} HOURS`;
      default:
        return `${days} DAYS & ${hours}:${minutes}:${seconds} HOURS`;
    }
  };

  let textColor;
  if (remainingTime >= 180 * 24 * 3600) {
    textColor = '#B7BBBF';
  } else if (remainingTime <= 30 * 24 * 3600 && remainingTime > 5 * 24 * 3600) {
    textColor = '#EF6C00';
  } else if (remainingTime <= 5 * 24 * 3600) {
    textColor = '#C62828';
  } else {
    textColor = '#4A4F54';
  }

  return <Grid style={{ color: textColor }}>{remainingTime <= 0 ? 'PAYMENT DUE' : formatTime(remainingTime)}</Grid>;
};

const PlatinumStorageChargesTableHeader = ({ classes }) => (
  <TableHead>
    <CustomTableRow>
      <TableCell className={classes.cellCenter} scope="row" width="30%">
        ADDED ON
      </TableCell>
      <TableCell scope="row" width="40%" className={classes.cellCenter}>
        INVENTORY LEFT
      </TableCell>
      <TableCell scope="row" width="30%" className={classes.cellCenter} style={{ color: '#4CAF50' }}>
        <Grid container justifyContent="center" alignItems="center" style={{ gap: 5 }}>
          <Grid>STORAGE COST SAVED</Grid>
          <Grid style={{ color: '#4CAF50', cursor: 'pointer' }}>
            <Tooltip placement="top-center" arrow title="You are saving by being a SwagUp member." classes={classes}>
              <InfoOutlinedIcon style={{ width: 13, height: 13, marginTop: 7 }} />
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </CustomTableRow>
  </TableHead>
);

const InventoryHistoryImageContainer = ({ item, FreeInventoryStorageFee }) => {
  const classes = useStyles();

  const [openZoomImage, setZoomImage] = useState(false);

  return (
    <>
      <Grid item className={classes.inventoryImage} style={{ marginTop: FreeInventoryStorageFee ? 22 : 0 }}>
        <Grid item st>
          <ImgWithHandler src={item.image} width={256} alt={item.name} height={256} className={classes.image} />
        </Grid>
        <ZoomOutMapIcon
          style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}
          onClick={() => setZoomImage(true)}
        />
      </Grid>
      <ImageDialog
        img={item.image}
        largeImageSrc={item.image}
        open={openZoomImage}
        onClose={() => setZoomImage(false)}
      />
    </>
  );
};

const FirstColomnData = ({ item, FreeInventoryStorageFee }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.dataColumn}>
      <Grid item className={classes.item}>
        <CustomTypography variant="body3RegularInter" style={{ paddingBottom: 4 }}>
          Color
        </CustomTypography>
        <Grid container alignItems="center" style={{ width: '200px' }}>
          <CustomTypography variant="subtitle3SemiBoldInter" style={{ textTransform: 'capitalize' }}>
            {item?.theme_color ? item.theme_color : 'Custom'}
          </CustomTypography>
          <Grid
            style={{
              display: 'inline-block',
              marginLeft: 8,
              borderRadius: 10,
              width: 20,
              height: 20,
              backgroundColor: `${item?.theme_color_hex ? `#${item.theme_color_hex}` : 'transparent'}`
            }}
          />
        </Grid>
      </Grid>

      <Grid item className={classes.item}>
        <CustomTypography variant="body3RegularInter" style={{ paddingBottom: 4 }}>
          Date Created
        </CustomTypography>
        <CustomTypography variant="subtitle3SemiBoldInter">
          {dayjs(item?.created_at).format('MM/DD/YYYY')}
        </CustomTypography>
      </Grid>

      {FreeInventoryStorageFee ? (
        <Grid item className={classes.currentInventory}>
          <CustomTypography variant="subtitle3SemiBoldInter">Current inventory</CustomTypography>
          <Grid container style={{ gap: FreeInventoryStorageFee ? 5 : 12, width: FreeInventoryStorageFee ? 500 : 200 }}>
            <ProductQuantities item={item} newInventory list />
          </Grid>
        </Grid>
      ) : (
        <>
          {!item.nextPaymentDate ? (
            <></>
          ) : (
            <Grid item className={classes.item}>
              <CustomTypography variant="body3RegularInter" style={{ paddingBottom: 4 }}>
                Next Payment Date
              </CustomTypography>
              <CustomTypography variant="subtitle3SemiBoldInter">
                {item.nextPaymentDate ? dayjs(item.nextPaymentDate).format('MM/DD/YYYY') : 'MM/DD/YYYY'}
              </CustomTypography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const SecondColomnData = ({
  item,
  FreeInventoryStorageFee,
  disableInventoryCTA,
  ShippingAvalability,
  pendingStorageItemsPriceBeforeDiscount,
  pendingStorageItemsPrice,
  storageDiscount,
  isPackOrProduct,
  noPendingStorageDiscountDifference,
  isFreeTier,
  ZeroInventoryStorageBadge
}) => {
  const classes = useStyles();

  const membershipCheck = useMembershipCheck();

  return (
    <Grid container className={FreeInventoryStorageFee ? classes.dataColumn : classes.secondColomnData}>
      <Grid item className={classes.item}>
        <CustomTypography variant="body3RegularInter">Status</CustomTypography>
        {item?.nextPaymentDate !== undefined ? (
          <CustomTypography
            variant="subtitle3SemiBoldInter"
            style={{ color: !FreeInventoryStorageFee && disableInventoryCTA ? '#989EA4' : '#4CAF50' }}
          >
            {!FreeInventoryStorageFee && disableInventoryCTA ? 'Inactive' : 'Active'}
          </CustomTypography>
        ) : (
          <CustomTypography variant="subtitle3SemiBoldInter" style={{ color: '#989EA4' }}>
            {item?.nextPaymentDate === undefined && 'Out of Stock'}
          </CustomTypography>
        )}
      </Grid>

      <Grid item className={classes.item}>
        <CustomTypography variant="body3RegularInter" style={{ paddingBottom: 4 }}>
          Shipping Availability
        </CustomTypography>
        <CustomTypography variant="subtitle3SemiBoldInter">{ShippingAvalability}</CustomTypography>
      </Grid>

      <>
        {!pendingStorageItemsPriceBeforeDiscount && !pendingStorageItemsPrice && storageDiscount ? (
          <></>
        ) : (
          <>
            {!FreeInventoryStorageFee && (
              <Grid item className={classes.item}>
                <Grid justifyContent="flex-start" alignItems="center" style={{ display: 'flex' }}>
                  <CustomTypography variant="body3RegularInter" style={{ paddingBottom: 4, paddingRight: 5 }}>
                    Pending Storage Cost
                  </CustomTypography>
                  <Grid style={{ color: '#4A4F54', cursor: 'pointer' }}>
                    <Tooltip
                      placement="bottom-center"
                      arrow
                      title={`This is the pending storage cost for this ${isPackOrProduct}, incl sales tax. When due, we'll charge your Swag Card Balance for the storage cost.`}
                      classes={classes}
                    >
                      <InfoOutlinedIcon style={{ width: 13, height: 13, marginTop: 2 }} />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  {!membershipCheck.isBasic && !noPendingStorageDiscountDifference && (
                    <StrikeOutText value={pendingStorageItemsPriceBeforeDiscount} fontSize={16} color="#898C91" />
                  )}
                  <CustomTypography variant="subtitle3SemiBoldInter" style={{ padding: '0px 3px' }}>
                    {moneyStr(pendingStorageItemsPrice)}
                  </CustomTypography>
                  {!isFreeTier && !ZeroInventoryStorageBadge && (
                    <Grid item>
                      {membershipCheck.isGold ? (
                        <DiscountBagde discount={storageDiscount} size="small" />
                      ) : (
                        <DiscountBagde discount={storageDiscount} size="small" />
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </>
    </Grid>
  );
};

const ThirdColomnData = ({ item, itemMockupUrl, FreeInventoryStorageFee, totalSave }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.dataColumn} style={{ gap: 16 }}>
      <Grid item style={{ padding: '0px !important' }}>
        <Button
          variant="text"
          href={itemMockupUrl}
          disabled={!itemMockupUrl}
          target="_blank"
          rel="noreferrer"
          download
          className={classes.mockupLink}
          style={{ color: '#125CFF' }}
        >
          <SaveAltOutlinedIcon />
          <CustomTypography variant="body2SemiBoldInter" style={{ color: '#125CFF' }}>
            Mockup PDF
          </CustomTypography>
        </Button>
      </Grid>

      {FreeInventoryStorageFee ? (
        <Grid item className={classes.item}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" style={{ gap: 5 }}>
                <CustomTypography variant="body3RegularInter" style={{ paddingBottom: 4, color: '#4CAF50' }}>
                  Total Storage Cost Saved
                </CustomTypography>
                <Grid style={{ color: '#4CAF50', cursor: 'pointer' }}>
                  <Tooltip
                    placement="bottom-center"
                    arrow
                    title="You are saving by being a SwagUp member."
                    classes={classes}
                  >
                    <InfoOutlinedIcon style={{ width: 13, height: 13, marginTop: 2 }} />
                  </Tooltip>
                </Grid>
              </Grid>
              <CustomTypography variant="subtitle3SemiBoldInter" style={{ color: '#4CAF50' }}>
                {moneyStr(totalSave)}
              </CustomTypography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item className={classes.currentInventory}>
          <CustomTypography variant="subtitle3SemiBoldInter" style={{ paddingBottom: 4 }}>
            Current inventory
          </CustomTypography>
          <Grid container style={{ gap: 12 }}>
            <ProductQuantities item={item} newInventory list />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const InventoryHistoryData = ({
  item,
  FreeInventoryStorageFee,
  disableInventoryCTA,
  ShippingAvalability,
  pendingStorageItemsPriceBeforeDiscount,
  pendingStorageItemsPrice,
  storageDiscount,
  isPackOrProduct,
  noPendingStorageDiscountDifference,
  isFreeTier,
  ZeroInventoryStorageBadge,
  itemMockupUrl,
  totalSave
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.inventoryHistoryData}>
      <InventoryHistoryImageContainer item={item} FreeInventoryStorageFee={FreeInventoryStorageFee} />
      <FirstColomnData item={item} FreeInventoryStorageFee={FreeInventoryStorageFee} />
      <SecondColomnData
        item={item}
        FreeInventoryStorageFee={FreeInventoryStorageFee}
        ZeroInventoryStorageBadge={ZeroInventoryStorageBadge}
        disableInventoryCTA={disableInventoryCTA}
        ShippingAvalability={ShippingAvalability}
        pendingStorageItemsPriceBeforeDiscount={pendingStorageItemsPriceBeforeDiscount}
        pendingStorageItemsPrice={pendingStorageItemsPrice}
        storageDiscount={storageDiscount}
        isPackOrProduct={isPackOrProduct}
        noPendingStorageDiscountDifference={noPendingStorageDiscountDifference}
        isFreeTier={isFreeTier}
      />
      <ThirdColomnData
        item={item}
        itemMockupUrl={itemMockupUrl}
        FreeInventoryStorageFee={FreeInventoryStorageFee}
        totalSave={totalSave}
      />
    </Grid>
  );
};

const PlatinumStorageChargesTableData = ({ classes, item, product, storagePrice, storagePriceBeforeDiscount }) => {
  const getPendingStorageItemsQuantity = history => history.reduce((sum, h) => sum + h.remainingQuantity, 0);

  const pendingStorageItemsQuantity = useMemo(() => getPendingStorageItemsQuantity(item.sizes), [item.sizes]);
  const pendingStorageItemsPrice = useMemo(() => pendingStorageItemsQuantity * (storagePrice || 0), [
    pendingStorageItemsQuantity,
    storagePrice
  ]);
  const pendingStorageItemsPriceBeforeDiscount = useMemo(
    () => pendingStorageItemsQuantity * (storagePriceBeforeDiscount || 0),
    [pendingStorageItemsQuantity, storagePriceBeforeDiscount]
  );

  const StorageSavedCost = pendingStorageItemsPriceBeforeDiscount - pendingStorageItemsPrice;

  return (
    <TableHead
      style={{
        height: item.dueStatus === dueDateStatuses.alert || dueDateStatuses.warning ? '100px' : '60px',
        position: 'relative'
      }}
    >
      <TableCell className={classes.cellCenter} scope="row">
        <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, color: '#4A4F54' }}>
          {item.created_at ? formatDate(item.created_at) : 'XX/XX/XX'}
        </CustomTypography>
      </TableCell>
      <TableCell scope="row" width="30%" className={classes.cellCenter}>
        <Grid container spacing={4} justifyContent={product.stock.length === 1 ? 'center' : 'flex-start'}>
          <StorageInventoryLeft item={item} product={product} classes={classes} />
        </Grid>
      </TableCell>
      <TableCell scope="row" className={classes.cellCenter}>
        <CustomTypography variant="body3RegularInter" style={{ fontWeight: 500, color: '#4CAF50' }}>
          {moneyStr(StorageSavedCost)}
        </CustomTypography>
      </TableCell>
    </TableHead>
  );
};

const BackButtonWithTitle = ({ titleText }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Grid container alignItems="center" className={classes.backBtnRoot}>
      <Grid item>
        <IconButton className={classes.icon} onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid item onClick={handleBack}>
        <CustomTypography variant="body4MediumInter" className={classes.title}>
          {titleText}
        </CustomTypography>
      </Grid>
    </Grid>
  );
};

const PaymentFailed = ({ classes }) => {
  const history = useHistory();

  const handleAddFundsToSwagCard = () => {
    history.push('/billing/add-credits');
  };

  const handleUpgradeMembership = () => {
    history.push('/membership/manage-membership-switch');
  };

  return (
    <Grid container xs={12} className={classes.paymentFailedAlertWrapper}>
      <Grid item container xs={6}>
        <Grid item xs={1}>
          <WarningIcon className={classes.paymentFailedAlertTitle} />
        </Grid>
        <Grid item xs={11}>
          <CustomTypography variant="body2SemiBoldInter" className={classes.paymentFailedAlertTitle}>
            Your Swag Card Balance has a Negative Value
          </CustomTypography>
        </Grid>
        <Grid item xs={1} />
        <Grid item container xs={10} offset={1}>
          <CustomTypography variant="body3RegularInter" className={classes.paymentFailedAlertMessage}>
            Your Swag Card balance has a <span style={{ fontWeight: 600 }}>Negative Balance</span>. Please add funds to
            your Swag Card balance to cover these costs and resume shipments.{' '}
            <span style={{ fontWeight: 600 }}>
              You can also Upgrade now to Platinum Membership and never pay for storage fee again.
            </span>
          </CustomTypography>
        </Grid>
      </Grid>

      <Grid item container xs={6} style={{ gap: 24, justifyContent: 'end', flexWrap: 'nowrap' }}>
        <Grid item>
          <Button
            onClick={handleAddFundsToSwagCard}
            className={`${classes.paymentFailedAlertButton} ${classes.paymentFailedAlertSwagCardBtn}`}
          >
            <CustomTypography variant="subtitle3SemiBoldInter">Add funds to Swag Card</CustomTypography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={`${classes.paymentFailedAlertButton} ${classes.paymentFailedAlertUpgradeMembershipBtn}`}
            variant="primary"
            onClick={handleUpgradeMembership}
          >
            <CustomTypography variant="subtitle3SemiBoldInter">Upgrade Membership</CustomTypography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export {
  ProductColor,
  ProductColorV2,
  ProductImage,
  productImageTitle,
  ProductNameInput,
  ProductQuantities,
  SelectionMark,
  selectionMarkTitle,
  SendSwagButton,
  useReorderLink,
  StorageChargesTableHeader,
  StorageChargesTableData,
  InventoryProductsTimer,
  PlatinumStorageChargesTableHeader,
  PlatinumStorageChargesTableData,
  StorageInventoryLeft,
  BackButtonWithTitle,
  PaymentFailed,
  InventoryHistoryData
};
