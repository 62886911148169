import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Link } from 'react-router-dom';
import { StylessButton } from '../buttons';
import useIntegrations from '../../hooks/useIntegrations';
import { getIntegrationImageSrcSet } from './common';
import Img from '../shared/Img';

const styles = theme => ({
  integrationImageWrapper: {
    height: 72,
    width: 72,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 10
  },
  integrationImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  integrationItem: {
    padding: '20px 24px  !important',
    border: '1px solid #EBEDF0 !important',
    width: '100% !important',
    textAlign: 'left !important',
    position: 'relative',
    borderRadius: 10,
    background: ({ enabled }) => (enabled ? undefined : '#F2F2F2 !important')
  },
  tabPanel: {
    paddingTop: 16,
    width: '100%'
  },
  integrationName: { marginBottom: 8, textAlign: 'left' },
  integrationDescription: { marginBottom: 8, textAlign: 'left' },
  integrationCTAPanel: { textAlign: 'left', paddingTop: 8 },
  integrationCTA: {
    height: 30,
    padding: '0px 12px',
    borderWidth: 1,
    fontFamily: 'Inter',
    fontSize: 12,
    lineHeight: '12px',
    fontWeight: 400,
    background: ({ enabled }) => (enabled ? undefined : '#EBEDF0 !important')
  },
  comingSoon: {
    position: 'absolute',
    right: 12,
    bottom: 6
  }
});

const useStyles = makeStyles(styles);

const IntegrationItem = ({ item, onNext, isConnected }) => {
  const classes = useStyles({ enabled: item.active });
  return (
    <Grid item xs={3}>
      <StylessButton
        onClick={isConnected ? undefined : () => !item.url && onNext(item.rutter_id)}
        disabled={!item.active}
        className={classes.integrationItem}
      >
        <Grid container spacing={4}>
          <Grid item>
            <Box className={classes.integrationImageWrapper}>
              <Img
                src={item.image_url}
                srcSet={getIntegrationImageSrcSet(item.rutter_id)}
                alt={item.name}
                className={classes.integrationImage}
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Typography variant="body3MediumInter" className={classes.integrationName}>
              {item.name}
            </Typography>
            <Typography variant="body4RegularInter" className={classes.integrationDescription}>
              {item.description}
            </Typography>
            <div className={classes.integrationCTAPanel}>
              <Button
                size="small"
                component={isConnected ? Link : undefined}
                variant={isConnected ? 'primary' : 'secondary'}
                onClick={isConnected ? undefined : () => !item.url && onNext(item.rutter_id)}
                to={isConnected ? `/integrations/orders/?platform=${item.rutter_id}` : undefined}
                href={item.url}
                target={item.url ? '_blank' : undefined}
                rel={item.url ? 'noreferrer' : undefined}
                disabled={!item.active}
                className={classes.integrationCTA}
              >
                {isConnected ? 'View' : !item.active ? 'Coming soon' : 'Connect'}
              </Button>
            </div>
          </Grid>
          {/* {!item.active && (
            <Grid container>
              <Grid item>
                <Typography variant="body4MediumInter" className={classes.comingSoon}>
                  Coming soon
                </Typography>
              </Grid>
            </Grid>
          )} */}
        </Grid>
      </StylessButton>
    </Grid>
  );
};

const NativeIntegrations = () => {
  const { integrations, onOpen, getConnectedPlatform } = useIntegrations();

  const onOpenRutter = platform => {
    onOpen(platform);
  };

  const classes = useStyles();
  return (
    <Box className={classes.tabPanel}>
      <Grid container spacing={4}>
        {integrations.map(item => (
          <IntegrationItem
            key={item.key}
            item={item}
            onNext={onOpenRutter}
            isConnected={!!getConnectedPlatform(item.rutter_id)}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default NativeIntegrations;
