import React, { useState } from 'react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useLocation, Link } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useDispatch } from 'react-redux';
import QuantitySelect from './QuantitySelect';
import { updateProductInCart } from '../../../actions/cart';
import { moneyStr, buildUrlWithParam } from '../../../helpers/utils';
import { resize } from '../../../utils/images';
import styles from './CartContent.styles';
import gtm from '../../../utils/gtm';

const useStyles = makeStyles(styles);

const CartContent = ({ cart, pack, presetQuantities, minQuantity, onProductRemove }) => {
  const [openQuantitySelect, setOpenQuantitySelect] = useState();

  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleToggle = idx => () => setOpenQuantitySelect(idx === openQuantitySelect ? null : idx);

  const handleQuantityChange = idx => quantity => {
    setOpenQuantitySelect(null);
    dispatch(updateProductInCart(cart[idx].product.id, { quantity }));
    cart.map(item => {
      const transactionId = item.product.id;
      const itemName = item.product.name;
      const itemPrice = item.product.price;
      const itemQuantityPerPack = quantity;
      gtm.changeCartQuantity(transactionId, itemName, itemPrice, itemQuantityPerPack);
    });
  };

  const urlToOpen = product => buildUrlWithParam(location, 'details', product.id);

  return (
    <OverlayScrollbarsComponent className={classes.root}>
      <List>
        {cart
          .filter(item => item.product)
          .map(({ product, quantity, colorIdx, prices }, idx) => (
            <div key={product.id}>
              <ListItem component={Link} replace to={urlToOpen(product)} disableGutters className={classes.item}>
                <ListItemAvatar className={classes.imageContainer}>
                  <Avatar
                    variant="square"
                    src={resize(colorIdx !== null ? product.available_colors[colorIdx].image : product.img, 40)}
                    alt={product.name}
                    className={classes.image}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={product.name}
                  secondary={
                    <>
                      <strong>Price</strong>: Starting at{' '}
                      <strong>{moneyStr(prices[pack?.quantity || quantity])}</strong>
                      <br />
                      <strong>Color</strong>:{' '}
                      {colorIdx !== null ? product.available_colors[colorIdx].label : 'Designer'}
                      <br />
                      {pack && (
                        <>
                          <strong>Qty per pack</strong>: {quantity}
                        </>
                      )}
                    </>
                  }
                  className={classes.infoContainer}
                  classes={{ primary: classes.name, secondary: classes.details }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" size="small" aria-label="delete" onClick={() => onProductRemove(product)}>
                    <DeleteOutlineIcon color="#787B80" style={{ fontSize: 20 }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {!pack && (
                <QuantitySelect
                  value={quantity}
                  quantities={presetQuantities}
                  prices={prices}
                  open={openQuantitySelect === idx}
                  minQuantity={minQuantity}
                  onToggle={handleToggle(idx)}
                  onChange={handleQuantityChange(idx)}
                  className={classes.quantitySelect}
                />
              )}
            </div>
          ))}
      </List>
    </OverlayScrollbarsComponent>
  );
};

export default CartContent;
