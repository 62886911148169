const textAlignment = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const styles = theme => ({
  modalContainer: {
    padding: '60px 50px',
    width: '100%',
    position: 'relative'
  },
  bannerModal: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
  },
  inventoryModalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'fixed',
    padding: '0px 50px',
    height: 150,
    backgroundColor: '#fff',
    top: 0,
    left: 0,
    zIndex: 99999
  },
  inventoryModalBody: {
    overflowY: 'auto',
    maxHeight: '95%',
    height: '100%',
    position: 'relative',
    top: 90,
    width: '100%'
  },
  paymentFailedAlertWrapper: {
    marginTop: 24,
    backgroundColor: '#FFF3E0',
    borderRadius: 5,
    padding: 16,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paymentFailedAlertTitle: {
    color: '#C62828'
  },
  paymentFailedAlertMessage: {
    color: '#131415'
  },
  paymentFailedAlertButton: {
    height: '48px',
    borderRadius: '56px',
    padding: '14px 24px',
    borderWidth: 1
  },
  paymentFailedAlertUpgradeMembershipBtn: {
    backgroundColor: '#125CFF',
    '& > span > p': {
      color: '#fff'
    }
  },
  paymentFailedAlertSwagCardBtn: {
    borderColor: '#131415',
    color: '#131415'
  },
  productDetails: {
    ...textAlignment,
    justifyContent: 'space-evenly !important',
    border: '1px solid #E5E7E8',
    borderRadius: 10,
    padding: '24px 0px'
  },
  inventoryImage: {
    ...textAlignment,
    width: 250,
    height: 200,
    maxWidth: 250,
    maxHeight: 200,
    position: 'relative'
  },
  inventoryProductImage: {
    width: 240,
    height: 200,
    objectFit: 'contain',
    border: '1px solid #E5E7E8',
    borderRadius: 10
  },
  inventoryProductImageFallback: {
    width: 240,
    height: 200,
    objectFit: 'cover',
    border: '1px solid #E5E7E8',
    borderRadius: 10
  },
  inventoryCTA: {
    ...textAlignment,
    justifyContent: 'space-between !important',
    gap: 14
  },
  tooltip: {
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 350
  },
  reorderLink: {
    height: 'unset',
    padding: 0,
    '&:hover': {
      textDecoration: 'underline !important'
    }
  },
  mockupLink: {
    height: 'unset',
    '&:hover, &:focus': { textDecoration: 'underline !important' },
    '&:active': { color: '#1C4783' },
    '& p': { color: 'inherit' },
    '&  svg': { fontSize: 20, marginRight: 8 }
  },
  orderTable: {
    '& .MuiTableCell-root': {
      borderRight: '0px !important',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& .divContent': theme.typography.body4RegularInter
    },
    '& th.MuiTableCell-root .divContent': {
      color: '#989EA4'
    }
  },
  cellCenter: {
    textAlign: 'center',
    color: '#989EA4',
    '& .divContent': {
      width: '100%',
      textAlign: 'center'
    }
  }
});

export default styles;
