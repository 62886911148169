import * as React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { ProductOnboardingItem } from './ProductOnboardingItem';
import { Helmet, CenteredGrid } from '../shared';
import tags from '../../apis/seoTags';
import log from '../../logger';
import { getFieldFromCookie } from '../../helpers/utilsCookies';
import { useQueryParams } from '../../hooks';

const useStyles = makeStyles(theme => ({
  center: {
    paddingTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 960,
      minWidth: 320,
      paddingTop: 50,
      paddingBottom: 80
    }
  },
  container: {
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 60
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  logo: {
    paddingBottom: 24,
    width: 200,
    display: 'flex',
    margin: 'auto'
  },
  productOnboardingTitle: {
    fontSize: 36,
    textAlign: 'center',
    color: '#0B1829',
    lineHeight: 1.75,
    [theme.breakpoints.down('sm')]: { lineHeight: 'normal', padding: '0px 10px' }
  },
  productOnboardingDescription: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    textAlign: 'center',
    color: '#787B80',
    [theme.breakpoints.down('sm')]: { padding: '10px 10px 0px 10px' }
  }
}));

export const cards = {
  customPacks: {
    image: 'swag-pack',
    subtitle: 'Create a custom swag pack',
    to: '/custom-swag-packs/catalog'
  },
  bulk: {
    image: 'bulk-swag',
    subtitle: 'Individual products for any use',
    to: '/bulk/catalog'
  }
};

const ProductOnboarding = () => {
  const classes = useStyles();
  const query = useQueryParams();
  const affiliateFromQuery = query.get('affiliate_id');
  const affiliateFromCookie = getFieldFromCookie('affiliate_id');

  const isPostalReferral = [affiliateFromQuery, affiliateFromCookie].includes('postal.io');

  const postalsText = (
    <>
      It’s easy to create swag for your<span style={{ color: '#125d8c' }}> Postals</span>
    </>
  );

  const [title, onboardingOptions] = isPostalReferral
    ? [postalsText, ['customPacks']]
    : ['Let’s start your swag project', ['customPacks', 'bulk']];

  log.debug('affiliate from query:', affiliateFromQuery, ', from cookie:', affiliateFromCookie);
  log.debug('came from Postal.io?', isPostalReferral);

  return (
    <div className={classes.container}>
      <Helmet tags={tags.productOnboarding} />
      <Grid container justifyContent="center">
        <CenteredGrid container justifyContent="center" item className={classes.center}>
          <Grid item xs={12}>
            {isPostalReferral && (
              <img className={classes.logo} src="/images/public/postalio.svg" alt="Postal.io logo" />
            )}
            <Typography variant="h1" className={classes.productOnboardingTitle}>
              {title}
            </Typography>
            <p className={classes.productOnboardingDescription}>Select one of the options below:</p>
          </Grid>
          <Grid container justifyContent="center">
            {onboardingOptions.map(option => (
              <ProductOnboardingItem
                key={option}
                content={cards[option]}
                isCustomPacksCard={option === 'customPacks'}
                isPostalReferral={isPostalReferral}
              />
            ))}
          </Grid>
        </CenteredGrid>
      </Grid>
    </div>
  );
};

export default ProductOnboarding;
