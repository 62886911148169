import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import Scrollbar from 'react-scrollbars-custom';
import { Pagination } from '../../../../shared';
import { TableRow, TableCell, CheckBox } from './TableCommon';
import { useLocalPagination } from '../../../../../hooks';
import ShippingMethodDrawer from './ShippingMethodDrawer';
import BottomMenu from './BottomMenu';
import useFilterAndSortRecipients from '../hooks/useFilterAndSortRecipients';
import styles from './SelectedRecipientsTable.styles';
import { deleteShipmentGroupRecipients, validateDirectoryOrders } from '../../../../../actions/shipmentGroupActions';
import RecipientRow from './RecipientRow';
import { useInSendSwagPath } from '../hooks';

const useStyles = makeStyles(styles);

const SelectedRecipientsTable = ({ search, ordering, filters, onEditRecipient }) => {
  const classes = useStyles();

  const [isShippingMethodOpen, toggleShippingMethod] = React.useReducer(prevOpen => !prevOpen, false);
  const [[selected], setSelected] = React.useState([new Set()]);
  const [recipientMenuOpen, setRecipientMenuOpen] = React.useState();

  const dispatch = useDispatch();
  const { recipients, total, filteredTotal } = useFilterAndSortRecipients({ search, filters, ordering });

  const pagination = useLocalPagination(filteredTotal);
  const offset = pagination.pageIndex * pagination.perPage;
  const paginatedRecipients = recipients.filter((_, idx) => idx >= offset && idx < offset + pagination.perPage);

  const { setPageIndex } = pagination;
  React.useEffect(() => {
    setPageIndex(0);
  }, [search, filters, setPageIndex]);

  const handleClearSelected = () => {
    selected.clear();
    setSelected([selected]);
  };

  const allSelected = recipients.length > 0 && recipients.every(c => selected.has(c.id));
  const indeterminate = !allSelected && recipients.some(c => selected.has(c.id));
  const handleCheckAll = () => {
    recipients.forEach(r => {
      if (indeterminate || allSelected) selected.delete(r.id);
      else selected.add(r.id);
    });
    setSelected([selected]);
  };

  const isStockLimited = useInSendSwagPath();
  const handleDeleteRecipients = ids => e => {
    e.stopPropagation();
    dispatch(deleteShipmentGroupRecipients(ids));
    if (isStockLimited) dispatch(validateDirectoryOrders([], isStockLimited, false));

    ids.forEach(id => selected.delete(id));
    setSelected([selected]);
  };

  const handleCheck = recipientId => {
    if (recipientMenuOpen) return;

    if (selected.has(recipientId)) selected.delete(recipientId);
    else selected.add(recipientId);
    setSelected([selected]);
  };

  const handleEditRecipient = recipient => e => {
    e.stopPropagation();
    setRecipientMenuOpen();
    onEditRecipient(recipient);
  };

  return (
    <>
      <Grid container direction="column" style={{ flex: 1, padding: '0px 80px' }}>
        <Grid container direction="column" className={classes.tableContainer}>
          <TableContainer component={Scrollbar} className={classes.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <CheckBox
                      color="primary"
                      checked={allSelected}
                      indeterminate={indeterminate}
                      onClick={handleCheckAll}
                      disabled={total === 0}
                    />
                  </TableCell>
                  <TableCell width={36} style={{ borderRight: 'none' }} />
                  <TableCell className={classes.nameCell}>NAME</TableCell>
                  <TableCell>ADDRESS</TableCell>
                  <TableCell>QTY</TableCell>
                  <TableCell style={{ borderRight: 'none' }}>SHIPPING</TableCell>
                  <TableCell width={36} />
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRecipients.map(recipient => (
                  <RecipientRow
                    key={recipient.id}
                    recipient={recipient}
                    isSelected={selected.has(recipient.id)}
                    isMenuOpen={Boolean(recipientMenuOpen)}
                    highlightText={search}
                    onSelect={() => handleCheck(recipient.id)}
                    onEdit={handleEditRecipient(recipient.id)}
                    onRemove={handleDeleteRecipients([recipient.id])}
                    hideInactiveWarnings={isStockLimited}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container alignItems="center" className={classes.paginationContainer}>
            <Pagination
              count={filteredTotal}
              endText="recipients"
              startText="Show"
              perPage={pagination.perPage}
              onNext={pagination.onNext}
              pageIndex={pagination.pageIndex}
              onPrevious={pagination.onPrevious}
              sizeOptions={pagination.sizeOptions}
              onPerPageChange={pagination.changeSize}
              buttonClass={classes.paginationBtn}
            />
          </Grid>
        </Grid>
        {isShippingMethodOpen && (
          <ShippingMethodDrawer open={isShippingMethodOpen} onClose={toggleShippingMethod} selectedIdsSet={selected} />
        )}
      </Grid>
      <BottomMenu
        selectedCount={selected.size}
        onChangeShippingMethod={toggleShippingMethod}
        onRemove={handleDeleteRecipients([...selected.values()])}
        onClose={handleClearSelected}
      />
    </>
  );
};

export default SelectedRecipientsTable;
