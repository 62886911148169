import { CREATE_ORDER, REMOVE_BAG_SUBITEM } from '../actions/types';

const BagReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORDER:
      return action.payload;

    case REMOVE_BAG_SUBITEM:
      return {
        ...state,
        packs: state.packs.map(pack =>
          pack.idBag !== action.payload.idBag
            ? pack
            : {
                ...pack,
                items: pack.items.filter(item => item.id !== action.payload.idItem)
              }
        )
      };

    default:
      return state.packs
        ? state
        : {
            ...state,
            packs: []
          };
  }
};

export default BagReducer;
