import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useAsync, usePagination, useQueryParams } from '../../hooks';
import toErrorPage from '../../helpers/toErrorPage';
import { Pagination, EmptyState } from '../shared';
import ActionBar from './ActionBar';
import Invoices from './Invoices';
import Loader from '../global/Loader';
import DashBoard from '../../apis/DashBoard';
import apiPaths from '../../helpers/apiPaths';
import { errorAndLog, okAndLog } from '../../helpers/utils';
import log from '../../logger';
import SearchSortFilter from '../shared/SearchSortFilter';

const styles = () => ({
  title: {
    fontFamily: 'Gilroy',
    fontSize: 40,
    fontWeight: 600,
    textAlign: 'left',
    color: '#1B1C1F',
    lineHeight: 1
  }
});

const useStyles = makeStyles(styles);

const fetchInvoices = async query => {
  log.debug('fetchInvoices Action - Entering');
  const currentQuery = `${apiPaths.invoices}?${query}`;

  try {
    const apiCall = await DashBoard.get(currentQuery);

    const logger = apiCall.status === 200 ? okAndLog : errorAndLog;
    return logger('fetchInvoices', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('fetchInvoices', e.status, e.data);
  }
};

const perPageOptions = [12, 24, 36, 48];
const InvoicesContainer = () => {
  const query = useQueryParams();
  const search = query.get('search') || '';

  const [total, setTotal] = useState(-1);
  const [invoices, setInvoices] = useState([]);

  const { nextPage, pageIndex, perPage, sizeOptions, previousPage, changeSize } = usePagination(total, perPageOptions);
  const [paginate, response, isPending, hasFailed] = useAsync(fetchInvoices, 250);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (hasFailed) toErrorPage(response, history);
  }, [hasFailed, response, history]);

  useEffect(() => {
    if (response) setInvoices(response.data.results);
  }, [response]);

  useEffect(() => {
    if (response) {
      const newTotal = response.data.count;
      if (total <= -1 && newTotal === 0) setTotal(-2);
      else if (response && newTotal !== total) setTotal(newTotal);
    }
  }, [response, total]);

  const status = query.get('status') || '';
  const ordering = query.get('ordering') || '-created_at';

  const handleFetchInvoices = useCallback(() => {
    const apiQuery = new URLSearchParams();
    apiQuery.set('limit', perPage);
    apiQuery.set('ordering', ordering);
    apiQuery.set('search', search);
    apiQuery.set('status', status);
    apiQuery.set('offset', pageIndex * perPage);
    paginate(apiQuery.toString());

    return paginate.cancel;
  }, [ordering, search, status, pageIndex, perPage, paginate]);

  const sortOptions = {
    '-created_at': 'Most Recent',
    created_at: 'Less Recent',
    '-total_plus_tax': 'Highest First',
    total_plus_tax: 'Lowest First'
  };

  const statusFilterOptions = {
    Paid: 'Paid',
    only_pending_payment: 'Only Pending Payment',
    over_due: 'Overdue',
    'Pending Payment': 'Pending Payment'
  };

  useEffect(() => {
    const cancel = handleFetchInvoices();
    return () => cancel();
  }, [handleFetchInvoices]);

  log.debug('InvoicesContainer pageIndex:', pageIndex, 'invoices:', invoices, 'total:', total);

  if (total === -1) return <Loader absolute />;

  const noInvoices = total === -2 && !(search || status);

  const searchSortFilterConfig = {
    search: { placeholder: 'Search Invoices' },
    sort: { options: sortOptions },
    filters: [{ label: 'Status', queryParam: 'status', options: statusFilterOptions, isSingleSelect: true }]
  };

  return (
    <Grid container direction="column" justifyContent="space-between">
      {noInvoices ? (
        <>
          <Typography variant="h3" className={classes.title}>
            Invoices
          </Typography>
          <EmptyState
            title="No Invoices"
            image={{
              path: '/images/account/empty-invoice.png',
              alt: 'No invoices',
              text: 'You don’t have any invoices.'
            }}
            marginTop={0}
          />
        </>
      ) : (
        <>
          <Invoices invoices={invoices} onFetchInvoices={handleFetchInvoices} isPending={isPending}>
            <SearchSortFilter config={searchSortFilterConfig} />
          </Invoices>
          <Grid container justifyContent="center" style={{ paddingTop: 20 }}>
            <Pagination
              count={total}
              endText="invoices"
              perPage={perPage}
              next={nextPage}
              pageIndex={pageIndex}
              previous={previousPage}
              sizeOptions={sizeOptions}
              onPerPageChange={changeSize}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default InvoicesContainer;
