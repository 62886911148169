export default () => ({
  paymentLabel: {
    fontFamily: 'Inter',
    fontSize: 16,
    color: '#8d9299',
    display: 'flex',
    alignItems: 'center'
  },
  paymentCommonValue: {
    fontFamily: 'Inter',
    color: '#787B80',
    fontWeight: 400,
    fontSize: 14
  },
  paymentTotalText: {
    fontFamily: 'Inter',
    color: '#000',
    fontWeight: 600,
    fontSize: 24
  },
  totalContainer: {
    padding: '16px 0 24',
    paddingBottom: 12
  },
  subTotalContainer: {
    padding: '24px 0 8px',
    paddingBottom: 12
  },
  subTotalContainerBottom: {
    padding: '0px 0 32px',
    paddingBottom: 12
  },
  paymentTotalValue: {
    fontFamily: 'Inter',
    color: '#787B80',
    fontWeight: 400,
    fontSize: 14
  },
  paymentCreditValue: {
    fontFamily: 'Inter',
    fontSize: 16,
    color: '#45af5f'
  },
  creditsLeftContainer: {
    paddingTop: 21,
    borderTop: 'solid 1px #ebecf5'
  },
  cardSelect: {
    height: 'unset',
    padding: 0,
    '&:hover': { backgroundColor: 'transparent' },
    '& .MuiButton-label > span': {
      margin: '0 4px',
      color: '#434c5f',
      fontSize: 12
    }
  }
});
