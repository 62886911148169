import axios from 'axios';
import api from '../DashBoardForm';
import apiPaths from '../../helpers/apiPaths';
import { concatIfNotExists } from '../../utils/array';

const buildPrices = prices => prices.reduce((acc, { quantity, price }) => ({ ...acc, [quantity]: +price }), {});
const buildAllPrices = (products, isBulk) =>
  products.reduce((acc, p) => ({ ...acc, [p.item_id]: buildPrices(isBulk ? p.quantities : p.prices) }), {});

const cancelTokens = {};

export default {
  async fetch() {
    const result = await api.get(apiPaths.categories);

    return result.data;
  },

  async getPricing(products, numberOfColors, quantities, isBulk, key) {
    if (cancelTokens[key]) cancelTokens[key].cancel('Pricing request canceled due to new request');
    cancelTokens[key] = axios.CancelToken.source();

    const { data } = isBulk
      ? await api.post(
          apiPaths.bulkPricing,
          {
            products: products.map(item => ({
              item_id: item.product.id,
              quantities: concatIfNotExists(quantities, item.quantity).map(quantity => ({ quantity })),
              colors: numberOfColors
            }))
          },
          { cancelToken: cancelTokens[key].token }
        )
      : await api.post(
          apiPaths.packMultiplePrice,
          {
            products: products.map(item => ({
              item_id: item.product.id,
              units_per_pack: item.quantity,
              colors: numberOfColors
            })),
            quantities: quantities.map(quantity => ({ quantity }))
          },
          { cancelToken: cancelTokens[key].token }
        );

    return {
      prices: buildAllPrices(data.products, isBulk),
      totals: isBulk ? null : buildPrices(data.quantities)
    };
  }
};
