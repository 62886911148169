import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import useFetchEmployeesByIds from './useFetchEmployeesByIds';
import { deleteShipmentGroupRecipientsToSelect } from '../../../../../actions/shipmentGroupActions';

const useSelectNewContacts = () => {
  const [selected, setSelected] = React.useState(new Map());
  const dispatch = useDispatch();
  const { idsToSelect } = useSelector(state => state.shipmentGroup);
  const [idsToFetch, setIdsToFetch] = React.useState();

  const [fetchedEmployees] = useFetchEmployeesByIds(idsToFetch);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (idsToSelect.length === 0) return;

    const localIdsToSelect = idsToSelect;
    dispatch(deleteShipmentGroupRecipientsToSelect(idsToSelect));

    const cache = queryClient.getQueryCache();
    const contacts = cache.queries
      .filter(q => q.queryHash.includes('employees'))
      .reduce((acc, q) => [...acc, ...q.state.data.results], []);

    if (isEmpty(contacts)) {
      setIdsToFetch(localIdsToSelect);
      return;
    }

    const newSelected = new Map();
    const missingIds = localIdsToSelect.filter(id => {
      const contact = contacts.find(c => c.id === id);
      if (contact) newSelected.set(id, contact);

      return !contact;
    });

    if (newSelected.size > 0) {
      setSelected(newSelected);
    }

    if (missingIds.length > 0) {
      setIdsToFetch(missingIds);
    }
  }, [idsToSelect, queryClient, setIdsToFetch, dispatch]);

  React.useEffect(() => {
    if (!fetchedEmployees) return;

    const newSelected = new Map();
    fetchedEmployees.forEach(e => newSelected.set(e.id, e));
    setSelected(newSelected);
  }, [fetchedEmployees]);

  return selected;
};

export default useSelectNewContacts;
