import { SET_SHOW_TOP_MESSAGE } from '../actions/types';

const initialState = () => localStorage.getItem('noTopMessage') === null;

const TopMessageReducer = (state = initialState(), action) => {
  switch (action.type) {
    case SET_SHOW_TOP_MESSAGE:
      return action.payload;

    default:
      return state;
  }
};

export default TopMessageReducer;
