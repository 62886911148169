import * as React from 'react';
import { Box, Grid, IconButton, makeStyles, Modal } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CheckCircleRounded, Close } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ellipsisStyles } from '../shared/styles/commonStyles';

const styles = theme => ({
  modalViewDetailsContainer: {
    borderRadius: 20,
    background: 'white',
    padding: 24,
    position: 'relative'
  },
  automationModal: {
    padding: 0,
    marginTop: '92px',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 640,
    overflow: 'hidden'
  },
  automationModalHeader: {
    padding: '12px 0px 34px 0px'
  },
  automationModalHeaderText: {
    ...ellipsisStyles,
    color: '#131415',
    fontSize: '20px',
    lineHeight: ' 28px',
    letterSpacing: '-0.03em'
  },
  modalHeaderBar: {},
  modalBody: { width: '100%' },
  close: {
    width: 20,
    height: 20,
    fontSize: 12
  },
  successIcon: {
    width: 56,
    height: 56,
    color: 'rgb(152, 70, 221)'
  },
  modalIconSection: {
    padding: '0px 0px 24px 0px'
  },
  modalTitle: {
    paddingBottom: '24px'
  },
  modalSubtitle: {
    marginBottom: 24
  },
  modalCTASection: {
    marginBottom: 24
  }
});

const useStyles = makeStyles(styles);

const SuccessModal = ({ isOpen, onClose, successData = {} }) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="order-details-title"
      aria-describedby="order-details-description"
      open={isOpen}
      onClose={onClose}
      className={classes.automationModal}
    >
      <Box className={classes.modalViewDetailsContainer}>
        <Grid container className={classes.modalBody}>
          <Grid container justifyContent="flex-end" className={classes.modalHeaderBar}>
            <Grid item>
              <IconButton className={classes.close} onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Box className={classes.modalBody}>
            <Grid container justifyContent="center" className={classes.modalIconSection}>
              <Grid item>
                <Box>
                  <CheckCircleRounded className={classes.successIcon} />
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Box>
                  <Typography variant="body1RegularInter" className={classes.modalTitle}>
                    {successData.title}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" className={classes.modalSubtitle}>
              <Grid item>
                <Box>
                  <Typography variant="body3RegularInter" className={classes.modalSubtitle}>
                    {successData.subtitle}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" className={classes.modalCTASection}>
              <Grid item>
                <Box>
                  {successData.cta?.external ? (
                    <Button
                      variant="primary"
                      size="small"
                      href={successData.cta?.link}
                      onClick={onClose}
                      target="_blank"
                    >
                      {successData.cta?.text}
                    </Button>
                  ) : (
                    <Button variant="primary" size="small" component={Link} to={successData.cta?.link}>
                      {successData.cta?.text}
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
