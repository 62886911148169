import { makeStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    marginTop: 0
  },
  iconContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '4px'
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#125CFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px'
  }
});
const useStyles = makeStyles(styles);

const IconLabel = ({ icon, label, handleClick, isHideLabel, id = 'iconLabelId', style }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconContainer} onClick={handleClick} style={style}>
      {icon}
      {isHideLabel && (
        <span id={id} className={classes.label}>
          {label}
        </span>
      )}
    </div>
  );
};

export default IconLabel;
