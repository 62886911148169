import { menuStyles, hideableFilters } from './TableCommon';

export const style = theme => {
  return {
    ...menuStyles,
    title: {
      fontFamily: ({ slimHeader }) => (slimHeader ? 'Inter' : 'Gilroy-Bold'),
      fontWeight: ({ slimHeader }) => (slimHeader ? 600 : undefined),
      color: '#0b1829',
      flex: ({ leftBarNavigation }) => (leftBarNavigation ? 'unset' : 'initial'),
      [theme.breakpoints.only('lg')]: {
        fontSize: '20px !important'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '30px !important'
      },
      fontSize: ({ leftBarNavigation, hideLeftNav }) => (leftBarNavigation && !hideLeftNav ? 30 : 36)
    },
    emptyStateTitle: {
      marginTop: 24,
      fontFamily: ({ slimHeader }) => (slimHeader ? 'Inter' : 'Gilroy-Bold'),
      fontSize: 24,
      color: '#0b1829'
    },
    subtitle: {
      marginTop: 12,
      fontFamily: ({ slimHeader }) => (slimHeader ? 'Inter' : 'Gilroy-Medium'),
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.57,
      color: '#787b80'
    },
    verticalDivider: {
      margin: '6px 8px',
      backgroundColor: 'rgba(0, 0, 0, 0.06)'
    },
    sidebar: { border: '1px solid #f0f2f5' },
    createButton: {
      margin: '35px auto 0 auto',
      padding: '6px 0',
      width: ({ slimHeader }) => (slimHeader ? 256 : 206),
      height: 56,
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: '#fafafa',
        color: '#787b80'
      }
    },
    deleteButton: {
      margin: '35px auto 0 auto',
      padding: '6px 0',
      width: 206,
      height: 56
    },
    toolbar: {
      height: ({ slimHeader }) => (slimHeader ? 80 : 123),
      padding: '36px 0',
      position: 'relative'
    },
    animatedBox: {
      transition: 'all',
      transitionDuration: '1s',
      transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)'
    },
    hideableFilters
  };
};

export default style;
