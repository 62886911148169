import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getEmployeeShipAddress, joinFields } from '../../helpers/utils';
import styles from './styles/onboarding';
import { useSizes } from '../../hooks';

const getIsCurrent = (index, step, country) => {
  return country !== 'US' ? index + 1 === step : index === step;
};

const getSize = (sizes, id) => sizes?.find(s => s.id === id)?.name;

const getUSAorDefault = country => (country === 'US' ? country : 'International');

const InfoLabel = ({ classes, label, isCurrent, value }) => (
  <div>
    {value ? (
      <div>
        <p className={classes.propertyLabel}>{label}</p>
        <p className={classes.propertyValue}>{value}</p>
      </div>
    ) : (
      <div>
        {isCurrent ? (
          <div>
            <p className={classes.propertyLabel}>{label}</p>
            <div className={classes.currentContent} />
          </div>
        ) : (
          <div>
            <div className={classes.pendingLabel} />
            <div className={classes.pendingContent} />
          </div>
        )}
      </div>
    )}
  </div>
);

const SummaryContent = ({ classes, onboardingInfo: { currentInfo, currentStep } }) => {
  const { data: sizes } = useSizes();
  return (
    <Grid container className={classes.summaryContent}>
      <Grid item xs={12}>
        <InfoLabel
          classes={classes}
          label="Name"
          isCurrent={currentStep === 2}
          value={joinFields([currentInfo.first_name, currentInfo.last_name], ' ')}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoLabel classes={classes} label="Email" isCurrent={currentStep === 3} value={currentInfo.email} />
      </Grid>
      <Grid item xs={12}>
        <InfoLabel classes={classes} label="Company" isCurrent={currentStep === 4} value={currentInfo.company_name} />
      </Grid>
      <Grid item xs={12}>
        <InfoLabel
          classes={classes}
          label="Department/Position"
          isCurrent={currentStep === 5}
          value={
            currentInfo.department && `${currentInfo.department}${currentInfo.title ? `/${currentInfo.title}` : ''}`
          }
        />
      </Grid>
      <Grid item xs={12}>
        <InfoLabel
          classes={classes}
          label="Location"
          isCurrent={currentStep === 6}
          value={currentInfo.shipping_country ? getUSAorDefault(currentInfo.shipping_country) : undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoLabel
          classes={classes}
          label="Address"
          isCurrent={currentStep === 7}
          value={currentInfo.shipping_address1 && getEmployeeShipAddress(currentInfo)}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoLabel
          classes={classes}
          label="Phone"
          isCurrent={getIsCurrent(8, currentStep, currentInfo.shipping_country)}
          value={currentInfo.phone_number}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoLabel
          classes={classes}
          label="Shirt Size"
          isCurrent={getIsCurrent(9, currentStep, currentInfo.shipping_country)}
          value={getSize(sizes, currentInfo.size)}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoLabel
          classes={classes}
          label="Your goal"
          isCurrent={getIsCurrent(10, currentStep, currentInfo.shipping_country)}
          value={currentInfo.how_do_you_plan_to_use_swag_packs}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoLabel
          classes={classes}
          label="Quantity"
          isCurrent={getIsCurrent(11, currentStep, currentInfo.shipping_country)}
          value={currentInfo.packs_per_year}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  onboardingInfo: state.onboardingInfo
});

export default connect(mapStateToProps)(withStyles(styles)(SummaryContent));
