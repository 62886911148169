export const MenuIcon = () => (
  <svg className="svg-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1652_25475)">
      <path
        d="M7.33333 9.44657L5.88667 7.9999L7.33333 6.55323L6.39333 5.60657L4 7.9999L6.39333 10.3932L7.33333 9.44657Z"
        fill="black"
      />
      <path
        d="M9.60699 10.3932L12.0003 7.9999L9.60699 5.60657L8.66699 6.55323L10.1137 7.9999L8.66699 9.44657L9.60699 10.3932Z"
        fill="black"
      />
      <path
        d="M12.6667 1.99996H9.88C9.6 1.22663 8.86667 0.666626 8 0.666626C7.13333 0.666626 6.4 1.22663 6.12 1.99996H3.33333C3.24 1.99996 3.15333 2.00663 3.06667 2.02663C2.80667 2.07996 2.57333 2.21329 2.39333 2.39329C2.27333 2.51329 2.17333 2.65996 2.10667 2.81996C2.04 2.97329 2 3.14663 2 3.33329V9.99996V10.6666V12.6666C2 12.8466 2.04 13.0266 2.10667 13.1866C2.17333 13.3466 2.27333 13.4866 2.39333 13.6133C2.57333 13.7933 2.80667 13.9266 3.06667 13.98C3.15333 13.9933 3.24 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6666V10.6666V9.99996V3.33329C14 2.59996 13.4 1.99996 12.6667 1.99996ZM8 1.83329C8.27333 1.83329 8.5 2.05996 8.5 2.33329C8.5 2.60663 8.27333 2.83329 8 2.83329C7.72667 2.83329 7.5 2.60663 7.5 2.33329C7.5 2.05996 7.72667 1.83329 8 1.83329ZM12.6667 9.99996V10.6666V12.6666H3.33333V10.6666V9.99996V3.33329H12.6667V9.99996Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1652_25475">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
