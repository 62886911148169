import { scrollBar } from '../../../shared/styles/commonStyles';

const styles = theme => ({
  exportButtom: {
    padding: '0px 12px',
    fontFamily: 'Gilroy',
    cursor: 'pointer',
    color: '#3577d4',
    '&:disabled': {
      color: 'ebeef2'
    }
  },
  productSelectionDrawer: {
    width: 864
  },
  productEditHeaderSection: {
    padding: '0px 100px 24px 48px'
  },
  productEditFooterSection: {
    padding: '32px 100px 24px 48px'
  },
  productEditContentSection: {
    padding: '0px 100px 24px 48px',
    height: 'calc(100vh - 180px)',
    ...scrollBar
  },
  inputTextMultiline: {
    '& .MuiOutlinedInput-multiline': {
      padding: '14px 16px'
    }
  },
  createUpdatePageTitle: { color: '#0B1829' },
  inputLabel: { marginBottom: 8, cursor: 'default' },
  inputLabelLight: { marginBottom: 0, cursor: 'default', color: '#4A4F54', position: 'absolute', top: -18 },
  formControl: {
    marginTop: 0,
    marginLeft: 0,
    alignItems: 'flex-start',
    width: '100%',
    '& > div': {
      width: '100%'
    },
    '& .MuiFormLabel-root': {
      marginTop: -6
    }
  },
  checkBoxFormControl: {
    marginTop: 0,
    marginLeft: 0,
    alignItems: 'center',
    width: '100%',
    '& > div': {
      width: '100%'
    },
    '& .MuiFormLabel-root': {
      marginTop: -6
    }
  },
  selectField: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    '& .MuiPaper-root': {
      height: '320px !important'
    },
    '& .MuiSelect-root': {
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    }
  },
  shareModalMain: { minHeight: '477px' },
  confirmModal: {
    height: 'auto',
    maxHeight: 'fit-content',
    width: 400,
    margin: 'auto',
    background: '#fff',
    padding: 0,
    outline: 'none'
  },
  confirmModalHeader: { height: '32px' },
  confirmModalMain: { padding: '0px 32px' },
  confirmModalContent: { padding: '12px 0px' },
  statusBox: {
    paddingTop: '48px',
    borderRight: '1px solid #E5E7E8',
    width: '140px',
    height: '100%'
  },
  confirmModalTitle: {
    paddingBottom: 32
  }
});
export default styles;
