import * as React from 'react';
import log from '../logger';

const refetchIntervalSequence = [6, 8, 10].map(n => n * 1000);

const useOpportunityRefetchInterval = (isOrderView, productPricesInProcess, isFetching) => {
  const [refetchIndex, setRefetchIndex] = React.useState(-1);
  const hasRefetchStarted = React.useRef(false);

  React.useEffect(() => {
    if (isOrderView && productPricesInProcess) {
      if (!hasRefetchStarted.current) {
        hasRefetchStarted.current = true;
        setRefetchIndex(0);
      } else if (!isFetching) {
        setRefetchIndex(prev => prev + 1);
      }
    } else {
      setRefetchIndex(-1);
      hasRefetchStarted.current = false;
    }
  }, [productPricesInProcess, isOrderView, isFetching]);

  React.useEffect(() => {
    if (refetchIndex === refetchIntervalSequence.length) {
      log.debug('resetting refetchIndex & hasRefetchStarted');
      setRefetchIndex(-1);
      hasRefetchStarted.current = false;
    }
  }, [refetchIndex]);

  log.debug('productPricesInProcess:', productPricesInProcess, 'isFetching:', isFetching);
  log.debug('refetchIndex:', refetchIndex, 'hasRefetchStarted:', hasRefetchStarted.current);
  log.debug('nextRefetchInterval:', refetchIntervalSequence[refetchIndex]);

  const nextRefetchInterval = refetchIntervalSequence[refetchIndex];
  return { nextRefetchInterval, hasRefetchStarted: hasRefetchStarted.current };
};

export default useOpportunityRefetchInterval;
