const styles = theme => ({
  container: {
    paddingBottom: 42,
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: { paddingTop: 0 }
  },
  center: {
    paddingTop: '40px !important',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      paddingLeft: '0 !important',
      minWidth: '1280px'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1120px',
      paddingLeft: '0 !important',
      minWidth: '1120px'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '960px',
      minWidth: '960px'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '320px',
      paddingRight: 20,
      paddingLeft: '20px !important'
    }
  },
  pPageTitle: {
    fontFamily: 'Gilroy-bold',
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 0.71,
    color: '#0f2440',
    marginBottom: 36
  },
  GoBackOneContainer: { textAlign: 'center' },
  UploadCSVContainer: { textAlign: 'center' },
  GoBack: {
    width: 305,
    height: 56,
    borderRadius: 32,
    fontFamily: 'Gilroy',
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none',
    lineHeight: 1.75,
    backgroundColor: 'transparent',
    textAlign: 'center',
    color: '#1f1d1d',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  ContinueContainer: {
    float: 'right',
    paddingRight: 4
  },
  Continue: {
    width: 305,
    height: 56
  },
  // Review options later
  MultiShippingCardOverview: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    paddingTop: 43,
    marginBottom: 26,
    maxWidth: 634
  },
  ItemContainer: {
    padding: '18px 0'
  },
  CardHeaderBorder: {
    padding: '0 43px 20px 43px',
    borderBottom: 'solid 1px #ebecf5',
    marginBottom: 18
  },
  CardHeader: {
    padding: '0 43px 20px 43px',
    marginBottom: 18
  },
  CardContent: {
    padding: '0 43px 20px 43px'
  },
  ColapseContentContainer: {
    maxWidth: 360
  },
  HeaderTitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    color: '#a2abb8',
    padding: '14px 0'
  },
  HeaderContent: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.43,
    color: '#434c5f'
  },
  SmallItemImg: {
    width: 48,
    height: 48,
    borderRadius: 5,
    border: 'solid 1px #a2abb8',
    margin: '0 auto',
    '& img': {
      width: '100%'
    }
  },
  AmountToShip: {
    textAlign: 'right',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.43,
    color: '#434c5f',
    paddingTop: 14,
    paddingRight: 15
  },
  ItemDescription: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434c5f'
  },
  ItemSpecs: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    color: '#434c5f'
  },
  checkoutSideBarContainer: {
    [theme.breakpoints.down('sm')]: { marginTop: 40 }
  }
});
export default styles;
