import React from 'react';
import { makeStyles, Typography, Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { isOneSize } from '../../../helpers/utils';
import { ImgWithHandler } from '../../global/ImgUtils';
import styles from './styles/Product';

const useStyles = makeStyles(styles);

const Product = ({ name, image, selected, quantities, error, onSelect }) => {
  const classes = useStyles({ selected });

  return (
    <Grid item className={clsx(classes.container, { selectable: !error })}>
      <Grid container justifyContent="center" className={classes.card}>
        {error && (
          <div className={classes.unselectable}>
            <Typography variant="body1">{error}</Typography>
          </div>
        )}
        <ImgWithHandler src={image} width={256} height={256} className={classes.image} />
        <Grid container className={classes.quantities}>
          {isOneSize(quantities) ? (
            <Typography variant="body1">One Size: {quantities[0].quantity}</Typography>
          ) : (
            quantities.map((q, i) => (
              <Grid key={q.size.id} item xs={6}>
                <Grid container justifyContent={i % 2 === 0 ? 'flex-start' : 'flex-end'}>
                  <Grid container style={{ maxWidth: 70 }}>
                    <Grid item>
                      <Typography variant="body1">{q.size.name}: </Typography>
                    </Grid>
                    <Grid item style={{ textAlign: 'end', flex: 1 }}>
                      <Typography variant="body1">{q.quantity}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" style={{ marginTop: 10 }}>
        <Typography variant="body1" className={classes.productName}>
          {name}
        </Typography>
        <IconButton className={classes.selectBtn} disabled={Boolean(error)} onClick={onSelect}>
          {selected ? (
            <CheckIcon fontSize="small" style={{ color: '#ffffff' }} />
          ) : (
            <AddIcon color="primary" fontSize="small" />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Product;
