import * as React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation, useParams, useHistory, Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cleanShipmentGroup } from '../../../actions/shipmentGroupActions';
import TopBar from '../../shared/TopBar';
import Stepper from '../../shared/Stepper';
import SelectQuantities from './SelectQuantities';
import SelectProducts from './SelectProducts';
import { createReorderProducts } from '../../../actions';

const steps = [
  { step: 'select-products', label: 'Select Products' },
  { step: 'select-quantities', label: 'Select Sizes and Quantities' },
  { step: 'order', label: 'Review Order' }
];

const ReorderHome = () => {
  const { step } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      const isLeavingSendSwag = ['/reorder', '/import-contacts/'].every(
        path => !history.location.pathname.includes(path)
      );
      if (isLeavingSendSwag) {
        dispatch(cleanShipmentGroup);
        dispatch(createReorderProducts([]));
      }
    };
  }, [dispatch, history]);

  const state = location.state ?? { from: { pathname: '/inventory' } };

  const stepNumber = steps.findIndex(s => s.step === step);
  const handleGoBack = () => {
    if (location.state?.from) {
      history.goBack();
    } else if (stepNumber === 0) {
      history.push({ pathname: '/inventory' });
    } else {
      history.replace({ pathname: `/reorder/${steps[stepNumber - 1]}`, state });
    }
  };

  const handleClickStepper = location.state?.from
    ? undefined
    : stepIndex => history.replace({ pathname: `/reorder/${steps[stepIndex]}`, state });

  const heightProp = step === 'select-quantities' ? 'minHeight' : 'height';

  const { leftBarNavigation } = useFlags();

  const { leftNavOpen } = useSelector(state => state.commonReducer);

  return (
    <Box
      {...{ [heightProp]: '100vh' }}
      display="flex"
      flexDirection="column"
      marginLeft={leftBarNavigation ? (leftNavOpen ? 50 : 10) : 0}
    >
      <TopBar onGoBack={handleGoBack}>
        <Stepper steps={steps} active={stepNumber} onClick={handleClickStepper} />
      </TopBar>
      <Switch>
        <Route path="/reorder/select-products">
          <Box flex={1} minHeight={0}>
            <SelectProducts />
          </Box>
        </Route>
        <Route path="/reorder/select-quantities/:ids">
          <SelectQuantities />
        </Route>
        <Redirect to="/reorder/select-products" />
      </Switch>
    </Box>
  );
};

export default ReorderHome;
