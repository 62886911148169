import { CREATE_ORDER, CHANGE_BAG_OPEN_STATUS, REMOVE_BAG_SUBITEM } from './types';

export const createOrder = order => async dispatch =>
  dispatch({
    type: CREATE_ORDER,
    payload: {
      ...order,
      cost: order.packs.reduce((sum, pack) => sum + pack.cost, 0)
    }
  });

export const changeBagOpenStatus = status => async dispatch =>
  dispatch({
    type: CHANGE_BAG_OPEN_STATUS,
    payload: status
  });

export const removeBagSubItem = (idBag, idItem) => async dispatch =>
  dispatch({
    type: REMOVE_BAG_SUBITEM,
    payload: { idBag, idItem }
  });
