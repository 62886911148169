import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: 120
  },
  input: {
    fontSize: 20,
    fontWeight: 500,
    cursor: 'default',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    },
    '& > input': {
      textAlign: 'center',
      cursor: 'default'
    },
    '&:before': {
      content: 'none'
    }
  },
  button: {
    width: 28,
    height: 28,
    fontFamily: 'Gilroy',
    fontSize: 12,
    backgroundColor: '#f4f8ff',
    borderRadius: 10,
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:hover': {
      backgroundColor: '#EDEDFC'
    },
    '& .MuiSvgIcon-root': {
      width: 14
    }
  }
}));
