const styles = {
  linearColorPrimary: {
    backgroundColor: '#aabfdc',
    height: '9px'
  },
  linearBarColorPrimary: {
    backgroundColor: '#3577d4'
  },
  progressText: {
    fontFamily: 'Futura',
    fontSize: '12px',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#3577d4',
    height: '15px',
    paddingBottom: '19px'
  },
  linearColorPrimarySlim: {
    backgroundColor: '#aabfdc',
    height: '4px'
  }
};

export default styles;
