import React from 'react';
import { Grid, TableRow, TableCell } from '@material-ui/core';
import round from 'lodash/round';
import {
  sumByQuantityParseInt,
  isPack,
  getProductPrice,
  sizesWithQuantities,
  getShippingPrice,
  moneyStr
} from '../../helpers/utils';
import { storageTypes } from './storageTypes';
import { productionTime } from '../../apis/constants';
import log from '../../logger';
import { ImgWithHandler } from '../global/ImgUtils';

export const getProductSizes = product => product.sizes || product.quantities_per_size;

export const productionTimeText = proof => {
  if (proof.production_time.length === 0) {
    return '';
  }
  const { product } = proof;
  const prodTimeDays =
    proof.production_time === productionTime.standard ? product.standard_turnaround_time : product.rush_turnaround_time;
  return `${proof.production_time} ${prodTimeDays} days`;
};

export const getStoragePrice = (product, storagePrices) => {
  const storagePrice = storagePrices.find(s => s.storage_category === product.storage_category);
  return parseFloat(storagePrice.price || storagePrice.price_without_discount);
};

export const quantityPriceStorageShipping = (
  proof,
  storagePrices,
  storageType,
  isInternational,
  individualPackPrices
) => {
  log.debug('quantityPriceStorageShipping, proof:', proof);
  const product = proof.product || proof; // "proof" here can be an invoice product
  const sizes = getProductSizes(proof);
  const productQty = sumByQuantityParseInt(sizes);
  const productPrice = getProductPrice(proof);
  const productRushFee = getProductPrice(proof, 'rush_fee');
  const productPriceBeforeDiscount = getProductPrice(proof, 'price_without_discount');
  const productRushFeeBeforeDiscount = getProductPrice(proof, 'rush_fee_without_discount');
  const storagePrice = storageType ? getStoragePrice(product, storagePrices) : 0;
  const shipping = isPack(product.record_type) ? getShippingPrice(isInternational, individualPackPrices) : 'TBD';
  const estimatedShippingPrice = storageType === storageTypes.creditForShipping ? shipping : 0;
  log.debug('productQty:', productQty, 'productPrice:', productPrice, 'storagePrice:', storagePrice);
  return {
    sizes,
    productQty,
    productPrice,
    productRushFee,
    storagePrice,
    estimatedShippingPrice,
    productPriceBeforeDiscount,
    productRushFeeBeforeDiscount
  };
};

const ProductSummary = ({
  product,
  productSizes,
  productQty,
  productProduction,
  productPriceOutput,
  productRushFee,
  totalPriceOutput,
  classes
}) => (
  <TableRow className={classes.tableRow}>
    <TableCell colSpan={2} className={classes.tableCell}>
      <Grid container>
        <Grid item>
          <div className={classes.imgContainer}>
            <ImgWithHandler src={product.image} alt={product.name} width={78} height={78} />
          </div>
        </Grid>
        <Grid item xs style={{ paddingLeft: 16 }}>
          <p className={classes.packTitle}>
            <b>{product.name}</b>
          </p>
          <p className={classes.itemDesc}>Color: {product.theme_color || 'Custom'}</p>
          <p className={classes.itemDesc}>Size: {productSizes}</p>
          <p className={classes.itemDesc}>Qty: {productQty}</p>
          <p className={classes.itemDesc}>Production: {productProduction}</p>
          <p className={classes.itemDesc}>Price: {productPriceOutput}</p>
          {productRushFee !== 0 && <p className={classes.itemDesc}>Rush production: {moneyStr(productRushFee)}</p>}
          <p className={classes.packTotal}>Total: {totalPriceOutput}</p>
        </Grid>
      </Grid>
    </TableCell>
  </TableRow>
);

export const ListProducts = ({ proof, storagePrices, storageType, isInternational, individualPackPrices, classes }) => {
  log.debug('ListProducts called with proof:', proof);
  const p = quantityPriceStorageShipping(proof, storagePrices, storageType, isInternational, individualPackPrices);
  const { sizes, productQty, productPrice, productRushFee, storagePrice } = p;
  const price = round(productPrice + productRushFee, 2);
  const totalProductPrice = round(productQty * price, 2);
  return (
    <ProductSummary
      product={proof.product}
      productSizes={sizesWithQuantities(sizes)}
      productQty={productQty}
      productProduction={productionTimeText(proof)}
      productPriceOutput={moneyStr(productPrice)}
      productRushFee={productRushFee}
      productStorage={moneyStr(storagePrice)}
      totalPriceOutput={moneyStr(totalProductPrice)}
      classes={classes}
    />
  );
};

export const multishippingProductTotal = (sizesQty, product, storagePrice, shipping) =>
  sizesQty * (getProductPrice(product) + storagePrice + (typeof shipping === 'number' ? shipping : 0));

export const formatDate = date => `${date.getDate()}/${date.getMonth() + 1}`;

export const buildShippingOrderProofsOrAP = items => ({
  account_products: items.map(p => ({
    account_product: p.product.id,
    production_time: p.production_time,
    sizes: p.sizes
      .filter(s => s.quantity > 0)
      .map(s => ({
        size: s.size.id,
        quantity: s.quantity
      }))
  }))
});
