import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography } from '@swagup-com/components';
import { DefaultPaymentProfile, StyledTab, StyledTabs, SwagCard } from './common';
import { useCompany } from '../../../../../../hooks';
import { paymentMethods } from '../../../../../../apis/constants';

const useStyles = makeStyles({
  paymentMethodsPanel: {},
  cardPaymentPanel: { paddingTop: 24 },
  swagCardPanel: {
    minHeight: 0
  }
});

const TabPanel = ({ selected, children }) => {
  return selected ? children : <div style={{ minHeight: 220 }} />;
};

const ShippingPaymentMode = ({
  inputValue,
  currentBalance,
  setInputValue,
  useCreditFirst,
  setUseCreditFirst,
  setInvalid,
  customOrder
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [method, setMethod] = useState(paymentMethods.creditCard);
  const classes = useStyles();

  const { data: company } = useCompany();
  const { payment_profile: defaultProfile } = company || {};

  const handlePaymentMethodChange = newMethod => {
    window.sessionStorage.setItem('method', newMethod);
  };

  useEffect(() => {
    handlePaymentMethodChange(method);
    return () => {
      window.sessionStorage.removeItem('method');
      window.sessionStorage.removeItem('updatePaymentProfile');
    };
  }, []);

  const handleChange = (event, newValue) => {
    const tabIndex = [paymentMethods.creditCard, paymentMethods.ach, paymentMethods.wireTransfer].indexOf(newValue);
    setCurrentTab(tabIndex);
    handlePaymentMethodChange(newValue);
    setMethod(newValue);
  };
  const { allowToPayWithCredits } = useFlags();

  return (
    <Grid style={{ margin: '20px 40px' }}>
      <Box className={classes.paymentMethodsPanel}>
        {allowToPayWithCredits && (
          <Box className={classes.swagCardPanel}>
            <SwagCard
              currentBalance={currentBalance}
              inputValue={inputValue}
              setInputValue={setInputValue}
              useCreditFirst={useCreditFirst}
              setUseCreditFirst={setUseCreditFirst}
              setInvalid={setInvalid}
              customOrder={customOrder}
            />
          </Box>
        )}
        <Box className={classes.cardPaymentPanel} style={{ paddingTop: allowToPayWithCredits ? undefined : 0 }}>
          <Typography variant="body2SemiBoldInter" style={{ paddingBottom: 18 }}>
            Pay the remaining amount with
          </Typography>
          <StyledTabs value={method} onChange={handleChange}>
            <StyledTab label="Credit card" value={paymentMethods.creditCard} />
          </StyledTabs>
          <TabPanel selected={currentTab === 0}>
            <DefaultPaymentProfile paymentProfile={defaultProfile} />
          </TabPanel>
        </Box>
      </Box>
    </Grid>
  );
};

export default ShippingPaymentMode;
