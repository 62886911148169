import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import clsx from 'clsx';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SwipeableViews from 'react-swipeable-views';
import styles from './styles/existingRecipientsModal';
import { Modal } from '../../../shared/Modal';
import { StylessButton } from '../../../buttons';
import useMembershipCheck from '../../../../hooks/useMembershipCheck';
import redeemServicesPaths from '../../../../helpers/redeemServicesPaths';
import { CustomSlimTextFieldThin, GreenAntSwitch } from '../../../storefronts/storeCommon';
import { imageSrcSet } from '../../../../helpers/utils';
import { CustomTextField } from '../../redeemCommon';
import { redeemPages } from '../../../../apis/redeemServices';
import LimitedTextField from '../../../global/LimitedTextField';
import EmailPreview from '../../../integrationsDev/integrationFlow/EmailPreview';
// import { BlueSwitch } from '../../account/AccountSharedComponents';

const useModalStyles = makeStyles(styles);

const Header = ({ classes }) => (
  <TableHead>
    <TableRow>
      <TableCell className={clsx(classes.headerCell, classes.firstCell)}>First Name</TableCell>
      <TableCell className={classes.headerCell}>Last Name</TableCell>
      <TableCell className={classes.headerCell}>Email</TableCell>
      <TableCell className={classes.headerCell}>Credits</TableCell>
    </TableRow>
  </TableHead>
);

const Body = ({ classes, contacts = [] }) => (
  <TableBody className={classes.tableBody}>
    {contacts.map(row => (
      <TableRow key={row.firstName}>
        <TableCell className={clsx(classes.bodyCell, classes.firstCell)}>{row.first_name}</TableCell>
        <TableCell className={classes.bodyCell}>{row.last_name}</TableCell>
        <TableCell className={classes.bodyCell}>{row.email}</TableCell>
        <TableCell className={classes.bodyCell}>
          <span style={{ color: parseFloat(row.amount) > 0 ? '#4CAF50' : '#C62828' }}>${row.amount}</span>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

const CustomMessage = ({ customMessageConfig, onActionUpdate }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [openPreview, setOpenPreview] = useState(false);

  const handleSubjectInputChange = event => {
    const { value } = event.target;
    onActionUpdate({
      customSubject: value
    });
  };

  const handleInputChange = event => {
    const { value } = event.target;
    onActionUpdate({
      customMessage: value
    });
  };

  const classes = useModalStyles();
  return (
    <Box className={classes.modalContent}>
      <Grid container justifyContent="center" style={{ paddingTop: 12 }}>
        <Grid item xs={12}>
          <Box className={classes.customMessageForm}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="subtitle3SemiBoldInter">Add custom message</Typography>
              </Grid>
              <Grid item>
                <GreenAntSwitch
                  checked={customMessageConfig.showCustomMessage}
                  onChange={({ target: { checked } }) =>
                    onActionUpdate({
                      showCustomMessage: checked
                    })
                  }
                  value={customMessageConfig.showCustomMessage}
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Box style={{ marginLeft: 16, height: 16 }}>
                  <StylessButton onClick={() => setOpenPreview(true)}>
                    <img
                      alt="Store-Front-Preview"
                      src="/images/storefront/store-front-preview.png"
                      srcSet={imageSrcSet('/images/storefront/store-front-preview.png')}
                      width={16}
                      height={16}
                    />
                  </StylessButton>
                </Box>
              </Grid>
            </Grid>
            <Box style={{ paddingTop: 8 }}>
              <Typography variant="body3RegularInter" className={classes.appreciationTex}>
                Keep in mind that recipients will read this message when receiving their redemption link. It’s a nice
                way to say “I appreciate you”!
              </Typography>
            </Box>
            {customMessageConfig.showCustomMessage && (
              <Box style={{ paddingTop: 12 }}>
                <Typography variant="body3SemiBoldInter" className={classes.textForm}>
                  Subject line *
                </Typography>
                <CustomTextField
                  value={customMessageConfig.customSubject}
                  placeholder="Add custom subject"
                  onChange={handleSubjectInputChange}
                  className={classes.customSubject}
                  fullWidth
                />
                <Typography variant="body3SemiBoldInter" className={classes.textForm} style={{ marginTop: 12 }}>
                  Message *
                </Typography>
                <LimitedTextField
                  value={customMessageConfig.customMessage}
                  placeholder="Add your message"
                  maxLength={500}
                  onChange={handleInputChange}
                  className={classes.longInputText}
                  fullWidth
                />
                <Typography variant="body3RegularInter" style={{ paddingTop: '10px', color: '#F44336' }}>
                  {errorMsg}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <EmailPreview
        open={openPreview}
        message={customMessageConfig.customMessage}
        onClose={() => setOpenPreview(false)}
      />
    </Box>
  );
};

const buildRecipientPayload = (contacts, settings) => {
  const payload = contacts.map(c => ({
    platformId: c.id,
    firstName: c.first_name || c.firstName || undefined,
    lastName: c.last_name || c.lastName || undefined,
    email: c.email?.toLowerCase() || c.emailAddress || undefined,
    ...settings
  }));
  return payload;
};

const buildSendEmailPayload = (contacts, settings) => {
  const payload = contacts.map(c => ({
    id: c.id,
    email: c.email?.toLowerCase() || c.emailAddress || undefined,
    ...settings
  }));
  return payload;
};

const ExistingRecipientsModal = ({
  open,
  onClose,
  contactsToAdd = [],
  pageId,
  csvImportError,
  uploadKind,
  onReload,
  selectedItems,
  sendingCase
}) => {
  const [customersToShow, setCustomersToShow] = useState([]);
  // const [isCleanAdding, setIsCleanAdding] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [showErrors, setShowErrors] = useState(false);
  const [customMessageConfig, setCustomMessageConfig] = useState({});
  const [uploadStatus, setUploadStatus] = useState();

  const uploadType = csvImportError ? 'error' : uploadKind;
  console.log('xxx: ', csvImportError, uploadType, sendingCase);

  const queryClient = useQueryClient();

  const addRecipientsByEmail = useMutation(params => redeemPages.createUniqueUrlBulk(pageId, params), {
    onSuccess: res => {
      onReload(false);
      setUploadStatus('successful');
      setCurrentStep(2);
      setCustomMessageConfig({});
    }
  });

  const sendRecipientsByEmail = useMutation(params => redeemPages.sendEmailBulk(pageId, params), {
    onSuccess: res => {
      onReload(false);
      setUploadStatus('successful');
      setCurrentStep(2);
      setCustomMessageConfig({});
    }
  });

  useEffect(() => {
    if (open && uploadType !== 'error') setCurrentStep(1);
  }, [open, uploadType]);

  useEffect(() => {
    setCustomersToShow(contactsToAdd);
  }, [contactsToAdd]);

  const handleOnOnAddCustomer = data => {
    const { customMessage, customSubject, showCustomMessage } = data;
    const settings = {
      redeemPageId: pageId,
      customMessage: showCustomMessage ? customMessage : '',
      customSubject: showCustomMessage ? customSubject : ''
    };

    const payload =
      sendingCase === 'resend' ? buildSendEmailPayload(selectedItems) : buildRecipientPayload(contactsToAdd);

    if (sendingCase === 'resend') sendRecipientsByEmail.mutate({ recipients: payload, ...settings });
    else addRecipientsByEmail.mutate({ recipients: payload, ...settings });
  };

  const handleOnAddNewCustomers = () => {
    handleOnOnAddCustomer({
      recipients: customersToShow,
      ...customMessageConfig
    });
    // onClose();
  };

  useEffect(() => {
    if (uploadType === 'error') setCurrentStep(2);
    else setCurrentStep(1);
  }, [uploadType]);

  const handleOnClose = param => {
    onClose(param);
    queryClient.invalidateQueries(redeemServicesPaths.redemptions(pageId));
  };

  const onActionUpdate = data => {
    setCustomMessageConfig(prev => ({ ...prev, ...data }));
  };

  const users = sendingCase === 'resend' ? selectedItems.length : contactsToAdd.length;

  const isSendDisabled = customMessageConfig.showCustomMessage
    ? !customMessageConfig.customMessage || !customMessageConfig.customSubject
    : false;

  const classes = useModalStyles();

  return (
    <Modal
      // title="The customers below already exist"
      open={open}
      onClose={handleOnClose}
      className={classes.modal}
      titleClass={classes.title}
      subtitleClass={classes.subtitle}
      closeButtonClass={classes.closeButton}
      actions={
        currentStep === 1 ? (
          <Grid container justifyContent="center" style={{ paddingBottom: 12 }}>
            <Grid item>
              <Grid container justifyContent="center" style={{ paddingBottom: 12, width: 364 }}>
                <Grid item>
                  <Button size="small" variant="text" onClick={handleOnClose} className={classes.cancelButton}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs />
                <Grid item>
                  <Button
                    size="small"
                    variant="primary"
                    onClick={handleOnAddNewCustomers}
                    disabled={isSendDisabled}
                    loading={addRecipientsByEmail.isLoading}
                    style={{ width: addRecipientsByEmail.isLoading ? 182 : 164 }}
                  >
                    {`Send email${users > 1 ? 's' : ''}`}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center" style={{ paddingBottom: 12 }}>
            <Grid item>
              <Grid container justifyContent="center" style={{ paddingBottom: 12, width: 300 }}>
                <Grid item>
                  <Button
                    size="small"
                    variant="primary"
                    onClick={() => handleOnClose({ retry: uploadStatus !== 'successful' })}
                    style={{ width: 232 }}
                  >
                    {uploadType !== 'error' ? 'Back to redeem page' : 'Try again'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    >
      <Box>
        <SwipeableViews
          axis="x"
          index={currentStep - 1}
          className={classes.swipeableViews}
          onChange={(event, newValue) => (newValue ? setCurrentStep(newValue) : false)}
          disabled
        >
          <Grid container justifyContent="center" className={classes.modalContent}>
            <Grid item>
              <Typography variant="body3RegularInter" className={classes.text}>
                {`${users} user${users > 1 ? 's' : ''} selected`}
              </Typography>
              <Typography variant="h5SemiBoldInter" className={classes.textTitle}>
                Email Recipients
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {currentStep === 1 && (
                <CustomMessage customMessageConfig={customMessageConfig} onActionUpdate={onActionUpdate} />
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="center" className={classes.modalContent}>
            <Grid item xs={8}>
              <Grid container justifyContent="center">
                <Grid item>
                  {currentStep === 2 && (
                    <Box className={classes.successfulWrapper}>
                      <img
                        src={`/images/storefront/${uploadType !== 'error' ? 'csv-successful.png' : 'csv-failed.png'}`}
                        alt="successful"
                        className={classes.successfulImage}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h5SemiBoldInter" className={classes.textTitle}>
                {uploadType === 'error' ? (
                  <span>Your CSV failed to upload</span>
                ) : (
                  'Redeem page link was successfully sent'
                )}
              </Typography>
              {sendingCase !== 'resend' && (
                <Typography variant="body3RegularInter" className={classes.text} style={{ marginBottom: 0 }}>
                  {uploadType !== 'error' ? (
                    ''
                  ) : (
                    <>
                      <span>Please confirm you're using the template provided</span>
                      <br />
                      <span>and each row is filled in properly.</span>
                    </>
                  )}
                </Typography>
              )}
              {uploadType === 'error' && (
                <Grid container justifyContent="center" style={{ marginTop: 12 }}>
                  <Grid item>
                    <StylessButton onClick={() => setShowErrors(prev => !prev)}>
                      {showErrors ? 'Hide errors' : 'Show errors'}
                    </StylessButton>
                  </Grid>
                </Grid>
              )}
              {uploadType === 'error' && showErrors && (
                <div
                  className={classes.errorList}
                  dangerouslySetInnerHTML={{ __html: csvImportError?.replaceAll('\n', '<br />') }}
                />
              )}
            </Grid>
          </Grid>
        </SwipeableViews>
      </Box>
    </Modal>
  );
};

export default ExistingRecipientsModal;
