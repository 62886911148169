import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, FormControlLabel, Box, MenuItem, IconButton } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import _, { isEmpty } from 'lodash';
import { Add, Delete } from '@material-ui/icons';
import styles from '../styles/storefrontsHome';
import { CustomTextField, RedeemCustomSelectField } from '../storeCommon';
// import CheckBoxFormControl from '../../global/CheckBoxFormControl';
import { BlueSwitch } from '../../account/AccountSharedComponents';

const useStyles = makeStyles(styles);

const maxLinks = 5;
const linkTypes = ['Internal', 'External']; // 'Yes or No',

const internalLinks = [
  { name: 'Home', path: '/' },
  { name: 'Products', path: '/products' }
];

const LinkRow = ({ link, onAdd, onRemove, onUpdate, isLast, classes }) => {
  const [updatedCustomLink, setUpdatedCustomLink] = useState({});
  // const [optionsSameAsLabel, setOptionsSameAsLabel] = useState(true);

  useEffect(() => {
    if (isEmpty(updatedCustomLink) && !isEmpty(link)) setUpdatedCustomLink(link);
  }, [link]);

  useEffect(() => {
    if (!isEmpty(updatedCustomLink) && !isEmpty(link)) onUpdate(updatedCustomLink);
  }, [updatedCustomLink]);

  const handleOnLinkTextChange = ({ target: { value } }) => {
    setUpdatedCustomLink(prev => ({ ...prev, text: value }));
  };

  const handleOnLinkTypeChange = type => {
    setUpdatedCustomLink(prev => ({ ...prev, type }));
  };

  const handleOnLinkUrlChange = ({ target: { value } }) => {
    setUpdatedCustomLink(prev => ({ ...prev, url: value }));
  };

  // const handleOnOptionAdd = newOption => {
  //   setUpdatedCustomQuestion(prev => ({
  //     ...prev,
  //     options: [...(prev.options || []), { ...newOption, id: (prev.options?.length || 0) + 1 }]
  //   }));
  // };

  // const handleOnOptionRemove = option => {
  //   setUpdatedCustomQuestion(prev => ({ ...prev, options: prev.options.filter(o => o.id !== option.id) }));
  // };

  // const handleOnOptionUpdate = option => {
  //   setUpdatedCustomQuestion(prev => ({ ...prev, options: prev.options.map(o => (o.id === option.id ? option : o)) }));
  // };

  const handleOnAdd = () => {
    onAdd(updatedCustomLink);
    setUpdatedCustomLink({});
  };

  const isDisabled = () => {
    if (isEmpty(updatedCustomLink)) return true;
    if (
      isEmpty(link) &&
      (isEmpty(updatedCustomLink.text) || isEmpty(updatedCustomLink.type) || isEmpty(updatedCustomLink.url))
    )
      return true;
    return false;
  };

  return (
    <Box style={{ marginBottom: 32, paddingBottom: 4, borderBottom: isLast ? undefined : '1px solid #EBEDF0' }}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <FormControlLabel
                className={classes.formControl}
                labelPlacement="top"
                control={
                  <CustomTextField
                    className={classes.inputText}
                    placeholder="Link Text"
                    value={updatedCustomLink.text || ''}
                    name="text"
                    onChange={handleOnLinkTextChange}
                    fullWidth
                  />
                }
                label={
                  <Typography variant="body3RegularInter" className={classes.inputLabel}>
                    Link Text
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                className={classes.formControl}
                labelPlacement="top"
                control={
                  <RedeemCustomSelectField
                    id="type"
                    name="type"
                    label="Link Type"
                    value={updatedCustomLink.type || 'Link Type'}
                    className={classes.selectField}
                    totalItems={linkTypes.length}
                    handleSelect={({ target: { value } }) => handleOnLinkTypeChange(value)}
                    fullWidth
                    withTooltip
                  >
                    {linkTypes.map(lk => (
                      <MenuItem key={lk} value={lk} title={lk}>
                        {lk}
                      </MenuItem>
                    ))}
                  </RedeemCustomSelectField>
                }
                label={
                  <Typography variant="body3RegularInter" className={classes.inputLabel}>
                    Link Type
                  </Typography>
                }
              />
            </Grid>
            {updatedCustomLink.type?.includes('Internal') ? (
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.formControl}
                  labelPlacement="top"
                  control={
                    <RedeemCustomSelectField
                      id="type"
                      name="type"
                      label="Internal Url"
                      value={updatedCustomLink.url}
                      className={classes.selectField}
                      totalItems={linkTypes.length}
                      handleSelect={handleOnLinkUrlChange}
                      fullWidth
                      withTooltip
                    >
                      {internalLinks.map(intL => (
                        <MenuItem key={intL.name} value={intL.path} title={intL.name}>
                          {intL.name}
                        </MenuItem>
                      ))}
                    </RedeemCustomSelectField>
                  }
                  label={
                    <Typography variant="body3RegularInter" className={classes.inputLabel}>
                      Internal Url
                    </Typography>
                  }
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.formControl}
                  labelPlacement="top"
                  control={
                    <CustomTextField
                      className={classes.inputText}
                      placeholder="External Url"
                      value={updatedCustomLink.url || ''}
                      name="url"
                      onChange={handleOnLinkUrlChange}
                      fullWidth
                    />
                  }
                  label={
                    <Typography variant="body3RegularInter" className={classes.inputLabel}>
                      External Url
                    </Typography>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box className={classes.customQuestionAActionSection}>
                <Grid container justifyContent="flex-end" spacing={3}>
                  <Grid item>
                    {!isEmpty(link) ? (
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => onRemove(updatedCustomLink)}
                        disabled={isDisabled()}
                        className={classes.customQuestionRemove}
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        size="small"
                        onClick={handleOnAdd}
                        disabled={isDisabled()}
                        className={classes.customQuestionAdd}
                      >
                        Add
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const sampleLink = {
  idx: 1,
  text: 'Home',
  type: 'Internal',
  url: '/'
};

const HeaderSettings = ({ store, setStoreSettings }) => {
  const headerComponent = store.visualSettings?.headerComponent;
  const links = headerComponent?.links?.filter(l => !l.delete) || [];
  const [addHeaderLinks, setAddHeaderLinks] = useState(links.length > 0);

  useEffect(() => {
    const newlinks = links.length > 0 ? links : [sampleLink];
    setStoreSettings({
      ...store.visualSettings,
      headerComponent: {
        ...headerComponent,
        links: addHeaderLinks ? newlinks : []
      }
    });
  }, [addHeaderLinks]);

  const hasLinks = headerComponent?.links.length > 0;

  useEffect(() => {
    setAddHeaderLinks(hasLinks);
  }, [hasLinks]);

  const handleOnCustomLinkAdd = newLink => {
    const currentLinks = links || [];
    setStoreSettings({
      ...store.visualSettings,
      headerComponent: {
        ...headerComponent,
        links: [...currentLinks, { ...newLink, idx: (_.max(currentLinks.map(cl => cl.idx || cl.id)) || 0) + 1 }]
      }
    });
  };

  const handleOnCustomLinkRemove = link => {
    if (link.idx) {
      setStoreSettings({
        ...store.visualSettings,
        headerComponent: {
          ...headerComponent,
          links: links.filter(l => l.idx !== link.idx)
        }
      });
    }
    if (link.id) {
      setStoreSettings({
        ...store.visualSettings,
        headerComponent: {
          ...headerComponent,
          links: links.map(l => (l.id === link.id ? { ...l, delete: true } : l))
        }
      });
    }
  };

  const handleOnCustomLinkUpdate = link => {
    setStoreSettings({
      ...store.visualSettings,
      headerComponent: {
        ...headerComponent,
        links: links.map(l => ((link.idx && l.idx === link.idx) || (link.id && l.id === link.id) ? link : l))
      }
    });
  };

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" className={classes.questionnaireAsk}>
          <Grid item xs>
            <Typography variant="body2RegularInter">Would you like to add shop navigation menu links?</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              labelPlacement="start"
              control={
                <BlueSwitch
                  checked={addHeaderLinks}
                  onChange={({ target: { checked } }) => setAddHeaderLinks(checked)}
                />
              }
            />
          </Grid>
        </Grid>

        {links.map((link, index) => (
          <LinkRow
            key={link.id || link.idx}
            link={link}
            onRemove={handleOnCustomLinkRemove}
            onUpdate={handleOnCustomLinkUpdate}
            isLast={index === maxLinks - 1}
            classes={classes}
          />
        ))}
      </Grid>
      {addHeaderLinks && headerComponent?.links.length < maxLinks && (
        <Grid item xs={12}>
          <LinkRow link={{}} isLast classes={classes} onAdd={handleOnCustomLinkAdd} />
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderSettings;
