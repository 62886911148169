const styles = theme => ({
  root: { paddingBottom: 20 },
  breadcrumbContainer: {
    width: '100%',
    padding: '23px 0 25px'
  },
  packSidebar: {
    paddingLeft: '75px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px !important'
    }
  }
});

export default styles;
