import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import log from '../logger';
import apiPaths from '../helpers/apiPaths';
import globalApi from '../apis/swagup/global';
import { fifteenMinutes, oneHour } from '../helpers/helperConstants';
import { LOAD_COMPANY, LOAD_PROFILE } from '../actions/types';
import { useMembership } from '../contexts/membershipContext';
import { joinFields, setAuthToken } from '../helpers/utils';

const defaultOptions = {
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  staleTime: fifteenMinutes,
  cacheTime: oneHour
};

const useDefaultQuery = (queryKey, queryFn, options) => {
  return useQuery(queryKey, queryFn, { ...defaultOptions, ...options });
};

const useCompany = () => {
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();
  const query = useQuery(apiPaths.accounts, () => globalApi.fetchAccount(), { enabled: !!authState?.isAuthenticated });
  const { data, status } = query;
  useEffect(() => {
    if (status === 'success' && !_.isEmpty(data)) {
      dispatch({ type: LOAD_COMPANY, payload: data });
    }
  }, [status, data, dispatch]);

  log.log('useCompany: ', 'query return', { data, status });
  return query;
};

const useCountries = (options = {}) => {
  return useDefaultQuery(apiPaths.countries, () => globalApi.fetchCountries(), options);
};

const useProfileCore = (options = {}) => {
  return useDefaultQuery(apiPaths.profiles, () => globalApi.fetchProfile(), {
    select: data => {
      const currentAccount = data?.related_accounts?.find(a => a.id === data.current_account);
      return { ...data, currentAccount };
    },
    ...options
  });
};

const useProfile = (options = {}) => {
  const dispatch = useDispatch();
  const query = useProfileCore(options);

  const { data, status } = query;
  useEffect(() => {
    if (status === 'success') {
      dispatch({ type: LOAD_PROFILE, payload: data });
    }
  }, [status, data, dispatch]);

  return query;
};

const useSetAuthCookies = (props = {}) => {
  const { data: profile } = useProfileCore();
  const { currentMembership } = useMembership();

  if (profile?.id) {
    const comanyName = profile.related_accounts?.find(ra => ra.id === profile.current_account)?.name;
    setAuthToken(
      joinFields([profile.first_name, profile.last_name], ' '),
      profile.email,
      profile.intercom_hmac_hash,
      comanyName,
      props.userInitial,
      props.backgroundColor,
      currentMembership?.image
    );
  }
};

const useSizes = (options = {}) => {
  return useDefaultQuery(apiPaths.sizes, () => globalApi.fetchSizes(), options);
};

const useMetaData = options => {
  return useDefaultQuery(apiPaths.metadata, () => globalApi.fetchMetadataAsObj(), options);
};

const useGlobalQuery = enabled => {
  const metadata = useMetaData({ enabled });
  const sizes = useSizes({ enabled });
  const countries = useCountries({ enabled });
  const profile = useProfile({ enabled });
  const company = useCompany({ enabled });

  const queries = [metadata, sizes, countries, profile, company];
  const isLoading = queries.some(query => query.status === 'loading');

  return { isLoading, queries };
};

export {
  useGlobalQuery,
  useCompany,
  useCountries,
  useProfile,
  useSizes,
  useMetaData,
  useProfileCore,
  useSetAuthCookies
};
