const styles = () => ({
  timer: {
    background: '#125CFF',
    border: '1px solid #fff',
    width: 'auto',
    height: 30,
    color: '#fff',
    gap: 8,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 12px',
    marginBottom: 1
  },
  timerExpired: {
    fontSize: 10,
    background: '#F44336',
    border: '1px solid #FF5733',
    width: 'auto',
    height: 36,
    color: '#fff',
    borderRadius: 18,
    gap: 10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12
  },
  timeText: {
    fontFamily: 'Inter',
    fontSize: 10,
    color: '#fff',
    fontWeight: 600,
    lineHeight: '12px'
  },
  timeTextLoose: {
    fontFamily: 'Inter',
    fontSize: 10,
    color: '#fff',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: '3px',
    textTransform: 'uppercase'
  }
});

export default styles;
