import * as React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { withStyles } from '@material-ui/core';

export const SearchIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.628 12.59c-2.74 0-4.962-2.221-4.962-4.961s2.222-4.962 4.962-4.962c2.74 0 4.962 2.221 4.962 4.962 0 1.134-.381 2.18-1.022 3.016l1.765 1.764-.494.43-.43.494-1.765-1.764c-.836.64-1.881 1.021-3.016 1.021zm0-1.24c2.055 0 3.721-1.666 3.721-3.721 0-2.056-1.666-3.722-3.721-3.722S3.906 5.573 3.906 7.63c0 2.055 1.667 3.721 3.722 3.721z"
      clipRule="evenodd"
    />
  </svg>
);

export const Warehouse = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <path
      fill="#3577D4"
      d="M2 6.577c0-.345.22-.65.547-.76l5.137-1.712c.205-.068.427-.068.632 0l5.137 1.713c.327.109.547.414.547.759V11.6c0 .221-.18.4-.4.4h-3.2c-.22 0-.4-.179-.4-.4V10H6v1.6c0 .221-.18.4-.4.4H2.4c-.22 0-.4-.179-.4-.4V6.577z"
    />
  </svg>
);

export const Truck = ({ size = '16', disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 16 16">
    <path
      fill={disabled ? '#bdbdbd' : '#3577D4'}
      fillRule="evenodd"
      d="M11.624 5.5h-1.363v-.7c0-.596-.261-.8-.861-.8H2.933c-.6 0-.933.204-.933.8v4.667c0 .596.358.908.958.908 0 .9.73 1.625 1.636 1.625.905 0 1.636-.726 1.636-1.625h3.273c0 .9.73 1.625 1.636 1.625.906 0 1.637-.726 1.637-1.625h.212c.3 0 .545-.244.545-.542V8.03c0-.233-.076-.46-.218-.65l-1.254-1.663c-.104-.136-.268-.217-.437-.217zm-7.01 5.667c-.367 0-.667-.3-.667-.667 0-.367.3-.667.666-.667.367 0 .667.3.667.667 0 .367-.3.667-.667.667zM11.563 6l1.069 1.333H10.2V6h1.364zm-.417 5.167c-.367 0-.667-.3-.667-.667 0-.367.3-.667.667-.667.366 0 .666.3.666.667 0 .367-.3.667-.666.667z"
      clipRule="evenodd"
    />
  </svg>
);

export const TruckOutlined = ({ size = '16', disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 16 16">
    <path
      fill="none" // Remove fill for outline
      stroke={disabled ? '#bdbdbd' : '#3577D4'} // Set stroke color
      strokeWidth="1" // Adjust stroke width for desired thickness
      fillRule="evenodd"
      d="M11.624 5.5h-1.363v-.7c0-.596-.261-.8-.861-.8H2.933c-.6 0-.933.204-.933.8v4.667c0 .596.358.908.958.908 0 .9.73 1.625 1.636 1.625.905 0 1.636-.726 1.636-1.625h3.273c0 .9.73 1.625 1.636 1.625.906 0 1.637-.726 1.637-1.625h.212c.3 0 .545-.244.545-.542V8.03c0-.233-.076-.46-.218-.65l-1.254-1.663c-.104-.136-.268-.217-.437-.217zm-7.01 5.667c-.367 0-.667-.3-.667-.667 0-.367.3-.667.666-.667.367 0 .667.3.667.667 0 .367-.3.667-.667.667zM11.563 6l1.069 1.333H10.2V6h1.364zm-.417 5.167c-.367 0-.667-.3-.667-.667 0-.367.3-.667.667-.667.366 0 .666.3.666.667 0 .367-.3.667-.666.667z"
      clipRule="evenodd"
    />
  </svg>
);

const styles = {
  icon: {
    position: 'absolute',
    marginTop: -7,
    right: 0
  }
};

export const InvalidShipmentNotification = withStyles(styles)(({ classes }) => (
  <FiberManualRecordIcon viewBox="-7 -7 40 40" fontSize="small" htmlColor="#f44336" className={classes.icon} />
));
