import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import styles from './styles/addNewCustomersModal';
import { Modal } from '../../shared/Modal';
import CustomerContactForm from './CustomerContactForm';

const useModalStyles = makeStyles(styles);

const CreateNewCustomerModal = ({ open, onClose }) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const handleOnAddNewCustomers = formData => {
    console.log('formData xxxxxx', formData);
    onClose();
  };

  const classes = useModalStyles();

  const isFormEmpty = formData => {
    return formData.firstName.trim() === '' || formData.lastName.trim() === '' || formData.email.trim() === '';
  };

  const handleInputChange = formData => {
    setIsFormValid(!isFormEmpty(formData));
  };

  return (
    <Modal
      title="Create New Customers"
      open={open}
      onClose={onClose}
      className={classes.modal}
      titleClass={classes.title}
      subtitleClass={classes.subtitle}
      closeButtonClass={classes.closeButton}
      actions={
        <Grid container justifyContent="center" style={{ paddingBottom: 12 }}>
          <Button
            size="small"
            variant="primary"
            onClick={handleOnAddNewCustomers}
            style={{ width: 256 }}
            disabled={!isFormValid}
          >
            Add New Customers
          </Button>
        </Grid>
      }
    >
      <Grid container justifyContent="center" className={classes.modalContent}>
        <CustomerContactForm onSubmit={handleOnAddNewCustomers} onInputChange={handleInputChange} />
      </Grid>
    </Modal>
  );
};

export default CreateNewCustomerModal;
