import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import styles from './styles/EmployeePhoto';

const getUpperCasedInitial = name => name?.[0]?.toUpperCase() ?? '';

const EmployeePhoto = ({ classes, employee, width = 48, height = 48, borderRadius = '50%', fontSize, selectedKey }) => {
  return employee.photo ? (
    <img
      alt="employee"
      src={employee.photo}
      className={classes.imageEmployee}
      style={{ width, height, borderRadius }}
    />
  ) : (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.initialsEmployee}
      style={{ width, height }}
    >
      <Grid item>
        <p
          className={employee.id === selectedKey ? classes.initialsSelected : classes.initials}
          style={{ fontSize: fontSize || parseInt(height * 0.36, 10) }}
        >{`${getUpperCasedInitial(employee.first_name)}${getUpperCasedInitial(employee.last_name)}`}</p>
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(EmployeePhoto);
