import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CheckCircle } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import styles from './styles/sendSwagConfiguration';
import { imageResolver, reverseTimingCoverter } from './common';

const useStyles = makeStyles(styles);

const SendSwagConfigurationFinish = ({ action, automation }) => {
  const currentTimingRaw = action.settings?.timing;

  const timingData = reverseTimingCoverter(currentTimingRaw);

  const classes = useStyles();

  return (
    <Box>
      <Grid container justifyContent="center">
        <Grid item>
          <Grid container justifyContent="center">
            <Grid item>
              <CheckCircle className={classes.notificationIcon} />
            </Grid>
          </Grid>
          <Typography variant="body3SemiBoldInter" className={classes.actionSubTitle}>
            Your automation is now set up!
          </Typography>
          <Typography variant="body4RegularInter" className={classes.actionFinishedSubTitle}>
            Shipments are going to be created with the current configuration
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Box className={classes.summaryContainer}>
          <Grid container alignItems="center">
            <Grid item>
              <Box className={classes.itemImageContainer}>
                <img src={imageResolver(automation?.type)} alt={automation?.name} className={classes.itemImage} />
              </Box>
            </Grid>
            <Grid item style={{ paddingLeft: 24 }}>
              <Typography variant="body3SemiBoldInter" className={classes.actionSubTitle}>
                {action?.name} {automation.name}
              </Typography>
            </Grid>
          </Grid>
          <Box className={classes.finishDivider} />
          <Grid container>
            <Grid item xs={7}>
              <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
                Product
              </Typography>
              <Grid container alignItems="center">
                <Grid item>
                  <Box className={classes.itemImageContainer}>
                    <img
                      src={action.settings?.product?.image}
                      alt={action.settings?.product?.name}
                      className={classes.itemImage}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography variant="body3MediumInter" className={classes.productNameSummary}>
                    {action.settings?.product?.name}
                  </Typography>
                  <Box className={classes.productTag}>
                    {action.settings?.product?.color} / {action.settings?.product?.sizeName}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
                Preferences
              </Typography>
              <Typography variant="body3MediumInter" className={classes.productName}>
                US Shipments: <span>{action.settings?.deliveryMethods?.domestic}</span>
              </Typography>
              <Typography variant="body3MediumInter" className={classes.productName}>
                International: <span>{action.settings?.deliveryMethods?.international}</span>
              </Typography>
              <Typography variant="body3MediumInter" className={classes.productName}>
                Employee Types:{' '}
                <span>{isEmpty(action.settings?.employeeTypes) ? 'All' : action.settings?.employeeTypes?.join()}</span>
              </Typography>
              <Typography variant="body3MediumInter" className={classes.productName}>
                Execution: <span>{action.settings?.automatic ? 'Automatic' : 'Manual'}</span>
              </Typography>
              <Typography variant="body3MediumInter" className={classes.productName}>
                Timing:{' '}
                <span>
                  {action.settings?.timing === undefined || action.settings?.timing === 0
                    ? 'Exact date'
                    : `${timingData.inputNumber} ${timingData.inputTimeMeasure} ${timingData.inputTiming}`}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SendSwagConfigurationFinish;
