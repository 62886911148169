import * as React from 'react';
import { Button } from '@swagup-com/components';
import { Grid, makeStyles } from '@material-ui/core';
import { useOrder } from '../OrderContext';
import { ProductProvider, useProduct } from '../common/ProductContext';
import Drawer from '../common/Drawer';
import InformationRow from '../common/InformationRow';
import ProductBreakdown from '../common/ProductBreakdown';
import useUnallocatedPerProduct from '../hooks/useUnallocatedPerProduct';
import useSortedSizes from '../hooks/useSortedSizes';
import styles from './UnallocatedDrawer.styles';

const useStyles = makeStyles(styles);

const ProductDetails = ({ quantities }) => {
  const [isBreakdownOpen, toggleBreakdown] = React.useReducer(prev => !prev, false);
  const { updatedProduct: product } = useProduct();
  const sortedSizes = useSortedSizes(
    Object.entries(quantities.breakdown).map(([size, quantity]) => ({ size: +size, quantity }))
  );

  const classes = useStyles();

  return (
    <Grid container className={classes.warehouseProduct}>
      <InformationRow
        product={product.product}
        name={product.product.name}
        info={
          <>
            Qty left:{' '}
            <span className={classes.productWarehouseTotal} style={{ color: '#fe8e26' }}>
              {quantities.total}
            </span>
          </>
        }
      >
        <Button variant="text" onClick={toggleBreakdown} className={classes.updateButton}>
          Update Quantity
        </Button>
      </InformationRow>
      <Grid container style={{ marginTop: 20, rowGap: 12 }}>
        {sortedSizes.map(
          ({ size, quantity, name }) =>
            quantity > 0 && (
              <Grid key={size} className={classes.quantityContainer}>
                <p className={classes.size} style={{ fontSize: 12 }}>
                  {name}
                </p>
                <p className={classes.quantity} style={{ color: '#fe8e26' }}>
                  {quantity}
                </p>
              </Grid>
            )
        )}
      </Grid>
      <ProductBreakdown open={isBreakdownOpen} onClose={toggleBreakdown} />
    </Grid>
  );
};

const UnallocatedDrawer = ({ open, onClose }) => {
  const { products } = useOrder();

  const unallocated = useUnallocatedPerProduct();
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <h2 className={classes.title}>Unassigned Items</h2>
      <p className={classes.subtitle}>
        You have not decided what to do with these items yet, if no action is taken we will store these items in the
        warehouse once you finalize your order.
      </p>
      {products.map(
        product =>
          unallocated[product.id].total > 0 && (
            <ProductProvider key={product.id} product={product}>
              <ProductDetails quantities={unallocated[product.id]} />
            </ProductProvider>
          )
      )}
    </Drawer>
  );
};

export default UnallocatedDrawer;
