export default {
  paginationContainer: {
    marginTop: 24,
    position: 'relative',
    minHeight: 20
  },
  alertRoot: {
    padding: '10px 20px',
    borderRadius: 15,
    boxShadow: '0 16px 36px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
    transition: 'all 0.2s ease-in',
    '&.show': {
      opacity: 1,
      transform: 'translate(-50%, -50%)',
      transition: 'transform top 0.25s ease-out'
    }
  },
  alertMessage: {
    marginRight: 60,
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: 'normal',
    color: '#4a505c',
    fontFeatureSettings: 'tnum',
    fontVariantNumeric: 'tabular-nums'
  },
  deleteBtn: {
    height: 'auto',
    color: '#f44336'
  },
  chipRoot: {
    marginLeft: 44,
    height: 32,
    backgroundColor: '#fee8e7'
  },
  chipLabel: {
    fontSize: 12,
    color: '#f44336'
  }
};
