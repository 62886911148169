import { ellipsisStyles } from '../../shared/styles/commonStyles';

export default theme => ({
  root: {
    width: 250,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 68px)',
      height: '72px !important',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center'
    }
  },
  actionArea: {
    [theme.breakpoints.down('xs')]: {
      width: 68
    },
    position: 'relative',
    '&:hover': {
      '& #view-item': {
        opacity: 0,
        transition: 'opacity 0.5s ease'
      },
      '& #lifestyle-view-item': {
        opacity: 1,
        transition: 'opacity 0.5s ease'
      }
    }
  },
  image: {
    height: 250,
    backgroundSize: 'contain',
    [theme.breakpoints.down('xs')]: {
      height: 68
    }
  },
  imageLifestye: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 250,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: 68
    }
  },
  content: {
    display: 'flex',
    padding: theme.spacing(3),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      paddingBottom: '12px !important'
    }
  },
  details: {
    width: '100%'
  },
  title: {
    height: 30,
    overflow: 'hidden',
    margin: 0,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '15px',
    color: theme.palette.neutral.darkBlue,
    [theme.breakpoints.down('xs')]: {
      ...ellipsisStyles,
      height: 'unset'
    }
  },
  price: {
    marginTop: theme.spacing(0.75),
    opacity: 0.6,
    fontSize: 12,
    lineHeight: 'normal',
    color: theme.palette.neutral.darkBlue
  },
  button: {
    flex: '0 0 auto',
    marginLeft: 'auto',
    width: 36,
    height: 36
  }
});
