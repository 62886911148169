/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, IconButton, makeStyles, Table, TableBody, TableContainer, useMediaQuery } from '@material-ui/core';
import { Button, Typography, Tooltip } from '@swagup-com/components';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import CloseIcon from '@material-ui/icons/Close';
import { useQueryClient } from 'react-query';
import styles from './styles/inventoryProductDetailStyles';
import Banner from '../shared/Banner';
import { reorderProductDisabledCopy } from '../../helpers/helperConstants';
import {
  PaymentFailed,
  PlatinumStorageChargesTableData,
  PlatinumStorageChargesTableHeader,
  StorageChargesTableData,
  StorageChargesTableHeader,
  useReorderLink,
  BackButtonWithTitle,
  InventoryHistoryData
} from './InventoryCommon';
import accountProductsApi from '../../apis/swagup/accountProducts';
import { calculateTimeRemaining, isPack, moneyStr } from '../../helpers/utils';
import useStorageInfoMain from '../pages/orders/requested/hooks/useStorageInfoMain';
import { useMembership } from '../../contexts/membershipContext';
import useMembershipCheck from '../../hooks/useMembershipCheck';
import { useCompany, useCreditSummary, usePaginatedQuery } from '../../hooks';
import { Helmet } from '../shared';
import seoTags from '../../apis/seoTags';
import ArrowTooltip from '../pages/orders/requested/common/Tooltip';
import useInventoryHistory from '../../hooks/useInventoryHistory';
import DiscardInventoryModal from './DiscardInventoryModal';
import Loader from '../global/Loader';

const dueDateStatuses = {
  normal: 'normal',
  warning: 'warning',
  alert: 'alert'
};

const useStyles = makeStyles(styles);

const multiply = arr => {
  return arr.reduce((prv, current) => {
    prv *= current;
    return prv;
  }, 1);
};

const sum = arr => {
  return arr.reduce((prv, current) => {
    prv += current;
    return prv;
  }, 0);
};

const totalHistoryCalculations = (item, storagePrices) => {
  const { storage_category, history } = item;
  const storagePrice = storagePrices?.[storage_category];
  const quantites =
    history?.map(mapObj => mapObj.sizes.map(sizeObj => [sizeObj?.remainingQuantity, storagePrice]))?.flat() || [];
  const multipliedArr = quantites.map(mpArr => multiply(mpArr));
  const total = sum(multipliedArr);

  return total;
};

const InventoryProductDetail = ({ refetch }) => {
  const [showMembershipBanner, setShowMembershipBanner] = useState(true);
  const [item, setItem] = useState({});
  const [pendingStorageItemsPrice, setPendingStorageItemsPrice] = useState(0);
  const [pendingStorageItemsPriceBeforeDiscount, setPendingStorageItemsPriceBeforeDiscount] = useState(0);
  const [productPackDetails, setProductPackDetails] = useState({});
  const location = useLocation();
  const history = useHistory();

  const { storagePrices, storagePricesBeforeDiscount } = useStorageInfoMain();
  const { data: company } = useCompany();
  const negativeBalance = Number(company.balance) < 0;
  const allowNegativeBalance = company?.allow_negative_credit_balance;
  const noSendSwag = negativeBalance && !allowNegativeBalance;

  const hasStock = item?.stock?.reduce((acc, size) => acc + size.quantity, 0) > 0;
  const reorderLink = useReorderLink([item?.id]);
  const itemMockupUrl = item?.image?.replace('.png', '.pdf');
  const ShippingAvalability = item?.can_ship_international ? 'GLOBAL' : 'US ONLY';
  const isPackOrProduct = isPack(item?.record_type) ? 'Pack' : 'Product';

  const params = useParams();

  const id = params.id.indexOf('?') !== -1 ? params.id.substr(0, params.id.indexOf('?')) : params.id;

  const queryClient = useQueryClient();

  const isLoading = false;

  const handleFetch = async () => {
    const data = await queryClient.fetchQuery(['accountProducts', +id], () => accountProductsApi.getOne({}, +id));

    if (data?.results?.length) {
      setProductPackDetails(data.results[0]);
    }
  };

  const {
    inventoryHistory,
    getGroupByPaidDate,
    getAccountProductInventoryDueInfo,
    useStorageTaxes
  } = useInventoryHistory(item ? [item] : [], {}, +id);

  useEffect(() => {
    handleFetch();
  }, [id]);

  useEffect(() => {
    if (productPackDetails?.id) {
      const _history = inventoryHistory?.length
        ? getGroupByPaidDate(inventoryHistory.filter(inv => inv.account_product === +id))
        : [];
      const _item = {
        ...productPackDetails,
        history: _history,
        ...getAccountProductInventoryDueInfo(_history)
      };
      setItem(_item);
    }
  }, [productPackDetails, inventoryHistory]);

  const getPendingStorageItemsQuantity = history =>
    history?.reduce(
      (sum, h) =>
        // sum + (h.dueStatus === dueDateStatuses.alert ? h.sizes.reduce((acc, s) => acc + s.remainingQuantity, 0) : 0),
        sum + h.sizes.reduce((acc, s) => acc + s.remainingQuantity, 0),
      0
    );

  const pendingStorageItemsQuantity = useMemo(() => getPendingStorageItemsQuantity(item?.history), [item.history]);

  const currnetDate = dayjs().format('MM/DD/YYYY');
  const nextPaymentDate = dayjs(item?.nextPaymentDate, 'MM/DD/YYYY');
  const disableInventoryCTA =
    item.inventoryDueStatus !== dueDateStatuses.normal && nextPaymentDate.diff(currnetDate, 'day') < 0;

  const {
    currentMembership: { isFreeTier, storage_discount: storageDiscount },
    payPendingStorageFees,
    useDiscardInventories,
    setAlertMessage
  } = useMembership();

  const { mutate: discardInventories } = useDiscardInventories();

  const membershipCheck = useMembershipCheck();
  const FreeInventoryStorageFee = storageDiscount === Number(100);
  const ZeroInventoryStorageBadge = storageDiscount === Number(0);

  const totalCost = totalHistoryCalculations(item, storagePrices);
  const totalCostBeforeDiscount = totalHistoryCalculations(item, storagePricesBeforeDiscount);
  const totalSave = totalCostBeforeDiscount - totalCost;

  const { data: creditSummary } = useCreditSummary();

  const [showDiscardInventoryModal, setShowDiscardInventoryModal] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const outDatedStorageFeesItems = useMemo(() => {
    const isMembershipBasic = membershipCheck.isBasic || membershipCheck.name?.toLowerCase()?.indexOf('basic') !== -1;
    if (isMembershipBasic) {
      return item?.history
        ?.map(history => {
          const daysLeftForNextPaymentDate = calculateTimeRemaining(history?.nextPaymentDate);
          const isOutDated = daysLeftForNextPaymentDate <= 0;
          return { id: history?.id, sizes: history?.sizes, isOutDated };
        })
        ?.filter(history => history?.isOutDated);
    }
    return [];
  }, [item.history, membershipCheck.isBasic]);

  useEffect(() => {
    if (outDatedStorageFeesItems?.length && item?.storage_category in storagePrices && creditSummary) {
      const storagePriceForCurrentItem = storagePrices[item.storage_category];

      const payload = outDatedStorageFeesItems.reduce(
        (acc, history) => {
          const sizes = history?.sizes.map(size => ({
            id: size.id,
            quantity: size.remainingQuantity
          }));
          sizes.forEach(size => {
            acc.inventories.push(size.id);
            acc.amount += storagePriceForCurrentItem * size.quantity;
          });
          return acc;
        },
        { inventories: [], amount: 0 }
      );

      (async () => {
        try {
          await payPendingStorageFees.mutate(payload);
          await refetch({ force: true });
        } catch (error) {
          throw error;
        }
      })();
    }
  }, [outDatedStorageFeesItems, creditSummary, pendingStorageItemsPriceBeforeDiscount, storagePrices]);

  const handleMembershipBannerClose = () => {
    setShowMembershipBanner(false);
  };

  const UpgradeYourMembership = () => {
    return (
      <Grid className={classes.inventoryModalHeader}>
        {FreeInventoryStorageFee ? (
          <Banner
            image="/images/pricing/membershipThumbsUp.png"
            title="Thanks to your current membership, you do not have any storage fees!"
            FreeInventoryStorageFee
          />
        ) : (
          <Banner
            image="/images/pricing/platinum2x.png"
            title="Upgrade to Platinum and never pay for storage again"
            buttonLabel="Upgrade Now"
            buttonLink="/membership/manage-membership-switch"
          />
        )}
        <IconButton className={classes.bannerCloseBtn} onClick={handleMembershipBannerClose}>
          <CloseIcon style={{ fontSize: 20 }} />
        </IconButton>
      </Grid>
    );
  };

  const MembershipContainer = ({ classes, company }) => {
    const negativeBalance = Number(company?.balance) < 0;
    const allowNegativeBalance = company?.allow_negative_credit_balance;
    const noSendSwag = negativeBalance && !allowNegativeBalance;

    return (
      <Grid container>
        {!FreeInventoryStorageFee && noSendSwag ? (
          <PaymentFailed classes={classes} />
        ) : (
          <>{showMembershipBanner && <UpgradeYourMembership />}</>
        )}
      </Grid>
    );
  };

  const handleOnCLoseDiscardInventoryModal = () => setShowDiscardInventoryModal(false);

  const handleOnOpenDiscardInventoryModal = () => setShowDiscardInventoryModal(true);

  const handleDiscardInventories = async discard_option => {
    try {
      setShowLoader(true);
      const product_id = params.id.indexOf('?') !== -1 ? params.id.substr(0, params.id.indexOf('?')) : +params.id;
      const payload = {
        product_id,
        discard_option
      };
      await discardInventories(payload, {
        onSuccess: () => {
          handleOnCLoseDiscardInventoryModal();
          setAlertMessage({ message: `Inventory for ${item.name} was discarded.`, type: 'info' });
          setShowLoader(false);
          history.push('/inventory?inventory=available');
        },
        onError: error => {
          setShowLoader(false);
          setAlertMessage({ message: 'Discard failed, please contact your AE.', type: 'error' });
        }
      });
    } catch (error) {
      setShowLoader(false);
      setAlertMessage({ message: 'Discard failed, please contact your AE.', type: 'error' });
    }
  };

  const noPendingStorageDiscountDifference = pendingStorageItemsPriceBeforeDiscount === pendingStorageItemsPrice;

  const disableSendSwagBtn = useMemo(() => {
    return (
      (!FreeInventoryStorageFee && disableInventoryCTA) ||
      noSendSwag ||
      (!item.nextPaymentDate && !item.history?.length)
    );
  }, [FreeInventoryStorageFee, disableInventoryCTA, noSendSwag, item]);

  const classes = useStyles({ disableSendSwagBtn });

  const fetchTaxes = useStorageTaxes();

  const getStorageFeesWithTaxes = async (
    amount_without_discount,
    amount_with_discount,
    quantity,
    setAmountWithoutDiscount,
    setAmountWithDiscount
  ) => {
    const payload = {
      amount_without_discount,
      amount_with_discount,
      quantity
    };

    // eslint-disable-next-line no-async-promise-executor
    const resp = await fetchTaxes(payload);
    setAmountWithoutDiscount(resp?.total_amount_without_discount || amount_without_discount);
    setAmountWithDiscount(resp?.total_amount_with_discount || amount_with_discount);
  };

  useEffect(() => {
    if (!pendingStorageItemsQuantity || !storagePrices || !item?.storage_category) return;
    const amount_without_discount =
      pendingStorageItemsQuantity * (storagePricesBeforeDiscount[item?.storage_category] || 0);
    const amount_with_discount = pendingStorageItemsQuantity * (storagePrices[item?.storage_category] || 0);

    // eslint-disable-next-line no-return-await
    (async () =>
      // eslint-disable-next-line no-return-await
      await getStorageFeesWithTaxes(
        amount_without_discount,
        amount_with_discount,
        pendingStorageItemsQuantity,
        setPendingStorageItemsPriceBeforeDiscount,
        setPendingStorageItemsPrice
      ))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingStorageItemsQuantity, storagePrices, item?.storage_category]);

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Grid container className={classes.modalContainer}>
        <Helmet tags={seoTags.inventoryHistories} />
        <BackButtonWithTitle titleText="Back to Inventory" />
        <Grid className={classes.inventoryModalBody}>
          <Grid container justifyContent="space-between" alignItems="center" style={{ paddingBottom: '25px' }}>
            <Grid>
              <Typography variant={item.name?.length < 45 ? 'h3SemiBoldInter' : 'h6SemiBoldInter'}>
                {item.name}
              </Typography>
            </Grid>
            <Grid item className={classes.inventoryCTA}>
              <Tooltip title={item.enabled ? '' : reorderProductDisabledCopy} classes={{ tooltip: classes.tooltip }}>
                <Button
                  component={Link}
                  to={reorderLink}
                  disabled={!item.enabled}
                  className={classes.reorderLink}
                  variant="text"
                  style={{ color: '#125CFF' }}
                >
                  <Typography variant="body3SemiBoldInter" style={{ color: disableSendSwagBtn ? '#fff' : '#125CFF' }}>
                    Reorder
                  </Typography>
                </Button>
              </Tooltip>
              {item.history?.length > 0 && (
                <ArrowTooltip
                  variant="alert"
                  title={
                    (!FreeInventoryStorageFee && disableInventoryCTA) ||
                    (noSendSwag &&
                      'Your Swag Card Balance has a Negative Balance. Please add funds to your Swag Card Balance to resume shipments')
                  }
                  style={{ width: '100%' }}
                  placement="top-center"
                  arrow
                >
                  <Button
                    variant="secondary"
                    size="small"
                    component={Link}
                    to={{
                      pathname: '/send-swag/select-recipients',
                      search: `product=${item.id}`,
                      state: { from: location, item }
                    }}
                    disabled={!hasStock || disableSendSwagBtn}
                    style={{
                      height: 40,
                      width: 130,
                      color: '#125CFF',
                      borderColor: disableSendSwagBtn ? '#989EA4' : '#125CFF'
                    }}
                  >
                    <Typography
                      variant="body3SemiBoldInter"
                      style={{
                        color: disableSendSwagBtn ? '#989EA4' : '#125CFF'
                      }}
                    >
                      Send Swag
                    </Typography>
                  </Button>
                </ArrowTooltip>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <InventoryHistoryData
              item={item}
              FreeInventoryStorageFee={FreeInventoryStorageFee}
              ShippingAvalability={ShippingAvalability}
              pendingStorageItemsPriceBeforeDiscount={pendingStorageItemsPriceBeforeDiscount}
              pendingStorageItemsPrice={pendingStorageItemsPrice}
              storageDiscount={storageDiscount}
              isPackOrProduct={isPackOrProduct}
              noPendingStorageDiscountDifference={noPendingStorageDiscountDifference}
              isFreeTier={isFreeTier}
              ZeroInventoryStorageBadge={ZeroInventoryStorageBadge}
              itemMockupUrl={itemMockupUrl}
              totalSave={totalSave}
            />
          </Grid>

          {!item.history?.length ? (
            <></>
          ) : (
            <>
              <MembershipContainer item={item} classes={classes} company={company} />

              <Grid container style={{ marginTop: 28, marginBottom: 70 }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    {FreeInventoryStorageFee ? (
                      <Typography variant="h3SemiBoldInter">Added inventory</Typography>
                    ) : (
                      <Typography variant="h3SemiBoldInter">Upcoming Storage Charges</Typography>
                    )}
                  </Grid>
                  {FreeInventoryStorageFee ? (
                    <Button
                      variant="text"
                      size="small"
                      style={{ height: 48, width: 200, color: '#C62828', cursor: 'pointer' }}
                      onClick={handleOnOpenDiscardInventoryModal}
                    >
                      <DeleteOutlinedIcon />
                      <Typography variant="body3SemiBoldInter" style={{ color: '#C62828', cursor: 'pointer' }}>
                        Discard Inventory
                      </Typography>
                    </Button>
                  ) : (
                    <Grid>
                      <Button
                        variant="text"
                        size="small"
                        style={{ height: 48, width: 200, color: '#C62828', cursor: 'pointer' }}
                        onClick={handleOnOpenDiscardInventoryModal}
                      >
                        <DeleteOutlinedIcon />
                        <Typography variant="body3SemiBoldInter" style={{ color: '#C62828', cursor: 'pointer' }}>
                          Discard Inventory
                        </Typography>
                      </Button>

                      {!noSendSwag && (
                        <Button
                          variant="secondary"
                          size="small"
                          component={Link}
                          to={{
                            pathname: '/billing/add-credits'
                          }}
                          style={{ height: 48, width: 225, color: '#125CFF', borderColor: '#125CFF' }}
                        >
                          <Typography variant="body3SemiBoldInter" style={{ color: '#125CFF', cursor: 'pointer' }}>
                            Add Funds to Swag Card
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Box style={{ width: '100%', margin: '22px 0px' }}>
                  {FreeInventoryStorageFee ? (
                    <TableContainer>
                      <Table stickyHeader className={classes.orderTable}>
                        <PlatinumStorageChargesTableHeader classes={classes} />
                        {item.history?.map(historyItem => (
                          <PlatinumStorageChargesTableData
                            key={historyItem.id}
                            item={historyItem}
                            product={item}
                            storagePrice={storagePrices[item.storage_category]}
                            storagePriceBeforeDiscount={storagePricesBeforeDiscount[item.storage_category]}
                            classes={classes}
                            getStorageFeesWithTaxes={getStorageFeesWithTaxes}
                          />
                        ))}
                        <TableBody />
                      </Table>
                    </TableContainer>
                  ) : (
                    <TableContainer>
                      <Table stickyHeader className={classes.orderTable}>
                        <StorageChargesTableHeader classes={classes} item={item} />
                        {item.history?.map(historyItem => (
                          <StorageChargesTableData
                            key={historyItem.id}
                            item={historyItem}
                            product={item}
                            storagePrice={storagePrices[item.storage_category]}
                            storagePriceBeforeDiscount={storagePricesBeforeDiscount[item.storage_category]}
                            classes={classes}
                            getStorageFeesWithTaxes={getStorageFeesWithTaxes}
                          />
                        ))}
                        <TableBody />
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <DiscardInventoryModal
        open={showDiscardInventoryModal}
        handleOnCLose={handleOnCLoseDiscardInventoryModal}
        handleDiscardInventories={handleDiscardInventories}
        showLoader={showLoader}
      />
    </>
  );
};

export default InventoryProductDetail;
