import * as React from 'react';
import { useTransition, animated } from '@react-spring/web';
import { IconButton, makeStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const useIndicatorStyles = makeStyles({
  root: {
    display: 'inline-block',
    width: 6,
    height: 6,
    marginRight: 4,
    borderRadius: '50%',
    backgroundColor: '#ebedf0',
    transition: `background-color 200ms ease-in-out`,
    '&[data-active=true]': {
      backgroundColor: '#3577d4'
    }
  }
});

const Indicator = ({ active }) => {
  const classes = useIndicatorStyles();

  return <div className={classes.root} data-active={active} />;
};

const btnCommon = {
  width: 40,
  height: 40,
  position: 'absolute',
  top: '50%',
  zIndex: 1,
  backgroundColor: '#fff',
  borderRadius: '50%',
  border: '1px solid #ebedf0',
  cursor: 'pointer',
  color: '#0b1829',
  '&:hover:not(:disabled)': {
    backgroundColor: '#fff'
  },
  '&:disabled': {
    border: 'none',
    backgroundColor: '#fafafa'
  }
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 1040,
    padding: 48,
    borderRadius: 15,
    boxShadow: '0 36px 84px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      padding: 24
    }
  },
  innerContainer: {
    margin: '0 72px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  slidesContainer: {
    width: '100%',
    height: '100%',
    gridArea: 'item',
    display: 'grid',
    gridTemplateAreas: '"slide"'
  },
  slide: {
    gridArea: 'slide'
  },
  backBtn: {
    ...btnCommon,
    left: 0,
    transform: 'translate(-72px, -50%)',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(calc(-50% - 48px), -50%)'
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(calc(-50% - 24px), -50%)'
    }
  },
  nextBtn: {
    ...btnCommon,
    right: 0,
    transform: 'translate(72px, -50%)',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(calc(50% + 48px), -50%)'
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(calc(50% + 24px), -50%)'
    }
  },
  indicators: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  }
}));

const Slideshow = ({ slides }) => {
  const classes = useStyles();
  const [index, setIndex] = React.useState(0);

  const transitions = useTransition(slides[index], {
    key: slide => slide.key,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const disabledBack = index === 0;
  const handleBack = () => {
    if (disabledBack) return;
    setIndex(prev => prev - 1);
  };

  const disabledNext = index === slides.length - 1;
  const handleNext = () => {
    if (disabledNext) return;
    setIndex(prev => prev + 1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.indicators}>
          {slides.map((item, i) => (
            <Indicator key={item.key} active={index === i} />
          ))}
        </div>
        <IconButton aria-label="Previous" onClick={handleBack} disabled={disabledBack} className={classes.backBtn}>
          <ChevronLeft />
        </IconButton>
        <div className={classes.slidesContainer}>
          {transitions((style, slide) => (
            <animated.div style={style} className={classes.slide}>
              {slide}
            </animated.div>
          ))}
        </div>
        <IconButton aria-label="Next" onClick={handleNext} disabled={disabledNext} className={classes.nextBtn}>
          <ChevronRight />
        </IconButton>
      </div>
    </div>
  );
};

export default Slideshow;
