import React from 'react';
import { withStyles, Chip } from '@material-ui/core';

const ErrorCounter = withStyles({
  root: {
    width: 20,
    height: 20,
    borderRadius: 10,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  label: {
    overflow: 'unset',
    fontSize: 12
  }
})(({ classes, count }) => <Chip classes={classes} label={count} />);

export { ErrorCounter };
