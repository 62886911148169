import commonStyles from './commonStyles';

const commonTitleText = {
  fontFamily: 'Gilroy-SemiBold',
  color: '#434d5c'
};

export default () => ({
  ...commonStyles,
  subTitle: {
    ...commonTitleText,
    fontSize: 20
  },
  sendButton: {
    '&:hover': { backgroundColor: '#3577d4' },
    textTransform: 'none',
    padding: '20px 124px',
    backgroundColor: '#3577d4',
    borderRadius: 32,
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 16,
    color: '#ffffff',
    lineHeight: 'normal',
    '&.Mui-disabled': { backgroundColor: '#ecedef !important' }
  },
  errorAlert: {
    width: 'inherit',
    marginBottom: 25
  }
});
