export default {
  subtitle: {
    maxWidth: 650,
    marginTop: ({ matchesHeight }) => (matchesHeight ? 6 : 16)
  },
  optionsContainer: {
    margin: '-10px 0',
    width: '100%',
    maxWidth: 1050,
    marginTop: ({ matchesHeight }) => (matchesHeight ? 20 : 100)
  },
  option: {
    width: 210,
    height: 270,
    paddingBottom: 10,
    borderRadius: 20,
    border: 'solid 1px #f0f2f5',
    backgroundColor: '#fff',
    margin: '0 18px',
    cursor: 'pointer',
    boxShadow: '0 0 0 0 transparent',
    transition: 'box-shadow 300ms ease',
    '&.selected': {
      boxShadow: '0 0 2px 1px #3577d4',
      transition: 'box-shadow 300ms ease'
    }
  },
  optionText: {
    fontSize: 16,
    lineHeight: 1.38,
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  errorAlert: {
    marginTop: ({ matchesHeight }) => (matchesHeight ? 15 : 50)
  },
  optionsInfoContainer: {
    margin: '10px 20px'
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'justify',
    width: 480,
    minHeight: 118,
    padding: '23px 35px',
    backgroundColor: '#ffffff',
    position: 'relative',
    transition: 'box-shadow 300ms ease',
    '&.selected': {
      zIndex: 1,
      borderRadius: 10,
      boxShadow: '0 16px 36px 0 rgba(0, 0, 0, 0.05)',
      transition: 'box-shadow 300ms ease'
    }
  },
  infoText: {
    fontSize: 14,
    lineHeight: 1.71,
    letterSpacing: 'normal',
    '& .title': {
      fontWeight: 700,
      color: '#0b1829',
      '&.selected': {
        color: '#3577d4'
      }
    }
  }
};
