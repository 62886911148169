import React, { useState, useEffect } from 'react';
import { Box, Drawer, FormControlLabel, Grid, IconButton, MenuItem, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Button, Typography } from '@swagup-com/components';
import { isEmpty } from 'lodash';
import {
  CustomTextField,
  DeleteProductOptionModal,
  RedeemCustomSelectField,
  getChangedProperties
} from '../storeCommon';
import styles from './styles/productOptionEditDrawer';
import VariantsEdit from './VariantsEdit';
import { Modal } from '../../shared/Modal';
import LifeStyeImagesEdit from './LifeStyeImagesEdit';
import { BlueSwitch } from '../../account/AccountSharedComponents';

const useStyles = makeStyles(styles);

// const collections = ['Featured', 'Best Sellers', 'New Arrivals', 'Seasonal Selection', 'Discount Deals'];

const categories = [
  'Water bottles',
  'Travel tumblers',
  'Coffee mugs',
  'Tops',
  'Sweatshirts',
  'Outerwear',
  'Accessories',
  'Notebooks & pens',
  'Backpacks',
  'Totes & duffles',
  'Tech items',
  'Custom pack'
];

const genders = ['Male', 'Female'];

const ProductOptionEditDrawer = ({
  open,
  onClose,
  onSave,
  onRemove,
  product = {},
  isLoading,
  isRemoving,
  sellingOutOfStock
}) => {
  const [updatedProduct, setUpdatedProduct] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (updatedProduct?.id !== product.id) setUpdatedProduct(product);
  }, [product]);

  const handleOnChange = event => {
    const { name, value } = event.target;
    setUpdatedProduct(prev => ({ ...prev, [name]: value }));
  };

  const handleOnVariantEdit = newVariantData => {
    setUpdatedProduct(prev => ({
      ...prev,
      variants: prev.variants.map(v => (v.id === newVariantData.id ? newVariantData : v))
    }));
  };

  const handleOnSave = () => {
    const currentChages = getChangedProperties(product, updatedProduct);
    onSave({ productId: product.id, payload: currentChages });
  };

  const handleOnVariantGenderEdit = event => {
    const { value } = event.target;
    setUpdatedProduct(prev => {
      const [fristVariant, ...restVariants] = prev.variants;
      return {
        ...prev,
        variants: [{ ...fristVariant, gender: value }, ...restVariants]
      };
    });
  };

  const handleOnRemove = () => {
    setDeleteOpen(false);
    onRemove(product.id);
  };

  const classes = useStyles();
  return (
    <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper: classes.productSelectionDrawer }}>
      <Box>
        <Grid container>
          <Grid item>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.productEditHeaderSection}>
        <Typography variant="h4SemiBoldInter">Edit your product</Typography>
      </Box>
      <Box className={classes.productEditContentSection}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body3SemiBoldInter">Product details</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <CustomTextField
                  className={classes.inputText}
                  placeholder="Product name"
                  defaultValue={updatedProduct.name}
                  name="name"
                  onChange={handleOnChange}
                  value={updatedProduct.name}
                  fullWidth
                />
              }
              fullWidth
              label={
                <Typography variant="body3RegularInter" className={classes.inputLabel}>
                  Product name
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <BlueSwitch
                  id="collection"
                  name="collection"
                  size="small"
                  checked={updatedProduct.collection === 'Featured'}
                  onChange={({ target }) =>
                    handleOnChange({ target: { name: target.name, value: target.checked ? 'Featured' : 'None' } })
                  }
                />
              }
              fullWidth
              label={
                <Typography variant="body3RegularInter" className={classes.inputLabel}>
                  Featured Collection
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <RedeemCustomSelectField
                  id="category"
                  name="category"
                  label="Category"
                  value={updatedProduct.category}
                  className={classes.selectField}
                  totalItems={categories.length}
                  handleSelect={handleOnChange}
                  fullWidth
                  withTooltip
                >
                  {categories.map(item => (
                    <MenuItem key={item} value={item} title={item}>
                      {item}
                    </MenuItem>
                  ))}
                </RedeemCustomSelectField>
              }
              fullWidth
              label={
                <Typography variant="body3RegularInter" className={classes.inputLabel}>
                  Category
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <CustomTextField
                  className={classes.inputTextMultiline}
                  placeholder="Product description"
                  defaultValue={product.description}
                  value={updatedProduct.description}
                  name="description"
                  onChange={handleOnChange}
                  multiline
                  minRows={3}
                  maxRows={5}
                  fullWidth
                />
              }
              fullWidth
              label={
                <Typography variant="body3RegularInter" className={classes.inputLabel}>
                  Product description
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <VariantsEdit
          variants={updatedProduct.variants}
          onVariantEdit={handleOnVariantEdit}
          sellingOutOfStock={sellingOutOfStock}
        />
        <Box>
          <LifeStyeImagesEdit
            images={updatedProduct.images}
            mainVariant={updatedProduct.variants?.[0]}
            onChange={handleOnChange}
            onChangeVariant={handleOnVariantEdit}
            productName={updatedProduct.name}
            productImage={updatedProduct.imageUrl}
          />
        </Box>
      </Box>
      <Box className={classes.productEditFooterSection}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="text" size="small" onClick={() => setDeleteOpen(true)} loading={isRemoving}>
              Remove Product
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end">
              <Button variant="primary" size="small" onClick={handleOnSave} loading={isLoading}>
                Save Product
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <DeleteProductOptionModal open={deleteOpen} onClose={() => setDeleteOpen(false)} onDelete={handleOnRemove} />
    </Drawer>
  );
};

export default ProductOptionEditDrawer;
