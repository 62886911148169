import { Grid, Tooltip, makeStyles } from '@material-ui/core';
import { CheckCircle, RadioButtonChecked } from '@material-ui/icons';
import React from 'react';
import { COLOR_CONSTANT, PRODUCT_STATUS } from '../../utils/constants';
import { textOnHoverNew } from '../global/proofsCommon';

const styles = {
  status: {
    height: '2px',
    alignSelf: 'stretch',
    borderRadius: '100px',
    marginBottom: '8px'
  },
  statusText: {
    fontFamily: 'Inter',
    fontSize: '12px',
    paddingLeft: '3px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHight: '16px',
    justifyContent: 'center',
    color: '#131415'
  }
};
const useStyles = makeStyles(styles);

export const Circle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="10" cy="10.5" r="10" fill="#D6D8DB" />
  </svg>
);

export const OngoingStatus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="3" viewBox="0 0 100% 3" fill="none">
    <rect y="0.5" width="100%" height="2" rx="1" fill="#B8CFF0" />
    <rect y="0.5" width="50%" height="2" rx="1" fill="#125CFF" />
  </svg>
);

const ProgressBar = ({ selectedStatusIndex }) => {
  const classes = useStyles();
  return (
    <Grid
      style={{ padding: '40px 0', margin: 0 }}
      container
      xs={12}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {PRODUCT_STATUS.map((status, index) => (
        <Grid
          item
          xs={12 / PRODUCT_STATUS.length}
          key={index}
          container
          direction="column"
          style={{ textAlign: 'center', flexBasis: '19%', alignItems: 'self-start' }}
        >
          <Grid container direction="row" alignItems="center" style={{ gap: '8px', width: '100%' }} xs={12}>
            <Grid item xs={1}>
              {(selectedStatusIndex > index ||
                (selectedStatusIndex === index && selectedStatusIndex === PRODUCT_STATUS.length - 1)) && (
                <CheckCircle style={{ color: COLOR_CONSTANT.SELECTED_BLUE }} />
              )}
              {selectedStatusIndex === index && selectedStatusIndex !== PRODUCT_STATUS.length - 1 && (
                <RadioButtonChecked style={{ color: COLOR_CONSTANT.SELECTED_BLUE }} />
              )}
              {selectedStatusIndex < index && <Circle />}
            </Grid>
            <Grid
              xs={10}
              item
              style={{
                paddingLeft: '1em',
                marginBottom:
                  selectedStatusIndex !== PRODUCT_STATUS.length - 1 && selectedStatusIndex === index && '10px'
              }}
            >
              {selectedStatusIndex === index && selectedStatusIndex !== PRODUCT_STATUS.length - 1 ? (
                <OngoingStatus />
              ) : (
                <div
                  className={classes.status}
                  style={{
                    background:
                      selectedStatusIndex >= index ? COLOR_CONSTANT.SELECTED_BLUE : COLOR_CONSTANT.LIGHT_BORDER_GREY
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid>
            <Tooltip title={textOnHoverNew[status]} arrow placement="top" fontColor="#0b1829" backgroundColor="white">
              <div className={classes.statusText}>{status}</div>
            </Tooltip>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProgressBar;
