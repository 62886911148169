import * as React from 'react';
import isEmpty from 'lodash/isEmpty';

const perPageDefaults = [12, 24, 36, 48];
const useLocalPagination = (total, perPageArg) => {
  const [pageIndex, setPageIndex] = React.useState(0);
  const perPageOptions = isEmpty(perPageArg) ? perPageDefaults : perPageArg;
  const [perPage, setPerPage] = React.useState(perPageOptions[0]);

  React.useEffect(() => {
    const lastPageIndex = Math.max(0, Math.ceil(total / perPage) - 1);
    setPageIndex(prev => Math.min(prev, lastPageIndex));
  }, [total, perPage]);

  const onNext = React.useCallback(() => setPageIndex(prev => prev + 1), []);
  const onPrevious = React.useCallback(() => setPageIndex(prev => prev - 1), []);

  const setNewPerPage = React.useCallback(
    value => {
      const newPerPageIndex = Math.floor((pageIndex * perPage) / value);
      setPageIndex(newPerPageIndex);
      setPerPage(value);
    },
    [pageIndex, perPage]
  );

  return {
    onNext,
    pageIndex,
    perPage,
    onPrevious,
    setPageIndex,
    changeSize: setNewPerPage,
    sizeOptions: perPageOptions
  };
};

export default useLocalPagination;
