import React from 'react';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';
import { Switch, Route, useHistory, useParams, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Typography, Tabs, Tab } from '@swagup-com/components';
import SideBar from '../../account/SideBar';
import { CenteredGrid, Helmet, Link, Alert } from '../../shared';
import Company from '../../account/CompanyContainer';
import Profile from '../../account/ProfileContainer';
import Transactions from '../../account/TransactionsContainer';
import Credits from '../../account/CreditsContainer';
import Invoices from '../../account/InvoicesContainer';
import PaymentProfiles from '../../account/PaymentProfilesContainer';
import AddCredits from '../../account/AddCredits';
import tags from '../../../apis/seoTags';
import Membership from '../../account/Membership';
import MembershipConfirmation from '../../account/MembershipConfirmation';
import { SelectionProvider, SelectionContext } from '../contact/SelectionContext';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 82px)',
    paddingTop: 32,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 100
    }
  },
  sidebar: {
    minWidth: 232,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      minWidth: 'auto'
    }
  },
  main: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: { padding: '40px 24px' }
  },
  tab: {
    fontFamily: 'Inter',
    fontWeight: 600,
    padding: '0px',
    marginRight: '32px'
  }
}));

const AccountHome = () => {
  const classes = useStyles();
  const { view } = useParams();
  const location = useLocation();
  const getView = () => {
    switch (location.pathname) {
      case '/billing/accounts':
        return 0;
      case '/billing/invoices':
        return 1;
      case '/billing/credits':
        return 2;
      case '/billing/transactions':
        return 3;
      default:
        return 2;
    }
  };
  return (
    <SelectionProvider>
      <CenteredGrid className={classes.root}>
        <Tabs value={getView()} className={classes.tabs} style={{ padding: '0px 0px' }}>
          <Tab label="My accounts" component={Link} to="/billing/accounts" className={classes.tab} />
          <Tab label="Invoices" component={Link} to="/billing/invoices" className={classes.tab} />
          <Tab label="Credits" component={Link} to="/billing/credits" className={classes.tab} />
          <Tab label="Transactions" component={Link} to="/billing/transactions" className={classes.tab} />
        </Tabs>

        <div className={clsx(classes.content, 'fade-translate')} key={view}>
          <Switch>
            <Route path="/billing/accounts" exact>
              <PaymentProfiles />
            </Route>
            <Route path="/billing/invoices" exact>
              <Invoices />
            </Route>
            <Route path="/billing/credits" exact>
              <Credits />
            </Route>
            <Route path="/billing/add-credits" exact>
              <AddCredits />
            </Route>
            <Route path="/billing/transactions" exact>
              <Transactions />
            </Route>
          </Switch>
        </div>
      </CenteredGrid>
    </SelectionProvider>
  );
};

export default AccountHome;
