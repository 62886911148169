import React, { useState, useCallback, memo, useEffect } from 'react';
import { FixedSizeList, areEqual } from 'react-window';
import memoize from 'memoize-one';
import { withStyles, Grid, Button, Zoom } from '@material-ui/core';
import { Tooltip } from '@swagup-com/components';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import handleImg from '../../helpers/handleImgHelper';
import { Img } from '../global/ImgUtils';
import styles from './styles/EmployeesSelected';

const ProductQuantities = ({ stock, classes }) => (
  <Grid container>
    {stock.map(qps => (
      <Grid key={qps.size.id} item xs={4} align="left" style={{ marginBottom: 20 }}>
        <p className={classes.productSize}>
          <span className={clsx({ [classes.productNoInv]: qps.quantity === 0 })}>{qps.size.name}:</span>
          <span
            className={qps.quantity > 0 ? classes.productSizeValue : classes.productNoInv}
          >{` ${qps.quantity}`}</span>
        </p>
      </Grid>
    ))}
  </Grid>
);

const Li = memo(function Li({ style, data, index }) {
  const { employees, onDeselectEmployee, classes, employeesWithErrors, hideDelete } = data;
  const [hovered, setHovered] = useState(false);
  const employee = employees[index];

  return (
    <li style={style} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <Grid container style={{ paddingBottom: 16, cursor: 'default' }}>
        <Tooltip
          placement="top"
          classes={{ tooltip: classes.tooltip }}
          title={employeesWithErrors.get(employee.id) ?? ''}
          TransitionComponent={Zoom}
        >
          <Grid container alignItems="center" item xs={10}>
            <Grid item xs={3}>
              <div className={classes.photoContainer}>
                <Img
                  src={employee.photo}
                  width={32}
                  height={32}
                  fallbackSrc="/images/public/UserDefaultIcon.svg"
                  alt={`${employee.photo ? `User${employee.id}` : 'Default user'} photo`}
                />
              </div>
            </Grid>
            <Grid container alignItems="center" item xs={9}>
              <p
                className={classes.employeeName}
                style={employeesWithErrors.has(employee.id) ? { color: 'red' } : undefined}
              >
                {employee.first_name || ''} {employee.last_name || ''}
              </p>
            </Grid>
          </Grid>
        </Tooltip>
        <Grid container item xs={2} className={classes.deleteButtonContainer}>
          {hovered && !hideDelete && (
            <Button
              variant="contained"
              disableElevation
              disableRipple
              disableFocusRipple
              className={classes.deleteButton}
              onClick={() => onDeselectEmployee(employee, true)}
            >
              <CloseIcon className={classes.deleteButtonIcon} />
            </Button>
          )}
        </Grid>
      </Grid>
    </li>
  );
}, areEqual);

const createItemData = memoize((employees, onDeselectEmployee, classes, employeesWithErrors, hideDelete) => ({
  employees,
  onDeselectEmployee,
  classes,
  employeesWithErrors,
  hideDelete
}));

const EmployeesSelected = ({
  classes,
  employees,
  onDeselectEmployee,
  product,
  employeesWithErrors = new Map(),
  hideDelete
}) => {
  const [listRef, setListRef] = useState(null);
  const [listHeight, setListHeight] = useState(0);

  const changeListRef = useCallback(ref => {
    setListRef(ref);
  }, []);

  useEffect(() => {
    const handleResize = () => setListHeight(listRef.clientHeight);
    if (listRef) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (listRef) window.removeEventListener('resize', handleResize);
    };
  }, [listRef]);

  const itemData = createItemData(
    [...employees.values()],
    onDeselectEmployee,
    classes,
    employeesWithErrors,
    hideDelete
  );

  return (
    <div className={classes.container}>
      <Grid container className={classes.innerContainer}>
        <Grid item xs={12} className={classes.contactsSection} style={{ height: isEmpty(product) && '100%' }}>
          <div className={classes.titleContainer}>
            <p className={classes.title}>Selected contacts</p>
            <div className={classes.totalCountContainer}>
              <p className={classes.totalCountText}>{employees.size}</p>
            </div>
          </div>
          {employees.size > 0 ? (
            <ul className={classes.list} ref={changeListRef}>
              <FixedSizeList
                height={listHeight}
                itemData={itemData}
                itemCount={employees.size}
                itemSize={48}
                width={260}
                itemKey={(index, data) => data.employees[index].id}
              >
                {Li}
              </FixedSizeList>
            </ul>
          ) : (
            <div className={classes.placeholder} />
          )}
        </Grid>
        {!isEmpty(product) && (
          <Grid item xs={12} className={classes.productSection}>
            <p className={classes.productSectionTitle}>Product inventory left</p>
            <Grid container style={{ overflowY: 'auto', overflowX: 'hidden', height: '100%' }}>
              <Grid item>
                <Img
                  src={handleImg(product.image, 78, 78)}
                  alt={product.name}
                  className={classes.productImage}
                  width={56}
                  height={56}
                />
              </Grid>
              <p className={classes.productName}>{product.name}</p>
              <Grid container alignItems="center" style={{ marginBottom: 24 }}>
                <p className={classes.productColor}>
                  Color: <span className="bold">{product.theme_color || 'Custom'}</span>
                </p>
                <div
                  className={classes.productColorSample}
                  style={{ backgroundColor: product.theme_color_hex ? `#${product.theme_color_hex}` : 'transparent' }}
                />
              </Grid>
              <ProductQuantities stock={product.stock} classes={classes} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(EmployeesSelected);
