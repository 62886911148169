import React from 'react';
import sumBy from 'lodash/sumBy';
import { Grid, Dialog, IconButton, Divider, ButtonBase, withStyles, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import Close from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { Truck, Warehouse } from '../../../../icons';
import { getUsedQuantitiesBySize } from '../../../../../helpers/utils';
import Img from '../../../../shared/Img';
import { productImageBasedOnStatus } from '../../../../global/proofsCommon';
import useUnallocatedPerProduct from '../hooks/useUnallocatedPerProduct';
import { substractQuantitiesBreakdowns } from '../common/utilsOrder';
import { changeColorLightness } from '../../../../shared/styles/utils';
import styles from './CreateShipmentDialog.styles';

const useStyles = makeStyles(styles);

const ActionButton = withStyles({
  button: ({ disabled }) => ({
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 166,
    color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#3577d4',
    '&:hover': disabled ? {} : { color: changeColorLightness('#3577d4') }
  }),
  chip: {
    display: 'grid',
    placeItems: 'center',
    width: 36,
    height: 36,
    borderRadius: '50%',
    backgroundColor: '#f4f8ff'
  },
  text: {
    textAlign: 'left',
    color: 'inherit',
    maxWidth: 112,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '22px'
  }
})(({ classes, onClick, icon, text, disabled }) => (
  <ButtonBase className={classes.button} onClick={onClick} disabled={disabled}>
    <div className={classes.chip}>{icon}</div>
    <p className={classes.text}>{text}</p>
  </ButtonBase>
));

const buildRemainingBreakdown = (productId, unallocated, shipment) =>
  substractQuantitiesBreakdowns(unallocated[productId].breakdown, getUsedQuantitiesBySize(productId, [shipment]));

const UnallocatedQuantity = ({ product }) => {
  const classes = useStyles();

  const shipment = useSelector(state => state.shipmentGroup);
  const unallocated = useUnallocatedPerProduct();
  const remainingQuantitiesBreakdown = React.useRef(buildRemainingBreakdown(product.id, unallocated, shipment));

  const remainingQuantity = sumBy(Object.values(remainingQuantitiesBreakdown.current), quantity =>
    Math.max(0, quantity)
  );

  return (
    <div key={product.id}>
      <Img
        src={productImageBasedOnStatus(product.product, 78, 78)}
        alt={product.product.name}
        width={48}
        height={48}
        style={{ borderRadius: 10, border: 'solid 1px #f0f2f5' }}
      />
      <p className={classes.quantity}>x{remainingQuantity}</p>
    </div>
  );
};

const CreateShipmentDialog = ({ open, onClose, disabled, onCreateAnother, onSendToWarehouse, onSkip }) => {
  const classes = useStyles();
  const shipment = useSelector(state => state.shipmentGroup);

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
      <Grid container justifyContent="flex-end">
        <IconButton aria-label="Close" className={classes.iconBtn} onClick={onClose}>
          <Close className={classes.icon} />
        </IconButton>
      </Grid>
      <p className={classes.title}>What would you like to do with the remaining products?</p>
      <p className={classes.subtitle}>You can “Skip for now” and update your shipments on the Orders tab</p>
      <Grid container justifyContent="center" className={classes.productsContainer}>
        {shipment.products?.map(product => (
          <UnallocatedQuantity key={product.id} product={product} />
        ))}
      </Grid>
      <Divider style={{ margin: '36px 0' }} />
      <Grid container justifyContent="center">
        <Grid container justifyContent="space-between" style={{ padding: '0 195px' }}>
          <ActionButton disabled={disabled} onClick={onCreateAnother} icon={<Truck />} text="Create another Shipment" />
          <ActionButton
            disabled={disabled}
            onClick={onSendToWarehouse}
            icon={<Warehouse />}
            text="Send remaining to Warehouse"
          />
        </Grid>
        <Grid container justifyContent="center" style={{ margin: '39px 0 15px' }}>
          <Button disabled={disabled} className={classes.skipBtn} variant="text" withIcon onClick={onSkip}>
            Skip for now
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CreateShipmentDialog;
