import React, { useState } from 'react';
import { Grid, TableRow, TableCell, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import round from 'lodash/round';
import uniqBy from 'lodash/uniqBy';
import sumBy from 'lodash/sumBy';

import { useParams } from 'react-router-dom';
import { getProductSizes, productionTimeText } from './common';
import { getProductPrice, sizesWithQuantities, sumByQuantityParseInt, moneyStr } from '../../helpers/utils';
import { ImgWithHandler } from '../global/ImgUtils';

const ProductImage = ({ product, classes }) => (
  <Grid item xs={3}>
    <div className={classes.imgContainer}>
      <ImgWithHandler src={product.image} alt={product.name} width={78} height={78} />
    </div>
  </Grid>
);

const ProductNameHeader = ({ product, onClick, isExpanded, classes }) => (
  <Grid container>
    <Grid item xs={9}>
      <p className={classes.packTitle}>
        <b>{product.name}</b>
      </p>
    </Grid>
    <Grid item xs={2} align="right">
      <IconButton className={classes.ExpandCollapse} aria-label="DropDown" onClick={onClick}>
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </Grid>
  </Grid>
);

const CollapsedData = ({ qty, price, rushFee, total, classes }) => (
  <Grid container>
    <Grid item xs={3}>
      <p className={classes.itemDesc}>Qty: {qty}</p>
    </Grid>
    <Grid item xs={4}>
      <p className={classes.itemDesc}>Price: {moneyStr(price)}</p>
    </Grid>
    <Grid item xs={4}>
      {rushFee !== 0 && <p className={classes.itemDesc}>Rush production: {moneyStr(rushFee)}</p>}
    </Grid>
    <Grid item xs={5} align="right">
      <p className={classes.packTotal}>Total: {moneyStr(total)}</p>
    </Grid>
  </Grid>
);

const ExpandedData = ({ color, size, qty, prodTime, price, rushFee, total, classes }) => (
  <Grid container>
    <Grid item xs={12}>
      <p className={classes.itemDesc}>Color: {color}</p>
      <p className={classes.itemDesc}>Size: {size}</p>
      <p className={classes.itemDesc}>Qty: {qty}</p>
      <p className={classes.itemDesc}>Production: {prodTime}</p>
      <p className={classes.itemDesc}>Price: {moneyStr(price)}</p>
      {rushFee !== 0 && <p className={classes.itemDesc}>Rush production: {moneyStr(rushFee)}</p>}
      <p className={classes.packTotal}>Total: {moneyStr(total)}</p>
    </Grid>
  </Grid>
);

const ItemDetailsSidebar = ({ proof, shippingElements, classes }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const sizes = getProductSizes(proof);
  const qty = sumByQuantityParseInt(sizes);
  const price = getProductPrice(proof);
  const rushFee = getProductPrice(proof, 'rush_fee');
  const totalPrice = round(price + rushFee, 2);
  const total = round(qty * totalPrice, 2);

  const { id } = useParams();
  const addressCount = id
    ? uniqBy(shippingElements, 'employee.id').length
    : sumBy(shippingElements, e => e.products.filter(p => p.id === proof.id).length);

  return (
    <TableRow className={classes.tableRow}>
      <TableCell colSpan={2} className={classes.tableCell}>
        <Grid container>
          <ProductImage product={proof.product} classes={classes} />
          <Grid item xs={9}>
            <ProductNameHeader
              product={proof.product}
              onClick={toggleExpanded}
              isExpanded={isExpanded}
              classes={classes}
            />
            {isExpanded ? (
              <ExpandedData
                color={proof.product.theme_color || 'Custom'}
                size={sizesWithQuantities(sizes)}
                qty={qty}
                prodTime={productionTimeText(proof)}
                price={price}
                rushFee={rushFee}
                total={total}
                classes={classes}
              />
            ) : (
              <CollapsedData qty={qty} price={price} rushFee={rushFee} total={total} classes={classes} />
            )}
          </Grid>
        </Grid>
        <p className={classes.DeliveringTo}>
          {addressCount === 0
            ? 'Sending to Warehouse'
            : `Delivering to ${addressCount} ${addressCount === 1 ? 'address' : 'addresses'}`}
        </p>
      </TableCell>
    </TableRow>
  );
};

export default ItemDetailsSidebar;
