import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles/ImageWithHeader';
import log from '../../logger';

const ImageWithHeader = ({ classes, headerText = '', file, alt = 'Preview' }) => (
  <>
    {headerText && <p className={classes.headerText}>{headerText}</p>}
    <img
      alt={alt}
      src={file}
      className={classes.imgContainer}
      onError={() => {
        log.debug('ImageWithHeader render error');
      }}
    />
  </>
);

export default withStyles(styles)(ImageWithHeader);
