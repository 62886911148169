import React, { useState, useEffect, useContext, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { reduxForm, Form } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import withLoader from '../HOC/withLoader';
import { LoaderContext } from '../global/NewLoader';
import { zipList } from '../../helpers/DataStructs/zipList';
import { getPaymentProfiles, setDefaultPaymentProfile, loadCompany } from '../../actions';
import { paymentMethods } from '../../apis/constants';
import CreditCard from './CreditCard';
import ACH from './ACH';
import WireTransfer from './WireTransfer';
// import MailCheck from './MailCheck';
import toErrorPage from '../../helpers/toErrorPage';
import { moneyStr } from '../../helpers/utils';
import AddPaymentMethodDialog from '../account/AddPaymentMethodDialog';
import AvailableCredits from './AvailableCredits';
import PaymentOptions from './PaymentOptions';
import PaymentOptionsModal from './PaymentOptionsModal';
import ErrorAlert from '../shared/ErrorAlert';
import styles from '../global/styles/PaymentSection';
import log from '../../logger';
import { useCompany, useCreditSummary, useIsAchPrepaid } from '../../hooks';
import apiPaths from '../../helpers/apiPaths';

const isCreditCardOrACH = (method, isAchPrepaid) =>
  paymentMethods.creditCard === method || (isAchPrepaid && paymentMethods.ach === method);

const paymentSubmit = (
  accountId,
  setDefaultProfile,
  handleOnSubmitSuccess,
  handleOnSubmitError,
  profileSelected,
  isAchPrepaid
) => {
  const { method } = window.sessionStorage;
  if (isCreditCardOrACH(method, isAchPrepaid) && profileSelected && !profileSelected.default) {
    const customerPaymentProfileId = profileSelected.customer_payment_profile_id;
    setDefaultProfile(accountId, customerPaymentProfileId, true)
      .then(response => {
        if (response.result === 'ok') {
          handleOnSubmitSuccess(response.data);
        } else {
          handleOnSubmitError(response);
        }
      })
      .catch(e => handleOnSubmitError(e));
  } else handleOnSubmitSuccess();
};

const PaymentSection = props => {
  const {
    simple,
    classes,
    children,
    handleBeforeSubmit,
    handleAfterPayConfirmation,
    handleErrorDuringConfirmation,
    handleSubmit,
    onValidityChange,
    handleUseCreditFirst,
    cost,
    credit,
    orderCost,
    paymentProfiles
  } = props;
  const history = useHistory();
  const [method, setMethod] = useState(paymentMethods.creditCard);
  const [activeTab, setActiveTab] = useState(0);
  const [isUsingCreditAvailable, setIsUsingCreditAvailable] = useState(false);
  const [showOtherPaymentMethods, setShowOtherPaymentMethods] = useState(true);
  const [remaining, setRemaining] = useState(0);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [defaultProfile, setDefaultProfile] = useState(null);
  const [openPaymentOptionModal, setOpenPaymentOptionModal] = useState(false);
  const [selectedDefaultProfile, setSelectedDefaultProfile] = useState(true);
  const [continuePaymentMethod, setContinuePaymentMethod] = useState(false);
  const [showTabInfo, setShowTabInfo] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const selectedProfile = useRef(null);
  const { isLoading, setIsLoading } = useContext(LoaderContext);

  const queryClient = useQueryClient();

  const isAchPrepaid = useIsAchPrepaid();

  const { data: company } = useCompany();
  const accountId = company.id;

  const { data: creditSummary } = useCreditSummary();
  const currentCredit = credit || creditSummary.current_balance;

  const { enterpriseGlobalCheckoutFlowTemp031722: enterpriseCheckoutActive } = useFlags();
  const isEnterpriseAccount = company.enterprise;
  const isEnterpriseFlowActive = isEnterpriseAccount && enterpriseCheckoutActive;

  const handlePaymentInfoChange = infoChange => window.sessionStorage.setItem('updatePaymentProfile', infoChange);

  const setPaymentProfileSelected = value => {
    selectedProfile.current = value;
  };

  // const isPostalReferral = useSelector(state => state.company.affiliate_id === 'postal.io');

  const paymentTabs = [
    {
      title: 'Credit Card',
      content() {
        return (
          <CreditCard
            setContinuePaymentMethod={setContinuePaymentMethod}
            handleCardInfoChange={handlePaymentInfoChange}
            setPaymentProfileSelected={setPaymentProfileSelected}
          />
        );
      },
      paymentMethod: paymentMethods.creditCard
    },
    {
      title: 'ACH',
      content() {
        return (
          <ACH
            setContinuePaymentMethod={setContinuePaymentMethod}
            handleACHInfoChange={handlePaymentInfoChange}
            setPaymentProfileSelected={setPaymentProfileSelected}
          />
        );
      },
      paymentMethod: paymentMethods.ach
    },
    {
      title: 'Wire Transfer',
      content() {
        return <WireTransfer />;
      },
      paymentMethod: paymentMethods.wireTransfer
    }
    // hidden for a while - the check payment
    // {
    //   title: isPostalReferral ? 'Bill to Postal' : 'Mail Check',
    //   content() {
    //     return <MailCheck postal={isPostalReferral} />;
    //   },
    //   paymentMethod: paymentMethods.mailCheck
    // }
  ];

  const [tabs, setTabs] = useState(zipList(paymentTabs));

  const getAccountId = async () => {
    const apiCall = await props.loadCompany();
    log.debug('called loadCompany, apiCall:', apiCall);
    return apiCall.result === 'ok' ? apiCall.data.results[0].id : null;
  };

  const getDefaultProfile = async id => {
    setIsLoading(true);
    const accId = id || (await getAccountId());
    log.debug('getDefaultProfile, accId:', accId);
    if (accId) {
      const apiCall = await props.getPaymentProfiles(accId);
      if (apiCall.result === 'ok') {
        selectedProfile.current = apiCall.data.find(profile => profile.default);
      } else {
        toErrorPage(apiCall, history);
      }
    } else {
      toErrorPage("can't get account/company id in PaymentSection", history);
    }
    setIsLoading(false);
  };

  const handlePaymentMethodChange = newMethod => window.sessionStorage.setItem('method', newMethod);

  useEffect(() => {
    setTabs(zipList(simple ? paymentTabs.slice(0, isAchPrepaid ? 2 : 1) : paymentTabs));
    handlePaymentMethodChange(method);
    handlePaymentInfoChange(false);
    getDefaultProfile(accountId);
    if (handleUseCreditFirst) handleUseCreditFirst(false);
    return () => {
      window.sessionStorage.removeItem('method');
      window.sessionStorage.removeItem('updatePaymentProfile');
    };
  }, []);

  useEffect(() => setTabs(prevTabs => prevTabs.setActive(activeTab)), [activeTab]);

  useEffect(() => window.sessionStorage.setItem('method', method), [method]);

  useEffect(() => {
    const creditCardOrACHTab = isCreditCardOrACH(method, isAchPrepaid);
    const canPlaceOrder =
      !creditCardOrACHTab || (defaultProfile && defaultProfile.payment_method === method && selectedDefaultProfile);

    if (isUsingCreditAvailable) {
      const dif = parseFloat(currentCredit) - parseFloat(cost ?? orderCost);
      setRemaining(dif);
      onValidityChange(dif > 0 || canPlaceOrder);
      setShowOtherPaymentMethods(dif < 0);
      if (dif > 0) setMethod(paymentMethods.accountCredits);
    } else {
      setMethod(Object.values(paymentMethods)[activeTab]);
      onValidityChange(canPlaceOrder);
      setShowOtherPaymentMethods(true);
    }
  }, [isUsingCreditAvailable, method, defaultProfile, selectedDefaultProfile, activeTab]);

  useEffect(() => {
    setShowTabInfo(!(isLoading || openPaymentOptionModal || openContactModal));
  }, [openContactModal, openPaymentOptionModal, isLoading]);

  useEffect(() => {
    if (handleUseCreditFirst) handleUseCreditFirst(isUsingCreditAvailable);
  }, [isUsingCreditAvailable, handleUseCreditFirst]);

  const handleOnSubmitSuccess = async paymentConfirmation => {
    const error = await handleAfterPayConfirmation(paymentConfirmation);
    setErrorMessage(error);
    if (!error) {
      queryClient.invalidateQueries(apiPaths.accountOrders);
    }
    setIsLoading(false);
  };

  const handleOnSubmitError = error => {
    setIsLoading(false);
    if (handleErrorDuringConfirmation) handleErrorDuringConfirmation(error);
  };

  const handleOnSubmit = async () => {
    if (handleBeforeSubmit) handleBeforeSubmit();
    else setIsLoading(true);
    paymentSubmit(
      accountId,
      props.setDefaultPaymentProfile,
      handleOnSubmitSuccess,
      handleOnSubmitError,
      selectedProfile.current,
      isAchPrepaid
    );
  };

  const setTabAndMethod = (newTab, newMethod) => {
    if (activeTab !== newTab) setActiveTab(newTab);
    if (method !== newMethod) setMethod(newMethod);
  };

  const isDefaultACH = profile => profile && profile.payment_method === paymentMethods.ach;

  const updateTabAndMethod = defaultProf => {
    const [newTab, newMethod] = isDefaultACH(defaultProf) ? [1, paymentMethods.ach] : [0, paymentMethods.creditCard];
    setTabAndMethod(newTab, newMethod);
  };

  useEffect(() => {
    if (!isEmpty(paymentProfiles)) {
      const defaultProf = paymentProfiles.find(p => p.default);
      const currentProfile =
        isAchPrepaid || defaultProf?.payment_method !== paymentMethods.ach ? defaultProf : undefined;
      setDefaultProfile(currentProfile);
      updateTabAndMethod(currentProfile);
      if (!currentProfile) setErrorMessage('No default payment method selected');
    }
  }, [paymentProfiles, isAchPrepaid]);

  useEffect(() => {
    if (!defaultProfile || isLoading || openContactModal || openPaymentOptionModal) return;

    updateTabAndMethod(defaultProfile);
  }, [defaultProfile, isLoading, openContactModal, openPaymentOptionModal]);

  const handleSubmitFn = handleSubmit ? () => handleSubmit(handleOnSubmit) : handleOnSubmit;

  const toggleUsingCreditAvailable = () => setIsUsingCreditAvailable(isUsingCredit => !isUsingCredit);

  const areProfilesOf = payMethod => paymentProfiles.some(p => p.payment_method === payMethod);

  const handleChangeTab = (index, paymentMethod) => {
    const defaultTab = defaultProfile && defaultProfile.payment_method === paymentMethod;
    const creditCardOrACHTab = isCreditCardOrACH(paymentMethod, isAchPrepaid);
    const openModal = creditCardOrACHTab && !defaultTab && areProfilesOf(paymentMethod);
    setOpenPaymentOptionModal(isAchPrepaid ? openModal : false);
    setTabAndMethod(index, paymentMethod);
  };

  const toggleDefaultProfile = () => setSelectedDefaultProfile(isSelected => !isSelected);

  const toggleOpenPaymentOption = () => setOpenPaymentOptionModal(isOpen => !isOpen);

  const toggleOpenContactModal = () => setOpenContactModal(isOpen => !isOpen);

  const [title, subtitle] =
    method === paymentMethods.creditCard ? ['Credit Card', 'credits cards'] : ['ACH Account', 'ACH accounts'];

  const setDefaultOnModalSelector = async () => {
    setOpenPaymentOptionModal(false);
    if (selectedProfile && !selectedProfile.current.default) {
      setIsLoading(true);
      const paymentProfileId = selectedProfile.current.customer_payment_profile_id;
      const response = await props.setDefaultPaymentProfile(accountId, paymentProfileId, true);
      if (response.result === 'ok') {
        selectedProfile.current = response.data;
        setDefaultProfile(selectedProfile.current);
        updateTabAndMethod(selectedProfile.current);
        setSelectedDefaultProfile(true);
        setIsLoading(false);
        setShowTabInfo(true);
      } else toErrorPage(response, history);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmitFn()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {handleUseCreditFirst && (
              <AvailableCredits
                isUsingCreditAvailable={isUsingCreditAvailable}
                orderCost={cost ?? orderCost}
                currentBalance={currentCredit}
                toggleUsingCreditAvailable={toggleUsingCreditAvailable}
              />
            )}
          </Grid>
          {isUsingCreditAvailable && showOtherPaymentMethods && (
            <Grid item xs={12}>
              <span className={classes.payRemaining}>Select a payment method to pay the remaining</span>
              <span className={classes.payRemainingQty}>{`  ${moneyStr(-remaining, 2)}`}</span>
            </Grid>
          )}
          {!isEnterpriseFlowActive && showOtherPaymentMethods && (
            <PaymentOptions
              activeTab={activeTab}
              tabs={tabs}
              handleChangeTab={handleChangeTab}
              defaultProfile={defaultProfile}
              selectedDefaultProfile={selectedDefaultProfile}
              toggleDefaultProfile={toggleDefaultProfile}
              showTabInfo={showTabInfo}
              areProfilesInActiveTab={areProfilesOf(Object.values(paymentMethods)[activeTab])}
              toggleOpenPaymentOption={toggleOpenPaymentOption}
              title={title}
              setOpenContactModal={setOpenContactModal}
              setOpenPaymentOptionModal={setOpenPaymentOptionModal}
              canAddPaymentProfiles={paymentProfiles.length < 10}
              classes={classes}
            />
          )}
          <Grid container item xs={12}>
            {errorMessage && (
              <ErrorAlert error={errorMessage} onError={setErrorMessage} className={classes.errorAlert} />
            )}
            <Grid item xs={12} style={{ paddingTop: 24 }}>
              {!isLoading && children}
            </Grid>
          </Grid>
          <PaymentOptionsModal
            open={openPaymentOptionModal}
            tabs={tabs}
            toggleOpenPaymentOption={toggleOpenPaymentOption}
            title={title}
            subtitle={subtitle}
            continuePaymentMethod={continuePaymentMethod}
            setDefaultPaymentProfile={setDefaultOnModalSelector}
            setOpenContactModal={setOpenContactModal}
            setOpenPaymentOptionModal={setOpenPaymentOptionModal}
            canAddPaymentProfiles={paymentProfiles.length < 10}
            classes={classes}
          />
        </Grid>
      </Form>
      <AddPaymentMethodDialog paymentMethod={method} open={openContactModal} onClose={toggleOpenContactModal} />
    </>
  );
};

const mapStateToProps = state => ({
  orderCost: state.shippingOrderCost,
  paymentProfiles: state.paymentProfiles
});

const PaymentSectionWithForm = reduxForm({
  form: 'paymentSectionForm',
  enableReinitialize: false
})(PaymentSection);

export default connect(mapStateToProps, {
  getPaymentProfiles,
  setDefaultPaymentProfile,
  loadCompany
})(withLoader(PaymentSectionWithForm, false, styles));
