import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { setReorderProductsData, toggleReorderProductDetails, removeBagSubItem } from '../../actions';
import { ReorderImageSizesSection, ReorderPriceSection, ReorderDetailsSection } from './CommonReorderElements';
import ReorderSizesProductionTimeElements from './ReorderSizesProductionTimeElements';
import log from '../../logger';

const reorderProductImageSizesSection = (element, onChangeSize, onRemoveElement, onToggleDetails, classes) => (
  <ReorderImageSizesSection
    element={element}
    onChangeSize={onChangeSize}
    onRemoveElement={onRemoveElement}
    classes={classes}
    onToggleDetails={onToggleDetails}
  />
);

const reorderProductPriceSection = (element, onSetProductionTime, classes) => (
  <ReorderPriceSection element={element} onSetProductionTime={onSetProductionTime} classes={classes} />
);

const reorderProductDetailsSection = (element, doRemoveBagSubItem) => (
  <ReorderDetailsSection element={element} doRemoveBagSubItem={doRemoveBagSubItem} />
);

const ReorderProductsList = props => {
  const { setInPricing } = props;
  const history = useHistory();
  const route = useRouteMatch();
  const reorderProducts = useSelector(state => state.reorderProducts);

  const dispatch = useDispatch();

  const setReorderProductsSizesProductionTime = async (toBeRemoved, newSizes, newProdTimes) => {
    const [path] = route.path.split(':');
    const remainingIds = route.params.ids.split(',').filter(id => !toBeRemoved.has(+id));
    log.debug('removed elements id:', toBeRemoved, 'remaining ids:', remainingIds);
    const newElements = reorderProducts.filter(p => !toBeRemoved.has(p.id));
    const response = await dispatch(setReorderProductsData(newElements, newSizes, newProdTimes));
    if (toBeRemoved.size > 0) {
      const from = history.location.state?.from ? null : '/inventory';
      const newPath = newElements.length > 0 ? `${path}${remainingIds.join()}` : from;
      const action = newPath ? () => history.replace(newPath, history.location.state) : history.goBack;

      return { response, action };
    }
    return { response };
  };

  const toggleElementDetails = id => dispatch(toggleReorderProductDetails(id));
  const removeSubItem = (idBag, idItem) => dispatch(removeBagSubItem(idBag, idItem));

  return (
    <ReorderSizesProductionTimeElements
      elements={reorderProducts}
      setInPricing={setInPricing}
      setElementsData={setReorderProductsSizesProductionTime}
      renderImageSizesSection={reorderProductImageSizesSection}
      renderPriceSection={reorderProductPriceSection}
      toggleElementDetails={toggleElementDetails}
      removeBagSubItem={removeSubItem}
      renderDetailsSection={reorderProductDetailsSection}
    />
  );
};

export default React.memo(ReorderProductsList);
