const styles = {
  prodTimeRadio: ({ disabled, checked }) => ({
    backgroundColor: disabled ? '#fafafa' : (checked && '#f1f5fb') || 'transparent',
    color: disabled ? '#f0f2f5' : (checked && '#3577d4') || '#d4d9e2',
    border: '1px solid !important',
    height: 80,
    cursor: disabled ? 'default' : 'pointer',
    borderRadius: 12,
    padding: '14px 16px',
    width: '100%'
  }),
  prodType: ({ disabled, checked }) => ({
    color: disabled ? '#ced1d6' : (checked && '#3577d4') || '#0f2440',
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    display: 'flex',
    textAlign: 'left'
  }),
  prodTypeCost: ({ disabled }) => ({
    color: disabled ? '#ced1d6' : '#3577d4',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    textAlign: 'right'
  }),
  turnAroundTime: ({ disabled }) => ({
    color: disabled ? '#ced1d6' : '#8d9299',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12
  }),
  barRoot: {
    width: '50%',
    height: 8,
    borderRadius: 20
  },
  bar: { backgroundColor: '#f0f2f5' },
  rushIcon: { marginLeft: 8 },
  discountText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  discountBadge: { background: '#90BEFF', borderRadius: 16, padding: '4px 10px 4px 6px' },
  discountImageWrapper: { width: 16, height: 16, position: 'relative' },
  discountImage: { width: '100%', height: '100%', objectFit: 'contain' }
};

export default styles;
