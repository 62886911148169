import * as React from 'react';
import { useRouter } from 'next/router';

const useQueryParams = () => {
  const router = useRouter();
  const query = router?.query;
  const search = router ? query.toString() : window.location.search;
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default useQueryParams;
