import axios from '../DashBoard';
import apiPaths from '../../helpers/apiPaths';
import { status200, status200or201, status201 } from './status.utils';

// TODO: This shouldn't be necessary in the near future
const patchNegativeStock = products =>
  products?.map(product => ({
    ...product,
    stock: product.stock.map(s => ({
      ...s,
      quantity: Math.max(0, s.quantity)
    }))
  }));

const deleteOrRestoreAccountProducts = ({ ids, deleteIt }) =>
  axios
    .patch(
      apiPaths.accountProducts,
      ids.map(id => ({ id, delete_it: deleteIt })),
      status200
    )
    .then(response => response.data);

export default {
  fetch: params =>
    axios.get(apiPaths.accountProducts, { ...status200, params }).then(response => ({
      ...response.data,
      status: response.status, // TODO: Remove after refactoring to use react query
      result: response.result, // TODO: Remove after refactoring to use react query
      results: patchNegativeStock(response.data.results)
    })),

  getOne: (params, id) =>
    axios.get(`${apiPaths.accountProducts}?id=${id}`, { ...status200, params }).then(response => ({
      ...response.data,
      status: response.status, // TODO: Remove after refactoring to use react query
      result: response.result, // TODO: Remove after refactoring to use react query
      results: patchNegativeStock(response.data.results)
    })),

  editName: ({ prodId, name }) =>
    axios.patch(apiPaths.accountProduct(prodId), { name }, status200).then(response => response.data),

  sendComment: (prodId, comment) =>
    axios.post(apiPaths.accountProductComments(prodId), comment, status201).then(response => response.data),

  fetchComments: prodId =>
    axios
      .get(`${apiPaths.accountProductComments(prodId)}?ordering=-modified_on`, status200)
      .then(response => response.data),

  createAccountProducts: products =>
    axios.post(apiPaths.accountProducts, products, status201).then(response => response.data),

  updatePack: async ({ id, packItems }) => {
    const payload = {
      items: packItems?.map(item => ({
        product: item.product.id,
        units_per_pack: item.units_per_pack
      }))
    };

    const response = await axios.put(apiPaths.accountProduct(id), payload, status200);
    return response.data;
  },

  deleteAccountProducts: ids => deleteOrRestoreAccountProducts({ ids, deleteIt: true }),

  restoreAccountProducts: ids => deleteOrRestoreAccountProducts({ ids, deleteIt: false }),

  availableFilters: () => axios.get(apiPaths.availableFilters).then(response => response.data),

  getProduct: id => axios.get(apiPaths.accountProduct(id), status200).then(response => response.data),

  inventoryHistory: query =>
    axios.get(`${apiPaths.inventoryHistory}?${query}`, status200).then(response => response.data),

  imageBackgroundRemoval: (imagePath, bucket) =>
    axios
      .post(apiPaths.imageBackgroundRemoval, { input_image: imagePath, bucket }, status200or201)
      .then(rslt => rslt.data)
      .catch(({ response }) => response.data)
};
