import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import { Grid, Card, CardContent, CardActions, Chip, makeStyles, Typography, withStyles } from '@material-ui/core';
import { imageSrcSet } from '../../helpers/utils';
import gtm from '../../utils/gtm';

const useStyles = makeStyles({
  card: ({ isCustomPacksCard }) => ({
    borderRadius: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'center',
    alignItems: 'center',
    width: 544,
    border: '1px solid #ebedf0',
    margin: '0px 32px',
    backgroundColor: isCustomPacksCard ? '#EBF1FB' : '#FFFFFF'
  }),
  subtitle: ({ isCustomPacksCard }) => ({
    fontFamily: 'Gilroy-Bold',
    maxWidth: isCustomPacksCard ? 360 : 280,
    margin: 'auto',
    color: '#0b1829'
  }),
  center: { justifyContent: 'center' },
  option: { paddingTop: 45 },
  cardContent: { paddingTop: 0 },
  starterPackBtn: {
    '&:hover': {
      color: '#004ca2'
    }
  }
});

const CardDetails = ({ content, isCustomPacksCard, classes }) => {
  const location = useLocation();
  const fromProducts = location.state?.fromProducts;
  const fromLandingPages = location.pathname === '/paid-onboarding';
  const buttons = [
    { text: 'Explore preset packs', to: '/preset-packs' },
    { text: 'Build my own pack', to: { pathname: content.to, state: { fromProducts } } }
  ];
  const [primaryBtn, secondaryBtn] = fromLandingPages ? buttons : buttons.reverse();

  const handleGTM = () => gtm.onClick(`Onboarding - ${isCustomPacksCard ? 'Build a Pack' : 'Bulk Swag'}`);

  return (
    <>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" className={classes.subtitle}>
          {content.subtitle}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.center}>
        <Grid container direction="column" style={{ marginBottom: 10 }}>
          <Button variant="primary" component={Link} to={primaryBtn.to} replace={content.replace} onClick={handleGTM}>
            {isCustomPacksCard ? primaryBtn.text : 'Select bulk items'}
          </Button>
          {isCustomPacksCard && (
            <Button
              variant="text"
              component={Link}
              to={secondaryBtn.to}
              replace={content.replace}
              onClick={handleGTM}
              className={classes.starterPackBtn}
            >
              {secondaryBtn.text}
            </Button>
          )}
        </Grid>
      </CardActions>
    </>
  );
};

const StatusChip = withStyles({
  root: {
    padding: '9px 13px',
    borderRadius: 16,
    textAlign: 'center',
    color: '#3577D4',
    backgroundColor: '#F4F8FF',
    alignSelf: 'flex-end',
    position: 'absolute',
    marginTop: 14,
    marginRight: 14
  },
  label: {
    fontSize: 12,
    padding: 0,
    fontFamily: 'Gilroy-Medium'
  }
})(Chip);

const ProductOnboardingItem = ({ content, isCustomPacksCard, isPostalReferral }) => {
  const classes = useStyles({ isCustomPacksCard });
  const src = `/images/dashboard/${content.image}.png`;
  return (
    <Grid container justifyContent="center" item xs={12} md={isPostalReferral ? 12 : 6} className={classes.option}>
      <Card elevation={0} data-testid={content.image} className={classes.card}>
        {isCustomPacksCard && <StatusChip label="Recommended" />}
        <img
          src={src}
          srcSet={imageSrcSet('/images/dashboard/', content.image)}
          alt={content.image}
          style={{ height: 205 }}
        />
        <CardDetails content={content} isCustomPacksCard={isCustomPacksCard} classes={classes} />
      </Card>
    </Grid>
  );
};

export { ProductOnboardingItem };
