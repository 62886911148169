const defaults = {
  variant: 'default',
  fontSize: 'unset',
  fontColor: 'unset',
  fontFamily: 'unset',
  backgroundColor: 'unset',
  padding: 'unset',
  margin: 'unset',
  border: 'unset',
  borderRadius: 'unset',
  width: 'unset',
  height: 'unset',
  objectFit: 'unset',
  action: 'unset',
  argument: 'unset',
  contentAlignment: 'unset'
};

const headerTitle = {
  ...defaults,
  name: 'Heeader Title',
  type: 'header-title',
  value: 'Shop the SwagUp Collection',
  fontSize: '56px',
  fontColor: 'inherit',
  fontFamily: 'inherit',
  margin: '0px 0px 32px 0px'
};

const subHeaderText = {
  ...defaults,
  name: 'Subheader Text',
  type: 'subheader-text',
  value: 'Shop all products',
  fontSize: '42px',
  fontColor: 'inherit',
  fontFamily: 'inherit'
};

const subtitleText = {
  ...defaults,
  name: 'Subtitle Text',
  type: 'subtitle-text',
  value: 'Shop seasonally refreshed items to show your SwagUp pride 365 days a year!',
  fontSize: '24px',
  fontColor: 'inherit',
  fontFamily: 'inherit',
  margin: '0px 0px 32px 0px'
};

const produtCatalogSectionText = {
  ...defaults,
  name: 'Text',
  type: 'text',
  value: 'Here is our amazing product catalog tailored just for you.',
  fontSize: '16px',
  fontColor: 'inherit',
  fontFamily: 'inherit'
};

const buttonLink = {
  ...defaults,
  name: 'Button Link',
  type: 'button-link',
  value: 'Shop Now',
  action: 'link',
  argument: '#product-catalog-section',
  fontSize: '16px',
  fontColor: '#ffffff',
  fontFamily: 'inherit',
  backgroundColor: 'inherit',
  padding: '8px 42px',
  margin: '0px',
  border: 'none',
  borderRadius: 'inherit',
  width: 'fit-content',
  height: 'auto'
};

const image = {
  ...defaults,
  name: 'Image',
  type: 'image',
  value: '/images/storefront/storefront-hero.png',
  fontSize: '16px',
  fontColor: '#ffffff',
  fontFamily: 'Gilroy',
  backgroundColor: '#3577D4',
  padding: '12px 16px;',
  borderRadius: '16px',
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

const productCatalog = {
  ...defaults,
  name: 'Product Catalog',
  type: 'product-catalog',
  value: 'all',
  action: 'Filter',
  argument: 'item',
  fontSize: '16px',
  fontColor: 'inherit',
  fontFamily: 'inherit',
  backgroundColor: 'inherit',
  width: 'auto',
  height: 'auto'
};

const featuredProductCatalog = {
  ...defaults,
  name: 'Product Catalog',
  type: 'product-catalog',
  value: 'Featured',
  action: 'Filter',
  argument: 'item',
  fontSize: '16px',
  fontColor: 'inherit',
  fontFamily: 'inherit',
  backgroundColor: 'inherit',
  width: 'auto',
  height: 'auto'
};

export {
  headerTitle,
  subtitleText,
  buttonLink,
  image,
  productCatalog,
  subHeaderText,
  produtCatalogSectionText,
  featuredProductCatalog
};
