import { Box, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { MenuItem, Typography } from '@swagup-com/components';
import { CheckCircle } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { StylessButton } from '../../buttons';
import styles from '../styles/storefrontsHome';
import { ColorInput, CustomTextField, RedeemCustomSelectField } from '../storeCommon';
import { isHexColor } from '../../shared/styles/utils';

const useStyles = makeStyles(styles);
const heroVariantStyles = () => ({
  varianBox: {
    width: 240,
    height: 142,
    padding: '4px 2px',
    borderRadius: 8,
    border: ({ selected }) => `1px solid ${selected ? '#3577D4' : 'transparent'}`,
    cursor: 'pointer',
    position: 'relative'
  },
  checkedIcon: {
    display: ({ selected }) => (selected ? 'inherit' : 'none'),
    position: 'absolute',
    zIndex: 1,
    top: 6,
    right: 12,
    height: 16,
    width: 12,
    fontSize: 16,
    color: '#3577D4'
  }
});

const useSheroVariantStylestyles = makeStyles(heroVariantStyles);

const HeroVariant = ({ name, imageSrc, onselect, selected }) => {
  const classes = useSheroVariantStylestyles({ selected });
  return (
    <StylessButton onClick={() => onselect(name)}>
      <Box className={classes.varianBox}>
        {selected && <CheckCircle className={classes.checkedIcon} />}
        <img src={imageSrc} style={{ width: '100%', height: '100%' }} alt="checked" />
      </Box>
    </StylessButton>
  );
};

// const getCustomPixelFontSize = (componenType, value) => {
//   switch (componenType) {
//     case 'subtitle-text':
//       switch (value) {
//         case 'Small':
//           return '20px';
//         case 'Medium':
//           return '24px';
//         case 'Large':
//           return '28px';
//         default:
//           return '24px';
//       }
//     case 'header-title':
//       switch (value) {
//         case 'Small':
//           return '48px';
//         case 'Medium':
//           return '56px';
//         case 'Large':
//           return '64px';
//         default:
//           return '42px';
//       }
//     case 'button-link':
//       switch (value) {
//         case 'Small':
//           return '14px';
//         case 'Medium':
//           return '16px';
//         case 'Large':
//           return '18px';
//         default:
//           return '16px';
//       }
//     default:
//       return '16px';
//   }
// };

// const getCustomNameFontSize = (componenType, value) => {
//   switch (componenType) {
//     case 'subtitle-text':
//       switch (value) {
//         case '20px':
//           return 'Small';
//         case '24px':
//           return 'Medium';
//         case '28px':
//           return 'Large';
//         default:
//           return 'Medium';
//       }
//     case 'header-title':
//       switch (value) {
//         case '48px':
//           return 'Small';
//         case '56px':
//           return 'Medium';
//         case '64px':
//           return 'Large';
//         default:
//           return 'Medium';
//       }
//     case 'button-link':
//       switch (value) {
//         case '14px':
//           return 'Small';
//         case '16px':
//           return 'Medium';
//         case '18px':
//           return 'Large';
//         default:
//           return 'Medium';
//       }
//     default:
//       return 'Medium';
//   }
// };

const TextEditSection = ({
  label,
  name,
  placeholder,
  position,
  section,
  onSectionEdit,
  fontSizes,
  multiline,
  visualSettings
}) => {
  const classes = useStyles();
  if (isEmpty(section.columns[0]?.components[position])) return null;
  return (
    <Box style={{ marginTop: 24 }}>
      <FormControlLabel
        className={classes.formControl}
        labelPlacement="top"
        control={
          <CustomTextField
            className={classes.inputText}
            placeholder={placeholder}
            defaultValue={section.columns[0].components[position].value}
            value={section.columns[0].components[position].value}
            name={name}
            onChange={event =>
              onSectionEdit({
                ...section,
                columns: section.columns.map((col, idx) =>
                  idx === 0
                    ? {
                        ...col,
                        components: col.components.map((comp, index) =>
                          index === position ? { ...comp, value: event.target.value } : comp
                        )
                      }
                    : col
                )
              })
            }
            multiline={multiline}
            fullWidth
          />
        }
        label={
          <Typography variant="body3RegularInter" className={classes.inputLabel}>
            {label}
          </Typography>
        }
      />
      <Grid container alignItems="center" spacing={6} style={{ marginTop: 16 }}>
        <Grid item xs={6}>
          <FormControlLabel
            className={classes.formControl}
            labelPlacement="top"
            control={
              <RedeemCustomSelectField
                id="fontSize"
                name="fontSize"
                label="Font Size"
                value={section.columns[0].components[position].fontSize}
                className={classes.selectField}
                totalItems={fontSizes.length}
                handleSelect={({ target: { value } }) =>
                  onSectionEdit({
                    ...section,
                    columns: section.columns.map((col, idx) =>
                      idx === 0
                        ? {
                            ...col,
                            components: col.components.map((comp, index) =>
                              index === position ? { ...comp, fontSize: value } : comp
                            )
                          }
                        : col
                    )
                  })
                }
                fullWidth
                withTooltip
              >
                {fontSizes.map(fs => (
                  <MenuItem key={fs.name} value={fs.value} title={fs.name}>
                    {fs.name}
                  </MenuItem>
                ))}
              </RedeemCustomSelectField>
            }
            label={
              <Typography variant="body3RegularInter" className={classes.inputLabel}>
                Font Size
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            className={classes.formControl}
            labelPlacement="top"
            control={
              <ColorInput
                value={
                  section.columns[0].components[position].fontColor === 'inherit'
                    ? visualSettings.fontColor
                    : section.columns[0].components[position].fontColor
                }
                onChange={color =>
                  onSectionEdit({
                    ...section,
                    columns: section.columns.map((col, idx) =>
                      idx === 0
                        ? {
                            ...col,
                            components: col.components.map((comp, index) =>
                              index === position ? { ...comp, fontColor: color } : comp
                            )
                          }
                        : col
                    )
                  })
                }
                inputClass={
                  isHexColor(section.columns[0].components[position].fontColor) ||
                  section.columns[0].components[position].fontColor === 'inherit'
                    ? classes.inputColorText
                    : classes.inputColorTextError
                }
              />
            }
            label={
              <Typography variant="body3RegularInter" className={classes.inputLabel}>
                Font color
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ButtonEditSection = ({
  label = 'Button text*',
  name = 'button',
  placeholder = 'Edit your button text',
  position = 2,
  section,
  onSectionEdit,
  fontSizes,
  buttonStyles,
  multiline,
  visualSettings
}) => {
  const classes = useStyles();
  if (isEmpty(section.columns[0]?.components[position])) return null;
  return (
    <Box style={{ marginTop: 24 }}>
      <FormControlLabel
        className={classes.formControl}
        labelPlacement="top"
        control={
          <CustomTextField
            className={classes.inputText}
            placeholder={placeholder}
            defaultValue={section.columns[0].components[position].value}
            value={section.columns[0].components[position].value}
            name={name}
            onChange={event =>
              onSectionEdit({
                ...section,
                columns: section.columns.map((col, idx) =>
                  idx === 0
                    ? {
                        ...col,
                        components: col.components.map((comp, index) =>
                          index === position ? { ...comp, value: event.target.value } : comp
                        )
                      }
                    : col
                )
              })
            }
            multiline={multiline}
            fullWidth
          />
        }
        label={
          <Typography variant="body3RegularInter" className={classes.inputLabel}>
            {label}
          </Typography>
        }
      />
      <Grid container alignItems="center" spacing={6} style={{ marginTop: 16 }}>
        <Grid item xs={6}>
          <FormControlLabel
            className={classes.formControl}
            labelPlacement="top"
            control={
              <RedeemCustomSelectField
                id="fontSize"
                name="fontSize"
                label="Font Size"
                value={section.columns[0].components[position].fontSize}
                className={classes.selectField}
                totalItems={fontSizes.length}
                handleSelect={({ target: { value } }) =>
                  onSectionEdit({
                    ...section,
                    columns: section.columns.map((col, idx) =>
                      idx === 0
                        ? {
                            ...col,
                            components: col.components.map((comp, index) =>
                              index === position ? { ...comp, fontSize: value } : comp
                            )
                          }
                        : col
                    )
                  })
                }
                fullWidth
                withTooltip
              >
                {fontSizes.map(fs => (
                  <MenuItem key={fs.name} value={fs.value} title={fs.name}>
                    {fs.name}
                  </MenuItem>
                ))}
              </RedeemCustomSelectField>
            }
            label={
              <Typography variant="body3RegularInter" className={classes.inputLabel}>
                Font Size
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            className={classes.formControl}
            labelPlacement="top"
            control={
              <ColorInput
                value={
                  section.columns[0].components[position].fontColor === 'inherit'
                    ? visualSettings.fontColor
                    : section.columns[0].components[position].fontColor
                }
                onChange={color =>
                  onSectionEdit({
                    ...section,
                    columns: section.columns.map((col, idx) =>
                      idx === 0
                        ? {
                            ...col,
                            components: col.components.map((comp, index) =>
                              index === position ? { ...comp, fontColor: color } : comp
                            )
                          }
                        : col
                    )
                  })
                }
                inputClass={
                  isHexColor(section.columns[0].components[position].fontColor) ||
                  section.columns[0].components[position].fontColor === 'inherit'
                    ? classes.inputColorText
                    : classes.inputColorTextError
                }
              />
            }
            label={
              <Typography variant="body3RegularInter" className={classes.inputLabel}>
                Font color
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={6} style={{ marginTop: 16 }}>
        <Grid item xs={6}>
          <FormControlLabel
            className={classes.formControl}
            labelPlacement="top"
            control={
              <RedeemCustomSelectField
                id="borderRadius"
                name="borderRadius"
                label="Button style"
                value={section.columns[0].components[position].borderRadius}
                className={classes.selectField}
                totalItems={buttonStyles.length}
                handleSelect={({ target: { value } }) =>
                  onSectionEdit({
                    ...section,
                    columns: section.columns.map((col, idx) =>
                      idx === 0
                        ? {
                            ...col,
                            components: col.components.map((comp, index) =>
                              index === position ? { ...comp, borderRadius: value } : comp
                            )
                          }
                        : col
                    )
                  })
                }
                fullWidth
                withTooltip
              >
                {buttonStyles.map(fs => (
                  <MenuItem key={fs.name} value={fs.value} title={fs.name}>
                    {fs.name}
                  </MenuItem>
                ))}
              </RedeemCustomSelectField>
            }
            label={
              <Typography variant="body3RegularInter" className={classes.inputLabel}>
                Button stye
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            className={classes.formControl}
            labelPlacement="top"
            control={
              <ColorInput
                value={
                  section.columns[0].components[position].backgroundColor === 'inherit'
                    ? visualSettings.accentColor
                    : section.columns[0].components[position].backgroundColor
                }
                onChange={color =>
                  onSectionEdit({
                    ...section,
                    columns: section.columns.map((col, idx) =>
                      idx === 0
                        ? {
                            ...col,
                            components: col.components.map((comp, index) =>
                              index === position ? { ...comp, backgroundColor: color } : comp
                            )
                          }
                        : col
                    )
                  })
                }
                inputClass={
                  isHexColor(section.columns[0].components[position].backgroundColor) ||
                  section.columns[0].components[position].backgroundColor === 'inherit'
                    ? classes.inputColorText
                    : classes.inputColorTextError
                }
              />
            }
            label={
              <Typography variant="body3RegularInter" className={classes.inputLabel}>
                Button color
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export { HeroVariant, TextEditSection, ButtonEditSection };
