import React from 'react';
import { Box, FormControlLabel, Grid, Slider, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { isNumber } from 'lodash';
import { ColorInput, CustomTextField, ImageSelector } from '../storeCommon';
import styles from '../styles/storefrontsHome';
import { heroSectionLandscape, heroSectionTwoColumns } from '../dataTemplates/sectionTemplates';
import { isHexColor } from '../../shared/styles/utils';
import { ButtonEditSection, HeroVariant, TextEditSection } from './pageContentCommon';

const useStyles = makeStyles(styles);

const fontSizesHeader = [
  { name: 'Small', value: '48px' },
  { name: 'Medium', value: '56px' },
  { name: 'Large', value: '64px' }
];
const fontSizesSubHeader = [
  { name: 'Small', value: '20px' },
  { name: 'Medium', value: '24px' },
  { name: 'Large', value: '28px' }
];

const fontSizesButton = [
  { name: 'Small', value: '14px' },
  { name: 'Medium', value: '16px' },
  { name: 'Large', value: '18px' }
];

const buttonStyles = [
  { name: 'Same as theme', value: 'inherit' },
  { name: 'Rounded', value: '22px' },
  { name: 'Square', value: '4px' }
];

const herovariants = [heroSectionTwoColumns, heroSectionLandscape];

const HeroSectionEdit = ({ section, onSectionEdit, handleFileUpload, artworkLoader, visualSettings }) => {
  const getSectionImage = () =>
    section.variant === 'hero-two-columns' ? section.columns[1].components[0].value : section.backgroundImage;

  const getSectionImageProperty = () =>
    section.variant === 'hero-two-columns'
      ? 'visualSettings.sections[0].columns[1].components[0].value'
      : 'visualSettings.sections[0].backgroundImage';

  const onResetSectionImage = () => {
    const tempSection = section;
    if (section.variant === 'hero-two-columns')
      tempSection.columns[1].components[0].value = '/images/storefront/storefront-hero.png';
    else tempSection.backgroundImage = '/images/storefront/storefront-hero.png';
    onSectionEdit(tempSection);
  };
  const getEditableVariant = s => ({
    ...s,
    id: section.id,
    backgroundImage: s.variant === 'hero-landscape' ? section.columns[1]?.components[0].value : s.backgroundImage,
    // overlayColor: section.overlayColor,
    // overlayOpacity: section.overlayOpacity,
    columns: s.columns.map((col, idx) => ({
      ...col,
      id: section.columns[idx]?.id,
      components: col.components.map((comp, index) => ({
        ...comp,
        id: section.columns[idx]?.components[index]?.id,
        value:
          s.variant === 'hero-two-columns' && idx === 1
            ? section.backgroundImage
            : section.columns[idx]?.components[index]?.value || comp.value,
        fontColor: section.columns[idx]?.components[index]?.fontColor || comp.fontColor,
        fontSize: section.columns[idx]?.components[index]?.fontSize || comp.fontSize,
        backgroundColor: section.columns[idx]?.components[index]?.backgroundColor || comp.backgroundColor,
        borderRadius: section.columns[idx]?.components[index]?.borderRadius || comp.borderRadius
      }))
    }))
  });

  const classes = useStyles();
  return (
    <Box>
      <Box>
        <Typography variant="body3SemiBoldInter" style={{ marginBottom: 6 }}>
          Choose your layout
        </Typography>
        <Grid container justifyContent="center" spacing={8}>
          {herovariants.map(herovariant => (
            <Grid key={herovariant.variant} item>
              <HeroVariant
                name={herovariant.variant}
                selected={section.variant === herovariant.variant}
                imageSrc={`/images/storefront/${herovariant.variant}.svg`}
                onselect={() => onSectionEdit(getEditableVariant(herovariant))}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box style={{ marginTop: 24 }}>
        <Typography variant="body3SemiBoldInter" style={{ marginBottom: 6 }}>
          Choose your display image
        </Typography>
        <ImageSelector
          imageName={getSectionImage()}
          selected={!!getSectionImage()}
          onSelect={onResetSectionImage}
          artworkLoader={artworkLoader}
          url={getSectionImage()}
          property={getSectionImageProperty()}
          additionalProperty="seoSettings.featuredImage"
          handleFileUpload={handleFileUpload}
        />
        <Box style={{ marginTop: 16 }}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Box>
                {/* <Typography variant="body4RegularInter">Overlay color</Typography> */}
                <FormControlLabel
                  className={classes.formControl}
                  labelPlacement="top"
                  control={
                    <ColorInput
                      value={section.overlayColor}
                      onChange={color => onSectionEdit({ ...section, overlayColor: color })}
                      inputClass={
                        isHexColor(section.overlayColor) || section.overlayColor === 'unset'
                          ? classes.inputColorText
                          : classes.inputColorTextError
                      }
                    />
                  }
                  label={
                    <Typography variant="body3RegularInter" className={classes.inputLabel}>
                      Overlay color
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography variant="body3RegularInter" style={{ marginBottom: 6 }}>
                  Overlay opacity
                </Typography>
                <Grid container alignItems="center" spacing={4}>
                  <Grid item xs={8}>
                    <Slider
                      value={parseInt(isNumber(section.overlayOpacity) ? Number(section.overlayOpacity) * 100 : 0, 10)}
                      min={0}
                      max={100}
                      step={1}
                      onChange={(event, newValue) =>
                        onSectionEdit({
                          ...section,
                          overlayOpacity: newValue ? Number(newValue) / 100 : 0
                        })
                      }
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      className={classes.inputText}
                      placeholder="opacity"
                      defaultValue={parseInt(
                        isNumber(section.overlayOpacity) ? Number(section.overlayOpacity) * 100 : 0,
                        10
                      )}
                      value={parseInt(isNumber(section.overlayOpacity) ? Number(section.overlayOpacity) * 100 : 0, 10)}
                      name="overlayOpacity"
                      onChange={event =>
                        onSectionEdit({
                          ...section,
                          overlayOpacity: event.target.value ? Number(event.target.value) / 100 : 0
                        })
                      }
                      fullWidth
                      InputProps={{
                        endAdornment: '%'
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box style={{ marginTop: 24 }}>
        <Typography variant="body3SemiBoldInter">Customize text</Typography>
        <TextEditSection
          label="Hero text*"
          name="headerText"
          placeholder="Edit your heading text"
          section={section}
          position={0}
          fontSizes={fontSizesHeader}
          onSectionEdit={onSectionEdit}
          visualSettings={visualSettings}
        />
        <TextEditSection
          label="Subtitle text*"
          name="subtitleText"
          placeholder="Edit your subtitle text"
          section={section}
          position={1}
          fontSizes={fontSizesSubHeader}
          onSectionEdit={onSectionEdit}
          visualSettings={visualSettings}
          multiline
        />
      </Box>
      <Box style={{ marginTop: 24 }}>
        <Typography variant="body3SemiBoldInter">Customize button</Typography>
        <ButtonEditSection
          section={section}
          fontSizes={fontSizesButton}
          buttonStyles={buttonStyles}
          onSectionEdit={onSectionEdit}
          visualSettings={visualSettings}
        />
      </Box>
    </Box>
  );
};

const PageContent = ({ store, setStoreSettings, artworkLoader, handleFileUpload }) => {
  // const classes = useStyles();

  const getHeroSection = () => {
    const { sections } = store.visualSettings;
    return sections.find(s => s.templateName === 'hero-section');
  };

  const editSection = value => {
    const { sections } = store.visualSettings;
    const newSections = sections.map(s => (s.templateName === value.templateName ? value : s));
    return { sections: newSections };
  };

  return (
    <HeroSectionEdit
      section={getHeroSection()}
      onSectionEdit={newSection => setStoreSettings(editSection(newSection))}
      handleFileUpload={handleFileUpload}
      artworkLoader={artworkLoader}
      visualSettings={store.visualSettings}
    />
  );
};

export default PageContent;
