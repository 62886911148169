/* eslint-disable camelcase */
import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import CenteredGrid from '../shared/CenteredGrid';
import Header from './overview/Header';
import Help from './overview/Help';
import Separator from './overview/Separator';
import { ProductColor } from './InventoryCommon';

const useStyles = makeStyles(theme => ({
  columnContainer: {
    padding: '24px 0 49px',
    '& .leftColumn': {
      paddingRight: 18
    },
    '& .rightColumn': {
      paddingLeft: 18
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      '& .leftColumn': {
        paddingRight: 0,
        paddingTop: 20
      },
      '& .rightColumn': {
        paddingLeft: 0
      }
    }
  },

  pictureContainer: {
    paddingRight: 12
  },
  imageContainer: {
    width: 42,
    height: 42,
    position: 'relative'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  storageItemsContainer: {
    paddingTop: '24px'
  },
  storageItemRow: {
    border: 'solid 1px #ebeef2',
    borderRadius: 15,
    backgroundColor: '#ffffff',
    padding: '12px 24px 12px 16px',
    marginBottom: 12,
    position: 'relative'
  },
  summaryContainer: {
    border: 'solid 1px #ebeef2',
    borderRadius: 15,
    backgroundColor: '#ffffff',
    padding: '24px 16px'
  }
}));

const StoragePaymentOverview = () => {
  const { from, data, products, totalQuantity, totalCost } = useLocation().state || {};
  const classes = useStyles();

  const insufficientData = !(data || products);
  if (insufficientData) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container>
      <CenteredGrid container direction="column" alignItems="center">
        <Header />
        <Separator light />
        <Grid container spacing={8}>
          <Grid item xs={8}>
            <Box className={classes.storageItemsContainer}>
              {products.map(inv => (
                <Grid key={inv.id} container alignItems="center" className={classes.storageItemRow}>
                  <Grid item>
                    <Box className={classes.pictureContainer}>
                      <Box className={classes.imageContainer}>
                        <img src={inv.image} alt={inv.name} className={classes.image} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Typography component="p" variant="body4MediumInter">
                      {inv.name}
                    </Typography>
                    <Grid style={{ marginTop: 12, marginLeft: 2 }}>
                      <ProductColor item={inv} />
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Typography component="p" variant="body5MediumInter">
                      Total Quantity
                    </Typography>
                    <Grid style={{ marginTop: 12, marginLeft: 2 }}>
                      <Typography component="p" variant="body4MediumInter">
                        {inv.totalQuantity}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography component="p" variant="body5MediumInter">
                      Storage Price
                    </Typography>
                    <Grid style={{ marginTop: 12, marginLeft: 2 }}>
                      <Typography component="p" variant="body4MediumInter">
                        ${inv.storagePrice}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Typography component="p" variant="body5MediumInter" style={{ textAlign: 'right' }}>
                      Storage Cost
                    </Typography>
                    <Grid style={{ marginTop: 12, marginLeft: 2 }}>
                      <Typography component="p" variant="body4MediumInter" style={{ textAlign: 'right' }}>
                        ${inv.storageCost}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.storageItemsContainer}>
              <Box className={classes.summaryContainer}>
                <Typography component="p" variant="body3MediumInter">
                  Summary
                </Typography>
                <Box style={{ marginTop: 12, paddingTop: 12, borderTop: 'solid 1px #ebeef2' }}>
                  <Grid container alignItems="flex-end" style={{ marginBottom: 12 }}>
                    <Grid item xs>
                      <Typography component="p" variant="body4RegularInter">
                        Total Quantity
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="p" variant="body3MediumInter">
                        {totalQuantity}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="flex-end">
                    <Grid item xs>
                      <Typography component="p" variant="body4RegularInter">
                        Total Cost
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="p" variant="body3MediumInter">
                        ${totalCost.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {data?.id && (
                <Box style={{ padding: '24px 0px' }}>
                  <a
                    href={`${process.env.REACT_APP_API_ENDPOINT}/generic-invoices/${data?.id}`}
                    alt="invoice"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      component="p"
                      variant="body3MediumInter"
                      style={{ textAlign: 'right', color: '#3577d4' }}
                    >
                      Download Invoice
                    </Typography>
                  </a>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        <Help />
      </CenteredGrid>
    </Grid>
  );
};

export default StoragePaymentOverview;
