import React from 'react';
import { Grid, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import CloseButton from '../modals/CloseButton';
import styles from './styles/StorageBreakdownModal';

const StorageBreakdownItem = ({ label, frequency, examples, classes }) => (
  <>
    <Grid item xs={3}>
      <p className={classes.ItemSizePrice}>{label}</p>
      <p className={classes.RegularItemText} style={{ margin: '7px 0', paddingRight: 45 }}>
        {frequency}
      </p>
      <p className={classes.RegularItemText} style={{ width: 102 }}>
        {examples}
      </p>
    </Grid>
  </>
);

const ShippingDomesticBreakdownItem = ({ title, label, frequency, classes }) => (
  <>
    <Grid item xs={3}>
      <p className={classes.ShippingTitle}>{title}</p>
      <p className={classes.ItemSizePrice}>{label}</p>
      <p className={classes.RegularItemText}>{frequency}</p>
    </Grid>
  </>
);

const ShippingInternationalBreakdownItem = ({ label, frequency, classes }) => (
  <>
    <Grid item xs={3}>
      <p className={classes.ItemSizePrice} style={{ paddingTop: 26 }}>
        {label}
      </p>
      <p className={classes.RegularItemText}>{frequency}</p>
    </Grid>
  </>
);

const ShippingBreakdownRow = ({ individualPackPrices, classes }) => (
  <>
    <Grid item xs={4}>
      <p className={classes.ShippingTitle}>Bulk Item Shipping</p>
      <p className={classes.ItemSizePrice}>Determined by Address</p>
      <p className={classes.RegularItemText}>per shipment</p>
    </Grid>
    <Grid item xs={1}>
      <div className={classes.Plus}>
        <Add />
      </div>
    </Grid>
    <ShippingDomesticBreakdownItem
      title="SwagPack Shipping"
      label={`${'Domestic - $'}${individualPackPrices && Math.floor(individualPackPrices.us_price)}`}
      frequency="per pack"
      classes={classes}
    />
    <Grid item xs={1}>
      <div className={classes.Or}>or</div>
    </Grid>
    <ShippingInternationalBreakdownItem
      label={`${'International - $'}${individualPackPrices && Math.floor(individualPackPrices.international_price)}`}
      frequency="per pack"
      classes={classes}
    />
  </>
);

const StorageBreakdownModal = ({ classes, open, onClose, storageCategories, storagePrices, individualPackPrices }) => {
  const reducer = (r, item) => ({ ...r, [item.storage_category]: item.price });
  const pricesDict = storagePrices && storagePrices.reduce(reducer, {});

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <CloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.Title}>Price breakdown</div>
        <p className={classes.Question}>How is Storage Charged?</p>
        <Grid container style={{ marginBottom: 25 }}>
          {storageCategories &&
            storageCategories.map(item => (
              <StorageBreakdownItem
                key={item.id}
                label={`${item.label} - $${pricesDict[item.id]}`}
                frequency={item.frequency}
                examples={`(${item.examples})`}
                classes={classes}
              />
            ))}
        </Grid>
        <div className={classes.Question}>How is Shipping Charged?</div>
        <Grid container style={{ marginBottom: 25 }}>
          <ShippingBreakdownRow individualPackPrices={individualPackPrices} classes={classes} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(StorageBreakdownModal);
