const tableLeftOffset = -44;
export default {
  scrollableSection: {
    flex: 1,
    marginTop: 18,
    marginLeft: tableLeftOffset,
    width: `calc(100% - ${tableLeftOffset}px) !important`,
    '& .ScrollbarsCustom-Wrapper': {
      top: '28px !important'
    },
    '& .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX': {
      width: `calc(100% + ${2 * tableLeftOffset}px) !important`,
      top: '0 !important',
      bottom: 'unset !important',
      left: `${-tableLeftOffset}px !important`
    }
  },
  table: {
    [`& th:not([class*="statusCell"]):not([class*="checkboxCell"]),
      & td:not([class*="statusCell"]):not([class*="checkboxCell"])`]: {
      minWidth: 156,
      maxWidth: 156,
      fontFamily: 'Gilroy-Medium',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    '& th, & td': {
      border: 'none'
    },
    '& th': {
      backgroundColor: '#ffffff',
      padding: '0 20px 13px 0',
      lineHeight: 'normal',
      letterSpacing: 2,
      color: '#868a8f'
    },
    '& td': {
      padding: '14px 20px 14px 0',
      lineHeight: 0,
      letterSpacing: 'normal',
      color: '#0b1829',
      cursor: 'pointer',
      '&.error': {
        color: '#f44336'
      }
    },
    '& .checkboxCell': {
      minWidth: -tableLeftOffset,
      maxWidth: -tableLeftOffset
    },
    '& .statusCell': {
      minWidth: 46,
      maxWidth: 46,
      fontFamily: 'Gilroy-SemiBold'
    }
  },
  checkbox: {
    padding: 0,
    marginLeft: 5,
    '& svg': {
      fontSize: 18
    }
  },
  okIndicator: {
    fontSize: 20,
    color: '#45af5f'
  }
};
