import { OutlinedInput, withStyles } from '@material-ui/core';

const PaddingLessOutlinedInput = withStyles({
  root: {
    padding: 0
  },
  input: {
    borderRadius: '32px !important',
    padding: '20px 28px'
  }
})(OutlinedInput);

export default PaddingLessOutlinedInput;
