import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '@swagup-com/components';
import SwipeableViews from 'react-swipeable-views';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/membership';
import { CancelMembershipNewModal, MembershipItem } from './MembershipCommon';

const useStyles = makeStyles(styles);

const MembershipCancellation = ({ currentMembership, basicMembership, onAccept, company }) => {
  const [cancelMembershipModalOpen, setCancelMembershipModalOpen] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [reason, setReason] = React.useState();

  useEffect(() => {
    if (isEmpty(currentMembership) || currentMembership.isFreeTier)
      setTimeout(() => {
        setCurrentStep(3);
      }, 100);
  }, [currentMembership]);
  const showIntetcomChat = () => {
    // eslint-disable-next-line no-undef
    if (Intercom) Intercom('showSpace', 'messages');
  };
  const history = useHistory();
  const { leftBarNavigation } = useFlags();
  const classes = useStyles();
  return (
    <Grid container spacing={3} alignItems="stretch" className={classes.membershipManagementPanel}>
      <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
        <Box className={classes.membershipCancellationPanelBox}>
          <Box>
            <Link to="/membership/manage-membership">
              <Grid container alignItems="center">
                <Grid item>
                  <Box style={{ maxHeight: 24, width: 32 }}>
                    <ArrowBack className={classes.backArrow} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography className={classes.backText}>Back</Typography>
                </Grid>
              </Grid>
            </Link>
          </Box>
          <Box className={classes.membershipBoxHeader}>
            <Typography className={classes.cancelMembershipTitle}>
              Premium benefits you will loose when your subscription ends
            </Typography>
          </Box>
          <Box className={classes.cancellationItemsPanel}>
            <Grid container justifyContent="center" alignItems="center" spacing={10}>
              <Grid item>
                <MembershipItem
                  item={currentMembership}
                  customButton={
                    <Button
                      variant="primary"
                      size="small"
                      className={classes.manageMembership}
                      onClick={() => {
                        history.push('/membership/manage-membership');
                      }}
                    >
                      Keep Membership
                    </Button>
                  }
                  isCurrent
                  isCancel
                  classes={classes}
                />
              </Grid>
              <Grid item>
                <MembershipItem
                  item={basicMembership}
                  customButton={
                    <Button
                      variant="secondary"
                      size="small"
                      className={classes.manageMembership}
                      onClick={() => {
                        setCancelMembershipModalOpen(true);
                      }}
                    >
                      Continue to cancel
                    </Button>
                  }
                  isCancel
                  classes={classes}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.membershipCancellationPanelBox}>
          <Box className={classes.membershipBoxFinalHeader}>
            <Typography className={classes.cancelMembershipTitle}>
              We value your feedback, why are you cancelling?
            </Typography>
          </Box>
          <Box className={classes.membershipReasonSection}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={({ currentTarget: { value } }) => setReason(value)}
            >
              <FormControlLabel
                value="I want to change subscriptions"
                className={classes.cancelMembershipReasons}
                control={<Radio color="primary" />}
                label="I want to change subscriptions"
              />
              <FormControlLabel
                value="It’s too expensive"
                className={classes.cancelMembershipReasons}
                control={<Radio color="primary" />}
                label="It’s too expensive"
              />
              <FormControlLabel
                value="I don’t understand the membership benefits"
                className={classes.cancelMembershipReasons}
                control={<Radio color="primary" />}
                label="I don’t understand the membership benefits"
              />
              <FormControlLabel
                value="Other"
                className={classes.cancelMembershipReasons}
                control={<Radio color="primary" />}
                label="Other"
              />
            </RadioGroup>
          </Box>
          <Typography className={classes.cancelMembershipDisclaimer}>
            * If you cancel your membership, you will still have to pay its fee until the end of your current billing
            cycle on: {dayjs(company.membership_expiration_date).format('MM/DD/YYYY')}
          </Typography>
          <Box className={classes.cancelMembershipCTASection}>
            <Grid container spacing={4}>
              <Grid item>
                <Button
                  variant="primary"
                  size="small"
                  className={classes.manageMembership}
                  onClick={() => {
                    history.push('/membership/manage-membership');
                    setCurrentStep(1);
                  }}
                >
                  Keep Membership
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="secondary"
                  size="small"
                  className={classes.manageMembership}
                  onClick={() => onAccept(reason)}
                  disabled={!reason}
                >
                  Continue to cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ paddingTop: 16 }}>
            <Typography className={classes.cancelMembershipTitle}>Additional options to consider</Typography>
            <Box className={classes.cancelMembershipFooterSection}>
              <Grid container spacing={8}>
                <Grid item xs={6}>
                  <Typography className={classes.cancelMembershipReasons}>
                    Have your needs changed? See all the plans we offer, and find the best one for you.
                  </Typography>
                  <Box className={classes.cancelMembershipGeneralCTA}>
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => history.push('/membership/manage-membership-switch')}
                      className={classes.cancelMembershipFinalOptions}
                    >
                      Switch plan
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.cancelMembershipReasons}>
                    Request assistance form our customer support team, and they’ll follow up directly.
                  </Typography>
                  <Box className={classes.cancelMembershipGeneralCTA}>
                    <Button
                      variant="text"
                      size="small"
                      className={classes.cancelMembershipFinalOptions}
                      onClick={showIntetcomChat}
                    >
                      Customer support
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box className={classes.membershipCancellationPanelBox}>
          <Box className={classes.membershipBoxFinalHeader}>
            <Typography className={classes.cancelMembershipTitle}>
              We are sad to see you go. Your Membership subscription has been cancelled.
            </Typography>
          </Box>
          <Box className={classes.cancelMembershipTextContainer}>
            <Typography className={classes.cancelMembershipText}>
              Your Boosted Up Swag membership has been cancelled and you will no longer be billed.
            </Typography>
          </Box>
          <Box className={classes.cancelMembershipTextContainer}>
            <Typography className={classes.cancelMembershipText}>
              You will continue to have access to your Membership features until the end of your current billing cycle
              on: {dayjs(company.membership_expiration_date).format('MM/DD/YYYY')}
            </Typography>
          </Box>
          <Box className={classes.membershipBoxFinalHeader}>
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                setCurrentStep(1);
                history.push(leftBarNavigation ? '/settings/your-membership' : '/membership/your-membership');
              }}
              className={classes.manageMembership}
            >
              Done
            </Button>
          </Box>
        </Box>
      </SwipeableViews>
      <CancelMembershipNewModal
        open={cancelMembershipModalOpen}
        onClose={() => setCancelMembershipModalOpen(false)}
        onCancel={() => {
          setCancelMembershipModalOpen(false);
          setCurrentStep(2);
        }}
        onAccept={() => {
          history.push('/membership/manage-membership');
          setCancelMembershipModalOpen(false);
        }}
        membership={currentMembership}
      />
    </Grid>
  );
};

export default MembershipCancellation;
