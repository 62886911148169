import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import styles from './styles/integrationsBanner';
import { imageSrcSet } from '../../helpers/utils';
import { useMembership } from '../../contexts/membershipContext';
import gtm from '../../utils/gtm';

const useStyles = makeStyles(styles);

const IntegrationsBanner = () => {
  const [open, setOpen] = useState(true);
  const { currentMembership } = useMembership();
  const classes = useStyles();
  if (!open) return null;

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item>
        <Box className={classes.birdImageWrapper}>
          <img
            alt="SwagUp Integrations"
            src="/images/integrations/constellation.png"
            className={classes.birdImg}
            storefront-promostorefront-promo
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Typography variant="h5SemiBoldInter" className={classes.earlyBirdText} style={{ textAlign: 'center' }}>
          Cut out those manual tasks! <br />
          <span>Automate swag with HRIS integrations</span>
        </Typography>
      </Grid>
      <Grid item>
        {/* {currentMembership.isFreeTier ? (
          <Button
            variant="primary"
            className={classes.noMembershipCTA}
            component={Link}
            to={{ pathname: '/membership/manage-membership', state: { fromProducts: false } }}
            onClick={() => gtm.onClick('Early Bird - Upgrade Now')}
          >
            Upgrade Now
          </Button>
        ) : ( */}
        <Button
          className={classes.membershipCTA}
          component={Link}
          to={{ pathname: '/integrations', state: { fromProducts: false } }}
          onClick={() => gtm.onClick('Integrations Promo - Try it now')}
        >
          Try it now
        </Button>
        {/* )} */}
      </Grid>
      <Grid item>
        <Box style={{ height: 100 }}>
          <IconButton className={classes.closeIconButton} onClick={() => setOpen(false)}>
            <Close className={classes.closeIcon} />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default IntegrationsBanner;
