const redeemServicesPaths = {
  redeemPages: '/redeem-pages',
  redeemPagesByAccount: account => `/redeem-pages/by-account/${account}`,
  redeemPage: slug => `/redeem-pages/${slug}`,
  redemptions: id => `/redemptions/${id}`,
  createRedemptions: '/redemptions',
  retryRedemptions: id => `/redemptions/retry/${id}`,
  cancelRedemptions: id => `/redemptions/cancel/${id}`,
  deleteRedemptions: id => `/redemptions/delete/${id}`,
  verifyAddress: '/verifications/verify-address',
  verifyName: '/verifications/verify-project-name',
  verifyEmail: '/verifications/verify-email-dns',
  export: id => `/redemptions/export/${id}`,
  summary: id => `/metrics/redeem-page-summary/${id}`,
  createUniqueUrlBulk: id => `/redeem-pages/create-unique-url/${id}`,
  sendEmailBulk: id => `/redeem-pages/send-email/${id}`
};

export default redeemServicesPaths;
