import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import { Tab, Tabs } from '@swagup-com/components';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/membership';
import { BenefitItem, RewardItem, YourMembershipBox, YourRewards } from './MembershipCommon';
import { useQueryParams } from '../../hooks';
import { useMembership } from '../../contexts/membershipContext';
import { Pagination } from '../shared';

const useStyles = makeStyles(styles);

const errorMessages = {
  AlreadyCanceled: `Your Membership was already canceled`
};

const YourMembership = () => {
  const [currentTab, setCurrentTab] = useState('1');
  const [infoMessage, setInfoMessage] = React.useState();

  const { currentMembership, company, rewards, totalRewards } = useMembership();
  const { leftBarNavigation } = useFlags();
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();

  const rpoffset = query.get('rpoffset');

  useEffect(() => {
    const newMembership = query.get('new-membership');
    if (newMembership === currentMembership?.name) {
      setInfoMessage(`${newMembership} is your new Membership`);
    }

    const tab = query.get('tab');
    if (tab === 'rewards') {
      setCurrentTab('2');
    } else setCurrentTab('1');
    const error = query.get('error');
    if (error) setInfoMessage(errorMessages[error]);
  }, [query, currentMembership]);

  const handleChange = (event, newValue) => {
    if (newValue === '2') {
      query.set('tab', 'rewards');
      if (!query.get('rplimit') && !query.get('rpoffset')) {
        query.set('rpoffset', '0');
      }
    } else {
      query.delete('tab');
      query.delete('rplimit');
      query.delete('rpoffset');
    }
    history.replace({ ...location, search: query.toString() });
  };

  const handleToastClose = () => {
    setInfoMessage();
    history.replace({ ...location, search: undefined });
  };

  const classes = useStyles();
  return (
    <Grid
      container
      item
      xs={12}
      className={classes.mainContainer}
      style={{ height: leftBarNavigation ? null : '100%' }}
    >
      {infoMessage && (
        <Grid
          container
          justifyContent="center"
          style={!leftBarNavigation ? { marginTop: 14, position: 'relative' } : { position: 'relative' }}
        >
          <Alert
            onClose={handleToastClose}
            delayTime={5000}
            className={classes.floatingInfoMessage}
            fontStyles={{ fontSize: 12, padding: 0 }}
          >
            {infoMessage}
          </Alert>
        </Grid>
      )}
      <Grid container item xs={12} spacing={4}>
        <Grid item xs={12} md={6}>
          <YourMembershipBox membership={currentMembership} />
        </Grid>
        <Grid item xs={12} md={6}>
          <YourRewards account={company} />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3} style={{ height: '100%' }}>
        <TabContext value={currentTab}>
          <Grid container style={{ borderBottom: '1px solid #E5E7E8', paddingLeft: 25 }}>
            <Tabs value={currentTab} onChange={handleChange} className={classes.tabs}>
              <Tab label="Benefits" value="1" />
              <Tab label="Rewards" value="2" />
            </Tabs>
          </Grid>
          <TabPanel value="1" style={{ width: '100%', height: '100%' }}>
            <Grid container>
              <Grid item xs={6} style={{ paddingLeft: 24, paddingTop: 20 }}>
                {currentMembership.benefits?.map(b => (
                  <BenefitItem key={b.id} item={b} />
                ))}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2" style={{ width: '100%', height: '100%' }}>
            <Grid container style={{ paddingBottom: 12 }}>
              {rewards.map(r => (
                <RewardItem key={r.id} item={r} classes={classes} />
              ))}
              <Grid container justifyContent="center" style={{ paddingTop: 16 }}>
                <Pagination
                  count={totalRewards}
                  endText="Reward Points"
                  perPage={12}
                  onNext={() => {
                    let offset = parseInt(rpoffset || 0, 10);
                    offset += 1;
                    if (offset < totalRewards / 12) {
                      query.set('rpoffset', `${offset}`);
                      history.replace({ ...location, search: query.toString() });
                    }
                  }}
                  pageIndex={parseInt(rpoffset || 0, 10)}
                  onPrevious={() => {
                    let offset = parseInt(rpoffset || 0, 10);
                    offset -= 1;
                    if (offset > -1) {
                      query.set('rpoffset', `${offset}`);
                      history.replace({ ...location, search: query.toString() });
                    }
                  }}
                  sizeOptions={[12]}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default YourMembership;
