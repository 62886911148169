import * as React from 'react';
import debounce from 'lodash/debounce';
import { useOrder } from '../OrderContext';

const ProductContext = React.createContext();
ProductContext.displayName = 'ProductContext';

const ProductProvider = ({ product, children }) => {
  const { products } = useOrder();
  const [updatedProduct, updateProduct] = React.useState(product);

  const restProducts = React.useMemo(() => products.filter(p => p.id !== product.id), [products, product]);
  const debouncedUpdateProduct = React.useCallback(debounce(updateProduct, 350), []);

  React.useEffect(() => {
    debouncedUpdateProduct(product);
  }, [debouncedUpdateProduct, product]);

  return (
    <ProductContext.Provider value={{ updatedProduct, updateProduct: debouncedUpdateProduct, product, restProducts }}>
      {children}
    </ProductContext.Provider>
  );
};

const useProduct = () => {
  const state = React.useContext(ProductContext);
  if (state === undefined) {
    throw new Error(`useProduct must be used within a ProductProvider`);
  }
  return state;
};

export { ProductProvider, useProduct };
