import React, { useState } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@swagup-com/components';

import apiAddress from '../../../../apis/swagup/addresses';
import EmployeeForm from '../../../directory/employeesList/EmployeeForm';
import { ErrorBanner } from './common';
import AddressConfirmation from '../../../shared/AddressConfirmation';
import Loader from '../../../global/Loader';
import { getSchema, handleContactError } from '../../../global/Forms/commonValidations';
import styles from './styles/invalidAddressForm';

const fieldsToValidate = [
  'shipping_country',
  'shipping_address1',
  'shipping_address2',
  'shipping_city',
  'phone_number',
  'shipping_state',
  'shipping_zip'
];

const resolver = yupResolver(getSchema(fieldsToValidate));

const useStyles = makeStyles(styles);

const InvalidAddressForm = ({ employeeAddress, invalidAddressErrorMessage, onSaveAddress, onClose }) => {
  const [addressVerification, setAddressVerification] = useState({ address: {} });
  const [isAddressConfirmationOpen, setIsAddressConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm({
    resolver,
    mode: 'all',
    defaultValues: employeeAddress
  });
  const { formState, handleSubmit, setError } = formMethods;

  const classes = useStyles();

  const onSave = async data => {
    setIsLoading(true);

    const employeeData = {
      ...data,
      force_address: true
    };

    const address = {
      ...employeeData,
      street: data.shipping_address1,
      secondary: data.shipping_address2,
      city: data.shipping_city,
      state: data.shipping_state,
      zipcode: data.shipping_zip,
      country: data.shipping_country
    };

    const { result, data: responseData } = await apiAddress.validate(data);

    setIsLoading(false);

    if (result === 'ok') {
      await onSaveAddress(employeeData);
    } else {
      setIsLoading(false);
      handleContactError({
        data: responseData,
        setError,
        errorBehavior: () => {
          setAddressVerification({
            address,
            message: responseData.message
          });
          setIsAddressConfirmationOpen(true);
        },
        state: data.shipping_state
      });
    }
  };

  return (
    <>
      <ErrorBanner message={invalidAddressErrorMessage} />
      <Box p="30px 20px 0 20px">
        <form onSubmit={handleSubmit(onSave)}>
          {isLoading && <Loader />}
          <Grid container className={classes.formContainer}>
            <Grid container item className={classes.formFieldsContainer}>
              <FormProvider {...formMethods}>
                <EmployeeForm justAddress />
              </FormProvider>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.formActionsContainer}>
            <Grid container item justifyContent="flex-end" xs={4} style={{ paddingRight: 20 }}>
              <Button onClick={onClose} variant="text">
                <Typography variant="button">Discard Changes</Typography>
              </Button>
            </Grid>
            <Grid container justifyContent="flex-end" item xs={4}>
              <Grid item xs={11}>
                <Button variant="primary" type="submit" disabled={!formState.isValid} fullWidth>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <AddressConfirmation
            open={isAddressConfirmationOpen}
            onClose={() => setIsAddressConfirmationOpen(false)}
            address={addressVerification.address}
            message={addressVerification.message}
            callbackAction={onSaveAddress}
          />
        </form>
      </Box>
    </>
  );
};

export default InvalidAddressForm;
