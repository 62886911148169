import DashBoard from '../apis/DashBoard';
import { okAndLog, errorAndLog } from '../helpers/utils';
import { mergeCampaignIdsWithData } from '../helpers/utilsCookies';
import apiPaths from '../helpers/apiPaths';
import log from '../logger';

export const subscribe = async data => {
  log.debug('subscribe Action - Entering');
  try {
    const dataWithCookie = mergeCampaignIdsWithData(data);
    const apiCall = await DashBoard.post(apiPaths.subscribers, dataWithCookie);
    if (apiCall.status === 200) {
      // mailchimp returns status 200 if ok
      return okAndLog('subscribe', apiCall.status, apiCall.data);
    }
    return errorAndLog('subscribe', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('subscribe', e.status, e.data);
  }
};
