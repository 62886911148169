import axios from '../DashBoard';
import apiPaths from '../../helpers/apiPaths';
import { status201, status200 } from './status.utils';
import { handleResponse, okAndLog, errorAndLog, handleError } from './response.utils';

const handleOk = (msg, { status, data }) =>
  data.success ? okAndLog(msg, status, data) : errorAndLog('response error', status, data);

const handlePaymentResponse = (msg, apiCall) =>
  apiCall.then(resp => handleOk(msg, resp)).catch(e => handleError(msg, e));

export default {
  fetch: id => handleResponse(`get invoice ${id}`, axios.get(apiPaths.invoice(id), status200)),

  pay: id => handlePaymentResponse(`pay invoice ${id}`, axios.post(apiPaths.invoicePayments(id), null, status201))
};
