import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import { useLocation } from 'react-router-dom';
import round from 'lodash/round';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { moneyStr, getProductPrice, sumByQuantity, buildUrlWithParam } from '../../../../helpers/utils';
import { OrderAgainButton, isOrderProcessing } from './CommonOrderElements';
import { paymentMethods, invoiceStatus } from '../../../../apis/constants';
import useDiscountsAndRewards from '../../../../hooks/useDiscountsAndRewards';
import StrikeOutText from '../../../global/StrikeOutCost';
import { useInvoice } from '../../../../hooks';
import BenefitTab from '../../../global/BenefitTab';

const getPrices = products => {
  let price = 0;
  let priceWithoutDiscount = 0;
  let rushFee = 0;
  let rushFeeWithoutDiscount = 0;
  let storage = 0;
  let storageWithoutDiscount = 0;
  let qty = 0;
  products.forEach(p => {
    const sizesQty = sumByQuantity(p.sizes);
    price = round(price + round(getProductPrice(p) * sizesQty, 2), 2);
    priceWithoutDiscount = round(
      priceWithoutDiscount + round(getProductPrice(p, 'price_without_discount') * sizesQty, 2),
      2
    );
    rushFee = round(rushFee + round(getProductPrice(p, 'rush_fee') * sizesQty, 2), 2);
    rushFeeWithoutDiscount = round(
      rushFeeWithoutDiscount + round(getProductPrice(p, 'rush_fee_without_discount') * sizesQty, 2),
      2
    );
    storage = round(storage + round(getProductPrice(p, 'fulfillment_price') * sizesQty, 2), 2);
    storageWithoutDiscount = round(
      storageWithoutDiscount + round(getProductPrice(p, 'fulfillment_price_without_discount') * sizesQty, 2),
      2
    );
    qty += sizesQty;
  });
  return [price, priceWithoutDiscount, rushFee, rushFeeWithoutDiscount, storage, storageWithoutDiscount, qty];
};

const commonSemiBoldText = { fontFamily: 'Gilroy-SemiBold', fontSize: 20, color: '#0b1829' };
const commonMediumText = { fontFamily: 'Gilroy-Medium', fontSize: 14, paddingTop: 16 };

const useStyles = makeStyles({
  summaryHeader: { ...commonSemiBoldText, marginBottom: 24 },
  summaryLabel: { ...commonMediumText, color: '#787b80' },
  summaryValue: { ...commonMediumText, color: '#0b1829' },
  totalValue: { ...commonSemiBoldText, paddingTop: 20 },
  totalLabel: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    color: '#0b1829',
    paddingTop: 23
  },
  paymentMethod: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#787b80',
    marginTop: 25
  },
  creditCardIcon: {
    width: 18,
    height: 18,
    marginTop: 23,
    marginLeft: 10
  },
  exportIcon: {
    width: 16,
    height: 16,
    marginLeft: 6,
    marginBottom: -2
  },
  invLink: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#3577d4',
    '&:hover': { color: '#004ca2' }
  }
});

const MembershipPanel = ({ discount, rewards, pointsRate }) => (
  <Grid container justifyContent="center">
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <BenefitTab
          icon="/images/membership/rewardRocket.png"
          text="Membership Discounts"
          value={discount > 0 ? `-${moneyStr(discount)}` : '$0.00'}
        />
      </Grid>
      <Grid item xs={12}>
        <BenefitTab
          icon="/images/membership/rewards.png"
          text={`Reward Points ${pointsRate}X`}
          value={rewards}
          type="reward"
        />
      </Grid>
    </Grid>
  </Grid>
);

const SummaryItem = ({ label, value, isTotal, previous, classes, discountAmount, rewardPoints, pointsRate }) => (
  <Grid container style={isTotal ? { borderTop: '1px solid #ced1d6', marginTop: 20 } : undefined}>
    {isTotal && pointsRate && (
      <Box style={{ paddingTop: 24, width: '100%' }}>
        <MembershipPanel discount={discountAmount} rewards={rewardPoints} pointsRate={pointsRate} />
      </Box>
    )}
    <Grid item xs align="left">
      <p className={clsx(classes.summaryLabel, { [classes.totalLabel]: isTotal })}>{label}</p>
    </Grid>
    <Grid item align="right">
      <p className={clsx(classes.summaryValue, { [classes.totalValue]: isTotal })}>
        <StrikeOutText value={previous} fontSize={isTotal ? 16 : 12} color="#898C91" marginRight={8} />
        {moneyStr(value)}
      </p>
    </Grid>
  </Grid>
);

const InvoicePDF = ({ url, classes }) => (
  <a id="download-invoice" target="_blank" rel="noopener noreferrer" href={url} download className={classes.invLink}>
    Download invoice
    <SaveAltOutlinedIcon className={classes.exportIcon} />
  </a>
);

const PaymentMethod = ({ order, classes }) => {
  if (order.status === invoiceStatus.processingPayment || !order.payment_profile.payment_method) return null;

  const isCreditCard = order.payment_profile.payment_method === paymentMethods.creditCard;
  const creditCardNumber = (isCreditCard && order.payment_profile.payment_method_desc?.replace(/X/g, '')) || '';

  return (
    <>
      <p className={classes.paymentMethod}>
        Payment method:
        <span
          style={{ color: '#0b1829', marginLeft: 5 }}
        >{`${order.payment_profile.payment_method} ${creditCardNumber}`}</span>
      </p>
      {isCreditCard && (
        <img src="/images/orders/credit-card.svg" alt="Credit Card" className={classes.creditCardIcon} />
      )}
    </>
  );
};

const OrderSummary = ({ order, isLoading }) => {
  const location = useLocation();
  const classes = useStyles();
  const [
    subTotal,
    subTotalWithoutDiscount,
    rushFee,
    rushFeeWithoutDiscount,
    storage,
    storageWithoutDiscount,
    qty
  ] = getPrices(order.products);
  const products = order.products.map(p => p.product);
  const urlToOpen = buildUrlWithParam(location, 'open', order.invoice_name);

  const { data: invoice } = useInvoice(order.invoice);
  const shippingPercent = 100 - (invoice?.shipping_discount || 0);
  const shippingCreditsBeforeDiscount = round(
    isEmpty(invoice) || !shippingPercent ? order.shipping_credits : (invoice.shipping_credits * 100) / shippingPercent,
    2
  );

  const { billingGlobalUseTaxJarForTaxCollectionTemp012022: showTaxes } = useFlags();

  const orderTotal = subTotal + rushFee + parseFloat(order.shipping_credits) + parseFloat(order.total_fulfillment);
  const orderTotalBeforeDiscount =
    subTotalWithoutDiscount +
    rushFeeWithoutDiscount +
    shippingCreditsBeforeDiscount +
    parseFloat(order.total_fulfillment_without_discount);

  const totalPlusTaxBeforeDiscount = orderTotalBeforeDiscount + parseFloat(order.tax);

  const getSummaryRows = () => {
    const rows = [
      { key: 1, label: `Subtotal (${qty} items)`, value: subTotal, beforeDiscountValue: subTotalWithoutDiscount },
      { key: 2, label: 'Rush production', value: rushFee, beforeDiscountValue: rushFeeWithoutDiscount },
      {
        key: 3,
        label: 'Storage',
        value: order.total_fulfillment,
        beforeDiscountValue: order.total_fulfillment_without_discount
      },
      { key: 4, label: 'Shipping', value: order.shipping_credits, beforeDiscountValue: shippingCreditsBeforeDiscount },
      { key: 5, label: 'Estimated Tax', value: order.tax },
      // { key: 6, label: 'Discounts', value: -order.discount_amount || 0 }
      { key: 7, label: 'Total', value: order.total_plus_tax, beforeDiscountValue: totalPlusTaxBeforeDiscount }
    ];
    return showTaxes ? rows : rows.filter(r => r.label !== 'Estimated Tax');
  };

  const summaryRows = getSummaryRows();

  const discountAmount = totalPlusTaxBeforeDiscount - parseFloat(order.total_plus_tax);
  const rewardPoints = Math.ceil((isEmpty(invoice) ? 1 : invoice.reward_points_rate) * orderTotal);

  return (
    <Grid container style={{ paddingLeft: 50 }}>
      <p className={classes.summaryHeader}>Order overview</p>
      {summaryRows.map(row => (
        <SummaryItem
          key={row.key}
          label={row.label}
          value={row.value}
          previous={isEmpty(invoice) ? undefined : row.beforeDiscountValue}
          isTotal={row.label === 'Total'}
          classes={classes}
          discountAmount={discountAmount}
          rewardPoints={rewardPoints}
          pointsRate={invoice?.reward_points_rate}
        />
      ))}
      <PaymentMethod order={order} classes={classes} />
      <Grid container alignItems="center" style={{ marginTop: 32 }}>
        <Grid item container xs={6}>
          {order.invoice_pdf_url && <InvoicePDF url={order.invoice_pdf_url} classes={classes} />}
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <OrderAgainButton
            products={products}
            variant="primary"
            urlToOpen={order.status === invoiceStatus.pendingPayment ? urlToOpen : undefined}
            isOrderProcessing={isOrderProcessing(order)}
            isLoading={isLoading}
            width={148}
            height={56}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
