import React, { useState, useReducer } from 'react';
import { Grid, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import clsx from 'clsx';
import { Button } from '@swagup-com/components';
import { useCompany, useCountries, useMemoizeStateFields } from '../../hooks';
import AddressConfirmation from '../shared/AddressConfirmation';
import { normalizeUSZip, s3 } from '../../helpers/utils';
import { TextField, SelectField } from '../global/reactHookFormFields';
import { saveCompany } from '../../actions';
import verifyAddress from '../../helpers/addressValidation';
import log from '../../logger';
import styles from './styles/Company';
import { Img } from '../global/ImgUtils';
import { getSchema } from '../global/Forms/commonValidations';
import toErrorPage from '../../helpers/toErrorPage';
import apiPaths from '../../helpers/apiPaths';

const useStyles = makeStyles(styles);

const uploadImage = (img, onSuccess, toggleLoading) => {
  toggleLoading();
  s3.upload({
    Key: `${Date.now()}${img.name}`,
    Body: img,
    ContentType: img.type
  })
    .on('httpUploadProgress', evt => log.debug(`Uploaded :: ${(evt.loaded * 100) / evt.total}%`))
    .send((err, data) => {
      if (err) {
        log.error('There was an error uploading your file: ', err.message);
        // TODO: Show an error message.
      } else {
        log.debug('uploadImage data:', data);
        onSuccess(data.Location);
      }
      toggleLoading();
    });
};

const ImgInputContainer = ({ title, path, children }) => {
  const classes = useStyles({ path });

  return (
    <>
      <Typography variant="h6" className={classes.logoTitleContainer}>
        <strong>{title}</strong> File formats: .pdf, .png, .ai, .eps, .jpeg, .svg
      </Typography>
      <Grid container>
        <Grid item xs={4} md={3}>
          <div className={classes.container}>
            <Img
              src={path || '/images/account/upload.svg'}
              fallbackSrc="/images/custom/assets/no-preview.png"
              alt={title}
              className={classes.logo}
              style={{}}
            />
          </div>
        </Grid>
        <Grid container alignItems="center" item xs={8} md={9}>
          <Button size="small" component="label" className={clsx(classes.uploadButton, classes.button)}>
            UPLOAD
            {children}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const fieldsToValidate = ['shipping_address1', 'shipping_city', 'shipping_state', 'shipping_zip', 'name_company'];
const resolver = yupResolver(getSchema(fieldsToValidate));

const buildRequestData = container => ({
  name: container.name_company || container.name,
  shipping_address1: container.shipping_address1,
  shipping_address2: container.shipping_address2,
  shipping_city: container.shipping_city,
  shipping_country: container.shipping_country,
  shipping_state: container.shipping_state,
  shipping_zip: container.shipping_zip
});

const Company = () => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, toggleLoading] = useReducer(prevLoading => !prevLoading, false);
  const [addressVerification, setAddressVerification] = useState({ address: {} });

  const { data: company } = useCompany();
  const { data: countries } = useCountries();
  const getProvinces = country => countries?.find(c => c.iso2 === country)?.provinces ?? [];

  const { control, register, setValue, watch, handleSubmit, reset, formState, trigger, setError, setFocus } = useForm({
    defaultValues: {
      ...buildRequestData(company),
      name_company: company.name,
      logo: company.logo,
      secondary_logo: company.secondary_logo
    },
    mode: 'onChange',
    resolver
  });

  const { isDirty, isValid, errors } = formState;

  const country = watch('shipping_country');
  const [onCountryChange, onStateChange, states] = useMemoizeStateFields({
    country,
    setCountry: () => trigger(['shipping_state', 'shipping_zip']),
    change: setValue,
    initialState: company.shipping_state
  });

  const handleClose = event => {
    setIsModalOpen(false);
    if (event !== 'confirmed') toggleLoading();
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const submitCompany = async data => {
    const response = await dispatch(
      saveCompany({
        ...buildRequestData(data),
        id: company.id,
        logo_path: data.logo || null,
        secondary_logo_path: data.secondary_logo || null,
        force_address: true
      })
    );

    if (response.result === 'ok') {
      setFocus('name_company');
      queryClient.invalidateQueries(apiPaths.accounts);

      reset(data, { keepDirty: false });
    } else if (response.status === 400) {
      setError(
        'name_company',
        {
          type: 'manual',
          message: response.data?.name.find(r => r.includes('account with this name already exists.'))
            ? 'An account with this name already exists.'
            : response.data?.name.join()
        },
        { shouldFocus: true }
      );
    } else {
      toErrorPage(response.result, history);
    }
    toggleLoading();
  };

  const onSubmit = async data => {
    const address = {
      ...data,
      street: data.shipping_address1,
      secondary: data.shipping_address2,
      city: data.shipping_city,
      state: data.shipping_state,
      zipcode: data.shipping_zip,
      country: data.shipping_country
    };

    toggleLoading();
    const { result, message } = await verifyAddress(address);
    if (result === 'ok') {
      await submitCompany(address);
    } else {
      setIsModalOpen(true);
      setAddressVerification({ address, message });
    }
  };

  const handleLogoChange = fieldName => e => {
    e.preventDefault();
    const img = e.target.files[0];
    if (img) uploadImage(img, newPath => setValue(fieldName, newPath, { shouldDirty: true }), toggleLoading);
  };

  // This is due to React's SyntheticEvent
  // More info here: https://duncanleung.com/fixing-react-warning-synthetic-events-in-setstate/
  const handleReset = e => {
    e.persist();
    setFocus('name_company');
    reset();
  };

  const handleChangeZip = e => {
    if (country === 'US') {
      const usZip = normalizeUSZip(e?.target?.value ?? '');
      setValue('shipping_zip', usZip);
    }
  };

  const logoPath = watch('logo');
  const secondaryLogoPath = watch('secondary_logo');
  const provinces = getProvinces(country);

  return (
    <Grid component="form" onSubmit={handleSubmit(onSubmit)} container style={{ marginTop: 40, marginBottom: 40 }}>
      <Grid container item sm={6} xs={12}>
        <Grid item xs={12}>
          <ImgInputContainer path={logoPath} title="LOGO">
            <input
              type="file"
              onChange={handleLogoChange('logo')}
              accept="image/*, application/pdf, application/illustrator, application/postscript"
              style={{ display: 'none' }}
            />
            <input type="hidden" {...register('logo')} />
          </ImgInputContainer>
        </Grid>
      </Grid>
      <Grid item sm={6} xs={12}>
        <ImgInputContainer path={secondaryLogoPath} title="SECOND LOGO">
          <input
            type="file"
            onChange={handleLogoChange('secondary_logo')}
            accept="image/*, application/pdf, application/illustrator, application/postscript"
            style={{ display: 'none' }}
          />
          <input type="hidden" {...register('secondary_logo')} />
        </ImgInputContainer>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.subTitle}>
          Company Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoFocus
          autoComplete="name_company"
          error={errors.name_company?.message}
          placeholder="Company"
          {...register('name_company')}
          defaultValue={company.name}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          name="shipping_country"
          error={errors.shipping_country?.message}
          totalItems={countries.length}
          onSelectChange={onCountryChange}
          control={control}
          defaultValue={company.shipping_country}
          fullWidth
        >
          {countries?.map(c => (
            <MenuItem key={c.iso2} value={c.iso2}>
              {c.name}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="shipping_address1"
          error={errors.shipping_address1?.message}
          defaultValue={company.shipping_address1}
          placeholder="Company address"
          {...register('shipping_address1')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="shipping_address2"
          error={errors.shipping_address2?.message}
          defaultValue={company.shipping_address2}
          placeholder="Floor, suite, unit (optional)"
          {...register('shipping_address2')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="shipping_city"
          error={errors.shipping_city?.message}
          defaultValue={company.shipping_city}
          placeholder="City"
          {...register('shipping_city')}
          fullWidth
        />
      </Grid>
      <Grid style={{ paddingRight: 12 }} item xs={6}>
        {provinces.length === 0 ? (
          <TextField
            autoComplete="shipping_state"
            error={errors.shipping_state?.message}
            defaultValue={states.current[country] ?? ''}
            placeholder="State / Province / Region"
            {...register('shipping_state')}
            onChange={onStateChange}
            fullWidth
          />
        ) : (
          <SelectField
            name="shipping_state"
            error={errors.shipping_state?.message}
            defaultValue={states.current[country] ?? ''}
            onSelectChange={onStateChange}
            totalItems={provinces.length}
            label="State"
            placeholder="State / Province / Region"
            control={control}
            fullWidth
          >
            <MenuItem value="" disabled>
              State
            </MenuItem>
            {provinces.map(p => (
              <MenuItem key={p.code} value={p.code}>
                {p.name}
              </MenuItem>
            ))}
          </SelectField>
        )}
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: 12 }}>
        <TextField
          autoComplete="shipping_zip"
          error={errors.shipping_zip?.message}
          defaultValue={company.shipping_zip}
          placeholder="Zip"
          {...register('shipping_zip')}
          onInput={handleChangeZip}
          fullWidth
        />
      </Grid>
      <Grid item style={{ paddingTop: 20, paddingRight: 60 }} container justifyContent="flex-end" xs={6}>
        <Button className={classes.button} variant="text" onClick={handleReset} disabled={loading || !isDirty}>
          <Typography variant="button">Cancel</Typography>
        </Button>
      </Grid>
      <Grid style={{ paddingTop: 20 }} container justifyContent="flex-end" item xs={6}>
        <Grid item xs={11}>
          <Button
            fullWidth
            className={classes.button}
            variant="primary"
            type="submit"
            disabled={loading || !isValid || !isDirty}
            loading={loading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <AddressConfirmation
        open={isModalOpen}
        onClose={handleClose}
        address={addressVerification.address}
        message={addressVerification.message}
        callbackAction={submitCompany}
      />
    </Grid>
  );
};

export default Company;
