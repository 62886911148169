const styles = theme => ({
  tableCell: {
    paddingTop: 0,
    paddingBottom: 32,
    borderBottom: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  },
  imgContainer: {
    width: 78,
    height: 78,
    backgroundColor: '#ffffff',
    '& img': {
      objectFit: 'contain',
      width: 78
    }
  },
  packTitle: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    lineHeight: 1.43,
    padding: 4,
    paddingTop: 0
  },
  packTotal: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    lineHeight: 1.43,
    padding: 4
  },
  itemDesc: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#434c5f',
    padding: 4
  },
  subTitle: {
    color: '#434c5f',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Gilroy',
    marginBottom: 16
  },
  infoContainer: {
    [theme.breakpoints.down('xs')]: { padding: '0 24px' }
  },
  productsContainer: {
    [theme.breakpoints.down('xs')]: { paddingTop: 32 }
  }
});

export default styles;
