import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Img } from '../../global/ImgUtils';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  header: {
    padding: '32px 0 24px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  headerTitle: {
    marginLeft: 36,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginLeft: 0
    }
  },
  title: { width: 585 },
  description: {
    marginTop: 11,
    lineHeight: '26px',
    letterSpacing: 0,
    width: 500
  }
}));

const Header = () => {
  const classes = useStyles();
  const { isFromSendSwag } = useLocation().state;

  const [title, imgSrc] = isFromSendSwag
    ? ['Your shipments have been placed.', '/images/shipSwag/truck-150x150.png']
    : ['We’re on it!', '/images/shipSwag/blue-box-150x150.png'];

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.headerContainer}>
      <div className={classes.header}>
        <Img src={imgSrc} alt="shipment-box" width={150} height={150} />
        <Grid container direction="column" justifyContent="center" className={classes.headerTitle}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Your shipments are being processed. We&apos;ll send you an email confirmation and a follow up if any issues
            arise.
          </Typography>
        </Grid>
      </div>
      <Button
        variant="primary"
        component={Link}
        to="/shipments"
        className="leftColumn"
        style={{ width: 210, height: 56 }}
      >
        Back to Shipments
      </Button>
    </Grid>
  );
};

export default Header;
