/* eslint-disable no-nested-ternary */
import { greyRoundBorder, scrollBarAutohide } from '../../../../shared/styles/commonStyles';
import { changeColorLightness } from '../../../../shared/styles/utils';

export default {
  drawer: {
    padding: '24px 24px 36px 50px',
    width: '77%'
  },
  button: {
    height: 24,
    padding: 0,
    width: 24
  },
  submitButton: {
    height: 56,
    marginTop: 48,
    width: '100%',
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#fafafa',
      color: '#787b80'
    }
  },
  icon: {
    color: '#787b80',
    width: 18,
    height: 18
  },
  title: {
    color: '#0b1829',
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '34px',
    margin: '10px 0 0 0'
  },
  unhighlightedText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.57,
    color: '#787b80'
  },
  hightlightedText: {
    color: '#0b1829',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57
  },
  removeButton: {
    height: 14,
    padding: 8,
    marginRight: -8,
    letterSpacing: 0,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 0.71,
    color: '#f44336',
    '&:hover': { color: changeColorLightness('#f44336') }
  },
  price: {
    color: '#0b1829',
    fontFamily: 'Gilroy-Bold',
    fontSize: 20,
    height: 32,
    letterSpacing: 0,
    lineHeight: '32px',
    margin: 0
  },
  priceTitle: {
    color: '#787b80',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '22px',
    margin: 0
  },
  quantity: {
    color: '#0b1829',
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    height: 26,
    letterSpacing: 0,
    lineHeight: '26px',
    margin: 0
  },
  productionButton: ({ active, disabled }) => ({
    height: 80,
    padding: '18px 20px 16px',
    borderRadius: 10,
    border: disabled ? 'solid 1px #E5E7E8' : active ? 'solid 1px #1C4783' : 'solid 1px #D6D8DB',
    backgroundColor: disabled ? '#F5F5F6' : active ? '#EBF1FB' : '#F5F5F6',
    '& span': {
      height: '100%'
    },
    '&:hover': !disabled
      ? {
          border: 'solid 1px #1C4783',
          backgroundColor: '#EBF1FB',
          cursor: 'pointer'
        }
      : undefined
  }),
  productionSubtitle: ({ disabled }) => ({
    color: disabled ? '#B7BBBF' : '#4A4F54',
    fontSize: 14,
    textAlign: 'left',
    margin: 0,
    cursor: 'inherit'
  }),
  productionTitle: ({ disabled, active }) => ({
    cursor: 'inherit',
    marginBottom: 0,
    '& p': {
      color: active ? '#3577D4' : disabled ? '#989EA4' : '#131415',
      marginBottom: 0,
      fontSize: 16
    }
  }),
  productListContainer: {
    ...greyRoundBorder,
    height: ({ isPack }) => (isPack ? '80%' : 'unset'),
    marginTop: 20
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#f0f2f5',
    margin: '16px 0'
  },
  headerPrice: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.57,
    color: '#0b1829'
  },
  headerRow: {
    padding: '0 24px'
  },
  headerText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 0.8,
    color: '#787b80'
  },
  productList: {
    ...scrollBarAutohide,
    padding: '0 24px'
  },
  divider: {
    height: 1,
    margin: '0 24px',
    backgroundColor: '#f0f2f5'
  },
  productDescription: {
    maxWidth: 'calc(100% - 70px)',
    marginLeft: 16
  },
  pricePerPack: {
    minWidth: 60,
    marginLeft: 60,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 600,
    color: '#0b1829',
    textAlign: 'right'
  },
  pricingMessage: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    letterSpacing: 'normal'
  },
  quantityColumn: {
    textAlign: 'center',
    flexGrow: 0,
    maxWidth: '12.499998%',
    flexBasis: '12.499998%'
  },
  priceColumn: {
    textAlign: 'center',
    flexGrow: 0,
    maxWidth: '8.333333%',
    flexBasis: '8.333333%'
  },
  totalColumn: {
    textAlign: 'end',
    maxWidth: '12.499998%',
    flexBasis: '12.499998%'
  },
  skeleton: {
    width: 42,
    height: 16,
    display: 'inline-flex'
  },
  discountText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  discountBadge: { background: '#90BEFF', borderRadius: 16, padding: '4px 10px 4px 6px' },
  discountImageWrapper: { width: 16, height: 16, position: 'relative' },
  discountImage: { width: '100%', height: '100%', objectFit: 'contain' }
};
