import { useCallback, useEffect, useState } from 'react';
import { isEmpty, round } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMembership } from '../contexts/membershipContext';

const discountTypes = ['shipping_discount', 'product_discount', 'storage_discount', 'rush_production_discount'];

const useDiscountsAndRewards = (
  total,
  type = 'shipping',
  multipleTotals,
  fromPayment,
  addedFunds = 0,
  swagCardPayment = 0,
  exceeds = 0
) => {
  const [totalBeforeDiscount, setTotalBeforeDiscount] = useState(0);
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [appliedReward, setAppliedReward] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [multipleDiscounts, setMultipleDiscounts] = useState({});

  const { currentMembership, isLoading } = useMembership();

  const { swagupMembershipPlans } = useFlags();

  useEffect(() => {
    if (!swagupMembershipPlans || isEmpty(currentMembership)) return;

    let finalTotalBeforeDiscount = 0;
    const singleMembershipHumdred = currentMembership?.[`${type}_discount`] === 100 ? total : 0;
    const allHundredsDiscounts = multipleTotals
      ? round(
          discountTypes.reduce(
            (disc, tt) => disc + (currentMembership[tt] === 100 ? multipleTotals?.[tt] ?? total : 0),
            0
          ),
          2
        )
      : singleMembershipHumdred;

    if (currentMembership?.isFreeTier) {
      finalTotalBeforeDiscount = multipleTotals ? discountTypes.reduce((sum, t) => sum + multipleTotals[t], 0) : total;
      const realPoints = Math.ceil(
        (finalTotalBeforeDiscount + addedFunds - swagCardPayment - allHundredsDiscounts) *
          parseFloat(currentMembership.reward_points_rate)
      );
      setAppliedReward(realPoints);
      return;
    }

    const discountSingle = () => {
      let originalTotal = total;
      const currentDiscount = currentMembership?.[`${type}_discount`];
      if (currentDiscount) {
        const effectivePercent = 100 - currentDiscount;
        if (effectivePercent) {
          const discountAmount = round((originalTotal * 100) / effectivePercent - total, 2).toFixed(2);
          originalTotal = round((originalTotal * 100) / effectivePercent, 2);
          setAppliedDiscount(discountAmount);
        } else {
          setAppliedDiscount((originalTotal || 0).toFixed(2));
        }
      }
      setDiscount(currentDiscount);
      finalTotalBeforeDiscount = originalTotal;
      return total;
    };

    const discountMultiple = () => {
      let originalTotalCost = 0;
      const totalDiscountsAux = discountTypes.reduce((sum, t) => {
        let originalTotal = multipleTotals[t];
        originalTotalCost += originalTotal || 0;
        const currentDiscount = currentMembership?.[t];
        if (originalTotal && currentDiscount) {
          const effectivePercent = 100 - currentDiscount;
          if (effectivePercent) {
            const discountAmount = round((originalTotal * 100) / effectivePercent - originalTotal, 2);
            originalTotal = round((originalTotal * 100) / effectivePercent, 2);
            setMultipleDiscounts(prev => ({ ...prev, [t]: originalTotal }));
            finalTotalBeforeDiscount += originalTotal;
            return sum + discountAmount;
          }
          setMultipleDiscounts(prev => ({ ...prev, [t]: originalTotal }));
          finalTotalBeforeDiscount += originalTotal;
          return sum + originalTotal;
        }
        finalTotalBeforeDiscount += originalTotal;
        setMultipleDiscounts(prev => ({ ...prev, [t]: 0 }));
        return sum;
      }, 0);

      setAppliedDiscount(round(totalDiscountsAux, 2).toFixed(2));
      return originalTotalCost || 0;
    };

    const finalTotalDiscount = multipleTotals ? discountMultiple() : discountSingle();
    if (currentMembership?.reward_points_rate) {
      setAppliedReward(
        Math.ceil(
          round(
            ((fromPayment ?? finalTotalDiscount) + addedFunds - swagCardPayment - allHundredsDiscounts + exceeds) *
              parseFloat(currentMembership.reward_points_rate)
          ),
          2
        )
      );
    }
    setTotalBeforeDiscount(round(finalTotalBeforeDiscount, 2));
  }, [
    addedFunds,
    currentMembership,
    exceeds,
    fromPayment,
    multipleTotals,
    swagCardPayment,
    swagupMembershipPlans,
    total,
    type
  ]);

  const getCustomTotalBeforeDiscount = useCallback(
    customTotalParam => {
      let originalTotal = customTotalParam || 0;
      const currentDiscount = currentMembership?.[`${type}_discount`];
      if (currentDiscount) {
        const effectivePercent = 100 - currentDiscount;
        originalTotal = round((originalTotal * 100) / effectivePercent, 2);
      }
      return originalTotal;
    },
    [currentMembership, type]
  );

  return {
    isLoading,
    totalBeforeDiscount,
    appliedDiscount,
    appliedReward,
    discount,
    getCustomTotalBeforeDiscount,
    currentMembership,
    multipleDiscounts
  };
};

export default useDiscountsAndRewards;
