import { SEND_DATA_ANET } from './types';
import { sendPaymentDataToAnet } from '../apis/authorizenet';

export const sendDataToAnet = data => async dispatch => {
  const result = await sendPaymentDataToAnet(data);
  dispatch({
    type: SEND_DATA_ANET,
    payload: result
  });
  return result;
};
