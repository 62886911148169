import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import max from 'lodash/max';
import min from 'lodash/min';
import isEmpty from 'lodash/isEmpty';

import accountProductsApi from '../apis/swagup/accountProducts';
import useQueryParams from './useQueryParams';
import apiPaths from '../helpers/apiPaths';

const useProductsFilters = () => {
  return useQuery([apiPaths.availableFilters], accountProductsApi.availableFilters);
};

const useFilters = initialFilters => {
  const [filters, setFilters] = React.useState([]);
  const history = useHistory();
  const query = useQueryParams();

  const getFiltersFromUrl = React.useCallback(
    () =>
      initialFilters.map(f => {
        const queryFilter = query.get(f.value);
        if (!queryFilter) {
          return f;
        }

        const querySplitted = queryFilter.split(',');
        if (f.type === 'range')
          return {
            ...f,
            range: querySplitted
          };

        return {
          ...f,
          selected: f.options?.find(o => o.id === queryFilter)?.name,
          options: f.options?.map(fo => ({
            ...fo,
            value: querySplitted.includes(`${fo.id ?? fo.name}`)
          }))
        };
      }),
    [initialFilters, query]
  );

  React.useEffect(() => {
    const filtersFromUrl = getFiltersFromUrl();

    setFilters(filtersFromUrl);
  }, [initialFilters, getFiltersFromUrl, query]);

  const handleCheck = (value, { filterLabel, id }, radioButtonBehavior) => {
    const updatedFilters = filters.map(f => {
      if (f.label !== filterLabel) {
        return f;
      }
      return {
        ...f,
        options: f.options.map(fo => {
          if (fo.id === id) {
            return { ...fo, value };
          }

          return radioButtonBehavior ? { ...fo, value: false } : fo;
        })
      };
    });

    setFilters(updatedFilters);
  };

  const handleSlide = (range, filterLabel) => {
    const updatedFilters = filters.map(f => (f.label === filterLabel ? { ...f, range } : f));
    setFilters(updatedFilters);
  };

  const updateUrlFromFilters = React.useCallback(
    updatedFilters => {
      updatedFilters.forEach(f => {
        if (f.type === 'range') {
          if (f.max !== max(f.range) || f.min !== min(f.range)) {
            query.set(f.value, f.range.join());
          } else {
            query.delete(f.value);
          }
        } else {
          const selected = f.options
            ?.filter(fo => fo.value)
            .map(fo => fo.id ?? fo.name)
            .join();

          if (!isEmpty(selected)) {
            query.set(f.value, selected);
          } else {
            query.delete(f.value);
          }
        }
      });

      history.replace({ ...history.location, search: query.toString() });
    },
    [history, query]
  );

  const handleSelect = (value, { filterLabel, id }) => {
    const updatedFilters = filters.map(fs =>
      fs.label === filterLabel
        ? {
            ...fs,
            options: fs.options.map(f => (f.id === id ? { ...f, value } : { ...f, value: false }))
          }
        : fs
    );
    setFilters(updatedFilters);
    updateUrlFromFilters(updatedFilters);
  };

  const applyFilters = () => updateUrlFromFilters(filters);

  const clearFilters = (excludes, eraseChanges) => {
    const updatedFilters = eraseChanges
      ? getFiltersFromUrl()
      : filters.map(f => {
          if (!isEmpty(excludes) && excludes.includes(f.label)) return f;
          if (f.type === 'range')
            return {
              ...f,
              range: [f.min, f.max]
            };
          return {
            ...f,
            options: f.options.map(fo => ({ ...fo, value: false }))
          };
        });

    setFilters(updatedFilters);
    updateUrlFromFilters(updatedFilters);
  };

  return { filters, handleCheck, handleSlide, handleSelect, applyFilters, clearFilters };
};

const defaultFilterValidation = id => Number.isInteger(+id) && +id > 0;

const useQueryFilterValidated = (name, validate = defaultFilterValidation, removeAll = false, defaultValue = '') => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const queryFilter = query.get(name);
  if (!queryFilter) {
    if (defaultValue) {
      query.set(name, defaultValue);
      history.replace({ ...history.location, search: query.toString() });
    }

    return defaultValue;
  }

  const queryFilterValidated = queryFilter
    .split(',')
    .filter(id => validate(id, queryFilter))
    .join();
  const fail = queryFilter !== queryFilterValidated;
  if (fail) {
    if (queryFilterValidated && !removeAll) {
      query.set(name, queryFilterValidated);
    } else {
      query.delete(name);
    }

    history.replace({ ...history.location, search: query.toString() });
  }

  return fail && removeAll ? '' : queryFilterValidated;
};

export { useFilters, useQueryFilterValidated, useProductsFilters };
