import { scrollBar } from '../../../../shared/styles/commonStyles';

const templateStyles = () => ({
  drawer: {
    width: 560
  },
  closeDrawer: {
    height: 24,
    width: 24,
    color: '#989EA4',
    cursor: 'pointer',
    '&:hover': {
      color: '#787E84'
    }
  },
  contentSection: {
    padding: '4px 40px'
  },
  systemBar: {
    padding: '0px'
  },
  creditBalanceWarning: {
    color: '#989EA4',
    textAlign: 'right',
    marginBottom: 4
  },
  creditBalance: {
    color: '#2E7D32',
    textAlign: 'right',
    lineHeight: '24px'
  },
  cardImgContainer: {
    width: 98,
    height: 72,
    position: 'relative'
  },
  cardImage: {
    height: '100%',
    objectFit: 'contain'
  },
  cardNumber: {
    color: '#4A4F54',
    '& span': {
      letterSpacing: '1px'
    }
  },
  isDefault: {
    background: '#E5E7E8',
    color: '#25282A',
    fontFamily: 'Inter',
    lineHeight: '16px',
    fontSize: 8,
    textTransform: 'uppercase',
    borderRadius: '2px',
    padding: '2px 4px',
    fontWeight: 500
  },
  cardDetailsText: { color: '#4A4F54', marginBottom: 8 },
  lockIcon: { height: 22, width: 16, color: '#989EA4' },
  drawerContent: { height: '100%', maxHeight: '100vh', padding: '20px', position: 'relative' },
  titleContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  summaryContainer: { paddingTop: 32, paddingBottom: 20 },
  addMoneyTitle: { color: '#4A4F54' },
  recommendedCreditText: { color: '#4A4F54', marginTop: 8 },
  addMoneyDescription: {
    color: '#989EA4',
    '& a': {
      color: '#3577D4',
      textDecoration: 'underline'
    },
    marginBottom: 20,
    marginTop: 4
  },
  viewContent: { height: 'calc(100vh - 88px)', ...scrollBar, paddingRight: 20, paddingLeft: 20 },
  viewContentNewCard: { height: 'calc(100vh - 176px)', ...scrollBar },
  inputLabel: { marginBottom: 6, cursor: 'default' },
  inputLabelForm: { marginBottom: 3, cursor: 'default' },
  selectField: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    '& .MuiPaper-root': {
      height: '320px !important',
      background: 'red'
    },
    '& .MuiSelect-root': {
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    }
  },
  formControlLabel: {
    margin: 0,
    padding: 0,
    marginBottom: 12
  },
  paymentMethodSection: {
    paddingTop: 20
  },
  cardImageWrapper: {
    height: 24,
    width: 34
  },
  menuPaper: {
    borderRadius: 12
  },
  menuItem: {},
  labelInput: { paddingTop: 0 },
  formInputContainer: { paddingTop: '0px !important', paddingBottom: '0px !important' },
  textFieldRoot: {
    '& fieldset': {
      borderRadius: 12
    }
  },
  textInput: {
    borderRadius: 12,
    padding: '14px 16px'
  }
});

export default templateStyles;
