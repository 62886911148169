import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, CircularProgress, Drawer, Grid, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Button, Typography } from '@swagup-com/components';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import styles from './styles/productSelectionDrawer';
import accountProductsApi from '../../apis/swagup/accountProducts';
import { useProductsFilters, useQueryFilterValidated } from '../../hooks/useFilters';
import { useQueryParams } from '../../hooks';
import { ImgWithHandler } from '../global/ImgUtils';
import Loader from '../global/Loader';
import InventoryItemDetailsModal from '../inventory/InventoryItemDetailsModal';
import { productStatus } from '../../apis/constants';
import { toSentenceCase } from '../account/MembershipCommon';
import apiPaths from '../../helpers/apiPaths';
import SearchSortFilter from '../shared/SearchSortFilter';
import { PRODUCT_STATUS_OPTIONS } from '../../utils/constants';

const useStyles = makeStyles(styles);

const ProductRow = ({ product, selected, toggleProduct, disabled, classes }) => {
  const location = useLocation();
  const linkRef = React.useRef();
  const MAX_LIMIT = 4;
  const inStock = product.stock.some(st => st.quantity > 0);
  const unusableRow =
    !inStock &&
    (product.status === productStatus.inactive || !product.enabled || isEmpty(product.stock.filter(st => st.active)));
  const urlParams = new URLSearchParams(location.search);
  urlParams.set('details', product.id);
  const fontColorStyle = { color: unusableRow ? '#4A4F54' : undefined, overflow: 'unset' };
  return (
    <Grid
      container
      alignItems="center"
      className={classes.productRowContainer}
      style={{
        borderBottom: selected ? '1px solid #3577D4' : undefined,
        background: unusableRow ? 'rgba(0, 0, 0, 0.08)' : undefined,
        opacity: disabled && !selected ? 0.6 : undefined
      }}
      onClick={({ target: { id } }) =>
        id?.includes('zoom-') || (!selected && (unusableRow || disabled)) ? false : toggleProduct(product)
      }
    >
      <Grid item xs={5}>
        <Grid container alignItems="center">
          <Grid item>
            <Checkbox
              color="primary"
              disabled={!selected && (unusableRow || disabled || selected > MAX_LIMIT)}
              checked={!!selected}
              value={!!selected}
              onChange={() => toggleProduct(product)}
              style={{ cursor: 'pointer' }}
              className={classes.productCheckbox}
            />
          </Grid>
          <Grid item>
            <Link
              to={{ ...location, search: urlParams.toString() }}
              ref={linkRef}
              onClick={() => linkRef.current.blur()}
            >
              <div className={classes.productImageContainer}>
                <ImgWithHandler
                  src={product.image}
                  width={78}
                  height={78}
                  alt={product.name}
                  className={classes.productImage}
                />
                <Grid
                  id="zoom-container"
                  container
                  justifyContent="center"
                  alignItems="center"
                  className="zoom"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    display: 'none',
                    background: 'rgba(255, 255, 255, 0.5)'
                  }}
                >
                  <Grid id="zoom-wrapper" item>
                    <img
                      id="zoom-image"
                      src="/images/storefront/zoom-in.svg"
                      className={classes.productZoomImage}
                      alt="zoom"
                    />
                  </Grid>
                </Grid>
              </div>
            </Link>
          </Grid>
          <Grid item xs>
            <div className={classes.productInfoContainer}>
              <Typography
                variant="body2SemiBoldInter"
                className={classes.rowProductName}
                style={{ color: unusableRow ? '#989EA4' : undefined }}
              >
                {product.name}
              </Typography>
              <Typography variant="body3RegularInter" className={classes.combinedText} style={fontColorStyle}>
                Status:{' '}
                <span style={{ color: product.status !== 'Approved' ? '#989EA4' : undefined }}>
                  {PRODUCT_STATUS_OPTIONS[product.new_status] || product.new_status}
                </span>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body3RegularInter" style={fontColorStyle}>
          {product.record_type?.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body3RegularInter" style={fontColorStyle}>
          {product.can_ship_international ? 'GLOBAL' : 'U.S Only'}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.productInfoContainer}>
          {unusableRow ? (
            <Typography variant="body3MediumInter" style={{ color: '#989EA4' }}>
              CURRENTLY UNAVAILABLE
            </Typography>
          ) : (
            <Grid container alignItems="center">
              {inStock ? (
                product.stock
                  .filter(st => st.quantity || st.active)
                  .map(st => (
                    <Grid
                      key={st.size.id}
                      title={st.quantity}
                      item
                      xs={st.size.id === 9 ? 6 : 2}
                      style={{ paddingBottom: 3, paddingTop: 3, marginRight: 10 }}
                    >
                      <Typography variant="body3MediumInter" className={classes.combinedText} style={fontColorStyle}>
                        {st.size.name}
                        {inStock && (
                          <>
                            : <span>{st.quantity}</span>
                          </>
                        )}
                      </Typography>
                    </Grid>
                  ))
              ) : (
                <Typography variant="body3MediumInter" style={{ color: '#989EA4' }}>
                  OUT OF STOCK
                </Typography>
              )}
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const formatColor = color => {
  if (!color) return 'none';
  return color.includes('#') ? color : `#${color}`;
};

const processProductOption = (p, sellingOutOfStock) => ({
  productId: p.id,
  name: p.name,
  description: p.description || '',
  imageUrl: p.image || '/images/storefront/rocket.png',
  isVisible: true,
  type: p.record_type,
  category: toSentenceCase(p.category),
  canShipInternational: p.can_ship_international,
  variants: [
    {
      platformId: p.id,
      name: p.name,
      description: p.description || '',
      imageUrl: p.image || '/images/storefront/rocket.png',
      inStock: !!p.stock.find(stock => stock.quantity), // visible_in_inventory
      colorName: p.theme_color || 'custom',
      colorValue: formatColor(p.theme_color_hex) || '#fff',
      isVisible: true,
      isApparel: p.is_apparel,
      recordType: p.record_type,
      items: p.items.map(({ product }) => ({
        platformId: product.id,
        name: product.name,
        imageUrl: product.image || '/images/storefront/rocket.png',
        colorName: product.theme_color || 'custom',
        colorValue: formatColor(product.theme_color_hex) || '#fff'
      })),
      stock: p.stock.map(stock => ({
        sizeName: stock.size.name,
        quantity: stock.quantity,
        isVisible: !!stock.quantity || sellingOutOfStock,
        platformSizeId: stock.size.id,
        price: p.price_per_unit_based_on_100
      }))
    }
  ]
});

const PRODUCT_CHUNK_SIZE = 12;

const ProductSelectionDrawer = ({ open, onClose, onAdd, products = [], onStockOnly, sellingOutOfStock }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [accountProducts, setAccountProducts] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [limit, setLimit] = useState(PRODUCT_CHUNK_SIZE);

  // useEffect(() => {
  //   setSelectedProducts(products);
  // }, [products]);

  const recordType = useQueryFilterValidated(
    'record_type',
    (id, value) => ['product', 'pack'].includes(id) && value.split(',').length === 1
  );

  const inventory = useQueryFilterValidated(
    'inventory',
    (id, value) => ['available', 'out_of_stock'].includes(id) && value.split(',').length === 1
  );

  const status = useQueryFilterValidated('status');
  const category = useQueryFilterValidated('category');
  const canShipInternational = useQueryFilterValidated(
    'can_ship_international',
    (id, value) => ['true', 'false'].includes(id) && value.split(',').length === 1
  );
  const ordering = useQueryFilterValidated(
    'ordering',
    (id, value) => ['-created_at', 'created_at', 'name', '-name'].includes(id) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const { data: productFiltersData = {}, isLoading: isProductFiltersLoading } = useProductsFilters();
  const { product_categories: productCategories } = productFiltersData;

  const query = useQueryParams();

  const search = query.get('search') || '';
  //   const haveActiveFilters = [search, recordType, status, category, ordering].some(e => e.length > 0);

  const accountProductsParams = {
    search,
    record_type: recordType,
    inventory: onStockOnly ? 'available' : inventory,
    status,
    category,
    can_ship_international: canShipInternational,
    ordering
    // visible_in_inventory: true,
  };

  const elementRef = useRef(null);

  const { data, isLoading } = useQuery(
    [apiPaths.accountProducts, accountProductsParams, limit],
    () => accountProductsApi.fetch({ ...accountProductsParams, limit }),
    {
      onSuccess: () => setIsLoadingMore(false)
    }
  );

  const handleLoadMore = () => {
    setLimit(l => l + PRODUCT_CHUNK_SIZE);
    setIsLoadingMore(true);
  };

  const listenToScroll = ({ target }) => {
    const scrolledBottom = target && target.scrollHeight - 32 < target.scrollTop + target.clientHeight;
    const canLoadMore = data?.count >= limit || (!data?.count && limit > PRODUCT_CHUNK_SIZE);
    if (scrolledBottom && canLoadMore) handleLoadMore();
  };

  useEffect(() => {
    if (data?.results) setAccountProducts(data.results);
  }, [data]);

  useEffect(() => {
    setLimit(PRODUCT_CHUNK_SIZE);
  }, [search, category, recordType, status]);

  const isSelected = prod => selectedProducts.find(p => p.productId === prod.id);

  const toggleProduct = prod =>
    isSelected(prod)
      ? setSelectedProducts(sp => sp.filter(p => p.productId !== prod.id))
      : setSelectedProducts(sp =>
          sp.find(p => p.productId === prod.id) ? sp : [...sp, processProductOption(prod, sellingOutOfStock)]
        );

  const itemDetailsId = +(query.get('details') ?? -1);
  const classes = useStyles();
  const categoryOptions = isProductFiltersLoading
    ? {}
    : Object.fromEntries(productCategories.slice().map(ps => [ps.id, ps.name]));
  const searchSortFilterConfig = {
    search: { placeholder: 'Search Products' },
    filters: [
      { label: 'Category', queryParam: 'category', options: categoryOptions },
      {
        label: 'Stock Status',
        queryParam: 'inventory',
        options: { available: 'In Stock', out_of_stock: 'Out of Stock' }
      }
    ]
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.productSelectionDrawer }}
      PaperProps={{ id: 'product-selection-drawer', ref: elementRef, onScroll: listenToScroll }}
    >
      <div className={classes.productSelectionBar}>
        <Grid container>
          <Box className={classes.productSelectionDrawerCentered}>
            <Grid container justifyContent="flex-start" className={classes.systemBar}>
              <Grid item>
                <Close className={classes.closeDrawer} onClick={onClose} />
              </Grid>
            </Grid>
            <Grid container className={classes.productsDrawerHeader} alignItems="center">
              <Grid item xs>
                <Typography variant="h5SemiBoldInter" className={classes.createRedeemOptionTitle}>
                  Add Products
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="primary"
                  onClick={() => {
                    onAdd(selectedProducts);
                    setSelectedProducts([]);
                    onClose();
                  }}
                  className={classes.addButton}
                >
                  Add
                  {selectedProducts.length > 0 && <span style={{ marginLeft: 2 }}>({selectedProducts.length})</span>}
                </Button>
              </Grid>
            </Grid>
            <SearchSortFilter config={searchSortFilterConfig} />
          </Box>
          <Grid container className={classes.productsDrawerContent}>
            <Grid container className={classes.productTableHeader}>
              <Grid container className={classes.productSelectionDrawerCentered}>
                <Grid item xs={5}>
                  <Typography variant="overlineMedium" className={classes.headerTextLeft}>
                    PRODUCT
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="overlineMedium" className={classes.headerText}>
                    TYPE
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="overlineMedium" className={classes.headerText}>
                    SHIPPING OPTIONS
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="overlineMedium" className={classes.headerText}>
                    SEE AVAILABILITY
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{ position: 'relative', minHeight: 'calc(100vh - 172px)' }}>
        <Box className={classes.productSelectionDrawerCentered} style={{ width: 'auto' }}>
          <Grid container className={classes.productRowsContainer}>
            {accountProducts
              .filter(
                ap =>
                  ap.new_status.toLowerCase() !== 'inactive' &&
                  !products.find(p => p.variants.find(v => v.platformId === ap.id))
              )
              .map(product => (
                <ProductRow
                  key={product.id}
                  product={product}
                  classes={classes}
                  selected={isSelected(product)}
                  toggleProduct={() => toggleProduct(product)}
                />
              ))}
          </Grid>
          <Grid container justifyContent="center" style={{ paddingTop: 8, paddingBottom: 32 }}>
            <Grid item>{isLoadingMore && <CircularProgress />}</Grid>
          </Grid>
        </Box>
        {isLoading && !isLoadingMore && <Loader absolute />}
      </div>
      <InventoryItemDetailsModal item={accountProducts.find(accProd => accProd.id === itemDetailsId)} hidePDFDownload />
    </Drawer>
  );
};

export default ProductSelectionDrawer;
