import { round } from 'lodash';
import React from 'react';
import { moneyStr } from '../../helpers/utils';

const StrikeOutText = ({ value, surPlus = 0, fontSize = 12, color = '#787B80', marginRight = 4 }) =>
  value > 0 ? (
    <span
      style={{
        fontFamily: 'Inter',
        fontSize,
        marginRight,
        color,
        textDecoration: 'line-through'
      }}
    >
      {moneyStr(round(value + surPlus, 2).toFixed(2))}
    </span>
  ) : null;

export default StrikeOutText;
