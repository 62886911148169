import React, { useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
  IconButton,
  LinearProgress
} from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';
import isArray from 'lodash/isArray';
import chunk from 'lodash/chunk';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { imageSrcSet, joinFields } from '../../../helpers/utils';
import { useSelection } from './SelectionContext';
import useGetGroupsContactsIds from './useGetGroupsContactsIds';
import RemoveContactsModalOld from './RemoveContactsModalOld';
import { contactsApi } from '../../../apis/swagup';
import apiPaths from '../../../helpers/apiPaths';

const styles = theme => ({
  iconClose: {
    color: '#434c5f',
    cursor: 'pointer',
    padding: 4
  },
  paper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 646,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 8,
    margin: 0,
    outline: 'none',
    borderRadius: 10
  },
  titleContainer: {
    paddingTop: 40,
    textAlign: 'center'
  },
  actionsContainer: {
    paddingTop: 36,
    paddingBottom: 16
  },
  title: {
    maxWidth: 510,
    lineHeight: '36px',
    fontFamily: 'Gilroy-Medium'
  },
  subtitle: {
    maxWidth: 439,
    paddingTop: 16
  },
  removeButton: {
    height: 56,
    maxWidth: 198,
    paddingLeft: 10,
    paddingRight: 10,
    '&:focus': { boxShadow: '0 0 0 4px rgba(244, 67, 54, 0.5)' }
  },
  cancelButton: {
    height: 56,
    width: 198,
    backgroundColor: 'transparent',
    marginTop: 12,
    '&:hover': { backgroundColor: 'rgb(0, 0, 0, 0.05)' },
    '&:focus': { boxShadow: '0 0 0 4px rgba(141, 146, 153, 0.5)' }
  },
  cancelButtonText: {
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    lineHeight: '12px',
    color: '#8d9299'
  },
  loading: {
    fontSize: 12,
    fontFamily: 'Gilroy-Medium',
    color: '#0b1829'
  },
  progressRoot: {
    margin: 'auto',
    width: 200,
    height: 3,
    borderRadius: 2,
    marginTop: 12,
    backgroundColor: '#f0f2f5'
  }
});

const getModalSubtitle = contacts =>
  joinFields(
    [
      ((isArray(contacts) && contacts.some(c => c.has_orders && !c.has_pending_orders)) || contacts.hasOrders) &&
        'Personal data will be redacted in the associated shipments.',
      ((isArray(contacts) && contacts.some(c => c.has_pending_orders)) || contacts.hasWithPendingOrders) &&
        `Some contacts won't be removed, they have pending shipments.`,
      "This action can't be undone."
    ],
    ' '
  );

const useStyles = makeStyles(styles);

const deleteBulkSize = 300;

const LoaderSection = ({ progress, loadingText }) => {
  const classes = useStyles();
  return (
    <div>
      <p className={classes.loading}>{progress === 100 ? 'Complete' : loadingText}</p>
      <LinearProgress variant="determinate" classes={{ root: classes.progressRoot }} value={progress} />
    </div>
  );
};

const RemoveContactsModal = ({ contacts, isOpen, onDelete, onClose }) => {
  const [deleteOperationProgress, setDeleteOperationProgress] = useState(0);

  const classes = useStyles();
  const { areAllSelected, excluded, totalSelected } = useSelection();
  const {
    contactsIds,
    hasWithPendingOrders,
    hasWithOrders,
    isLoading,
    progress: loadingIdsProgress,
    setEnabled
  } = useGetGroupsContactsIds(excluded, areAllSelected);

  const justOneContact = contacts?.length === 1;
  useEffect(() => {
    setEnabled(isOpen && areAllSelected && !justOneContact);
  }, [isOpen, areAllSelected, setEnabled, justOneContact]);

  const softDeleteContactsBulks = useMutation(
    async ({ ids, chunks }) => {
      const apiRslt = await contactsApi.softDelete(ids);
      return { ...apiRslt, chunks };
    },
    {
      onSuccess: ({ chunks }) => {
        const deleted = chunks ? totalSelected - chunks.length * deleteBulkSize : 1;
        setDeleteOperationProgress((deleted * 100) / totalSelected);

        const idsChunk = chunks?.pop();
        if (idsChunk) {
          const filteredChunk = idsChunk.filter(id => !excluded.has(id));
          softDeleteContactsBulks.mutate({ ids: filteredChunk, chunks });
        } else onDelete('ok');
      },
      onError: () => onDelete('error')
    }
  );

  // const deleteContactsBulks = useMutation(
  //   async ({ ids, chunks }) => {
  //     const apiRslt = await contactsApi.delete(ids);
  //     return { ...apiRslt, chunks };
  //   },
  //   {
  //     onSuccess: ({ chunks }) => {
  //       const deleted = chunks ? totalSelected - chunks.length * deleteBulkSize : 1;
  //       setDeleteOperationProgress((deleted * 100) / totalSelected);

  //       const idsChunk = chunks?.pop();
  //       if (idsChunk) {
  //         const filteredChunk = idsChunk.filter(id => !excluded.has(id));
  //         deleteContactsBulks.mutate({ ids: filteredChunk, chunks });
  //       } else onDelete('ok');
  //     },
  //     onError: () => onDelete('error')
  //   }
  // );

  const queryClient = useQueryClient();
  const handleDelete = () => {
    const cidChunk = chunk(contactsIds, deleteBulkSize);
    const singleId = justOneContact && contacts?.map(c => c.id);
    softDeleteContactsBulks.mutate({
      ids: singleId || cidChunk.pop() || [...excluded.keys()],
      chunks: singleId ? undefined : cidChunk
    });
    queryClient.invalidateQueries(apiPaths.contacts);
  };

  // const handleDelete = () => {
  //   const cidChunk = chunk(contactsIds, deleteBulkSize);
  //   const singleId = justOneContact && contacts?.map(c => c.id);
  //   deleteContactsBulks.mutate({
  //     ids: singleId || cidChunk.pop() || [...excluded.keys()],
  //     chunks: singleId ? undefined : cidChunk
  //   });
  //   queryClient.invalidateQueries(apiPaths.contacts);
  // };

  if (!isOpen) return null;

  const message =
    justOneContact || (!areAllSelected && excluded.size === 1)
      ? ` ${joinFields(
          [[...(contacts || excluded.values())][0].first_name, [...(contacts || excluded.values())][0].last_name],
          ' '
        )}`
      : ' the selected contacts';

  const isOneContactToBeRemoved = justOneContact || totalSelected === 1;

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={isOpen || softDeleteContactsBulks.isLoading}
      onClose={softDeleteContactsBulks.isLoading ? undefined : onClose}
    >
      <DialogTitle aria-labelledby="remove-contacts-dialog">
        <Grid container justifyContent="flex-end">
          <IconButton
            aria-label="close"
            className={classes.iconClose}
            onClick={onClose}
            disabled={softDeleteContactsBulks.isLoading}
          >
            <IconClose />
          </IconButton>
        </Grid>
        <Grid container justifyContent="center">
          <img
            src="/images/public/delete-employee.png"
            srcSet={imageSrcSet('/images/public/delete-employee.png')}
            className={classes.image}
            alt="Delete Employee"
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" className={classes.titleContainer}>
          <Typography variant="h5" className={classes.title}>
            Are you sure you want to remove
            <strong>{message}</strong> from your directory?
          </Typography>
          {isLoading ? (
            <LoaderSection
              progress={loadingIdsProgress}
              loadingText={`Getting ${totalSelected} contact${totalSelected === 1 ? '' : 's'} ready to delete`}
            />
          ) : (
            <div>
              {softDeleteContactsBulks.isLoading && (
                <LoaderSection
                  progress={deleteOperationProgress}
                  loadingText={`Removing ${isOneContactToBeRemoved ? 1 : totalSelected} contact${
                    isOneContactToBeRemoved ? '' : 's'
                  }`}
                />
              )}
              <Typography variant="subtitle1" className={classes.subtitle}>
                {getModalSubtitle(
                  areAllSelected ? { hasWithPendingOrders: hasWithPendingOrders(), hasWithOrders } : excluded
                )}
              </Typography>
            </div>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container className={classes.actionsContainer}>
          <Grid container justifyContent="center" item xs={12}>
            <Button
              fullWidth
              variant="alert"
              type="submit"
              disabled={isLoading || softDeleteContactsBulks.isLoading || (areAllSelected && contactsIds.length === 0)}
              onClick={handleDelete}
              className={classes.removeButton}
            >
              <Typography variant="button">Remove Contact{totalSelected === 1 ? '' : 's'}</Typography>
            </Button>
          </Grid>
          <Grid container justifyContent="center" item xs={12}>
            <Button
              variant="primary"
              onClick={onClose}
              className={classes.cancelButton}
              disabled={softDeleteContactsBulks.isLoading}
            >
              <span className={classes.cancelButtonText}>Cancel</span>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const RemoveContactsModalSwitcher = props => {
  const { contactsFeGroupingTemp011921: grouping } = useFlags();
  return grouping ? <RemoveContactsModal {...props} /> : <RemoveContactsModalOld {...props} />;
};

export default RemoveContactsModalSwitcher;
