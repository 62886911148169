import React from 'react';
import { makeStyles, IconButton, Drawer as MuiDrawer } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useClasses = makeStyles({
  iconBtn: {
    position: 'absolute',
    top: 24,
    right: 24,
    padding: 0
  },
  icon: {
    width: 18,
    height: 18
  }
});

const useClassesV2 = makeStyles({
  iconBtn: {
    position: 'absolute',
    top: 15,
    left: 25,
    padding: 0
  },
  icon: {
    width: 24,
    height: 24
  }
});

const Drawer = ({ children, anchor, onClose, ...props }) => {
  const { enableUnallocatedProductsManagementV2 } = useFlags();
  const classes = useClasses();
  const classesV2 = useClassesV2();
  const { iconBtn, icon } = enableUnallocatedProductsManagementV2 ? classesV2 : classes;

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDrawer anchor={anchor || 'right'} onClose={handleClose} {...props}>
      <IconButton aria-label="Close" className={iconBtn} onClick={onClose}>
        <Close className={icon} />
      </IconButton>
      {children}
    </MuiDrawer>
  );
};

export default Drawer;
