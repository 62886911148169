import React from 'react';
import { Box, Grid, makeStyles, withStyles } from '@material-ui/core';
import { TextField, Typography } from '@swagup-com/components';
import { ChevronRight } from '@material-ui/icons';
import { CustomTooltip } from '../../products/commonProductsElements';
import styles from './styles/sendSwagConfiguration';
import Select from '../../global/Select';

const useStyles = makeStyles(styles);

const IntegrationItem = ({ title, icon, small, selected, product, ellipsisStylesStyle, long, type }) => {
  const classes = useStyles({ selected, product });
  const longSize = long ? 9 : 8;
  return (
    <Grid container justifyContent="center" className={classes.integrationSection}>
      <Grid item xs={small ? 6 : longSize}>
        <Box className={classes.integrationSelectionItem}>
          <Grid container alignItems="center" spacing={6}>
            <Grid item>{icon}</Grid>
            <Grid item xs={small ? 6 : 8} style={{ textAlign: 'left', position: 'relative' }}>
              <CustomTooltip
                title={title}
                arrow
                placement="top-start"
                disableHoverListener={!ellipsisStylesStyle || !title || title.length < 17}
              >
                <Typography
                  variant="subtitle2SemiBoldInter"
                  className={classes.integrationItemTitle}
                  style={{ marginBottom: product ? 12 : 0 }}
                >
                  {title}
                </Typography>
              </CustomTooltip>

              <Box className={classes.productTag}>{type}</Box>
            </Grid>
            <Grid item style={{ paddingRight: 0, paddingLeft: 0 }}>
              {!product && <ChevronRight style={{ color: '#131415', marginTop: 4 }} />}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const IntegrationCustomSelectField = withStyles({
  root: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    '& .MuiSelect-root': {
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    },
    '& .MuiSelect-selectMenu': { borderRadius: 12 },
    '& .MuiSelect-select': { paddingLeft: 20 },
    '& fieldset': { borderRadius: 12 },
    '& .MuiSelect-icon': { right: '16px !important' },
    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '12px !important' }
  },
  menuPaper: {
    borderRadius: '12px !important',
    paddingTop: '0px',
    paddingBottom: '0px',
    '& .MuiListItem-root': { paddingTop: '14px', paddingBottom: '14px' },
    '& .MuiList-root': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  }
})(props => <Select {...props} />);

const IntegrationSlimTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': { borderRadius: 12 },
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
      height: 43,
      '& #start-adornment': {
        color: '#374151',
        fontSize: 14,
        marginBottom: -2
      },
      '& input': {
        padding: '8px 16px 8px 20px',
        minWidth: 20,
        fontFamily: 'Inter',
        '&::placeholder': {
          fontSize: 12
        }
      },
      '& fieldset': {
        border: '1px solid #D6D8DB',
        borderRadius: 12
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #3577D4'
      }
    }
  }
})(TextField);

export { IntegrationItem, IntegrationCustomSelectField, IntegrationSlimTextField };
