const getStepByRoute = route => {
  const withoutVariant = route
    .split('/')
    .slice(0, -2)
    .join('/');

  if (route.includes('upload-logo')) {
    return 0;
  }
  if (withoutVariant === '/onboarding/mockups') {
    return 2;
  }

  return 1;
};

const designStatus = {
  failure: 'Failure',
  pending: 'Pending',
  success: 'Success'
};

const getAmount = {
  '50-100': 100,
  '250-500': 300,
  '1000+': 1000,
  'Im not sure': 100
};

const getDomain = email => email?.split('@')?.[1];

export { designStatus, getAmount, getStepByRoute, getDomain };
