import React, { useEffect, useState } from 'react';
import { withStyles, Grid, makeStyles, Modal, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { TextField as SwagupTextField, Button, Typography } from '@swagup-com/components';
import { useIntercom } from 'react-use-intercom';
import { getOnboardingOnlySizes, clearbitEmailValidation } from '../../actions';
import { imageSrcSet, normalizeUSZip } from '../../helpers/utils';
import { formatCreditCardNumber, formatCVC } from '../../helpers/utilsCC';
import OnboardingSelector from './OnboardingSelector';
import SelectorField from './SelectorField';
import { InputAssigner } from './Common';
import OnboardingSliderSelector from './OnboardingSliderSelector';
import Loader from './Loader';
import globalApi from '../../apis/swagup/global';
import states from '../../apis/usStates';
import styles from './styles/onboarding';
import ExpirationMonthPicker from '../global/ExpirationMonthPicker';
import PhoneInput from '../global/PhoneInput';

const handleClearbitEmailValidation = async email => {
  if (email) {
    const response = await clearbitEmailValidation(email);
    if (response.result !== 'ok') return 'Email verification failed, please try again';
    const { valid, account_exists: accountExists, msg } = response.data;
    return valid && !accountExists ? undefined : msg;
  }
  return 'This field may not be blank.';
};

const handlePhoneValidation = (country, oldPhone, prevError) => async phone => {
  if (!phone || oldPhone === phone) return null;

  try {
    const response = await globalApi.validatePhones([{ phone, country }]);
    return response.data[0].is_valid ? null : 'Invalid phone number format';
  } catch (e) {
    if (e.message.includes('request canceled')) return prevError;
    return 'An error ocurred while validating. Please, try again';
  }
};

const modalText = { color: '#131415', textAlign: 'center', fontFamily: 'Inter', fontSize: 24, marginBottom: 16 };

const useStyle = makeStyles(theme => ({
  inputSearch: {
    height: 64,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 42,
      paddingTop: 4,
      fontSize: 14,
      borderRadius: 21
    }
  },
  helperText: {
    fontSize: 11
  },
  modal: { height: '100vh' },
  modalContainer: {
    height: 344,
    width: 400,
    background: '#FFFFFF',
    margin: 'auto',
    marginTop: '16%',
    borderRadius: 16,
    overflowY: 'visible'
  },
  modalHeader: {
    position: 'relative',
    paddingTop: 124
  },
  modalImageWrapper: {
    width: 182,
    height: 182,
    margin: 'auto',
    overflow: 'hidden',
    borderRadius: 24,
    position: 'absolute',
    top: '-82px',
    left: 'calc(50% - 92px)'
  },
  modalCenterImage: { height: '100%', width: '100%', objectFit: 'cover' },
  modalTitle: { ...modalText },
  modalTextContainer: { margin: '0px 64px', marginBottom: 24 },
  modalSubTitle: { ...modalText, fontSize: 12, lineHeight: '16px' },
  modalButton: { height: 48, width: 124, borderRadius: 24 }
}));

const TextField = ({ errorText, ...props }) => {
  const classes = useStyle();
  return (
    <SwagupTextField
      className={classes.inputSearch}
      FormHelperTextProps={{
        className: classes.helperText
      }}
      error={!!errorText}
      helperText={errorText}
      {...props}
    />
  );
};

const SingleInputStep = withStyles(styles)(({ classes, property, placeholder, handleChange, errors, normalize }) => {
  const eventHandler = ({ target }) => {
    target.value = normalize ? normalize(target.value) : target.value;
    handleChange({ target: { id: target.id, value: target.value } });
  };
  return (
    <div className={classes.singleInputContainer}>
      <TextField
        id={property}
        placeholder={placeholder}
        onChange={eventHandler}
        onBlur={eventHandler}
        errorText={errors[property]}
      />
    </div>
  );
});

const SecondStep = InputAssigner(
  2,
  withStyles(styles)(({ classes, handleChange, errors }) => (
    <Grid container spacing={4}>
      <Grid item md={6} xs={12} className={classes.inputLeft}>
        <TextField
          id="first_name"
          placeholder="First name"
          onChange={handleChange}
          onBlur={handleChange}
          errorText={errors.first_name}
        />
      </Grid>
      <Grid item md={6} xs={12} className={classes.inputRight}>
        <TextField
          id="last_name"
          placeholder="Last name"
          onChange={handleChange}
          onBlur={handleChange}
          errorText={errors.last_name}
        />
      </Grid>
    </Grid>
  ))
);

const existingClientEmailErrorMessage =
  'Existing clients, please contact your account manager for assistance in obtaining samples';
const ThirdStep = InputAssigner(1, ({ asyncHandleChange, errors }) => {
  const [open, setOpen] = useState(false);
  const { showMessages } = useIntercom();
  useEffect(() => {
    if (errors && errors.email === existingClientEmailErrorMessage) {
      setTimeout(() => {
        setOpen(true);
      }, 500);
    }
  }, [errors]);

  const ChatWithUs = () => {
    try {
      // eslint-disable-next-line no-undef
      if (Intercom) Intercom('showSpace', 'messages');
      return setOpen(false);
    } catch {
      return setOpen(false);
    }
  };

  const classes = useStyle();
  return (
    <>
      <SingleInputStep
        handleChange={event => asyncHandleChange({ ...event, extraValidation: handleClearbitEmailValidation })}
        property="email"
        placeholder="Your email"
        handleOnBlur={asyncHandleChange}
        errors={{ ...errors, email: errors?.email === existingClientEmailErrorMessage ? true : errors.email }}
      />
      <Modal open={open} onClose={() => setOpen()} className={classes.modal}>
        <Box className={classes.modalContainer}>
          <Box className={classes.modalHeader}>
            <Box className={classes.modalImageWrapper}>
              <img
                src="/images/onboarding/chat-with-us.png"
                srcSet={imageSrcSet('/images/onboarding/chat-with-us.png')}
                alt="Chat with us"
                className={classes.modalCenterImage}
              />
            </Box>
          </Box>
          <Typography className={classes.modalTitle}>Welcome back</Typography>
          <Box className={classes.modalTextContainer}>
            <Typography className={classes.modalSubTitle}>
              We see that you are an existing SwagUp customer. Please contact your account representative to request
              free samples, or speak to us now via chat.
            </Typography>
          </Box>
          <Grid container justifyContent="center">
            <Button size="small" variant="primary" className={classes.modalButton} onClick={ChatWithUs}>
              Chat now
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
});

const CompanyStep = InputAssigner(1, ({ handleChange, errors }) => (
  <SingleInputStep
    handleChange={handleChange}
    handleOnBlur={handleChange}
    property="company_name"
    placeholder="Your Company"
    errors={errors}
  />
));

const OnboardingSelectorItem = withStyles(styles)(({ classes, iconSrc, extraClass }) => (
  <img src={iconSrc} className={extraClass || classes.selectorPic} alt="" />
));

const OnboardingSelectorItemBottom = withStyles(styles)(({ classes, iconSrc, extraClass, height }) => (
  <Grid container alignItems="baseline" alignContent="flex-end" style={{ height: height || 82 }}>
    <Grid item>
      <img src={iconSrc} className={extraClass || classes.selectorPic} alt="" />
    </Grid>
  </Grid>
));

const FourthStep = InputAssigner(
  2,
  withStyles(styles)(({ classes, handleChange, errors }) => {
    const items = [
      <OnboardingSelectorItem key="HR" iconSrc="/images/onboarding/hr.svg" />,
      <OnboardingSelectorItem key="Marketing" iconSrc="/images/onboarding/manager.svg" />,
      <OnboardingSelectorItem key="Sales" iconSrc="/images/onboarding/founder.svg" />,
      <OnboardingSelectorItem
        key="Other"
        iconSrc="/images/onboarding/image-placeholder-40-x-40.svg"
        validation={value => !value && 'Required'}
      />
    ];
    return (
      <div className={classes.onboardingSelectorContainer}>
        <OnboardingSelector
          items={items}
          handleChange={handleChange}
          property="department"
          attachmentProperty="title"
          attachmentPlaceholder="Position"
          errors={errors}
        />
      </div>
    );
  }),
  ['title']
);

const FifthStep = InputAssigner(
  1,
  withStyles(styles)(({ classes, handleChange }) => {
    const items = [
      <OnboardingSelectorItem
        name="USA"
        key="US"
        iconSrc="/images/onboarding/usa.svg"
        extraClass={classes.imgUSAFlag}
      />,
      <OnboardingSelectorItem key="International" iconSrc="/images/onboarding/planet.svg" small />
    ];
    return (
      <div className={classes.onboardingSelectorContainer}>
        <OnboardingSelector items={items} handleChange={handleChange} property="shipping_country" />
        <p className={classes.stepsSubText}>
          International shipment is subject to a $25 shipping fee. We’ll ask for your credit card details to complete
          the request
        </p>
      </div>
    );
  })
);

const SixthStep = InputAssigner(
  6,
  connect(({ onboardingInfo, countries }) => ({
    country: onboardingInfo.currentInfo.shipping_country,
    countries
  }))(
    withStyles(styles)(({ classes, handleChange, errors, country, countries, state, clearForm }) => {
      const selectedCountry = countries.find(c => c.iso2 === state.shipping_country);
      const countryHasProvinces = selectedCountry?.provinces?.length > 0;
      const isNational = country === 'US';
      if (isNational && state.shipping_country !== 'US') {
        clearForm();
        handleChange({ target: { id: 'shipping_country', value: 'US' } });
      }
      const internationalSwitch = !isNational && state.shipping_country === 'US';
      if (internationalSwitch) clearForm();
      return (
        <div className={classes.addressWizardContent}>
          <Grid container style={{ paddingBottom: 20 }} spacing={6}>
            {!isNational && (
              <Grid item xs={12} className={classes.addressInputContainer}>
                <SelectorField
                  property="shipping_country"
                  items={countries}
                  errors={errors}
                  textValue={state.shipping_country === 'US' ? '' : state.shipping_country}
                  onChange={event => {
                    if (event?.target?.value !== state.shipping_country) {
                      handleChange(event);
                      if (state.shipping_state) handleChange({ target: { id: 'shipping_state', value: '' } });
                    }
                  }}
                  valueFieldName="iso2"
                  hasAbbreviation
                  placeholder="Country"
                  excludes={['US']}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: isNational ? 0 : 18 }} className={classes.addressInputContainer}>
              <TextField
                id="shipping_address1"
                placeholder="Street and number, P.O. box, c/o"
                onChange={handleChange}
                onBlur={handleChange}
                errorText={errors.shipping_address1}
              />
            </Grid>
            <Grid item xs={!isNational ? 6 : 12} className={classes.addressInputContainer}>
              <TextField
                id="shipping_address2"
                placeholder="Apt, suite, etc."
                onChange={handleChange}
                onBlur={handleChange}
                errorText={errors.shipping_address2}
              />
            </Grid>
            <Grid item xs={!isNational ? 6 : 12} className={classes.addressInputContainer}>
              <TextField
                id="shipping_city"
                placeholder="City"
                onChange={handleChange}
                onBlur={handleChange}
                errorText={errors.shipping_city}
              />
            </Grid>
            <Grid item xs={6} className={classes.addressInputContainer}>
              {!isNational ? (
                <>
                  {countryHasProvinces ? (
                    <SelectorField
                      property="shipping_state"
                      errors={errors}
                      onChange={handleChange}
                      items={selectedCountry?.provinces}
                      textValue={internationalSwitch ? '' : state.shipping_state}
                      valueFieldName="code"
                      hasAbbreviation
                      placeholder="State/Province"
                    />
                  ) : (
                    <TextField
                      id="shipping_state"
                      placeholder="State/Province"
                      onChange={handleChange}
                      onBlur={handleChange}
                      errorText={errors.shipping_state}
                    />
                  )}
                </>
              ) : (
                <SelectorField
                  property="shipping_state"
                  textValue={state.shipping_state}
                  errors={errors}
                  onChange={handleChange}
                  items={states}
                  valueFieldName="abbreviation"
                  hasAbbreviation
                  placeholder="State"
                />
              )}
            </Grid>
            <Grid item xs={6} className={classes.addressInputContainer}>
              <TextField
                id="shipping_zip"
                placeholder="Zip"
                onChange={({ target }) => {
                  target.value = isNational ? normalizeUSZip(target.value) : target.value;
                  handleChange({ target: { id: target.id, value: target.value } });
                }}
                onBlur={({ target }) => {
                  target.value = isNational ? normalizeUSZip(target.value) : target.value;
                  handleChange({ target: { id: target.id, value: target.value } });
                }}
                errorText={errors.shipping_zip}
              />
            </Grid>
          </Grid>
        </div>
      );
    })
  ),
  ['shipping_address2'],
  {
    properties: ['shipping_state', 'shipping_zip'],
    condition: { property: 'shipping_country', values: ['US'] },
    reverse: true
  },
  ['shipping_state', 'shipping_zip']
);

const CreditCardStep = InputAssigner(
  4,
  withStyles(styles)(({ classes, handleChange, errors }) => (
    <div className={classes.addressWizardContent}>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.addressInputContainer}>
          <TextField
            id="card_number"
            placeholder="Card number"
            onChange={event => {
              handleChange(event);
              event.target.value = formatCreditCardNumber(event.target.value);
            }}
            onBlur={handleChange}
            errorText={errors.card_number}
          />
        </Grid>

        <Grid item xs={12} className={classes.addressInputContainer}>
          <TextField
            id="naccount"
            placeholder="Name on Card"
            onChange={handleChange}
            onBlur={handleChange}
            errorText={errors.naccount}
          />
        </Grid>
        <Grid item xs={6} className={classes.addressInputContainer}>
          <div className={classes.expirationMonthPickerCorrector}>
            <ExpirationMonthPicker
              id="expiration_date"
              name="expiration_date"
              onChange={value => {
                const event = { target: { id: 'expiration_date', value } };
                if (value === 'None') event.target.innerError = 'Invalid date format';
                handleChange(event);
              }}
              onBlur={e => {
                const { value } = e.target;
                const event = { target: { id: 'expiration_date', value } };
                if (!value) event.target.innerError = 'Required';
                handleChange(event);
              }}
              error={Boolean(errors.expiration_date)}
              helperText={errors.expiration_date}
            />
          </div>
        </Grid>
        <Grid item xs={6} className={classes.addressInputContainer}>
          <TextField
            id="security_code"
            placeholder="Security code"
            onChange={event => {
              handleChange(event);
              event.target.value = formatCVC(event.target.value);
            }}
            onBlur={handleChange}
            errorText={errors.security_code}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <div className={classes.cardInfoCharge}>
                <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>Total: $25</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ))
);

const SeventhStep = InputAssigner(
  1,
  connect(({ onboardingInfo }) => ({
    country: onboardingInfo.currentInfo.shipping_country
  }))(({ state, asyncHandleChange, errors, country }) => {
    const [phone, setPhone] = React.useState(state.phone_number || '');
    const [isBlurred, setIsBlurred] = React.useState(false);
    const handleOnChange = value => {
      setPhone(value);
      setIsBlurred(true);
      const prevError = errors.phone_number === 'Required' ? null : errors.phone_number;
      return asyncHandleChange({
        target: { id: 'phone_number', value },
        extraValidation: handlePhoneValidation(country, phone, prevError)
      });
    };
    return (
      <PhoneInput
        key={country}
        value={phone}
        name="phone_number"
        placeholder="Your phone"
        country={country === 'International' ? undefined : country}
        onChange={handleOnChange}
        onBlur={isBlurred ? undefined : handleOnChange}
        variant="outlined"
        meta={{ error: errors.phone_number }}
      />
    );
  })
);

const EigthStep = InputAssigner(
  1,
  withStyles(styles)(({ classes, handleChange, state, errors }) => (
    <div className={classes.sizesWizardContent}>
      <div className={classes.noMobileExact}>
        <Loader
          loader={getOnboardingOnlySizes}
          property="marks"
          filterFunc={sizes =>
            sizes
              .filter(size => size.label !== 'One Size' && (size.quantity > 0 || size.extraStock > 0))
              .map((size, index) => ({ id: size.id, value: index + 1, label: size.label, name: size.label }))
          }
        >
          <OnboardingSliderSelector handleChange={handleChange} currentValue={state.size} property="size" />
        </Loader>
      </div>
      <div className={classes.onMobileExact}>
        <Loader
          loader={getOnboardingOnlySizes}
          property="items"
          filterFunc={sizes =>
            sizes
              .filter(size => size.label !== 'One Size' && (size.quantity > 0 || size.extraStock > 0))
              .map((size, index) => ({ id: size.id, value: index + 1, label: size.label, name: size.label }))
          }
        >
          <SelectorField
            property="size"
            errors={errors}
            onChange={handleChange}
            valueFieldName="id"
            placeholder="Size"
            textValue={state.size}
          />
        </Loader>
      </div>
    </div>
  ))
);

const NinthStep = InputAssigner(
  1,
  withStyles(styles)(({ classes, handleChange, errors }) => {
    const items = [
      <OnboardingSelectorItem key="Onboard employees" iconSrc="/images/onboarding/onboard-employees.svg" />,
      <OnboardingSelectorItem key="Onboard clients" iconSrc="/images/onboarding/onboard-clients.svg" />,
      <OnboardingSelectorItem key="Events" iconSrc="/images/onboarding/events.svg" />,
      <OnboardingSelectorItem
        key="Other"
        iconSrc="/images/onboarding/im-not-sure.svg"
        attachmentProperty="reason"
        attachmentPlaceholder="Your goal"
      />
    ];
    return (
      <div className={classes.addressWizardContent}>
        <OnboardingSelector
          items={items}
          handleChange={handleChange}
          property="how_do_you_plan_to_use_swag_packs"
          errors={errors}
          spacing={2}
        />
      </div>
    );
  })
);
const TenthStep = InputAssigner(
  1,
  withStyles(styles)(({ handleChange }) => {
    const items = [
      <OnboardingSelectorItemBottom key="50-100" iconSrc="/images/onboarding/group-88.svg" />,
      <OnboardingSelectorItemBottom key="250-500" iconSrc="/images/onboarding/250-500.svg" />,
      <OnboardingSelectorItemBottom key="1000+" iconSrc="/images/onboarding/1000.svg" />,
      <OnboardingSelectorItemBottom key="Im not sure" iconSrc="/images/onboarding/group-104.svg" />
    ];
    return <OnboardingSelector items={items} handleChange={handleChange} property="packs_per_year" />;
  })
);

const ElevenStep = InputAssigner(
  1,
  withStyles(styles)(({ handleChange, classes }) => {
    const items = [
      <OnboardingSelectorItem key="Google" iconSrc="/images/onboarding/google.svg" extraClass={classes.imgSource} />,
      <OnboardingSelectorItem
        key="Linkedin"
        iconSrc="/images/onboarding/linkedin.svg"
        extraClass={classes.imgSource}
      />,
      <OnboardingSelectorItem
        key="Blog/Content"
        iconSrc="/images/onboarding/blog.svg"
        extraClass={classes.imgSource}
      />,
      <OnboardingSelectorItem
        key="Friend/Colleague"
        iconSrc="/images/onboarding/friend.svg"
        extraClass={classes.imgSource}
      />,
      <OnboardingSelectorItem
        key="Social Media"
        iconSrc="/images/onboarding/network.svg"
        extraClass={classes.imgSource}
      />,
      <OnboardingSelectorItem key="Other" iconSrc="/images/onboarding/others.svg" extraClass={classes.imgSource} />
    ];
    return (
      <OnboardingSelector items={items} handleChange={handleChange} property="how_did_you_hear_about_us" xs={4} wide />
    );
  })
);

export {
  SecondStep,
  ThirdStep,
  CompanyStep,
  FourthStep,
  FifthStep,
  SixthStep,
  SeventhStep,
  EigthStep,
  NinthStep,
  TenthStep,
  ElevenStep,
  CreditCardStep
};
