import * as React from 'react';
import { Grid, makeStyles, TableContainer, Table, TableBody, Box } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useState, useMemo, useEffect } from 'react';
import { min } from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import { ArrowBack } from '@material-ui/icons';
import StoreFilterSection from '../StoreFilterSection';
import { PayoutRow, TableEmptyState, TableHeaderPayout } from '../storeCommon';
import styles from './styles/payoutsTab';
import { usePaginatedQuery, useQueryParams } from '../../../hooks';
import storefrontsServicesPaths from '../../../helpers/storefrontsServicesPaths';
import { paginationRequestConverter } from '../../../helpers/utils';
import { useQueryFilterValidated } from '../../../hooks/useFilters';
import { storePayouts } from '../../../apis/storefrontsServices';
import { CenteredGrid, Pagination } from '../../shared';
import SpinningSnackBar from '../components/SpinningSnackBar/SpinningSnackBar';
import PayoutDetails from './PayoutDetails';
import SearchSortFilter from '../../shared/SearchSortFilter';

const useStyles = makeStyles(styles);

const perPageOptions = [10, 20, 30, 40];

const PayoutsTab = ({ store, searchParam }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [payoutId, setPayoutId] = useState();
  const [detailsIndex, setDetailsIndex] = useState();

  useEffect(() => {
    setDetailsIndex(!payoutId ? 0 : 1);
  }, [payoutId]);

  const query = useQueryParams();

  const search = query.get(searchParam) || '';
  const isSearching = !!search;
  const ordering = useQueryFilterValidated(
    'ordering',
    (xid, value) => ['-created_at', 'created_at'].includes(xid) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const {
    query: { data: payoutsResults, isFetching },
    pagination
  } = usePaginatedQuery({
    queryKey: [storefrontsServicesPaths.payouts(store?.id), search, ordering],
    queryFn: (limit, offset) => {
      return store.id
        ? storePayouts.list(store.id, paginationRequestConverter({ limit, offset, search, ordering }))
        : [];
    },
    perPageOptions
  });

  const storeRelatedPayouts = useMemo(() => payoutsResults?.results || [], [payoutsResults?.results]);

  const showPagination = pagination?.count > min(pagination?.sizeOptions);

  const toogleSelectAll = () =>
    setSelectedItems(prev => (prev.length !== storeRelatedPayouts.length ? storeRelatedPayouts.map(r => r) : []));

  const toogleSelectedItems = sitem =>
    setSelectedItems(prev =>
      prev.find(it => it.id === sitem.id) ? prev.filter(it => it.id !== sitem.id) : [...prev, sitem]
    );

  const payout = useMemo(() => storeRelatedPayouts?.find(c => c.id === payoutId) || {}, [
    storeRelatedPayouts,
    payoutId
  ]);

  const classes = useStyles();

  const searchSortFilterConfig = {
    search: { placeholder: 'Payouts', queryParam: 'search_payouts' },
    sort: { options: { '-created_at': 'Most Recent', created_at: 'Less Recent' } }
  };

  return (
    <div>
      <CenteredGrid>
        <Grid container alignItems="center">
          <Grid item>
            {!detailsIndex ? (
              <Typography variant="h2BoldInter">Payouts</Typography>
            ) : (
              <Typography variant="h2BoldInter" className={classes.tabTitle}>
                Payouts / <span>{payout.payoutNumber}</span>
              </Typography>
            )}
          </Grid>
          <Grid item xs />
          <Grid item>
            {detailsIndex > 0 && (
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setDetailsIndex(0);
                  setPayoutId();
                }}
              >
                <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <ArrowBack style={{ height: 16, width: 16, marginRight: 6 }} />
                  <Typography variant="body4RegularInter" style={{ color: '#3577D4', fontWeight: 600, fontSize: 16 }}>
                    Back to Payouts
                  </Typography>
                </Grid>
              </Button>
            )}
          </Grid>
        </Grid>
      </CenteredGrid>
      <SwipeableViews axis="x" index={detailsIndex} className={classes.swipeableViews} disabled>
        <Box>
          <SearchSortFilter config={searchSortFilterConfig} />
          <CenteredGrid>
            {storeRelatedPayouts?.length === 0 ? (
              <TableEmptyState isSearching={isSearching} type="payout" />
            ) : (
              <TableContainer>
                <Table stickyHeader className={classes.orderTable}>
                  <TableHeaderPayout
                    classes={classes}
                    toogleSelectAll={toogleSelectAll}
                    allSelected={storeRelatedPayouts.length === selectedItems.length}
                  />
                  <TableBody>
                    {storeRelatedPayouts?.map(p => (
                      <PayoutRow
                        key={p.id}
                        payout={p}
                        isSelected={!!selectedItems.find(s => s.id === p.id)}
                        toogleSelectedItems={toogleSelectedItems}
                        onShowDetails={id => setPayoutId(id)}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {showPagination && (
              <Grid container justifyContent="center" alignItems="center" className={classes.paginationContainer}>
                <Grid item>
                  <Pagination {...pagination} startText="Show" endText="payouts" buttonClass={classes.paginationBtn} />
                </Grid>
              </Grid>
            )}
          </CenteredGrid>
        </Box>
        <PayoutDetails store={store} payout={payout} classes={classes} />
      </SwipeableViews>

      <SpinningSnackBar open={isFetching} message="Loading..." />
    </div>
  );
};

export default PayoutsTab;
