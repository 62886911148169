import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';

const styles = () => ({
  discountText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  discountBadge: {
    background: '#90BEFF',
    borderRadius: 16,
    padding: ({ size }) => (size === 'small' ? '4px 6px 4px 3px' : '4px 10px 4px 6px')
  },
  discountBadgeGold: {
    background: '#1C4783',
    borderRadius: 16,
    padding: ({ size }) => (size === 'small' ? '4px 6px 4px 3px' : '4px 10px 4px 6px')
  },
  discountImageWrapper: { width: 16, height: 16, position: 'relative' },
  discountImage: { width: '100%', height: '100%', objectFit: 'contain' }
});

const useStyles = makeStyles(styles);

const DiscountBagde = ({ discount = 0, size = 'normal' }) => {
  const classes = useStyles({ size });
  const isMembershipDiscountPercentageGreaterThanFifty = discount > 50;

  return (
    <Box className={isMembershipDiscountPercentageGreaterThanFifty ? classes.discountBadgeGold : classes.discountBadge}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Box className={classes.discountImageWrapper}>
            {isMembershipDiscountPercentageGreaterThanFifty ? (
              <img src="/images/membership/gold-membership.png" className={classes.discountImage} alt="discount" />
            ) : (
              <img src="/images/membership/rewardRocket.png" className={classes.discountImage} alt="discount" />
            )}
          </Box>
        </Grid>
        <Grid item xs>
          <span className={classes.discountText}>{`${discount ? `-${discount}` : 0}`}%</span>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DiscountBagde;
