import GTM from 'react-gtm-module';
import { conversionDefaults } from '../helpers/utils';

const getLandingPage = () => window.location.pathname.split('/').pop();

export default {
  submitCatalogForm(order) {
    GTM.dataLayer({
      dataLayer: {
        event: 'submitForm',
        ecommerce: {
          transaction_id: order.id,
          value: order.pack_budget * order.pack_quantity || order.pack_budget,
          pricePerPack: Number(order.pack_budget),
          totalEstimate: Number(order.pack_budget * order.pack_quantity),
          currency: 'USD',
          items: order.items
        },
        orderId: order.id,
        riskLevel: order.risk_level,
        formType: order.form_type,
        packBudget: Number(order.pack_budget),
        packQuantity: order.pack_quantity,
        work_email: order.work_email,
        inhands_date: order.inhands_date,
        orderTotal: (
          order.pack_quantity *
          parseFloat(order.pack_budget) *
          conversionDefaults.stardardOrderMargin *
          conversionDefaults.standardOrderClosingPercent
        ).toFixed(2),
        enhanced_conversion_data: {
          email: order?.work_email
        }
      }
    });
  },

  submitContactForm(formName) {
    GTM.dataLayer({
      dataLayer: {
        event: 'submitContactForm',
        formName,
        value: conversionDefaults.getcontactFormConversionValue()
      }
    });
  },

  submitSamplePackForm(data) {
    GTM.dataLayer({
      dataLayer: {
        event: 'submitSamplePackForm',
        value: conversionDefaults.getRequestSampleConversionValue(),
        enhanced_conversion_data: {
          email: data?.email
        }
      }
    });
  },

  onClickStartWithPresetPack(name) {
    GTM.dataLayer({
      dataLayer: {
        event: 'onClickStartWithPresetPack',
        name
      }
    });
  },

  onClickLandingCTA() {
    GTM.dataLayer({
      dataLayer: {
        event: 'clickLandingCTA',
        landingPage: getLandingPage()
      }
    });
  },

  onClickRequestSampleCTA() {
    GTM.dataLayer({
      dataLayer: {
        event: 'clickRequestSampleCTA',
        landingPage: getLandingPage()
      }
    });
  },

  onClick(text) {
    GTM.dataLayer({
      dataLayer: {
        event: 'Click',
        text
      }
    });
  },

  onClickGenerateAIMockupsWithLogo(companyName, domain, packTemplate, designId) {
    GTM.dataLayer({
      dataLayer: {
        event: 'generateAIMockupsWithLogo',
        companyName,
        domain,
        packTemplate,
        designId
      }
    });
  },

  submitAIMockupOrderRequest(
    companyName,
    domain,
    packTemplate,
    designId,
    collageId,
    collageVariantNumber,
    userProvidedLogo
  ) {
    GTM.dataLayer({
      dataLayer: {
        event: 'submitAIMockupOrderRequest',
        companyName,
        domain,
        packTemplate,
        designId,
        collageId,
        collageVariantNumber,
        userProvidedLogo
      }
    });
  },

  onClickSeasonPromotionCTA() {
    GTM.dataLayer({
      dataLayer: {
        event: 'clickSeasonPromotionCTA',
        landingPage: getLandingPage()
      }
    });
  },

  onClickContinue(text) {
    GTM.dataLayer({
      dataLayer: {
        event: 'onClickContinue',
        value: text
      }
    });
  },

  addToCart(product) {
    GTM.dataLayer({
      dataLayer: {
        event: 'add_to_cart',
        ecommerce: {
          transaction_id: product.product.id,
          item_name: product.product.name,
          item_price: product.product.price,
          item_quantity: product.product.quantity,
          total_estimate: product.product.price * product.product.quantity
        }
      }
    });
  },

  // * Below Data Layer method is called when we are ordering the custom Pack.

  changeCartQuantity(transactionId, itemName, itemPrice, itemQuantityPerPack, newQuantity) {
    GTM.dataLayer({
      dataLayer: {
        event: 'update_cart_quantity',
        ecommerce: {
          transaction_id: transactionId,
          item_name: itemName,
          item_price: itemPrice,
          itemQuantityPerPack
        },
        packCompleteQuantity: newQuantity,
        totalEstimate: itemPrice * newQuantity
      }
    });
  },

  removeFromCart(product) {
    GTM.dataLayer({
      dataLayer: {
        event: 'remove_from_cart',
        ecommerce: {
          transaction_id: product.id,
          items: product.name,
          price: product.price
        }
      }
    });
  },

  viewItem(product) {
    GTM.dataLayer({
      dataLayer: {
        event: 'view_item',
        ecommerce: {
          transaction_id: product.id,
          items: product.name,
          price: product.price
        }
      }
    });
  },

  logInDetails(profile, currentMembership) {
    GTM.dataLayer({
      dataLayer: {
        event: 'company_details',
        company: {
          companyId: profile?.currentAccount?.id,
          companyName: profile?.currentAccount?.name,
          userEmail: profile?.email,
          membershipPlan: currentMembership?.name,
          membershipCreated: currentMembership?.created_at,
          membershipActive: currentMembership?.active,
          membershipFreeTier: currentMembership?.isFreeTier
        }
      }
    });
  }
};
