/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { Dialog, Divider, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import SwipeableViews from 'react-swipeable-views';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import isEmpty from 'lodash/isEmpty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CenteredGrid, Helmet } from '../shared';
import styles from './styles/redeemPagesHome';
import { getPageLink, MIN_CREDIT_BALANCE, prepareArtworksOnS3 } from './redeemCommon';
import { useCompany, useCreditSummary } from '../../hooks';
import { redeemPages, verifications } from '../../apis/redeemServices';
import gtm from '../../utils/gtm';
import redeemServicesPaths from '../../helpers/redeemServicesPaths';
import seoTags from '../../apis/seoTags';
import { isHexColor } from '../shared/styles/utils';
import { useQueryFilterValidated } from '../../hooks/useFilters';
import ProductSelectionDrawer from './ProductSelectionDrawer';
import RedeemTemplates from './RedeemTemplates';
import ProductSelection from './creationSteps/ProductSelection';
import ShippingSettings from './creationSteps/ShippingSettings';
import PageContent from './creationSteps/PageContent';
import PageTheme from './creationSteps/PageTheme';
import PageBasics from './creationSteps/PageBasics';
import ConfirmationPage from './creationSteps/ConfirmationPage';
import { CustomTooltip } from '../products/commonProductsElements';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import CustomQuestions from './creationSteps/CustomQuestions';

const useStyles = makeStyles(styles);

const darkTheme = {
  theme: 'dark',
  backgroundColor: '#161C25',
  fontColor: '#D6D8DB',
  accentColor: '#3577D4',
  fontFamily: 'Inter'
};
const lightTheme = {
  theme: 'light',
  backgroundColor: '#FFFFFF',
  fontColor: '#131415',
  accentColor: '#9846DD',
  fontFamily: 'Gilroy'
};
const dataTemplate = {
  projectName: 'New Redeem Page',
  isActive: true,
  productOptions: [],
  customQuestions: [],
  allowInternationalShipping: true,
  collectionOnly: true,
  headline: 'Welcome to CompanyName',
  body: 'We want to send swag your way! Click below and enter your shipping details.',
  callToActionButtonText: 'Start Here',
  clientImage: '/images/redeem/rocket.png',
  backgroundColor: '#FFFFFF',
  fontColor: '#131415',
  accentColor: '#9846DD',
  fontFamily: 'Gilroy',
  requireSizeSelection: true,
  requireCompanyName: false,
  showSelectedProduct: true,
  confirmationPageHeadline: 'Congratulations',
  confirmationPageBody: "You're on the short list of future swag recipients",
  selectProduct: true,
  productQuestion: 'What swag product do you prefer?',
  expressShipping: false,
  noLimit: false,
  uniqueUrl: false,
  uniqueUrlNumber: 0,
  skipAddress: false
};

const FormContainer = ({ children, title, step, maxSteps, top = 10, showDisclaimer }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body3RegularInter" className={classes.steps}>{`Step ${step}/${maxSteps}`}</Typography>
      <Typography variant="h3BoldInter" className={classes.stepTitle}>
        {title}
      </Typography>
      <div
        className={classes.stepContainer}
        style={{
          paddingTop: top,
          maxHeight: showDisclaimer ? 'calc(100vh - 320px)' : 'calc(100vh - 292px)',
          paddingRight: 8
        }}
      >
        {children}
      </div>
    </>
  );
};

const NotEnoughCreditModal = ({ open, onClose, onAccept, classes }) => (
  <Dialog open={open} onClose={onClose} classes={{ paper: classes.notEnoughCreditsModal }}>
    <Grid container>
      <div>
        <Typography variant="body2MediumInter" className={classes.notEnoughCreditsModalTitle}>
          Are you sure?
        </Typography>
        <Typography variant="body3RegularInter">
          You will not be able to set this redeem page "Active" and receive submissions until you add a minimum of $100
          in your Credit Balance.
        </Typography>
        <Divider className={classes.notEnoughCreditsModalDivider} />
        <Grid container spacing={6} justifyContent="flex-end">
          <Grid item>
            <Button size="small" variant="text" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button size="small" variant="primary" onClick={onAccept}>
              Create Now
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  </Dialog>
);

const templateFields = [
  {
    name: 'headline',
    placeholder: 'Edit your Redeem Page Header',
    label: 'Heading text',
    required: true
  },
  {
    name: 'body',
    placeholder: 'Edit your Redeem Page Subtitle',
    label: 'Subtitle text',
    multiline: true,
    required: true
  },
  {
    name: 'callToActionButtonText',
    placeholder: 'Edit your Call to action Text',
    label: 'CTA text',
    required: true
  },
  {
    name: 'clientImage',
    placeholder: 'Change the Product Display Image',
    label: 'Display image',
    image: true
  }
];

const fontFamilies = [
  { value: 'Gilroy', label: 'Gilroy' },
  { value: 'Helvetica', label: 'Helvetica' },
  { value: 'Futura', label: 'Futura' },
  { value: 'Inter', label: 'Inter' }
];

const themeVars = [
  { key: 'backgroundColor', label: 'Background Color' },
  { key: 'fontColor', label: 'Font Color' },
  { key: 'accentColor', label: 'Accent Color' }
];

const themeVarsErrors = {
  fontColor: 'Invalid font color value',
  backgroundColor: 'Invalid background color value',
  accentColor: 'Invalid accent color value'
};

const skippedProperty = ['company', 'theme'];

const RedeemPagesCreate = () => {
  const [page, setPage] = useState(dataTemplate);
  const [currentStep, setCurrentStep] = useState(1);
  const [artworkLoader, setArtworkLoader] = useState([]);
  const [nammingError, setNammingError] = useState();
  const [generalError, setGeneralError] = useState();
  const [openProductDrawer, setOpenProductDrawer] = useState(false);
  const [openNotEnoughCreditModal, setOpenNotEnoughCreditModal] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const { data: company } = useCompany();

  const { data: originalPage } = useQuery(redeemServicesPaths.redeemPage(id), () => redeemPages.get(id), {
    enabled: !!id
  });

  useEffect(() => {
    if (originalPage?.id)
      setPage({
        ...originalPage,
        accountName: company.name,
        productOptions: originalPage.productOptions ?? [],
        selectProduct: originalPage.selectProduct ?? false,
        requireSizeSelection: originalPage.requireSizeSelection ?? true,
        requireCompanyName: originalPage.requireCompanyName ?? false,
        showSelectedProduct: originalPage.showSelectedProduct ?? true,
        allowInternationalShipping: originalPage.allowInternationalShipping ?? true,
        noLimit: originalPage.noLimit ?? false,
        expressShipping: originalPage.expressShipping ?? false,
        productQuestion: originalPage.productQuestion || dataTemplate.productQuestion,
        confirmationPageHeadline: originalPage.confirmationPageHeadline || dataTemplate.confirmationPageHeadline,
        confirmationPageBody: originalPage.confirmationPageBody || dataTemplate.confirmationPageBody
      });
  }, [company.name, originalPage]);

  useEffect(() => {
    document.getElementById('root').style.background = 'linear-gradient(90deg, rgba(255,255,255) 50%, #EBF1FB 50%)';
    return () => (document.getElementById('root').style.background = '#ffffff');
  }, []);

  useEffect(() => {
    if (company.id)
      setPage(p => ({
        ...p,
        company,
        accountId: p.accountId || company.id,
        headline: id ? p.headline : `Welcome!`,
        accountName: p.accountName ?? company.name,
        companyDisplayName: p.companyDisplayName ?? company.name,
        clientLogo: p.clientLogo ?? company.logo
      }));
  }, [company, id]);

  const { isLoading: isNameQueryLoading } = useQuery(
    [redeemServicesPaths.verifyName, page.projectName, page.accountId],
    () => verifications.names({ projectName: page.projectName.trim(), accountId: page.accountId }),
    {
      onSuccess: data => setNammingError(data?.available ? '' : 'The current Redeem Page name is already been used'),
      enabled: !!page.projectName && page.projectName !== originalPage?.projectName && !!page.accountId
    }
  );

  useEffect(() => {
    const worthIt = !!(
      originalPage?.id &&
      page?.companyDisplayName &&
      originalPage?.companyDisplayName !== page?.companyDisplayName
    );
    const edition = !!id;
    if (!edition || worthIt)
      setPage(p => ({
        ...p,
        copyright: page.companyDisplayName
          ? `© ${new Date().getFullYear()} by ${page.companyDisplayName} in partnership with SwagUp`
          : '© 2022 SwagUp'
      }));
  }, [page?.companyDisplayName]);

  useEffect(() => {
    if (!id) {
      const showProductImg = !page.collectionOnly && page.productOptions?.length > 1;
      setPage(p => ({
        ...p,
        showSelectedProduct: showProductImg
      }));
    }
  }, [id, page.collectionOnly, page.productOptions]);

  const option = useQueryFilterValidated(
    'option',
    (key, value) => ['collection', 'auto-ship'].includes(key) && value.split(',').length === 1
  );

  useEffect(() => {
    if (option)
      setPage(p => ({ ...p, collectionOnly: option === 'collection', selectProduct: option !== 'collection' }));
  }, [option]);

  const createPayloadPage = () => {
    let returnPage = page;
    returnPage = Object.keys(returnPage).reduce(
      (rslt, key) => (!skippedProperty.includes(key) ? { ...rslt, [key]: returnPage[key] } : rslt),
      {}
    );

    if (id)
      returnPage = Object.keys(returnPage).reduce(
        (rslt, key) => (returnPage[key] !== originalPage[key] ? { ...rslt, [key]: returnPage[key] } : rslt),
        {}
      );

    return returnPage;
  };

  const { moreProductOptionsRedeemPages, customQuestionsRedeem } = useFlags();
  const standardMaxSteps = page.collectionOnly ? 5 : 6;
  const questionnaireMaxSteps = page.collectionOnly ? 6 : 7;
  const maxSteps = customQuestionsRedeem ? questionnaireMaxSteps : standardMaxSteps;
  const continueUrl = id ? `/redeem-history/${originalPage?.urlSlug}` : '/redeem-pages';

  const queryClient = useQueryClient();
  const createRedeem = useMutation(
    params => (id ? redeemPages.update(page.id, { ...params }) : redeemPages.create(params)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([redeemServicesPaths.redeemPages, company.id]);
        return history.push(continueUrl, {
          infoMessage: `<a href="${getPageLink(page)}/preview" target="_blank" rel="noreferrer">Redeem page</a> ${
            id ? 'updated' : 'created'
          }`
        });
      },
      onError: ({ data }) => setGeneralError(data.Messages?.find(m => m))
    }
  );

  const { data: creditSummary } = useCreditSummary();

  const handleOnPrevious = () => {
    const futureStep = currentStep - 1;
    if (futureStep === 0) history.push(continueUrl);
    else setCurrentStep(futureStep);
  };

  const excutePageProcessing = () => {
    const updatedPage = createPayloadPage(page);
    setOpenNotEnoughCreditModal(false);
    return createRedeem.mutate({ ...updatedPage, isActive: id ? page.isActive : false });
  };

  const handleONext = () => {
    const futureStep = currentStep + 1;
    if (futureStep > maxSteps) {
      setGeneralError();
      if (!id && !page.collectionOnly && creditSummary.current_balance < MIN_CREDIT_BALANCE)
        return setOpenNotEnoughCreditModal(true);
      const updatedPage = createPayloadPage(page);
      return isEmpty(updatedPage) ? history.push(continueUrl) : createRedeem.mutate(updatedPage);
    }
    return setCurrentStep(futureStep);
  };

  const isThemeSelected = t => ['fontFamily', ...themeVars.map(tv => tv.key)].every(key => page[key] === t[key]);
  const onChange = ({ target: { value, name } }) => setPage({ ...page, [name]: value });

  const handleDeleteProduct = prod =>
    setPage(p => ({ ...p, productOptions: p.productOptions.filter(pr => pr.productId !== prod.productId) }));

  const handleFileUpload = async (acceptedFiles, property) => {
    setArtworkLoader(al => [...al, property]);
    const image = acceptedFiles[0];
    if (!image) {
      setArtworkLoader(al => al.filter(a => a !== property));
      return true;
    }
    // const filePath = URL.createObjectURL(image);
    const uploaded = await prepareArtworksOnS3(image);
    setPage(p => ({ ...p, [property]: uploaded.url }));
    setArtworkLoader(al => al.filter(a => a !== property));
    return false;
  };

  const errors = () => {
    switch (currentStep) {
      case 1:
        if (artworkLoader.length) return 'Uploading files...';
        if (!page.projectName) return 'The name of the page is required';
        if (isNameQueryLoading) return 'Validating Project Name...';
        return page.projectName !== originalPage?.projectName && nammingError;
      case 2:
        if (!isHexColor(page.fontColor)) return themeVarsErrors.fontColor;
        if (!isHexColor(page.backgroundColor)) return themeVarsErrors.backgroundColor;
        if (!isHexColor(page.accentColor)) return themeVarsErrors.accentColor;
        return page.projectName !== originalPage?.projectName && nammingError;
      case 3:
        if (!page.headline || !page.body || !page.callToActionButtonText || !page.clientImage || artworkLoader.length)
          return 'Some required fields are missing';
        return false;
      case 4:
        return (
          (page.selectProduct || !page.collectionOnly) &&
          page.productOptions.length < 1 &&
          'You must select at least one product'
        );
      case 5:
        if (page.customQuestions?.length > 0 && page.customQuestions.some(cq => isEmpty(cq.question)))
          return 'Question fields cannot be empty.';
        if (
          page.customQuestions?.length > 0 &&
          page.customQuestions.some(cq => cq.type.includes('choice') && isEmpty(cq.options))
        )
          return 'At least a question option is required.';
        if (
          page.customQuestions?.length > 0 &&
          page.customQuestions.some(cq => cq.type.includes('choice') && cq.options.some(o => isEmpty(o.label)))
        )
          return 'No question option can be empty.';
        return false;
      case 6:
        if (!page.confirmationPageHeadline) return 'The Confirmation Page header text is required';
        if (!page.confirmationPageBody) return 'The Confirmation Page subtitle is required';
        return false;
      default:
        return false;
    }
  };

  const cantContinue = !(company?.id && !errors()) || createRedeem.isLoading;
  const showDisclaimer = !id && currentStep === maxSteps && !page.collectionOnly;

  const maxProducts = moreProductOptionsRedeemPages ? 12 : 4;

  return (
    <>
      <Helmet tags={seoTags.redeem} />
      <CenteredGrid className={classes.root} style={{ paddingTop: 12 }}>
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: 32 }}>
            <Grid container direction="column" className={classes.fullHeight}>
              <Grid item xs>
                <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
                  <FormContainer
                    title="Start with the basics"
                    step={currentStep}
                    maxSteps={maxSteps}
                    showDisclaimer={showDisclaimer}
                  >
                    <PageBasics
                      page={page}
                      company={company}
                      setPage={setPage}
                      handleFileUpload={handleFileUpload}
                      artworkLoader={artworkLoader}
                      onChange={onChange}
                    />
                  </FormContainer>
                  <FormContainer
                    title="Choose your theme"
                    step={currentStep}
                    maxSteps={maxSteps}
                    showDisclaimer={showDisclaimer}
                  >
                    <PageTheme
                      page={page}
                      setPage={setPage}
                      isThemeSelected={isThemeSelected}
                      lightTheme={lightTheme}
                      darkTheme={darkTheme}
                      fontFamilies={fontFamilies}
                      themeVars={themeVars}
                    />
                  </FormContainer>
                  <FormContainer
                    title="Design your landing page"
                    step={currentStep}
                    maxSteps={maxSteps}
                    showDisclaimer={showDisclaimer}
                  >
                    <PageContent
                      page={page}
                      templateFields={templateFields}
                      artworkLoader={artworkLoader}
                      setPage={setPage}
                      handleFileUpload={handleFileUpload}
                      onChange={onChange}
                    />
                  </FormContainer>
                  <FormContainer
                    title={page.collectionOnly ? 'Select your page type' : 'Product Selection'}
                    step={currentStep}
                    maxSteps={maxSteps}
                    showDisclaimer={showDisclaimer}
                    top={4}
                  >
                    <ProductSelection
                      page={page}
                      setPage={setPage}
                      onChange={onChange}
                      setOpenProductDrawer={setOpenProductDrawer}
                      handleDeleteProduct={handleDeleteProduct}
                    />
                  </FormContainer>
                  {customQuestionsRedeem ? (
                    [
                      <FormContainer
                        title="Customized Question"
                        step={currentStep}
                        maxSteps={maxSteps}
                        showDisclaimer={showDisclaimer}
                      >
                        <CustomQuestions page={page} setPage={setPage} />
                      </FormContainer>,
                      <FormContainer
                        title="Edit your confirmation page"
                        step={currentStep}
                        maxSteps={maxSteps}
                        showDisclaimer={showDisclaimer}
                      >
                        <ConfirmationPage page={page} onChange={onChange} />
                      </FormContainer>
                    ].map(stepComponent => stepComponent)
                  ) : (
                    <FormContainer
                      title="Edit your confirmation page"
                      step={currentStep}
                      maxSteps={maxSteps}
                      showDisclaimer={showDisclaimer}
                    >
                      <ConfirmationPage page={page} onChange={onChange} />
                    </FormContainer>
                  )}

                  <FormContainer
                    title="Shipment Settings"
                    step={currentStep}
                    maxSteps={maxSteps}
                    showDisclaimer={showDisclaimer}
                    top={4}
                  >
                    <ShippingSettings
                      page={page}
                      setPage={setPage}
                      credit={creditSummary.current_balance}
                      company={company}
                      history={history}
                    />
                  </FormContainer>
                </SwipeableViews>
              </Grid>
              <Grid item className={classes.wizardFooter} style={{ paddingTop: showDisclaimer ? 0 : undefined }}>
                <Grid container>
                  {showDisclaimer && (
                    <Grid item xs={12}>
                      <Typography variant="body3RegularInter" className={classes.finalDisclaimer}>
                        By creating this Autoship Redeem Page, you acknowledge and agree to our{' '}
                        <a href="https://www.swagup.com/return-refund-policy" target="_blank" rel="noreferrer">
                          Return/Refund Policy
                        </a>
                        .
                      </Typography>
                    </Grid>
                  )}
                  {generalError && (
                    <Grid item xs={12} style={{ position: 'relative' }}>
                      <Typography
                        variant="body4RegularInter"
                        className={classes.preferedImageError}
                        style={{ position: 'absolute', maxWidth: '100%', top: -18, ...ellipsisStyles }}
                      >
                        {generalError}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <Button
                      size="small"
                      variant="text"
                      onClick={handleOnPrevious}
                      className={classes.previous}
                      fullWidth
                    >
                      <KeyboardBackspaceIcon className={classes.previousIcon} />
                      {currentStep === 1 ? 'Redeem Pages' : 'Previous step'}
                    </Button>
                  </Grid>
                  <Grid xs item />
                  <Grid item>
                    <CustomTooltip title={errors() || ''} arrow placement="top-start" disableHoverListener={!errors()}>
                      <div style={{ minWidth: 156 }}>
                        <Button
                          size="small"
                          variant="primary"
                          onClick={() => {
                            handleONext();
                            gtm.onClickContinue('Create Redeem page');
                          }}
                          fullWidth
                          disabled={cantContinue}
                          loading={createRedeem.isLoading}
                        >
                          {currentStep === maxSteps ? `${id ? 'Update' : 'Create'}` : 'Continue'}
                        </Button>
                      </div>
                    </CustomTooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container style={{ paddingLeft: 32, height: '100%', paddingTop: 122 }}>
              <Grid item xs={12}>
                <RedeemTemplates page={page} currentStep={currentStep} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <NotEnoughCreditModal
          open={openNotEnoughCreditModal}
          onClose={() => setOpenNotEnoughCreditModal(false)}
          onAccept={excutePageProcessing}
          classes={classes}
        />
        <ProductSelectionDrawer
          open={openProductDrawer}
          products={page.productOptions}
          onClose={() => setOpenProductDrawer(false)}
          onAdd={selectedProducts => setPage(p => ({ ...p, productOptions: selectedProducts }))}
          maxProducts={maxProducts}
          onStockOnly={!page.collectionOnly}
          companyId={company.id}
        />
      </CenteredGrid>
    </>
  );
};

export default RedeemPagesCreate;
