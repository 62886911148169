import { FETCH_STORAGE_PRICES, FETCH_STORAGE_CATEGORIES } from '../actions/types';

const StoragePriceReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STORAGE_PRICES:
      return !action.payload
        ? state
        : {
            ...state,
            ...action.payload
          };
    case FETCH_STORAGE_CATEGORIES:
      return !action.payload
        ? state
        : {
            ...state,
            ...action.payload
          };

    default:
      return state;
  }
};

export default StoragePriceReducer;
