export default {
  subtitle: {
    maxWidth: 650,
    marginTop: 16
  },
  dropzone: {
    marginTop: 98,
    marginBottom: 36,
    width: 650,
    height: 136,
    borderRadius: 15,
    border: 'dashed 1px #3577d4',
    backgroundColor: '#ffffff',
    outline: 'none',
    textAlign: 'center',
    position: 'relative'
  },
  dropzoneImage: {
    position: 'absolute',
    width: 62,
    height: 62,
    top: -32,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  uploadBtn: {
    fontSize: 12,
    marginBottom: 2,
    height: 'auto',
    padding: 0
  },
  uploadedContainer: {
    marginTop: 98,
    marginLeft: -14,
    padding: '17px 22px 17px 14px',
    width: 678,
    transition: 'all 0.3s ease',
    '&:hover': {
      borderRadius: 10,
      boxShadow: ({ uploaded }) => uploaded && '0 16px 36px 0 rgba(0, 0, 0, 0.05)'
    },
    '&:hover > button': {
      opacity: 1
    }
  },
  imageContainer: {
    width: 50,
    height: 50,
    borderRadius: 10,
    border: 'solid 1px #f0f2f5'
  },
  fileDetails: {
    maxWidth: 525,
    marginLeft: 24,
    paddingRight: 24,
    letterSpacing: 'normal',
    '& > p': { color: '#868a8f' },
    '& > .name': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.57,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: ({ uploaded }) => uploaded && '#0b1829'
    }
  },
  deleteBtn: {
    opacity: 0,
    width: 36,
    height: 36,
    borderRadius: 10,
    backgroundColor: '#fef0ef !important',
    transition: 'all 0.3s ease',
    '& svg': {
      color: '#f44336',
      fontSize: 20
    }
  },
  retryBtn: {
    height: 'auto',
    padding: 0
  }
};
