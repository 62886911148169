import axios from '../DashBoard';
import apiPaths from '../../helpers/apiPaths';
import { status200, status201 } from './status.utils';

// ! We add a limit to avoid groups pagination
export default {
  fetch: () =>
    axios.get(`${apiPaths.contactGroups}?limit=9999&ordering=-created_at`, status200).then(response => response.data),
  create: data => axios.post(apiPaths.contactGroups, data, status201)
};
