export default theme => ({
  subtitle: {
    color: '#000',
    marginTop: 40,
    lineHeight: '22px',
    textAlign: 'left'
  },
  infoText: {
    lineHeight: '22px',
    marginBottom: '40px',
    textAlign: 'left'
  },
  dragAndDropContainer: {
    borderRadius: '4px',
    border: 'solid 1px #d4d9e2',
    backgroundColor: '#fff'
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start'
    }
  },
  continueButton: {
    width: 200,
    marginTop: 30
  },
  warningTextContainer: {
    marginBottom: 5,
    fontFamily: 'Gilroy-SemiBold',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  warningIcon: {
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      marginRight: 6
    }
  }
});
