export const reorderProductDisabledCopy = "Product disabled. Can't be ordered again";
export const reorderDisabledCopy = 'A product from this order is no longer available';

export const redactedText = '** redacted **';

export const shippingNotesPlaceHolder =
  'This field is not meant for removing from or adding products to this shipment. Please reach out to team@swagup.com if you need to make edits to your inventory';

const oneSecond = 1000;
export const fiveMinutes = 5 * 60 * oneSecond;
export const fifteenMinutes = 15 * 60 * oneSecond;
export const oneHour = 4 * fifteenMinutes;

export const minimumAcquirableCredits = 1;

export const shipmentStatuses = {
  adding: 'adding',
  success: 'success',
  idle: 'idle'
};

export const loremIpsum =
  'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore';

export const cantRushText = 'One or more items in your order are not available for rush production';

export const shipmentGroupsErrorTypes = {
  sizeWarning: 'size-warning',
  inactiveSizeWarning: 'inactive-size-warning',
  error: 'error'
};

export const cannotGuaranteeTurnaroundTime =
  'We are not able to guarantee in-hands dates, however, our current turnaround time for production is 3-4 weeks.';
