import * as React from 'react';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';

import { useQuery } from 'react-query';
import shippingPricesApi from '../../../../../apis/swagup/shippingPrices';
import { sumByQuantity } from '../../../../../helpers/utils';
import { addressFields } from '../common/utilsOrder';
import apiPaths from '../../../../../helpers/apiPaths';

const useShippingPricesQueryCurated = (
  products,
  recipient,
  selectedQuantitiesRef,
  directoryOrder,
  onUpdateDeliveryMethods,
  canUpdate
) => {
  const [selectedQuantities] = selectedQuantitiesRef;
  const [shippingPricePayload, setShippingPricePayload] = React.useState({});
  const [standardMethod, setStandardMethod] = React.useState(
    directoryOrder?.deliveryMethods?.find(dm => dm.name === 'Standard')
  );
  const [expressMethod, setExpressMethod] = React.useState(
    directoryOrder?.deliveryMethods?.find(dm => dm.name === 'Express')
  );
  const debouncedSetShippingPricePayload = React.useCallback(debounce(setShippingPricePayload, 350), [
    setShippingPricePayload
  ]);

  const quantityRecord = React.useMemo(() =>
    products
      .filter(p => selectedQuantities.has(p.id) && sumByQuantity(selectedQuantities.get(p.id)))
      .reduce((sum, p) => sum + sumByQuantity(selectedQuantities.get(p.id)), 0)
  );

  React.useEffect(() => {
    debouncedSetShippingPricePayload({
      quantities: products
        .filter(p => selectedQuantities.has(p.id) && sumByQuantity(selectedQuantities.get(p.id)))
        .map(p => ({
          account_product: p.product.id,
          quantity: sumByQuantity(selectedQuantities.get(p.id))
        })),
      ...pick(recipient, addressFields)
    });
  }, [recipient, products, selectedQuantities, quantityRecord, debouncedSetShippingPricePayload]);

  const enabled = !!(sumByQuantity(shippingPricePayload.quantities || []) > 0 && (!canUpdate || canUpdate()));

  // const queryClient = useQueryClient();
  const query = useQuery(
    [apiPaths.shippingPrices, shippingPricePayload],
    () => {
      // queryClient.cancelQueries(apiPaths.shippingPrices);
      return shippingPricesApi.fetch(shippingPricePayload).then(response => response.data);
    },
    { enabled }
  );

  const { data, isLoading, status } = query;

  React.useEffect(() => {
    if (!canUpdate || canUpdate()) {
      const customStandardMethod = data?.delivery_methods?.find(m => m.name === 'Standard');
      const customExpressMethod = data?.delivery_methods?.find(m => m.name === 'Express');
      let finalMethods = [];
      if (customStandardMethod) {
        setStandardMethod(customStandardMethod);
        finalMethods = [...finalMethods, { ...customStandardMethod, selected: true }];
      } else setStandardMethod();

      if (customExpressMethod) {
        setExpressMethod(customExpressMethod);
        finalMethods = [...finalMethods, { ...customExpressMethod, selected: finalMethods.length === 0 }];
      } else setExpressMethod();

      if (finalMethods.length > 0 || quantityRecord > 0) onUpdateDeliveryMethods(finalMethods);
    }
  }, [data]);

  React.useEffect(() => {
    if (quantityRecord === 0) {
      setStandardMethod();
      setExpressMethod();
      if (onUpdateDeliveryMethods) {
        onUpdateDeliveryMethods([]);
      }
    }
  }, [quantityRecord]);

  return [standardMethod, expressMethod, isLoading, status];
};

export default useShippingPricesQueryCurated;
