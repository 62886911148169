import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import debounce from 'lodash/debounce';
import SigleContentPage from './SigleContentPage';
import { sleep } from '../../helpers/utils';
import { WizardButtons, StepHeader, CentredContentContainer } from './Common';
import { fieldValidation } from '../../helpers/commonValidations';
import styles from './styles/onboarding';

const payform = require('payform');

function stringFormat(format, ...args) {
  return format.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
}

const validateCCNumber = value => {
  return (!value || !payform.validateCardNumber(value)) && 'Please, provide a valid Credit Card number';
};

const validateExpDate = value => {
  return (
    (!value || !payform.validateCardExpiry(payform.parseCardExpiry(value))) &&
    'Please, provide a valid Credit Card date'
  );
};

const validateCVC = value => {
  return (!value || !payform.validateCardCVC(value)) && 'Please, provide a valid Credit Card CVC';
};

const additionalValidations = field => {
  switch (field) {
    case 'card_number':
      return [validateCCNumber];
    case 'expiration_date':
      return [validateExpDate];
    case 'security_code':
      return [validateCVC];
    case 'email':
    case 'company_name':
    case 'department':
    case 'first_name':
    case 'last_name':
      return [value => !value && 'Required'];
    default:
      return undefined;
  }
};

class WizardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateInfo: {},
      viewIndex: 0,
      alreadyVisited: false,
      errors: {},
      hasChanged: false,
      furtherError: undefined,
      myStep: undefined
    };
  }

  asyncHandleValidations = async (field, value, extraValidation) => {
    const { state } = this;
    this.setState({ hasChanged: false });
    const validators = [
      ...(additionalValidations(field, state.stateInfo) || []),
      ...(fieldValidation(field, { [field]: value }) || [])
    ];

    if (validators && validators.length && validators.length > 0) {
      let result;
      let index = 0;
      while (index < validators.length && !result) {
        result = validators[index](value);
        index += 1;
      }
      let otherValidation;
      if (!result && extraValidation) otherValidation = await extraValidation(value);
      this.setState({ hasChanged: true });
      return !result && extraValidation ? otherValidation : result;
    }
    this.setState({ hasChanged: true });
    return undefined;
  };

  handleValidations = (field, value, stateInfo, extraValidation, values) => {
    const validators = [
      ...(additionalValidations(field, stateInfo) || []),
      ...(fieldValidation(field, { ...(values || stateInfo), [field]: value }) || [])
    ];

    if (validators && validators.length && validators.length > 0) {
      let result;
      let index = 0;
      while (index < validators.length && !result) {
        result = validators[index](value);
        index += 1;
      }
      let otherValidation;
      if (!result && extraValidation) otherValidation = extraValidation(value);
      return !result && extraValidation ? otherValidation : result;
    }
    return undefined;
  };

  asyncHandleChange = async ({ target, extraValidation }) => {
    const trimedTarget = { ...target, value: isString(target.value) ? target.value.trim() : target.value };
    const validationResult = await this.asyncHandleValidations(trimedTarget.id, trimedTarget.value, extraValidation);
    this.setState(prevState => ({
      stateInfo: { ...prevState.stateInfo, [trimedTarget.id]: trimedTarget.value },
      errors: {
        ...prevState.errors,
        [trimedTarget.id]: trimedTarget.innerError || validationResult
      },
      hasChanged: true
    }));
  };

  handleChange = ({ target: { id, value, innerError }, extraValidation }) => {
    this.applyChanges({ id, value: isString(value) ? value.trim() : value, innerError }, extraValidation);
    this.setState({ furtherError: undefined });
  };

  handleOnNext = () => {
    const { behaviorText, intermedium } = this.props;
    const { stateInfo, alreadyVisited } = this.state;
    if (!alreadyVisited && behaviorText) {
      this.setState({ viewIndex: 1 });
      if (intermedium) intermedium();
      sleep(2000).then(() => {
        this.goNextStep(stateInfo);
        this.setState({ viewIndex: 0, alreadyVisited: true });
      });
    } else this.goNextStep(stateInfo);
  };

  updateStep = () => {
    const { currentStep, maxSteps } = this.props;
    this.setState({ myStep: { step: currentStep, outOf: maxSteps } });
  };

  isCurrentStepMatchingCurrentView = () => {
    const { currentStep, maxSteps } = this.props;
    const { myStep } = this.state;
    return (
      !myStep ||
      currentStep === myStep.step ||
      (currentStep !== myStep.step &&
        myStep.outOf !== maxSteps &&
        ((currentStep < myStep.step && maxSteps < myStep.outOf) ||
          (currentStep > myStep.step && maxSteps > myStep.outOf)))
    );
  };

  goNextStep = stateInfo => {
    const { nextStep, furtherValidations } = this.props;
    const { hasChanged } = this.state;
    if (hasChanged && this.isCurrentStepMatchingCurrentView()) {
      this.updateStep();
      if (furtherValidations) {
        furtherValidations(
          stateInfo,
          rsltState => {
            const currentKeys = Object.keys(rsltState);
            currentKeys.forEach(key => {
              if (key === 'withforcedError') {
                const { id, value, innerError } = rsltState.withforcedError;
                this.handleChange({ target: { id, value, innerError } });
              } else this.handleChange({ target: { id: key, value: rsltState[key] } });
            });
          },
          furtherError => this.setState({ furtherError })
        );
      } else nextStep(stateInfo);
    }
  };

  handleBehaviorText = () => {
    const { behaviorText, field, optionalField } = this.props;
    const { stateInfo } = this.state;

    if (field || optionalField) return stringFormat(behaviorText, stateInfo[field] || stateInfo[optionalField]);
    return behaviorText;
  };

  clearForm = () => {
    const { stateInfo } = this.state;
    const currentKeys = Object.keys(stateInfo);
    currentKeys.forEach(key => {
      const inputElement = document.getElementById(key);
      if (inputElement) inputElement.value = '';
    });
    this.setState({
      stateInfo: {},
      errors: {},
      hasChanged: false
    });
  };

  canNext = () => {
    const { hasChanged, stateInfo, errors, furtherError } = this.state;
    const { Inputs } = this.props;
    let skipped = 0;
    if (Inputs.skips?.length) skipped = Inputs.skips.filter(skip => isEmpty(stateInfo[skip])).length;
    if (Inputs.skipsOnCondition)
      skipped = Inputs.skipsOnCondition.condition(stateInfo)
        ? skipped + Inputs.skipsOnCondition.properties.filter(skip => isEmpty(stateInfo[skip])).length
        : skipped;
    const currentKeys = Object.keys(stateInfo).filter(prop => stateInfo[prop]);
    const currentErrorKeys = Object.keys(errors);
    if (Inputs.fields) {
      const watchFieldErrors = Inputs.fields.reduce(
        (sum, key) => ({ ...sum, [key]: this.handleValidations(key, stateInfo[key], stateInfo) }),
        {}
      );
      if (Inputs.fields.some(key => errors[key] === 'Required' && !watchFieldErrors[key]))
        return this.setState({ errors: { ...errors, ...watchFieldErrors } });
    }

    return (
      hasChanged &&
      !furtherError &&
      currentKeys.length === Inputs.inputs - skipped &&
      !currentErrorKeys.find(key => errors[key] || this.handleValidations(key, stateInfo[key], stateInfo)) &&
      (!Inputs.fields || !Inputs.fields.find(key => this.handleValidations(key, stateInfo[key], stateInfo)))
    );
  };

  goPreviousStep = () => {
    const { stateInfo } = this.state;
    const { previousStep } = this.props;
    this.updateStep();
    previousStep(stateInfo);
  };

  applyChanges(target, extraValidation) {
    const { validateAll } = this.props;
    this.setState(prevState => {
      const { stateInfo, errors } = prevState;
      const otherFields = Object.keys(stateInfo).filter(field => field !== target.id);
      const otherFieldsErrors = validateAll
        ? otherFields.reduce((acc, field) => {
            acc[field] = this.handleValidations(field, stateInfo[field], stateInfo, extraValidation, {
              ...stateInfo,
              [target.id]: target.value
            });
            return acc;
          }, {})
        : {};
      return {
        stateInfo: { ...stateInfo, [target.id]: target.value },
        errors: {
          ...errors,
          ...otherFieldsErrors,
          [target.id]: target.innerError || this.handleValidations(target.id, target.value, stateInfo, extraValidation)
        },
        hasChanged: true
      };
    });
  }

  render() {
    const {
      classes,
      Inputs,
      title,
      behaviorImageSrc = '/images/public/nopic.jpg',
      maxWidthTitle = 472,
      maxWidthInput = '100%',
      currentStep,
      maxSteps,
      smallTitle,
      subTitle
    } = this.props;
    const { stateInfo, viewIndex, errors, furtherError } = this.state;
    return (
      <SwipeableViews axis="x" index={viewIndex} className={classes.swipeableViews} disabled>
        <Grid
          id="wizard-form"
          container
          direction="column"
          style={{ height: '100%', paddingLeft: 1, outline: 'none' }}
          tabIndex={0}
          onKeyDown={e => e.keyCode === 13 && this.canNext() && this.handleOnNext()}
        >
          <Grid item>
            <div className={classes.logoContainerTwo}>
              <a color="inherit" href="/">
                <img src="/images/public/su-logo.svg" className={classes.logo} alt="" />
              </a>
            </div>
          </Grid>
          <Grid item xs>
            <Grid container alignItems="center" className={classes.wizardContent}>
              <Grid item xs={12}>
                <div className={classes.wizardInnerContent} style={{ maxWidth: maxWidthTitle }}>
                  <StepHeader title={title} maxWidthTitle={maxWidthTitle} smallTitle={smallTitle} subTitle={subTitle} />
                </div>
                <form
                  className={classes.wizardInnerContent}
                  style={{ maxWidth: maxWidthInput }}
                  onSubmit={e => {
                    e.preventDefault();
                  }}
                >
                  <Inputs
                    handleChange={this.handleChange}
                    asyncHandleChange={debounce(params => this.asyncHandleChange(params), 750)}
                    errors={errors}
                    state={stateInfo}
                    handleErrors={er => this.setState({ errors: er })}
                    clearForm={this.clearForm}
                  />
                </form>
              </Grid>
            </Grid>
          </Grid>
          {furtherError && (
            <Grid item>
              <Grid container justifyContent="center">
                <p className={classes.errorText} style={{ textAlign: 'center' }}>
                  {furtherError}
                </p>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <div style={{ backgroundColor: '#dfdfdf' }}>
              <div style={{ height: 2, width: `${(100 * currentStep) / maxSteps}%`, backgroundColor: '#3577d4' }} />
            </div>

            <WizardButtons
              nextStep={this.handleOnNext}
              previousStep={this.goPreviousStep}
              canNext={this.canNext()}
              canPrevious={this.isCurrentStepMatchingCurrentView()}
            />
          </Grid>
        </Grid>
        <SigleContentPage>
          <Grid container direction="column">
            <CentredContentContainer>
              <div className={classes.logoContainer}>
                <a color="inherit" href={process.env.REACT_APP_MAIN_DOMAIN}>
                  <img src="/images/public/su-logo.svg" className={classes.logo} alt="" />
                </a>
              </div>
            </CentredContentContainer>
            <CentredContentContainer>
              <img src={behaviorImageSrc} className={classes.behaviorImg} alt="" />
            </CentredContentContainer>
            <CentredContentContainer paddingTop={32}>
              <h2 className={classes.behaviorText}>{this.handleBehaviorText()}</h2>
            </CentredContentContainer>
          </Grid>
        </SigleContentPage>
      </SwipeableViews>
    );
  }
}
const mapStateToProps = state => ({
  currentStep: state.onboardingInfo.currentStep,
  currentInfo: state.onboardingInfo.currentInfo,
  maxSteps: state.onboardingInfo.paths.length
});
export default connect(mapStateToProps)(withStyles(styles)(withRouter(WizardForm)));
