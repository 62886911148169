/* eslint-disable camelcase */
import { isEmpty } from 'lodash';
import shipmentsApi from '../apis/swagup/shipments';
import {
  SHIPMENTS_FETCH_START,
  SHIPMENTS_FETCH_SUCCESS,
  SHIPMENTS_DELETE_START,
  SHIPMENTS_DELETE_SUCCESS
} from './types';

const isQueryClean = ({ search, createdAt, statusIn, source_filter }) =>
  !search && createdAt === 'all' && statusIn === '' && isEmpty(source_filter);

export const fetchShipments = options => async dispatch => {
  dispatch({ type: SHIPMENTS_FETCH_START });

  const { results: shipments, count: total } = await shipmentsApi.fetch(options);

  dispatch({
    type: SHIPMENTS_FETCH_SUCCESS,
    payload: { shipments, total: isQueryClean(options) && total === 0 ? -2 : total }
  });

  return total;
};

export const deleteShipment = (id, options) => async dispatch => {
  dispatch({ type: SHIPMENTS_DELETE_START });
  const result = await shipmentsApi.delete(id);
  const { results: shipments, count: total } = await shipmentsApi.fetch(options);

  dispatch({
    type: SHIPMENTS_DELETE_SUCCESS,
    payload: { shipments, total: isQueryClean(options) && total === 0 ? -2 : total }
  });
  return result;
};

export const deleteSoftShipment = (id, options) => async dispatch => {
  dispatch({ type: SHIPMENTS_DELETE_START });
  const result = await shipmentsApi.softDelete(id);
  const { results: shipments, count: total } = await shipmentsApi.fetch(options);

  dispatch({
    type: SHIPMENTS_DELETE_SUCCESS,
    payload: { shipments, total: isQueryClean(options) && total === 0 ? -2 : total }
  });
  return result;
};
