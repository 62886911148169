import React from 'react';
import { Grid, Radio, makeStyles } from '@material-ui/core';
import { Lens as LensIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@swagup-com/components';
import round from 'lodash/round';
import gtm from '../../../../utils/gtm';
import { accountOrderProductStatus, invoiceStatus } from '../../../../apis/constants';
import { reorderProductDisabledCopy, reorderDisabledCopy } from '../../../../helpers/helperConstants';
import { isValidColorHex, sumByQuantity, isPack, moneyStr } from '../../../../helpers/utils';
import { ImgWithHandler } from '../../../global/ImgUtils';
import { useReorderLink } from '../../../inventory/InventoryCommon';

const useButtonStyles = makeStyles({
  orderAgainBtn: ({ isOrderItem, width, height, fontSize = 16 }) => ({
    fontSize,
    fontWeight: isOrderItem ? 500 : 600,
    width,
    height,
    '&:hover': { color: isOrderItem ? '#004ca2' : 'none' }
  }),
  tooltip: ({ isOrderItem }) => ({
    marginTop: isOrderItem ? -5 : 10,
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 500
  })
});

const useRadioStyles = makeStyles({
  multiColorIcon: { width: 13, marginTop: 1 },
  lensIcon: {
    padding: 0,
    border: '1px solid #ced1d6',
    '& .MuiSvgIcon-root': {
      width: 13,
      height: 13
    }
  }
});

const commonText = { fontFamily: 'Gilroy-Medium', fontSize: 12 };

const useProductStyles = makeStyles({
  productContainer: ({ isOrderDetails }) => ({
    padding: isOrderDetails ? '18px 0px 22px 0px' : '24px 0px 22px 24px'
  }),
  productImg: {
    width: 56,
    height: 56,
    objectFit: 'contain',
    borderRadius: 12,
    boxShadow: '0 10px 20px 0px rgba(0, 0, 0, 0.04)'
  },
  productName: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#0b1829'
  },
  recordTypeIcon: {
    ...commonText,
    color: '#0b1829',
    marginLeft: 30
  },
  productPrice: {
    ...commonText,
    color: '#787b80',
    marginLeft: 8
  },
  divider: {
    borderTop: '1px solid #ebedf0',
    borderBottom: 'none',
    margin: '0px 25px'
  }
});

const isProductDisabled = product => !product.enabled || product.status === accountOrderProductStatus.pendingMockup;

const OrderAgainButton = ({
  products,
  isOrderItem,
  urlToOpen,
  isOrderProcessing,
  isLoading,
  variant = 'secondary',
  width,
  height,
  fontSize
}) => {
  const classes = useButtonStyles({ isOrderItem, width, height, fontSize });

  const reorderLink = useReorderLink(products.map(p => p.id));

  const [label, to, replace, handleClick] = urlToOpen
    ? ['Pay now', urlToOpen, true, undefined]
    : [`Re-order ${isOrderItem ? 'item' : ''}`, reorderLink, false, () => gtm.onClick('Order again')];

  const disabled = products.some(isProductDisabled);

  const button = (
    <Button
      id={label === 'Pay now' ? 'pay-now' : 're-order'}
      size="small"
      component={Link}
      variant={variant}
      to={to}
      replace={replace}
      onClick={handleClick}
      disabled={disabled || isLoading || isOrderProcessing}
      loading={isLoading}
      className={classes.orderAgainBtn}
    >
      {label}
    </Button>
  );

  if (disabled) {
    return (
      <Tooltip
        title={isOrderItem ? reorderProductDisabledCopy : reorderDisabledCopy}
        classes={{ tooltip: classes.tooltip }}
      >
        <div>{button}</div>
      </Tooltip>
    );
  }

  return button;
};

const ColorIcon = ({ product }) => {
  const { multiColorIcon, lensIcon } = useRadioStyles();

  return isValidColorHex(product.theme_color_hex) ? (
    <Radio
      value={product.id}
      icon={<LensIcon />}
      disabled
      className={lensIcon}
      style={{ color: `#${product.theme_color_hex.replace('#', '')}` }}
    />
  ) : (
    <img src="/images/public/multicolor.png" alt="Multicolor" className={multiColorIcon} />
  );
};

const Product = ({ orderProduct, classes }) => {
  const { product, price, rush_fee: rushFee, sizes } = orderProduct;

  return (
    <>
      <Grid item container xs={1}>
        <ImgWithHandler src={product.image} alt={product.name} width={78} height={78} className={classes.productImg} />
      </Grid>
      <Grid item container xs={11} alignItems="center" style={{ paddingLeft: 30 }}>
        <Grid item>
          <p className={classes.productName}>
            {product.name}
            <span className={classes.recordTypeIcon}>{isPack(product.record_type) ? '📦 Pack' : '👕 Product'}</span>
          </p>
        </Grid>
        <Grid item container alignItems="flex-start">
          <ColorIcon product={product} />
          <p className={classes.productPrice}>
            {moneyStr(round(parseFloat(price) + parseFloat(rushFee), 2))}
            {` x ${sumByQuantity(sizes)}`}
          </p>
        </Grid>
      </Grid>
    </>
  );
};

const OrderProduct = ({ orderProduct, showReorderItem, showDivider, isOrderDetails }) => {
  const classes = useProductStyles({ isOrderDetails });
  const justify = `flex-${isOrderDetails ? 'end' : 'start'}`;

  return (
    <>
      <Grid container alignItems="center" className={classes.productContainer}>
        <Grid item container xs={isOrderDetails ? 9 : 6}>
          <Product orderProduct={orderProduct} classes={classes} />
        </Grid>
        <Grid item container xs={isOrderDetails ? 3 : 6} justifyContent={justify}>
          {showReorderItem && <OrderAgainButton products={[orderProduct.product]} isOrderItem variant="text" />}
        </Grid>
      </Grid>
      {showDivider && <hr className={classes.divider} />}
    </>
  );
};

const isOrderProcessing = order =>
  order?.status === invoiceStatus.processingPayment || order?.invoice_name === 'Processing';

export { OrderAgainButton, ColorIcon, OrderProduct, isOrderProcessing };
