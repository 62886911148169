import { scrollBar } from '../../../shared/styles/commonStyles';

const styles = theme => ({
  container: {
    marginTop: 0,
    paddingTop: 40,
    paddingBottom: 42,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  itemImageContainer: {
    position: 'relative',
    width: 68,
    height: 68
  },
  actionImageContainer: {
    position: 'relative',
    width: 68,
    height: 68
  },
  itemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  automatationYearContainer: {
    border: '1px solid #EBEDF0',
    padding: '12px 16px',
    borderRadius: 10
  },
  modalView: {
    '& .MuiPaper-root': {
      width: 768
    }
  },
  integrationImageContainer: {
    height: 48
  },
  automatationOptions: {
    borderRadius: 16,
    marginBottom: 24,
    background: ({ shopCreditsNotAllowed }) => (shopCreditsNotAllowed ? '#EBEDF0' : undefined),
    border: '1px solid #EBEDF0',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    padding: '12px 16px',
    '&:hover': {
      boxShadow: ({ shopCreditsNotAllowed }) =>
        shopCreditsNotAllowed ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'rgba(149, 157, 165, 0.3) 0px 8px 24px',
      background: ({ shopCreditsNotAllowed }) => (shopCreditsNotAllowed ? '#EBEDF0' : 'rgba(53, 119, 212, 0.08)')
    }
  },
  modalHeaderTextLeft: {
    textWrap: 'wrap',
    textAlign: 'left'
  },
  modalHeaderTextRight: {
    textWrap: 'wrap',
    textAlign: 'right'
  },
  flowImageContainer: {
    position: 'relative',
    width: 48,
    height: 48
  },
  automatationOptionButton: {
    minWidth: 90
  },
  modalHeader: {
    border: '1px solid #CED1D6',
    padding: '16px 24px',
    borderRadius: 10,
    marginTop: 24
  },
  closeButton: {
    position: 'absolute',
    height: 12,
    width: 12,
    padding: 6,
    '& .MuiSvgIcon-root': {
      fontSize: 16
    },
    right: 24,
    top: 16,
    [theme.breakpoints.down('xs')]: {
      right: 2,
      top: 17
    }
  },
  icon: {
    fontSize: 16
  },
  addYear: {
    borderRadius: 12,
    marginBottom: 24,
    border: '1px solid #EBEDF0',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    padding: '12px 16px 8px 16px',
    '&:hover': {
      boxShadow: 'rgba(149, 157, 165, 0.3) 0px 8px 24px'
    }
  },
  swipeableViews: {
    height: '476px',
    width: '100%',
    // minHeight: 'calc(100vh - 202px)',
    '& .react-swipeable-view-container': {
      height: '100%'
    },
    '& > div > div': {
      overflowX: 'hidden !important',
      overflowY: 'hidden !important'
    }
  },
  automatationOptionsContainer: {
    padding: '12px 12px 32px 12px',
    maxHeight: 456,
    ...scrollBar
  },
  automatationOptionsTitle: {
    textAlign: 'center',
    marginBottom: 16,
    marginTop: 4
  },
  actionText: { paddingLeft: 24 },
  customTitle: {
    padding: '16px 24px',
    minHeight: 78,
    textAlign: 'center',
    '& > .MuiTypography-root': {
      fontSize: ({ disableTypography }) => (disableTypography ? null : 24),
      lineHeight: ({ disableTypography }) => (disableTypography ? null : '30px')
    }
  },
  editIconButton: {
    fontSize: 16,
    color: '#3577d4',
    '& span': {
      marginRight: 8
    }
  },
  editIcon: {
    fontSize: 16,
    marginBottom: 3
  }
});

export default styles;
