import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useCatalogFilter = (
  initialCategoryValues,
  search,
  selectedPriceRange,
  defaultPriceRange,
  initialPriceRageValues,
  showCollectionsItems
) => {
  const { catalogCollections } = useFlags();

  const filterCatalog = useCallback(
    category => {
      if (
        (catalogCollections ? getCategoryFilter(category, showCollectionsItems) : true) &&
        (!initialCategoryValues || initialCategoryValues.includes(`${category.id}`)) &&
        (isEmpty(search) ||
          category.items.find(
            item =>
              item.name.toLowerCase().includes(search.toLowerCase()) ||
              item.description.toLowerCase().includes(search.toLowerCase())
          )) &&
        (selectedPriceRange === defaultPriceRange.join() ||
          !!category.items.find(
            item =>
              (isEmpty(search) ||
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item.description.toLowerCase().includes(search.toLowerCase())) &&
              Number(item.price) >= initialPriceRageValues[0] &&
              Number(item.price) <= initialPriceRageValues[1]
          ))
      )
        return true;
      return false;
    },
    [initialCategoryValues, search, selectedPriceRange, defaultPriceRange, initialPriceRageValues]
  );

  const filterCatalogItems = useCallback(
    item => {
      if (
        !catalogCollections ||
        (filterStrings(item.collections, showCollectionsItems) && item.visible_in_collections)
      ) {
        if (
          (isEmpty(search) ||
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.description.toLowerCase().includes(search.toLowerCase())) &&
          (selectedPriceRange === defaultPriceRange.join() ||
            (Number(item.price) >= initialPriceRageValues[0] && Number(item.price) <= initialPriceRageValues[1]))
        )
          return true;
      }
      return false;
    },
    [search, selectedPriceRange, defaultPriceRange, initialPriceRageValues]
  );

  const filterStrings = (targetStrings, elementsArray) => {
    if (elementsArray.length === 0) return true;
    const targetArray = targetStrings?.split(';') || [];
    for (const element of targetArray) {
      if (elementsArray.includes(element)) return true;
    }
    return false;
  };

  const getCategoryFilter = (category, showCollectionsItems) => {
    for (const item of category.items) {
      if (filterStrings(item.collections, showCollectionsItems)) {
        return true;
      }
    }
    return false;
  };
  return { filterCatalog, filterCatalogItems };
};

export default useCatalogFilter;
