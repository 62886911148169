const optionText = {
  fontFamily: 'Gilroy',
  color: '#787b80',
  fontSize: 14,
  fontWeight: 500
};

export default {
  drawerPaper: {
    maxWidth: 448,
    padding: '36px 40px 0px 32px'
  },
  ctaWrapper: {
    borderBottom: '1px solid #eaeaea',
    marginBottom: 24
  },
  optionTitle: optionText,
  cta: {
    ...optionText,
    color: '#3577d4',
    height: 'fit-content',
    minWidth: 'auto',
    padding: 0
  },

  whiteColor: { border: '1px solid #787B80' },
  decorationName: {
    fontFamily: 'Gilroy',
    color: '#0b1829',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'left'
  }
};
