import * as React from 'react';
import { useHistory } from 'react-router-dom';
import partition from 'lodash/partition';
import difference from 'lodash/difference';

import accountProductsApi from '../apis/swagup/accountProducts';
import useQueryParams from './useQueryParams';
import log from '../logger';

const fetchProducts = async ids => {
  try {
    const { results } = await accountProductsApi.fetch({ ids });
    return results;
  } catch (error) {
    log.error(error);
    return [];
  }
};

const productsToMap = products => new Map(products?.map(p => [p.id, p]) ?? []);

const getPossibleIdsFromQuery = query => {
  const possibleIds = query
    .get('selected')
    ?.split(',')
    .map(Number)
    .filter(id => id > 0 && Number.isInteger(id));

  return [...new Set(possibleIds)];
};

const getCheckboxProps = (products, selectedProducts) => {
  const checked = products.length > 0 && products.every(p => selectedProducts.has(p.id));
  const indeterminate = (!checked && products.some(p => selectedProducts.has(p.id))) || undefined;
  const disabled = products.length === 0;

  return { checked, indeterminate, disabled };
};

const defaultMapResults = p => p;
const defaultIsProductAvailable = () => true;

const useSelectedProducts = ({
  products,
  previouslySelected,
  isProductAvailable = defaultIsProductAvailable,
  mapResults = defaultMapResults
}) => {
  const history = useHistory();

  const [[selectedProducts], setSelectedProducts] = React.useState(() => [productsToMap(previouslySelected)]);
  const [isLoading, setIsLoading] = React.useState(false);

  const query = useQueryParams();
  const productsMap = React.useMemo(() => Object.fromEntries(products.map(p => [p.id, p])), [products]);

  const redirectLocationRef = React.useRef();
  const setSelectedIdsToUrl = React.useCallback(
    newSelectedIds => {
      if (newSelectedIds.length > 0) {
        query.set('selected', newSelectedIds);
      } else {
        query.delete('selected');
      }
      const location = redirectLocationRef.current ?? { ...history.location, search: query.toString() };
      history.replace(location);
      redirectLocationRef.current = null;
    },
    [query, history]
  );

  React.useEffect(() => {
    const updateSelection = async possibleIds => {
      const [idsFetched, idsNotFetched] = partition(possibleIds, id => Boolean(productsMap[id]));

      idsFetched
        .filter(id => isProductAvailable(productsMap[id]))
        .forEach(id => selectedProducts.set(id, productsMap[id]));

      if (idsNotFetched.length > 0) {
        setIsLoading(true);
        const results = await fetchProducts(idsNotFetched.join(','));
        setIsLoading(false);
        results
          .map(mapResults)
          .filter(isProductAvailable)
          .forEach(p => selectedProducts.set(p.id, p));
      }
      setSelectedProducts([selectedProducts]);
      setSelectedIdsToUrl([...selectedProducts.keys()]);
    };

    const possibleIds = getPossibleIdsFromQuery(query);

    if (query.get('selected') && possibleIds.length === 0 && selectedProducts.size === 0) {
      setSelectedIdsToUrl([]);
    }

    if (possibleIds.length !== selectedProducts.size && !isLoading) {
      updateSelection(possibleIds);
    }
  }, [query, productsMap, selectedProducts, isLoading, setSelectedIdsToUrl, isProductAvailable, mapResults]);

  const handleCheck = product => {
    if (!isProductAvailable(product)) return;

    const wasChecked = selectedProducts.delete(product.id);
    if (!wasChecked) {
      selectedProducts.set(product.id, product);
    }
    setSelectedProducts([selectedProducts]);
    setSelectedIdsToUrl([...selectedProducts.keys()]);
  };

  const availableProducts = React.useMemo(() => products.filter(isProductAvailable), [products, isProductAvailable]);
  const checkboxProps = getCheckboxProps(availableProducts, selectedProducts);

  const handleCheckAll = () => {
    const { checked } = checkboxProps;
    availableProducts.forEach(p => (checked ? selectedProducts.delete(p.id) : selectedProducts.set(p.id, p)));

    setSelectedProducts([selectedProducts]);

    const selectedProductsId = [...selectedProducts.keys()];
    const availableIds = availableProducts.map(p => p.id);

    const newSelectedIds = checked
      ? difference(selectedProductsId, availableIds)
      : [...new Set([...selectedProductsId, ...availableIds])];

    setSelectedIdsToUrl(newSelectedIds);
  };

  const clearSelection = React.useCallback(
    redirectLocation => {
      redirectLocationRef.current = redirectLocation;
      setSelectedProducts([new Map()]);
      setSelectedIdsToUrl([]);
    },
    [setSelectedIdsToUrl]
  );

  return { selectedProducts, isLoading, checkboxProps, handleCheck, handleCheckAll, clearSelection };
};

export default useSelectedProducts;
