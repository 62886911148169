import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useAuth } from '../../../global/Authentication/AuthProvider';

const styles = {
  textContainer: {
    textAlign: 'center',
    marginTop: 40
  },
  title: {
    color: '#000',
    fontWeight: 500,
    fontSize: 16
  },
  linkContainer: {
    marginTop: 20
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 16
  },
  arrow: {
    marginLeft: 10
  }
};

const RobotLoader = ({ classes }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Box width="100%" mt="50px">
      <Grid container item justifyContent="center" xs={12}>
        <Grid container item justifyContent="center" xs={12}>
          <img alt="Loading" src="/images/onboarding/robot-loader.gif" />
        </Grid>
        <Grid item xs={12} className={classes.textContainer}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.title}>
              Please wait while we create your mockups. This may take a few seconds.
            </Typography>
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            xs={12}
            className={classes.linkContainer}
          >
            <Link to={isAuthenticated ? '/' : '/custom-swag-packs/catalog'} className={classes.link}>
              {isAuthenticated ? 'Take me to my dashboard instead' : 'Let me start building my swag project'}
              <ArrowForwardIcon className={classes.arrow} />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(RobotLoader);
