import { useFlags } from 'launchdarkly-react-client-sdk';

const oldLink = 'https://scheduler.default.com/38/queue/22';
const newLink = 'https://forms.default.com/77167';

const useDemoBookingUrl = () => {
  const { demoBookingUrlReplacement } = useFlags();

  return demoBookingUrlReplacement ? newLink : oldLink;
};

export default useDemoBookingUrl;
