import React from 'react';
import { Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core';
import CloseButton from '../modals/CloseButton';
import styles from './styles/addPaymentMethodDialog';
import PaymentMethodForm from './PaymentMethodForm';
import { paymentMethods } from '../../apis/constants';

const useStyles = makeStyles(styles);

const AddPaymentMethodDialog = ({ paymentMethod, isDefault = true, open, onClose }) => {
  const classes = useStyles();

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const [modalTitle, detailSubtitle] =
    paymentMethod === paymentMethods.creditCard
      ? ['Add new Credit Card', 'Card details']
      : ['Add new ACH Account', 'Account details'];

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.Dialog }} className={classes.modalDialog}>
      <DialogTitle className={classes.DialogTitle}>
        <CloseButton onClose={onClose} classes={{ container: classes.closeButton }} />
        {modalTitle}
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <PaymentMethodForm
          isOpen={open}
          selectedMethod={paymentMethod}
          detailSubtitle={detailSubtitle}
          isDefault={isDefault}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddPaymentMethodDialog;
