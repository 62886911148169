import React from 'react';
import { Link } from 'react-router-dom';

export function CustomWidthButtonInverse({
  className,
  type,
  children,
  width = 'auto',
  height = 'auto',
  onClick,
  id,
  onMouseEnter,
  onMouseLeave,
  color = '#3577d4',
  fontFamily = 'Futura',
  fontSize = 16,
  uppercase,
  fontWeightNormal
}) {
  return (
    <button
      id={id}
      type={type || 'button'}
      onClick={onClick}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        display: 'table',
        borderRadius: 4,
        cursor: 'pointer',
        width,
        height,
        backgroundColor: '#ffffff',
        color,
        border: `2px solid ${color || '#3577d4'}`,
        textDecorationLine: 'none',
        fontFamily,
        fontSize,
        margin: 'auto',
        fontWeight: fontWeightNormal ? 'normal' : 'bold',
        lineHeight: 1.75,
        letterSpacing: 'normal',
        textAlign: 'center',
        textTransform: uppercase ? 'uppercase' : 'none',
        outline: 0
      }}
    >
      <div
        id={id}
        style={{
          verticalAlign: 'middle'
        }}
      >
        {children}
      </div>
    </button>
  );
}

export function RoundedButtonLink({
  className,
  to = '#',
  children,
  size,
  fill,
  color,
  border = 1,
  onClick,
  cursorType = 'pointer'
}) {
  return (
    <Link
      type="submit"
      to={to}
      onClick={onClick}
      className={className}
      style={{
        display: 'table',
        cursor: cursorType,
        textAlign: 'center !important',
        color: color === undefined ? 'transparent' : fill,
        textDecorationLine: 'none'
      }}
    >
      <div
        style={{
          verticalAlign: 'middle',
          textAlign: 'center',
          border: `${border}px #cccccc solid`,
          borderRadius: size === undefined ? 0 : size / 2,
          width: size === undefined ? 'auto' : size,
          height: size === undefined ? 'auto' : size,
          backgroundColor: fill === undefined ? 'transparent' : fill
        }}
      >
        {children}
      </div>
    </Link>
  );
}

export function DecrementButton({ className, fill, color, onClick, id, disabled = false }) {
  return (
    <button
      id={id}
      disabled={disabled}
      type="button"
      onClick={!disabled ? onClick : undefined}
      className={className}
      style={{
        cursor: 'pointer',
        textAlign: 'center !important',
        color: color === undefined ? 'transparent' : fill,
        textDecorationLine: 'none',
        backgroundColor: 'transparent',
        border: 0,
        outline: 0
      }}
    >
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g id="STAFF" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="SHIP" transform="translate(-1040.000000, -658.000000)" fill={disabled ? '#aaaaaa' : '#434C5F'}>
            <g id="Group-4" transform="translate(732.000000, 493.000000)">
              <g id="minus-copy" transform="translate(306.000000, 163.000000)">
                <path
                  d="M12,4 C7.589,4 4,7.589 4,12 C4,16.411 7.589,20 12,20 C16.411,20 20,16.411 20,12 C20,7.589 16.411,4 12,4 M12,22 C6.486,22 2,17.515 2,12 C2,6.486 6.486,2 12,2 C17.514,2 22,6.486 22,12 C22,17.515 17.514,22 12,22"
                  id="Fill-438"
                />
                <path
                  d="M17,12 C17,12.5522847 16.5443356,13 16.0046024,13 L7.99539757,13 C7.44565467,13 7,12.5561352 7,12 L7,12 C7,11.4477153 7.4556644,11 7.99539757,11 L16.0046024,11 C16.5543453,11 17,11.4438648 17,12 L17,12 Z"
                  id="Fill-439"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  );
}

export function IncrementButton({ className, id, fill, color, onClick, disabled = false }) {
  return (
    <button
      id={id}
      disabled={disabled}
      type="button"
      onClick={!disabled ? onClick : undefined}
      className={className}
      style={{
        cursor: 'pointer',
        textAlign: 'center !important',
        color: color === undefined ? 'transparent' : fill,
        textDecorationLine: 'none',
        backgroundColor: 'transparent',
        border: 0,
        outline: 0
      }}
    >
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g id="STAFF" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="SHIP" transform="translate(-1107.000000, -658.000000)" fill={disabled ? '#aaaaaa' : '#434C5F'}>
            <g id="Group-4" transform="translate(732.000000, 493.000000)">
              <g id="plus-copy" transform="translate(373.000000, 163.000000)">
                <path
                  d="M12,20.0005 C7.589,20.0005 4,16.4115 4,12.0005 C4,7.5895 7.589,4.0005 12,4.0005 C16.411,4.0005 20,7.5895 20,12.0005 C20,16.4115 16.411,20.0005 12,20.0005 M12,2.0005 C6.486,2.0005 2,6.4865 2,12.0005 C2,17.5155 6.486,22.0005 12,22.0005 C17.514,22.0005 22,17.5155 22,12.0005 C22,6.4865 17.514,2.0005 12,2.0005"
                  id="Fill-436"
                />
                <path
                  d="M13,8.0098689 C13,7.45240985 12.5561352,7.0005 12,7.0005 L12,7.0005 C11.4477153,7.0005 11,7.44385318 11,8.0098689 L11,11.0005 L8.0093689,11.0005 C7.45190985,11.0005 7,11.4443648 7,12.0005 L7,12.0005 C7,12.5527847 7.44335318,13.0005 8.0093689,13.0005 L11,13.0005 L11,15.9911311 C11,16.5485902 11.4438648,17.0005 12,17.0005 L12,17.0005 C12.5522847,17.0005 13,16.5571468 13,15.9911311 L13,13.0005 L15.9906311,13.0005 C16.5480902,13.0005 17,12.5566352 17,12.0005 L17,12.0005 C17,11.4482153 16.5566468,11.0005 15.9906311,11.0005 L13,11.0005 L13,8.0098689 Z"
                  id="Fill-437"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  );
}

export function StylessButton({
  id,
  className,
  children,
  width = 'auto',
  height = 'auto',
  onClick,
  onMouseEnter,
  onMouseLeave,
  onLoad,
  textAlign = 'center',
  fontFamily = 'Roboto',
  disabled,
  style
}) {
  return (
    <button
      type="button"
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      onLoad={onLoad}
      disabled={disabled}
      style={{
        cursor: disabled ? 'default' : 'pointer',
        textAlign,
        border: 'none',
        width,
        height,
        backgroundColor: 'transparent',
        color: disabled ? '#787b80' : '#3577d4',
        textDecorationLine: 'none',
        outline: 0,
        padding: 0,
        ...style
      }}
    >
      <div
        style={{
          verticalAlign: 'middle',
          fontFamily,
          letterSpacing: 'normal',
          textAlign
        }}
      >
        {children}
      </div>
    </button>
  );
}

export function CustomWidthButtonHolydays({
  id,
  className,
  children,
  width = 'auto',
  height = '56px',
  radius = 28,
  fontFamily = 'Futura',
  fontSize = 16,
  onClick,
  onKeyPress,
  disabled,
  type = 'submit',
  ...props
}) {
  return (
    <button
      id={id}
      type={type}
      onClick={onClick}
      onKeyPress={onKeyPress}
      className={className}
      disabled={!!disabled}
      style={{
        display: 'table',
        cursor: disabled ? 'not-allowed' : 'pointer',
        textAlign: 'center !important',
        border: 0,
        borderRadius: radius,
        width,
        height,
        backgroundColor: disabled ? '#dcdcdc' : '#9846dd',
        color: '#ffffff',
        textDecorationLine: 'none',
        fontFamily,
        fontSize,
        fontWeight: 'bold',
        lineHeight: 1.75,
        outline: 0,
        letterSpacing: 'normal',
        textTransform: 'uppercase'
      }}
      {...props}
    >
      <div
        id={id}
        style={{
          verticalAlign: 'middle',
          textAlign: 'center'
        }}
      >
        {children}
      </div>
    </button>
  );
}

export function CustomWidthButtonOnboarding({
  id,
  className,
  children,
  width = 'auto',
  height = '56px',
  radius = 28,
  fontFamily = 'Futura',
  fontSize = 16,
  onClick,
  disabled
}) {
  return (
    <button
      id={id}
      type="submit"
      onClick={onClick}
      className={className}
      disabled={disabled === undefined ? false : disabled}
      style={{
        display: 'table',
        cursor: disabled ? 'not-allowed' : 'pointer',
        textAlign: 'center !important',
        border: 0,
        borderRadius: radius,
        width,
        height,
        backgroundColor: disabled === true ? '#dcdcdc' : '#3577d4',
        color: '#ffffff',
        textDecorationLine: 'none',
        fontFamily,
        fontSize,
        fontWeight: 500,
        lineHeight: 1.75,
        outline: 0,
        letterSpacing: 'normal',
        textTransform: 'uppercase'
      }}
    >
      <div
        id={id}
        style={{
          verticalAlign: 'middle',
          textAlign: 'center'
        }}
      >
        {children}
      </div>
    </button>
  );
}
