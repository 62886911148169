import React, { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { makeStyles, Typography, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProfileIcon, CompanyIcon, BillingIcon, MembershipIcon } from '../icons';
import styles from './styles/SideBar';

const useStyles = makeStyles(styles);

const SideBarItem = ({ children, text, route, view }) => {
  const { pathname } = useLocation();
  const matchedView = pathname.split('/').pop();
  const isSelected =
    view === matchedView ||
    (view === 'credits' && matchedView === 'add-credits') ||
    (view === 'your-membership' &&
      [
        'your-membership',
        'manage-membership',
        'change-membership',
        'manage-membership-switch',
        'manage-membership-cancel',
        'manage-membership-confirmation'
      ].includes(matchedView));
  const classes = useStyles({ isSelected, isNested: route });

  return (
    <ListItem
      button
      selected={isSelected}
      className={classes.listItem}
      component={NavLink}
      activeClassName={classes.selectedColor}
      to={`${route ? `${route}/` : '/account/'}${view}`}
    >
      <ListItemIcon className={classes.selectedColor}>{children}</ListItemIcon>
      <ListItemText>
        <Typography variant="body1" className={classes.itemText}>
          {text}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};

const ExpandibleTabs = ({ list, route }) => (
  <List component="div" disablePadding>
    {list.map(tab => (
      <SideBarItem key={tab.view} view={tab.view} text={tab.text} route={route} />
    ))}
  </List>
);

const ExpandibleSection = ({ route, name, list }) => {
  const location = useLocation();
  const isSelected = location.pathname.includes(route);
  const [isExpanded, setIsExpanded] = useState(true);
  const classes = useStyles({ isSelected });

  return (
    <>
      <ListItem style={{ paddingRight: 0 }} button onClick={() => setIsExpanded(expanded => !expanded)}>
        <ListItemIcon className={classes.selectedColor}>
          {route === '/billing' ? <BillingIcon /> : <MembershipIcon />}
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body1" className={classes.itemText}>
            {name}
          </Typography>
        </ListItemText>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <ExpandibleTabs list={list} route={route} />
      </Collapse>
    </>
  );
};

const SideBar = () => {
  const classes = useStyles();
  const { swagupMembershipPlans } = useFlags();

  return (
    <List component="nav" className={classes.root}>
      <SideBarItem view="profile" text="Profile">
        <ProfileIcon />
      </SideBarItem>
      <SideBarItem view="company" text="Company">
        <CompanyIcon />
      </SideBarItem>
      <ExpandibleSection
        route="/billing"
        name="Billing"
        list={[
          { view: 'accounts', text: 'My Accounts' },
          { view: 'invoices', text: 'Invoices' },
          { view: 'credits', text: 'Credits' },
          { view: 'transactions', text: 'Transactions' }
        ]}
      />
      {swagupMembershipPlans && (
        <ExpandibleSection route="/membership" name="Membership" list={[{ view: 'your-membership', text: 'Manage' }]} />
      )}
    </List>
  );
};

export default SideBar;
