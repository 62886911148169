import React, { useState } from 'react';
import { withStyles, Grid, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { subscribe } from '../../actions';
import styles from './styles/Subscribe';
import { emailRequired, getSchema } from './Forms/commonValidations';
// email with corporate validation
// (?!gmail\.com)(?!yahoo\.com)(?!hotmail\.com)(?!aol\.com)(?!outlook\.com)(?!icloud\.com)(?!inbox\.com)(?!mail\.com)
// Reusable with any other form

const SubscribeMessage = withStyles(styles)(({ classes, error, onTryAgain }) => (
  <div>
    <p className={classes.subscribeTitle}>{error ? 'Something went wrong' : 'Thank you for signing up!'} </p>
    {error ? (
      <p className={classes.regularText}>
        There was a problem with your subscription. <br /> Please try{' '}
        <Button type="submit" onClick={onTryAgain} className={classes.againButton}>
          again
        </Button>
      </p>
    ) : (
      <p className={classes.regularText}>
        We’ll be delivering content to you shortly. <br /> Welcome aboard!
      </p>
    )}
  </div>
));

const resolver = yupResolver(getSchema([], { email: emailRequired }));

const Subscribe = ({ Subscriber }) => {
  const [response, setResponse] = useState();

  const { formState, register, handleSubmit } = useForm({ mode: 'all', resolver });
  const { errors } = formState;

  const submit = data => {
    subscribe(data)
      .then(resp => setResponse(resp))
      .catch(e => setResponse(e));
  };

  return (
    <Grid container justifyContent="center">
      {response ? (
        <SubscribeMessage error={response.result !== 'ok'} onTryAgain={() => setResponse()} />
      ) : (
        <form onSubmit={handleSubmit(submit)}>
          <Subscriber register={register} errors={errors} />
        </form>
      )}
    </Grid>
  );
};

export default Subscribe;
