import React, { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { isEmpty } from 'lodash';

const styles = () => ({
  stepNumberIcon: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: ({ reached }) => (reached ? '#3577D4' : '#D9D9D9')
  },
  stepNumberIconIcon: {
    width: '12px',
    height: '12px',
    color: '#FFFFFF',
    marginTop: 4
  },
  stepNumberIconText: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    lineHeight: '12px',
    fontSize: 10
  },
  stepText: {
    color: ({ reached }) => (reached ? '#3577D4' : '#989EA4'),
    fontFamily: 'Inter',
    lineHeight: '16px',
    fontSize: 12
  },
  progressBar: {
    width: '100%',
    borderRadius: '5px'
  },
  progressBarContainer: {
    marginBottom: 4
  },
  stepItem: { paddingLeft: 8 },
  stepsContainer: { paddingTop: 14 }
});

const useStyles = makeStyles(styles);

const StepItem = ({ value, isFirst, reached, isLast }) => {
  const classes = useStyles({ reached });
  return (
    <Grid item style={{ paddingLeft: isFirst ? 25 : undefined, paddingRight: isLast ? 25 : undefined }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Typography variant="body4RegularInter" className={classes.stepText}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SimpleCustomStepper = ({ currentStep, steps = [], exactProgress }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (currentStep > 0 && steps.length > 0) {
      const customProgress = isEmpty(exactProgress)
        ? (100 / steps.length) * currentStep
        : exactProgress[currentStep - 1];
      setProgress(customProgress);
    }
  }, [currentStep, exactProgress, steps]);
  const classes = useStyles();
  return (
    <Box className={classes.customStepper}>
      <Grid container className={classes.progressBarContainer}>
        <LinearProgress
          variant="determinate"
          classes={{
            root: classes.progressBar,
            colorPrimary: classes.linearColorPrimary,
            barColorPrimary: classes.linearBarColorPrimary
          }}
          value={progress}
        />
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between" className={classes.stepsContainer}>
        {steps.map((step, index) => (
          <StepItem
            key={step}
            stepNumber={index + 1}
            value={step}
            isFirst={!index}
            isLast={index === steps.length - 1}
            reached={index < currentStep}
            overcame={index < currentStep - 1 || index === steps.length - 1}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default SimpleCustomStepper;
