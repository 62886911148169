import React, { useState, useEffect } from 'react';
import DropZone from 'react-dropzone';
import { withStyles } from '@material-ui/core';
import ProgressBar from './ProgressBar';
import ImageWithHeader from './ImageWithHeader';
import DragAndDropText from './DragAndDropText';
import { generateUniqueId, s3 } from '../../helpers/utils';
import styles from './styles/ImageUpload';

const uploadStages = { stopped: 0, inProgress: 1, done: 2 };

const uploadInfo = ({ classes, progress }) => stage =>
  ({
    [uploadStages.stopped]: <DragAndDropText classes={classes} />,
    [uploadStages.inProgress]: (
      <div className={classes.progressContainer}>
        <ProgressBar progress={progress} />
      </div>
    ),
    [uploadStages.done]: <p className={classes.normalTextBlue}>Upload Another</p>
  }[stage]);

const UploadStage = ({ stage, headerText, file, fileType, classes, progress }) => {
  const textInHeader = stage === uploadStages.done ? 'Upload Completed' : headerText;
  const fileToShow =
    !fileType || /image\/(png|jpeg|bmp|svg|ico|gif|xml)/.test(fileType) ? file : '/images/custom/assets/no-preview.png';
  return (
    <>
      <ImageWithHeader headerText={textInHeader} file={fileToShow} />
      {uploadInfo({ classes, progress })(stage)}
    </>
  );
};

const ImageUpload = ({
  fileBefore,
  classes,
  onChange,
  onProgress,
  label,
  accept = 'image/*',
  minSize = 10,
  maxSize = 10 * 1024 * 1024,
  reject = ''
}) => {
  const [file, setFile] = useState(undefined);
  const [fileType, setFileType] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [stage, setStage] = useState(uploadStages.stopped);

  useEffect(() => {
    setFile(fileBefore);
  }, [fileBefore]);

  const uploadOk = data => {
    setFile(data.Location);
    setStage(uploadStages.done);
    setProgress(0);
    onChange(data.Location);
  };

  const uploadError = () => {
    setProgress(0);
    setStage(uploadStages.stopped);
  };

  const uploadDone = (err, data) => {
    if (onProgress) onProgress(false);

    if (err) uploadError(err);
    else uploadOk(data);
  };

  const uploadInProgress = ({ loaded, total }) => {
    if (total > 0) setProgress((loaded * 100) / total);
  };

  const onDrop = files => {
    const [droppedFile] = files;
    const canUpload = droppedFile && reject.indexOf(droppedFile.type) === -1;

    if (canUpload) {
      setFileType(droppedFile.type);
      setStage(uploadStages.inProgress);

      if (onProgress) onProgress(true);

      const uniqueId = generateUniqueId();

      s3.upload({
        Key: `${uniqueId}-logo-${droppedFile.name.replace(/\s+/g, '-')}`,
        Body: droppedFile,
        ContentType: droppedFile.type
      })
        .on('httpUploadProgress', uploadInProgress)
        .send(uploadDone);
    }
  };

  return (
    <div className={classes.container} style={{ align: 'center', cursor: 'pointer' }}>
      <DropZone multiple={false} onDrop={onDrop} accept={accept} minSize={minSize} maxSize={maxSize}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: classes.offOutline })}>
            <input {...getInputProps()} />
            <UploadStage
              stage={stage}
              headerText={label}
              file={file}
              fileType={fileType}
              classes={classes}
              progress={progress}
            />
          </div>
        )}
      </DropZone>
    </div>
  );
};

export default withStyles(styles)(ImageUpload);
