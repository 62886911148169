import * as React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button, makeStyles, Menu, Fade } from '@material-ui/core';
import { COLOR_CONSTANT } from '../../utils/constants';

const useStyles = makeStyles({
  button: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 'fit-content',
    height: 24,
    padding: '2px 8px',
    color: ({ open }) => (open ? '#0b1829' : '#787b80'),
    '&:hover': { color: '#0b1829' }
  },
  buttonIcon: {
    color: '#125CFF',
    fontSize: 24,
    transform: ({ open }) => (open ? 'rotate(-180deg)' : '')
  },
  buttonText: {
    color: 'inherit',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '8px',
    marginRight: 8
  },
  buttonSelectedText: {
    color: '#0B1829'
  },
  menu: {
    marginTop: 10,
    borderRadius: 8,
    boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.05)',
    padding: ({ blank }) => (blank ? '12px 0' : 20),
    background: '#FFFFFF',
    minWidth: 196
  }
});

const Dropdown = ({ anchorEl, setAnchorEl, label, blank, selected, onClose, children, MenuListProps, hidden }) => {
  const open = Boolean(anchorEl);
  const classes = useStyles({ open, blank });
  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) onClose();
  };

  return (
    <>
      <Button
        aria-controls={label}
        aria-haspopup="true"
        variant="standard"
        onClick={e => setAnchorEl(e.currentTarget)}
        // className={classes.button}
        style={{ display: hidden ? 'none' : undefined, background: COLOR_CONSTANT.NEUTRAL_GREY, height: '40px' }}
      >
        <span className={classes.buttonText}>
          {selected ? (
            <>
              {label}: <span className={classes.buttonSelectedText}>{selected}</span>
            </>
          ) : (
            label
          )}
        </span>
        <KeyboardArrowDownIcon className={classes.buttonIcon} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id={label}
        keepMounted
        tabIndex="-1"
        open={open}
        getContentAnchorEl={null}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        MenuListProps={{ style: { padding: 0 }, ...MenuListProps }}
        classes={{ paper: classes.menu }}
      >
        {children}
      </Menu>
    </>
  );
};

export default Dropdown;
