const apiPaths = {
  account: accountId => `${apiPaths.accounts}${accountId}/`,
  accountOrders: '/account-orders/',
  accountPaymentProfile: (accountId, profileId) => `${apiPaths.accountPaymentProfiles(accountId)}${profileId}/`,
  accountPaymentProfiles: accountId => `${apiPaths.accounts}${accountId}${apiPaths.paymentProfiles}`,
  accountProduct: id => `${apiPaths.accountProducts}${id}/`,
  accountProductComments: id => `${apiPaths.accountProduct(id)}comments/`,
  accountProductPrices: '/account-product-prices/',
  accountProducts: '/account-products/',
  accounts: '/accounts/',
  addresses: '/addresses/',
  authorizeCreditCards: '/authorize-credit-cards/',
  availableFilters: '/available-filters/',
  bulkPricing: '/bulk-pricing/',
  categories: '/categories/',
  collections: '/collections/',
  payment: '/charge-credit-cards/',
  contactForms: '/contact-forms/',
  contactFormSubjects: '/contact-form-subjects/',
  contactGroups: '/contact-groups/',
  contact: id => `${apiPaths.contacts}${id}/`,
  contacts: '/directory/',
  countries: '/countries/',
  credits: '/credits/',
  creditsSummaries: '/credits/summaries/',
  defaultBulk: '/items/?default_bulk_only=true',
  defaultSwag: '/pack-templates/?default_pack_only=true',
  deliveryMethods: '/delivery-methods/',
  design: id => `${apiPaths.designs}${id}/`,
  designOrders: id => `${apiPaths.design(id)}orders/`,
  designs: '/designs/',
  employees: '/employees/',
  employeeOrder: id => `${apiPaths.employeeOrders}${id}/`,
  employeeOrders: '/employee-orders/',
  filters: page => `/filters/?page=${page}`,
  bulkEmployeeOrders: '/bulk-employee-orders/',
  individualPackPrices: '/shipping-prices/individual-packs/',
  integrations: '/integrations/integrations',
  integration: id => `/integrations/integrations/${id}`,
  integrationStores: id => `/integrations/stores/?access_token=${id}`,
  integrationTriggers: id => `/integrations/triggers/?access_token=${id}`,
  integrationEmployeeTypes: '/integrations/customer-employment-types/',
  integrationTrigger: (id, token) => `/integrations/triggers/${id}/?access_token=${token}`,
  integrationActionOrders: id => `/integrations/action-requests/?access_token=${id}`,
  integrationActionOrder: (id, token) =>
    id
      ? `/integrations/action-requests/${id}/?access_token=${token}`
      : `/integrations/action-requests/?access_token=${token}`,
  integrationActions: id => `/integrations/actions/?access_token=${id}`,
  integrationAction: (id, token) => `/integrations/actions/${id}/?access_token=${token}`,
  integrationsGetMergeLink: '/integrations/get-merge-link/',
  integrationOrders: '/integrations/orders/',
  integrationProduct: '/integrations/push-product/',
  integrationProducts: '/integrations/products/',
  integrationStores: '/integrations/stores/',
  integrationVariants: '/integrations/variants/',
  inventory: '/stock/',
  inventoryHistory: '/inventory-histories/',
  invoice: id => `${apiPaths.invoices}${id}/`,
  invoicePayments: id => `${apiPaths.invoice(id)}payments/`,
  invoices: '/invoices/',
  metadata: '/public-metadata/',
  notifications: '/notifications/', // removed notifications components, if they are used this commit can be undone
  opportunities: '/opportunities/',
  opportunity: id => `${apiPaths.opportunities}${id}/`,
  opportunityProofs: id => `${apiPaths.opportunity(id)}proofs/`,
  validateOpportunity: `/validate-opportunities/`,
  orders: '/orders/',
  packMultiplePrice: '/pack-multiple-price/',
  packTemplates: '/pack-templates/',
  partners: '/partners/',
  paymentProfiles: '/payment-profiles/',
  process: id => `${apiPaths.processes}${id}/`,
  processes: '/processes/',
  processOutput: (processId, outputId) => `${apiPaths.processOutputs(processId)}${outputId}/`,
  processOutputs: id => `${apiPaths.process(id)}outputs/`,
  profile: id => `${apiPaths.profiles}${id}/`,
  profiles: '/profiles/',
  proof: id => `${apiPaths.proofs}${id}/`,
  proofComments: id => `${apiPaths.proof(id)}comments/`,
  proofPrices: '/proof-prices/',
  proofs: '/proofs/',
  proratedAmount: '/prorated-amount',
  samplePacks: '/sample-packs/',
  samplePacksEmails: '/sample-packs/emails/',
  samplePacksInventory: '/sample-pack-inventory/',
  saveContacts: '/save-contacts/',
  shipmentGroup: (oppId, groupId) => `${apiPaths.shipmentGroups(oppId)}${groupId}/`,
  shipmentGroups: oppId => `${apiPaths.opportunity(oppId)}shipments/`,
  shippingPrices: '/shipping-prices/',
  sizes: '/sizes/',
  storageCategories: '/storage-categories/',
  storagePrices: '/storage-prices/',
  storagePricesWithStorage: '/storage-prices?storage=True',
  subscribers: '/subscribers/',
  swagContacts: '/swag-contacts/',
  transactions: '/transactions/',
  updateContactsSizes: '/output-contact-sizes/',
  warehouseProofs: oppId => `${apiPaths.opportunity(oppId)}warehouse-proofs/`,
  cancelSubscription: '/cancel-subscription/',
  memberships: '/membership-tiers/',
  rewards: '/reward-points/',
  updateSubscription: '/update-subscription/',
  shipmentSources: '/shipment-sources',
  imageBackgroundRemoval: '/background-image-removal',
  payStagnantInventories: '/pay-stagnant-inventories',
  discardInventories: '/discard-inventories',
  downloadOrderQuote: id => `/opportunity/${id}/get-opportunity-invoice`,
  storageTaxes: '/storage-taxes/'
};

export default apiPaths;
