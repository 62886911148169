import { useQueryClient } from 'react-query';
import apiPaths from '../../../../../helpers/apiPaths';

const useStorageInfo = () => {
  const queryClient = useQueryClient();
  const storage = queryClient.getQueryData(apiPaths.storagePrices);
  const categories = queryClient.getQueryData(apiPaths.storageCategories);

  if (storage === undefined) throw new Error('You should fetch storage prices before using the `useStorageInfo` hook');

  if (categories === undefined)
    throw new Error('You should fetch storage categories before using the `useStorageInfo` hook');

  const storagePrices = storage.results.reduce((acc, p) => ({ ...acc, [p.storage_category]: Number(p.price) }), {});
  const storagePricesBeforeDiscount = storage.results.reduce(
    (acc, p) => ({ ...acc, [p.storage_category]: Number(p.price_without_discount) }),
    {}
  );
  const storageCategoryNames = categories.reduce((names, c) => ({ ...names, [c.id]: c.name }), {});
  return { storagePrices, storagePricesBeforeDiscount, storageCategoryNames };
};

export default useStorageInfo;
