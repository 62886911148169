import * as React from 'react';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Close } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StylessButton } from '../buttons';
import useIntegrations from '../../hooks/useIntegrations';
import { getIntegrationImageSrcSet } from './common';
import Img from '../shared/Img';

const styles = theme => ({
  integrationImageWrapper: {
    height: 72,
    width: 72,
    position: 'relative'
  },
  integrationImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  integrationItem: {
    padding: '20px 24px  !important',
    border: '1px solid #EBEDF0 !important',
    width: '100% !important',
    textAlign: 'left !important',
    borderRadius: 10,
    background: ({ enabled }) => (enabled ? undefined : '#EBEDF0 !important')
  },
  tabPanel: {
    paddingTop: 16,
    width: '100%'
  },
  integrationName: { marginBottom: 8, textAlign: 'left' },
  integrationDescription: { marginBottom: 8, textAlign: 'left' },
  integrationCTAPanel: { textAlign: 'left', paddingTop: 8 },
  integrationCTA: {
    height: 30,
    padding: '0px 12px',
    borderWidth: 1,
    fontFamily: 'Inter',
    fontSize: 12,
    lineHeight: '12px',
    fontWeight: 400,
    background: ({ enabled }) => (enabled ? undefined : '#EBEDF0 !important')
  },
  cancel: {
    width: 24,
    height: 24,
    fontSize: 16,
    '& .MuiSvgIcon-root': {
      fontSize: 16
    }
  }
});

const useStyles = makeStyles(styles);

const IntegrationItem = ({ item, onDisconnect, onConnect, isConnected }) => {
  const [currentStep, setCurrentStep] = useState();
  const { allowedToDisconnect } = useFlags();
  const classes = useStyles({ enabled: item.active });
  return (
    <Grid item xs={12}>
      <StylessButton disabled={!item.active} className={classes.integrationItem}>
        <Grid container spacing={6} alignItems="center">
          <Grid item>
            <Box className={classes.integrationImageWrapper}>
              <Img
                src={item.image_url}
                srcSet={getIntegrationImageSrcSet(item.rutter_id)}
                alt={item.name}
                className={classes.integrationImage}
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Typography variant="body3MediumInter" className={classes.integrationName}>
              {item.name}
            </Typography>
            <Typography variant="body4RegularInter" className={classes.integrationDescription}>
              {item.description}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.integrationCTAPanel}>
              <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
                <Grid container xs alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Button
                      size="small"
                      variant={isConnected ? 'primary' : 'secondary'}
                      onClick={isConnected ? () => setCurrentStep(2) : () => !item.url && onConnect(item.rutter_id)}
                      disabled={!item.active || !allowedToDisconnect}
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.integrationCTA}
                    >
                      {isConnected ? 'Disconnect' : 'Connect'}
                    </Button>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body4RegularInter" style={{ textAlign: 'center' }}>
                      Are you sure?
                    </Typography>
                  </Grid>
                  <Grid item style={{ paddingRight: 12 }}>
                    <Button
                      size="small"
                      variant="primary"
                      className={classes.integrationCTA}
                      onClick={() => {
                        onDisconnect(item.rutter_id);
                        setCurrentStep(1);
                      }}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid item>
                    <IconButton className={classes.cancel} onClick={() => setCurrentStep(1)}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </SwipeableViews>
            </div>
          </Grid>
        </Grid>
      </StylessButton>
    </Grid>
  );
};

const NativeIntegrationsSettings = () => {
  const { integrations, onOpen, onExit, getConnectedPlatform } = useIntegrations();

  const onOpenRutter = platform => {
    onOpen(platform);
  };

  const onDisconnect = platform => {
    onExit(platform);
  };

  const classes = useStyles();
  return (
    <Box className={classes.tabPanel}>
      <Grid container spacing={4}>
        {integrations.map(item => (
          <IntegrationItem
            key={item.key}
            item={item}
            onConnect={onOpenRutter}
            onDisconnect={onDisconnect}
            isConnected={!!getConnectedPlatform(item.rutter_id)}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default NativeIntegrationsSettings;
