import { ellipsisStyles, scrollBar, scrollBarAutohide } from '../../../shared/styles/commonStyles';
import { isLightColor } from '../../../shared/styles/utils';

const arrowButtonCommon = {
  position: 'absolute',
  top: 'calc(50% + 25px)', // plus marginBottom of item name
  cursor: 'pointer',
  fontSize: 18,
  marginTop: -22,
  transition: '0.6s ease',
  fontWeight: 'bold',
  userSelect: 'none',
  width: 36,
  height: 36,
  color: '#3577d4 !important',
  backgroundColor: '#ffffff',
  padding: 0,
  borderRadius: 10,
  boxShadow: '0 8px 24px 0 rgba(27, 28, 31, 0.05)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none'
};
const cardContainer = {
  height: 109,
  width: 330,
  padding: '16px 8px 14px',
  borderRadius: 12,
  backgroundColor: '#ffffff',
  marginBottom: 17
};

const styles = theme => ({
  container: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingTop: 62,
      height: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 18
    }
  },
  changesSection: {
    ...scrollBar
  },
  chevronButton: {
    padding: '2px',
    position: 'absolute',
    borderRadius: 5,
    boxShadow: '0 8px 24px 0 rgba(27, 28, 31, 0.05)',
    backgroundColor: '#fff',
    color: '#3577d4',
    top: '47%'
  },
  center: {
    paddingTop: '0 40px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      minWidth: '100%'
    }
  },
  detailsSectionContainer: {
    padding: '14px 22px'
  },
  detailsSectionContainerLeftNavOpen: {
    padding: '14px 22px 14px 0px'
  },
  detailsAndImageContainer: {
    [theme.breakpoints.up(1921)]: {
      width: '66.66%',
      maxWidth: '66.66%',
      minWidth: '66.66%'
    }
  },
  detailsContainer: { paddingRight: 20 },
  productName: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    width: '100%',
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#0b1829'
  },
  backButton: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 64
  },
  backIcon: {
    marginRight: 10,
    fontSize: 18,
    color: '#434d5c',
    cursor: 'pointer'
  },
  backText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    color: '#8d9299'
  },
  subDetails: {
    margin: '33px 0 24px',
    display: 'block'
  },
  subDetailsText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: 'normal',
    fontWeight: 500,
    marginBottom: 11,
    color: '#787b80'
  },
  subDetailsValue: {
    fontFamily: 'Gilroy-SemiBold',
    lineHeight: 0.67,
    color: '#0f2440',
    marginTop: 24,
    fontSize: 24
  },
  subDetailsLink: {
    marginTop: 14,
    color: '#3577d4'
  },
  subDetailsBigger: {
    marginTop: 24,
    fontSize: 24
  },
  subDetailsMedium: {
    marginTop: 0,
    fontSize: 16
  },
  subDetailsDownloadContainer: {
    width: 194,
    borderRadius: 10,
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    padding: '0 22px',
    marginTop: 24
  },
  subDetailsDownloadLink: {
    margin: '24px 0',
    '& img': { marginTop: -2 }
  },
  carouselItemImg: {
    borderRadius: 15,
    backgroundColor: '#ffffff',
    border: 'solid 1px #ebeef2'
  },
  carouselItemName: {
    ...ellipsisStyles,
    width: '100%',
    textAlign: 'center',
    marginBottom: 14,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#434c5f'
  },
  prevButtonClass: {
    ...arrowButtonCommon,
    left: -18
  },
  nextButtonClass: {
    ...arrowButtonCommon,
    right: -18
  },
  requestChangesButton: {
    marginTop: 40,
    '& span': {
      fontFamily: 'Gilroy-Bold',
      fontSize: 14,
      lineHeight: 0.71,
      color: '#3577d4'
    }
  },
  commentsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up(1921)]: {
      maxWidth: '33.33%',
      flexBasis: '33.33%'
    }
  },
  decorationButton: {
    fontFamily: 'Gilroy-Medium',
    color: '#000000',
    fontSize: 16,
    paddingLeft: 0,
    paddingRight: 0,
    height: 'fit-content',
    display: 'inline-flex',
    justifyContent: 'space-between'
  },
  decorationCTA: {
    fontFamily: 'Gilroy',
    color: '#0b1829',
    fontWeight: 'bold',
    fontSize: 24
  },
  disabledCtaText: {
    color: '#a8abb0'
  },
  drawer: {
    width: '100%',
    maxWidth: 400,
    position: 'relative',
    zIndex: 0,
    height: 'calc(100vh - 100px)',
    display: 'flex',
    padding: '0px, 3px',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down(1921)]: {
      position: 'fixed',
      width: 400,
      top: 100,
      right: 0,
      flexShrink: 0
    },
    [theme.breakpoints.down(1440)]: {
      width: 370,
      right: 2
    }
  },
  commentAction: {
    width: '100%',
    padding: '15px 20px 25px 20px',
    display: 'flex',
    alignItems: 'center'
  },
  newComment: {
    marginLeft: 16,
    '& *': {
      fontFamily: 'Gilroy-Medium',
      fontSize: 12,
      lineHeight: 0.83,
      color: '#434d5c'
    },
    '& .MuiOutlinedInput-root': {
      height: 42,
      borderRadius: 20,
      backgroundColor: '#f7f7f7',
      '& > input': {
        background: 'transparent'
      }
    },
    '& input:-webkit-autofill': { '-webkit-transition': 'color 9999s ease-out, background-color 9999s ease-out' },
    '& fieldset': { border: 'solid 1px #ebeef2 !important' }
  },
  newImageComment: {
    height: 40,
    width: 40,
    border: 'solid 1px #ebeef2',
    backgroundColor: '#f7f7f7',
    color: '#9098a3'
  },
  commentHeader: {
    width: '100%',
    borderBottom: '1px solid #ebeef2',
    padding: 20
  },
  commentHeaderTitle: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    color: '#0f2440'
  },
  invalidTextContainer: {
    position: 'absolute',
    top: 60,
    left: 90
  },
  downloadIcon: {
    width: 16,
    height: 16,
    marginLeft: 3
  },
  headerContainer: {
    width: '100%'
  },
  downloadLink: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 0.86,
    textAlign: 'left',
    color: '#3577d4'
  },
  subHeaderContainer: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
      marginRight: 10,
      justifyContent: 'space-between'
    }
  },
  subHeader: {
    width: 180,
    height: 41,
    marginTop: 'auto',
    marginBottom: 32,
    '& svg': { height: 18, width: 18 }
  },
  lastSubHeader: {
    marginLeft: 18,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  breadcrumbContainer: {
    marginTop: 24,
    '& nav': { lineHeight: '14px' },
    '& li.MuiBreadcrumbs-separator': { fontSize: 10, color: '#3577d4', marginLeft: 5, marginRight: 5 },
    '& h6': {
      fontSize: 12,
      fontFamily: 'Gilroy-Medium',
      fontWeight: 500,
      color: '#3577d4',
      lineHeight: 'normal'
    },
    '& li:last-child': { '& h6': { color: '#787b80' } }
  },
  priceXPack: {
    fontWeight: 600,
    color: '#0b1829'
  },
  disabledLink: {
    color: '#787b80'
  },
  containerGrey: {
    ...cardContainer,
    [theme.breakpoints.down('xs')]: { padding: 16 }
  },
  containerBlue: {
    ...cardContainer,
    border: 'solid 2px #3577d4',
    [theme.breakpoints.down('xs')]: { padding: 16 }
  },
  infoAddress: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 1,
    color: '#868a8f',
    marginTop: 8
  },
  colorCircle: ({ color }) => ({
    borderRadius: 6,
    minWidth: 12,
    height: 12,
    marginRight: 9,
    backgroundColor: color,
    border: isLightColor(color) ? '1px solid #787B80' : undefined
  }),
  packProductName: {
    ...ellipsisStyles,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#0b1829',
    marginBottom: 8
  },
  priceXItem: {
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'left',
    color: '#787b80',
    width: 'max-content'
  },
  deleteIcon: {
    height: 20,
    width: 20,
    color: '#787B80'
  },
  productDetails: {
    paddingTop: 24,
    padding: 32,
    borderRadius: 12,
    backgroundColor: '#ffffff'
  },
  topSection: {
    marginBottom: 12
  },
  selectedProductName: {
    ...ellipsisStyles,
    fontSize: 24,
    fontWeight: 600,
    color: '#0b1829',
    margin: 0
  },
  container384x284: {
    minHeight: 284,
    minWidth: 384,
    maxWidth: '100%',
    maxHeight: '100%',
    textAlign: 'center',
    position: 'relative',
    padding: 5,
    border: 'solid 1px #ebedf0',
    borderRadius: 15,
    backgroundColor: '#ffffff',
    marginBottom: 17,
    '& img': {
      objectFit: 'contain',
      height: '100%'
    }
  },
  thumbnailImage: {
    height: 66,
    width: 66,
    '& img': {
      objectFit: 'cover',
      height: 66,
      width: 66
    }
  },
  quantityPerPack: {
    fontSize: 14,
    color: '#787b80',
    lineHeight: 'normal'
  },
  productList: {
    ...scrollBarAutohide,
    width: 345,
    height: 510
  },
  downloadTemplateText: {
    textAlign: 'right',
    color: '#3577d4',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500
  },
  counter: {
    '& input': { fontSize: 20 },
    '& > .MuiTextField-root': { width: 100 }
  },
  iconSearchContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    width: 24,
    height: 24,
    zIndex: 99,
    '& button': { padding: 0 }
  },
  searchButton: {
    color: '#0b1829',
    fontFamily: 'Gilroy',
    fontSize: 24
  },
  modalDialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiPaper-root': {
      height: 600,
      width: 800,
      maxHeight: 600,
      maxWidth: 800
    }
  },
  dialogTitle: {
    paddingBottom: 0,
    '& h2': {
      fontFamily: 'Gilroy-Medium',
      height: 32,
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: '#434d5c',
      textAlign: 'center'
    }
  },
  modalDialogContent: {
    textAlign: 'center',
    overflow: 'hidden',
    paddingTop: 0,
    paddingBottom: 16,
    '&  img': {
      objectFit: 'contain !important'
    }
  },
  containerChip: { padding: '8px 0px' },
  skeleton: {
    width: 100,
    height: 16,
    display: 'inline-flex'
  },
  unsavedChangeDialog: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'visible',
    alignItems: 'center',
    '& .MuiDialog-paperWidthSm': { overflow: 'visible', maxWidth: 912 },
    '& .MuiDialogContent-root': { width: '100%', padding: '0px 120px' },
    '& .MuiDialogActions-root': { width: '100%', padding: '0px 120px' }
  },
  modalNote: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    marginTop: 32,
    textAlign: 'left',
    fontWeight: 500,
    color: '#0b1829'
  },
  unsavedChangeTitle: {
    fontFamily: 'Gilroy',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 'normal',
    textAlign: 'center',
    color: '#0b1829'
  },
  unsavedChangeInfo: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#787b80',
    margin: '16px 0px 25px 0px'
  },
  unsavedChangeProduct: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 'normal',
    textAlign: 'center',
    color: '#0b1829',
    marginBottom: 16
  },
  discardChanges: {
    height: 48
  },
  sendChanges: {
    height: 48
  },
  decorationInfo: {
    width: 'auto !important',
    boxShadow: 'none',
    borderRadius: '0px !important',
    backgroundColor: 'transparent  !important',
    borderBottom: 'solid 1px #E8E9EB  !important',
    '&.Mui-expanded': { width: 'auto', boxShadow: 'none' }
  },
  changesTitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.63,
    textAlign: 'left',
    color: '#000000'
  },
  changesDescription: {
    height: 'auto !important',
    fontSize: 14,
    fontWeight: 500,
    color: '#787b80',
    whiteSpace: 'break-spaces'
  },
  changesDescriptionStrong: {
    fontSize: 14,
    color: '#000000',
    fontWeight: 'normal',
    whiteSpace: 'break-spaces'
  },
  itemsApproved: {
    fontFamily: ' Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.63,
    color: '#000000',
    marginBottom: 9
  },
  themeColorText: {
    fontFamily: 'Gilroy',
    fontSize: 16,
    color: '#000000'
  },
  logoContainer: {
    height: 24,
    maxWidth: 124
  },
  logo: {
    height: '100%',
    objectFit: 'contain',
    marginBottom: 2
  },
  colorIcon: {
    backgroundColor: '#2b63be',
    width: 12,
    height: 12,
    borderRadius: 6,
    border: ({ color }) => (isLightColor(color) ? '1px solid #787B80' : undefined)
  },
  newWindow: {
    paddingBottom: '2px !important'
  },
  rocketImgContainer: {
    width: 100,
    position: 'relative'
  },
  rocketImage: {
    width: '100%',
    position: 'absolute',
    top: -50
  },
  divider: {
    height: 1,
    backgroundColor: '#e8e9eb',
    width: '100%',
    marginBottom: 28
  },
  containerButtons: {
    width: 330,
    marginBottom: 12
  },
  updateButton: {
    height: 36,
    '& span': { fontSize: 12 }
  },
  discardButton: {
    height: 36,
    paddingLeft: 0,
    paddingRight: 0,
    '& span': { fontSize: 12 }
  },
  alertContainer: {
    width: 330,
    marginBottom: 16,
    '& h6': {
      fontSize: 14
    }
  },
  alert: {
    '& .MuiAlert-icon,& .MuiAlert-message': { padding: 0 }
  },
  alertStyles: {
    fontSize: 14,
    color: '#3577d4',
    paddingLeft: 0
  }
});

export default styles;
