const commonFont = {
  fontFamily: 'Gilroy',
  fontSize: 16,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  lineHeight: 1.36
};

const styles = () => ({
  root: {
    flexGrow: 1
  },
  headerText: {
    ...commonFont,
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.58,
    color: '#434c5f',
    opacity: 0.54,
    paddingTop: '24px',
    paddingBottom: '16px'
  },
  boldText: {
    ...commonFont,
    color: '#0B1829',
    fontWeight: 600
  },
  normalText: {
    ...commonFont,
    color: '#0B1829',
    paddingBottom: '24px',
    fontWeight: 600
  },
  normalTextBlue: {
    ...commonFont,
    color: '#3577d4',
    paddingBottom: '42px',
    cursor: 'pointer',
    fontWeight: 600
  },
  spanText: {
    ...commonFont,
    color: '#3577d4',
    height: '38px',
    cursor: 'pointer',
    fontWeight: 600
  },
  imgContainer: {
    maxWidth: '100px',
    height: '100px',
    margin: 'auto'
  },
  container: {
    borderRadius: 15,
    border: 'dashed 2px #CED1D6',
    backgroundColor: '#fafafa'
  },
  progressContainer: {
    paddingLeft: '43px',
    paddingRight: '43px',
    paddingBottom: '16px'
  },
  slimProgressContainer: {
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  offOutline: {
    outline: 'none'
  }
});

export default styles;
