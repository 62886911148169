export default theme => ({
  root: {
    minHeight: 150,
    height: '100%',
    overflowY: 'auto'
  },
  item: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3)
  },
  infoContainer: {
    margin: 0
  },
  name: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    lineHeight: 'normal',
    color: '#0B1829',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  details: {
    fontSize: 10,
    color: '#787B80'
  },
  imageContainer: {
    minWidth: 65
  },
  image: {
    width: 55,
    height: 55,
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.neutral.grey90}`,
    '& img': {
      objectFit: 'contain'
    }
  },
  quantitySelect: {
    '& p': {
      fontSize: 14,
      fontWeight: 400
    }
  }
});
