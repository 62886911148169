import React from 'react';
import AccountCard from './AccountCard';
import { deletePaymentProfile } from '../../actions';
import { EmptyState } from '../shared';
import { paymentMethods } from '../../apis/constants';

const AccountsContainer = ({ company, paymentProfiles, selectedMethod, classes }) => {
  const profiles = paymentProfiles.filter(p => p.payment_method === selectedMethod);

  if (profiles.length === 0) {
    const [image, title, text] =
      selectedMethod === paymentMethods.creditCard
        ? [
            '/images/account/empty-credit-card.png',
            'No saved Credit cards',
            'Add a Credit Card to make Swag \npayments easy!'
          ]
        : [
            '/images/account/empty-ach.png',
            'No saved ACH accounts',
            'Add an ACH account to make Swag \npayments easy!'
          ];

    return (
      <EmptyState
        title={title}
        image={{
          path: image,
          alt: title,
          text
        }}
        marginTop={0}
      />
    );
  }

  const isLastProfile = paymentProfiles.length === 1;

  return profiles.map(p => (
    <AccountCard
      key={p.customer_payment_profile_id}
      classes={classes}
      account={p}
      company={company}
      deletePaymentProfile={isLastProfile || !p.default ? deletePaymentProfile : undefined}
    />
  ));
};

export default AccountsContainer;
