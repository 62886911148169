import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles/OnboardingExperienceLayout';
import { GoBack } from '../../shared';

const OnboardingExperienceLayout = ({
  classes,
  renderTitle,
  renderControls,
  renderList,
  renderSelectedList,
  renderFooter
}) => {
  return (
    <div className={classes.container}>
      <Grid container className={classes.innerContainer}>
        <Grid container className={classes.centerContainer}>
          <div>
            <GoBack />
          </div>
          <div>
            <Grid container className={classes.titleContainer}>
              {renderTitle}
            </Grid>
          </div>
          {renderControls && <div className={classes.controlsContainer}>{renderControls}</div>}
          <div className={classes.tableContainer}>{renderList}</div>
        </Grid>
        <div className={classes.rightContainer}>{renderSelectedList}</div>
      </Grid>
      <Grid container justifyContent="center" className={classes.bottomContainer}>
        <Grid container className={classes.innerContainer}>
          {renderFooter}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(OnboardingExperienceLayout);
