import * as React from 'react';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { useOrder } from '../OrderContext';
import Drawer from '../common/Drawer';
import { getShipmentGroupsInfo, imageSrcSet, moneyStr } from '../../../../../helpers/utils';
import { greyRoundBorder } from '../../../../shared/styles/commonStyles';

const useStyles = makeStyles({
  drawer: {
    maxWidth: 594,
    padding: '32px 45px'
  },
  title: {
    marginBottom: 12,
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 24,
    color: '#0b1829'
  },
  shippingMethodName: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 600,
    color: '#0b1829'
  },
  infoType: {
    marginTop: 12,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#787b80'
  },
  infoValue: {
    marginTop: 8,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    color: '#0b1829'
  },
  totalCost: {
    marginTop: 8,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 600,
    color: '#0b1829'
  },
  divider: {
    marginLeft: 72,
    width: 'calc(100% - 72px)',
    backgroundColor: '#f0f2f5'
  }
});

const ShippingPrice = ({ classes, image, name, recipients, price, imageStyles }) => (
  <Grid container style={{ marginTop: 24, marginBottom: 15 }} role="row">
    <Grid container>
      <Grid item>
        <img srcSet={imageSrcSet(image)} alt={name} style={{ ...greyRoundBorder, ...imageStyles }} />
      </Grid>
      <Grid item container xs style={{ paddingLeft: 24 }}>
        <Grid container>
          <p className={classes.shippingMethodName}>{name}</p>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} container direction="column">
            <p className={classes.infoType}>Number of Recipients</p>
            <p className={classes.infoValue}>{recipients}</p>
          </Grid>
          <Grid item xs={6} container direction="column" style={{ textAlign: 'right' }}>
            <p className={classes.infoType}>Total Shipping Cost</p>
            <p className={classes.totalCost}>{moneyStr(price)}</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const ShipmentBreakdown = ({ open, onClose }) => {
  const classes = useStyles();
  const { shipmentGroups } = useOrder();

  const { international, domestic } = getShipmentGroupsInfo(shipmentGroups);

  return (
    <Drawer open={open} onClose={onClose} classes={{ paper: classes.drawer }}>
      <Grid container>
        <h2 className={classes.title}>Shipping Breakdown</h2>
        <ShippingPrice
          classes={classes}
          name="International"
          recipients={international.recipients}
          price={international.price}
          image="/images/orders/world.png"
          imageStyles={{ padding: 12 }}
        />
        <Divider className={classes.divider} />
        <ShippingPrice
          classes={classes}
          name="Domestic"
          recipients={domestic.recipients}
          price={domestic.price}
          image="/images/orders/usa.png"
          imageStyles={{ padding: '12px 9px' }}
        />
      </Grid>
    </Drawer>
  );
};

export default ShipmentBreakdown;
