import React from 'react';
import { Grid, Button, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CenteredGrid } from '../../../shared';

import styles from './LetsGetStarted.styles';

const LetsGetStarted = ({ classes }) => (
  <CenteredGrid container justifyContent="center" className={classes.container}>
    <Grid item md={7} xs={12} className={classes.textContainer}>
      <span className={classes.miniTitle}>Get started</span>
      <h2 className={classes.title}>Let’s get started with building your pack</h2>
      <p className={classes.text}>And get your packs in no time!</p>
    </Grid>
    <Grid item md={5} xs={12} style={{ paddingTop: 120, position: 'relative' }}>
      <Grid container justifyContent="center">
        <img alt="title" src="/images/custom-pack-landing/getstarted.svg" className={classes.image} />
        <Link to="/custom-swag-packs/catalog">
          <Button variant="contained" color="primary" className={classes.button}>
            <span className={classes.buttonText}>Get Started</span>
          </Button>
        </Link>
      </Grid>
      <div className={classes.imgContainer}>&nbsp;</div>
    </Grid>
  </CenteredGrid>
);

export default withStyles(styles)(LetsGetStarted);
