import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Company from './Company';
import styles from './styles/commonStyles';

const useStyles = makeStyles(styles);

const CompanyContainer = () => {
  const classes = useStyles();
  const { leftBarNavigation } = useFlags();

  return (
    <Grid style={{ maxWidth: '80%' }}>
      {!leftBarNavigation ? (
        <>
          <Typography variant="h3" className={classes.title}>
            Company
          </Typography>
          <hr className={classes.line} />
        </>
      ) : null}
      <Company />
    </Grid>
  );
};

export default CompanyContainer;
