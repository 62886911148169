import React, { useEffect, useState } from 'react';
import { Dialog, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { Close } from '@material-ui/icons';
import styles from '../styles/redeemPagesHome';
import { StylessButton } from '../../buttons';
import { moneyStr, creditColor } from '../../../helpers/utils';
import CreditDrawer from '../components/CreditCardDrawer/CreditDrawer';
import { CustomTooltip } from '../../products/commonProductsElements';
import usePageProductBasedInfo from '../hooks/usePageProductBasedInfo';
import { MIN_CREDIT_BALANCE } from '../redeemCommon';

const useStyles = makeStyles(styles);

const Section = ({ title, children, spacing = 10, infoIcon }) => {
  const classes = useStyles();
  return (
    <Grid container style={{ marginBottom: spacing * 1.6 }}>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="body1MediumInter" className={classes.sectionLabel} style={{ marginBottom: spacing }}>
              {title}
            </Typography>
          </Grid>
          <Grid item>{infoIcon}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

const RadioSelector = ({ options = [], onSelect, selectedValue, variant = 'default', disable }) => {
  const classes = useStyles();
  const customStyle = value => (selectedValue === value ? { background: '#D7E4F6', border: '2px solid #3577D4' } : {});
  return (
    <Grid container spacing={5}>
      {options.map(option => (
        <Grid key={option.title} item xs={6}>
          <Grid
            container
            role="button"
            justifyContent={variant === 'large' ? 'flex-start' : 'center'}
            alignItems="center"
            className={classes.radioSelectorPill}
            style={{
              height: variant === 'large' ? 72 : undefined,
              padding: variant === 'large' ? '0px 20px' : undefined,
              background: disable === option.value ? '#eaeaea' : undefined,
              ...customStyle(option.value)
            }}
            onClick={disable !== option.value ? () => onSelect(option.value) : undefined}
          >
            <Grid item>
              <Typography variant={selectedValue === option.value ? 'body2SemiBoldInter' : 'body2RegularInter'}>
                {option.title}
              </Typography>
              {option.subtitle && (
                <Typography variant="body3RegularInter" color="#4A4F54">
                  {option.subtitle}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const LimitInfoModal = ({ open, onClose, classes }) => (
  <Dialog open={open} onClose={onClose} classes={{ paper: classes.infoModal }}>
    <Grid container>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Close className={classes.closeDrawer} onClick={onClose} />
        </Grid>
      </Grid>
      <div>
        <Typography variant="h6SemiBoldInter" style={{ marginBottom: 8 }}>
          Limit Redemptions to
        </Typography>
        <Typography variant="body2MediumInter" className={classes.limitInfoTitles}>
          Available Inventory
        </Typography>
        <Typography variant="body3RegularInter">
          Once a size or product runs out of inventory, we remove it as an option for recipients to select.
        </Typography>
        <Typography variant="body2MediumInter" className={classes.limitInfoTitles}>
          No Limit
        </Typography>
        <Typography variant="body3RegularInter">
          We&apos;ll continue to collect submissions after you run out of inventory. Shipments will need to be manually
          scheduled after you order more inventory.
        </Typography>
      </div>
    </Grid>
  </Dialog>
);

const shippingMethodOptions = [
  {
    value: false,
    title: 'Standard',
    subtitle: '+3-5 business days'
  },
  {
    value: true,
    title: 'Express 🚀',
    subtitle: '+1-3 business days'
  }
];

const limitOptions = [
  {
    value: false,
    title: 'Available Inventory'
  },
  {
    value: true,
    title: 'No limit'
  }
];

const allowInternationalShippingOptions = [
  {
    value: true,
    title: 'Globally'
  },
  {
    value: false,
    title: 'U.S Only'
  }
];

const ShippingSettings = ({ page, setPage, credit, company, history }) => {
  const [openCreditDrawer, setOpenCreditDrawer] = useState(false);
  const [openLimitInfoModal, setOpenLimitInfoModal] = useState(false);

  const classes = useStyles();

  const { recommendedCredits, canAllowInternational } = usePageProductBasedInfo(page);

  useEffect(() => {
    if (!canAllowInternational && page.allowInternationalShipping) {
      setPage(p => ({ ...p, allowInternationalShipping: false }));
    }
  }, [canAllowInternational, page.allowInternationalShipping, setPage]);
  return (
    <Grid container>
      <Section title="Shipping method">
        <RadioSelector
          options={shippingMethodOptions}
          selectedValue={page.expressShipping}
          variant="large"
          onSelect={value => setPage(p => ({ ...p, expressShipping: value }))}
        />
      </Section>
      <Section
        title="Limit redemptions to"
        infoIcon={
          <StylessButton onClick={() => setOpenLimitInfoModal(true)}>
            <CustomTooltip title="More info...">
              <div className={classes.infoIconContainer}>
                <img src="/images/account/outline-info.svg" alt="Limit" className={classes.infoIcon} />
              </div>
            </CustomTooltip>
          </StylessButton>
        }
      >
        <RadioSelector
          options={limitOptions}
          selectedValue={page.noLimit}
          onSelect={value => setPage(p => ({ ...p, noLimit: value }))}
        />
      </Section>
      <Section title="Allow shipments" spacing={12.5}>
        <RadioSelector
          options={allowInternationalShippingOptions}
          selectedValue={page.allowInternationalShipping}
          onSelect={value => setPage(p => ({ ...p, allowInternationalShipping: value }))}
          disable={!canAllowInternational ? true : undefined}
        />
      </Section>
      <Section title="Credits Overview" spacing={4}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Typography variant="body2MediumInter" className={classes.creditBalanceTitle}>
                  Your credit balance
                </Typography>
              </Grid>
              <Grid item>
                <CustomTooltip title="Credit balance will be used to cover your shipping costs">
                  <div className={classes.infoIconContainerSmall}>
                    <img src="/images/account/outline-info.svg" alt="Limit" className={classes.infoIcon} />
                  </div>
                </CustomTooltip>
              </Grid>
            </Grid>

            <StylessButton onClick={() => setOpenCreditDrawer(true)}>
              <Typography variant="body2RegularInter" className={classes.creditBalanceLink}>
                Reload credit balance
              </Typography>
            </StylessButton>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h4SemiBoldInter"
              className={classes.creditBalance}
              style={{ color: creditColor(credit) }}
            >
              {moneyStr(credit)}
            </Typography>
            <Typography variant="body2RegularInter" className={classes.creditBalanceWarning}>
              Minimum req: {moneyStr(MIN_CREDIT_BALANCE)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body4ItalicInter" className={classes.creditRecomendation}>
              Recommended credit balance: {moneyStr(recommendedCredits)}
            </Typography>
          </Grid>
        </Grid>
      </Section>
      <LimitInfoModal open={openLimitInfoModal} onClose={() => setOpenLimitInfoModal(false)} classes={classes} />
      <CreditDrawer
        open={openCreditDrawer}
        credit={credit}
        onClose={() => setOpenCreditDrawer(false)}
        company={company}
        history={history}
        recommendedCredits={recommendedCredits}
      />
    </Grid>
  );
};

export default ShippingSettings;
