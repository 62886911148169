export default theme => ({
  iconBtn: {
    position: 'absolute',
    top: 24,
    right: 24,
    padding: 6
  },
  icon: {
    width: 18,
    height: 18
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    left: '50%',
    margin: 0,
    maxWidth: 780,
    outline: 'none',
    padding: '24px 0',
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '34px',
    letterSpacing: 'normal',
    color: '#0b1829',
    marginTop: 12
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '26px',
    letterSpacing: 'normal',
    color: '#787b80',
    marginTop: 8
  },
  quantity: {
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '28px',
    letterSpacing: 'normal',
    color: '#0b1829',
    marginTop: 8
  },
  productsContainer: {
    columnGap: '36px',
    marginTop: 36
  },
  skipBtn: {
    height: 20,
    padding: 0,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    lineHeight: '10px',
    letterSpacing: 'normal',
    color: '#787b80',
    '&:hover': { color: '#0b1829' }
  }
});
