import { useRouter } from 'next/router';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

const urls = [
  /\/bulk\/catalog/,
  /\/custom-swag-packs\/catalog/,
  /\/products\/order/,
  /\/send-swag\//,
  /\/orders-requested\/[0-9]+\/?$/,
  /\/orders-requested\/[0-9]+\/select-products/,
  /\/orders-requested\/[0-9]+\/select-recipients/,
  /\/orders-requested\/[0-9]+\/create-shipment/,
  /\/orders-requested\/[0-9]+\/order-overview/,
  /\/reorder\/select-quantities/
];

const useIntercomOnLeft = (currentMembership, profile) => {
  const isSSR = useRouter();
  const location = isSSR ? null : useLocation();
  const { update } = useIntercom();
  const moveIntercomLeft = isSSR ? null : urls.some(url => url.test(location?.pathname));

  React.useEffect(() => {
    if (moveIntercomLeft) {
      update({
        alignment: 'left',
        horizontalPadding: 20,
        verticalPadding: 20,
        company: {
          companyId: `${profile?.currentAccount?.id}`,
          name: `${profile?.currentAccount?.name}`,
          plan: `${currentMembership?.name}`,
          createdAt: `${currentMembership?.created_at}`
        },
        customAttributes: {
          membershipActive: currentMembership?.active,
          membershipFreeTier: currentMembership?.isFreeTier
        }
      });
    } else {
      update({
        alignment: 'right',
        horizontalPadding: 20,
        verticalPadding: 20,
        company: {
          companyId: `${profile?.currentAccount?.id}`,
          name: `${profile?.currentAccount?.name}`,
          plan: `${currentMembership?.name}`,
          createdAt: `${currentMembership?.created_at}`
        },
        customAttributes: {
          membershipActive: currentMembership?.active,
          membershipFreeTier: currentMembership?.isFreeTier
        }
      });
    }
  }, [moveIntercomLeft, update, profile, currentMembership]);
};

export default useIntercomOnLeft;
