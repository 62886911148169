import axios from '../DashBoardForm';
import apiPaths from '../../helpers/apiPaths';
import gtm from '../../utils/gtm';
import { mergeCampaignIdsWithData } from '../../helpers/utilsCookies';
import { errorAndLog, okAndLog } from '../../helpers/utils';
import { status201 } from './status.utils';

export const sendMail = async (values, formName = '') => {
  const mergedData = mergeCampaignIdsWithData(values);
  return axios
    .post(apiPaths.contactForms, mergedData, status201)
    .then(({ status, data }) => {
      gtm.submitContactForm(formName);
      return okAndLog('sendMail', status, data);
    })
    .catch(e => {
      const { status, data } = e.response || (e.request ? { data: e.request } : { status: e.status, data: e });
      return errorAndLog('sendMail', status, data);
    });
};
