import * as React from 'react';
import { Grid, makeStyles, withStyles, FormControlLabel, Typography, Chip } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import AddPaymentMethodDialog from './AddPaymentMethodDialog';
import styles from './styles/accounts';
import AccountsContainer from './AccountsContainer';
import { BlueSwitch, CheckBoxPaymentMethod } from './AccountSharedComponents';
import { paymentMethods } from '../../apis/constants';
import AutomaticChargesModal from './AutomaticChargesModal';
import { allowAutomaticCharges } from '../../actions';
import toErrorPage from '../../helpers/toErrorPage';
import { useCompany, useIsAchPrepaid } from '../../hooks';

const useStyles = makeStyles(styles);

const TaxExemptChip = withStyles({
  root: {
    borderRadius: 10,
    textAlign: 'center',
    backgroundColor: '#f4f8ff',
    height: 38,
    width: 'fit-content'
  },
  label: {
    fontSize: 12,
    color: '#0b1829',
    fontFamily: 'Gilroy-Regular'
  }
})(Chip);

const Accounts = () => {
  const { data: company } = useCompany();
  const paymentProfiles = useSelector(state => state.paymentProfiles);
  const isAchPrepaid = useIsAchPrepaid();

  const defaultMethod = paymentProfiles.find(p => p.default)?.payment_method;
  const [paymentMethod, setPaymentMethod] = React.useState(defaultMethod || paymentMethods.creditCard);
  const [automaticCharges, setAutomaticCharges] = React.useState(company.allow_automatic_charges);
  const [openAddPaymentMethod, setOpenAddPaymentMethod] = React.useState(false);
  const [openAutomaticCharges, setOpenAutomaticCharges] = React.useState(false);

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!isAchPrepaid) {
      setPaymentMethod(paymentMethods.creditCard);
    }
  }, [isAchPrepaid]);

  const handleAllow = async () => {
    const response = await dispatch(allowAutomaticCharges(company.id, !company.allow_automatic_charges));
    if (response.result === 'ok') setAutomaticCharges(!company.allow_automatic_charges);
    else toErrorPage(response, history);
    setOpenAutomaticCharges(false);
  };

  const handleChangeAutomaticCharges = () => {
    if (automaticCharges) {
      handleAllow();
    } else {
      setOpenAutomaticCharges(true);
    }
  };

  const toggleOpenPaymentModal = () => setOpenAddPaymentMethod(isOpen => !isOpen);

  const toggleOpenChargesModal = () => setOpenAutomaticCharges(isOpen => !isOpen);

  const paymentMethodProfiles = paymentProfiles.filter(p => p.payment_method === paymentMethod);

  const { leftBarNavigation } = useFlags();
  const isModalOpen = useSelector(state => state.modal);

  const [buttonText, subtitle] =
    paymentMethod === paymentMethods.creditCard
      ? ['Add a Credit Card', 'Saved Credit Cards']
      : ['Add ACH Account', 'Saved ACH Accounts'];

  return (
    <Grid container direction="column">
      {!leftBarNavigation ? (
        <>
          <Typography variant="h3" className={classes.title}>
            My Accounts
          </Typography>
          <hr className={classes.line} style={{ marginBottom: 14 }} />
        </>
      ) : null}
      {!company.is_taxable && <TaxExemptChip label="Tax Exempt" />}
      <Typography variant="h3" className={classes.sectionTitle}>
        Payment Method
      </Typography>
      <Grid container>
        <CheckBoxPaymentMethod
          classes={classes}
          method={paymentMethods.creditCard}
          selectedMethod={paymentMethod}
          onSelection={setPaymentMethod}
        />
        {isAchPrepaid && (
          <CheckBoxPaymentMethod
            classes={classes}
            method={paymentMethods.ach}
            selectedMethod={paymentMethod}
            onSelection={setPaymentMethod}
          />
        )}
      </Grid>
      {paymentMethodProfiles.length > 0 && (
        <Grid container style={{ paddingTop: 39 }}>
          <Grid item xs={5}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {subtitle}
            </Typography>
          </Grid>
          <Grid item container xs={7} justifyContent="flex-end" className={classes.allowChargesText}>
            {/* Hidden allow automatic charges feature until there is a working functionality for this  */}
            {false && (
              <FormControlLabel
                control={
                  <BlueSwitch
                    checked={automaticCharges}
                    onChange={handleChangeAutomaticCharges}
                    value={automaticCharges}
                    color="primary"
                    size="small"
                  />
                }
                classes={{ label: classes.labelSwitch }}
                labelPlacement="start"
                label="Allow Automatic Charges"
              />
            )}
          </Grid>
        </Grid>
      )}
      <AccountsContainer
        company={company}
        paymentProfiles={paymentProfiles}
        selectedMethod={paymentMethod}
        classes={classes}
      />
      <Grid
        container
        justifyContent="center"
        className={clsx(classes.addAccountContainer, { [classes.paddingES]: paymentMethodProfiles.length === 0 })}
      >
        {!leftBarNavigation ? (
          <Button
            variant={paymentMethodProfiles.length > 0 ? 'primary' : 'secondary'}
            disabled={paymentProfiles.length === 10}
            onClick={toggleOpenPaymentModal}
            className={classes.addAccountBtn}
          >
            {buttonText}
          </Button>
        ) : null}
      </Grid>
      {leftBarNavigation ? (
        <AddPaymentMethodDialog
          isDefault={paymentProfiles.length === 0}
          paymentMethod={paymentMethod}
          open={isModalOpen}
          onClose={() => dispatch({ type: 'Add-Credit-Card' })}
        />
      ) : (
        <AddPaymentMethodDialog
          isDefault={paymentProfiles.length === 0}
          paymentMethod={paymentMethod}
          open={openAddPaymentMethod}
          onClose={toggleOpenPaymentModal}
        />
      )}
      <AutomaticChargesModal open={openAutomaticCharges} onToggleOpen={toggleOpenChargesModal} onAllow={handleAllow} />
    </Grid>
  );
};

export default Accounts;
