const styles = theme => ({
  title: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.83,
    letterSpacing: 'normal',
    color: '#0f2440',
    paddingTop: 24,
    paddingBottom: 18
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#8d9299',
    paddingTop: '16.6px',
    paddingLeft: 25,
    paddingBottom: '10.4px'
  },
  subtitleSelected: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4',
    paddingTop: '16.6px',
    paddingLeft: 25,
    paddingBottom: '10.4px'
  },
  subtitleSelectedCustom: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4',
    paddingTop: '16.6px',
    paddingLeft: 25,
    paddingBottom: '5px'
  },
  option: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 17,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440',
    paddingLeft: 25,
    paddingBottom: 19
  },
  optionSelected: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 17,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4',
    paddingLeft: 25,
    paddingBottom: 19
  },
  inputContainer: {
    borderRadius: 10,
    border: 'solid 1px #d3dbe5',
    backgroundColor: '#fafbfc',
    borderOpacity: 0.2,
    cursor: 'pointer'
  },
  inputContainerSelected: {
    borderRadius: 10,
    border: 'solid 1px #3577d4',
    backgroundColor: '#fafbfc'
  },
  button: {
    width: '246px',
    height: '56px',
    borderRadius: '4px',
    backgroundColor: '#3577d4',
    fontFamily: 'Futura',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.75',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3577d4'
    }
  },
  textField: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 0,
    paddingBottom: 12
  },
  textFieldEnterCredit: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: -10,
    paddingBottom: 8
  },
  recommendedCredit: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px ! important'
    }
  },
  inputCreditText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 17,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4'
  }
});
export default styles;
