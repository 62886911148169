const styles = () => ({
  orderCard: {
    padding: 24,
    marginBottom: 24,
    marginRight: 10,
    borderRadius: 4,
    border: '1px solid #ccc',
    backgroundColor: '#ffffff'
  }
});

export default styles;
