import React from 'react';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { Box, Grid, Hidden, Typography, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Button } from '@swagup-com/components';
import { isWeekend } from '../../../../helpers/commonDateFunctions';
import { TextField } from '../../../global/reactHookFormFields';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      padding: '0 40px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: '0 10px'
    }
  },
  fieldTitle: {
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      margin: '0 10px'
    }
  }
});

const useStyles = makeStyles(styles);

const Form = ({ submitRequest, packsAmount, minQuantity }) => {
  const minimumDeliveryDate = dayjs().add(4, 'weeks');
  const { handleSubmit, control, formState, register } = useForm({
    defaultValues: {
      date: minimumDeliveryDate,
      quantity: packsAmount
    },
    mode: 'onChange'
  });
  const { errors } = formState;

  const classes = useStyles();

  return (
    <Box ml="20px" mr="20px" className={classes.root}>
      <form onSubmit={handleSubmit(submitRequest)}>
        <Grid container item spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.fieldTitle}>
              How many swag packs are you looking to get?
            </Typography>
            <TextField
              type="number"
              fullWidth
              error={errors.quantity?.message}
              {...register('quantity', {
                required: 'Required',
                min: { value: minQuantity, message: `Must be at least ${minQuantity} swag packs` }
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" wrap="nowrap" className={classes.fieldTitle}>
              <Typography variant="h5">Do you need these by a certain date?</Typography>
            </Grid>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <KeyboardDatePicker
                  {...field}
                  autoOk
                  minDate={minimumDeliveryDate}
                  shouldDisableDate={date => isWeekend(date.toDate())}
                  format="MMMM Do, YYYY"
                  inputVariant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    style: { cursor: 'default' },
                    inputProps: { style: { cursor: 'default' } }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Hidden mdDown>
              <Button variant="primary" type="submit">
                Submit Request
              </Button>
            </Hidden>
            <Hidden lgUp>
              <Button variant="primary" type="submit" fullWidth>
                Submit Request
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
