import React, { useState, useEffect, useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Tooltip, Button } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Loader from '../../global/Loader';
import { deleteEmployee, saveEmployee } from '../../../actions';
import { RoundedButtonLink } from '../../buttons';
import { BackLinkPageTitle } from '../../shared';
import { useAsync } from '../../../hooks';
import tags from '../../../apis/seoTags';
import ShippingHistoryList from './shippingHistory/ShippingHistoryList';
import toErrorPage from '../../../helpers/toErrorPage';
import RemoveContactsModal from '../../pages/shipments/contacts/RemoveContactsModal';
import EmployeeFormContainer from './EmployeeFormContainer';
import { removeEmployeeMessage } from '../../pages/shipments/contacts/ContactsList';
import { contactsApi } from '../../../apis/swagup';
import styles from './styles/EmployeeFormContainer';
import log from '../../../logger';
import { SelectionContext, SelectionProvider } from '../../pages/shipments/contacts/SelectionContext';

const useStyles = makeStyles(styles);

const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const locationEmployee = location.state?.employee;
  const { contactsFeGroupingTemp011921: grouping, leftBarNavigation } = useFlags();

  const getEmployee = useCallback(
    () => contactsApi.fetchById(id, () => history.push(leftBarNavigation ? '/contacts' : '/shipments/contacts')),
    [history, id]
  );
  const [fetchEmployee, employeeResponse, employeeIsPending, employeeHasFailed] = useAsync(getEmployee);
  const getOrders = useCallback(() => contactsApi.fetchOrders(id), [id]);
  const [fetchOrders, ordersResponse, ordersIsPending, ordersHasFailed] = useAsync(getOrders);

  const [isRemoveContactsOpen, setIsRemoveContactsOpen] = useState(false);

  const classes = useStyles();

  const employee = useMemo(() => locationEmployee || employeeResponse || {}, [locationEmployee, employeeResponse]);

  log.debug('EmployeeDetails - employee from props:', employee);

  useEffect(() => {
    if (isEmpty(employee)) {
      fetchEmployee();
    }
  }, [employee, fetchEmployee]);

  useEffect(() => {
    if (!ordersResponse) {
      fetchOrders();
    }
  }, [ordersResponse, fetchOrders]);

  useEffect(() => {
    if (employeeHasFailed) toErrorPage(employeeResponse, history);
    if (ordersHasFailed) toErrorPage(ordersResponse, history);
  }, [employeeHasFailed, ordersHasFailed, employeeResponse, ordersResponse, history]);

  const toggleRemoveContactsOpen = () => setIsRemoveContactsOpen(isOpen => !isOpen);

  const handleDelete = result => {
    toggleRemoveContactsOpen();
    if (result === 'error') toErrorPage({ result }, history);
    history.push(leftBarNavigation ? '/contacts' : '/shipments/contacts');
  };

  const handleDeleteOld = async () => {
    toggleRemoveContactsOpen();
    const resp = await dispatch(deleteEmployee(employee.id));
    if (resp.result === 'error') toErrorPage(resp, history);
    history.push(leftBarNavigation ? '/contacts' : '/shipments/contacts');
  };

  const saveEmployeeAction = data => dispatch(saveEmployee({ ...data, size: data.size || null }));

  const hasOrdersOrShipments = employee.has_pending_orders || employee.is_in_shipment_groups;
  const topRow = (
    <Grid container justifyContent="space-between">
      <Grid item xs={10}>
        <Grid container>
          <BackLinkPageTitle title="Details" link={leftBarNavigation ? '/contacts' : '/shipments/contacts'} />
        </Grid>
      </Grid>
      <Grid container item justifyContent="space-between" xs={2} className={classes.actionsContainer}>
        <RoundedButtonLink
          color="#cccccc"
          size={48}
          border={0}
          to={{
            pathname: `/send-swag/select-products`,
            state: { contacts: [employee], from: location }
          }}
        >
          <img alt="" src="/images/staff/ship-icon.svg" />
        </RoundedButtonLink>
        <Tooltip
          title={removeEmployeeMessage(employee.has_orders, hasOrdersOrShipments)}
          classes={{ tooltip: classes.tooltip }}
        >
          <div>
            <Button
              className={classes.deleteBtn}
              disabled={hasOrdersOrShipments}
              onClick={toggleRemoveContactsOpen}
              disableElevation
              disableRipple
              disableFocusRipple
            >
              <img
                alt="delete-icon"
                src="/images/staff/delete-icon.svg"
                style={{ opacity: hasOrdersOrShipments ? 0.35 : 1 }}
              />
            </Button>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const isLoading = employeeIsPending;

  return (
    <div className={classes.container}>
      {isLoading && <Loader />}
      <Grid container justifyContent="center">
        <Grid item className={classes.center}>
          <EmployeeFormContainer
            saveEmployee={saveEmployeeAction}
            employee={employee}
            topRow={topRow}
            tag={tags.employeeDetails}
            cancelText="Discard Changes"
            classes={classes}
          />
          <ShippingHistoryList
            shippingHistory={ordersResponse}
            isLoading={ordersIsPending && !isLoading}
            employee={employee}
          />
          <SelectionProvider>
            <SelectionContext.Consumer>
              {({ excluded, updateExcluded }) => {
                if (excluded.size === 0) updateExcluded(employee);
                return (
                  <RemoveContactsModal
                    contacts={[employee]}
                    onDelete={grouping ? handleDelete : handleDeleteOld}
                    isOpen={isRemoveContactsOpen}
                    onClose={toggleRemoveContactsOpen}
                  />
                );
              }}
            </SelectionContext.Consumer>
          </SelectionProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeeDetails;
