export default {
  productHeader: {
    marginTop: 38,
    paddingRight: 33,
    width: ({ perfectWidth }) => perfectWidth
  },
  searchField: {
    width: 280,
    height: 56,
    fontSize: 14
  },
  sortByContainer: {
    width: 240
  },
  sortBy: {
    '& .MuiSelect-root, & fieldset': { padding: 0 },
    '& .MuiSvgIcon-root': { right: 0 },
    '& fieldset': { border: 'none !important' }
  },
  scrollable: {
    flex: 1,
    marginTop: 30
  },
  retryBtn: {
    height: 'auto',
    padding: 0
  }
};
