import * as React from 'react';
import { makeStyles, Snackbar, CircularProgress, Slide } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { Alert } from '@material-ui/lab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import { statuses } from '../../reducers/globalNotificationReducer';
import { STATUS_CHANGED } from '../../actions/types';

const useStyles = makeStyles(theme => ({
  snackbar: { top: 114 },
  notificationContainer: ({ severity = statuses.pending }) => ({
    alignItems: 'center',
    flexDirection: severity === statuses.pending ? 'row-reverse' : 'row',
    border: severity === statuses.pending ? '' : `1px solid ${theme.palette[severity]['500']}`,
    backgroundColor: severity === statuses.pending ? '#3B4048' : theme.palette[severity]['50'],
    boxShadow: '0px 8px 16px 2px rgba(0, 0, 0, 0.04)',
    borderRadius: 4,
    padding: '12px 16px',
    '& > .MuiAlert-icon, & > .MuiAlert-message': { padding: 0 },
    '& > .MuiAlert-icon': {
      marginRight: 0,
      [severity === statuses.pending ? 'marginLeft' : 'marginRight']: 12,
      '& > svg': { fontSize: 20 }
    },
    maxWidth: 1440,
    [theme.breakpoints.only('lg')]: {
      maxWidth: 1200
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: 900
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '80vw'
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '90vw'
    }
  }),
  loadingIcon: { color: '#989EA4' }
}));

const ErrorContent = ({ message }) => {
  const classes = useStyles({ severity: statuses.error });

  return (
    <Alert severity="error" classes={{ root: classes.notificationContainer }}>
      <Typography variant="body3MediumInter">{message}</Typography>
    </Alert>
  );
};

const SuccesContent = ({ message }) => {
  const classes = useStyles({ severity: statuses.success });

  return (
    <Alert icon={<CheckCircleIcon />} classes={{ root: classes.notificationContainer }}>
      <Typography variant="body3MediumInter">{message}</Typography>
    </Alert>
  );
};

const LoadingContent = ({ message }) => {
  const classes = useStyles({ severity: statuses.pending });

  return (
    <Alert
      icon={<CircularProgress size={16} classes={{ root: classes.loadingIcon }} />}
      classes={{ root: classes.notificationContainer }}
    >
      <Typography variant="body3MediumInter" style={{ color: '#FFF' }}>
        {message}
      </Typography>
    </Alert>
  );
};

const ContentWrapper = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { status } = useSelector(state => state.globalNotification);
  const shouldBeOpen = status !== statuses.idle;

  // handle open status internally to allow showing current notification until close transition finish
  const [open, setOpen] = React.useState(shouldBeOpen);
  React.useEffect(() => {
    setOpen(shouldBeOpen);
  }, [shouldBeOpen]);

  const handleOnClose = () => {
    setOpen(false);
    // wait for the transition to finish before returning data to its initial state
    setTimeout(() => {
      dispatch({
        type: STATUS_CHANGED,
        payload: { status: statuses.idle, message: '' }
      });
    }, 300);
  };

  const autoHideDuration = status === statuses.success || status === statuses.error ? 3000 : null;

  return (
    <Snackbar
      open={open}
      onClose={handleOnClose}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{ root: classes.snackbar }}
      ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
      disableWindowBlurListener
    >
      {/* div component to hold a reference for the transition component */}
      <div>{children}</div>
    </Snackbar>
  );
};

const ContentMap = {
  [statuses.success]: SuccesContent,
  [statuses.error]: ErrorContent,
  [statuses.pending]: LoadingContent
};

const GlobalNotification = () => {
  const { status, message } = useSelector(state => state.globalNotification);

  const Content = ContentMap[status];

  return <ContentWrapper>{Content && <Content message={message} />}</ContentWrapper>;
};

export default GlobalNotification;
