export default {
  formContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffff',
    zIndex: 2,
    padding: '50px 72px 58px 72px',
    transform: 'translateX(100%)',
    transition: 'transform 500ms ease-in',
    display: 'none',
    '&.open': {
      display: 'initial',
      transform: 'translateX(0)',
      transition: 'transform 500ms ease-out'
    }
  },
  formHeader: {
    marginBottom: 105,
    position: 'relative'
  },
  errorAlert: {
    position: 'absolute',
    top: 72,
    left: 0,
    maxWidth: 824,
    width: '100%',
    '& button': {
      height: 'auto'
    }
  },
  saveBtn: {
    width: 224,
    height: 56,
    minHeight: 'unset',
    margin: '62px auto 0 auto'
  }
};
