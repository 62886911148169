const styles = theme => ({
  dialog: {
    maxWidth: 1084,
    maxHeight: 478,
    borderRadius: 10,
    position: 'relative'
  },
  dialogTitle: {
    padding: '39px 24px 27px 24px !important'
  },
  title: {
    fontFamily: 'Gilroy',
    fontSize: 36,
    fontWeight: 'bold',
    color: '#0f2440',
    textAlign: 'center',
    lineHeight: 'normal'
  },
  subTitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: 1.57,
    fontWeight: '500',
    color: '#8d9299',
    marginTop: 16,
    textAlign: 'center',
    paddingLeft: 160,
    paddingRight: 160
  },
  closeButton: {
    width: 25,
    height: 25,
    padding: 1,
    color: '#1d1d1d',
    position: 'absolute',
    top: 28,
    right: 28
  },
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1.5)
  },
  instructions: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  stepLabel: {
    '& span': {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 16,
      fontWeight: 600,
      color: '#8d9299'
    }
  },
  active: {
    color: '#3577d4 !important'
  },
  completed: {
    color: '#0f2440 !important'
  },
  completedFooter: {
    color: '#8d9299 !important'
  },
  label: {
    fontFamily: 'Gilroy-SemiBold !important',
    fontSize: '16px !important',
    fontWeight: 600,
    color: '#8d9299'
  },
  labelFooter: {
    fontFamily: 'Gilroy-Medium !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    color: '#8d9299'
  },
  buttonContainer: {
    marginTop: 46,
    marginBottom: 51,
    justifyContent: 'center'
  },
  continueButton: {
    width: '180px !important',
    borderRadius: '32px !important',
    textTransform: 'none !important',
    '& div': { fontFamily: 'Gilroy' }
  },
  stepperFooter: {
    width: 520,
    height: 'auto',
    '& > div': {
      padding: 14
    }
  }
});

export default styles;
