import React from 'react';
import { Box, Typography } from '@material-ui/core';

export const InfoContainer = ({ children }) => (
  <Box border="2px solid #ebedf0" borderRadius="10px" padding="10px 20px" margin="20px 0 35px">
    {children}
  </Box>
);

export const ErrorBanner = ({ message }) => (
  <Box width="calc(100% - 36px)" bgcolor="#fee8e7" p="6px 20px" ml="18px" borderRadius={10} textAlign="center">
    <Typography variant="body1" style={{ color: '#f44636' }}>
      {message}
    </Typography>
  </Box>
);
