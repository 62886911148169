import React from 'react';
import { Table, TableRow, TableCell, TableBody, withStyles } from '@material-ui/core';
import styles from './styles/GenericSummaryBottom';
import { moneyStr } from '../../helpers/utils';

const SummaryBottomItemCell = ({ text, alignment, classes, title, paddingTop, fontSize }) => (
  <TableCell align={alignment} className={classes.cellNone}>
    <p className={title ? classes.pTitle : classes.pText} style={{ fontSize, paddingTop }}>
      {' '}
      {text}
    </p>
  </TableCell>
);

const SummaryBottomItem = ({ text, value, classes, titlePaddingTop, valueSize, separator }) =>
  separator ? (
    <TableRow>
      <TableCell
        colSpan={2}
        className={classes.cellNone}
        style={{ padding: '0 0 20px 0', borderTop: '1px solid #ebeef2' }}
      />
    </TableRow>
  ) : (
    <TableRow className={classes.tableRow}>
      <SummaryBottomItemCell text={`${text}:`} alignment="left" classes={classes} title paddingTop={titlePaddingTop} />
      <SummaryBottomItemCell text={moneyStr(value)} alignment="right" classes={classes} fontSize={valueSize} />
    </TableRow>
  );

const GenericSummaryBottom = ({ totalQty, total, classes }) => (
  <Table className={classes.summaryTable}>
    <TableBody>
      <SummaryBottomItem
        text={`Est. total (${totalQty})`}
        value={total}
        classes={classes}
        titlePaddingTop={6}
        valueSize={24}
      />
    </TableBody>
  </Table>
);

export default withStyles(styles)(GenericSummaryBottom);
