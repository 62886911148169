import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { CenteredGrid, Helmet } from '../shared';
import { Img } from '../global/ImgUtils';
import seoTags from '../../apis/seoTags';
// import styles from './styles/storefrontsHome';

// const useStyles = makeStyles(styles);

const StoreCreated = () => {
  const location = useLocation();
  const { store } = location.state || {};
  // const classes = useStyles();
  return (
    <div>
      <Helmet tags={seoTags.storefronts} />
      <CenteredGrid>
        <Grid container justifyContent="center" alignItems="center" style={{ height: 'calc(100vh - 80px)' }}>
          <Grid item>
            <Typography variant="h5BoldInter" style={{ textAlign: 'center ' }}>
              You are all set!
            </Typography>
            <Typography variant="subtitle3RegularInter" style={{ textAlign: 'center ' }}>
              Now you are ready to start adding products to your shop
            </Typography>
            <Grid container justifyContent="center">
              <Grid item>
                <Box style={{ width: 323, height: 323 }}>
                  <Img src="/images/storefront/dashboard-management.svg" width="100%" height="100%" />
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  variant="primary"
                  size="small"
                  component={Link}
                  to={`/shop-details/${store?.urlSlug}?tab=products`}
                >
                  Add Products to Shop
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CenteredGrid>
    </div>
  );
};

export default StoreCreated;
