export default {
  header: {
    paddingTop: 24,
    paddingBottom: 48,
    marginBottom: 48,
    textAlign: 'center',
    borderBottom: '2px solid #434c5f'
  },
  title: {
    color: '#0f2440',
    fontSize: 40
  },
  summary: {
    paddingBottom: 30,
    marginBottom: 30,
    maxWidth: 730
  },
  subTitle: {
    linHeight: 1,
    fontWeight: 500,
    marginBottom: 16,
    color: '#1B1C1F'
  }
};
