import React from 'react';
import { submit, isValid, isSubmitting } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from '@swagup-com/components';

const SubmitButton = ({ formName, dispatch, valid, submitting, disabled, children, ...props }) => (
  <Button
    id="Payment-with-amount"
    disabled={!valid || submitting || disabled}
    onClick={() => dispatch(submit(formName))}
    {...props}
  >
    {children}
  </Button>
);

const mapStateToProps = (state, ownProps) => ({
  valid: isValid(ownProps.formName)(state),
  submitting: isSubmitting(ownProps.formName)(state)
});

export default connect(mapStateToProps)(SubmitButton);
