import { ellipsisStyles } from '../../../../../shared/styles/commonStyles';

const tableCellCommon = {
  borderTop: '1px solid	#434c5f !important',
  borderBottom: 'none'
};

const styles = () => ({
  header: {
    opacity: 0.9,
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    marginBottom: 16,
    paddingLeft: 40,
    borderBottom: 'none'
  },
  shippingAddress: {
    paddingLeft: 0
  },
  item: {
    paddingLeft: 40
  },
  size: {
    textAlign: 'center'
  },
  quantity: {
    textAlign: 'center'
  },
  normalText: {
    ...ellipsisStyles,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  employee: {
    opacity: 0.9,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal'
  },
  name: {
    fontWeight: 'bold'
  },
  address: {
    fontWeight: 'normal'
  },
  addressContainer: {
    marginTop: 8,
    marginBottom: 1,
    overflow: 'auto',
    maxHeight: 84,
    width: 300
  },
  tableCellEmployeeInfo: {
    ...tableCellCommon,
    verticalAlign: 'top',
    width: 250,
    paddingTop: 22,
    paddingLeft: 0
  },
  tableCellPackImgWithInfo: {
    ...tableCellCommon,
    width: 400,
    paddingRight: 0,
    paddingTop: 15
  },
  tableCellSize: {
    ...tableCellCommon,
    verticalAlign: 'text-bottom',
    opacity: 0.9,
    width: 204,
    textAlign: 'center',
    paddingLeft: 40
  },
  tableCellQty: {
    ...tableCellCommon,
    verticalAlign: 'text-bottom',
    paddingLeft: 40,
    textAlign: 'center',
    paddingTop: 16
  }
});

export default styles;
