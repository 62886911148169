import * as React from 'react';
import { Box, ButtonBase, makeStyles, Divider, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { changeColorLightness } from '../../../../shared/styles/utils';
import Dropdown from './Dropdown';
import { useSizes } from '../../../../../hooks';
import CheckBoxFormControl from '../../../../global/CheckBoxFormControl';

const commonLabel = {
  display: 'inline-block',
  color: '#787b80',
  fontFamily: 'Gilroy-Medium',
  fontSize: 14,
  lineHeight: '10px',
  letterSpacing: 0,
  minWidth: 25
};

const useOptionStyles = makeStyles({
  root: { margin: 0 },
  label: {
    ...commonLabel,
    color: ({ checked }) => (checked ? '#0b1829' : '#787b80'),
    marginLeft: 8
  }
});

const SizeCheckbox = ({ label, checked, onClick }) => {
  const classes = useOptionStyles({ checked });

  return (
    <CheckBoxFormControl
      label={label}
      checked={checked}
      onClick={onClick}
      rootClass={classes.root}
      labelClass={classes.label}
    />
  );
};

const useRadioItemStyles = makeStyles({
  label: {
    ...commonLabel,
    '.Mui-checked+&': {
      color: '#0b1829'
    }
  },
  radio: {
    color: '#ced1d6',
    padding: '6px 9px',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem'
    }
  }
});

const RadioItem = ({ value, label }) => {
  const classes = useRadioItemStyles();
  return (
    <FormControlLabel
      classes={{ label: classes.label }}
      value={value}
      control={<Radio classes={{ root: classes.radio }} color="primary" />}
      label={label}
    />
  );
};

const commonButtonStyles = {
  padding: 0,
  height: 'fit-content'
};

const commonHeaderStyles = {
  fontFamily: 'Gilroy-Medium',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: 0,
  color: '#787b80',
  marginTop: 10
};

const useFilterStyles = makeStyles({
  applyButton: {
    ...commonButtonStyles,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    lineHeight: '10px',
    letterSpacing: 0,
    color: '#3577d4',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  clearButton: {
    ...commonButtonStyles,
    ...commonHeaderStyles,
    marginTop: 0,
    '&:hover': { color: '#0b1829' }
  },
  divider: {
    backgroundColor: '#f0f2f5',
    height: 1,
    margin: ({ withSizes }) => (withSizes ? '15px 0' : '0 0 15px 0')
  },
  header: commonHeaderStyles,
  addressOptionsContainer: {
    rowGap: '14px',
    padding: '6px 0'
  },
  sizeOptionsContainer: {
    rowGap: '18px',
    position: 'relative',
    paddingTop: 12
  }
});

const addressFilters = [
  { label: 'All', value: 'all' },
  { label: 'United States', value: 'domestic' },
  { label: 'International', value: 'international' }
];

const defaultSizesOptions = [{ label: 'No Size', value: 'None', checked: false }];

const sizesSelector = sizes =>
  sizes
    ?.filter(size => size.category === 'Apparel')
    .map(size => ({ label: size.name, value: size.id, checked: false }));

const Filter = ({ filters, onFiltersSubmit, withSizes = true }) => {
  const classes = useFilterStyles({ withSizes });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sizeFilters, setSizeFilters] = React.useState(filters?.sizes ?? defaultSizesOptions);
  const [selectedAddress, setSelectedAddress] = React.useState(filters?.address ?? 'all');

  const { data: allSizes } = useSizes();
  const sizes = React.useMemo(() => sizesSelector(allSizes), [allSizes]);
  React.useEffect(() => {
    if (anchorEl) {
      setSizeFilters(filters?.sizes ?? (sizes ? [...sizes, ...defaultSizesOptions] : defaultSizesOptions));
      setSelectedAddress(filters?.address ?? 'all');
    }
  }, [filters, anchorEl, sizes]);

  const handleSizeClick = sizeValue => checked =>
    setSizeFilters(prevOptions =>
      prevOptions.map(option => (option.value === sizeValue ? { ...option, checked } : option))
    );

  const handleClear = () => {
    setSizeFilters(prevSizes => prevSizes.map(size => ({ ...size, checked: false })));
    setSelectedAddress('all');
  };

  const handleSubmit = () => {
    onFiltersSubmit({ address: selectedAddress, sizes: sizeFilters, group_ids: filters?.group_ids });
    setAnchorEl(null);
  };

  const handleAddressChange = e => setSelectedAddress(e.target.value);

  return (
    <Dropdown anchorEl={anchorEl} setAnchorEl={setAnchorEl} label="Filters">
      <Box width="162px" disabled>
        <p className={classes.header}>Filter by Address</p>
        <Grid container className={classes.addressOptionsContainer}>
          <RadioGroup aria-label="adresses" value={selectedAddress} onChange={handleAddressChange}>
            {addressFilters.map(a => (
              <RadioItem key={a.value} value={a.value} label={a.label} />
            ))}
          </RadioGroup>
        </Grid>
        {withSizes && (
          <>
            <p className={classes.header}>Filter by Sizes</p>
            <Grid container className={classes.sizeOptionsContainer}>
              {sizeFilters.map(({ label, value, checked }, index) => (
                <Grid container alignItems="center" key={label} item xs={index % 2 === 0 ? 6 : 5}>
                  <SizeCheckbox label={label} value={value} checked={checked} onClick={handleSizeClick(value)} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        <Divider className={classes.divider} />
        <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 8 }}>
          <ButtonBase variant="text" className={classes.applyButton} onClick={handleSubmit}>
            Apply
          </ButtonBase>
          <ButtonBase variant="text" className={classes.clearButton} onClick={handleClear}>
            Clear
          </ButtonBase>
        </Grid>
      </Box>
    </Dropdown>
  );
};

export default Filter;
