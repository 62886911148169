import axios from '../DashBoard';
import { status200, status201 } from './status.utils';
import apiPaths from '../../helpers/apiPaths';

export default {
  fetchById: id => axios.get(apiPaths.proof(id), status200).then(response => response.data),

  fetch: query => axios.get(`${apiPaths.proofs}?${query}`, status200).then(response => response.data),

  updatePack: async ({ id, packItems }) => {
    const payload = {
      items: packItems?.map(item => ({
        product: item.product.id,
        units_per_pack: item.units_per_pack
      }))
    };

    const response = await axios.put(apiPaths.proof(id), payload, status200);
    return response.data;
  },

  fetchPrices: async (products, cancelToken) => {
    const proofs = products.map(p => ({
      proof: p.id,
      production_time: p.production_time,
      items: p.product.items?.map(i => ({
        product: i.product.id,
        units_per_pack: i.units_per_pack
      })),
      sizes: p.sizes.filter(s => s.quantity > 0).map(s => ({ size: s.size.id, quantity: s.quantity }))
    }));
    const response = await axios.post(apiPaths.proofPrices, { proofs }, { ...status201, cancelToken });

    const proofId = products[0].id;
    const priceInfo = response.data?.proofs.find(proof => proof.proof === proofId);
    if (priceInfo) return { id: proofId, ...priceInfo };

    const productPayload = proofs.find(proof => proof.proof === proofId);
    return { id: proofId, price: 0, rush_fee: 0, ...productPayload };
  }
};
