import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';

import {
  ADD_SHIPMENT_GROUP_DIRECTORY_ORDERS,
  ADD_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT,
  ADD_SHIPMENT_GROUP_RECIPIENTS_SUCCESS,
  ADD_SHIPMENT_GROUP_RECIPIENTS_START,
  CLEAN_SHIPMENT_GROUP,
  DELETE_SHIPMENT_GROUP_DIRECTORY_ORDER,
  DELETE_SHIPMENT_GROUP_RECIPIENTS,
  DELETE_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT,
  CREATED_SHIPMENT_GROUP_DIRECTORY_ORDERS,
  SELECT_SHIPMENT_GROUP_PRODUCTS,
  SELECT_SHIPMENT_GROUP_RECIPIENTS,
  TOGGLE_SELECT_FROM_EXISTING,
  UPDATE_SHIPMENT_GROUP_DIRECTORY_ORDER,
  UPDATE_SHIPMENT_GROUP_LOADING_STATUS,
  UPDATE_SHIPMENT_GROUP_RECIPIENT,
  UPDATE_SELECTED_RECIPIENTS_SHIPPING_METHOD,
  SET_SHIPMENT_GROUP_ID,
  VALIDATE_SHIPMENT_GROUP_DIRECTORY_ORDERS,
  SET_CONTACT_FETCH_QUERY,
  UPDATE_SELECTED_RECIPIENTS_SHIPPING_DATE,
  UPDATE_SHIPMENT_GROUP_RECIPIENT_COLLAPSED,
  TOOGLE_ALL_SHIPMENT_GROUP_RECIPIENTS_COLLAPSED
} from '../actions/types';
import {
  doesProofHasInactiveSizes,
  mapProductsToInactiveSizes
} from '../components/pages/orders/requested/common/utilsOrder';
import { shipmentGroupsErrorTypes, shipmentStatuses } from '../helpers/helperConstants';
import { getQtyLeftPerProduct, isOneSize } from '../helpers/utils';

const initialState = {
  id: null,
  products: [],
  recipients: [],
  directoryOrders: [],
  idsToSelect: [],
  errors: {},
  isSelectingFromExisting: false,
  status: shipmentStatuses.idle
};

// TODO: Move to helperContants after deleting old send swag flow
const errorMessages = {
  deliveryMethods:
    'This recipient does not have an available shipping method. Please, remove the recipient from the list.',
  selectedDeliveryMethods:
    'This recipient does not have an selected shipping method. Please, select a shipping method.',
  products: 'Select an available size or remove the recipient from the list.',
  unavailableSize:
    "A size is unavailable for one or more of the products you've selected, please select one of the available sizes for this recipient.",
  outOfStock: "One or more products don't have enough inventory to cover this recipient.",
  noQuantities: 'Select the product(s) to send to this recipient or remove it from the list.',
  inactiveSize: 'This recipient has Out Of Stock sizes selected. Please update.',
  noShipment: 'You do not have the inventory needed to fulfill this shipment',
  noInternationalShipment: 'We removed 1 or more products from this shipment that can not be sent internationally'
};

const getWarningText = methodName => `${methodName} shipping not available for this recipient`;

const cantBeShippedInternational = (product, recipient) =>
  !product.product.can_ship_international && recipient.shipping_country !== 'US';

const checkSizeMismatch = (products, recipient) =>
  products.some(
    p =>
      !isOneSize(p.sizes) &&
      (recipient.shipping_country === 'US' || p.product.can_ship_international) &&
      p.sizes.every(size => size.size.id !== recipient.size?.id)
  );

const getErrorMessage = (directoryOrder, recipient, products, hasSizeMismatch, hasMissingStock, stockLeft) => {
  if (directoryOrder) return isEmpty(directoryOrder.deliveryMethods) && errorMessages.deliveryMethods;

  if (!directoryOrder.deliveryMethods.find(dm => dm.selected)) return errorMessages.selectedDeliveryMethods;

  if (products.every(p => cantBeShippedInternational(p, recipient)))
    // DO was not created for this recipient: there's a product-recipient mismatch
    return errorMessages.deliveryMethods;
  const hasSomeStockLeft = products.some(p => Object.keys(stockLeft[p.id]).some(size => stockLeft[p.id][size] > 0));
  if (!hasSomeStockLeft) return errorMessages.outOfStock;
  if (hasMissingStock) return errorMessages.unavailableSize;
  if (!recipient.size?.id) return errorMessages.products;
  // if (hasSizeMismatch) return errorMessages.unavailableSize;

  return errorMessages.noQuantities;
};

const getError = (directoryOrder, recipient, products, hasSizeMismatch, hasMissingStock, stockLeft) => {
  const errorMsg = getErrorMessage(directoryOrder, recipient, products, hasSizeMismatch, hasMissingStock, stockLeft);
  return errorMsg ? { text: errorMsg, type: shipmentGroupsErrorTypes.error } : null;
};

const getInactiveSizeWarning = (products, directoryOrder) => {
  const productsWithInactiveSizes = mapProductsToInactiveSizes(products);
  const hasInactiveSize = directoryOrder?.proofs.some(doesProofHasInactiveSizes(productsWithInactiveSizes));
  return hasInactiveSize
    ? { text: errorMessages.inactiveSize, type: shipmentGroupsErrorTypes.inactiveSizeWarning }
    : null;
};

const getWarning = (hasSizeMismatch, isStockLimited, directoryOrder, recipient, products) => {
  if (!directoryOrder) return null;
  const noDMProofs = products
    .filter(p => !directoryOrder?.deletedProducts?.includes(p.id))
    .filter(p => cantBeShippedInternational(p, recipient));
  if (noDMProofs.length > 0)
    return { text: errorMessages.noInternationalShipment, type: shipmentGroupsErrorTypes.sizeWarning };

  const inactiveSizeWarningMsg = getInactiveSizeWarning(products, directoryOrder);
  if (inactiveSizeWarningMsg) return inactiveSizeWarningMsg;

  // const hasSizeMismatchSomeProduct = hasSizeMismatch && directoryOrder;
  const isMissingStockSomeProduct =
    isStockLimited &&
    directoryOrder?.proofs.length < products.filter(p => !directoryOrder?.deletedProducts?.includes(p.id)).length;
  if (isMissingStockSomeProduct) {
    return { text: errorMessages.unavailableSize, type: shipmentGroupsErrorTypes.sizeWarning };
  }

  return null;
};

const getRecipientError = ({ recipient, recipientsMissingStock, shipment, isStockLimited, stockLeft }) => {
  const { products, directoryOrders } = shipment;
  if (!directoryOrders) return null;
  const directoryOrder = directoryOrders.find(d => d.directory === recipient.id);
  if (!directoryOrder) return { text: errorMessages.noShipment, type: shipmentGroupsErrorTypes.error };
  const hasSizeMismatch = checkSizeMismatch(products, recipient);
  const productsMissingStock = products.filter(p => {
    const size = isOneSize(p.sizes) ? p.sizes[0].size.id : recipient.size?.id;
    return !stockLeft[p.id][size];
  });
  const hasMissingStock =
    isStockLimited &&
    (recipientsMissingStock?.[recipient.id] ||
      productsMissingStock.length === products.filter(p => !directoryOrder.deletedProducts?.includes(p.id)).length);
  const error = getError(directoryOrder, recipient, products, hasSizeMismatch, hasMissingStock, stockLeft);
  const warning = getWarning(hasSizeMismatch, isStockLimited, directoryOrder, recipient, shipment.products);

  return error || warning;
};

const hasMethod = (methods, methodName) => Boolean(methods?.find(m => m.name === methodName));

const selectDeliveryMethod = (methods, methodName) =>
  hasMethod(methods, methodName)
    ? methods.map(m => (m.name === methodName ? { ...m, selected: true } : { ...m, selected: undefined }))
    : methods;

const ShipmentGroupReducer = (shipment = initialState, action) => {
  switch (action.type) {
    case CLEAN_SHIPMENT_GROUP:
      return { ...initialState, fetchQuery: shipment.fetchQuery };

    case SELECT_SHIPMENT_GROUP_PRODUCTS:
      return { ...shipment, products: action.payload };

    case SELECT_SHIPMENT_GROUP_RECIPIENTS: {
      const createdAt = new Date();
      const recipients = action.payload.map(recipient => ({ ...recipient, created_at: createdAt }));
      return { ...shipment, recipients: [...shipment.recipients, ...recipients] };
    }

    case UPDATE_SHIPMENT_GROUP_RECIPIENT: {
      const recipient = action.payload;
      return {
        ...shipment,
        recipients: shipment.recipients.map(r =>
          r.id === recipient.id ? { ...recipient, created_at: r.created_at } : r
        )
      };
    }

    case DELETE_SHIPMENT_GROUP_RECIPIENTS: {
      const { directoryOrders, recipients } = shipment;
      const ids = action.payload;
      return {
        ...shipment,
        recipients: recipients.filter(r => !ids.includes(r.id)),
        directoryOrders: directoryOrders.filter(dOrder => !ids.includes(dOrder.directory)),
        errors: omit(shipment.errors, ids)
      };
    }

    case TOOGLE_ALL_SHIPMENT_GROUP_RECIPIENTS_COLLAPSED: {
      const { recipients } = shipment;
      return {
        ...shipment,
        recipients: recipients.map(recipient => ({ ...recipient, collapsed: action.payload }))
      };
    }

    case UPDATE_SHIPMENT_GROUP_RECIPIENT_COLLAPSED: {
      const { recipients } = shipment;
      const { id, value } = action.payload;
      return {
        ...shipment,
        recipients: recipients.map(recipient => (recipient.id === id ? { ...recipient, collapsed: value } : recipient))
      };
    }

    case ADD_SHIPMENT_GROUP_DIRECTORY_ORDERS:
      return { ...shipment, directoryOrders: [...shipment.directoryOrders, ...action.payload] };

    case UPDATE_SHIPMENT_GROUP_LOADING_STATUS: {
      return { ...shipment, isLoading: action.payload };
    }

    case UPDATE_SHIPMENT_GROUP_DIRECTORY_ORDER: {
      const { directoryOrder, removeError } = action.payload;
      const { directoryOrders, errors } = shipment;
      const recipientId = directoryOrder.directory;
      const newDOs = [];
      let wasFoundDirectoryOrder = false;
      directoryOrders.forEach(dOrder => {
        if (dOrder.directory !== recipientId) {
          newDOs.push(dOrder);
        } else {
          newDOs.push({ ...dOrder, ...directoryOrder });
          wasFoundDirectoryOrder = true;
        }
      });

      if (!wasFoundDirectoryOrder) {
        newDOs.push(directoryOrder);
      }

      return {
        ...shipment,
        directoryOrders: newDOs,
        errors: mapValues(errors, (error, rId) => (+rId === recipientId ? { ...error, hidden: removeError } : error))
      };
    }

    case DELETE_SHIPMENT_GROUP_DIRECTORY_ORDER: {
      const { directoryOrders, errors } = shipment;
      const recipientId = action.payload;
      const recipientError = { type: 'error', text: errorMessages.deliveryMethods };
      return {
        ...shipment,
        directoryOrders: directoryOrders.filter(dOrder => dOrder.directory !== recipientId),
        errors: { ...errors, [recipientId]: recipientError }
      };
    }

    case VALIDATE_SHIPMENT_GROUP_DIRECTORY_ORDERS: {
      const { recipientsMissingStock, isStockLimited, hideError } = action.payload;
      const { products, recipients, directoryOrders, errors } = shipment;

      const stockLeft = getQtyLeftPerProduct(products, directoryOrders);
      const newErrors = {};
      recipients.forEach(r => {
        const error = getRecipientError({
          recipient: r,
          recipientsMissingStock,
          shipment,
          isStockLimited,
          stockLeft
        });
        if (error) {
          const hidden =
            error.type === shipmentGroupsErrorTypes.inactiveSizeWarning ? false : hideError || errors[r.id]?.hidden;
          newErrors[r.id] = { ...error, hidden };
        }
      });

      return {
        ...shipment,
        errors: newErrors
      };
    }

    case ADD_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT:
      return { ...shipment, idsToSelect: [...shipment.idsToSelect, ...action.payload] };

    case DELETE_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT:
      return { ...shipment, idsToSelect: shipment.idsToSelect?.filter(id => !action.payload.includes(id)) };

    case TOGGLE_SELECT_FROM_EXISTING:
      return { ...shipment, isSelectingFromExisting: !shipment.isSelectingFromExisting };

    case SET_CONTACT_FETCH_QUERY:
      return { ...shipment, fetchQuery: action.payload };

    case UPDATE_SELECTED_RECIPIENTS_SHIPPING_METHOD: {
      const { recipients, directoryOrders, errors } = shipment;
      const { methodName, selectedIdsSet } = action.payload;

      const noWarningsErrors = omitBy(errors, value => value.type === 'warning');

      const newErrors = recipients
        .filter(r => selectedIdsSet.has(r.id))
        .reduce((ers, recipient) => {
          const directoryOrder = directoryOrders.find(d => d.directory === recipient.id);
          if (!directoryOrder) return ers;

          const methodChanged = hasMethod(directoryOrder.deliveryMethods, methodName);

          const error = ers[recipient.id];
          if (error) return ers;

          return methodChanged
            ? ers
            : {
                ...ers,
                [recipient.id]: {
                  type: 'warning',
                  text: getWarningText(methodName)
                }
              };
        }, noWarningsErrors);

      return {
        ...shipment,
        errors: newErrors,
        directoryOrders: shipment.directoryOrders.map(dOrder =>
          selectedIdsSet.has(dOrder.directory)
            ? {
                ...dOrder,
                deliveryMethods: selectDeliveryMethod(dOrder.deliveryMethods, methodName)
              }
            : dOrder
        )
      };
    }

    case UPDATE_SELECTED_RECIPIENTS_SHIPPING_DATE: {
      const { shippingDate, selectedIdsSet } = action.payload;
      return {
        ...shipment,
        directoryOrders: shipment.directoryOrders.map(dOrder =>
          selectedIdsSet.has(dOrder.directory)
            ? {
                ...dOrder,
                shippingDate
              }
            : dOrder
        )
      };
    }

    case SET_SHIPMENT_GROUP_ID:
      return { ...shipment, id: action.payload };

    case ADD_SHIPMENT_GROUP_RECIPIENTS_START:
      return { ...shipment, status: shipmentStatuses.adding };

    case ADD_SHIPMENT_GROUP_RECIPIENTS_SUCCESS:
      return { ...shipment, status: shipmentStatuses.success };
    case CREATED_SHIPMENT_GROUP_DIRECTORY_ORDERS:
      return { ...shipment, status: shipmentStatuses.idle };
    default:
      return shipment;
  }
};

export default ShipmentGroupReducer;
