import log from '../logger';

const toErrorPage = (err, history) => {
  // TODO this should be reworked to include more context, maybe
  // TODO a data param & avoid going to /error when err === 401
  log.debug('Error detected:', err);
  if (process.env.REACT_APP_LOG_ROCKET_PROJECT_ID && window.location.hostname !== 'localhost') {
    // eslint-disable-next-line global-require
    const LogRocket = require('logrocket');
    LogRocket.captureException(err);
  }

  // TODO this is not enough
  if (err !== 401)
    history.push({
      pathname: '/error',
      state: { targetError: err, pathname: window.location.pathname, fullURL: window.location.href }
    });
};

export default toErrorPage;
