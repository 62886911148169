export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '0 1px 2px 1px #ccc',
    padding: 20,
    margin: 5,
    maxWidth: 400,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: 270,
      height: 270,
      padding: '10px 20px'
    }
  },
  customPackImage: {
    border: '2px dashed #3577d4',
    borderRadius: 20,
    marginBottom: 7,
    [theme.breakpoints.down('sm')]: {
      height: 220,
      width: 220,
      marginBottom: 0
    }
  },
  image: {
    height: 337,
    width: 337,
    [theme.breakpoints.down('sm')]: {
      height: 220,
      width: 220
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: 16,
    fontWeight: 500
  },
  selectText: {
    fontFamily: 'Gilroy-Bold',
    color: '#3577d4'
  }
});
