import { ellipsisStyles } from '../../../../shared/styles/commonStyles';

const commonFontStyle = {
  fontFamily: 'Gilroy',
  fontSize: 12,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal'
};

const textMaxWidth = (theme, initialWidth) => ({
  maxWidth: initialWidth,
  [theme.breakpoints.down('lg')]: {
    maxWidth: initialWidth - 85
  }
});

const styles = theme => ({
  root: {
    border: '1px solid #d4d9e2',
    borderRadius: 4,
    padding: 0,
    maxWidth: 340,
    minWidth: 262
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: 271,
    paddingTop: 15
  },
  cardInfoContainer: {
    padding: 16,
    flexGrow: 2
  },
  multipleProductsStatusContainer: {
    position: 'absolute',
    width: 140,
    marginLeft: -70,
    left: '50%',
    bottom: 17
  },
  multipleProductsStatus: {
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#434c5f',
    backgroundColor: '#ecedef !important',
    '& > img': { width: 16, height: 16, marginRight: 4 },
    '& > .MuiTypography-root': {
      fontSize: 10,
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'uppercase'
    }
  },
  packTitle: {
    ...commonFontStyle,
    ...ellipsisStyles,
    ...textMaxWidth(theme, 250),
    fontWeight: 'bold',
    color: '#434c5f',
    marginBottom: 16
  },
  packNormaltext: {
    ...commonFontStyle,
    ...ellipsisStyles,
    color: '#434c5f',
    marginBottom: 0,
    opacity: '0.9'
  },
  trackingInfoLink: {
    ...commonFontStyle,
    ...ellipsisStyles,
    marginLeft: 8,
    marginBottom: 0
  },
  linkAlike: {
    color: '#3577d4',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  packEmployeeName: {
    ...commonFontStyle,
    ...ellipsisStyles,
    ...textMaxWidth(theme, 280),
    fontWeight: 'bold',
    color: '#434c5f',
    marginBottom: 16
  },
  packDateText: {
    ...commonFontStyle,
    color: '#434c5f',
    marginBottom: 0,
    opacity: '0.9',
    textAlign: 'right'
  },
  packDeletetext: {
    ...commonFontStyle,
    textAlign: 'right',
    color: '#f44336',
    marginTop: 'auto',
    padding: 0
  },
  packButtonsContainer: {
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center'
  },
  packViewDetails: {
    background: 'transparent',
    whiteSpace: 'nowrap',
    padding: '3px 15px',
    '&:hover': {
      background: 'transparent'
    }
  },
  infoIcon: {
    height: 18,
    width: 18,
    color: '#989ea4'
  },
  tooltip: {
    fontSize: 12,
    textAlign: 'center',
    width: 320
  }
});

export default styles;
