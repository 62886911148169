import commonStyles, { commonText } from './commonStyles';
import { scrollBar } from '../../shared/styles/commonStyles';

export default {
  ...commonStyles,
  productImg: {
    borderRadius: 10,
    border: 'solid 1px #ebeef2'
  },
  productName: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    color: '#0f2440',
    marginBottom: 10
  },
  productCommon: {
    ...commonText,
    marginBottom: 10
  },
  productTextDark: { color: '#0f2440' },
  employeesList: {
    ...scrollBar
  },
  employeeName: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    color: '#0f2440',
    marginBottom: 8
  },
  employeeAddress: {
    ...commonText,
    fontSize: 14
  },
  squareButton: {
    width: 32,
    minWidth: 32,
    height: 32,
    borderRadius: 10,
    padding: 0
  },
  expandCollapseButton: {
    border: 'solid 1px #8d9299',
    backgroundColor: '#ffffff',
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)' }
  },
  expandCollapseButtonIcon: {
    color: '#434d5c',
    fontSize: 14
  },
  deleteButton: {
    backgroundColor: 'rgba(244, 67, 54, 0.08)',
    marginTop: 6,
    marginRight: 6,
    '&:hover': { backgroundColor: 'rgba(244, 67, 54, 0.2)' }
  },
  deleteButtonIcon: {
    color: '#f44336',
    fontSize: 14
  },
  listItem: {
    marginBottom: 33,
    cursor: 'default',
    '&~&': { marginBottom: 0, height: '45px !important' }
  }
};
