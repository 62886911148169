import { SET_SHOW_DASHBOARD_MESSAGE } from '../actions/types';

const DashboardBarMessageReducer = (state = true, action) => {
  switch (action.type) {
    case SET_SHOW_DASHBOARD_MESSAGE:
      return action.payload;

    default:
      return state;
  }
};

export default DashboardBarMessageReducer;
