import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';

import { Typography as CustomTypography } from '@swagup-com/components';

import style from './PaymentFailedAlert.style';

const useStyles = makeStyles(style);

const PaymentFailedAlert = ({ title, message, highlightedText = '', ActionButtons = <></> }) => {
  const classes = useStyles();

  return (
    <Grid container xs={12} className={classes.paymentFailedAlertWrapper}>
      <Grid item container xs={6}>
        <Grid item xs={1}>
          <WarningIcon className={classes.paymentFailedAlertTitle} />
        </Grid>
        <Grid item xs={11}>
          <CustomTypography variant="body2SemiBoldInter" className={classes.paymentFailedAlertTitle}>
            {title}
          </CustomTypography>
        </Grid>
        <Grid item xs={1} />
        <Grid item container xs={11} offset={1}>
          <CustomTypography variant="body3RegularInter" className={classes.paymentFailedAlertMessage}>
            {message}
            {highlightedText && (
              <>
                {' '}
                <span style={{ fontWeight: 600 }}>{highlightedText}</span>
              </>
            )}
          </CustomTypography>
        </Grid>
      </Grid>

      <ActionButtons />
    </Grid>
  );
};

export default PaymentFailedAlert;
