export default theme => ({
  thankYou: {
    color: '#0f2440',
    fontSize: 48,
    fontWeight: 500
  },
  advice: {
    opacity: 0.54,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    marginBottom: 24,
    color: '#0f2440'
  },
  contact: {
    border: 'solid 1px #ebeef2',
    padding: '24px 46px',
    borderRadius: 15,
    maxWidth: 500,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  },
  address: {
    color: '#0f2440',
    fontWeight: 500,
    fontFamily: 'Gilroy-SemiBold',
    opacity: 0.56,
    fontSize: 16
  },
  email: {
    color: '#0f2440',
    fontWeight: 500,
    fontFamily: 'Gilroy-SemiBold',
    opacity: 0.56,
    fontSize: 16,
    paddingBottom: 12
  },
  name: {
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    marginBottom: 16,
    maxWidth: 364,
    fontWeight: 500
  },
  container: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  }
});
