import * as React from 'react';
import {
  AccountBalance,
  CalendarToday,
  Close,
  ExpandLess,
  ExpandMore,
  FlashOn,
  LocalShipping,
  Receipt,
  RemoveCircleOutline,
  Warning
} from '@material-ui/icons';
import {
  Box,
  Grid,
  IconButton,
  Switch,
  Tooltip as MuiTooltip,
  makeStyles,
  withStyles,
  Dialog,
  DialogContent,
  Tooltip,
  Divider
} from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { StylessButton } from '../../../../../buttons';
import { productImageBasedOnStatus } from '../../../../../global/proofsCommon';
import Img from '../../../../../shared/Img';
import { cardImg, imageSrcSet, moneyStr } from '../../../../../../helpers/utils';
import { useMembership } from '../../../../../../contexts/membershipContext';
import {
  updateSelectedRecipientsShippingDate,
  updateSelectedRecipientsShippingMethod
} from '../../../../../../actions/shipmentGroupActions';
import CalendarModal from '../../../../../global/CalendarModal';
import { shouldDisableDate } from '../../../../../../helpers/commonDateFunctions';
import { useShippingCutoffHour } from '../../../../../../hooks';
import { ellipsisStyles } from '../../../../../shared/styles/commonStyles';

const styles = {
  arrowIcon: { width: 20, height: 20, color: '#787b80', marginTop: 1 },
  unhighlightedText: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.57,
    color: '#787b80'
  },
  buttonOpenItem: {
    borderRadius: 4,
    height: '24px !important',
    width: '24px !important',
    border: '1px solid #D6D8DB !important',
    zIndex: 999
  },
  productImageWrapper: {
    height: 118,
    width: 118,
    position: 'relative'
  },
  productImage: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  cardSectionTitle: {
    fontFamily: 'Inter',
    color: '#000',
    fontSize: 18,
    fontWeight: 600
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: 12,
    color: '#8d9299',
    marginBottom: 12
  },
  commonSubTitle: {
    fontFamily: 'Inter',
    fontSize: 16,
    color: '#131415',
    marginBottom: 6,
    fontWeight: 600
  },
  deliveryMethod: {
    fontFamily: 'Inter',
    fontSize: 14,
    color: '#0f2440',
    padding: '5px 8px',
    border: 'solid 1px #8d9299',
    borderRadius: 6,
    marginLeft: 12
  },
  shippingNotes: {
    fontFamily: 'Inter',
    fontSize: 14,
    color: '#434d5c',
    lineHeight: 1.57,
    whiteSpace: 'pre-wrap'
  },
  paymentWrapper: {
    width: '100%',
    padding: '12px 0px'
  },
  paymentMethodsHeader: {
    position: 'relative'
  },
  paymentMethodsHeaderContent: {
    position: 'absolute',
    width: '100%'
  },
  shippingValues: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    color: '#4A4F54'
  },
  iconButton: {
    width: 24,
    height: 24,
    padding: 2
  },
  swagCards: { paddingTop: 32 },
  swagCard: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 20
  },
  bottomActionMenuContainer: {
    // width: '100%',
    position: 'fixed',
    bottom: 24
  },
  bottomActionMenu: {
    // width: 682,
    padding: '6px 4px 6px 32px',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    boxShadow: '0px 20px 40px 2px rgba(0, 0, 0, 0.12)'
  },
  bottomActionMenuIcon: {
    width: '24px',
    height: '24px',
    color: '#000000'
  },
  bottomActionMenuButton: {
    '&:hover': {
      '& svg': {
        color: '#3577D4'
      },
      '& p': {
        color: '#3577D4'
      }
    }
  },
  bottomActionMenuRemoveButton: {
    '& svg': {
      color: '#F44336'
    },
    '& p': {
      color: '#F44336'
    },
    '&:hover': {
      '& svg': {
        color: '#E40306 !important'
      },
      '& p': {
        color: '#E40306 !important'
      }
    }
  },
  bottomActionMenuCloseButton: {
    '& svg': {
      color: '#434c5f'
    },
    '&:hover': {
      '& svg': {
        color: '#000000 !important'
      }
    }
  },
  divider: { background: '#E5E7E8', height: 32 },
  dialog: {
    width: 564,
    borderRadius: 20,
    padding: 24,
    overflowY: 'visible'
  },
  dialogTitle: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'center'
  },
  icon: { width: 18, height: 18, color: '#787B80' },
  closeButton: { width: 18, height: 18, color: '#787B80', padding: 0 },
  warningIconContainer: {
    position: 'relative'
  },
  dialogWarningIcon: {
    position: 'absolute',
    width: 92,
    height: 82,
    left: -46,
    top: -72
  },
  dialogContent: { paddingTop: 24 },
  errorsBadge: ({ errors }) => ({
    padding: '1px 4px',
    paddingRight: 10,
    fontSize: 10,
    borderRadius: 10,
    color: errors ? '#F40306' : '#F44336',
    // border: errors ? '1px solid #F40306' : '1px solid #F44336',
    background: errors ? '#FFE1E5' : undefined
  }),
  warningsBadge: ({ warnings }) => ({
    padding: '1px 4px',
    paddingRight: 10,
    fontSize: 10,
    borderRadius: 10,
    color: warnings ? '#FE6E06' : '#FE8E26',
    // border: warnings ? '1px solid #FE6E06' : '1px solid #FE8E26',
    background: warnings ? '#FFF1E5' : undefined
  }),
  productName: { ...ellipsisStyles, color: '#131415', marginRight: 12, maxWidth: 400 }
};

const useStyles = makeStyles(styles);

const tooltip = {
  root: {
    boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    backgroundImage: 'none',
    color: '#0b1829',
    borderRadius: 10
  },
  text: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 'normal',
    textAlign: 'center'
  }
};

const useWarningIconStyles = makeStyles({
  tooltip: {
    ...tooltip.root,
    ...tooltip.text,
    padding: '11px 14px 16px',
    lineHeight: 1.5,
    maxWidth: 228
  },
  cell: {
    maxWidth: 36,
    borderRight: 'none',
    '& .divCell': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center'
    },
    '& svg': {
      fontSize: 16,
      color: ({ type }) => (type === 'error' ? '#f44336' : '#fe8e26')
    }
  }
});

const ToogleButtom = ({ onClick, opened, style }) => {
  const classes = useStyles();
  return (
    <StylessButton onClick={onClick} className={classes.buttonOpenItem} style={style}>
      {opened ? <ExpandLess className={classes.arrowIcon} /> : <ExpandMore className={classes.arrowIcon} />}
    </StylessButton>
  );
};

const WarningIcon = ({ type, text, marginTop, marginBottom, small, noTooltip }) => {
  const classes = useWarningIconStyles({ type });
  const [open, setOpen] = React.useState(false);

  return (
    <Tooltip
      open={open}
      title={text || ''}
      placement="top"
      classes={{ tooltip: classes.tooltip }}
      arrow
      disableFocusListener={noTooltip}
    >
      <Box width={18} className={classes.cell} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        {text && <Warning style={{ marginTop, marginBottom, ...(small ? { width: 12, height: 12 } : {}) }} />}
      </Box>
    </Tooltip>
  );
};

const ProductInformation = ({ product, name, info, showWarning }) => {
  const noImage = product === undefined;
  const classes = useStyles({ noImage });

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Box className={classes.productImageWrapper}>
          {!noImage && (
            <Img src={productImageBasedOnStatus(product, 180, 180)} alt={name} className={classes.productImage} />
          )}
        </Box>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item title={name}>
            <Typography variant="body2SemiBoldInter" className={classes.productName}>
              {name}
            </Typography>
          </Grid>
          <Grid item>
            {showWarning && (
              <WarningIcon
                text="You haven't selected any shipment quantity for this product"
                type="warning"
                marginBottom={-3}
              />
            )}
          </Grid>
        </Grid>
        <Typography variant="body3RegularInter" className={classes.rowInfo} style={{ color: '#131415', marginTop: 4 }}>
          {info}
        </Typography>
      </Grid>
    </Grid>
  );
};

const FileUploadZone = ({ handleFileUpload, children, disabled, renderComponent }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileUpload,
    accept:
      'image/*, application/pdf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/docx,application/pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return (
    <Grid container {...getRootProps()} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
      <input {...getInputProps()} disabled={disabled} />
      {renderComponent ? renderComponent({ isDragActive }) : children}
    </Grid>
  );
};

const SmallSwitch = withStyles({
  root: {
    overflow: 'visible',
    height: 24,
    width: 28,
    padding: '6px 0px 6px 0px',
    '& .Mui-disabled .MuiSwitch-thumb': {
      backgroundColor: '#C6C8CB !important'
    },
    '& .Mui-disabled  MuiSwitch-track': {
      backgroundColor: '#000000 !important'
    }
  },
  switchBase: {
    height: 24,
    padding: 0,
    color: '#B7BBBF',
    '&$checked': {
      color: '#4CAF50',
      transform: 'translateX(10px)'
    },
    '&$checked + $track': {
      backgroundColor: '#4CAF50',
      opacity: 0.5
    },
    '&.MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#000000',
      opacity: 0.1
    },
    '& .MuiSwitch-thumb': {
      width: 18,
      height: 18
    }
  },
  checked: {},
  track: { backgroundColor: '#00000029', opacity: 0.8 },
  thumb: {
    boxShadow: 'none'
  }
})(Switch);

const ShipmentSection = ({ title, employeesQty, totalPrice, itemsQty }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="center" style={{ marginBottom: 8 }}>
        <p className={classes.cardSectionTitle}>{title}</p>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <p className={classes.commonSubTitle}>Total recipients</p>
          <p className={classes.shippingValues}>{employeesQty}</p>
        </Grid>
        {itemsQty && (
          <Grid item>
            <p className={classes.commonSubTitle}>Total items</p>
            <p className={classes.shippingValues}>{itemsQty}</p>
          </Grid>
        )}
        <Grid item style={{ textAlign: 'right' }}>
          <p className={classes.commonSubTitle}>Amount</p>
          <p className={classes.shippingValues}>{moneyStr(totalPrice)}</p>
        </Grid>
      </Grid>
    </>
  );
};

const FinalPaymentItem = ({ allowToPayWithCredits, swagCardPayment, creditCardPayment }) => {
  const classes = useStyles();

  const { company } = useMembership();
  const { payment_profile: defaultProfile } = company || {};
  const paymentProfileCard = defaultProfile?.credit_card || {};
  const currentPaymentMethod = window.sessionStorage.getItem('method');
  const [isOpen, setIsOpen] = React.useState(true);
  return (
    <Box className={classes.paymentWrapper}>
      <Box className={classes.paymentMethodsHeader}>
        <Grid container className={classes.paymentMethodsHeaderContent} justifyContent="flex-end" alignItems="center">
          <Grid item xs>
            <Typography variant="body4RegularInter" style={{ color: '#4A505C' }}>
              Payment Method{allowToPayWithCredits ? 's' : ''}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton className={classes.iconButton} onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      {isOpen && (
        <Box className={classes.swagCards}>
          {allowToPayWithCredits && (
            <Grid container alignItems="center" className={classes.swagCard}>
              <Grid item xs={8}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body3RegularInter" style={{ color: '#4A505C' }}>
                      Payment with Swag Card
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img
                      srcSet={imageSrcSet('/images/orders/swag_credit_card.png')}
                      alt="credit-card"
                      style={{ width: 32, marginBottom: -6, marginRight: -4 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Typography variant="body3SemiBoldInter" style={{ color: '#45AF5F', textAlign: 'right' }}>
                  {moneyStr(swagCardPayment > 0 ? swagCardPayment : 0)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            alignItems="center"
            className={classes.swagCard}
            style={{ borderBottom: '1px solid #E5E7E8' }}
          >
            <Grid item xs={8}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body3RegularInter" style={{ color: '#4A505C' }}>
                    Payment with {currentPaymentMethod}
                  </Typography>
                </Grid>
                <Grid item>
                  {currentPaymentMethod === 'Credit Card' ? (
                    <img
                      src={cardImg(paymentProfileCard.card_type)}
                      alt={paymentProfileCard.Visa}
                      style={{ width: 24, height: 16, marginBottom: -2 }}
                    />
                  ) : (
                    <div>
                      {currentPaymentMethod === 'ACH' ? (
                        <AccountBalance style={{ width: 24, height: 16, marginBottom: -2, color: 'rgb(74, 80, 92)' }} />
                      ) : (
                        <Receipt style={{ width: 24, height: 16, marginBottom: -2, color: 'rgb(74, 80, 92)' }} />
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Typography variant="body3SemiBoldInter" style={{ color: '#4A505C', textAlign: 'right' }}>
                {moneyStr(creditCardPayment > 0 ? creditCardPayment : 0)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#3B4048',
    maxWidth: 'unset',
    padding: 10,
    borderRadius: 4,
    '& > p': { color: '#FFF' }
  },
  arrow: {
    color: '#3B4048'
  }
})(({ children, title, ...props }) => (
  <MuiTooltip
    arrow
    title={title ? <Typography variant="body3RegularInter">{title}</Typography> : ''}
    placement="top"
    {...props}
  >
    {children}
  </MuiTooltip>
));

// const getMethodsByName = (directoryOrders, name) =>
// directoryOrders.map(d => d.deliveryMethods.find(dm => dm.name === name)).filter(Boolean);

const BottomActionMenu = ({ selectedCount, onRemove, onClose, selectedIdsSet, showCalendar }) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  // const directoryOrders = useSelector(state =>
  //   state.shipmentGroup.directoryOrders
  //     .filter(d => selectedIdsSet.has(d.directory))
  //     .filter(d => !isEmpty(d.deliveryMethods))
  // );

  // const standardMethods = React.useMemo(() => getMethodsByName(directoryOrders, 'Standard'), [directoryOrders]);
  // const expressMethods = React.useMemo(() => getMethodsByName(directoryOrders, 'Express'), [directoryOrders]);

  const dispatch = useDispatch();

  const handleSelect = methodName => {
    dispatch(
      updateSelectedRecipientsShippingMethod({
        methodName,
        selectedIdsSet
      })
    );
    onClose();
  };

  const handleChangeShippingDate = shippingDate => {
    dispatch(
      updateSelectedRecipientsShippingDate({
        shippingDate,
        selectedIdsSet
      })
    );
    onClose();
  };
  const shippingCutoffHour = useShippingCutoffHour();
  const classes = useStyles();

  if (selectedCount === 0) return null;
  return (
    <Box className={classes.bottomActionMenuContainer}>
      <Box className={classes.bottomActionMenu}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs>
            <Typography variant="body3SemiBoldInter" style={{ marginRight: 16 }}>{`${selectedCount} recipient${
              selectedCount > 1 ? 's' : ''
            } selected`}</Typography>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>
          {showCalendar && (
            <>
              <Grid item>
                <Button variant="text" className={classes.bottomActionMenuButton} onClick={() => setOpenCalendar(true)}>
                  <Box>
                    <CalendarToday className={classes.bottomActionMenuIcon} />
                    <Typography variant="body3MediumInter">Shipping Date</Typography>
                  </Box>
                </Button>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" className={classes.divider} />
              </Grid>
            </>
          )}
          <Grid item>
            <Button variant="text" className={classes.bottomActionMenuButton} onClick={() => handleSelect('Standard')}>
              <Box>
                <LocalShipping className={classes.bottomActionMenuIcon} />
                <Typography variant="body3MediumInter">Standard</Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" className={classes.bottomActionMenuButton} onClick={() => handleSelect('Express')}>
              <Box>
                <FlashOn className={classes.bottomActionMenuIcon} />
                <Typography variant="body3MediumInter">Express</Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>
          <Grid item>
            <Button variant="text" className={classes.bottomActionMenuRemoveButton} onClick={onRemove}>
              <Box>
                <RemoveCircleOutline className={classes.bottomActionMenuIcon} />
                <Typography variant="body3MediumInter">Remove</Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>
          <Grid item>
            <Button variant="text" onClick={onClose} className={classes.bottomActionMenuCloseButton}>
              <Box>
                <Close className={classes.bottomActionMenuIcon} />
              </Box>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <CalendarModal
        key={openCalendar}
        open={openCalendar}
        onSubmit={handleChangeShippingDate}
        onClose={() => setOpenCalendar(false)}
        disablePast
        shouldDisableDate={date => shouldDisableDate(date, shippingCutoffHour)}
        initialDate={new Date()}
        variant="square"
      />
    </Box>
  );
};

const RemoveDialog = ({ onRemove, title, actioButtonText, cancelButtonText, open, onClose, titleSize = 8 }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }} className={classes.modalDialog}>
      <Grid container justifyContent="center">
        <Grid item className={classes.warningIconContainer}>
          <Img src="/images/public/wanring.png" className={classes.dialogWarningIcon} />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={titleSize}>
          <Typography variant="body2MediumInter" className={classes.dialogTitle}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <DialogContent className={classes.dialogContent}>
        <Grid container justifyContent="center">
          <Grid item>
            <Button variant="primary" size="small" onClick={onRemove}>
              {actioButtonText}
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ paddingTop: 8 }}>
          <Grid item>
            <Button variant="text" size="small" onClick={onClose}>
              {cancelButtonText}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const ErrorsWarningsSelector = ({
  onToogleError,
  onToogleWarning,
  errors,
  warnings,
  totalErrors,
  totalWarnings,
  errorOff,
  warningsOff
}) => {
  useEffect(() => {
    if (totalErrors === 0) errorOff();
    if (totalWarnings === 0) warningsOff();
  }, [totalErrors, totalWarnings]);
  const classes = useStyles({ errors, warnings });
  return (
    <Grid container alignItems="center" spacing={2}>
      {totalErrors > 0 && (
        <Grid item>
          <IconButton onClick={onToogleError} className={classes.errorsBadge}>
            <WarningIcon text="Filter all Errros" marginBottom={-2} type="error" small noTooltip />
            <span style={{ marginLeft: 2 }}>
              {totalErrors} {`Error${totalErrors > 1 ? 's' : ''}`}
            </span>
          </IconButton>
        </Grid>
      )}
      {totalWarnings > 0 && (
        <Grid item>
          <IconButton onClick={onToogleWarning} className={classes.warningsBadge}>
            <WarningIcon text="Filter all Warnings" marginBottom={-2} type="warning" small noTooltip />
            <span style={{ marginLeft: 2 }}>
              {totalWarnings} {`Warning${totalWarnings > 1 ? 's' : ''}`}{' '}
            </span>
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export {
  ToogleButtom,
  ProductInformation,
  useWarningIconStyles,
  FileUploadZone,
  SmallSwitch,
  ShipmentSection,
  FinalPaymentItem,
  CustomTooltip,
  BottomActionMenu,
  RemoveDialog,
  WarningIcon,
  ErrorsWarningsSelector
};
