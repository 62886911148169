import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { InfoOutlined } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/appliedMembershipPanel';
import { intStr } from '../../helpers/utils';
import Tooltip from '../shared/Tooltip';

const useStyles = makeStyles(styles);
const BenefitTab = ({ icon, text, value, type = 'discount', size, alone, earlyBirdPromotion }) => {
  const classes = useStyles({ discount: type === 'discount', alone, earlyBirdPromotion });
  const finalIcon = !isEmpty(earlyBirdPromotion) ? '/images/public/early-bird/early-bird.png' : icon;
  let realValue = value;
  if (type === 'reward') realValue = value > 0 ? intStr(value) : 0;

  const earlyBirdText = !earlyBirdPromotion?.isFreeTier ? (
    <span>
      <span style={{ textDecoration: 'line-through' }}>{`${earlyBirdPromotion?.reward_points_rate / 2}X`}</span>
      {` ${earlyBirdPromotion?.reward_points_rate}X Reward Points`}
    </span>
  ) : (
    <span style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
      {`Just ${earlyBirdPromotion?.reward_points_rate}X Reward Points`}
      <Tooltip
        interactive
        title="By upgrading to a membership you will be eligible for the early bird special offer"
        placement="top"
        width={242}
        backgroundColor="white"
        fontColor="#0B1829"
        fontSize={14}
        enterTouchDelay={50}
        leaveTouchDelay={5000}
      >
        <InfoOutlined style={{ margin: '0px 4px', height: 12, width: 12, color: '#FFFFFF' }} />
      </Tooltip>
      <Link
        style={{
          width: 52,
          height: 20,
          padding: '5px 5px',
          fontSize: 10,
          lineHeight: '10px',
          borderRadius: 10,
          background: '#125CFF',
          color: '#FFFFFF'
        }}
        to="/membership/manage-membership"
      >
        Upgrade
      </Link>
    </span>
  );

  const { leftBarNavigation } = useFlags();
  const finalText = !isEmpty(earlyBirdPromotion) ? earlyBirdText : text;
  return (
    <Grid container alignItems="center" className={classes.benefitTab}>
      <Grid item>
        <Link
          to={`${leftBarNavigation ? '/settings/your-membership' : '/membership/your-membership'}${
            type === 'reward' ? '?tab=rewards' : ''
          }`}
        >
          <Box className={classes.iconWrapper}>
            <img src={finalIcon} alt={text} className={classes.icon} />
          </Box>
        </Link>
      </Grid>
      <Grid item xs>
        <Typography className={classes.benefitText} style={{ fontSize: size === 'small' ? 10 : undefined }}>
          {finalText}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.benefitText}>{realValue}</Typography>
      </Grid>
    </Grid>
  );
};

export default BenefitTab;
