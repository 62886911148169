import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = theme => ({
  container: {
    background: '#6AC6DE',
    borderRadius: 6,
    marginBottom: 24
  },
  birdImageWrapper: {
    height: 100,
    width: 134,
    position: 'relative'
  },
  birdImg: {
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  },
  earlyBirdText: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: 28,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-0.84px',
    '& strong': {
      color: '#125CFF'
    },
    '& span': {
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '-0.72px'
    },
    '& p': {
      color: '#FFFFFF',
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '-0.72px',
      marginBottom: 0
    }
  },
  membershipCTA: {
    height: ' 50px',
    border: 'none',
    fontFamily: 'Inter',
    ffontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  noMembershipCTA: {
    height: ' 50px',
    background: '#125CFF',
    border: 'none',
    fontFamily: 'Inter',
    ffontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  closeIconButton: { padding: 6 },
  closeIcon: {
    color: '#FFFFFF',
    height: 18,
    width: 18
  }
});

export default styles;
