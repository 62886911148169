export default {
  header: {
    color: '#0B1829',
    fontFamily: 'Gilroy',
    fontSize: 24,
    fontWeight: 'bold'
  },
  decorationEditSection: {
    paddingTop: 24,
    paddingBottom: 72
  },
  messageContainer: {
    borderBottom: '1px solid #E8E9EB',
    paddingBottom: 16,
    marginBottom: 32
  },
  timeStamp: {
    fontFamily: 'Gilroy',
    color: '#787B80',
    fontSize: 14
  },
  continueSection: {
    paddingBottom: 45,
    paddingTop: 18,
    backgroundColor: '#ffffff',
    position: 'sticky',
    bottom: 0
  },
  description: {
    fontFamily: 'Gilroy',
    color: '#787B80',
    fontSize: 14,
    alignItems: 'center',
    fontWeight: 300,
    whiteSpace: 'break-spaces',
    width: '100%',
    '& strong': {
      fontWeight: 500,
      paddingLeft: 0
    }
  },
  descriptionOnly: {
    fontFamily: 'Gilroy',
    color: '#686B70',
    fontSize: 14,
    fontWeight: 400,
    '& p': { color: '#686B70' }
  },
  product: {
    fontFamily: 'Gilroy',
    color: '#0B1829',
    fontWeight: 500,
    fontSize: 14,
    marginTop: 6
  },
  from: {
    fontFamily: 'Gilroy',
    color: '#0B1829',
    fontSize: 14,
    marginBottom: 0
  },
  iconWrapper: {
    textAlign: 'center',
    display: 'table',
    height: '100%'
  },
  decorationHistorySection: {
    width: '100%'
  },
  headerContainer: {
    position: 'sticky',
    top: 0,
    background: '#ffffff'
  },
  icon: {
    color: '#0B1829',
    display: 'table-cell',
    verticalAlign: 'middle',
    height: 18,
    width: 20
  },
  artwork: {
    height: 32,
    maxWidth: 124,
    objectFit: 'contain'
  },
  nonImage: {
    height: 20,
    marginBottom: -4,
    maxWidth: 124,
    objectFit: 'contain'
  },
  colorIcon: {
    display: 'inline-block',
    height: 14,
    width: 14,
    borderRadius: 7,
    marginBottom: -3,
    border: '1px solid #787B80',
    marginRight: 6,
    marginLeft: 3
  },
  artworkOld: {
    height: 124,
    maxWidth: 124,
    objectFit: 'contain'
  },
  newWindow: {
    paddingBottom: '0px !important'
  },
  historyHeader: {
    background: '#ffffff'
  },
  historyContainer: {
    display: 'contents'
  },
  decoration: { marginTop: 8 },
  logo: { marginBottom: -2 }
};
