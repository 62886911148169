import { scrollBar } from '../../../shared/styles/commonStyles';

export default theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fafafa',
    overflowX: 'hidden',
    height: 'calc(100vh - 100px)', // height of the header
    '& *::-webkit-scrollbar': {
      backgroundColor: '#e9f0fa',
      width: 4,
      borderRadius: 4
    },
    '& *::-webkit-scrollbar-thumb': {
      backgroundColor: '#d4d9e2',
      width: 10,
      borderRadius: 10
    }
  },
  innerContainer: {
    height: 'calc(100% - 95px)',
    maxWidth: 1280,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 1120
    },
    [theme.breakpoints.down('sm')]: { minWidth: '320px' }
  },
  centerContainer: {
    height: '100%',
    flexFlow: 'column',
    maxWidth: 930,
    padding: '30px 50px 0 0',
    [theme.breakpoints.down(1281)]: {
      maxWidth: 890,
      padding: '30px 20px 0 0'
    }
  },
  controlsContainer: {
    marginTop: 20
  },
  titleContainer: {
    marginTop: 17
  },
  rightContainer: {
    maxWidth: '100%',
    position: 'relative',
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: '#ffffff',
      width: '100vw',
      height: 'calc(100% + 95px)',
      zIndex: 1
    }
  },
  tableContainer: {
    marginTop: 20,
    flex: '1 1 auto',
    ...scrollBar
  },
  bottomContainer: {
    position: 'relative',
    width: '100%',
    height: 95,
    backgroundColor: '#ffffff',
    borderTop: '2px solid #d4d9e2',
    boxShadow: '0 15px 65px 0 rgba(26, 58, 98, 0.05)',
    '& > div': { height: '100%' }
  }
});
