import * as React from 'react';
import Carousel from '../../../../opportunities/components/Carousel';
import { productImageBasedOnStatus } from '../../../../global/proofsCommon';
import { isPack } from '../../../../../helpers/utils';

const ProductImage = ({ product }) => {
  const imageSources = isPack(product.record_type)
    ? [
        productImageBasedOnStatus(product, 180, 180),
        ...product.items.map(i => productImageBasedOnStatus(i.product, 180, 180))
      ]
    : [productImageBasedOnStatus(product, 180, 180)];

  return (
    <Carousel
      width={140}
      height={140}
      imageSources={imageSources}
      leftButtonStyle={{ left: '-8%' }}
      rightButtonStyle={{ right: '-8%' }}
    />
  );
};

export default ProductImage;
