const storefrontsServicesPaths = {
  storefronts: '/storefronts',
  storefrontsByAccount: account => `/storefronts/by-account/${account}`,
  storefront: slug => `/storefronts/${slug}`,
  orders: id => `/orders/${id}`,
  order: (accId, id) => `/orders/${accId}/${id}`,
  createOrders: '/orders',
  retryOrders: id => `/orders/retry/${id}`,
  cancelOrders: id => `/orders/cancel/${id}`,
  deleteOrders: id => `/orders/delete/${id}`,
  verifyAddress: '/verifications/verify-address',
  verifyName: '/verifications/verify-project-name',
  verifyEmail: '/verifications/verify-email-dns',
  export: id => `/orders/export/${id}`,
  summary: id => `/metrics/storefront-summary/${id}`,
  summaryAll: id => `/metrics/storefront-summary-all/${id}`,
  products: id => `/product-options/${id}`,
  customers: id => `/customers/${id}`,
  customerOrderHistory: (storeId, customerId) => `/customers/${storeId}/order-history/${customerId}`,
  existingCustomer: storeId => `/customers/${storeId}/existing-customers-by-email`,
  payouts: id => `/payouts/${id}`,
  payoutsSingle: (id, pid) => `/payouts/${id}/${pid}`,
  productsBulk: id => `/product-options-bulk/${id}`,
  customersBulk: id => `/customers/bulk/${id}`,
  customersAddByEmail: id => `/customers/${id}/add-credits-by-email`,
  customersFundsBulk: id => `customers/${id}/add-credits`,
  customerUpdate: (id, customerId) => `/customers/${id}/${customerId}`,
  bulkDelete: id => `customers/bulk-delete/${id}`,
  storeProducts: (storeId, productId) => `/product-options/${storeId}/${productId}`,
  payoutOrders: (storeId, payoutId) => `/payouts/${storeId}/orders/${payoutId}`,
  exportCustomers: storeId => `/customers/${storeId}/export`
};

export default storefrontsServicesPaths;
