import commonStyles from './commonStyles';

export default () => ({
  ...commonStyles,
  subTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    color: '#000',
    fontSize: 20
  },
  sendButton: {
    '&:hover': { backgroundColor: '#3577d4' },
    textTransform: 'none',
    backgroundColor: '#3577d4',
    borderRadius: 24,
    height: 48,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 16,
    color: '#ffffff',
    lineHeight: 'normal',
    '&.Mui-disabled': { backgroundColor: '#ecedef !important' }
  },
  errorAlert: {
    width: 'inherit',
    marginBottom: 25
  },
  summarySection: {
    padding: '24px 0px 16px',
    borderBottom: 'solid 1px #E5E7E8'
  }
});
