import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles/InventorySelected';
import { ImgWithHandler } from '../../global/ImgUtils';

const placeholder = classes => (
  <>
    <div className={classes.placeHolderContainer}>
      <div className={classes.placeholderContentBlue} />
    </div>
    <div className={classes.placeHolderContainer}>
      <div className={classes.placeholderTitle} />
      <div className={classes.placeholderContent} />
    </div>
    <div className={classes.placeHolderContainer}>
      <div className={classes.placeholderTitle} />
      <div className={classes.placeholderContent} />
    </div>
    <div className={classes.placeHolderContainer}>
      <div className={classes.placeholderTitle} />
      <div className={classes.placeholderContent} />
    </div>
    <div className={classes.placeHolderDualContainer}>
      <div className="first">
        <div className={classes.placeholderTitle} />
        <div className={classes.placeholderContent} />
      </div>
      <div className="second">
        <div className={classes.placeholderTitle} />
        <div className={classes.placeholderContent} />
      </div>
    </div>
  </>
);

const InventorySelected = ({ classes, inventory }) => (
  <div className={classes.container}>
    <p className={classes.title}>Items</p>
    {inventory.size > 0 ? (
      <ul className={classes.list}>
        {[...inventory.values()].map(item => (
          <li key={item.id} className={classes.listItem}>
            <div className={classes.photoContainer}>
              <ImgWithHandler src={item.image} width={40} height={40} style={{ objectFit: 'contain' }} />
            </div>
            <div className={classes.detailsContainer}>
              <p className={classes.itemName}>{item.name}</p>
              <p className={classes.itemColor}>
                Color: <span className={classes.itemColorValue}>{`${item.theme_color || 'Custom'}`}</span>
                <span
                  style={{
                    display: 'inline-block',
                    marginLeft: 8,
                    borderRadius: 8,
                    width: 12,
                    height: 12,
                    backgroundColor: `${item.theme_color_hex ? `#${item.theme_color_hex}` : 'transparent'}`
                  }}
                />
              </p>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      placeholder(classes)
    )}
  </div>
);

export default withStyles(styles)(InventorySelected);
