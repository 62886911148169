import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Checkbox, Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Add,
  Block,
  BlockOutlined,
  EmojiTransportation,
  EmojiTransportationOutlined,
  IndeterminateCheckBox
} from '@material-ui/icons';
import debounce from 'lodash/debounce';
import { statuses } from '../../../reducers/processReducer';
import { newPaginateEmployee, deleteEmployees } from '../../../actions';
import { EmptyState, Helmet, Pagination } from '../../shared';
import { usePagination, useQueryParams, useAsync } from '../../../hooks';
import ContactsList from './ContactsList';
import Actions from './Actions';
import Loader from '../../global/Loader';
import { SortByName, FilledSearchField } from '../../global/Filters';
import RemoveContactsModal from './RemoveContactsModal';
import toErrorPage from '../../../helpers/toErrorPage';
import tags from '../../../apis/seoTags';
import styles from '../../directory/employeesList/styles/EmployeesHome';
import log from '../../../logger';
import AddContactOptions from '../../addContacts/AddContactOptions';
import { commonSearchFields, buildSearchQuery } from '../../../helpers/utils';
import Groups from '../../groups/Groups';
import { useQueryFilterValidated } from '../../../hooks/useFilters';
import { useSelection } from './SelectionContext';
import SearchSortFilter from '../../shared/SearchSortFilter';
import ActionBar from '../../redeemPagesDev/components/ActionBar/ActionBar';
import { Truck } from '../../icons';
import { TruckOutlined } from '../../icons/shipments';

const AddContact = ({ classes, onClick }) => (
  <Grid container>
    <Grid item>
      <Grid container alignItems="center" alignContent="center" style={{ height: '100%' }}>
        <Grid item>
          <div className={classes.addContactDiv} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs>
      <Button variant="primary" onClick={onClick} style={{ width: 180, height: 58, fontSize: 15 }}>
        Add Contact <Add className={classes.addContactIcon} />
      </Button>
    </Grid>
  </Grid>
);

const queryBuilder = ({ search, sort, groups }) => {
  const searchQuery = buildSearchQuery(search, commonSearchFields);
  return `${searchQuery}&ordering=${sort}${groups ? `&group_ids=${groups}` : ''}`;
};

const searchSortFilterConfig = {
  search: { placeholder: 'Search Contacts' },
  sort: {
    queryParam: 'sort',
    options: {
      '-created_at': 'Most Recent',
      created_at: 'Less Recent',
      full_name: 'Name A - Z',
      '-full_name': 'Name Z - A'
    }
  }
};

const useStyles = makeStyles(styles);

const perPageOptions = [12, 24, 36, 48];
const Contacts = ({ selectedContacts, onSelectContacts, setExistContacts, sendSwag, selectedContactsNumbers }) => {
  const [total, setTotal] = useState(-1);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  // const [openAddContactModal, setOpenAddContactModal] = useState(false);
  const isSearching = useRef(false);
  const contacts = useSelector(state => state.employees);
  const processDone = useSelector(state => [statuses.idle, statuses.success].includes(state.process.status));

  const isHiddenAccount = useSelector(state => state.company.is_hidden_account);

  const isXS = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const { contactsFeGroupingTemp011921: grouping, leftBarNavigation } = useFlags();

  const {
    areAllSelected,
    isPartSelected,
    allowBulkRemove,
    excluded,
    toogleSelectionStatus,
    setScopeTotal,
    reset
  } = useSelection();

  // const areAllSelectedOld =
  //   contacts.every(c => selectedContacts?.has(c.id)) && contacts.every(c => selectedContacts?.has(c.id));
  // const isPartSelectedOld = !areAllSelectedOld && contacts.some(c => selectedContacts?.has(c.id));

  const dispatch = useDispatch();
  const memoizedEmployeePagination = useCallback(
    (limit, offset, query) => dispatch(newPaginateEmployee(limit, offset, query)),
    [dispatch]
  );
  const [paginate, response, isPending, hasFailed] = useAsync(memoizedEmployeePagination, 250);

  const query = useQueryParams();
  const search = query.get('search') || '';
  const groups = useQueryFilterValidated('groups');
  const openParam = query.get('add-open');
  const openAddContactModal = openParam === 'true';

  useEffect(() => {
    setExistContacts(false);
  }, [setExistContacts]);

  useEffect(() => {
    if (contacts.length === 0) setExistContacts(false);
  }, [contacts, setExistContacts]);

  useEffect(() => {
    if (response?.result === 'ok') {
      const { count } = response.data ?? 0;
      setTotal(!search && !isSearching.current && groups.length === 0 && count === 0 ? -2 : count);
      setScopeTotal(count);
      if (!search && !isSearching.current && count > 0) setExistContacts(true);
      isSearching.current = false;
    }
  }, [response, search, setExistContacts, groups, setScopeTotal]);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (response?.result === 'ok' && location.state?.recentlyCreated) {
      toogleSelectionStatus();
      history.replace({ ...location, state: { ...location, recentlyCreated: undefined } });
    }
  }, [history, location, response, toogleSelectionStatus]);

  useEffect(() => {
    if (hasFailed) toErrorPage(response, history);
  }, [hasFailed, response, history]);

  const { nextPage, pageIndex, perPage, sizeOptions, previousPage, changeSize } = usePagination(total, perPageOptions);

  const sort = query.get('sort') || '-created_at';
  const offset = pageIndex * perPage;
  log.debug('EmployeesHome paginate, offset:', offset, 'search:', search, 'sort:', sort, 'isPending:', isPending);

  useEffect(() => {
    paginate(perPage, offset, queryBuilder({ search, sort, groups }));

    return () => paginate.cancel();
  }, [offset, perPage, search, sort, processDone, paginate, groups]);

  const navigateToFirstPage = useCallback(() => {
    query.set('page', 1);
    history.replace({ pathname: history.location.pathname, search: query.toString() });
  }, [query, history]);

  const debouncedSearch = useCallback(
    debounce(text => {
      if (text) {
        query.set('search', text);
      } else {
        query.delete('search');
      }
      isSearching.current = true;
      navigateToFirstPage();
    }, 500),
    [navigateToFirstPage]
  );

  const handleSortChange = e => {
    query.set('sort', e.target.value);
    navigateToFirstPage();
  };

  const handleSelectedGroupsChange = groupsId => {
    if (groupsId) {
      query.set('groups', groupsId);
    } else {
      query.delete('groups');
    }
    isSearching.current = true;
    navigateToFirstPage();
  };

  const selectedNoPendingShipments = selectedContacts
    ? [...selectedContacts.values()].filter(c => !(c.has_pending_orders || c.is_in_shipment_groups))
    : [];

  const doPaginate = () => paginate(perPage, offset, queryBuilder({ search, sort, groups }));

  const removeSelected = result => {
    if (result === 'ok') {
      reset();
      doPaginate();
      setOpenRemoveModal(false);
    } else {
      toErrorPage('error on deleting contacts', history);
    }
  };

  const removeSelectedOld = async () => {
    const ids = selectedNoPendingShipments.map(c => c.id);
    const deleteResponse = await dispatch(deleteEmployees(ids));
    if (deleteResponse.result === 'ok') {
      selectedNoPendingShipments.forEach(c => selectedContacts.delete(c.id));
      onSelectContacts([selectedContacts]);
      doPaginate();
      setOpenRemoveModal(false);
    } else {
      toErrorPage(deleteResponse, history);
    }
  };

  const handleChangeSelection = () => {
    if (areAllSelectedOld) {
      contacts.forEach(c => selectedContacts.delete(c.id));
    } else {
      contacts.forEach(c => selectedContacts.set(c.id, c));
    }
    onSelectContacts([selectedContacts]);
  };

  const handleChangeActions = ({ target: { value } }) => {
    if (value === 'remove') setOpenRemoveModal(true);
    else if (value === 'ship') {
      if (grouping) {
        sendSwag(excluded, areAllSelected);
      } else {
        const ids = [...selectedContacts.keys()].join();
        if (ids.length > 0) {
          history.push({
            pathname: `/send-swag/select-products`,
            state: {
              contacts: [...selectedContacts.values()],
              from: location
            }
          });
        }
      }
    }
  };

  const handleOnAddContactModalClose = React.useCallback(() => {
    query.delete('add-open');
    history.replace({ ...history.location, search: query.toString() });
  }, [query, history]);

  const classes = useStyles();

  const toggleRemoveModal = () => setOpenRemoveModal(isOpen => !isOpen);

  if (contacts.length === 0 && total === -1) {
    return <Loader absolute />;
  }

  const { canceled } = response?.data ?? {};

  if (isHiddenAccount) {
    return (
      <>
        <Helmet tags={tags.contacts} />
        <EmptyState
          title="Contacts have been disabled for this account. Please reach out to your Account Executive for support."
          image={{
            path: '/images/staff/empty-contacts.png',
            alt: 'No contacts'
          }}
          marginTop={0}
        />
      </>
    );
  }

  return (
    <>
      <Helmet tags={tags.contacts} />
      <AddContactOptions open={openAddContactModal} onClose={handleOnAddContactModalClose} />
      {total === -2 ? (
        <EmptyState
          title="No saved contacts"
          image={{
            path: '/images/staff/empty-contacts.png',
            alt: 'No contacts',
            text: 'Let us know where to ship all your Swag!'
          }}
          button={{
            onClick: handleOnAddContactModalClose,
            text: 'Add Contact'
          }}
          marginTop={0}
        />
      ) : (
        <Grid
          container
          justifyContent={isXS ? 'center' : 'flex-start'}
          style={{ marginTop: leftBarNavigation ? 0 : 50 }}
        >
          <Grid item xs={12}>
            <SearchSortFilter config={searchSortFilterConfig} smallPadding />
          </Grid>
          {/* <Grid item style={{ paddingRight: 0, marginBottom: 12 }}>
            <div style={{ textAlign: 'left', marginLeft: -3 }}>
              <Actions
                areAllSelected={grouping ? areAllSelected : areAllSelectedOld}
                isPartSelected={grouping ? isPartSelected : isPartSelectedOld}
                onAction={handleChangeActions}
                onCheck={grouping ? toogleSelectionStatus : handleChangeSelection}
                allowSendSwag={grouping ? areAllSelected || isPartSelected : selectedContacts.size > 0}
                allowRemoveAction={grouping ? allowBulkRemove : selectedNoPendingShipments.length > 0}
              />
            </div>
          </Grid>
          <Grid item xs style={{ paddingLeft: 45, paddingRight: 40, minWidth: 265, marginBottom: 10 }}>
            <FilledSearchField value={search} onChange={e => debouncedSearch(e.target.value)} fullWidth height={58} />
          </Grid>

          <Grid container justifyContent="center" item style={{ maxWidth: 445 }}>
            <Grid item style={{ paddingRight: 10, marginBottom: 10 }}>
              <SortByName title="Sort:" value={sort} onChange={handleSortChange} width={240} height={58} />
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <AddContact onClick={() => setOpenAddContactModal(true)} classes={classes} />
            </Grid>
          </Grid> */}
          {grouping && (
            <Grid item xs={12} style={{ paddingBottom: 24 }}>
              <Groups onGroupClick={handleSelectedGroupsChange} />
            </Grid>
          )}
          <Box pt="4px" position="relative" width="100%">
            {(isPending || canceled) && <Loader absolute />}
            <Grid item xs={12}>
              <ContactsList
                employees={contacts}
                onDelete={doPaginate}
                selectedContacts={selectedContacts}
                onSelectContacts={onSelectContacts}
                SelectionComponent={() => (
                  <Checkbox
                    checked={areAllSelected || isPartSelected}
                    indeterminate={isPartSelected}
                    onChange={grouping ? toogleSelectionStatus : handleChangeSelection}
                    indeterminateIcon={<IndeterminateCheckBox className={classes.indeterminate} />}
                    className={classes.checkbox}
                    style={{ color: areAllSelected ? '#3577d4' : '#8d9299' }}
                  />
                )}
              />
            </Grid>
            <Box pt="20px">
              <Grid container item justifyContent="center">
                <Pagination
                  count={total}
                  endText="contacts"
                  perPage={perPage}
                  next={nextPage}
                  pageIndex={pageIndex}
                  previous={previousPage}
                  sizeOptions={sizeOptions}
                  onPerPageChange={changeSize}
                />
              </Grid>
            </Box>
          </Box>
          <RemoveContactsModal
            isOpen={openRemoveModal}
            onClose={toggleRemoveModal}
            onDelete={grouping ? removeSelected : removeSelectedOld}
            contacts={contacts}
          />
          <ActionBar
            open={grouping ? areAllSelected || isPartSelected : selectedContacts.size > 0}
            totalItemSelected={selectedContactsNumbers}
            handleClose={() => {
              onSelectContacts([]);
              reset();
            }}
            type="contact"
            actions={[
              {
                text: 'Send Swag',
                tooltip: 'You need to select contacts in order to send swag',
                action: () => handleChangeActions({ target: { value: 'ship' } }),
                disabled: !(grouping ? areAllSelected || isPartSelected : selectedContacts.size > 0),
                baseIcon: ({ className }) => (
                  <div className={className} style={{ height: 20 }}>
                    <TruckOutlined
                      size="24"
                      disabled={!(grouping ? areAllSelected || isPartSelected : selectedContacts.size > 0)}
                    />
                  </div>
                ),
                hoverIcon: ({ className }) => (
                  <div className={className} style={{ height: 20 }}>
                    <Truck
                      size="24"
                      disabled={!(grouping ? areAllSelected || isPartSelected : selectedContacts.size > 0)}
                    />
                  </div>
                )
              },
              {
                text: 'Remove',
                tooltip: 'You cannot remove contacts with pending shipment',
                action: () => handleChangeActions({ target: { value: 'remove' } }),
                disabled: false, //! (grouping ? allowBulkRemove : selectedNoPendingShipments.length > 0),
                baseIcon: BlockOutlined,
                hoverIcon: Block
              }
            ]}
          />
        </Grid>
      )}
    </>
  );
};

export default Contacts;
