import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SideBar from '../../account/SideBar';
import { CenteredGrid, Helmet } from '../../shared';
import Company from '../../account/CompanyContainer';
import Profile from '../../account/ProfileContainer';
import Transactions from '../../account/TransactionsContainer';
import Credits from '../../account/CreditsContainer';
import Invoices from '../../account/InvoicesContainer';
import PaymentProfiles from '../../account/PaymentProfilesContainer';
import AddCredits from '../../account/AddCredits';
import tags from '../../../apis/seoTags';
import Membership from '../../account/Membership';
import MembershipConfirmation from '../../account/MembershipConfirmation';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 82px)',
    paddingTop: 32,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 100
    }
  },
  sidebar: {
    minWidth: 232,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      minWidth: 'auto'
    }
  },
  main: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: { padding: '40px 24px' }
  }
}));

const routes = [
  { path: '/account/profile', page: Profile, tagKey: 'profile' },
  { path: '/account/company', page: Company, tagKey: 'account' },
  { path: '/billing/accounts', page: PaymentProfiles, tagKey: 'paymentProfiles' },
  { path: '/billing/invoices', page: Invoices, tagKey: 'invoices' },
  { path: '/billing/credits', page: Credits, tagKey: 'credits' },
  { path: '/billing/add-credits', page: AddCredits, tagKey: 'addCredits' },
  { path: '/billing/transactions', page: Transactions, tagKey: 'transactions' },
  { path: '/membership/your-membership', page: Membership, tagKey: 'membership' },
  { path: '/membership/manage-membership', page: Membership, tagKey: 'membership' },
  { path: '/membership/manage-membership-switch', page: Membership, tagKey: 'membership' },
  { path: '/membership/manage-membership-cancel', page: Membership, tagKey: 'membership' },
  { path: '/membership/manage-membership-confirmation', page: MembershipConfirmation, tagKey: 'membership' },
  { path: '/membership/change-membership', page: Membership, tagKey: 'membership' }
];
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AccountHome = () => {
  const classes = useStyles();

  return (
    <Elements stripe={stripePromise}>
      <CenteredGrid container className={classes.container}>
        <Grid item xs={12} sm="auto" className={classes.sidebar}>
          <SideBar />
        </Grid>
        <Grid item xs={12} sm className={classes.main}>
          <Switch>
            {routes.map(({ path, page: Page, tagKey }) => (
              <Route key={tagKey} path={path} exact>
                <Helmet tags={tags[tagKey]} />
                <Page />
              </Route>
            ))}
            <Redirect to="/account/profile" />
          </Switch>
        </Grid>
      </CenteredGrid>
    </Elements>
  );
};

export default AccountHome;
