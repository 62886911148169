import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import storefrontsServicesPaths from '../../helpers/storefrontsServicesPaths';
import { paginationResponseConverter } from '../../helpers/utils';
import axios from '../StorefrontsServiceAPI';
import { status200, status200or201, status200or201or204 } from '../swagup/status.utils';

const apiErrorParser = errorResponse => {
  const defaultErrorMesage = 'Oops... there was something wrong';
  try {
    if (!isString(errorResponse)) {
      const messages = errorResponse.Messages;
      const currentError = messages[0];
      const rawMessage = currentError.split(': ')[1];

      if (rawMessage?.startsWith('{')) {
        const error = JSON.parse(rawMessage);
        const errorValue = Object.values(error)[0];
        const errorField = Object.keys(error)[0];
        const errorMessage = isArray(errorValue) ? errorValue[0] : errorValue;
        return { status: 'error', message: errorMessage, field: errorField, errorResponse };
      }
      return { status: 'error', message: rawMessage || currentError, errorResponse };
    }
    return { status: 'error', message: errorResponse, errorResponse };
  } catch {
    return { status: 'error', message: defaultErrorMesage, errorResponse };
  }
};

export default {
  list: (id, params) =>
    axios
      .get(storefrontsServicesPaths.customers(id), { status200, params })
      .then(rslt => paginationResponseConverter(rslt.data)),
  customerOrderHistory: (storeId, customerId) =>
    axios
      .get(storefrontsServicesPaths.customerOrderHistory(storeId, customerId), { status200 })
      .then(rslt => paginationResponseConverter(rslt.data)),
  existingCustomer: (storeId, customers) =>
    axios
      .post(storefrontsServicesPaths.existingCustomer(storeId), { customers }, status200or201)
      .then(rslt => rslt.data),
  add: (id, params) =>
    axios
      .post(storefrontsServicesPaths.customers(id), params, status200or201)
      .then(rslt => rslt.data)
      .catch(({ response }) => apiErrorParser(response.data)),
  addBulk: (id, params) =>
    axios
      .post(storefrontsServicesPaths.customersBulk(id), params, status200or201or204)
      .then(rslt => rslt.data)
      .catch(({ response }) => apiErrorParser(response.data)),
  addByEmail: (id, params) =>
    axios
      .post(storefrontsServicesPaths.customersAddByEmail(id), params, status200or201or204)
      .then(rslt => rslt.data)
      .catch(({ response }) => apiErrorParser(response.data)),
  addMultipleFunds: (id, params) =>
    axios
      .post(storefrontsServicesPaths.customersFundsBulk(id), params, status200or201or204)
      .then(rslt => rslt.data)
      .catch(({ response }) => apiErrorParser(response.data)),
  update: (storeId, customerId, params) =>
    axios
      .patch(storefrontsServicesPaths.customerUpdate(storeId, customerId), params, status200or201or204)
      .then(rslt => rslt.data)
      .catch(({ response }) => apiErrorParser(response.data)),
  delete: (storeId, params) =>
    axios
      .post(storefrontsServicesPaths.bulkDelete(storeId), params, status200or201or204)
      .then(rslt => rslt.data)
      .catch(({ response }) => apiErrorParser(response.data)),
  export: (storeId, params) =>
    axios
      .post(
        `${storefrontsServicesPaths.exportCustomers(storeId)}?search=${params?.search}&ordering=${params?.ordering}`,
        status200or201or204
      )
      .then(rslt => rslt.data)
      .catch(({ response }) => apiErrorParser(response.data))
};
