import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Typography, makeStyles } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Link from './Link';

const useStyles = makeStyles({
  link: {
    fontFamily: 'Gilroy-Regular',
    cursor: 'pointer',
    color: '#989EA4'
  },
  separator: {
    fontSize: 16,
    color: '#787B80'
  },
  text: {
    fontFamily: 'Gilroy-Regular',
    cursor: 'default',
    color: '#131415',
    fontWeight: 600
  }
});

const Breadcrumbs = ({ separator, links = [] }) => {
  const classes = useStyles();
  const breadcrumbSeparator = separator || <ChevronRight className={classes.separator} fontSize="small" />;

  return (
    <MuiBreadcrumbs separator={breadcrumbSeparator}>
      {links.map(l =>
        l.to ? (
          <Link key={l.title} color="inherit" to={l.to} absolute={l.absolute}>
            <Typography className={l.styles || classes.link} variant="subtitle2">
              {l.title}
            </Typography>
          </Link>
        ) : (
          <Typography className={l.styles || classes.text} key={l.title} variant="subtitle2">
            {l.title}
          </Typography>
        )
      )}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
