const styles = {
  formActionsContainer: {
    paddingTop: 20
  },
  subtitle: {
    lineHeight: '22px'
  },
  paper: {
    maxWidth: 750,
    width: 750
  }
};

export default styles;
