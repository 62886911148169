import * as React from 'react';
import { Box, Grid, IconButton, makeStyles, Modal } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { Close } from '@material-ui/icons';
import log from '../../logger';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import { useProfile } from '../../hooks';

const ZAPIER_EMBEDDED_ID = 'fze-zme-3EOriumLCabJEOCNZ6YnhS7DeamtXg3wV4XABBfJ';

const styles = theme => ({
  modalViewDetailsContainer: {
    borderRadius: 12,
    background: 'white',
    padding: '0px 1px 12px 1px'
  },
  automationModal: {
    height: 'auto',
    maxHeight: 'fit-content',
    padding: 0,
    marginTop: '112px',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: 'white',
    width: 1440,
    overflow: 'hidden',
    [theme.breakpoints.only('lg')]: {
      width: 1200
    },
    [theme.breakpoints.only('md')]: {
      width: 900
    },
    [theme.breakpoints.only('sm')]: {
      width: 600
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  automationModalHeader: {
    padding: '0px 4px 0px 32px',
    height: '52px',
    borderBottom: '1px solid #E5E7E8'
  },
  automationModalHeaderText: {
    ...ellipsisStyles,
    color: '#131415',
    fontSize: '20px',
    lineHeight: ' 28px',
    letterSpacing: '-0.03em'
  },
  modalBody: {
    minHeight: '80vh'
  },
  tabPanel: {
    paddingTop: 16,
    width: '100%'
  }
});

const useStyles = makeStyles(styles);

const ZapierIntegration = ({ open, setOpen }) => {
  const onClose = () => {
    let zapierFullExperience = document.getElementsByTagName('zapier-full-experience');
    [zapierFullExperience] = zapierFullExperience;
    zapierFullExperience = zapierFullExperience?.shadowRoot;
    if (zapierFullExperience) {
      log.debug('zapierFullExperience: ', zapierFullExperience);
      let zapierZapManager = zapierFullExperience.getElementById(ZAPIER_EMBEDDED_ID);
      if (zapierZapManager) {
        log.debug('zapierZapManager: ', zapierFullExperience);
        zapierZapManager = zapierZapManager.shadowRoot;
        const mainNodes = [...zapierZapManager.childNodes];
        const zapierFrame = mainNodes.find(n => n.getAttribute('title') === 'Zapier Zap Manager');
        log.debug('zapierFrame: ', zapierFrame);
        if (zapierFrame) zapierFrame.src += '';
        // setAttribute('id', `custom-zapier-zap-manager-${frameId}`);
        // setFrameId(prev => prev + 1);
      }
    }
    setOpen(false);
  };

  const { data } = useProfile();
  const { email, first_name: firstName, last_name: lasttName } = data || {};

  const classes = useStyles();
  return (
    <>
      <Box className={classes.tabPanel}>
        <Grid container>
          <Grid item xs={12}>
            <Box style={{ marginBottom: 42 }}>
              <zapier-zap-templates
                sign-up-email={email}
                sign-up-first-name={firstName}
                sign-up-last-name={lasttName}
                client-id="3EOriumLCabJEOCNZ6YnhS7DeamtXg3wV4XABBfJ"
                theme="light"
                apps="swagup"
                create-without-template="hide"
                limit={4}
                use-this-zap="show"
              />
            </Box>
            <Box>
              <zapier-full-experience
                client-id="3EOriumLCabJEOCNZ6YnhS7DeamtXg3wV4XABBfJ"
                theme="light"
                app-search-bar-display="show"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Modal
        aria-labelledby="order-details-title"
        aria-describedby="order-details-description"
        open={open}
        onClose={onClose}
        className={classes.automationModal}
      >
        <Box className={classes.modalViewDetailsContainer}>
          <Grid container direction="column" className={classes.modalBody}>
            <Grid item>
              <Grid container alignItems="center" className={classes.automationModalHeader}>
                <Grid item xs>
                  <Typography variant="h6SemiBoldInter">Create an Automation</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={onClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <iframe
                id="create-automation-frame"
                title="Create an Automation"
                height="-webkit-fill-available"
                width="100%"
                style={{ height: '-webkit-fill-available' }}
                src="https://zapier.com/webintent/create-zap?create=true&amp;entry-point-location=explore&amp;entry-point-method=connect_call_to_action&amp;provider=swagup&amp;utm_source=partner&amp;utm_medium=embed&amp;utm_campaign=zap_manager_element&amp;referer=https%3A%2F%2Fdashboard.staging.swagup.com%2Fdashboard%2Fzapier-integrations%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_manager_element%26referer%3Dhttps%253A%252F%252Fdashboard.staging.swagup.com%252Fdashboard%252Fzapier-integrations&amp;referrer=https%3A%2F%2Fdashboard.staging.swagup.com%2Fdashboard%2Fzapier-integrations%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_manager_element%26referer%3Dhttps%253A%252F%252Fdashboard.staging.swagup.com%252Fdashboard%252Fzapier-integrations"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ZapierIntegration;
