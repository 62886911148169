import React, { useState } from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Paper, Button, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PostAdd } from '@material-ui/icons';
import handleImg from '../../../../../../helpers/handleImgHelper';
import { joinFields, getSortedBySize, sumByQuantity } from '../../../../../../helpers/utils';
import { ImgWithHandler, Img } from '../../../../../global/ImgUtils';
import styles from './styles/OrderOverview';
import { useSizes } from '../../../../../../hooks';
import { ToogleButtom, WarningIcon } from './common';

const useStyles = makeStyles(styles);

const useProducts = order => {
  const shipment = useSelector(state => state.shipmentGroup);
  const sizesData = useSizes();

  const products = order.proofs.map(proof => {
    // We know at this point that the products in the directory order
    // *are* in the shipment. If this line fails it's
    // a bug in the shipment group creation page.
    const product = shipment.products.find(p => p.id === proof.proof);
    const sizes = proof.sizes.map(size => ({ ...size, ...sizesData.data.find(s => size.size === s.id) }));

    return {
      ...product,
      sizes
    };
  });

  return products;
};

const ProductsPreview = ({ order, onShowMoreProducts }) => {
  const classes = useStyles();
  const products = useProducts(order);

  return (
    <Grid container>
      {products.slice(0, 4).map(product =>
        product.sizes.map(size => (
          <Grid item key={`${product.product}-${size.id}`} style={{ marginRight: 24 }}>
            <ImgWithHandler
              src={product.product.image}
              alt={product.product.name}
              width={40}
              height={40}
              className={classes.productImgSmall}
            />
            <p className={classes.productQtySmall}>x{size.quantity}</p>
          </Grid>
        ))
      )}
      {products.length > 4 && (
        <Grid item>
          <Button className={classes.moreProductsButton} onClick={onShowMoreProducts}>
            <p className={classes.productQtySmall} style={{ color: '#3577d4' }}>
              +{products.length - 4}
            </p>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const ProductsList = ({ order }) => {
  const classes = useStyles();
  const products = useProducts(order);
  return (
    <Grid container className={classes.cardSection} style={{ paddingTop: 24 }}>
      {products.map(product => (
        <Grid container className={classes.shippingItem} key={product.product}>
          <Grid item>
            <Img
              src={handleImg(product.product.image, 78, 78)}
              alt={product.product.name}
              width={70}
              height={70}
              className={classes.productImg}
            />
          </Grid>
          <Grid item xs={8}>
            <Box style={{ paddingLeft: 42 }}>
              <p className={classes.productName}>{product.product.name}</p>
              <Grid container spacing={3}>
                <Grid item>
                  <p className={classes.productCommon}>Color:</p>
                </Grid>
                <Grid item>
                  <p className={classes.productCommon}>
                    <span className={classes.productTextDark}>{product.product.theme_color || 'Custom'}</span>
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className={classes.productCommon}>{`Size${product.sizes.length > 1 ? 's:' : ':'}`}</p>
                </Grid>
                {getSortedBySize(product.sizes).map(size => (
                  <Grid key={size.id} item>
                    <p className={classes.productCommon}>
                      <span className={classes.productTextDark}>
                        {size.name || 'Custom'}{' '}
                        <span style={{ fontsSize: 10 }}>{product.sizes.length > 1 ? `x${size.quantity}` : ''}</span>
                      </span>
                    </p>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <p className={classes.productQty}>x{sumByQuantity(product.sizes)}</p>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const TextAlignRight = ({ children }) => {
  return <div style={{ textAlign: 'right' }}>{children}</div>;
};

const ShippingInfo = ({ order }) => {
  const classes = useStyles();

  return (
    <Grid container style={{ paddingTop: 6, paddingBottom: 12 }}>
      <Grid item xs style={{ paddingRight: 70 }}>
        <Grid container>
          <Grid item xs style={{ paddingRight: 24 }}>
            <p className={classes.commonSubTitle}>Shipping Notes</p>
            <p className={classes.employeeAddress} style={{ fontSize: 12, marginTop: 4 }}>
              {order.shippingNotes}
            </p>
          </Grid>
          {order.documentation && (
            <Grid item xs={4}>
              <p className={classes.commonSubTitle}>Documentation</p>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <PostAdd style={{ height: 24, width: 24, color: '#6F777E' }} />
                </Grid>
                <Grid item xs>
                  <p className={classes.employeeAddress} style={{ fontSize: 12, marginTop: 4 }}>
                    {order.documentation?.name}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <TextAlignRight>
          <>
            <p className={classes.commonSubTitle}>Shipping Date</p>
            <p className={classes.employeeName}>{dayjs(order.shippingDate).format('MM/DD/YYYY')}</p>
          </>
        </TextAlignRight>
      </Grid>
    </Grid>
  );
};

const getAddress = recipient =>
  joinFields(
    [
      joinFields([recipient.shipping_address1, recipient.shipping_address2], ' '),
      recipient.shippping_city,
      joinFields([recipient.shipping_state, recipient.shipping_zip], ' ')
    ],
    ', '
  );

const ShipmentCard = ({ order }) => {
  const classes = useStyles();

  const { recipients, errors } = useSelector(state => state.shipmentGroup);
  const [isListExpanded, setIsListExpanded] = useState(false);

  const recipient = recipients.find(r => r.id === order.directory);
  const deliveryMethod = order.deliveryMethods.find(dm => dm.selected);

  const address = getAddress(recipient);

  const warning = errors[recipient?.id];

  return (
    <Paper elevation={0} className={classes.card}>
      <Grid container className={classes.cardSection}>
        <Grid item xs style={{ paddingRight: 32 }}>
          <Grid container style={{ marginBottom: 12 }}>
            <Grid item xs={10} style={{ paddingRight: 32 }}>
              <p className={classes.commonSubTitle}>Recipient</p>
              <Grid container>
                <Grid item>
                  <p className={classes.employeeName} style={{ marginRight: 12 }}>
                    {joinFields([recipient.first_name, recipient.last_name], ' ')}
                  </p>
                </Grid>
                <Grid item>{warning && <WarningIcon marginBottom={-8} type="warning" text={warning.text} />}</Grid>
              </Grid>

              <p className={classes.employeeAddress}>{address}</p>
            </Grid>
            <Grid item xs={2}>
              <TextAlignRight>
                <>
                  <p className={classes.commonSubTitle}>Delivery</p>
                  <p className={classes.employeeName}>{deliveryMethod?.name}</p>
                </>
              </TextAlignRight>
            </Grid>
          </Grid>
          {isListExpanded ? (
            <ShippingInfo order={order} />
          ) : (
            <ProductsPreview order={order} onShowMoreProducts={() => setIsListExpanded(true)} />
          )}
        </Grid>
        <Grid item>
          <ToogleButtom opened={isListExpanded} onClick={() => setIsListExpanded(!isListExpanded)} />
        </Grid>
      </Grid>
      {isListExpanded && <ProductsList order={order} />}
    </Paper>
  );
};

const OrderOverview = ({ paginatedDirectoryOrders }) => {
  const shipment = useSelector(state => state.shipmentGroup);
  const directoryOrders = paginatedDirectoryOrders ?? shipment.directoryOrders;

  return directoryOrders.map(order => <ShipmentCard key={order.directory} order={order} />);
};

export default OrderOverview;
