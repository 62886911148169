import { GET_INVOICES } from '../actions/types';

const InvoicesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return action.payload;
    default:
      return state;
  }
};

export default InvoicesReducer;
