import {
  ADD_SHIPPMENT,
  ADD_PRODUCT_TO_SHIP,
  DELETE_SHIPPING_CARD,
  SAVE_DATA_TO_SHIP,
  SELECT_BATCH_DELIVERY_METHOD,
  ADD_BATCH_SHIPPING_DATE,
  ADD_BATCH_SHIPPING_NOTE,
  ADD_SHIPPING_TAX,
  ADD_BATCH_SHIPPING_TAX,
  ADD_SHIPPING_DELIVERY_METHOD,
  CLEAN_MULTISHIPPING,
  CREATE_SHIPMENT
} from '../actions/types';

const updateField = (obj, field, value) => (obj[field] !== value ? { ...obj, [field]: value } : obj);

const MultishippingReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_SHIPPMENT:
      return [...state, action.payload];

    case ADD_PRODUCT_TO_SHIP:
      return state.map(st =>
        st.id === action.payload.elemId
          ? {
              ...st,
              products: [...st.products, action.payload.product]
            }
          : st
      );

    case DELETE_SHIPPING_CARD:
      return state.filter(card => card.id !== action.payload.cardId);

    case SAVE_DATA_TO_SHIP:
      return state.map(st =>
        st.id === action.payload.elemId
          ? {
              ...st,
              employee: { ...action.payload.saveShipping, save: true }
            }
          : st
      );

    case SELECT_BATCH_DELIVERY_METHOD:
      return state.map(st =>
        action.payload.elemIds.includes(st.id)
          ? {
              ...st,
              deliveryMethods: st.deliveryMethods.map(dm =>
                updateField(dm, 'selected', dm.name === action.payload.selectedMethod)
              )
            }
          : st
      );

    case ADD_SHIPPING_TAX:
    case ADD_SHIPPING_DELIVERY_METHOD:
      return state.map(shipping =>
        shipping.id === action.payload.id
          ? {
              ...shipping,
              ...action.payload
            }
          : shipping
      );

    case ADD_BATCH_SHIPPING_DATE:
    case ADD_BATCH_SHIPPING_NOTE:
    case ADD_BATCH_SHIPPING_TAX:
      return state.map(shipping =>
        action.payload.ids.includes(shipping.id)
          ? {
              ...shipping,
              ...action.payload.property
            }
          : shipping
      );

    case CLEAN_MULTISHIPPING:
      return [];

    case CREATE_SHIPMENT:
      return action.payload;

    default:
      return state;
  }
};

export default MultishippingReducer;
