import * as React from 'react';
import { useState } from 'react';
import { Typography, Button } from '@swagup-com/components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Scrollbars from 'react-scrollbars-custom';
import { isEmpty } from 'lodash';
import { useCreditSummary } from '../../../../../../hooks';
import styles from './styles/common';
import { joinFields, moneyStr } from '../../../../../../helpers/utils';
import ShippingPaymentMode from './ShippingPaymentMode';
import { TotalRefundToSwagCard } from './common';

const useStyles = makeStyles(styles);

const UpdateShippingModal = ({ open, onClose, directory, handleUpdateShipment, content }) => {
  const {
    data: { current_balance }
  } = useCreditSummary();

  const [inputValue, setInputValue] = useState();
  const [useCreditFirst, setUseCreditFirst] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const classes = useStyles();

  if (isEmpty(content) || isEmpty(content?.oldone) || !open) return null;

  const { oldone: oldRecipient, newone: newRecipient, newTotalShipping, oldTotalShipping } = content;
  const previousRecipientName = joinFields([oldRecipient.shipping_first_name, oldRecipient.shipping_last_name], ' ');

  const previousRecipientAddress = joinFields(
    [
      oldRecipient.shipping_address1,
      oldRecipient.shipping_address2,
      oldRecipient.shipping_city,
      oldRecipient.shipping_state,
      oldRecipient.shipping_zip,
      oldRecipient.shipping_country
    ],
    ', '
  );

  const newRecipientName = joinFields([newRecipient.first_name, newRecipient.last_name], ' ');

  const newRecipientAddress = joinFields(
    [
      newRecipient.shipping_address1,
      newRecipient.shipping_address2,
      newRecipient.shipping_city,
      newRecipient.shipping_state,
      newRecipient.shipping_zip,
      newRecipient.shipping_country
    ],
    ', '
  );

  const totalShippingPrice =
    oldTotalShipping > newTotalShipping
      ? Number(oldTotalShipping) - Number(newTotalShipping)
      : Number(newTotalShipping) - Number(oldTotalShipping);

  const onUpdate = () => {
    handleUpdateShipment({
      ...newRecipient,
      shipmentID: oldRecipient?.id,
      credits_to_use: inputValue || 0
    });
  };

  const payShippingCost = oldTotalShipping > newTotalShipping;
  const refundShippingCost = oldTotalShipping < newTotalShipping;

  const ModalTitle = ({ title, subtitle }) => (
    <Grid>
      <Typography variant="subtitle1SemiBoldInter" style={{ color: '#0B1829', textAlign: 'center', paddingBottom: 10 }}>
        {title}
      </Typography>
      <Grid style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', marginBottom: 20 }}>
        <Typography variant="body4MediumInter" className={classes.newShipmentDetails}>
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={open}
      onClose={reason => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth="lg"
      BackdropProps={{
        classes: {
          root: classes.transparentBackdrop
        }
      }}
    >
      <DialogTitle id="updateShipping-dialog-title" style={{ marginTop: 15 }}>
        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
          {totalShippingPrice === 0 && (
            <ModalTitle
              title="Updated Shipping Address"
              subtitle="The new shipment address have resulted in a no price change for the shipment. Please review the details
                    below."
            />
          )}
          {payShippingCost && (
            <ModalTitle
              title="New Lower Shipping Cost"
              subtitle="The new shipment details have resulted in a lower cost. We will refund the difference to your swag card balance."
            />
          )}
          {refundShippingCost && (
            <ModalTitle
              title="Updated Shipping Cost"
              subtitle="The new shipment details have resulted in a price change for the shipment. Please review the details below."
            />
          )}
          <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', top: '15px', right: '15px' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Scrollbars
          style={{
            width: 740,
            display: 'flex',
            height: 330,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid className={classes.modalContainer}>
            <Grid container style={{ border: '1px solid #EBEDF0', borderRadius: '10px' }}>
              <Grid className={classes.recipientShippingAddress}>
                <Grid className={classes.address}>
                  <Typography variant="body3MediumInter" style={{ color: '#787B80', paddingBottom: 8 }}>
                    Previous Address
                  </Typography>
                  <Typography variant="body3MediumInter" style={{ color: '#787B80' }}>
                    {previousRecipientName}
                  </Typography>
                  <Typography variant="body3MediumInter" style={{ color: '#787B80' }}>
                    {previousRecipientAddress}
                  </Typography>
                </Grid>
                <Grid className={classes.address}>
                  <Typography
                    variant="body3MediumInter"
                    style={{ color: '#787B80', textAlign: 'end', paddingBottom: 8 }}
                  >
                    Previous Shipping Cost
                  </Typography>
                  <Typography variant="body1MediumInter" style={{ color: '#0B1829', textAlign: 'end' }}>
                    {moneyStr(Number(oldTotalShipping))}
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={classes.recipientShippingAddress}>
                <Grid className={classes.address}>
                  <Typography variant="body3MediumInter" style={{ color: '#787B80', paddingBottom: 8 }}>
                    New Address
                  </Typography>
                  <Typography variant="body3SemiBoldInter" style={{ color: '#0B1829' }}>
                    {newRecipientName}
                  </Typography>
                  <Typography variant="body3SemiBoldInter" style={{ color: '#0B1829' }}>
                    {newRecipientAddress}
                  </Typography>
                </Grid>
                <Grid className={classes.address}>
                  <Typography
                    variant="body3MediumInter"
                    style={{ color: '#787B80', textAlign: 'end', paddingBottom: 8 }}
                  >
                    New Shipping Cost
                  </Typography>
                  <Typography variant="body1MediumInter" style={{ color: '#0B1829', textAlign: 'end' }}>
                    {moneyStr(Number(newTotalShipping))}
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={classes.totalPayment}>
                <Typography variant="body3MediumInter" style={{ color: '#0B1829' }}>
                  {Number(oldTotalShipping) > Number(newTotalShipping) && <TotalRefundToSwagCard />}
                  {Number(oldTotalShipping) < Number(newTotalShipping) && 'Difference Due'}
                  {Number(oldTotalShipping) === Number(newTotalShipping) && 'No Dues to Pay'}
                </Typography>
                <Typography variant="subtitle1SemiBoldInter" style={{ color: '#0B1829', textAlign: 'end' }}>
                  {moneyStr(Number(totalShippingPrice))}
                </Typography>
              </Grid>
            </Grid>
            {totalShippingPrice !== 0 && refundShippingCost && (
              <Grid container className={classes.paymentContainer} style={{ paddingBottom: 0, margin: '20px 0px' }}>
                <Typography variant="body2MediumInter" style={{ color: '#4A505C', padding: '0px 18px' }}>
                  Payment Methods
                </Typography>
                <ShippingPaymentMode
                  inputValue={inputValue}
                  currentBalance={current_balance}
                  setInputValue={setInputValue}
                  useCreditFirst={useCreditFirst}
                  setUseCreditFirst={setUseCreditFirst}
                  setInvalid={setInvalid}
                  customOrder={{ currentTotal: totalShippingPrice, funds: 0 }}
                />
              </Grid>
            )}
          </Grid>
        </Scrollbars>
      </DialogContent>
      <DialogActions className={classes.shipmentBtn}>
        <Button variant="text" id="cancle-shipment" onClick={onClose}>
          <Typography variant="body2MediumInter" style={{ color: '#F44636' }}>
            Cancel Update
          </Typography>
        </Button>
        <Button
          variant="primary"
          id="update-shipment"
          style={{ height: '56px' }}
          onClick={() => {
            onUpdate(directory?.id);
          }}
        >
          <Typography variant="body2MediumInter" style={{ color: '#FFF' }}>
            Update Shipment
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateShippingModal;
