import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Grid } from '@material-ui/core';
import { Button, Tooltip } from '@swagup-com/components';
import {
  ProductImage,
  ProductNameInput,
  ProductColor,
  ProductQuantities,
  SelectionMark,
  SendSwagButton,
  productImageTitle,
  selectionMarkTitle,
  useReorderLink
} from './InventoryCommon';
import { scrollBar } from '../shared/styles/commonStyles';
import { reorderProductDisabledCopy } from '../../helpers/helperConstants';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    borderRadius: 15,
    border: ({ selected }) => (selected ? '1px solid #3577d4' : 'solid 1px #ebeef2'),
    backgroundColor: ({ isHovered }) => (isHovered ? '#3577d4' : '#ffffff'),
    height: 396,
    width: 276,
    padding: '15px 30px'
  },
  sendSwagItemBtn: {
    width: 210,
    border: 'none'
  },
  reorderItemBtn: {
    width: 210,
    marginTop: 20,
    border: 'solid 1px #ffffff'
  },
  quantitiesContainer: {
    ...scrollBar,
    maxHeight: 120,
    marginTop: 4,
    minHeight: 84
  },
  selectionContainer: {
    position: 'absolute',
    top: 12,
    right: 12
  },
  infoContainer: {
    maxHeight: 150,
    [theme.breakpoints.down('xl')]: { maxHeight: 170 }
  },
  tooltip: {
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 500
  }
}));

const InventoryCardItem = ({ item, selected, onToggleSelection, readOnly }) => {
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles({ isHovered, selected, readOnly });

  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  const reorderLink = useReorderLink([item.id]);

  return (
    <Grid
      container
      onMouseEnter={!readOnly ? handleMouseOver : undefined}
      onMouseLeave={!readOnly ? handleMouseOut : undefined}
      direction="column"
      justifyContent="space-between"
      className={classes.card}
    >
      <Grid container alignItems="center" justifyContent="center" item style={{ display: isHovered ? 'none' : 'flex' }}>
        <ProductImage
          linkTo={!readOnly && reorderLink}
          item={item}
          name={item.name}
          imgSrc={item.image}
          title={productImageTitle(item.enabled)}
          width={180}
          height={180}
        />
      </Grid>
      <Grid container direction="column" justifyContent="space-between" item xs className={classes.infoContainer}>
        <ProductNameInput blue={isHovered} item={item} readOnly={readOnly} />
        <Grid container alignItems="center" style={{ marginTop: 12 }}>
          <ProductColor item={item} blue={isHovered} />
          {onToggleSelection && item.enabled && (
            <div className={classes.selectionContainer}>
              <SelectionMark
                selected={selected}
                title={selectionMarkTitle(selected)}
                onClick={() => onToggleSelection(item)}
                classes={classes}
              />
            </div>
          )}
        </Grid>
        <Grid container className={classes.quantitiesContainer}>
          <ProductQuantities item={item} blue={isHovered} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" style={{ display: isHovered ? 'flex' : 'none' }}>
        <Grid item>
          <SendSwagButton item={item} className={classes.sendSwagItemBtn} />
        </Grid>
        <Grid item>
          <Tooltip title={item.enabled ? '' : reorderProductDisabledCopy} classes={{ tooltip: classes.tooltip }}>
            <div>
              <Button
                component={Link}
                to={reorderLink}
                disabled={!item.enabled}
                size="small"
                variant="primary"
                className={classes.reorderItemBtn}
              >
                Re-order
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InventoryCardItem;
