import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SwipeableViews from 'react-swipeable-views';
import { MembershipDowngrade, MembershipItem } from './MembershipCommon';
import styles from './styles/membership';

const useStyles = makeStyles(styles);

const MembershipListPanel = ({
  memberships,
  currentMembership,
  selectedItemId,
  setSelectedMembership,
  setSelectedItemId,
  selectedMembership
}) => {
  const [currentStep, setCurrentStep] = React.useState(1);
  const history = useHistory();
  const classes = useStyles();
  return (
    <Grid container spacing={3} alignItems="stretch" className={classes.membershipPanel}>
      <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
        <ScrollMenu
          data={memberships
            .filter(m => m.membership_visible ?? true)
            .map(item => (
              <Box key={item.id} className={classes.membershipItemBox} onClick={() => setSelectedItemId(item.id)}>
                <MembershipItem
                  key={item.id}
                  item={item}
                  onSelect={() => {
                    setSelectedMembership(item);
                    if (currentMembership.default_price.unit_amount > item.default_price.unit_amount) setCurrentStep(2);
                    else history.push('/membership/change-membership');
                  }}
                  onCancel={() => history.push('/membership/manage-membership-cancel')}
                  isCurrent={item.id === currentMembership?.id}
                  classes={classes}
                />
              </Box>
            ))}
          inertiaScrolling
          menuClass={classes.horizontalScrollMenu}
          innerWrapperClass={classes.horizontalScrollInnerWrapper}
          itemStyle={{ outline: 'none' }}
          selected={selectedItemId}
          wheel={false}
          scrollToSelected
          scrollBy={1}
        />
        <MembershipDowngrade
          currentMembership={currentMembership}
          nextMembership={selectedMembership}
          onContinue={() => {
            history.push('/membership/change-membership');
            setCurrentStep(1);
          }}
          onLeave={() => setCurrentStep(1)}
        />
      </SwipeableViews>
    </Grid>
  );
};

export default MembershipListPanel;
