import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useDispatch } from 'react-redux';
import { useQueryClient, useMutation, useIsMutating } from 'react-query';
import Confetti from 'react-canvas-confetti';
import { Button } from '@swagup-com/components';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import debounce from 'lodash/debounce';

import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setAccountProductStatus, getOpportunityById } from '../../../actions';
import Breadcrumbs from '../../shared/Breadcrumbs';
import Counter from '../../shared/Counter';
import { Img } from '../../global/ImgUtils';
import { productImageBasedOnStatus } from '../../global/proofsCommon';
import FeedbackSection from './FeedbackSection';
import { Alert } from '../../shared';
import { StylessButton } from '../../buttons';
import {
  UnsavedChangesModal,
  DownloadLink,
  Skeleton,
  getNewProof,
  ItemCard,
  ImageDialog,
  IconSearch
} from './ProofDetailsSectionsExtension';
import styles from './styles/ProofDetails';
import { productStatus } from '../../../apis/constants';
import accountProductsApi from '../../../apis/swagup/accountProducts';
import proofsApi from '../../../apis/swagup/proofs';
import apiPaths from '../../../helpers/apiPaths';
import { decorationName, getVisibleDecorationsKeys, decorationStatusText } from './common';
import { canApproveProductOld, approveProductText } from '../../../helpers/productUtils';
import { moneyStr, isPack, s3 } from '../../../helpers/utils';
import { useLeftNavbar } from '../../../contexts/leftNavbar';

const useStyles = makeStyles(styles);

const prepareForApproveDisplay = item => ({
  ...item,
  itemColor: { theme_color_hex: item.theme_color_hex, theme_color_name: item.theme_color }
});

const generateChangesRequest = ({ itemColor, decorations }, getName) => {
  let payload = itemColor ? { item_color: itemColor.theme_color_name, item_color_hex: itemColor.theme_color_hex } : {};

  if (decorations)
    payload = {
      ...payload,
      decorations: decorations.map(decoration => {
        const extendedlDecorationData = {
          location: decoration.Location,
          dimension: decoration.Dimensions,
          imprint_type: decoration.Imprint_Type,
          additional_notes: decoration.Notes,
          artwork_path: decoration.artwork?.url,
          artwork_name: decoration.artwork?.fileName
        };
        const minimalDecorationData = {
          operation: decorationStatusText(decoration),
          name: getName ? getName(decoration) : decoration.Name
        };
        return decoration.deleted ? minimalDecorationData : { ...minimalDecorationData, ...extendedlDecorationData };
      })
    };
  return payload;
};

const isDeepEmpty = obj =>
  isEmpty(obj) ||
  (isArray(obj) && obj.every(o => isDeepEmpty(o))) ||
  Object.keys(obj).every(key => isPlainObject(obj) && isDeepEmpty(obj[key])) ||
  (obj.created && obj.deleted);

const prepareArtworksOnS3 = async obj => {
  const prepared = await Object.keys(obj).reduce(async (loaded, key) => {
    let value = obj[key];
    if (key === 'artwork') {
      const s3Data = await s3
        .upload({
          Key: `${Date.now()}-${value.fileName.replace(' ', '-').replace('_', '-')}`,
          Body: value.image,
          ContentType: value.image.type
        })
        .promise();
      value = { fileName: value.fileName, url: s3Data.Location };
    }
    return { ...(await loaded), [key]: value };
  }, '');
  return prepared;
};

const decorationsForSend = async (decorations, s3upload) => {
  const toSendKeys = getVisibleDecorationsKeys(decorations);
  const ready = toSendKeys.reduce(
    async (loaded, key) => ({
      ...(await loaded),
      [key]: s3upload ? await prepareArtworksOnS3(decorations[key]) : decorations[key]
    }),
    {}
  );
  const rslt = await Promise.resolve(ready);
  return rslt;
};

const HeaderSection = ({ proof, fromProducts, inProductDetails, classes }) => {
  const history = useHistory();

  const { product } = proof;
  const price = isPack(product.record_type)
    ? product.items.reduce((sum, item) => sum + round(item.units_per_pack * item.product.price, 2), 0)
    : +proof.price;

  const getBreadcrumbLinks = () =>
    inProductDetails
      ? [{ title: 'Products', to: '/products' }, { title: product.name }]
      : [
          { title: 'Orders', to: '/orders' },
          {
            title: `Order Request #${proof.opportunity}`,
            to: { pathname: `/orders-requested/${proof.opportunity}`, state: { fromProducts } }
          },
          { title: product.name }
        ];

  return (
    <Grid container className={classes.headerContainer}>
      <Grid item container alignItems="center" className={classes.breadcrumbContainer} xs={12}>
        <KeyboardBackspaceIcon
          onClick={() => history.push(inProductDetails ? '/products' : `/orders-requested/${proof.opportunity}`)}
          className={classes.backIcon}
        />
        <Breadcrumbs links={getBreadcrumbLinks()} separator="/" />
      </Grid>
      <Grid item container className={classes.subDetails} xs={12} sm={6}>
        <p className={classes.productName}>{product.name}</p>
      </Grid>
      <Grid item container xs={12} sm={6}>
        <Grid container className={classes.subHeaderContainer} justifyContent="flex-end">
          <Grid item className={classes.subHeader}>
            <Grid container>
              <Grid item>
                <p className={classes.subDetailsText} style={{ marginRight: 6 }}>
                  {isPack(product.record_type) ? 'Price per pack: ' : 'Price: '}{' '}
                </p>
              </Grid>
              <Grid item>
                <p className={classes.subDetailsText}>
                  {!Number.isFinite(price) ? (
                    <Skeleton style={{ width: 60 }} />
                  ) : (
                    <span className={classes.priceXPack}>{moneyStr(price)}</span>
                  )}
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={clsx(classes.subHeader, classes.lastSubHeader)}>
            <p className={classes.subDetailsText}>Mockup presentation file</p>
            <div>
              <DownloadLink
                disabled={!product?.image}
                link={product.image?.replace('.png', '.pdf')}
                classes={classes}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const getChanges = (originalProof, modifiedProof) => {
  const dif = [];

  if (originalProof)
    originalProof.product.items.forEach(item => {
      const packItem = modifiedProof?.product.items?.find(i => i.product.id === item.product.id);

      if (!packItem) {
        dif.push({
          type: 'removed_item',
          itemId: item.product.id
        });
      } else if (packItem.units_per_pack !== item.units_per_pack) {
        dif.push({
          type: 'changed_quantity',
          itemId: item.product.id,
          newQuantity: packItem.units_per_pack
        });
      }
    });

  return dif;
};

const ProductCardSection = ({
  onDeleteProduct,
  onSelectProduct,
  setLocalProof,
  proof,
  localProof,
  selected,
  isLoadingPrices,
  inProductDetails
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const refs = React.useRef(new Map());

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const proofId = +proof.id;
  const isMutatingChanges = useIsMutating([apiPaths.proofs, proofId]);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (selected && isPack(proof.product.record_type) && refs.current.get(selected.id))
      refs.current.get(selected.id).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [proof, selected]);

  const checkIfOpportunityIsActive = async () => {
    const response = await dispatch(getOpportunityById(proof.opportunity));
    if (response.result === 'ok') {
      history.push(`/orders-requested/${proof.opportunity}`);
    } else {
      history.push(`/orders`);
    }
  };

  const redirect = () => {
    if (inProductDetails) history.push('/products');
    else checkIfOpportunityIsActive();
  };

  const updatePackMutation = useMutation(
    [apiPaths.proofs, proofId],
    ({ packItems }) => {
      const api = inProductDetails ? accountProductsApi : proofsApi;
      return api.updatePack({ id: proofId, packItems });
    },
    {
      onMutate: () => {
        const currentProof = queryClient.getQueryData([apiPaths.proofs, proofId]);
        return { currentProof };
      },
      onSuccess: (data, variables, context) => {
        setLocalProof(context.currentProof);
        setShowAlert(true);
        if (proof.product.items.length === 0) {
          redirect();
        }
      },
      onError: redirect
    }
  );

  const discardChanges = () => queryClient.setQueryData([apiPaths.proofs, proof.id], localProof);

  const updatePack = () => updatePackMutation.mutate({ packItems: proof.product.items });

  const itemsApproved = isPack(proof.product.record_type)
    ? proof.product.items.reduce((sum, i) => ([productStatus.approved].includes(i.product.status) ? sum + 1 : sum), 0)
    : 0;
  const itemsTotal = proof.product.items?.length || 0;
  const showUpdateButtom = !isEmpty(getChanges(localProof, proof));

  return (
    <>
      {isPack(proof.product.record_type) && (
        <Grid>
          {itemsTotal > 0 && (
            <Grid item xs={12} className={classes.itemsApproved}>
              Items Approved {itemsApproved}/{itemsTotal}
            </Grid>
          )}
          {showAlert && (
            <Grid className={classes.alertContainer}>
              <Alert
                onClose={() => setShowAlert(false)}
                delayTime={6000}
                width={310}
                style={{ margingBottom: 20, justifyContent: 'left' }}
              >
                Your pack is updated!
              </Alert>
            </Grid>
          )}
          {showUpdateButtom && (
            <Grid item container align="center" justifyContent="space-between" className={classes.containerButtons}>
              <Button
                variant="text"
                size="small"
                disabled={isLoadingPrices || isMutatingChanges > 0}
                onClick={discardChanges}
                loading={false}
                className={classes.discardButton}
              >
                Discard Changes
              </Button>
              <Button
                variant="primary"
                size="small"
                disabled={isLoadingPrices || isMutatingChanges > 0}
                onClick={updatePack}
                loading={isLoadingPrices || isMutatingChanges > 0}
                className={classes.updateButton}
              >
                Save Changes
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      <Grid item container xs className={classes.productList}>
        <Grid container direction="column" style={{ width: 330 }}>
          {isPack(proof.product.record_type) ? (
            proof.product.items.map(item => {
              return (
                <div
                  role="button"
                  key={item.product.id}
                  tabIndex={0}
                  onKeyDown={() => onSelectProduct(item)}
                  onClick={() => onSelectProduct(item)}
                  style={{ cursor: 'pointer' }}
                  ref={ref => refs.current.set(item.product.id, ref)}
                >
                  <ItemCard
                    product={item.product}
                    selected={item.product.id === selected?.id}
                    hideTrashCan={proof.is_in_invoice}
                    onDeleteProduct={onDeleteProduct}
                    isLoading={isLoadingPrices}
                  />
                </div>
              );
            })
          ) : (
            <ItemCard
              product={{ ...proof.product, price: +proof.price }}
              selected
              hideTrashCan
              isLoading={isLoadingPrices}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

const ProductDetail = ({
  onChangePrice,
  onModifyQuantities,
  onSelectProduct,
  setLocalProof,
  setNextItem,
  proof,
  localProof,
  packOrBulkItem,
  nextItem,
  changesHistory,
  isRemoveItem,
  inProductDetails
}) => {
  const [unitsXPack, setUnitsXPack] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [fireConfetti, setFireConfetti] = useState(false);
  const [busy, setBusy] = useState(false);
  const [changesRequested, setChangesRequested] = useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [itemFeedBack, setItemFeedBack] = useState({ id: 0 });

  const unmount = useRef(false);
  const action = useRef(null);
  const queryClient = useQueryClient();

  const history = useHistory();
  const classes = useStyles();

  const proofId = +proof.id;
  const { id } = packOrBulkItem.product;
  const newStatus =
    packOrBulkItem.product.status === productStatus.designReady
      ? productStatus.productionRequest
      : productStatus.approved;

  const statusMutation = useMutation(
    [apiPaths.accountProducts, id, newStatus],
    newIdWithStatus =>
      setAccountProductStatus({
        id: newIdWithStatus.id,
        newStatus: newIdWithStatus.newStatus
      }),
    {
      onMutate: newIdWithStatus => {
        queryClient.cancelQueries([apiPaths.accountProducts, id, newStatus]);
        const previousProof = queryClient.getQueryData([apiPaths.proofs, proofId]);
        const localPreviousProof = localProof;
        queryClient.setQueryData([apiPaths.proofs, proofId], getNewProof(proof, newIdWithStatus));
        setLocalProof(getNewProof(localProof, newIdWithStatus));

        return { previousProof, localPreviousProof };
      },
      onSuccess: () => {
        setFireConfetti({});
      },
      onError: (error, newIdWithStatus, context) => {
        queryClient.setQueryData([apiPaths.proofs, proofId], context.previousProof);
        setLocalProof(context.localPreviousProof);
      }
    }
  );

  const sendCommentMutation = useMutation(
    [apiPaths.accountProducts, proofId, id],
    info => accountProductsApi.sendComment(id, info),
    {
      onSuccess: comment => {
        queryClient.setQueryData(
          [apiPaths.proofs, proofId],
          getNewProof(proof, { id, newStatus: productStatus.changesRequested })
        );
        setLocalProof(getNewProof(localProof, { id, newStatus: productStatus.changesRequested }));
        queryClient.setQueryData([apiPaths.accountProducts, 'comments', +id], old => ({
          ...old,
          results: [comment, ...old.results]
        }));
      },
      onSettled: () => {
        setChangesRequested({});
        setBusy(false);
      }
    }
  );

  useEffect(() => {
    if (isRemoveItem || isEqual(packOrBulkItem?.product.id, nextItem?.product.id)) return;

    if (!isDeepEmpty(changesRequested) && !changesRequested.id) {
      setOpenConfirmationDialog(true);
      action.current = null;
    } else if (isPack(proof.product.record_type)) {
      onSelectProduct(nextItem.product.id);
    }
  }, [changesRequested, nextItem, packOrBulkItem, proof, onSelectProduct, isRemoveItem]);

  useEffect(() => {
    setUnitsXPack(packOrBulkItem.units_per_pack);
    if (packOrBulkItem.product.id !== itemFeedBack.id || packOrBulkItem.product.status !== itemFeedBack.status) {
      setItemFeedBack(packOrBulkItem.product);
    }
  }, [itemFeedBack, packOrBulkItem]);

  const debouncedChangeUnitsXPack = useCallback(
    debounce(units => {
      queryClient.setQueryData([apiPaths.proofs, proofId], currentProof => ({
        ...currentProof,
        product: {
          ...currentProof.product,
          items: currentProof.product.items.map(item =>
            item.product.id === id
              ? {
                  ...item,
                  units_per_pack: units
                }
              : item
          )
        }
      }));
      onModifyQuantities(!inProductDetails);
    }, 750),
    [packOrBulkItem]
  );

  const requestChanges = async () => {
    setBusy(true);
    const decorations = await decorationsForSend(changesRequested.decorations || {}, true);
    const payload = generateChangesRequest(
      {
        ...changesRequested,
        decorations: Object.keys(decorations).map(key => decorations[key])
      },
      decoration => decorationName(decoration)
    );
    sendCommentMutation.mutate({ product: proof.product.id, ...payload });
  };

  const handleRequestChanges = () => {
    setOpenConfirmationDialog(false);
    requestChanges();
    setNextItem(packOrBulkItem);
  };

  const handleApprove = () => {
    setOpenConfirmationDialog(false);
    statusMutation.mutate({ id: packOrBulkItem.product.id, newStatus });
  };

  const handleChangeUnitsXPack = units => {
    onChangePrice(!inProductDetails);
    debouncedChangeUnitsXPack(units);
    setUnitsXPack(units);
  };

  const handleFinishedAnimation = () => {
    const nextForApproval = proof.product.items.find(
      i => i.product.id !== id && canApproveProductOld(i.product.status)
    );
    if (nextForApproval) {
      onSelectProduct(nextForApproval.product.id);
    }
    setFireConfetti(false);
  };

  const isPackType = isPack(proof.product.record_type);
  const canBeApproved = changesRequested.id || isDeepEmpty(changesRequested);
  const canApproveProd = canApproveProductOld(packOrBulkItem.product.status);
  const hideIconSearch = [productStatus.pendingMockup, productStatus.inProgress].includes(
    packOrBulkItem.product.status
  );
  const productImg = productImageBasedOnStatus(packOrBulkItem.product, 690, 690);

  const { leftBarNavigation } = useFlags();

  const leftNavBarContext = useLeftNavbar();

  const leftNavOpen = leftNavBarContext?.leftNavOpen || false;

  return (
    <Grid
      container
      className={`${classes.detailsSectionContainer} ${
        leftBarNavigation && leftNavOpen ? classes.detailsSectionContainerLeftNavOpen : ''
      }`}
    >
      <Grid item xs={12}>
        <Grid container alignItems="center" className={classes.topSection}>
          {packOrBulkItem.has_multiple_packs && (
            <Grid item xs={12} className={classes.alert}>
              <Alert
                showIcon={<ErrorOutlineIcon fontSize="small" style={{ alignSelf: 'center' }} />}
                fontStyles={classes.alertStyles}
                style={{ marginBottom: 10, width: '100%' }}
              >
                This item is in multiple packs, any design changes will affect all packs this item is part of.
              </Alert>
            </Grid>
          )}
          <Grid item xs={9}>
            <Typography variant="body2" className={classes.selectedProductName}>
              {isPackType ? packOrBulkItem.product.name : ''}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end">
              {canBeApproved ? (
                <Button
                  variant="primary"
                  size="small"
                  disabled={!canApproveProd || statusMutation.isLoading}
                  onClick={() => setOpenConfirmationDialog(true)}
                  style={
                    !canApproveProd
                      ? {
                          backgroundColor: '#fafafa',
                          color: '#787b80'
                        }
                      : undefined
                  }
                  loading={statusMutation.isLoading}
                >
                  {approveProductText(packOrBulkItem.product.status)}
                </Button>
              ) : (
                <Button
                  variant="primary"
                  size="small"
                  disabled={busy}
                  onClick={() => {
                    action.current = null;
                    setOpenConfirmationDialog(true);
                  }}
                  loading={busy}
                >
                  Submit Changes
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="stretch" spacing={6}>
          <Grid item xs={6}>
            <Grid container>
              <StylessButton
                onClick={() => setOpenDialog(true)}
                disabled={hideIconSearch}
                width="100%"
                style={{ cursor: productImg === '/images/proofs/mockup.png' ? 'arrow' : 'zoom-in' }}
              >
                <div className={classes.container384x284}>
                  <Img src={productImg} alt="product" style={{ width: '100%', objectFit: 'contain' }} />
                  {!hideIconSearch && <IconSearch classes={classes} />}
                  <Confetti
                    fire={fireConfetti}
                    particleCount={250}
                    origin={{ x: 0.5, y: 1 }}
                    colors={['#3577d4', '#9846dd', '#ffffff', '#0066ff', '#F0E8F7', '#F7A9FA']}
                    style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
                    onDecay={handleFinishedAnimation}
                  />
                </div>
              </StylessButton>
              {isPackType && productStatus.approved !== packOrBulkItem.product.status && !proof.is_in_invoice && (
                <Grid container style={{ padding: '0px 0px 20px 0px' }}>
                  <Grid item xs={5}>
                    <div className={classes.counter}>
                      <div className={classes.quantityTitle}>
                        <Typography className={classes.quantityPerPack}>Quantity per pack</Typography>
                      </div>
                      <Counter
                        value={unitsXPack}
                        min={1}
                        max={packOrBulkItem.hazardous_materials ? 1 : 10}
                        maxQuantityError={
                          packOrBulkItem.hazardous_materials
                            ? 'You cannot create a pack with more than 1 hazmat product'
                            : undefined
                        }
                        onChange={handleChangeUnitsXPack}
                        minimal
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FeedbackSection
              item={itemFeedBack}
              onChangesRequested={setChangesRequested}
              changesHistory={changesHistory}
            />
          </Grid>
        </Grid>
      </Grid>
      <ImageDialog
        img={productImg}
        largeImageSrc={packOrBulkItem.product.image}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
      <UnsavedChangesModal
        product={packOrBulkItem.product}
        changes={canBeApproved ? prepareForApproveDisplay(itemFeedBack) : changesRequested}
        onContinue={canBeApproved ? handleApprove : handleRequestChanges}
        isOpen={openConfirmationDialog}
        canBeApproved={canBeApproved}
        forConfirmation={
          isEqual(packOrBulkItem?.product.id, nextItem?.product?.id) &&
          (!action.current || action.current.includes('dashboard/proof-details'))
        }
        onClose={() => {
          setOpenConfirmationDialog(false);
          if (!isEqual(packOrBulkItem?.product.id, nextItem?.product?.id)) setNextItem(packOrBulkItem);
        }}
        discardChanges={() => {
          if (action.current) {
            unmount.current = true;
            history.push(action.current);
          } else {
            onSelectProduct(nextItem.product.id);
            setOpenConfirmationDialog(false);
          }
        }}
      />
      <Prompt
        when={!isDeepEmpty(changesRequested) && !changesRequested.id && !busy}
        message={(location, operation) => {
          if (operation === 'REPLACE') {
            return true;
          }
          setOpenConfirmationDialog(true);
          action.current = location.pathname;
          return unmount.current;
        }}
      />
    </Grid>
  );
};

export { HeaderSection, ProductCardSection, ProductDetail };
