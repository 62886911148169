export default {
  dialog: {
    maxWidth: 780,
    width: '100%'
  },
  dialogContent: {
    marginTop: -8,
    paddingBottom: 16
  },
  title: {
    color: '#0b1829',
    marginTop: ({ hasImg }) => (hasImg ? 30 : 18)
  },
  primaryBtn: {
    width: 236,
    marginTop: 36
  },
  secondaryBtn: {
    marginTop: 36,
    height: 'auto',
    padding: 0
  },
  description: {
    fontSize: 16,
    fontFamily: 'Gilroy',
    fontWeight: 400,
    lineHeight: 2,
    color: '#868a8f'
  }
};
