export default theme => ({
  root: {
    marginLeft: 200,
    marginRight: 300,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    },

    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      paddingTop: 20,
      maxWidth: 385
    }
  }
});
