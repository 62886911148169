import React, { useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FullscreenStepper } from '../../layouts/FullscreenStepper';
import DownloadTemplate from '../../addContacts/DownloadTemplate';
import UploadContacts from '../../addContacts/UploadContacts';
import ReviewColumns from '../../addContacts/reviewColumns/ReviewColumns';
import ReviewContacts from '../../addContacts/reviewContacts/ReviewContacts';
import NameContactGroup from '../../addContacts/grouping/NameContactGroup';
import SelectAction from '../../addContacts/SelectAction';

const getSteps = contactsGrouping => {
  const steps = [
    { label: 'Download Template', path: 'download-template' },
    { label: 'Upload Contacts', path: 'upload-contacts' },
    { label: 'Review Columns', path: 'review-columns' },
    { label: 'Review Values', path: 'review-contacts' }
  ];
  steps.push(
    contactsGrouping
      ? { label: 'Name Contact Group', path: 'name-contact-group' }
      : { label: 'Contact group action', path: 'select-action' }
  );

  return steps;
};
const ImportContacts = () => {
  const history = useHistory();
  const { step } = useParams();
  const { contactsFeGroupingTemp011921: grouping, leftBarNavigation } = useFlags();
  const from = useRef(useLocation().state?.from || (leftBarNavigation ? '/contacts' : '/shipments/contacts'));

  const steps = getSteps(grouping);
  const activeStep = steps.findIndex(s => step && step.includes(s.path));

  useEffect(() => {
    if (!step) history.replace(`/import-contacts/${steps[0].path}`);
  }, [step, history, steps]);

  const handleNextStep = useCallback(
    (param, state) => history.replace(`/import-contacts/${steps[activeStep + 1].path}/${param || ''}`, state),
    [activeStep, steps, history]
  );

  const handlePreviousStep = useCallback(
    param =>
      activeStep > 0
        ? history.replace(`/import-contacts/${steps[activeStep - 1].path}/${param || ''}`)
        : history.goBack(),
    [activeStep, steps, history]
  );

  const getStepComponent = () => {
    switch (step) {
      case 'upload-contacts':
        return UploadContacts;
      case 'review-columns':
        return ReviewColumns;
      case 'review-contacts':
        return ReviewContacts;
      case 'name-contact-group':
        return NameContactGroup;
      case 'select-action':
        return SelectAction;
      default:
        return DownloadTemplate;
    }
  };

  const Component = getStepComponent();

  return (
    <FullscreenStepper steps={steps} activeStep={activeStep}>
      <Component from={from.current} onNextStep={handleNextStep} onPreviousStep={handlePreviousStep} />
    </FullscreenStepper>
  );
};

export default ImportContacts;
