import React from 'react';
import { Grid, GridList, Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@swagup-com/components';
import OrderSummaryModalTable from './OrderSummaryModalTable';
import { Link } from '../../../../shared';
import styles from './styles/OrderSummaryModal';

const OrderSummaryModalHeader = ({ classes, title, subtitle }) => (
  <Grid container direction="column" alignItems="center" justifyContent="center">
    <Grid item xs={12}>
      <p className={classes.title}>{title}</p>
      <p className={classes.subtitle}>{subtitle}</p>
    </Grid>
  </Grid>
);

const OrderSummaryModal = ({ classes, open, onClose, trackingUrl, products, employee }) => (
  <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
    <DialogTitle>
      <div style={{ textAlign: 'right', lineHeight: 'normal' }}>
        <CloseIcon onClick={onClose} className={classes.closeIcon} />
      </div>
    </DialogTitle>
    <OrderSummaryModalHeader
      classes={classes}
      title="Order details"
      subtitle="Find a breakdown of your order below with a quick view to its shipping location"
    />
    <DialogContent>
      <GridList className={classes.orderContent} style={{ marginBottom: 50 }}>
        <OrderSummaryModalTable products={products} employee={employee} />
      </GridList>
      {trackingUrl && (
        <Grid container justifyContent="center" style={{ marginBottom: 50 }}>
          <Button
            variant="primary"
            size="small"
            component={Link}
            to={trackingUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: 386 }}
          >
            TRACK ORDER
          </Button>
        </Grid>
      )}
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(OrderSummaryModal);
