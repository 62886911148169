import React, { useState, useEffect, useMemo } from 'react';
import {
  withStyles,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Tabs as MuiTabs,
  Tab as MuiTab
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useIsAchPrepaid } from '../../../hooks';
import AddPaymentMethodDialog from '../../account/AddPaymentMethodDialog';
import gtm from '../../../utils/gtm';
import ACHItem from '../../paymentSection/ACHItem';
import CreditCardItem from '../../paymentSection/CreditCardItem';
import styles from './styles/PaymentProfilesModal';
import { paymentMethods } from '../../../apis/constants';

const Tabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 30,
      width: '100%',
      backgroundColor: '#3577d4'
    }
  }
})(MuiTabs);

const Tab = withStyles(() => ({
  root: {
    textTransform: 'none',
    color: '#8d9299',
    fontSize: 16,
    fontFamily: 'Gilroy-SemiBold',
    '&:focus': {
      opacity: 1
    },
    '&.Mui-selected': {
      fontFamily: 'Gilroy-Bold',
      color: '#434d5c'
    }
  }
}))(MuiTab);

const TabPanelACH = ({ paymentProfiles, selectedPaymentProfile, onSelectPaymentProfile }) => (
  <Grid container>
    {paymentProfiles.map(pm => (
      <ACHItem
        key={pm.customerPaymentProfileId}
        aCH={pm}
        selectedBankAccount={selectedPaymentProfile}
        toggleSelectedBankAccount={onSelectPaymentProfile}
      />
    ))}
  </Grid>
);

const TabPanelCCard = ({ paymentProfiles, selectedPaymentProfile, onSelectPaymentProfile }) => (
  <Grid container>
    {paymentProfiles.map(pm => (
      <CreditCardItem
        key={pm.customerPaymentProfileId}
        creditCard={pm}
        selectedCreditCard={selectedPaymentProfile}
        toggleSelectedCreditCard={onSelectPaymentProfile}
      />
    ))}
  </Grid>
);

const PaymentProfilesModal = ({
  open,
  onClose,
  classes,
  paymentProfile,
  paymentProfiles,
  onSelectDefaultPaymentProfile
}) => {
  const [tabSelected, setTabSelected] = useState();
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState();
  const [showNewPayment, setShowNewPayment] = useState(false);

  const isAchPrepaid = useIsAchPrepaid();

  useEffect(() => {
    if (open) {
      if (!isAchPrepaid && paymentProfile?.payment_method === paymentMethods.ach) {
        setTabSelected(paymentMethods.creditCard);
        return;
      }

      setTabSelected(paymentProfile ? paymentProfile.payment_method : paymentMethods.creditCard);
      if (paymentProfile) setSelectedPaymentProfile(paymentProfile.customer_payment_profile_id);
    }
  }, [open, paymentProfile, isAchPrepaid]);

  const ccards = useMemo(() => {
    return paymentProfiles
      .filter(p => p.payment_method === paymentMethods.creditCard)
      .map(p => ({
        ...p.credit_card,
        customerPaymentProfileId: p.customer_payment_profile_id
      }));
  }, [paymentProfiles]);

  const achs = useMemo(() => {
    return paymentProfiles
      .filter(p => p.payment_method === paymentMethods.ach)
      .map(p => ({
        ...p.bank_account,
        customerPaymentProfileId: p.customer_payment_profile_id
      }));
  }, [paymentProfiles]);

  const CurrentTabPanel = tabSelected === paymentMethods.creditCard ? TabPanelCCard : TabPanelACH;

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <p className={classes.dialogTitle}>Select payment method</p>
        <IconButton classes={{ root: classes.dialogTitleCloseRoot }} disableRipple disableFocusRipple onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Tabs
          value={tabSelected}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newValue) => setTabSelected(newValue)}
          aria-label="disabled tabs example"
          TabIndicatorProps={{ children: <div /> }}
        >
          <Tab disableRipple label="Credit Cards" value={paymentMethods.creditCard} />
          {isAchPrepaid && <Tab disableRipple label="ACH Accounts" value={paymentMethods.ach} />}
        </Tabs>
      </DialogContent>
      <DialogContent classes={{ root: classes.dialogContentRoot }} style={{ textAlign: 'right' }}>
        <Button
          disableRipple
          disableFocusRipple
          disableTouchRipple
          className={classes.newPaymentProfileButton}
          onClick={() => setShowNewPayment(true)}
        >
          Add new
        </Button>
        <AddPaymentMethodDialog
          paymentMethod={tabSelected}
          open={showNewPayment}
          onClose={() => setShowNewPayment(!showNewPayment)}
        />
      </DialogContent>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <CurrentTabPanel
          paymentProfiles={tabSelected === paymentMethods.creditCard ? ccards : achs}
          selectedPaymentProfile={selectedPaymentProfile}
          onSelectPaymentProfile={id => setSelectedPaymentProfile(id)}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          variant="contained"
          disabled={!selectedPaymentProfile}
          disableElevation
          disableRipple
          disableFocusRipple
          disableTouchRipple
          classes={{ root: classes.dialogActionsButtonRoot, label: classes.dialogActionsButtonLabel }}
          onClick={() => {
            onSelectDefaultPaymentProfile(selectedPaymentProfile);
            gtm.onClickContinue('Payment method clicked');
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(PaymentProfilesModal);
