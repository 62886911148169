// Common
export const LEFT_NAV_OPEN = 'LEFT_NAV_OPEN';
export const LEFT_NAV_CLOSE = 'LEFT_NAV_CLOSE';
export const HIDE_LEFT_NAV = 'HIDE_LEFT_NAV';

// Catalog
export const CATALOG_API_CALL_START = 'CATALOG_API_CALL_START';
export const CATALOG_API_CALL_SUCCESS = 'CATALOG_API_CALL_SUCCESS';
export const CATALOG_API_CALL_ERROR = 'CATALOG_API_CALL_ERROR';
export const CATALOG_INITIALIZE = 'CATALOG_INITIALIZE';
export const CATALOG_LOAD = 'CATALOG_LOAD';
export const CATALOG_PURCHASE = 'CATALOG_PURCHASE';
export const CATALOG_SET_SELECTED_COLLECTIONS = 'CATALOG_SET_SELECTED_COLLECTIONS';
export const CATALOG_RESET_SELECTED_COLLECTIONS = 'CATALOG_RESET_SELECTED_COLLECTIONS';

// Cart
export const CART_API_CALL_START = 'CART_API_CALL_START';
export const CART_API_CALL_SUCCESS = 'CART_API_CALL_SUCCESS';
export const CART_API_CALL_ERROR = 'CART_API_CALL_ERROR';
export const CART_ADD_PRODUCT = 'CART_ADD_PRODUCT';
export const CART_REMOVE_PRODUCT = 'CART_REMOVE_PRODUCT';
export const CART_UPDATE_PRODUCT = 'CART_UPDATE_PRODUCT';
export const CART_CHANGE_QUANTITY = 'CART_CHANGE_QUANTITY';
export const CART_CHANGE_NUMBER_OF_COLORS = 'CART_CHANGE_NUMBER_OF_COLORS';
export const CART_REFRESH_PRICES = 'CART_REFRESH_PRICES';
export const CART_CLEAR = 'CART_CLEAR';

// Sign
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const IS_LOGGED = 'IS_LOGGED';
export const AUTHENTICATE = 'AUTHENTICATE';

// Orders
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const SELECT_ORDER = 'SELECT_ORDER';

// Proofs
export const ADD_PRODUCT_COMMENTS = 'ADD_PRODUCT_COMMENTS';
export const FETCH_DELIVERY_METHODS = 'FETCH_DELIVERY_METHODS';
export const SET_PRODUCT_COMMENTS = 'SET_PRODUCT_COMMENTS';
export const CLEAN_PRODUCT_COMMENTS = 'CLEAN_PRODUCT_COMMENTS';
export const SET_PROOF_DATA = 'SET_PROOF_DATA';

// Opportunities
export const SET_OPPORTUNITY = 'SET_OPPORTUNITY';

// Employees
export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE';
export const DELETE_EMPLOYEES = 'DELETE_EMPLOYEES';
export const LOADING_DELIVERY_METHODS = 'LOADING_DELIVERY_METHODS';

// Shipments
export const SHIPMENTS_FETCH_START = 'SHIPMENTS_FETCH_START';
export const SHIPMENTS_FETCH_SUCCESS = 'SHIPMENTS_FETCH_SUCCESS';
export const SHIPMENTS_DELETE_START = 'SHIPMENTS_DELETE_START';
export const SHIPMENTS_DELETE_SUCCESS = 'SHIPMENTS_DELETE_SUCCESS';
export const SHIPMENTS_HAVE_INVALID_ADDRESS = 'SHIPMENTS_HAVE_INVALID_ADDRESS';

// Shipment Group
export const ADD_SHIPMENT_GROUP_DIRECTORY_ORDERS = 'ADD_SHIPMENT_GROUP_DIRECTORY_ORDERS';
export const ADD_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT = 'ADD_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT';
export const ADD_SHIPMENT_GROUP_RECIPIENTS_START = 'ADD_SHIPMENT_GROUP_RECIPIENTS_START';
export const ADD_SHIPMENT_GROUP_RECIPIENTS_SUCCESS = 'ADD_SHIPMENT_GROUP_RECIPIENTS_SUCCESS';
export const CLEAN_SHIPMENT_GROUP = 'CLEAN_SHIPMENT_GROUP';
export const CREATED_SHIPMENT_GROUP_DIRECTORY_ORDERS = 'CREATED_SHIPMENT_GROUP_DIRECTORY_ORDERS';
export const DELETE_SHIPMENT_GROUP_DIRECTORY_ORDER = 'DELETE_SHIPMENT_GROUP_DIRECTORY_ORDER';
export const DELETE_SHIPMENT_GROUP_RECIPIENTS = 'DELETE_SHIPMENT_GROUP_RECIPIENTS';
export const DELETE_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT = 'DELETE_SHIPMENT_GROUP_RECIPIENTS_TO_SELECT';
export const SELECT_SHIPMENT_GROUP_PRODUCTS = 'SELECT_SHIPMENT_GROUP_PRODUCTS';
export const SELECT_SHIPMENT_GROUP_RECIPIENTS = 'SELECT_SHIPMENT_GROUP_RECIPIENTS';
export const TOGGLE_SELECT_FROM_EXISTING = 'TOGGLE_SELECT_FROM_EXISTING';
export const UPDATE_SHIPMENT_GROUP_DIRECTORY_ORDER = 'UPDATE_SHIPMENT_GROUP_DIRECTORY_ORDER';
export const UPDATE_SHIPMENT_GROUP_RECIPIENT = 'UPDATE_SHIPMENT_GROUP_RECIPIENT';
export const UPDATE_SHIPMENT_GROUP_LOADING_STATUS = 'UPDATE_SHIPMENT_GROUP_LOADING_STATUS';
export const UPDATE_SELECTED_RECIPIENTS_SHIPPING_METHOD = 'UPDATE_SELECTED_RECIPIENTS_SHIPPING_METHOD';
export const UPDATE_SELECTED_RECIPIENTS_SHIPPING_DATE = 'UPDATE_SELECTED_RECIPIENTS_SHIPPING_DATE';
export const SET_SHIPMENT_GROUP_ID = 'SET_SHIPMENT_GROUP_ID';
export const SET_CONTACT_FETCH_QUERY = 'SET_CONTACT_FETCH_QUERY';
export const VALIDATE_SHIPMENT_GROUP_DIRECTORY_ORDERS = 'VALIDATE_SHIPMENT_GROUP_DIRECTORY_ORDERS';
export const TOOGLE_ALL_SHIPMENT_GROUP_RECIPIENTS_COLLAPSED = 'TOOGLE_ALL_SHIPMENT_GROUP_RECIPIENTS_COLLAPSED';
export const UPDATE_SHIPMENT_GROUP_RECIPIENT_COLLAPSED = 'UPDATE_SHIPMENT_GROUP_RECIPIENT_COLLAPSED';

// Bag
export const CREATE_ORDER = 'CREATE_ORDER';
export const CHANGE_BAG_OPEN_STATUS = 'CHANGE_BAG_OPEN_STATUS';
export const REMOVE_BAG_SUBITEM = 'REMOVE_BAG_SUBITEM';

// profile
export const LOAD_PROFILE = 'LOAD_PROFILE';
export const PATCH_PROFILE = 'PATCH_PROFILE';

// company
export const LOAD_COMPANY = 'LOAD_COMPANY';
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const GET_PAYMENT_PROFILES = 'GET_PAYMENT_PROFILES';
export const ADD_PAYMENT_PROFILE = 'ADD_PAYMENT_PROFILE';
export const DELETE_PAYMENT_PROFILE = 'DELETE_PAYMENT_PROFILE';
export const SET_DEFAULT_PAYMENT_PROFILE = 'SET_DEFAULT_PAYMENT_PROFILE';
export const EDIT_PAYMENT_PROFILE_NAME = 'EDIT_PAYMENT_PROFILE_NAME';

// account product
export const SET_ACCOUNT_PRODUCT = 'SET_ACCOUNT_PRODUCT';

// checkout
export const SEND_DATA_ANET = 'SEND_DATA_ANET';
export const GET_PAYMENT_PROFILE = 'GET_PAYMENT_PROFILE';

// invoice
export const SELECT_INVOICE = 'SELECT_INVOICE';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_BY_QUERY = 'GET_INVOICES_BY_QUERY';
export const SELECT_PUBLIC_INVOICE = 'SELECT_PUBLIC_INVOICE';

// sizes
export const FETCH_SIZES = 'FETCH_SIZES';

// Multishipping
export const ADD_SHIPPMENT = 'ADD_SHIPPMENT';
export const ADD_PRODUCT_TO_SHIP = 'ADD_PRODUCT_TO_SHIP';
export const DELETE_SHIPPING_CARD = 'DELETE_SHIPPING_CARD';
export const SAVE_DATA_TO_SHIP = 'SAVE_DATA_TO_SHIP';
export const SELECT_BATCH_DELIVERY_METHOD = 'SELECT_BATCH_DELIVERY_METHOD';
export const ADD_BATCH_SHIPPING_DATE = 'ADD_BATCH_SHIPPING_DATE';
export const ADD_BATCH_SHIPPING_NOTE = 'ADD_BATCH_SHIPPING_NOTE';
export const ADD_SHIPPING_TAX = 'ADD_SHIPPING_TAX';
export const ADD_BATCH_SHIPPING_TAX = 'ADD_BATCH_SHIPPING_TAX';
export const ADD_SHIPPING_DELIVERY_METHOD = 'ADD_SHIPPING_DELIVERY_METHOD';
export const CLEAN_MULTISHIPPING = 'CLEAN_MULTISHIPPING';
export const SAVE_CREDIT = 'SAVE_CREDIT';
export const SET_SHIPPING_ORDER_COST = 'SET_SHIPPING_ORDER_COST';
export const CREATE_SHIPMENT = 'CREATE_SHIPMENT';

// storage
export const FETCH_STORAGE_PRICES = 'FETCH_STORAGE_PRICES';
export const FETCH_STORAGE_CATEGORIES = 'FETCH_STORAGE_CATEGORIES';

// shipping prices
export const FETCH_INDIVIDUAL_PACK_PRICES = 'FETCH_INDIVIDUAL_PACK_PRICES';

// Onboarding
export const ONBOARDING_NEXT_STEP = 'ONBOARDING_NEXT_STEP';
export const ONBOARDING_PREVIOUS_STEP = 'ONBOARDING_PREVIOUS_STEP';
export const ONBOARDING_UPDATE_INFO = 'ONBOARDING_UPDATE_INFO';
export const ONBOARDING_RESET_STEPS = 'ONBOARDING_RESET_STEPS';
export const ONBOARDING_TWO_PAGE = 'ONBOARDING_TWO_PAGE';
export const ONBOARDING_ONE_PAGE = 'ONBOARDING_ONE_PAGE';

// Shipping credits
export const FETCH_CREDIT_INFO = 'FETCH_PRODUCTS_PENDING';
export const ADD_CREDIT = 'FETCH_PRODUCTS_SUCCESS';

// Reorder
export const CREATE_REORDER_PRODUCTS = 'CREATE_REORDER_PRODUCTS';
export const SET_REORDER_PRODUCTS_DATA = 'SET_REORDER_PRODUCTS_DATA';
export const TOGGLE_REORDER_PRODUCT_DETAILS = 'TOGGLE_REORDER_PRODUCT_DETAILS';
export const REMOVE_REORDER_PRODUCTS = 'REMOVE_REORDER_PRODUCTS';

// Global
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_METADATA = 'FETCH_METADATA';

// Top Message
export const SET_SHOW_TOP_MESSAGE = 'SET_SHOW_TOP_MESSAGE';

// Dashboard Bar Message
export const SET_SHOW_DASHBOARD_MESSAGE = 'SET_SHOW_DASHBOARD_MESSAGE';

// Saving contacts snackbar
export const PROCESS_IDLE = 'PROCESS_IDLE';
export const PROCESS_PENDING = 'PROCESS_PENDING';
export const PROCESS_SUCCESS = 'PROCESS_SUCCESS';
export const PROCESS_SUCCESS_PROCESSED = 'PROCESS_SUCCESS_PROCESSED';
export const PROCESS_FAILED = 'PROCESS_FAILED';
export const PROCESS_START = 'PROCESS_START';

// Global notifications
export const STATUS_CHANGED = 'STATUS_CHANGED';

// Executions
export const SEND_SWAG_EXECUTION = 'SEND_SWAG_EXECUTION';

// Integrations
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export const EXIT_INTEGRATION = 'EXIT_INTEGRATION';
export const CLEAR_INTEGRATION = 'CLEAR_INTEGRATION';
export const SET_INTEGRATIONS_TRIAL_MODAL_CLOSE = 'SET_INTEGRATIONS_TRIAL_MODAL_CLOSE';

// Integrations - TopHeader CTA
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_TAB_VALUE = 'SET_TAB_VALUE';
export const SET_BUTTON_TEXTS = 'SET_BUTTON_TEXTS';

export const openModal = () => ({
  type: OPEN_MODAL
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});

export const setTabValue = value => ({
  type: SET_TAB_VALUE,
  payload: value
});

export const setButtonTexts = (nativeText, zapierText) => ({
  type: SET_BUTTON_TEXTS,
  payload: { nativeText, zapierText }
});
