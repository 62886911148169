import pick from 'lodash/pick';
import mergeWith from 'lodash/mergeWith';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import mapValues from 'lodash/mapValues';
import flatten from 'lodash/flatten';

import axios from 'axios';
import { partition, sumBy } from 'lodash';

export const addressFields = [
  'shipping_address1',
  'shipping_address2',
  'shipping_city',
  'shipping_country',
  'shipping_state',
  'shipping_zip'
];

export const isSameAddress = (contactA, contactB) =>
  isEqual(pick(contactA, addressFields), pick(contactB, addressFields));

export const getQuantitiesBreakdown = sizes =>
  sizes.map(sz => ({ [sz.size?.id ?? sz.size]: sz.quantity })).reduce(merge, {});

export const joinQuantitiesBreakdowns = (breakdownA = {}, breakdownB = {}) => {
  const jointBreakdown = { ...breakdownA };
  return mergeWith(jointBreakdown, breakdownB, (qtyA, qtyB) => Math.max((qtyA ?? 0) + (qtyB ?? 0), 0));
  // Minimum value is capped at 0 because negative quantities are not valid
};

export const substractQuantitiesBreakdowns = (breakdownA = {}, breakdownB = {}) => {
  const breakdownToSubtract = mapValues(breakdownB, qty => qty * -1);
  return joinQuantitiesBreakdowns(breakdownA, breakdownToSubtract);
};

export const cancellableQuery = query => (...args) => {
  const source = axios.CancelToken.source();
  const promise = query(...args, source.token);

  // Cancel the request on manual cancellation or if React Query calls the `promise.cancel` method
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const mapProductToProof = product => ({
  id: product.id,
  product,
  sizes: product.stock
});

export const isInactiveSize = size => !size.active && size.quantity > 0;

export const doesProductHasInactiveSizes = product => product.sizes.some(isInactiveSize);

const getSizeId = size => size.size?.id ?? size.id;

export const doesInactiveSizeHasQuantity = sizes => quantity => {
  return quantity.quantity > 0 && sizes.some(s => getSizeId(s) === quantity.size && !s.active);
};

const getProofId = proof => proof.proof?.id ?? proof.proof;

export const doesProofHasInactiveSizes = productsWithInactiveSizes => proof =>
  proof.sizes.some(s => productsWithInactiveSizes[getProofId(proof)]?.includes(s.size));

export const doesDirectoryHasInactiveSizes = productsWithInactiveSizes => directory =>
  directory.proofs.some(doesProofHasInactiveSizes(productsWithInactiveSizes));

export const mapProductsToInactiveSizes = products =>
  Object.fromEntries(
    products.map(p => [
      p.id,
      Array.from(
        p.sizes.filter(s => !s.active),
        s => s.size.id
      )
    ])
  );

export const mapSizesWithActiveField = (sizes, product) =>
  sizes
    .filter(s => s.category === 'Apparel')
    .map(s => {
      const productSize = product.sizes.find(ps => ps.size.id === s.id);
      return { ...s, active: productSize?.active ?? true };
    });

export const buildProofAlikeObject = sizes => product => ({
  ...product,
  product: { ...product, ...product.product },
  sizes: sizes.map(size => {
    const sizeInfo = product.sizes.find(s => s.size.id === size.id);
    return { size, quantity: sizeInfo ? sizeInfo.quantity : 0 };
  })
});

export const mapOrderProductsToUnallocated = orderProducts =>
  orderProducts.reduce(
    (acc, { id, sizes }) => ({
      ...acc,
      [id]: {
        total: sizes.reduce((sum, s) => sum + s.quantity, 0),
        breakdown: sizes
          .filter(size => size.quantity > 0)
          .reduce((acc2, s) => ({ ...acc2, [s.size.id]: s.quantity }), {})
      }
    }),
    {}
  );

export const buildWarehouseProofs = quantities =>
  quantities
    .filter(([, breakdown]) => Object.values(breakdown).some(qty => qty > 0))
    .map(([{ id }, breakdown]) => ({
      proof: id,
      sizes: Object.entries(breakdown)
        .map(([sizeId, quantity]) => ({ size: +sizeId, quantity }))
        .filter(size => size.quantity > 0)
    }));

export const getDirectoryOrders = order => flatten(order.shipmentGroups.map(sg => sg.directory_orders));

const productionRequestMessage =
  'A SwagUp team member is reviewing your order. You will receive an email shortly when you are able to proceed with payment.';
const outOfStockMessage = 'Out of Stock quantities have been removed';

const pricingErrorMeesage = 'Error: there is a product(s) with $0.00 price';

export const cannotContinueMessage = (
  orderHasProductsUnapproved,
  orderInProductionRequest,
  orderHasInactiveSizes,
  hasPricingError
) => {
  if (hasPricingError) return pricingErrorMeesage;

  if (orderInProductionRequest) return productionRequestMessage;
  return orderHasProductsUnapproved || orderHasInactiveSizes
    ? `You can proceed with the Request once all ${
        orderHasProductsUnapproved
          ? `the Products are Approved ${orderHasInactiveSizes ? `and ${outOfStockMessage}` : ''}`
          : outOfStockMessage
      }`
    : '';
};

export const getShipmentsGroupQty = ({ directory_orders }) =>
  directory_orders.reduce(
    (add, dior) => add + dior.proofs.reduce((acc, p) => acc + p.sizes.reduce((plus, s) => plus + s.quantity, 0), 0),
    0
  );

export const getShipmentGroupsInfo = shipmentGroups => {
  const domesticPrice = sumBy(shipmentGroups, sg => Number(sg.domestic_price));
  const internationalPrice = sumBy(shipmentGroups, sg => Number(sg.international_price));
  const domesticPriceBeforeDiscount = sumBy(shipmentGroups, sg => Number(sg.domestic_price_without_discount || 0));
  const internationalPriceBeforeDiscount = sumBy(shipmentGroups, sg =>
    Number(sg.international_price_without_discount || 0)
  );

  const orders = shipmentGroups.reduce((ords, sg) => ords.concat(sg.directory_orders), []);
  const [domestic, international] = partition(orders, o => o.directory.shipping_country === 'US');
  const domesticRecipients = new Set(domestic.map(o => o.directory.id)).size;
  const internationalRecipients = new Set(international.map(o => o.directory.id)).size;

  return {
    domestic: {
      price: domesticPrice,
      price_without_discount: domesticPriceBeforeDiscount,
      recipients: domesticRecipients
    },
    international: {
      price: internationalPrice,
      price_without_discount: internationalPriceBeforeDiscount,
      recipients: internationalRecipients
    }
  };
};

export const getShipmentsQty = shipmentGroups => shipmentGroups.reduce((sum, sg) => sum + getShipmentsGroupQty(sg), 0);
