import React from 'react';
import { Grid } from '@material-ui/core';
import CardContainer from './CardContainer';
import MultiItemProducts from './MultiItemProducts';
import MultiItemsHeader from './MultiItemsHeader';

const ShippingHistoryMultiItems = ({ status, date, orderNumber, trackingUrl, tracking, products, employee }) => (
  <CardContainer>
    <Grid container justifyContent="space-between">
      <Grid item xs={12}>
        <MultiItemsHeader
          date={date}
          orderNumber={orderNumber}
          itemsLength={products.length}
          trackingUrl={trackingUrl}
          tracking={tracking}
          status={status}
          employee={employee}
          products={products}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiItemProducts products={products} />
      </Grid>
    </Grid>
  </CardContainer>
);

export default ShippingHistoryMultiItems;
