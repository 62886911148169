import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Grid, Typography, Chip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@swagup-com/components';
import isEmpty from 'lodash/isEmpty';
import csvProcessesApi from '../../../apis/swagup/csvProcesses';
import toErrorPage from '../../../helpers/toErrorPage';
import { usePagination, useAsync } from '../../../hooks';
import Pagination from '../../shared/Pagination';
import Loader from '../../global/Loader';
import { Container, Footer } from '../../layouts/FullscreenStepper';
import ConfirmationModal from '../ConfirmModal';
import ContactForm from './ContactForm';
import ContactsTable from './ContactsTable';
import styles from './styles/ReviewContacts';

const useStyles = makeStyles(styles);

const perPageOptions = [12, 24, 36, 48];
const ReviewContacts = ({ onNextStep, onPreviousStep }) => {
  const classes = useStyles();
  const { fileId } = useParams();
  const history = useHistory();
  const [contacts, setContacts] = useState([]);
  const [total, setTotal] = useState([]);
  const [totalWithErrors, setTotalWithErrors] = useState(0);
  const [[selectedContacts], setSelectedContacts] = useState([new Map()]);
  const prevSelectedAmount = useRef(selectedContacts.size);
  const [showPrevStepConfirmation, setShowPrevStepConfirmation] = useState(false);
  const [showNextStepConfirmation, setShowNextStepConfirmation] = useState(false);
  const [editingContact, setEditingContact] = useState();
  const [isLoading, setIsLoading] = useState();
  const { nextPage, pageIndex, perPage, sizeOptions, previousPage, changeSize } = usePagination(total, perPageOptions);

  const fetchOutputsCb = useCallback(() => csvProcessesApi.fetchOutputs(fileId, perPage, pageIndex * perPage), [
    fileId,
    pageIndex,
    perPage
  ]);
  const [fetchOutputs, outputs, isOutputsPending, outputsError] = useAsync(fetchOutputsCb, 500);

  const fetchCSVAndDeleteInvalidCb = useCallback(async () => {
    const csvResp = await csvProcessesApi.fetchBadCSV(fileId);
    if (csvResp?.result === 'error') return csvResp;
    const deleteResp = await csvProcessesApi.deleteAllOutputs(fileId, 0);
    if (deleteResp?.result === 'error') return deleteResp;

    return csvResp;
  }, [fileId]);
  const [fetchCSVAndDeleteInvalid, invalidCSV, isInvalidCSVPending, invalidCSVError] = useAsync(
    fetchCSVAndDeleteInvalidCb
  );

  useEffect(() => {
    const fetchWithErrors = async () => {
      const response = await csvProcessesApi.fetchOutputs(fileId, 1, 0, 0);
      if (response.result === 'ok') setTotalWithErrors(response.data.count);
    };
    fetchWithErrors();
  }, [fileId]);

  useEffect(() => {
    setIsLoading(isOutputsPending || isInvalidCSVPending);
  }, [isOutputsPending, isInvalidCSVPending]);

  useEffect(() => {
    const error = [outputsError, invalidCSVError].find(err => err);
    if (error) toErrorPage(error, history);
  }, [outputsError, invalidCSVError, history]);

  useEffect(() => {
    fetchOutputs();
    return () => fetchOutputs.cancel();
  }, [fetchOutputs]);

  useEffect(() => {
    if (outputs?.result === 'ok') {
      setContacts(outputs.data.results);
      setTotal(outputs.data.count);
    }
  }, [outputs]);

  useEffect(() => {
    if (invalidCSV) {
      const blob = new Blob([invalidCSV.data], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `invalid-contacts-for${fileId}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link);
      onNextStep(fileId);
    }
  }, [invalidCSV, fileId, onNextStep]);

  const toggleSelect = e => {
    const { name } = e.target;
    const deleted = selectedContacts.delete(+name);
    if (!deleted) {
      const contact = contacts.find(c => c.id === +name);
      selectedContacts.set(contact.id, contact);
    }
    setSelectedContacts([selectedContacts]);
  };

  const amountSelectedInPage = contacts.filter(c => selectedContacts.has(c.id))?.length || 0;

  const toggleSelectAll = () => {
    const allSelected = amountSelectedInPage === contacts.length;
    contacts.forEach(c => (allSelected ? selectedContacts.delete(c.id) : selectedContacts.set(c.id, c)));
    setSelectedContacts([selectedContacts]);
  };

  const handleOpenEdit = (e, data) => {
    if (e.target.nodeName !== 'INPUT' && !e.target.className.includes('checkboxCell')) {
      setEditingContact(data);
    }
  };

  const handleSaveContact = () => {
    if (!isEmpty(editingContact.errors)) setTotalWithErrors(prev => prev - 1);
    fetchOutputs();
    setEditingContact();
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const response = await csvProcessesApi.deleteOutputs(fileId, [...selectedContacts.keys()].join());
    if (response.result === 'ok') {
      fetchOutputs();
      const withErrors = [...selectedContacts.values()].reduce((acc, c) => (c.output_json ? acc + 1 : acc), 0);
      setTotalWithErrors(prev => prev - withErrors);
      selectedContacts.clear();
      setSelectedContacts([selectedContacts]);
    }
  };

  if (selectedContacts.size) prevSelectedAmount.current = selectedContacts.size;
  const continueFn = totalWithErrors === 0 ? () => onNextStep(fileId) : () => setShowNextStepConfirmation(true);

  return (
    <>
      <Container direction="column" style={{ paddingBottom: 30 }}>
        {isLoading && <Loader />}
        <Typography variant="h4">Review Values</Typography>
        <Grid container>
          <Typography variant="body1">Click on the row to edit</Typography>
          {totalWithErrors > 0 && (
            <Chip
              classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              label={`${totalWithErrors} row${totalWithErrors > 1 ? 's' : ''} have errors`}
            />
          )}
        </Grid>
        <ContactsTable
          contacts={contacts}
          amountSelectedInPage={amountSelectedInPage}
          selectedContacts={selectedContacts}
          onEdit={handleOpenEdit}
          onToggleSelect={toggleSelect}
          onToggleSelectAll={toggleSelectAll}
        />
        <Grid container alignItems="center" className={classes.paginationContainer}>
          {total >= 0 && (
            <Pagination
              count={total}
              pageIndex={pageIndex}
              perPage={perPage}
              next={nextPage}
              previous={previousPage}
              sizeOptions={sizeOptions}
              onPerPageChange={changeSize}
              endText="contacts"
            />
          )}
          <Alert
            icon={false}
            classes={{
              root: classes.alertRoot,
              message: classes.alertMessage
            }}
            action={
              <Button variant="text" onClick={handleDelete} className={classes.deleteBtn}>
                Delete
              </Button>
            }
            className={selectedContacts.size > 0 ? 'show' : undefined}
          >
            {prevSelectedAmount.current} contacts selected
          </Alert>
        </Grid>
        <ConfirmationModal
          open={showPrevStepConfirmation}
          title="Are you sure you want to go back?"
          description="All your edits will be lost and this action cannot be undone"
          onClose={() => setShowPrevStepConfirmation(false)}
          primaryAction={{ text: 'Stay on this page', onClick: () => setShowPrevStepConfirmation(false) }}
          secondaryAction={{ text: 'Go to Previous page', onClick: () => onPreviousStep(fileId) }}
        />
        <ConfirmationModal
          open={showNextStepConfirmation}
          title="The CSV will continue with the correct recipients only"
          description="A separate CSV will be downloaded with the failed ones"
          onClose={() => setShowNextStepConfirmation(false)}
          primaryAction={{
            text: 'Continue',
            loading: isInvalidCSVPending,
            onClick: fetchCSVAndDeleteInvalid
          }}
        />
      </Container>
      {editingContact && (
        <ContactForm
          key={editingContact.id}
          fileId={fileId}
          contact={editingContact}
          onSave={handleSaveContact}
          onClose={() => setEditingContact()}
        />
      )}
      <Footer
        onContinue={total !== totalWithErrors && continueFn}
        onPrevious={() => setShowPrevStepConfirmation(true)}
      />
    </>
  );
};

export default ReviewContacts;
