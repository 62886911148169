import React from 'react';
import { Grid, Button, withStyles } from '@material-ui/core';
import { CenteredGrid } from '../../../shared';
import styles from './HaveAQuestion.styles';

const HaveAQuestion = ({ classes, onOpenModal }) => (
  <Grid container justifyContent="center" className={classes.externalContainer}>
    <CenteredGrid container justifyContent="center" className={classes.container}>
      <Grid item xs={12}>
        <h2 className={classes.title}>Still have a question?</h2>
      </Grid>
      <Grid item xs={12}>
        <p className={classes.subText}>Feel free to contact us now</p>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" className={classes.button} onClick={() => onOpenModal(true)}>
          <span className={classes.buttonText}>Contact Us</span>
        </Button>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default withStyles(styles)(HaveAQuestion);
