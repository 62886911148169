export default {
  dialog: {
    height: '100%',
    maxHeight: 624,
    width: '100%',
    maxWidth: 980,
    padding: '9px 63px 35px 63px',
    '& .MuiDialogContent-root': { height: '100%' }
  },
  table: {
    '& th, & td': {
      border: 'none',
      backgroundColor: '#ffffff',
      fontFamily: 'Gilroy-Medium',
      fontSize: 12,
      color: '#868a8f',
      lineHeight: 'normal',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    '& th': { padding: '0 0 15px 0' },
    '& td': {
      padding: '12px 15px 12px 0',
      color: '#0b1829',
      lineHeight: 0.83
    },
    '& .large': { width: 156, maxWidth: 156 },
    '& .small': { width: 100, maxWidth: 100 }
  },
  select: {
    width: '100%',
    height: 16,
    fontSize: 12,
    '& .MuiSelect-root, & fieldset': { padding: 0 },
    '& .MuiSvgIcon-root': { right: 0 },
    '& fieldset': { border: 'none !important' }
  },
  selectRoot: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100%'
  },
  selectIcon: {
    color: '#3577d4',
    width: 16,
    height: 16,
    top: 'calc(50% - 8px)'
  }
};
