const styles = theme => ({
  root: {
    marginTop: 0
  },
  shipmentsContainer: {
    position: 'relative',
    width: '100%'
  },
  filterContainer: {
    padding: '0 12px',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      marginTop: 25,
      '& ~ &': { marginTop: 10 }
    }
  },
  infoContainer: {
    position: 'relative',
    width: '100%'
  },
  floatingInfoMessage: {
    maxWidth: 464,
    zIndex: 1400,
    width: 'auto',
    top: '32px',
    position: 'fixed'
  },
  comboFilter: {
    maxWidth: 260,
    width: '100%',
    [theme.breakpoints.down('xs')]: { maxWidth: '100%' }
  },
  search: {
    height: 58
  },
  iconContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '4px'
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#125CFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px'
  }
});

export default styles;
