import * as React from 'react';
import { Divider, makeStyles, MenuItem } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { Button } from '@swagup-com/components';
import { MenuButton, menuStyles } from './TableCommon';
import gtm from '../../../../../utils/gtm';

const useStyles = makeStyles(theme => ({
  ...menuStyles,
  menuItem: {
    ...menuStyles.menuItem,
    padding: '0px 4px',
    borderRadius: 10,
    '&.Mui-disabled': {
      color: '#787b80'
    }
  },
  addButton: ({ open }) => ({
    padding: '0 17px 0 24px',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    letterSpacing: 0,
    width: 178,
    height: 48,
    color: open ? '#787b80' : '#3577d4',
    border: open ? 'solid 1px transparent' : 'solid 1px #3577d4',
    [theme.breakpoints.up(1280)]: {
      width: 'fit-content',
      height: 40,
      fontSize: 12
    }
  }),
  addButtonIcon: { fontSize: 16 }
}));

const CustomButton = ({ open, onClick, disabled }) => {
  const classes = useStyles({ open });
  const Icon = open ? Close : Add;

  return (
    <Button
      aria-controls="add-recipient-menu"
      aria-haspopup="true"
      className={classes.addButton}
      onClick={event => {
        gtm.onClick('Add Recipients');
        onClick(event);
      }}
      disabled={disabled}
    >
      Add Recipients
      <Icon className={classes.addButtonIcon} />
    </Button>
  );
};

const AddRecipientButton = ({ onSelectExisting, onAddNew, disabled, disableSelect }) => {
  const classes = useStyles();

  const [open, toggleOpen] = React.useReducer(prev => !prev, false);

  const handleSelectExisting = () => {
    toggleOpen();
    onSelectExisting();
  };
  const handleAddNew = () => {
    toggleOpen();
    onAddNew();
  };

  return (
    <MenuButton
      open={open}
      onClick={toggleOpen}
      onClose={toggleOpen}
      ButtonComponent={CustomButton}
      classes={{ paper: classes.menu }}
      id="add-recipient-menu"
      disabled={disabled}
    >
      <MenuItem className={classes.menuItem} onClick={handleSelectExisting} disabled={disableSelect}>
        Select from Existing
      </MenuItem>
      <Divider className={classes.divider} style={{ margin: '5px 0' }} />
      <MenuItem className={classes.menuItem} onClick={handleAddNew}>
        Add New Contact
      </MenuItem>
    </MenuButton>
  );
};

export default AddRecipientButton;
