import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Img } from '../global/ImgUtils';
import { Modal } from '../shared/Modal';
import styles from './styles/ConfirmModal';

const useStyles = makeStyles(styles);

const ConfirmModal = ({ open, imgSrc, title, description, onClose, primaryAction, secondaryAction }) => {
  const classes = useStyles({ hasImg: Boolean(imgSrc) });
  return (
    <Modal open={open} onClose={onClose} innerClasses={{ paper: classes.dialog }}>
      <Grid container direction="column" alignItems="center" className={classes.dialogContent}>
        {imgSrc && <Img src={imgSrc} style={{ height: 96 }} />}
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.description}>{description}</div>
        <Button
          variant="primary"
          className={classes.primaryBtn}
          loading={primaryAction.loading}
          disabled={primaryAction.loading}
          onClick={() => primaryAction.onClick()}
        >
          {primaryAction.text}
        </Button>
        {secondaryAction && (
          <Button variant="text" onClick={() => secondaryAction.onClick()} className={classes.secondaryBtn}>
            {secondaryAction.text}
          </Button>
        )}
      </Grid>
    </Modal>
  );
};

export default ConfirmModal;
