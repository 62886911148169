import React, { forwardRef, useContext } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useRouter } from 'next/router';
import { hiddenNavbarRoutes } from '../../utils/constants';

const styles = theme => ({
  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 1440,
    [theme.breakpoints.only('lg')]: {
      width: 1200
    },
    [theme.breakpoints.only('md')]: {
      width: 900
    },
    [theme.breakpoints.only('sm')]: {
      width: 600
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
      paddingLeft: 8,
      paddingRight: 8
    }
  }
});

const CenteredGridWithRef = ({ children, classes, className, ...props }, ref) => {
  const { leftBarNavigation } = useFlags();

  const router = useRouter();

  let pathname = '';

  if (router) {
    pathname = router.pathname;
  } else if (window !== 'undefined') {
    pathname = window.location.pathname;
  }

  pathname = pathname.replace('/dashboard', '');

  const hideLeftNav = hiddenNavbarRoutes.some(route => pathname.startsWith(route));

  return (
    <Grid {...props} ref={ref} className={!leftBarNavigation || hideLeftNav ? clsx(classes.center, className) : ''}>
      {children}
    </Grid>
  );
};

const CenteredGrid = forwardRef(CenteredGridWithRef);

export default withStyles(styles)(CenteredGrid);
