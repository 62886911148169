import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCreditSummary } from '../../hooks';
import { moneyStr } from '../../helpers/utils';
import TransactionsContainer from './TransactionsContainer';
import styles from './styles/credits';
import Loader from '../global/Loader';
import { BalanceCreditIcon } from '../icons';
import Tooltip from '../shared/Tooltip';

const useStyles = makeStyles(styles);

const CreditDetail = ({ title, type, icon, color, isEmptyState }) => {
  const classes = useStyles({ color });
  const { data: creditSummary } = useCreditSummary();

  return (
    <Grid item xs={12} className={classes.detailContainer}>
      <Grid container alignItems="center" className={classes.detailCard}>
        <Grid item container justifyContent="center" alignItems="center" className={classes.iconContainer}>
          {icon}
        </Grid>
        <Grid item xs container direction="column" alignItems="flex-start" style={{ paddingLeft: 24 }}>
          <Typography variant="body1" className={classes.detailTitle}>
            {title}
          </Typography>
          <Typography variant="h5" className={isEmptyState ? classes.emptyInfo : undefined} style={{ lineHeight: 1 }}>
            {isEmptyState ? '' : moneyStr(creditSummary[type])}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CreditsContainer = () => {
  const classes = useStyles();
  const { leftBarNavigation } = useFlags();
  const [showCreditsHistory, setShowCreditsHistory] = useState(false);
  const { isLoading } = useCreditSummary();

  return (
    <>
      {isLoading ? (
        <Loader absolute />
      ) : (
        <>
          {!leftBarNavigation ? (
            <>
              <Grid container item justifyContent="space-between" alignItems="center" style={{ height: 56 }}>
                <Grid item xs lg={6} container alignItems="center">
                  <Typography variant="h3" className={classes.title}>
                    Credits Overview
                  </Typography>
                  <Tooltip
                    title={`Find out how your credits are being used, by who, when and how much you have left!${
                      showCreditsHistory ? ' Don’t forget to add credits with the button to the right' : ''
                    }`}
                    placement="right"
                    width={230}
                    color="blue"
                  >
                    <img src="/images/account/outline-info.svg" alt="Confirmed" className={classes.infoIcon} />
                  </Tooltip>
                </Grid>
                {showCreditsHistory && (
                  <Button
                    variant="primary"
                    component={Link}
                    to="/billing/add-credits"
                    className={classes.addCreditsBtn}
                  >
                    ADD CREDITS
                  </Button>
                )}
              </Grid>
            </>
          ) : null}
          <Grid container justifyContent="space-between" item className={classes.creditsContainer}>
            <CreditDetail
              title="Available Credits"
              type="current_balance"
              icon={<BalanceCreditIcon />}
              color="#eff5fc"
              isEmptyState={!showCreditsHistory}
            />
          </Grid>
          {showCreditsHistory && (
            <Grid item xs className={classes.tableTitleContainer}>
              <Typography variant="h6" className={classes.tableTitle}>
                Credits History
              </Typography>
            </Grid>
          )}
        </>
      )}
      <Grid container xs item className={classes.tableContainer}>
        <TransactionsContainer
          showTitle={false}
          showLoader={!isLoading}
          type="credits"
          setShowCreditsHistory={setShowCreditsHistory}
        />
      </Grid>
    </>
  );
};

export default CreditsContainer;
