import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import InventoryCardItem from '../../inventory/InventoryCardItem';

const useStyles = makeStyles(theme => ({
  listContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 276px)',
    justifyContent: 'center',
    gap: 32,
    height: '100%',
    overflowY: 'auto',
    paddingRight: 10,
    paddingBottom: 10,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, 276px)'
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      gap: 24
    }
  }
}));

const InventoryList = ({ inventory, selectedInventory, onToggleSelection, onContainerRefChange }) => {
  const classes = useStyles();

  return (
    <Grid container ref={onContainerRefChange} className={classes.listContainer}>
      {inventory.map(item => (
        <InventoryCardItem
          item={item}
          key={item.id}
          onToggleSelection={onToggleSelection}
          selected={selectedInventory.has(item.id)}
          readOnly
        />
      ))}
    </Grid>
  );
};

export default InventoryList;
