import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { useDispatch, useSelector } from 'react-redux';
// import { FixedSizeList } from 'react-window';
// import AutoSizer from 'react-virtualized-auto-sizer';
import { useFilterAndSortRecipients, useInSendSwagPath } from '../../hooks';
import { CheckBox } from '../TableCommon';
import { useLocalPagination } from '../../../../../../hooks';
import EditRecipientRow from './EditRecipientRow';
import { Pagination } from '../../../../../shared';
// import TopMenu from './TopMenu';
import {
  deleteShipmentGroupRecipients,
  validateDirectoryOrders,
  toogleAllShipmentGroupRecipientsCollapsed
} from '../../../../../../actions/shipmentGroupActions';
// import BottomMenu from '../BottomMenu';
import { BottomActionMenu, ErrorsWarningsSelector, RemoveDialog } from './common';

const customScrollBar = {
  overflowY: 'auto',
  overflowX: 'hidden',
  width: 'auto',
  marginRight: -1,
  '&::-webkit-scrollbar': {
    backgroundColor: '#efeff2',
    width: 1,
    borderRadius: 1
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d3dbe5',
    width: 1,
    borderRadius: 1
  }
};

const styles = {
  selectedRecipientPanel: { padding: '0 0px' },
  selectedRecipientPanelSection: { padding: '0 20px 0px 80px' },
  paginationBtn: {
    width: 32,
    height: 32,
    minWidth: 32,
    padding: 8,
    border: 'none !important',
    boxShadow: 'none',
    '&:hover': { color: 'rgba(0, 0, 0, 0.54)' }
  },
  recipientsContainer: {
    ...customScrollBar,
    maxHeight: 'calc(100vh -  212px)',
    width: '100%',
    padding: '0 20px 82px 80px'
    // position: 'relative'
    // '& > div': {
    //   '& > div': {
    //     overflow: 'visible !important'
    //   }
    // }
  },
  recipientsListContainer: {
    ...customScrollBar
  }
};

const useStyles = makeStyles(styles);

const perPageDefaults = [6, 8, 10, 12];

const SelectedRecipientPanel = ({ search, ordering, filters }) => {
  const [openMultiRemoveDialog, setOpenMultiRemoveDialog] = React.useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
  const [selectedShipmentId, setSelectedShipmentId] = React.useState();
  const [onlyErrors, setOnlyErrors] = React.useState(false);
  const [onlyWarnings, setOnlyWranings] = React.useState(false);
  const [[selected], setSelected] = React.useState([new Set()]);
  const { recipients, total, filteredTotal, totalErrors, totalWarnings } = useFilterAndSortRecipients({
    search,
    filters,
    ordering,
    onlyErrors,
    onlyWarnings
  });
  const pagination = useLocalPagination(filteredTotal, perPageDefaults);
  const offset = pagination.pageIndex * pagination.perPage;

  const paginatedRecipients = recipients.filter((_, idx) => idx >= offset && idx < offset + pagination.perPage);

  const dispatch = useDispatch();

  const isStockLimited = useInSendSwagPath();
  const { setPageIndex } = pagination;
  React.useEffect(() => {
    setPageIndex(0);
  }, [search, filters, setPageIndex]);

  const handleClearSelected = () => {
    selected.clear();
    setSelected([selected]);
  };

  const allSelected = recipients.length > 0 && recipients.every(c => selected.has(c.id));
  const indeterminate = !allSelected && recipients.some(c => selected.has(c.id));
  const handleCheckAll = () => {
    recipients.forEach(r => {
      if (indeterminate || allSelected) selected.delete(r.id);
      else selected.add(r.id);
    });
    setSelected([selected]);
  };

  const allCollapsed = recipients?.length > 0 && recipients.every(d => d.collapsed);
  const collapsedInterminate = !allCollapsed && recipients.some(d => d.collapsed);

  const handleToogleCollapse = () => {
    if (allCollapsed) dispatch(toogleAllShipmentGroupRecipientsCollapsed(false));
    else dispatch(toogleAllShipmentGroupRecipientsCollapsed(true));
  };

  const handleCheck = recipientId => {
    if (selected.has(recipientId)) selected.delete(recipientId);
    else selected.add(recipientId);
    setSelected([selected]);
  };
  const handleDeleteRecipients = ids => {
    dispatch(deleteShipmentGroupRecipients(ids));
    if (isStockLimited) dispatch(validateDirectoryOrders([], isStockLimited, false));

    ids.forEach(id => selected.delete(id));
    setSelected([selected]);
  };

  const handleDeleteARecipient = sId => {
    dispatch(deleteShipmentGroupRecipients([sId]));
    if (isStockLimited) dispatch(validateDirectoryOrders([], isStockLimited, false));

    selected.delete(sId);
    setSelected([selected]);
  };

  const handleRemoveMultShipment = () => setOpenMultiRemoveDialog(true);
  const onRemoveMultShipmentClose = () => setOpenMultiRemoveDialog(false);

  const handleRemoveShipment = sId => {
    setSelectedShipmentId(sId);
    setOpenRemoveDialog(true);
  };
  const onRemoveShipmentClose = () => setOpenRemoveDialog(false);

  const isLimitedStock = useInSendSwagPath();

  const classes = useStyles();

  // const RecipientRow = ({ index, style }) => {
  //   const recipient = paginatedRecipients[index];
  //   if (!recipient) return null;
  //   return (
  //     <div style={{ ...style }}>
  //       <EditRecipientRow
  //         recipientId={recipient.id}
  //         limitedStock={isLimitedStock}
  //         isSelected={selected.has(recipient.id)}
  //         onSelect={() => handleCheck(recipient.id)}
  //         hideInactiveWarnings={isStockLimited}
  //         onRemove={() => handleRemoveShipment(recipient.id)}
  //       />
  //     </div>
  //   );
  // };

  return (
    <Box className={classes.selectedRecipientPanel}>
      <Box className={classes.selectedRecipientPanelSection}>
        <Box style={{ paddingLeft: 18, paddingBottom: 8 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <CheckBox
                    color="primary"
                    checked={allSelected}
                    indeterminate={indeterminate}
                    onClick={handleCheckAll}
                    disabled={total === 0}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body4RegularInter" style={{ lineHeight: '12px', marginTop: 2 }}>
                    Select All
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingLeft: 32 }}>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <CheckBox
                    color="primary"
                    checked={allCollapsed}
                    indeterminate={collapsedInterminate}
                    onClick={handleToogleCollapse}
                    disabled={total === 0}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body4RegularInter" style={{ lineHeight: '12px', marginTop: 2 }}>
                    {allCollapsed ? 'Expand' : 'Collapse'} All
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingLeft: 32 }}>
              <ErrorsWarningsSelector
                errors={onlyErrors}
                warnings={onlyWarnings}
                onToogleError={() => setOnlyErrors(prev => !prev)}
                onToogleWarning={() => setOnlyWranings(prev => !prev)}
                errorOff={() => setOnlyErrors(false)}
                warningsOff={() => setOnlyWranings(false)}
                totalErrors={totalErrors}
                totalWarnings={totalWarnings}
              />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Grid container justifyContent="center">
                <Grid item>
                  <Pagination
                    count={filteredTotal}
                    endText="recipients"
                    startText="Show"
                    perPage={pagination.perPage}
                    onNext={pagination.onNext}
                    pageIndex={pagination.pageIndex}
                    onPrevious={pagination.onPrevious}
                    sizeOptions={pagination.sizeOptions}
                    onPerPageChange={pagination.changeSize}
                    buttonClass={classes.paginationBtn}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Grid container>
        <Box className={classes.recipientsContainer}>
          {paginatedRecipients.map(recipient => (
            <Grid key={recipient.id} item xs={12}>
              <EditRecipientRow
                recipientId={recipient.id}
                limitedStock={isLimitedStock}
                isSelected={selected.has(recipient.id)}
                onSelect={() => handleCheck(recipient.id)}
                hideInactiveWarnings={isStockLimited}
                onRemove={() => handleRemoveShipment(recipient.id)}
              />
            </Grid>
          ))}
          {/* <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                className={classes.recipientsListContainer}
                height={height}
                itemCount={pagination.perPage}
                itemSize={allCollapsed ? 168 : 892}
                width={width}
              >
                {RecipientRow}
              </FixedSizeList>
            )}
          </AutoSizer> */}
        </Box>
      </Grid>
      <Box className={classes.selectedRecipientPanelSection}>
        <Grid container justifyContent="center">
          <Grid item xs={7}>
            <BottomActionMenu
              selectedCount={selected.size}
              onRemove={handleRemoveMultShipment}
              onClose={handleClearSelected}
              selectedIdsSet={selected}
              showCalendar={isLimitedStock}
            />
          </Grid>
        </Grid>
      </Box>
      <RemoveDialog
        open={openRemoveDialog}
        title="Are you sure want to remove this shipment?"
        actioButtonText="Remove shipment"
        cancelButtonText="Keep shipment"
        onRemove={() => {
          onRemoveShipmentClose();
          handleDeleteARecipient(selectedShipmentId);
        }}
        onClose={onRemoveShipmentClose}
      />
      <RemoveDialog
        open={openMultiRemoveDialog}
        title="Are you sure want to remove these shipments?"
        actioButtonText="Remove shipments"
        cancelButtonText="Keep shipments"
        onRemove={() => {
          onRemoveMultShipmentClose();
          handleDeleteRecipients([...selected.values()]);
        }}
        onClose={onRemoveMultShipmentClose}
      />
    </Box>
  );
};

export default SelectedRecipientPanel;
