import React, { useState } from 'react';
import { Box, Typography, Divider, Grid, makeStyles } from '@material-ui/core';
import round from 'lodash/round';
import { Button } from '@swagup-com/components';
import Loader from '../../../global/Loader';
import shipmentsApi from '../../../../apis/swagup/shipments';
import Payment from './Payment';
import { InfoContainer, ErrorBanner } from './common';
import { joinFields, moneyStr } from '../../../../helpers/utils';
import { useCreditActions, useCreditSummary } from '../../../../hooks';
import styles from './styles/invalidAddressPayment';
import { minimumAcquirableCredits } from '../../../../helpers/helperConstants';

const getAddressText = address =>
  joinFields(
    [
      address.shipping_address1,
      address.shipping_city,
      address?.shipping_state,
      address.shipping_country,
      address?.shipping_zip
    ],
    ' '
  );

const useStyles = makeStyles(styles);

const InvalidAddressPayment = ({
  newAddress,
  oldAddress,
  oldShippingPrice,
  newShippingPrice,
  returnToAddress,
  shipmentId,
  fetchShipments,
  onClose
}) => {
  const [isValidPayment, setIsValidPayment] = useState(true);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [error, setError] = useState(null);

  const amountToRefund = oldShippingPrice - newShippingPrice;
  const haveToPay = amountToRefund < 0;

  const { data: creditSummary, isFetching: fetchingCredits } = useCreditSummary({ enabled: haveToPay });
  const { current_balance: credit } = creditSummary;
  const payWithCC = Math.max(0, round(Math.abs(amountToRefund) - credit, 2) || 0);

  const newAddressText = getAddressText(newAddress);
  const oldAddressText = getAddressText(oldAddress);

  const classes = useStyles();

  const handleError = message => {
    setError(message);
    setUpdateInProgress(false);
  };

  const updateAddress = async () => {
    try {
      const response = await shipmentsApi.updateAddress(shipmentId, newAddress);
      if (response.status === 200) {
        setUpdateInProgress(false);
        onClose();
        fetchShipments();
      } else handleError('Error updating contact.');
    } catch (e) {
      handleError('Error updating shipment.');
    }
  };

  const { addCredits } = useCreditActions();

  const handleChangeAddress = () => {
    setUpdateInProgress(true);

    if (payWithCC === 0) {
      updateAddress();
    } else {
      const creditsInfo = { amount: Math.max(minimumAcquirableCredits, payWithCC) };
      addCredits.mutate(creditsInfo, {
        onSuccess: updateAddress,
        onError: () => handleError('Error in payment.')
      });
    }
  };

  return (
    <>
      {error && (
        <Box p="0 11px">
          <ErrorBanner message={error} />
        </Box>
      )}
      {(fetchingCredits || updateInProgress) && <Loader absolute />}
      <Grid container direction="column" alignItems="center">
        <Box width="100%" padding="0 30px">
          <InfoContainer>
            <Grid container direction="row" wrap="nowrap" className={classes.infoSection}>
              <Grid container direction="column">
                <Typography className={classes.infoHeader}>Previous Address</Typography>
                <Typography title={oldAddressText} className={classes.infoText}>
                  {oldAddressText}
                </Typography>
              </Grid>
              <Grid container direction="column" alignItems="flex-end">
                <Typography className={classes.infoHeader}>Previous cost</Typography>
                <Typography className={classes.infoText}>{moneyStr(oldShippingPrice)}</Typography>
              </Grid>
            </Grid>
            <Divider style={{ margin: '10px 0' }} />
            <Grid container direction="row" wrap="nowrap" className={classes.infoSection}>
              <Grid container direction="column">
                <Typography className={classes.infoHeader}>New Address</Typography>
                <Typography title={newAddressText} className={classes.infoText}>
                  {newAddressText}
                </Typography>
              </Grid>
              <Grid container direction="column" alignItems="flex-end">
                <Typography className={classes.infoHeader}>New cost</Typography>
                <Typography className={classes.infoText}>{moneyStr(newShippingPrice)}</Typography>
              </Grid>
            </Grid>
            <Divider style={{ margin: '10px 0' }} />
            <Grid container direction="row" wrap="nowrap" className={classes.infoSection}>
              <Grid container direction="column">
                <Typography className={classes.infoText}>
                  {haveToPay ? 'Total payable' : 'Total refund (Credit balance)'}
                </Typography>
              </Grid>
              <Grid container alignItems="flex-end" direction="column">
                <Typography className={classes.infoText}>{moneyStr(Math.abs(amountToRefund))}</Typography>
              </Grid>
            </Grid>
          </InfoContainer>
          {haveToPay && !fetchingCredits && credit > 0 && (
            <Payment
              credit={credit}
              totalToPay={Math.abs(amountToRefund)}
              textToRefundCredit="Use available credits to pay for shipping"
              onValidityChange={info => setIsValidPayment(info)}
            />
          )}
          <Grid container item justifyContent="flex-end" className={classes.buttonsContainer}>
            <Grid container justifyContent="flex-end" item xs={4}>
              <Button variant="text" onClick={returnToAddress} className={classes.cancelButton}>
                Discard Changes
              </Button>
            </Grid>
            <Grid container justifyContent="flex-end" item xs={4}>
              <Button
                variant="primary"
                onClick={handleChangeAddress}
                disabled={payWithCC > 0 && !isValidPayment}
                className={classes.updateButton}
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default InvalidAddressPayment;
