import React from 'react';
import BackgroundCircleCompany from '../../../onboarding/BackgroundCircleCompany';

const BackgroundAdornments = ({ isMd }) => {
  return (
    <>
      <BackgroundCircleCompany
        size="22%"
        color="#9846dd"
        top={isMd ? '-10%' : '-28%'}
        left={isMd ? '50%' : '3%'}
        outlined
      />
      <BackgroundCircleCompany size="4%" color="#9846dd" bottom="18%" left="16%" />
      <BackgroundCircleCompany size="6%" color="#9846dd" bottom="6%" left="2%" invisible />
    </>
  );
};

export default BackgroundAdornments;
