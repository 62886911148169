import { scrollBarAutohide, ellipsisStyles } from '../../../../../../shared/styles/commonStyles';

export default {
  productQuantity: {
    borderRadius: '10px !important',
    border: '1px solid #F0F2F5',
    padding: '12px 12px',
    width: '100%',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.05)',
    marginBottom: 20
  },
  productSummary: {
    width: '100%'
  },
  productSummaryContent: {},
  expandIcon: {
    marginTop: -22,
    marginRight: 0,
    padding: 0,
    color: '#4A505C'
  },
  arrowIcon: { width: 20, height: 20, color: '#787b80', marginTop: 1 },
  buttonOpenItem: {
    borderRadius: 4,
    height: '24px !important',
    width: '24px !important',
    border: '1px solid #D6D8DB !important',
    zIndex: 999
  },
  productDescription: {
    position: 'relative',
    paddingLeft: 10,
    maxWidth: '164px'
  },
  greyTextName: {
    ...ellipsisStyles,
    fontFamily: 'Inter',
    maxWidth: '100%',
    lineHeight: '20px',
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 8,
    color: '#989EA4'
  },
  greyText: {
    ...ellipsisStyles,
    fontFamily: 'Inter',
    maxWidth: '100%',
    fontSize: 14,
    fontWeight: 400,
    color: '#989EA4'
  },
  boldText: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 500,
    color: '#0b1829'
  },
  boldTextUnits: {
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 600,
    color: '#0b1829'
  },
  boldTextLight: {
    fontFamily: 'Inter',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
    color: '#989EA4'
  },
  sizeQtyText: {
    marginLeft: 4,
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 400,
    color: '#989EA4'
  },
  sizeText: {
    marginLeft: 10,
    minWidth: 32,
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 400,
    color: '#989EA4'
  },
  sizeTextLight: {
    fontFamily: 'Inter',
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 400,
    color: '#787E84'
  },
  productName: {
    ...ellipsisStyles,
    width: '100%',
    fontSize: 14
  },
  productSummaryDetails: {
    padding: '0 20px'
  },
  divider: {
    width: '100%',
    backgroundColor: '#CED1D6',
    height: 1
  },
  title: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
    color: '#0B1829'
  },
  header: { padding: '0px 16px 24px 16px' },
  productsCards: { padding: '0px 16px' },
  productQuantities: {
    ...scrollBarAutohide,
    width: 'calc(100% - 8px)',
    height: '100%'
  },
  total: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '30px',
    color: '#0B1829'
  }
};
