import React, { useState } from 'react';
import DropZone from 'react-dropzone';
import { Grid, LinearProgress, withStyles } from '@material-ui/core';
import { generateUniqueId, s3 } from '../../helpers/utils';
import styles from './styles/ImageUpload';
import progressBarstyles from './styles/ProgressBar';

const ProgressBar = withStyles(progressBarstyles)(({ progress, classes }) => (
  <LinearProgress
    variant="determinate"
    value={progress}
    classes={{
      colorPrimary: classes.linearColorPrimarySlim,
      barColorPrimary: classes.linearBarColorPrimary
    }}
  />
));

const uploadStages = { stopped: 0, inProgress: 1, done: 2 };

const CustomImageUpload = ({
  classes,
  onChange,
  onProgress,
  accept = 'image/*',
  minSize = 10,
  maxSize = 10 * 1024 * 1024,
  reject = '',
  imageKey = 'image-variant',
  className,
  disabled,
  children
}) => {
  const [progress, setProgress] = useState(0);
  const [stage, setStage] = useState(uploadStages.stopped);

  const uploadOk = data => {
    setStage(uploadStages.done);
    setProgress(0);
    onChange(data.Location);
  };

  const uploadError = () => {
    setProgress(0);
    setStage(uploadStages.stopped);
  };

  const uploadDone = (err, data) => {
    if (onProgress) onProgress(false);

    if (err) uploadError(err);
    else uploadOk(data);
  };

  const uploadInProgress = ({ loaded, total }) => {
    if (total > 0) setProgress((loaded * 100) / total);
  };

  const onDrop = files => {
    const [droppedFile] = files;
    const canUpload = droppedFile && reject.indexOf(droppedFile.type) === -1;

    if (canUpload) {
      setStage(uploadStages.inProgress);

      if (onProgress) onProgress(true);

      const uniqueId = generateUniqueId();

      s3.upload({
        Key: `${uniqueId}-${imageKey}-${droppedFile.name.replace(/\s+/g, '-')}`,
        Body: droppedFile,
        ContentType: droppedFile.type
      })
        .on('httpUploadProgress', uploadInProgress)
        .send(uploadDone);
    }
  };

  return (
    <div
      className={className || classes.container}
      style={{ align: 'center', cursor: disabled ? 'not-allowed' : 'pointer', position: 'relative' }}
    >
      <DropZone
        multiple={false}
        onDrop={onDrop}
        disabled={disabled}
        accept={accept}
        minSize={minSize}
        maxSize={maxSize}
      >
        {({ getRootProps, getInputProps }) => (
          <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            <Grid item xs={12}>
              <div {...getRootProps({ className: classes.offOutline })}>
                <input {...getInputProps()} />
                {children}
                <div style={{ height: 8, paddingTop: 4 }}>
                  {uploadStages.inProgress === stage && (
                    <div className={classes.slimProgressContainer}>
                      <ProgressBar progress={progress} />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </DropZone>
    </div>
  );
};

export default withStyles(styles)(CustomImageUpload);
