import commonStyles from './commonStyles';
import { ellipsisStyles } from '../../shared/styles/commonStyles';

const { title, line } = commonStyles;

const cardContainer = {
  height: 90,
  borderRadius: 15,
  boxShadow: '0 15px 50px 0 rgba(27, 28, 31, 0.03)',
  backgroundColor: '#ffffff',
  padding: 20,
  marginBottom: 20,
  alignContent: 'center',
  alignItems: 'center'
};
const commonPaymentButton = {
  maxWidth: 193,
  padding: '12px 20px',
  borderRadius: 10,
  backgroundColor: '#ffffff',
  textTransform: 'none',
  boxShadow: 'none',
  fontFamily: 'Gilroy-SemiBold',
  fontSize: 14,
  fontWeight: 500,
  marginRight: 20
};
const commonPaymentButtonSm = {
  maxWidth: 160,
  padding: '8px 12px 8px 15px',
  fontSize: 13,
  marginTop: 10
};

const styles = theme => ({
  title,
  line,
  sectionTitle: {
    marginBottom: 16,
    marginTop: 20,
    fontSize: 16,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: { marginBottom: 0 }
  },
  paymentMethodButton: {
    ...commonPaymentButton,
    border: 'solid 1px #ebeef2',
    color: '#434c5f',
    [theme.breakpoints.down('sm')]: { ...commonPaymentButtonSm }
  },
  paymentMethodButtonSelected: {
    ...commonPaymentButton,
    border: 'solid 1px #3577d4',
    color: '#3577d4',
    [theme.breakpoints.down('sm')]: { ...commonPaymentButtonSm }
  },
  formControlLabel: {
    margin: 0,
    padding: 0
  },
  containerGrey: {
    ...cardContainer,
    border: 'solid 2px #ebeef2',
    [theme.breakpoints.down('xs')]: { padding: 16 }
  },
  containerBlue: {
    ...cardContainer,
    border: 'solid 2px #3577d4',
    [theme.breakpoints.down('xs')]: { padding: 16 }
  },
  infoAddress: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 1,
    color: '#868a8f',
    marginTop: 8
  },
  paymentName: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    lineHeight: 1,
    color: '#0b1829',
    maxWidth: 350,
    [theme.breakpoints.down('sm')]: { maxWidth: 170 }
  },
  accountNumber: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    lineHeight: 0.88,
    color: '#434c5f',
    marginBottom: 10
  },
  deleteButton: {
    width: 36,
    height: 36,
    borderRadius: 10,
    backgroundColor: '#fff0ef',
    color: '#f44336',
    marginRight: 10,
    '&:hover': { backgroundColor: '#fff0ef' }
  },
  accountAddressTitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 0.83,
    color: '#0f2440'
  },
  accountAddress: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    lineHeight: 1.5,
    color: '#8d9299',
    [theme.breakpoints.down('xs')]: { fontSize: 14 }
  },
  AccountType: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 0.75,
    color: '#8d9299'
  },
  AddAccountContainer: {
    textAlign: 'center',
    padding: 60,
    [theme.breakpoints.down('xs')]: { padding: 20 }
  },
  noAccountsContainer: {
    textAlign: 'center',
    paddingTop: 66
  },
  noAccountsTitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 24,
    color: '#434d5c',
    marginTop: 35,
    marginBottom: 12
  },
  noAccountCTA: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 1.67,
    color: '#8f8f8f'
  },
  labelSwitch: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    lineHeight: 1.25,
    color: '#0f2440',
    marginRight: 8,
    textAlign: 'end',
    [theme.breakpoints.down('xs')]: { fontSize: 14 }
  },
  labelPaymentSwitch: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: 0.75,
    color: '#8d9299',
    marginRight: 8
  },
  labelPaymentSwitchDisabled: {
    color: '#3577d4 !important'
  },
  addIcon: {
    fontSize: 30,
    marginLeft: 27,
    marginRight: -20
  },
  input: {
    padding: 0,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    color: '#0b1829',
    width: 350
  },
  editButton: {
    padding: 0,
    margin: '-2px 0px 0px 5px'
  },
  infoSection: {
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: { paddingLeft: 40 },
    [theme.breakpoints.down('xs')]: { paddingLeft: 45 }
  },
  deleteButtonContainer: {
    [theme.breakpoints.down('sm')]: { marginTop: -7, marginBottom: 5 }
  },
  addAccountContainer: {
    paddingTop: 60,
    paddingBottom: 23,
    [theme.breakpoints.down('xs')]: { padding: 20 }
  },
  allowChargesText: {
    paddingRight: 5,
    marginBottom: 15,
    marginTop: -4
  },
  deleteIcon: {
    fontSize: 20,
    marginTop: -3
  },
  addAccountBtn: {
    display: 'inline-flex'
  },
  paddingES: {
    paddingTop: 0
  },
  cardImageContainer: {
    width: 48,
    height: 32
  },
  cardImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
});
export default styles;
