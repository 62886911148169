import dayjs from 'dayjs';
import { SET_INTEGRATIONS_TRIAL_MODAL_CLOSE } from '../actions/types';

const initialState = { isOpen: true, lastOpenedDate: dayjs().format('MM/DD/YYYY') };

const integrationFreeTrialExpiredModalStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTEGRATIONS_TRIAL_MODAL_CLOSE: {
      const nextState = { isOpen: false, lastOpenedDate: dayjs().format('MM/DD/YYYY') };

      return nextState;
    }
    default:
      return state;
  }
};

export default integrationFreeTrialExpiredModalStatusReducer;
