import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import CustomStepper from './CustomStepper';

const styles = () => ({
  header: {
    paddingBottom: 24
  },
  content: {},
  wizardStepper: {},
  swipeableViews: {
    height: '100%',
    width: '100%',
    // minHeight: 'calc(100vh - 202px)',
    '& .react-swipeable-view-container': {
      height: '100%'
    },
    '& > div > div': {
      overflowX: 'hidden !important',
      overflowY: 'hidden !important'
    }
  }
});

const useStyles = makeStyles(styles);

const WizardStepper = ({ currentStep, steps, exactProgress, basePath, children, paddingRight }) => {
  const classes = useStyles();
  return (
    <Box className={classes.wizardStepper}>
      <Box className={classes.header} style={{ paddingRight }}>
        <CustomStepper currentStep={currentStep} steps={steps} exactProgress={exactProgress} basePath={basePath} />
      </Box>
      <Box className={classes.content}>
        <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
          {children}
        </SwipeableViews>
      </Box>
    </Box>
  );
};

export default WizardStepper;
