import React from 'react';
import { withStyles } from '@material-ui/core';
import BackgroundCircleCompany from './BackgroundCircleCompany';
import styles from './styles/backgroundLight';

const BackgroundAdornments = () => (
  <>
    <BackgroundCircleCompany size="26%" color="#9846dd" top="-24%" left="4%" outlined />
    <BackgroundCircleCompany size="8%" color="#9846dd" top="-8%" right="30%" />
    <BackgroundCircleCompany size="4%" color="#9846dd" bottom="12%" left="16%" />
    <BackgroundCircleCompany size="12%" color="#003399" top="20%" right="0%" invisible />
    <BackgroundCircleCompany size="6%" color="#9846dd" bottom="14%" right="10%" invisible />
    <BackgroundCircleCompany size="24%" color="#9846dd" bottom="-18%" right="-8%" outlined />
  </>
);

export default withStyles(styles)(BackgroundAdornments);
