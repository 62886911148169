import {
  ONBOARDING_NEXT_STEP,
  ONBOARDING_PREVIOUS_STEP,
  ONBOARDING_UPDATE_INFO,
  ONBOARDING_RESET_STEPS,
  ONBOARDING_ONE_PAGE,
  ONBOARDING_TWO_PAGE
} from '../actions/types';

const paths = [
  'welcome',
  'name',
  'email',
  'company',
  'position',
  'location',
  'address',
  'credit-card',
  'phone',
  'size',
  'goal',
  'quantity',
  'how-do-you-learn-about-us',
  'thank-you'
];

const isUS = country => ['US', 'USA'].includes(country);

const onboardingInfoReducer = (
  state = {
    currentStep: 1,
    currentInfo: {},
    paths,
    twoPages: false
  },
  action
) => {
  switch (action.type) {
    case ONBOARDING_NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep === state.paths.length ? state.paths.length : state.currentStep + 1
      };

    case ONBOARDING_PREVIOUS_STEP:
      return { ...state, currentStep: state.currentStep === 1 ? 1 : state.currentStep - 1 };

    case ONBOARDING_UPDATE_INFO:
      if (!state.paths.includes('credit-card') && !isUS(state.currentInfo.shipping_country)) {
        state.paths.splice(7, 0, 'credit-card');
        return {
          ...state,
          currentInfo: { ...state.currentInfo, ...action.payload },
          paths: state.paths
        };
      }
      if (state.paths.includes('credit-card') && isUS(state.currentInfo.shipping_country)) {
        return {
          ...state,
          currentInfo: { ...state.currentInfo, ...action.payload },
          paths: state.paths.filter(path => path !== 'credit-card')
        };
      }

      return { ...state, currentInfo: { ...state.currentInfo, ...action.payload } };

    case ONBOARDING_RESET_STEPS:
      return {
        ...state,
        currentStep: 1,
        currentInfo: {},
        paths,
        twoPages: false
      };

    case ONBOARDING_ONE_PAGE:
      return { ...state, twoPages: false };

    case ONBOARDING_TWO_PAGE:
      return { ...state, twoPages: true };

    default:
      return state;
  }
};

export default onboardingInfoReducer;
