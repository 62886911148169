const optionText = {
  fontFamily: 'Gilroy',
  color: '#787b80',
  fontSize: 14,
  fontWeight: 500
};

export default {
  drawerPaper: {
    maxWidth: 448,
    padding: '36px 40px 0px 32px'
  },
  ctaWrapper: {
    marginBottom: 24,
    width: '100%',
    display: 'flex'
  },
  optionTitle: optionText,
  cta: {
    ...optionText,
    color: '#125CFF',
    height: 'fit-content',
    minWidth: 'auto',
    padding: 0,
    marginBottom: '8px'
  },

  whiteColor: { border: '1px solid #787B80' },
  decorationName: {
    fontFamily: 'Gilroy',
    color: '#0b1829',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'left'
  },
  buttonsContainer: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: '8px'
  },
  submitButtonStyles: {
    background: '#125CFF',
    minWidth: '168px',
    height: '40px',
    borderRadius: '32px',
    fontSize: 14,
    fontWeight: 'normal'
  },
  cancelButtonStyles: {
    fontSize: 14,
    fontWeight: 'normal',
    minWidth: '168px',
    height: '40px'
  },
  appBarStyles: {
    boxShadow: 'none'
  },
  tabs: {
    '&.MuiTabs-root': { maxWidth: 353, overflowX: 'auto !important' },
    '& .MuiTab-root': {
      minWidth: 140,
      padding: '6px 19px'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#125CFF'
    },
    display: 'block'
  },
  tab: {
    maxHeight: 34,
    minHeight: 34,
    maxWidth: 140,
    minWidth: 140,
    marginBottom: '8px'
  },
  activeTab: {
    maxHeight: 34,
    minHeight: 34,
    maxWidth: 140,
    minWidth: 140,
    backgroundColor: '#000000',
    color: '#ffffff',
    borderRadius: '4px',
    marginBottom: '8px'
  },
  disableDecorationsTab: {
    maxHeight: 34,
    minHeight: 34,
    maxWidth: 220,
    minWidth: 220
  },
  tabContainerStyles: {
    '& .MuiBox-root': {
      padding: '12px 0px'
    },
    padding: 0,
    borderTop: '1px solid #E5E7E8',
    marginTop: -7
  },
  deleteTabBtn: {
    '& .MuiButton-root': {
      color: '#131415'
    },
    minWidth: 24,
    padding: 0,
    color: '#131415'
  },
  proofSectionLabel: {
    fontSize: 14,
    color: '#4A4F54',
    lineHeight: 'normal',
    margin: '4px 0px 4px'
  }
};
