import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { isNumber } from 'lodash';
import { Check, CheckBoxRounded, CheckCircle } from '@material-ui/icons';
import clsx from 'clsx';
import { useQuery, useQueryClient } from 'react-query';
import SwipeableViews from 'react-swipeable-views';
import styles from './styles/existingCustomersModal';
import { Modal } from '../../shared/Modal';
import { CustomSlimTextField } from '../storeCommon';
import { moneyStr } from '../../../helpers/utils';
import { StylessButton } from '../../buttons';
import useMembershipCheck from '../../../hooks/useMembershipCheck';
import { CustomTooltip } from '../../products/commonProductsElements';
import storefrontsServicesPaths from '../../../helpers/storefrontsServicesPaths';
import { storeCustomers } from '../../../apis/storefrontsServices';
// import { BlueSwitch } from '../../account/AccountSharedComponents';

const useModalStyles = makeStyles(styles);

const Header = ({ classes }) => (
  <TableHead>
    <TableRow>
      <TableCell className={clsx(classes.headerCell, classes.firstCell)}>First Name</TableCell>
      <TableCell className={classes.headerCell}>Last Name</TableCell>
      <TableCell className={classes.headerCell}>Email</TableCell>
      <TableCell className={classes.headerCell}>Credits</TableCell>
    </TableRow>
  </TableHead>
);

const Body = ({ classes, contacts = [] }) => (
  <TableBody className={classes.tableBody}>
    {contacts.map(row => (
      <TableRow key={row.firstName}>
        <TableCell className={clsx(classes.bodyCell, classes.firstCell)}>{row.first_name}</TableCell>
        <TableCell className={classes.bodyCell}>{row.last_name}</TableCell>
        <TableCell className={classes.bodyCell}>{row.email}</TableCell>
        <TableCell className={classes.bodyCell}>
          <span style={{ color: parseFloat(row.amount) > 0 ? '#4CAF50' : '#C62828' }}>${row.amount}</span>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

const ExistingCustomersModal = ({
  open,
  onClose,
  contactsToAdd = [],
  storeId,
  onAdd,
  uploadStatus,
  csvImportError
}) => {
  const [customersToShow, setCustomersToShow] = useState([]);
  const [isCleanAdding, setIsCleanAdding] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [showErrors, setShowErrors] = useState(false);

  const contactsEmails = contactsToAdd.map(c => c.email);

  const membershipCheck = useMembershipCheck();

  const queryClient = useQueryClient();

  const { data: existingCustomersAPI, isLoading } = useQuery(
    [storefrontsServicesPaths.existingCustomer(storeId)],
    () => storeCustomers.existingCustomer(storeId, contactsEmails),
    {
      enabled: !!storeId && contactsEmails.length > 0
    }
  );

  useEffect(() => {
    if (existingCustomersAPI?.existingCustomers?.length > 0) {
      const existingContacts = contactsToAdd.filter(c =>
        existingCustomersAPI?.existingCustomers.find(ec => ec === c.email)
      );
      setCustomersToShow(existingContacts);
      setIsCleanAdding(false);
    } else {
      setCustomersToShow(contactsToAdd);
    }
  }, [existingCustomersAPI, contactsToAdd]);

  const handleOnAddNewCustomers = () => {
    onAdd({
      customers: customersToShow
    });
    // onClose();
  };

  useEffect(() => {
    if (uploadStatus === 'successful' || uploadStatus === 'error') setCurrentStep(2);
    else setCurrentStep(1);
  }, [uploadStatus]);

  const handleOnClose = param => {
    onClose(param);
    queryClient.invalidateQueries(storefrontsServicesPaths.existingCustomer(storeId));
  };

  const classes = useModalStyles();
  return (
    <Modal
      // title="The customers below already exist"
      open={open}
      onClose={handleOnClose}
      className={classes.modal}
      titleClass={classes.title}
      subtitleClass={classes.subtitle}
      closeButtonClass={classes.closeButton}
      actions={
        currentStep === 1 ? (
          <Grid container justifyContent="center" style={{ paddingBottom: 12 }}>
            <Grid item>
              <Grid container justifyContent="center" style={{ paddingBottom: 12, width: 300 }}>
                <Grid item>
                  <Button size="small" variant="text" onClick={handleOnClose} className={classes.cancelButton}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs />
                <Grid item>
                  <Button size="small" variant="primary" onClick={handleOnAddNewCustomers} style={{ width: 132 }}>
                    Yes, upload
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center" style={{ paddingBottom: 12 }}>
            <Grid item>
              <Grid container justifyContent="center" style={{ paddingBottom: 12, width: 300 }}>
                <Grid item>
                  <Button
                    size="small"
                    variant="primary"
                    onClick={() => handleOnClose({ retry: uploadStatus !== 'successful' })}
                    style={{ width: 132 }}
                  >
                    {uploadStatus === 'successful' ? 'Great!' : 'Try again'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    >
      <Box>
        <SwipeableViews
          axis="x"
          index={currentStep - 1}
          className={classes.swipeableViews}
          onChange={(event, newValue) => (newValue ? setCurrentStep(newValue) : false)}
          disabled
        >
          <Grid container justifyContent="center" className={classes.modalContent}>
            <Grid item>
              <Typography variant="h4SemiBoldInter" className={classes.textTitle}>
                {isCleanAdding ? 'The customers below are new to your shop' : 'The customers below already exist'}
              </Typography>
              <Typography variant="body3RegularInter" className={classes.text}>
                The following funds will be added to their balance.
                <br />
                Do you want to continue with uploading this CSV?
              </Typography>
              {!membershipCheck.shopCreditsAllowed && (
                <Typography variant="body4RegularInter" className={classes.text}>
                  You must upgrade to Platinum in order to add credit funds.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.summaryTable} stickyHeader>
                  <Header classes={classes} />
                  <Body classes={classes} contacts={customersToShow} />
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" className={classes.modalContent}>
            <Grid item xs={8}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Box className={classes.successfulWrapper}>
                    <img
                      src={`/images/storefront/${
                        uploadStatus === 'successful' ? 'csv-successful.png' : 'csv-failed.png'
                      }`}
                      alt="successful"
                      className={classes.successfulImage}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h4SemiBoldInter" className={classes.textTitle} style={{ fontSize: 24 }}>
                {uploadStatus === 'successful' ? 'CSV upload successful' : <span>Your CSV failed to upload</span>}
              </Typography>
              <Typography variant="body3RegularInter" className={classes.text} style={{ marginBottom: 0 }}>
                {uploadStatus === 'successful' ? (
                  'Your customers are now up to date in your shop.'
                ) : (
                  <>
                    <span>Please confirm you're using the template provided</span>
                    <br />
                    <span>and each row is filled in properly.</span>
                  </>
                )}
              </Typography>
              {uploadStatus === 'error' && (
                <Grid container justifyContent="center" style={{ marginTop: 12 }}>
                  <Grid item>
                    <StylessButton onClick={() => setShowErrors(prev => !prev)}>
                      {showErrors ? 'Hide errors' : 'Show errors'}
                    </StylessButton>
                  </Grid>
                </Grid>
              )}
              {uploadStatus === 'error' && showErrors && (
                <div
                  className={classes.errorList}
                  dangerouslySetInnerHTML={{ __html: csvImportError?.replaceAll('\n', '<br />') }}
                />
              )}
            </Grid>
          </Grid>
        </SwipeableViews>
      </Box>
    </Modal>
  );
};

export default ExistingCustomersModal;
