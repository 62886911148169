const integration = {
  id: 23,
  name: 'Justwork',
  automations: [
    {
      id: 1,
      name: 'On New Hires',
      type: 'new_hires',
      action: {
        id: 1,
        name: 'Send Swag',
        type: 'send_swag',
        settings: {
          id: 1,
          deliveryMethods: {
            domestic: 'Express',
            international: 'Standard'
          },
          product: {
            id: 1,
            account_product: 11307,
            image:
              'https://swagup-static.swagup.com/platform-staging2022/media/accounts/account_10089/account-products/p11307-1705495669771.png',
            name: 'Premium Heavyweight Hoodie',
            color: 'Black',
            sizeName: 'XL',
            size: 2,
            quantity: 1,
            stock: [
              {
                size: {
                  id: 10,
                  name: 'XXS',
                  category: 'Apparel',
                  modified_on: '2022-09-25T20:40:06.585674Z',
                  sort_order: 1
                },
                quantity: 42,
                active: true,
                minimum: 4,
                modified_on: '2024-03-27T08:26:53.963794Z'
              },
              {
                size: {
                  id: 1,
                  name: 'XS',
                  category: 'Apparel',
                  modified_on: '2022-09-26T11:30:30.631729Z',
                  sort_order: 2
                },
                quantity: 50,
                active: true,
                minimum: 5,
                modified_on: '2024-01-17T19:17:15.860671Z'
              },
              {
                size: {
                  id: 2,
                  name: 'S',
                  category: 'Apparel',
                  modified_on: '2022-09-25T20:40:06.585152Z',
                  sort_order: 3
                },
                quantity: 49,
                active: true,
                minimum: 4,
                modified_on: '2024-01-17T19:17:41.436356Z'
              },
              {
                size: {
                  id: 3,
                  name: 'M',
                  category: 'Apparel',
                  modified_on: '2022-09-25T20:40:06.585219Z',
                  sort_order: 4
                },
                quantity: 44,
                active: true,
                minimum: 4,
                modified_on: '2024-03-27T08:26:54.258362Z'
              },
              {
                size: {
                  id: 4,
                  name: 'L',
                  category: 'Apparel',
                  modified_on: '2022-09-25T20:40:06.585285Z',
                  sort_order: 5
                },
                quantity: 50,
                active: true,
                minimum: 5,
                modified_on: '2024-01-17T19:18:27.840462Z'
              },
              {
                size: {
                  id: 5,
                  name: 'XL',
                  category: 'Apparel',
                  modified_on: '2022-09-25T20:40:06.585349Z',
                  sort_order: 6
                },
                quantity: 46,
                active: true,
                minimum: 4,
                modified_on: '2024-03-27T07:03:29.335041Z'
              },
              {
                size: {
                  id: 6,
                  name: '2XL',
                  category: 'Apparel',
                  modified_on: '2022-09-25T20:40:06.585412Z',
                  sort_order: 7
                },
                quantity: 100,
                active: true,
                minimum: 10,
                modified_on: '2024-01-17T19:19:36.462285Z'
              },
              {
                size: {
                  id: 7,
                  name: '3XL',
                  category: 'Apparel',
                  modified_on: '2022-09-25T20:40:06.585476Z',
                  sort_order: 8
                },
                quantity: 50,
                active: true,
                minimum: 5,
                modified_on: '2024-01-17T19:20:06.650349Z'
              }
            ]
          }
        }
      }
    },
    {
      id: 2,
      name: 'On Employee Birthdays',
      type: 'birthdays',
      action: {
        id: 1,
        name: 'Send Redeem Link',
        type: 'redeem_link',
        settings: {
          id: 1,
          redeem_page: {
            id: 263,
            name: 'TIAA Demo Redeem',
            image: 'https://swagup-resource-uploader.s3.amazonaws.com/1708792495923-F6EYRmBWAAEe2TG.jpeg',
            type: 'Standard URL',
            redeem_page_id: 263,
            showCustomMessage: true,
            customMessage: 'Hello there',
            customSubject: 'Hello'
          }
        }
      }
    },
    {
      id: 3,
      name: 'On Employee Anniversaries',
      type: 'anniversaries',
      years: [
        {
          id: 1,
          value: 2,
          name: 'Year 2',
          action: {
            id: 1,
            name: 'Send Shop Credits',
            type: 'shop_credits',
            settings: {
              id: 1,
              store: {
                store_id: 58,
                name: 'Featured Store',
                type: 'Preorder',
                image:
                  'https://swagup-resource-uploader.s3.amazonaws.com/1706896267280-1706676533551-Screen-Shot-2024-01-30-at-11.48.49-PM.png',
                giftAmount: 20,
                showCustomMessage: true,
                customMessage: 'Hello there Bro',
                customSubject: 'Hello bro'
              }
            }
          }
        }
      ],
      action: {}
    }
  ]
};

export default integration;
