import React from 'react';
import { withStyles, Grid, Button, FormControl, ThemeProvider, createTheme } from '@material-ui/core';
import clsx from 'clsx';
import Subscribe from '../../../global/Subscribe';
import { CenteredGrid } from '../../../shared';
import styles from './SignUpNewsLetter.styles';
import { TextField } from '../../../global/reactHookFormFields';

const customTheme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid rgba(255, 255, 255, 1)'
        },
        '&:after': { borderBottom: '2px solid white' },
        '&.Mui-error:after': { borderBottomColor: 'white' }
      }
    },
    MuiFormHelperText: {
      root: { '&.Mui-error': { color: 'white' } }
    }
  }
});

const SignUpNewsLetter = withStyles(styles)(({ register, errors, classes }) => (
  <CenteredGrid container justifyContent="center" className={classes.container}>
    <Grid item xs={12}>
      <span className={classes.miniTitle}>Newsletter</span>
    </Grid>
    <Grid item xs={12}>
      <h2 className={classes.title}>Sign up for the newsletter!</h2>
    </Grid>
    <Grid item xs={12}>
      <p className={classes.subText}>Be the first to learn about the latest swag and culture building tips</p>
    </Grid>
    <Grid container className={classes.emailForm}>
      <Grid item md={7} xs={12} container justifyContent="flex-end" className={classes.center}>
        <FormControl className={classes.inputContainer}>
          <ThemeProvider theme={customTheme}>
            <TextField
              placeholder="Your email"
              variant="standard"
              {...register('email')}
              error={errors?.email?.message}
              InputProps={{
                className: classes.input,
                autoComplete: 'off'
              }}
            />
          </ThemeProvider>
        </FormControl>
      </Grid>
      <Grid
        item
        md={5}
        xs={12}
        container
        justifyContent="flex-start"
        className={clsx(classes.center, classes.subscribeButtonContainer)}
      >
        <Button variant="contained" type="submit" className={classes.subscribeButton}>
          <span>Subscribe</span>
        </Button>
      </Grid>
    </Grid>
  </CenteredGrid>
));

export default withStyles(styles)(({ classes }) => (
  <Grid container justifyContent="center" className={classes.subscriberContainer}>
    <Subscribe Subscriber={SignUpNewsLetter} />
  </Grid>
));
