import React, { useState } from 'react';
import { Typography } from '@swagup-com/components';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import { CenteredGrid } from '../../shared';
import { CustomRates, SwagupRates, SettingToggleBtn } from '../storeCommon';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '90vh'
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none'
  },
  settings: {
    border: '1px solid #E5E7E8',
    borderRadius: 10,
    padding: 15
  },
  selloos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10
  },
  emailContact: {
    paddingTop: 10
  },
  emailBox: {
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      border: 'none !important'
    }
  }
}));

const StoreSettingsTab = () => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState('SwagUp');
  const [StockOption, setStockOption] = useState('Yes');

  const handleToggle = (option, actionType) => {
    if (actionType === 'generalSettings') {
      setStockOption(option);
    } else if (actionType === 'shippingSettings') {
      setSelectedOption(option);
    }
  };

  const EmailTextField = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const handleInputChange = e => {
      const inputValue = e.target.value;
      setEmail(inputValue);
      setError(!validateEmail(inputValue));
    };

    return (
      <TextField
        type="email"
        value={email}
        onChange={handleInputChange}
        error={error}
        helperText={error ? 'Invalid email address' : ''}
        fullWidth
        InputProps={{
          style: {
            borderColor: error ? '#F44336' : '#989EA4',
            border: '1px solid #989EA4',
            padding: '12px 16px',
            borderRadius: 12
          },
          placeholder: 'Email'
        }}
        InputLabelProps={{
          style: {
            color: '#989EA4'
          }
        }}
        className={classes.emailBox}
      />
    );
  };

  const GeneralSettings = () => {
    return (
      <>
        <Grid className={classes.settings}>
          <Typography variant="h5SemiBoldInter">General settings</Typography>
          <Grid className={classes.selloos}>
            <Typography variant="body3MediumInter">Stop selling a product once it is out of stock?</Typography>
            <SettingToggleBtn
              firstButtonText="Yes"
              secondButtonText="No"
              buttonWidth="80px"
              onToggle={options => handleToggle(options, 'generalSettings')}
              selectedOption={StockOption}
            />
          </Grid>
        </Grid>
        <Grid className={classes.settings} style={{ marginTop: 20 }}>
          <Typography variant="h5SemiBoldInter">Contact email</Typography>
          <Grid container className={classes.emailContact}>
            <Typography variant="body3RegularInter" style={{ paddingBottom: 8 }}>
              Type in an email where customers can contact you
            </Typography>
            <EmailTextField />
          </Grid>
        </Grid>
      </>
    );
  };

  const ShippingSettings = () => {
    return (
      <Grid className={classes.settings}>
        <Typography variant="h5SemiBoldInter">Shipping Settings</Typography>
        <Grid className={classes.selloos}>
          <Typography variant="body3MediumInter">Charge SwagUp or Custom Shipping rates</Typography>
          <SettingToggleBtn
            firstButtonText="SwagUp"
            secondButtonText="Custom"
            buttonWidth="100px"
            onToggle={options => handleToggle(options, 'shippingSettings')}
            selectedOption={selectedOption}
          />
        </Grid>
        {selectedOption === 'SwagUp' && <SwagupRates />}
        {selectedOption === 'Custom' && <CustomRates />}
      </Grid>
    );
  };

  return (
    <Grid className={classes.root}>
      <CenteredGrid>
        <Typography variant="h2BoldInter" style={{ paddingBottom: 20 }}>
          Settings
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <GeneralSettings />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <ShippingSettings />
            </Paper>
          </Grid>
        </Grid>
      </CenteredGrid>
    </Grid>
  );
};

export default StoreSettingsTab;
