import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import round from 'lodash/round';
import toErrorPage from '../../../../helpers/toErrorPage';
import useAsync from '../../../../hooks/useAsync';
import shippingPricesApi from '../../../../apis/swagup/shippingPrices';
import shipmentsApi from '../../../../apis/swagup/shipments';
import Loader from '../../../global/Loader';
import { Modal } from '../../../shared/Modal';
import InvalidAddressForm from './InvalidAddressForm';
import InvalidAddressPayment from './InvalidAddressPayment';
import styles from './styles/invalidAddressModal';

const useStyles = makeStyles(styles);

const getShippingAddressData = shipping => ({
  phone_number: shipping?.phone_number || '',
  shipping_address1: shipping?.shipping_address1,
  shipping_address2: shipping?.shipping_address2,
  shipping_city: shipping?.shipping_city,
  shipping_zip: shipping?.shipping_zip,
  shipping_state: shipping?.shipping_state || '',
  shipping_country: shipping?.shipping_country || 'US'
});

const InvalidAddressModal = ({ shipment, fetchShipments, onClose }) => {
  const history = useHistory();
  const [newAddress, setNewAddress] = useState(null);
  const [newShippingPrice, setNewShippingPrice] = useState(null);
  const [updating, setUpdating] = useState(false);

  const quantities = useMemo(
    () => shipment.products.map(p => ({ account_product: p.product.id, quantity: p.quantity })),
    [shipment.products]
  );
  const fetchShipmentCallback = useCallback(() => shippingPricesApi.fetch({ ...newAddress, quantities }), [
    newAddress,
    quantities
  ]);
  const [fetchShippingPrice, response, isPending, error] = useAsync(fetchShipmentCallback);

  useEffect(() => {
    if (newAddress) {
      fetchShippingPrice();
    }
  }, [fetchShippingPrice, newAddress]);

  useEffect(() => {
    if (response?.data?.delivery_methods && !isPending) {
      setNewShippingPrice(
        response.data.delivery_methods
          .find(d => d.name === shipment.delivery_method?.name)
          ?.prices.breakdown.reduce((acc, curr) => curr.total_price + acc, 0)
      );
    }
  }, [response, isPending, shipment.delivery_method?.name]);

  const memoizeHandleUpdateAddress = useCallback(async () => {
    setUpdating(true);
    const shipmentResponse = await shipmentsApi.updateAddress(shipment.id, newAddress);
    if (shipmentResponse.status === 200) {
      setUpdating(false);
      onClose();
      fetchShipments();
    }
  }, [shipment.id, newAddress, onClose, fetchShipments]);

  useEffect(() => {
    if (round(newShippingPrice, 2) === round(parseFloat(shipment.total_shipping), 2)) {
      memoizeHandleUpdateAddress();
    }
  }, [newShippingPrice, shipment, memoizeHandleUpdateAddress]);

  useEffect(() => {
    if (error) {
      toErrorPage(error, history);
    }
  }, [error, history]);

  const classes = useStyles();
  const employeeAddress = getShippingAddressData(shipment);

  const showInvalidAddressPayment = newAddress && newShippingPrice && !updating;

  return (
    <Modal
      title={showInvalidAddressPayment ? 'Shipping Price Update' : 'This shipping address cannot be verified'}
      subtitle={
        showInvalidAddressPayment
          ? 'The new address has a different shipping price than the previous one, please review the changes below.'
          : null
      }
      open={!!shipment}
      onClose={onClose}
      innerClasses={{ paper: classes.paper }}
      classes={{ title: classes.title }}
    >
      {(isPending || updating) && <Loader absolute />}

      {showInvalidAddressPayment ? (
        <InvalidAddressPayment
          newAddress={newAddress}
          oldAddress={employeeAddress}
          oldShippingPrice={+shipment.total_shipping}
          newShippingPrice={newShippingPrice}
          returnToAddress={() => setNewAddress(null)}
          shipmentId={shipment.id}
          fetchShipments={fetchShipments}
          onClose={onClose}
        />
      ) : (
        <InvalidAddressForm
          employeeAddress={newAddress ?? employeeAddress}
          invalidAddressErrorMessage={response?.data?.error_detail?.message || shipment.invalid_address_error_message}
          onClose={onClose}
          onSaveAddress={address => setNewAddress(address)}
        />
      )}
    </Modal>
  );
};

export default InvalidAddressModal;
