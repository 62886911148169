import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { ArrowDropDown } from '@material-ui/icons';
import { joinFields } from '../../helpers/utils';
import { ImgWithHandler } from '../global/ImgUtils';
import templateStyles from './styles/redeemTemplates';

const useTempletesStyles = makeStyles(templateStyles);

const redemptionInfo = {
  firstName: 'John',
  lastName: 'Doe',
  country: 'US',
  addressLine1: '28 Shinn Street',
  city: 'New York',
  state: 'NY',
  zipCode: 10011,
  phoneNumber: '212-763-1308',
  apparelSize: 'M'
};

const ThankYouSection = ({ page }) => {
  const classes = useTempletesStyles(page);
  const { confirmationPageHeadline, confirmationPageBody } = page;
  return (
    <Grid container justifyContent="center" className={classes.formInfoContent}>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.thankYouSectionTextSection}>
              <Typography variant="body3RegularInter" className={classes.congratulation}>
                {confirmationPageHeadline}
              </Typography>
              <p className={classes.doneNote}>{confirmationPageBody}</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <div className={classes.productImageContainer} style={{ padding: 8 }}>
                      <img src={page.clientImage} alt={page.projectName} className={classes.productImage} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <div className={classes.redemptionInfoContainer}>
                  <p className={classes.redemptionNote}>Just to be sure, here’s the information you entered:</p>
                  <p className={classes.redemptionInfo}>
                    Full Name: <span>{joinFields([redemptionInfo.firstName, redemptionInfo.lastName], ' ')}</span>
                  </p>
                  <p className={classes.redemptionInfo}>
                    Address:{' '}
                    <span>
                      {joinFields(
                        [
                          redemptionInfo.addressLine1,
                          redemptionInfo.addressLine2,
                          redemptionInfo.city,
                          redemptionInfo.state,
                          redemptionInfo.zipCode,
                          redemptionInfo.country
                        ],
                        ', '
                      )}
                    </span>
                  </p>
                  {redemptionInfo.phoneNumber && (
                    <p className={classes.redemptionInfo}>Phone: {redemptionInfo.phoneNumber}</p>
                  )}
                  {redemptionInfo.apparelSize && (
                    <p className={classes.redemptionInfo}>Shirt Size: {redemptionInfo.apparelSize}</p>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const fakeProduct = {
  id: 1,
  name: 'Sample Product'
};

const fakeProducts = [fakeProduct, { ...fakeProduct, id: 2 }, { ...fakeProduct, id: 3 }, { ...fakeProduct, id: 4 }];

const ProductSelectionView = ({ products, classes }) => {
  const fillProduct = () => {
    switch (products.length) {
      case 0:
        return fakeProducts;
      default:
        return products;
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: 184 }}>
      <Grid item style={{ width: !products.length || products.length === 4 ? '36%' : '56%' }}>
        <Grid container justifyContent="center" spacing={2}>
          {fillProduct().map(prod => (
            <Grid key={prod.id} item xs={products.length === 3 ? 4 : 6}>
              <Grid container justifyContent="center">
                <Grid item>
                  <div
                    className={
                      products.length && products.length < 3
                        ? classes.productImageContainerLarge
                        : classes.productImageContainer
                    }
                  >
                    <ImgWithHandler
                      src={prod.imageUrl}
                      width={256}
                      height={256}
                      alt={prod.name}
                      className={classes.productImage}
                    />
                  </div>
                  <Typography variant="body4RegularInter" className={classes.productViewDetail}>
                    View Detail
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const FormInput = ({ name, select, classes }) => (
  <div>
    <Typography variant="body4RegularInter" className={classes.formLabel}>
      {name}
    </Typography>
    <div className={classes.formInput}>{select && <ArrowDropDown className={classes.formInputIcon} />}</div>
  </div>
);

const ContactInfoForm = ({ classes, showSizes }) => (
  <Grid container justifyContent="center" className={classes.formInfoContent}>
    <Grid item xs={7}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormInput name="First Name" classes={classes} />
        </Grid>
        <Grid item xs={6}>
          <FormInput name="Last Name" classes={classes} />
        </Grid>
        <Grid item xs={12}>
          <FormInput name="Email" classes={classes} />
        </Grid>
        <Grid item xs={12}>
          <FormInput name="Phone" classes={classes} />
        </Grid>
        {showSizes && (
          <Grid item xs={12}>
            <FormInput name="Size" classes={classes} select />
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);

const DefaultView = ({ headline, body, callToActionButtonText, clientImage, projectName, classes }) => (
  <Grid container alignItems="center" style={{ height: '100%' }} className={classes.section}>
    <Grid item xs={6} style={{ paddingBottom: 2 }}>
      <p className={classes.headerText}>{headline}</p>
      <p className={classes.subtitle}>{body}</p>
      <div className={classes.button}>{callToActionButtonText}</div>
    </Grid>
    <Grid item xs={6}>
      <Grid container alignContent="center" style={{ height: '100%' }}>
        <div className={classes.productContainer}>
          <img src={clientImage} alt={projectName} className={classes.product} />
        </div>
      </Grid>
    </Grid>
  </Grid>
);

const InformationGatherigView = ({ showSizes, productOptions, productQuestion, showProductView, classes }) => (
  <Grid container justifyContent="center" style={{ height: '100%' }} className={classes.section}>
    <Grid item xs={8}>
      <Typography
        variant="body3RegularInter"
        className={classes.productQuestion}
        style={{ marginBottom: !showProductView ? 22 : undefined }}
      >
        {showProductView ? productQuestion : 'Tell us a little about yourself'}
      </Typography>
      {showProductView && (
        <Typography variant="body3RegularInter" className={classes.productQuestionCTAText}>
          Select one below
        </Typography>
      )}
    </Grid>
    <Grid item xs={12}>
      {showProductView ? (
        <ProductSelectionView products={productOptions} classes={classes} />
      ) : (
        <ContactInfoForm classes={classes} showSizes={showSizes} />
      )}
    </Grid>
    <Grid item xs={12}>
      <Grid container justifyContent="center" className={classes.wizardButtonSection}>
        <Grid item xs={7}>
          <Grid container alignItems="center">
            <Grid item>
              <div className={classes.previous}>
                <Grid container alignItems="center">
                  <KeyboardBackspaceIcon className={classes.previousIcon} />
                  <span>Home</span>
                </Grid>
              </div>
            </Grid>
            <Grid item xs />
            <Grid item>
              <div className={classes.button}>Continue</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const RedeemTemplates = ({ page, currentStep }) => {
  const {
    projectName,
    headline,
    body,
    clientLogo,
    clientImage,
    callToActionButtonText,
    backgroundColor,
    fontColor,
    accentColor,
    fontFamily,
    copyright,
    productOptions,
    productQuestion,
    selectProduct,
    requireSizeSelection
  } = page;

  const getSwipeableViewsIndex = () => {
    switch (currentStep) {
      case 5:
      case 6:
        return 2;
      case 4:
        return 1;
      default:
        return 0;
    }
  };

  const showProductView = selectProduct && productOptions.length !== 1;
  const showSizes =
    (!selectProduct && requireSizeSelection) ||
    (selectProduct && productOptions.length === 1 && !productOptions[0].sizes.every(s => s.size === 'One Size'));
  const classes = useTempletesStyles({ backgroundColor, fontColor, accentColor, fontFamily });

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.section}>
        <Grid container>
          <Grid item>
            <div className={classes.logoContainer}>
              {clientLogo && <img src={clientLogo} alt={projectName} className={classes.logo} />}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid container style={{ height: '100%', overflowX: 'hidden' }} alignItems="center">
          <Grid item xs={12}>
            <SwipeableViews axis="x" index={getSwipeableViewsIndex()} className={classes.swipeableViews} disabled>
              <DefaultView
                headline={headline}
                body={body}
                callToActionButtonText={callToActionButtonText}
                clientImage={clientImage}
                projectName={projectName}
                classes={classes}
              />
              <InformationGatherigView
                showSizes={showSizes}
                productOptions={productOptions}
                productQuestion={productQuestion}
                showProductView={showProductView}
                classes={classes}
              />
              <Grid container alignItems="center" style={{ height: '100%' }} className={classes.section}>
                <ThankYouSection page={page} />
              </Grid>
            </SwipeableViews>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.section}>
        <Grid container justifyContent="center">
          <div className={classes.advisory} dangerouslySetInnerHTML={{ __html: copyright }} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default RedeemTemplates;
