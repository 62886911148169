import { SET_OPPORTUNITY } from './types';
import DashBoard from '../apis/DashBoard';
import apiPaths from '../helpers/apiPaths';
import { errorAndLog, okAndLog } from '../helpers/utils';
import log from '../logger';

export const setOpportunity = opp => async dispatch =>
  dispatch({
    type: SET_OPPORTUNITY,
    payload: opp
  });

export const getOpportunityById = id => async dispatch => {
  log.debug('getOpportunityById Action - Entering');
  try {
    const apiCall = await DashBoard.get(apiPaths.opportunity(id));
    if (apiCall.status === 200) {
      setOpportunity(apiCall.data)(dispatch);
      return okAndLog('getOpportunityById', apiCall.status, apiCall.data);
    }
    return errorAndLog('getOpportunityById', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('getOpportunityById', e.status, e.data);
  }
};
