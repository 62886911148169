import { ellipsisStyles, greyRoundBorder } from '../../../../shared/styles/commonStyles';

export default theme => ({
  root: {
    padding: '35px 30px 0 80px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '35px 10px 0 20px'
    }
  },
  navbarStyle: {
    width: 'calc(calc(100% - 40px))'
  },
  navbarClosed: {
    marginLeft: '40px',
    width: 'calc(100% - 150px)'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    fontWeight: 500,
    color: '#0b1829',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    margin: '0 0 4px'
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#787b80',
    lineHeight: 1.67,
    letterSpacing: 'normal'
  },
  allCheckboxsContainer: {
    margin: '24px 4px'
  },
  formControl: ({ preventFurtherActions }) => ({
    marginLeft: 0,
    ...(preventFurtherActions ? { cursor: 'default' } : {})
  }),
  allCheckbox: ({ preventFurtherActions }) => ({
    paddingLeft: 0,
    width: 16,
    height: 16,
    color: preventFurtherActions ? 'rgba(0, 0, 0, 0.26)' : '#ced1d6',
    '& svg': {
      width: 16,
      height: 16
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#3577d4'
    },
    ...(preventFurtherActions ? { userSelect: 'none', pointerEvents: 'none' } : {})
  }),
  products: {
    flex: 1,
    '& .ScrollbarsCustom-Content': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gridColumnGap: 100,
      paddingRight: '20px !important',
      minHeight: '0 !important',
      [theme.breakpoints.down('sm')]: {
        gridColumnGap: 50
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'auto',
        gridColumnGap: 0
      }
    }
  },
  checkbox: {
    padding: '0 24px 0 4px'
  },
  image: {
    ...greyRoundBorder,
    width: 78,
    height: 78,
    marginRight: 24
  },
  name: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: ({ unavailable }) => (unavailable ? '#ced1d6' : '#0b1829'),
    maxWidth: '100%',
    ...ellipsisStyles
  },
  quantity: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    letterSpacing: 'normal',
    color: ({ unavailable }) => (unavailable ? '#ced1d6' : '#787b80')
  },
  separator: {
    height: 1,
    backgroundColor: '#f0f2f5',
    margin: '24px 0 24px 150px'
  },
  btnContainer: {
    height: 18,
    opacity: 0,
    padding: '18px 0 0',
    transition: 'all 150ms ease-in',
    '&.show': {
      height: 92,
      opacity: 1,
      padding: '18px 0',
      transition: 'all 150ms ease-out'
    }
  },
  btn: {
    height: 56,
    letterSpacing: 'normal'
  },
  btnIcon: {
    width: 20,
    height: 20,
    margin: '3px 0 0 8px'
  },
  demoLabel: ({ preventFurtherActions }) => ({
    marginLeft: 4,
    marginTop: 1,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 12,
    letterSpacing: 'normal',
    color: preventFurtherActions ? 'rgba(0, 0, 0, 0.26)' : '#0b1829'
  }),
  sizesBreakdown: {
    width: 248,
    padding: '0 16px 16px',
    margin: '0',
    boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    backgroundImage: 'none'
  },
  size: {
    marginTop: 16,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 'normal',
    color: '#0b1829',
    textAlign: 'left'
  },
  unavailableSize: {
    color: '#ced1d6'
  },
  productImageContainer: {
    width: 78,
    height: 78,
    borderRadius: 4,
    position: 'relative',
    '&:hover': {
      '& .zoom': {
        display: 'flex !important',
        cursor: 'pointer'
      }
    }
  }
});
