import * as React from 'react';
import chunk from 'lodash/chunk';
import { contactsApi } from '../../../../../apis/swagup';

const limit = 500;
const useFetchEmployeesByIds = ids => {
  const [employees, setEmployees] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!ids?.length) {
      setEmployees();
      return;
    }

    setIsLoading(true);
    const fetchAllEmployees = async () => {
      const idsQueries = chunk(ids, limit).map(idsChunk => contactsApi.fetch({ directoryIds: idsChunk.join(), limit }));
      const responses = await Promise.allSettled(idsQueries);

      setEmployees(() =>
        responses.reduce((acc, response) => {
          if (response.status === 'fulfilled') {
            return [...acc, ...response.value.results];
          }
          return acc;
        }, [])
      );
      setIsLoading(false);
    };

    fetchAllEmployees();
  }, [ids]);

  return [employees, isLoading];
};

export default useFetchEmployeesByIds;
