import axios from 'axios';
import { FETCH_EMPLOYEES, DELETE_EMPLOYEE, SAVE_EMPLOYEE, ADD_EMPLOYEE, DELETE_EMPLOYEES } from './types';
import DashBoard from '../apis/DashBoard';
import { okAndLog, errorAndLog } from '../helpers/utils';
import log from '../logger';
import apiPaths from '../helpers/apiPaths';

export const addEmployee = employee => dispatch =>
  DashBoard.post(
    apiPaths.contacts,
    { ...employee, phone_number: employee.phone_number ?? '' },
    { validateStatus: status => status === 201 }
  )
    .then(({ status, data }) => {
      dispatch({
        type: ADD_EMPLOYEE,
        payload: { ...data }
      });
      return okAndLog('addEmployee', status, data);
    })
    .catch(error => errorAndLog('addEmployee', error?.status, error?.data));

export const saveEmployee = employee => async dispatch => {
  log.debug('saveEmployee Action - Entering');

  try {
    const apiCall = await DashBoard.patch(apiPaths.contact(employee.id), {
      ...employee,
      phone_number: employee.phone_number ?? ''
    });
    if (apiCall.status === 200) {
      dispatch({
        type: SAVE_EMPLOYEE,
        payload: apiCall.data
      });
      return okAndLog('saveEmployee', apiCall.status, apiCall.data);
    }
    return errorAndLog('saveEmployee', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('saveEmployee', e?.status, e?.data);
  }
};

export const deleteEmployee = id => async dispatch => {
  log.debug('deleteEmployee Action - Entering');
  try {
    const apiCall = await DashBoard.delete(apiPaths.contact(id));
    if (apiCall.status === 204) {
      dispatch({
        type: DELETE_EMPLOYEE,
        payload: id
      });
      return okAndLog('deleteEmployee', apiCall.status, apiCall.data);
    }
    return errorAndLog('deleteEmployee', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('deleteEmployee', e.status, e.data);
  }
};

export const deleteEmployees = ids => async dispatch => {
  log.debug('deleteEmployees Action - Entering');
  try {
    const apiCall = await DashBoard.delete(`${apiPaths.contacts}?pk=${ids.join('&pk=')}`);
    if (apiCall.status === 204) {
      dispatch({
        type: DELETE_EMPLOYEES,
        payload: ids
      });
      return okAndLog('deleteEmployees', apiCall.status, apiCall.data);
    }
    return errorAndLog('deleteEmployees', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('deleteEmployees', e.status, e.data);
  }
};

// TODO: newPaginateEmployee & paginateEmployee should be unified
let cancelNewPaginateEmployeeTokenSource;
export const newPaginateEmployee = (limit = 24, offset = 0, query = '') => async dispatch => {
  if (cancelNewPaginateEmployeeTokenSource?.cancel) {
    cancelNewPaginateEmployeeTokenSource.cancel('Employees pagination canceled due to new request');
  }
  log.debug('newPaginateEmployee Action - Entering');
  try {
    const queryData = query ? `&${query}` : '';
    cancelNewPaginateEmployeeTokenSource = axios.CancelToken.source();
    const apiCall = await DashBoard.get(
      `${apiPaths.contacts}?limit=${limit}&offset=${offset}&contacts=not_soft_deleted${queryData}`,
      {
        cancelToken: cancelNewPaginateEmployeeTokenSource.token
      }
    );

    if (apiCall.status === 200) {
      dispatch({
        type: FETCH_EMPLOYEES,
        payload: apiCall.data.results
      });
      return okAndLog('newPaginateEmployee', apiCall.status, apiCall.data);
    }
    return errorAndLog('newPaginateEmployee', apiCall.status, apiCall.data);
  } catch (e) {
    if (axios.isCancel(e)) return okAndLog('Employees pagination request canceled', null, { canceled: true });
    return errorAndLog('newPaginateEmployee', e.status, e.data);
  }
};
