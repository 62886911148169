import * as React from 'react';
import { Chip, Collapse, makeStyles, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button } from '@swagup-com/components';
import { fiveMinutes } from '../../helpers/helperConstants';
import useIsOverflow from '../../hooks/useIsOverflow';
import { ellipsisStyles, scrollBarAutohide } from '../shared/styles/commonStyles';
import apiPaths from '../../helpers/apiPaths';
import { contactGroupsApi } from '../../apis/swagup';
import { useQueryFilterValidated } from '../../hooks/useFilters';

const useStyles = makeStyles({
  title: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.5,
    textAlign: 'left',
    color: '#0b1829',
    marginBottom: 8
  },
  label: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#0B1829'
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'left',
    gap: 12,
    paddingTop: 8,
    overflow: 'auto',
    ...scrollBarAutohide
  },
  container: {
    paddingTop: 6
  },
  expandButton: {
    minWidth: 78,
    height: 28,
    fontSize: 12,
    marginTop: ({ expand }) => (expand ? 'auto' : 8)
  },
  collapse: {
    ...ellipsisStyles,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: 12,
    maxHeight: 36,
    paddingTop: 8
  }
});

const CustomChip = withStyles({
  outlinedSecondary: {
    border: 'solid 2px #787B80',
    backgroundColor: '#FFFFFF',
    color: '#0B1829',
    '&.MuiChip-outlinedSecondary:hover': {
      background: '#ebedf0'
    }
  },
  outlinedPrimary: {
    '&.MuiChip-outlinedPrimary:hover': {
      background: '#d7e4f6'
    }
  },
  label: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.5,
    padding: '5px 12px'
  },
  deleteIcon: {
    height: 12,
    width: 12
  }
})(Chip);

const GroupChip = ({ group, selected, onClick }) => (
  <CustomChip
    label={group.name}
    variant="outlined"
    color={selected ? 'primary' : 'secondary'}
    deleteIcon={<CloseIcon />}
    onClick={() => onClick(group.id)}
    onDelete={selected ? () => onClick(group.id) : undefined}
  />
);

const CollapseButton = ({ onClick, expand }) => {
  const classes = useStyles({ expand });

  const [Icon, text] = expand ? [ExpandLessIcon, 'Collapse'] : [ExpandMoreIcon, 'View all'];

  return (
    <Button
      onClick={onClick}
      variant="text"
      className={classes.expandButton}
      endIcon={<Icon style={{ fontSize: 14 }} />}
    >
      {text}
    </Button>
  );
};

export const useFetchGroups = () => {
  const { contactsFeGroupingTemp011921: grouping } = useFlags();

  const { data } = useQuery(apiPaths.contactGroups, () => contactGroupsApi.fetch(), {
    enabled: grouping,
    staleTime: fiveMinutes
  });
  return { groups: data?.results || [] };
};

const Groups = ({ onGroupClick }) => {
  const classes = useStyles();
  const [expand, setExpand] = React.useState(false);
  const queryGroups = useQueryFilterValidated('groups');
  const ids = queryGroups.length > 0 ? queryGroups.split(',').map(Number) : [];
  const [[selectedGroups], setSelectedGroups] = React.useState([new Set(ids)]);

  const { groups } = useFetchGroups();

  const handleGroupClick = groupId => {
    if (!selectedGroups.delete(groupId)) selectedGroups.add(groupId);
    setSelectedGroups([selectedGroups]);
    if (onGroupClick) onGroupClick([...selectedGroups.keys()].join());
  };

  const ref = React.createRef();
  const isOverflow = useIsOverflow(ref, true);

  const toggleExpand = () => {
    setExpand(prevExpand => !prevExpand);
    ref.current.scrollTop = 0;
  };

  return (
    <>
      {groups.length > 0 && (
        <>
          <p className={classes.title}>Groups</p>
          <div style={{ flexDirection: 'row', display: 'inline-flex', verticalAlign: 'baseline' }}>
            <Collapse in={expand} collapsedSize={36} timeout={500}>
              <div ref={ref} className={expand ? classes.chipContainer : classes.collapse}>
                {groups.map(g => (
                  <GroupChip
                    key={g.id}
                    group={g}
                    selected={selectedGroups.has(g.id)}
                    onClick={() => handleGroupClick(g.id)}
                  />
                ))}
                {expand && <CollapseButton onClick={toggleExpand} expand={expand} />}
              </div>
            </Collapse>
            {!expand && isOverflow && <CollapseButton onClick={toggleExpand} expand={expand} />}
          </div>
        </>
      )}
    </>
  );
};

export default Groups;
