import { SELECT_INVOICE, GET_INVOICES, GET_INVOICES_BY_QUERY, SELECT_PUBLIC_INVOICE } from './types';
import DashBoard from '../apis/DashBoard';
import PublicAPI from '../apis/PublicAPI';
import apiPaths from '../helpers/apiPaths';
import { okAndLog, errorAndLog } from '../helpers/utils';
import log from '../logger';

export const selectInvoice = id => async dispatch => {
  log.debug('selectInvoice Action - Entering');
  try {
    const apiCall = await DashBoard.get(apiPaths.invoice(id));
    if (apiCall.status === 200) {
      dispatch({
        type: SELECT_INVOICE,
        payload: apiCall.data
      });
      return okAndLog('selectInvoice', apiCall.status, apiCall.data);
    }
    return errorAndLog('selectInvoice', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('selectInvoice', e.status, e.data);
  }
};

export const getInvoices = () => async dispatch => {
  log.debug('getInvoices Action - Entering');
  try {
    const apiCall = await DashBoard.get(apiPaths.invoices);
    if (apiCall.status === 200) {
      dispatch({
        type: GET_INVOICES,
        payload: apiCall.data
      });
      return okAndLog('getInvoices', apiCall.status, apiCall.data);
    }
    return errorAndLog('getInvoices', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('getInvoices', e.status, e.data);
  }
};

export const getInvoicesByQuery = (query, params) => async dispatch => {
  const currentQuery = params ? `${apiPaths.invoices}?${params}&limit=24` : query;

  log.debug('query:', currentQuery);
  log.debug('getInvoicesByQuery Action - Entering');
  try {
    const apiCall = await DashBoard.get(currentQuery);
    if (apiCall.status === 200) {
      dispatch({
        type: GET_INVOICES_BY_QUERY,
        payload: apiCall.data
      });
      return okAndLog('getInvoicesByQuery', apiCall.status, apiCall.data);
    }
    return errorAndLog('getInvoicesByQuery', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('getInvoicesByQuery', e.status, e.data);
  }
};

export const fetchPublicInvoice = id => async dispatch => {
  log.debug('fetchPublicInvoice Action - Entering');
  try {
    const apiCall = await PublicAPI.get(apiPaths.invoice(id));
    if (apiCall.status === 200) {
      dispatch({
        type: SELECT_PUBLIC_INVOICE,
        payload: apiCall.data
      });
      return okAndLog('fetchPublicInvoice', apiCall.status, apiCall.data);
    }
    return errorAndLog('fetchPublicInvoice', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('fetchPublicInvoice', e.status, e.data);
  }
};
