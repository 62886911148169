import { useQuery, useQueryClient } from 'react-query';
import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import apiPaths from '../../../../../helpers/apiPaths';
import global from '../../../../../apis/swagup/global';

const useStorageInfoMain = () => {
  const [storagePrices, setStoragePrices] = useState({});
  const [storagePricesBeforeDiscount, setStoragePricesBeforeDiscount] = useState({});

  const { data: storage } = useQuery([apiPaths.storagePrices], global.fetchStoragePricesWithStorage);

  useEffect(() => {
    if (!isEmpty(storage?.results)) {
      const storagePricesAux = storage.results.reduce(
        (acc, p) => ({ ...acc, [p.storage_category]: Number(p.price) }),
        {}
      );
      const storagePricesBeforeDiscountAux = storage.results.reduce(
        (acc, p) => ({ ...acc, [p.storage_category]: Number(p.price_without_discount) }),
        {}
      );
      setStoragePrices(storagePricesAux);
      setStoragePricesBeforeDiscount(storagePricesBeforeDiscountAux);
    }
  }, [storage]);

  return { storagePrices, storagePricesBeforeDiscount };
};

export default useStorageInfoMain;
