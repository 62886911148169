/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Route, Switch, useLocation, useParams, useHistory, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { Button, Typography as CustomTypography } from '@swagup-com/components';
import { usePaginatedQuery, useQueryParams } from '../../../hooks';
import { cleanShipmentGroup } from '../../../actions/shipmentGroupActions';
import accountProductsApi from '../../../apis/swagup/accountProducts';
import apiPaths from '../../../helpers/apiPaths';
import Loader from '../../global/Loader';
import SelectProducts from '../orders/requested/shipments/SelectProducts';
import SelectRecipients from '../orders/requested/shipments/SelectRecipients';
import Stepper from '../../shared/Stepper';
import TopBar from '../../shared/TopBar';
import { mapProductToProof } from '../orders/requested/common/utilsOrder';
import ShipmentsReviewRouter from '../orders/requested/shipments/ShipmentsReviewRouter';
import { Helmet } from '../../shared';
import seoTags from '../../../apis/seoTags';
import useInventoryHistory from '../../../hooks/useInventoryHistory';

import PaymentFailedAlert from '../../UI/Common/PaymentFailedAlert';

import styles from '../../UI/Common/PaymentFailedAlert.style';
import { useMembership } from '../../../contexts/membershipContext';

const useStyles = makeStyles(styles);

const perPageOptions = [12, 24, 36, 48];

const SelectProductsWrapper = ({
  preventFurtherActions,
  products,
  isLoading,
  isLoadingHistory,
  pagination,
  isFetched,
  isFetching
}) => {
  if (isLoading || isLoadingHistory) return <Loader />;

  return (
    <SelectProducts
      products={products}
      pagination={pagination}
      isFetching={!isFetched && isFetching}
      preventFurtherActions={preventFurtherActions}
    />
  );
};

const steps = [
  { step: 'select-products', label: 'Select Products' },
  { step: 'select-recipients', label: 'Select Recipients' },
  { step: 'payment', label: 'Payment' }
];

const stepsNew = [
  { step: 'select-products', label: 'Select Products' },
  { step: 'select-recipients', label: 'Select Recipients' },
  { step: 'payment', label: 'Payment Review' }
];

const NegativeBalanceAlertMessageActionButtons = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleAddFundsToSwagCard = () => {
    history.push('/billing/add-credits');
  };

  const handleUpgradeMembership = () => {
    history.push('/membership/manage-membership-switch');
  };

  return (
    <>
      <Grid item container xs={6} style={{ gap: 4, justifyContent: 'end', flexWrap: 'nowrap' }}>
        <Grid item>
          <Button
            onClick={handleAddFundsToSwagCard}
            className={`${classes.paymentFailedAlertButton} ${classes.paymentFailedAlertSwagCardBtn}`}
          >
            <CustomTypography variant="subtitle3SemiBoldInter">Add funds to Swag Card</CustomTypography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={`${classes.paymentFailedAlertButton} ${classes.paymentFailedAlertUpgradeMembershipBtn}`}
            variant="primary"
            onClick={handleUpgradeMembership}
          >
            <CustomTypography variant="subtitle3SemiBoldInter">Upgrade Membership</CustomTypography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const SendSwag = () => {
  const { step } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const query = useQueryParams();
  const product = query.get('product') || '';

  React.useEffect(() => {
    return () => {
      const isLeavingSendSwag = ['/send-swag', '/import-contacts/'].every(
        path => !history.location.pathname.includes(path)
      );
      if (isLeavingSendSwag) {
        dispatch(cleanShipmentGroup);
      }
    };
  }, [dispatch, history]);

  const stepNumber = steps.findIndex(s => s.step === step);
  const handleGoBack = stepNumber <= 0 && !location.state?.from ? () => history.push('/') : history.goBack;

  const handleContinueToCheckout = () => history.push('/send-swag/payment/review-shipment');

  const { updatedSendSwagFlow } = useFlags();

  const {
    company: { balance, allow_negative_credit_balance }
  } = useMembership();

  const preventFurtherActions = useMemo(() => {
    return +balance < 0 && allow_negative_credit_balance === false;
  }, [balance, allow_negative_credit_balance]);

  const recordTypeParams = query.get('product_type')?.split(',') ?? [];

  const search = query.get('search') || '';
  const recordType = recordTypeParams?.length === 1 ? recordTypeParams[0] : 'all';

  const accountProductsParams = {
    visible_in_inventory: true,
    search: search || undefined,
    record_type: recordType,
    inventory: 'available',
    ordering: '-created_at'
  };

  const {
    query: { data, isLoading, isFetched, isFetching },
    pagination
  } = usePaginatedQuery({
    queryKey: [apiPaths.accountProducts, accountProductsParams],
    queryFn: (limit, offset) => {
      return accountProductsApi.fetch({ limit, offset, ...accountProductsParams });
    },
    queryOptions: {
      select: queryData => ({
        ...queryData,
        results: queryData.results.map(mapProductToProof)
      })
    },
    perPageOptions
  });

  const inventoryItems = data?.results ?? [];

  const {
    inventoryHistory,
    isLoading: isLoadingHistory,
    getAccountProductInventoryDueInfo,
    getGroupByPaidDate
  } = useInventoryHistory(inventoryItems, accountProductsParams);

  const products = useMemo(() => {
    const inventoryAux = inventoryItems.map(i => ({
      ...i,
      history: getGroupByPaidDate(inventoryHistory.filter(inv => inv.account_product === i.id))
    }));
    return inventoryAux.map(i => ({ ...i, ...getAccountProductInventoryDueInfo(i.history) }));
  }, [inventoryItems, inventoryHistory]);

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      overflow={step !== 'payment' ? 'hidden' : undefined}
      bgcolor="#fff"
    >
      <Helmet tags={seoTags.shipments} />
      <TopBar onGoBack={handleGoBack}>
        <Stepper
          steps={updatedSendSwagFlow ? stepsNew : steps}
          active={stepNumber}
          onClick={!isEmpty(product) ? () => history.push('/send-swag/select-products') : undefined}
        />
      </TopBar>

      {preventFurtherActions && (
        <Grid style={{ margin: '10px 80px' }}>
          <PaymentFailedAlert
            title="Insufficient Swag Card funds"
            message={
              <>
                Your Swag Card balance has a <span style={{ fontWeight: 700 }}>negative value.</span> Please add funds
                to your Swag Card balance to cover new shipments.
              </>
            }
            highlightedText="Your balance may be depleted due to recurring storage fees, you can also upgrade now to Platinum Membership and never pay for storage again."
            ActionButtons={NegativeBalanceAlertMessageActionButtons}
          />
        </Grid>
      )}

      <Switch>
        <Route path="/send-swag/select-products">
          <SelectProductsWrapper
            preventFurtherActions={preventFurtherActions}
            products={products}
            isLoading={isLoading}
            isFetched={isFetched}
            isFetching={isFetching}
            isLoadingHistory={isLoadingHistory}
            pagination={pagination}
          />
        </Route>
        <Route path="/send-swag/select-recipients/">
          <SelectRecipients
            step="select-recipients"
            limitedStock
            onCreate={handleContinueToCheckout}
            products={products}
            preventFurtherActions={preventFurtherActions}
          />
        </Route>
        <Route path="/send-swag/payment">
          <ShipmentsReviewRouter preventFurtherActions={preventFurtherActions} />
        </Route>
        <Redirect to="/send-swag/select-products" />
      </Switch>
    </Box>
  );
};

export default SendSwag;
