import { scrollBar } from '../../shared/styles/commonStyles';

const styles = theme => ({
  sectionTitle: {
    fontFamily: 'Futura',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  formContainer: {
    padding: '0px 0px 30px 15px',
    maxWidth: '690px',
    borderRadius: '4px'
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#3577d4'
  },
  tabSelected: {
    fontFamily: 'Gilroy-bold !important',
    color: '#434d5c !important'
  },
  tabRoot: {
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 500,
    fontSize: 16,
    textTransform: 'none',
    lineHeight: 1.3,
    opacity: 1,
    color: '#8d9299'
  },
  checkoutSubHeading: {
    fontFamily: 'Roboto',
    fontSize: 14,
    marginBottom: 24,
    fontWweight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  checkoutSection: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    textTransform: 'uppercase'
  },
  formParagraph: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  pPaddingBottom: {
    paddingBottom: '15px'
  },
  pPaddingTop: {
    paddingTop: '15px'
  },
  tabTitle: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    textTransform: 'uppercase'
  },
  checkoutActions: {
    paddingTop: '40px'
  },
  bigSectionHeader: {
    fontFamily: 'Futura',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    padding: '40px 0 0 0',
    marginBottom: 0
  },
  submitButton: {
    width: '282px',
    height: '56px',
    '&:hover': {
      backgroundColor: '#3577d4'
    },
    '& span': {
      fontFamily: 'Futura',
      fontSize: '16px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal'
    },
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: '4px',
    backgroundColor: '#3577d4'
  },
  disclaimer: {
    fontFamily: 'Roboto',
    marginTop: 15,
    fontSize: 12
  },
  formControl: {
    marginTop: '20px !important'
  },
  Loading: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '2',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  containerCreditAvailable: {
    borderRadius: 15,
    boxShadow: '0 15px 65px 0 rgba(27, 28, 31, 0.03)',
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    padding: '23px 27px',
    marginBottom: 15
  },
  containerCreditCard: {
    borderRadius: 15,
    boxShadow: '0 15px 65px 0 rgba(27, 28, 31, 0.03)',
    border: 'solid 2px #ebeef2',
    backgroundColor: '#ffffff',
    padding: '23px 27px'
  },
  buttonCreditCard: {
    height: 90,
    width: '100% !important',
    marginBottom: 23,
    cursor: 'none'
  },
  containerCreditAvailableSelected: {
    boxShadow: '0 25px 95px 0 rgba(27, 28, 31, 0.05)',
    border: 'solid 2px #3577d4'
  },
  creditAvailable: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    lineHeight: 1,
    color: '#0b1829'
  },
  textCreditAvailable: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 1,
    color: '#868a8f',
    marginTop: 8
  },
  orderCost: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#434d5c',
    lineHeight: 0.83,
    paddingBottom: 20
  },
  valueOrderCost: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    color: '#0f2440',
    lineHeight: 0.81
  },
  valueAvailableCredit: {
    fontFamily: 'Gilroy',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 0.81,
    color: '#45af5f'
  },
  payRemaining: {
    fontFamily: 'FuturaBT-Book',
    fontSize: 24,
    fontWeight: 'normal',
    color: '#434c5f',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginBottom: 40
  },
  payRemainingQty: {
    fontFamily: 'FuturaBT-Book',
    fontSize: 24,
    fontWeight: 500,
    color: '#3577d4',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginBottom: 40
  },
  modalContainer: {
    position: 'absolute',
    top: 110,
    left: '50%',
    marginLeft: -326,
    margin: '0 auto',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('xs')]: {
      top: 56,
      width: '90%',
      marginLeft: '-45%'
    }
  },
  buttonChange: {
    fontWeight: 500,
    padding: 0,
    height: 25,
    '&:hover': { color: '#004ca2' }
  },
  gridList: {
    ...scrollBar,
    height: 215,
    width: '100%'
  },
  modalDialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiDialogContent-root': {
      ...scrollBar
    }
  },
  Dialog: {
    width: 724,
    maxWidth: 724
  },
  modalTitle: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: '#434d5c',
    textAlign: 'center',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal'
  },
  modalSubtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    color: '#8d9299',
    textAlign: 'center',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    marginTop: 15
  },
  modalText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434d5c',
    marginTop: 17,
    marginBottom: 16
  },
  closeButton: {
    marginTop: 25,
    marginRight: 1
  },
  accountImg: {
    width: 48,
    height: 42
  },
  DialogTitle: {
    padding: '0px 24px'
  },
  errorAlert: {
    maxWidth: 'inherit',
    width: 'inherit'
  }
});
export default styles;
