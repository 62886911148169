import React, { useReducer, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { IconButton, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Button, Typography as CustomTypography } from '@swagup-com/components';
import { CheckedIcon, UncheckedIcon } from '../icons';
import { changeInputValue } from '../../helpers/utils';
import { reorderProductDisabledCopy } from '../../helpers/helperConstants';
import apiPaths from '../../helpers/apiPaths';
import accountProductsApi from '../../apis/swagup/accountProducts';
import log from '../../logger';
import styles from './styles/InventoryDisplayTool';
import { ImgWithHandler } from '../global/ImgUtils';
import { useQueryParams } from '../../hooks';

const productImageTitle = enabled => (enabled ? 'Order again' : reorderProductDisabledCopy);

const useStyles = makeStyles(styles);

const ProductImage = ({ item, name, imgSrc, title, width, height, linkTo }) => {
  const img = <ImgWithHandler src={imgSrc} alt={name} title={title} width={width} height={height} />;

  return (
    <div style={{ width, height }}>
      {item?.enabled && linkTo ? (
        <Link to={linkTo} style={{ display: 'inline-flex' }}>
          {img}
        </Link>
      ) : (
        img
      )}
    </div>
  );
};

const ProductNameInput = ({ item, blue, readOnly }) => {
  const classes = useStyles({ blue });
  const [newProductName, setNewProductName] = useState('');
  const [isEditing, toggleIsEditing] = useReducer(prev => !prev, false);
  const shouldUpdateProductName = useRef(true);

  const queryClient = useQueryClient();
  const { mutate: editName } = useMutation(
    [apiPaths.accountProducts, { ids: item.id }],
    name => {
      if (name !== item.name) accountProductsApi.editName({ prodId: item.id, name });
    },
    { onSuccess: () => queryClient.invalidateQueries([apiPaths.accountProducts]) }
  );

  const handleChangeProductName = ({ target: { value } }) => setNewProductName(value);

  const handleKeyDownProductName = event => {
    if ([27, 13].includes(event.keyCode)) {
      shouldUpdateProductName.current = event.keyCode === 13;
      event.target.blur();
    }
  };

  const handleOnBlur = event => {
    const trimmedName = newProductName.trim();
    const mustUpdateProductName =
      shouldUpdateProductName.current && trimmedName.length > 0 && trimmedName !== item.name;

    if (mustUpdateProductName) {
      log.debug(`about to update product name to: ${trimmedName}`);
      editName(trimmedName);
    } else {
      shouldUpdateProductName.current = true;
    }
    const newInputValue = mustUpdateProductName ? trimmedName : item.name;
    if (event.target.value !== newInputValue) {
      changeInputValue(event.target, newInputValue);
    }
    toggleIsEditing();
  };

  return readOnly ? (
    <p className={classes.showProductName}>{item.name}</p>
  ) : (
    <input
      type="text"
      id={item.id}
      defaultValue={item.name}
      className={isEditing ? classes.editProductName : classes.showProductName}
      onChange={handleChangeProductName}
      onKeyDown={handleKeyDownProductName}
      onFocus={toggleIsEditing}
      onBlur={handleOnBlur}
      maxLength={80}
    />
  );
};

const ProductColor = ({ item, blue }) => {
  const classes = useStyles({ blue, color: item.theme_color_hex ? `#${item.theme_color_hex}` : 'transparent' });

  return (
    <Grid container alignItems="center">
      <Typography variant="subtitle2" className={classes.text}>
        Color:
      </Typography>
      <span className={classes.colorText}>{item.theme_color || 'Custom'} </span>
      <div className={classes.colorCircle} />
    </Grid>
  );
};

const ProductColorV2 = ({ item }) => {
  const classes = useStyles({ color: `#${item?.theme_color_hex}` });

  return (
    <Grid container alignItems="center">
      <CustomTypography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
        Color: {item.theme_color || 'Custom'}
      </CustomTypography>
      {item.theme_color_hex && <div className={classes.colorCircle} style={{ border: '1px solid #B7BBBF' }} />}
    </Grid>
  );
};

const selectionMarkTitle = selected => (selected ? null : 'Select to REORDER');

const SelectionMark = ({ selected, title, onClick }) => (
  <IconButton onClick={onClick} title={title} size="small">
    {selected ? <CheckedIcon /> : <UncheckedIcon />}
  </IconButton>
);

const getSizeName = (name, quantity) => {
  if (name === 'One Size') return quantity === 0 ? 'Inventory' : 'One Size';
  return name;
};

const ProductQuantities = ({ item, blue, list }) => {
  const classes = useStyles({ blue, list });
  const isBetweenSmAndLg = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));
  const bigCardsWidth = isBetweenSmAndLg ? 63 : 58;
  const listWidth = list ? 80 : bigCardsWidth;

  return item.stock.map(qps => {
    const sizeNameValue = getSizeName(qps.size.name, qps.quantity);
    const width = ['One Size', 'Inventory'].includes(sizeNameValue) ? '100%' : listWidth;
    const [sizeNameColor, quantityColor] =
      qps.quantity === 0 ? ['#d3dbe5', '#d3dbe5'] : ['inherit', blue ? 'white' : '#0f2440'];

    const sizeName = <span style={{ color: sizeNameColor }}>{sizeNameValue}</span>;
    const quantity = <span style={{ color: quantityColor, marginLeft: 2 }}>{qps.quantity}</span>;

    return (
      <Typography key={qps.size.id} variant="subtitle2" className={classes.quantityText} style={{ width }}>
        {sizeName}:{quantity}
        {list && qps.quantity < qps.minimum && <ArrowDropDownIcon style={{ marginBottom: 2 }} />}
      </Typography>
    );
  });
};

const SendSwagButton = ({ item, className }) => {
  const location = useLocation();
  const isDisabled = item.stock.reduce((acc, size) => acc + size.quantity, 0) === 0;
  const query = useQueryParams();

  React.useEffect(() => {
    query.set('selected', item.id);
  }, [item, query]);

  return (
    <Button
      size="small"
      component={Link}
      disabled={isDisabled}
      to={{ pathname: '/send-swag/select-products', search: query.toString(), state: { from: location, item } }}
      className={className}
    >
      Send Swag
    </Button>
  );
};

const PayStorageButton = ({ item, className }) => {
  const location = useLocation();
  const isDisabled = item.stock.reduce((acc, size) => acc + size.quantity, 0) === 0;
  const query = useQueryParams();

  React.useEffect(() => {
    query.set('selected', item.id);
  }, [item, query]);

  return (
    <Button
      size="small"
      variant="primary"
      component={Link}
      disabled={isDisabled}
      to={{ pathname: '/storage-payment', search: query.toString(), state: { from: location, item } }}
      className={className}
    >
      Pay item Storage
    </Button>
  );
};

const getSelectedSearchParam = ids => {
  const query = new URLSearchParams();
  query.set('selected', ids);
  return query.toString();
};

const useReorderLink = ids => {
  const location = useLocation();
  return {
    pathname: '/reorder/select-products',
    search: getSelectedSearchParam(ids),
    state: { from: location }
  };
};

export {
  ProductColor,
  ProductColorV2,
  ProductImage,
  productImageTitle,
  ProductNameInput,
  ProductQuantities,
  SelectionMark,
  selectionMarkTitle,
  SendSwagButton,
  useReorderLink,
  PayStorageButton
};
