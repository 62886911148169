import React from 'react';
import { TextField } from '@swagup-com/components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core';
import { scrollBar } from '../shared/styles/commonStyles';
import PhoneInput from './PhoneInput';

const ReduxTextField = ({ placeholder, input, label, meta: { touched, error }, ...custom }) => (
  <TextField
    fullWidth
    placeholder={placeholder}
    multiline={input.name === 'message'}
    maxRows={3}
    error={(touched || input.value) && Boolean(error)}
    helperText={(touched || input.value) && (error || '')}
    {...input}
    {...custom}
    style={{ paddingTop: 5, paddingBottom: touched && Boolean(error) ? 0 : 23 }}
  />
);

const hideOverflow = props => props.totalItems * props.itemHeight >= window.innerHeight - 96;
const useStyles = makeStyles({
  selectMenu: {
    '& .MuiMenu-paper': {
      borderRadius: '20px',
      overflow: props => hideOverflow(props) && 'hidden',
      padding: props => hideOverflow(props) && '5px 10px 5px 0',
      height: props => hideOverflow(props) && '100%',
      '& ul': {
        width: '100% !important',
        height: '100%',
        ...scrollBar
      }
    }
  }
});

// Not used anymore, for now
const ReduxSelectField = ({
  input,
  label,
  children,
  meta: { touched, error },
  onSelectChange,
  className,
  variant,
  outerClassName,
  itemHeight = 45,
  totalItems,
  ...custom
}) => {
  const { value } = input;
  const classes = useStyles({ itemHeight, totalItems: totalItems || children.length });
  const SelectIcon = props => <KeyboardArrowDownIcon style={{ right: 25 }} {...props} />;
  return (
    <TextField
      fullWidth
      label={value === '' ? label : ''}
      select
      error={(touched || value) && Boolean(error)}
      helperText={(touched || value) && (error || '')}
      {...input}
      {...custom}
      style={{ paddingTop: 5, paddingBottom: touched && Boolean(error) ? 0 : 23 }}
      SelectProps={{
        MenuProps: { className: classes.selectMenu },
        IconComponent: SelectIcon
      }}
      InputLabelProps={{ shrink: false, style: { paddingTop: 6, color: '#bdbdbd' } }}
      onChange={event => {
        input.onChange(event);
        if (onSelectChange) {
          onSelectChange(event);
        }
      }}
    >
      {children}
    </TextField>
  );
};

// Not used anymore, for now
const ReduxPhoneField = ({ input, meta, label, country, placeholder, variant }) => (
  <PhoneInput {...input} meta={meta} label={label} country={country} placeholder={placeholder} variant={variant} />
);

export { ReduxTextField, ReduxSelectField, ReduxPhoneField };
