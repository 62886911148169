import React from 'react';
import { Grid, withStyles, Checkbox, Switch, FormControlLabel } from '@material-ui/core';

const BlueCheckbox = withStyles({
  root: {
    padding: 0,
    color: '#3577d4',
    '&$checked': { color: '#3577d4' }
  },
  checked: {}
})(Checkbox);

const GrayCheckbox = withStyles({
  root: {
    padding: 0,
    color: '#d3dbe5',
    '&$checked': { color: '#d3dbe5' }
  },
  checked: {}
})(Checkbox);

const BlueSwitch = withStyles({
  root: {
    overflow: 'visible'
  },
  switchBase: {
    color: '#B7BBBF',
    '&$checked': {
      color: '#3577d4'
    },
    '&$checked + $track': {
      backgroundColor: '#3577d4',
      opacity: 0.5
    }
  },
  checked: {},
  track: { backgroundColor: '#00000029', opacity: 0.8 },
  thumb: {
    boxShadow: 'none'
  }
})(Switch);

const CheckBoxPaymentMethod = ({ classes, method, selectedMethod, onSelection }) => (
  <Grid
    container
    alignItems="center"
    justifyContent="space-between"
    className={selectedMethod === method ? classes.paymentMethodButtonSelected : classes.paymentMethodButton}
    style={{ cursor: 'pointer' }}
    onClick={() => onSelection(method)}
  >
    <Grid item xs={8}>
      {method}
    </Grid>
    <Grid item xs={2}>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          selectedMethod === method ? (
            <BlueCheckbox checked value={selectedMethod} />
          ) : (
            <GrayCheckbox value={selectedMethod} />
          )
        }
      />
    </Grid>
  </Grid>
);

const GreenAntSwitch = withStyles(theme => ({
  root: {
    width: 32,
    height: 16,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    transform: 'translate(2px, 1px)',
    '&$checked': {
      transform: 'translate(16px, 1px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50'
      }
    }
  },
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

export { CheckBoxPaymentMethod, BlueSwitch, GrayCheckbox, BlueCheckbox, GreenAntSwitch };
