import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { Box } from '@material-ui/core';
import dayjs from 'dayjs';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { cleanShipmentGroup } from '../../../../../actions/shipmentGroupActions';
import { useOrder } from '../OrderContext';
import Loader from '../../../../global/Loader';
import useEditShipmentGroup from '../hooks/useEditShipmentGroup';
import Stepper from '../../../../shared/Stepper';
import TopBar from '../../../../shared/TopBar';
import SelectRecipients from './SelectRecipients';
import SelectProducts from './SelectProducts';
import useUnallocatedPerProduct from '../hooks/useUnallocatedPerProduct';
import { getQuantitiesBreakdown, joinQuantitiesBreakdowns, substractQuantitiesBreakdowns } from '../common/utilsOrder';
import { orderApi } from '../../../../../apis/swagup';
import { getUsedQuantitiesBySize } from '../../../../../helpers/utils';
import apiPaths from '../../../../../helpers/apiPaths';

const buildDirectoryOrders = directoryOrders =>
  directoryOrders
    .filter(d => !isEmpty(d.deliveryMethods))
    .map(d => ({
      directory: d.directory,
      proofs: d.proofs,
      delivery_method: d.deliveryMethods.find(dm => dm.selected).id,
      shipping_notes: d.shippingNotes,
      shipping_date: d.shippingDate ? dayjs(d.shippingDate).format('YYYY-MM-DD') : undefined
    }));

const buildShipmentGroup = (shipmentGroups, shipmentGroup) => ({
  name: `Shipment ${shipmentGroups.length + 1}`,
  directory_orders: buildDirectoryOrders(shipmentGroup.directoryOrders)
});

const buildWarehouseProofs = quantities =>
  quantities
    .filter(([, breakdown]) => Object.values(breakdown).some(qty => qty > 0))
    .map(([{ id }, breakdown]) => ({
      proof: id,
      sizes: Object.entries(breakdown)
        .map(([sizeId, quantity]) => ({ size: +sizeId, quantity }))
        .filter(size => size.quantity > 0)
    }));

const ShipmentCreation = () => {
  const { id, step, shipmentGroupId } = useParams();

  const { id: orderId, products, shipmentGroups, warehouseProofs } = useOrder();

  const shipment = useSelector(state => state.shipmentGroup);
  const isloading = useEditShipmentGroup(+shipmentGroupId);

  const hasShipmentGroupId = !Number.isNaN(+shipmentGroupId);
  const lastStep = `${hasShipmentGroupId ? 'save' : 'create'}-shipment`;
  const lastStepValue = `${hasShipmentGroupId ? 'Save' : 'Create'} Shipment`;
  const steps = [
    { step: 'select-products', label: 'Select Products' },
    { step: 'select-recipients', label: 'Select Recipients' },
    { step: lastStep, label: lastStepValue }
  ];

  const history = useHistory();
  React.useEffect(() => {
    if (!steps.map(s => s.step).includes(step)) history.replace(`/orders-requested/${id}`);
  });

  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      const path = history.location.pathname;
      const creatingShipmentRegex = new RegExp('/orders-requested/[0-9]+/.*', 'g');
      if (!path.includes('/import-contacts/') && !path.match(creatingShipmentRegex)) {
        dispatch(cleanShipmentGroup);
      }
    };
  }, [dispatch, history]);

  const queryClient = useQueryClient();

  const createShipmentGroupMutation = useMutation(orderApi.createShipmentGroup, {
    onMutate: () => queryClient.cancelQueries([apiPaths.shipmentGroups(id)])
  });

  const handleCreateShipmentThen = postSuccess => () =>
    createShipmentGroupMutation.mutate(
      { id, shipmentGroup: buildShipmentGroup(shipmentGroups, shipment) },
      {
        onSuccess: data => {
          queryClient.setQueryData([apiPaths.shipmentGroups(id)], prev => ({
            ...prev,
            results: [...prev.results, data]
          }));
          queryClient.invalidateQueries(apiPaths.opportunities);
          queryClient.invalidateQueries(apiPaths.opportunity(orderId));
          queryClient.invalidateQueries(apiPaths.shipmentGroups(id));
          postSuccess();
        }
      }
    );

  const selectedProducts = useSelector(state => state.shipmentGroup.products);
  const unallocated = useUnallocatedPerProduct();
  const warehouseMutation = useMutation(apiPaths.warehouseProofs(id), {
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries(apiPaths.opportunities),
        queryClient.invalidateQueries(apiPaths.warehouseProofs(id))
      ]);

      history.push({ pathname: `/orders-requested/${id}`, state: { showWarehouse: true } });
    }
  });
  const editShipmentGroupMutation = useMutation([apiPaths.shipmentGroups(id)], orderApi.editShipmentGroup, {
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries(apiPaths.opportunities),
        queryClient.invalidateQueries(apiPaths.shipmentGroups(id))
      ]);

      history.push(`/orders-requested/${id}`);
    }
  });
  const deleteMutation = useMutation(orderApi.deleteShipmentGroup, {
    onSettled: async () => {
      queryClient.cancelQueries([apiPaths.shipmentGroups(id)]);
      await queryClient.invalidateQueries(apiPaths.shipmentGroups(id));
      history.push(`/orders-requested/${id}`);
    }
  });

  const selectedProductsUnallocated = React.useMemo(
    () =>
      selectedProducts.map(p => [
        { id: p.id, warehouseProof: warehouseProofs.find(wp => wp.proof.id === p.id) },
        substractQuantitiesBreakdowns(unallocated[p.id].breakdown, getUsedQuantitiesBySize(p.id, [shipment]))
      ]),
    [selectedProducts, warehouseProofs, unallocated, shipment]
  );
  const hasUnallocated = selectedProductsUnallocated.some(([, breakdown]) =>
    Object.values(breakdown).some(value => value > 0)
  );

  const handleSkip = handleCreateShipmentThen(() => history.push(`/orders-requested/${id}`));

  const handleCreateShipment = () =>
    hasUnallocated ? history.push(`/orders-requested/${id}/create-shipment`) : handleSkip();

  const handleCreateAnotherShipment = handleCreateShipmentThen(() =>
    history.push(`/orders-requested/${id}/select-products`)
  );

  const handleSendToWarehouse = handleCreateShipmentThen(() => {
    const newWarehouseQuantities = selectedProductsUnallocated.map(([product, breakdown]) => [
      product,
      joinQuantitiesBreakdowns(getQuantitiesBreakdown(product.warehouseProof?.sizes || []), breakdown)
    ]);

    const warehousePayload = { id, proofs: buildWarehouseProofs(newWarehouseQuantities, warehouseProofs.length === 0) };

    warehouseMutation.mutate(warehousePayload);
  });

  const handleUpdateShipment = () =>
    editShipmentGroupMutation.mutate({
      opportunityId: id,
      shipmentGroupId,
      data: { directory_orders: buildDirectoryOrders(shipment.directoryOrders) }
    });

  const handleDeleteShipment = () => deleteMutation.mutate({ opportunityId: id, shipmentGroupId });

  const handleGoBack = hasShipmentGroupId ? () => history.push(`/orders-requested/${id}`) : history.goBack;

  const stepNumber = steps.findIndex(s => s.step === step);
  const mutations = [createShipmentGroupMutation, editShipmentGroupMutation, warehouseMutation, deleteMutation];
  const selectRecipientProps = {
    isLoading: mutations.some(mutation => mutation.isLoading),
    onCreate: hasShipmentGroupId ? handleUpdateShipment : handleCreateShipment,
    onCreateAnother: handleCreateAnotherShipment,
    onDelete: handleDeleteShipment,
    onSendToWarehouse: handleSendToWarehouse,
    onSkip: handleSkip
  };

  return (
    <Box height="100vh" display="flex" flexDirection="column" overflow="hidden">
      <TopBar onGoBack={handleGoBack}>
        <Stepper steps={steps} active={stepNumber} disablePrev={hasShipmentGroupId} />
      </TopBar>
      {isloading ? (
        <Loader />
      ) : (
        <Switch>
          <Route path="/orders-requested/:id/select-products">
            <SelectProducts products={products} />
          </Route>
          <Route path="/orders-requested/:id/select-recipients/:shipmentGroupId?">
            <SelectRecipients step="select-recipients" {...selectRecipientProps} />
          </Route>
          <Route path={`/orders-requested/:id/${lastStep}`}>
            <SelectRecipients step={lastStep} {...selectRecipientProps} />
          </Route>
        </Switch>
      )}
    </Box>
  );
};

export default ShipmentCreation;
