const styles = () => ({
  MultiShippingCardOverview: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    paddingTop: 43,
    marginBottom: 26,
    maxWidth: 680
  },
  ItemContainer: {
    padding: '18px 0'
  },
  CardHeaderBorder: {
    padding: '0 43px 20px 43px',
    borderBottom: 'solid 1px #ebecf5',
    marginBottom: 18
  },
  CardHeader: {
    padding: '0 43px 20px 43px',
    marginBottom: 18
  },
  CardContent: {
    padding: '0 43px 20px 43px'
  },
  ColapseContentContainer: {
    maxWidth: 360
  },
  HeaderTitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    color: '#a2abb8',
    padding: '14px 0'
  },
  HeaderContent: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.43,
    color: '#434c5f'
  },
  SmallItemImg: {
    width: 60,
    height: 60,
    borderRadius: 5,
    border: 'solid 0.8px #a2abb8',
    // margin: '0 auto',
    padding: 4,
    '& img': {
      width: 50,
      height: 50
    }
  },
  AmountToShip: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.43,
    color: '#434c5f',
    paddingTop: 14,
    // paddingRight: 15,
    maxWidth: 60
  },
  ItemDescription: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434c5f'
  },
  ItemSpecs: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    color: '#434c5f'
  }
});
export default styles;
