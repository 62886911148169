/* eslint-disable camelcase */
import * as React from 'react';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';

import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { CircularProgress, Divider, Grid, makeStyles, Slide, TextField, FormControl } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Button } from '@swagup-com/components';
import { useParams } from 'react-router-dom';
import EditContact from './EditContact';
import ShippingMethodButton from './ShippingMethodButton';
import QuantitiesSelection from './QuantitiesSelection';
import InformationRow from '../common/InformationRow';
import Drawer from '../common/Drawer';
import ArrowTooltip from '../common/Tooltip';
import { useInSendSwagPath, useShippingPricesQuery } from '../hooks';
import {
  updateShipmentGroupDirectoryOrder,
  updateShipmentGroupRecipient,
  validateDirectoryOrders
} from '../../../../../actions/shipmentGroupActions';
import {
  getUsedQuantitiesBySize,
  getEmployeeShipAddress,
  getFormattedPhoneNumber,
  joinFields,
  onlyNaturalNumberOrEmpty,
  sumByProperty,
  sumByQuantity
} from '../../../../../helpers/utils';
import {
  addressFields,
  getQuantitiesBreakdown,
  substractQuantitiesBreakdowns,
  doesInactiveSizeHasQuantity,
  mapSizesWithActiveField
} from '../common/utilsOrder';
import apiPaths from '../../../../../helpers/apiPaths';
import CalendarModalTool from '../../../../global/CalendarModalTool';
import styles from './EditRecipient.styles';
import { minimumShippingDate } from '../../../../../helpers/commonDateFunctions';
import { useShippingCutoffHour, useSizes } from '../../../../../hooks';
import InactiveSizesAlert from '../common/InactiveSizesAlert';
import { useOrder } from '../OrderContext';
import { useMembership } from '../../../../../contexts/membershipContext';

const maxNoteLength = 255;

const useStyles = makeStyles(styles);

const getTotalQuantity = selectedQuantities =>
  sumBy(Array.from(selectedQuantities), ([, sizes]) => sumByQuantity(sizes));
const buildSelectedQuantities = (shipmentGroup, directoryOrder) =>
  shipmentGroup?.products.map(p => {
    const proof = directoryOrder?.proofs.find(pr => pr.proof === p.id);
    return proof ? [proof.proof, proof.sizes] : [p.id, []];
  });

const getSaveBtnTooltipText = (isLoading, totalQuantity, exceededStock, currentMethod, shippingMethods) => {
  if (!isLoading) {
    if (totalQuantity === 0) return 'Quantity selected must be greater than zero';
    if (!shippingMethods.find(Boolean)) return 'No shipping method available';
    if (exceededStock) return "One or more products don't have enough inventory to cover this recipient";
    if (currentMethod === -1 || !shippingMethods.find(method => method?.id === currentMethod))
      return 'A shipping method must be selected';
  }
  return '';
};

const RecipientForm = ({ recipientId, limitedStock, onClose, onEditContact }) => {
  const shipmentGroup = useSelector(state => state.shipmentGroup);
  const recipient = shipmentGroup.recipients.find(r => r.id === recipientId);
  const directoryOrder = shipmentGroup.directoryOrders.find(dOrder => dOrder.directory === recipient.id);
  const [selectedQuantitiesRef, setSelectedQuantities] = React.useState([
    new Map(buildSelectedQuantities(shipmentGroup, directoryOrder))
  ]);
  const [selectedQuantities] = selectedQuantitiesRef;
  const [currentMethod, setCurrentMethod] = React.useState(
    directoryOrder?.deliveryMethods?.find(dm => dm.selected)?.id ?? -1
  );
  const [shippingNotes, setShippingNotes] = React.useState(directoryOrder?.shippingNotes ?? '');

  const isLimitedStock = useInSendSwagPath();
  const shippingCutoffHour = useShippingCutoffHour();
  const [shippingDate, setShippingDate] = React.useState(
    isLimitedStock ? directoryOrder?.shippingDate ?? minimumShippingDate(new Date(), shippingCutoffHour) : undefined
  );

  const { shipmentGroupId } = useParams();
  const areQuantitiesPristine = React.useRef(true);

  const qtyLeftPerProduct = React.useMemo(
    () =>
      limitedStock &&
      shipmentGroup.products.map(product => {
        const otherShipments = shipmentGroup.directoryOrders.filter(dOrder => dOrder.directory !== recipient.id);
        return substractQuantitiesBreakdowns(
          getQuantitiesBreakdown(product.sizes),
          getUsedQuantitiesBySize(product.id, [{ directoryOrders: otherShipments }])
        );
      }),
    [limitedStock, shipmentGroup, recipient]
  );
  const { shipmentGroups } = useOrder() || {};
  const permanentQtyLeftPerProduct = React.useMemo(
    () =>
      shipmentGroup.products.map(product => {
        const otherShipments = shipmentGroup.directoryOrders.filter(dOrder => dOrder.directory !== recipient.id);
        return substractQuantitiesBreakdowns(
          getQuantitiesBreakdown(product.sizes),
          getUsedQuantitiesBySize(product.id, limitedStock ? [{ directoryOrders: otherShipments }] : shipmentGroups)
        );
      }),
    [limitedStock, shipmentGroup, shipmentGroups, recipient]
  );

  const { data, isLoading, status } = useShippingPricesQuery(shipmentGroup.products, recipient, selectedQuantitiesRef);
  const standardMethod = data?.delivery_methods?.find(m => m.name === 'Standard');
  const expressMethod = data?.delivery_methods?.find(m => m.name === 'Express');

  const handleChangeQuantity = productId => sizeId => e => {
    const value = onlyNaturalNumberOrEmpty(String(e.target.value));
    const currentQuantities = selectedQuantities.get(productId);
    const newQuantity = value ? Number(value) : '';

    selectedQuantities.set(
      productId,
      currentQuantities.find(q => q.size === sizeId)
        ? currentQuantities.map(s => (s.size === sizeId ? { ...s, quantity: newQuantity } : s))
        : [...currentQuantities, { size: sizeId, quantity: newQuantity }]
    );
    setSelectedQuantities([selectedQuantities]);
    areQuantitiesPristine.current = false;
  };

  const handleResetQuantity = productId => () => {
    const currentQuantities = selectedQuantities.get(productId);
    if (!isEmpty(currentQuantities)) {
      selectedQuantities.set(productId, []);
      setSelectedQuantities([selectedQuantities]);
    }
  };

  const handleResetAllQuantities = () => {
    Array.from(selectedQuantities).forEach(([key]) => selectedQuantities.set(key, []));
    setSelectedQuantities([selectedQuantities]);
  };

  const handleNoteChange = debounce(value => setShippingNotes(value), 50);

  const dispatch = useDispatch();
  const handleSaveQuantity = () => {
    const proofs = Array.from(selectedQuantities)
      .map(([proof, sizes]) => ({
        proof,
        sizes: sizes.filter(s => s.quantity > 0)
      }))
      .filter(proof => !isEmpty(proof.sizes));
    const deliveryMethods = (data?.delivery_methods ?? directoryOrder?.deliveryMethods).map(dm => ({
      ...dm,
      selected: dm.id === currentMethod
    }));
    const newDirectoryOrder = { directory: recipient.id, proofs, deliveryMethods, shippingNotes, shippingDate };

    dispatch(updateShipmentGroupDirectoryOrder(newDirectoryOrder, true));
    dispatch(validateDirectoryOrders([], isLimitedStock, false));
    onClose();
  };

  React.useEffect(() => {
    if (areQuantitiesPristine.current) {
      setSelectedQuantities([new Map(buildSelectedQuantities(shipmentGroup, directoryOrder))]);
    }
    if (currentMethod === -1) {
      setCurrentMethod(directoryOrder?.deliveryMethods?.find(dm => dm.selected)?.id ?? -1);
    }
  }, [shipmentGroup, directoryOrder, currentMethod]);

  const totalQuantity = getTotalQuantity(selectedQuantities);
  const exceededStock =
    limitedStock &&
    shipmentGroup.products.some((product, index) => {
      const qtyLeft = qtyLeftPerProduct[index];
      return selectedQuantities.get(product.id).some(({ size, quantity }) => qtyLeft[size] < quantity);
    });
  const saveBtnTooltipMessage = getSaveBtnTooltipText(isLoading, totalQuantity, exceededStock, currentMethod, [
    standardMethod,
    expressMethod
  ]);
  const disabled = saveBtnTooltipMessage !== '' || isLoading;
  const classes = useStyles();

  const shipmentGroupHasInactiveSizes = shipmentGroup.products.some(p =>
    selectedQuantities.get(p.id).some(doesInactiveSizeHasQuantity(p.sizes))
  );

  const { data: apiSizes } = useSizes();

  const {
    currentMembership: { shipping_discount, isFreeTier }
  } = useMembership();

  const isEditingShipmentGroup = `${shipmentGroupId}` === `${shipmentGroup?.id}`;

  const addRemainingItems = () => {
    const isOneSize = sizes => sizes.length === 1 && sizes[0].size.name === 'One Size';
    shipmentGroup.products.forEach((p, index) => {
      const onChangeQty = handleChangeQuantity(p.id);
      const qtyLeft = permanentQtyLeftPerProduct[index];
      const quantities = selectedQuantities.get(p.id);
      if (isOneSize(p.sizes)) {
        const currentQuantity = isEditingShipmentGroup ? quantities[0]?.quantity : 0;
        const fullQuantity = currentQuantity + qtyLeft[p.sizes[0].size.id];
        onChangeQty(p.sizes[0].size.id)({ target: { value: fullQuantity } });
      } else {
        const sizes = mapSizesWithActiveField(apiSizes, p);
        sizes.forEach(size => {
          const currentQuantity = isEditingShipmentGroup ? quantities.find(s => s.size === size.id)?.quantity || 0 : 0;
          const fullQuantity = currentQuantity + qtyLeft[size.id];
          onChangeQty(size.id)({ target: { value: fullQuantity } });
        });
      }
    });
  };

  return (
    <Grid container direction="column" style={{ flexBasis: '100%' }}>
      <Grid container>
        <p className={classes.title}>Shipment Details</p>
      </Grid>
      <Grid container>
        <p className={classes.subtitle} style={{ marginTop: 36 }}>
          Recipient
        </p>
      </Grid>
      <InformationRow
        className={classes.recipientSummary}
        name={
          <>
            {joinFields([recipient.first_name, recipient.last_name], ' ')}
            {recipient.size && (
              <>
                , <span style={{ color: '#787b80' }}>{recipient.size.name}</span>
              </>
            )}
          </>
        }
        info={getEmployeeShipAddress(recipient)}
      >
        <Button variant="text" className={classes.button} onClick={onEditContact}>
          Edit Recipient <EditOutlinedIcon className={classes.editIcon} />
        </Button>
      </InformationRow>
      <Divider className={classes.fullwidthDivider} style={{ marginTop: 34 }} />
      <Grid container style={{ marginTop: 36, marginBottom: 16 }} alignItems="center">
        <Grid item xs>
          <p className={classes.subtitle}>Selected Products</p>
        </Grid>
        <Grid item>
          <Button variant="text" className={classes.addAllRemaining} onClick={addRemainingItems}>
            Add All Remaining Items
          </Button>
        </Grid>
      </Grid>
      {shipmentGroupHasInactiveSizes && !isLimitedStock && <InactiveSizesAlert />}
      {shipmentGroup.products.map((p, index) => (
        <Slide key={p.id} direction="up" in={selectedQuantities.has(p.id)} mountOnEnter unmountOnExit>
          <Grid container>
            <QuantitiesSelection
              qtyLeft={limitedStock ? qtyLeftPerProduct[index] : undefined}
              qtyLeftForAddItems={permanentQtyLeftPerProduct[index]}
              recipient={recipientId}
              errorMessage={
                recipient.shipping_country !== 'US' && !p.product.can_ship_international ? (
                  <span>
                    International delivery is not available for this product. Please refer to our{' '}
                    <a
                      href="https://support.swagup.com/en/articles/6952397-international-shipments-restricted-items"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Shipping Policy*
                    </a>{' '}
                    for more information.
                  </span>
                ) : (
                  ''
                )
              }
              product={p}
              quantities={selectedQuantities.get(p.id)}
              onChangeQty={handleChangeQuantity(p.id)}
              onReset={handleResetQuantity(p.id)}
              hideInactiveWarnings={isLimitedStock}
              isEditingShipmentGroup={isEditingShipmentGroup}
            />
          </Grid>
        </Slide>
      ))}
      <Grid container style={{ marginTop: 24 }}>
        <p className={classes.subtitle}>Shipping Method</p>
      </Grid>
      <Grid container justifyContent="space-between" style={{ height: 84, marginTop: 16 }}>
        {isLoading ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress style={{ width: 50, height: 50 }} />
          </Grid>
        ) : (
          <>
            <ShippingMethodButton
              active={currentMethod === standardMethod?.id}
              disabled={standardMethod === undefined}
              showMessage={totalQuantity > 0 && status === 'success'}
              price={sumByProperty(standardMethod?.prices.breakdown ?? [], 'total_price')}
              originalPrice={
                isFreeTier ? 0 : sumByProperty(standardMethod?.prices.breakdown ?? [], 'total_price_without_discount')
              }
              discount={shipping_discount}
              time={standardMethod?.turnaround_time}
              name="Standard"
              onClick={standardMethod ? () => setCurrentMethod(standardMethod?.id) : undefined}
            />
            <ShippingMethodButton
              active={currentMethod === expressMethod?.id}
              disabled={expressMethod === undefined}
              showMessage={totalQuantity > 0 && status === 'success'}
              price={sumByProperty(expressMethod?.prices.breakdown ?? [], 'total_price')}
              originalPrice={
                isFreeTier ? 0 : sumByProperty(expressMethod?.prices.breakdown ?? [], 'total_price_without_discount')
              }
              discount={shipping_discount}
              time={expressMethod?.turnaround_time}
              name="Express"
              onClick={expressMethod ? () => setCurrentMethod(expressMethod?.id) : undefined}
            />
          </>
        )}
      </Grid>
      <Divider className={classes.fullwidthDivider} style={{ marginTop: 34 }} />

      {isLimitedStock && (
        <>
          <Grid container>
            <p className={classes.subtitle}>Shipping Date</p>
          </Grid>
          <Grid container style={{ marginTop: 16 }}>
            <FormControl>
              <CalendarModalTool
                selectedDate={shippingDate}
                onSubmit={setShippingDate}
                variant="rounded"
                submitText="Select"
              />
            </FormControl>
          </Grid>
          <Divider className={classes.fullwidthDivider} style={{ marginTop: 34 }} />
        </>
      )}

      <Grid container>
        <p className={classes.subtitle}>Shipping Notes</p>
      </Grid>
      <Grid container className={classes.shippingNoteContainer}>
        <TextField
          className={classes.shippingNote}
          defaultValue={shippingNotes}
          onChange={e => handleNoteChange(e.target.value)}
          placeholder="Write your shipping notes here"
          minRows={3}
          maxRows={3}
          multiline
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.textAreaResize },
            inputProps: { maxLength: maxNoteLength }
          }}
        />

        <p className={classes.notesLengthCounter}>
          {shippingNotes.length} / {maxNoteLength}
        </p>
      </Grid>

      <Grid item container alignItems="flex-end" xs>
        <Grid container justifyContent="space-between" alignItems="center" style={{ paddingTop: 44 }}>
          <ArrowTooltip title={saveBtnTooltipMessage}>
            <Button
              variant="primary"
              size="medium"
              className={classes.saveButton}
              onClick={handleSaveQuantity}
              disabled={disabled}
            >
              Save ({totalQuantity})
            </Button>
          </ArrowTooltip>
          <Button
            variant="text"
            className={classes.resetButton}
            onClick={handleResetAllQuantities}
            style={{ fontSize: 14 }}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const EditRecipient = ({ recipientId, limitedStock, open, onOpen, onClose }) => {
  const recipient = useSelector(state => state.shipmentGroup.recipients.find(r => r.id === recipientId));
  const [isEditDrawerOpen, setIsEditDrawerOpen] = React.useState(false);

  const toggleEditDrawer = fn => () => {
    setIsEditDrawerOpen(prev => !prev);
    fn();
  };

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const handleSaveContact = contact => {
    dispatch(updateShipmentGroupRecipient(contact));
    queryClient.invalidateQueries(apiPaths.contacts);
    toggleEditDrawer(onOpen)();
  };

  const recipientData = recipient
    ? {
        ...pick(recipient, [...addressFields, 'id', 'first_name', 'last_name', 'company_name', 'title', 'email']),
        phone_number: getFormattedPhoneNumber(recipient.phone_number, recipient.shipping_country),
        size: recipient.size?.id ?? ''
      }
    : undefined;

  const classes = useStyles();
  return (
    <>
      <Drawer open={open} onClose={onClose} classes={{ root: classes.drawerRoot, paper: classes.drawerPaper }}>
        <RecipientForm
          recipientId={recipientId}
          limitedStock={limitedStock}
          onClose={onClose}
          onEditContact={toggleEditDrawer(onClose)}
        />
      </Drawer>
      <EditContact
        open={isEditDrawerOpen}
        onClose={toggleEditDrawer(onOpen)}
        onSuccess={handleSaveContact}
        defaultValues={recipientData}
      />
    </>
  );
};

export default EditRecipient;
