import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { addEmployee } from '../../../actions';
import tags from '../../../apis/seoTags';
import styles from './styles/EmployeeFormContainer';
import EmployeeFormContainer from './EmployeeFormContainer';
import BackLinkPageTitle from '../../shared/BackLinkPageTitle';

const useStyles = makeStyles(styles);

const EmployeeAdd = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { leftBarNavigation } = useFlags();

  const from = state?.from;
  const topRow = (
    <Grid container>
      <BackLinkPageTitle
        title="Add New Contact"
        link={from || (leftBarNavigation ? '/contacts' : '/shipments/contacts')}
      />
    </Grid>
  );

  const saveEmployee = data => dispatch(addEmployee({ ...data, size: data.size || undefined }));

  return (
    <div className={classes.container}>
      <Grid container justifyContent="center">
        <Grid item className={classes.center}>
          <EmployeeFormContainer saveEmployee={saveEmployee} topRow={topRow} tag={tags.addEmployee} classes={classes} />
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeeAdd;
