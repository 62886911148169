import { scrollBar } from '../../../shared/styles/commonStyles';

export default () => ({
  dialogPaper: {
    borderRadius: 10,
    width: '100%',
    maxWidth: 780,
    maxHeight: '99%'
  },
  dialogTitleRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    padding: '30px 0 20px 0'
  },
  dialogTitle: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    color: '#434d5c'
  },
  dialogTitleCloseRoot: {
    position: 'absolute',
    top: 23,
    right: 28,
    '& svg': {
      color: '#434d5c',
      fontSize: 20
    }
  },
  dialogContentRoot: {
    ...scrollBar,
    padding: '0 49px 0 54px',
    marginRight: 5,
    minHeight: 50,
    '& .MuiGrid-root': { margin: 0 }
  },
  dialogActionsRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 0 45px 0'
  },
  dialogActionsButtonRoot: {
    padding: '22px 55px',
    borderRadius: 32,
    textTransform: 'none',
    lineHeight: 'normal',
    backgroundColor: '#3577d4',
    '&:hover': { backgroundColor: '#2f6abd' }
  },
  dialogActionsButtonLabel: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    color: '#ffffff'
  },
  newPaymentProfileButton: {
    textTransform: 'none',
    color: '#3577d4',
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    height: 50,
    '&:hover': { backgroundColor: 'transparent' }
  }
});
