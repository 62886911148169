const styles = {
  input: {
    padding: '18px 28px'
  },
  indeterminate: {
    color: '#3577d4'
  },
  actionsText: {
    fontFamily: 'Gilroy-SemiBold',
    color: '#434d5c',
    lineHeight: '12px'
  },
  checkbox: {
    padding: 0,
    paddingRight: 15,
    paddingBottom: 0,
    marginTop: -2
  },
  select: {
    width: 180,
    paddingTop: 0,
    paddingBottom: 0
  },
  optionContainer: {
    height: 32,
    marginLeft: 40,
    display: 'flex',
    alignItems: 'center'
  }
};

export default styles;
