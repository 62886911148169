import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography, CircularProgress, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDropzone } from 'react-dropzone';
import { Button } from '@swagup-com/components';
import log from '../../logger';
import { s3 } from '../../helpers/utils';
import csvProcessesApi from '../../apis/swagup/csvProcesses';
import { useAsync, useProfile } from '../../hooks';
import Loader from '../global/Loader';
import { Img } from '../global/ImgUtils';
import { Container, Footer } from '../layouts/FullscreenStepper';
import { uploadDone, uploadInProgress, uploadStages } from './uploaderUtils';
import ErrorAlert from '../shared/ErrorAlert';
import styles from './styles/UploadContacts';

const Dropzone = ({ classes, onDrop }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: '.csv, text/csv'
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignContent="center"
      className={classes.dropzone}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Img src="/images/addContact/csv-icon-62.png" className={classes.dropzoneImage} />
      <Typography variant="h6" style={{ lineHeight: 0.7 }}>
        Drag your CSV here
      </Typography>
      <Grid item style={{ marginTop: 20 }}>
        <Typography variant="body2" component="span" style={{ color: '#1f1d1d' }}>
          or&nbsp;
        </Typography>
        <Button variant="text" onClick={open} className={classes.uploadBtn}>
          upload from your computer
        </Button>
      </Grid>
    </Grid>
  );
};

const FileDetails = ({ classes, name, size, transferTime, progress, uploadStage, onRemove }) => (
  <Grid container justifyContent="space-between" alignItems="center" className={classes.uploadedContainer}>
    <Grid item container alignItems="center" style={{ width: 'unset' }}>
      <Grid item container justifyContent="center" alignItems="center" className={classes.imageContainer}>
        <Img src="/images/addContact/csv-icon-62.png" width={36} height={36} />
      </Grid>
      <Grid item className={classes.fileDetails}>
        <Typography variant="body1" className="name">
          {name}
        </Typography>
        <Typography variant="body2">
          {size} - {uploadStage === uploadStages.inProgress ? 0 : transferTime} seconds
        </Typography>
      </Grid>
    </Grid>
    {uploadStage === uploadStages.inProgress ? (
      <CircularProgress variant="static" value={progress} size={20} />
    ) : (
      <IconButton disableRipple className={classes.deleteBtn} onClick={onRemove}>
        <DeleteOutlineIcon />
      </IconButton>
    )}
  </Grid>
);

const useStyles = makeStyles(styles);
const bytesInMB = 1024 * 1024;

const UploadContacts = ({ onNextStep, onPreviousStep }) => {
  const [file, setFile] = useState();
  const [fileS3Location, setFileS3Location] = useState();
  const [uploadStage, setUploadStage] = useState(uploadStages.stopped);
  const [transferTime, setTransferTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState();
  const [processFile, processResponse, isPending, processError] = useAsync(csvProcessesApi.create);
  const classes = useStyles({ uploaded: uploadStage === uploadStages.done });
  const [error, setError] = useState();

  const { data: profile } = useProfile();

  const onDrop = React.useCallback(
    files => {
      log.debug('ImageUpload onDrop files:', files);
      setError(undefined);
      const [newFile] = files;
      if (newFile) {
        setUploadError(undefined);
        setFile(newFile);
        setUploadStage(uploadStages.inProgress);
        s3.upload({ Key: `${profile.id}-${newFile.name}`, Body: newFile })
          .on('httpUploadProgress', event => uploadInProgress(event, setProgress, setUploadStage, setTransferTime))
          .send((err, data) => {
            if (err) setUploadError(err);
            uploadDone(err, data, setTransferTime, setFileS3Location, setProgress, setUploadStage);
          });
      }
    },
    [profile.id]
  );

  useEffect(() => {
    if (uploadError)
      setError({
        message: 'There was an error while uploading the CSV',
        action: () => onDrop([file])
      });
  }, [uploadError, file, onDrop]);

  useEffect(() => {
    if (processError) {
      setError({
        message:
          processError?.status === 400
            ? 'This CSV file appears to be empty or does not have a valid format'
            : 'There was an error while processing the CSV',
        action:
          processError?.status === 400
            ? undefined
            : () => {
                setError(undefined);
                processFile(fileS3Location);
              }
      });
    }
  }, [processError, processFile, fileS3Location]);

  useEffect(() => {
    if (processResponse) {
      const { id, headers_translation: headersTranslation } = processResponse.data;
      onNextStep(id, { headersTranslation });
    }
  }, [processResponse, onNextStep]);

  const sizeCalc = () => {
    log.debug('File: ', file);
    const totalBytes = file.size || 0;
    return totalBytes < bytesInMB ? `${Math.floor(totalBytes / 1024)}KB` : `${Math.floor(totalBytes / bytesInMB)}MB`;
  };

  const removeFile = () => {
    setUploadStage(uploadStages.stopped);
    setError(undefined);
    setProgress(0);
    setTransferTime(0);
  };

  const canContinue = fileS3Location && uploadStage === uploadStages.done && !error;

  const tryAgain = error?.action ? (
    <Button variant="text" onClick={error.action} className={classes.retryBtn}>
      Try again
    </Button>
  ) : null;

  return (
    <>
      <Container direction="column">
        {isPending && <Loader />}
        <Typography variant="h4">Upload CSV</Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Drag and drop your CSV file or select a file from your computer. The upload process can take a few seconds.
        </Typography>
        {uploadStage === uploadStages.stopped ? (
          <Dropzone classes={classes} onDrop={onDrop} />
        ) : (
          <FileDetails
            classes={classes}
            name={file.name}
            size={sizeCalc()}
            progress={progress}
            uploadStage={uploadStage}
            transferTime={transferTime}
            onRemove={removeFile}
          />
        )}
        {error && <ErrorAlert error={error.message} action={tryAgain} style={{ marginTop: 36 }} />}
      </Container>
      <Footer onContinue={canContinue && (() => processFile(fileS3Location))} onPrevious={() => onPreviousStep()} />
    </>
  );
};

export default UploadContacts;
