import React, { useState, memo } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import memoize from 'memoize-one';
import { withStyles, Grid, Paper, Button } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { FixedSizeList, areEqual } from 'react-window';
import handleImg from '../../helpers/handleImgHelper';
import { Img } from '../global/ImgUtils';
import { getEmployeeShipAddress, joinFields, isOneSize } from '../../helpers/utils';
import styles from './styles/OrderOverview';

const Li = memo(function Li({ style, data, index }) {
  const hideDelete = useLocation().state?.hideDelete;
  const [hovered, setHovered] = useState(false);
  const { employees, onDeselectEmployee, oneSize, classes } = data;
  const employee = employees[index];

  return (
    <Grid
      container
      style={style}
      className={classes.listItem}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Grid item xs={10}>
        <p className={classes.employeeName}>
          {joinFields([employee.first_name, employee.last_name], ' ')}
          {!oneSize && `, ${employee.size.name}`}
        </p>
        <p className={classes.employeeAddress}>{getEmployeeShipAddress(employee)}</p>
      </Grid>
      <Grid container item xs={2} justifyContent="flex-end">
        {hovered && !hideDelete && (
          <Button
            variant="contained"
            disableElevation
            disableRipple
            disableFocusRipple
            className={clsx(classes.deleteButton, classes.squareButton)}
            onClick={() => onDeselectEmployee(employee.shippingItemId)}
          >
            <CloseIcon className={classes.deleteButtonIcon} />
          </Button>
        )}
      </Grid>
    </Grid>
  );
}, areEqual);

const createItemData = memoize((employees, onDeselectEmployee, oneSize, classes) => ({
  employees,
  onDeselectEmployee,
  oneSize,
  classes
}));

const itemSize = 78;
const getRealHeight = (employees, cardHeight, productSectionHeight) => {
  const totalEmployees = employees.length > 3 ? employees.length : 3;
  const employeesTotalHeight = itemSize * totalEmployees;
  const totalHeight = employeesTotalHeight + 91 + productSectionHeight;
  if (totalHeight < cardHeight) return totalHeight;
  return cardHeight;
};

const OrderOverview = ({ classes, employees, product, removeEmployee, cardHeight }) => {
  const [productContainerRef, setProductContainerRef] = useState({ clientHeight: 0 });
  const [isListExpanded, setIsListExpanded] = useState(false);

  const itemData = createItemData(employees, removeEmployee, isOneSize(product.stock), classes);

  const localCardHeight = isListExpanded
    ? getRealHeight(employees, cardHeight, productContainerRef.clientHeight)
    : productContainerRef.clientHeight;
  const listSectionHeight = localCardHeight - productContainerRef.clientHeight;

  return (
    <>
      <p className={classes.subTitle}>Order overview</p>
      <Paper elevation={0} className={classes.card} style={{ height: localCardHeight }}>
        <Grid container className={classes.cardSection} ref={setProductContainerRef}>
          <Grid item xs={10}>
            <p className={classes.commonSubTitle}>Product</p>
            <Grid container>
              <Img src={handleImg(product.image, 78, 78)} width={70} height={70} className={classes.productImg} />
              <Grid item style={{ width: 'calc(100% - 90px)', marginLeft: 20 }}>
                <p className={classes.productName}>{product.name}</p>
                <p className={classes.productCommon}>
                  Color: <span className={classes.productTextDark}>{product.theme_color || 'Custom'}</span>
                </p>
                <p className={classes.productCommon}>
                  Qty: <span className={classes.productTextDark}>{employees.length}</span>
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                disableElevation
                disableRipple
                disableFocusRipple
                className={clsx(classes.expandCollapseButton, classes.squareButton)}
                onClick={() => setIsListExpanded(!isListExpanded)}
              >
                {isListExpanded ? (
                  <ExpandLess className={classes.expandCollapseButtonIcon} />
                ) : (
                  <ExpandMore className={classes.expandCollapseButtonIcon} />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {isListExpanded && (
          <Grid container className={classes.cardSection} style={{ height: listSectionHeight }}>
            <p className={classes.commonSubTitle}>Recipients</p>
            <FixedSizeList
              width="100%"
              height={listSectionHeight - 91}
              itemData={itemData}
              itemCount={employees.length}
              itemSize={itemSize}
              itemKey={(index, data) => data.employees[index].id}
              className={classes.employeesList}
            >
              {Li}
            </FixedSizeList>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default withStyles(styles)(OrderOverview);
