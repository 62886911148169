export default {
  title: {
    fontFamily: 'Gilroy',
    fontWeight: 700,
    fontSize: 24,
    color: '#0b1829',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: 1,
    minWidth: 0,
    padding: 0,
    marginTop: 36,
    '& .ScrollbarsCustom-Content': {
      paddingRight: '20px !important'
    }
  },
  addButton: {
    minWidth: 164,
    height: 56,
    letterSpacing: 0
  }
};
