import { SET_SHIPPING_ORDER_COST } from '../actions/types';

const ShippingOrderCostReducer = (state = 0, action) => {
  switch (action.type) {
    case SET_SHIPPING_ORDER_COST:
      return action.payload;

    default:
      return state;
  }
};

export default ShippingOrderCostReducer;
