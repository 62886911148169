import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import React from 'react';
// import ArrowTooltip from '../../pages/orders/requested/common/Tooltip';
import { CustomTextField, ImageSelector } from '../storeCommon';
import styles from '../styles/storefrontsHome';

const useStyles = makeStyles(styles);

const PageBasics = ({ store, setStoreSettings, company, handleFileUpload, artworkLoader }) => {
  // const onSettingsChange = ({ target: { value, name } }) => setPage({ ...page, [name]: value });
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body3RegularInter" className={classes.inputLabel}>
          Shop Name
          {/* {page.hasBeenPublished ? <span className={classes.readonly}>Read only</span> : <strong>*</strong>} */}
        </Typography>
        <CustomTextField
          key={store.hasBeenPublished ? 'customStoreName' : 'storeName'}
          defaultValue={store.generalSettings.projectName}
          className={classes.inputText}
          placeholder="Store Name"
          value={
            store.hasBeenPublished
              ? store.generalSettings.customStoreName || store.generalSettings.storeName
              : store.generalSettings.storeName
          }
          name={store.hasBeenPublished ? 'customStoreName' : 'storeName'}
          onChange={event =>
            setStoreSettings(
              store.hasBeenPublished ? { customStoreName: event.target.value } : { storeName: event.target.value },
              'generalSettings'
            )
          }
          fullWidth
          // disabled={page.hasBeenPublished}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 20 }}>
        <Typography variant="body3RegularInter" className={classes.inputLabel}>
          Company Name
        </Typography>
        <CustomTextField
          key="companyName"
          className={classes.inputText}
          placeholder="Company Name"
          value={store.generalSettings.companyDisplayName || ''}
          name="companyDisplayName"
          onChange={event => setStoreSettings({ companyDisplayName: event.target.value }, 'generalSettings')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 20 }}>
        <Typography variant="body3RegularInter" className={classes.inputLabel}>
          Announcement Bar
        </Typography>
        <CustomTextField
          key="announcement"
          className={classes.inputText}
          placeholder="Announcement"
          value={store.generalSettings.announcement}
          name="announcement"
          onChange={event => setStoreSettings({ announcement: event.target.value }, 'generalSettings')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 24 }}>
        <Grid container>
          <Grid item>
            <div>
              <Typography variant="body3RegularInter" className={classes.inputLabel}>
                Choose Logo
              </Typography>
            </div>
          </Grid>
        </Grid>
        <ImageSelector
          imageName={store.clientLogo || company.logo}
          selected={!!store.clientLogo}
          onSelect={selected => setStoreSettings({ logo: selected ? '' : company.logo })}
          artworkLoader={artworkLoader}
          url={store.clientLogo}
          property="visualSettings.logo"
          handleFileUpload={handleFileUpload}
        />
      </Grid>
    </Grid>
  );
};

export default PageBasics;
