import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {
  createTheme,
  ThemeProvider,
  Grid,
  IconButton,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import lightBlue from '@material-ui/core/colors/lightBlue';
import PropTypes from 'prop-types';
import dayjsUtils from '@date-io/dayjs';
import { dayjs } from '../../helpers/dayjs';

import { CustomWidthButtonInverse } from '../buttons';
import { scrollBar } from '../shared/styles/commonStyles';
import log from '../../logger';

const customTheme = createTheme({
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        padding: '16px 16px',
        paddingTop: 4,
        '& p': {
          color: '#0B1829',
          fontSize: 20
        }
      },
      transitionContainer: {
        height: 28
      },
      daysHeader: {
        padding: '0px 16px'
      },
      dayLabel: {
        margin: 'auto'
      },
      iconButton: {
        color: '#0B1829',
        backgroundColor: '#F2F6FD',
        borderRadius: 10,
        fontSize: 12,
        padding: '8px 8px',
        fontFamily: 'Gilroy !important',
        '&:hover': {
          color: '#3577D4',
          backgroundColor: '#ebf1fb'
        }
      }
    },
    MuiSvgIcon: {
      root: {
        height: 16,
        width: 16
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: 272,
        borderTop: '1px solid #F0F2F5',
        borderBottom: '1px solid #F0F2F5',
        paddingTop: 12,
        paddingBottom: 16,
        marginTop: 22
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: 376,
        minWidth: 376,
        display: 'block',
        minHeight: 372
      }
    },
    MuiPickersDay: {
      day: {
        color: '#0f2440',
        width: 46,
        height: 44,
        fontFamily: 'Gilroy',
        borderRadius: 10,
        fontSize: 16,
        lineHeight: 'normal',
        letterSpacing: 'normal'
      },
      daySelected: {
        backgroundColor: '#3577D4',
        letterSpacing: 'normal',
        color: '#FFFFFF',
        '&:hover': {
          color: '#3577D4',
          backgroundColor: '#ebf1fb'
        }
      },
      dayDisabled: {
        color: '#888888'
      },
      current: {
        color: '#0f2440'
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue['400']
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: 32
      }
    }
  }
});

const useStyles = makeStyles({
  outlined: {
    padding: '12px 12px',
    paddingLeft: 40,
    paddingRight: 8,
    border: '1px solid #d8d8d8',
    margin: '0px !important',
    borderRadius: 4,
    color: '#434c5f !important',
    '& input': {
      color: '#434c5f !important',
      padding: '6px 0px !important',
      fontSize: 14
    },
    '& :before': {
      border: '0px !important',
      borderBottom: '0px !important'
    },
    '& :after': {
      border: '0px !important',
      borderBottom: '0px !important',
      color: '#3577d4 !important'
    }
  },
  calendarContainer: {
    position: 'relative',
    '& p': {
      fontFamily: 'Gilroy'
    }
  },
  selectedDateText: {
    fontFamily: 'Gilroy',
    fontSize: 16,
    color: '#0B1829',
    fontWeight: 400
  },
  calendarHeader: {
    padding: '8px 8px 8px 28px'
  },
  buttonsContainer: {
    padding: '16px 12px'
  },
  shippingbutton: {
    height: '56px !important',
    width: 'auto!important',
    border: '1px solid #3577d4 !important',
    borderRadius: '28px !important',
    paddingLeft: '22px !important',
    paddingRight: '22px !important',
    fontSize: '16px !important',
    fontFamily: 'Gilroy !important',
    '&:hover': {
      color: '#ffffff !important',
      backgroundColor: '#3577D4 !important'
    }
  },
  button: {
    width: 14,
    height: 14,
    color: '#1d1d1d'
  },
  underline: {
    paddingBottom: 2,
    paddingLeft: 40,
    paddingRight: 8,
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    margin: '0px !important',
    color: '#434c5f !important',
    '& input': {
      color: '#434c5f !important',
      padding: '6px 0px !important',
      fontSize: 14
    },
    '& :before': {
      border: '0px !important',
      borderBottom: '0px !important'
    },
    '& :after': {
      border: '0px !important',
      borderBottom: '0px !important',
      color: '#3577d4 !important'
    }
  },
  cIcon: {
    color: '#1d1d1d !important',
    opacity: 0.54,
    position: 'absolute',
    left: 8,
    top: '48%',
    transform: 'translate(0, -50%)'
  },
  modalContent: {
    ...scrollBar,
    padding: 0,
    paddingTop: ({ square }) => (square ? '0px !important' : undefined)
  },
  modalLayout: {
    backgroundColor: '#FFFFFF',
    outline: 'none',
    borderRadius: 32
  },
  calendarTitle: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    color: '#868A8F'
  },
  modalDialog: {
    '& [role="dialog"]': {
      borderRadius: ({ square }) => (square ? 10 : 32),
      paddingTop: ({ square }) => (square ? 0 : undefined)
    }
  }
});

const CalendarModal = ({
  minDate,
  maxDate,
  disablePast,
  shouldDisableDate,
  variant,
  format = 'MMMM Do, YYYY',
  open,
  onClose,
  onSubmit,
  initialDate,
  submitText = 'Select'
}) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);

  useEffect(() => {
    setSelectedDate(initialDate);
  }, [initialDate]);

  const handleDateChange = date => {
    const d = date.toDate();
    setSelectedDate(d);
    log.debug('CalendarModal, date changed to:', d);
  };

  const classes = useStyles({ square: variant === 'square' });

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.modalDialog}
      >
        <DialogContent className={classes.modalContent}>
          <div className={classes.modalLayout}>
            <MuiPickersUtilsProvider utils={dayjsUtils}>
              <Grid container justifyContent="center">
                <Grid item className={classes.calendarContainer}>
                  <Grid container alignItems="center" className={classes.calendarHeader}>
                    <Grid item xs>
                      <span className={classes.calendarTitle}>Select Date</span>
                    </Grid>
                    <Grid item>
                      <IconButton aria-label="Delete" onClick={onClose}>
                        <CloseIcon className={classes.button} />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <DatePicker
                    className={variant && variant === 'underline' ? classes.underline : classes.outlined}
                    variant="static"
                    margin="normal"
                    id="date-picker-inline"
                    disableToolbar
                    minDate={minDate}
                    maxDate={maxDate}
                    disablePast={disablePast}
                    shouldDisableDate={date => shouldDisableDate(date.toDate())}
                    value={selectedDate}
                    onChange={handleDateChange}
                    labelFunc={date => dayjs(date).format(format)}
                    autoOk={false}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems="center" className={classes.buttonsContainer}>
            <Grid item xs>
              <p className={classes.selectedDateText}>{dayjs(selectedDate).format(format)}</p>
            </Grid>
            <Grid item>
              <CustomWidthButtonInverse
                className={classes.shippingbutton}
                onClick={() => {
                  onSubmit(selectedDate);
                  onClose();
                }}
              >
                {submitText}
              </CustomWidthButtonInverse>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

CalendarModal.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default CalendarModal;
