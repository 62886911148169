import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { Typography } from '@swagup-com/components';
import Tooltip from '../shared/Tooltip';
import { shipmentStatus } from '../../apis/constants';

const commonShipmentStatusStyles = {
  padding: '7px 10px',
  borderRadius: 8,
  fontSize: 12,
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'uppercase'
};

const styles = {
  delivered: {
    color: '#45af5f',
    backgroundColor: '#e9f5ec'
  },
  onItsWay: {
    color: '#3577d4',
    backgroundColor: '#e7effa'
  },
  cancelled: {
    color: '#f44336',
    backgroundColor: '#fee8e7'
  },
  canceled: {
    color: '#f44336',
    backgroundColor: '#fee8e7'
  },
  pendingProduction: {
    color: '#8d9299',
    backgroundColor: '#f1f2f3'
  },
  success: {
    color: '#45af5f',
    backgroundColor: '#e9f5ec'
  },
  incomplete: {
    color: '#8d9299',
    backgroundColor: '#f1f2f3'
  },
  outOfStock: {
    color: '#fe8e26',
    backgroundColor: '#fff1e5'
  },
  insufficientCredit: {
    color: '#fe8e26',
    backgroundColor: '#fff1e5'
  },
  paymentProcessed: {
    color: '#3577d4',
    backgroundColor: '#e7effa'
  }
};

const statusToStyle = {
  [shipmentStatus.onItsWay]: 'onItsWay',
  [shipmentStatus.scheduled]: 'onItsWay',
  [shipmentStatus.delivered]: 'delivered',
  [shipmentStatus.cancelled]: 'cancelled',
  [shipmentStatus.canceled]: 'canceled',
  [shipmentStatus.invalidAddress]: 'cancelled',
  [shipmentStatus.failure]: 'cancelled',
  [shipmentStatus.success]: 'success',
  [shipmentStatus.incomplete]: 'incomplete',
  [shipmentStatus.outOfStock]: 'outOfStock',
  [shipmentStatus.insufficientCredit]: 'insufficientCredit',
  [shipmentStatus.paymentProcessed]: 'paymentProcessed'
};

const tooltipText = {
  [shipmentStatus.onItsWay]: 'This shipment is on its way to the recipient.',
  [shipmentStatus.scheduled]: 'See the Shipping Date below to know when your swag is scheduled to leave the warehouse.',
  [shipmentStatus.pendingProduction]: 'The items in this shipment are in the process of being produced.',
  [shipmentStatus.cancelled]: 'This shipment has been cancelled',
  [shipmentStatus.canceled]: 'This order has been cancelled',
  [shipmentStatus.failure]: 'This shipment was not able to be scheduled due to an error.',
  [shipmentStatus.invalidAddress]: 'The address for this shipment was invalid.',
  [shipmentStatus.delivered]: "This swag has been delivered to the recipient's address!",
  [shipmentStatus.returnToSender]: 'This shipment was returned to the warehouse.',
  [shipmentStatus.success]: 'This order was successfully processed.',
  [shipmentStatus.incomplete]: 'This order was partially processed.',
  [shipmentStatus.outOfStock]: 'You ran out of inventory.',
  [shipmentStatus.insufficientCredit]: 'Replenish credit to continue shipment.',
  [shipmentStatus.paymentProcessed]: 'Customer payment was processed successfully.'
};

const useShipmentStyles = makeStyles({
  shipmentStatus: ({ status, lightStyle }) => ({
    ...commonShipmentStatusStyles,
    ...styles[statusToStyle[status] || 'pendingProduction'],
    borderRadius: lightStyle ? 16 : 8,
    fontWeight: lightStyle ? 400 : 600,
    fontSize: 10
  }),
  tooltip: {
    fontSize: 12,
    textAlign: 'center',
    width: 320
  }
});

const ShipmentStatus = ({ status, withTooltip, tooltipPlacement, lightStyle, rounded }) => {
  const classes = useShipmentStyles({ status, lightStyle, rounded });
  return withTooltip ? (
    <Tooltip placement={tooltipPlacement} title={tooltipText[status]} classes={{ tooltip: classes.tooltip }}>
      <Typography variant="body4RegularInter" className={classes.shipmentStatus}>
        {status}
      </Typography>
    </Tooltip>
  ) : (
    <Typography variant="body4RegularInter" className={classes.shipmentStatus}>
      {status}
    </Typography>
  );
};

ShipmentStatus.propTypes = {
  status: PropTypes.string.isRequired,
  withTooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

ShipmentStatus.defaultProps = {
  withTooltip: false,
  tooltipPlacement: 'top'
};

export default ShipmentStatus;
