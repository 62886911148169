import React, { useState } from 'react';
import { Grid, withStyles, LinearProgress, Hidden } from '@material-ui/core';
import { shipmentStatus } from '../../apis/constants';
import { sumByQuantity, joinFields } from '../../helpers/utils';
import { ProductImage60x60 } from './SharedComponents';
import { StylessButton } from '../buttons';
import DetailsModal from '../directory/staffOrders/DetailsModal';
import { redactedText } from '../../helpers/helperConstants';
import styles from './styles/DisplayTool';

const shipmentStatusToProgress = {
  [shipmentStatus.scheduled]: 33,
  [shipmentStatus.onItsWay]: 66,
  [shipmentStatus.delivered]: 100
};

const shipmentProgress = status => shipmentStatusToProgress[status] || 0;

const Shipments = ({ classes, shipment, showLine }) => {
  const [orderForDetails, setOrderForDetails] = useState(null);
  const staffIconPath = `/images/dashboard/shipment-tracking-url${shipment.tracking_url ? '' : '-empty'}.svg`;
  const notEmptyStaff = shipment.products.length > 0;

  return (
    <Grid container>
      <Grid item sm={1} xs={2}>
        <ProductImage60x60 product={notEmptyStaff ? shipment.products[0].product : undefined} classes={classes} />
      </Grid>
      <Grid item sm={5} xs={10}>
        <Grid item container sm={12} xs={12} className={classes.separateShipmentInfoContainer}>
          <Grid item sm={11} xs={11}>
            <p className={classes.packTitle}>{notEmptyStaff && shipment.products[0].product.name}</p>
          </Grid>
          <Grid item sm={6} xs={6} className={classes.separateInfo}>
            <p className={classes.infoOrderText}>
              {shipment.redacted
                ? redactedText
                : joinFields([shipment.employee.first_name, shipment.employee.last_name], ' ')}
            </p>
          </Grid>
          <Grid
            item
            container
            sm={6}
            xs={6}
            direction="column"
            justifyContent="flex-end"
            className={classes.separateInfo}
          >
            <p className={classes.infoOrderText}>{shipment.shipping_date}</p>
          </Grid>
        </Grid>
      </Grid>
      <Hidden only="xs">
        <Grid item sm={1} align="center">
          <p className={classes.qtyOrderText}>
            <span className={classes.qtyX}>x</span>
            <span> {` ${sumByQuantity(shipment.products)}`}</span>
          </p>
        </Grid>
        <Grid item sm={4} align="center" className={classes.separateShipmentStatus}>
          <div className={classes.statusText} align="left">
            {shipment.status}
            <LinearProgress
              classes={{ root: classes.progressBarRoot, bar: classes.progressBar }}
              variant="determinate"
              value={shipmentProgress(shipment.status)}
            />
          </div>
        </Grid>
        <Grid item sm={1} align="right" className={classes.separateShipmentStatus}>
          <StylessButton onClick={() => setOrderForDetails(shipment)}>
            <img src={staffIconPath} className={classes.svgShipmentImage} alt="" />
          </StylessButton>
        </Grid>
      </Hidden>
      {showLine && <hr className={classes.shipmentLine} />}
      <DetailsModal order={orderForDetails} onClose={() => setOrderForDetails(null)} />
    </Grid>
  );
};

export default withStyles(styles)(Shipments);
