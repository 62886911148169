const styles = theme => ({
  container: {
    paddingBottom: 100,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      paddingLeft: '0px',
      minWidth: '1280px'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1120px',
      paddingLeft: '0px',
      minWidth: '1120px'
    },
    [theme.breakpoints.down(1025)]: {
      maxWidth: '1024px',
      minWidth: '1024px',
      paddingLeft: '24px !important',
      paddingRight: 24
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
      paddingRight: '24px !important',
      paddingLeft: '24px !important',
      minWidth: '10vw',
      padding: '50px 0 100px 0'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      paddingRight: '24px',
      paddingLeft: '24px',
      minWidth: '100%',
      padding: '0 0 100px 0'
    }
  },
  textContainer: {
    paddingTop: '100px !important',
    maxWidth: 520,
    [theme.breakpoints.down('lg')]: { paddingTop: '50px !important' }
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: '4.5rem',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#0f2440',
    maxWidth: 465,
    marginTop: 0
  },
  subtitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.5rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440',
    marginBottom: 50
  },
  category: {
    marginBottom: 40,
    '& img': { width: 48, height: 48, marginBottom: 10 },
    '& p': {
      maxWidth: 220,
      opacity: 0.54,
      fontFamily: 'Gilroy-SemiBold',
      fontSize: '1rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.63,
      letterSpacing: 'normal',
      color: '#0f2440'
    },
    '&.mb-0': { marginBottom: '0 !important' }
  },
  imageOuterContainer: {
    paddingTop: 120,
    position: 'relative'
  },
  imageContainer: {
    marginTop: -38,
    [theme.breakpoints.down(1025)]: { marginTop: 0 }
  },
  imageBackCard: {
    width: '88%',
    height: '70%',
    position: 'absolute',
    top: '31%',
    left: '8%',
    zIndex: -999,
    borderRadius: '75px 5px 75px 5px',
    boxShadow: '0 30px 100px 0 rgba(212, 217, 226, 0.5)',
    [theme.breakpoints.down('lg')]: { top: '33%' },
    [theme.breakpoints.down('sm')]: { top: '34%' },
    [theme.breakpoints.down('xs')]: { height: '80%', width: '95%', left: '2%', top: '28%' }
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    marginTop: -80
  },
  button: {
    marginTop: 60,
    padding: '23px 75px',
    [theme.breakpoints.down('xs')]: { padding: '23px 50px' }
  },
  buttonText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
    textTransform: 'none'
  },
  link: {
    textAlign: 'center',
    marginTop: 35,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.6,
    letterSpacing: 'normal',
    color: '#3577d4',
    cursor: 'pointer'
  }
});

export default styles;
