import React from 'react';
import clsx from 'clsx';
import { Grid, withStyles } from '@material-ui/core';
import { StylessButton } from '../buttons';
import { BlueCheckbox, GrayCheckbox } from '../account/AccountSharedComponents';
import { cardImg } from '../../helpers/utils';
import styles from '../global/styles/PaymentSection';
import { paymentMethods } from '../../apis/constants';

const CreditCardItem = ({
  classes,
  key,
  creditCard,
  selectedCreditCard,
  toggleSelectedCreditCard,
  toggleDefaultProfile,
  isDefault
}) => (
  <StylessButton
    key={key}
    className={classes.buttonCreditCard}
    onClick={
      toggleDefaultProfile ||
      (toggleSelectedCreditCard && (() => toggleSelectedCreditCard(creditCard.customerPaymentProfileId)))
    }
  >
    <Grid
      item
      container
      className={clsx(
        classes.containerCreditCard,
        (isDefault || selectedCreditCard === creditCard.customerPaymentProfileId) &&
          classes.containerCreditAvailableSelected
      )}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={cardImg(creditCard.card_type)} className={classes.accountImg} alt={creditCard.card_type} />
      </Grid>
      <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 20 }}>
        <p className={classes.creditAvailable}>{creditCard.card_number}</p>
        <p className={classes.textCreditAvailable}>Exp: {creditCard.expiration_date}</p>
      </Grid>
      <Grid item container xs={1} justifyContent="flex-end" style={{ alignContent: 'center' }}>
        {isDefault || selectedCreditCard === creditCard.customerPaymentProfileId ? (
          <BlueCheckbox checked onChange={() => {}} value={paymentMethods.creditCard} />
        ) : (
          <GrayCheckbox checked={false} onChange={() => {}} value={paymentMethods.creditCard} />
        )}
      </Grid>
    </Grid>
  </StylessButton>
);

export default withStyles(styles)(CreditCardItem);
