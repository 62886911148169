import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@swagup-com/components';
import { Grid, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#1C4783',
    borderRadius: 10,
    marginBottom: 24,
    marginTop: 30,
    width: '100%',
    height: 100,
    maxHeight: 100,
    position: 'relative'
  },
  image: {
    width: 70,
    height: 80,
    objectFit: 'contain'
  },
  bannerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 16
  },
  closeIconButton: {
    padding: 6,
    position: 'absolute',
    top: 0,
    right: 8
  },
  closeIcon: {
    color: '#FFFFFF',
    height: 18,
    width: 18
  }
}));

const CatalogBanner = ({ image, title, buttonLabel, buttonLink }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  if (!open) return null;

  return (
    <Grid container className={classes.banner}>
      <Grid className={classes.bannerContent}>
        <Grid item>
          <img src={image} alt={title} className={classes.image} width={70} height={80} />
        </Grid>
        <Grid alignItems="center">
          <Typography variant="h6SemiBoldInter" style={{ color: '#FFFFFF', fontSize: 22 }}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="secondary"
            component={Link}
            to={buttonLink}
            style={{ height: 50, background: '#125CFF', border: '1px solid #125CFF' }}
            id="catalog-membership-upgrade"
          >
            <Typography
              variant="subtitle3SemiBoldInter"
              id="catalog-membership-upgrade-btn"
              style={{ color: '#FFFFFF' }}
            >
              {buttonLabel}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <IconButton className={classes.closeIconButton} onClick={() => setOpen(false)}>
        <Close className={classes.closeIcon} />
      </IconButton>
    </Grid>
  );
};

export default CatalogBanner;
