import * as React from 'react';
import sum from 'lodash/sum';
import isEqual from 'lodash/isEqual';

import { Button, Typography } from '@swagup-com/components';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Skeleton } from '@material-ui/lab';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ToolTipIcon } from '../../../../icons';
import SizesConfiguration from './SizesConfiguration';
import ArrowTooltip from '../common/Tooltip';
import { useOrder } from '../OrderContext';
import { useProduct } from '../common/ProductContext';
import { getUsedQuantitiesBySize, isPack, moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import { joinQuantitiesBreakdowns, getQuantitiesBreakdown, cancellableQuery } from '../common/utilsOrder';
import { productionTime as productionSpeed } from '../../../../../apis/constants';
import styles from '../common/ProductBreakdown.styles';
import api from '../../../../../apis/swagup/order';
import apiProof from '../../../../../apis/swagup/proofs';
import apiPaths from '../../../../../helpers/apiPaths';
import { cantRushText } from '../../../../../helpers/helperConstants';
import AppliedMembershipPanel from '../../../../global/AppliedMembershipPanel';
import useDiscountsAndRewards from '../../../../../hooks/useDiscountsAndRewards';
import StrikeOutText from '../../../../global/StrikeOutCost';

const useStyles = makeStyles(styles);

const usePriceInfo = () => {
  const { updatedProduct, restProducts } = useProduct();
  const queryClient = useQueryClient();
  return useQuery(
    [apiPaths.proofPrices, [updatedProduct, ...restProducts]],
    () => {
      queryClient.cancelQueries(apiPaths.proofPrices);
      return cancellableQuery(apiProof.fetchPrices)([updatedProduct, ...restProducts]);
    },
    { keepPreviousData: true }
  );
};

export const ProductionButton = ({ variant, disabled }) => {
  const { updatedProduct: product, updateProduct } = useProduct();
  const classes = useStyles({ active: product.production_time === variant, disabled });

  const { data: priceInfo, status } = usePriceInfo();

  const isStandard = variant === productionSpeed.standard;
  const turnaroundTime = isStandard ? product.product.standard_turnaround_time : product.product.rush_turnaround_time;

  const handleClick = () => updateProduct(oldProduct => ({ ...oldProduct, production_time: variant }));
  const handleKeyDown = e => {
    if (e.keyCode === 13) handleClick();
  };

  const { swagupMembershipPlans } = useFlags();
  const totalQty = sumByQuantity(product.sizes);
  const rushFee = totalQty * +(priceInfo?.rush_fee ?? 0);
  const rushFeeBeforeDiscount = totalQty * +(priceInfo?.rush_fee_without_discount ?? 0);
  const isLoading = status === 'loading';
  const { totalBeforeDiscount, discount } = useDiscountsAndRewards(rushFee || rushFeeBeforeDiscount, 'rush_production');

  const { twentyPercentRushText } = useFlags();

  const percent = twentyPercentRushText ? 20 : 10;

  return (
    <Button
      fullWidth
      // tabIndex={disabled ? '-1' : '0'}  Temporarily removed until we have proper keyboard navigation design
      aria-pressed={product.production_time === variant}
      className={classes.productionButton}
      onClick={disabled ? undefined : handleClick}
      onKeyDown={disabled ? undefined : handleKeyDown}
      disabled={disabled}
    >
      <Grid container direction="column" alignItems="flex-start" justifyContent="space-between">
        <Grid container alignItems="center" spacing={3} className={classes.productionTitle}>
          <Grid item xs>
            <Typography variant="body1SemiBoldInter" style={{ textAlign: 'left', margin: 0 }}>
              {variant}
              {!isStandard && !disabled && (
                <img src="/images/public/rush.svg" alt="Lightning Icon" style={{ marginLeft: 8 }} />
              )}
              {!isStandard && disabled && (
                <img src="/images/public/rush-disabled.svg" alt="Lightning Icon" style={{ marginLeft: 8 }} />
              )}
            </Typography>
          </Grid>
          {swagupMembershipPlans && !isStandard && totalBeforeDiscount > 0 && (
            <Grid item>
              <span style={{ color: '#787B80', textDecoration: 'line-through' }}>{moneyStr(totalBeforeDiscount)}</span>
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1SemiBoldInter">
              {isStandard
                ? moneyStr(0)
                : (isLoading && <Skeleton width={45} height={25} />) || `+ ${moneyStr(rushFee)}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs>
            <Typography variant="body2RegularInter" className={classes.productionSubtitle}>
              {turnaroundTime} Business Days {isStandard ? ' ' : `(${percent}%)`}
            </Typography>
          </Grid>

          {swagupMembershipPlans && !isStandard && discount > 0 && (
            <Grid item>
              <Box className={classes.discountBadge}>
                <Grid container>
                  <Grid item>
                    <Box className={classes.discountImageWrapper}>
                      <img src="/images/membership/rewardRocket.png" className={classes.discountImage} alt="discount" />
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <span className={classes.discountText}>-{discount}%</span>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Button>
  );
};

const noRushAPIErrorMsg = 'One or more items in your order are not available for rush production';

const getTotalQuantityMessage = (oldTotal, totalQty, product, usedQuantities) => {
  const showMinimumToOrderError = Math.max(totalQty, usedQuantities) < product.product.minimum_to_reorder;

  if (showMinimumToOrderError)
    return `Total ${isPack(product.product.record_type) ? 'packs' : 'items'} must be ${
      product.product.minimum_to_reorder
    } or more`;
  if (totalQty > oldTotal) {
    return `${totalQty - oldTotal} new`;
  }
  if (product.price === 0) {
    return 'No price available. Try again or contact your Account Manager';
  }

  return '';
};

const Pricing = ({ onClose }) => {
  const [oldTotalPrice, setOldTotalPrice] = React.useState(null);
  const classes = useStyles();

  const order = useOrder();
  const { updatedProduct, updateProduct, product, restProducts } = useProduct();
  const { data: priceInfo, status, error } = usePriceInfo();

  const queryClient = useQueryClient();
  React.useEffect(() => {
    queryClient.invalidateQueries([apiPaths.proofPrices, updatedProduct]);
  }, [queryClient, updatedProduct]);

  const oldTotal = sumByQuantity(product.sizes);
  React.useEffect(() => {
    if (status === 'success' && oldTotalPrice === null)
      setOldTotalPrice(oldTotal * +(priceInfo?.price ?? 0) + oldTotal * +(priceInfo?.rush_fee ?? 0));
  }, [status, oldTotal, oldTotalPrice, priceInfo]);

  const totalQty = sumByQuantity(updatedProduct.sizes);
  const subtotal = totalQty * +(priceInfo?.price ?? 0);
  const subtotalBeforeDiscount = totalQty * +(priceInfo?.price_without_discount ?? 0);
  const rushFee = updatedProduct.production_time === productionSpeed.rush ? totalQty * +(priceInfo?.rush_fee ?? 0) : 0;
  const rushFeeBeforeDiscount =
    updatedProduct.production_time === productionSpeed.rush
      ? totalQty * +(priceInfo?.rush_fee_without_discount ?? 0)
      : 0;
  const total = subtotal + rushFee;

  const proofMutation = useMutation([apiPaths.proofs, product.id], api.updateOpportunityProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(apiPaths.opportunities);
      queryClient.invalidateQueries(apiPaths.opportunityProofs(product.opportunity));
      onClose();
    }
  });

  if (status === 'error') {
    if (error.data?.non_field_errors?.[0] === noRushAPIErrorMsg) {
      updateProduct(oldProduct => ({ ...oldProduct, production_time: productionSpeed.standard }));
    } else throw new Error(JSON.stringify(error.data));
  }

  const handleSubmit = () => proofMutation.mutate({ id: order.id, products: [updatedProduct, ...restProducts] });

  const { enableUnallocatedProductsManagementV2 } = useFlags();

  const { shipmentGroups, warehouseProofs } = useOrder();
  const shippingMoreThanRequested = ([size, shipQuantity]) =>
    updatedProduct.sizes.some(sz => sz.size.id === +size && sz.quantity < shipQuantity);
  const shipmentQuantities = getUsedQuantitiesBySize(product.id, shipmentGroups);
  const warehouseProofSizes = warehouseProofs.find(wp => wp.proof.id === product.id)?.sizes;
  const usedQuantitiesBreakdown =
    !enableUnallocatedProductsManagementV2 && warehouseProofSizes?.length > 0
      ? joinQuantitiesBreakdowns(shipmentQuantities, getQuantitiesBreakdown(warehouseProofSizes))
      : shipmentQuantities;
  const missingStockForShipment = Object.entries(usedQuantitiesBreakdown).some(shippingMoreThanRequested);
  const totalUsedQuantities = sum(Object.values(usedQuantitiesBreakdown));
  const totalQuantityMessage = getTotalQuantityMessage(oldTotal, totalQty, product, totalUsedQuantities);

  const isLoading = status === 'loading' || proofMutation.isLoading;
  const disabled =
    isLoading ||
    isEqual(updatedProduct, product) ||
    totalQty < Math.max(product.product.minimum_to_reorder, 1) ||
    missingStockForShipment ||
    priceInfo === undefined ||
    priceInfo.price === 0;

  const multipleTotals = React.useMemo(
    () => ({
      product_discount: subtotal || subtotalBeforeDiscount,
      rush_production_discount: rushFee || rushFeeBeforeDiscount
    }),
    [subtotal, subtotalBeforeDiscount, rushFee, rushFeeBeforeDiscount]
  );

  const { totalBeforeDiscount, multipleDiscounts } = useDiscountsAndRewards(0, '', multipleTotals);
  return (
    <>
      <Grid container style={{ marginTop: 6 }}>
        <AppliedMembershipPanel
          type="product"
          total={subtotal}
          multipleTotals={multipleTotals}
          fullWidth
          style={{ marginTop: 16 }}
        />
        <Grid container justifyContent="space-between" alignItems="baseline" style={{ position: 'relative' }}>
          <p className={classes.priceTitle} style={{ marginTop: 8 }}>
            Total quantity
          </p>
          <p className={classes.quantity}>{totalQty}</p>
          {totalQuantityMessage && (
            <Grid container justifyContent="flex-end" style={{ position: 'absolute', top: 43 }}>
              <p
                className={classes.pricingMessage}
                style={{ color: totalQty >= product.product.minimum_to_reorder ? '#45af5f' : '#f44336' }}
              >
                {totalQuantityMessage}
              </p>
            </Grid>
          )}
        </Grid>
        <div className={classes.separator} style={{ marginTop: 20 }} />
        <Grid container justifyContent="space-between" alignItems="baseline">
          <p className={classes.priceTitle}>Subtotal</p>
          <p className={classes.quantity}>
            {isLoading ? (
              <Skeleton width={73} height={12} />
            ) : (
              <>
                <StrikeOutText value={multipleDiscounts?.product_discount} />
                {moneyStr(subtotal)}
              </>
            )}
          </p>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="baseline" style={{ marginTop: 20 }}>
          <p className={classes.priceTitle}>Rush production</p>
          <p className={classes.quantity}>
            {isLoading ? (
              <Skeleton width={75} height={12} />
            ) : (
              <>
                <StrikeOutText value={multipleDiscounts?.rush_production_discount} />
                {moneyStr(rushFee)}
              </>
            )}
          </p>
        </Grid>
        <div className={classes.separator} style={{ marginTop: 16 }} />
        <Grid container justifyContent="space-between" alignItems="baseline" style={{ position: 'relative' }}>
          <p className={classes.priceTitle}>Total price</p>
          <p className={classes.price}>
            {isLoading ? (
              <Skeleton width={100} height={16} />
            ) : (
              <>
                <StrikeOutText value={totalBeforeDiscount} fontSize={14} />
                {moneyStr(total)}
              </>
            )}
          </p>
          {oldTotalPrice < total && (
            <Grid container justifyContent="flex-end" style={{ position: 'absolute', top: 32 }}>
              <p className={classes.pricingMessage} style={{ color: '#45af5f' }}>
                {moneyStr(total - oldTotalPrice)} added
              </p>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Button
        disabled={disabled}
        variant="primary"
        className={classes.submitButton}
        loading={isLoading}
        onClick={handleSubmit}
      >
        Save Changes
      </Button>
    </>
  );
};

const ProductionConfiguration = ({ canRush, onClose }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" style={{ paddingRight: 26 }}>
      <Grid container alignItems="center">
        <p className={classes.hightlightedText}>Estimated production time</p>
        <ArrowTooltip
          padding="10px 14px"
          maxWidth={228}
          variant="primary"
          title="Production times are estimates and not guarantees."
        >
          <ToolTipIcon style={{ width: 16, height: 16, marginLeft: 4 }} />
        </ArrowTooltip>
      </Grid>
      <Grid container direction="column" justifyContent="space-between" style={{ height: 184, marginTop: 20 }}>
        <ProductionButton variant={productionSpeed.standard} />
        <ArrowTooltip padding="10px 14px" maxWidth={228} variant="primary" title={canRush ? '' : cantRushText}>
          <ProductionButton variant={productionSpeed.rush} disabled={!canRush} />
        </ArrowTooltip>
      </Grid>
      <SizesConfiguration />
      <Pricing onClose={onClose} />
    </Grid>
  );
};

export default ProductionConfiguration;
