import { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import useApiCall from './useApiCall';
import log from '../logger';

// TODO - Rewrite this using react-query
const useInfiniteScroll = (itemsPerPage = 24, initialApiFunc, initialContainer, isAscendent = false) => {
  const [reachedEnd, setReachedEnd] = useState(false);
  const currentLimit = useRef(itemsPerPage);
  const [container, setContainer] = useState(initialContainer);
  const [{ response, isApiCallPending, isApiError }, setApiFunction, callApiFunction] = useApiCall(initialApiFunc);
  const previousScrollHeight = useRef(0);

  const onScroll = useCallback(
    debounce(() => {
      if (isApiCallPending || reachedEnd || !container) return;
      const shouldFetchMoreItems = isAscendent
        ? container.scrollTop === 0
        : Math.ceil(container.clientHeight + container.scrollTop) >= container.scrollHeight;
      if (shouldFetchMoreItems) {
        callApiFunction(currentLimit.current);
      }
    }, 250),
    [isApiCallPending, reachedEnd, callApiFunction, container]
  );
  useLayoutEffect(() => {
    if (isApiCallPending && container) {
      container.scrollTop = isAscendent ? 0 : container.scrollHeight;
    }
  }, [isApiCallPending, container]);

  useLayoutEffect(() => {
    if (container) {
      if (currentLimit.current === itemsPerPage) {
        container.scrollTop = isAscendent ? container.scrollHeight : 0;
      } else if (isApiCallPending) {
        container.scrollTop = isAscendent ? 0 : container.scrollHeight;
      } else if (isAscendent) {
        const scrollTo =
          container.scrollHeight > previousScrollHeight.current
            ? container.scrollHeight - previousScrollHeight.current
            : container.scrollHeight;
        container.scrollTop = scrollTo;
      }

      previousScrollHeight.current = container.scrollHeight;
      container.addEventListener('scroll', onScroll);
      return () => {
        log.debug('removeEventListener in useInfiniteScroll');
        container.removeEventListener('scroll', onScroll);
      };
    }

    return () => {};
  }, [isApiCallPending, container]);

  useEffect(() => {
    if (response?.result !== 'nodata') {
      currentLimit.current += itemsPerPage;
      setReachedEnd(response?.data.next === null);
    }
  }, [response]);

  const setApiFunc = useCallback(
    func => {
      if (container) {
        container.scrollTop = 0;
      }
      currentLimit.current = itemsPerPage;
      setApiFunction(() => func(currentLimit.current));
    },
    [container, setApiFunction, itemsPerPage]
  );

  return [{ response, isApiCallPending, isApiError }, setApiFunc, setContainer];
};

export default useInfiniteScroll;
