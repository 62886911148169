import commonStyles from './commonStyles';

const customScrollBar = {
  overflowY: 'auto',
  overflowX: 'hidden',
  width: 'auto',
  marginRight: -1,
  '&::-webkit-scrollbar': {
    backgroundColor: '#efeff2',
    width: 1,
    borderRadius: 1
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d3dbe5',
    width: 1,
    borderRadius: 1
  }
};

export default theme => ({
  ...commonStyles,
  container: {
    paddingBottom: 42,
    minHeight: 'calc(100vh - 81px)',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0
    }
  },
  title: {
    fontFamily: 'Inter',
    fontSize: 24,
    color: '#0f2440',
    fontWeight: 600,
    lineHeight: '32px' /* 133.333% */,
    letterSpacing: '-0.72px'
  },
  sendButton: {
    '&:hover': { backgroundColor: '#3577d4' },
    textTransform: 'none',
    width: 150,
    height: 56,
    backgroundColor: '#3577d4',
    borderRadius: 32,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 16,
    color: '#ffffff',
    lineHeight: 'normal',
    '&.Mui-disabled': { backgroundColor: '#ecedef !important' }
  },
  stickyBar: {
    padding: '14px 0',
    width: '100%',
    position: 'fixed',
    bottom: '0',
    backgroundColor: '#fff'
  },
  errorAlert: {
    top: 124
  },
  warningsBadge: ({ warnings }) => ({
    padding: '6px 4px 0px 4px',
    paddingRight: 10,
    fontSize: 10,
    borderRadius: 10,
    color: warnings ? '#FE6E06' : '#FE8E26',
    // border: warnings ? '1px solid #FE6E06' : '1px solid #FE8E26',
    background: warnings ? '#FFF1E5' : undefined
  }),
  recipientsContainer: {
    ...customScrollBar,
    maxHeight: 'calc(100vh -  324px)',
    width: '100%',
    padding: '0 20px 0px 0px'
    // position: 'relative'
    // '& > div': {
    //   '& > div': {
    //     overflow: 'visible !important'
    //   }
    // }
  }
});
