import React, { useState } from 'react';
import { makeStyles, Drawer, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import styles from './styles/creditDrawer';
import CreditDrawerAddCredits from './CreditDrawerAddCredits';
import CreditDrawerAddCard from './CreditDrawerAddCard';

const useStyles = makeStyles(styles);

const CreditDrawer = ({ open, onClose, credit, company, history, recommendedCredits }) => {
  const [currentViewIndex, setCurrentViewIndex] = useState(0);
  const classes = useStyles();
  return (
    <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper: classes.drawer }}>
      <Grid container className={classes.drawerContent}>
        <Grid container justifyContent="flex-start" className={classes.systemBar}>
          <Grid item>
            <Close
              className={classes.closeDrawer}
              onClick={currentViewIndex === 1 ? () => setCurrentViewIndex(0) : onClose}
            />
          </Grid>
        </Grid>
        <SwipeableViews index={currentViewIndex} axis="x" disabled>
          <CreditDrawerAddCredits
            credit={credit}
            company={company}
            history={history}
            recommendedCredits={recommendedCredits}
            onAddPaymentMethod={() => setCurrentViewIndex(1)}
            currentStep={currentViewIndex}
          />
          <CreditDrawerAddCard onCancel={() => setCurrentViewIndex(0)} />
        </SwipeableViews>
      </Grid>
    </Drawer>
  );
};

export default CreditDrawer;
