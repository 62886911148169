import * as React from 'react';
import { Box } from '@material-ui/core';
import { useOrder } from '../../OrderContext';
import { ProductProvider } from '../../common/ProductContext';

import { Header, OrderProduct } from './common';

const ReviewOrder = () => {
  const order = useOrder();
  const products = order?.products ?? [];

  return (
    <Box>
      <Header header="Review your order" />
      <Box>
        {products.map(product => (
          <ProductProvider key={product.id} product={product}>
            <OrderProduct />
          </ProductProvider>
        ))}
      </Box>
    </Box>
  );
};

export default ReviewOrder;
