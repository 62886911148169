import { greyRoundBorder, scrollBar } from '../../../../shared/styles/commonStyles';
import { changeColorLightness } from '../../../../shared/styles/utils';

export default {
  drawerRoot: {
    zIndex: '1000 !important'
  },
  drawerPaper: {
    maxWidth: 812,
    padding: '0 50px 18px'
  },
  title: {
    marginTop: 31,
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: '#0b1829'
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: '#0b1829'
  },
  addAllRemaining: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.57,
    color: '#3577d4',
    height: 32,
    padding: 0
  },
  recipientSummary: {
    ...greyRoundBorder,
    marginTop: 16,
    padding: 19
  },
  divider: {
    backgroundColor: '#f0f2f5'
  },
  fullwidthDivider: {
    margin: '0 -50px',
    backgroundColor: '#f0f2f5'
  },
  button: {
    height: 36,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 0.71,
    letterSpacing: 'normal',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  selectAllAvailableButton: {
    height: 20,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 0.67,
    letterSpacing: 'normal',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  resetButton: {
    height: 20,
    minWidth: 'unset',
    color: '#787b80',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 0.67,
    letterSpacing: 'normal',
    '&:hover': { color: changeColorLightness('#787b80') }
  },
  editIcon: {
    height: 36,
    width: 36,
    marginLeft: 16,
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#f4f8ff'
  },
  unselectedProduct: {
    ...greyRoundBorder,
    marginTop: 16,
    padding: 24,
    backgroundColor: '#fafafa'
  },
  shippingNoteContainer: {
    marginTop: 16,
    height: 135,
    border: 'solid 1px #e1e3e5',
    borderRadius: 20,
    padding: '8px 24px'
  },
  shippingNote: {
    width: '100%',
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    lineHeight: 1.57,
    color: '#787b80'
  },
  notesLengthCounter: {
    width: '100%',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 'normal',
    color: '#8d9299',
    textAlign: 'right'
  },
  textAreaResize: {
    ...scrollBar,
    height: '88px !important',
    paddingRight: 8
  },
  saveButton: {
    height: 56,
    letterSpacing: 0,
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#fafafa',
      color: '#787b80'
    }
  }
};
