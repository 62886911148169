const colorSetter = ({ isSelected }) => (isSelected ? '#3577d4' : '#434c5f');

const styles = theme => ({
  root: {
    maxWidth: 200
  },
  listItem: {
    paddingLeft: ({ isNested }) => (isNested ? theme.spacing(6) : 16)
  },
  itemText: {
    fontSize: 16,
    lineHeight: 1,
    fontWeight: ({ isSelected }) => (isSelected ? 600 : 500),
    color: colorSetter
  },
  selectedColor: {
    color: colorSetter,
    minWidth: 42
  }
});

export default styles;
