import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { Button } from '@swagup-com/components';
import isEmpty from 'lodash/isEmpty';
import gtm from '../../../utils/gtm';
import { dayjs } from '../../../helpers/dayjs';
import { hasExtension } from './common';
import styles from './styles/ItemsChangesHistory';
import { joinFields, truncateFileName } from '../../../helpers/utils';

const useStyles = makeStyles(styles);

const getSubject = decorations => {
  if (isEmpty(decorations)) return 'Item Color Change';

  const subjectData = { created: 0, updated: 0, deleted: 0 };
  decorations.forEach(d => {
    subjectData[d.operation.toLowerCase()] += 1;
  });

  const decorationChanges = Object.entries(subjectData)
    .filter(([, v]) => v > 0)
    .map(([k, v]) => `${v} ${k}`);

  return `Decorations Changes: ${joinFields(decorationChanges, ', ')}.`;
};

const MessageContainer = ({ message }) => {
  const classes = useStyles();
  const oldVersion = message.attachment_doc || message.description;
  return oldVersion ? (
    <div>
      {message.attachment_doc && (
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {hasExtension(message.attachment_doc, ['.pdf', '.eps', '.ai']) ? (
              <img src="/images/public/unknown-file.svg" alt="logo" className={classes.nonImage} />
            ) : (
              <img className={classes.artworkOld} src={message.attachment_doc} alt="attachment" />
            )}
          </Grid>
          <Grid item>
            <a href={message.attachment_doc} target="_blank" rel="noopener noreferrer">
              <img src="/images/public/open-new-window.svg" alt="swagup" className={classes.logo} />
            </a>
          </Grid>
        </Grid>
      )}
      {message.description && (
        <div className={classes.descriptionOnly} dangerouslySetInnerHTML={{ __html: message.description }} />
      )}
    </div>
  ) : (
    <div>
      <p className={classes.description}>
        <strong style={{ fontWeight: 500 }}>{getSubject(message.decorations)}</strong>
      </p>
      {message.item_color && (
        <p className={classes.description}>
          <strong>Item Color: </strong>
          <span className={classes.colorIcon} style={{ backgroundColor: message.item_color_hex }} />
          {message.item_color}
        </p>
      )}
      {message.decorations?.map(d => (
        <div key={d.name} className={classes.decoration}>
          <p className={classes.description}>
            <strong>
              {`${d.name} (${d.operation})`}
              {d.operation !== 'Deleted' && ':'}
            </strong>
          </p>
          {d.imprint_type && (
            <p className={classes.description}>
              <strong>Imprint Type: </strong>
              {d.imprint_type}
            </p>
          )}
          {d.location && (
            <p className={classes.description}>
              <strong>Location: </strong>
              {d.location}
            </p>
          )}
          {d.dimension && (
            <p className={classes.description}>
              <strong>Dimension: </strong>
              {d.dimension}
            </p>
          )}
          {d.artwork_doc && d.artwork_name && (
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <p className={classes.description}>
                  <strong>Artwork: </strong>
                </p>
              </Grid>
              <Grid item>
                <img src="/images/public/unknown-file.svg" alt="logo" className={classes.nonImage} />
              </Grid>
              <Grid item>
                <p className={classes.description}>{truncateFileName(d.artwork_name, 20)}</p>
              </Grid>
              <Grid item className={classes.newWindow}>
                <a href={d.artwork_doc} target="_blank" rel="noopener noreferrer">
                  <img src="/images/public/open-new-window.svg" alt="swagup" className={classes.logo} />
                </a>
              </Grid>
            </Grid>
          )}

          {d.additional_notes && (
            <p className={classes.description}>
              <strong>Notes: </strong>
              {d.additional_notes}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

const ItemsChangesHistory = ({ changesHistory, onContinue, productName }) => {
  const classes = useStyles();
  const { results: messages } = changesHistory;
  return (
    <Grid container direction="column" className={classes.historyContainer}>
      <Grid item className={classes.historyHeader}>
        <p className={classes.header}>Changes History</p>
      </Grid>
      <Grid item xs>
        <Grid container className={classes.decorationEditSection}>
          <div>
            {messages?.map((m, idx) => (
              <Grid
                key={m.id}
                container
                className={classes.messageContainer}
                style={{ borderBottom: idx === messages.length - 1 ? 0 : undefined }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    {m.user.username?.includes('swagup.com') ? (
                      <div className={classes.iconWrapper}>
                        <img
                          src="/images/public/mini-logo.svg"
                          alt={`${m.user.first_name} ${m.user.last_name}`}
                          className={classes.icon}
                        />
                      </div>
                    ) : (
                      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
                        <Grid item>
                          <Person fontSize="small" className={classes.icon} />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item>
                    <p className={classes.from}>{`${m.user.first_name} ${m.user.last_name}`}</p>
                  </Grid>
                  <Grid item>
                    <p className={classes.timeStamp}>{dayjs(m.created_at).format('MMMM Do YYYY [at] h:mm a')}</p>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <p className={classes.product}>{productName}:</p>
                  </Grid>
                  <MessageContainer message={m} />
                </Grid>
              </Grid>
            ))}
          </div>
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end" alignItems="flex-end" className={classes.continueSection}>
        <Grid item>
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              onContinue();
              gtm.onClickContinue('Item change history clicked');
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ItemsChangesHistory;
