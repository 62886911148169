const styles = () => ({
  summaryTitle: {
    marginLeft: 0,
    marginBottom: 15,
    fontWeight: 'bold',
    fontFamily: 'Futura',
    fontSize: 24
  },
  summaryTable: {
    minWidth: '282px',
    width: 348
  },
  summaryTableStandard: {
    minWidth: '282px'
  },
  tableRow: {
    height: 'auto'
  },
  cellNone: {
    borderBottom: '0px !important',
    padding: '0px 0px 13px 0px !important'
  },
  pTitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    color: '#8d9299'
  },
  pText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    color: '#0f2440'
  }
});
export default styles;
