/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { makeStyles, Step, StepConnector, StepLabel, Stepper as MuiStepper, withStyles } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  stepIcon: ({ active, completed }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    borderRadius: 99,
    color: active ? 'white' : completed ? '#3577d4' : '#787b80',
    border: completed ? 'solid 0.4px #3577d4' : 'solid 0.4px transparent',
    boxShadow: active ? '0 2px 8px 0 rgba(0, 0, 0, 0.15)' : 'unset',
    backgroundColor: active ? '#3577d4' : completed ? '#f4f8ff' : '#f0f2f5'
  }),
  stepNumber: {
    fontFamily: 'Gilroy-SemiBold',
    margin: 0,
    color: 'inherit',
    fontSize: 7
  },
  stepCompleted: {
    fontSize: 10
  }
});

const StepIcon = ({ index, ...props }) => {
  const classes = useStyles(props);

  const { completed } = props;
  return (
    <div className={classes.stepIcon}>
      {completed ? <DoneIcon className={classes.stepCompleted} /> : <p className={classes.stepNumber}>{index}</p>}
    </div>
  );
};

const VerticalConnector = withStyles({
  root: {
    width: 1,
    height: 8,
    margin: '0 16px',
    backgroundColor: '#ced1d6'
  }
})(StepConnector);

const CustomStepLabel = withStyles({
  label: {
    fontSize: 12,
    color: '#787b80',
    fontFamily: 'Gilroy-Medium',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  active: { '&$label': { color: '#0b1829' } },
  completed: { '&$label': { color: '#0b1829' } }
})(StepLabel);

const CustomStep = withStyles({
  root: { cursor: ({ clickable }) => (clickable === 'true' ? 'pointer' : 'default') }
})(Step);

const Stepper = ({ active, steps, disablePrev, onClick }) => {
  const history = useHistory();
  const handleClick = index => {
    const [fn, param] = onClick ? [onClick, index] : [history.goBack, index - active];
    return () => fn(param);
  };

  const isClickable = index => !disablePrev && index < active;
  return (
    <MuiStepper connector={<VerticalConnector />} activeStep={active} style={{ padding: 0 }}>
      {steps.map(({ label }, index) => (
        <CustomStep key={label} clickable={isClickable(index).toString()}>
          <CustomStepLabel
            onClick={isClickable(index) ? handleClick(index) : undefined}
            StepIconComponent={props => <StepIcon {...props} index={index + 1} />}
          >
            {label}
          </CustomStepLabel>
        </CustomStep>
      ))}
    </MuiStepper>
  );
};

export default Stepper;
