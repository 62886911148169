import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Button } from '@swagup-com/components';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';

import { useOrder } from '../OrderContext';
import Drawer from '../common/Drawer';
import InformationRow from '../common/InformationRow';
import WarehousePriceTooltip from './WarehousePriceTooltip';
import { moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import useSortedSizes from '../hooks/useSortedSizes';
import useStorageInfo from '../hooks/useStorageInfo';
import styles from './WarehouseOverview.styles';
import InactiveSizesAlert from '../common/InactiveSizesAlert';

const useStyles = makeStyles(styles);

const mapWarehouseProofSizesWithActiveField = (sizes, product) =>
  sizes.map(s => {
    const productSize = product.sizes.find(ps => ps.size.id === s.size);
    return { ...s, active: productSize?.active ?? true };
  });

const WarehouseProductDetails = ({ warehouseProof, hasInactiveSize }) => {
  const classes = useStyles({ showWarning: hasInactiveSize });

  const sortedSizes = useSortedSizes(warehouseProof.sizes);
  const { storagePrices, storageCategoryNames } = useStorageInfo();
  const { products } = useOrder();

  const storageCategory = warehouseProof.proof.product.storage_category;
  const totalPrice = sumByQuantity(warehouseProof.sizes) * storagePrices[storageCategory];
  const categoryBreakdown = {
    name: storageCategoryNames[storageCategory] === 'Pack' ? 'Pack' : `${storageCategoryNames[storageCategory]} item`,
    price: storagePrices[storageCategory],
    quantity: sumByQuantity(warehouseProof.sizes),
    totalPrice
  };

  const productWithInactiveSizes = products.find(product => product.id === warehouseProof.proof.id);
  const allSizes = mapWarehouseProofSizesWithActiveField(sortedSizes, productWithInactiveSizes);

  return (
    <Grid container className={classes.warehouseProduct} role="grid">
      <InformationRow
        product={warehouseProof.proof.product}
        name={warehouseProof.proof.product.name}
        info={
          <>
            Storing qty: <span className={classes.productWarehouseTotal}>{sumByQuantity(warehouseProof.sizes)}</span>
          </>
        }
      >
        <Grid
          item
          xs={3}
          container
          direction="column"
          justifyContent="space-around"
          style={{ height: '100%', textAlign: 'right' }}
        >
          <WarehousePriceTooltip storageCategories={[categoryBreakdown]}>
            <Grid container alignItems="center" justifyContent="flex-end">
              <InfoOutlined
                titleAccess={`Storage price breakdown for ${warehouseProof.proof.product.name}`}
                className={classes.infoIcon}
              />
              <p className={classes.greyText} style={{ fontSize: 12, justifySelf: 'flex-end' }}>
                Storage price
              </p>
            </Grid>
          </WarehousePriceTooltip>
          <p className={classes.productWarehouseInfo}>{moneyStr(totalPrice)}</p>
        </Grid>
      </InformationRow>
      <Grid container className={classes.quantitiesList}>
        {allSizes.map(({ size, quantity, name, active }) => (
          <Grid key={size} className={classes.quantityContainer}>
            <p className={classes.greyText} style={{ fontSize: 12 }}>
              {name}
            </p>
            <p className={!active ? classes.quantityWarning : classes.quantity}>{quantity}</p>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export const getQuantityPerCategory = warehouseProofs =>
  mapValues(
    groupBy(warehouseProofs, wp => wp.proof.product.storage_category),
    group => sumBy(group, wp => sumByQuantity(wp.sizes))
  );

const WarehouseOverview = ({ open, onClose, onUpdateQuantities }) => {
  const classes = useStyles();

  const { warehouseProofs, warehouseProofsWithInactiveSizes } = useOrder();
  const { storagePrices, storageCategoryNames } = useStorageInfo();

  const quantityPerCategory = getQuantityPerCategory(warehouseProofs.filter(wp => sumByQuantity(wp.sizes) > 0));

  const storageCategories = Object.keys(quantityPerCategory).map(category => ({
    name: storageCategoryNames[category] === 'Pack' ? 'Pack' : `${storageCategoryNames[category]} item`,
    price: storagePrices[category],
    quantity: quantityPerCategory[category],
    totalPrice: quantityPerCategory[category] * storagePrices[category]
  }));

  const proofs = sortBy(
    warehouseProofs.filter(wp => sumByQuantity(wp.sizes) > 0),
    wp => wp.proof.id
  ).map(wp => (
    <WarehouseProductDetails
      key={wp.id}
      warehouseProof={wp}
      hasInactiveSize={warehouseProofsWithInactiveSizes.has(wp.id)}
    />
  ));

  const total = sumBy(warehouseProofs, wp => sumByQuantity(wp.sizes));
  const price = sumBy(
    warehouseProofs,
    wp => sumByQuantity(wp.sizes) * storagePrices[wp.proof.product.storage_category]
  );

  return (
    <Drawer open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <h2 className={classes.title}>Products to store in Warehouse</h2>
      {warehouseProofsWithInactiveSizes.size > 0 && <InactiveSizesAlert itemText="items" />}
      {proofs}
      <Grid container justifyContent="space-between" style={{ marginTop: 36 }}>
        <Grid>
          <p className={classes.greyText} style={{ fontSize: 14 }}>
            Total Quantity
          </p>
          <p className={classes.summaryInfo}>{total}</p>
        </Grid>
        <Grid style={{ textAlign: 'right' }}>
          <WarehousePriceTooltip storageCategories={storageCategories}>
            <Grid container alignItems="center">
              <InfoOutlined
                titleAccess="Total storage price breakdown"
                className={classes.infoIcon}
                style={{ fontSize: 14 }}
              />
              <p className={classes.greyText} style={{ fontSize: 14 }}>
                Total Storage price
              </p>
            </Grid>
          </WarehousePriceTooltip>
          <p className={classes.summaryInfo}>{moneyStr(price)}</p>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 20 }}>
        <Button variant="text" onClick={onUpdateQuantities} className={classes.button}>
          Update Quantities
        </Button>
      </Grid>
    </Drawer>
  );
};

export default WarehouseOverview;
