import * as React from 'react';
import { Box, Grid, makeStyles, withStyles } from '@material-ui/core';
import { Button, Tab, Tabs, Typography } from '@swagup-com/components';
import SwipeableViews from 'react-swipeable-views';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useCallback } from 'react';
import { InfoRounded } from '@material-ui/icons';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePaginatedQuery, useProfile, useQueryParams } from '../../hooks';
import ZapierIntegration from './ZapierIntegration';
import NativeIntegrations from './NativeIntegrations';
import NativeIntegrationsSettings from './NativeIntegrationsSettings';
import { SearchField } from '../products/commonProductsElements';
import { integrationsApi } from '../../apis/swagup';
import apiPaths from '../../helpers/apiPaths';
import useMembershipCheck from '../../hooks/useMembershipCheck';
import useIntegrations from '../../hooks/useIntegrations';
import TypeformDialog from '../global/TypeformDialog';
import {
  IntegrationTrialExpiredExtendedModal,
  IntegrationTrialExpiredModal,
  IntegrationTrialWarningBanner,
  IntegrationTrialWarningModal
} from './common';
import useIntegrationFreeTrial from './hooks/useIntegrationFreeTrial';
import { SET_INTEGRATIONS_TRIAL_MODAL_CLOSE, closeModal, setTabValue } from '../../actions/types';

const styles = theme => ({
  swipeableViews: {
    height: '100%',
    width: '100%',
    // minHeight: 'calc(100vh - 202px)',
    '& .react-swipeable-view-container': {
      height: '100%'
    },
    '& > div > div': {
      overflowX: 'hidden !important',
      overflowY: 'hidden !important'
    }
  },
  needSyncBox: {
    maxWidth: 448,
    background: '#EBF1FB',
    border: '1px solid #3577D4',
    borderRadius: 4,
    padding: '8px 16px 8px 16px'
  },
  infoIcon: {
    width: 16,
    height: 16,
    color: '#3577D4'
  }
});

const useStyles = makeStyles(styles);

const CustomTab = withStyles({
  root: {
    minWidth: 30,
    padding: 0,
    margin: '0 16px',
    '&:first-of-type': {
      marginLeft: 0
    },
    '&:last-of-type': {
      marginRight: 0
    }
  }
})(Tab);

const SwipeableViewPanel = ({ id, selectedValue, children }) => <Box>{id === selectedValue && children}</Box>;

const perPageOptions = [15, 24, 36, 42];

const NativeIntegrationsHome = () => {
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValueLocal] = React.useState('native');
  const [openModal, setOpenModal] = React.useState(false);

  const dispatch = useDispatch();
  const integrationFreeTrialModalStatus = useSelector(state => state.integrationFreeTrialModalStatus);
  const openExpired =
    integrationFreeTrialModalStatus.isOpen ||
    integrationFreeTrialModalStatus.lastOpenedDate !== dayjs().format('MM/DD/YYYY');

  const handleCloseTrialModal = () => {
    dispatch({ type: SET_INTEGRATIONS_TRIAL_MODAL_CLOSE });
  };

  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();

  const tab = query.get('tab');

  const search = query.get('search') || '';

  const {
    query: { data: integrationsData, isFetching: isLoading },
    pagination
  } = usePaginatedQuery({
    queryKey: [apiPaths.integrations, search],
    queryFn: (limit, offset) => {
      return integrationsApi.fetch(search, limit, offset, '-connected,name');
    },
    perPageOptions
  });

  const integrations = integrationsData?.results || [];

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const onTabsChange = newValue => {
    query.set('tab', newValue);
    dispatch(setTabValue(newValue));

    // if (newValue === 'settings') {
    //   query.set('per_page', '6');
    // }
    // else {
    //   query.set('per_page', '16');
    // }
    history.replace({ ...location, search: query.toString() });
  };

  React.useEffect(() => {
    if (tab) {
      setTabValueLocal(tab);

      // if (tab === 'settings') {
      //   query.set('per_page', '6');
      // }
      // if (tab === 'native') {
      //   query.set('per_page', '16');
      // }
      // history.replace({ ...location, search: query.toString() });
    }
  }, [tab]);

  const searchByQueryParam = useCallback(
    value => {
      query.set('search', value);
      history.replace(`${history.location.pathname}?${query.toString()}`);
    },
    [query, history]
  );

  const { connectedPlatforms } = useIntegrations();
  const allowSettings = connectedPlatforms.length > 0;

  const membershipCheck = useMembershipCheck();
  const integrationFreeTrial = useIntegrationFreeTrial();
  const profileApi = useProfile();
  const { data: profile } = profileApi;

  const classes = useStyles();

  const openIntegrationModal = useSelector(state => state.integrationNavBarReducer);
  const { leftBarNavigation } = useFlags();

  const integrationBanner = () => {
    switch (true) {
      case integrationFreeTrial.showExpired:
        return (
          <IntegrationTrialExpiredModal
            integrationFreeTrial={integrationFreeTrial}
            profile={profile}
            open={openExpired}
            handleOnClose={handleCloseTrialModal}
          />
        );
      case integrationFreeTrial.showWarning:
        return <IntegrationTrialWarningBanner integrationFreeTrial={integrationFreeTrial} profile={profile} />;
      case !membershipCheck.integrationsAllowed && tabValue === 'native':
        return (
          <Box className={classes.needSyncBox}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <InfoRounded className={classes.infoIcon} />
              </Grid>
              <Grid item xs>
                <Typography variant="body4RegularInter">
                  Try any native integration for a FREE 30 day trial! After 30 days, you will need to be a SwagUp member
                  to continue using. <br />
                  <a href="https://www.swagup.com/membership" target="_blank" rel="noreferrer">
                    Learn more about memberships here.
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  };

  const IntegrationHeader = () => {
    return (
      <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: 24 }}>
        <Grid item xs={2}>
          <Typography variant="h2BoldInter" style={{ height: 56 }}>
            Integrations
          </Typography>
        </Grid>
        <Grid item xs={2} container justifyContent="flex-end">
          {tabValue === 'zapier' && (
            <Button variant="primary" style={{ minWidth: 228, height: 56 }} onClick={() => setOpen(true)}>
              Create an Automation
            </Button>
          )}
          {tabValue === 'native' && (
            <Button variant="primary" style={{ minWidth: 260, height: 56 }} onClick={handleOpenModal}>
              Request a New Integration
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container alignItems="center">
        {!leftBarNavigation && <IntegrationHeader />}
        <Grid item xs>
          <Grid container justifyContent="center">
            <Grid item>{integrationBanner()}</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs>
          <Tabs value={tabValue} onChange={(e, newValue) => onTabsChange(newValue)}>
            <CustomTab label="Native" value="native" />
            <CustomTab label="Zapier" value="zapier" />
            {allowSettings && <CustomTab label="Settings" value="settings" />}
          </Tabs>
        </Grid>
        <Grid item>
          {(tabValue === 'native' || tabValue === 'settings') && (
            <SearchField
              key="search"
              placeholder="Search By Name"
              onChange={searchByQueryParam}
              rounded
              lean
              inverseHover
            />
          )}
        </Grid>
      </Grid>
      <SwipeableViews
        axis="x"
        index={['native', 'zapier', 'settings'].indexOf(tabValue)}
        className={classes.swipeableViews}
        disabled
      >
        <SwipeableViewPanel id="native" selectedValue={tabValue}>
          <NativeIntegrations integrations={integrations} pagination={pagination} />
        </SwipeableViewPanel>
        <SwipeableViewPanel id="zapier" selectedValue={tabValue}>
          {leftBarNavigation ? (
            <ZapierIntegration
              open={openIntegrationModal.tabValue == 'zapier' && openIntegrationModal.openModal}
              setOpen={() => {
                dispatch(closeModal());
              }}
            />
          ) : (
            <ZapierIntegration open={open} setOpen={setOpen} />
          )}
        </SwipeableViewPanel>
        {allowSettings && (
          <SwipeableViewPanel id="settings" selectedValue={tabValue}>
            <NativeIntegrationsSettings integrations={integrations} pagination={pagination} />
          </SwipeableViewPanel>
        )}
      </SwipeableViews>
      {leftBarNavigation ? (
        <TypeformDialog
          open={openIntegrationModal.tabValue == 'native' && openIntegrationModal.openModal}
          onClose={() => dispatch(closeModal())}
          typeformId="native-typeform"
          typeformSrc="https://swagup.typeform.com/to/vcovQCby"
        />
      ) : (
        <TypeformDialog
          open={openModal}
          onClose={handleCloseModal}
          typeformId="native-typeform"
          typeformSrc="https://swagup.typeform.com/to/vcovQCby"
        />
      )}
    </>
  );
};

export default NativeIntegrationsHome;
