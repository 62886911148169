import React from 'react';
import { FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { MenuItem, Typography } from '@swagup-com/components';
import { ColorInput, PresetTemplate, RedeemCustomSelectField } from '../redeemCommon';
import styles from '../styles/redeemPagesHome';
import { isHexColor } from '../../shared/styles/utils';

const useStyles = makeStyles(styles);

const PageTheme = ({ page, setPage, isThemeSelected, lightTheme, darkTheme, fontFamilies, themeVars }) => {
  const classes = useStyles();
  return (
    <Grid container style={{ overflow: 'hidden' }}>
      <Grid item xs={12}>
        <PresetTemplate
          onSelect={() => setPage({ ...page, ...lightTheme })}
          selected={isThemeSelected(lightTheme)}
          name="Light Theme"
          subtext="Let’s go to the beach and enjoy the sun."
          image="lighttheme"
        />
      </Grid>
      <Grid item xs={12}>
        <PresetTemplate
          onSelect={() => setPage({ ...page, ...darkTheme })}
          selected={isThemeSelected(darkTheme)}
          name="Dark Theme"
          subtext="Let’s head to space and go to the moon!"
          image="darktheme"
        />
      </Grid>
      <Grid item xs={12}>
        <PresetTemplate
          onSelect={() => setPage(p => ({ ...p, theme: 'custom', accentColor: p.accentColor.toLocaleLowerCase() }))}
          selected={!isThemeSelected(darkTheme) && !isThemeSelected(lightTheme)}
          name="Custom Theme"
          subtext="Feeling adventurous? Customize the look of your redeem page. "
          image="customtheme"
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <RedeemCustomSelectField
                  id="fontFamily"
                  name="fontFamily"
                  label="Font Family"
                  value={page.fontFamily || 'Font Family'}
                  className={classes.selectField}
                  totalItems={fontFamilies.length}
                  handleSelect={({ target: { value } }) => setPage(p => ({ ...p, fontFamily: value }))}
                  fullWidth
                  withTooltip
                >
                  {fontFamilies.map(ff => (
                    <MenuItem key={ff.value} value={ff.value} title={ff.label}>
                      {ff.label}
                    </MenuItem>
                  ))}
                </RedeemCustomSelectField>
              }
              label={
                <Typography variant="body3RegularInter" className={classes.inputLabel}>
                  Font Family
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Grid container spacing={10}>
              {themeVars.map(tv => (
                <Grid item xs={4} key={tv.key}>
                  <FormControlLabel
                    className={classes.formControl}
                    labelPlacement="top"
                    control={
                      <ColorInput
                        value={page[tv.key].toLocaleUpperCase()}
                        onChange={color => setPage(p => ({ ...p, [tv.key]: color }))}
                        inputClass={isHexColor(page[tv.key]) ? classes.inputColorText : classes.inputColorTextError}
                      />
                    }
                    label={
                      <Typography variant="body3RegularInter" className={classes.inputLabel}>
                        {tv.label}
                      </Typography>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageTheme;
