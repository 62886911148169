import React from 'react';
import { useAuth } from '../global/Authentication/AuthProvider';
import { Helmet } from '../shared';
import tags from '../../apis/seoTags';
import DashboardLoggedOut from './DashboardLoggedOut';
import DashboardSummary from './DashboardSummary';

const DashboardHome = () => {
  const { isAuthenticated, isPending } = useAuth();
  return (
    !isPending && (
      <>
        <Helmet tags={isAuthenticated ? tags.dashboardLoggedIn : tags.dashboardLoggedOut} />
        {isAuthenticated ? <DashboardSummary /> : <DashboardLoggedOut />}
      </>
    )
  );
};

export default DashboardHome;
