import * as React from 'react';
import { Grid, makeStyles, withStyles } from '@material-ui/core';
import { Button, Tab, Tabs, Typography } from '@swagup-com/components';
import SwipeableViews from 'react-swipeable-views';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useQueryParams } from '../../hooks';
import ZapierIntegration from './ZapierIntegration';
import NativeIntegrations from './NativeIntegrations';
import NativeIntegrationsSettings from './NativeIntegrationsSettings';

const styles = theme => ({
  swipeableViews: {
    height: '100%',
    width: '100%',
    // minHeight: 'calc(100vh - 202px)',
    '& .react-swipeable-view-container': {
      height: '100%'
    },
    '& > div > div': {
      overflowX: 'hidden !important',
      overflowY: 'hidden !important'
    }
  }
});

const useStyles = makeStyles(styles);

const CustomTab = withStyles({
  root: {
    minWidth: 30,
    padding: 0,
    margin: '0 16px',
    '&:first-of-type': {
      marginLeft: 0
    },
    '&:last-of-type': {
      marginRight: 0
    }
  }
})(Tab);

const NativeIntegrationsHome = () => {
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState('native');
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();

  const tab = query.get('tab');
  const onTabsChange = newValue => {
    query.set('tab', newValue);
    history.replace({ ...location, search: query.toString() });
  };

  React.useEffect(() => {
    if (tab) setTabValue(tab);
  }, [tab]);

  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center" style={{ marginBottom: 24 }}>
        <Grid item xs>
          <Typography variant="h2BoldInter" style={{ height: 56 }}>
            Integrations
          </Typography>
        </Grid>
        <Grid item>
          {tabValue === 'zapier' && (
            <Button variant="primary" style={{ minWidth: 180, height: 56 }} onClick={() => setOpen(true)}>
              Create an Automation
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Tabs value={tabValue} onChange={(e, newValue) => onTabsChange(newValue)}>
          <CustomTab label="Native" value="native" />
          <CustomTab label="Zapier" value="zapier" />
          <CustomTab label="Settings" value="settings" />
        </Tabs>
      </Grid>
      <SwipeableViews
        axis="x"
        index={['native', 'zapier', 'settings'].indexOf(tabValue)}
        className={classes.swipeableViews}
        disabled
      >
        <NativeIntegrations />
        <ZapierIntegration open={open} setOpen={setOpen} />
        <NativeIntegrationsSettings />
      </SwipeableViews>
    </>
  );
};

export default NativeIntegrationsHome;
