import * as React from 'react';
import { ArrowBack as ArrowBackIcon, HelpOutline as HelpOutlineIcon } from '@material-ui/icons';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { changeColorLightness } from './styles/utils';

const useStyles = makeStyles({
  topBar: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
    backgroundColor: 'white',
    borderBottom: '1px solid #f0f2f5',
    minHeight: 80,
    padding: '0 80px'
  },
  goBackBtn: {
    height: 16,
    minWidth: 16,
    padding: 0,
    marginRight: 16
  },
  goBackIcon: {
    color: '#787b80',
    cursor: 'pointer',
    fontSize: 16
  },
  helpLink: {
    color: '#3577d4',
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  helpText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '20px',
    marginLeft: 4
  },
  infoIcon: { color: 'inherit' }
});

const TopBar = ({ children, onGoBack }) => {
  const classes = useStyles();
  const { leftBarNavigation } = useFlags();
  return (
    <Grid container alignItems="center" justifyContent="space-between" className={classes.topBar}>
      {!leftBarNavigation && (
        <>
          <Box display="flex" alignItems="center">
            {onGoBack && (
              <Button variant="text" onClick={onGoBack} className={classes.goBackBtn}>
                <ArrowBackIcon className={classes.goBackIcon} />
              </Button>
            )}
            {children || <img src="/images/public/su-logo.svg" alt="logo" />}
          </Box>
          <a href="https://support.swagup.com" target="_blank" rel="noopener noreferrer" className={classes.helpLink}>
            <IconButton size="small" className={classes.infoIcon}>
              <HelpOutlineIcon style={{ fontSize: 14 }} />
            </IconButton>
            <span className={classes.helpText}>Need Help?</span>
          </a>
        </>
      )}
    </Grid>
  );
};

export default TopBar;
