import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import React from 'react';
import ArrowTooltip from '../../pages/orders/requested/common/Tooltip';
import { CustomTextField, ImageSelector } from '../redeemCommon';
import styles from '../styles/redeemPagesHome';

const useStyles = makeStyles(styles);

const PageBasics = ({ page, company, setPage, handleFileUpload, artworkLoader, onChange }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body3RegularInter" className={classes.inputLabel}>
          Page Name
          {page.hasBeenPublished ? <span className={classes.readonly}>Read only</span> : <strong>*</strong>}
        </Typography>
        <CustomTextField
          key="projectName"
          className={classes.inputText}
          placeholder="Redeem Page Name"
          value={page.projectName}
          name="projectName"
          onChange={onChange}
          fullWidth
          disabled={page.hasBeenPublished}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 20 }}>
        <Typography variant="body3RegularInter" className={classes.inputLabel}>
          Company Name
        </Typography>
        <CustomTextField
          key="companyName"
          className={classes.inputText}
          placeholder="Company Name"
          value={page.companyDisplayName || ''}
          name="companyDisplayName"
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 24 }}>
        <Grid container>
          <Grid item>
            <div>
              <Typography variant="body3RegularInter" className={classes.inputLabel}>
                Choose Logo
              </Typography>
            </div>
          </Grid>
        </Grid>
        <ImageSelector
          imageName={page.clientLogo || company.logo}
          selected={!!page.clientLogo}
          onSelect={selected => setPage(p => ({ ...p, clientLogo: selected ? '' : company.logo }))}
          artworkLoader={artworkLoader}
          url={page.clientLogo}
          property="clientLogo"
          handleFileUpload={handleFileUpload}
        />
      </Grid>
    </Grid>
  );
};

export default PageBasics;
