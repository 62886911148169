import { SELECT_INVOICE, SELECT_PUBLIC_INVOICE } from '../actions/types';

const SelectedInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECT_INVOICE:
      return action.payload;
    case SELECT_PUBLIC_INVOICE:
      return action.payload;

    default:
      return state;
  }
};

export default SelectedInvoiceReducer;
