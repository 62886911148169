import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { paymentMethods } from '../../apis/constants';
import CreditCardItem from './CreditCardItem';

const CreditCard = ({ paymentProfiles, setContinuePaymentMethod, handleCardInfoChange, setPaymentProfileSelected }) => {
  const [selectedCreditCard, setSelectedCreditCard] = useState(null);
  const [creditCards, setCreditCards] = useState(null);

  const setCreditCardFieldState = () => handleCardInfoChange(true);

  const paymentProfileById = cardId =>
    paymentProfiles.find(
      p => p.payment_method === paymentMethods.creditCard && p.customer_payment_profile_id === cardId
    );

  const toggleSelectedCreditCard = (value, initial = false) => {
    if (selectedCreditCard === value && !initial) {
      setSelectedCreditCard(null);
      setContinuePaymentMethod(false);
    } else {
      setSelectedCreditCard(value);
      setPaymentProfileSelected(paymentProfileById(value));
      setContinuePaymentMethod(true);
    }
    setCreditCardFieldState();
  };

  useEffect(() => {
    setContinuePaymentMethod(false);
    if (!isEmpty(paymentProfiles)) {
      const cCards = paymentProfiles
        .filter(p => p.payment_method === paymentMethods.creditCard)
        .map(p => ({
          ...p.credit_card,
          ...{ customerPaymentProfileId: p.customer_payment_profile_id }
        }));
      setCreditCards(cCards);
      const defaultCreditCard = paymentProfiles.find(p => p.payment_method === paymentMethods.creditCard && p.default);
      if (defaultCreditCard) toggleSelectedCreditCard(defaultCreditCard.customer_payment_profile_id, true);
    }
    return () => setContinuePaymentMethod(true);
  }, [paymentProfiles]);

  return (
    <Grid container>
      {creditCards &&
        creditCards.map(creditCard => (
          <CreditCardItem
            key={creditCard.customerPaymentProfileId}
            creditCard={creditCard}
            selectedCreditCard={selectedCreditCard}
            toggleSelectedCreditCard={toggleSelectedCreditCard}
          />
        ))}
    </Grid>
  );
};

const mapStateToProps = state => ({
  paymentProfiles: state.paymentProfiles
});

export default connect(mapStateToProps)(CreditCard);
