import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './CategoriesSidebarItem.styles';

const useStyles = makeStyles(styles);

const CategoriesSidebarItem = ({ category, active, onClick }) => {
  const selected = useSelector(state =>
    category.items.some(p1 => state.cart.items?.some(p2 => p1.id === p2.product.id))
  );

  const classes = useStyles({ active, selected });
  const { catalogCollections } = useFlags();

  return (
    <>
      <ListItem button key={category.id} onClick={onClick} className={classes.root}>
        {!catalogCollections && (
          <ListItemIcon className={classes.icon}>
            <img src={active ? category.iconActive : category.icon} alt={category.CatName} />
          </ListItemIcon>
        )}
        <ListItemText>
          <Typography className={classes.text}>{category.CatName}</Typography>
        </ListItemText>
      </ListItem>
      <div
        className="divider"
        style={{ height: '1px', width: '100%', backgroundColor: '#E9F2FF', margin: '0px 5px 0px 5px' }}
      />
    </>
  );
};

export default CategoriesSidebarItem;
