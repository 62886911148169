import { useQuery } from 'react-query';
import { invoicesApi } from '../apis/swagup';
import { fifteenMinutes } from '../helpers/helperConstants';
import apiPaths from '../helpers/apiPaths';

const useInvoice = (id, options) => {
  return useQuery([apiPaths.invoices, id], () => invoicesApi.fetch(id), {
    enabled: Boolean(id),
    staleTime: fifteenMinutes,
    select: data => data.data,
    ...options
  });
};

export { useInvoice };
