import React from 'react';
import { Grid, withStyles, Tabs, Tab } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { paymentMethods } from '../../apis/constants';
import { useIsAchPrepaid } from '../../hooks';
import CreditCardItem from './CreditCardItem';
import ACHItem from './ACHItem';
import NewPaymentMethod from './NewPaymentMethod';
import styles from '../global/styles/PaymentSection';

const PaymentCard = ({ defaultProfile, selectedDefaultProfile, toggleDefaultProfile }) =>
  defaultProfile ? (
    <Grid item container xs={12}>
      {defaultProfile.payment_method === paymentMethods.creditCard ? (
        <Grid item xs={12}>
          <CreditCardItem
            key={defaultProfile.customer_payment_profile_id}
            creditCard={defaultProfile.credit_card}
            selectedCreditCard={defaultProfile.credit_card}
            toggleDefaultProfile={toggleDefaultProfile}
            isDefault={selectedDefaultProfile}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <ACHItem
            key={defaultProfile.customer_payment_profile_id}
            aCH={defaultProfile.bank_account}
            selectedBankAccount={defaultProfile.bank_account}
            toggleDefaultProfile={toggleDefaultProfile}
            isDefault={selectedDefaultProfile}
          />
        </Grid>
      )}
    </Grid>
  ) : null;

const ChangeButton = ({ toggleOpenPaymentOption, classes }) => (
  <Button variant="text" onClick={toggleOpenPaymentOption} className={classes.buttonChange}>
    Change
  </Button>
);

const PaymentOptions = ({
  activeTab,
  tabs,
  handleChangeTab,
  defaultProfile,
  selectedDefaultProfile,
  toggleDefaultProfile,
  showTabInfo,
  areProfilesInActiveTab,
  toggleOpenPaymentOption,
  title,
  setOpenContactModal,
  setOpenPaymentOptionModal,
  canAddPaymentProfiles,
  classes
}) => {
  const isAchPrepaid = useIsAchPrepaid();
  const indexActiveTabs = isAchPrepaid ? 1 : 0;

  return (
    <Grid item container style={{ marginTop: 15 }}>
      <Grid item xs={12}>
        <Tabs value={activeTab} classes={{ indicator: classes.tabsIndicator }} variant="fullWidth">
          {tabs.asArray().map((tab, index) => (
            <Tab
              key={tab.title}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              disableRipple
              label={tab.title}
              onClick={() => handleChangeTab(index, tab.paymentMethod)}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 20 }}>
        {activeTab <= indexActiveTabs ? (
          <Grid container>
            {areProfilesInActiveTab ? (
              <Grid container>
                {showTabInfo && (
                  <>
                    <PaymentCard
                      defaultProfile={defaultProfile}
                      selectedDefaultProfile={selectedDefaultProfile}
                      toggleDefaultProfile={toggleDefaultProfile}
                    />
                    <ChangeButton toggleOpenPaymentOption={toggleOpenPaymentOption} classes={classes} />
                  </>
                )}
              </Grid>
            ) : (
              <Grid item container xs={12}>
                {canAddPaymentProfiles && (
                  <NewPaymentMethod
                    title={title}
                    setOpenContactModal={setOpenContactModal}
                    setOpenPaymentOptionModal={setOpenPaymentOptionModal}
                  />
                )}
              </Grid>
            )}
          </Grid>
        ) : (
          tabs.activeTab().content()
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PaymentOptions);
