import apiPaths from '../../helpers/apiPaths';
import api from '../DashBoard';
import { status200, status201 } from './status.utils';
import { handleResponse } from './response.utils';

export default {
  getSummaries: () => handleResponse('getSummaries', api.get(apiPaths.creditsSummaries, status200)),

  addCredits: creditInfo =>
    api.post(apiPaths.credits, creditInfo, status201).then(response => {
      if (!response.data.success) {
        throw new Error(response.data.error_message);
      }
      return response.data;
    }),

  payment: paymentInfo =>
    api.post(apiPaths.payment, paymentInfo, status201).then(response => {
      if (!response.data.success) {
        throw new Error(response.data.error_message);
      }
      return response.data;
    }),

  transactions: params => api.get(`${apiPaths.transactions}${params || ''}`, status200).then(response => response.data),

  changeDefaultProfile: (accountId, profileId) =>
    api.patch(apiPaths.accountPaymentProfile(accountId, profileId), {
      default: true
    })
};
