import log from '../../logger';

export const okAndLog = (msg, status, data) => {
  const resp = { result: 'ok', status, data };
  log.debug(msg, resp);
  return resp;
};

export const errorAndLog = (msg, status, data) => {
  const resp = { result: 'error', status, data };
  log.error(msg, resp);
  return resp;
};

export const handleError = (msg, e) => {
  const { status, data } = e.response || { data: e };
  return errorAndLog(msg, status, data);
};

export const handleResponse = (msg, apiCall) =>
  apiCall.then(resp => okAndLog(msg, resp.status, resp.data)).catch(e => handleError(msg, e));
