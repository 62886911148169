export default {
  dialog: {
    width: '100%',
    maxWidth: 780,
    paddingBottom: 40
  },
  titleSection: {
    textAlign: 'center',
    position: 'relative',
    padding: '36px 24px'
  },
  title: {
    color: '#0b1829',
    lineHeight: 'normal'
  },
  subTitle: { color: '#868a8f' },
  iconButton: {
    position: 'absolute',
    width: 18,
    height: 18,
    top: 24,
    right: 24
  },
  icon: { fontSize: 14 },
  contentSection: { padding: '0 24px' },
  link: {
    width: 236,
    height: 270,
    borderRadius: 20,
    border: 'solid 1px #f0f2f5',
    backgroundColor: '#fff',
    marginBottom: 20,
    padding: '9px 40px 26px 40px',
    textAlign: 'center',
    '&:hover': {
      border: 'solid 1px #3577d4'
    }
  }
};
