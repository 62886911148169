import {
  CREATE_REORDER_PRODUCTS,
  SET_REORDER_PRODUCTS_DATA,
  TOGGLE_REORDER_PRODUCT_DETAILS,
  REMOVE_REORDER_PRODUCTS
} from '../actions/types';

const ReorderProductsReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_REORDER_PRODUCTS:
      return action.payload;

    case SET_REORDER_PRODUCTS_DATA: {
      const updatedProductsMap = new Map(action.payload.map(up => [up.id, up]));
      return state.map(reorderProduct => {
        const updatedProduct = updatedProductsMap.get(reorderProduct.id);

        if (!updatedProduct) {
          return reorderProduct;
        }

        const { items, ...rest } = updatedProduct;
        const newReorderProduct = {
          ...reorderProduct,
          ...rest
        };
        return items.length > 0
          ? {
              ...newReorderProduct,
              product: { ...reorderProduct.product, items }
            }
          : newReorderProduct;
      });
    }

    case TOGGLE_REORDER_PRODUCT_DETAILS:
      return state.map(reorderProduct =>
        reorderProduct.id === action.payload
          ? {
              ...reorderProduct,
              showDetails: !reorderProduct.showDetails
            }
          : reorderProduct
      );

    case REMOVE_REORDER_PRODUCTS:
      return state.filter(reorderProduct => !action.payload.has(reorderProduct.id));

    default:
      return state;
  }
};

export default ReorderProductsReducer;
