import { FETCH_INDIVIDUAL_PACK_PRICES } from '../actions/types';

const ShippingIndividualPacksPricesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INDIVIDUAL_PACK_PRICES:
      return !action.payload
        ? state
        : {
            ...state,
            ...action.payload
          };

    default:
      return state;
  }
};

export default ShippingIndividualPacksPricesReducer;
