import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import { Link, useLocation } from 'react-router-dom';
import { Helmet, CenteredGrid } from '../shared';
import accountProductsApi from '../../apis/swagup/accountProducts';
import tags from '../../apis/seoTags';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import global from '../../apis/swagup/global';
import apiPaths from '../../helpers/apiPaths';
import InventoryListItem from './InventoryListItem';
import Loader from '../global/Loader';
import { useQueryParams } from '../../hooks';
import { creditApi } from '../../apis/swagup';

const styles = theme => ({
  container: {
    marginTop: 0,
    paddingTop: 40,
    paddingBottom: 42,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  titleContainer: {
    [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
  },
  modalViewDetailsContainer: {
    borderRadius: 12,
    background: 'white',
    padding: '0px 1px 12px 1px'
  },
  automationModal: {
    height: 'auto',
    maxHeight: 'fit-content',
    padding: 0,
    marginTop: '112px',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: 'white',
    width: 1440,
    overflow: 'hidden',
    [theme.breakpoints.only('lg')]: {
      width: 1200
    },
    [theme.breakpoints.only('md')]: {
      width: 900
    },
    [theme.breakpoints.only('sm')]: {
      width: 600
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  automationModalHeader: {
    padding: '0px 4px 0px 32px',
    height: '52px',
    borderBottom: '1px solid #E5E7E8'
  },
  automationModalHeaderText: {
    ...ellipsisStyles,
    color: '#131415',
    fontSize: '20px',
    lineHeight: ' 28px',
    letterSpacing: '-0.03em'
  },
  modalBody: {
    minHeight: '80vh'
  }
});

const useStyles = makeStyles(styles);

const StagnantInventory = () => {
  const classes = useStyles();
  const [absoluteStorageCost, setAbsoluteStorageCost] = React.useState(0);
  const [stagnantInventory, setStagnantInventory] = React.useState([]);
  const [selectedInventory, setSelectedInventory] = React.useState([]);
  const [sendSwagQuery, setSendSwagQuery] = React.useState('');

  const { data, isLoading } = useQuery(
    [apiPaths.accountProducts, { inventory: 'available', visible_in_inventory: true }],
    () => accountProductsApi.fetch({ inventory: 'available', visible_in_inventory: true })
  );

  const { results } = data || { results: [] };

  const { data: storagePrices, isLoading: storagePicesIsLoading } = useQuery(
    [apiPaths.storagePrices],
    global.fetchStoragePrices
  );

  const { data: transactionsData, isLoading: transactionsIsLoading } = useQuery([apiPaths.transactions], () =>
    creditApi.transactions('?codes__isnull=false')
  );

  const transactions = React.useMemo(() => transactionsData?.results || [], [transactionsData]);

  const wrapItemPrice = React.useCallback(
    item => {
      if (!isEmpty(storagePrices?.results)) {
        const itemCategory = storagePrices.results.find(sp => sp.storage_category === item.storage_category);
        const storagePrice = parseFloat(itemCategory.price);
        const totalQuantity = item.stock.reduce((sum, s) => sum + s.quantity, 0);

        return { ...item, storageCost: (totalQuantity * storagePrice).toFixed(2), totalQuantity, storagePrice };
      }
      return { ...item, storageCost: 0, totalQuantity: 0, storagePrice: 0 };
    },
    [storagePrices]
  );

  const itemInCodes = (codes, id) => !codes || codes.split('-').find(code => code === `${id}`);

  React.useEffect(() => {
    if (!isEmpty(results)) {
      const wrapped = results
        .filter(inv => !transactions.some(trans => itemInCodes(trans.codes, inv.id)))
        .map(inv => wrapItemPrice(inv));
      setStagnantInventory(wrapped);
      setAbsoluteStorageCost(wrapped.reduce((sum, w) => sum + parseFloat(w.storageCost), 0));
    }
  }, [storagePrices, results, wrapItemPrice, transactions]);

  const toogleSelect = item => {
    const selected = selectedInventory.find(si => si.id === item.id);
    if (selected) return setSelectedInventory(selectedInventory.filter(si => si.id !== item.id));
    return setSelectedInventory([...selectedInventory, item]);
  };

  const location = useLocation();
  const query = useQueryParams();

  React.useEffect(() => {
    query.set('selected', selectedInventory.map(si => si.id).join());
    setSendSwagQuery(query.toString());
  }, [query, selectedInventory]);

  React.useEffect(() => {
    if (isEmpty(selectedInventory)) {
      query.set('selected', stagnantInventory.map(si => si.id).join());
      setSendSwagQuery(query.toString());
    }
  }, [query, selectedInventory, stagnantInventory]);

  return (
    <>
      <Helmet tags={tags.integrations} />
      <CenteredGrid container justifyContent="center" className={classes.container}>
        <Grid container alignItems="center" style={{ marginBottom: 12 }}>
          <Grid item xs>
            <Typography variant="h2BoldInter">Stagnant Inventory</Typography>
          </Grid>
          <Grid item style={{ paddingRight: 12 }}>
            <Button
              variant="secondary"
              component={Link}
              to={{
                pathname: '/send-swag/select-products',
                search: sendSwagQuery,
                state: { from: location, selectedInventory }
              }}
              style={{ minWidth: 180, height: 56 }}
            >
              Send Swag
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="primary"
              component={Link}
              to={{
                pathname: '/storage-payment/',
                search: sendSwagQuery,
                state: { from: location, selectedInventory }
              }}
              style={{ minWidth: 180, height: 56 }}
            >
              Pay Storage
            </Button>
          </Grid>
          <Grid container alignItems="flex-end">
            <Grid item style={{ paddingTop: '12px' }}>
              {selectedInventory.length > 0 && (
                <Typography component="p" variant="body4RegularInter">
                  Selected: <strong>{selectedInventory.length}</strong>
                </Typography>
              )}
            </Grid>
            <Grid item xs />
            <Grid item style={{ paddingTop: '12px' }}>
              <Typography component="p" variant="body2MediumInter">
                Total Storage Cost: <strong>${absoluteStorageCost.toFixed(2)}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {stagnantInventory.map(item => (
              <InventoryListItem
                key={item.id}
                item={item}
                onSelect={() => toogleSelect(item)}
                selected={!!selectedInventory.find(si => si.id === item.id)}
              />
            ))}
          </Grid>
        </Grid>
        {(isLoading || storagePicesIsLoading || transactionsIsLoading) && <Loader absolute />}
      </CenteredGrid>
    </>
  );
};

export default StagnantInventory;
