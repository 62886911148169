import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMembership } from '../contexts/membershipContext';

const useMembershipCheck = () => {
  const [membershipCheck, setMembershipCheck] = useState({});
  const { currentMembership } = useMembership();
  const { shopsCreditsAllowed } = useFlags();

  useEffect(() => {
    if (currentMembership) {
      const isPlatinum =
        (currentMembership.id === 1 && currentMembership.name === 'Platinum') ||
        (currentMembership.id === 2 && currentMembership.name === 'Platinum');
      const isGold =
        (currentMembership.id === 2 && currentMembership.name === 'Gold') ||
        (currentMembership.id === 6 && currentMembership.name === 'Gold');
      const isSilver =
        (currentMembership.id === 3 && currentMembership.name === 'Silver') ||
        (currentMembership.id === 1 && currentMembership.name === 'Silver');
      const isBasic =
        (currentMembership.id === 4 && currentMembership.name === 'Basic' && currentMembership?.isFreeTier === true) ||
        (currentMembership.id === 7 && currentMembership.name === 'Basic' && currentMembership?.isFreeTier === true);

      setMembershipCheck({
        name: currentMembership.name,
        isPlatinum,
        isGold,
        isSilver,
        isBasic,
        shopsAllowed: currentMembership.swagup_shops,
        shopCreditsAllowed: currentMembership.shop_credit_gifting || shopsCreditsAllowed,
        integrationsAllowed: currentMembership.integrations
      });
    }
  }, [currentMembership]);

  return membershipCheck;
};

export default useMembershipCheck;
