import { arrowGenerator } from '../../../helpers/utils';

const styles = theme => ({
  packTitle: {
    fontFamily: 'Gilroy-bold',
    fontSize: 36,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px'
    }
  },
  packTitleSub: {
    fontFamily: 'Gilroy-bold',
    fontSize: '24px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'normal',
      fontSize: '14px',
      opacity: 0.64
    }
  },
  pack: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    color: '#8d9299',
    marginLeft: 4,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'normal',
      fontSize: '12px'
    }
  },
  packNote: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    color: '#434d5c',
    textAlign: 'left',
    marginTop: 54,
    borderRadius: 10,
    backgroundColor: 'rgba(235, 241, 251, 0.5)',
    padding: '10px 13px',
    width: 'max-content',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'normal',
      fontSize: '12px'
    }
  },
  packOptionsContent: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px 124px 0px 124px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: 24
    }
  },
  editPackIcon: {
    '& img': {
      marginTop: '5px'
    }
  },
  editPackTitle: {
    marginBottom: '17px',
    '& input': {
      fontSize: '24px',
      padding: '14.5px 14px'
    },
    '& fieldset': {
      borderRadius: '9px'
    }
  },
  startButton: {
    '& button': {
      width: '284px',
      height: '72px',
      textTransform: 'none',
      borderRadius: '36px'
    },
    '& span': {
      fontFamily: 'Gilroy-Bold',
      fontSize: '20px',
      fontWeight: 500
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        height: '48px',
        width: '244px'
      },
      '& span': {
        fontSize: '14px'
      }
    }
  },
  includesDescription: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.86',
    letterSpacing: 'normal',
    color: '#434d5c',
    '& p': { fontFamily: 'Gilroy-Medium', color: '#434d5c' }
  },
  includesTitle: {
    marginTop: 36,
    fontFamily: 'Gilroy-Bold',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434d5c',
    marginBottom: 16
  },
  arrowPopper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto 15px',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  bootstrapPopper: arrowGenerator(theme.palette.common.black),
  bootstrapTooltip: {
    backgroundColor: theme.palette.common.black
  },
  bootstrapPlacementLeft: {
    margin: '0 8px'
  },
  bootstrapPlacementRight: {
    margin: '0 8px'
  },
  bootstrapPlacementTop: {
    margin: '8px 0'
  },
  bootstrapPlacementBottom: {
    margin: '8px 0'
  },
  htmlPopper: arrowGenerator('#dadde9'),
  htmlTooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    '& b': {
      fontWeight: theme.typography.fontWeightMedium
    }
  }
});

export default styles;
