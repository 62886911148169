import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles/ImageWithDescription';
import TrackingInfo from './TrackingInfo';
import { ImgWithHandler } from '../../../global/ImgUtils';

const ImageWithDescription = ({
  width,
  height,
  classes,
  name,
  trackingUrl,
  tracking,
  color,
  quantity,
  extraValue,
  img,
  showTrackingUrl = true,
  size = 'large'
}) => (
  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
    <Grid item style={{ marginRight: 12 }}>
      <div style={{ height, width }}>
        <ImgWithHandler src={img} alt="" width={width} height={height} style={{ maxHeight: height, maxWidth: width }} />
      </div>
    </Grid>
    <Grid item xs={7}>
      <p className={size === 'small' ? classes.titleSmall : classes.title}>{name}</p>
      <p className={size === 'small' ? classes.secondaryTextSmall : classes.secondaryText}>
        {`Color: ${color || 'Custom'}`}
      </p>
      {extraValue && <p className={size === 'small' ? classes.normalTextSmall : classes.normalText}>{extraValue}</p>}
      {quantity && <p className={classes.normalText}>{`Qty: ${quantity}`}</p>}
      {showTrackingUrl && <TrackingInfo classes={classes} trackingUrl={trackingUrl} tracking={tracking} />}
    </Grid>
  </Grid>
);

export default withStyles(styles)(ImageWithDescription);
