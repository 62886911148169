import React from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';

const useOptionStyles = makeStyles({
  checkbox: {
    padding: 0,
    width: 12,
    height: 12,
    '& .MuiSvgIcon-root': {
      width: 12,
      height: 12
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#3577d4'
    }
  },
  root: {
    margin: 0,
    marginBottom: 16,
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: '#3577d4',
        backgroundColor: '#f4f8ff'
      }
    }
  },
  label: {
    display: 'inline-block',
    color: '#0B1829',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: '14px',
    minWidth: 25,
    marginLeft: 8
  }
});

const CheckBoxFormControl = ({ label, checked, onClick, rootClass, labelClass, ...props }) => {
  const classes = useOptionStyles({ checked });

  return (
    <FormControlLabel
      classes={{ root: rootClass || classes.root, label: labelClass || classes.label }}
      control={
        <Checkbox
          id={label}
          tabIndex="0"
          checked={checked}
          onChange={e => onClick(e.target.checked)}
          inputProps={{ 'aria-label': label, 'data-testid': label, ...props }}
          className={classes.checkbox}
        />
      }
      label={label}
    />
  );
};

export default CheckBoxFormControl;
