export default theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  navbarOpen: props => ({
    ...(props.isAuthenticated
      ? {
          '&>div:nth-child(2)': {
            width: 'calc(calc(100% - 300px))',
            marginLeft: '260px'
          },
          [theme.breakpoints.down('xs')]: {
            '&>div:nth-child(2)': {
              width: '100%',
              marginLeft: '0px'
            }
          }
        }
      : {})
  }),
  navbarClosed: {
    '&>div:nth-child(2)': {
      marginLeft: '160px',
      width: 'calc(100% - 240px)'
    },
    [theme.breakpoints.up('lg')]: {
      '&>div:nth-child(2)': {
        marginLeft: '120px',
        width: 'calc(100% - 160px)'
      }
    },
    [theme.breakpoints.down('xs')]: {
      '&>div:nth-child(2)': {
        width: '100%',
        marginLeft: '0px'
      }
    }
  }
});
