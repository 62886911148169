const common = {
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: '2',
  cursor: 'pointer',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
};
const styles = () => ({
  customWidthLoader: {
    position: 'absolute',
    ...common
  },
  fullScreenLoader: {
    position: 'fixed',
    ...common
  }
});

export default styles;
