import { scrollBar } from '../../shared/styles/commonStyles';

const styles = theme => ({
  title: { fontFamily: 'Gilroy-Bold', color: '#0B1829', fontSize: 36, lineHeight: '1' },
  header: { paddingBottom: 24, alignItems: 'center', background: '#FFFFFF' },
  manageMembership: { height: 40 },
  cancelMembership: { height: 40, color: '#4A4F54', border: 'none' },
  cancelMembershipCTA: { height: 40, color: '#3577D4', border: 'none' },
  membershipItem: {
    width: 254,
    border: '1px solid #EBEDF0',
    borderRadius: 10,
    padding: '14px 18px',
    background: '#FFFFFF'
  },
  membershipItemContent: { ...scrollBar, height: 312, padding: '0px 4px 6px 4px', margin: '0px -4px 24px -4px' },
  membershipItemBox: { height: '100%', marginLeft: 8, marginRight: 16, position: 'relative' },
  rewardItemText: { textAlign: 'center', color: '#999999' },
  swipeableViews: {
    height: '100%',
    width: '100%',
    // minHeight: 'calc(100vh - 202px)',
    '& .react-swipeable-view-container': {
      height: '100%'
    },
    '& > div > div': {
      overflowX: 'hidden !important',
      overflowY: 'hidden !important'
    }
  },
  yourMembershipWrapper: { border: '1px solid #719DFF', borderRadius: 12, padding: '4px 8px' },
  yourMembershipText: {
    color: '#125CFF',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Inter',
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  afterSwitching: {
    color: '#989EA4',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Inter',
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  yourMembershipWrapperCanditate: { background: '#FF9800', borderRadius: 12, padding: '4px 8px' },
  yourMembershipTextCanditate: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Inter',
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  membershipItemName: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    textWrap: 'wrap'
  },
  membershipItemDescription: {
    color: '#808080',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    textWrap: 'wrap'
  },
  membershipItemPrice: {
    color: '#125CFF',
    lineHeight: '32px',
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 4
  },
  membershipItemImageWrapper: {
    height: 56,
    width: 56,
    position: 'relative'
  },
  membershipItemImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  chevronRight: { color: '#4A4F54', height: 48, width: 48 },
  whatHappensNext: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
    marginBottom: 18
  },
  whatHappensNextItem: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    paddingInlineStart: '16px',
    '& span': {
      fontWeight: 600
    }
  },
  whatHappensNextDivider: { background: '#E5E7E8', height: 1, width: '100%', marginTop: 32, marginBottom: 56 },
  benefitName: {
    color: '#131415',
    fontSize: ({ fromItem }) => (fromItem ? '10px' : '12px'),
    fontFamily: 'Gilroy-Regular',
    lineHeight: '18px',
    textWrap: 'wrap'
  },
  checkCircleRounded: {
    color: ({ candidate }) => (candidate ? '#FF9800' : '#2BB572'),
    width: 16,
    height: 16,
    margin: 0,
    padding: 0
  },
  root: {
    width: '100%',
    position: 'relative',
    height: '100%',
    maxWidth: 'calc(100% - 64px)'
    // maxHeight: 'calc(100vh - 202px)',
    // maxWidth: 1440 - 232, // 232px the width of the Sidebar
    // [theme.breakpoints.only('lg')]: {
    //   width: 1300 - 232
    // },
    // [theme.breakpoints.only('md')]: {
    //   width: 1000 - 232
    // }
  },
  newRoot: {
    width: '100%',
    position: 'relative',
    height: '100%',
    // maxHeight: 'calc(100vh - 202px)',
    maxWidth: 1440, // 232px the width of the Sidebar
    [theme.breakpoints.only('lg')]: {
      width: 1200 - 232
    },
    [theme.breakpoints.only('md')]: {
      width: 900 - 232
    }
  },
  rewardItemValue: {
    color: '#4CAF50',
    textAlign: 'center'
  },
  subContainer: {
    justifyContent: 'center',
    alignItems: 'stretch'
  },
  mainContainer: {
    margin: '0px 0px 25px 0px',
    border: '1px solid #EBEDF0',
    borderRadius: '10px',
    padding: '10px'
    // height: '100%'
  },
  membershipStep: {
    position: 'relative',
    height: '100%'
  },
  membershipPanel: {
    maxWidth: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'relative',
    background: '#ffffff'
  },
  membershipManagementPanel: {
    maxWidth: '100%%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'relative',
    height: '100%',
    paddingTop: 32,
    background: '#72A0E114'
  },
  orderDisclaimerBox: { marginTop: 24 },
  orderDisclaimerText: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '-0.12px'
  },
  orderBox: {
    borderRadius: 5,
    border: '1px solid #B7BBBF',
    padding: '8px 12px',
    height: 64
  },

  membershipManagementConfirmationPanel: {
    maxWidth: '100%%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'relative',
    paddingLeft: 8,
    paddingTop: 32,
    backgroundImage: 'linear-gradient(180deg, #F2F6FF 40%, #FFFFFF 40%)'
  },
  confirmationBox: {
    width: 256,
    height: 305,
    background: '#FFFFFF',
    borderRadius: 10,
    border: '1px solid #EBEDF0',
    padding: '14px 20px'
  },
  confirmationBoxTitle: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    marginBottom: 6
  },
  confirmationBoxText: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '-0.12px'
  },
  yourMembership: {
    borderRight: '1px solid #E5E7E8',
    padding: '16px 24px',
    height: '100%'
  },
  floatingInfoMessage: {
    maxWidth: 464,
    zIndex: 99,
    width: 'auto',
    top: '-32px',
    position: 'absolute'
  },
  membershipImage: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 74,
    height: 74
  },
  membershipDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingBottom: '20px'
  },
  statusPanelTitle: { color: '#0B1829', marginBottom: 8 },
  statusPanelDescription: { color: '#868A8F', marginBottom: 16 },
  yourRewards: {
    padding: '16px 24px 16px 10px',
    height: '100%'
  },
  rewardsDetails: {
    paddingLeft: '16px'
  },
  rewardChat: {
    width: '170px',
    height: '28px',
    backgroundColor: '#D7E4F6',
    color: '#3577D4',
    '&:hover, &:focus': {
      boxShadow: 'none',
      backgroundColor: '#D7E4F6'
    }
  },
  rewardsTabImage: {
    display: 'flex',
    justifyContent: 'center',
    width: 48,
    height: 48
  },
  itemReward: { paddingLeft: '12px', paddingRight: '12px' },
  itemRewardLong: { paddingLeft: '0px', paddingRight: '0px' },
  rewardSection: {
    height: 48,
    borderBottom: '1px solid #E5E7E8'
  },
  freePlanChip: {
    width: '60px',
    height: '18px',
    backgroundColor: '#FE8E26',
    color: '#FFFFFF',
    marginLeft: 8,
    borderRadius: '2px',
    '&:hover, &:focus': {
      boxShadow: 'none',
      backgroundColor: '#FE8E26'
    }
  },
  leftArrow: {
    left: 0,
    zIndex: 999,
    cursor: 'pointer',
    width: 32,
    height: 32,
    borderRadius: 10,
    backgroundColor: '#4A4F54',
    marginRight: 22
  },
  rightArrow: {
    right: 0,
    cursor: 'pointer',
    width: 32,
    height: 32,
    borderRadius: 10,
    backgroundColor: '#4A4F54',
    marginLeft: 22
  },
  horizontalScrollMenu: { maxWidth: '100%', height: '100%', '& > div': { height: '100%' } },
  horizontalScrollInnerWrapper: { height: '100%', '& > div': { height: '100%' } },
  cancelMembershipModal: { background: '#ffffff', width: 512, borderRadius: 24, padding: '16px 24px' },
  cancelMembershipNewModal: { background: '#ffffff', width: 580, borderRadius: 10, padding: '24px 0px' },
  cancelModalSection: { padding: '0px 20px' },
  cancelMembershipModallDivider: { background: '#E5E7E8', marginBottom: 24 },
  cancelMembershipModalTitle: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '32px',
    textWrap: 'wrap',
    marginBottom: 16
  },
  cancelMembershipModalText: {
    color: '#4A4F54',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    textWrap: 'wrap',
    marginBottom: 24
  },
  backArrow: {
    color: '#4A4F54 !important',
    height: 24,
    width: 24
  },
  backText: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: '#4A4F54',
    margin: 0
  },
  membershipManagementPanelBox: {
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#ffffff',
    width: 718,
    height: 345,
    borderRadius: 10,
    padding: '18px 32px',
    border: '1px solid #EBEDF0'
  },
  membershipCancellationPanelBox: {
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#ffffff',
    width: 718,
    height: 'auto',
    borderRadius: 10,
    padding: '18px 32px',
    paddingBottom: 28,
    border: '1px solid #EBEDF0'
  },
  membershipDescription: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '-0.12px',
    marginBottom: 4
  },
  membershipName: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    marginBottom: 4
  },
  membershipImageWrapper: {
    background: '#72A0E114',
    position: 'relative',
    width: 68,
    height: 68,
    borderRadius: 12
  },
  membershipStrikeThrough: {
    textDecoration: 'line-through',
    color: '#808080'
  },
  membershipPricingText: {
    ...styles.membershipDescription,
    color: '#808080'
  },
  membershipBoxHeader: {
    padding: '16px 0px 24px 0px',
    background: '#ffffff'
  },
  membershipBoxFinalHeader: {
    padding: '16px 0px'
  },
  membershipBoxActions: {
    padding: '32px 0px',
    paddingBottom: 8
  },
  membershipActions: {
    color: '#3577D4',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: 6
  },
  membershipActionsButton: {
    color: '#3577D4',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
    padding: '4px 0px',
    height: 24,
    marginBottom: 6
  },
  perkItem: {
    border: '1px solid #EBEDF0',
    borderRadius: 5,
    padding: '5px 12px',
    width: 100,
    height: 110
  },
  perkItemImageWrapper: {
    width: 64,
    height: 64
  },
  perkItemName: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '16px',
    textAlign: 'center',
    '& span': {
      color: '#4CAF50'
    }
  },
  nextPerkItemName: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '16px',
    textAlign: 'center',
    '& span': {
      color: '#4A4F54'
    }
  },
  cancelMembershipNewModalTitle: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    '& span': {
      color: '#4CAF50'
    }
  },
  cancelMembershipContent: {
    padding: '24px 0px'
  },
  cancelMembershipDisclaimer: {
    color: '#C62828',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '16px'
  },
  cancelMembershipReasons: {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    '& span.MuiTypography-root': {
      color: '#000000',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px'
    }
  },
  cancelMembershipTitle: {
    color: '#131415',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  membershipReasonSection: { paddingBottom: 12 },
  cancelMembershipFooterSection: { paddingTop: 24 },
  cancelMembershipCTASection: { padding: '24px 0px' },
  cancelMembershipFinalOptions: { paddingLeft: 0, paddingRight: 0 },
  cancelMembershipTextContainer: { paddingTop: 8, paddingBottom: 16 },
  cancelMembershipText: {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  youllBeCharged: {
    marginBottom: 24,
    '& span': {
      fontWeight: 600
    }
  },
  paymentFormLabel: {
    color: '#131415',
    marginBottom: 4
  },
  paymentFormContent: {
    background: '#72A0E114',
    padding: '20px 12px',
    border: '1px solid #EBEDF0',
    borderRadius: 10,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.05)',
    '&:hover': {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.065)'
    }
  },
  paymentFormInput: {
    color: '#131415',
    fontFamily: 'Inter !important',
    border: '1px solid #B7BBBF',
    borderRadius: 6,
    padding: '8px 12px',
    '::placeholder': {
      color: '#EBEDF0'
    },
    '&:hover': {
      border: '1px solid #A7ABAF'
    }
  },
  formErrors: {
    color: '#f44336',
    height: 12
  }
});
export default styles;
