import React from 'react';
import { withStyles, Grid, Button } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import round from 'lodash/round';
import { useIsAchPrepaid } from '../../../../../../hooks';
import { paymentMethods } from '../../../../../../apis/constants';
import { moneyStr, cardImg } from '../../../../../../helpers/utils';
import styles from './styles/PaymentSection';
import AppliedMembershipPanel from '../../../../../global/AppliedMembershipPanel';
import StrikeOutText from '../../../../../global/StrikeOutCost';
import useDiscountsAndRewards from '../../../../../../hooks/useDiscountsAndRewards';
import { Img } from '../../../../../global/ImgUtils';

const PaymentSectionRow = ({ label, labelClass, value, valueClass, containerClass, marginBottom }) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    className={containerClass}
    style={{ marginBottom }}
  >
    <p className={labelClass}>{label}</p>
    <p className={valueClass}>{value}</p>
  </Grid>
);

const isCreditCard = paymentProfile => paymentProfile.payment_method === paymentMethods.creditCard;
const getPaymentData = paymentProfile => {
  if (isCreditCard(paymentProfile))
    return {
      number: paymentProfile.credit_card.card_number,
      image: cardImg(paymentProfile.credit_card.card_type),
      type: paymentMethods.creditCard
    };

  return {
    number: paymentProfile.bank_account.account_number,
    image: '/images/account/ach.svg',
    type: paymentMethods.ach
  };
};

const getPaymentTypeText = type => (type === paymentMethods.creditCard ? 'Card' : paymentMethods.ach);

const FromCardLabel = ({ classes, paymentProfile, onShowPaymentsProfiles }) => {
  const paymentData = paymentProfile && getPaymentData(paymentProfile);
  const text = paymentData ? getPaymentTypeText(paymentData.type) : '';
  return (
    <>
      <span style={{ marginRight: 14 }}>Payment from {text}</span>
      <Button
        disableRipple
        disableFocusRipple
        disableTouchRipple
        className={classes.cardSelect}
        onClick={onShowPaymentsProfiles}
      >
        {paymentData ? (
          <>
            <Img src={paymentData.image} alt={paymentData.type} width={20} />
            <span>{paymentData.number.slice(-4)}</span>
          </>
        ) : (
          <span style={{ color: '#3577d4' }}>Select</span>
        )}
        <ExpandMore style={{ color: paymentData ? '#434d5c' : '#3577d4', fontSize: 16 }} />
      </Button>
    </>
  );
};

const PaymentSection = ({ classes, totalItems, usedCredits = 0, balance, total, totalWithoutDiscount }) => {
  // const [fromPayment, fromCredits, creditsLeft] = [
  //   round(total - usedCredits, 2),
  //   usedCredits,
  //   round(balance - usedCredits, 2)
  // ];
  const fromPayment = round(total - usedCredits, 2);
  const { totalBeforeDiscount } = useDiscountsAndRewards(total || totalWithoutDiscount);
  return (
    <>
      <PaymentSectionRow
        label={`Subtotal (${totalItems} item${totalItems > 1 ? 's' : ''})`}
        value={
          <>
            <StrikeOutText value={totalBeforeDiscount} fontSize={14} />
            {moneyStr(total)}
          </>
        }
        labelClass={classes.paymentCommonValue}
        valueClass={classes.paymentTotalValue}
        containerClass={classes.subTotalContainer}
      />
      <PaymentSectionRow
        label="Tax"
        value={moneyStr(0)}
        labelClass={classes.paymentCommonValue}
        valueClass={classes.paymentTotalValue}
        containerClass={classes.subTotalContainerBottom}
      />
      <AppliedMembershipPanel
        total={total || totalWithoutDiscount}
        fromPayment={fromPayment}
        fullWidth
        style={{ marginBottom: 20, width: '100%' }}
      />
      <PaymentSectionRow
        label="Total"
        value={
          <>
            <StrikeOutText value={totalBeforeDiscount} fontSize={14} />
            {moneyStr(total)}
          </>
        }
        labelClass={classes.paymentTotalText}
        valueClass={classes.paymentTotalText}
        containerClass={classes.totalContainer}
      />
      {/* {useCreditsFirst && (
        <PaymentSectionRow
          label="Payment from Credit"
          value={moneyStr(fromCredits)}
          labelClass={classes.paymentLabel}
          valueClass={classes.paymentCreditValue}
          marginBottom={16}
        />
      )} */}
      {/* {fromPayment > 0 && (
        <PaymentSectionRow
          label={
            <FromCardLabel
              classes={classes}
              paymentProfile={defaultProfile}
              onShowPaymentsProfiles={onShowPaymentsProfiles}
            />
          }
          value={moneyStr(fromPayment)}
          labelClass={classes.paymentLabel}
          valueClass={classes.paymentCommonValue}
          marginBottom={useCreditsFirst && 20}
        />
      )} */}
      {/* {useCreditsFirst && (
        <PaymentSectionRow
          label="Credit left"
          value={moneyStr(creditsLeft)}
          labelClass={classes.paymentLabel}
          valueClass={classes.paymentCommonValue}
          containerClass={classes.creditsLeftContainer}
        />
      )} */}
    </>
  );
};

export default withStyles(styles)(PaymentSection);
