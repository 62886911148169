import { useEffect, useState, useMemo } from 'react';
import { useRutterLink } from 'react-rutter-link';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';
import { CLEAR_INTEGRATION, EXIT_INTEGRATION, SET_INTEGRATIONS } from '../actions/types';
import apiPaths from '../helpers/apiPaths';
import { integrationsApi } from '../apis/swagup';
import { rutterClienSecret, rutterClientId, rutterPublicKey } from '../apis/rutterUtils';
import { useCompany } from './globalQueries';

const publicKey = rutterPublicKey;
const clientId = rutterClientId;
const secret = rutterClienSecret;

// const integrationWrapperFunc = integration => ({ ...integration, enabled: true });

const useIntegrations = () => {
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [currentPublicToken, setCurrentPublicToken] = useState();
  // const [currentConnection, setCurrentonnection] = useState();

  const { data: connectionAccess } = useQuery(
    `rutterapi-connectionAccess-${currentPublicToken}`,
    () => integrationsApi.connectionAccess({ public_token: currentPublicToken, client_id: clientId, secret }),
    { enabled: !!currentPublicToken }
  );

  const { data: connectionApiData } = useQuery(
    `rutterapi-connection-${connectionAccess?.access_token}`,
    () => integrationsApi.connection({ access_token: connectionAccess?.access_token }),
    { enabled: !!connectionAccess?.access_token }
  );

  const connection = connectionApiData?.connection;
  const connectedPlatforms = useSelector(state => state.integrations);

  const dispatch = useDispatch();

  const onSuccess = publicToken => {
    setCurrentPublicToken(publicToken);
  };

  useEffect(() => {
    if (currentPublicToken)
      dispatch({
        type: SET_INTEGRATIONS,
        payload: [
          { publicToken: currentPublicToken, connectedPlatform: selectedPlatform, connectionAccess, connection }
        ]
      });
  }, [currentPublicToken, dispatch, selectedPlatform, connectionAccess, connection]);

  const rutterConfig = {
    publicKey,
    onSuccess
  };

  const { open, exit } = useRutterLink(rutterConfig);

  const onOpen = platform => {
    setCurrentPublicToken();
    setSelectedPlatform(platform);
    open({ platform });
  };

  const onExit = platform => {
    dispatch({
      type: EXIT_INTEGRATION,
      payload: { connectedPlatform: platform }
    });
    setCurrentPublicToken();
    setSelectedPlatform();
    exit({ platform });
  };

  const onReconnect = platform => {
    onExit(platform);
    onOpen(platform);
  };

  const { data: integrationsData, isLoading } = useQuery(apiPaths.integrations, () => integrationsApi.fetch());

  let integrations = integrationsData?.results || [];
  integrations = [...integrations.filter(i => i.active), ...integrations.filter(i => !i.active)];
  const isIntegrationAvailable = platform => integrations.find(intg => intg.active && intg.rutter_id === platform);
  const getConnectedPlatform = platform =>
    connectedPlatforms.find(plat => plat.connectedPlatform?.toLowerCase() === platform?.toLowerCase());

  const { data: company } = useCompany();
  const { data: integrationsStoresData, isLoading: isLoadingStores } = useQuery(
    [apiPaths.integrationStores, company?.id],
    () => integrationsApi.getStores()
  );

  const stores = useMemo(() => integrationsStoresData?.results || [], [integrationsStoresData]);

  useEffect(() => {
    const storesClean = stores.map(store => ({
      id: store.id,
      connectedPlatform: store.integration_platform.rutter_id,
      createdAt: store.created_at,
      type: store.integration_type,
      connected: store.connected,
      connectionAccess: {
        access_token: store.access_token,
        rutter_id: store.rutter_id,
        domain_name: store.domain,
        url: store.url,
        isReady: !store.needs_sync,
        image_url: store.integration_platform.image_url,
        type: store.integration_platform.type
      },
      connection: {
        access_token: store.access_token,
        rutter_id: store.rutter_id,
        domain_name: store.domain,
        store_name: store.name,
        url: store.url,
        isReady: !store.needs_sync
      }
    }));
    if (!isEmpty(storesClean))
      dispatch({
        type: SET_INTEGRATIONS,
        payload: storesClean
      });
    else
      dispatch({
        type: CLEAR_INTEGRATION
      });
  }, [stores, dispatch]);

  const queryClient = useQueryClient();

  const signalNewStore = useMutation(newStore => integrationsApi.signalNewStore(newStore), {
    onSuccess: () => {
      queryClient.invalidateQueries(apiPaths.integrationStores);
      queryClient.invalidateQueries([apiPaths.integrations]);
      queryClient.invalidateQueries(apiPaths.integrations);
    }
  });

  const createNewStore = newStore => signalNewStore.mutate(newStore);

  useEffect(() => {
    const store = stores[0];
    if (!store && connection && connectionAccess && !signalNewStore.isLoading && !isLoadingStores) {
      const newStore = {
        name: connection.store_name,
        platform: selectedPlatform,
        access_token: connectionAccess.access_token
      };
      setTimeout(() => {
        signalNewStore.mutate(newStore);
      }, 2000);
    }
  }, [connection, connectionAccess]);

  return {
    isLoading,
    integrations,
    onOpen,
    onExit,
    connectedPlatforms,
    selectedPlatform,
    isIntegrationAvailable,
    getConnectedPlatform,
    onReconnect,
    isLoadingStores,
    createNewStore
  };
};

export default useIntegrations;
