import * as React from 'react';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowBack, Refresh } from '@material-ui/icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { toSentenceCase } from '../account/MembershipCommon';
import useIntegrations from '../../hooks/useIntegrations';
import apiPaths from '../../helpers/apiPaths';
import IntegrationOrderItem from './IntegrationOrderItem';
import { integrationsApi } from '../../apis/swagup';
import { usePagination, useQueryParams } from '../../hooks';
import { FilterBy, SearchField, SortBy } from '../products/commonProductsElements';
import Loader from '../global/Loader';
import { imageSrcSet } from '../../helpers/utils';
import { Pagination } from '../shared';
import Img from '../shared/Img';

const styles = theme => ({
  mainContainer: { width: '100%', position: 'relative' },
  goBackContainer: { position: 'absolute', top: -22 },
  emptyProductsTitle: {
    textAlign: 'center',
    marginBottom: 16
  },
  emptyProductsText: {
    textAlign: 'center',
    width: 420,
    marginTop: 16,
    marginBottom: 24
  },
  mainButton: { minWidth: 180, height: 56 }
});

const useStyles = makeStyles(styles);

const EmptyState = ({ title, subtitle, small, actionContent }) => {
  const classes = useStyles({ small });
  return (
    <Box style={{ paddingTop: small ? 24 : 56 }}>
      <Typography variant="body1MediumInter" className={classes.emptyProductsTitle}>
        {title}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item>
          <Box className={classes.emptyProductsImageWrapper}>
            <Img
              srcSet={imageSrcSet('/images/integrations/empty-products.png')}
              className={classes.integrationImage}
              alt="empty-products"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="body3RegularInter" className={classes.emptyProductsText}>
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          {actionContent || (
            <Button size="small" variant="primary" component={Link} to="/integrations">
              Go Back
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const orderConvertion = order => {
  return {
    ...order,
    totalItems: order.line_items?.length
  };
};

const perPageOptions = [6, 12, 24, 40];

const IntegrationsOrdersHome = () => {
  const [error, setError] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(false);
  const history = useHistory();
  const { getConnectedPlatform, onReconnect } = useIntegrations();
  const query = useQueryParams();
  const platform = query.get('platform');
  const search = query.get('search');
  const ordering = query.get('ordering');
  const limit = query.get('per_page');
  const offset = query.get('page');
  const orderStatus = query.get('order_status');
  const orders = query.get('orders');
  const seachQuery = `search=${search || ''}&ordering=${ordering || ''}&limit=${limit || ''}&offset=${
    offset ? (parseInt(offset, 10) - 1) * parseInt(limit, 10) : ''
  }${
    !orderStatus || (orderStatus?.includes('failed') && orderStatus?.includes('success'))
      ? ''
      : `&swagup_order__isnull=${orderStatus?.includes('failed') ? 'true' : 'false'}`
  }${orders ? `&id__in=${orders}` : ''}`;
  const selectedPlatform = getConnectedPlatform(platform);

  const { data: integrationOrderData, isLoading } = useQuery(
    [apiPaths.integrationOrders, selectedPlatform?.connectedPlatform, seachQuery],
    () =>
      integrationsApi.fetchIntegrationOrders(selectedPlatform?.connectionAccess?.access_token, seachQuery, () =>
        setError(true)
      ),
    {
      enabled: platform && !!selectedPlatform?.connectionAccess?.access_token,
      onerror: () => {
        setError(true);
      }
    }
  );

  const total = integrationOrderData?.count || 0;
  const { nextPage, pageIndex, perPage, sizeOptions, previousPage, changeSize } = usePagination(total, perPageOptions);

  const cleanOrder = order => ({ rutter_id: order.rutter_id });

  const queryClient = useQueryClient();
  const refresh = () => {
    queryClient.invalidateQueries([apiPaths.integrationOrders, selectedPlatform?.connectedPlatform, seachQuery]);
  };
  const retryOrder = useMutation(
    data => integrationsApi.retryOrder(cleanOrder(data), selectedPlatform?.connectionAccess?.access_token),
    {
      onSuccess: (data, param) => {
        refresh();
        const [nextOrder, ...rest] = param?.orders || [];
        if (nextOrder) {
          retryOrder.mutate({ ...nextOrder, orders: rest });
        }
      }
    }
  );

  useEffect(() => {
    if (!ordering) {
      query.set('ordering', '-created_at');
      history.replace(`${history.location.pathname}?${query.toString()}`);
    }
  }, [history, ordering, query]);

  const searchByQueryParam = useCallback(
    value => {
      query.set('search', value);
      history.replace(`${history.location.pathname}?${query.toString()}`);
    },
    [query, history]
  );

  const integrationOrders = integrationOrderData?.results?.map(orderConvertion) || [];

  const onRetry = order => {
    const iOrder = cleanOrder(order);
    retryOrder.mutate(iOrder);
  };

  const retryAllFailedOrder = () => {
    const allFailedOrders = integrationOrders.filter(io => io.error_message);
    const [nextOrder, ...rest] = allFailedOrders;
    retryOrder.mutate({ ...nextOrder, orders: rest });
  };

  const onRefresh = () => {
    setFakeLoading(true);
    refresh();
    setTimeout(() => {
      setFakeLoading(false);
    }, 500);
  };

  const handleOnApply = React.useCallback(
    queryName => value => {
      if (!value) {
        query.delete(queryName);
      } else {
        query.set(queryName, value);
      }
      history.replace({ ...history.location, search: query.toString() });
    },
    [history, query]
  );

  const initialOrderStatusValues = orderStatus?.split(',').filter(Boolean);
  const hasFailedOrders = integrationOrders.find(io => io.error_message);
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.goBackContainer}>
        <Link to="/integrations" style={{ marginBottom: 12 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ArrowBack style={{ fontSize: 16, marginTop: 2 }} />
            </Grid>
            <Grid item>
              <Typography component="p" variant="body3RegularInter" style={{ fontSize: 12, color: '#3577D4' }}>
                Go back
              </Typography>
            </Grid>
          </Grid>
        </Link>
      </Box>
      <Grid container alignItems="center" style={{ marginBottom: 24 }}>
        <Grid item xs>
          <Typography variant="h2BoldInter"> {toSentenceCase(platform)}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="primary"
            className={classes.mainButton}
            disabled={!hasFailedOrders}
            onClick={retryAllFailedOrder}
          >
            Retry failed Order
          </Button>
        </Grid>
      </Grid>
      <Box style={{ padding: '4px 0px' }}>
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={3}>
            <SearchField
              key="search"
              placeholder="Search By Name"
              onChange={searchByQueryParam}
              rounded
              lean
              inverseHover
            />
          </Grid>
          <Grid item>
            <FilterBy
              key={`order_status=${orderStatus}`}
              label="Order Status:"
              options={{ failed: 'Failed', success: 'Success' }}
              initialValues={initialOrderStatusValues}
              onApply={handleOnApply('order_status')}
            />
          </Grid>
          <Grid item>
            <SortBy />
          </Grid>
          <Grid item xs />
          <Grid item title="Refresh">
            <IconButton onClick={onRefresh}>
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      {error ? (
        <EmptyState
          title={`An error ocurred while trying to get your ${toSentenceCase(platform)} integration orders`}
          subtitle="Go ahead and reconnect to try to solve this issue"
          actionContent={
            <Button size="small" variant="primary" onClick={() => onReconnect(platform)}>
              Reconnect
            </Button>
          }
        />
      ) : (
        <>
          {platform && !!selectedPlatform?.connectionAccess?.access_token ? (
            <>
              {isEmpty(integrationOrders) ? (
                <EmptyState
                  title="You don’t have Orders to show yet!"
                  subtitle="Go to the Inventory tab to start connecting products to your preferred tool. "
                />
              ) : (
                integrationOrders.map(io => (
                  <IntegrationOrderItem key={io.id} integrationOrder={io} onRetry={onRetry} />
                ))
              )}
            </>
          ) : (
            <EmptyState
              title="You have not connected to a platform yet"
              subtitle="Go back to select a platform to connect to."
            />
          )}
        </>
      )}

      <Grid container justifyContent="center">
        <Grid item>
          <Pagination
            count={total}
            endText="orders"
            perPage={perPage}
            next={nextPage}
            pageIndex={pageIndex}
            previous={previousPage}
            sizeOptions={sizeOptions}
            onPerPageChange={changeSize}
          />
        </Grid>
      </Grid>

      {(isLoading || retryOrder.isLoading || fakeLoading) && <Loader />}
    </Box>
  );
};

export default IntegrationsOrdersHome;
