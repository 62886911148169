const styles = theme => ({
  container: {
    padding: '100px 0'
  },
  textContainer: {
    paddingTop: '50px !important',
    paddingLeft: '60px !important',
    maxWidth: 520,
    [theme.breakpoints.down('xs')]: { paddingLeft: '0 !important' }
  },
  miniTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#9846dd',
    textTransform: 'uppercase',
    '&:after': {
      display: 'inline-block',
      content: "''",
      borderTop: '2px solid #9846dd',
      width: '4rem',
      margin: '0 30px',
      transform: 'translateY(-0.3rem)',
      [theme.breakpoints.down('sm')]: { margin: '0 10px' },
      [theme.breakpoints.down('xs')]: { margin: '0 10px' }
    }
  },
  title: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '3.5rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#0f2440',
    maxWidth: 490,
    marginTop: 28
  },
  text: {
    opacity: '0.54',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '20px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.8,
    letterSpacing: 'normal',
    color: '#0f2440',
    maxWidth: 490
  },
  imgContainer: {
    // display: 'flex',
    // alignItems: 'flex-end',
    // maxHeight: 580,
    '& > img': { objectFit: 'contain', width: '100%', borderRadius: '75px 5px 75px 5px' }
  }
});

export default styles;
