import React from 'react';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { Link, useLocation } from 'react-router-dom';
import { Check } from '@material-ui/icons';
import Dropdown from './Dropdown';
import { useQueryParams } from '../../hooks';

const styles = () => ({
  label: {
    color: '#4A4F54',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    '& span': {
      color: '#3577D4'
    }
  },
  root: { height: 35 }
});
const useStyles = makeStyles(styles);

const CustomCheckIcon = () => (
  <div style={{ height: 24, width: 24 }}>
    <Check style={{ color: '#3577d4', height: 18 }} />
  </div>
);
const SelectFilter = ({ selectOptions = {}, property = '', customTitle = '', excluded }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState();
  const location = useLocation();
  const query = useQueryParams();

  const selected = selectOptions[query.get(property)] || 'All';

  const title = (
    <Typography variant="body2RegularInter" className={classes.label}>
      {customTitle}: <span style={{ color: '#125CFF' }}>{selected}</span>
    </Typography>
  );
  const defaultQuery = new URLSearchParams(query);
  defaultQuery.delete(property);

  return (
    <Dropdown
      label={title}
      blank
      anchorEl={anchorEl}
      onClose={() => setAnchorEl()}
      setAnchorEl={setAnchorEl}
      hidden={excluded}
    >
      <MenuItem
        component={Link}
        key="All"
        to={{ ...location, search: defaultQuery.toString() }}
        onClick={() => setAnchorEl()}
        classes={classes}
      >
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
              All
            </Typography>
          </Grid>
          <Grid item>{selected === 'All' && <CustomCheckIcon />}</Grid>
        </Grid>
      </MenuItem>
      {Object.entries(selectOptions).map(([value, name]) => {
        const newQuery = new URLSearchParams(query);
        newQuery.set(property, value);
        return (
          <MenuItem
            component={Link}
            key={value}
            to={{ ...location, search: newQuery.toString() }}
            onClick={() => setAnchorEl()}
            classes={classes}
          >
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
                  {name}
                </Typography>
              </Grid>
              <Grid item>{selected === name && <CustomCheckIcon />}</Grid>
            </Grid>
          </MenuItem>
        );
      })}
    </Dropdown>
  );
};

export default SelectFilter;
