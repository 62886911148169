import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { moneyStr } from '../../../../../../helpers/utils';
import { useInSendSwagPath } from '../../hooks';
import ArrowTooltip from '../../common/Tooltip';

const useStyles = makeStyles({
  shippingMethodButton: ({ active }) => ({
    width: '100%',
    padding: '8px 12px',
    borderRadius: 6,
    border: active ? 'solid 1px #E5E7E8' : 'solid 1px #E5E7E8',
    backgroundColor: active ? '#EBF1FB' : 'white',
    '&:hover': {
      border: 'solid 1px #3577d4',
      backgroundColor: '#f4f8ff',
      cursor: 'pointer'
    },
    '&[disabled]': {
      backgroundColor: '#f8f8f8',
      filter: 'grayscale(100%)'
    }
  }),
  shippingMethodTitle: {
    color: '#3577d4',
    cursor: 'inherit',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '16px',
    'button[disabled] &': { color: '#787b80' }
  },
  shippingMethodButtonLabel: {
    height: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  shippingMethodSubtitle: {
    color: '#4A4F54',
    cursor: 'inherit',
    fontFamily: 'Inter',
    textAlign: 'left',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '16px'
  },
  discountText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 8,
    fontFamily: 'Inter',
    fontWeight: 400,
    marginBottom: 4,
    lineHeight: '10px',
    textTransform: 'uppercase'
  },
  discountBadge: { background: '#90BEFF', borderRadius: 10, padding: '2px 6px 2px 4px' },
  discountImageWrapper: { width: 12, height: 12, position: 'relative' },
  discountImage: { width: '100%', height: '100%', objectFit: 'contain' }
});

const expressDisableText =
  'Due to increased demand, we are temporarily restricting express shipping in order to balance our focus on service and safety.';

const ShippingMethodButton = ({
  name,
  price,
  originalPrice,
  discount,
  time,
  active,
  disabled,
  showMessage,
  onClick,
  ...props
}) => {
  const classes = useStyles({ active });
  const { disableExpressShipmentsGlobalPermanent062422: expressShipments, swagupMembershipPlans } = useFlags();
  const comesFromSendSwag = useInSendSwagPath();
  const estimatedDurationPrefix = comesFromSendSwag ? 'Shipping date' : 'Production time';

  const disableOnlyExpress = name === 'Express' && !expressShipments;
  const disableBtn = disabled || disableOnlyExpress;

  return (
    <Button
      disabled={disableBtn}
      onClick={onClick}
      // onKeyDown={handleKeyDown}
      tabIndex="0"
      aria-pressed={active}
      classes={{ root: classes.shippingMethodButton, label: classes.shippingMethodButtonLabel }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Grid container alignItems="center" spacing={3} className={classes.shippingMethodTitle}>
        <Grid item xs>
          <p style={{ textAlign: 'left', margin: 0 }}>
            {name}
            {name === 'Express' && <> 🚀</>}
          </p>
        </Grid>
        {swagupMembershipPlans && originalPrice > 0 && (
          <Grid item>
            <span style={{ color: '#787B80', textDecoration: 'line-through' }}>
              {moneyStr(disableBtn ? 0 : originalPrice)}
            </span>
          </Grid>
        )}
        <Grid item>
          <span>{moneyStr(disableBtn ? 0 : price)}</span>
        </Grid>
      </Grid>
      <Grid container alignItems="center" style={{ width: '100%' }}>
        <Grid item xs>
          <p className={classes.shippingMethodSubtitle}>
            {disableBtn
              ? showMessage && 'This shipping method is not available'
              : `${estimatedDurationPrefix} + ${time}`}
          </p>
        </Grid>
        {swagupMembershipPlans && discount > 0 && (
          <Grid item>
            <Box className={classes.discountBadge}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box className={classes.discountImageWrapper}>
                    <img src="/images/membership/rewardRocket.png" className={classes.discountImage} alt="discount" />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box style={{ paddingBottom: 2 }}>
                    <span className={classes.discountText}>-{discount}%</span>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </Button>
  );
};

export default ShippingMethodButton;
