import React, { useState } from 'react';
import {
  Checkbox,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const FormInput = ({ select, options, classes }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  return (
    <div>
      <div
        ref={anchorRef}
        className={classes.formInput}
        style={{
          display: 'flex',
          alignItems: select ? 'center' : 'baseline',
          width: 224,
          paddingLeft: 6,
          paddingTop: select ? 0 : 6,
          cursor: select ? 'pointer' : 'text',
          height: select ? undefined : 32,
          position: 'relative'
        }}
      >
        <p style={{ fontSize: 8, lineHeight: '8px' }}>{select ? 'Select' : 'Type'} your answer</p>
        {select && <ArrowDropDown className={classes.formInputIcon} onClick={() => setOpen(true)} />}
      </div>
      <Popper open={open} anchorEl={anchorRef.current} role="menu" transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className={classes.formInputPaper}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {options.map(option => (
                    <MenuItem key={option.label} className={classes.formInputPaperMenuItem}>
                      <Typography variant="body4RegularInter">{option.label}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const WrapperRadio = ({ name, options = [], classes }) => (
  <FormControl>
    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name={name}>
      <Grid container>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio classes={{ root: classes.selector }} disabled />}
            label={option.label}
            classes={{ root: classes.selectorLabel }}
          />
        ))}
      </Grid>
    </RadioGroup>
  </FormControl>
);

const WrapperCheckBox = ({ name, options = [], classes }) => (
  <FormControl fullWidth>
    <FormGroup name={name}>
      <Grid container>
        {options.map((option, i) => (
          <Grid key={`${option.value}$-${i + 1}`} item xs={options.length > 3 ? 6 : 12}>
            <FormControlLabel
              value={option.value}
              control={<Checkbox name={option.value} classes={{ root: classes.selector }} disabled />}
              label={option.label}
              classes={{ root: classes.selectorLabel }}
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  </FormControl>
);

const AnswerCollection = ({ options, name, type, classes }) => {
  const getAnswerComponent = () => {
    switch (type) {
      case 'Text Field':
        return <FormInput name={name} multiline classes={classes} />;
      case 'Yes or No':
        return (
          <WrapperRadio
            name={name}
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ]}
            classes={classes}
          />
        );
      case 'Single choice':
        return <FormInput name={name} options={options} select classes={classes} />;
      case 'Multiple choice':
        return <WrapperCheckBox fullWidth name={name} options={options} classes={classes} />;
      default:
        return <h1>Component Here</h1>;
    }
  };

  return getAnswerComponent();
};

const QuestionForm = ({ customQuestion, name, classes }) => {
  return (
    <Grid container className={classes.questionContainer}>
      <Grid item xs={12}>
        <Typography className={classes.question}>{customQuestion.question}</Typography>
        <AnswerCollection options={customQuestion.options} type={customQuestion.type} name={name} classes={classes} />
      </Grid>
    </Grid>
  );
};

const CustomQuestionsFields = ({ customQuestions, classes }) => (
  <Grid container direction="column" className={classes.section}>
    <Grid item>
      <Typography className={classes.formStepCaption}>Quick Questionnaire</Typography>
    </Grid>
    <Grid item xs>
      <Grid container alignItems="center" justifyContent="center" className={classes.formInputsContainer}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {customQuestions?.map((customQuestion, index) => (
              <Grid key={customQuestion.question} item xs={12}>
                <QuestionForm customQuestion={customQuestion} classes={classes} name={`answer${index}`} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default CustomQuestionsFields;
