import React, { useEffect, useState } from 'react';
import { Grid, withStyles, Fab } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useHistory, useParams } from 'react-router-dom';
import log from '../../logger';
import styles from './styles/MultiShippingPayment';
import Loader from '../global/Loader';
import {
  createShipment,
  fetchIndividualPackPrices,
  fetchStorageCategories,
  fetchStoragePrices,
  saveCredit,
  setReorderProducts
} from '../../actions';
import CheckoutSideBar from './CheckoutSidebar';
import { Breadcrumbs, Helmet } from '../shared';
import tags from '../../apis/seoTags';
import { sumByQuantityParseInt } from '../../helpers/utils';
import StorageCard from './StorageCard';
import { storageTypes } from './storageTypes';
import toErrorPage from '../../helpers/toErrorPage';
import { updateProofQuantitiesByDirectory, calculateTotalShipment } from './MultishippingCommon';
import { getProductSizes, getStoragePrice } from './common';
import { useCompany, useShippingCutoffHour, useSizes } from '../../hooks';
import { useOrder } from '../pages/orders/requested/OrderContext';
import { buildProofAlikeObject, getDirectoryOrders } from '../pages/orders/requested/common/utilsOrder';

const MultishippingStorageOptions = props => {
  const { classes, shippingElements, items, credits } = props;

  const { data: company } = useCompany();
  const isInternational = company.shipping_country !== 'US';
  const [individualPackPrices, setIndividualPackPrices] = useState({});
  const [storagePrices, setStoragePrice] = useState([]);
  const [storageCategories, setStorageCategories] = useState([]);
  const [storageTotal, setStorageTotal] = useState(0);
  const [isCreditOpen, setIsCreditOpen] = useState(false);
  const [isAsIShipOpen, setIsAsIShipOpen] = useState(false);

  const isDataLoaded = !isEmpty(storagePrices) && !isEmpty(storageCategories) && !isEmpty(individualPackPrices);

  const history = useHistory();
  const fetchStoragePricesData = async () => {
    const response = await props.fetchStoragePrices();
    if (response.result === 'ok') {
      setStoragePrice(response.data.results);
    } else {
      toErrorPage(response.result, history);
    }
  };

  const fetchIndividualPackPricesData = async () => {
    const response = await props.fetchIndividualPackPrices();
    if (response.result === 'ok') {
      setIndividualPackPrices(response.data.results[0]);
    } else {
      toErrorPage(response.result, history);
    }
  };

  const fetchStorageCategoriesData = async () => {
    const response = await props.fetchStorageCategories();
    if (response.result === 'ok') {
      setStorageCategories(response.data.results);
    } else {
      toErrorPage(response.result, history);
    }
  };

  const { id } = useParams();

  const sendCredit = credit => {
    const newCredit = credit + (id ? 0 : calculateTotalShipment(shippingElements));
    props.saveCredit(newCredit);
  };

  useEffect(() => {
    fetchIndividualPackPricesData();
    fetchStorageCategoriesData();
    fetchStoragePricesData();
  }, []);

  const shippingCutoffHour = useShippingCutoffHour();
  const order = useOrder();
  const dispatch = useDispatch();
  const { data: sizes } = useSizes();

  useEffect(() => {
    if (items.length === 0) {
      dispatch(setReorderProducts(order.products.map(buildProofAlikeObject(sizes))));
      dispatch(createShipment(sizes, getDirectoryOrders(order), shippingCutoffHour));
    }
  }, [items, order, shippingCutoffHour, sizes, dispatch]);

  useEffect(() => {
    let storageTotalTemp = 0;

    const newProofs = updateProofQuantitiesByDirectory(items, shippingElements);

    log.debug('real proofs: ', newProofs);

    if (!isEmpty(storagePrices)) {
      newProofs.forEach(proof => {
        const productSizes = getProductSizes(proof);
        const quantities = sumByQuantityParseInt(productSizes);
        storageTotalTemp += getStoragePrice(proof.product, storagePrices) * quantities;
      });

      setStorageTotal(storageTotalTemp);
    }
  });

  const isPostalReferral = company.affiliateId === 'postal.io';

  return (
    <div className={classes.container}>
      <Helmet tags={tags.multiShippingStorageOptions} />
      {isDataLoaded ? (
        <Grid container justifyContent="center">
          <Grid item className={classes.center}>
            <Grid container>
              <Grid item xs={12} sm={12} md={8} lg={8} style={{ padding: '0 20px' }}>
                <Breadcrumbs links={[{ title: 'Review Order' }, { title: 'Storage & Shipping' }]} />
                <h1 className={classes.pPageTitle}>Storage & Shipping</h1>
                <Grid container>
                  <Grid item xs={12}>
                    <StorageCard
                      storageType={storageTypes.creditForShipping}
                      buttonText="ADD PAYMENT"
                      isInternational={isInternational}
                      sendCredit={credit => sendCredit(credit)}
                      setStorage={() => history.push(`/orders-requested/${id}/order-payment`)}
                      storagePrices={storagePrices}
                      individualPackPrices={individualPackPrices}
                      setIsOpen={setIsCreditOpen}
                      setClose={() => setIsCreditOpen(false)}
                      isOpen={isCreditOpen}
                    />
                  </Grid>
                  {!isPostalReferral && (
                    <>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <div className={classes.ConditionalContainer}>
                          <h3 className={classes.Conditional}>OR</h3>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <StorageCard
                          storageType={storageTypes.payAsIShip}
                          sendCredit={() => sendCredit(0)}
                          isInternational={isInternational}
                          buttonText="Add Payment"
                          requirements="Credit card on file required*"
                          setStorage={() => history.push(`/orders-requested/${id}/order-payment`)}
                          storagePrices={storagePrices}
                          individualPackPrices={individualPackPrices}
                          setIsOpen={setIsAsIShipOpen}
                          setClose={() => setIsAsIShipOpen(false)}
                          isOpen={isAsIShipOpen}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid container>
                    <Grid item xs={6}>
                      <div className={classes.GoBackOneContainer} style={{ paddingTop: 20 }}>
                        <Fab className={classes.GoBack} onClick={history.goBack}>
                          <Grid container style={{ paddingTop: 10 }}>
                            <Grid item xs={2} style={{ paddingTop: 2 }}>
                              <KeyboardBackspaceIcon />
                            </Grid>
                            <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 40 }}>
                              Previous step
                            </Grid>
                          </Grid>
                        </Fab>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className={classes.checkoutSideBarContainer}>
                <CheckoutSideBar
                  individualPackPrices={individualPackPrices}
                  products={items}
                  selectedCredit={credits}
                  selectedStorage={storageTotal}
                  shippingElements={shippingElements}
                  showCredit
                  showStorage={storageTotal > 0}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  shippingElements: state.multishipping,
  items: state.reorderProducts,
  credits: state.credits
});

export default connect(mapStateToProps, {
  fetchIndividualPackPrices,
  fetchStorageCategories,
  fetchStoragePrices,
  saveCredit
})(withStyles(styles)(MultishippingStorageOptions));
