import { Box, Divider, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../../../../hooks';
import SelectFilter from '../../../../global/SelectFilter';
import { FilterBy } from '../../../../products/commonProductsElements';
import { CenteredGrid } from '../../../../shared';

const defaultFilters = [
  {
    name: 'globalShippingOption',
    label: 'Shipping Option',
    options: {
      true: 'Global',
      false: 'U.S Only'
    },
    behavior: 'select'
  }
];

const useStyles = makeStyles({
  shipmentFilter: {
    marginTop: 12,
    minHeight: 56,
    borderTop: '1px solid #E5E7E8',
    borderBottom: '1px solid #E5E7E8',
    overflowX: 'hidden'
  }
});

const ShipmentGroupFilterSection = ({ setIsFiltering, filters = defaultFilters }) => {
  const query = useQueryParams();
  const history = useHistory();
  const handleOnApply = React.useCallback(
    queryName => value => {
      if (setIsFiltering) setIsFiltering(true);
      if (!value) {
        query.delete(queryName);
      } else {
        query.set(queryName, value);
      }
      history.replace({ ...history.location, search: query.toString() });
    },
    [history, query, setIsFiltering]
  );

  const classes = useStyles();
  return (
    <CenteredGrid container justifyContent="space-between" alignItems="center" className={classes.shipmentFilter}>
      <Box display="flex" style={{ padding: '0px 50px' }}>
        <Typography variant="body2MediumInter" style={{ color: '#4A4F54' }}>
          FILTER
        </Typography>
        <Divider orientation="vertical" style={{ margin: '0 24px' }} flexItem />
        {filters.map(filter =>
          filter.behavior === 'select' ? (
            <SelectFilter
              key={`${filter.name}=${query.get(filter.name)}`}
              customTitle={filter.label}
              property={filter.name}
              selectOptions={filter.options}
            />
          ) : (
            <FilterBy
              key={`${filter.name}=${query.get(filter.name)}`}
              label={filter.label}
              options={filter.options}
              onApply={handleOnApply(filter.name)}
              initialValues={
                query.get(filter.name)
                  ? query
                      .get(filter.name)
                      ?.split(',')
                      .filter(Boolean)
                  : undefined
              }
              behavior={filter.behavior}
            />
          )
        )}
      </Box>
    </CenteredGrid>
  );
};

export default ShipmentGroupFilterSection;
