const buildAccountProductsApiCallData = (product, sizes) => ({
  account_products: [
    {
      product: product.id,
      sizes: sizes
        .filter(size => size.qty > 0)
        .map(size => ({
          size: size.id,
          quantity: size.qty
        }))
    }
  ]
});

const buildSizesApiCallData = (sizes, newSizes) =>
  sizes.map(({ size }) => ({
    size: size.id,
    quantity: newSizes[size.id]
  }));

const buildIndexedSizes = sizes => {
  const indexedSizes = {};
  sizes.forEach(size => {
    indexedSizes[typeof size.size === 'number' ? size.size : size.size.id] = size.quantity;
  });
  return indexedSizes;
};

const buildUpdatedSizesQuantity = (sizes, indexedSizes) =>
  sizes.map(size => ({
    ...size,
    quantity: indexedSizes[size.size.id]
  }));

const buildIndexedItemsPrices = items => {
  const indexedItemsPrices = {};
  items.forEach(item => {
    const { price, rush_fee: rushFee, product } = item;
    indexedItemsPrices[product] = { price, rushFee };
  });
  return indexedItemsPrices;
};

const buildProductUpdatedItems = (proof, ap) => {
  if (!ap) {
    return proof.product.items.map(item => ({
      ...item,
      product: { ...item.product, price: 0, rush_fee: 0 }
    }));
  }

  const newPrices = buildIndexedItemsPrices(ap.items);
  return proof.product.items.map(item => {
    const { price, rushFee } = newPrices[item.product.id];
    return item.product.price === price && item.product.rush_fee === rushFee
      ? item
      : {
          ...item,
          product: { ...item.product, price, rush_fee: rushFee }
        };
  });
};

export {
  buildAccountProductsApiCallData,
  buildSizesApiCallData,
  buildIndexedSizes,
  buildUpdatedSizesQuantity,
  buildProductUpdatedItems
};
