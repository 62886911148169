import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, Typography } from '@swagup-com/components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Alert, CenteredGrid, Helmet, Link } from '../shared';
import styles from './styles/storefrontsHome';
import { useCreditSummary } from '../../hooks';
import { storefronts } from '../../apis/storefrontsServices';
import storefrontServicesPaths from '../../helpers/storefrontsServicesPaths';
import { imageSrcSet } from '../../helpers/utils';
import seoTags from '../../apis/seoTags';
import { CustomTooltip } from '../products/commonProductsElements';
import apiPaths from '../../helpers/apiPaths';
import StorefrontShareModal from './StorefrontShareModal';
import EditField from '../global/EditField';
import StoreDetailsTab from './storeDetailsTabs/StoreDetailsTab';
import { getPageLink } from './storeCommon';

const useStyles = makeStyles(styles);

const StorefrontsHistory = () => {
  const [store, setStore] = useState({});
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(true);
  const [wasSuccessful, setWasSuccessful] = useState({ was: false, what: 'scheduled', who: 'Shipment' });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [customProcessingMessage, setCustomProcessingMessage] = useState();

  const classes = useStyles();

  const { id } = useParams();

  const history = useHistory();
  const location = useLocation();
  const { infoMessage } = location.state || {};

  const company = useSelector(state => state.company);

  const { data: storefrontQuery } = useQuery(storefrontServicesPaths.storefront(id), () => storefronts.get(id), {
    enabled: !!id
  });

  useEffect(() => {
    if (storefrontQuery?.id) setStore(storefrontQuery);
  }, [storefrontQuery]);

  const queryClient = useQueryClient();

  const updateStore = useMutation(changes => storefronts.update(store.id, changes), {
    onSuccess: (req, param) => {
      queryClient.invalidateQueries([storefrontServicesPaths.storefronts, company.id]);
      queryClient.invalidateQueries([storefrontServicesPaths.orders(store.id)]);
      setStore(prevStore => ({ ...prevStore, ...param }));
      setCustomProcessingMessage();
      if (param.uniqueUrl && param.uniqueUrlNumber > 0) {
        setWasSuccessful({ was: true, what: 'created', who: `${param.uniqueUrlNumber} unique` });
        setShowSnackbar(true);
      }
    }
  });
  const { data: creditSummary } = useCreditSummary();

  const handleClose = () => {
    setShowInfoMessage(false);
    history.replace({ ...location, state: { ...location.state, infoMessage: undefined } });
  };

  useEffect(() => {
    queryClient.invalidateQueries(apiPaths.creditsSummaries);
    queryClient.invalidateQueries([storefrontServicesPaths.orders(store.id)]);
  }, [store.id, queryClient]);

  const onSave = changes => {
    const properties = ['isActive', 'uniqueUrl', 'uniqueUrlNumber', 'allowMultipleRedemptions', 'emailRestriction'];
    const latestChanges = properties.reduce(
      (acc, property) =>
        !_.isUndefined(changes[property]) && changes[property] !== store[property]
          ? { ...acc, [property]: changes[property] }
          : acc,
      {}
    );
    if (_.isEmpty(latestChanges) && !changes.uniqueUrlNumber) return;

    if (latestChanges.uniqueUrl || changes.uniqueUrlNumber) {
      if (changes.uniqueUrlNumber > 0) setCustomProcessingMessage(`Generating ${changes.uniqueUrlNumber} unique links`);
      setShareModalOpen(false);
    }
    updateStore.mutate(latestChanges);
  };

  return (
    <>
      <Helmet tags={seoTags.storefronts} />
      <CenteredGrid>
        <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: 18 }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item>
                    <Link to="/shops">
                      <Typography variant="body4RegularInter" className={classes.goBack}>
                        <ArrowBackIcon className={classes.goBackIcon} />
                        Back to Shops
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs style={{ paddingLeft: 4 }}>
                    <Typography variant="body4RegularInter" className={classes.goBack}>
                      / {store.generalSettings?.customStoreName || store.generalSettings?.storeName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                {infoMessage && showInfoMessage && (
                  <Grid container justifyContent="center" style={{ position: 'relative' }}>
                    <Alert
                      onClose={handleClose}
                      delayTime={10000}
                      className={classes.floatingInfoMessage}
                      fontStyles={{ fontSize: 12, padding: 0 }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: infoMessage }} />
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Grid>
          <Grid item xs>
            <EditField
              value={store.generalSettings?.customStoreName || store.generalSettings?.storeName}
              className={classes.storefrontDetailsName}
              onEdit={name => updateStore.mutate({ customStoreName: name })}
              isLoading={updateStore.isLoading}
              style={{ maxWidth: '100%' }}
              fullWidth={false}
            />
          </Grid>
          <Grid item style={{ paddingRight: 16 }}>
            <Button
              variant="text"
              component={Link}
              to={store.accessSettings ? getPageLink(store.accessSettings) : '/'}
              style={{ minWidth: 110, height: 56 }}
              target="_blank"
              rel="noreferrer"
              className={classes.previewBtn}
            >
              <Typography variant="buttonMediumInter" style={{ color: '#3577D4' }}>
                Preview
              </Typography>
              <span style={{ paddingLeft: 8 }}>
                <img
                  alt="Store-Front-Preview"
                  src="/images/storefront/store-front-preview.png"
                  srcSet={imageSrcSet('/images/storefront/store-front-preview.png')}
                  width={24}
                  height={24}
                />
              </span>
            </Button>
          </Grid>
          <Grid item style={{ paddingRight: 16 }}>
            <Button
              variant="text"
              component={Link}
              to={`/shop-customize/${store.accessSettings?.urlSlug}`}
              style={{ minWidth: 110, height: 56 }}
            >
              <Typography variant="buttonMediumInter" style={{ color: '#3577D4' }}>
                Customize
              </Typography>
              <span style={{ paddingLeft: 8 }}>
                <EditOutlinedIcon />
              </span>
            </Button>
          </Grid>
          <Grid item>
            <CustomTooltip
              title="A min. credit balance of $100 is required to activate an Autoship storefront page"
              arrow
              placement="top-start"
              disableHoverListener={store.collectionOnly || store.isActive || creditSummary.current_balance >= 0}
            >
              <Button
                variant="text"
                onClick={() => setShareModalOpen(true)}
                style={{ minWidth: 110, height: 56 }}
                disabled={!store.collectionOnly && !store.isActive && creditSummary.current_balance < 0}
              >
                <Typography variant="buttonMediumInter" style={{ color: '#3577D4' }}>
                  Share
                </Typography>
                <span style={{ paddingLeft: 8, paddingBottom: 4 }}>
                  <img
                    alt="Store-Front-Share"
                    src="/images/storefront/share.png"
                    srcSet={imageSrcSet('/images/storefront/share.png')}
                    width={16}
                    height={20}
                  />
                </span>
              </Button>
            </CustomTooltip>
          </Grid>
        </Grid>
      </CenteredGrid>
      <StoreDetailsTab store={store} setStore={setStore} />
      <CenteredGrid>
        <StorefrontShareModal
          store={store}
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
          onSave={onSave}
          isSaving={updateStore.isLoading}
        />
      </CenteredGrid>
    </>
  );
};

export default StorefrontsHistory;
