import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { isArray, round } from 'lodash';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet, CenteredGrid, Alert } from '../../../../shared';
import tags from '../../../../../apis/seoTags';
import Breadcrumbs from '../../../../shared/Breadcrumbs';
import { useOrder } from '../OrderContext';
import ReviewOrder from './orderReview/ReviewOrder';
import WizardStepper from '../../../../global/WizardStepper';
import OrderReviewSummary from './orderReview/OrderReviewSummary';
import AddFunds from './orderReview/AddFunds';
import PaymentMethod from './orderReview/PaymentMethod';
import { multiShippingAccountOrder } from '../../../../../actions';
import { formatDate, getSelected } from '../../../../../helpers/utils';
import { buildShippingOrderProofsOrAP, errorParser } from './orderReview/common';
import { useCompany, useCreditSummary, useQueryParams } from '../../../../../hooks';
import { paymentMethods } from '../../../../../apis/constants';
import { useLeftNavbar } from '../../../../../contexts/leftNavbar';

const commonText = { fontFamily: 'Gilroy-Medium', fontSize: 12 };

const useStyles = makeStyles({
  subTotalItem: { paddingTop: 12, borderTop: '1px solid #D6D8DB' },
  backTitle: { ...commonText, color: '#0b1829' },
  currentTitle: { ...commonText, color: '#787b80' },
  separator: { fontSize: 13, marginTop: 2 },
  container: props => ({
    padding: '24px 0px',
    ...(props.leftBarNavigation
      ? props.isNavbarOpen
        ? { maxWidth: 'calc(100% - 280px)', marginLeft: 260 }
        : { maxWidth: 'calc(100% - 150px)', marginLeft: 110 }
      : {})
  }),
  grey: { color: '#4A4F54' },
  arrowBack: {
    color: '#787b80',
    fontSize: 16,
    margin: '8px 7px 0px 0px'
  },
  productContainer: {
    borderRadius: 8,
    border: '1px solid #D6D8DB',
    padding: '20px 24px',
    marginBottom: 16
  },
  floatingInfoMessage: {
    maxWidth: 600,
    zIndex: 999999999,
    width: 'auto',
    top: '80px',
    position: 'fixed'
  }
});

const GoBack = ({ orderNo, classes }) => (
  <Grid container>
    <Link to={`/orders-requested/${orderNo}`}>
      <ArrowBack className={classes.arrowBack} />
    </Link>
    <Breadcrumbs
      separator={<span className={classes.separator}>/</span>}
      links={[
        { title: 'Back', to: `/orders-requested/${orderNo}`, styles: classes.backTitle },
        { title: `Order #${orderNo}`, to: `/orders-requested/${orderNo}`, styles: classes.currentTitle },
        { title: 'Order Review', styles: classes.currentTitle }
      ]}
    />
  </Grid>
);

const wizardSteps = [
  { name: 'Review Order', path: 'review-order' },
  { name: 'Add Funds', path: 'add-funds' },
  { name: 'Payment Method', path: 'payment-method' },
  { name: 'Order Summary', path: 'payment-method' }
];

const RequestOverview = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [disableContinue, setDisableContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCreditsLoading, setIsCreditsLoading] = useState(false);
  const [infoMessage, setInfoMessage] = useState();
  const order = useOrder();
  const location = useLocation();
  const history = useHistory();

  const {
    data: { current_balance }
  } = useCreditSummary();

  const [inputValue, setInputValue] = useState();
  const [useCreditFirst, setUseCreditFirst] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const { data: company } = useCompany();
  const isEnterpriseAccount = company.enterprise;
  const {
    sendFeDisplayedOrderTotalCostToApiTemp021722: sendOrderTotalCostToApi,
    enterpriseGlobalCheckoutFlowTemp031722: enterpriseCheckoutActive,
    leftBarNavigation
  } = useFlags();

  const isEnterpriseFlowActive = isEnterpriseAccount && enterpriseCheckoutActive;

  const { shippingElements, reorderProducts } = useSelector(state => state);

  useEffect(() => {
    switch (location.pathname) {
      case `/orders-requested/${order.id}/review-order`:
        setCurrentStep(1);
        break;
      case `/orders-requested/${order.id}/add-funds`:
        setCurrentStep(2);
        break;
      case `/orders-requested/${order.id}/payment-method`:
        setCurrentStep(3);
        break;
      case `/orders-requested/${order.id}/order-summary`:
        setCurrentStep(4);
        break;
      default:
        setCurrentStep(1);
    }
  }, [location.pathname, order.id]);

  const buildMultiShippingOrderPayload = () => {
    const [proofsOrAPs, proofOrAP] = ['account_products', 'account_product'];

    const directoryOrders = shippingElements.map(shippingElement => {
      const { employee, products, deliveryMethods } = shippingElement;
      const selectedDeliveryMethod = getSelected(deliveryMethods) || deliveryMethods[0];
      return {
        directory: {
          employee: employee.id,
          force_address: true
        },
        ...{
          [proofsOrAPs]: products.map(prod => ({
            [proofOrAP]: prod.id,
            sizes: prod.sizes
              .filter(s => s.quantity > 0)
              .map(s => ({
                size: s.id,
                quantity: s.quantity
              }))
          }))
        },
        shipping_date: formatDate(shippingElement.shippingDate, 'YYYY-MM-DD'),
        shipping_notes: shippingElement.shippingNote,
        delivery_method: selectedDeliveryMethod && selectedDeliveryMethod.id
      };
    });

    return {
      ...buildShippingOrderProofsOrAP(reorderProducts),
      directory_orders: directoryOrders,
      payment_method: window.sessionStorage.getItem('method'),
      shipping_credits: round(order.funds, 2), // round(credits, 2),
      use_credits_first: useCreditFirst,
      credits_to_use: inputValue
    };
  };

  const buildOrderPayload = () => {
    // const shippingInfo = getShipmentGroupsInfo(order.shipmentGroups);

    const payload = {
      opportunity: Number(order.id),
      payment_method: isEnterpriseFlowActive ? paymentMethods.ach : window.sessionStorage.getItem('method'),
      shipping_credits: round(order.funds, 2), // round(shippingInfo.international.price + shippingInfo.domestic.price + credits, 2),
      use_credits_first: useCreditFirst,
      credits_to_use: inputValue
    };

    return sendOrderTotalCostToApi
      ? { ...payload, price_display_to_user_at_checkout: round(order.currentTotal, 2) }
      : payload;
  };

  const createMultiShippingOrder = async () => {
    setDisableContinue(true);
    setLoading(true);
    const payloadBuilder = order.id ? buildOrderPayload : buildMultiShippingOrderPayload;
    const payload = payloadBuilder();
    // log.debug('createMultiShippingOrder:', payload);
    const response = await multiShippingAccountOrder(payload);
    // log.debug('createMultiShippingOrder response:', response);
    if (response.result === 'ok') {
      const { invoice } = isArray(response.data) ? response.data[0] : response.data;
      history.push(`/order-requested-summary/${invoice}`);
      setDisableContinue(false);
      setLoading(false);
      return undefined;
    }
    const errorMessage = errorParser(response.data);
    const capitalizeFirstLetter = string => (string && string[0].toUpperCase() + string.slice(1)) || '';
    setInfoMessage(errorMessage ? capitalizeFirstLetter(errorMessage) : null);
    setDisableContinue(false);
    setLoading(false);
    return undefined; // || defaultErrorMsg(response.status);
  };

  const query = useQueryParams();

  const handleContinue = async () => {
    switch (location.pathname) {
      case `/orders-requested/${order.id}/review-order`:
        history.replace({ pathname: `/orders-requested/${order.id}/add-funds`, search: query.toString() });
        break;
      case `/orders-requested/${order.id}/add-funds`:
        history.replace({ pathname: `/orders-requested/${order.id}/payment-method`, search: query.toString() });
        break;
      case `/orders-requested/${order.id}/payment-method`:
        await createMultiShippingOrder();
        break;
      default:
        history.push(`/orders-requested/${order.id}/review-order`);
    }
  };
  const handleToastClose = () => {
    setInfoMessage();
  };

  const leftNavBarContext = useLeftNavbar();

  const leftNavOpen = leftNavBarContext?.leftNavOpen || false;

  const classes = useStyles({ leftBarNavigation, isNavbarOpen: leftNavOpen });

  return (
    <div className={classes.container}>
      <CenteredGrid container>
        <Helmet tags={tags.orderReview} />
        {infoMessage && (
          <Grid container justifyContent="center" style={{ position: 'relative' }}>
            <Alert
              onClose={handleToastClose}
              delayTime={50000}
              className={classes.floatingInfoMessage}
              fontStyles={{ fontSize: 12, padding: 0 }}
              severity="error"
            >
              <div dangerouslySetInnerHTML={{ __html: infoMessage }} />
            </Alert>
          </Grid>
        )}
        <Box style={{ paddingBottom: 30 }}>
          <GoBack orderNo={order.id} classes={classes} />
        </Box>
        <Grid container spacing={8}>
          <Grid item md={8} sm={12}>
            <Box>
              <WizardStepper
                currentStep={currentStep}
                steps={wizardSteps}
                exactProgress={[4, 31, 57, 87]}
                basePath={`/orders-requested/${order.id}`}
              >
                <ReviewOrder />
                <AddFunds
                  setInvalid={setInvalid}
                  isCurrentView={currentStep === 2}
                  setIsCreditsLoading={setIsCreditsLoading}
                />
                <PaymentMethod
                  inputValue={inputValue}
                  currentBalance={current_balance}
                  setInputValue={setInputValue}
                  useCreditFirst={useCreditFirst}
                  setUseCreditFirst={setUseCreditFirst}
                  setInvalid={setInvalid}
                />
              </WizardStepper>
            </Box>
          </Grid>
          <Grid item md={4} sm={12}>
            <OrderReviewSummary
              handleContinue={handleContinue}
              isPaymentMethod={currentStep === 3}
              inputValue={inputValue}
              disableContinue={disableContinue || invalid}
              loading={loading}
              isCreditsLoading={isCreditsLoading}
            />
          </Grid>
        </Grid>
      </CenteredGrid>
    </div>
  );
};

export default RequestOverview;
