import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './styles/InventoryDisplayTool';
import { ProductImage, productImageTitle, useReorderLink } from './InventoryCommon';

const InventoryPackItem = ({ item, classes }) => {
  const linkToReorder = useReorderLink([item.product.id]);

  return (
    <Grid item xs={12} sm={6} lg={4} container style={{ padding: '10px 20px 20px 0px' }}>
      <Grid item style={{ paddingTop: 0 }}>
        <ProductImage
          productName={item.name}
          linkTo={item.product.enabled && linkToReorder}
          imgSrc={item.product.image}
          title={productImageTitle(item.product.enabled)}
          width={78}
          height={78}
          classes={classes}
        />
      </Grid>
      <Grid item xs style={{ padding: '0px 8px 0px 24px' }}>
        <p className={classes.detailsPackUnits} style={{ marginTop: 6 }}>{`${item.units_per_pack} per pack`}</p>
        <p className={classes.detailsPackName}>{item.product.name}</p>

        <p className={classes.detailsPackColor}>
          <span style={{ color: '#8d9299' }}>Color:</span>
          <span style={{ color: '#434d5c' }}>{` ${item.product.theme_color || 'Custom'}`}</span>
        </p>
        {item.product.enabled && (
          <Link to={linkToReorder} className={classes.tableLinkText}>
            Order in bulk
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(InventoryPackItem);
