import axios from '../DashBoard';
import apiPaths from '../../helpers/apiPaths';
import { status200 } from './status.utils';

export default {
  async fetchOrders(id) {
    const response = await axios.get(apiPaths.employeeOrders, { params: { employee: id, ordering: '-created_at' } });
    return response.data.results;
  },

  async fetchById(id, onError = err => err) {
    const data = await axios
      .get(apiPaths.contact(id))
      .then(response => response.data)
      .catch(() => onError());
    return data;
  },

  async fetch({ limit, offset, search, ordering, directoryIds, sizes, shippingCountry, groupIds } = {}) {
    return axios
      .get(apiPaths.contacts, {
        params: {
          limit,
          offset,
          search,
          ordering,
          directory_ids: directoryIds,
          size_ids: sizes,
          shipping_country: shippingCountry,
          group_ids: groupIds,
          contacts: 'not_soft_deleted'
        },
        ...status200
      })
      .then(response => response.data);
  },

  async delete(ids) {
    const response = await axios.delete(`${apiPaths.contacts}?pk=${ids.join('&pk=')}`);
    return response;
  },

  async softDelete(contacts) {
    const response = await axios.patch(
      `${apiPaths.employees}`,
      contacts.map(c => ({ id: c, delete_it: true }))
    );
    return response;
  }
};
