import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CheckCircle } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import styles from './styles/sendSwagConfiguration';
import { imageResolver, reverseTimingCoverter } from './common';

const useStyles = makeStyles(styles);

const RedeemLinkConfigurationFinish = ({ action, automation }) => {
  const currentTimingRaw = action.settings?.timing;

  const timingData = reverseTimingCoverter(currentTimingRaw);

  const classes = useStyles();

  return (
    <Box>
      <Grid container justifyContent="center">
        <Grid item>
          <Grid container justifyContent="center">
            <Grid item>
              <CheckCircle className={classes.notificationIcon} />
            </Grid>
          </Grid>
          <Typography variant="body3SemiBoldInter" className={classes.actionSubTitle}>
            Your automation is now set up!
          </Typography>
          <Typography variant="body4RegularInter" className={classes.actionFinishedSubTitle}>
            Finish the details on the Redeem tab
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Box className={classes.summaryContainer}>
          <Grid container alignItems="center">
            <Grid item>
              <Box className={classes.itemImageContainer}>
                <img src={imageResolver(automation?.type)} alt={automation?.name} className={classes.itemImage} />
              </Box>
            </Grid>
            <Grid item style={{ paddingLeft: 24 }}>
              <Typography variant="body3SemiBoldInter" className={classes.actionSubTitle}>
                {action?.name} {automation?.name}
              </Typography>
            </Grid>
          </Grid>
          <Box className={classes.finishDivider} />
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
                Redeem Page
              </Typography>
              <Grid container alignItems="center" spacing={4}>
                <Grid item>
                  <Box className={classes.itemImageContainer}>
                    <img
                      src={action.settings?.redeem_page?.image}
                      alt={action.settings?.redeem_page?.name}
                      className={classes.itemImage}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography variant="body3MediumInter" className={classes.productNameSummary}>
                    {action.settings?.redeem_page?.name}
                  </Typography>
                  <Typography variant="body4RegularInter" className={classes.productName}>
                    {action.settings?.redeem_page?.type}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.sectionTitleBoxRight}>
              <Box className={classes.sectionTitleBoxWrapper}>
                <Box className={classes.sectionTitleBox}>
                  <Typography variant="subtitle3SemiBoldInter" className={classes.sectionTitle}>
                    Preferences
                  </Typography>
                  <Typography variant="body3MediumInter" className={classes.productName}>
                    Custom Subject: <span>{action.settings?.redeem_page?.customSubject || 'None'}</span>
                  </Typography>
                  <Typography variant="body3MediumInter" className={classes.productName}>
                    Custom Message: <span>{action.settings?.redeem_page?.customMessage || 'None'}</span>
                  </Typography>
                  <Typography variant="body3MediumInter" className={classes.productName}>
                    Employee Types:{' '}
                    <span>
                      {isEmpty(action.settings?.employeeTypes) ? 'All' : action.settings?.employeeTypes?.join()}
                    </span>
                  </Typography>
                  <Typography variant="body3MediumInter" className={classes.productName}>
                    Execution: <span>{action.settings?.automatic ? 'Automatic' : 'Manual'}</span>
                  </Typography>
                  <Typography variant="body3MediumInter" className={classes.productName}>
                    Timing:{' '}
                    <span>
                      {action.settings?.timing === undefined || action.settings?.timing === 0
                        ? 'Exact date'
                        : `${timingData.inputNumber} ${timingData.inputTimeMeasure} ${timingData.inputTiming}`}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default RedeemLinkConfigurationFinish;
