import * as React from 'react';
import { useQuery } from 'react-query';
import { contactsApi } from '../../../apis/swagup';
import apiPaths from '../../../helpers/apiPaths';
import { useQueryFilterValidated } from '../../../hooks/useFilters';

const limit = 1000;

const useGetGroupsContactsIds = (excluded, areAllSelected) => {
  const [contactsIds, setContactsIds] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [enabled, setEnabled] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [scopeTotal, setScopeTotal] = React.useState(0);
  const [hasWithOrders, setHasWithOrders] = React.useState(false);
  const [withPendingOrders, setWithPendingOrders] = React.useState([]);

  const search = useQueryFilterValidated('search', () => true);
  const groups = useQueryFilterValidated('groups');

  const handleStatus = React.useCallback(
    (results, total) => {
      const withPendingOrdersAux = [];
      const extractedIds = results.map(
        ({
          id,
          has_pending_orders: hasPendingOrders,
          has_orders: hasOrders,
          is_in_shipment_groups: isInShipmentGroups
        }) => {
          const markExcluded = excluded.has(id) || hasPendingOrders || isInShipmentGroups;
          if (markExcluded) withPendingOrdersAux.push(id);
          if (!hasWithOrders && hasOrders && !hasPendingOrders) setHasWithOrders(true);
          return markExcluded ? -1 : id;
        }
      );
      if (withPendingOrdersAux.length > 0) setWithPendingOrders(prev => [...prev, ...withPendingOrdersAux]);

      setContactsIds([...contactsIds, ...extractedIds.filter(ei => ei > 0)]);

      const currentOffset = offset + limit;
      if (currentOffset < total) {
        setOffset(currentOffset);
        setProgress((currentOffset * 100) / total);
      } else {
        setScopeTotal(total);
        setEnabled(false);
      }
    },
    [contactsIds, excluded, hasWithOrders, offset]
  );

  React.useEffect(() => {
    setContactsIds([]);
    setOffset(0);
    setProgress(0);
    setScopeTotal(0);
    setHasWithOrders(false);
    setEnabled(false);
    setWithPendingOrders([]);
  }, [groups, search, excluded.size, areAllSelected]);

  const contactsPayload = { search, groupIds: groups, ordering: 'created_at', limit, offset };

  const { isLoading } = useQuery([apiPaths.contacts, contactsPayload], () => contactsApi.fetch(contactsPayload), {
    enabled: enabled && scopeTotal === 0,
    onSuccess: ({ results, count }) => handleStatus(results, count)
  });

  const hasWithPendingOrders = () =>
    areAllSelected && withPendingOrders.length > 0 && withPendingOrders.some(wpo => !excluded.has(wpo));

  return {
    contactsIds,
    hasWithPendingOrders,
    hasWithOrders,
    isLoading,
    progress,
    setEnabled
  };
};

export default useGetGroupsContactsIds;
