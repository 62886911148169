import React from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@swagup-com/components';
import { Box, Card, CardContent, Divider, Grid, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { CheckCircle, Sync } from '@material-ui/icons';
import dayjs from 'dayjs';
import { CenteredGrid } from '../../shared';
import { storePayouts } from '../../../apis/storefrontsServices';
import storefrontsServicesPaths from '../../../helpers/storefrontsServicesPaths';
import { moneyStr } from '../../../helpers/utils';
import { StatusChip } from '../../global/proofsCommon';
import { OrderRow, TableHeaderOrder } from '../storeCommon';

const PayoutDetails = ({ store, payout, classes }) => {
  // const { data: payoutsApi, isLoading: isLoading } = useQuery(
  //     [storefrontsServicesPaths.payoutsSingle(store.id, payout.id)],
  //     () => storePayouts.get
  //     (store.id, payout.id),
  //     {
  //       enabled: !!store.id && !!payout.id
  //     }
  //   );

  const { data: payoutsOrdersApi, isLoading: isLoadingOrders } = useQuery(
    [storefrontsServicesPaths.payoutOrders(store.id, payout.id)],
    () => storePayouts.payoutOrders(store.id, payout.id),
    {
      enabled: !!store.id && !!payout.id
    }
  );

  const relatedOrders = payoutsOrdersApi?.results || [];

  const isPending = payout.status === 'Upcoming';

  const NetPayoutCard = () => {
    return (
      <Card style={{ boxShadow: 'none', width: 500 }}>
        <CardContent>
          <Typography variant="body3RegularInter" style={{ marginBottom: 6 }}>
            Net payout
          </Typography>
          <Grid
            container
            style={{ marginBottom: 16, gap: 20, display: 'flex', justifyContent: 'start', alignContent: 'center' }}
          >
            <Typography variant="h4BoldInter">{moneyStr(payout.amount)}</Typography>
            <StatusChip label={`${payout.status}`} />
          </Grid>
          <Grid container>
            <Grid item>
              <Typography variant="body3RegularInter" style={{ marginBottom: 6 }}>
                Sales made
              </Typography>
              <Typography variant="subtitle3SemiBoldInter">{moneyStr(payout.totalSales)}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body3RegularInter" style={{ marginBottom: 6, color: 'transparent' }}>
                Menus
              </Typography>
              <Typography variant="subtitle3SemiBoldInter" style={{ textAlign: 'center' }}>
                -
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body3RegularInter" style={{ marginBottom: 6 }}>
                Sales tax
              </Typography>
              <Typography variant="subtitle3SemiBoldInter">{moneyStr(payout.salesTax)}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body3RegularInter" style={{ marginBottom: 6, color: 'transparent' }}>
                Menus
              </Typography>
              <Typography variant="subtitle3SemiBoldInter" style={{ textAlign: 'center' }}>
                -
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body3RegularInter" style={{ marginBottom: 6 }}>
                Fees
              </Typography>
              <Typography variant="subtitle3SemiBoldInter">{moneyStr(payout.swagupFee)}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <CenteredGrid>
      <NetPayoutCard />
      <Divider />
      <Typography variant="body2SemiBoldInter" style={{ padding: '20px 16px' }}>
        Timeline
      </Typography>
      <Box style={{ width: 482, border: '1px solid #E5E7E8', borderRadius: 12, padding: '16px 12px' }}>
        <Grid container spacing={4}>
          <Grid item>
            <CheckCircle style={{ color: isPending ? undefined : '#4CAF50' }} />
            <Box style={{ height: 64, background: isPending ? '#B7BBBF' : '#4CAF50', width: 1, marginLeft: 10 }} />
          </Grid>
          <Grid item>
            <Typography variant="body3MediumInter" style={{ color: isPending ? undefined : '#4CAF50' }}>
              Payout {isPending ? 'scheduled to hit' : 'sent to '} credit balance
            </Typography>
            <Typography variant="body4RegularInter" style={{ color: isPending ? undefined : '#4CAF50' }}>
              {dayjs(payout.paymentDate).format('MMMM Do, YYYY')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item>
            <Sync style={{ marginTop: 4, color: isPending ? '#4CAF50' : undefined }} />
          </Grid>
          <Grid item>
            <Typography variant="body3MediumInter" style={{ color: isPending ? '#4CAF50' : undefined }}>
              Payout scheduled automatically
            </Typography>
            <Typography variant="body4RegularInter" style={{ color: isPending ? '#4CAF50' : undefined }}>
              {dayjs(payout.createdDate).format('MMMM Do, YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider style={{ margin: '20px 0px' }} />

      <Grid style={{ padding: '0px 16px' }}>
        <Typography variant="body2SemiBoldInter" style={{ marginBottom: 20 }}>
          Overview
        </Typography>
        <Grid container item xs={12} spacing={3} style={{ height: 50 }}>
          <Grid item xs={2}>
            <Paper style={{ boxShadow: 'none' }}>
              <Typography variant="body3MediumInter" style={{ color: '#989EA4' }}>
                Date Paid
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper style={{ boxShadow: 'none' }}>
              <Typography variant="body3MediumInter" style={{ color: '#131415' }}>
                {dayjs(payout.paymentDate).format('MMMM Do, YYYY')}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={3} style={{ height: 50 }}>
          <Grid item xs={2}>
            <Paper style={{ boxShadow: 'none' }}>
              <Typography variant="body3MediumInter" style={{ color: '#989EA4' }}>
                Description
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper style={{ boxShadow: 'none' }}>
              <Typography variant="body3MediumInter" style={{ color: '#131415' }}>
                {payout.description}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={3} style={{ height: 50 }}>
          <Grid item xs={2}>
            <Paper style={{ boxShadow: 'none' }}>
              <Typography variant="body3MediumInter" style={{ color: '#989EA4' }}>
                Destination
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper style={{ boxShadow: 'none' }}>
              <Typography variant="body3MediumInter" style={{ color: '#131415' }}>
                {payout.destination}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Divider style={{ marginBottom: 20 }} />

      <Grid>
        <Typography variant="body2SemiBoldInter" style={{ padding: '0px 16px', marginBottom: 20 }}>
          Orders Included
        </Typography>
        <Box style={{ width: '100%', marginBottom: 64 }}>
          <TableContainer>
            <Table stickyHeader className={classes.orderTable}>
              <TableHeaderOrder classes={classes} />
              <TableBody>
                {relatedOrders?.map(order => (
                  <OrderRow key={order.id} order={order} hideMenu />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </CenteredGrid>
  );
};

export default PayoutDetails;
