import React from 'react';
import { makeStyles, Grid, InputAdornment, MenuItem, withStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Lock } from '@material-ui/icons';
import { useQueryClient } from 'react-query';
import styles from './styles/creditDrawer';
import { formatCreditCardNumber, formatCVC } from '../../../../helpers/utilsCC';
import { normalizeExDate, useScrollTop } from '../../../../helpers/utils';
import PaymentMethodForm from '../../../account/PaymentMethodForm';
import { TextField, SelectField } from '../../../global/reactHookFormFields';
import { paymentMethods } from '../../../../apis/constants';
import apiPaths from '../../../../helpers/apiPaths';

const useStyles = makeStyles(styles);

const CustomHooksTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: '14px 16px'
      },
      '& fieldset': {
        border: '1px solid #D6D8DB',
        borderRadius: 12
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #3577D4'
      }
    }
  }
})(TextField);

const CardDetails = ({ register, errors, getValues }) => {
  const classes = useStyles();

  const handleChangeExDate = value => {
    const prevValue = getValues('expiration_date');
    return normalizeExDate(value ?? '', prevValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2SemiBoldInter" className={classes.cardDetailsText}>
          Card Details
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          Card Number
        </Typography>
        <CustomHooksTextField
          autoComplete="off"
          placeholder="Credit card number"
          error={errors.card_number?.message}
          {...register('card_number')}
          formatFn={formatCreditCardNumber}
          defaultValue=""
          fullWidth
          data-private
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Lock className={classes.lockIcon} />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          Name on card
        </Typography>
        <CustomHooksTextField
          autoComplete="off"
          placeholder="Name on card"
          error={errors.full_name?.message}
          {...register('full_name')}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          Expiration Date
        </Typography>
        <CustomHooksTextField
          autoComplete="off"
          placeholder="Expiration date"
          error={errors.expiration_date?.message}
          {...register('expiration_date')}
          formatFn={handleChangeExDate}
          fullWidth
          data-private
        />
      </Grid>
      <Grid item xs={6} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          CVC
        </Typography>
        <CustomHooksTextField
          autoComplete="off"
          placeholder="CVC"
          error={errors.cvc?.message}
          {...register('cvc')}
          formatFn={formatCVC}
          fullWidth
          data-private
        />
      </Grid>
    </Grid>
  );
};

const BillingInformation = ({
  register,
  errors,
  country,
  control,
  provinces,
  countries,
  company,
  handleCountryChange,
  states,
  onStateChange,
  handleChangeZip
}) => {
  const classes = useStyles();
  const { selectRoot, menuPaper, menuItem, textFieldRoot, textInput, inputWarning, labelInput } = classes;

  const selectClasses = {
    selectRoot,
    menuPaper,
    menuItem,
    textFieldRoot,
    textInput,
    inputWarning,
    labelInput
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2SemiBoldInter" className={classes.cardDetailsText}>
          Billing Information
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          Country / Region*
        </Typography>
        <SelectField
          name="shipping_country"
          label="Country"
          error={errors.shipping_country?.message}
          totalItems={countries.length}
          onSelectChange={handleCountryChange}
          control={control}
          fullWidth
          classes={selectClasses}
        >
          {countries?.map(c => (
            <MenuItem key={c.iso2} value={c.iso2}>
              {c.name}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={12} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          First Name*
        </Typography>
        <CustomHooksTextField
          placeholder="First name"
          error={errors.first_name?.message}
          {...register('first_name')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          Last Name*
        </Typography>
        <CustomHooksTextField
          placeholder="Last name"
          error={errors.last_name?.message}
          {...register('last_name')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          Street Address*
        </Typography>
        <CustomHooksTextField
          placeholder="Street address"
          error={errors.shipping_address1?.message}
          {...register('shipping_address1')}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          Apt, Suite, Building
        </Typography>
        <CustomHooksTextField
          placeholder="Apt, Suite, Building"
          error={errors.shipping_address2?.message}
          {...register('shipping_address2')}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          City*
        </Typography>
        <CustomHooksTextField
          placeholder="City"
          error={errors.shipping_city?.message}
          {...register('shipping_city')}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          State*
        </Typography>
        {provinces.length === 0 ? (
          <CustomHooksTextField
            placeholder="State / Province / Region"
            error={errors.billing_state?.message}
            defaultValue={states.current[country] ?? ''}
            {...register('billing_state')}
            onChange={onStateChange}
            fullWidth
          />
        ) : (
          <SelectField
            name="billing_state"
            error={errors.billing_state?.message}
            defaultValue={states.current[country] ?? ''}
            onSelectChange={onStateChange}
            totalItems={provinces.length}
            control={control}
            label="State"
            fullWidth
            classes={selectClasses}
            onChange={onStateChange}
          >
            {provinces.map(p => (
              <MenuItem key={p.code} value={p.code}>
                {p.name}
              </MenuItem>
            ))}
          </SelectField>
        )}
      </Grid>
      <Grid item xs={6} className={classes.formInputContainer}>
        <Typography variant="body3RegularInter" className={classes.inputLabelForm}>
          Zip*
        </Typography>
        <CustomHooksTextField
          placeholder="Postal Code"
          error={errors.billing_zip?.message}
          defaultValue={company.shipping_zip}
          {...register('billing_zip')}
          onInput={handleChangeZip}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const AddCreditCardForm = ({
  register,
  control,
  errors,
  countries,
  handleCountryChange,
  provinces,
  states,
  company,
  onStateChange,
  handleChangeZip,
  getValues,
  disabled,
  isLoading,
  isDefault,
  setIsDefault
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const onAddingCard = () => queryClient.invalidateQueries([apiPaths.paymentProfiles, company?.id]);
  const [scrollTop, scrollProps] = useScrollTop();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className={classes.viewContentNewCard}
        {...scrollProps}
        style={{
          borderTop: scrollTop > 0 ? 'solid 1px #D6D8DB' : 'solid 1px transparent'
        }}
      >
        <Grid container style={{ paddingRight: 12 }}>
          <Grid item xs={12}>
            <CardDetails register={register} errors={errors} getValues={getValues} />
          </Grid>
          <Grid item xs={12}>
            <BillingInformation
              register={register}
              control={control}
              countries={countries}
              errors={errors}
              handleCountryChange={handleCountryChange}
              provinces={provinces}
              states={states}
              company={company}
              onStateChange={onStateChange}
              handleChangeZip={handleChangeZip}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox defaultChecked={isDefault} onChange={({ target: { checked } }) => setIsDefault(checked)} />
              }
              label={
                <Typography variant="body3RegularInter" style={{ marginLeft: 8 }}>
                  Set as default payment method
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ paddingTop: 16, borderTop: '1px solid #D6D8DB' }}>
          <Grid item>
            <Button
              variant="primary"
              size="small"
              type="submit"
              disabled={disabled || isLoading}
              loading={isLoading}
              onClick={onAddingCard}
            >
              Add Card
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CreditDrawerAddCard = ({ onCancel }) => {
  return (
    <Grid container style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid item xs={12}>
        <Typography variant="h5SemiBoldInter" style={{ paddingBottom: '8px' }}>
          Credit or Debit Card
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PaymentMethodForm
          selectedMethod={paymentMethods.creditCard}
          CustomForm={AddCreditCardForm}
          onClose={onCancel}
        />
      </Grid>
    </Grid>
  );
};

export default CreditDrawerAddCard;
