import { isHexColor } from '../../shared/styles/utils';

const toTitleCase = str => str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

const decorationStatusText = decoration => {
  const { deleted, created } = decoration;
  if (deleted) return `Deleted`;
  if (created) return 'Created';
  return `Updated`;
};

const decorationVisibilityRules = d => !(d.created && d.deleted);

const getVisibleDecorationsKeys = decorations =>
  Object.keys(decorations).filter(key => decorationVisibilityRules(decorations[key]));

const decorationName = decoration => (decoration.created ? decoration.Name : `Decoration ${decoration.Name || 'New'}`);

const hasExtension = (file, extensions) => file && extensions && extensions.find(ext => file.endsWith(ext));

const formatColor = hexColor => {
  if (!isHexColor(hexColor)) return 'multicolor';
  return `#${hexColor.replace('#', '')}`;
};

export { toTitleCase, decorationName, decorationStatusText, hasExtension, formatColor, getVisibleDecorationsKeys };
