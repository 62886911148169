const styles = theme => ({
  container: {
    paddingBottom: 42
  },
  center: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw',
      paddingLeft: '0 !important',
      minWidth: '100vw'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      minWidth: '100%',
      padding: '0px 24px !important'
    }
  },
  stylessButton: {
    height: '100% !important',
    '& > div, & .MuiButton-label': {
      height: '100% !important'
    },
    [theme.breakpoints.down('sm')]: {
      '& div': {
        height: 'auto !important'
      }
    }
  },
  hoveredButton: {
    boxShadow: '0 24px 56px 0 rgba(152, 70, 221, 0.25)',
    backgroundColor: '#9846dd',
    padding: '35px !important',
    borderRadius: '20px !important',
    height: '100% !important',
    border: 'solid 1px #9846dd',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 56px !important'
    }
  },
  simpleButton: {
    boxShadow: '0 24px 56px 0 rgba(255, 255, 255, 0.25)',
    padding: '35px !important',
    borderRadius: '20px !important',
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    height: '100% !important',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 56px !important'
    }
  },
  packNameText: {
    fontSize: 24,
    color: ({ isHovered }) => (isHovered ? '#ffffff' : '#0f2440'),
    paddingTop: 12,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      maxWidth: 228
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 288,
      paddingTop: 8,
      textAlign: 'center'
    }
  },
  packNameSubText: {
    fontSize: 16,
    lineHeight: 1.5,
    color: ({ isHovered }) => (isHovered ? '#ffffff' : '#8d9299'),
    paddingTop: 16,
    maxWidth: 268,
    [theme.breakpoints.down('md')]: {
      maxWidth: 288
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 288,
      paddingTop: 8,
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vh - 172px)'
    }
  },
  packPriceText: {
    fontSize: 16,
    flexGrow: 'unset',
    color: ({ isHovered }) => (isHovered ? '#ffffff' : '#8d9299'),
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  packPriceTextBlack: {
    color: ({ isHovered }) => (isHovered ? '#ffffff' : '#0f2440')
  },
  containerMinimumText: {
    justifyContent: 'flex-start',
    flexGrow: 'unset',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  minimumText: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    color: ({ isHovered }) => (isHovered ? '#ffffff' : '#8d9299'),
    borderRadius: 5,
    padding: 5,
    width: 'max-content',
    border: 'solid 1px #ebeef2',
    marginTop: 36,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  whiteText: {
    color: '#ffffff'
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  },
  packImg: {
    height: 268,
    width: 268,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: ({ isHovered }) => (isHovered ? 20 : 0),

    [theme.breakpoints.down('md')]: {
      width: 228,
      height: 228
    },
    [theme.breakpoints.down('xs')]: {
      width: 213,
      height: 213
    }
  },

  containerCustomImg: {
    maxHeight: 268,
    maxWidth: 268,
    border: 'dashed 3px #3577d4',
    borderRadius: 20,
    backgroundColor: 'rgba(235, 241, 251, 0.5)',
    [theme.breakpoints.down('md')]: {
      width: 228,
      height: 228
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vh - 172px)',
      maxHeight: 'calc(100vh - 172px)'
    }
  },
  customImg: {
    display: 'block',
    width: '155%',
    height: 'auto',
    margin: 'auto',
    position: 'relative',
    left: '53%',
    paddingBottom: 14,
    transform: 'translatex(-50%)'
  },
  hrBlue: {
    width: 78,
    height: 8,
    backgroundColor: '#3577d4'
  },
  grayBackground: {
    backgroundColor: '#fafafa',
    border: '1px solid #fafafa',
    paddingTop: '51px',
    paddingBottom: '51px'
  },
  whiteBackground: {
    backgroundColor: '#ffffff',
    border: '1px solid #ffffff',
    paddingTop: '51px',
    paddingBottom: '51px'
  },
  packDisclaimer: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    marginTop: '16px'
  },
  graySectionPack: {
    paddingLeft: '126px !important'
  },
  graySectionDesc: {
    paddingRight: '73px'
  }
});
export default styles;
