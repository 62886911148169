// see source reference https://github.com/streamich/react-use/blob/master/docs/useTimeoutFn.md
import * as React from 'react';

const useTimeout = (fn, delay = 0, onAutomaticExecute = false) => {
  const ready = React.useRef(false);
  const timeout = React.useRef();
  const callback = React.useRef(fn);

  const isReady = React.useCallback(() => ready.current, []);

  const set = React.useCallback(() => {
    ready.current = false;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      ready.current = true;
      callback.current();
    }, delay);
  }, [delay]);

  const clear = React.useCallback(() => {
    ready.current = null;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  React.useEffect(() => {
    callback.current = fn;
  }, [fn]);

  React.useEffect(() => {
    if (onAutomaticExecute) {
      set();
    }
    return clear;
  }, [set, clear, onAutomaticExecute]);

  return [isReady, clear, set];
};

export default useTimeout;
