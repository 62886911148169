import * as React from 'react';
import { makeStyles, Grid, Paper, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultPaymentProfile } from '../../../actions';
import CreditsSwitch from '../../global/sendSwagReviewCommons/CreditsSwitch';
import PaymentSection from '../../global/sendSwagReviewCommons/PaymentSection';
import ShipmentSection from '../../global/sendSwagReviewCommons/ShipmentSection';
import PaymentProfilesModal from '../../global/sendSwagReviewCommons/PaymentProfilesModal';
import ErrorAlert from '../../shared/ErrorAlert';
import { useCompany, useIsAchPrepaid } from '../../../hooks';
import { getShipmentSummary, canSendSwag } from '../../../helpers/utils';
import styles from './styles/ShippingSummary';
import useDiscountsAndRewards from '../../../hooks/useDiscountsAndRewards';

const useStyles = makeStyles(styles);

const ShippingSummary = ({
  balance,
  paymentProfiles,
  useCreditsFirst,
  onToggleUseCredits,
  onSendSwag,
  errorMessage,
  onError
}) => {
  const classes = useStyles();

  const isAchPrepaid = useIsAchPrepaid();
  const { data: company } = useCompany();
  const { allow_negative_credit_balance: allowNegativeCreditBalance } = company;
  const [showPaymentsModal, setShowPaymentsModal] = React.useState(false);

  const shipment = useSelector(state => state.shipmentGroup);
  const paymentProfile = paymentProfiles.find(pm => pm.default);

  const dispatch = useDispatch();
  const handleSelectDefaultPaymentProfile = paymentProfileId => {
    dispatch(setDefaultPaymentProfile(company.id, paymentProfileId, true));
    setShowPaymentsModal(false);
  };

  const summary = React.useMemo(() => getShipmentSummary(shipment), [shipment]);
  const totalItems = summary.domestic.numberOfItems + summary.international.numberOfItems;
  const total = summary.domestic.total + summary.international.total;
  const totalBeforeDiscount = summary.domestic.totalBeforeDiscount + summary.international.totalBeforeDiscount;
  return (
    <>
      <Grid container alignItems="flex-end" justifyContent="space-between" style={{ marginBottom: 16 }}>
        <p className={classes.subTitle}>Shipping summary</p>
        <CreditsSwitch
          balance={balance}
          useCreditsFirst={useCreditsFirst}
          onToggleUseCredits={onToggleUseCredits}
          disabled={balance <= 0 && !allowNegativeCreditBalance}
        />
      </Grid>
      <Paper elevation={0} className={classes.card}>
        {summary.domestic.numberOfRecipients > 0 && (
          <Grid container className={classes.cardSection}>
            <ShipmentSection
              title="DOMESTIC"
              employeesQty={summary.domestic.numberOfRecipients}
              itemsQty={summary.domestic.numberOfItems}
              totalPrice={summary.domestic.total}
            />
          </Grid>
        )}
        {summary.international.numberOfRecipients > 0 && (
          <Grid container className={classes.cardSection}>
            <ShipmentSection
              title="INTERNATIONAL"
              employeesQty={summary.international.numberOfRecipients}
              itemsQty={summary.international.numberOfItems}
              totalPrice={summary.international.total}
            />
          </Grid>
        )}
        <Grid container className={classes.cardSection}>
          <PaymentSection
            allowNegativeCreditBalance={allowNegativeCreditBalance}
            useCreditsFirst={useCreditsFirst}
            paymentProfiles={paymentProfiles}
            totalItems={totalItems}
            total={total}
            totalWithoutDiscount={totalBeforeDiscount}
            credits={balance}
            onShowPaymentsProfiles={() => setShowPaymentsModal(true)}
          />
        </Grid>
      </Paper>
      {onSendSwag && (
        <Grid container justifyContent="center" style={{ marginTop: 30 }}>
          {errorMessage && <ErrorAlert error={errorMessage} onError={onError} className={classes.errorAlert} />}
          <Button
            className={classes.sendButton}
            onClick={() => onSendSwag(total)}
            disabled={!canSendSwag(company, total, paymentProfiles, useCreditsFirst, isAchPrepaid)}
          >
            Send Swag
          </Button>
        </Grid>
      )}
      <PaymentProfilesModal
        open={showPaymentsModal}
        onClose={() => setShowPaymentsModal(false)}
        paymentProfile={paymentProfile}
        paymentProfiles={paymentProfiles}
        onSelectDefaultPaymentProfile={handleSelectDefaultPaymentProfile}
      />
    </>
  );
};

export default ShippingSummary;
