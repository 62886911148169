import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import Drawer from '../../common/Drawer';
import ContactForm from '../../shipments/ContactForm';

const useStyles = makeStyles({
  root: { zIndex: '1300 !important' },
  paper: {
    maxWidth: 594,
    padding: '35px 20px 35px 45px'
  }
});

const EditShippingRecipient = ({ open, onClose, handleSubmit, onSuccess, defaultValues }) => {
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={onClose} classes={classes}>
      <ContactForm
        title="Edit recipient"
        submitText="Save"
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        defaultValues={defaultValues}
        minimal
      />
    </Drawer>
  );
};

export default EditShippingRecipient;
