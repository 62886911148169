import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { isNumber } from 'lodash';
import templateStyles from './styles/storefrontTemplates';
import { isHexColor } from '../shared/styles/utils';

const useTempletesStyles = makeStyles(templateStyles);

const reducePixelByHalf = pixelString => {
  // Check if the string ends with 'px'
  if (typeof pixelString === 'string' && pixelString.endsWith('px')) {
    // Extract the number part from the string and convert it to a number
    const pixelValue = parseFloat(pixelString);

    // Reduce the pixel value by half
    const reducedPixelValue = pixelValue / 2.2;

    // Return the reduced pixel value with 'px' appended
    return `${reducedPixelValue}px`;
  }
  // Return the original string if it doesn't match the expected format
  return pixelString;
};

const reducePaddingByHalf = paddingString => {
  // Split the input string by space to get horizontal and vertical padding values
  const paddingValues = paddingString.split(' ');

  // Check if the paddingValues array contains two values
  if (paddingValues.length === 2) {
    // Extract the horizontal and vertical padding values and convert them to numbers
    const horizontalPadding = parseFloat(paddingValues[0]);
    const verticalPadding = parseFloat(paddingValues[1]);

    // Reduce the horizontal and vertical padding values by half
    const reducedHorizontalPadding = horizontalPadding / 2;
    const reducedVerticalPadding = verticalPadding / 2;

    // Return the reduced padding values in the format "XXpx XXpx"
    return `${reducedHorizontalPadding}px ${reducedVerticalPadding}px`;
  }
  // Return the original string if it doesn't match the expected format
  return paddingString;
};

const getComponent = component => {
  switch (true) {
    case component.type === 'image': {
      const { value, width, height, objectFit, borderRadius, name } = component;
      return (
        <div
          className={component.type}
          style={{
            width,
            height,
            borderRadius: reducePixelByHalf(borderRadius),
            position: 'relative',
            backgroundImage: `url(${value})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
          }}
        />
      );
    }
    case component.type === 'header-title': {
      const { value, fontSize, fontColor, fontFamily, contentAlignment } = component;
      return (
        <h1
          style={{
            fontSize: reducePixelByHalf(fontSize),
            color: fontColor,
            fontFamily,
            textAlign: contentAlignment,
            marginTop: 0,
            marginBottom: 8
          }}
        >
          {value}
        </h1>
      );
    }
    case component.type === 'subtitle-text': {
      const { value, fontSize, fontColor, fontFamily, contentAlignment } = component;
      return (
        <h4
          style={{
            fontSize: reducePixelByHalf(fontSize),
            color: fontColor,
            fontFamily,
            textAlign: contentAlignment,
            marginTop: 2
          }}
        >
          {value}
        </h4>
      );
    }
    case component.type === 'text': {
      const { value, fontSize, fontColor, fontFamily, contentAlignment } = component;
      return <p style={{ fontSize, color: fontColor, fontFamily, textAlign: contentAlignment }}>{value}</p>;
    }
    case component.type === 'button-link': {
      const {
        value,
        backgroundColor,
        fontSize,
        fontColor,
        fontFamily,
        padding,
        borderRadius,
        width,
        height,
        contentAlignment
      } = component;
      return (
        <Grid container justifyContent={contentAlignment}>
          <Grid item>
            <div
              className={component.type}
              style={{
                backgroundColor: backgroundColor === 'inherit' ? undefined : backgroundColor,
                fontSize: reducePixelByHalf(fontSize),
                color: fontColor,
                fontFamily,
                padding: reducePaddingByHalf(padding),
                borderRadius: reducePixelByHalf(borderRadius === 'inherit' ? undefined : borderRadius),
                width,
                height
              }}
            >
              {value}
            </div>
          </Grid>
        </Grid>
      );
    }
    default:
      return null;
  }
};

const useComponentStyles = makeStyles(() => ({
  component: {
    '& .image': {
      opacity: 1
    }
  },
  componentO: {
    '& .image': {
      height: ({ sectionOverlay, index }) => (sectionOverlay && index === 1 ? `100% !important` : undefined),
      width: ({ sectionOverlay, index }) => (sectionOverlay && index === 1 ? `100% !important` : undefined),
      borderTopLeftRadius: ({ sectionOverlay, index }) =>
        sectionOverlay && index === 1 ? `0px !important` : undefined,
      borderBottomLeftRadius: ({ sectionOverlay, index }) =>
        sectionOverlay && index === 1 ? '0px !important' : undefined,
      backgroundPosition: ({ sectionOverlay, index }) =>
        sectionOverlay && index === 1 ? 'center center !important' : undefined,
      backgroundSize: ({ sectionOverlay, index }) => (sectionOverlay && index === 1 ? 'cover !important' : undefined)
    }
  }
}));

const ComponentsGenerator = ({ components = [], sectionOverlay, index }) => {
  const classes = useComponentStyles({ sectionOverlay, index });
  return (
    <div
      className={sectionOverlay ? classes.componentO : classes.component}
      style={components.length > 1 ? undefined : { height: '100%', width: '100%' }}
    >
      {components.map(component => getComponent(component))}
    </div>
  );
};

const alignmentCoverter = alignment => {
  switch (alignment) {
    case 'center':
      return 'center';
    case 'rgiht':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

const ColumnsGenerator = ({ columns = [], sectionOverlay }) => {
  return columns.map((column, index) => {
    const { width, padding, contentAlignment, verticalAlignment, components } = column;
    return (
      <Grid key={column.id} item style={{ width, padding: reducePaddingByHalf(padding), zIndex: 0, maxHeight: '100%' }}>
        <Grid
          container
          justifyContent={alignmentCoverter(contentAlignment)}
          alignItems={alignmentCoverter(verticalAlignment)}
          style={{ width: '100%', height: '100%', position: 'relative' }}
        >
          <ComponentsGenerator components={components} sectionOverlay={sectionOverlay} index={index} />
        </Grid>
      </Grid>
    );
  });
};

const hexToRgba = (hexColor, opacity) => {
  // Remove the hash (#) if present
  const hex = hexColor.replace(/^#/, '');

  // Parse the hex values to RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Validate the opacity value (between 0 and 1)
  const validOpacity = Math.min(1, Math.max(0, opacity));

  // Return the RGBA color
  return `rgba(${r}, ${g}, ${b}, ${validOpacity})`;
};

const useSectionStyles = makeStyles(() => ({
  section: {
    '& .button-link': {
      background: ({ accentColor }) => accentColor || undefined,
      borderRadius: ({ buttonStyle }) =>
        buttonStyle === 'Rounded' ? '22px' : buttonStyle === 'Square' ? '4px' : undefined
    },
    '& .overlay': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: ({ overlayColor, overlayOpacity }) =>
        isHexColor(overlayColor) && isNumber(overlayOpacity) ? hexToRgba(overlayColor, overlayOpacity) : 'transparent'
    }
  }
}));

const SectionsGenerator = ({ sections = [], accentColor, buttonStyle }) => {
  const heightParser = h => h.replace('vh', '%');
  const [heroSection, ...rest] = sections;
  const justFirstSection = [heroSection];
  return justFirstSection.map(section => {
    const {
      height,
      backgroundColor,
      backgroundImage,
      backgroundPosition,
      backgroundSize,
      fontColor,
      fontFamily,
      overlayColor,
      overlayOpacity,
      columns
    } = section;

    const sectionOverlay = isHexColor(overlayColor) && overlayOpacity > 0;
    const classes = useSectionStyles({ accentColor, overlayColor, overlayOpacity, buttonStyle });
    return (
      <Grid
        key={section.id}
        className={classes.section}
        justifyContent="center"
        container
        alignItems="stretch"
        style={{
          position: 'relative',
          height: heightParser(height),
          backgroundColor,
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'unset',
          backgroundPosition,
          backgroundSize,
          fontColor,
          fontFamily,
          borderRadius: 6,
          overflow: 'hidden'
        }}
      >
        <div className="overlay" />
        <ColumnsGenerator columns={columns} sectionOverlay={sectionOverlay} />
      </Grid>
    );
  });
};

export default SectionsGenerator;
