import React from 'react';
import { FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { MenuItem, Typography } from '@swagup-com/components';
import { ColorInput, PresetTemplate, RedeemCustomSelectField } from '../storeCommon';
import styles from '../styles/storefrontsHome';
import { isHexColor } from '../../shared/styles/utils';

const useStyles = makeStyles(styles);

const PageTheme = ({ store, setStoreSettings, isThemeSelected, lightTheme, darkTheme, fontFamilies, themeVars }) => {
  const classes = useStyles();
  return (
    <Grid container style={{ overflow: 'hidden' }}>
      <Grid item xs={12}>
        <PresetTemplate
          onSelect={() => setStoreSettings({ ...lightTheme })}
          selected={isThemeSelected(lightTheme)}
          name="Light Theme"
          subtext="Let’s go to the beach and enjoy the sun."
          image="lighttheme"
        />
      </Grid>
      <Grid item xs={12}>
        <PresetTemplate
          onSelect={() => setStoreSettings({ ...darkTheme })}
          selected={isThemeSelected(darkTheme)}
          name="Dark Theme"
          subtext="Let’s head to space and go to the moon!"
          image="darktheme"
        />
      </Grid>
      <Grid item xs={12}>
        <PresetTemplate
          onSelect={() =>
            setStoreSettings({
              theme: 'custom',
              accentColor: store.visualSettings.accentColor.toLocaleLowerCase()
            })
          }
          selected={!isThemeSelected(darkTheme) && !isThemeSelected(lightTheme)}
          name="Custom Theme"
          subtext="Feeling adventurous? Customize the look of your storefront."
          image="customtheme"
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <RedeemCustomSelectField
                  id="fontFamily"
                  name="fontFamily"
                  label="Font Family"
                  value={store.visualSettings.fontFamily || 'Font Family'}
                  className={classes.selectField}
                  totalItems={fontFamilies.length}
                  handleSelect={({ target: { value } }) => setStoreSettings({ fontFamily: value })}
                  fullWidth
                  withTooltip
                >
                  {fontFamilies.map(ff => (
                    <MenuItem key={ff.value} value={ff.value} title={ff.label}>
                      {ff.label}
                    </MenuItem>
                  ))}
                </RedeemCustomSelectField>
              }
              label={
                <Typography variant="body3RegularInter" className={classes.inputLabel}>
                  Font Family
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Grid container spacing={10}>
              {themeVars.map(tv => (
                <Grid item xs={4} key={tv.key}>
                  <FormControlLabel
                    className={classes.formControl}
                    labelPlacement="top"
                    control={
                      <ColorInput
                        value={store.visualSettings[tv.key].toLocaleUpperCase()}
                        onChange={color => setStoreSettings({ [tv.key]: color })}
                        inputClass={
                          isHexColor(store.visualSettings[tv.key])
                            ? classes.inputColorText
                            : classes.inputColorTextError
                        }
                      />
                    }
                    label={
                      <Typography variant="body3RegularInter" className={classes.inputLabel}>
                        {tv.label}
                      </Typography>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <FormControlLabel
                  className={classes.formControl}
                  labelPlacement="top"
                  control={
                    <RedeemCustomSelectField
                      id="buttonStyle"
                      name="buttonStyle"
                      label="Button Style"
                      value={store.visualSettings.buttonStyle || 'Rounded'}
                      className={classes.selectField}
                      totalItems={2}
                      handleSelect={({ target: { value } }) => setStoreSettings({ buttonStyle: value })}
                      fullWidth
                      withTooltip
                    >
                      <MenuItem value="Rounded" title="Rounded">
                        Rounded
                      </MenuItem>
                      <MenuItem value="Square" title="Square">
                        Square
                      </MenuItem>
                    </RedeemCustomSelectField>
                  }
                  label={
                    <Typography variant="body3RegularInter" className={classes.inputLabel}>
                      Button Style
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  className={classes.formControl}
                  labelPlacement="top"
                  control={
                    <ColorInput
                      value={
                        store.visualSettings.footer === 'unset'
                          ? store.visualSettings.backgroundColor
                          : store.visualSettings.footer
                      }
                      onChange={color => setStoreSettings({ footer: color })}
                      inputClass={
                        isHexColor(store.visualSettings.footer) || store.visualSettings.footer === 'unset'
                          ? classes.inputColorText
                          : classes.inputColorTextError
                      }
                    />
                  }
                  label={
                    <Typography variant="body3RegularInter" className={classes.inputLabel}>
                      Footer Color
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageTheme;
