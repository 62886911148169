import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { ArrowBack, KeyboardArrowDown } from '@material-ui/icons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { isEmpty } from 'lodash';
import { StylessButton } from '../../buttons';
import {
  InvoiceProduct,
  SORDStatuses,
  ToogleButtom,
  estimatedShippingDate,
  isDomesticShipping,
  isInternationalShipping,
  orderBeCompletedText,
  orderStatuses
} from '../../pages/orders/requested/details/orderReview/common';
import { Breadcrumbs, CenteredGrid } from '../../shared';
import {
  addBusinessDaysToDate,
  joinFields,
  maxTurnaround,
  moneyStr,
  sumByProperty,
  sumByQuantity
} from '../../../helpers/utils';
import SimpleCustomStepper from '../../global/SimpleCustomStepper';
import { shipmentStatus } from '../../../apis/constants';
import ShipmentStatus from '../../global/ShipmentStatus';
import { fetchInvoiceOrders } from '../../../actions';
import { useCompany, useQueryParams } from '../../../hooks';
import toErrorPage from '../../../helpers/toErrorPage';
import Loader from '../../global/Loader';
import { CustomTooltip } from '../../products/commonProductsElements';
import apiPaths from '../../../helpers/apiPaths';
import { useReorderLink } from '../../inventory/InventoryCommon';
import { StatusChip } from '../../global/proofsCommon';
import { invoiceStatusStyles } from '../../account/Invoices';
import { shipmentsApi } from '../../../apis/swagup';
import shippingPricesApi from '../../../apis/swagup/shippingPrices';
import invoices from '../../../apis/swagup/invoices';
import InvoiceReviewSummary from '../../pages/orders/requested/details/orderReview/InvoiceReviewSummary';
import { TableEmptyState } from '../storeCommon';
import { toSentenceCase } from '../../account/MembershipCommon';
import { Img } from '../../global/ImgUtils';
import { ellipsisStyles } from '../../shared/styles/commonStyles';

const commonText = { fontFamily: 'Inter', fontSize: 12 };
const styles = () => ({
  orderSummaryPageContainer: { paddingTop: ({ isSummary }) => (isSummary ? 32 : 0) },
  title: {
    marginBottom: 12,
    color: '#131415',
    fontSize: 20,
    fontWeight: 700,
    fontFamily: 'Inter',
    lineHeight: '28px',
    '& span': {
      color: '#3577D4'
    }
  },
  subTitle: {
    fontWeight: 500,
    fontFamily: 'Inter',
    lineHeight: '28px',
    color: '#4A4F54',
    '& span': {
      fontWeight: 400
    }
  },
  sectionTitle: {
    fontWeight: 500,
    fontFamily: 'Inter',
    lineHeight: '28px',
    color: '#4A4F54',
    '& span': {
      fontWeight: 400
    }
  },
  orderStatusPanel: { padding: '24px 30px 0px 30px', borderRadius: 10 },
  statusTitle: { marginBottom: 12 },
  statusSubTitle: { marginBottom: 16 },
  statusText: { color: '#4A4F54', marginBottom: 6, fontSize: 14 },
  orderStatusBox: {},
  statusButtonLike: ({ isNext }) => ({
    width: 124,
    height: 28,
    borderRadius: 14,
    '& p': { color: '#3577D4', fontFamily: 'Inter' },
    background: isNext ? 'transparent' : '#F4F8FF',
    padding: '4px 10px'
  }),
  simpleText: { color: '#787B80', fontSize: 12, textAlign: 'right', '& span': { color: '#0B1829' } },
  orderStatusIconWrapper: {
    background: '#3577D4',
    height: 18,
    width: 18,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 9
  },
  orderStatusIcon: {
    background: '#3577D4',
    color: '#ffffff',
    height: '16px',
    width: '16px',
    marginTop: 1,
    marginLeft: 1
  },
  orderStatusIconWrapperNext: {
    background: '#F4F8FF',
    height: 18,
    width: 18,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 9
  },
  orderStatusIconNext: {
    background: '#F4F8FF',
    color: '#3577D4',
    height: '16px',
    width: '16px',
    marginTop: 1,
    marginLeft: 1
  },
  orderStatusVirtualBox: { borderLeft: '1px solid #EBEDF0' },
  statusDetail: { padding: '8px 0px 16px 44px' },
  statusDetailTitleOn: {
    color: '#4A505C',
    marginBottom: 2
  },
  statusDetailText: { color: '#787B80' },
  orderStatusItem: { marginLeft: -18 },
  orderProductsPanel: { paddingTop: 20 },
  subTotalItem: { paddingTop: 12, borderTop: '1px solid #D6D8DB' },
  backTitle: { ...commonText, color: '#0b1829' },
  currentTitle: { ...commonText, color: '#787b80' },
  separator: { fontSize: 13, marginTop: 2 },
  container: { padding: '24px 0px' },
  grey: { color: '#4A4F54' },
  arrowBack: {
    color: '#787b80',
    fontSize: 16,
    margin: '8px 7px 0px 0px'
  },
  productContainer: {
    borderRadius: 8,
    border: '1px solid #D6D8DB',
    padding: '20px 24px',
    marginBottom: 16
  },
  shipmentContainer: {
    borderRadius: 15,
    padding: '24px'
  },
  header: {},
  contentDivider: { height: '100%', width: 1, background: '#EBEDF0', margin: '6px 32px' },
  shippingSummary: {
    borderRadius: 15,
    border: '1px solid #EBEDF0',
    marginBottom: 124
  },
  buttonIcon: {
    marginTop: 4,
    color: 'inherit',
    fontSize: 18,
    transform: ({ open }) => (open ? 'rotate(-180deg)' : '')
  },
  itemTitle: {
    color: '#4A4F54',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '16px',
    marginBottom: 16
  },
  itemSKU: {
    color: '#4A4F54',
    fontFamily: 'Inter',
    fontSize: 14,
    maxWidth: 320,
    fontWeight: 400,
    lineHeight: '14px',
    ...ellipsisStyles
  },
  itemVariant: {
    color: '#4A4F54',
    height: 24,
    minWidth: 64,
    width: 'fit-content',
    padding: '0px 6px',
    borderRadius: 12,
    background: '#D6D8DB',
    fontFamily: 'Inter',
    margin: '10px 0px',
    fontSize: 10,
    marginBottom: 16,
    fontWeight: 600
  },
  productImageContainer: {
    height: 102,
    width: 102
  },
  productImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  statusMainTitle: {
    marginBottom: 30
  },
  statusTextTitle: {
    marginBottom: 8
  },
  editRecipientAddress: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  recipientAddress: {
    color: '#4A505C'
  },
  cancleBtn: ({ disabled }) => ({
    color: disabled ? '#A4A7AD' : '#C62828',
    height: '40px'
  }),
  floatingInfoMessage: {
    maxWidth: 464,
    zIndex: 1400,
    width: 'auto',
    top: '32px',
    position: 'fixed'
  }
});

const useStyles = makeStyles(styles);

const StatusBar = ({ icon, text, isNext, children }) => {
  const classes = useStyles({ isNext });
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Box className={classes.statusButtonLike}>
          <Grid container alignItems="center" style={{ height: '100%' }}>
            <Grid item>{icon}</Grid>
            <Grid item xs>
              <p style={{ textAlign: 'center' }}>{text}</p>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  );
};

const GoBack = ({ link, classes }) => (
  <Grid container>
    <Link to={link}>
      <ArrowBack className={classes.arrowBack} />
    </Link>
    <Breadcrumbs
      separator={<span className={classes.separator}>/</span>}
      links={[
        { title: 'Back', to: link, styles: classes.backTitle },
        { title: 'Order Review', styles: classes.currentTitle }
      ]}
    />
  </Grid>
);

// const isReachedDate = (first, second) => dayjs(first).isSame(second, 'day') || dayjs(first).isAfter(second, 'day');

// const itsAllShipped = (directoryOrders = [], orderProducts = []) => {
//   return orderProducts.every(({ product }) => {
//     const associatedDiors = directoryOrders.filter(dior => dior.products.find(p => p.product === product.id));
//     return associatedDiors.every(
//       dior => ![shipmentStatus.scheduled, shipmentStatus.pendingProduction].includes(dior.status)
//     );
//   });
// };

const getStatusDescription = order => {
  // , BUSINESS_DAYS_TO_START_PRODUCTION, TURNAROUND_TIME_PACKING, directoryOrders) => {
  const { sales_order } = order;
  if (!isEmpty(sales_order)) {
    switch (sales_order.status) {
      case SORDStatuses.pendingFinalCheck:
      case SORDStatuses.pendingPO:
        return orderStatuses.orderPlaced;
      case SORDStatuses.partialPO:
      case SORDStatuses.POComplete:
      case SORDStatuses.clientApprovedDelay:
        return orderStatuses.inProduction;
      case SORDStatuses.QAComplete:
      case SORDStatuses.readyToPack:
        return orderStatuses.qualityCheck;
      case SORDStatuses.missionComplete:
        return orderStatuses.completed;
      default:
        return orderStatuses.canceled;
    }
  }
  return orderStatuses.orderPlaced;
};

const OrderPanel = ({ order, isPreorder }) => {
  // , directoryOrders }) => {
  const classes = useStyles();
  // const metaData = useMetaData();
  // const { BUSINESS_DAYS_TO_START_PRODUCTION, TURNAROUND_TIME_PACKING } = metaData.data || {};
  // const statusDescription = getStatusDescription(order);
  //   order,
  //   BUSINESS_DAYS_TO_START_PRODUCTION,
  //   TURNAROUND_TIME_PACKING,
  //   directoryOrders
  // );

  const { customerInformation } = order;
  const { paymentInformation } = order;
  const shipment = order.shippingInformation?.shipment || {};
  const { orderStatus } = order;
  const total = paymentInformation
    ? paymentInformation.amount + paymentInformation.shipmentAmount + paymentInformation.taxAmount
    : 0;

  return (
    <Box key={order.id} className={classes.orderStatusPanel}>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 12 }}>
          <Grid container alignItems="center">
            <Grid item>
              <ShipmentStatus
                status={isPreorder && orderStatus === 'Error' ? 'Payment Processed' : orderStatus}
                withTooltip
                lightStyle
              />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Typography variant="body3SemiBoldInter">
                Tracking number:
                <a
                  target="_blank"
                  style={{ width: 'min-content', display: 'inline-block' }}
                  rel="noopener noreferrer"
                  href={shipment.trackingUrl}
                >
                  <Typography variant="body3SemiBoldInter" style={{ color: '#3577D4' }}>
                    {shipment.trackingNumber || '-'}
                  </Typography>
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.orderDetailsBox}>
            <Typography variant="h6SemiBoldInter" className={classes.title}>
              Order <span>{order.orderNumber}</span>
            </Typography>
            <Typography variant="body3RegularInter" className={classes.subTitle} style={{ marginBottom: 6 }}>
              Placed on: <span>{dayjs(order.createdDate).format('MM/DD/YYYY')}</span>
            </Typography>
            <Typography variant="body3RegularInter" className={classes.subTitle} style={{ marginBottom: 6 }}>
              Customer: <span>{joinFields([customerInformation?.firstName, customerInformation?.lastName])}</span>
            </Typography>
            <Typography variant="body3RegularInter" className={classes.subTitle}>
              Items: <span>{paymentInformation?.itemsAmount}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box className={classes.orderDetailsBox}>
            <Typography variant="body3MediumInter" className={classes.sectionTitle} style={{ marginBottom: 4 }}>
              Shipping method
            </Typography>
            <Typography variant="body3RegularInter" className={classes.statusText} style={{ marginBottom: 24 }}>
              {order.shippingInformation?.deliveryMethod}
            </Typography>
            <Typography variant="body3MediumInter" className={classes.sectionTitle} style={{ marginBottom: 4 }}>
              Shipping status
            </Typography>
            <Grid container>
              <Grid item>
                <ShipmentStatus status={shipment.status || 'Cancelled'} withTooltip lightStyle />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box className={classes.orderDetailsBox}>
            <Typography variant="body3SemiBoldInter" className={classes.title}>
              Total: {moneyStr(total)}
            </Typography>
            <Typography variant="body3RegularInter" className={classes.subTitle} style={{ marginBottom: 4 }}>
              Subtotal: <span>{moneyStr(paymentInformation?.amount)}</span>
            </Typography>
            <Typography variant="body3RegularInter" className={classes.subTitle} style={{ marginBottom: 4 }}>
              Shipment Cost: <span>{moneyStr(paymentInformation?.shipmentAmount)}</span>
            </Typography>
            <Typography variant="body3RegularInter" className={classes.subTitle}>
              Tax: <span>{moneyStr(paymentInformation?.taxAmount)}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.orderDetailsBox}>
            <Typography variant="body3RegularInter" className={classes.subTitle} style={{ marginBottom: 4 }}>
              Payment Method: <span>{toSentenceCase(paymentInformation?.paymentMethod?.replace('_', ' '))}</span>
            </Typography>
            <Typography variant="body3RegularInter" className={classes.subTitle}>
              Currency: <span>{paymentInformation?.currency?.replace('_', ' ')}</span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const OrderSummaryPageStatusContent = ({ order, isPreorder }) => {
  const classes = useStyles({ isSummary: false });
  return (
    <Box className={classes.orderSummaryPageContainer}>
      <OrderPanel key={order.id} order={order} isPreorder={isPreorder} />
    </Box>
  );
};

const getAddress = recipient =>
  joinFields(
    [
      joinFields([recipient.addressLine1, recipient.addressLine2], ' '),
      recipient.city,
      joinFields([recipient.state, recipient.country], ' ')
    ],
    ', '
  );

const OrderRecipientInformation = ({ order }) => {
  const { customerInformation } = order;
  const { shippingInformation } = order;
  const classes = useStyles();
  return (
    <Box className={classes.orderStatusPanel} style={{ paddingTop: 8, borderRadius: 0 }}>
      <Box style={{ paddingTop: 16, borderTop: '1px solid #E5E7E8', borderRadius: 0 }} />
      <Grid container spacing={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
        <Grid item xs={3}>
          <Typography variant="body3RegularInter" className={classes.subTitle} style={{ marginBottom: 6 }}>
            Recipient
          </Typography>
          <Typography variant="body3RegularInter" className={classes.statusText} style={{ fontWeight: 700 }}>
            {joinFields([customerInformation?.firstName, customerInformation?.lastName])}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body3RegularInter" className={classes.subTitle} style={{ marginBottom: 6 }}>
            Contact information
          </Typography>
          <Typography variant="body3RegularInter" className={classes.statusText}>
            {customerInformation?.emailAddress}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body3RegularInter" className={classes.subTitle} style={{ marginBottom: 6 }}>
            Shipping Address
          </Typography>
          <Typography variant="body3RegularInter" className={classes.statusText}>
            {getAddress(shippingInformation)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body3RegularInter" className={classes.subTitle} style={{ marginBottom: 6 }}>
            Billing Address
          </Typography>
          <Typography variant="body3RegularInter" className={classes.statusText}>
            Same as Shipping Address
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const OrderSelectedProducts = ({ order }) => {
  const { selectedProducts } = order;
  const classes = useStyles();
  return (
    <Box className={classes.orderStatusPanel} style={{ paddingTop: 8, borderRadius: 0 }}>
      <Box style={{ paddingTop: 16, borderTop: '1px solid #E5E7E8', borderRadius: 0 }} />
      <Grid container spacing={6}>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="body3RegularInter" className={classes.subTitle}>
            Items
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {selectedProducts.map(({ variant, sizes }) =>
            sizes.map(size => (
              <Grid sp={variant.id} container style={{ paddingBottom: 24 }}>
                <Grid item>
                  <Box className={classes.productImageContainer}>
                    <Img src={variant.imageUrl} atl={variant.name} className={classes.productImage} />
                  </Box>
                </Grid>
                <Grid item xs style={{ paddingLeft: 32 }}>
                  <Typography variant="body3SemiBoldInter" className={classes.itemTitle}>
                    {variant.name}
                  </Typography>
                  <Grid container justifyContent="center" alignItems="center" className={classes.itemVariant}>
                    <Grid item>{`${variant.colorName || 'custom'} / ${size.sizeName}`}</Grid>
                  </Grid>
                  <Typography variant="body4RegularInter" className={classes.itemSKU}>
                    {variant.description}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body3SemiBoldInter" className={classes.itemPricing}>
                    {moneyStr(size.price)} X {size.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body3SemiBoldInter" className={classes.itemPricing}>
                    {moneyStr(parseFloat(size.price) * size.quantity)}
                  </Typography>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const OrderSummaryPage = ({ order, isPreorder }) => {
  const [openDetails, setOpenDetails] = useState(true);

  const { data: company } = useCompany();

  const classes = useStyles({ open: openDetails });

  // const reorderLink = useReorderLink(invoice?.products.map(({ product }) => product.id) || []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <OrderSummaryPageStatusContent order={order} isPreorder={isPreorder} />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" style={{ paddingRight: 24 }}>
          <Grid item>
            <StylessButton onClick={() => setOpenDetails(prev => !prev)}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography component="p" variant="body3RegularInter" style={{ fontSize: 12, color: '#3577D4' }}>
                    Order details
                  </Typography>
                </Grid>
                <Grid item>
                  <KeyboardArrowDown className={classes.buttonIcon} />
                </Grid>
              </Grid>
            </StylessButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {openDetails && (
          <>
            <OrderRecipientInformation order={order} />
            <OrderSelectedProducts order={order} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

const OrderDetails = ({ orderDetails, isPreorder }) => {
  return (
    <CenteredGrid>
      {isEmpty(orderDetails) ? <TableEmptyState /> : <OrderSummaryPage order={orderDetails} isPreorder={isPreorder} />}
    </CenteredGrid>
  );
};

const AllOrderDetails = ({ orders }) => {
  return (
    <CenteredGrid>
      {isEmpty(orders) ? <TableEmptyState /> : orders.map(order => <OrderSummaryPage order={order} />)}
    </CenteredGrid>
  );
};

export { OrderDetails, AllOrderDetails };
