import { Box, Divider, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks';
import SelectFilter from '../global/SelectFilter';
import { FilterBy, SearchField, SortBy } from '../products/commonProductsElements';
import { CenteredGrid } from '../shared';

const defaultFilters = [
  {
    name: 'isActive',
    label: 'Status',
    options: {
      true: 'Active',
      false: 'Inactive'
    },
    behavior: 'select'
  },
  {
    name: 'collectionOnly',
    label: 'Redeem Type',
    options: {
      true: 'Collection',
      false: 'Autoship'
    },
    behavior: 'select'
  }
];

const useStyles = makeStyles({
  searchInputContainer: {
    '& .MuiInputBase-root': { minWidth: 232 }
  }
});
const defaultSortOptions = {
  '-created_at': 'Most Recent',
  created_at: 'Less Recent'
};
const RedeemFilterSection = ({
  setIsFiltering,
  filters = defaultFilters,
  searchPlaceholder = 'Search Redeem Pages',
  RigthSideComponent = () => <SortBy sortOptions={defaultSortOptions} />
}) => {
  const query = useQueryParams();
  const history = useHistory();
  const handleOnApply = React.useCallback(
    queryName => value => {
      if (setIsFiltering) setIsFiltering(true);
      if (!value) {
        query.delete(queryName);
      } else {
        query.set(queryName, value);
      }
      history.replace({ ...history.location, search: query.toString() });
    },
    [history, query, setIsFiltering]
  );

  const searchParam = query.get('search') || '';
  const classes = useStyles();
  return (
    <CenteredGrid container justifyContent="space-between" alignItems="center">
      <Box display="flex">
        <Typography variant="body2MediumInter" style={{ color: '#4A4F54' }}>
          FILTER
        </Typography>
        <Divider orientation="vertical" style={{ margin: '0 24px' }} flexItem />
        {filters.map(filter =>
          filter.behavior === 'select' ? (
            <SelectFilter
              key={`${filter.name}=${query.get(filter.name)}`}
              customTitle={filter.label}
              property={filter.name}
              selectOptions={filter.options}
            />
          ) : (
            <FilterBy
              key={`${filter.name}=${query.get(filter.name)}`}
              label={filter.label}
              options={filter.options}
              onApply={handleOnApply(filter.name)}
              initialValues={
                query.get(filter.name)
                  ? query
                      .get(filter.name)
                      ?.split(',')
                      .filter(Boolean)
                  : undefined
              }
              behavior={filter.behavior}
            />
          )
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        flex={1}
        className={classes.searchInputContainer}
      >
        <SearchField
          key={searchParam || 'search'}
          placeholder={searchPlaceholder}
          defaultValue={searchParam}
          onChange={handleOnApply('search')}
        />
        <RigthSideComponent />
      </Box>
    </CenteredGrid>
  );
};

export default RedeemFilterSection;
