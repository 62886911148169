import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useAsync from '../../hooks/useAsync';
import Accounts from './Accounts';
import { getPaymentProfiles } from '../../actions';
import toErrorPage from '../../helpers/toErrorPage';
import Loader from '../global/Loader';
import { useCompany } from '../../hooks';

const PaymentProfilesContainer = () => {
  const dispatch = useDispatch();
  const { data: company } = useCompany();

  const fetchInfo = useCallback(async () => {
    return dispatch(getPaymentProfiles(company.id));
  }, [dispatch, company]);

  const [fetchPaymentProfiles, infoResponse, infoIsPending, infoHasFailed] = useAsync(fetchInfo);
  useEffect(() => {
    fetchPaymentProfiles();
  }, [fetchPaymentProfiles]);

  const history = useHistory();
  useEffect(() => {
    if (infoHasFailed) toErrorPage(infoHasFailed, history);
  }, [infoHasFailed, history]);

  const isLoading = infoIsPending || !infoResponse;
  return isLoading ? <Loader absolute /> : <Accounts />;
};

export default PaymentProfilesContainer;
