import React, { useEffect, useState } from 'react';
import { Grid, withStyles, Fab } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import isArray from 'lodash/isArray';

import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import log from '../../logger';
import styles from './styles/MultiShippingPayment';
import toErrorPage from '../../helpers/toErrorPage';
import {
  multiShippingOrder,
  fetchIndividualPackPrices,
  fetchStorageCategories,
  fetchStoragePrices,
  setReorderProducts,
  createShipment
} from '../../actions';
import PaymentSection from '../paymentSection/PaymentSection';
import CheckoutSideBar from './CheckoutSidebar';
import { Breadcrumbs, Helmet } from '../shared';
import tags from '../../apis/seoTags';
import { formatDate, sumByQuantityParseInt, getSelected, getShipmentGroupsInfo } from '../../helpers/utils';
import { getWarehouseProducts, updateProofQuantitiesByDirectory } from './MultishippingCommon';
import { getProductSizes, getStoragePrice, buildShippingOrderProofsOrAP } from './common';
import Loader from '../global/Loader';
import { useOrder } from '../pages/orders/requested/OrderContext';
import { defaultErrorMsg } from '../../utils/errors';
import { ToWarehouseCard } from './MultishippingOverview';
import { paymentMethods } from '../../apis/constants';
import { useCompany, useShippingCutoffHour, useSizes } from '../../hooks';
import { buildProofAlikeObject, getDirectoryOrders } from '../pages/orders/requested/common/utilsOrder';

const MultiShippingPayment = props => {
  const { classes, shippingElements, items, credits } = props;
  const [individualPackPrices, setIndividualPackPrices] = useState({});
  const [storagePrices, setStoragePrice] = useState([]);
  const [storageCategories, setStorageCategories] = useState([]);
  const [storageTotal, setStorageTotal] = useState(0);
  const [isValidCreditCard, setIsValidCreditCard] = useState(true);

  const isDataLoaded = () => !isEmpty(storagePrices) && !isEmpty(storageCategories) && !isEmpty(individualPackPrices);

  const history = useHistory();
  const fetchStoragePricesData = async () => {
    const response = await props.fetchStoragePrices();
    if (response.result === 'ok') {
      setStoragePrice(response.data.results);
    } else {
      toErrorPage(response.result, history);
    }
  };

  const fetchIndividualPackPricesData = async () => {
    const response = await props.fetchIndividualPackPrices();
    if (response.result === 'ok') {
      setIndividualPackPrices(response.data.results[0]);
    } else {
      toErrorPage(response.result, history);
    }
  };

  const fetchStorageCategoriesData = async () => {
    const response = await props.fetchStorageCategories();
    if (response.result === 'ok') {
      setStorageCategories(response.data.results);
    } else {
      toErrorPage(response.result, history);
    }
  };

  useEffect(() => {
    fetchIndividualPackPricesData();
    fetchStorageCategoriesData();
    fetchStoragePricesData();
  }, []);

  const shippingCutoffHour = useShippingCutoffHour();
  const order = useOrder();
  const dispatch = useDispatch();
  const { data: sizes } = useSizes();

  useEffect(() => {
    if (items.length === 0) {
      dispatch(setReorderProducts(order.products.map(buildProofAlikeObject(sizes))));
      dispatch(createShipment(sizes, getDirectoryOrders(order), shippingCutoffHour));
    }
  }, [items, order, shippingCutoffHour, sizes, dispatch]);

  useEffect(() => {
    let storageTotalTemp = 0;
    const newProofs = updateProofQuantitiesByDirectory(items, shippingElements);

    if (!isEmpty(storagePrices)) {
      newProofs.forEach(proof => {
        const productSizes = getProductSizes(proof);
        const quantities = sumByQuantityParseInt(productSizes);
        storageTotalTemp += getStoragePrice(proof.product, storagePrices) * quantities;
      });

      setStorageTotal(storageTotalTemp);
    }
  });

  const buildMultiShippingOrderPayload = () => {
    const [proofsOrAPs, proofOrAP] = ['account_products', 'account_product'];

    const directoryOrders = shippingElements.map(shippingElement => {
      const { employee, products, deliveryMethods } = shippingElement;
      const selectedDeliveryMethod = getSelected(deliveryMethods) || deliveryMethods[0];
      return {
        directory: {
          employee: employee.id,
          force_address: true
        },
        ...{
          [proofsOrAPs]: products.map(prod => ({
            [proofOrAP]: prod.id,
            sizes: prod.sizes
              .filter(s => s.quantity > 0)
              .map(s => ({
                size: s.id,
                quantity: s.quantity
              }))
          }))
        },
        shipping_date: formatDate(shippingElement.shippingDate, 'YYYY-MM-DD'),
        shipping_notes: shippingElement.shippingNote,
        delivery_method: selectedDeliveryMethod && selectedDeliveryMethod.id
      };
    });

    return {
      ...buildShippingOrderProofsOrAP(items),
      directory_orders: directoryOrders,
      payment_method: window.sessionStorage.getItem('method'),
      shipping_credits: round(credits, 2)
    };
  };

  const { id } = useParams();
  const shippingOrderCost = useSelector(state => state.shippingOrderCost);
  const { data: company } = useCompany();
  const isEnterpriseAccount = company.enterprise;
  const {
    sendFeDisplayedOrderTotalCostToApiTemp021722: sendOrderTotalCostToApi,
    enterpriseGlobalCheckoutFlowTemp031722: enterpriseCheckoutActive
  } = useFlags();
  const isEnterpriseFlowActive = isEnterpriseAccount && enterpriseCheckoutActive;

  const buildOrderPayload = () => {
    const shippingInfo = getShipmentGroupsInfo(order.shipmentGroups);

    const payload = {
      opportunity: Number(id),
      payment_method: isEnterpriseFlowActive ? paymentMethods.ach : window.sessionStorage.getItem('method'),
      shipping_credits: round(shippingInfo.international.price + shippingInfo.domestic.price + credits, 2)
    };

    return sendOrderTotalCostToApi
      ? { ...payload, price_display_to_user_at_checkout: round(shippingOrderCost, 2) }
      : payload;
  };

  const createMultiShippingOrder = async () => {
    const payloadBuilder = id ? buildOrderPayload : buildMultiShippingOrderPayload;
    const payload = payloadBuilder();
    log.debug('createMultiShippingOrder:', payload);
    const response = await props.multiShippingOrder(payload);
    log.debug('createMultiShippingOrder response:', response);
    if (response.result === 'ok') {
      const { invoice } = isArray(response.data) ? response.data[0] : response.data;
      history.push(`/shipping-order-confirmation/${invoice}`);
      return undefined;
    }
    return response.data?.error_message || defaultErrorMsg(response.status);
  };

  const onCreditCardValidityChange = isValid => setIsValidCreditCard(isValid);

  const handleErrorDuringPayment = error => toErrorPage(error, history);

  const products = getWarehouseProducts(shippingElements, items);

  const commonlinks = [{ title: 'Review Order' }, { title: 'Storage & Shipping' }];
  const enterpriseFlowLinks = [{ title: 'Place Order' }];
  const links = isEnterpriseFlowActive ? enterpriseFlowLinks : [...commonlinks, ...enterpriseFlowLinks];

  return (
    <div className={classes.container}>
      <Helmet tags={tags.multiShippingPayment} />
      {isDataLoaded() ? (
        <Grid container justifyContent="center" spacing={0}>
          <Grid item className={classes.center}>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={12} md={8}>
                <Breadcrumbs links={links} />
                <h1 className={classes.pPageTitle}>{isEnterpriseFlowActive ? 'Order overview' : 'Payment Details'}</h1>
                {isEnterpriseFlowActive && products.length > 0 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <ToWarehouseCard
                        classes={classes}
                        shippingElements={shippingElements}
                        items={items}
                        individualPackPrices={individualPackPrices}
                        storagePrices={storagePrices}
                        storageCategories={storageCategories}
                      />
                    </Grid>
                  </Grid>
                )}
                <PaymentSection
                  onValidityChange={onCreditCardValidityChange}
                  handleAfterPayConfirmation={createMultiShippingOrder}
                  handleErrorDuringConfirmation={handleErrorDuringPayment}
                >
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.GoBackOneContainer}>
                        <Fab className={classes.GoBack} onClick={history.goBack}>
                          <Grid container style={{ paddingTop: 10 }}>
                            <Grid item xs={2} style={{ paddingTop: 2 }}>
                              <KeyboardBackspaceIcon />
                            </Grid>
                            <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 40 }}>
                              Previous step
                            </Grid>
                          </Grid>
                        </Fab>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.ContinueContainer}>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={!isValidCreditCard}
                          className={classes.Continue}
                          id="pay-order"
                        >
                          Place Order
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </PaymentSection>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className={classes.checkoutSideBarContainer}>
                <CheckoutSideBar
                  individualPackPrices={individualPackPrices}
                  products={items}
                  selectedCredit={credits}
                  selectedStorage={storageTotal}
                  shippingElements={shippingElements}
                  showCredit={credits > 0}
                  showStorage={storageTotal > 0}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  shippingElements: state.multishipping,
  items: state.reorderProducts,
  credits: state.credits
});

export default connect(mapStateToProps, {
  multiShippingOrder,
  fetchIndividualPackPrices,
  fetchStorageCategories,
  fetchStoragePrices
})(withStyles(styles)(MultiShippingPayment));
