// import { ellipsisStyles, scrollBar } from '../../shared/styles/commonStyles';
// import { changeColorLightness } from '../../shared/styles/utils';

const styles = theme => ({
  exportButtom: {
    padding: '0px 12px',
    fontFamily: 'Gilroy',
    cursor: 'pointer',
    color: '#3577d4',
    '&:disabled': {
      color: 'ebeef2'
    }
  },
  paginationContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 12,
    borderTop: 'solid 1px #f0f2f5',
    paddingTop: '32px'
  },
  orderTable: {
    '& .MuiTableCell-root': {
      borderRight: '0px !important',
      '& .divContent': theme.typography.body4RegularInter
    },
    '& th.MuiTableCell-root .divContent': {
      color: '#989EA4'
    }
  },
  tabTitle: {
    color: '#989EA4',
    '& span': {
      color: '#131415'
    }
  },
  cellCentered: {
    '& .divContent': {
      width: '100%',
      textAlign: 'center'
    }
  }
});
export default styles;
