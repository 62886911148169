import React, { useState, useEffect } from 'react';
import { Grid, withStyles, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import round from 'lodash/round';
import CalendarModalTool from '../global/CalendarModalTool';
import { moneyStr, getTaxes, totalShippingPrice, getSelected } from '../../helpers/utils';
import styles from './styles/ShipmentDetailsHome';
import {
  selectBatchDeliveryMethod,
  addBatchShippingDate,
  addBatchShippingNote,
  addBatchShippingTax
} from '../../actions';
import { minimumShippingDate } from '../../helpers/commonDateFunctions';
import { shippingNotesPlaceHolder } from '../../helpers/helperConstants';

import log from '../../logger';
import { useShippingCutoffHour } from '../../hooks';

const getTurnaroundTime = ({ min, max }) => {
  if (max === 1) return '1 business day';
  if (min === max) return `${min} business days`;
  return `${min}-${max} business days`;
};

const DeliveryOptions = ({
  classes,
  deliveryMethods,
  shippingType,
  onSelectDeliveryMethod,
  estimatedDelivery,
  shippingsSelected
}) => {
  const dmSummary = {};
  shippingsSelected.forEach(s => {
    s.deliveryMethods.forEach(dm => {
      if (!dmSummary[dm.name]) {
        dmSummary[dm.name] = { min: 300, max: 0, price: 0, count: 0 };
      }
      dmSummary[dm.name].min = Math.min(dm.turnaround_time_min_days, dmSummary[dm.name].min);
      dmSummary[dm.name].max = Math.max(dm.turnaround_time_max_days, dmSummary[dm.name].max);
      dmSummary[dm.name].price += totalShippingPrice(dm.prices.breakdown);
      dmSummary[dm.name].count += 1;
    });
  });

  const dmInfo = {};
  deliveryMethods.forEach(dm => {
    if (dmSummary[dm.name].count === shippingsSelected.length) {
      dmSummary[dm.name].show = true;
      if (dm.selected) dmInfo.dmAlreadySelected = true;
      if (!dmInfo.firstDM) dmInfo.firstDM = dm;
    }
  });
  log.debug('dmSummary:', dmSummary, 'dmInfo:', dmInfo);
  if (!dmInfo.dmAlreadySelected && dmInfo.firstDM) onSelectDeliveryMethod(dmInfo.firstDM);

  const elementClass = (dmName, selected, regular) => (shippingType === dmName ? selected : regular);

  return (
    <Grid container>
      {deliveryMethods.map(dm =>
        dm.name !== 'Priority' && dmSummary[dm.name].show ? (
          <Grid item xs={12} key={dm.id}>
            <div
              className={elementClass(dm.name, classes.ShippingTypeSelected, classes.ShippingType)}
              role="presentation"
              onClick={() => onSelectDeliveryMethod(dm)}
            >
              <Grid container>
                <Grid item xs={6}>
                  <p className={elementClass(dm.name, classes.MethodTitleSelected, classes.MethodTitle)}>
                    {dm.name === 'Express' ? 'Express 🚀' : dm.name}
                  </p>
                </Grid>
                <Grid item xs={6} align="right">
                  <p className={elementClass(dm.name, classes.MethodTitleSelected, classes.MethodTitle)}>
                    {moneyStr(round(dmSummary[dm.name].price / dmSummary[dm.name].count, 2))}
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p className={classes.DeliveryTime}>{getTurnaroundTime(dmSummary[dm.name])}</p>
                </Grid>
              </Grid>
            </div>
            {dm.selected && estimatedDelivery && (
              <div className={classes.EstimatedMessageContainer}>
                <p className={classes.EstimatedMessage}>Est. delivery {estimatedDelivery}</p>
              </div>
            )}
          </Grid>
        ) : (
          <div />
        )
      )}
    </Grid>
  );
};

const ShippingMethod = ({
  classes,
  deliveryMethods,
  onSelectDeliveryMethod,
  shippingType,
  estimatedDelivery,
  shippingsSelected,
  onePackOnly
}) => (
  <div className={classes.ShippingContainer}>
    <p className={classes.CalculationHeaderDelivery}>Delivery method{onePackOnly ? ' (Per pack)' : ''}</p>
    <DeliveryOptions
      classes={classes}
      deliveryMethods={deliveryMethods}
      shippingType={shippingType}
      onSelectDeliveryMethod={onSelectDeliveryMethod}
      estimatedDelivery={estimatedDelivery}
      shippingsSelected={shippingsSelected}
    />
  </div>
);

const getIds = arr => arr.map(e => e.id);

const getIdsToChange = (shippingElement, shippingElements) => {
  const isDomestic = shippingElement.employee.shipping_country === 'US';
  const filteredShippings = shippingElements.filter(s => {
    const isShipToUSA = s.employee.shipping_country === 'US';
    return isDomestic ? isShipToUSA : !isShipToUSA;
  });
  return getIds(filteredShippings);
};

const maxNoteLength = 220;

const ShipmentDetails = ({
  classes,
  shippingElements,
  elementId,
  estimatedDelivery,
  onePackOnly,
  selectBatchDeliveryMethodAction,
  addBatchShippingDateAction,
  addBatchShippingNoteAction,
  addBatchShippingTaxAction,
  shippingsSelected
}) => {
  const shippingElement = shippingElements.find(ms => ms.id === elementId);
  const { deliveryMethods } = shippingElement;
  const selectedMethod = getSelected(deliveryMethods);
  const methodName = (selectedMethod && selectedMethod.name) || 'Standard';

  const [shippingType, setShippingType] = useState(methodName);
  const [minDate, setMinDate] = useState(new Date());
  const [shippingDate, setShippingDate] = useState(new Date(shippingElement.shippingDate || Date.now()));
  const [currentNote, setCurrentNote] = useState('');

  const saveShippingDate = date => {
    addBatchShippingDateAction(getIdsToChange(shippingElement, shippingElements), date);
    setShippingDate(date);
  };

  const shippingCutoffHour = useShippingCutoffHour();

  const onSelectDeliveryMethod = dm => {
    const ids = getIdsToChange(shippingElement, shippingElements);
    selectBatchDeliveryMethodAction(ids, dm.name);
    setShippingType(dm.name);
    setMinDate(minimumShippingDate(new Date(), shippingCutoffHour));
    const total = totalShippingPrice(dm.prices.breakdown);
    addBatchShippingTaxAction(ids, getTaxes(shippingElement.employee.shipping_state, total));
  };

  useEffect(() => {
    if (shippingDate < minDate) saveShippingDate(minDate);
  }, [minDate]);

  useEffect(() => {
    const { deliveryMethods: deliveryM } = shippingElements.find(ms => ms.id === elementId);
    const selectedM = getSelected(deliveryM);
    const methodN = (selectedM && selectedM.name) || 'Standard';
    setShippingType(methodN);
    if (selectedM) {
      setShippingDate(shippingElement.shippingDate || new Date());
      setMinDate(minimumShippingDate(new Date(), shippingCutoffHour));
    }
  }, [elementId, shippingCutoffHour]);

  useEffect(() => {
    setCurrentNote(shippingElements[0].shippingNote || '');
  }, [shippingElements]);

  useEffect(() => {
    const { deliveryMethods: deliveryM } = shippingElements.find(ms => ms.id === elementId);
    const selectedM = getSelected(deliveryM);
    if (selectedM) {
      setShippingDate(shippingElement.shippingDate || new Date());
      setMinDate(minimumShippingDate(new Date(), shippingCutoffHour));
    }
  }, [deliveryMethods, shippingCutoffHour]);

  const handleChangeNote = () => addBatchShippingNoteAction(getIds(shippingElements), currentNote.trim());

  return (
    <div className={classes.RecipientActions}>
      <Grid container>
        <Grid item container direction="column" xs={6}>
          <Grid item style={{ marginBottom: 31 }}>
            <p className={classes.CalculationHeader}>Select Shipping date </p>
            <CalendarModalTool
              selectedDate={shippingDate}
              onSubmit={saveShippingDate}
              minDate={minDate}
              variant="rounded"
            />
          </Grid>
          <Grid item>
            <ShippingMethod
              classes={classes}
              deliveryMethods={deliveryMethods}
              onSelectDeliveryMethod={onSelectDeliveryMethod}
              shippingType={shippingType}
              estimatedDelivery={estimatedDelivery}
              shippingsSelected={shippingsSelected}
              onePackOnly={onePackOnly}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={6}>
              <p className={classes.CalculationHeader}>Shipping notes</p>
              <div className={classes.notesContainer}>
                <TextField
                  className={classes.textShippingNote}
                  placeholder={shippingNotesPlaceHolder}
                  multiline
                  maxRows={5}
                  value={currentNote}
                  onBlur={handleChangeNote}
                  onChange={e => setCurrentNote(e.target.value)}
                  InputProps={{
                    classes: { input: classes.textAreaResize },
                    inputProps: { maxLength: maxNoteLength }
                  }}
                />
                <p className={classes.notesLength}>
                  {currentNote.length} / {maxNoteLength}
                </p>
              </div>
            </Grid>
            <p className={classes.subTextShippingNote}>Notes will apply to both domestic and international shipment</p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  shippingElements: state.multishipping
});

export default connect(mapStateToProps, {
  selectBatchDeliveryMethodAction: selectBatchDeliveryMethod,
  addBatchShippingDateAction: addBatchShippingDate,
  addBatchShippingNoteAction: addBatchShippingNote,
  addBatchShippingTaxAction: addBatchShippingTax
})(withStyles(styles)(ShipmentDetails));
