import {
  SHIPMENTS_HAVE_INVALID_ADDRESS,
  SHIPMENTS_FETCH_START,
  SHIPMENTS_FETCH_SUCCESS,
  SHIPMENTS_DELETE_START,
  SHIPMENTS_DELETE_SUCCESS
} from '../actions/types';

const initialState = { items: [], total: -1, loading: false, haveInvalidAddress: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case SHIPMENTS_FETCH_START:
    case SHIPMENTS_DELETE_START:
      return {
        ...state,
        loading: true
      };

    case SHIPMENTS_FETCH_SUCCESS:
    case SHIPMENTS_DELETE_SUCCESS:
      return {
        ...state,
        items: action.payload.shipments,
        total: action.payload.total,
        loading: false
      };

    case SHIPMENTS_HAVE_INVALID_ADDRESS:
      return {
        ...state,
        haveInvalidAddress: action.payload
      };

    default:
      return state;
  }
};
