import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { SortBy, FilledSearchField } from '../global/Filters';

const useStyles = makeStyles(theme => ({
  sort: {
    width: 240,
    [theme.breakpoints.down('md')]: {
      width: 220
    }
  },
  filter: { width: 290 },
  search: { height: 56 },
  actionBar: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start'
    }
  },
  searchContainer: {
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: 200
    }
  }
}));

const createOption = (text, value) => ({ text, value });

const allSortOptions = {
  none: createOption('None', 'None'),
  newestFirst: createOption('Newest first', '-created_at'),
  oldestFirst: createOption('Oldest first', 'created_at'),
  descendByName: createOption('Name A to Z ', 'first_name'),
  ascendByName: createOption('Name Z to A', '-first_name'),
  descendByInvoiceId: createOption('Largest id first ', 'id'),
  ascendByInvoiceId: createOption('Smallest id first', '-id'),
  descendByAmount: createOption('Lowest first', 'total_plus_tax'),
  ascendByAmount: createOption('Highest first ', '-total_plus_tax'),
  invoicePaidSort: createOption('Paid first', '-status '),
  invoiceTeamSort: createOption('Team A - A', ' ')
};

const sortOptions = [
  allSortOptions.newestFirst,
  allSortOptions.oldestFirst,
  allSortOptions.ascendByAmount,
  allSortOptions.descendByAmount
];

const allFilterOptions = {
  all: createOption('All', 'All'),
  paid: createOption('Paid', 'Paid'),
  onlyPendingPayment: createOption('Only Pending Payment', 'only_pending_payment'),
  overdue: createOption('Overdue', 'over_due'),
  pendingPayment: createOption('Pending Payment', 'Pending Payment')
};

const filterOptions = [
  allFilterOptions.all,
  allFilterOptions.paid,
  allFilterOptions.onlyPendingPayment,
  allFilterOptions.overdue,
  allFilterOptions.pendingPayment
];

const ActionBar = ({
  filterSelected,
  onChangeFilter,
  sortSelected,
  onChangeSort,
  searchPlaceholder,
  searchValue,
  onChangeSearch
}) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" className={classes.actionBar}>
      <Grid item md={3} lg={4} className={classes.searchContainer}>
        <FilledSearchField
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={onChangeSearch}
          fullWidth
          height={58}
        />
      </Grid>
      <Grid container justifyContent="center" item md>
        <SortBy
          classes={{ sortBy: classes.sort }}
          title="Sort:"
          value={sortSelected || allSortOptions.newestFirst.value}
          onChange={onChangeSort}
          items={sortOptions}
          height={58}
        />
      </Grid>
      <Grid container justifyContent="center" item md>
        <SortBy
          title="Filter:"
          classes={{ sortBy: classes.filter }}
          value={filterSelected || allFilterOptions.all.value}
          onChange={onChangeFilter}
          items={filterOptions}
          height={58}
        />
      </Grid>
    </Grid>
  );
};

export default ActionBar;
