import Payment from 'payment';

const clearNumber = (value = '') => value.replace(/\D+/g, '');

const formatCreditCardNumber = value => {
  if (!value) {
    return value;
  }
  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  switch (issuer) {
    case 'amex':
      return `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`.trim();
    case 'dinersclub':
      return `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`.trim();
    default:
      return `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
        12,
        19
      )}`.trim();
  }
};

const formatCVC = (value, prevValue, allValues = {}) => {
  const clearValue = clearNumber(value);
  let maxLength = 4;
  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }
  return clearValue.slice(0, maxLength);
};

export { formatCreditCardNumber, formatCVC };
