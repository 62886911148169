import React, { useEffect } from 'react';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { CompanyWarningTooltip } from '../../../../shared';
import { normalizeUSZip, zipCodeText } from '../../../../../helpers/utils';
import { useCountries, useMemoizeStateFields, useSizes } from '../../../../../hooks';
import { PhoneField, SelectField, TextField } from '../../../../global/reactHookFormFields';

const useStyles = makeStyles({
  primaryText: {
    fontFamily: 'Gilroy-Medium',
    letterSpacing: 'normal',
    fontSize: 14,
    color: '#0b1829'
  },
  inputsContainer: {
    padding: '16px 0 24px 0',
    width: 'calc(100% + 9px)'
  }
});

const PersonalInfo = ({ isSaving, minimal }) => {
  const classes = useStyles();
  const { formState, control, register, watch } = useFormContext();
  const { errors } = formState;
  const { data: sizes } = useSizes({ select: sizesData => sizesData.filter(s => s.category === 'Apparel') });

  const country = watch('shipping_country');

  return (
    <>
      <p className={classes.primaryText}>Personal info</p>
      <Grid container spacing={3} className={classes.inputsContainer}>
        <Grid style={{ paddingRight: 8 }} item sm={6} xs={12}>
          <TextField
            autoFocus
            autoComplete="first_name"
            error={errors.first_name?.message}
            disabled={isSaving}
            placeholder="First name"
            {...register('first_name', { deps: ['last_name'] })}
            fullWidth
            withTooltip
          />
        </Grid>
        <Grid item style={{ paddingLeft: 8 }} sm={6} xs={12}>
          <TextField
            autoComplete="last_name"
            error={errors.last_name?.message}
            disabled={isSaving}
            placeholder="Last name"
            {...register('last_name', { deps: ['first_name'] })}
            fullWidth
            withTooltip
          />
        </Grid>
        {!minimal && (
          <Grid item style={{ paddingRight: 8 }} sm={6} xs={12}>
            <CompanyWarningTooltip>
              <TextField
                autoComplete="company_name"
                error={errors.company?.message}
                disabled={isSaving}
                placeholder="Company"
                {...register('company_name')}
                fullWidth
                withTooltip
              />
            </CompanyWarningTooltip>
          </Grid>
        )}
        {!minimal && (
          <Grid item style={{ paddingRight: 8 }} sm={6} xs={12}>
            <TextField
              autoComplete="job_title"
              error={errors.company?.message}
              disabled={isSaving}
              placeholder="Job title"
              {...register('title')}
              fullWidth
              withTooltip
            />
          </Grid>
        )}
        <Grid item style={{ paddingRight: 8 }} sm={6} xs={12}>
          <PhoneField
            name="phone_number"
            error={errors.phone_number?.message}
            disabled={isSaving}
            country={country}
            control={control}
            defaultValue=""
            placeholder="Phone number"
            variant="outlined"
            withTooltip
          />
        </Grid>
        <Grid item style={{ paddingLeft: 8 }} sm={6} xs={12}>
          <TextField
            autoComplete="email"
            error={errors.email?.message}
            disabled={isSaving}
            placeholder="Email"
            {...register('email')}
            fullWidth
            withTooltip
          />
        </Grid>
        {!minimal && (
          <Grid style={{ paddingRight: 8 }} item sm={6} xs={12}>
            <SelectField
              name="size"
              error={errors.size?.message}
              totalItems={sizes.length}
              disabled={isSaving}
              control={control}
              label="Shirt Size"
              hideLabel
              defaultValue=""
              fullWidth
              withTooltip
            >
              <MenuItem value="">
                <i>None</i>
              </MenuItem>
              {sizes.map(size => (
                <MenuItem key={size.id} value={size.id}>
                  {size.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const ShippingAddress = ({ isLoading }) => {
  const classes = useStyles();
  const { formState, control, register, watch, setValue, trigger } = useFormContext();
  const { errors } = formState;
  const query = useCountries();

  const [country, state] = watch(['shipping_country', 'shipping_state']);
  const [onCountryChange, onStateChange] = useMemoizeStateFields({ country, change: setValue, initialState: state });

  useEffect(() => {
    if (country) trigger('phone_number');
  }, [country, trigger]);

  if (query.status === 'error') throw new Error(query.error.data?.detail);

  const isInternational = country !== 'US';
  const handleChangeZip = e => {
    if (!isInternational) {
      const { value } = e.target;
      const newValue = normalizeUSZip(value);
      setValue('shipping_zip', newValue, { shouldValidate: true });
    }
  };

  const countries = query.data || [];
  const provinces = countries.find(c => c.iso2 === country)?.provinces ?? [];

  return (
    <>
      <p className={classes.primaryText}>Shipping address</p>
      <Grid container spacing={3} className={classes.inputsContainer}>
        <Grid item xs={12}>
          <SelectField
            name="shipping_country"
            error={errors.shipping_country?.message}
            totalItems={countries.length}
            disabled={isLoading || query.isLoading}
            onSelectChange={onCountryChange}
            control={control}
            label="Country"
            hideLabel
            defaultValue="US"
            fullWidth
            withTooltip
          >
            {countries?.map(c => (
              <MenuItem key={c.iso2} value={c.iso2}>
                {c.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="shipping_address1"
            error={errors.shipping_address1?.message}
            disabled={isLoading}
            placeholder="Address"
            {...register('shipping_address1')}
            fullWidth
            withTooltip
          />
        </Grid>
        <Grid item style={{ paddingRight: 8 }} sm={6} xs={12}>
          <TextField
            error={errors.shipping_address2?.message}
            disabled={isLoading}
            autoComplete="shipping_address2"
            placeholder="Floor, suite, unit (optional)"
            {...register('shipping_address2')}
            fullWidth
            withTooltip
          />
        </Grid>
        <Grid item style={{ paddingLeft: 8 }} sm={6} xs={12}>
          <TextField
            autoComplete="shipping_city"
            error={errors.shipping_city?.message}
            disabled={isLoading}
            placeholder="City"
            {...register('shipping_city')}
            fullWidth
            withTooltip
          />
        </Grid>
        <Grid item style={{ paddingRight: 8 }} sm={6} xs={12}>
          {provinces.length === 0 ? (
            <TextField
              autoComplete="shipping_state"
              error={errors.shipping_state?.message}
              disabled={isLoading}
              placeholder="State / Province / Region"
              {...register('shipping_state')}
              onChange={onStateChange}
              fullWidth
              withTooltip
            />
          ) : (
            <SelectField
              name="shipping_state"
              error={errors.shipping_state?.message}
              disabled={isLoading}
              onSelectChange={onStateChange}
              totalItems={provinces.length}
              label="State"
              hideLabel
              defaultValue=""
              control={control}
              fullWidth
              withTooltip
            >
              {provinces.map(p => (
                <MenuItem key={p.code} value={p.code}>
                  {p.name}
                </MenuItem>
              ))}
            </SelectField>
          )}
        </Grid>
        <Grid item style={{ paddingLeft: 8 }} sm={6} xs={12}>
          <TextField
            error={errors.shipping_zip?.message}
            disabled={isLoading}
            autoComplete="shipping_zip"
            placeholder={zipCodeText(isInternational)}
            {...register('shipping_zip')}
            onInput={handleChangeZip}
            fullWidth
            withTooltip
          />
        </Grid>
      </Grid>
    </>
  );
};

export { PersonalInfo, ShippingAddress };
