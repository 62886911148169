import { ellipsisStyles } from '../../../../shared/styles/commonStyles';

const styles = {
  infoHeader: {
    fontSize: 14,
    marginBottom: 6
  },
  infoText: {
    ...ellipsisStyles,
    maxWidth: 400,
    lineHeight: '26px',
    color: '#0b1829'
  },
  infoSection: {
    padding: '11px 5px 9px'
  },
  cancelButton: {
    height: 56,
    marginRight: 30
  },
  updateButton: {
    height: 56
  }
};

export default styles;
