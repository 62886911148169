import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Box, Grid, Radio, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CheckCircle, CheckCircleRounded } from '@material-ui/icons';
import { debounce } from 'lodash';
import accountProducts from '../../../apis/swagup/accountProducts';
import { Pagination } from '../../shared';
import { CustomTooltip, SearchField } from '../../products/commonProductsElements';
import styles from './styles/sendSwagConfiguration';
import { StylessButton } from '../../buttons';
import { imageSrcSet, moneyStr } from '../../../helpers/utils';
import apiPaths from '../../../helpers/apiPaths';
import Img from '../../shared/Img';
import { ellipsisStyles } from '../../shared/styles/commonStyles';
import {
  CustomSlimTextField,
  CustomSlimTextFieldThin,
  CustomTextField,
  GreenAntSwitch
} from '../../storefronts/storeCommon';
import EmailPreview from './EmailPreview';
import { IntegrationItem } from './commonComponents';

const useStyles = makeStyles(styles);

const starterAmounts = [10, 20, 50];

const StoreConfigurationCredits = ({ action, onActionUpdate }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [openPreview, setOpenPreview] = useState(false);

  const handleGiftAmount = value => {
    onActionUpdate({
      ...action,
      settings: {
        ...action.settings,
        store: { ...action.settings?.store, giftAmount: value }
      }
    });
  };

  const handleShowCustomMessage = value => {
    onActionUpdate({
      ...action,
      settings: {
        ...action.settings,
        store: { ...action.settings?.store, showCustomMessage: value }
      }
    });
  };

  const handleGiftAmountChange = event => {
    let { value } = event.target;
    const convertToNumber = Number(value);
    value = isNaN(convertToNumber) ? '' : convertToNumber;

    onActionUpdate({
      ...action,
      settings: {
        ...action.settings,
        store: { ...action.settings?.store, giftAmount: value || '' }
      }
    });
  };

  const handleInputChange = ({ target }) => {
    const limitCharacters = target.value;
    if (limitCharacters.length > 500) {
      setErrorMsg('Cannot enter more than 500 characters');
    } else {
      setErrorMsg('');
    }
    onActionUpdate({
      ...action,
      settings: {
        ...action.settings,
        store: { ...action.settings?.store, customMessage: limitCharacters.substring(0, 500) }
      }
    });
  };

  const handleSubjectInputChange = ({ target }) => {
    const limitCharacters = target.value;
    if (limitCharacters.length > 100) {
      setErrorMsg('Cannot enter more than 100 characters');
    } else {
      setErrorMsg('');
    }

    onActionUpdate({
      ...action,
      settings: {
        ...action.settings,
        store: { ...action.settings?.store, customSubject: limitCharacters.substring(0, 100) }
      }
    });
  };

  const selectedStore = action.settings?.store || {};

  const classes = useStyles();

  return (
    <Box className={classes.integrationProductsWrapper}>
      <Typography variant="body3SemiBoldInter" className={classes.actionTitleSmall}>
        Shop selected for this automation
      </Typography>
      <Grid container justifyContent="center">
        <IntegrationItem
          key={selectedStore.id}
          title={selectedStore.name}
          icon={
            <Box className={classes.integrationProductImageWrapper}>
              <Img
                src={selectedStore.image || '/images/public/nopic.jpg'}
                alt={selectedStore.name}
                className={classes.integrationImage}
              />
            </Box>
          }
          type={selectedStore.type}
          ellipsisStylesStyle={ellipsisStyles}
          product
          long
        />
      </Grid>
      <Box>
        <Typography variant="body3SemiBoldInter" className={classes.actionSubTitle} style={{ marginTop: 12 }}>
          Now set the funds to add to their Shop Credit Balance
        </Typography>
        <Grid container justifyContent="center" className={classes.modalContent}>
          <Grid item xs={9}>
            <Typography variant="body3SemiBoldInter" className={classes.textForm}>
              Select the amount you want to gift
            </Typography>
            <Grid container spacing={3} alignItems="center">
              {starterAmounts.map(sa => (
                <Grid key={sa} item xs={3}>
                  <StylessButton onClick={() => handleGiftAmount(sa)} style={{ width: '100%' }}>
                    <Box
                      className={
                        starterAmounts.includes(selectedStore.giftAmount) && sa == selectedStore.giftAmount
                          ? classes.amountSelectorSelected
                          : classes.amountSelector
                      }
                    >
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography variant="body3SemiBoldInter" className={classes.amountSelectorText}>
                            {moneyStr(sa)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <CheckCircle style={{ marginBottom: 1 }} />
                        </Grid>
                      </Grid>
                    </Box>
                  </StylessButton>
                </Grid>
              ))}
              <Grid item xs={3}>
                <CustomSlimTextField
                  placeholder="0.00"
                  name="custom-amount"
                  onChange={handleGiftAmountChange}
                  fullWidth
                  value={!starterAmounts.includes(selectedStore.giftAmount) ? selectedStore.giftAmount : null}
                  InputProps={{
                    startAdornment: <span id="start-adornment">$</span>
                  }}
                />
              </Grid>
            </Grid>
            <Box style={{ paddingTop: 28 }}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Box className={classes.customMessageForm}>
                    <Grid container justifyContent="center">
                      <Grid item xs>
                        <Typography variant="subtitle3SemiBoldInter">Add custom message</Typography>
                      </Grid>
                      <Grid item>
                        <GreenAntSwitch
                          checked={selectedStore.showCustomMessage}
                          onChange={({ target: { checked } }) => handleShowCustomMessage(checked)}
                          value={selectedStore.showCustomMessage}
                          color="primary"
                        />
                      </Grid>
                      <Grid item>
                        <Box style={{ marginLeft: 16, height: 16 }}>
                          <StylessButton onClick={() => setOpenPreview(true)}>
                            <img
                              alt="Store-Front-Preview"
                              src="/images/storefront/store-front-preview.png"
                              srcSet={imageSrcSet('/images/storefront/store-front-preview.png')}
                              width={16}
                              height={16}
                            />
                          </StylessButton>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box style={{ paddingTop: 8 }}>
                      <Typography variant="body3RegularInter" className={classes.appreciationTex}>
                        Keep in mind that recipients will read this message when receiving their gift. It’s a nice way
                        to say “I appreciate you”!
                      </Typography>
                    </Box>
                    {selectedStore.showCustomMessage && (
                      <Box style={{ paddingTop: 12 }}>
                        <Typography variant="body3SemiBoldInter" className={classes.textForm}>
                          Subject line *
                        </Typography>
                        <CustomTextField
                          value={selectedStore.customSubject}
                          onChange={handleSubjectInputChange}
                          placeholder="Add a subject"
                          fullWidth
                        />
                        <Typography variant="body3SemiBoldInter" style={{ paddingBottom: 16, marginTop: 16 }}>
                          Message *
                        </Typography>
                        <CustomSlimTextFieldThin
                          className={classes.inputTextMulti}
                          placeholder="Add your message"
                          value={selectedStore.customMessage}
                          onChange={handleInputChange}
                          multiline
                          InputProps={{
                            endAdornment: (
                              <Typography variant="body3RegularInter" style={{ color: '#989EA4' }}>{`${selectedStore
                                .customMessage?.length || 0}/500`}</Typography>
                            )
                          }}
                        />
                        <Typography variant="body3RegularInter" style={{ paddingTop: '10px', color: '#F44336' }}>
                          {errorMsg}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <EmailPreview open={openPreview} message={selectedStore.customMessage} onClose={() => setOpenPreview(false)} />
    </Box>
  );
};

export default StoreConfigurationCredits;
