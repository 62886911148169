import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StorefrontsHome from './StorefrontsHome';
import StorefrontsHistory from './StorefrontsHistory';
import StorefrontsCreate from './StorefrontsCreate';
import StoreCreated from './StoreCreated';

const StoresRouter = () => {
  return (
    <Switch>
      <Route path="/shops" exact>
        <StorefrontsHome />
      </Route>
      <Route path={['/shop-create', '/shop-customize/:id']} exact>
        <StorefrontsCreate />
      </Route>
      <Route path="/shop-details/:id" exact>
        <StorefrontsHistory />
      </Route>
      <Route path="/shops/all-set" exact>
        <StoreCreated />
      </Route>
    </Switch>
  );
};

export default StoresRouter;
