import * as React from 'react';
import { CircularProgress, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { EditOutlined } from '@material-ui/icons';
import { ellipsisStyles } from '../shared/styles/commonStyles';

const text = {
  height: 26,
  fontFamily: 'Gilroy-SemiBold',
  fontSize: 16,
  lineHeight: 1.63,
  letterSpacing: 'normal',
  color: '#0b1829',
  ...ellipsisStyles
};

const useStyles = makeStyles({
  text,
  input: {
    ...text,
    padding: 0,
    margin: 0
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  editBtn: {
    padding: 0,
    transition: 'opacity 0.3s ease-out',
    backgroundColor: 'transparent !important'
  },
  editIcon: {
    width: 16,
    height: 16,
    color: '#0b1829'
  },
  container: {
    maxWidth: 450,
    borderRadius: 4,
    marginLeft: -2,
    marginRight: -2,
    paddingLeft: 2,
    paddingRight: 8,
    '&:hover, &:focus-within': {
      // boxShadow: '0 0 1px 1px #D6D8DB'
    },
    '& .carousel-button, & .carousel-image': {
      transition: 'all 0.3s ease-in'
    },
    '&:not(:hover) .editBtn': {
      visibility: 'hidden',
      opacity: 0
    },
    '&:hover .carousel-image': {
      border: '0 solid #ffffff',
      boxShadow: '0 8px 24px 0 rgba(27, 28, 31, 0.05)'
    }
  }
});

const EditField = ({ value = '', onEdit, isLoading, style, className, fullWidth = true, ...props }) => {
  const classes = useStyles();

  const [isEditing, setIsEditing] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState(value);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleEdit = () => {
    setIsEditing(false);
    const trimmedValue = currentValue.trim();
    if (trimmedValue.length === 0 || value === trimmedValue) {
      setCurrentValue(value);
    } else {
      onEdit(trimmedValue);
      setCurrentValue(trimmedValue);
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      handleEdit();
    } else if (e.keyCode === 27) {
      setCurrentValue(value);
      setIsEditing(false);
    }
  };

  return (
    <Grid
      container
      className={classes.container}
      style={{ boxShadow: isEditing ? '0 0 1px 1px #D6D8DB' : undefined, ...style }}
    >
      <Grid item xs>
        <TextField
          type="text"
          disabled={!isEditing}
          value={currentValue}
          autoFocus
          onChange={e => setCurrentValue(e.target.value)}
          onBlur={handleEdit}
          onKeyDown={handleKeyDown}
          fullWidth={fullWidth}
          InputProps={{
            disableUnderline: true,
            inputProps: { maxLength: 100, ...props },
            classes: { input: className || classes.input },
            endAdornment: (
              <Grid item className={classes.editContainer}>
                {isLoading ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  <IconButton
                    className={clsx(classes.editBtn, { editBtn: true })}
                    onClick={() => setIsEditing(true)}
                    disableRipple
                    disableFocusRipple
                  >
                    <EditOutlined data-testid="edit-icon" className={classes.editIcon} />
                  </IconButton>
                )}
              </Grid>
            )
          }}
        />
      </Grid>
    </Grid>
  );
};

export default EditField;
