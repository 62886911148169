import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
  DialogActions
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { scrollBar } from './styles/commonStyles';

const useStyles = makeStyles(theme => ({
  title: {
    padding: 24,
    minHeight: 48,
    textAlign: 'center',
    '& > .MuiTypography-root': {
      fontSize: ({ disableTypography }) => (disableTypography ? null : 24),
      lineHeight: ({ disableTypography }) => (disableTypography ? null : '30px')
    }
  },
  subtitle: {
    lineHeight: '22px',
    marginTop: 10,
    color: '#8D9299',
    fontSize: 16
  },
  content: {
    ...scrollBar,
    paddingBottom: 24
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 15,
    [theme.breakpoints.down('xs')]: {
      right: 2,
      top: 17
    }
  }
}));

export const Modal = ({
  title,
  subtitle,
  actions,
  children,
  onClose,
  disableTypography,
  innerClasses,
  classes: overrideClasses,
  titleClass,
  subtitleClass,
  closeButtonClass,
  ...props
}) => {
  const classes = useStyles({ disableTypography, classes: overrideClasses });

  return (
    <Dialog onClose={onClose} maxWidth={false} {...props} classes={innerClasses}>
      <IconButton aria-label="close" className={closeButtonClass || classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className={titleClass || classes.title} disableTypography={disableTypography}>
        {title || ''}
        {subtitle && (
          <Typography align="center" className={subtitleClass || classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent className={props.typeform ? props.typeform : classes.content}>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
