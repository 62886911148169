import { makeStyles } from '@material-ui/core';

const GlobalStyles = () => {
  makeStyles({
    '@global': {
      body: {
        backgroundColor: 'white'
      }
    }
  })();

  return null;
};

export default GlobalStyles;
