import * as React from 'react';
import { Box, Grid, Snackbar, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckIcon from '@material-ui/icons/Check';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import { ActionCard, InfoCard, ShipmentsCard, ShipmentsCardV2 } from './Cards';
import OrderSummary from '../summary/OrderSummary';
import ReviewProducts from './ReviewProducts';
import { useOrder } from '../OrderContext';
import { changeColorLightness } from '../../../../shared/styles/utils';
import WarehouseOverview from './WarehouseOverview';
import SendToWarehouse from './SendToWarehouse';
import { Alert } from '../../../../shared';
import { opportunityStatus } from '../../../../../apis/constants';
import ArrowTooltip from '../common/Tooltip';
import gtm from '../../../../../utils/gtm';
import { cannotContinueMessage } from '../common/utilsOrder';

const commonLink = {
  alignItems: 'center',
  display: 'inline-flex',
  fontFamily: 'Gilroy-SemiBold',
  fontSize: 14,
  padding: '3px 8px',
  width: 'fit-content',
  letterSpacing: 'normal',
  height: 'auto'
};

const useStyles = makeStyles({
  continueButton: {
    height: 56,
    width: 236,
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#fafafa',
      color: '#787b80'
    }
  },
  goBack: {
    alignItems: 'center',
    color: '#787b80',
    display: 'inline-flex',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    marginLeft: 80,
    marginTop: 18,
    paddingTop: 3,
    paddingRight: 10,
    '&:hover': { color: changeColorLightness('#787b80') }
  },
  goBackIcon: {
    cursor: 'pointer',
    fontSize: 16,
    height: 16,
    marginRight: 10
  },
  shipmentLink: {
    ...commonLink,
    color: '#3577d4',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  warehouseLink: ({ showWarning }) => ({
    ...commonLink,
    color: showWarning ? '#fa902d' : '#3577d4',
    '&:hover': { color: changeColorLightness(showWarning ? '#fa902d' : '#3577d4') }
  }),
  linkIcon: {
    cursor: 'pointer',
    fontSize: 16,
    marginLeft: 6
  },
  title: {
    color: '#0b1829',
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '34px',
    margin: '0 0 4px 0'
  },
  cardsContainer: {
    position: 'sticky',
    height: '100%',
    top: 162
  },
  alert: {
    top: 104
  }
});

const RequestDetails = () => {
  const { enableUnallocatedProductsManagementV2 } = useFlags();

  const order = useOrder();
  const { productsWithInactiveSizes, warehouseProofsWithInactiveSizes } = order;
  const orderHasInactiveSizes = productsWithInactiveSizes.length > 0;

  const classes = useStyles({ showWarning: warehouseProofsWithInactiveSizes.size > 0 });

  const { id } = useParams();
  const location = useLocation();
  const [showUpdateAlert, setShowUpdateAlert] = React.useState(false);

  const noWarehouseShipment =
    order.warehouseProofs?.length === 0 || order.warehouseProofs.every(wp => wp.sizes.length === 0);
  const [isWarehouseOverviewOpen, toggleIsWarehouseOverviewOpen] = React.useReducer(
    prev => !prev,
    location.state?.showWarehouse
  );
  const [isSendToWarehouseOpen, toggleIsSendToWarehouseOpen] = React.useReducer(prev => !prev, false);
  const warehouseToggle = noWarehouseShipment ? toggleIsSendToWarehouseOpen : toggleIsWarehouseOverviewOpen;

  const history = useHistory();
  React.useEffect(() => {
    if (location.state?.showWarehouse) {
      if (!isWarehouseOverviewOpen) toggleIsSendToWarehouseOpen();
      history.replace({ ...location, state: { showWarehouse: false } });
    }
  }, [isWarehouseOverviewOpen, location, history, toggleIsSendToWarehouseOpen]);

  const handleCloseSendToWarehouse = ({ quantitiesChanged }) => {
    toggleIsSendToWarehouseOpen();
    if (quantitiesChanged) {
      setShowUpdateAlert(true);
    }
  };

  const orderHasProductsUnapproved = ![opportunityStatus.proofsApproved, opportunityStatus.proofsApprovedOld].includes(
    order.status
  );
  const orderInProductionRequest = [opportunityStatus.finalizingProofs, opportunityStatus.finalizingProofsOld].includes(
    order.status
  );

  const handleUpdateQuantities = () => {
    toggleIsSendToWarehouseOpen();
    toggleIsWarehouseOverviewOpen();
  };

  const fromProducts = location.state?.fromProducts;
  const [to, backToText] = fromProducts ? ['/products', 'Back to Products'] : ['/orders-requested', 'Back to Orders'];

  const ShipmentsCardComp = enableUnallocatedProductsManagementV2 ? ShipmentsCardV2 : ShipmentsCard;

  return (
    <Grid container>
      <Grid container direction="column" item xs style={{ minWidth: 0 }}>
        <Grid>
          <Link to={to} className={classes.goBack}>
            <ArrowBackIcon className={classes.goBackIcon} />
            {backToText}
          </Link>
        </Grid>
        <Box display="flex" width="100%" flexGrow="1" p="24px 80px" position="relative">
          <Grid item xs={6} style={{ paddingRight: 20 }}>
            <ReviewProducts fromProducts={fromProducts} />
          </Grid>
          <Grid container direction="column" item xs={6} className={classes.cardsContainer}>
            <h2 className={classes.title}>Shipping & Storage</h2>
            <ActionCard
              title="Ship Swag to Recipients"
              image={<img src="/images/shipments/truck@3x.png" alt="Swagup Truck" width={108} height={96} />}
            >
              <Link
                to={`/orders-requested/${id}/select-products`}
                onClick={() => gtm.onClick('Create Shipment')}
                className={classes.shipmentLink}
              >
                Create Shipment
                <AddIcon className={classes.linkIcon} />
              </Link>
            </ActionCard>
            <ShipmentsCardComp />
            <ActionCard
              title="SwagUp Storage"
              image={<img src="/images/shipments/warehouse@3x.png" alt="Swagup Storage" width={108} height={96} />}
            >
              <Button variant="text" className={classes.warehouseLink} onClick={warehouseToggle}>
                {noWarehouseShipment ? 'Send to Warehouse' : 'View details'}
                <ChevronRightIcon className={classes.linkIcon} />
              </Button>
              <SendToWarehouse
                open={isSendToWarehouseOpen}
                onClose={toggleIsSendToWarehouseOpen}
                onSendQtyToWarehouse={handleCloseSendToWarehouse}
              />
              <WarehouseOverview
                open={isWarehouseOverviewOpen}
                onClose={toggleIsWarehouseOverviewOpen}
                onUpdateQuantities={handleUpdateQuantities}
              />
            </ActionCard>
            <InfoCard />
          </Grid>
        </Box>
      </Grid>
      <Grid container item style={{ width: 300 }}>
        <OrderSummary>
          <ArrowTooltip
            variant="primary"
            title={cannotContinueMessage(orderHasProductsUnapproved, orderInProductionRequest, orderHasInactiveSizes)}
          >
            <Button
              variant="primary"
              component={Link}
              to={`/orders-requested/${id}/order-overview`}
              onClick={() => {
                gtm.onClickContinue('View order overview clicked');
              }}
              disabled={orderHasProductsUnapproved || orderHasInactiveSizes}
              className={classes.continueButton}
              withIcon
            >
              Continue
            </Button>
          </ArrowTooltip>
        </OrderSummary>
      </Grid>
      <Snackbar
        open={showUpdateAlert}
        classes={{ anchorOriginTopCenter: classes.alert }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowUpdateAlert(false)}
      >
        <Alert
          showIcon={<CheckIcon fontSize="small" style={{ alignSelf: 'center' }} />}
          width={270}
          style={{ paddingBottom: 20, justifyContent: 'center' }}
          onClose={() => setShowUpdateAlert(false)}
        >
          Update successful
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default RequestDetails;
