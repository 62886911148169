const styles = theme => ({
  container: {
    padding: '20px 0 100px 0'
  },
  textContainer: {
    paddingTop: '150px !important',
    paddingRight: '60px !important',
    maxWidth: 520,
    [theme.breakpoints.down('xs')]: { paddingTop: '0 !important' }
  },
  miniTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#9846dd',
    textTransform: 'uppercase',
    marginTop: 100,
    '&:after': {
      display: 'inline-block',
      content: "''",
      borderTop: '2px solid #9846dd',
      width: '4rem',
      margin: '0 30px',
      transform: 'translateY(-0.3rem)',
      [theme.breakpoints.down('sm')]: { margin: '0 10px' },
      [theme.breakpoints.down('xs')]: { margin: '0 10px' }
    }
  },
  title: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '3.5rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#0f2440',
    maxWidth: 430,
    marginTop: 28
  },
  text: {
    opacity: '0.54',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '20px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.8,
    letterSpacing: 'normal',
    color: '#0f2440',
    maxWidth: 490
  },
  imgContainer: {
    width: '88%',
    height: '75%',
    position: 'absolute',
    top: '24%',
    left: '8%',
    zIndex: -999,
    borderRadius: '75px 5px 75px 5px',
    boxShadow: '0 30px 100px 0 rgba(212, 217, 226, 0.5)',
    [theme.breakpoints.down('xs')]: { height: '80%', width: '95%', left: '2%' }
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    marginTop: -80
  },
  button: {
    borderRadius: '32px',
    backgroundColor: '#3577d4',
    padding: '26px 84px',
    border: 'none',
    cursor: 'pointer',
    margin: '45px 0 60px 0',
    [theme.breakpoints.down('xs')]: { padding: '23px 60px' }
  },
  buttonText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.75,
    letterSpacing: 'normal',
    color: '#ffffff',
    textTransform: 'none'
  }
});

export default styles;
