const styles = {
  title: {
    padding: '40px 120px 0',
    '& > .MuiTypography-root': {
      fontFamily: 'Gilroy-Bold'
    }
  },
  subtitle: {
    lineHeight: '22px'
  },
  paper: {
    maxWidth: 730,
    width: 730
  },
  infoItemContainer: {
    height: 40
  },
  infoItemText: {
    color: '#0b1829'
  },
  infoItemAmount: {
    color: '#0b1829',
    marginLeft: 'auto',
    lineHeight: '32px'
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5
  },
  buttonsContainer: {
    marginBottom: 10
  },
  cancelButton: {
    color: '#787b80',
    height: 56,
    marginRight: 30
  },
  continueButton: {
    height: 56
  }
};

export default styles;
