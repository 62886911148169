import React, { useState, useRef } from 'react';
import { Grid, Table, TableRow, TableCell, TableBody, InputBase, Button, Typography } from '@material-ui/core';
import round from 'lodash/round';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { StylessButton } from '../buttons';
import ProductionTimeCustomRadio from '../global/productionTimeCustomRadio';
// TODO: ProofSubItem refactor pending
import ProofSubItem from './proofSubItem';
import Tooltip from '../shared/Tooltip';
import { ToolTipIcon, CollapseExpandIcon } from '../icons';
import {
  moneyStr,
  sumByQuantity,
  isOneSize,
  isPack,
  getProductPrice,
  onlyNaturalNumberOrEmpty
} from '../../helpers/utils';
import getSizesTotalQtyErrorMsg from './getSizesTotalQtyErrorMsg';
import { ImgWithHandler } from '../global/ImgUtils';
import { productionTime as productionSpeed } from '../../apis/constants';
import ArrowTooltip from '../pages/orders/requested/common/Tooltip';
import AppliedMembershipPanel from '../global/AppliedMembershipPanel';
import useDiscountsAndRewards from '../../hooks/useDiscountsAndRewards';
import StrikeOutText from '../global/StrikeOutCost';

const presetQuantities = [25, 50, 75, 100, 125, 150, 175, 200, 300];

const ElementOneSize = ({ element, onChangeSize, onChangeSizeInput, classes }) => {
  const elemSize = element.sizes[0];
  const [qty, setQty] = useState(elemSize.quantity);
  const inputRef = useRef(null);

  const handleQtyClick = value => {
    setQty(value);
    inputRef.current.value = '';
    onChangeSize(value, element.sizes[0], element);
  };

  const handleChangeSizeInput = (e, size) => {
    onChangeSizeInput(e, size);
    setQty(parseInt(e.target.value, 10) || 0);
  };

  return (
    <>
      {presetQuantities.map(value => (
        <Grid key={value} item xs={3} align="left" style={{ marginTop: 20 }}>
          <Button
            disabled={element.product.minimum_to_reorder > value}
            className={clsx({ [classes.qtyButtonSelected]: qty === value }, classes.qtyButton)}
            variant="outlined"
            onClick={() => handleQtyClick(value)}
          >
            {value}
          </Button>
        </Grid>
      ))}
      <Grid item xs={6} align="center" style={{ marginTop: 20 }}>
        <InputBase
          defaultValue={(!presetQuantities.includes(elemSize.quantity) && elemSize.quantity) || ''}
          className={clsx(classes.textFieldQty, { placeholder: qty === 0 })}
          style={{ minWidth: 190 }}
          inputProps={{ style: { textAlign: 'left', paddingLeft: 15 } }}
          onChange={e => handleChangeSizeInput(e, elemSize)}
          placeholder="Custom quantity"
          inputRef={inputRef}
        />
      </Grid>
    </>
  );
};

const ElementMultipleSizes = ({ element, onChangeSizeInput, classes }) => {
  const { sizes } = element;
  const inLast4Sizes = index =>
    sizes
      .slice(-4)
      .map(s => sizes.indexOf(s))
      .includes(index);

  return element.sizes.map((size, index) => {
    const isInactive = !size.active;
    const warningMsg = isInactive && 'This size is Inactive.';
    return (
      <Grid item key={size.size.id} xs={3}>
        <ArrowTooltip variant="alert" title={warningMsg}>
          <Grid item className={classes.sizeInputContainer} style={{ position: 'relative' }}>
            <p className={classes.SizeName}>
              {size.size.name}
              {isInactive && <InfoOutlinedIcon viewBox="-3 -9 25 31" className={classes.warningIcon} />}
            </p>

            <InputBase
              defaultValue={size.quantity || ''}
              className={clsx(classes.textFieldQty, { [classes.mb0]: inLast4Sizes(index) })}
              onChange={e => onChangeSizeInput(e, size)}
              disabled={isInactive}
            />
          </Grid>
        </ArrowTooltip>
      </Grid>
    );
  });
};

const ElementSizesEditor = ({ element, onChangeSize, classes }) => {
  const handleChangeSizeInput = (e, size) => {
    e.target.value = onlyNaturalNumberOrEmpty(e.target.value);
    const qty = parseInt(e.target.value, 10) || 0;
    onChangeSize(qty, size, element);
  };

  return isOneSize(element.sizes) ? (
    <ElementOneSize
      element={element}
      onChangeSize={onChangeSize}
      onChangeSizeInput={handleChangeSizeInput}
      classes={classes}
    />
  ) : (
    <ElementMultipleSizes element={element} onChangeSizeInput={handleChangeSizeInput} classes={classes} />
  );
};

const ProductImage = ({ product, classes }) => (
  <div className={classes.imgContainer}>
    <ImgWithHandler src={product.image} alt={product.name} width={256} height={256} />
  </div>
);

const CollapseExpand = ({ element, onToggleDetails, classes }) => (
  <Grid container alignItems="center" style={{ cursor: 'pointer' }} onClick={() => onToggleDetails(element.id)}>
    <p className={classes.restock}>{element.showDetails ? 'Collapse' : 'Expand'}</p>
    <CollapseExpandIcon collapsed={element.showDetails} />
  </Grid>
);

const ReorderImageSection = ({ element, onRemoveElement, onToggleDetails, classes }) => (
  <Grid container alignItems="center" item style={{ width: 256 }}>
    <Grid item xs={12} style={{ paddingBottom: 38 }}>
      <ProductImage product={element.product} classes={classes} />
    </Grid>
    <Grid item xs={6}>
      {onToggleDetails && <CollapseExpand element={element} onToggleDetails={onToggleDetails} classes={classes} />}
    </Grid>
    <Grid item xs={6} container justifyContent="flex-end">
      <StylessButton onClick={() => onRemoveElement(element.id)} textAlign="right">
        <p className={classes.remove}>Remove</p>
      </StylessButton>
    </Grid>
  </Grid>
);

const ReorderSizesSection = ({ element, onChangeSize, classes }) => (
  <Grid item className={classes.reorderSizesSectionContainer}>
    <Grid container direction="column">
      <p className={classes.packTittleNew}>{element.product.name}</p>
      <div>
        <p className={classes.packNormalTextNew} style={{ marginBottom: isOneSize(element.sizes) ? 25 : 55 }}>
          <span style={{ color: '#8d9299' }}>Color:</span>
          {` ${element.product.theme_color || ' Custom'}`}
        </p>
        <p className={classes.packNormalTextNew}>
          {isOneSize(element.sizes) ? 'Select quantity' : 'Enter quantity by sizes'}
        </p>
        <Grid container style={{ paddingTop: 16, maxWidth: 300 }}>
          <ElementSizesEditor element={element} onChangeSize={onChangeSize} classes={classes} />
        </Grid>
      </div>
    </Grid>
  </Grid>
);

const ReorderImageSizesSection = ({ element, onChangeSize, onRemoveElement, onToggleDetails, classes }) => (
  <Grid container item xs={7} className={classes.reorderImageSizesSection}>
    <ReorderImageSection
      element={element}
      onRemoveElement={onRemoveElement}
      onToggleDetails={onToggleDetails}
      classes={classes}
    />
    <ReorderSizesSection element={element} onChangeSize={onChangeSize} classes={classes} />
  </Grid>
);

const ProductionTimeSelection = ({ element, onSetProductionTime, classes: { packNormalText, productionTime } }) => (
  <TableRow>
    <TableCell colSpan={2} style={{ border: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
      <Grid container alignItems="flex-end" style={{ paddingBottom: 24 }}>
        <Grid item>
          <p className={clsx(packNormalText, productionTime)}>Estimated production time</p>
        </Grid>
        <Grid item style={{ paddingLeft: 4 }}>
          <Tooltip title="Production times are estimates and not guarantees" width={225} fontSize={12}>
            <Grid item style={{ cursor: 'pointer' }}>
              <ToolTipIcon />
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid style={{ marginBottom: 8 }}>
        <ProductionTimeCustomRadio element={element} onSetProductionTime={onSetProductionTime} />
      </Grid>
    </TableCell>
  </TableRow>
);

const PriceAndTotalCell = ({ text, className, padding, align, totalBeforeDiscount }) => (
  <TableCell style={{ border: 0, padding }} align={align}>
    <p className={className}>
      <StrikeOutText value={totalBeforeDiscount} fontSize={12} marginRight={8} />
      {text}
    </p>
  </TableCell>
);

const PriceAndTotalRow = ({ label, value, classes, paddingLabel, paddingValue, valueClass, discount }) => (
  <TableRow>
    <PriceAndTotalCell text={label} className={classes.packNormalText} padding={paddingLabel} />
    <PriceAndTotalCell
      text={value}
      className={clsx(classes.packBoldText, valueClass)}
      padding={paddingValue || paddingLabel}
      align="right"
      totalBeforeDiscount={discount}
    />
  </TableRow>
);

const PriceAndTotal = ({ element, classes }) => {
  const isRush = element.production_time === productionSpeed.rush;
  const productPrice = getProductPrice(element);
  const productPriceBeforeDiscount = getProductPrice(element, 'price_wihtout_discount');
  const productRushFee = isRush ? getProductPrice(element, 'rush_fee') : 0;
  const productRushFeeBeforeDiscount = isRush ? getProductPrice(element, 'rush_fee_without_discount') : 0;
  const productPriceOutput = `${moneyStr(productPrice)}`;
  const productRushFeeOutput = `${moneyStr(productRushFee)}`;
  const sizesTotalQuantity = sumByQuantity(element.sizes);
  const totalPrice = round(productPrice + productRushFee, 2);
  const totalPriceOutput = `${moneyStr(round(totalPrice * sizesTotalQuantity, 2))}`;
  const [totalSuffix, priceSuffix] = isPack(element.product.record_type) ? ['packs', 'pack'] : ['items', 'item'];

  const multipleTotals = React.useMemo(
    () => ({
      product_discount: productPrice || productPriceBeforeDiscount,
      rush_production_discount: productRushFee || productRushFeeBeforeDiscount
    }),
    [productPrice, productPriceBeforeDiscount, productRushFee, productRushFeeBeforeDiscount]
  );
  const multipleTotalsALL = React.useMemo(
    () => ({
      product_discount: round((productPrice || productPriceBeforeDiscount) * sizesTotalQuantity, 2),
      rush_production_discount: round((productRushFee || productRushFeeBeforeDiscount) * sizesTotalQuantity, 2)
    }),
    [productPrice, productPriceBeforeDiscount, productRushFee, productRushFeeBeforeDiscount, sizesTotalQuantity]
  );
  const { multipleDiscounts } = useDiscountsAndRewards(0, '', multipleTotals);
  const { totalBeforeDiscount: totalBeforeDiscountAll } = useDiscountsAndRewards(0, '', multipleTotalsALL);

  const rows = [
    { key: 1, label: `Total ${totalSuffix}:`, value: sizesTotalQuantity, paddingLabel: '8px 0 14px' },
    {
      key: 2,
      label: `Price per ${priceSuffix}:`,
      value: productPriceOutput,
      paddingLabel: 0,
      discount: multipleDiscounts.product_discount
    },
    {
      key: 3,
      label: `Rush production per ${priceSuffix}:`,
      value: productRushFeeOutput,
      paddingLabel: '16px 0 24px',
      discount: multipleDiscounts.rush_production_discount
    },
    {
      key: 4,
      label: 'Total:',
      value: totalPriceOutput,
      paddingLabel: '30px 0 0',
      valueClass: 'packBoldBiggerText',
      discount: totalBeforeDiscountAll
    }
  ];
  return rows.map(row =>
    row.key === 3 ? (
      <>
        {!isRush ? (
          <TableRow>
            <TableCell colSpan={2} style={{ border: 0, padding: 0 }}>
              <AppliedMembershipPanel
                total={totalPrice}
                multipleTotals={multipleTotalsALL}
                type="product"
                fullWidth
                style={{ marginTop: 12 }}
              />
            </TableCell>
          </TableRow>
        ) : (
          <>
            <PriceAndTotalRow
              key={row.key}
              label={row.label}
              value={row.value}
              classes={classes}
              paddingLabel={row.paddingLabel}
              paddingValue={row.paddingValue}
              valueClass={row.valueClass}
              discount={row.discount}
            />
            <TableRow>
              <TableCell colSpan={2} style={{ border: 0, padding: 0 }}>
                <AppliedMembershipPanel
                  total={totalPrice}
                  multipleTotals={multipleTotalsALL}
                  type="product"
                  fullWidth
                  style={{ marginTop: 0 }}
                />
              </TableCell>
            </TableRow>
          </>
        )}
      </>
    ) : (
      <PriceAndTotalRow
        key={row.key}
        label={row.label}
        value={row.value}
        classes={classes}
        paddingLabel={row.paddingLabel}
        paddingValue={row.paddingValue}
        valueClass={row.valueClass}
        discount={row.discount}
      />
    )
  );
};

const ReorderPriceSection = ({ element, onSetProductionTime, classes }) => (
  <Grid item xs={5} className={classes.reorderPriceSection}>
    <Table>
      <TableBody>
        <ProductionTimeSelection element={element} onSetProductionTime={onSetProductionTime} classes={classes} />
        <PriceAndTotal element={element} classes={classes} />
      </TableBody>
    </Table>
    {getSizesTotalQtyErrorMsg(element) && (
      <Grid container className={classes.qtyErrorContainer}>
        <Typography variant="body1" className={classes.qtyError}>
          {getSizesTotalQtyErrorMsg(element)}
        </Typography>
      </Grid>
    )}
  </Grid>
);

const ReorderDetailsSection = ({ element, doRemoveBagSubItem }) =>
  element.showDetails ? (
    <Grid container style={{ padding: '10px 43px 49px 43px', borderTop: '1px solid #ebeef2' }}>
      <Grid container>
        {element.product.items.map(item => (
          <ProofSubItem
            key={item.product.id}
            item={item}
            packColor={element.product.color}
            packKind={element.product.pack_kind}
            removeBagSubItem={doRemoveBagSubItem}
            idProof={element.id}
          />
        ))}
      </Grid>
    </Grid>
  ) : null;

export { ReorderImageSizesSection, ReorderPriceSection, ReorderDetailsSection };
