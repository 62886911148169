import commonStyles, { commonText } from './commonStyles';
import { ellipsisStyles } from '../../../../../../shared/styles/commonStyles';

const productQty = {
  fontFamily: 'Inter',
  fontSize: 16,
  color: '#4A505C'
};

export default {
  ...commonStyles,
  productImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 10,
    border: 'solid 1px #ebeef2'
  },
  productImgSmall: {
    borderRadius: 5,
    border: 'solid 1px #ebeef2'
  },
  productQty: {
    ...productQty,
    fontWeight: 600,
    marginBottom: 10,
    textAlign: 'right'
  },
  productQtySmall: {
    ...productQty,
    fontSize: 14,
    textAlign: 'center'
  },
  moreProductsButton: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderRadius: 5,
    border: 'solid 1px #ebeef2',
    backgroundColor: 'rgba(235, 241, 251, 0.5)',
    '&:hover': { backgroundColor: 'rgba(235, 241, 251, 0.7)' }
  },
  productName: {
    ...ellipsisStyles,
    fontFamily: 'Inter',
    fontSize: 16,
    color: '#0f2440',
    marginBottom: 10,
    lineHeight: 'normal'
  },
  productCommon: {
    ...commonText,
    marginBottom: 10,
    lineHeight: 'normal'
  },
  productTextDark: { color: '#0f2440' },
  shippingItem: {
    alignItems: 'stretch',
    marginTop: 0,
    '&~&': { marginTop: 14 }
  },
  employeeName: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 16,
    color: '#0B1829',
    marginBottom: 2
  },
  employeeAddress: {
    ...commonText,
    color: '#434c5f',
    fontSize: 14,
    whiteSpace: 'pre-wrap'
  },
  squareButton: {
    width: 32,
    minWidth: 32,
    height: 32,
    borderRadius: 10,
    padding: 0,
    backgroundColor: '#ffffff',
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)' }
  },
  expandedButton: { border: 'solid 1px #8d9299' },
  collapsedButton: { border: 'solid 1px #ebeef2' },
  expandCollapseButtonIcon: {
    color: '#434d5c',
    fontSize: 14
  }
};
