import { CLOSE_MODAL, OPEN_MODAL, SET_TAB_VALUE, SET_BUTTON_TEXTS } from '../actions/types';

const initialState = {
  openModal: false,
  tabValue: 'native', // default Tab
  buttonTexts: {
    native: 'Request a New Integration',
    zapier: 'Create an Automation'
  }
};

const IntegrationNavBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        openModal: true
      };
    case CLOSE_MODAL:
      return {
        ...state,
        openModal: false
      };
    case SET_TAB_VALUE:
      return {
        ...state,
        tabValue: action.payload
      };
    case SET_BUTTON_TEXTS:
      return {
        ...state,
        buttonTexts: {
          native: action.payload.nativeText,
          zapier: action.payload.zapierText
        }
      };
    default:
      return state;
  }
};

export default IntegrationNavBarReducer;
