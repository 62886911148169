import React from 'react';

export const ListViewIcon = () => (
  <svg width="18" height="18" viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4H14V6H8V4ZM8 8H14V10H8V8ZM8 12H14V14H8V12ZM4 4H6V6H4V4ZM4 8H6V10H4V8ZM4 12H6V14H4V12ZM17.1 0H0.9C0.4 0 0 0.4 0 0.9V17.1C0 17.5 0.4 18 0.9 18H17.1C17.5 18 18 17.5 18 17.1V0.9C18 0.4 17.5 0 17.1 0ZM16 16H2V2H16V16Z"
      fill="currentColor"
    />
  </svg>
);

export const CardViewIcon = () => (
  <svg width="18" height="18" viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0V8H8V0H0ZM6 6H2V2H6V6ZM0 10V18H8V10H0ZM6 16H2V12H6V16ZM10 0V8H18V0H10ZM16 6H12V2H16V6ZM10 10V18H18V10H10ZM16 16H12V12H16V16Z"
      fill="currentColor"
    />
  </svg>
);

export const CheckedIcon = () => (
  <svg width="34" height="34" viewBox="0 0 34 34">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17ZM10.7536 16.1693L14.5983 20.0399L23.2556 11.3333L24.5556 12.6421L14.5983 22.6667L9.44444 17.478L10.7536 16.1693Z"
      fill="#3577D4"
    />
  </svg>
);

export const UncheckedIcon = () => (
  <svg width="34" height="34" viewBox="0 0 34 34">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM17 32.1111C8.65436 32.1111 1.88889 25.3456 1.88889 17C1.88889 8.65436 8.65436 1.88889 17 1.88889C25.3456 1.88889 32.1111 8.65436 32.1111 17C32.1111 25.3456 25.3456 32.1111 17 32.1111ZM10.7536 16.1693L14.5983 20.0399L23.2556 11.3333L24.5556 12.6421L14.5983 22.6667L9.44444 17.478L10.7536 16.1693Z"
      fill="#8D9299"
    />
  </svg>
);

export const ArrowIcon = ({ open }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8">
    <path
      fill="#3577D4"
      fillRule="nonzero"
      d={
        open
          ? 'M10.161 6.757L5.995 2.591 1.828 6.757A1.07 1.07 0 1 1 .314 5.243L5.243.314a1.07 1.07 0 0 1 1.514 0l4.929 4.929a1.07 1.07 0 0 1 0 1.514 1.092 1.092 0 0 1-1.525 0z'
          : 'M10.161.314L5.995 4.481 1.828.314A1.07 1.07 0 1 0 .314 1.828l4.929 4.929a1.07 1.07 0 0 0 1.514 0l4.929-4.929a1.07 1.07 0 0 0 0-1.514 1.092 1.092 0 0 0-1.525 0z'
      }
    />
  </svg>
);

export const MenuIcon = () => (
  <svg className="svg-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.333 1.33337H2.66634C1.99967 1.33337 1.33301 1.93337 1.33301 2.66671V4.67337C1.33301 5.15337 1.61967 5.56671 1.99967 5.80004V13.3334C1.99967 14.0667 2.73301 14.6667 3.33301 14.6667H12.6663C13.2663 14.6667 13.9997 14.0667 13.9997 13.3334V5.80004C14.3797 5.56671 14.6663 5.15337 14.6663 4.67337V2.66671C14.6663 1.93337 13.9997 1.33337 13.333 1.33337ZM12.6663 13.3334H3.33301V6.00004H12.6663V13.3334ZM13.333 4.66671H2.66634V2.66671H13.333V4.66671Z"
      fill="black"
    />
    <path d="M9.99967 8.00004H5.99967V9.33337H9.99967V8.00004Z" fill="black" />
  </svg>
);
