import { ellipsisStyles, scrollBar } from '../../../shared/styles/commonStyles';

const styles = theme => ({
  container: {
    marginTop: 0,
    paddingTop: 40,
    paddingBottom: 42,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  lightText: {
    color: '#989EA4',
    maxWidth: 216,
    ...ellipsisStyles
  },
  lightErrorText: {
    paddingLeft: 18,
    color: '#989EA4',
    maxWidth: 132,
    ...ellipsisStyles
  },
  cellText: {
    color: '#4A4F54',
    maxWidth: 216,
    ...ellipsisStyles
  },
  moreOptions: {
    width: '100%'
  },
  redemptionMenu: { borderRadius: 6 },
  cellCentered: {
    '& .divContent': {
      width: '100%',
      textAlign: 'center',
      color: '#989EA4'
    }
  },
  tableContainer: {
    borderTop: 'solid 1px #f0f2f5',
    borderBottom: 'solid 1px #f0f2f5',
    backgroundColor: '#ffffff',
    overflowY: 'auto',
    overflow: 'hidden',
    flex: 1,
    minHeight: 44,
    ...scrollBar
  },
  tableWrapper: { position: 'relative', height: 'calc(100vh - 318px)' },
  tableWrapperSmall: { position: 'relative', height: 'calc(100vh - 348px)' },
  redemptionTable: {
    '& .MuiTableCell-root': {
      borderRight: '0px !important',
      '& .divContent': theme.typography.body4RegularInter
    },
    '& th.MuiTableCell-root .divContent': {
      color: '#989EA4'
    }
  },
  historyStatusContainer: {
    paddingTop: 12,
    paddingBottom: 12
  },
  isSearching: {
    marginTop: 4,
    position: 'absolute',
    textAlign: 'center',
    color: '#787B80'
  },
  inputSection: {
    paddingBottom: 20
  },
  inputSectionHeader: {
    paddingBottom: 4,
    paddingTop: 12,
    paddingRight: 24
  },
  separateProofButtonsContainer: {
    marginTop: 16,
    paddingTop: 10,
    borderTop: '1px solid  #E5E7E8'
  },
  historyStatus: {
    color: '#4A4F54'
  },
  snackbarContainer: {
    marginTop: 112,
    zIndex: 99
  },
  slimCell: {
    '& .divCell': {
      padding: '0px 6px'
    }
  },
  shippingStatusText: { color: '#4A4F54', ...ellipsisStyles },
  shippingSuccess: { height: 10, width: 10, borderRadius: '50%', background: '#4CAF50', marginRight: 8 },
  shippingWarning: { height: 10, width: 10, borderRadius: '50%', background: '#FF9800', marginRight: 8 },
  shippingError: { height: 10, width: 10, borderRadius: '50%', background: '#F44336', marginRight: 8 },
  shippingBall: { height: 10, width: 10, borderRadius: '50%', marginRight: 8 },
  shippingStatusContainer: { textAlign: 'left', maxWidth: 256, minWidth: 118 },
  shippingStatusDescription: { ...ellipsisStyles }
});

export default styles;
