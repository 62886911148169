import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import styles from './styles/DeliveryInfo';

const DeliveryInfo = ({ classes, deliveryMethod, shippingNote }) => (
  <Grid container>
    <Grid item xs={12}>
      <p className={classes.title}>DELIVERY METHOD</p>
      <p className={classes.deliveryText}>{`${deliveryMethod} delivery`}</p>
      {shippingNote && (
        <>
          <p style={{ paddingTop: 15 }} className={classes.title}>
            Shipping Notes
          </p>
          <p className={classes.deliveryText}>{shippingNote}</p>
        </>
      )}
    </Grid>
  </Grid>
);

export default withStyles(styles)(DeliveryInfo);
