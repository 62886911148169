const hintDialogListText = {
  fontSize: 14,
  lineHeight: 1.57,
  marginBottom: 8
};

export default theme => ({
  root: {
    maxWidth: '100%'
  },
  dateLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px'
  },
  fieldTitle: {
    color: '#0B1829',
    fontSize: 16,
    fontFamily: 'Gilroy-Medium',
    fontWeight: 400,
    marginBottom: theme.spacing(1.5),
    '& > small': {
      marginLeft: theme.spacing(0.75)
    }
  },
  formLabel: {
    marginBottom: theme.spacing(4),
    marginLeft: 4
  },
  formInput: {
    '& > div > fieldset, & > div:nth-child(2) > div > fieldset': {
      borderRadius: '10px'
    }
  },
  switchInputOff: {
    '& > span': {
      color: '#989EA4 !important'
    },
    '& > .MuiSwitch-track': {
      backgroundColor: '#989EA4 !important'
    }
  },
  switchInputOn: {
    '& > span': {
      color: '#125CFF !important'
    },
    '& > .MuiSwitch-track': {
      backgroundColor: '#125CFF !important'
    }
  },
  actions: {
    marginBottom: theme.spacing(6)
  },
  notice: {
    marginTop: theme.spacing(3),
    fontSize: 14,
    color: '#787B80'
  },
  logoHintContainer: {
    backgroundColor: '#f4f8ff',
    padding: '16px 33px 16px 23px',
    marginBottom: 16,
    borderRadius: 15
  },
  logoHintTextContainer: {
    flex: 1,
    marginLeft: 16
  },
  logoHintTextTitle: {
    color: '#0b1829',
    lineHeight: 1.5,
    marginBottom: 2,
    fontFamily: 'Gilroy-SemiBold'
  },
  logoHintTextHint: {
    color: '#787b80',
    fontSize: 12,
    lineHeight: 1.5
  },
  logoHintButton: {
    fontWeight: 400,
    fontSize: 15,
    height: 'unset'
  },
  hintDialogContainer: {
    padding: 48,
    minWidth: 808
  },
  hintDialogHeader: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.4,
    textTransform: 'uppercase',
    marginBottom: 16
  },
  hintDialogListTitle: {
    ...hintDialogListText,
    color: '#787b80'
  },
  hintDialogList: {
    margin: 0,
    paddingInlineStart: 16
  },
  hintDialogListItem: {
    ...hintDialogListText,
    color: '#0b1829'
  },
  hintDialogSeparator: {
    width: 24,
    borderTop: '1px solid #ced1d6',
    margin: '16px 0'
  },
  hintDialogCloseBtn: {
    fontSize: 14,
    borderWidth: 1,
    height: 48,
    padding: '14px 24px',
    marginTop: 24
  },
  hintDialogImgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  hintDialogImg: {
    display: 'block',
    '&:not(:last-child)': {
      marginBottom: 16
    }
  }
});

export const accordionStyles = {
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&$expanded': {
      minHeight: 56,
      margin: 0
    },
    '&:not(:first-child)': {
      borderTop: '2px solid #CED1D6'
    },
    '&:not(:last-child)': {
      borderBottom: 0
    }
  },
  expanded: {
    marginBottom: 40
  }
};

export const accordionSummaryStyles = theme => ({
  root: {
    padding: 0
  },
  content: {
    marginTop: theme.spacing(4.5),
    marginBottom: theme.spacing(4.5)
  }
});

export const accordionDetailsStyles = {
  root: {
    padding: 0
  }
};

export const datePickerStyles = theme => ({
  root: {
    '& > .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white
    }
  }
});
