const styles = {
  paymentText: {
    margin: '30px 0 20px',
    fontSize: 14,
    color: '#0b1829'
  },
  creditTitle: {
    fontSize: 14,
    color: '#0b1829',
    fontWeight: 600,
    lineHeight: '10px'
  },
  creditSubtitle: {
    fontSize: 12,
    lineHeight: '20px'
  },
  totalPayable: {
    color: '#0B1829',
    fontSize: 14,
    fontWeight: 700
  },
  availableCredit: {
    color: '#45AF5F',
    fontSize: 14,
    fontWeight: 700
  },
  payRemainingContainer: {
    marginTop: 20,
    matginBottom: 10
  },
  payRemaining: {
    fontSize: 14,
    fontWeight: 400,
    color: '#0B1829'
  },
  blueText: {
    color: '#3577D4'
  }
};

export default styles;
