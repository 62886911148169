/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Button } from '@swagup-com/components';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getFormattedPhoneNumber } from '../../../helpers/utils';
import verifyAddress from '../../../helpers/addressValidation';
import toErrorPage from '../../../helpers/toErrorPage';
import ImageUpload from '../../global/ImageUpload';
import EmployeeForm from './EmployeeForm';
import AddressConfirmation from '../../shared/AddressConfirmation';
import { Helmet } from '../../shared';
import Loader from '../../global/Loader';
import log from '../../../logger';
import { getSchema, handleContactError } from '../../global/Forms/commonValidations';

const getEmployeeData = employee => ({
  id: employee?.id,
  first_name: employee?.first_name,
  last_name: employee?.last_name,
  title: employee?.title,
  company: employee?.company_name,
  phone_number: getFormattedPhoneNumber(employee?.phone_number, employee?.shipping_country),
  photo_path: employee?.photo,
  size: employee?.size?.id ?? '',
  email: employee?.email ?? '',
  shipping_address1: employee?.shipping_address1,
  shipping_address2: employee?.shipping_address2,
  shipping_city: employee?.shipping_city,
  shipping_zip: employee?.shipping_zip,
  shipping_state: employee?.shipping_state || '',
  shipping_country: employee?.shipping_country || 'US'
});

const EmployeePhoto = ({ employee, onUploadProgress, control, onChange }) => {
  log.debug('EmployeePhoto - employee from props:', employee);

  const file = (employee && employee.photo) || '/images/profile/camera.svg';
  log.debug('EmployeePhoto - file:', file);

  return (
    <Controller
      name="photo_path"
      control={control}
      defaultValue=""
      render={() => (
        <ImageUpload label="Profile Image" fileBefore={file} onProgress={onUploadProgress} onChange={onChange} />
      )}
    />
  );
};

const CancelButton = ({ text, to }) => {
  const { leftBarNavigation } = useFlags();
  return (
    <Button
      component={Link}
      to={to || (leftBarNavigation ? '/contacts' : '/shipments/contacts')}
      style={{ border: 'none', backgroundColor: 'transparent' }}
    >
      <Typography variant="button">{text}</Typography>
    </Button>
  );
};

const SaveButton = ({ disabled }) => (
  <Button variant="primary" type="submit" disabled={disabled} fullWidth>
    Save
  </Button>
);

const fieldsToValidate = [
  'shipping_country',
  'first_name',
  'last_name',
  'email',
  'shipping_address1',
  'shipping_address2',
  'shipping_city',
  'phone_number',
  'shipping_state',
  'shipping_zip'
];

const resolver = yupResolver(getSchema(fieldsToValidate));

const EmployeeFormContainer = ({ tag, topRow, saveEmployee, employee, cancelText, classes }) => {
  const [addressVerification, setAddressVerification] = useState({ address: {} });
  const [employeePhotoPath, setEmployeePhotoPath] = useState(undefined);
  const [isAddressConfirmationOpen, setIsAddressConfirmationOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { leftBarNavigation } = useFlags();

  const history = useHistory();
  const from = useLocation().state?.from;
  const formMethods = useForm({
    resolver,
    mode: 'all',
    defaultValues: getEmployeeData(employee)
  });
  const { control, formState, handleSubmit, setError, reset } = formMethods;
  const isValid = isEmpty(formState.errors);
  const disableButton = isUploading || !isValid || !formState.isDirty;

  useEffect(() => {
    reset(getEmployeeData(employee));
  }, [reset, employee]);

  const postEmployee = async data => {
    setIsLoading(true);

    const response = await saveEmployee(data);
    if (response.result === 'error') {
      setIsLoading(false);
      handleContactError({
        data: response.data,
        setError,
        errorBehavior: () => toErrorPage(response, history),
        state: data.shipping_state
      });
    } else history.push(from || (leftBarNavigation ? '/contacts' : '/shipments/contacts'));
  };

  const onSave = async data => {
    setIsLoading(true);

    const employeeData = {
      ...data,
      id: employee?.id,
      company_name: data.company,
      size: data.size,
      photo_path: employeePhotoPath,
      force_address: true
    };

    const address = {
      ...employeeData,
      street: data.shipping_address1,
      secondary: data.shipping_address2,
      city: data.shipping_city,
      state: data.shipping_state,
      zipcode: data.shipping_zip,
      country: data.shipping_country
    };

    const { result, message } = await verifyAddress(address);
    setIsLoading(false);

    if (result === 'ok') {
      await postEmployee(employeeData);
    } else {
      setAddressVerification({ address, message });
      setIsAddressConfirmationOpen(true);
    }
  };

  const handleProgress = incomplete => setIsUploading(incomplete);

  const handleImageUpload = file => setEmployeePhotoPath(file);

  log.debug('EmployeeFormContainer condition for SaveButton, isUploading:', isUploading, 'disabled:', disableButton);

  return (
    <form onSubmit={handleSubmit(onSave)}>
      {isLoading && <Loader />}
      <Helmet tags={tag} />
      {topRow}
      <Grid container className={classes.formContainer}>
        <Grid item md={3}>
          <EmployeePhoto
            control={control}
            employee={employee}
            onChange={handleImageUpload}
            onUploadProgress={handleProgress}
          />
        </Grid>
        <Grid container item md={9} className={classes.formFieldsContainer}>
          <FormProvider {...formMethods}>
            <EmployeeForm />
          </FormProvider>
          <Grid container className={classes.formActionsContainer}>
            <Grid item style={{ paddingRight: 60 }} container justifyContent="flex-end" xs={6}>
              <CancelButton text={cancelText || 'Cancel'} to={from} />
            </Grid>
            <Grid container justifyContent="flex-end" item xs={6}>
              <Grid item xs={11}>
                <SaveButton disabled={disableButton} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddressConfirmation
        open={isAddressConfirmationOpen}
        onClose={() => setIsAddressConfirmationOpen(false)}
        address={addressVerification.address}
        message={addressVerification.message}
        callbackAction={postEmployee}
      />
    </form>
  );
};

export default EmployeeFormContainer;
