import { combineReducers } from 'redux';

import { reducer as reduxFormReducer } from 'redux-form';
import SignReducer from './signReducer';
import orderReducer from './orderReducer';
import SelectedOrderReducer from './selectedOrderReducer';
import BagReducer from './bagReducer';
import ProductCommentsReducer from './productCommentsReducer';
import ProfileReducer from './profileReducer';
import CompanyReducer from './companyReducer';
import AccountProductReducer from './accountProductReducer';
import CheckoutReducer from './checkoutReducer';
import GlobalNotificationReducer from './globalNotificationReducer';
import isBagOpenReducer from './isBagOpenReducer';
import EmployeeReducer from './employeeReducer';
import ProofConfirmationReducer from './proofConfirmationReducer';
import DeliveryMethodReducer from './deliveryMethodReducer';
import SelectedInvoiceReducer from './selectedInvoiceReducer';
import InvoicesReducer from './InvoicesReducer';
import SizeReducer from './sizeReducer';
import StoragePricesReducer from './storagePricesReducer';
import MultishippingReducer from './MultishippingReducer';
import CreditReducer from './creditReducer';
import ShippingIndividualPacksPricesReducer from './shippingIndividualPackReducer';
import OnboardingInfoReducer from './onboardingInfoReducer';
import ReorderProductsReducer from './reorderProductsReducer';
import CountriesReducer from './countriesReducer';
import PaymentProfilesReducer from './paymentProfilesReducer';
import ShippingOrderCost from './shippingOrderCostReducer';
import TopMessageReducer from './topMessageReducer';
import opportunityReducer from './opportunityReducer';
import DashboardBarMessageReducer from './dashboardBarMessageReducer';
import ShipmentGroupReducer from './shipmentGroupReducer';
import ProcessReducer from './processReducer';
import IntegrationReducer from './integrationReducer';
import integrationFreeTrialModalStatus from './integrationFreeTrialModalStatusReducer';
import IntegrationNavBarReducer from './integrationNavBarReducer';
import cart from './cart';
import catalog from './catalog';
import shipments from './shipments';
import MetadataReducer from './metadataReducer';
import ProcessSendSwagReducer from './processSendSwagReducer';
import RedeemReducer from './redeemReducer';
import ModalReducer from './modalReducers';
import commonReducer from './commonReducer';

export default combineReducers({
  commonReducer,
  cart,
  catalog,
  shipments,
  accountProduct: AccountProductReducer,
  bag: BagReducer,
  checkout: CheckoutReducer,
  company: CompanyReducer,
  countries: CountriesReducer,
  credits: CreditReducer,
  deliveryMethods: DeliveryMethodReducer,
  employees: EmployeeReducer,
  form: reduxFormReducer,
  globalNotification: GlobalNotificationReducer,
  individualPackPrices: ShippingIndividualPacksPricesReducer,
  integrationNavBarReducer: IntegrationNavBarReducer,
  invoices: InvoicesReducer,
  isBagOpen: isBagOpenReducer,
  metadata: MetadataReducer,
  modal: ModalReducer,
  multishipping: MultishippingReducer,
  onboardingInfo: OnboardingInfoReducer,
  opportunity: opportunityReducer,
  orders: orderReducer,
  process: ProcessReducer,
  productComments: ProductCommentsReducer,
  profile: ProfileReducer,
  proofConfirmation: ProofConfirmationReducer,
  redeem: RedeemReducer,
  reorderProducts: ReorderProductsReducer,
  shipmentGroup: ShipmentGroupReducer,
  shippingOrderCost: ShippingOrderCost,
  showDashboardMessage: DashboardBarMessageReducer,
  selectedInvoice: SelectedInvoiceReducer,
  selectedOrder: SelectedOrderReducer,
  sign: SignReducer,
  sizes: SizeReducer,
  storagePrices: StoragePricesReducer,
  openMessage: TopMessageReducer,
  paymentProfiles: PaymentProfilesReducer,
  processSendSwag: ProcessSendSwagReducer,
  integrations: IntegrationReducer,
  integrationFreeTrialModalStatus
});
