import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Box, Grid, makeStyles } from '@material-ui/core';
import SendSwagConfigurationSize from './SendSwagConfigurationSize';
import SendSwagConfigurationProduct from './SendSwagConfigurationProduct';
import styles from './styles/automationActionSelectionModal';
import SendSwagConfigurationDM from './SendSwagConfigurationDM';
import SendSwagConfigurationFinish from './SendSwagConfigurationFinish';
import ActionConfigurationExecution from './ActionConfigurationExecution';
import ActionConfigurationTiming from './ActionConfigurationTiming';
import StoreConfigurationFinish from './StoreConfigurationFinish';
import RedeemLinkConfigurationFinish from './RedeemLinkConfigurationFinish';

const useStyles = makeStyles(styles);

const RedeemLikConfiguration = ({ action, onActionUpdate }) => {
  return (
    <Box>
      <Typography variant="body3SemiBoldInter">Select the page you want to send a redeem link for</Typography>
    </Box>
  );
};

const ShopCreditsConfiguration = ({ action, onActionUpdate }) => {
  return (
    <Box>
      <Typography variant="body3SemiBoldInter">Select the shop you want to send credit for</Typography>
    </Box>
  );
};

const AutomationConfigurationFifth = ({ action, onActionUpdate, automation, isEmployeeTypesEmpty }) => {
  const fifthActionResolver = actionType => {
    switch (true) {
      case actionType === 'send_swag':
        return isEmployeeTypesEmpty ? (
          <ActionConfigurationTiming action={action} automation={automation} onActionUpdate={onActionUpdate} />
        ) : (
          <ActionConfigurationExecution action={action} automation={automation} onActionUpdate={onActionUpdate} />
        );
      case actionType === 'redeem_link':
        return isEmployeeTypesEmpty ? (
          <RedeemLinkConfigurationFinish action={action} automation={automation} />
        ) : (
          <ActionConfigurationTiming action={action} onActionUpdate={onActionUpdate} />
        );
      case actionType === 'shop_credits':
        return isEmployeeTypesEmpty ? (
          <StoreConfigurationFinish action={action} automation={automation} />
        ) : (
          <ActionConfigurationTiming action={action} onActionUpdate={onActionUpdate} />
        );
      default:
        return <ShopCreditsConfiguration action={action} automation={automation} />;
    }
  };

  const classes = useStyles();

  return (
    <Box>
      <Box>{fifthActionResolver(action?.type)}</Box>
    </Box>
  );
};

export default AutomationConfigurationFifth;
