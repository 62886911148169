import { Checkbox, Drawer, Grid, makeStyles, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Button, Typography } from '@swagup-com/components';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { sumByQuantity } from '../../helpers/utils';
import styles from './styles/productSelectedDetails';

const useStyles = makeStyles(styles);

const CollectionDetails = ({ summary, selectedProducts, classes }) => {
  const neatSummary = {
    ...summary,
    selectedProducts: selectedProducts.map(
      sp =>
        summary.selectedProducts.find(xp => xp.imageUrl === sp.image) || {
          productId: sp.id,
          name: sp.name,
          imageUrl: sp.image,
          totalCount: 0,
          excluded: false,
          sizes: sp.stock.map(st => ({ size: st.size.name, quantity: 0 }))
        }
    )
  };

  return (
    <Grid container>
      <Grid container className={classes.contentSection}>
        <Grid item xs={12} style={{ marginBottom: 8 }}>
          <Typography variant="h5SemiBoldInter">Product Selected Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography variant="body2SemiBoldInter" className={classes.productsHeader} style={{ marginBottom: 12 }}>
                TOTAL RECEPIENTS
              </Typography>
              <Typography variant="body2SemiBoldInter" style={{ color: '#4A4F54' }}>
                {neatSummary.totalRedemptions}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2SemiBoldInter"
                className={classes.productsHeaderCenter}
                style={{ marginBottom: 12 }}
              >
                TOTAL SIZES
              </Typography>
              <Grid container spacing={4} justifyContent="center">
                {neatSummary.totalSizes.map(size => (
                  <Grid key={size.size} item xs={2} style={{ textAlign: 'center' }} title={size.quantity}>
                    <Typography variant="body4RegularInter" className={classes.lightText} style={{ marginBottom: 4 }}>
                      {size.size}
                    </Typography>
                    <Typography variant="body4RegularInter" style={{ color: '#4A4F54' }}>
                      {size.quantity}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {neatSummary.selectedProducts.length > 0 && (
        <Grid container>
          <Grid item xs={12} className={classes.productsTableHeader}>
            <Grid container className={classes.contentSection}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Typography variant="body4RegularInter" className={classes.productsHeader}>
                    PRODUCTS
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body4RegularInter" className={classes.productsHeaderCenter}>
                    SIZE BREAKDOWN
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.contentSection}>
            {neatSummary.selectedProducts.map(product => (
              <Grid
                key={product.prodId}
                container
                alignItems="center"
                spacing={4}
                className={product.excluded ? classes.productRowContainerDisabled : classes.productRowContainer}
              >
                <Grid item xs={6}>
                  <Grid container alignItems="center" spacing={4}>
                    <Grid item>
                      <div
                        className={classes.productImageContainer}
                        style={{ border: product.excluded ? '1px solid #E6E8EB' : undefined }}
                      >
                        <img src={product.imageUrl} alt={product.name} className={classes.productImage} />
                        <div className="product-row-container-grayout" />
                      </div>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body2SemiBoldInter">{product.name}</Typography>
                      <Typography variant="body3RegularInter">Quantity Selected: {product.totalCount}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={4} justifyContent="center">
                    {product.sizes.map(size => (
                      <Grid key={size.size} item xs={size.size === 'One Size' ? 6 : 2} title={size.quantity}>
                        <Typography
                          variant="body3MediumInter"
                          className={classes.combinedText}
                          style={{ textAlign: 'center' }}
                        >
                          {size.size}: <span>{size.quantity}</span>
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const SmallCheckbox = withStyles({
  root: {
    padding: 0,
    height: 16,
    width: 16,
    color: '#D6D8DB',
    '&$checked': { color: '#3577d4' },
    '& .MuiSvgIcon-root': {
      height: 14,
      width: 14
    }
  },
  checked: {}
})(Checkbox);

const MediumCheckbox = withStyles({
  root: {
    padding: 0,
    height: 16,
    width: 16,
    color: '#D6D8DB',
    '&$checked': { color: '#3577d4' },
    '& .MuiSvgIcon-root': {
      height: 18,
      width: 18
    }
  },
  checked: {}
})(Checkbox);

const AutoShipDetails = ({ selectedProducts }) => {
  const history = useHistory();
  const location = useLocation();
  const [selected, setSelected] = useState([]);
  const classes = useStyles();

  const toogleSelected = sitem =>
    setSelected(prev =>
      prev.find(it => it.id === sitem.id) ? prev.filter(it => it.id !== sitem.id) : [...prev, sitem]
    );

  const toogleSelectAll = () =>
    setSelected(prev => (prev.length !== selectedProducts.length ? selectedProducts.map(sp => sp) : []));

  const reorder = () =>
    history.push({
      pathname: `/reorder/select-quantities/${selected.map(s => s.id).join()}`,
      state: { from: location }
    });

  return (
    <Grid container>
      <Grid container className={classes.contentSection}>
        <Grid item xs={12}>
          <Typography variant="h5SemiBoldInter" style={{ padding: '8px 40px', paddingLeft: 0 }}>
            Available Inventory
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.productsTableHeader} style={{ marginTop: 8 }}>
          <div className={classes.contentSection}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item>
                <SmallCheckbox checked={selectedProducts.length === selected.length} onChange={toogleSelectAll} />
              </Grid>
              <Grid item xs>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Typography variant="body4RegularInter" className={classes.productsHeader}>
                      INVENTORY
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body4RegularInter" className={classes.productsHeaderCenter}>
                      SIZE BREAKDOWN
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.contentSection}>
            {selectedProducts.map((product, index) => (
              <Grid
                key={product.id}
                container
                spacing={4}
                alignItems="center"
                className={
                  index === selectedProducts.length - 1 ? classes.productLastRowContainer : classes.productRowContainer
                }
              >
                <Grid item>
                  <MediumCheckbox
                    checked={!!selected.find(s => s.id === product.id)}
                    onChange={() => toogleSelected(product)}
                  />
                </Grid>
                <Grid item xs>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={6}>
                      <Grid container alignItems="center" spacing={4}>
                        <Grid item>
                          <div className={classes.productImageContainer}>
                            <img src={product.image} alt={product.name} className={classes.productImage} />
                            <div className="product-row-container-grayout" />
                          </div>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2SemiBoldInter">{product.name}</Typography>
                          <Typography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
                            Qty Left: {sumByQuantity(product.stock)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={4}>
                        {product.stock
                          .map(s => (s.size.name === 'XXS' ? { ...s, size: { ...s.size, id: 0 } } : s))
                          .sort((sA, sB) => (sA.size.category > sB.size.category || sA.size.id > sB.size.id ? 1 : -1))
                          .map(st => (
                            <Grid
                              key={st.size.id}
                              item
                              xs={st.size.id === 9 ? 6 : 2}
                              title={st.quantity}
                              style={{
                                maxWidth: st.size.name === 'One Size' ? '100%' : 80,
                                padding: '0px 0px 6px 0px'
                              }}
                            >
                              <Typography variant="body3MediumInter" className={classes.combinedText}>
                                {st.size.name}: <span>{st.quantity}</span>
                              </Typography>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <div className={classes.contentSection}>
          <Grid container>
            <Grid item>
              <Button size="small" variant="primary" onClick={reorder} disabled={selected.length === 0}>
                Reorder
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const ProductSelectedDetails = ({ open, onClose, summary, isCollection, selectedProducts }) => {
  const classes = useStyles();
  const Details = isCollection ? CollectionDetails : AutoShipDetails;
  return (
    <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper: classes.productSelectionDrawer }}>
      <Grid container justifyContent="flex-start" className={classes.systemBar}>
        <Grid item>
          <Close className={classes.closeDrawer} onClick={onClose} />
        </Grid>
      </Grid>
      <Details classes={classes} summary={summary} selectedProducts={selectedProducts} />
    </Drawer>
  );
};

export default ProductSelectedDetails;
