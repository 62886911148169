import React from 'react';
import { Grid, Modal, Fade, Backdrop, Box } from '@material-ui/core';
import { Button, Typography as SwagupTypography } from '@swagup-com/components';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { isHexColor } from '../../shared/styles/utils';
import { Img } from '../../global/ImgUtils';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'relative',
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    padding: '20px 40px',
    width: '600px',
    height: '550px',
    textAlign: 'center',
    borderRadius: 10
  },
  closeIcon: {
    position: 'absolute',
    top: '20px',
    right: '40px',
    cursor: 'pointer',
    height: 18,
    width: 18
  },
  approveButton: {
    height: 40,
    width: 110,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    margin: '20px 50px',
    borderColor: '#2663FF',
    backgroundColor: '#2663FF',
    color: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#163B99',
      borderColor: '#163B99'
    }
  }
}));

const toTitleCase = str => str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

const decorationStatusText = decoration => {
  const { deleted, created } = decoration;
  if (deleted) return `Deleted`;
  if (created) return 'Created';
  return `Updated`;
};

const decorationVisibilityRules = d => !(d.created && d.deleted);

const getVisibleDecorationsKeys = decorations =>
  Object.keys(decorations).filter(key => decorationVisibilityRules(decorations[key]));

const decorationName = decoration => (decoration.created ? decoration.Name : `Decoration ${decoration.Name || 'New'}`);

const hasExtension = (file, extensions) => file && extensions && extensions.find(ext => file.endsWith(ext));

const formatColor = hexColor => {
  if (!isHexColor(hexColor)) return 'multicolor';
  return `#${hexColor.replace('#', '')}`;
};

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const ApprovalModal = ({ open, handleClose, handleFinalApproval }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        onClick: null
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper}>
          <Close className={classes.closeIcon} onClick={handleClose} />
          <Grid container>
            <Grid item>
              <Img src="/images/proofs/legal-hand.png" width={200} height={200} style={{ margin: '20px 0px' }} />
              <SwagupTypography variant="h6SemiBoldInter" style={{ color: '#0B1829', paddingBottom: 20 }}>
                Just a minute!
              </SwagupTypography>
              <Box>
                <SwagupTypography variant="body3RegularInter" style={{ paddingBottom: 20 }}>
                  By Clicking on <strong>Approve</strong> you are confirming that all details of the mockup for this
                  product are correct and you understand that no further changes can be made after clicking on Approve.
                </SwagupTypography>
                <SwagupTypography variant="body4RegularInter">
                  We encourage you to double-check everything carefully before proceeding to order this product as
                  SwagUp is unable to offer any refunds, credits or returns for any issues with approved products that
                  are not the result of an error on our part.
                </SwagupTypography>
              </Box>
              <Button size="small" className={classes.approveButton} onClick={handleFinalApproval}>
                Approve
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export {
  toTitleCase,
  decorationName,
  decorationStatusText,
  hasExtension,
  formatColor,
  getVisibleDecorationsKeys,
  formatBytes,
  ApprovalModal
};
