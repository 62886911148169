export const commonText = {
  fontFamily: 'Gilroy-Medium',
  fontSize: 12,
  color: '#8d9299'
};

export default {
  subTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    color: '#434d5c',
    marginBottom: 16
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: 15,
    border: 'solid 1px #ebeef2',
    marginBottom: 32
  },
  cardSection: {
    padding: 32,
    '&~&': {
      borderTop: 'solid 1px #ebecf5'
    }
  },
  commonSubTitle: {
    ...commonText,
    whiteSpace: 'nowrap',
    marginBottom: 12
  }
};
