import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import SummaryContent from './SummaryContent';
import styles from './styles/onboarding';

const TwoColumnsContentPage = ({ classes, children, twoPages }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="stretch"
    alignContent="stretch"
    style={{ minHeight: '100vh', width: '100%' }}
  >
    <Grid item xs className={classes.noMobile} />
    <Grid item className={classes.center}>
      <Grid container style={{ height: '100%' }} alignItems="stretch" alignContent="stretch">
        <Grid item xs={twoPages ? 8 : 12} className={classes.pageOne}>
          {children}
        </Grid>
        <Grid item xs={4} style={{ display: twoPages ? 'block' : 'none' }} className={classes.pageTwo}>
          <Grid container alignItems="center" style={{ height: '100%' }}>
            <Grid item xs={12}>
              <SummaryContent />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      item
      xs
      style={{ backgroundColor: twoPages ? '#ffffff' : 'transparent', marginLeft: -1, marginRight: -1 }}
      className={classes.noMobile}
    />
  </Grid>
);

const mapStateToProps = state => ({
  twoPages: state.onboardingInfo.twoPages
});

export default connect(mapStateToProps)(withStyles(styles)(TwoColumnsContentPage));
