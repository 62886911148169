import * as React from 'react';
import clsx from 'clsx';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';

import { Box, Divider, Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ExpandMore } from '@material-ui/icons';
import { productImageBasedOnStatus } from '../../../../global/proofsCommon';
import { useInSendSwagPath } from '../hooks';
import { Img } from '../../../../global/ImgUtils';
import { getUsedQuantitiesBySize, moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import { greyRoundBorder } from '../../../../shared/styles/commonStyles';
import styles from './ShipmentSummary.styles';
import AppliedMembershipPanel from '../../../../global/AppliedMembershipPanel';
import useDiscountsAndRewards from '../../../../../hooks/useDiscountsAndRewards';
import StrikeOutText from '../../../../global/StrikeOutCost';

const useStyles = makeStyles(styles);

const ProductQuantity = ({ product, shipmentGroup, expanded, onExpandToggle }) => {
  const classes = useStyles();
  const isStockLimited = useInSendSwagPath();

  const usedQuantities = React.useMemo(() => getUsedQuantitiesBySize(product.id, [shipmentGroup]), [
    product.id,
    shipmentGroup
  ]);
  const visibleSizes = product.sizes.filter(size => size.quantity || Boolean(usedQuantities[size.size.id]));

  return (
    <Accordion expanded={expanded} onChange={onExpandToggle} classes={{ root: classes.productQuantity }}>
      <AccordionSummary
        classes={{
          root: classes.productSummary,
          content: classes.productSummaryContent,
          expandIcon: classes.expandIcon
        }}
        expandIcon={<ExpandMore />}
      >
        <Grid container style={{ flexWrap: 'nowrap' }}>
          <Img
            src={productImageBasedOnStatus(product.product, 78, 78)}
            alt={product.product.name}
            width={48}
            height={48}
            style={{ ...greyRoundBorder }}
          />
          <Grid
            item
            container
            direction="column"
            justifyContent="space-around"
            xs
            className={classes.productDescription}
          >
            <p className={clsx(classes.boldText, classes.productName)}>{product.product.name}</p>
            <p className={classes.greyText}>
              Qty:{' '}
              <span className={classes.boldText}>
                {isStockLimited ? sumByQuantity(product.sizes) : sum(Object.values(usedQuantities))}
              </span>
            </p>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.productSummaryDetails }}>
        <Grid container direction="column">
          <Grid container item xs={12}>
            {visibleSizes.map((size, idx) => (
              <React.Fragment key={size.size.id}>
                <Grid container justifyContent="space-between">
                  <p className={classes.boldText}>{size.size.name}:</p>
                  <p className={classes.greyText}>
                    {isStockLimited ? size.quantity : usedQuantities[size.size.id] || '-'}
                  </p>
                </Grid>
                {idx < visibleSizes.length - 1 && <Divider className={classes.divider} style={{ margin: '8px 0' }} />}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const ShipmentSummary = ({ children }) => {
  const shipment = useSelector(state => state.shipmentGroup);
  const [expandedProduct, setExpandedProduct] = React.useState(null);

  const shippingTotal = sum(
    shipment.directoryOrders.map(d =>
      sumBy(d.deliveryMethods?.find(dm => dm.selected)?.prices?.breakdown, 'total_price')
    )
  );
  const shippingTotalBeforeDiscount = sum(
    shipment.directoryOrders.map(d =>
      sumBy(d.deliveryMethods?.find(dm => dm.selected)?.prices?.breakdown, 'total_price_without_discount')
    )
  );

  const handleExpandToggle = id => () => setExpandedProduct(prevP => (id === prevP ? null : id));

  const classes = useStyles();
  const { totalBeforeDiscount } = useDiscountsAndRewards(shippingTotal || shippingTotalBeforeDiscount);
  return (
    <Box borderLeft="1px solid #f0f2f5" width={312} padding="36px 0" height="calc(100vh - 64px)">
      <Grid container direction="column" justifyContent="space-between" style={{ height: '100%', paddingLeft: 12 }}>
        <p className={classes.title}>Products and quantity</p>
        <Grid item xs className={classes.productQuantities}>
          <Grid container direction="column">
            {shipment.products?.map((product, idx) => (
              <React.Fragment key={product.id}>
                <ProductQuantity
                  expanded={expandedProduct === product.id}
                  product={product}
                  shipmentGroup={shipment}
                  onExpandToggle={handleExpandToggle(product.id)}
                />
                {idx < shipment.products.length - 1 && (
                  <Divider className={classes.divider} style={{ margin: '4px 20px', width: 'calc(100% - 40px)' }} />
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <AppliedMembershipPanel
            total={shippingTotal || shippingTotalBeforeDiscount}
            size="small"
            style={{ marginBottom: 16 }}
          />
        </Grid>
        <Grid item container style={{ padding: '0 20px' }}>
          <Divider className={classes.divider} />
          <Grid container item justifyContent="space-between" alignItems="center" style={{ marginTop: 20 }}>
            <p className={classes.greyText} style={{ fontSize: 14 }}>
              Total
            </p>
            <p className={classes.total}>
              <StrikeOutText value={totalBeforeDiscount} fontSize={14} />
              {moneyStr(shippingTotal)}
            </p>
          </Grid>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShipmentSummary;
