import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { ReduxTextField } from '../../global/reduxFormFields';
import { sendMail } from '../../../apis/swagup/sendMail';
import styles from './styles/ContactForm';
import { fieldValidationEmailRequired, runFieldValidations } from '../../../helpers/commonValidations';
import toErrorPage from '../../../helpers/toErrorPage';

const ContactForm = props => {
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const { formOrigin, classes, handleSubmit, pristine, submitting, invalid } = props;

  const onSubmit = async formValues => {
    const formName = formOrigin || 'packContactForm';
    const response = await sendMail(formValues, formName);
    if (response.result === 'ok') {
      setSuccess(true);
    } else {
      toErrorPage(response, history);
    }
  };

  return success ? (
    <div className={classes.SuccessMessage}>
      <h3>Message Received!</h3>
      <p>Your message has made it through to mission control. We’ll be in touch soon. To the moon!</p>
      <Button variant="primary" size="small" onClick={() => setSuccess(false)} style={{ width: 280 }}>
        Send another message
      </Button>
    </div>
  ) : (
    <div>
      <Typography className={classes.ContactHeader} variant="h2">
        SEND US A MESSAGE
      </Typography>
      <div className={classes.FormContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field name="first_name" component={ReduxTextField} placeholder="First Name" />
            </Grid>
            <Grid item xs={6}>
              <Field name="last_name" component={ReduxTextField} placeholder="Last Name" />
            </Grid>
          </Grid>
          <Field name="company" component={ReduxTextField} placeholder="Company" />
          <Field name="email" component={ReduxTextField} placeholder="Email" />
          <Field name="subject" component={ReduxTextField} placeholder="Subject" />
          <Field name="message" component={ReduxTextField} placeholder="Message" />
          <Grid className={classes.FormAction} container justifyContent="center">
            <Grid item xs={8}>
              <Button
                fullWidth
                variant="primary"
                size="large"
                type="submit"
                disabled={invalid || submitting || pristine}
              >
                SEND
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

const validate = values => {
  const fieldsToValidate = ['first_name', 'last_name', 'company', 'email', 'message', 'name', 'subject'];
  return runFieldValidations(fieldsToValidate, values, fieldValidationEmailRequired);
};

const mapStateToProps = state => ({
  initialValues: {
    first_name: state.profile.first_name,
    last_name: state.profile.last_name,
    company: state.company.name,
    email: state.profile.email
  }
});

const SendMail = reduxForm({
  form: 'contactForm',
  enableReinitialize: true,
  validate
})(withStyles(styles)(ContactForm));

export default connect(mapStateToProps)(SendMail);
