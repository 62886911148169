import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ArrowTooltip from '../common/Tooltip';
import { moneyStr } from '../../../../../helpers/utils';

const useStyles = makeStyles({
  text: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 'normal',
    color: '#0b1829'
  },
  total: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 'normal',
    color: '#0b1829'
  }
});

const WarehousePriceTooltip = ({ disabled, storageCategories, children }) => {
  const classes = useStyles();

  return (
    <ArrowTooltip
      title={
        disabled ? (
          ''
        ) : (
          <Grid container style={{ rowGap: 26 }}>
            {storageCategories.map(category => (
              <Grid key={category.name} container justifyContent="space-between">
                <Grid item xs={5} container direction="column" style={{ textAlign: 'start' }}>
                  <p className={classes.text}>{category.name}</p>
                  <p className={classes.text} style={{ fontSize: 14, marginTop: 6 }}>
                    {moneyStr(category.price)}
                  </p>
                </Grid>
                <Grid item xs={3} container direction="column" style={{ textAlign: 'start' }}>
                  <p className={classes.text}>Quantity</p>
                  <p className={classes.text} style={{ fontSize: 14, marginTop: 6 }}>
                    x{category.quantity}
                  </p>
                </Grid>
                <Grid item xs={4} container direction="column" style={{ textAlign: 'end' }}>
                  <p className={classes.text}>Total</p>
                  <p className={classes.total} style={{ marginTop: 6 }}>
                    {moneyStr(category.totalPrice)}
                  </p>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )
      }
      minWidth={300}
      padding={16}
    >
      {children}
    </ArrowTooltip>
  );
};

export default WarehousePriceTooltip;
