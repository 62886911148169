import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import styles from './styles/earlyBirdBanner';
import { imageSrcSet } from '../../helpers/utils';
import { useMembership } from '../../contexts/membershipContext';
import gtm from '../../utils/gtm';

const useStyles = makeStyles(styles);

const EarlyBirdBanner = () => {
  const [open, setOpen] = useState(true);
  const { currentMembership } = useMembership();
  const classes = useStyles();
  if (!open) return null;

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item>
        <Box className={classes.birdImageWrapper}>
          <img
            alt="Early Bird"
            src="/images/public/early-bird/early-bird.png"
            srcSet={imageSrcSet('/images/public/early-bird/early-bird.png')}
            className={classes.birdImg}
          />
        </Box>
      </Grid>
      <Grid item xs>
        {currentMembership.isFreeTier ? (
          <Typography variant="h5SemiBoldInter" className={classes.earlyBirdText}>
            <strong>Members Only</strong> Early Bird Special is back!{' '}
            <p>Starting August 4 - September 15, 2023 all Holiday orders get 2X the points</p>
          </Typography>
        ) : (
          <Typography variant="h5SemiBoldInter" className={classes.earlyBirdText}>
            Early Bird Special is back! <span>You get 2X the points for this Holiday season!</span>
          </Typography>
        )}
      </Grid>
      <Grid item>
        {currentMembership.isFreeTier ? (
          <Button
            variant="primary"
            className={classes.noMembershipCTA}
            component={Link}
            to={{ pathname: '/membership/manage-membership', state: { fromProducts: false } }}
            onClick={() => gtm.onClick('Early Bird - Upgrade Now')}
          >
            Upgrade Now
          </Button>
        ) : (
          <Button
            className={classes.membershipCTA}
            component={Link}
            to={{ pathname: '/product-onboarding', state: { fromProducts: false } }}
            onClick={() => gtm.onClick('Early Bird - Let’s go shopping!')}
          >
            Let’s go shopping!
          </Button>
        )}
      </Grid>
      <Grid item>
        <Box style={{ height: 100 }}>
          <IconButton className={classes.closeIconButton} onClick={() => setOpen(false)}>
            <Close className={classes.closeIcon} />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EarlyBirdBanner;
