const tableTitle = {
  fontFamily: 'Gilroy-Medium',
  fontSize: 12,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#8d9299',
  paddingTop: '18px !important',
  paddingBottom: '7px !important'
};

const tableCell = {
  padding: '0px 4px'
};

const topBorder = {
  borderTop: '1px solid #d4d9e2'
};

const header = {
  border: 'none',
  ...tableTitle,
  ...tableCell
};
const commonFont = {
  fontFamily: 'Gilroy-Medium',
  color: '#8d9299',
  paddingTop: '18px',
  paddingLeft: '0px',
  paddingBottom: '16px',
  lineHeight: 1.43
};

const regularText = {
  ...commonFont,
  fontSize: 14,
  paddingRight: '21px'
};

const boldText = {
  ...commonFont,
  fontSize: 14,
  fontWeight: 'bold',
  paddingTop: '18px',
  paddingLeft: '24px'
};
const alignRight = {
  textAlign: 'right',
  paddingRight: '0px !important'
};
const alignLeft = {
  textAlign: 'center'
};
const itemCell = {
  borderRight: '1px solid #d4d9e2',
  lineHeight: 1.43
};

const styles = theme => ({
  subtitle: {
    ...commonFont,
    ...tableCell,
    ...alignLeft,
    verticalAlign: 'top',
    fontSize: 10,
    borderBottom: '1px solid #d4d9e2',
    paddingLeft: '55px !important',
    paddingTop: '0px !important',
    paddingBottom: '14px !important',
    [theme.breakpoints.down(1280)]: {
      paddingLeft: '45px !important'
    },
    [theme.breakpoints.down(850)]: {
      paddingLeft: '25px !important'
    },
    [theme.breakpoints.down(780)]: {
      paddingLeft: '15px !important'
    }
  },
  totalCell: {
    border: 'none',
    ...tableCell,
    ...alignRight,
    borderLeft: '1px solid #d4d9e2',
    paddingTop: '18px',
    paddingLeft: '24px',
    paddingBottom: '16px',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440'
  },
  breakdownTable: {
    paddingTop: 15
  },
  whyBeingCharged: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 11,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4',
    cursor: 'pointer'
  },
  firstHeaderTable: {
    ...header,
    paddingLeft: '0px !important'
  },
  headerTable: {
    ...header,
    ...alignRight
  },
  headerTableStorage: {
    ...header,
    ...alignLeft
  },
  lastHeaderTable: {
    ...header,
    ...alignRight
  },
  questionTex: {
    border: 'none',
    ...tableCell,
    ...topBorder
  },
  subTotalLabel: {
    border: 'none',
    ...tableCell,
    ...topBorder,
    ...regularText,
    ...alignRight,
    paddingLeft: '55px !important',
    paddingRight: '-20px !important',
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#8d9299'
  },
  subTotal: {
    border: 'none',
    ...tableCell,
    ...topBorder,
    ...boldText,
    ...alignRight,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440'
  },
  itemCellBold: {
    border: 'none',
    ...tableCell,
    ...itemCell,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  itemCellRegular: {
    border: 'none',
    ...tableCell,
    ...alignRight,
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  itemCellRegularPrice: {
    border: 'none',
    ...tableCell,
    ...alignLeft,
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  emptyCell: {
    border: 'none'
  },
  emptyCellTopBorder: {
    border: 'none',
    ...topBorder
  },
  emptyCellBottomBorder: {
    border: 'none',
    borderBottom: '1px solid #d4d9e2'
  }
});

export default styles;
