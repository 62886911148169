import React from 'react';
import { Button } from '@swagup-com/components';
import { Grid, makeStyles } from '@material-ui/core';
import { Modal } from '../shared/Modal';

const useStyles = makeStyles({
  dialog: {
    width: 894,
    maxWidth: 894,
    borderRadius: 10
  },
  title: {
    fontFamily: 'Gilroy-bold',
    fontSize: 36,
    color: '#0f2440',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 15
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    lineHeight: 1.75,
    color: '#8d9299',
    textAlign: 'center',
    marginTop: 10
  }
});

const AutomaticChargesModal = ({ open, onToggleOpen, onAllow }) => {
  const classes = useStyles();

  const renderTitle = (
    <>
      <p className={classes.title}>Just double checking!</p>
      <p className={classes.subtitle}>
        By pressing allow, we will charge this card for on-going shipping fees if you do not have <br /> credits
        available.
      </p>
    </>
  );

  return (
    <Modal title={renderTitle} open={open} onClose={onToggleOpen} innerClasses={{ paper: classes.dialog }}>
      <Grid container direction="column" alignItems="center">
        <Button variant="primary" size="medium" onClick={onAllow} style={{ width: 256 }}>
          Allow
        </Button>
        <Button variant="text" onClick={onToggleOpen} style={{ marginTop: 10 }}>
          Nevermind
        </Button>
      </Grid>
    </Modal>
  );
};

export default AutomaticChargesModal;
