const validationMsgStyles = {
  position: 'absolute',
  top: ({ offset }) => offset,
  right: 0,
  left: 0,
  fontFamily: 'Gilroy-Medium',
  fontSize: 10,
  letterSpacing: 0,
  textAlign: 'center'
};

const icon = { fontSize: 14, cursor: 'pointer' };

export default {
  customQuantity: {
    width: 144,
    border: '1px solid #e1e3e5',
    height: 40,
    borderRadius: 10,
    backgroundColor: 'transparent',
    margin: 0,
    '& input': {
      fontFamily: 'Gilroy-Medium',
      fontSize: 16,
      color: '#0b1829',
      letterSpacing: 0,
      textAlign: 'left',
      padding: '6px 0 6px 15px',
      '&.Mui-disabled': {
        color: '#D6D8DB'
      }
    },
    '&.placeholder input': { color: '#787b80' },
    '&.MuiInputBase-input.Mui-disabled': {
      color: '#989EA4',
      border: 'solid 1px #D6D8DB'
    }
  },
  customQtyDisabled: {
    width: 48,
    height: 36,
    '& input': {
      fontSize: 12,
      textAlign: 'center',
      padding: 0
    }
  },
  sizeInputContainer: {
    flexBasis: 48,
    textAlign: 'center'
  },
  quantityButton: ({ active }) => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    color: active ? '#3577d4' : '#0b1829',
    border: active ? 'solid 1px #3577d4' : 'solid 1px #e1e3e5',
    letterSpacing: 0,
    flexBasis: 64,
    minWidth: 'unset',
    height: 40,
    padding: 0,
    borderRadius: 10,
    '&:hover': { border: 'solid 1px #3577d4' },
    '&.MuiButton-root.Mui-disabled': {
      border: 'solid 1px #D6D8DB',
      color: '#989EA4',
      width: 48
    }
  }),
  sizeInput: {
    border: '1px solid #e1e3e5',
    width: 48,
    height: 36,
    borderRadius: 10,
    backgroundColor: 'transparent',
    marginTop: 5,
    '& input': {
      width: '100%',
      fontFamily: 'Gilroy-Medium',
      fontSize: 12,
      color: '#0b1829',
      letterSpacing: 0,
      textAlign: 'center',
      '&.Mui-disabled': {
        color: '#B7BBBF'
      }
    },
    '& input:focus::placeholder': { color: 'transparent' },
    '&.placeholder input': { color: '#8d9299' },
    '&.Mui-disabled': {
      border: 'solid 1px #D6D8DB'
    }
  },
  sizeName: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#787b80'
  },
  disabledSizeName: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#989EA4'
  },
  limitError: {
    ...validationMsgStyles,
    color: '#f44336'
  },
  newQuantity: {
    ...validationMsgStyles,
    color: '#787b80'
  },
  remainingQuantity: {
    ...validationMsgStyles,
    color: '#787b80'
  },
  errorSizeInput: {
    border: '1px solid #f44336 !important'
  },
  warningSizeInput: {
    border: '1px solid #fa902d !important'
  },
  errorIcon: {
    ...icon,
    color: '#f44336'
  },
  warningIcon: {
    ...icon,
    color: '#fa902d'
  }
};
