import { FETCH_INDIVIDUAL_PACK_PRICES, SET_SHIPPING_ORDER_COST } from './types';
import DashBoard from '../apis/DashBoard';
import log from '../logger';
import { okAndLog, errorAndLog } from '../helpers/utils';
import apiPaths from '../helpers/apiPaths';

export const fetchIndividualPackPrices = () => async dispatch => {
  log.debug('fetchIndividualPackPrices Action - Entering');
  try {
    const apiCall = await DashBoard.get(apiPaths.individualPackPrices);
    if (apiCall.status === 200) {
      const individualPackPrices = apiCall.data.results;
      dispatch({
        type: FETCH_INDIVIDUAL_PACK_PRICES,
        payload: individualPackPrices
      });
      return okAndLog('fetchIndividualPackPrices', apiCall.status, apiCall.data);
    }
    return errorAndLog('fetchIndividualPackPrices', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('fetchIndividualPackPrices', e.status, e);
  }
};

export const setShippingOrderCost = value => async dispatch =>
  dispatch({
    type: SET_SHIPPING_ORDER_COST,
    payload: value
  });
