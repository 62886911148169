import * as React from 'react';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';

import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { productImageBasedOnStatus } from '../../../../../global/proofsCommon';
import { useInSendSwagPath } from '../../hooks';
import { Img } from '../../../../../global/ImgUtils';
import { getUsedQuantitiesBySize, moneyStr, sumByQuantity } from '../../../../../../helpers/utils';
import { greyRoundBorder } from '../../../../../shared/styles/commonStyles';
import styles from './styles/shipmentSummary';
import AppliedMembershipPanel from '../../../../../global/AppliedMembershipPanel';
import useDiscountsAndRewards from '../../../../../../hooks/useDiscountsAndRewards';
import StrikeOutText from '../../../../../global/StrikeOutCost';
import { ToogleButtom } from './common';

const useStyles = makeStyles(styles);

const ProductQuantity = ({ product, shipmentGroup }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const isStockLimited = useInSendSwagPath();

  const usedQuantities = React.useMemo(() => getUsedQuantitiesBySize(product.id, [shipmentGroup]), [
    product.id,
    shipmentGroup
  ]);
  const visibleSizes = product.sizes.filter(size => size.quantity || Boolean(usedQuantities[size.size.id]));

  const totalQty = sum(Object.values(usedQuantities));
  const usedQty = sumByQuantity(product.sizes);
  return (
    <Box className={classes.productQuantity}>
      <Grid container>
        <Grid item>
          <Img
            src={productImageBasedOnStatus(product.product, 78, 78)}
            alt={product.product.name}
            width={58}
            height={58}
            style={{ ...greyRoundBorder, width: 58, height: 58 }}
          />
        </Grid>
        <Grid item xs>
          <Box title={product.product.name} className={classes.productDescription}>
            <p className={classes.greyTextName}>{product.product.name}</p>
            <p className={classes.boldTextUnits}>
              {totalQty}
              <span className={classes.boldTextLight}>{` / ${usedQty}`}</span>
              {' Units'}
            </p>
          </Box>
        </Grid>
        <Grid item>
          <ToogleButtom opened={open} onClick={() => setOpen(prev => !prev)} />
        </Grid>
      </Grid>
      {open && (
        <Grid container>
          {visibleSizes.map((size, idx) => (
            <Grid key={size.size.id} item xs={12}>
              <Grid container style={{ paddingBottom: 4 }}>
                <Grid item>
                  <Box style={{ width: 58 }} />
                </Grid>
                <Grid item>
                  <p className={classes.sizeText}>{size.size.name}:</p>
                </Grid>
                <Grid item>
                  <p className={classes.sizeQtyText}>
                    {usedQuantities[size.size.id] || 0}
                    <span className={classes.sizeTextLight}>{` / ${size.quantity}`}</span>
                  </p>
                </Grid>
              </Grid>
              {/* {idx < visibleSizes.length - 1 && <Divider className={classes.divider} style={{ margin: '8px 0' }} />} */}
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

const ShipmentSummary = ({ children }) => {
  const shipment = useSelector(state => state.shipmentGroup);
  const [expandedProduct, setExpandedProduct] = React.useState(null);

  const shippingTotal = sum(
    shipment.directoryOrders.map(d =>
      sumBy(d.deliveryMethods?.find(dm => dm.selected)?.prices?.breakdown, 'total_price')
    )
  );
  const shippingTotalBeforeDiscount = sum(
    shipment.directoryOrders.map(d =>
      sumBy(d.deliveryMethods?.find(dm => dm.selected)?.prices?.breakdown, 'total_price_without_discount')
    )
  );

  const handleExpandToggle = id => () => setExpandedProduct(prevP => (id === prevP ? null : id));

  const classes = useStyles();
  const { totalBeforeDiscount } = useDiscountsAndRewards(shippingTotal || shippingTotalBeforeDiscount);
  return (
    <Box borderLeft="1px solid #f0f2f5" width={336} padding="24px 0 36px" height="calc(100vh - 64px)">
      <Grid container direction="column" justifyContent="space-between" style={{ height: '100%', paddingLeft: 16 }}>
        <Box className={classes.header}>
          <p className={classes.title}>Products being shipped</p>
        </Box>
        <Grid item xs className={classes.productQuantities}>
          <Grid container direction="column" className={classes.productsCards}>
            {shipment.products?.map(product => (
              <React.Fragment key={product.id}>
                <ProductQuantity
                  expanded={expandedProduct === product.id}
                  product={product}
                  shipmentGroup={shipment}
                  onExpandToggle={handleExpandToggle(product.id)}
                />
                {/* {idx < shipment.products.length - 1 && (
                  <Divider className={classes.divider} style={{ margin: '4px 20px', width: 'calc(100% - 40px)' }} />
                )} */}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item style={{ paddingTop: 12 }}>
          <AppliedMembershipPanel
            total={shippingTotal || shippingTotalBeforeDiscount}
            size="small"
            style={{ marginBottom: 16 }}
            onlyDiscount
            fixedDiscount={
              shippingTotal && shippingTotalBeforeDiscount && shippingTotal < shippingTotalBeforeDiscount
                ? shippingTotalBeforeDiscount - shippingTotal
                : undefined
            }
          />
        </Grid>
        <Grid item container style={{ padding: '0 12px' }}>
          <Divider className={classes.divider} />
          <Grid container item justifyContent="space-between" alignItems="center" style={{ marginTop: 20 }}>
            <p className={classes.greyText} style={{ fontSize: 14 }}>
              Total
            </p>
            <p className={classes.total}>
              <StrikeOutText value={totalBeforeDiscount} fontSize={14} />
              {moneyStr(shippingTotal)}
            </p>
          </Grid>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShipmentSummary;
