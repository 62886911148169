import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: 120
  },
  input: {
    fontSize: 24,
    fontWeight: 500,
    cursor: 'default',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    },
    '& > input': {
      textAlign: 'center',
      cursor: 'default'
    },
    '&:before': {
      content: 'none'
    }
  },
  button: {
    width: 32,
    height: 32,
    backgroundColor: '#F4F8FF',
    borderRadius: 7,
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:hover': {
      backgroundColor: '#EDEDFC'
    }
  }
}));
