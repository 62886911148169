import { scrollBar, ellipsisStyles } from '../../../shared/styles/commonStyles';

const placeholderCommon = {
  width: '100%',
  height: 9,
  borderRadius: 6
};

export default theme => ({
  container: {
    width: '100%',
    height: '100%',
    paddingTop: 96,
    paddingLeft: 30,
    [theme.breakpoints.down(1281)]: { paddingLeft: 20 }
  },
  title: {
    opacity: 0.54,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#0f2440'
  },
  list: {
    ...scrollBar,
    listStyle: 'none',
    paddingLeft: 0,
    marginTop: 20,
    height: 'calc(100% - 70px)',
    width: 270
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 18
  },
  photoContainer: {
    width: 40,
    height: 40
  },
  detailsContainer: {
    marginLeft: 10,
    height: 40
  },
  itemName: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-Bold',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1,
    color: '#0f2440',
    width: 200,
    paddingBottom: 6
  },
  itemColor: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    color: '#8d9299'
  },
  itemColorValue: {
    color: '#0f2440',
    paddingLeft: 5
  },
  placeHolderContainer: {
    width: 260,
    paddingTop: 5,
    paddingBottom: 15
  },
  placeHolderDualContainer: {
    width: 260,
    paddingBottom: 20,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '& > .first': { paddingRight: 10 },
    '& > .second': { paddingleft: 10 },
    '& > div > div:first-child': { width: 65 }
  },
  placeholderTitle: {
    width: 85,
    height: 6,
    backgroundColor: '#d4d9e2',
    borderRadius: 3,
    marginBottom: 10,
    opacity: 0.25
  },
  placeholderContent: {
    ...placeholderCommon,
    opacity: 0.5,
    backgroundColor: '#d4d9e2'
  },
  placeholderContentBlue: {
    ...placeholderCommon,
    backgroundColor: '#cce1ff'
  }
});
