/* eslint-disable no-param-reassign */
import React from 'react';
import { Box, Grid, makeStyles, Chip, Dialog, Divider, IconButton } from '@material-ui/core';
import { ArrowBack, CheckCircleRounded, Close } from '@material-ui/icons';
import { Button, Typography } from '@swagup-com/components';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/membership';
import { RewardArrow } from '../icons';
import { intStr, moneyStr } from '../../helpers/utils';
import { StylessButton } from '../buttons';
import CopyButton from '../buttons/CopyButton';
import { useCompany, useProfile } from '../../hooks';

const useStyles = makeStyles(styles);
// const Stripe = require('stripe');
// const stripeNode = Stripe(process.env.REACT_APP_STRIPE_KEY);

const toSentenceCase = text => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : '';
};
const BenefitItem = ({ item, candidate, fromItem }) => {
  const { leftBarNavigation } = useFlags();
  const classes = useStyles({ candidate, fromItem });
  return (
    <Box style={{ marginBottom: 12 }}>
      <Grid container alignItems="center" spacing={4}>
        <Grid item>
          <Box style={{ width: 16, height: 16 }}>
            <CheckCircleRounded className={classes.checkCircleRounded} />
          </Box>
        </Grid>
        <Grid item xs>
          <Typography
            variant={!leftBarNavigation ? 'body4RegularInter' : 'body1SemiBoldInter'}
            className={classes.benefitName}
          >
            {item.name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body4RegularInter">{item.value}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const RewardItem = ({ item, classes = {} }) => {
  return (
    <Grid container alignItems="center" className={classes.rewardSection}>
      <Grid item className={classes.itemReward}>
        <img className={classes.rewardsTabImage} src="/images/membership/rewardRocket.png" alt="membership" />
      </Grid>
      <Grid item className={classes.itemReward}>
        <Box style={{ width: 92 }}>
          <Typography variant="body4SemiBoldInter" className={classes.rewardItemValue}>
            {intStr(item.amount)}
          </Typography>
        </Box>
      </Grid>
      <Grid item className={classes.itemReward}>
        <RewardArrow />
      </Grid>
      <Grid item xs={2} className={classes.itemReward}>
        <Typography variant="body4RegularInter" className={classes.rewardItemText}>
          {item.description}
        </Typography>
      </Grid>
      <Grid item xs className={classes.itemRewardLong}>
        <Typography variant="body4RegularInter" className={classes.rewardItemText}>
          {item.concept}
        </Typography>
      </Grid>
      <Grid item className={classes.itemReward}>
        <Typography variant="body4RegularInter" className={classes.rewardItemText}>
          Reward date {dayjs(item.entry_date).format('MM/DD/YYYY')}
        </Typography>
      </Grid>
      <Grid item className={classes.itemReward}>
        <Typography variant="body4RegularInter" className={classes.rewardItemText}>
          Expires on {dayjs(item.expired_date).format('MM/DD/YYYY')}
        </Typography>
      </Grid>
    </Grid>
  );
};

const MembershipItem = ({
  item,
  onSelect,
  onCancel,
  isCurrent,
  isCancel,
  classes,
  candidate,
  customButton,
  isAfter,
  innerHeight
}) => {
  const { default_price: defaultPrice, images, actualInterval } = item;
  const price = defaultPrice || {};
  const image = images?.[0] || '/images/membership/starter-membership.png';
  const isFreeTier = price.unit_amount / 100 === 0;
  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      className={classes.membershipItem}
      style={
        isCancel && isCurrent
          ? {
              borderTop: '6px solid #3577D4',
              borderColor: '#3577D4',
              paddingTop: 8,
              borderTopRightRadius: 6,
              borderTopLeftRadius: 6
            }
          : undefined
      }
    >
      <Grid item>
        <Grid container style={{ marginBottom: 12 }}>
          <Grid item xs>
            <Box className={classes.membershipItemImageWrapper}>
              <img src={image} alt={item.name} className={classes.membershipItemImage} />
            </Box>
          </Grid>
          {(isCurrent || candidate || isAfter) && (
            <Grid item>
              <Box
                className={candidate ? classes.yourMembershipWrapperCanditate : classes.yourMembershipWrapper}
                style={{ border: isAfter ? '1px solid #989EA4' : undefined }}
              >
                {isAfter ? (
                  <Typography variant="body4RegularInter" className={classes.afterSwitching}>
                    after switching
                  </Typography>
                ) : (
                  <Typography
                    variant="body4RegularInter"
                    className={candidate ? classes.yourMembershipTextCanditate : classes.yourMembershipText}
                  >
                    {`${candidate ? 'new membership' : 'Your Membership'}`}
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
        <Box style={{ height: 64 }}>
          <Typography variant="body3RegularInter" className={classes.membershipItemName}>
            {item.name}
          </Typography>
          <Typography variant="body4RegularInter" className={classes.membershipItemDescription}>
            {item.description}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box style={{ marginBottom: 24 }}>
          <Typography
            variant="body3RegularInter"
            className={classes.membershipItemPrice}
            style={{ color: price.unit_amount / 100 === 0 ? '#131415' : undefined }}
          >
            {`${moneyStr(price.unit_amount / 100)}`}
          </Typography>
          <Typography variant="body4RegularInter" className={classes.membershipItemDescription}>
            {price.recurring?.interval
              ? `per ${price.recurring.interval}  Paid ${actualInterval === 'year' ? 'Annually' : 'Monthly'}`
              : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Box className={classes.membershipItemContent} style={{ height: isCancel ? 182 : innerHeight }}>
          {item.benefits?.map(b => (
            <BenefitItem key={b.id} item={b} candidate={candidate} fromItem />
          ))}
        </Box>
      </Grid>
      {customButton ? (
        <Grid item>
          <Grid container justifyContent="center">
            {customButton}
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          {onSelect && onCancel && (
            <Grid container justifyContent="center">
              {isCurrent ? (
                <>
                  {!isFreeTier ? (
                    <Button variant="secondary" size="small" className={classes.cancelMembership} onClick={onCancel}>
                      Cancel membership
                    </Button>
                  ) : (
                    <div style={{ height: 40 }} />
                  )}
                </>
              ) : (
                <Button variant="primary" size="small" className={classes.manageMembership} onClick={onSelect}>
                  Switch to this plan
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const YourMembershipBox = ({ membership }) => {
  const classes = useStyles();
  //  TODO Update Free Tier Subscription Name in the below line when going Live
  const { default_price: defaultPrice, images } = membership;
  const price = defaultPrice || {};
  const image = images?.[0] || '/images/membership/starter-membership.png';
  const isFreeTier = price.unit_amount / 100 === 0;

  return (
    <Grid container spacing={3} className={classes.yourMembership}>
      <Grid item>
        <img className={classes.membershipImage} src={image} alt="membership" width={74} height={74} />
      </Grid>
      <Grid item xs className={classes.rewardsDetails}>
        <Typography variant="body2SemiBoldInter" className={classes.statusPanelTitle}>
          {membership.name}
        </Typography>
        <Typography variant="body4RegularInter" className={classes.statusPanelDescription}>
          {membership.description}
          <span>
            {isFreeTier ? <Chip label="Free Plan" size="small" className={classes.freePlanChip} component="a" /> : null}
          </span>
        </Typography>
      </Grid>

      <Grid item style={{ paddingTop: 2, paddingRight: 16 }}>
        {isFreeTier ? null : (
          <>
            <Typography
              variant="body3RegularInter"
              className={classes.membershipItemPrice}
              style={{ color: price.unit_amount / 100 === 0 ? '#131415' : undefined }}
            >
              {`${moneyStr((price.unit_amount || 0) / 100)}`}
            </Typography>
            <Typography variant="body4RegularInter" className={classes.membershipItemDescription}>
              {`per ${price.recurring?.interval || ''}`}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const YourRewards = ({ account }) => {
  const classes = useStyles();
  const { data: company } = useCompany();
  return (
    <Grid container spacing={3} className={classes.yourRewards}>
      <Grid item>
        <img className={classes.membershipImage} src="/images/membership/rewards.png" alt="rewards" />
      </Grid>
      <Grid item xs className={classes.rewardsDetails}>
        <Typography variant="body2SemiBoldInter" className={classes.statusPanelTitle}>
          Your Rewards Points Balance
        </Typography>
        <Typography variant="body4RegularInter" className={classes.statusPanelDescription}>
          points collected by using our platform
        </Typography>
        <CopyButton textToCopy={company?.account_manager?.email} />
      </Grid>
      <Grid item style={{ paddingRight: 24 }}>
        <Typography variant="h5BoldInter" style={{ color: '#4CAF50' }}>
          {intStr(account.rewards_points_balance)}
        </Typography>
        <Typography variant="body4RegularInter" style={{ color: '#868A8F' }}>
          <span>{`worth ${moneyStr(account.rewards_points_value)}`}</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

const Header = ({ title, onAction, actionText, isFreeTier }) => {
  // TODO Update the membershipTierName once we are going live
  actionText = isFreeTier ? 'Upgrade Now' : actionText;
  const classes = useStyles();
  return (
    <Grid container className={classes.header}>
      <Grid item xs>
        <Typography variant="h3BoldInter" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      {onAction && (
        <Grid item>
          <Button
            variant="primary"
            size="small"
            className={classes.manageMembership}
            onClick={onAction}
            style={{ backgroundColor: actionText === 'Upgrade Now' ? '#FE8E26' : undefined }}
          >
            {actionText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const CancelMembershipModal = ({ open, onClose, onAccept }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.cancelMembershipModal }}>
      <Grid container>
        <div>
          <Typography variant="body2MediumInter" className={classes.cancelMembershipModalTitle}>
            Are you sure?
          </Typography>
          <Typography variant="body3RegularInter" className={classes.cancelMembershipModalText}>
            Once you cancel your membership, you will not be able to enjoy its benefits.
          </Typography>
          <Divider className={classes.cancelMembershipModallDivider} />
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item>
              <Button size="small" variant="text" className={classes.cancelMembership} onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button size="small" variant="primary" className={classes.manageMembership} onClick={onAccept}>
                Accept
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Dialog>
  );
};

const perks = [
  {
    name: 'Reward Points',
    property: 'reward_points_rate',
    sign: 'X',
    image: '/images/membership/rewards.png'
  },
  {
    name: 'Product Discount',
    property: 'product_discount',
    sign: '%',
    image: '/images/membership/starter-membership.png'
  },
  {
    name: 'Shipping Discount',
    property: 'shipping_discount',
    sign: '%',
    image: '/images/membership/shipping-discount.png'
  },
  {
    name: 'Storage Discount',
    property: 'storage_discount',
    sign: '%',
    image: '/images/membership/storage-discount.png'
  },
  {
    name: 'Priority Discount',
    property: 'rush_production_discount',
    sign: '%',
    image: '/images/membership/rush-production.png'
  }
];

const PerkItem = ({ item, membership, isNextPerk, classes }) => (
  <Box className={classes.perkItem}>
    <Grid container justifyContent="center">
      <Grid item>
        <Box className={classes.perkItemImageWrapper}>
          <img src={item.image} alt={item.name} className={classes.membershipItemImage} />
        </Box>
      </Grid>
    </Grid>
    <Box className={classes.perkItemContent}>
      <Typography className={isNextPerk ? classes.nextPerkItemName : classes.perkItemName}>
        <span>
          {membership[item.property]}
          {item.sign}
        </span>{' '}
        {item.name}
      </Typography>
    </Box>
  </Box>
);

const CancelMembershipNewModal = ({ open, onClose, onAccept, onCancel, membership, nextMembership }) => {
  const classes = useStyles();
  const availablePerks = perks.filter(perk => membership[perk.property] > 0);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: availablePerks.length > 4 ? classes.cancelMembershipNewModal : classes.cancelMembershipModal }}
    >
      <Box>
        <Box className={classes.cancelModalSection}>
          <Grid container alignItems="baseline">
            <Grid item xs>
              <Typography className={classes.cancelMembershipNewModalTitle}>
                Premium benefits and credits that end with your {nextMembership ? <span>{membership.name}</span> : null}{' '}
                subscription
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose} style={{ width: 18, height: 18 }}>
                <Close style={{ width: 18, height: 18 }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" className={classes.cancelMembershipContent}>
            {perks
              .filter(perk => membership[perk.property] > 0)
              .map(item => (
                <Grid key={item.name} item>
                  <PerkItem item={item} membership={membership} classes={classes} />
                </Grid>
              ))}
          </Grid>
          {nextMembership && (
            <Box style={{ paddingTop: 6 }}>
              <Typography className={classes.cancelMembershipNewModalTitle}>Will be replaced with:</Typography>
              <Grid container spacing={2} alignItems="center" className={classes.cancelMembershipContent}>
                {perks
                  .filter(perk => nextMembership[perk.property] > 0)
                  .map(item => (
                    <Grid key={item.name} item>
                      <PerkItem item={item} membership={nextMembership} classes={classes} isNextPerk />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          )}
        </Box>
        <Divider className={classes.cancelMembershipModallDivider} />
        <Box className={classes.cancelModalSection}>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item>
              <Button size="small" variant="text" className={classes.cancelMembershipCTA} onClick={onCancel}>
                Continue to {`${nextMembership ? 'Switch' : 'Cancel'}`}
              </Button>
            </Grid>
            <Grid item>
              <Button size="small" variant="primary" className={classes.manageMembership} onClick={onAccept}>
                Keep Membership
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};

const MembershipDowngrade = ({ currentMembership, nextMembership, onContinue, onLeave }) => {
  const [cancelMembershipModalOpen, setCancelMembershipModalOpen] = React.useState(false);
  const history = useHistory();
  const classes = useStyles();
  return (
    <Grid container spacing={3} alignItems="stretch" className={classes.membershipManagementPanel}>
      <Box className={classes.membershipCancellationPanelBox} style={{ height: 624 }}>
        <Box>
          <StylessButton onClick={onLeave}>
            <Grid container alignItems="center">
              <Grid item>
                <Box style={{ maxHeight: 24, width: 32 }}>
                  <ArrowBack className={classes.backArrow} />
                </Box>
              </Grid>
              <Grid item>
                <Typography className={classes.backText}>Back</Typography>
              </Grid>
            </Grid>
          </StylessButton>
        </Box>
        <Box className={classes.membershipBoxHeader}>
          <Typography className={classes.cancelMembershipTitle}>
            Premium benefits you will loose when your subscription ends
          </Typography>
        </Box>
        <Box className={classes.cancellationItemsPanel}>
          <Grid container justifyContent="center" alignItems="center" spacing={10}>
            <Grid item>
              <MembershipItem
                item={currentMembership}
                customButton={
                  <Button
                    variant="primary"
                    size="small"
                    className={classes.manageMembership}
                    onClick={() => {
                      history.push('/membership/manage-membership');
                      onLeave();
                    }}
                  >
                    Keep Membership
                  </Button>
                }
                isCurrent
                isCancel
                classes={classes}
              />
            </Grid>
            <Grid item>
              {!isEmpty(nextMembership) && (
                <MembershipItem
                  item={nextMembership}
                  customButton={
                    <Button
                      variant="secondary"
                      size="small"
                      className={classes.manageMembership}
                      onClick={() => {
                        setCancelMembershipModalOpen(true);
                      }}
                    >
                      Switch membership
                    </Button>
                  }
                  isCancel
                  isAfter
                  classes={classes}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <CancelMembershipNewModal
          open={cancelMembershipModalOpen}
          onClose={() => setCancelMembershipModalOpen(false)}
          onCancel={() => {
            setCancelMembershipModalOpen(false);
            onContinue();
          }}
          onAccept={() => {
            history.push('/membership/manage-membership');
            setCancelMembershipModalOpen(false);
            onLeave();
          }}
          membership={currentMembership}
          nextMembership={nextMembership}
        />
      </Box>
    </Grid>
  );
};

const getCycle = membership => {
  const interval = membership?.actualInterval;
  return interval === 'month' ? 'monthly' : 'annually';
};

export {
  BenefitItem,
  Header,
  YourRewards,
  YourMembershipBox,
  MembershipItem,
  RewardItem,
  toSentenceCase,
  CancelMembershipModal,
  CancelMembershipNewModal,
  MembershipDowngrade,
  getCycle
};
