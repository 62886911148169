import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, LinearProgress } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './styles/productionTimeCustomRadio';
import { productionTime } from '../../apis/constants';
import { sumByQuantity } from '../../helpers/utils';
import Tooltip from '../shared/Tooltip';
import useDiscountsAndRewards from '../../hooks/useDiscountsAndRewards';
import DiscountBagde from './DiscountBadge';

const toolTipText = 'One or more items in your order are not available for rush production';

const useStyles = makeStyles(styles);

const ProductionTimeButton = ({
  variant,
  turnaroundTime,
  checked,
  disabled,
  onClick,
  discount,
  swagupMembershipPlans
}) => {
  const classes = useStyles({ checked, disabled });

  const handleClick = disabled || checked ? undefined : () => onClick(variant);
  const isRushButton = variant === productionTime.rush;
  const pathIcon = `/images/public/rush${disabled ? '-disabled' : ''}.svg`;

  const { twentyPercentRushText } = useFlags();

  const percent = twentyPercentRushText ? 20 : 10;

  return (
    <Grid container alignItems="center" onClick={handleClick} className={classes.prodTimeRadio}>
      <Grid item xs={6} className={classes.prodType}>
        {variant}
        {isRushButton && <img src={pathIcon} alt="rush" className={classes.rushIcon} />}
      </Grid>
      <Grid item xs={6} className={classes.prodTypeCost}>
        {isRushButton && `+${percent}%`}
      </Grid>
      <Grid item xs={12} container alignItems="center">
        <Grid item xs>
          {turnaroundTime > 0 ? (
            <p className={classes.turnAroundTime}>{`${turnaroundTime} Business Days`}</p>
          ) : (
            <LinearProgress variant="determinate" value={100} classes={{ root: classes.barRoot, bar: classes.bar }} />
          )}
        </Grid>
        {discount && swagupMembershipPlans && (
          <Grid item>
            <DiscountBagde discount={discount} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const ProductionTimeCustomRadio = ({ element, onSetProductionTime }) => {
  const [prodTime, setProdTime] = useState(element.production_time);

  useEffect(() => {
    setProdTime(element.production_time);
  }, [element.production_time]);

  const handleClick = pTime => {
    setProdTime(pTime); // optimistic update
    onSetProductionTime(element, pTime);
  };

  const disabled = sumByQuantity(element.sizes) < Math.max(1, element.product.minimum_to_reorder);
  // rush_production is a new field; doing this we can deploy to production even if the backend doesn't send it
  const canRush = element.product.rush_production ?? true;
  const toolTipTitle = canRush || disabled ? '' : toolTipText;
  const {
    currentMembership: { rush_production_discount }
  } = useDiscountsAndRewards();

  const { swagupMembershipPlans } = useFlags();
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <ProductionTimeButton
            variant={productionTime.standard}
            turnaroundTime={element.product.standard_turnaround_time}
            checked={prodTime === productionTime.standard}
            disabled={disabled}
            onClick={handleClick}
          />
        </Grid>
        <Tooltip title={toolTipTitle} fontSize={12}>
          <Grid item xs={6}>
            <ProductionTimeButton
              variant={productionTime.rush}
              turnaroundTime={canRush ? element.product.rush_turnaround_time : 0}
              checked={prodTime === productionTime.rush}
              disabled={disabled || !canRush}
              onClick={handleClick}
              discount={rush_production_discount}
              swagupMembershipPlans={swagupMembershipPlans}
            />
          </Grid>
        </Tooltip>
      </Grid>
    </div>
  );
};

export { ProductionTimeButton, toolTipText };
export default ProductionTimeCustomRadio;
