import axios from '../stripeAxios';
import DashBoard from '../DashBoard';
import apiPaths from '../../helpers/apiPaths';
import { status200, status201 } from '../swagup/status.utils';

export default {
  fetchMemberships: async () => {
    const response = await axios.get('/products?expand[]=data.default_price', {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_KEY}`
      }
    });
    const { data } = await response.data;

    return data;
  },

  cancelSubscription: async (subscription, reason) =>
    DashBoard.post(apiPaths.cancelSubscription, { subscription, description: reason }, status201).then(
      rslt => rslt.data
    ),

  fetchMembershipsPrograms: async params =>
    DashBoard.get(apiPaths.memberships, { ...status200, params }).then(({ data }) => data.results),

  getAccountMembershipRewards: async query =>
    DashBoard.get(`${apiPaths.rewards}?ordering=-created_at${query || ''}`, status200).then(({ data }) => ({
      total: data.count,
      results: data.results
    })),
  getProratedAmount: async params =>
    DashBoard.get(apiPaths.proratedAmount, { ...status200, params }).then(({ data }) => data),

  updateSubscription: async params =>
    DashBoard.post(apiPaths.updateSubscription, params, status200).then(rslt => rslt.data),

  payPendingStorageFees: async params => {
    DashBoard.post(apiPaths.payStagnantInventories, params, status200).then(rslt => rslt.data);
  },

  discardInventories: async params => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await DashBoard.post(apiPaths.discardInventories, params, status200);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  getStorageTaxes: async params => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await DashBoard.post(apiPaths.storageTaxes, params, status200);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
};
