// import { ellipsisStyles, scrollBar } from '../../shared/styles/commonStyles';
// import { changeColorLightness } from '../../shared/styles/utils';

const styles = theme => ({
  exportButtom: {
    padding: '0px 12px',
    fontFamily: 'Gilroy',
    cursor: 'pointer',
    color: '#3577d4',
    '&:disabled': {
      color: 'ebeef2'
    }
  },
  title: {
    background: 'red'
  },
  productSelectionDrawer: {
    width: 864
  },
  productEditSection: {
    padding: '0px 100px 24px 48px'
  },
  inputTextMultiline: {
    '& .MuiOutlinedInput-multiline': {
      padding: '14px 16px'
    }
  },
  modalContent: { background: 'rgba(100,100,100,0.8)' },
  modalView: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiDialogTitle-root': {
      backgroundColor: '#ffffff'
    },
    '& .MuiDialogActions-root': {
      backgroundColor: '#ffffff'
    }
  },
  variantSectionTitle: { marginTop: 24 },
  createUpdatePageTitle: { color: '#0B1829' },
  inputLabel: { marginBottom: 8, cursor: 'default' },
  inputLabelLight: { marginBottom: 0, cursor: 'default', color: '#4A4F54', position: 'absolute', top: -18 },
  formControl: {
    marginTop: 0,
    marginLeft: 0,
    alignItems: 'flex-start',
    width: '100%',
    '& > div': {
      width: '100%'
    },
    '& .MuiFormLabel-root': {
      marginTop: -6
    }
  },
  checkBoxFormControl: {
    marginTop: 0,
    marginLeft: 0,
    alignItems: 'center',
    width: '100%',
    '& > div': {
      width: '100%'
    },
    '& .MuiFormLabel-root': {
      marginTop: -6
    }
  },
  selectField: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    '& .MuiPaper-root': {
      height: '320px !important'
    },
    '& .MuiSelect-root': {
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    }
  },
  stockCell: {
    paddingTop: 4,
    paddingBottom: 4
  },
  inputTex: {
    maxWidth: 124
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: 4
  },
  selectedImage: {
    height: 252,
    borderRadius: 4,
    padding: 1,
    border: '1px solid #E5E7E8',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    '& .selected-image-overlay': {
      height: '100%',
      width: '100%',
      background: '#000000',
      position: 'absolute',
      display: 'none',
      transition: 'all 4850ms ease-out',
      opacity: 0,
      borderRadius: 4
    },
    '& .selected-image-action': {
      position: 'absolute',
      display: 'none',
      opacity: 0,
      width: '100%',
      paddingTop: 6
    },
    '&:hover': {
      '& .selected-image-overlay': {
        opacity: 0.5,
        display: 'inherit'
      },
      '& .selected-image-action': {
        display: 'flex',
        opacity: 1,
        width: '100%'
      }
    }
  },
  selectedImageAction: {
    position: 'absolute',
    width: '100%',
    padding: 6
  },
  selectedImageDelete: {
    padding: 6,
    opacity: 1,
    zIndex: 999999,
    color: '#EAEAEA',
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: '#ffffff'
      }
    }
  },
  selectedImageDrag: {
    padding: 6,
    opacity: 1,
    zIndex: 999999,
    color: '#EAEAEA',
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: '#ffffff'
      }
    }
  },
  cellCentered: {
    '& .divContent': {
      width: '100%',
      textAlign: 'center'
    }
  },
  selectedImageDeleteIcon: {
    color: '#EAEAEA',
    opacity: 1
  },
  liveImage: {
    height: 118,
    borderRadius: 4,
    padding: 1,
    border: '1px solid #E5E7E8',
    // '&:hover': {
    //   border: '1px solid #D6D8DB'
    // },
    position: 'relative',
    '& .selected-image-overlay': {
      height: '100%',
      width: '100%',
      background: '#000000',
      position: 'absolute',
      display: 'none',
      transition: 'all 4850ms ease-out',
      opacity: 0,
      borderRadius: 4
    },
    '& .selected-image-action': {
      position: 'absolute',
      display: 'none',
      opacity: 0,
      width: '100%'
    },
    '&:hover': {
      '& .selected-image-overlay': {
        opacity: 0.5,
        display: 'inherit'
      },
      '& .selected-image-action': {
        display: 'grid',
        opacity: 1,
        width: '100%'
      }
    },
    width: '100%',
    cursor: 'pointer'
  },
  liveImageSelected: {
    height: 115,
    borderRadius: 4,
    padding: 1,
    border: '1px solid #3577d4',
    width: '100%',
    position: 'relative',
    cursor: 'pointer'
  },
  dragDropZone: {
    padding: '6px 6px',
    height: 118,
    cursor: 'pointer'
  },
  dragDropZoneMain: {
    padding: '0px 0px',
    height: 252,
    cursor: 'pointer'
  },
  dragDropZoneUpdate: {
    padding: '0px 0px',
    height: 118,
    position: 'relative',
    cursor: 'pointer'
  },
  dragDrop: {
    fontSize: 12,
    textAlign: 'center',
    '& span': {
      color: '#3577d4'
    }
  },
  preferedImageTypes: {
    fontSize: 8,
    textAlign: 'center',
    color: '#989EA4'
  },
  preferedImageError: {
    color: '#F44336',
    textAlign: 'center',
    fontSize: 8
  },
  linearColorPrimary: {
    backgroundColor: '#aabfdc',
    height: '9px'
  },
  linearBarColorPrimary: {
    backgroundColor: '#3577d4'
  },
  progressText: {
    fontFamily: 'Futura',
    fontSize: '12px',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#3577d4',
    height: '15px',
    paddingBottom: '19px'
  },
  linearColorPrimarySlim: {
    backgroundColor: '#aabfdc',
    height: '4px'
  }
});
export default styles;
