import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import styles from './styles/julyChristmas';
import { useMembership } from '../../contexts/membershipContext';
import gtm from '../../utils/gtm';

const useStyles = makeStyles(styles);

const Snowflake = ({ size = 32, top, left, noMobile, noDesktop }) => {
  const classes = useStyles({ noDesktop });
  return (
    <Box className={classes.snowflakeWrapper} style={{ width: size, height: size, top, left }}>
      <img
        alt="Snowflake"
        src="/images/dashboard/snowflake.png"
        className={noMobile ? classes.snowflakeImageNoMobile : classes.snowflakeImage}
      />
    </Box>
  );
};

const JulyChristmasBanner = ({ catalog }) => {
  const [open, setOpen] = useState(true);
  const { currentMembership } = useMembership();
  const classes = useStyles({ catalog });
  if (!open) return null;

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item md={8} xs={12}>
        <Box className={classes.greenBox}>
          <Grid container alignItems="center">
            <Grid item>
              <Box className={classes.birdImageWrapper}>
                <img
                  alt="SwagUp Shops"
                  src="/images/dashboard/jon-with-christmas-hat.png"
                  className={classes.birdImg}
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Box className={classes.christmasPromo}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h3SemiBoldInter" className={classes.christmasPromoText}>
                      Christmas
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3SemiBoldInter" className={classes.christmasPromoSubText}>
                      in July is extended!
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box style={{ height: 100 }}>
                <IconButton className={classes.closeIconButtonOnMobile} onClick={() => setOpen(false)}>
                  <Close className={classes.closeIcon} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Snowflake size={16} left="2%" top="12%" />
          <Snowflake size={20} left="12%" top="32%" noMobile />
          <Snowflake size={24} left={catalog ? '16%' : '13%'} top="78%" noMobile />
          <Snowflake size={28} left={catalog ? '58%' : '53%'} top="2%" noMobile />
          <Snowflake size={32} left="92%" top="28%" noDesktop />
          <Snowflake size={36} left={catalog ? '95%' : '92%'} top="5%" noMobile />
          <Snowflake size={20} left={catalog ? '93%' : '87%'} top="70%" noMobile />
        </Box>
      </Grid>
      <Grid item md={4} xs={12}>
        <Box className={classes.blueBox}>
          <Grid container>
            <Grid item xs>
              <Box className={classes.dealBox}>
                <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                  <Grid item>
                    {catalog ? (
                      <Typography variant="h6SemiBoldInter" className={classes.christmasPromoDealText}>
                        Get 10% by placing or prepaying <br /> your Holiday Order by 8/16
                      </Typography>
                    ) : (
                      <Typography variant="h6SemiBoldInter" className={classes.christmasPromoDealText}>
                        Get 10% by placing or prepaying <br /> your Holiday Order by 8/16
                      </Typography>
                    )}
                    <Grid container justifyContent="center">
                      <Grid item>
                        {!catalog && (
                          <Button
                            className={classes.membershipCTA}
                            // component={Link}
                            href="https://launch.swagup.com/swagup-christmas-in-july-promo"
                            target="_blank"
                            onClick={() => gtm.onClick('Christmas in July Promo - Order Now')}
                            size="small"
                          >
                            Learn How
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              {/* )} */}
            </Grid>
            <Grid item>
              <Box style={{ height: 100 }}>
                <IconButton className={classes.closeIconButton} onClick={() => setOpen(false)}>
                  <Close className={classes.closeIcon} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Snowflake size={16} left={catalog ? '6%' : '10%'} top={catalog ? '6%' : '24%'} />
          <Snowflake size={36} left="6%" top="60%" />
          <Snowflake size={28} left={catalog ? '90%' : '80%'} top={catalog ? '65%' : '55%'} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default JulyChristmasBanner;
