const styles = {
  title: {
    padding: '40px 120px 0'
  },
  paper: {
    maxWidth: 750,
    width: 750
  }
};

export default styles;
