import React, { useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { makeStyles, Grid, Paper, Button } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import handleImg from '../../../helpers/handleImgHelper';
import { joinFields, getSortedBySize } from '../../../helpers/utils';
import { ImgWithHandler, Img } from '../../global/ImgUtils';
import styles from './styles/OrderOverview';
import { useSizes } from '../../../hooks';

const useStyles = makeStyles(styles);

const useProducts = order => {
  const shipment = useSelector(state => state.shipmentGroup);
  const sizesData = useSizes();

  const products = order.proofs.map(proof => {
    // We know at this point that the products in the directory order
    // *are* in the shipment. If this line fails it's
    // a bug in the shipment group creation page.
    const product = shipment.products.find(p => p.id === proof.proof);
    const sizes = proof.sizes.map(size => ({ ...size, ...sizesData.data.find(s => size.size === s.id) }));

    return {
      ...product,
      sizes
    };
  });

  return products;
};

const ProductsPreview = ({ order, onShowMoreProducts }) => {
  const classes = useStyles();
  const products = useProducts(order);

  return (
    <Grid container>
      {products.slice(0, 4).map(product =>
        product.sizes.map(size => (
          <Grid item key={`${product.product}-${size.id}`} style={{ marginRight: 24 }}>
            <ImgWithHandler
              src={product.product.image}
              alt={product.product.name}
              width={40}
              height={40}
              className={classes.productImgSmall}
            />
            <p className={classes.productQtySmall}>x{size.quantity}</p>
          </Grid>
        ))
      )}
      {products.length > 4 && (
        <Grid item>
          <Button className={classes.moreProductsButton} onClick={onShowMoreProducts}>
            <p className={classes.productQtySmall} style={{ color: '#3577d4' }}>
              +{products.length - 4}
            </p>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const ProductsList = ({ order }) => {
  const classes = useStyles();
  const products = useProducts(order);

  return (
    <Grid container className={classes.cardSection}>
      {products.map(product =>
        getSortedBySize(product.sizes).map(size => (
          <Grid container className={classes.shippingItem} key={`${product.product}-${size.id}`}>
            <Grid item xs={2}>
              <Img
                src={handleImg(product.product.image, 78, 78)}
                alt={product.product.name}
                width={70}
                height={70}
                className={classes.productImg}
              />
            </Grid>
            <Grid item xs={8}>
              <p className={classes.productName}>{product.product.name}</p>
              <p className={classes.productCommon}>
                Color: <span className={classes.productTextDark}>{product.product.theme_color || 'Custom'}</span>
              </p>
              <p className={classes.productCommon}>
                Size: <span className={classes.productTextDark}>{size.name || 'Custom'}</span>
              </p>
            </Grid>
            <Grid item xs={2}>
              <p className={classes.productQty}>x{size.quantity}</p>
            </Grid>
          </Grid>
        ))
      )}
    </Grid>
  );
};

const ExpandCollapseButton = ({ isListExpanded, onToggleExpandCollapse }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="flex-end">
      <Button
        variant="contained"
        disableElevation
        disableRipple
        disableFocusRipple
        className={clsx(classes.squareButton, {
          [classes.collapsedButton]: !isListExpanded,
          [classes.expandedButton]: isListExpanded
        })}
        onClick={onToggleExpandCollapse}
      >
        {isListExpanded ? (
          <ExpandLess className={classes.expandCollapseButtonIcon} />
        ) : (
          <ExpandMore className={classes.expandCollapseButtonIcon} />
        )}
      </Button>
    </Grid>
  );
};

const ShippingInfo = ({ order }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={10} style={{ paddingRight: 70 }}>
        <p className={classes.commonSubTitle}>Shipping Notes</p>
        <p className={classes.employeeAddress}>{order.shippingNotes}</p>
      </Grid>
      <Grid item xs={2}>
        <p className={classes.commonSubTitle}>Shipping Date</p>
        <p className={classes.employeeName}>{dayjs(order.shippingDate).format('MM/DD/YYYY')}</p>
      </Grid>
    </Grid>
  );
};

const getAddress = recipient =>
  joinFields(
    [
      joinFields([recipient.shipping_address1, recipient.shipping_address2], ' '),
      recipient.shippping_city,
      joinFields([recipient.shipping_state, recipient.shipping_zip], ' ')
    ],
    ', '
  );

const ShipmentCard = ({ order }) => {
  const classes = useStyles();

  const { recipients } = useSelector(state => state.shipmentGroup);
  const [isListExpanded, setIsListExpanded] = useState(false);

  const recipient = recipients.find(r => r.id === order.directory);
  const deliveryMethod = order.deliveryMethods.find(dm => dm.selected);

  const address = getAddress(recipient);

  return (
    <Paper elevation={0} className={classes.card}>
      <Grid container className={classes.cardSection}>
        <Grid item xs={11}>
          <Grid container style={{ marginBottom: 32 }}>
            <Grid item xs={10} style={{ paddingRight: 70 }}>
              <p className={classes.commonSubTitle}>Recipient</p>
              <p className={classes.employeeName}>{joinFields([recipient.first_name, recipient.last_name], ' ')}</p>
              <p className={classes.employeeAddress}>{address}</p>
            </Grid>
            <Grid item xs={2}>
              <p className={classes.commonSubTitle}>Delivery</p>
              <p className={classes.employeeName}>{deliveryMethod.name}</p>
            </Grid>
          </Grid>
          {isListExpanded ? (
            <ShippingInfo order={order} />
          ) : (
            <ProductsPreview order={order} onShowMoreProducts={() => setIsListExpanded(true)} />
          )}
        </Grid>
        <Grid item xs={1}>
          <ExpandCollapseButton
            isListExpanded={isListExpanded}
            onToggleExpandCollapse={() => setIsListExpanded(!isListExpanded)}
          />
        </Grid>
      </Grid>
      {isListExpanded && <ProductsList order={order} />}
    </Paper>
  );
};

const OrderOverview = ({ paginatedDirectoryOrders }) => {
  const shipment = useSelector(state => state.shipmentGroup);
  const directoryOrders = paginatedDirectoryOrders ?? shipment.directoryOrders;

  return directoryOrders.map(order => <ShipmentCard key={order.directory} order={order} />);
};

export default OrderOverview;
