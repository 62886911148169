import * as React from 'react';
import filter from 'lodash/filter';
import partition from 'lodash/partition';

import { useSelector } from 'react-redux';
import { getMatchAddress, joinFields } from '../../../../../helpers/utils';

const sortBy = {
  full_name: (a, b) =>
    joinFields([a.first_name, a.last_name], ' ').localeCompare(joinFields([b.first_name, b.last_name], ' ')),
  created_at: (a, b) => {
    if (a.created_at === b.created_at) return 0;
    return a.created_at < b.created_at ? 1 : -1;
  }
};

const useFilterAndSortRecipients = ({ search, ordering, filters, onlyErrors, onlyWarnings }) => {
  const { recipients, errors } = useSelector(state => state.shipmentGroup);

  const filteredRecipients = React.useMemo(() => {
    const filtered = recipients.filter(r => {
      const matchAddress = getMatchAddress(filters?.address, r);
      const matchSearch = `${r.first_name} ${r.last_name}`.toLowerCase().includes(search.toLowerCase().trim());

      const checkedSizes = filter(filters?.sizes, 'checked');
      const noCheckedSizes = checkedSizes.length === 0;
      const hasSize = noCheckedSizes || checkedSizes.map(size => size.value).includes(r.size?.id ?? 'None');

      return matchSearch && hasSize && matchAddress;
    });

    // const [withIssues, withoutIssues] = partition(
    //   filtered,
    //   recipient => errors[recipient.id] && !errors[recipient.id].hidden
    // );
    // const [withErrors, withWarnings] = partition(withIssues, recipient => errors[recipient.id].type === 'error');
    // withErrors.sort(sortBy[ordering]);
    // withWarnings.sort(sortBy[ordering]);
    // withoutIssues.sort(sortBy[ordering]);

    filtered.sort(sortBy[ordering]);

    return filtered; // [...withErrors, ...withWarnings, ...withoutIssues];
  }, [recipients, ordering, search, filters]);

  const filteredRecipientsErrors = React.useMemo(() => {
    const filtered = recipients.filter(r => {
      const matchAddress = getMatchAddress(filters?.address, r);
      const matchSearch = `${r.first_name} ${r.last_name}`.toLowerCase().includes(search.toLowerCase().trim());

      const checkedSizes = filter(filters?.sizes, 'checked');
      const noCheckedSizes = checkedSizes.length === 0;
      const hasSize = noCheckedSizes || checkedSizes.map(size => size.value).includes(r.size?.id ?? 'None');

      return matchSearch && hasSize && matchAddress;
    });

    const withErrors = filtered.filter(r => errors[r.id]?.type === 'error');
    withErrors.sort(sortBy[ordering]);
    return withErrors;
  }, [recipients, ordering, search, filters, errors]);

  const filteredRecipientsWarnings = React.useMemo(() => {
    const filtered = recipients.filter(r => {
      const matchAddress = getMatchAddress(filters?.address, r);
      const matchSearch = `${r.first_name} ${r.last_name}`.toLowerCase().includes(search.toLowerCase().trim());

      const checkedSizes = filter(filters?.sizes, 'checked');
      const noCheckedSizes = checkedSizes.length === 0;
      const hasSize = noCheckedSizes || checkedSizes.map(size => size.value).includes(r.size?.id ?? 'None');

      return matchSearch && hasSize && matchAddress;
    });

    const withWarnings = filtered.filter(r =>
      ['size-warning', 'inactive-size-warning', 'warning'].includes(errors[r.id]?.type)
    );
    withWarnings.sort(sortBy[ordering]);

    return withWarnings;
  }, [recipients, ordering, search, filters, errors]);

  const filteredRecipientsSelection = React.useMemo(() => {
    switch (true) {
      case onlyErrors && onlyWarnings:
        return [...filteredRecipientsErrors, ...filteredRecipientsWarnings];
      case onlyErrors:
        return filteredRecipientsErrors;
      case onlyWarnings:
        return filteredRecipientsWarnings;
      default:
        return filteredRecipients;
    }
  }, [filteredRecipients, filteredRecipientsErrors, filteredRecipientsWarnings, onlyErrors, onlyWarnings]);

  return {
    total: recipients.length,
    filteredTotal: filteredRecipientsSelection.length,
    recipients: filteredRecipientsSelection,
    totalErrors: filteredRecipientsErrors.length,
    totalWarnings: filteredRecipientsWarnings.length
  };
};

export default useFilterAndSortRecipients;
