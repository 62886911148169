export const zipList = initialArray => {
  const [initialActive, ...restOfTabs] = initialArray;

  const zip = {
    previous: [],
    current: initialActive,
    next: restOfTabs
  };

  const convertZipToArray = currentZip => [...currentZip.previous, currentZip.current, ...currentZip.next];

  const setActive = currentZip => newActive => {
    const currentArray = convertZipToArray(currentZip);

    const newZip = {
      previous: currentArray.slice(0, newActive),
      current: currentArray[newActive],
      next: currentArray.slice(newActive + 1, currentArray.length)
    };

    return apiForZip(newZip);
  };

  const apiForZip = currentZip => ({
    asArray: () => convertZipToArray(currentZip),
    isActive: tab => currentZip.current === tab,
    setActive: setActive(currentZip),
    activeTab: () => currentZip.current
  });

  return apiForZip(zip);
};
