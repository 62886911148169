import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import csvProcessesApi from '../../../apis/swagup/csvProcesses';
import { useAsync, useSizes } from '../../../hooks';
import { FullscreenStepper, Container } from '../../layouts/FullscreenStepper';
import ShipmentDetailsHome from '../../inventoryShipment/ShipmentDetailsHome';
import SelectSwag from '../../shipSwag/selectSwag/SelectSwag';
import ErrorAlert from '../../shared/ErrorAlert';
import Loader from '../../global/Loader';

const defaultSteps = [
  { label: 'Select Swag', path: 'select-swag' },
  { label: 'Domestic shipment', path: 'domestic' },
  { label: 'International shipment', path: 'international' },
  { label: 'Review shipment', path: 'review-shipment' }
];

const ShipSwag = () => {
  const history = useHistory();
  const { step } = useParams();
  const { state } = useLocation();
  const [contacts, setContacts] = useState();
  const [steps, setSteps] = useState(defaultSteps);
  const { data: sizes } = useSizes();
  const [fetchContacts, response, isPending, error] = useAsync(csvProcessesApi.fetchOutputs);
  const { leftBarNavigation } = useFlags();

  useEffect(() => {
    if (!step) history.replace(`/ship-swag/${steps[0].path}`, state);
  }, [step, history, steps, state]);

  useEffect(() => {
    if (contacts?.length > 0) {
      const onlyDomestic = contacts.every(c => c.shipping_country === 'US') && 'international';
      const onlyInternational = contacts.every(c => c.shipping_country !== 'US') && 'domestic';
      if (onlyDomestic || onlyInternational)
        setSteps(defaultSteps.filter(s => s.path !== (onlyDomestic || onlyInternational)));
    }
  }, [contacts]);

  const { fileId, from } = state || {};
  useEffect(() => {
    if (fileId) {
      fetchContacts(fileId, 9999999);
    } else {
      history.replace(from || (leftBarNavigation ? '/contacts' : '/shipments/contacts'));
    }
  }, [fileId, history, fetchContacts]);

  useEffect(() => {
    if (response && sizes) {
      const outputs = response.data.results.map(output => {
        const data = JSON.parse(output.input_json);
        return {
          ...data,
          id: output.id,
          size: sizes.find(s => s.name === data.size)
        };
      });
      setContacts(outputs);
    }
  }, [response, sizes]);

  const activeStep = steps.findIndex(s => step && step.includes(s.path));

  const handleNextStep = useCallback(
    (param, newState) =>
      history.replace(`/ship-swag/${steps[activeStep + 1].path}/${param || ''}`, { ...state, ...newState }),
    [steps, activeStep, history, state]
  );

  const handlePreviousStep = useCallback(
    param =>
      activeStep > 0
        ? history.replace(`/ship-swag/${steps[activeStep - 1].path}/${param || ''}`, state)
        : history.replace(`/import-contacts/select-action/${fileId}`),
    [steps, activeStep, history, state, fileId]
  );

  const handleUpdateContacts = async newContacts => {
    const payload = {
      process: fileId,
      contact_sizes: newContacts.map(c => ({ output: c.id, size: c.size.id }))
    };
    const resp = await csvProcessesApi.updateOutputsSize(payload);
    if (resp.result !== 'ok') return false;

    setContacts(contacts.map(c => newContacts.find(nc => nc.id === c.id) || c));
    return true;
  };

  const getStepComponent = () => {
    switch (step) {
      case 'select-swag':
        return (
          <SelectSwag
            contacts={contacts}
            fetchContacts={fetchContacts}
            onUpdateContacts={handleUpdateContacts}
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
          />
        );
      case 'domestic':
        return (
          <ShipmentDetailsHome
            process={fileId}
            key="Domestic"
            step="Domestic"
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
          />
        );
      case 'international':
        return (
          <ShipmentDetailsHome
            process={fileId}
            key="International"
            step="International"
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
          />
        );
      default:
        return null;
    }
  };

  const tryAgain = (
    <Button variant="text" onClick={() => fetchContacts(fileId, 9999999)} style={{ height: 'auto', padding: 0 }}>
      Try again
    </Button>
  );

  const content = error ? (
    <Container direction="column">
      <ErrorAlert error="An error ocurred retrieving the CSV contacts" action={tryAgain} style={{ marginTop: 36 }} />
    </Container>
  ) : (
    getStepComponent()
  );

  return (
    <FullscreenStepper steps={steps} activeStep={activeStep}>
      {isPending && <Loader />}
      {(contacts || error) && content}
    </FullscreenStepper>
  );
};

export default ShipSwag;
