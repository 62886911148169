import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import round from 'lodash/round';
import styles from './styles/proofSubItem';
import { StylessButton, IncrementButton, DecrementButton } from '../buttons';
import { ImgWithHandler } from '../global/ImgUtils';
import { moneyStr } from '../../helpers/utils';

const processItem = (item, packKind) => (packKind && packKind.match(/^(Preset)|(Custom)/) ? item : item.product);

const processName = (item, packKind) =>
  packKind && packKind.match(/^(Preset)|(Custom)/) ? item.name : item.product.name;

const processColor = (item, packKind, packColor) =>
  packKind && packKind.match(/^(Preset)|(Custom)/)
    ? (item.images_per_color.length > 0 && packColor) || item.color_name
    : item.product.theme_color;

const useStyles = makeStyles(styles);

const ProofSubItem = ({
  item,
  packColor,
  packKind,
  removeProofSubItem,
  idBag,
  changeProofSubItemQty,
  selectedThemeIndex
}) => {
  const processedItem = processItem(item, packKind);
  const imageUrl =
    processedItem.images_per_color && processedItem.images_per_color.length > 0
      ? processedItem.images_per_color[selectedThemeIndex].image
      : processedItem.image;
  const classes = useStyles();

  const productItemTotalPrice = round(parseFloat(item.product.price) * item.units_per_pack, 2);

  return (
    <Grid key={item.id} item xs={4}>
      <Grid container spacing={0} style={{ paddingTop: 30 }}>
        <Grid item style={{ paddingTop: 0 }}>
          <ImgWithHandler src={imageUrl} width={78} height={78} alt={item.name} />
        </Grid>
        <Grid item xs style={{ padding: '0 10px 0 24px', width: 'calc(100% - 78px)' }}>
          {packKind !== 'Custom' && <p className={classes.qtyPerPackText}>{`${item.units_per_pack} PER PACK`}</p>}
          <p className={classes.itemTitle}>{processName(item, packKind)}</p>
          <p className={classes.itemColor} style={{ marginTop: 6 }}>
            Color:{' '}
            <span className={classes.itemColorValue}>{`${processColor(item, packKind, packColor) || 'Custom'}`}</span>
          </p>
          <p className={classes.itemPriceText}>{moneyStr(productItemTotalPrice)}</p>
          {packKind === 'Custom' && (
            <>
              <p className={classes.packNormalText} style={{ marginTop: 6 }}>
                {`$ ${item.price}`}
              </p>
              <Grid container alignItems="center" spacing={4}>
                <Grid item xs>
                  <p className={classes.packNormalText} style={{ marginTop: 6 }}>
                    Qty per pack:
                  </p>
                </Grid>
                <Grid item>
                  {!item.qtyLocked && (
                    <DecrementButton
                      height="auto"
                      disabled={item.units_per_pack <= 1}
                      onClick={() => changeProofSubItemQty(idBag, item.id, -1)}
                    />
                  )}
                </Grid>
                <Grid item>
                  <p className={classes.packNormalText} style={{ marginTop: 6, paddingLeft: 4, paddingRight: 4 }}>
                    {item.units_per_pack}
                  </p>
                </Grid>
                <Grid item>
                  {!item.qtyLocked && (
                    <IncrementButton height="auto" onClick={() => changeProofSubItemQty(idBag, item.id, 1)} />
                  )}
                </Grid>
              </Grid>
            </>
          )}
          {packKind === 'Custom' && (
            <div style={{ paddingTop: 6 }}>
              <StylessButton height="auto" onClick={() => removeProofSubItem(idBag, item.id)}>
                <p className={classes.tableLinkText}>Remove item</p>
              </StylessButton>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProofSubItem;
