import React, { useEffect } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Grid, Button, Snackbar } from '@material-ui/core';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import dayjs from 'dayjs';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getPaymentProfiles } from '../../../actions';
import toErrorPage from '../../../helpers/toErrorPage';
import Loader from '../../global/Loader';
import OrderOverview from './OrderOverview';
import styles from './styles/ShipmentsReview';
import ShippingSummary from './ShippingSummary';
import { GoBack, Pagination, CenteredGrid, ErrorAlert } from '../../shared';
import {
  useCompany,
  useCreditActions,
  useCreditSummary,
  useLocalPagination,
  useIsAchPrepaid,
  useShippingCutoffHour
} from '../../../hooks';
import apiPaths from '../../../helpers/apiPaths';
import { getSelected, totalShippingPrice, getShipmentSummary, canSendSwag } from '../../../helpers/utils';
import { shipmentsApi } from '../../../apis/swagup';
import { minimumAcquirableCredits } from '../../../helpers/helperConstants';
import { shouldDisableDate } from '../../../helpers/commonDateFunctions';
import { CustomTooltip } from '../../products/commonProductsElements';
import { SEND_SWAG_EXECUTION } from '../../../actions/types';
import { cleanShipmentGroup } from '../../../actions/shipmentGroupActions';
import useDiscountsAndRewards from '../../../hooks/useDiscountsAndRewards';

const useStyles = makeStyles(styles);

const doesShipmentHasSomeInvalidDates = (shipment, shippingCutoffHour) =>
  shipment.directoryOrders.some(order => shouldDisableDate(new Date(order.shippingDate), shippingCutoffHour));

const getEmployeeOrders = shipment =>
  shipment.directoryOrders.map(order => {
    const products = order.proofs.map(proof => {
      // We know at this point that the products in the directory order
      // *are* in the shipment. If this line fails it's
      // a bug in the shipment group creation page.
      const product = shipment.products.find(p => p.id === proof.proof).id;
      return {
        product,
        sizes: proof.sizes
      };
    });

    return {
      employee: order.directory,
      delivery_method: order.deliveryMethods.find(dm => dm.selected).id,
      products,
      shipping_date: dayjs(order.shippingDate).format('YYYY-MM-DD'),
      shipping_notes: order.shippingNotes
    };
  });

const getDataFromShipment = (shipment, isInternational = false) => {
  const employees = shipment.recipients
    .filter(recipient => isInternational === (recipient.shipping_country !== 'US'))
    .map(recipient => recipient.id);

  const directoryOrders = employees.map(employee =>
    shipment.directoryOrders.find(dOrder => dOrder.directory === employee)
  );

  const totalPrice = sum(
    directoryOrders.map(dOrder => {
      const selectedDm = getSelected(dOrder.deliveryMethods);
      return totalShippingPrice(selectedDm.prices.breakdown);
    })
  );

  return {
    employeesQty: employees.length,
    totalPrice
  };
};

const sortDirectoryOrders = recipients => (orderA, orderB) =>
  recipients[orderB.directory].created_at - recipients[orderA.directory].created_at;

const paginateDirectoryOrders = (offset, limit) => (_s, idx) => idx >= offset && idx < offset + limit;

const useSortedAndPaginatedDirectoryOrders = (offset, limit) => {
  const { recipients, directoryOrders } = useSelector(state => state.shipmentGroup);

  const recipientsMap = React.useMemo(() => Object.fromEntries(recipients.map(r => [r.id, r])), [recipients]);

  const sortedDirectoryOrders = React.useMemo(() => directoryOrders.slice().sort(sortDirectoryOrders(recipientsMap)), [
    recipientsMap,
    directoryOrders
  ]);

  const paginatedOrders = React.useMemo(() => sortedDirectoryOrders.filter(paginateDirectoryOrders(offset, limit)), [
    sortedDirectoryOrders,
    offset,
    limit
  ]);

  return paginatedOrders;
};

const defaultPerPageOptions = [5, 10, 20];

const ShipmentsReview = () => {
  const classes = useStyles();

  const { data: company } = useCompany();
  const isAchPrepaid = useIsAchPrepaid();

  const shipment = useSelector(state => state.shipmentGroup);
  const paymentProfiles = useSelector(state => state.paymentProfiles);
  const summary = React.useMemo(() => getShipmentSummary(shipment), [shipment]);

  const history = useHistory();
  const [useCreditsFirst, setUseCreditsFirst] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const { leftBarNavigation } = useFlags();

  const dispatch = useDispatch();
  const paymentProfileQuery = useQuery(
    [apiPaths.paymentProfiles, company.id],
    () => dispatch(getPaymentProfiles(company.id)),
    {
      enabled: !isEmpty(company),
      onError: error => toErrorPage(error, history)
    }
  );

  const firstRender = React.useRef(true);
  React.useEffect(() => {
    firstRender.current = false;
  }, []);

  const { data: creditSummary } = useCreditSummary({ enabled: firstRender.current || Boolean(errorMessage) });
  const { current_balance: balance } = creditSummary;

  const shippingCutoffHour = useShippingCutoffHour();

  const queryClient = useQueryClient();
  const maxOrders = +queryClient.getQueryData(apiPaths.metadata).MAX_BULK_DIRECTORY_ORDERS;
  const { addCredits, invalidateCache } = useCreditActions();
  const sendSwagMutation = useMutation(
    [apiPaths.employeeOrders],
    ({ orders }) => shipmentsApi.sendSwag(orders, maxOrders),
    {
      onSuccess: result => {
        dispatch({
          type: SEND_SWAG_EXECUTION,
          payload: { processing: false, time: dayjs() }
        });
        invalidateCache();
        if (result.status && result.status !== 201) {
          setErrorMessage('Error in shipment creation. Please contact your account manager for support.');
          return;
        }

        const domesticData = getDataFromShipment(shipment);
        const internationalData = getDataFromShipment(shipment, true);
        const total = round(domesticData.totalPrice + internationalData.totalPrice, 2);

        const creditsAvailable = useCreditsFirst && balance > 0 ? Math.min(total, balance) : 0;
        const selectedPaymentProfile = paymentProfiles.find(pm => pm.default);
        const paymentProfile =
          !selectedPaymentProfile || creditsAvailable >= total
            ? undefined
            : {
                ...selectedPaymentProfile,
                amount: round(total - creditsAvailable, 2)
              };

        queryClient.invalidateQueries(apiPaths.accountProducts);
        dispatch(cleanShipmentGroup);
        const credits = !paymentProfile ? total : creditsAvailable;
        history.push('/ship-swag-overview', {
          domesticData,
          internationalData,
          total,
          credits,
          paymentProfile,
          isFromSendSwag: true
        });
      },
      onError: error => {
        dispatch({
          type: SEND_SWAG_EXECUTION,
          payload: { processing: false, time: dayjs() }
        });
        toErrorPage(error, history);
      }
    }
  );

  const { processSendSwag } = useSelector(state => state);
  useEffect(() => {
    if (processSendSwag?.processing) {
      if (dayjs().diff(processSendSwag?.time, 'M') > 10) {
        // above 10 mins
        dispatch({
          type: SEND_SWAG_EXECUTION,
          payload: { processing: false, time: dayjs() }
        });
      }
    }
  }, [dispatch, processSendSwag]);

  const pagination = useLocalPagination(shipment.directoryOrders.length, defaultPerPageOptions);
  const offset = pagination.pageIndex * pagination.perPage;
  const paginatedDirectoryOrders = useSortedAndPaginatedDirectoryOrders(offset, pagination.perPage);

  const { allow_negative_credit_balance: allowNegativeCreditBalance } = company;
  React.useEffect(() => {
    if (!isEmpty(company)) setUseCreditsFirst(balance > 0 || allowNegativeCreditBalance);
  }, [company, balance, allowNegativeCreditBalance]);

  const { state } = useLocation();
  if (isEmpty(shipment))
    return <Redirect to={state?.from ?? (leftBarNavigation ? '/contacts' : '/shipments/contacts')} />;

  const handleToggleUseCredits = () => setUseCreditsFirst(prev => !prev);

  const handleSendSwag = async amountToPay => {
    const shouldBuyNewCredits = (!useCreditsFirst || amountToPay > balance) && !isEmpty(paymentProfiles);
    if (shouldBuyNewCredits) {
      const creditsAvailable = balance > 0 ? balance : 0;
      const creditsToBuy = useCreditsFirst ? amountToPay - creditsAvailable : amountToPay;
      const creditsInfo = { amount: Math.max(minimumAcquirableCredits, round(creditsToBuy, 2)) };
      try {
        await addCredits.mutateAsync(creditsInfo, {});
      } catch (e) {
        setErrorMessage(e.message || 'Error in Payment');
        return;
      }
    }

    dispatch({
      type: SEND_SWAG_EXECUTION,
      payload: { processing: true, time: dayjs() }
    });
    const employeeOrders = getEmployeeOrders(shipment);
    sendSwagMutation.mutate({ orders: employeeOrders });
  };

  if (shipment.directoryOrders.length === 0) return <Redirect to="/send-swag" />;

  const queries = [paymentProfileQuery, sendSwagMutation, addCredits];
  const isLoading = queries.some(query => query.isLoading);
  const total = round(summary.domestic.total + summary.international.total, 2);

  const hasSomeInvalidShippingDate = doesShipmentHasSomeInvalidDates(shipment, shippingCutoffHour);
  return (
    <Grid container justifyContent="center" className={classes.container}>
      {isLoading && <Loader />}
      <Snackbar
        open={Boolean(errorMessage)}
        classes={{ anchorOriginTopCenter: classes.errorAlert }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={8000}
        onClose={() => setErrorMessage('')}
      >
        <ErrorAlert error={errorMessage} action={() => setErrorMessage('')} style={{ marginTop: 36 }} />
      </Snackbar>

      <CenteredGrid container alignItems="flex-start" style={{ marginTop: 26 }}>
        <GoBack />
        <Grid container style={{ paddingBottom: 90 }}>
          <Grid item xs={12}>
            <p className={classes.title}>Review your Swag shipment</p>
          </Grid>
          <Grid item xs={6} style={{ paddingRight: 16 }}>
            <p className={classes.subTitle}>Order overview</p>
            <OrderOverview paginatedDirectoryOrders={paginatedDirectoryOrders} />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 16 }}>
            <ShippingSummary
              balance={balance}
              paymentProfiles={paymentProfiles}
              useCreditsFirst={useCreditsFirst}
              onToggleUseCredits={handleToggleUseCredits}
              errorMessage={errorMessage}
              onError={setErrorMessage}
            />
          </Grid>
        </Grid>
      </CenteredGrid>
      <Grid className={classes.stickyBar}>
        <CenteredGrid container justifyContent="space-between" alignItems="center">
          <Pagination
            count={shipment.directoryOrders.length}
            endText="shipments"
            perPage={pagination.perPage}
            onNext={pagination.onNext}
            pageIndex={pagination.pageIndex}
            onPrevious={pagination.onPrevious}
            sizeOptions={pagination.sizeOptions}
            onPerPageChange={pagination.changeSize}
          />
          <CustomTooltip
            title={
              processSendSwag?.processing
                ? 'There is a pending Send Swag request being processed, this could take up to 10 mins to complete.'
                : 'Some shipping dates are invalid. Please go back and reschedule the shipments in order to continue.'
            }
            disableHoverListener={!hasSomeInvalidShippingDate || processSendSwag?.processing}
          >
            <Button
              className={classes.sendButton}
              onClick={() => handleSendSwag(total)}
              disabled={
                !canSendSwag(company, total, paymentProfiles, useCreditsFirst, isAchPrepaid) ||
                hasSomeInvalidShippingDate ||
                processSendSwag?.processing
              }
            >
              Send Swag
            </Button>
          </CustomTooltip>
        </CenteredGrid>
      </Grid>
    </Grid>
  );
};

export default ShipmentsReview;
