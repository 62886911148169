import { useQuery } from 'react-query';
import accountProductsApi from '../../../apis/swagup/accountProducts';
import apiPaths from '../../../helpers/apiPaths';
// import { getRecommendedCredits } from '../storeCommon';

const usePageProductBasedInfo = page => {
  const accountProductsParams = {
    ids: page.productOptions?.map(p => p.productId).join()
  };
  const { data } = useQuery(
    [apiPaths.accountProducts, accountProductsParams],
    () => accountProductsApi.fetch(accountProductsParams),
    {
      enabled: !!page.productOptions?.length
    }
  );

  const selectedProducts = data?.results || [];
  const recommendedCredits = 0; // getRecommendedCredits(selectedProducts, page.expressShipping);
  const canAllowInternational = selectedProducts.every(sp => sp.can_ship_international);

  return { selectedProducts, recommendedCredits, canAllowInternational };
};

export default usePageProductBasedInfo;
