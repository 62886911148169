import { greyRoundBorder, scrollBar } from '../../../../../../shared/styles/commonStyles';
import { changeColorLightness } from '../../../../../../shared/styles/utils';

export default {
  drawerRoot: {
    zIndex: '1000 !important'
  },
  drawerPaper: {
    maxWidth: 812,
    padding: '0 50px 18px'
  },
  recipientRow: {
    padding: '16px 16px 32px',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.05)',
    borderRadius: 15,
    border: '1px solid #EBEDF0',
    position: 'relative',
    marginBottom: 24,
    '& .remove-shipment-section': {
      opacity: 0,
      transition: 'opacity 0.3s'
    },
    '&:hover': {
      '& .remove-shipment-section': {
        opacity: 1
      }
    },
    '& .remove-product-section': {
      // position: 'absolute',
      // bottom: 24
    }
  },
  documentBox: { width: 400, border: '1px solid #EBEDF0', borderRadius: 8, padding: '20px 16px' },
  title: {
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: '18px',
    color: '#4A505C',
    marginBottom: 8
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    color: '#131415'
  },
  rowInfo: {
    color: '#4A505C'
  },
  productShippingInfo: {
    paddingLeft: 24
  },
  productInfoPanel: {
    borderBottom: '1px solid #EBEDF0',
    paddingBottom: 12,
    marginBottom: 32
  },
  buttonIcon: {
    marginTop: 4,
    color: 'inherit',
    fontSize: 18,
    transform: ({ open }) => (open ? 'rotate(-180deg)' : '')
  },
  addAllRemaining: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.57,
    color: '#3577d4',
    height: 32,
    padding: 0
  },
  recipientSummary: {
    ...greyRoundBorder,
    marginTop: 16,
    padding: 19
  },
  divider: {
    backgroundColor: '#f0f2f5'
  },
  fullwidthDivider: {
    backgroundColor: '#E5E7E8',
    margin: '32px 0px 16px'
  },
  button: {
    height: 12,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 0.71,
    letterSpacing: 'normal',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  selectAllAvailableButton: {
    height: 20,
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 0.67,
    letterSpacing: 'normal',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  resetButton: {
    height: 20,
    minWidth: 'unset',
    color: '#787b80',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 0.67,
    letterSpacing: 'normal',
    '&:hover': { color: changeColorLightness('#787b80') }
  },
  editIcon: {
    height: 24,
    width: 24,
    marginLeft: 16,
    padding: 4,
    borderRadius: 4,
    backgroundColor: '#f4f8ff'
  },
  unselectedProduct: {
    ...greyRoundBorder,
    marginTop: 16,
    padding: 24,
    backgroundColor: '#fafafa'
  },
  shippingNoteContainer: {
    marginTop: 8,
    width: 364,
    padding: '12px 16px',
    border: 'solid 1px #D6D8DB',
    borderRadius: 8
  },
  shippingNote: {
    width: '100%',
    fontFamily: 'Inter',
    fontSize: 12,
    lineHeight: 1.57,
    color: '#787b80',
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontStyle: 'italic',
        fontWeight: 400,
        color: '#989EA4'
      }
    }
  },
  notesLengthCounter: {
    width: '100%',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 'normal',
    color: '#989EA4',
    textAlign: 'right'
  },
  miscInputTitle: {
    color: '#0B1829',
    marginBottom: 12
  },
  miscInputLowTitle: { color: '#0B1829' },
  miscInputSubTitle: {
    marginTop: 12,
    color: '#989EA4'
  },
  textAreaResize: {
    ...scrollBar,
    height: '88px !important',
    fontSize: 14,
    paddingRight: 8
  },
  saveButton: {
    height: 56,
    letterSpacing: 0,
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#fafafa',
      color: '#787b80'
    }
  },
  fileFormats: ({ disabled }) => ({
    color: disabled ? '#989EA4' : '#787B80',
    fontFamily: 'Inter',
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 400
  }),
  ImageUploadContainer: {
    marginTop: 12,
    width: '100%',
    borderRadius: 10,
    border: '1px solid #EBEDF0'
    // backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23E5E7E8FF' stroke-width='4' stroke-dasharray='11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
  },
  removeShipmentButton: {
    '&:hover': {
      '& svg': {
        color: '#F40306'
      },
      '& p': {
        color: '#F40306'
      }
    },
    '& svg': {
      color: '#F44336'
    },
    '& p': {
      color: '#F44336'
    }
  },
  imgContainer: {
    padding: '8px 16px'
  },
  docContainer: { padding: '14px 16px' },
  uploadText: ({ disabled }) => ({
    color: disabled ? '#989EA4' : '#6F777E',
    fontFamily: 'Inter',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
    '& span': {
      color: '#3577D4'
    }
  })
};
