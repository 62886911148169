import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = theme => ({
  red: {
    color: '#b00020'
  },
  tableHeader: {
    height: 'auto'
  },
  cellHeaderBag: {
    padding: '4px 0px 16px 5px',
    borderBottom: '1px solid #434c5f',
    '& p': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#434c5f',
      margin: 0
    }
  },
  qtyErrorContainer: {
    position: 'absolute',
    bottom: 25,
    left: 66,
    [theme.breakpoints.down('lg')]: { left: 43 }
  },
  qtyError: {
    fontSize: '14px',
    color: '#f44336',
    fontWeight: 600
  },
  imgContainerConfirmation: {
    position: 'relative',
    width: 102,
    height: 102
  },
  packImgConfirmation: {
    maxHeight: 102,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    width: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    objectFit: 'contain'
  },
  imgContainer: {
    position: 'relative',
    width: 256,
    height: 256
  },
  packImg: {
    maxHeight: 282,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    width: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    objectFit: 'contain'
  },
  ArtworkImg: {
    maxHeight: 65,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    width: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    objectFit: 'contain'
  },
  logoContainer: {
    position: 'relative',
    width: '100%',
    height: 65
  },
  packTittleNew: {
    ...ellipsisStyles,
    fontFamily: 'Gilroy-Bold',
    fontSize: '24px',
    color: '#0f2440',
    margin: 0,
    paddingBottom: '8px',
    maxWidth: '100%'
  },
  packTotal: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    margin: 0
  },
  packTittle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    margin: 0,
    paddingBottom: '8px'
  },
  packNormalTextNew: {
    fontFamily: 'Gilroy-Medium',
    fontSize: '16px',
    fontWeight: 500,
    color: '#0f2440',
    margin: 0
    // paddingBottom: '8px'
  },
  restock: {
    fontFamily: 'Gilroy-Medium',
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#3577d4',
    padding: 0,
    margin: '0px 10px 0px 0px'
  },
  packNormalText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#8d9299'
  },
  productionTime: {
    color: '#0f2440',
    marginTop: -25
  },
  packSizeQty: {
    verticalAlign: 'middle',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#434c5f',
    margin: 0,
    paddingBottom: '39px'
  },
  packBoldText: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440',
    marginRight: 2,
    '&.packBoldBiggerText': { fontSize: '24px' }
  },
  inputContainer: {
    textAlign: 'center',
    paddingBottom: '24px'
  },
  SizeName: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 0.88,
    color: '#8d9299',
    textAlign: 'center',
    marginBottom: 14,
    maxWidth: 56
  },
  textFieldQty: {
    border: '1px solid #c4c4c4',
    width: '56px',
    height: 42,
    borderRadius: '10px',
    backgroundColor: 'transparent',
    margin: '0px',
    marginBottom: 21,
    '& input': {
      textAlign: 'center',
      fontFamily: 'Gilroy-Medium',
      fontSize: 16,
      color: '#0f2440'
    },
    '&.placeholder input': { color: '#8d9299' }
  },
  mb0: { marginBottom: 0 },
  deletePack: {
    textAlign: 'right',
    width: 24,
    height: 24,
    marginBottom: 5,
    marginTop: -5
  },
  bagCell: {
    padding: '20px 0 4px 0',
    verticalAlign: 'top',
    borderBottom: '1px solid #434c5f'
  },
  bagBottomBorder: {
    padding: '20px 0 4px 0',
    verticalAlign: 'top',
    borderBottom: 'none !important'
  },
  remove: {
    fontFamily: 'Gilroy-Medium',
    fontSize: '16px',
    color: '#f44336'
  },
  qtyButtonSelected: {
    border: '2px solid #3577d4 !important',
    height: 42,
    minWidth: 56,
    padding: 0,
    borderRadius: 10
  },
  qtyButton: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    color: '#0f2440',
    border: 'solid 1px #d3dbe5',
    height: 42,
    minWidth: 56,
    padding: 0,
    borderRadius: 10
  },
  ProofConfirmationCard: {
    border: '1px solid #ebeef2',
    marginBottom: 16,
    borderRadius: 12,
    minHeight: 400,
    backgroundColor: '#ffffff'
  },
  reorderImageSizesSection: { padding: '40px 43px 49px 43px' },
  reorderPriceSection: {
    padding: '48px 66px 49px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 43,
      paddingRight: 43
    },
    borderLeft: '1px solid #ebeef2',
    position: 'relative'
  },
  reorderSizesSectionContainer: {
    paddingLeft: 43,
    width: 'calc(100% - 256px)'
  },
  sizeInputContainer: {
    flexBasis: 48,
    textAlign: 'center'
  },
  warningIcon: {
    fontSize: 14,
    cursor: 'pointer',
    color: '#fa902d'
  }
});

export default styles;
