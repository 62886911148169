import { useMutation, useQuery, useQueryClient } from 'react-query';
import { creditApi } from '../apis/swagup';
import apiPaths from '../helpers/apiPaths';

const oneMinute = 60 * 1000;
const useCreditSummary = options => {
  return useQuery(apiPaths.creditsSummaries, () => creditApi.getSummaries(), {
    placeholderData: { data: { current_balance: 0 } },
    staleTime: oneMinute,
    select: data => data.data,
    ...options
  });
};

const useCreditActions = () => {
  const queryClient = useQueryClient();

  const invalidateCache = () => queryClient.invalidateQueries(apiPaths.creditsSummaries);
  const invalidateProfilesCache = () => queryClient.invalidateQueries(apiPaths.paymentProfiles);

  const addCredits = useMutation(creditApi.addCredits, {
    onSuccess: () => {
      invalidateCache();
    }
  });

  const changeDefaultProfile = useMutation(creditApi.changeDefaultProfile, {
    onSuccess: () => {
      invalidateProfilesCache();
    }
  });

  return { addCredits, invalidateCache, changeDefaultProfile, invalidateProfilesCache };
};

export { useCreditSummary, useCreditActions };
