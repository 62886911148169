const styles = theme => ({
  container: {
    paddingBottom: 42,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0
    }
  },
  root: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  emptyInventoryCard: {
    margin: '0 auto',
    textAlign: 'center',
    maxWidth: '518px'
  },
  emptyInventoryHeader: {
    fontFamily: 'Gilroy-bold',
    fontSize: '40px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.2',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#0f2440',
    paddingBottom: '40px'
  },
  inventoryNotice: {
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 0,
    fontFamily: 'Gilroy',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  submitButton: {
    width: '282px',
    height: '56px',
    '& span': {
      fontFamily: 'Futura',
      fontSize: '16px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal'
    },
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: '4px',
    backgroundColor: '#3577d4',
    marginTop: '40px'
  }
});

export default styles;
