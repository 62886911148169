import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography } from '@swagup-com/components';
import { productImageBasedOnStatus } from '../../../../global/proofsCommon';
import Img from '../../../../shared/Img';
import { ellipsisStyles, greyRoundBorder } from '../../../../shared/styles/commonStyles';

const useStyles = makeStyles({
  rowRecipientName: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.75,
    color: '#0b1829',
    width: 360,
    ...ellipsisStyles
  },
  infoContainer: ({ noImage }) => ({
    height: '100%',
    marginLeft: noImage ? 0 : 16,
    overflow: 'hidden'
  }),
  rowName: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    color: '#0b1829',
    width: '100%',
    ...ellipsisStyles
  },
  rowInfo: {
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    lineHeight: 1.57,
    color: '#787b80'
  },
  productImage: {
    ...greyRoundBorder,
    width: 48,
    height: 48,
    backgroundColor: '#fff'
  }
});

const InformationRow = ({ className, product, name, info, children }) => {
  const { enableUnallocatedProductsManagementV2 } = useFlags();
  const noImage = product === undefined;
  const classes = useStyles({ noImage });

  const newDesign = () => {
    return (
      <>
        <Typography variant="body2SemiBoldInter" style={{ color: '#131415' }}>
          {name}
        </Typography>
        <Typography variant="body3RegularInter" className={classes.rowInfo} style={{ color: '#131415', marginTop: 4 }}>
          {info}
        </Typography>
      </>
    );
  };

  const oldDesign = () => {
    return (
      <>
        <p className={noImage ? classes.rowRecipientName : classes.rowName}>{name}</p>
        <p className={classes.rowInfo} style={{ fontSize: noImage ? 14 : 12 }}>
          {info}
        </p>
      </>
    );
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={className} role="row">
      {!noImage && <Img src={productImageBasedOnStatus(product, 78, 78)} alt={name} className={classes.productImage} />}
      <Grid item container direction="column" justifyContent="space-around" xs className={classes.infoContainer}>
        {enableUnallocatedProductsManagementV2 ? newDesign() : oldDesign()}
      </Grid>
      {children}
    </Grid>
  );
};

export default InformationRow;
