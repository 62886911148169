import { getDefaultQuantities } from '../helpers/utils';

export const payloadProfile = {
  id: '1234567890abcdefghij',
  name: 'first@last.com',
  first_name: 'First',
  last_name: 'Last',
  primary_phone: '+5353535353',
  mobile_phone: null,
  email: 'first@last.com'
};

export const items = [
  {
    id: 140,
    category: 32,
    name: 'Custom Mailer Box',
    img: 'https://swagup-static.swagup.com/platform-staging/media/form/box.png',
    price: '6.00',
    amount: 100,
    inCart: false
  },
  {
    id: 173,
    category: 32,
    name: 'Patagonia Better Sweater',
    img: 'https://swagup-static.swagup.com/platform-staging/media/form/Better_Sweater_Quarter_Zip.png',
    price: '92.00',
    inCart: true
  }
];

export const order = {
  id: 975,
  date: '2019-07-26',
  pack_quantity: 300,
  pack_budget: '30.00',
  inhands_date: '2019-07-26',
  comments: 'No response',
  primary_logo: null,
  secondary_logo: null,
  full_name: 'Julio C',
  work_email: 'julio@swagup.com',
  company: 'SwagUp Inc',
  items: [
    { item: 116, quantity: 1 },
    { item: 149, quantity: 1 },
    { item: 150, quantity: 1 }
  ],
  phone: null,
  gclid: null,
  billing_address1: null,
  billing_address2: null,
  billing_city: null,
  billing_state: null,
  billing_zip: null,
  risk_level: 1,
  type: 2,
  qualified: true
};

export const orderData = {
  budget: 60,
  amount: 100,
  name: 'Julio C',
  email: 'julio@swagup.com',
  company: 'SwagUp Inc'
};

export const contactData = {
  name: 'Julio C',
  email: 'julio@swagup.com',
  subject: 'hola',
  message: 'hola'
};

export const contactResponse = {
  id: 26,
  ...contactData,
  timestamp: '2019-08-02T20:01:29.181346Z'
};

export const pricesData = {
  quantities: getDefaultQuantities(),
  products: [140, 61, 52, 47, 116].map(id => ({
    item_id: id,
    colors: 3
  }))
};

export const pricesResponse = {
  quantities: [
    { quantity: 50, price: 64.62 },
    { quantity: 100, price: 50.58 },
    { quantity: 150, price: 46.96 },
    { quantity: 250, price: 43.74 },
    { quantity: 500, price: 39.61 },
    { quantity: 1000, price: 37.54 },
    { quantity: 600, price: 48.74 }
  ],
  products: [
    {
      item_id: 140,
      units_per_pack: 1,
      colors: 3,
      prices: [
        { quantity: 50, price: 8.56 },
        { quantity: 100, price: 8.05 },
        { quantity: 150, price: 7.66 },
        { quantity: 250, price: 6.88 },
        { quantity: 500, price: 5.04 },
        { quantity: 1000, price: 4.16 },
        { quantity: 600, price: 4.88 }
      ]
    },
    {
      item_id: 61,
      units_per_pack: 1,
      colors: 3,
      prices: [
        { quantity: 50, price: 11.54 },
        { quantity: 100, price: 8.89 },
        { quantity: 150, price: 8.0 },
        { quantity: 250, price: 7.3 },
        { quantity: 500, price: 6.77 },
        { quantity: 1000, price: 6.5 },
        { quantity: 600, price: 9.3 }
      ]
    },
    {
      item_id: 52,
      units_per_pack: 1,
      colors: 3,
      prices: [
        { quantity: 50, price: 18.18 },
        { quantity: 100, price: 15.52 },
        { quantity: 150, price: 14.64 },
        { quantity: 250, price: 13.93 },
        { quantity: 500, price: 13.4 },
        { quantity: 1000, price: 13.14 },
        { quantity: 600, price: 12.93 }
      ]
    },
    {
      item_id: 47,
      units_per_pack: 1,
      colors: 3,
      prices: [
        { quantity: 50, price: 14.7 },
        { quantity: 100, price: 8.89 },
        { quantity: 150, price: 7.98 },
        { quantity: 250, price: 7.25 },
        { quantity: 500, price: 6.7 },
        { quantity: 1000, price: 6.43 },
        { quantity: 600, price: 8.25 }
      ]
    },
    {
      item_id: 116,
      units_per_pack: 1,
      colors: 3,
      prices: [
        { quantity: 50, price: 11.64 },
        { quantity: 100, price: 9.23 },
        { quantity: 150, price: 8.68 },
        { quantity: 250, price: 8.38 },
        { quantity: 500, price: 7.7 },
        { quantity: 1000, price: 7.31 },
        { quantity: 600, price: 5.38 }
      ]
    }
  ]
};

export const nonce = {
  opaqueData: {
    dataDescriptor: 'COMMON.ACCEPT.INAPP.PAYMENT',
    dataValue:
      'eyJjb2RlIjoiNTBfMl8wNjAwMDUzM0FBOTNGRjZDMDU2NzkyQTQyRTU0NjU3QUM5ODQ1NzdCQTk0ODU4OERFMkREOTQ1ODhDOUM0MjEwOTk5ODI0Qzc0ODc4RkQ2NzMwQ0YzQUYxMzE4QUMyQkZDMkY3RTUzIiwidG9rZW4iOiI5NTY1MDI5MzA4ODQ3Mjg5NDAzNTAxIiwidiI6IjEuMSJ9'
  },
  messages: {
    resultCode: 'Ok',
    message: [
      {
        code: 'I00001',
        text: 'Successful.'
      }
    ]
  }
};

export const sizes = {
  count: 10,
  next: null,
  previous: null,
  results: [
    { id: 1, name: 'XS', category: 'Apparel' },
    { id: 2, name: 'S', category: 'Apparel' },
    { id: 3, name: 'M', category: 'Apparel' },
    { id: 4, name: 'L', category: 'Apparel' },
    { id: 5, name: 'XL', category: 'Apparel' },
    { id: 6, name: '2XL', category: 'Apparel' },
    { id: 7, name: '3XL', category: 'Apparel' },
    { id: 8, name: '4XL', category: 'Apparel' },
    { id: 9, name: 'One Size', category: 'Generic' },
    { id: 10, name: 'XXS', category: 'Apparel' }
  ]
};

export const reorderProduct = {
  id: 600,
  product: {
    id: 600,
    description: null,
    image: 'https://swagup-static.swagup.com/platform-staging/media/accounts/account_167/account-products/a0A2a0',
    price: '44.02',
    sort_order: 1,
    record_type: 'Pack',
    stock: [
      {
        size: {
          id: 1,
          name: 'XS',
          category: 'Apparel',
          modified_on: '2019-12-11T14:30:21.916348Z',
          sort_order: 2
        },
        quantity: 8,
        minimum: 1,
        modified_on: '2020-10-05T16:17:58.757548Z'
      },
      {
        size: {
          id: 2,
          name: 'S',
          category: 'Apparel',
          modified_on: '2019-12-11T14:30:21.921418Z',
          sort_order: 3
        },
        quantity: 2,
        minimum: 1,
        modified_on: '2020-10-04T15:04:07.643071Z'
      },
      {
        size: {
          id: 3,
          name: 'M',
          category: 'Apparel',
          modified_on: '2019-12-11T14:30:21.924795Z',
          sort_order: 4
        },
        quantity: 23833,
        minimum: 1,
        modified_on: '2020-10-04T15:13:44.706459Z'
      },
      {
        size: {
          id: 4,
          name: 'L',
          category: 'Apparel',
          modified_on: '2019-12-11T14:30:21.927945Z',
          sort_order: 5
        },
        quantity: 2,
        minimum: 1,
        modified_on: '2020-10-04T15:04:07.546959Z'
      },
      {
        size: {
          id: 5,
          name: 'XL',
          category: 'Apparel',
          modified_on: '2019-12-11T14:30:21.931354Z',
          sort_order: 6
        },
        quantity: 2,
        minimum: 1,
        modified_on: '2020-10-04T15:04:07.556079Z'
      },
      {
        size: {
          id: 6,
          name: '2XL',
          category: 'Apparel',
          modified_on: '2020-07-27T16:22:09.262937Z',
          sort_order: 7
        },
        quantity: 2,
        minimum: 1,
        modified_on: '2020-10-04T15:04:07.565009Z'
      },
      {
        size: {
          id: 7,
          name: '3XL',
          category: 'Apparel',
          modified_on: '2020-07-27T16:23:17.179571Z',
          sort_order: 8
        },
        quantity: 2,
        minimum: 1,
        modified_on: '2020-10-04T15:04:07.633456Z'
      }
    ],
    status: 'Approved',
    created_at: '2019-11-15T15:47:45.643387Z',
    created_by: 'devops@swagup.com.sandbox4',
    modified_on: '2020-10-05T18:47:15.536333Z',
    modified_by: 'devops',
    theme_color: null,
    theme_color_hex: null,
    items: [
      {
        product: {
          id: 594,
          description:
            "High quality vinyl stickers from Stickermule. Weatherproof and tear-proof mean these stickers will last a lifetime. It's always worth it to throw 2 or 3 of these in a pack!",
          name: 'Die Cut Stickers - 6 square inches',
          image:
            'https://swagup-static.swagup.com/platform-staging/media/accounts/account_167/account-products/a0A2a000002blcYEAQ.png',
          price: '1.04',
          sort_order: 1,
          record_type: 'Product',
          status: 'Approved',
          is_apparel: false,
          created_at: '2019-11-15T15:28:40.513042Z',
          created_by: 'devops@swagup.com.sandbox4',
          modified_on: '2020-09-25T15:11:49.845541Z',
          modified_by: 'devops',
          theme_color: 'Full Color',
          theme_color_hex: null,
          primary_color_requirement: null,
          secondary_color_requirement: null,
          enabled: true,
          can_ship_international: true,
          decorations: [
            {
              Colors: ['Blue'],
              Notes: '',
              Dimensions: '',
              Location: '',
              Imprint_Type: 'Full Color'
            }
          ]
        },
        units_per_pack: 1
      },
      {
        product: {
          id: 599,
          description:
            'This 17 oz, double wall, stainless steel, thermal bottle features a copper vacuum insulation and threaded stainless steel lid. \r\n\r\nItem Details: \r\n- 10-3/8" x 3"',
          name: 'The Best Seller',
          image:
            'https://swagup-static.swagup.com/platform-staging/media/accounts/account_167/account-products/a0A2a000002blcfEAA.png',
          price: '12.55',
          sort_order: 1,
          record_type: 'Product',
          status: 'Approved',
          is_apparel: false,
          created_at: '2019-11-15T15:40:40.591113Z',
          created_by: 'devops@swagup.com.sandbox4',
          modified_on: '2020-10-02T15:05:46.348378Z',
          modified_by: 'devops',
          theme_color: 'blue',
          theme_color_hex: '1A1E39',
          primary_color_requirement: null,
          secondary_color_requirement: null,
          enabled: true,
          can_ship_international: true,
          decorations: [
            {
              Colors: ['White'],
              Notes: '',
              Dimensions: '',
              Location: 'See Template',
              Imprint_Type: 'Screen Print'
            }
          ]
        },
        units_per_pack: 1
      },
      {
        product: {
          id: 596,
          description:
            "With a soft touch surface, fine point gel tip, and several color options, this pen will be your new favorite. Whether you're signing official documents or doodling your life away, this is the perfect pen for you to jot it all down.",
          name: 'Minimalist Soft Touch Pen',
          image:
            'https://swagup-static.swagup.com/platform-staging/media/accounts/account_167/account-products/a0A2a000002blcaEAA.png',
          price: '2.48',
          sort_order: 1,
          record_type: 'Product',
          status: 'Approved',
          is_apparel: false,
          created_at: '2019-11-15T15:29:05.180282Z',
          created_by: 'devops@swagup.com.sandbox4',
          modified_on: '2020-09-21T17:54:03.424311Z',
          modified_by: 'yans@swagup.com',
          theme_color: 'black',
          theme_color_hex: '111111',
          primary_color_requirement: null,
          secondary_color_requirement: null,
          enabled: true,
          can_ship_international: true,
          decorations: [
            {
              Colors: ['Blue'],
              Notes: '',
              Dimensions: '',
              Location: 'On one side of barrel',
              Imprint_Type: 'Full Color'
            }
          ]
        },
        units_per_pack: 1
      },
      {
        product: {
          id: 595,
          description:
            'Showcase your brand with this fully customizable mailer box! \r\n\r\nItem Details: \r\n- Includes outside print \r\n- 12.75" x 8.75" x 3.75"',
          name: 'Custom Mailer Box - 12.75" x 8.75" x 3.50"Milky',
          image:
            'https://swagup-static.swagup.com/platform-staging/media/accounts/account_167/account-products/a0A2a000002blccEAA.png',
          price: '8.05',
          sort_order: 1,
          record_type: 'Product',
          status: 'Approved',
          is_apparel: false,
          created_at: '2019-11-15T15:28:40.627387Z',
          created_by: 'devops@swagup.com.sandbox4',
          modified_on: '2020-09-21T17:54:03.192603Z',
          modified_by: 'yans@swagup.com',
          theme_color: 'Full Color',
          theme_color_hex: null,
          primary_color_requirement: null,
          secondary_color_requirement: null,
          enabled: true,
          can_ship_international: true,
          decorations: [
            {
              Colors: ['yellow'],
              Notes: '',
              Dimensions: '43534',
              Location: '',
              Imprint_Type: 'Full Color'
            }
          ]
        },
        units_per_pack: 1
      },
      {
        product: {
          id: 598,
          description:
            'This Soft Touch Journal features 192 pages of acid-free lined paper and an expandable inner pocket on its back cover. \r\n\r\nItem Details: \r\n- Italian Thermal PU Leather\r\n- 5 1/2" x 8 1/2"',
          name: 'Soft Touch Journal - Posh',
          image:
            'https://swagup-static.swagup.com/platform-staging/media/accounts/account_167/account-products/a0A2a000002blcbEAA.png',
          price: '9.89',
          sort_order: 1,
          record_type: 'Product',
          status: 'Approved',
          is_apparel: false,
          created_at: '2019-11-15T15:35:13.196454Z',
          created_by: 'devops@swagup.com.sandbox4',
          modified_on: '2020-09-21T17:54:03.404218Z',
          modified_by: 'yans@swagup.com',
          theme_color: 'Turquoise',
          theme_color_hex: '0077A0',
          primary_color_requirement: null,
          secondary_color_requirement: null,
          enabled: true,
          can_ship_international: true,
          decorations: [
            {
              Colors: ['Yellow'],
              Notes: '',
              Dimensions: '',
              Location: '',
              Imprint_Type: 'Screen Print'
            }
          ]
        },
        units_per_pack: 1
      },
      {
        product: {
          id: 593,
          description:
            '16pt card stock with smooth, rubberized coating. Front and back . full color with no additional charge. Can have a variety of custom messages printed on the backside. \r\n\r\nPerfect for welcome messages or special offers.',
          name: 'Insert Cards by Moo',
          image: 'https://swagup-static.swagup.com/platform-staging/media/accounts/account_167/account-products/a0A2a0',
          price: '1.09',
          sort_order: 1,
          record_type: 'Product',
          status: 'Approved',
          is_apparel: false,
          created_at: '2019-11-15T15:28:40.493207Z',
          created_by: 'devops@swagup.com.sandbox4',
          modified_on: '2020-09-21T17:54:03.191389Z',
          modified_by: 'yans@swagup.com',
          theme_color: 'Full Color',
          theme_color_hex: null,
          primary_color_requirement: null,
          secondary_color_requirement: null,
          enabled: true,
          can_ship_international: true,
          decorations: [
            {
              Colors: ['Green'],
              Notes: '',
              Dimensions: '',
              Location: 'Full Bleed',
              Imprint_Type: 'Full Color'
            }
          ]
        },
        units_per_pack: 1
      },
      {
        product: {
          id: 601,
          description:
            "You won't want to take off this best-selling, unisex, triblend, t-shirt that features a uniquely soft triblend fabrication, modern fit, and short sleeves.\r\n\r\nItem Details:\r\n- 4.2 oz., 50/25/25 polyester/combed ringspun cotton/rayon \r\n- Size XS-3XL",
          name: 'Triblend Unisex Tee - Next Level.',
          image: 'https://swagup-static.swagup.com/platform-staging/media/accounts/account_167/account-products/a0A2a0',
          price: '8.92',
          sort_order: 1,
          record_type: 'Product',
          status: 'Approved',
          is_apparel: true,
          created_at: '2019-11-15T16:37:34.229469Z',
          created_by: 'devops@swagup.com.sandbox4',
          modified_on: '2020-09-24T23:56:47.425073Z',
          modified_by: 'alessandro@swagup.com',
          theme_color: 'Envy',
          theme_color_hex: '10BB78',
          primary_color_requirement: null,
          secondary_color_requirement: null,
          enabled: true,
          can_ship_international: true,
          decorations: [
            {
              Colors: [],
              Notes: '',
              Dimensions: 'ert',
              Location: 'er',
              Imprint_Type: 'Embroidery'
            },
            {
              Colors: ['fghf', 'Blue'],
              Notes: '',
              Dimensions: '3x3',
              Location: 'Front',
              Imprint_Type: 'Embroidery'
            }
          ]
        },
        units_per_pack: 1
      }
    ],
    is_apparel: true,
    minimum_to_reorder: 1,
    standard_turnaround_time: 10,
    rush_turnaround_time: 7,
    name: 'SwagUp Test1 -- Pack 11/15/2019',
    visible_in_inventory: true,
    enabled: true,
    storage_category: 1,
    can_ship_international: true,
    decorations: null,
    product_type: 1
  },
  sizes: [
    {
      size: {
        id: 1,
        name: 'XS',
        category: 'Apparel',
        modified_on: '2019-12-11T14:30:21.916348Z',
        sort_order: 2
      },
      quantity: 0
    },
    {
      size: {
        id: 2,
        name: 'S',
        category: 'Apparel',
        modified_on: '2019-12-11T14:30:21.921418Z',
        sort_order: 3
      },
      quantity: 0
    },
    {
      size: {
        id: 3,
        name: 'M',
        category: 'Apparel',
        modified_on: '2019-12-11T14:30:21.924795Z',
        sort_order: 4
      },
      quantity: 0
    },
    {
      size: {
        id: 4,
        name: 'L',
        category: 'Apparel',
        modified_on: '2019-12-11T14:30:21.927945Z',
        sort_order: 5
      },
      quantity: 0
    },
    {
      size: {
        id: 5,
        name: 'XL',
        category: 'Apparel',
        modified_on: '2019-12-11T14:30:21.931354Z',
        sort_order: 6
      },
      quantity: 0
    },
    {
      size: {
        id: 6,
        name: '2XL',
        category: 'Apparel',
        modified_on: '2020-07-27T16:22:09.262937Z',
        sort_order: 7
      },
      quantity: 0
    },
    {
      size: {
        id: 7,
        name: '3XL',
        category: 'Apparel',
        modified_on: '2020-07-27T16:23:17.179571Z',
        sort_order: 8
      },
      quantity: 0
    }
  ],
  production_time: 'Standard',
  price: 0,
  total: 0
};

export { account, accountIndia, accountUK } from './accounts';
export { accountOrders } from './accountOrders';
export { accountProducts } from './accountProducts';
export { packs } from './packs';
export { paymentProfiles } from './paymentProfiles';
export { categories } from './categories';
export { cart } from './cart';
export { proof, proofs, inventory } from './proofs';
export { storagePrices, individualPackPrice, storageCategories, shippingPrices } from './shipping';
export { invoice } from './invoices';
export { employees, employeeOrders } from './employees';
export { transactions } from './transactions';
export { countries } from './countries';
export { opportunity, productionPrices } from './opportunity';
export { shipments } from './shipmentGroups';
export { warehouseProofs } from './warehouseProofs';
export { opportunities } from './opportunities';
export { profiles } from './profiles';
export { opportunityProducts } from './opportunityProducts';
export { default as metadata } from './metadata';
export { pendingDesign, successDesign, failedDesign, samplePack } from './aiMockupDesigns';
export { availableFilters } from './availableFilters';
export { contactGroups } from './contactGroups';
