import { scrollBar } from '../../shared/styles/commonStyles';

const styles = {
  container: {
    paddingTop: 53,
    paddingBottom: 42
  },
  mainCenteredGrid: {
    margin: '0 0 0 60px',
    borderBottom: ' 1px solid  #979797'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    paddingBottom: 18,
    color: '#434c5f'
  },
  description: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  balanceTitle: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    paddingBottom: 13,
    color: '#434c5f'
  },
  balanceDescription: {
    fontFamily: 'Roboto',
    fontSize: '36px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f'
  },
  paySectionTitle: {
    fontFamily: 'Roboto',
    fontSize: '40px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    paddingBottom: 30,
    color: '#434c5f'
  },

  line: {
    height: '1px',
    width: 550,
    overflow: 'hidden',
    border: 'solid 1px #979797',
    marginBottom: '30px'
  },
  linkPreviewContainer: {
    backgroundColor: '#FAFAFA',
    border: 'solid 1px #FAFAFA',
    borderRadius: 10
  },
  linkPreviewUrl: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#979797',
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 15,
    paddingRight: 15
  },
  links: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577D4 !important',
    verticalAlign: 'middle',
    cursor: 'over',
    paddingLeft: 0
  },
  payButton: {
    width: '282px',
    height: '56px',
    backgroundColor: '#3577d4',
    '& span': {
      fontFamily: 'Futura',
      fontSize: '16px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal'
    }
  },
  payConfirmTitle: {
    fontFamily: 'Futura',
    fontSize: '40px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#434c5f',
    textAlign: 'center'
  },
  payConfirmDescription: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#434c5f',
    textAlign: 'center'
  },
  Dialog: {
    width: 812,
    maxWidth: 812
  },
  closeButton: {
    marginTop: 20
  },
  modalDialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiDialogContent-root': {
      ...scrollBar
    }
  },
  DialogTitle: { padding: '0px 24px 15px 24px' },
  payNowButton: {
    width: 300,
    margin: 'auto'
  },
  pdfPreview: {
    paddingTop: 20,
    borderTop: 'solid 1px #979797'
  }
};
export default styles;
