import React from 'react';
import { withStyles } from '@material-ui/core';
import NewLoader from '../global/NewLoader';

const withLoader = (WrappedComponent, isSmall, styles, isFullScreen = false) => {
  const WithLoader = props => (
    <NewLoader isSmall={isSmall} isFullScreen={isFullScreen}>
      <WrappedComponent {...props} />
    </NewLoader>
  );

  return withStyles(styles || {})(WithLoader);
};

export default withLoader;
