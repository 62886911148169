import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Grid, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles/OrderSummaryModalTable';
import ImageWithDescription from '../ImageWithDescription';
import { getSortedBySize, joinFields } from '../../../../../helpers/utils';

const EmployeeInfo = ({ classes, firstName, lastName, address }) => (
  <div>
    <div>
      <p className={clsx(classes.employee, classes.name)}>{joinFields([firstName, lastName], ' ')}</p>
    </div>
    <div className={classes.addressContainer}>
      <p className={clsx(classes.employee, classes.address)}>{address}</p>
    </div>
  </div>
);

const ItemRow = ({ classes, product, quantity, size, employee }) => (
  <TableRow>
    <TableCell align="left" className={classes.tableCellEmployeeInfo}>
      <EmployeeInfo
        classes={classes}
        firstName={employee.first_name}
        lastName={employee.last_name}
        address={employee.shipping_address1}
      />
    </TableCell>
    <TableCell className={classes.tableCellPackImgWithInfo}>
      <div>
        <ImageWithDescription
          width={78}
          height={78}
          name={product.name}
          color={product.theme_color}
          extraValue={product.primary_color_requirement && `${product.primary_color_requirement} color print`}
          img={product.image}
          showTrackingUrl={false}
          size="small"
        />
      </div>
    </TableCell>
    <TableCell className={classes.tableCellSize}>
      <p className={classes.normalText}>{size.name}</p>
    </TableCell>
    <TableCell className={classes.tableCellQty}>
      <p className={classes.normalText}>{quantity}</p>
    </TableCell>
  </TableRow>
);

const OrderSummaryModalTableHeader = ({ classes }) => (
  <TableHead>
    <TableRow>
      {[
        { text: 'Shipping Address', key: 'shippingAddress' },
        { text: 'Item', key: 'item' },
        { text: 'Size', key: 'size' },
        { text: 'Quantity', key: 'quantity' }
      ].map(header => (
        <TableCell key={header.key} className={clsx(classes[header.key], classes.header)}>
          {header.text}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const OrderSummaryModalTable = ({ classes, products, employee }) => (
  <Grid container direction="column" alignItems="center" justifyContent="center">
    <Grid item xs={12}>
      <Table>
        <OrderSummaryModalTableHeader classes={classes} />
        <TableBody>
          {getSortedBySize(products).map(({ product, quantity, size }) => (
            <ItemRow
              key={product.id}
              classes={classes}
              product={product}
              quantity={quantity}
              size={size}
              employee={employee}
            />
          ))}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
);

export default withStyles(styles)(OrderSummaryModalTable);
