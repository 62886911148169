import React, { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { isEmpty } from 'lodash';
import { Check } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useQueryParams } from '../../hooks';

const styles = () => ({
  stepNumberIcon: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: ({ reached }) => (reached ? '#3577D4' : '#D9D9D9')
  },
  stepNumberIconIcon: {
    width: '12px',
    height: '12px',
    color: '#FFFFFF',
    marginTop: 4
  },
  stepNumberIconText: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    lineHeight: '12px',
    fontSize: 10
  },
  stepText: {
    color: ({ reached }) => (reached ? '#3577D4' : '#989EA4'),
    fontFamily: 'Inter',
    lineHeight: '16px',
    fontSize: 12
  },
  progressBar: {
    width: '100%',
    borderRadius: '5px'
  },
  progressBarContainer: {
    marginBottom: 12
  },
  stepItem: { paddingLeft: 16 },
  stepsContainer: { paddingTop: 16 }
});

const useStyles = makeStyles(styles);

const StepItem = ({ stepNumber, value, isFirst, reached, overcame, basePath }) => {
  const query = useQueryParams();
  const classes = useStyles({ reached });
  const isLinkable = basePath && reached && value.path;
  const ItemContent = () => (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Grid container alignItems="center" justifyContent="center" className={classes.stepNumberIcon}>
          <Grid item>
            {overcame ? (
              <Check className={classes.stepNumberIconIcon} />
            ) : (
              <span className={classes.stepNumberIconText}>{stepNumber}</span>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Typography variant="body4RegularInter" className={classes.stepText}>
          {value.name || value}
        </Typography>
      </Grid>
    </Grid>
  );
  return (
    <Grid item style={{ paddingLeft: isFirst ? 20 : undefined }}>
      {isLinkable ? (
        <Link to={{ pathname: `${basePath}/${value.path}`, search: query.toString() }} style={{ cursor: `pointer` }}>
          <ItemContent />
        </Link>
      ) : (
        <ItemContent />
      )}
    </Grid>
  );
};

const CustomStepper = ({ currentStep, steps = [], exactProgress, basePath }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (currentStep > 0 && steps.length > 0) {
      const customProgress = isEmpty(exactProgress)
        ? (100 / steps.length) * (currentStep - 1) + currentStep * 3
        : exactProgress[currentStep - 1];
      setProgress(customProgress);
    }
  }, [currentStep, exactProgress, steps]);
  const classes = useStyles();
  return (
    <Box className={classes.customStepper}>
      <Grid container className={classes.progressBarContainer}>
        <LinearProgress
          variant="determinate"
          classes={{
            root: classes.progressBar,
            colorPrimary: classes.linearColorPrimary,
            barColorPrimary: classes.linearBarColorPrimary
          }}
          value={progress}
        />
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between" className={classes.stepsContainer}>
        {steps.map((step, index) => (
          <StepItem
            key={step.name || step}
            stepNumber={index + 1}
            value={step}
            isFirst={!index}
            reached={index < currentStep}
            overcame={index < currentStep - 1 || index === steps.length - 1}
            basePath={basePath}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default CustomStepper;
