import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import { Button } from '@swagup-com/components';
import { isEmpty } from 'lodash';
import { SortBy, FilledSearchField, MultiselectFilter } from '../../../global/Filters';
import { shipmentStatus } from '../../../../apis/constants';

const useTopFiltersStyles = makeStyles(theme => ({
  sortBy: {
    width: 266,
    [theme.breakpoints.down('md')]: {
      width: 222
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  exportBtn: {
    height: '42px',
    top: '-22px',
    right: 10,
    position: 'absolute',
    fontSize: 14,
    padding: 0,
    '&:hover': {
      color: '#255394'
    }
  },
  exportIcon: {
    width: 14,
    height: 14,
    marginRight: 8
  }
}));

const filterByStatusOptions = [
  { value: 'pending_production', text: shipmentStatus.pendingProduction },
  { value: 'scheduled', text: shipmentStatus.scheduled },
  { value: 'on_its_way', text: shipmentStatus.onItsWay },
  { value: 'delivered', text: shipmentStatus.delivered },
  { value: 'return_to_sender', text: shipmentStatus.returnToSender },
  { value: 'cancelled', text: shipmentStatus.cancelled },
  { value: 'invalid_address', text: shipmentStatus.invalidAddress },
  { value: 'failure', text: shipmentStatus.failure }
];

const filterBySourceOptions = [
  { value: 'dashboard', text: 'dashboard', text1: 'SwagUp Platform' },
  { value: 'redeem', text: 'redeem', text1: 'SwagUp Redeem Service' },
  { value: 'storefronts', text: 'storefronts', text1: 'SwagUp Storefronts' },
  { value: 'shopify', text: 'shopify', text1: 'Shopify' },
  { value: 'zapier', text: 'zapier', text1: 'Zapier' },
  { value: 'commsor', text: 'commsor', text1: 'Commsor' },
  { value: 'commsor.v2', text: 'commsor.v2', text1: 'Commsor 2' },
  { value: 'Influitive', text: 'Influitive', text1: 'Influitive' },
  { value: 'Ceridian', text: 'Ceridian', text1: 'Ceridian' },
  { value: 'Dayforce', text: 'Dayforce', text1: 'Dayforce' }
];

const sortByPlacedOptions = [
  { value: 'all', text: 'All' },
  { value: 'week_to_date', text: 'Week to date' },
  { value: 'last_30_days', text: '30 days' },
  { value: 'last_90_days', text: '90 days' }
];

const convertFilterBySourceFromAPI = shipmentSources => {
  if (isEmpty(shipmentSources)) return filterBySourceOptions;

  return filterBySourceOptions.filter(
    fbo =>
      !!shipmentSources
        .filter(shs => shs.shipments > 0)
        .find(
          shs =>
            (fbo.value === 'dashboard' && shs.source === null) ||
            shs.source?.toLowerCase().includes(fbo.value.toLowerCase())
        )
  );
};

const TopFilters = ({
  search,
  statusIn,
  sourceIn,
  placed,
  onChangeSearch,
  onChangeStatus,
  onChangeSource,
  onChangePlaced,
  onExport,
  exportCSVDisabled,
  shipmentSources
}) => {
  const classes = useTopFiltersStyles();
  return (
    <Grid container item spacing={4}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        item
        xs={12}
        style={{ padding: '12px 0', position: 'relative' }}
      >
        <Grid item>
          <Button variant="text" className={classes.exportBtn} onClick={onExport} disabled={exportCSVDisabled}>
            <SaveAltOutlinedIcon className={classes.exportIcon} />
            Export Shipments
          </Button>
        </Grid>
      </Grid>
      <Grid item md sm={6} xs={12}>
        <FilledSearchField
          value={search}
          placeholder="Search by product or recipient name"
          onChange={onChangeSearch}
          fullWidth
          height={58}
        />
      </Grid>
      <Grid item md="auto" sm={6} xs={12}>
        <MultiselectFilter
          values={filterByStatusOptions}
          selected={statusIn}
          onApply={onChangeStatus}
          title="Status:"
          defaultText="All Products"
        />
      </Grid>
      <Grid item md="auto" sm={6} xs={12}>
        <MultiselectFilter
          values={convertFilterBySourceFromAPI(shipmentSources)}
          selected={sourceIn}
          onApply={onChangeSource}
          title="Source:"
          defaultText="All Sources"
        />
      </Grid>
      <Grid item md="auto" sm={6} xs={12}>
        <SortBy
          title="Placed:"
          value={placed}
          onChange={onChangePlaced}
          items={sortByPlacedOptions}
          classes={{ sortBy: classes.sortBy }}
        />
      </Grid>
    </Grid>
  );
};

export { TopFilters, filterByStatusOptions, sortByPlacedOptions, convertFilterBySourceFromAPI };
