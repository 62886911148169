import {
  CART_API_CALL_START,
  CART_API_CALL_SUCCESS,
  CART_API_CALL_ERROR,
  CATALOG_INITIALIZE,
  CATALOG_LOAD,
  CART_ADD_PRODUCT,
  CART_REMOVE_PRODUCT,
  CART_UPDATE_PRODUCT,
  CART_CHANGE_QUANTITY,
  CART_CHANGE_NUMBER_OF_COLORS,
  CART_REFRESH_PRICES,
  CART_CLEAR
} from '../actions/types';

const DEFAULT_QUANTITIES = [25, 50, 100, 150, 250, 500, 1000];

const initialState = {
  items: [],
  pack: null,
  numberOfColors: 1,
  minQuantity: {
    loggedIn: 25,
    loggedOut: 25,
    get: isLoggedIn => (isLoggedIn ? initialState.minQuantity.loggedIn : initialState.minQuantity.loggedOut)
  },
  presetQuantities: DEFAULT_QUANTITIES,
  template: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CART_API_CALL_START:
      return { ...state, loading: true, error: null };
    case CART_API_CALL_SUCCESS:
      return { ...state, loading: false, error: null };
    case CART_API_CALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CATALOG_INITIALIZE:
      return {
        ...state,
        pack: action.payload.isBulk
          ? null
          : {
              prices: DEFAULT_QUANTITIES.reduce((acc, q) => ({ ...acc, [q]: 0 }), {}),
              quantity: 100
            }
      };
    case CATALOG_LOAD: {
      const { catalog, template } = action.payload;

      return template
        ? {
            ...state,
            // * Uncomment the below line of code to change the Minimum Order Quantity in Packs
            // pack: {
            //   ...state.pack,
            //   quantity: template.minimum
            // },
            items: template.items.map(({ item, units_per_pack: quantity }) => ({
              product: catalog.find(c => c.id === item.category).items.find(p => p.id === item.id),
              quantity,
              colorIdx: null,
              prices: state.presetQuantities.reduce((acc, q) => ({ ...acc, [q]: 0 }), {})
            })),
            minQuantity: { ...state.minQuantity, loggedOut: template.minimum },
            template
          }
        : state;
    }
    case CART_ADD_PRODUCT:
      const { payload } = action;
      return {
        ...state,
        items: state.items.concat({
          ...payload,
          prices: payload.prices || state.presetQuantities.reduce((acc, q) => ({ ...acc, [q]: 0 }), {}),
          product: { ...payload.product, quantity: payload.quantity }
        })
      };
    case CART_REMOVE_PRODUCT:
      return {
        ...state,
        items: state.items.filter(i => i.product.id !== action.payload.id)
      };
    case CART_UPDATE_PRODUCT: {
      const { id, product } = action.payload;

      return {
        ...state,
        items: state.items.map(i => (i.product.id === id ? { ...i, ...product } : i))
      };
    }
    case CART_CHANGE_QUANTITY:
      return {
        ...state,
        pack: {
          ...state.pack,
          quantity: action.payload,
          prices: {
            ...state.pack.prices,
            [action.payload]: state.pack.prices[action.payload] || 0
          }
        }
      };
    case CART_CHANGE_NUMBER_OF_COLORS:
      return {
        ...state,
        numberOfColors: action.payload
      };
    case CART_REFRESH_PRICES: {
      const { prices, totals } = action.payload;

      return {
        ...state,
        items: state.items.map(i => ({ ...i, prices: prices[i.product.id] })),
        pack: state.pack && {
          ...state.pack,
          prices: totals
        }
      };
    }
    case CART_CLEAR:
      return {
        ...state,
        items: []
      };
    default:
      return state;
  }
};
