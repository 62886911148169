import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getSchema, maxChar } from '../../global/Forms/commonValidations';
import Loader from '../../global/Loader';
import { TextField } from '../../global/reactHookFormFields';
import { Container, Footer } from '../../layouts/FullscreenStepper';
import apiPaths from '../../../helpers/apiPaths';
import { contactGroupsApi } from '../../../apis/swagup';
import { setCurrentContactGroup } from '../../../actions/shipmentGroupActions';

const styles = {
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    color: '#787B80'
  },
  formContainer: {
    paddingTop: 48
  },
  nameInputLabel: {
    fontFamily: 'Gilroy-Medium',
    color: '#0B1829',
    fontSize: 14,
    marginTop: 0,
    marginBottom: 8
  },
  nameInput: {
    '& .MuiInputBase-root': {
      height: 56,
      minWidth: 400,
      borderRadius: 28
    },
    '& input': {
      '&::placeholder': {
        color: '#787B80',
        fontFamily: 'Gilroy-Regular',
        fontSize: 14,
        lineHeight: 10
      }
    }
  }
};

const useStyles = makeStyles(styles);

const resolver = yupResolver(
  getSchema([], {
    name: maxChar(50, 'Name can’t be more than 50 characters')
      .matches(/^[ A-Za-z0-9_#-]*$/, 'Only letters, numbers and these special characters: - _ # are allowed')
      .required()
  })
);

const NameContactGroup = ({ from, onPreviousStep }) => {
  const history = useHistory();
  const { fileId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { leftBarNavigation } = useFlags();
  const createContactGroup = useMutation(({ name }) => contactGroupsApi.create({ process: fileId, name }), {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(apiPaths.contactGroups);
      if (from.includes(leftBarNavigation ? '/contacts' : '/shipments/contacts'))
        return history.replace({
          pathname: leftBarNavigation ? '/contacts' : '/shipments/contacts',
          search: `groups=${data.id}`,
          state: { infoMessage: 'Contact group saved', recentlyCreated: true }
        });

      dispatch(setCurrentContactGroup({ groupIds: data.id }));
      return history.goBack();
    }
  });

  const handleNextStep = data => createContactGroup.mutate(data);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    resolver,
    reValidateMode: 'onChange',
    mode: 'all'
  });

  return (
    <Container>
      <Grid item style={{ width: '100%' }}>
        <form onSubmit={handleSubmit(handleNextStep)} style={{ height: '100%' }}>
          <Grid container direction="column" style={{ height: '100%' }}>
            <Grid item>
              <Typography variant="h4">What do you want to call this contact group?</Typography>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Naming this group of contacts will make it easier to send swag in bulk!
              </Typography>
            </Grid>
            <Grid item xs className={classes.formContainer}>
              <p className={classes.nameInputLabel}>Name this contact group</p>
              <TextField
                {...register('name')}
                error={errors?.name?.message}
                placeholder="e.g. “HR Team”"
                className={classes.nameInput}
              />
            </Grid>
            <Grid item style={{ marginLeft: -80 }}>
              <Footer
                onContinue={isValid ? () => {} : undefined}
                onPrevious={() => onPreviousStep(fileId)}
                continueButtonType="submit"
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
      {createContactGroup.isLoading && <Loader />}
    </Container>
  );
};

export default NameContactGroup;
