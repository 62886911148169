import { FETCH_DELIVERY_METHODS } from '../actions/types';

const DeliveryMethodReducer = (state = [], action) => {
  // let newState = state.slice();
  switch (action.type) {
    case FETCH_DELIVERY_METHODS:
      return action.payload;
    default:
      return state;
  }
};

export default DeliveryMethodReducer;
