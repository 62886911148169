const initialsComms = {
  fontFamily: 'Gilroy-SemiBold',
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  margin: 0
};

const styles = () => ({
  imgPack: {
    width: '100%',
    objectFit: 'cover'
  },
  imageEmployee: {
    objectFit: 'cover'
  },
  initialsEmployee: {
    borderRadius: '50%',
    background: '#ffffff'
  },
  initials: {
    ...initialsComms,
    fontSize: 12,
    color: '#8d9299'
  },
  initialsSelected: {
    ...initialsComms,
    fontSize: 20,
    color: '#3577d4'
  }
});

export default styles;
