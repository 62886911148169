import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Route, Switch } from 'react-router-dom';
import IntegrationsHomeStable from './IntegrationsHome';
import IntegrationsHomeDev from '../integrationsDev/IntegrationsHome';
import IntegrationFlowHome from '../integrationsDev/integrationFlow/IntegrationFlowHome';

const IntegrationsRouter = () => {
  const { newIntegrationFlow } = useFlags();
  const IntegrationsHome = newIntegrationFlow ? IntegrationsHomeDev : IntegrationsHomeStable;

  return (
    <Switch>
      <Route path={['/integrations/', '/integrations/orders']} exact>
        <IntegrationsHome />
      </Route>
      <Route path={['/integration-details/:id', '/integration-details/orders/:id']} exact>
        <IntegrationFlowHome />
      </Route>
    </Switch>
  );
};

export default IntegrationsRouter;
