export default theme => ({
  header: {
    lineHeight: 1.6,
    marginBottom: 24,
    minHeight: 31,
    fontSize: 21,
    color: '#131415',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  helpIcon: {
    height: 17,
    width: 17,
    color: '#989EA4',
    marginLeft: 7,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      height: 14,
      width: 14
    }
  },
  tooltip: {
    fontSize: 12,
    textAlign: 'justify',
    maxWidth: 450,
    width: 450
  },
  topCatalogHeader: {
    fontFamily: 'Gilroy',
    fontSize: 36,
    fontWeight: 600,
    color: '#131415',
    marginBottom: 22,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 34
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 27,
      marginBottom: 10
    }
  }
});
