import React from 'react';
import { Button } from '@swagup-com/components';
import { Grid, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation, Link } from 'react-router-dom';
import { Modal } from '../../../../shared/Modal';
import { useOrder } from '../OrderContext';
import { buildUrlWithParam } from '../../../../../helpers/utils';

const useStyles = makeStyles(theme => ({
  dialog: { maxWidth: 716 },
  title: {
    fontFamily: 'Gilroy-bold',
    fontSize: 24,
    color: '#0b1829',
    textAlign: 'center',
    marginTop: 20
  },
  subtitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: 1.57,
    color: '#787b80',
    textAlign: 'center',
    marginTop: 10,
    padding: '0px 120px',
    [theme.breakpoints.down('xs')]: { padding: 0 }
  },
  button: {
    width: 202,
    height: 56,
    marginBottom: 12
  }
}));

const InactiveSizesModal = () => {
  const { enableUnallocatedProductsManagementV2 } = useFlags();
  const classes = useStyles();
  const location = useLocation();
  const { productsWithInactiveSizes } = useOrder();
  const orderHasInactiveSizes = productsWithInactiveSizes.length > 0;
  const [open, setOpen] = React.useState(orderHasInactiveSizes);

  if (!orderHasInactiveSizes || enableUnallocatedProductsManagementV2) return null;

  const renderTitle = (
    <>
      <p className={classes.title}>Sizes no longer available</p>
      <p className={classes.subtitle}>
        You have sizes in your order that are currently out of stock. Please update the quantities in your order
        accordingly.
      </p>
    </>
  );

  const urlToOpen = buildUrlWithParam(location, 'productBreakdown', productsWithInactiveSizes[0].id);

  return (
    <Modal title={renderTitle} open={open} onClose={() => setOpen(false)} innerClasses={{ paper: classes.dialog }}>
      <Grid container direction="column" alignItems="center">
        <Button
          variant="primary"
          component={Link}
          replace
          to={urlToOpen}
          onClick={() => setOpen(false)}
          className={classes.button}
        >
          Update Quantities
        </Button>
      </Grid>
    </Modal>
  );
};

export default InactiveSizesModal;
