const storageTypes = Object.freeze({
  swagupWareHouse: {
    title: 'Swagup Warehouse',
    icon: '/images/shipping/swagup-warehouse-border.svg',
    quantityCaption: 'Estimated Credits:',
    description: 'Access your inventory at any time through your dashboard',
    swagupWareHouse: true
  },
  creditForShipping: {
    title: 'Add Credits for future Shipments',
    icon: '/images/shipping/delivery-truck-warehouse-border.svg',
    quantityCaption: 'Estimated Credits:',
    description: 'Set budgets for your shipping costs and make future shipments seamless with credits.',
    isPayAsShip: false
  },
  payAsIShip: {
    title: 'Pay as I ship',
    icon: '/images/shipping/card-border.svg',
    quantityCaption: 'Storage due now:',
    description:
      'Use payment on file for on demand shipping. Pay standard shipping rates every time you ship an item out of the warehouse.',
    isPayAsShip: true
  }
});

export { storageTypes };
