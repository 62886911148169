import React from 'react';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  LinearProgress
} from '@material-ui/core';
import { Button } from '@swagup-com/components';
import gtm from '../../../utils/gtm';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    maxWidth: 1150,
    padding: '32px 80px',
    [theme.breakpoints.up('lg')]: {
      padding: '32px 175px'
    }
  },
  titleRoot: {
    paddingTop: 0,
    textAlign: 'center'
  },
  contentRoot: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'center'
  },
  actionsRoot: {
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  loading: {
    fontSize: 12,
    fontFamily: 'Gilroy-Medium',
    color: '#0b1829'
  },
  progressRoot: {
    width: 200,
    height: 3,
    borderRadius: 2,
    marginTop: 12,
    backgroundColor: '#f0f2f5'
  },
  continueBtn: {
    width: 135,
    height: 56,
    marginTop: 25,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: 15,
      right: 40,
      marginTop: 0
    }
  }
}));

const ProgressModal = ({ open, progress, onContinue }) => {
  const { paper, ...classes } = useStyles();

  return (
    <Dialog open={open} classes={{ paper }}>
      <DialogTitle className={classes.titleRoot} disableTypography>
        <Typography variant="h5">How to fix errors</Typography>
        <Typography variant="subtitle1">
          Take a look below for a quick explanation of how to use our column matching tool
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <video width="800" autoPlay loop muted inline>
          <source src="/images/addContact/review-contacts.mp4" type="video/mp4" />
        </video>
      </DialogContent>
      <DialogActions className={classes.actionsRoot}>
        <p className={classes.loading}>{progress === 100 ? 'Complete' : 'Loading CSV'}</p>
        <LinearProgress variant="determinate" classes={{ root: classes.progressRoot }} value={progress} />
        <Button
          variant="primary"
          size="small"
          className={classes.continueBtn}
          onClick={() => {
            onContinue();
            gtm.onClickContinue('Continue to tour column matching tool');
          }}
          disabled={progress !== 100}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProgressModal;
