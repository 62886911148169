import * as React from 'react';
import { useSelector } from 'react-redux';
import { sumByProperty } from '../../../../../helpers/utils';

const useProductsByRecipients = () => {
  const { directoryOrders, products } = useSelector(state => state.shipmentGroup);

  return React.useMemo(() => {
    const images = products.reduce((acc, prod) => ({ ...acc, [prod.id]: prod.product.image }), {});

    return directoryOrders.reduce((acc, dOrder) => {
      let totalQty = 0;
      const doProducts = dOrder.proofs.map(proof => {
        const quantity = sumByProperty(proof.sizes, 'quantity');
        totalQty += quantity;
        const image = images[proof.proof];
        return { proof: proof.proof, quantity, image };
      });

      const deliveryMethod = dOrder.deliveryMethods?.find(dm => dm.selected)?.name ?? '';
      acc[dOrder.directory] = { proofs: doProducts, total: totalQty, deliveryMethod };
      return acc;
    }, {});
  }, [directoryOrders, products]);
};

export default useProductsByRecipients;
