const styles = () => ({
  headerText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    textAlign: 'center',
    color: '#787B80',
    paddingTop: 24
  },
  imgContainer: {
    width: 100,
    height: 116,
    display: 'block',
    margin: 'auto',
    objectFit: 'scale-down'
  }
});

export default styles;
