import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ProductOnboardingV2 from './ProductOnboardingV2';
import ProductOnboarding from './ProductOnboarding';

const ProofDetailsVersionSwitcher = () => {
  const { conciergeCheckout } = useFlags();
  return conciergeCheckout ? <ProductOnboardingV2 /> : <ProductOnboarding />;
};

export default ProofDetailsVersionSwitcher;
