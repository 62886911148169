import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';
import isEmpty from 'lodash/isEmpty';

import { sumByQuantity, getSelected, totalShippingPrice } from '../../helpers/utils';

const getRemainingQuantity = (prodId, shippingElements, items) => {
  const totalProduct = sumBy(items, item => (prodId === item.id ? sumByQuantity(item.sizes) : 0));
  const toShipProducts = sumBy(shippingElements, se => {
    const product = se.products.find(p => p.id === prodId);
    return product ? sumByQuantity(product.sizes) : 0;
  });

  return totalProduct - toShipProducts;
};

const getQuantityToShip = (productId, sizeId, multishipping) =>
  sumBy(multishipping, se => {
    const product = se.products.find(p => p.id === productId);
    return product ? product.sizes.find(size => size.id === sizeId)?.quantity ?? 0 : 0;
  });

const updateProofQuantitiesByDirectory = (proofs, multishipping) => {
  const updatedProofs = [];
  multishipping.forEach(m => {
    m.products.forEach(prod => {
      const foundProof = proofs.find(proof => proof.id === prod.id);
      const alreadyInArray = updatedProofs.find(up => up.id === prod.id);
      const newSizes = [];
      if (foundProof && !alreadyInArray) {
        foundProof.sizes.forEach(size => {
          const foundSize = prod.sizes.find(si => si.id === size.size.id);
          if (foundSize)
            newSizes.push({
              ...size,
              quantity: size.quantity - getQuantityToShip(foundProof.id, size.size.id, multishipping)
            });
        });
        updatedProofs.push({ ...foundProof, sizes: newSizes });
      }
    });
  });
  const result = proofs.filter(pc => !updatedProofs.find(up => up.id === pc.id));

  result.forEach(rslt => updatedProofs.push(rslt));
  return updatedProofs;
};

const sizesWithQuantities = sizes =>
  sizes
    .filter(size => size.quantity > 0)
    .map(({ name }) => name)
    .join(', ');

const calculateShippingPrice = (multishipping, checkCountry = undefined) =>
  sum(
    multishipping
      .filter(m => !isEmpty(m.employee) && (!checkCountry || checkCountry(m.employee.shipping_country)))
      .map(m => {
        if (!m.deliveryMethods) return 0;
        const method = getSelected(m.deliveryMethods);
        return method ? Number(method.price ?? totalShippingPrice(method.prices.breakdown)) : 0;
      })
  );

const calculateTotalShipment = multishipping => calculateShippingPrice(multishipping);

const getWarehouseProducts = (shippingElements, items) =>
  items
    .map(item => {
      const remaining = getRemainingQuantity(item.id, shippingElements, items);
      return remaining > 0
        ? {
            id: item.id,
            image: item.product.image,
            description: item.product.description,
            name: item.product.name,
            color: item.product.theme_color,
            sizes: item.sizes.map(size => ({
              id: size.size.id,
              name: size.size.name,
              quantity: size.quantity - getQuantityToShip(item.id, size.size.id, shippingElements)
            })),
            production_time: item.production_time,
            price: item.product.price,
            quantity: remaining
          }
        : false;
    })
    .filter(Boolean);

export {
  getRemainingQuantity,
  getQuantityToShip,
  updateProofQuantitiesByDirectory,
  sizesWithQuantities,
  calculateTotalShipment,
  getWarehouseProducts
};
