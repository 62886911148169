import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CheckCircleRounded } from '@material-ui/icons';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import dayjs from 'dayjs';
import accountProducts from '../../../apis/swagup/accountProducts';
import { Pagination } from '../../shared';
import { CustomTooltip, SearchField } from '../../products/commonProductsElements';
import styles from './styles/sendSwagConfiguration';
import { StylessButton } from '../../buttons';
import { imageSrcSet, paginationRequestConverter } from '../../../helpers/utils';
import apiPaths from '../../../helpers/apiPaths';
import Img from '../../shared/Img';
import { ellipsisStyles } from '../../shared/styles/commonStyles';
import redeemServicesPaths from '../../../helpers/redeemServicesPaths';
import { useCompany } from '../../../hooks';
import { redeemPages } from '../../../apis/redeemServices';
import storefrontsServicesPaths from '../../../helpers/storefrontsServicesPaths';
import { storefronts } from '../../../apis/storefrontsServices';
import { getPageLink } from '../../storefronts/storeCommon';

const useStyles = makeStyles(styles);

const perPageOptions = [8, 16, 24, 32];

const IntegrationItem = ({
  onNext,
  title,
  description,
  status,
  date,
  store,
  icon,
  alReadyConnected,
  small,
  selected,
  typeColor,
  statusColor,
  product,
  ellipsisStylesStyle,
  long,
  html
}) => {
  const classes = useStyles({ selected, product, alReadyConnected });
  const longSize = long ? 9 : 8;
  return (
    <StylessButton className={classes.integrationSection} onClick={onNext} disabled={alReadyConnected}>
      <Grid container justifyContent="center">
        <Grid item xs={small ? 6 : longSize}>
          <Box className={classes.integrationSelectionItem}>
            <Grid container alignItems="strech" spacing={3}>
              <Grid item>{icon}</Grid>
              <Grid item style={{ paddingLeft: 12, textAlign: 'left', position: 'relative' }}>
                <CustomTooltip
                  title={title}
                  arrow
                  placement="top-start"
                  disableHoverListener={!ellipsisStylesStyle || !title || title.length < 17}
                >
                  <Typography
                    variant="subtitle2SemiBoldInter"
                    className={classes.integrationItemTitle}
                    style={{ marginBottom: product ? 12 : 0, marginTop: 8 }}
                  >
                    {title}
                  </Typography>
                </CustomTooltip>
                <Typography variant="body4RegularInter" className={classes.integrationItemDescription}>
                  Date created: {date}
                </Typography>
              </Grid>
              <Grid item xs />
              <Grid item>
                <Grid container direction="column" style={{ height: '100%' }}>
                  <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs>
                        <Typography variant="body3RegularInter" style={{ color: statusColor }}>
                          {status}
                        </Typography>
                      </Grid>
                      <Grid item style={{ paddingTop: 8 }}>
                        <a href={`${getPageLink(store?.accessSettings)}`} target="_blank" rel="noreferrer">
                          <img
                            alt="Store-Front-Preview"
                            src="/images/storefront/store-front-preview.png"
                            srcSet={imageSrcSet('/images/storefront/store-front-preview.png')}
                            width={16}
                            height={16}
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs />
                  <Grid item>
                    <Typography variant="body3RegularInter" style={{ marginBottom: 4, color: typeColor }}>
                      {description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </StylessButton>
  );
};

const StoreConfigurationProduct = ({ action, onActionUpdate }) => {
  const [search, setSearch] = useState();
  const [total, setTotal] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(12);

  const paginatedFlieds = useMemo(
    () => ({
      nextPage: () => setOffset(prev => (prev < total ? (pageIndex + 1) * limit : prev)),
      pageIndex: offset / limit,
      perPage: limit,
      sizeOptions: perPageOptions,
      previousPage: () => setOffset(prev => (prev > 0 ? (pageIndex - 1) * limit : prev)),
      changeSize: newSize => setLimit(newSize)
    }),
    [limit, pageIndex, total, offset]
  );

  const { nextPage, pageIndex, perPage, sizeOptions, previousPage, changeSize } = paginatedFlieds;

  const { data: company } = useCompany();

  const { data: integrationPagesData, isLoading: integrationProductsLoading } = useQuery(
    [storefrontsServicesPaths.storefronts, search, limit, offset],
    () =>
      company.id &&
      storefronts.listByAccount(
        company.id,
        paginationRequestConverter({
          isActive: true,
          limit,
          offset,
          search
        })
      ),
    {
      enabled: !!action
    }
  );

  useEffect(() => {
    if (integrationPagesData) setTotal(integrationPagesData.count);
  }, [integrationPagesData]);

  const integrationPages = integrationPagesData?.results || [];

  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value);
    }, 750),
    [setSearch]
  );

  const searchByName = value => {
    debouncedSearch(value);
  };

  const selectedStore = action.settings?.store || {};
  // const queryClient = useQueryClient();

  const classes = useStyles();

  return (
    <Box>
      <Typography variant="body3SemiBoldInter" className={classes.actionTitle}>
        Select the shop you want for this automation
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Grid container alignItems="center" style={{ marginBottom: 12 }}>
            <Grid item xs={4}>
              <SearchField
                key="search"
                placeholder="Search By Name"
                onChange={searchByName}
                rounded
                lean
                inverseHover
              />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Pagination
                count={total}
                endText="products"
                perPage={perPage}
                onNext={nextPage}
                pageIndex={pageIndex}
                onPrevious={previousPage}
                sizeOptions={sizeOptions}
                onPerPageChange={changeSize}
                buttonClass={classes.paginationButton}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.integrationProductsWrapper}>
        <div>
          {integrationPages.length ? (
            integrationPages.map(store => (
              <IntegrationItem
                key={store.id}
                onNext={() =>
                  onActionUpdate({
                    ...action,
                    settings: {
                      ...action.settings,
                      store: {
                        store_id: store.id,
                        name: store.generalSettings.storeName,
                        type: store.stopSellingOutOfStock ? 'Standard' : 'Preorder',
                        image: store.seoSettings.featuredImage,
                        urlSlug: store.accessSettings.urlSlug
                      }
                    }
                  })
                }
                title={store.generalSettings.storeName}
                date={dayjs(store.createdDate).format('MM/DD/YYYY')}
                // description={store.stopSellingOutOfStock ? 'Standard' : 'Preorder'}
                typeColor="#434d5c"
                statusColor={store.isActive ? '#4CAF50' : '#434d5c'}
                status={store.isActive ? 'Active' : 'Inactive'}
                store={store}
                html
                icon={
                  <Box className={classes.integrationProductImageWrapper}>
                    <Img
                      src={store.seoSettings.featuredImage || '/images/public/nopic.jpg'}
                      alt={store.generalSettings.storeName}
                      className={classes.integrationImage}
                    />
                  </Box>
                }
                selected={selectedStore?.store_id === store.id}
                ellipsisStylesStyle={ellipsisStyles}
                product
                long
              />
            ))
          ) : (
            <Box>
              {integrationProductsLoading ? (
                <Typography variant="body1MediumInter" className={classes.emptyProductsTitle}>
                  Loading...
                </Typography>
              ) : (
                <>
                  <Typography variant="body1MediumInter" className={classes.emptyProductsTitle}>
                    You don’t have shops to connect yet!
                  </Typography>

                  <Grid container justifyContent="center">
                    <Grid item>
                      <Box className={classes.emptyProductsImageWrapper}>
                        <Img
                          srcSet={imageSrcSet('/images/integrations/empty-products.png')}
                          className={classes.integrationImage}
                          alt="empty-products"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Typography variant="body3RegularInter" className={classes.emptyProductsText}>
                        Go back to the Shops tab to create new shop.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button size="small" component={Link} variant="primary" to="/shops">
                        Go to Shops
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default StoreConfigurationProduct;
