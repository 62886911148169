const styles = theme => ({
  main: {
    overflowX: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('sm')]: { paddingTop: 14, maxWidth: '100%' }
  },
  logInText: {
    fontFamily: 'Gilroy',
    fontWeight: 'bold',
    fontSize: 39,
    textAlign: 'center',
    color: '#0f2440',
    maxWidth: 776,
    lineHeight: 1.33,
    [theme.breakpoints.between('sm', 'md')]: { fontSize: 29 },
    [theme.breakpoints.down('sm')]: { fontSize: 22 },
    [theme.breakpoints.down('xs')]: { fontSize: 16 }
  },
  imageDashboard: {
    width: '100%'
  }
});

export default styles;
