import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CheckCircleRounded } from '@material-ui/icons';
import { debounce } from 'lodash';
import accountProducts from '../../../apis/swagup/accountProducts';
import { Pagination } from '../../shared';
import { CustomTooltip, SearchField } from '../../products/commonProductsElements';
import styles from './styles/sendSwagConfiguration';
import { StylessButton } from '../../buttons';
import { imageSrcSet } from '../../../helpers/utils';
import apiPaths from '../../../helpers/apiPaths';
import Img from '../../shared/Img';
import { ellipsisStyles } from '../../shared/styles/commonStyles';

const useStyles = makeStyles(styles);

const IntegrationItem = ({ title, icon, small, selected, product, ellipsisStylesStyle, long, color }) => {
  const classes = useStyles({ selected, product });
  const longSize = long ? 9 : 8;
  return (
    <Grid container justifyContent="center" className={classes.integrationSection}>
      <Grid item xs={small ? 6 : longSize}>
        <Box className={classes.integrationSelectionItem}>
          <Grid container alignItems="center" spacing={6}>
            <Grid item>{icon}</Grid>
            <Grid item xs={small ? 6 : 8} style={{ textAlign: 'left', position: 'relative' }}>
              <CustomTooltip
                title={title}
                arrow
                placement="top-start"
                disableHoverListener={!ellipsisStylesStyle || !title || title.length < 17}
              >
                <Typography
                  variant="subtitle2SemiBoldInter"
                  className={classes.integrationItemTitle}
                  style={{ marginBottom: product ? 12 : 0 }}
                >
                  {title}
                </Typography>
              </CustomTooltip>

              <Box className={classes.productTag}>{color}</Box>
            </Grid>
            <Grid item style={{ paddingRight: 0, paddingLeft: 0 }}>
              {!product && <ChevronRight style={{ color: '#131415', marginTop: 4 }} />}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const SendSwagConfigurationSize = ({ action, onActionUpdate }) => {
  const selectedProduct = action.settings?.product || {};

  const classes = useStyles();

  return (
    <Box>
      <Typography variant="body3SemiBoldInter" className={classes.actionTitleSmall}>
        Product selected for this automation
      </Typography>
      <Grid container justifyContent="center">
        <IntegrationItem
          key={selectedProduct.id}
          title={selectedProduct.name}
          icon={
            <Box className={classes.integrationProductImageWrapper}>
              <Img
                src={selectedProduct.image || '/images/public/nopic.jpg'}
                alt={selectedProduct.name}
                className={classes.integrationImage}
              />
            </Box>
          }
          color={selectedProduct.color}
          ellipsisStylesStyle={ellipsisStyles}
          product
          long
        />
      </Grid>
      <Box className={classes.integrationProductsWrapper}>
        <Typography variant="body3SemiBoldInter" className={classes.actionSubTitle} style={{ marginTop: 12 }}>
          Now pick a size for the product
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Box className={classes.sizeTable}>
              <Grid container spacing={3}>
                {selectedProduct.stocks?.map(s => (
                  <Grid key={s.id} item xs={4}>
                    <StylessButton
                      style={{ width: '100%' }}
                      onClick={() =>
                        onActionUpdate({
                          ...action,
                          settings: {
                            ...action.settings,
                            product: {
                              ...action.settings.product,
                              size: s.size,
                              sizeName: s.sizeName,
                              quantity: 1
                            }
                          }
                        })
                      }
                    >
                      <Box className={s.size === selectedProduct?.size ? classes.sizeItemSelected : classes.sizeItem}>
                        <FormControlLabel
                          style={{ width: '100%' }}
                          control={
                            <Radio
                              color="primary"
                              checked={s.size === action?.settings?.product?.size}
                              onChange={() =>
                                onActionUpdate({
                                  ...action,
                                  settings: {
                                    ...action.settings,
                                    product: {
                                      ...action.settings.product,
                                      size: s.size,
                                      sizeName: s.sizeName,
                                      quantity: 1
                                    }
                                  }
                                })
                              }
                            />
                          }
                          label={
                            <Typography variant="body4RegularInter" style={{ marginLeft: 8, fontWeight: 'bold' }}>
                              {s.sizeName} <span style={{ fontWeight: 'normal', color: '#4A4F54' }}>{s.quantity}</span>
                            </Typography>
                          }
                        />
                      </Box>
                    </StylessButton>
                  </Grid>
                ))}
              </Grid>
              {/* <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Select</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Inventory Left</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProduct.stocks?.map(s => (
                    <TableRow key={s.id}>
                      <TableCell style={{ textAlign: 'center', padding: 0 }}>
                        <Radio
                          color="primary"
                          checked={s.size === action?.settings?.product?.size}
                          onChange={() =>
                            onActionUpdate({
                              ...action,
                              settings: {
                                ...action.settings,
                                product: {
                                  ...action.settings.product,
                                  size: s.size,
                                  sizeName: s.sizeName,
                                  quantity: 1
                                }
                              }
                            })
                          }
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>{s.size.name}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>{s.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SendSwagConfigurationSize;
