import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogContent, DialogTitle, DialogActions, useMediaQuery, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import CloseButton from '../modals/CloseButton';
import CopyToClipBoardButton from '../buttons/CopyToClipBoardButton';
import { reorderDateWithSlicing, moneyStr } from '../../helpers/utils';
import PdfViewer from '../global/PdfViewer';
import PaymentSection from '../paymentSection/PaymentSection';
import { InvoiceBalance, InvoiceInfo } from './InvoicesSharedComponents';
import { invoiceStatus } from '../../apis/constants';
import { SubmitButton } from '../shared';
import { invoicesApi } from '../../apis/swagup';
import { defaultErrorMsg } from '../../utils/errors';
import styles from './styles/invoicePaymentDialog';

const InvoicePdfPreview = ({ invoice, classes }) => (
  <Grid item container justifyContent="center" alignItems="center" className={classes.pdfPreview}>
    {invoice.invoice_pdf_url ? (
      <PdfViewer width={600} file={{ url: invoice.invoice_pdf_url }} />
    ) : (
      <p className={classes.payConfirmDescription}>PDF not available </p>
    )}
  </Grid>
);

const InvoicePayment = ({ invoice, onPayment, classes }) => {
  const [isValidForm, setIsValidForm] = useState(true);

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Grid item xs={12}>
          <p style={{ paddingTop: '14px', textAlign: 'center' }} className={classes.paySectionTitle}>
            Pay invoice
          </p>
        </Grid>
        <Grid item xs={12} style={{ width: 600 }}>
          <PaymentSection simple handleAfterPayConfirmation={onPayment} onValidityChange={setIsValidForm}>
            <SubmitButton fullWidth variant="primary" formName="paymentSectionForm" disabled={!isValidForm}>
              Pay {moneyStr(invoice.total_plus_tax)}
            </SubmitButton>
          </PaymentSection>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PaymentConfirmation = ({ classes }) => (
  <Grid id="Payment-confirmed" container justifyContent="center">
    <img style={{ width: 140, height: 140, paddingBottom: 25 }} src="/images/account/Card.svg" alt="Confirmed" />
    <Grid item xs={12}>
      <p className={classes.payConfirmTitle}>Payment confirmed!</p>
      <p style={{ paddingTop: 20, paddingBottom: 45 }} className={classes.payConfirmDescription}>
        You’ll be receiving a confirmation <br /> email with your receipt soon.
      </p>
    </Grid>
    <Button variant="primary" component={Link} to="/" style={{ width: 300 }}>
      Back to Dashboard
    </Button>
  </Grid>
);

const InvoiceLink = ({ url, classes }) => (
  <Grid item container xs={12} direction="row" justifyContent="flex-end" alignItems="flex-end">
    <div className={classes.linkPreviewContainer}>
      <p className={classes.linkPreviewUrl}>{`${process.env.REACT_APP_PUBLIC_URL}public-invoice/${url}`}</p>
    </div>
    <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
      <CopyToClipBoardButton textToCopy={`${process.env.REACT_APP_PUBLIC_URL}public-invoice/${url}`} />
    </Grid>
  </Grid>
);

const InvoiceHeader = ({ invoice, shareLink, classes }) => (
  <Grid item container xs={12}>
    <InvoiceInfo title="Invoice" description={invoice.name} classes={classes} />
    <InvoiceBalance
      balance={invoice.total_plus_tax}
      classes={classes}
      title={invoice.status === invoiceStatus.paid ? 'Amount Paid' : 'Balance Due'}
    />
    <InvoiceInfo
      title="Due Date"
      description={reorderDateWithSlicing(invoice.due_date, '/')}
      classes={classes}
      xs={2}
    />
    <Grid item container xs={10}>
      {shareLink && <InvoiceLink url={invoice.shared_pk} classes={classes} />}
    </Grid>
  </Grid>
);

const useStyles = makeStyles(styles);

const dialogInfo = {
  details: 0,
  pay: 1,
  payConfirmation: 2
};

const InvoicePaymentDialog = ({ invoice, isPublicInvoice, open, onPaid, onClose }) => {
  const [currentStep, setCurrentStep] = useState(dialogInfo.details);

  const classes = useStyles();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (open) setCurrentStep(isPublicInvoice ? dialogInfo.pay : dialogInfo.details);
  }, [open, isPublicInvoice]);

  const handlePayment = async () => {
    const response = await invoicesApi.pay(invoice.id);
    if (response.result === 'ok') {
      setCurrentStep(dialogInfo.payConfirmation);
      if (onPaid) {
        onPaid();
      }
      return undefined;
    }
    return response.data?.error_message || defaultErrorMsg(response.data?.status);
  };

  if (!invoice) return null;

  const shareLink = !isPublicInvoice && invoice.status === invoiceStatus.pendingPayment;
  const showPayButton = invoice.status === invoiceStatus.pendingPayment && currentStep === dialogInfo.details;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      className={classes.modalDialog}
      classes={{ paper: classes.Dialog }}
    >
      <DialogTitle id="alert-dialog-title" classes={{ root: classes.DialogTitle }}>
        <CloseButton classes={{ container: classes.closeButton }} onClose={onClose} />
        {currentStep !== dialogInfo.payConfirmation && (
          <InvoiceHeader invoice={invoice} shareLink={shareLink} classes={classes} />
        )}
      </DialogTitle>
      <DialogContent>
        {currentStep === dialogInfo.details && <InvoicePdfPreview invoice={invoice} classes={classes} />}
        {currentStep === dialogInfo.pay && (
          <InvoicePayment invoice={invoice} onPayment={handlePayment} classes={classes} />
        )}
        {currentStep === dialogInfo.payConfirmation && <PaymentConfirmation classes={classes} />}
      </DialogContent>
      <DialogActions style={{ padding: 24 }}>
        {showPayButton && (
          <Button variant="primary" onClick={() => setCurrentStep(dialogInfo.pay)} className={classes.payNowButton}>
            Pay now
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default InvoicePaymentDialog;
