import { withStyles, Chip } from '@material-ui/core';
import { productStatus } from '../../apis/constants';
import handleImg from '../../helpers/handleImgHelper';
import { PRODUCT_STATUS_OPTIONS } from '../../utils/constants';

const proofStatusStyles = {
  [productStatus.pendingMockup]: { color: '#fe8e26', backgroundColor: '#fff1e5' },
  [productStatus.proofChangesRequested]: { color: '#fe8e26', backgroundColor: '#fff1e5' },
  [productStatus.inProgress]: { color: '#45af5f', backgroundColor: '#e9f5ec' },
  [productStatus.designReady]: { color: '#45af5f', backgroundColor: '#e9f5ec' },
  [productStatus.productionRequest]: { color: '#45af5f', backgroundColor: '#e9f5ec' },
  [productStatus.productionReady]: { color: '#45af5f', backgroundColor: '#e9f5ec' },
  [productStatus.approved]: { color: '#3577d4', backgroundColor: '#ebf1fb' },
  [productStatus.proofInactive]: { color: '#8d9299', backgroundColor: '#ebeef2' }
};

const proofStatusStylesNew = {
  [productStatus.mockupDesign]: { color: '#ef6c00', backgroundColor: '#fff1e5' },
  [productStatus.mockupReview]: { color: '#125cff', backgroundColor: '#125cff1a' },
  [productStatus.proofDesign]: { color: '#ef6c00', backgroundColor: '#ffe0b2' },
  [productStatus.proofReview]: { color: '#9846dd', backgroundColor: '#eadaf8' },
  [productStatus.readyToOrder]: { color: '#4caf50', backgroundColor: '#e8f5e9' }
};

const itemStatusStyles = {
  [productStatus.pendingMockup]: { color: '#9846dd', backgroundColor: '#fbf7ff' },
  [productStatus.inProgress]: { color: '#9846dd', backgroundColor: '#fbf7ff' },
  [productStatus.changesRequested]: { color: '#787b80', backgroundColor: '#ebedf0' },
  [productStatus.designReady]: { color: '#3577d4', backgroundColor: '#f4f8ff' },
  [productStatus.productionRequest]: { color: '#787b80', backgroundColor: '#ebedf0' },
  [productStatus.productionReady]: { color: '#3577d4', backgroundColor: '#f4f8ff' },
  [productStatus.approved]: { color: '#45af5f', backgroundColor: '#f3fcf5' }
};

const textOnHover = {
  [productStatus.inProgress]: 'We received your request, expect designs soon',
  [productStatus.pendingMockup]: 'We received your request, expect designs soon',
  [productStatus.designReady]: 'Your designs are ready to be reviewed',
  [productStatus.pendingReview]: 'Your designs are ready to be reviewed',
  [productStatus.changesRequested]: 'We are making revisions based on your feedback',
  [productStatus.productionRequest]: 'Our designers are taking one last look to make sure everything checks out!',
  [productStatus.productionReady]: 'We need your final approval before going into production',
  [productStatus.approved]: 'You have approved this design'
};

const textOnHoverNew = {
  [productStatus.mockupDesign]: 'We are creating a product mockup based on your feedback, expect designs soon',
  [PRODUCT_STATUS_OPTIONS[productStatus.mockupDesign]]:
    'We are creating a product mockup based on your feedback, expect designs soon',
  [productStatus.mockupReview]: 'The mockup of the product is ready to be reviewed',
  [PRODUCT_STATUS_OPTIONS[productStatus.mockupReview]]: 'The mockup of the product is ready to be reviewed',
  [productStatus.proofDesign]: 'We are creating the proof file and decoration details based on the approved mockup',
  [PRODUCT_STATUS_OPTIONS[productStatus.proofDesign]]:
    'We are creating the proof file and decoration details based on the approved mockup',
  [productStatus.proofReview]:
    'We need your final approval of the proof files and decoration details before this product is ready to be ordered',
  [PRODUCT_STATUS_OPTIONS[productStatus.proofReview]]:
    'We need your final approval of the proof files and decoration details before this product is ready to be ordered',
  [productStatus.readyToOrder]: 'The product is approved and ready to be ordered',
  [PRODUCT_STATUS_OPTIONS[productStatus.readyToOrder]]: 'The product is approved and ready to be ordered'
};

const getItemStatusText = status =>
  ({
    [productStatus.pendingMockup]: 'In Progress',
    [productStatus.inProgress]: 'In Progress',
    [productStatus.designReady]: 'Pending Review',
    [productStatus.changesRequested]: 'Changes Requested',
    [productStatus.productionRequest]: 'Finalizing Proofs',
    [productStatus.productionReady]: 'Proofs Ready',
    [productStatus.approved]: 'Approved'
  }[status]);

const StatusChip = withStyles({
  root: ({ status }) => ({
    padding: '9px 13px',
    borderRadius: 14,
    textAlign: 'center',
    color: status?.color || '#8d9299',
    backgroundColor: status?.backgroundColor || '#ebeef2'
  }),
  label: {
    fontSize: 12,
    padding: 0,
    fontFamily: 'Gilroy-SemiBold'
  }
})(Chip);

const viewDetails = 'View Details';

const getButtonText = status =>
  ({
    [productStatus.pendingMockup]: viewDetails,
    [productStatus.inProgress]: viewDetails,
    [productStatus.designReady]: 'Review Mockups',
    [productStatus.changesRequested]: viewDetails,
    [productStatus.productionRequest]: 'View Mockups',
    [productStatus.productionReady]: 'Review Proofs',
    [productStatus.approved]: 'View Proofs',
    [productStatus.inactive]: viewDetails
  }[status]);

const productImageBasedOnStatus = (product, height, width) =>
  product.status === productStatus.pendingMockup || product.status === productStatus.inProgress
    ? '/images/proofs/mockup.png'
    : handleImg(product.image, width, height);

export {
  proofStatusStyles,
  itemStatusStyles,
  getButtonText,
  StatusChip,
  productImageBasedOnStatus,
  textOnHover,
  textOnHoverNew,
  getItemStatusText,
  proofStatusStylesNew
};
