const quantityText = {
  marginTop: 12,
  fontFamily: 'Gilroy-Medium',
  fontSize: 16,
  fontWeight: 500
};

export default {
  paper: {
    width: 792,
    padding: '32px 50px'
  },
  button: {
    padding: '6px 0',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 'normal',
    lineHeight: '10px'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 24,
    color: '#0b1829'
  },
  warehouseProduct: ({ showWarning }) => ({
    borderRadius: 10,
    border: `solid 1px ${showWarning ? '#fa902d' : '#f0f2f5'}`,
    marginTop: 25,
    padding: '19px 24px'
  }),
  quantitiesList: { marginTop: 24 },
  quantityContainer: {
    textAlign: 'center',
    marginRight: 10,
    width: 50
  },
  quantity: {
    ...quantityText,
    color: '#0b1829'
  },
  quantityWarning: {
    ...quantityText,
    color: '#fa902d'
  },
  greyText: {
    fontFamily: 'Gilroy-Medium',
    fontWeight: 500,
    color: '#787b80'
  },
  productWarehouseInfo: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    color: '#0b1829'
  },
  productWarehouseTotal: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    color: '#0b1829'
  },
  summaryInfo: {
    marginTop: 8,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 600,
    color: '#0b1829'
  },
  infoIcon: {
    marginRight: 6,
    fontSize: 10
  }
};
