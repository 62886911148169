import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import styles from './styles/onboarding';

const PrettoSlider = withStyles(theme => ({
  root: {
    color: '#ba68ff',
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    zIndex: 4,
    backgroundColor: '#fff',
    border: '0px solid #9846dd',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit'
    }
  },
  markLabelActive: {
    fontSize: 16,
    marginTop: 24,
    fontFamily: 'Gilroy',
    color: '#9846dd !important',
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Gilroy-Light',
      fontSize: 14
    }
  },
  markLabel: {
    fontSize: 16,
    marginTop: 24,
    fontFamily: 'Gilroy',
    color: '#8d9299',
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Gilroy-Light',
      fontSize: 14
    }
  },
  marked: {
    color: 'transparent'
  },
  active: {
    color: 'transparent'
  },
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  mark: {
    fontSize: 32
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 6,
    backgroundColor: '#9846dd',
    opacity: 0.05,
    borderRadius: 3
  }
}))(Slider);

class OnboardingSliderSelector extends Component {
  state = { size: 104, change: true, inverse: false };

  componentDidMount() {
    const { marks } = this.props;
    this.handleChange(undefined, marks?.[0].value);
  }

  valueLabelFormat = value => {
    const { marks } = this.props;
    const mark = marks.find(m => m.value === value);
    return mark ? marks.label : '';
  };

  valuetext = value => {
    const { marks } = this.props;
    const mark = marks.find(m => m.value === value);
    return mark ? marks.label : '';
  };

  ValueLabelComponent = props => {
    const { size, change, inverse } = this.state;
    const { classes } = this.props;
    const animationClass = inverse ? 'slider-thumb-inverse' : 'slider-thumb';
    return (
      <div {...props}>
        <div
          style={{
            height: 0,
            width: size
          }}
        >
          <div
            style={{
              height: size,
              width: size,
              backgroundColor: '#ffffff',
              borderRadius: 4,
              borderBottomLeftRadius: 24,
              borderBottomRightRadius: 24,
              marginTop: -1 * size * 1.103,
              position: 'relative',
              zIndex: -1,
              boxShadow: '0 12px 32px 0 rgba(26, 58, 98, 0.22)'
            }}
            className={classes.sliderMarker}
          >
            <img
              src="/images/onboarding/shirt.svg"
              style={{ objectFit: 'contain', width: '100%', padding: 16 }}
              alt=""
            />
            <div
              style={{
                width: 42,
                height: 32,
                backgroundColor: '#ffffff',
                borderRadius: 26,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                position: 'absolute',
                bottom: -21,
                left: '50%',
                marginLeft: -21,
                padding: 9,
                paddingTop: 4
              }}
            >
              <div
                style={{
                  height: 24,
                  width: 24,
                  backgroundColor: '#fff',
                  border: '4px solid #9846dd',
                  borderRadius: 12
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleChange = (event, value) => {
    const { handleChange, property, marks } = this.props;
    const mark = marks.find(m => m.value === value);
    if (mark) {
      handleChange({ target: { value: mark.id, id: property } });
      this.setState(prevState => ({
        size: 96 + value * 8,
        change: !prevState.change,
        inverse: prevState.size > 96 + value * 8
      }));
    } else handleChange({ target: { value: undefined, id: property } });
  };

  getSelectedMarkValue = (cValue, marks) => {
    const current = marks?.find(m => m.id === cValue);
    return current?.value || 0;
  };

  render() {
    const { marks, classes, currentValue } = this.props;
    return (
      <div className={classes.prettoSliderContainer}>
        <PrettoSlider
          defaultValue={1}
          ThumbComponent={this.ValueLabelComponent}
          valueLabelFormat={this.valueLabelFormat}
          getAriaValueText={this.valuetext}
          aria-labelledby="discrete-slider-restrict"
          step={1}
          track={false}
          valueLabelDisplay="auto"
          marks={marks}
          max={marks[marks.length - 1].value}
          min={1}
          value={this.getSelectedMarkValue(currentValue, marks)}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default withStyles(styles)(OnboardingSliderSelector);
