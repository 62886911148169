import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Step,
  StepConnector,
  StepLabel,
  Stepper
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { Button } from '@swagup-com/components';
import gtm from '../../utils/gtm';
import styles from './styles/SteppersModal';

const DotsConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: '#3577d4'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#3577d4'
    }
  },
  line: {
    borderColor: '#d3dbe5',
    height: 1,
    borderRadius: 1
  }
})(StepConnector);

const FooterDotsConnector = withStyles({
  alternativeLabel: {
    top: 6,
    left: 'calc(-50% + 5px)',
    right: 'calc(50% + 5px)'
  },
  active: {
    '& $line': {
      borderColor: '#d3dbe5'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#d3dbe5'
    }
  },
  line: {
    borderColor: '#d3dbe5',
    height: 1,
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.15)',
    border: 'solid 3px #ffffff',
    backgroundColor: '#d3dbe5'
  },
  active: {
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.15)',
    border: 'solid 3px #3577d4',
    backgroundColor: '#ffffff'
  },
  completed: {
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.15)',
    border: 'solid 3px #ffffff',
    backgroundColor: '#3577d4'
  }
});

const ColorlibStepIcon = ({ active, completed }) => {
  const classes = useColorlibStepIconStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    />
  );
};

const useFooterColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#fff',
    width: 12,
    height: 12,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: '#d3dbe5'
  },
  active: {
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.15)',
    border: 'solid 3px #3577d4',
    backgroundColor: '#ffffff'
  },
  completed: {
    boxShadow: 'none',
    border: 'none',
    backgroundColor: '#d3dbe5'
  }
});

const FooterColorlibStepIcon = ({ active, completed }) => {
  const classes = useFooterColorlibStepIconStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    />
  );
};

const CreateStepper = ({ classes, activeStep, steps, isModal }) => (
  <Stepper activeStep={activeStep} alternativeLabel connector={isModal ? <DotsConnector /> : <FooterDotsConnector />}>
    {steps.map(label => (
      <Step key={label}>
        <StepLabel
          classes={{
            label: isModal ? classes.label : classes.labelFooter,
            completed: isModal ? classes.completed : classes.completedFooter,
            active: classes.active
          }}
          StepIconComponent={isModal ? ColorlibStepIcon : FooterColorlibStepIcon}
        >
          {label}
        </StepLabel>
      </Step>
    ))}
  </Stepper>
);

const footerSteps = ['Domestic shipment', 'International shipment'];
const modalSteps = ['Select an item', 'Select contacts', ...footerSteps, 'Review'];
const SteppersModal = withStyles(styles)(({ classes, open, onClose, activeStep = 2 }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    classes={{ paper: classes.dialog }}
  >
    <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon style={{ width: 18 }} />
      </IconButton>
      <Grid container justifyContent="center" className={classes.title}>
        Your shipment is divided into 2 sections
      </Grid>
      <p className={classes.subTitle}>
        We’ve broken up shipments into domestic and international so that you can select the best method for each
      </p>
    </DialogTitle>
    <DialogContent>
      <div className={classes.root}>
        <CreateStepper classes={classes} activeStep={activeStep} isModal steps={modalSteps} />
        <Grid container className={classes.buttonContainer}>
          <Button
            variant="primary"
            style={{ width: 180, height: 56 }}
            onClick={() => {
              onClose();
              gtm.onClickContinue('Shipment option selection clicked');
            }}
          >
            Continue
          </Button>
        </Grid>
      </div>
    </DialogContent>
  </Dialog>
));

const SteppersFooter = withStyles(styles)(({ classes, step = 0 }) => (
  <div className={classes.stepperFooter}>
    <CreateStepper classes={classes} activeStep={step} steps={footerSteps} />
  </div>
));

export { SteppersModal, SteppersFooter };
