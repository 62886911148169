import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { saveEmployee } from '../../../../../actions';
import Drawer from '../common/Drawer';
import ContactForm from './ContactForm';

const useStyles = makeStyles({
  root: { zIndex: '1000 !important' },
  paper: {
    maxWidth: 594,
    padding: '35px 20px 35px 45px'
  }
});

const EditContact = ({ open, onClose, onSuccess, defaultValues }) => {
  const classes = useStyles();
  const handleSubmit = data => saveEmployee({ ...data, size: data.size || null });

  return (
    <Drawer open={open} onClose={onClose} classes={classes}>
      <ContactForm
        title="Edit recipient"
        submitText="Save Changes"
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        defaultValues={defaultValues}
      />
    </Drawer>
  );
};

export default EditContact;
