import React, { useState } from 'react';
import { Box, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { isNumber } from 'lodash';
import { Check, CheckBoxRounded, CheckCircle } from '@material-ui/icons';
import styles from './styles/addNewCustomersModal';
import { Modal } from '../../shared/Modal';
import { CustomSlimTextField } from '../storeCommon';
import { moneyStr } from '../../../helpers/utils';
import { StylessButton } from '../../buttons';
import useMembershipCheck from '../../../hooks/useMembershipCheck';
import { CustomTooltip } from '../../products/commonProductsElements';
// import { BlueSwitch } from '../../account/AccountSharedComponents';

const useModalStyles = makeStyles(styles);

const starterAmounts = [50, 100, 200];

const AddNewCustomersModal = ({ open, onClose, newCustomersCount, onAdd }) => {
  const [giftAmount, setGiftAmount] = useState(0);
  const [automaticallySendActivationEmail, setAutomaticallySendActivationEmail] = useState(true);

  const membershipCheck = useMembershipCheck();

  const handleInputChange = event => {
    const newValue = event.target.value;
    // Check if the new value is numeric or a valid negative number
    if (/^-?\d*$/.test(newValue)) {
      setGiftAmount(newValue);
    }

    return undefined;
  };

  const handleOnAddNewCustomers = () => {
    onAdd({
      storeCredits: giftAmount,
      automaticallySendActivationEmail
    });
    onClose();
  };

  const classes = useModalStyles();
  return (
    <Modal
      title="Create New Customers"
      open={open}
      onClose={onClose}
      className={classes.modal}
      titleClass={classes.title}
      subtitleClass={classes.subtitle}
      closeButtonClass={classes.closeButton}
      actions={
        <Grid container justifyContent="center" style={{ paddingBottom: 12 }}>
          <Button size="small" variant="primary" onClick={handleOnAddNewCustomers} style={{ width: 256 }}>
            Create New Customers
          </Button>
        </Grid>
      }
    >
      <Grid container justifyContent="center" className={classes.modalContent}>
        <Grid item>
          <Typography variant="body4RegularInter" className={classes.text}>
            {newCustomersCount} contacts selected
          </Typography>
          <Typography variant="h5SemiBoldInter" className={classes.textTitle}>
            Would you like to add funds to their Shop Credit Balance
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body3RegularInter" className={classes.textForm}>
            Select the amount you want to gift
          </Typography>
          <CustomTooltip
            title="You must upgrade to Platinum in order to use this feature"
            disableHoverListener={membershipCheck.shopCreditsAllowed}
          >
            <Grid container spacing={3} alignItems="center">
              {starterAmounts.map(sa => (
                <Grid key={sa} item xs={3}>
                  <StylessButton
                    onClick={() => setGiftAmount(sa)}
                    disabled={!membershipCheck.shopCreditsAllowed}
                    style={{ width: '100%' }}
                  >
                    <Box className={sa === giftAmount ? classes.amountSelectorSelected : classes.amountSelector}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography variant="body4RegularInter" className={classes.amountSelectorText}>
                            {moneyStr(sa)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <CheckCircle />
                        </Grid>
                      </Grid>
                    </Box>
                  </StylessButton>
                </Grid>
              ))}
              <Grid item xs={3}>
                <CustomSlimTextField
                  placeholder="0.00"
                  name="custom-amount"
                  onChange={handleInputChange}
                  fullWidth
                  InputProps={{
                    startAdornment: <span id="start-adornment">$</span>,
                    readOnly: !membershipCheck.shopCreditsAllowed
                  }}
                />
              </Grid>
            </Grid>
          </CustomTooltip>
          {/* <Box style={{ paddingTop: 24 }}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="start"
              control={
                <BlueSwitch
                  checked={automaticallySendActivationEmail}
                  onChange={({ target: { checked } }) => setAutomaticallySendActivationEmail(checked)}
                  value={automaticallySendActivationEmail}
                  color="primary"
                  size="small"
                />
              }
              label={
                <Typography variant="body3RegularInter" className={classes.inputLabel}>
                  Automatically Send Activation Email:
                </Typography>
              }
            />
          </Box> */}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddNewCustomersModal;
