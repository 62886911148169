/// // SETTINGS /////

import {
  featuredProductCatalogSection,
  heroSection,
  heroSectionLandscape,
  heroSectionTwoColumns,
  productCatalogSection
} from './sectionTemplates';

const darkTheme = {
  theme: 'dark',
  backgroundColor: '#161C25',
  fontColor: '#D6D8DB',
  accentColor: '#3577D4',
  fontFamily: 'Inter',
  buttonStyle: 'Rounded',
  footer: 'unset'
};

const lightTheme = {
  theme: 'light',
  backgroundColor: '#FFFFFF',
  fontColor: '#131415',
  accentColor: '#9846DD',
  fontFamily: 'Gilroy',
  buttonStyle: 'Rounded',
  footer: 'unset'
};

const visualSettings = {
  logo: undefined,
  backgroundColor: '#FFFFFF',
  fontColor: '#131415',
  accentColor: '#9846DD',
  fontFamily: 'Gilroy',
  buttonStyle: 'Rounded',
  footer: 'unset',
  sections: [heroSectionTwoColumns, featuredProductCatalogSection, productCatalogSection]
};

const seoSettings = {
  pageIcon: 'string',
  featuredImage: '/images/storefront/storefront-hero.png'
};

const generalSettings = {
  stopSellingOutOfStock: true,
  storeName: 'New Store',
  storeCustomName: '',
  companyName: undefined,
  companyDisplayName: undefined,
  disclaimer: '',
  announcement: '',
  copyright: ''
};

const accessSettings = {
  customDomain: 'string',
  emailRestriction: 'string',
  urlSlug: undefined
};

const shippingSettings = {
  canShipInternationally: true,
  isCustomRate: false,
  additionalMargin: 0,
  shippingUSStandardRate: 0,
  shippingUSSExpressRate: 0,
  shippingInternationalStandardRate: 0,
  shippingInternationalExpressRate: 0
};

const storeTemplate = {
  name: 'Home',
  description: 'This is a New Store',
  accountId: undefined,
  visualSettings,
  seoSettings,
  generalSettings,
  accessSettings,
  shippingSettings,
  hasBeenPublished: false,
  isActive: true,
  productOptions: []
};

// productOptions: [
//     {
//       productId: 0,
//       name: 'string',
//       description: 'string',
//       imageUrl: 'string',
//       colorName: 'string',
//       colorValue: 'string',
//       isApparel: true,
//       inStock: true,
//       items: [
//         {
//           imageUrl: 'string',
//           colorName: 'string',
//           colorValue: 'string',
//           name: 'string',
//           itemId: 0
//         }
//       ],
//       sizes: [
//         {
//           size: 'string',
//           available: true,
//           inStock: true,
//           platformSizeId: 0
//         }
//       ]
//     }
//   ]

export default storeTemplate;
