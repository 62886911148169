import * as React from 'react';
import { useIntercom } from 'react-use-intercom';
import { useOktaAuth, Security } from '@okta/okta-react';
import { toRelativeUrl, OktaAuth } from '@okta/okta-auth-js';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import log from '../../../logger';
import { clearAuthCookies } from '../../../helpers/utils';

const crypto = require('crypto');

const registerUser = user => {
  log.debug('registering user:', user, '..in:');

  if (process.env.REACT_APP_LOG_ROCKET_PROJECT_ID && window.location.hostname !== 'localhost') {
    // eslint-disable-next-line global-require
    const LogRocket = require('logrocket');
    log.debug('--LogRocket');
    LogRocket.identify(user.sub, {
      name: user.name,
      email: user.email
    });
  }

  log.debug('--GTM');
  const tagManagerArgs = {
    dataLayer: {
      event: 'userLogin',
      user_id: user.sub,
      email: user.email,
      given_name: user.given_name,
      family_name: user.family_name
    }
  };

  TagManager.dataLayer(tagManagerArgs);
};

const launchDarklyIdentify = (user, ldClient) => {
  if (!user) {
    ldClient.identify({
      key: 'AnonymousUser',
      anonymous: true
    });
  } else if (user.email !== ldClient.getUser().key) {
    ldClient.identify({
      key: user.email,
      name: user.name,
      email: user.email,
      zoneinfo: user.zoneinfo,
      anonymous: false
    });
  } else {
    log.debug('user', user, 'already identified in Launch Darkly');
    return;
  }

  log.debug('user', user?.email ?? 'anonymous', 'identified in Launch Darkly');
};

const AuthContext = React.createContext();

const AuthOktaProvider = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [user, setUser] = React.useState();
  const isGettingUser = React.useRef(false);

  const ldClient = useLDClient();
  const { demoFeature } = useFlags(); // just for demo/testing purposes

  const login = React.useCallback(
    async url => {
      log.debug('signInWithRedirect url:', url);
      if (url) {
        oktaAuth.setOriginalUri(url);
      }
      await oktaAuth.signInWithRedirect();
    },
    [oktaAuth]
  );

  const { update, shutdown, boot } = useIntercom();

  const logout = React.useCallback(async () => {
    clearAuthCookies();
    shutdown();
    await oktaAuth.signOut({
      postLogoutRedirectUri: process.env.REACT_APP_MAIN_DOMAIN
    });
  }, [oktaAuth, shutdown]);

  React.useEffect(() => {
    const setUserFromOkta = async () => {
      try {
        if (!user && !isGettingUser.current) {
          isGettingUser.current = true;
          log.debug('about to call oktaAuth.getUser()');
          const oktaUser = await oktaAuth.getUser();
          setUser(oktaUser);
          isGettingUser.current = false;
        }
      } catch (e) {
        log.error('exception:', e, 'in AuthOktaProvider getUser(), about to force logout');
        logout();
      }
    };

    if (authState) {
      if (authState.isAuthenticated) {
        setUserFromOkta();
      } else if (user) {
        setUser();
      }
    }
  }, [user, authState, oktaAuth, logout]);

  React.useEffect(() => {
    const mustWaitToIdentify =
      !authState || (authState.isAuthenticated && !user) || (!authState.isAuthenticated && user);

    if (!mustWaitToIdentify) {
      if (user) {
        log.debug('registering', user.email, 'in Intercom');
        const secretKey = process.env.REACT_APP_INTERCOM_APP_SECRET_KEY || '';
        const hmac = crypto.createHmac('sha256', secretKey);
        hmac.update(user.email || '');
        const userHashInHex = hmac.digest('hex');

        update({
          name: user.name,
          email: user.email,
          userHash: userHashInHex,
          hideDefaultLauncher: false
        });
      } else {
        log.debug('rebooting Intercom');
        shutdown();
        boot({ hideDefaultLauncher: false });
      }
      if (ldClient) {
        launchDarklyIdentify(user, ldClient);
      }
    }
  }, [authState, user, ldClient, update, shutdown, boot]);

  React.useEffect(() => {
    if (user) {
      registerUser(user);
    }
  }, [user]);

  const value = React.useMemo(
    () => ({
      login,
      logout,
      isAuthenticated: authState?.isAuthenticated ?? false,
      accessToken: authState?.accessToken,
      isPending: !authState
    }),
    [login, logout, authState]
  );

  log.debug('user:', user, 'flag demoFeature active?:', demoFeature);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECTURI,
  pkce: false,
  devMode: process.env.NODE_ENV !== 'production'
});

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
  oktaAuth.options.redirectUri = `${window.location.origin}${process.env.REACT_APP_OKTA_REDIRECT_PATH}`;

  oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
    sessionStorage.setItem('loadStateFromLocalStorage', true);
    log.debug('token', expiredToken, 'with key', key, 'has expired', window.location);
  });
}

const dummyAuthvalue = {
  isAuthenticated: false,
  isPending: true
};

const AuthDummyProvider = ({ children }) => {
  return <AuthContext.Provider value={dummyAuthvalue}>{children}</AuthContext.Provider>;
};

const AuthProvider = ({ children, isFromDashboard = false }) => {
  const history = useHistory();
  const [canUseOkta, setCanUseOkta] = React.useState(Boolean(isFromDashboard));

  React.useEffect(() => {
    log.debug('AuthProvider mounted', isFromDashboard ? '' : ', about to setCanUseOkta(true)');
    if (!isFromDashboard) setCanUseOkta(true);
  }, [isFromDashboard]);

  // for initial context see:
  // https://github.com/okta/okta-react/issues/60
  // https://github.com/okta/okta-react/commit/153474a55192bbccb980e779852456f3ba7f2f1f
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const basepath = history.createHref({});
    const originalUriWithoutBasepath = originalUri?.replace(basepath, '/');
    const relativeUrl = toRelativeUrl(originalUriWithoutBasepath, window.location.origin);
    log.debug('originalUri:', originalUri, 'basepath:', basepath);
    log.debug('originalUriWithoutBasepath:', originalUriWithoutBasepath, 'going to relativeUrl:', relativeUrl);
    history.replace(relativeUrl);
  };

  log.debug('AuthProvider, isFromDashboard:', isFromDashboard, 'canUseOkta:', canUseOkta);

  if (isFromDashboard || canUseOkta) {
    log.debug('--rendering with Okta');
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <AuthOktaProvider>{children}</AuthOktaProvider>
      </Security>
    );
  }

  // in SSR or hydrate, see https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  log.debug('--rendering with AuthDummyProvider');
  return <AuthDummyProvider>{children}</AuthDummyProvider>;
};

const useAuth = () => React.useContext(AuthContext);

export { AuthContext, useAuth };
export default AuthProvider;
