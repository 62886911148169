import { ellipsisStyles, scrollBarAutohide } from '../../../../../../shared/styles/commonStyles';

const commonText = { fontFamily: 'Inter', fontSize: 12 };
const styles = {
  subTotalItem: { paddingTop: 12, marginTop: 16, borderTop: '1px solid #EBEDF0' },
  backTitle: { ...commonText, color: '#0b1829' },
  currentTitle: { ...commonText, color: '#787b80' },
  separator: { fontSize: 13, marginTop: 2 },
  container: { padding: '32px 0px' },
  grey: { color: '#4A4F54' },
  arrowBack: {
    color: '#787b80',
    fontSize: 16,
    margin: '8px 7px 0px 0px'
  },
  productContainer: {
    borderRadius: 8,
    border: '1px solid #EBEDF0',
    padding: '16px 20px 16px 20px',
    marginBottom: 16,
    position: 'relative'
  },
  productNameText: { ...ellipsisStyles, textAlign: 'center', marginTop: 4, marginBottom: 8 },
  creditPaymentPanel: { border: '1px solid #EBEDF0', borderRadius: 10, padding: '12px 18px' },
  cardImageWrapper: { width: 48, height: 48, position: 'relative' },
  cardImage: { width: '100%', height: '100%', objectFit: 'contain' },
  swagCardCurrentBalanceText: {
    color: '#0B1829',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    marginBottom: 2,
    '& span': {
      fontWeight: 500,
      color: '#45AF5F'
    }
  },
  swagCardCurrentBalanceSubText: {
    color: '#787B80',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px'
  },
  creditCardPaymentPanel: { border: '1px solid #3577D4', borderRadius: 10, padding: '12px 18px' },
  creditCardImageWrapper: { width: 48, height: 48, position: 'relative' },
  creditCardImage: { width: '100%', height: '100%', objectFit: 'contain' },
  creditCardNumber: {
    color: '#0B1829',
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    textTransform: 'lowercase',
    '& span': {
      fontWeight: 500,
      color: '#45AF5F'
    }
  },
  hideButtonBox: { position: 'relative', width: '100%' },
  creditCardNumberSubText: {
    color: '#787B80',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    textTransform: 'lowercase'
  },
  defaultPaymentProfileSection: {
    padding: '16px 1px'
  },
  checkedIcon: {
    marginTop: 4,
    width: 20,
    height: 20,
    color: '#3577D4'
  },
  achPostPaymentanel: { padding: '16px 10px' },
  achImageWrapper: { width: 48, height: 48, position: 'relative' },
  achImage: { width: '100%', height: '100%', objectFit: 'contain' },
  achTitle: {
    color: '#0B1829',
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px',
    marginTop: 24,
    marginBottom: 24
  },
  achSubTitle: {
    color: '#787b80',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: 24
  },
  achInfoHeader: {
    color: '#0B1829',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: 12
  },
  achInfo: {
    color: '#0B1829',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px'
  },
  wireTransferPanel: { padding: '16px 10px' },
  wireTransferTitle: {
    color: '#0B1829',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '20px',
    marginBottom: 2
  },
  wireTransferText: {
    color: '#0B1829',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px'
  },
  itemsPanel: {
    marginTop: 12,
    paddingTop: 16,
    borderTop: '1px solid #EBEDF0',
    width: '100%'
  },
  headerRow: {
    padding: '0 24px'
  },
  productList: {
    ...scrollBarAutohide
  },
  divider: {
    height: 1,
    backgroundColor: '#EBEDF0'
  },
  headerText: {
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 0.8,
    color: '#787b80'
  },
  pricingMessage: {
    fontFamily: 'Inter',
    fontSize: 14,
    letterSpacing: 'normal'
  },
  quantityColumn: {
    textAlign: 'center',
    flexGrow: 0,
    maxWidth: '12.499998%',
    flexBasis: '12.499998%'
  },
  priceColumn: {
    textAlign: 'right',
    flexGrow: 0,
    maxWidth: '8.333333%',
    flexBasis: '8.333333%'
  },
  totalColumn: {
    textAlign: 'end',
    maxWidth: '12.499998%',
    flexBasis: '12.499998%'
  },
  productDescription: {
    maxWidth: 'calc(100% - 70px)',
    marginLeft: 16
  },
  hightlightedText: {
    color: '#0b1829',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.57
  },
  arrowIcon: { width: 20, height: 20, color: '#787b80', marginTop: 1 },
  unhighlightedText: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.57,
    color: '#787b80'
  },
  buttonOpenItem: {
    borderRadius: 4,
    height: '24px !important',
    width: '24px !important',
    border: '1px solid #D6D8DB !important',
    zIndex: 999
  },
  unhighlightedPriceText: {
    fontFamily: 'Inter',
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.57,
    color: '#787b80'
  },
  headerPrice: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.57,
    color: '#0b1829'
  },
  pricePerPack: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    color: '#0b1829',
    textAlign: 'right'
  },
  btnWrapperCTA: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  ccWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 8
  },
  newCreditCard: {
    color: '#3577D4',
    cursor: 'pointer',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '20px'
  },
  inputSwagFund: {
    border: '1px solid #D6D8DB',
    borderRadius: 12,
    padding: '8px 16px'
  },
  swagCardModal: {
    width: '480px',
    padding: 24,
    paddingBottom: 0
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  modalPayBtn: {
    padding: '10px 0px',
    width: 220,
    height: 40
  },
  creditInput: {
    '& input': {
      padding: '6px 0px 4px'
    }
  },
  productName: {
    ...ellipsisStyles,
    maxWidth: 232,
    marginBottom: 4
  },
  modalContainer: {
    maxWidth: '720px'
  },
  newShipmentDetails: {
    color: '#787B80',
    width: 400
  },
  recipientShippingAddress: {
    width: '720px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E5E7E8',
    padding: '18px 0px',
    margin: '0px 24px'
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    width: 250
  },
  totalPayment: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '720px',
    padding: '14px 24px 24px 24px',
    alignItems: 'center'
  },
  paymentContainer: {
    border: '1px solid #EBEDF0',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '18px 0px'
  },
  shipmentBtn: {
    padding: '12px 60px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '32px'
  },
  transparentBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  containerCreditAvailable: {
    borderRadius: 15,
    boxShadow: '0 15px 65px 0 rgba(27, 28, 31, 0.03)',
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    padding: '23px 27px',
    marginBottom: 15
  },
  accountImg: {
    width: 48,
    height: 42
  },
  creditAvailable: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    lineHeight: 1,
    color: '#0b1829'
  },
  textCreditAvailable: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    lineHeight: 1,
    color: '#868a8f',
    marginTop: 8
  }
};

export default styles;
