import React from 'react';
import { withStyles, Typography, useMediaQuery } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Link } from 'react-router-dom';
import styles from './FrontText.styles';
import { CenteredGrid } from '../../../shared';

const FrontText = ({ classes }) => {
  const isAboveXS = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const isBelowSM = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const thingsToKnow = [
    'We offer around 10 high-quality items per category',
    'You may choose multiple items per category',
    'Pricing on here are estimates',
    'By making picks you are not placing an order'
  ];

  return (
    <section id="explore-swag" className={classes.root}>
      {isAboveXS && <img src="/images/custom/assets/artwork.svg" alt="artwork" className={classes.artworkImage} />}
      <CenteredGrid className={classes.container}>
        <Typography className={classes.coverHeader} variant="h2">
          Get the perfect swag - in bulk
        </Typography>
        <Typography className={classes.subTitle} variant="subtitle1">
          Because you can never have too much of a good thing
        </Typography>
        <Typography className={classes.listTitle} variant="subtitle2">
          How it works:
        </Typography>
        <div className={classes.listToKnowContainer}>
          {thingsToKnow.map(item => (
            <Typography className={classes.listToKnow} key={item} variant="body1">
              + {item}
            </Typography>
          ))}
        </div>
        <Button
          variant="primary"
          size={isBelowSM ? 'small' : 'large'}
          data-testid="startButton"
          id="startButton"
          aria-label="Delete"
          component={Link}
          to="/bulk/catalog"
          className={classes.exploreSwagButton}
        >
          EXPLORE SWAG
        </Button>
      </CenteredGrid>
    </section>
  );
};

export default withStyles(styles)(FrontText);
