import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Grid, withStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import toErrorPage from '../../helpers/toErrorPage';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { deleteShippingCard, addShippingTax } from '../../actions';
import OnboardingExperienceLayout from '../global/onboardingExperience/OnboardingExperienceLayout';
import { Container, Footer as StepperFooter } from '../layouts/FullscreenStepper';
import styles from './styles/ShipmentDetailsHome';
import ShipmentDetails from './ShipmentDetails';
import { SteppersModal, SteppersFooter } from '../inventory/SteppersModal';
import EmployeesSelected from './EmployeesSelected';
import { getTaxes, totalShippingPrice, getSelected } from '../../helpers/utils';
import { GoBack } from '../shared';

const Title = ({ classes, shipmentType }) => <span className={classes.title}>{shipmentType} shipment details</span>;

const Footer = ({ classes, onContinue, onPreviousStep, disabledContinue, step }) => (
  <Grid container className={classes.container}>
    <Grid item>
      <GoBack goBackText="Previous step" onClick={onPreviousStep} />
    </Grid>
    <SteppersFooter step={step} />
    <Button
      size="small"
      variant="primary"
      disabled={disabledContinue}
      onClick={onContinue}
      className={classes.continueButton}
    >
      Next
    </Button>
  </Grid>
);

const isDomesticShipping = employee => employee.shipping_country.toLowerCase() === 'us';
const isInternationalShipping = employee => !isDomesticShipping(employee);
const shippingsByType = (shippings, type) => shippings.filter(s => type(s.employee));

const ShipmentDetailsHome = props => {
  const { classes, inventory, multishipping, product, step, process, onNextStep, onPreviousStep } = props;

  const [steppersModalOpen, setSteppersModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [[selectedEmployees], setSelectedEmployees] = useState([new Map()]);
  const [elementId, setElementId] = useState(null);
  const [shipmentType, setShipmentType] = useState(step);
  const [multishippingFiltered, setMultishippingFiltered] = useState(null);
  const [disabledContinue, setDisabledContinue] = useState(false);
  const [{ response, isApiError }, setContainer] = useInfiniteScroll();
  const history = useHistory();

  useEffect(() => {
    if (isEmpty(multishipping)) history.goBack();
  }, [multishipping, history]);

  const handleToggleSelectionEmployee = employee => {
    selectedEmployees.delete(employee.id);
    const item = multishippingFiltered.find(ms => ms.employee.id === employee.id);
    const filtered = shippingsByType(
      multishipping,
      shipmentType === 'Domestic' ? isDomesticShipping : isInternationalShipping
    ).filter(s => s.id !== item.id);
    props.deleteShippingCard(item.id);
    setMultishippingFiltered(filtered);
    if (!isEmpty(filtered)) setElementId(filtered[0].id);
    else setElementId(null);
    setSelectedEmployees([selectedEmployees]);
  };

  const areDomestic = multishipping.some(ms => isDomesticShipping(ms.employee));
  const areInternational = multishipping.some(ms => isInternationalShipping(ms.employee));

  const location = useLocation();

  const handlePreviousStep = () => {
    if (shipmentType === 'Domestic' || !areDomestic)
      history.push({
        pathname: '/send-swag/',
        search: location.state.search,
        state: {
          accountProduct: product,
          selectedEmployees: location.state.selectedEmployees,
          employeesSize: location.state.employeesSize,
          employeesWithErrors: location.state.employeesWithErrors
        }
      });
    else setShipmentType('Domestic');

    setDisabledContinue(false);
  };

  const handleNext = () => {
    if (shipmentType === 'Domestic' && areInternational) setShipmentType('International');
    else history.push('/send-swag-review', { hideDelete: Boolean(step), process });
  };

  useEffect(() => {
    if (isApiError) toErrorPage(response, history);
  }, [isApiError, response, history]);

  useEffect(() => {
    const item = selectedEmployee ? multishipping.find(ms => ms.employee.id === selectedEmployee) : null;
    if (item) {
      setElementId(item.id);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (shipmentType) {
      const filtered = shippingsByType(
        multishipping,
        shipmentType === 'Domestic' ? isDomesticShipping : isInternationalShipping
      );
      setSelectedEmployees([new Map()]);
      setMultishippingFiltered(filtered);
    } else {
      const newShipmentType =
        (shipmentType === 'International' && areDomestic && 'Domestic') ||
        (shipmentType === 'Domestic' && areInternational && 'International');

      if (newShipmentType) setShipmentType(newShipmentType);
    }
  }, [shipmentType]);

  useEffect(() => {
    if (!(isEmpty(multishipping) || step)) {
      setSelectedEmployee([new Map()]);
      if (areInternational && areDomestic) setSteppersModalOpen(true);
      if (areDomestic) setShipmentType('Domestic');
      else if (areInternational) setShipmentType('International');
    }
  }, []);

  useEffect(() => {
    setDisabledContinue(false);
    if (!isEmpty(multishippingFiltered)) {
      multishippingFiltered.forEach(item => {
        selectedEmployees.set(item.employee.id, item.employee);
        const selectedMethod = getSelected(item.deliveryMethods);
        const total = totalShippingPrice(selectedMethod.prices.breakdown);
        props.addShippingTax(item.id, getTaxes(item.employee.shipping_state, total));
      });
      setSelectedEmployees([selectedEmployees]);
      setElementId(multishippingFiltered[0].id);
    }
  }, [multishippingFiltered]);

  useEffect(() => {
    setDisabledContinue(!(areDomestic || areInternational));
  }, [areDomestic, areInternational]);

  const renderShipmentDetails = elementId && (
    <ShipmentDetails
      inventory={inventory}
      onContainerRefChange={setContainer}
      elementId={elementId}
      shippingElements={multishipping}
      shippingsSelected={multishippingFiltered}
      onePackOnly={Boolean(step)}
    />
  );

  const renderSelectedEmployees = (
    <EmployeesSelected
      employees={selectedEmployees}
      onDeselectEmployee={handleToggleSelectionEmployee}
      hideDelete={Boolean(step)}
    />
  );

  return (
    <>
      {step ? (
        <>
          <Grid container style={{ flex: 1 }}>
            <Container direction="column" style={{ maxWidth: 800 }}>
              <Title classes={classes} shipmentType={shipmentType} />
              {renderShipmentDetails}
            </Container>
            <div style={{ maxWidth: 300 }}>{renderSelectedEmployees}</div>
          </Grid>
          <StepperFooter
            onContinue={areInternational && step === 'Domestic' ? () => onNextStep() : handleNext}
            onPrevious={() => onPreviousStep()}
          />
        </>
      ) : (
        <>
          <SteppersModal open={steppersModalOpen} onClose={() => setSteppersModalOpen(false)} />
          <OnboardingExperienceLayout
            renderTitle={<Title classes={classes} shipmentType={shipmentType} />}
            renderList={renderShipmentDetails}
            renderSelectedList={renderSelectedEmployees}
            renderFooter={
              <Footer
                classes={classes}
                onContinue={handleNext}
                onPreviousStep={handlePreviousStep}
                disabledContinue={disabledContinue}
                step={shipmentType === 'Domestic' ? 0 : 1}
              />
            }
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  inventory: state.inventory,
  multishipping: state.multishipping,
  product: state.accountProduct
});

export default connect(mapStateToProps, {
  deleteShippingCard,
  addShippingTax
})(withStyles(styles)(ShipmentDetailsHome));
