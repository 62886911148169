import React from 'react';

const DragAndDropText = ({ classes }) => (
  <div>
    <p className={classes.boldText}>Drag and drop to upload</p>
    <p className={classes.normalText}>
      or <span className={classes.spanText}>choose file</span>
    </p>
  </div>
);
export default DragAndDropText;
