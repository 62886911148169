const styles = {
  title: {
    padding: '40px 120px 0',
    '& > .MuiTypography-root': {
      fontFamily: 'Gilroy-Bold'
    }
  },
  refundSectionIcon: {
    margin: 'auto 20px auto 0'
  },
  goBackButton: {
    color: '#787b80',
    marginRight: 40
  },
  cancelButton: {
    color: ' #f44336'
  }
};

export default styles;
