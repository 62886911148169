import React, { useState, useEffect } from 'react';
import { Alert as MuiAlert } from '@material-ui/lab';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CenteredGrid from './CenteredGrid';

const useStyles = makeStyles(theme => ({
  messageContainer: {
    padding: '0px 16px',
    color: theme.palette.common.black
  },
  root: { width: ({ width }) => width || '100%' }
}));

// direction prop is not being used, but it was extracted from the props spread, because when the <Snackbar/> component
// wrap <Alert /> it pass a wrong value like 'up' or 'down' to direction, and that is forwarded to the <CenteredGrid/>
// component. Then, a warning is released because the proper values are row, column, row-reverse and column-reverse
const Alert = ({
  children,
  severity = 'info',
  fontStyles = {},
  showIcon = false,
  bottomContent,
  width,
  onClose,
  delayTime,
  direction,
  ...props
}) => {
  const { root, messageContainer } = useStyles({ width });
  const [open, setOpen] = useState(true);
  const marginBottom = bottomContent ? 8 : 0;

  useEffect(() => {
    const id = delayTime
      ? setTimeout(() => {
          setOpen(false);
          if (onClose) onClose();
        }, delayTime)
      : null;
    return () => {
      if (id) clearTimeout(id);
    };
  }, [delayTime, onClose]);

  return (
    open && (
      <CenteredGrid container {...props}>
        <MuiAlert
          icon={showIcon}
          severity={severity}
          classes={{ root }}
          action={
            onClose && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                  onClose();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
        >
          <Typography className={messageContainer} variant="subtitle1" style={{ marginBottom, ...fontStyles }}>
            {children}
          </Typography>
          {bottomContent}
        </MuiAlert>
      </CenteredGrid>
    )
  );
};

export default Alert;
