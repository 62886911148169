import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import ShipmentsReviewOld from '../../../../directory/shipmentsReview/ShipmentsReview';
import ShipmentsReviewNew from './sendSwagFlow/ShipmentsReview';

const ShipmentsReviewRouter = ({ preventFurtherActions }) => {
  const { updatedSendSwagFlow } = useFlags();
  const ShipmentsReview = updatedSendSwagFlow ? ShipmentsReviewNew : ShipmentsReviewOld;
  return <ShipmentsReview preventFurtherActions={preventFurtherActions} />;
};

export default ShipmentsReviewRouter;
