import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Typography } from '@swagup-com/components';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useOrder } from '../OrderContext';
import Drawer from '../common/Drawer';
import InformationRow from '../common/InformationRow';
import WarehousePriceTooltip from './WarehousePriceTooltip';
import { moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import useSortedSizes from '../hooks/useSortedSizes';
import useStorageInfo from '../hooks/useStorageInfo';
import styles from './WarehouseOverviewV2.styles';
import InactiveSizesAlert from '../common/InactiveSizesAlert';
import useDiscountsAndRewards from '../../../../../hooks/useDiscountsAndRewards';

const useStyles = makeStyles(styles);

const mapWarehouseProofSizesWithActiveField = (sizes, product) =>
  sizes.map(s => {
    const productSize = product.sizes.find(ps => ps.size.id === s.size);
    return { ...s, active: productSize?.active ?? true };
  });

const WarehouseProductDetails = ({ warehouseProof, hasInactiveSize }) => {
  const classes = useStyles({ showWarning: hasInactiveSize });

  const sortedSizes = useSortedSizes(warehouseProof.sizes);
  const { storagePrices, storageCategoryNames } = useStorageInfo();
  const { products } = useOrder();

  const storageCategory = warehouseProof.proof.product.storage_category;
  const totalPrice = sumByQuantity(warehouseProof.sizes) * storagePrices[storageCategory];
  const categoryBreakdown = {
    name: storageCategoryNames[storageCategory] === 'Pack' ? 'Pack' : `${storageCategoryNames[storageCategory]} item`,
    price: storagePrices[storageCategory],
    quantity: sumByQuantity(warehouseProof.sizes),
    totalPrice
  };

  const productWithInactiveSizes = products.find(product => product.id === warehouseProof.proof.id);
  const allSizes = mapWarehouseProofSizesWithActiveField(sortedSizes, productWithInactiveSizes);

  return (
    <Grid container className={classes.warehouseProduct} role="grid">
      <InformationRow
        product={warehouseProof.proof.product}
        name={warehouseProof.proof.product.name}
        info={
          <Typography variant="body3RegularInter">
            Quantity Stored:{' '}
            <span className={classes.productWarehouseTotal}>{sumByQuantity(warehouseProof.sizes)}</span>
          </Typography>
        }
      >
        <Grid
          item
          xs={3}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ height: '100%', textAlign: 'right' }}
        >
          <Typography
            variant="body3MediumInter"
            alignItems="center"
            flexDirection="column"
            justifyContent="space-between"
            className={classes.priceToolTip}
          >
            Storage Price
            <span>
              <WarehousePriceTooltip storageCategories={[categoryBreakdown]}>
                <InfoOutlined className={classes.infoIcon} />
              </WarehousePriceTooltip>
            </span>
          </Typography>
          <Typography variant="body2SemiBoldInter" style={{ color: '#131415', marginTop: 4 }}>
            {moneyStr(totalPrice)}
          </Typography>
        </Grid>
      </InformationRow>
      <Grid container className={classes.quantitiesList}>
        <Typography variant="body3MediumInter" style={{ color: '#131415', marginBottom: 8 }}>
          Quantity by Size
        </Typography>
        <Grid container>
          {allSizes.map(({ size, quantity, name, active }) => (
            <Grid key={size} item className={classes.quantityContainer}>
              <Typography variant="body4RegularInter" style={{ color: '#787b80' }}>
                {name}
              </Typography>
              <Typography variant="body4RegularInter" className={!active ? classes.quantityWarning : classes.quantity}>
                {quantity}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const getQuantityPerCategory = warehouseProofs =>
  mapValues(
    groupBy(warehouseProofs, wp => wp.proof.product.storage_category),
    group => sumBy(group, wp => sumByQuantity(wp.sizes))
  );

const WarehouseOverview = ({ open, onClose }) => {
  const classes = useStyles();

  const { warehouseProofs, warehouseProofsWithInactiveSizes } = useOrder();
  const { storagePrices, storagePricesBeforeDiscount } = useStorageInfo();

  const proofs = sortBy(
    warehouseProofs.filter(wp => sumByQuantity(wp.sizes) > 0),
    wp => wp.proof.id
  ).map(wp => (
    <WarehouseProductDetails
      key={wp.id}
      warehouseProof={wp}
      hasInactiveSize={warehouseProofsWithInactiveSizes.has(wp.id)}
    />
  ));

  const total = sumBy(warehouseProofs, wp => sumByQuantity(wp.sizes));
  const price = sumBy(
    warehouseProofs,
    wp => sumByQuantity(wp.sizes) * storagePrices[wp.proof.product.storage_category]
  );
  const priceBeforeDiscount = sumBy(
    warehouseProofs,
    wp => sumByQuantity(wp.sizes) * storagePricesBeforeDiscount[wp.proof.product.storage_category]
  );

  const { swagupMembershipPlans } = useFlags();
  const { totalBeforeDiscount } = useDiscountsAndRewards(price || priceBeforeDiscount, 'storage');

  return (
    <Drawer open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <div className={classes.title}>
        <Typography variant="h5SemiBoldInter">Your SwagUp Storage</Typography>
      </div>
      <Grid container alignItems="flex-end" justifyContent="space-between">
        <Grid item xs>
          <Typography variant="body2RegularInter" style={{ color: '#989EA4' }}>
            Total Quantity
          </Typography>
          <Typography variant="h6SemiBoldInter" style={{ marginTop: 8, marginBottom: 8 }}>
            {total}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end" spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2RegularInter" style={{ color: '#989EA4', textAlign: 'right' }}>
                Total Storage Cost
              </Typography>
            </Grid>
            {swagupMembershipPlans && totalBeforeDiscount > 0 && (
              <Grid item style={{ textAlign: 'end' }}>
                <Typography variant="body3SemiBoldInter" style={{ color: '#787B80', textDecoration: 'line-through' }}>
                  {moneyStr(totalBeforeDiscount)}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography variant="h6SemiBoldInter" style={{ marginTop: 8, marginBottom: 8 }}>
                {moneyStr(price)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="body3RegularInter" className={classes.detail}>
        Your swag is automatically stored to SwagUp Warehouse at various pricing.
        <Typography variant="body3UnderlineInter" className={classes.link}>
          <a href="https://www.swagup.com/pricing" target="_blank" rel="noreferrer">
            View Details
          </a>
        </Typography>
      </Typography>
      {warehouseProofsWithInactiveSizes.size > 0 && <InactiveSizesAlert itemText="items" />}
      {proofs}
    </Drawer>
  );
};

export default WarehouseOverview;
