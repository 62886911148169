import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  SwipeableDrawer,
  Typography,
  Divider,
  LinearProgress,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Button, Tooltip } from '@swagup-com/components';
import round from 'lodash/round';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CartContent from './CartContent';
import QuantitySelect from './QuantitySelect';
import { moneyStr } from '../../../helpers/utils';
import { useHeaderBottomTracker } from '../../../hooks';
import { changeQuantity, removeProductFromCart } from '../../../actions/cart';
import styles from './Cart.styles';
import { useAuth } from '../../global/Authentication/AuthProvider';
import gtm from '../../../utils/gtm';
import AppliedMembershipPanel from '../../global/AppliedMembershipPanel';
import StrikeOutText from '../../global/StrikeOutCost';
import useDiscountsAndRewards from '../../../hooks/useDiscountsAndRewards';
import useDiscountPricing from '../../../hooks/useDiscountPricing';

const tooltipMsg = "Total est. doesn't include taxes and shipping fees";

const useStyles = makeStyles(styles);

const Cart = ({ nextStep, fromProducts }) => {
  const [price, setPrice] = React.useState({ perPack: 0, total: 0 });
  const [open, setOpen] = React.useState(false);
  const [quantitySelectOpen, setQuantitySelectOpen] = React.useState(false);
  const { items: cart, pack, minQuantity, presetQuantities, loading } = useSelector(state => state.cart);

  const { isAuthenticated, isPending } = useAuth();
  const isLoggedIn = !isPending && isAuthenticated;
  const currentMinQuantity = minQuantity.get ? minQuantity.get(isLoggedIn) : 25;

  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const top = useHeaderBottomTracker();
  const { getDiscountPrice } = useDiscountPricing();

  React.useEffect(() => {
    const addItemPrice = (sum, i) =>
      round(sum + round(getDiscountPrice(i.prices[pack?.quantity || i.quantity]) * i.quantity, 2), 2);
    const perPack = cart.reduce(addItemPrice, 0);

    if (Number.isFinite(perPack)) {
      const total = round(perPack * (pack?.quantity || 1), 2);
      setPrice({ perPack, total });
    }
  }, [cart, getDiscountPrice, pack]);

  const handleQuantityChange = newQuantity => {
    cart.map(item => {
      const transactionId = item.product.id;
      const itemName = item.product.name;
      const itemPrice = item.product.price;
      const itemQuantityPerPack = item.product.quantity;
      gtm.changeCartQuantity(transactionId, itemName, itemPrice, itemQuantityPerPack, newQuantity);
    });
    setQuantitySelectOpen(false);
    dispatch(changeQuantity(newQuantity));
  };

  const handleProductRemove = product => {
    if (cart.length === 1) setOpen(false);
    dispatch(removeProductFromCart(product));
  };

  const { swagupMembershipPlans } = useFlags();
  const { totalBeforeDiscount } = useDiscountsAndRewards(price.total, 'product');
  return (
    <SwipeableDrawer
      variant={isXs ? 'temporary' : 'persistent'}
      anchor="right"
      open={cart.length > 0 && (!isXs || open)}
      disableSwipeToOpen={cart.length === 0}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.root }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      PaperProps={{ style: { top } }}
    >
      <Typography component="h2" variant="h6" className={classes.header}>
        YOUR {pack ? 'SWAG PACK' : 'SWAG'}
      </Typography>
      {loading ? <LinearProgress className={classes.headerDivider} /> : <Divider className={classes.headerDivider} />}
      {!quantitySelectOpen && (
        <CartContent
          cart={cart}
          pack={pack}
          presetQuantities={presetQuantities}
          minQuantity={currentMinQuantity}
          onProductRemove={handleProductRemove}
        />
      )}
      {pack && (
        <>
          <QuantitySelect
            value={pack.quantity}
            quantities={presetQuantities}
            prices={pack.prices}
            open={quantitySelectOpen}
            minQuantity={currentMinQuantity}
            onToggle={setQuantitySelectOpen}
            onChange={handleQuantityChange}
            className={classes.quantitySelect}
          />
          <div className={classes.price}>
            <Typography className={classes.priceLabel}>Price per pack</Typography>
            <Typography className={classes.priceValue}>{moneyStr(price.perPack)}</Typography>
          </div>
        </>
      )}
      {isAuthenticated && (
        <AppliedMembershipPanel type="product" total={price.total} fullWidth className={classes.price} />
      )}
      <Divider />
      <div className={classes.total}>
        <Typography className={classes.totalLabel}>
          Total estimate
          <Tooltip placement="top-center" arrow title={tooltipMsg} classes={classes}>
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Tooltip>
        </Typography>
        <Typography className={classes.totalValue}>
          {swagupMembershipPlans && <StrikeOutText value={totalBeforeDiscount} />}
          {moneyStr(price.total)}
        </Typography>
      </div>
      {nextStep && (
        <div className={classes.btnContainer}>
          <Button
            variant="primary"
            loading={loading}
            disabled={loading}
            component={Link}
            to={{ pathname: nextStep, state: { fromProducts } }}
            className={classes.btn}
            fullWidth
            onClick={() => setOpen(false)}
          >
            Next: Add project details
          </Button>
        </div>
      )}
      <Typography className={clsx(classes.notice, !nextStep && classes.noticeAlign)}>
        No credit card info required
      </Typography>
    </SwipeableDrawer>
  );
};

export default React.memo(Cart);
