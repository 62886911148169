import { isArray, isEmpty } from 'lodash';

const imageResolver = triggerType => {
  switch (true) {
    case triggerType === 'new_hires':
      return '/images/integrations/new-hires.svg';
    case triggerType === 'birthdays':
      return '/images/integrations/birthdays.svg';
    case triggerType === 'anniversariess':
      return '/images/integrations/anniversaries.svg';
    default:
      return '/images/integrations/anniversaries.svg';
  }
};

const actionImageResolver = actionType => {
  switch (true) {
    case actionType === 'send_swag':
      return '/images/integrations/sendswag.svg';
    case actionType === 'redeem_link':
      return '/images/integrations/redeemlink.svg';
    case actionType === 'shop_credits':
      return '/images/integrations/online-shop.svg';
    default:
      return '/images/integrations/anniversaries.svg';
  }
};

const actionTextResolver = actionType => {
  switch (true) {
    case actionType === 'send_swag':
      return { title: 'Send a product', subtitle: 'Sizes are preset by you' };
    case actionType === 'redeem_link':
      return { title: 'Send a Redeem link', subtitle: 'Recipients pick their sizes' };
    case actionType === 'shop_credits':
      return { title: 'Send Shop Credits', subtitle: 'Recipients can shop in your store' };
    default:
      return { title: 'Send Shop Credits', subtitle: 'Recipients can shop in your store' };
  }
};

const automationsPlaceHolders = [
  {
    id: 1,
    name: 'A new employee is hired',
    type: 'new_hires',
    action: {}
  },
  {
    id: 2,
    name: `It's an employee's birthday`,
    type: 'birthdays',
    action: {}
  },
  {
    id: 3,
    name: `It's an employee's work anniversary`,
    type: 'anniversaries',
    years: [],
    action: {}
  }
];

const getActionNameByType = type => {
  switch (true) {
    case type === 'Send Product':
      return 'Send Swag';
    case type === 'Send Redeem Link':
      return 'Send Redeem Link';
    case type === 'Send Store Credit':
      return 'Send Shop Credits';
    default:
      return 'Custom';
  }
};

const getActionTypeByType = type => {
  switch (true) {
    case type === 'Send Product':
      return 'send_swag';
    case type === 'Send Redeem Link':
      return 'redeem_link';
    case type === 'Send Store Credit':
      return 'shop_credits';
    default:
      return 'custom';
  }
};

const getActionNameByTypeAPI = type => {
  switch (true) {
    case type === 'Send Swag':
      return 'Send Product';
    case type === 'Send Redeem Link':
      return 'Send Redeem Link';
    case type === 'Send Shop Credits':
      return 'Send Store Credit';
    default:
      return 'Custom';
  }
};

const getActionTypeByTypeAPI = type => {
  switch (true) {
    case type === 'send_swag':
      return 'Send Product';
    case type === 'redeem_link':
      return 'Send Redeem Link';
    case type === 'shop_credits':
      return 'Send Store Credit';
    default:
      return 'custom';
  }
};

const getActionSettingsByTriggerAction = triggerAction => {
  switch (true) {
    case triggerAction.type === 'Send Product':
      const product = triggerAction.products[0];
      const size = product?.sizes[0];
      return {
        deliveryMethods: {
          domestic: triggerAction.dm_domestic || 'Express',
          international: triggerAction.dm_international || 'Standard'
        },
        product: {
          account_product: product?.product,
          image: product?.product_image,
          name: product?.product_name,
          color: product?.theme_color || 'Black',
          sizeName: size?.size_name,
          size: size?.size,
          quantity: size?.quantity,
          stocks: product.stocks.map(st => ({
            id: st.id,
            size: st.size,
            sizeName: st.size_name,
            quantity: st.quantity
          }))
        }
      };
    case triggerAction.type === 'Send Redeem Link':
      return {
        redeem_page: {
          id: triggerAction.redeem_page,
          name: triggerAction.page_name || 'TIAA Demo Redeem',
          image:
            triggerAction.page_image ||
            'https://swagup-resource-uploader.s3.amazonaws.com/1708792495923-F6EYRmBWAAEe2TG.jpeg',
          type: triggerAction.page_type || 'Standard URL',
          redeem_page_id: triggerAction.redeem_page,
          showCustomMessage: !!(triggerAction.message || triggerAction.subject),
          customMessage: triggerAction.message,
          customSubject: triggerAction.subject
        }
      };
    case triggerAction.type === 'Send Store Credit':
      return {
        store: {
          store_id: triggerAction.store_id,
          name: triggerAction.page_name || 'Featured Store',
          type: triggerAction.page_type || 'Preorder',
          image:
            triggerAction.page_image ||
            'https://swagup-resource-uploader.s3.amazonaws.com/1706896267280-1706676533551-Screen-Shot-2024-01-30-at-11.48.49-PM.png',
          giftAmount: Number(triggerAction.amount),
          showCustomMessage: !!(triggerAction.message || triggerAction.subject),
          customMessage: triggerAction.message,
          customSubject: triggerAction.subject
        }
      };
    default:
      return {};
  }
};

const triggerConverter = (trigger, placeholder) => {
  if (isArray(trigger)) {
    if (isEmpty(trigger)) {
      return {
        ...placeholder,
        years: [
          {
            value: 365,
            name: `Year ${1}`,
            action: {}
          }
        ]
      };
    }
    return {
      ...placeholder,
      years: trigger.map(t => {
        const triggerAction = t.actions[0];

        if (isEmpty(triggerAction)) return {};

        const value = t.anniversary_days; // parseInt(t.anniversary_days / 365, 10);
        return {
          value,
          name: value % 365 === 0 ? `Year ${parseInt(value / 365)}` : `Day ${value}`,
          store: t.store,
          id: t.id,
          edit: true,
          active: t.active,
          employment_type: t.employment_type,
          action: {
            id: triggerAction.id,
            triger: triggerAction.triger,
            edit: true,
            name: getActionNameByType(triggerAction.type),
            type: getActionTypeByType(triggerAction.type),
            settings: {
              employeeTypes: t.employment_type?.split(','),
              automatic: triggerAction.trigger_automatic,
              timing: triggerAction.trigger_hours_to_trigger,
              ...getActionSettingsByTriggerAction(triggerAction)
            }
          }
        };
      })
    };
  }

  const triggerAction = trigger.actions[0];

  if (isEmpty(triggerAction)) return placeholder;

  return {
    ...placeholder,
    edit: true,
    store: trigger.store,
    id: trigger.id,
    active: trigger.active,
    employment_type: trigger.employment_type,
    action: {
      id: triggerAction.id,
      triger: triggerAction.triger,
      edit: true,
      name: getActionNameByType(triggerAction.type),
      type: getActionTypeByType(triggerAction.type),
      settings: {
        employeeTypes: trigger.employment_type?.split(','),
        automatic: triggerAction.trigger_automatic,
        timing: triggerAction.trigger_hours_to_trigger,
        ...getActionSettingsByTriggerAction(triggerAction)
      }
    }
  };
};

const integrationsConverter = (integrationsResults, integrationName) => {
  const onNewHireApi = integrationsResults?.find(i => i.type === 'New Hire');
  const onNewHirePlaceholder = automationsPlaceHolders.find(ap => ap.type === 'new_hires');
  const onNewHire = isEmpty(onNewHireApi) ? onNewHirePlaceholder : triggerConverter(onNewHireApi, onNewHirePlaceholder);

  const onBirthdayApi = integrationsResults?.find(i => i.type === 'Birthday');
  const onBirthdaysPlaceholder = automationsPlaceHolders.find(ap => ap.type === 'birthdays');
  const onBirthdays = isEmpty(onBirthdayApi)
    ? onBirthdaysPlaceholder
    : triggerConverter(onBirthdayApi, onBirthdaysPlaceholder);

  const onAnniversaryApi = integrationsResults?.filter(i => i.type === 'Employee Anniversary');
  const onAnniversaryPlaceholder = automationsPlaceHolders.find(ap => ap.type === 'anniversaries');
  const onAnniversary = isEmpty(onAnniversaryApi)
    ? onAnniversaryPlaceholder
    : triggerConverter(onAnniversaryApi, onAnniversaryPlaceholder);

  return {
    name: integrationName,
    automations: [onNewHire, onBirthdays, onAnniversary]
  };
};

const getTriggerTypeByTypeAPI = type => {
  switch (true) {
    case type === 'new_hires':
      return 'New Hire';
    case type === 'birthdays':
      return 'Birthday';
    case type === 'anniversaries':
      return 'Employee Anniversary';
    default:
      return 'custom';
  }
};

const anniversariesYearsToDays = value => (value ? value * 365 : 0);

const getTriggerActionByActionSettings = triggerAction => {
  const { settings } = triggerAction;
  switch (true) {
    case triggerAction.type === 'send_swag':
      const { product } = settings;
      return {
        type: getActionTypeByTypeAPI(triggerAction.type),
        dm_domestic: settings.deliveryMethods.domestic,
        dm_international: settings.deliveryMethods.international,
        products: [
          {
            product: product.account_product,
            product_image: product.image,
            product_name: product.name,
            theme_color: product.color,
            sizes: [
              {
                size: product.size,
                quantity: product.quantity
              }
            ]
          }
        ]
      };
    case triggerAction.type === 'redeem_link':
      const redeemPage = settings.redeem_page;
      return {
        type: getActionTypeByTypeAPI(triggerAction.type),
        redeem_page: redeemPage.id || redeemPage.redeem_page_id,
        page_name: redeemPage.name,
        page_image: redeemPage.image,
        page_type: redeemPage.type,
        message: redeemPage.customMessage,
        subject: redeemPage.customSubject,
        url_slug: redeemPage.urlSlug
      };
    case triggerAction.type === 'shop_credits':
      const { store } = settings;
      return {
        type: getActionTypeByTypeAPI(triggerAction.type),
        store_id: store.store_id,
        page_name: store.name,
        page_image: store.image,
        page_type: store.type,
        amount: store.giftAmount,
        message: store.customMessage,
        subject: store.customSubject,
        url_slug: store.urlSlug
      };
    default:
      return {};
  }
};

const apiDataConverter = data => {
  const triggerPostData = {
    type: getTriggerTypeByTypeAPI(data.type),
    store: data.store,
    anniversary_days: data.value, // anniversariesYearsToDays(data.value),
    automatic: data.action?.settings?.automatic,
    hours_to_trigger: data.action?.settings?.timing,
    employment_type: data.action?.settings?.employeeTypes?.join(),
    actions: [
      {
        trigger: data.edit ? data.id : undefined,
        automatic: data.action?.settings?.automatic,
        hours_to_trigger: data.action?.settings?.timing,
        ...getTriggerActionByActionSettings(data.action)
      }
    ]
  };

  return triggerPostData;
};

const apiDataConverterCreate = data => {
  const triggerPostData = {
    type: getTriggerTypeByTypeAPI(data.type),
    store: data.store,
    anniversary_days: data.value, // anniversariesYearsToDays(data.value),
    automatic: data.action?.settings?.automatic,
    hours_to_trigger: data.action?.settings?.timing,
    employment_type: data.action?.settings?.employeeTypes?.join(),
    actions: [
      {
        trigger: data.edit ? data.id : undefined,
        ...getTriggerActionByActionSettings(data.action)
      }
    ]
  };

  return triggerPostData;
};

const orderItem = {
  id: 1980,
  name: 'OQ000001980',
  trigger: 'New Hire',
  approved: true,
  action_type: 'Send Product',
  int_order: {
    id: 23,
    platform_name: 'Ceridian Dayforce',
    order_number: '10502463',
    address1: '2216 derrick dr',
    address2: null,
    city: 'greyson',
    country_code: 'US',
    first_name: 'Javon',
    last_name: 'Coler',
    postal_code: '30017',
    region: 'GA',
    phone: '+14048259893',
    rutter_id: '6e7e5451-52d8-4f5b-8d8a-1848480c71d5',
    external_platform_id: '10502463',
    swagup_order: null,
    error_message: 'Need more inventory',
    total_price: '0.00',
    payment_status: 'paid',
    line_items: [
      {
        rutter_id: '29852',
        quantity: 1,
        size: 9,
        created_at: '2024-04-16T02:06:02.714760Z',
        id: 22,
        swagup_product: 29852,
        variant_name: 'Pack 09/04/2022',
        variant_image: '',
        variant_sku: '29852_9',
        variant_price: '3.06',
        product_name: 'Pack 09/04/2022',
        external_platform_id: '29852',
        fulfillment_order_line_item_id: null
      }
    ],
    created_at: '2024-04-16T02:06:00.834898Z',
    created_by: null,
    status: 'fulfilled',
    billing_address1: '2216 derrick dr',
    billing_address2: null,
    billing_city: 'greyson',
    billing_country_code: 'US',
    billing_first_name: 'Javon',
    billing_last_name: 'Coler',
    billing_postal_code: '30017',
    billing_region: 'GA',
    billing_phone: null,
    customer_email: null,
    shop_name: 'GabyCorp-ceridian dayforce',
    shop_domain: null,
    order_status: 'active',
    cancelled_at: null,
    accepted_fulfillment: false,
    fulfillment_order_id: null
  },
  action: {
    id: 3,
    type: 'Send Store Credit',
    trigger: 1,
    dm_domestic: 'Standard',
    dm_international: 'Standard',
    redeem_page: null,
    store_id: 58,
    amount: '30.00',
    message: '',
    subject: null,
    page_type: null,
    page_name: null,
    page_image: null,
    created_by: 'mariella@swagup.com',
    created_at: '2024-04-15T20:22:55Z',
    products: []
  },
  int_customer: {
    id: 624,
    external_id: '10502542',
    rutter_id: '77e1414b-ec12-4022-8144-5d8f85e39796',
    home_location_id: '50a3ccc0-d65c-411f-ae4d-5006fe262607',
    work_location_id: '17d28a86-61e5-404e-a8a2-81e991db0b3e',
    created_at_integration: '2024-02-02T22:51:06.147274Z',
    remote_created_at: '2024-04-15T18:26:38.803953Z',
    first_name: 'Jeffrey',
    preferred_name: null,
    last_name: 'Mann',
    employment_status: null,
    ethnicity: null,
    gender: 'MALE',
    birth_date: '1970-11-17T00:00:00Z',
    hire_date: null,
    start_date: '2023-11-15T00:00:00Z',
    termination_date: null,
    marital_status: 'MARRIED_FILING_JOINTLY',
    personal_email: 'jeffrey.k.mann@gmail.com',
    work_email: null,
    mobile_phone_number: '+18065434331',
    store: 8
  }
};

const reverseTimingCoverter = t => {
  const timing = t === undefined ? 0 : t;
  const factor = timing > 0;
  const rate = timing % 24 === 0 ? 24 : 1;
  const timingRslt = (timing / rate) * (factor ? 1 : -1);

  return {
    inputTiming: factor ? 'after' : 'before',
    inputTimeMeasure: rate === 24 ? 'days' : 'hours',
    inputNumber: timingRslt
  };
};

export {
  imageResolver,
  actionImageResolver,
  actionTextResolver,
  integrationsConverter,
  apiDataConverter,
  orderItem,
  apiDataConverterCreate,
  reverseTimingCoverter
};
