import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { addEmployee } from '../../../../../actions';
import { Img } from '../../../../global/ImgUtils';
import { greyRoundBorder } from '../../../../shared/styles/commonStyles';
import Drawer from '../common/Drawer';
import ContactForm from './ContactForm';
import gtm from '../../../../../utils/gtm';

const text = {
  fontFamily: 'Gilroy-Medium',
  letterSpacing: 'normal'
};

const useCSVStyles = makeStyles({
  csvSection: {
    ...greyRoundBorder,
    padding: '22px 25px'
  },
  primaryText: {
    ...text,
    fontSize: 14,
    color: '#0b1829'
  },
  secondaryText: {
    ...text,
    fontSize: 12,
    color: '#787b80'
  },
  uploadCSVLink: {
    height: 'unset',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    letterSpacing: 0
  },
  separator: {
    margin: '30px 0',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      margin: '0 18px'
    },
    '&:before, &:after': {
      content: "''",
      display: 'flex',
      flex: 1,
      borderTop: '1px solid #f0f2f5'
    }
  }
});

const ImportFromCSV = () => {
  const classes = useCSVStyles();
  const location = useLocation();
  return (
    <>
      <Grid container alignItems="center" className={classes.csvSection}>
        <Grid item style={{ marginRight: 16, lineHeight: 0 }}>
          <Img src="/images/addContact/csv-icon-48.png" alt="csv-icon" width={48} height={48} />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <p className={classes.primaryText}>Upload multiple contacts at once</p>
          <p className={classes.secondaryText} style={{ marginTop: 6 }}>
            Use CSV upload feature
          </p>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to={{
              pathname: '/import-contacts/download-template',
              state: { from: `${location.pathname}${location.search}` }
            }}
            onClick={() => gtm.onClick('Upload CSV')}
            variant="text"
            className={classes.uploadCSVLink}
          >
            Upload CSV
          </Button>
        </Grid>
      </Grid>

      <p className={clsx(classes.separator, classes.secondaryText)}>
        <span>or</span>
      </p>
    </>
  );
};

const useStyles = makeStyles({
  root: { zIndex: '1300 !important' },
  paper: {
    maxWidth: 594,
    padding: '35px 20px 35px 45px'
  }
});

const AddContact = ({ open, onClose, onSuccess }) => {
  const classes = useStyles();
  const handleSubmit = data => addEmployee({ ...data, size: data.size || undefined });

  return (
    <Drawer open={open} onClose={onClose} classes={classes}>
      <ContactForm title="Add new contact" submitText="Add contact" onSubmit={handleSubmit} onSuccess={onSuccess}>
        <ImportFromCSV />
      </ContactForm>
    </Drawer>
  );
};

export default AddContact;
