const styles = {
  line: {
    backgroundColor: '#d4d9e2',
    border: 'none',
    height: 1,
    width: '100%',
    marginTop: 10,
    marginBottom: 40
  },
  title: {
    color: '#0f2440',
    lineHeight: 1,
    fontSize: 40,
    paddingBottom: 10
  }
};

export default styles;
