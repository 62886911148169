import * as React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Button } from '@swagup-com/components';
import { useTimeout } from '../../hooks';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    height: 10
  },
  copied: {
    display: 'flex',
    fontWeight: 'bold',
    lineHeight: 'normal',
    color: '#45af5f !important',
    cursor: 'default'
  },
  copyIcon: { fontSize: 14 },
  copyBtn: {
    padding: 0,
    height: 'auto',
    fontSize: 12,
    color: '#3577d4',
    '&:hover': { color: '#004ca2' },
    '& svg': { marginRight: 4 }
  }
};

const useStyles = makeStyles(styles);

const CopyToClipBoardButton = ({ textToCopy, buttonText = 'Copy link' }) => {
  const classes = useStyles();
  const [copied, setCopied] = React.useState();
  const [, , reset] = useTimeout(setCopied, 3000);

  const handleClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    reset();
  };

  return (
    <Grid className={classes.container}>
      {copied ? (
        <Typography variant="subtitle2" className={classes.copied}>
          Copied!
        </Typography>
      ) : (
        <Button variant="text" onClick={handleClick} className={classes.copyBtn}>
          <FileCopyOutlinedIcon className={classes.copyIcon} />
          {buttonText}
        </Button>
      )}
    </Grid>
  );
};

export default CopyToClipBoardButton;
