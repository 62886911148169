import * as React from 'react';
import sum from 'lodash/sum';
import { Button } from '@swagup-com/components';
import { Grid, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SizesListMultiSize, SizesListOneSize } from '../../common/SizesList';
import { isOneSize, sumByQuantity } from '../../../../../../helpers/utils';
import { changeColorLightness } from '../../../../../shared/styles/utils';
import { useSizes } from '../../../../../../hooks';
import { doesInactiveSizeHasQuantity, mapSizesWithActiveField } from '../../common/utilsOrder';
import { ProductInformation } from './common';

const useStyles = makeStyles({
  quantitiesSelection: ({ showWarning }) => ({
    borderRadius: 10,
    border: showWarning ? 'solid 4px #fa902d' : 'none',
    position: 'relative',
    width: '100%'
  }),
  bottomCTA: {
    width: '100%',
    marginTop: 32
    // position: 'absolute',
    // zIndex: 1,
    // bottom: 0
  },
  resetButton: {
    height: 20,
    minWidth: 'unset',
    color: '#787b80',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 0.67,
    letterSpacing: 'normal',
    '&:hover': { color: changeColorLightness('#787b80') }
  },
  addRemaining: {
    height: 20,
    minWidth: 'unset',
    color: '#3577d4',
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 0.67,
    letterSpacing: 'normal',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  errorMessage: {
    fontSize: 12,
    fontFamily: 'Gilroy-Medium',
    color: '#f44336'
  }
});

const QuantitiesSelection = ({
  product,
  qtyLeft,
  quantities,
  onChangeQty,
  onReset,
  errorMessage,
  hideInactiveWarnings,
  qtyLeftForAddItems,
  isEditingShipmentGroup,
  aditionalCTA,
  limitQty
}) => {
  const { data } = useSizes();

  const hasError = Boolean(errorMessage);
  const showQtyLeft = qtyLeft !== undefined;
  const totalQtyLeft = sum(Object.values(qtyLeft || {})) - sumByQuantity(quantities);
  const totalStock = sumByQuantity(product.sizes);
  const quantitySummary = showQtyLeft ? (
    <>
      Qty left: <span style={{ color: totalQtyLeft > 0 ? '#45af5f' : '#0b1829' }}>{Math.max(totalQtyLeft, 0)}</span> out
      of <span style={{ color: '#0b1829' }}>{totalStock}</span>
    </>
  ) : (
    <>
      Qty: <span style={{ color: '#45af5f' }}>{sumByQuantity(quantities)}</span>
    </>
  );

  const sizes = mapSizesWithActiveField(data, product);

  const addRemainingItems = () => {
    if (isOneSize(product.sizes)) {
      const currentQuantity = isEditingShipmentGroup ? quantities[0]?.quantity : 0;
      const fullQuantity = currentQuantity + qtyLeftForAddItems[product.sizes[0].size.id];
      onChangeQty(product.sizes[0].size.id)({ target: { value: fullQuantity } });
    } else {
      sizes.forEach(size => {
        const currentQuantity = isEditingShipmentGroup ? quantities.find(s => s.size === size.id)?.quantity || 0 : 0;
        const fullQuantity = currentQuantity + qtyLeftForAddItems[size.id];
        onChangeQty(size.id)({ target: { value: fullQuantity } });
      });
    }
  };
  const hasInactiveSizes = quantities.some(doesInactiveSizeHasQuantity(sizes));

  const { leftBarNavigation } = useFlags();

  const classes = useStyles({ showWarning: hasInactiveSizes && !hideInactiveWarnings });

  return (
    <Grid container className={classes.quantitiesSelection}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ProductInformation
            product={product.product}
            name={product.product.name}
            info={quantitySummary}
            showWarning={sumByQuantity(quantities) === 0}
          />
        </Grid>
        <Grid item xs>
          <Grid
            container
            style={{
              justifyContent: leftBarNavigation ? 'unset' : 'space-between',
              gap: leftBarNavigation ? '4px' : 'unset'
            }}
          >
            {isOneSize(product.sizes) ? (
              <SizesListOneSize
                currentQuantity={quantities[0]?.quantity || 0}
                onQtyClick={value => onChangeQty(product.sizes[0].size.id)({ target: { value } })}
                customSizeInputProps={{
                  maximumQuantity: showQtyLeft ? qtyLeft[product.sizes[0].size.id] : undefined,
                  onChange: onChangeQty(product.sizes[0].size.id),
                  placeholder: 'Custom qty',
                  limitQty
                }}
                disabled={hasError}
              />
            ) : (
              <SizesListMultiSize
                sizes={sizes}
                idSelector={size => size.id}
                nameSelector={size => size.name}
                inputPropsPerSize={size => {
                  const maximumQuantity = showQtyLeft ? qtyLeft[size.id] : undefined;
                  const isSizeInOrder = product.sizes.some(s => s.size.id === size.id);
                  const disableSize = hasError || maximumQuantity === 0 || !isSizeInOrder;
                  const quantity = quantities.find(s => s.size === size.id);
                  return {
                    currentQuantity: quantity?.quantity || 0,
                    maximumQuantity,
                    value: disableSize ? '' : quantity?.quantity ?? '',
                    placeholder: disableSize ? '' : '0',
                    disabled: disableSize,
                    onChange: onChangeQty(size.id),
                    style: { width: 44 },
                    limitQty
                  };
                }}
                hideInactiveWarnings={hideInactiveWarnings}
              />
            )}
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.bottomCTA}>
            {hasError && (
              <Grid xs={12}>
                <p className={classes.errorMessage}>{errorMessage}</p>
              </Grid>
            )}
            <Grid item>
              <Button variant="text" className={classes.addRemaining} disabled={hasError} onClick={addRemainingItems}>
                Add Remaining Items
              </Button>
            </Grid>
            <Grid item>
              <Button variant="text" className={classes.resetButton} disabled={hasError} onClick={onReset}>
                Reset
              </Button>
            </Grid>
          </Grid>
          {aditionalCTA}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuantitiesSelection;
