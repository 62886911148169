const styles = {
  tableContainer: {
    border: 'solid 1px #f0f2f5',
    borderRadius: 10,
    maxHeight: 612,
    flex: 1,
    overflow: 'hidden',
    marginBottom: 10
  },
  table: {
    flex: 1,
    width: 1010,
    '& .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY': {
      height: 'calc(100% - 60px) !important',
      top: '50px !important'
    }
  },
  nameCell: { '& .divCell': { padding: '0 20px 0 0' } },
  paginationContainer: {
    minHeight: 64,
    borderTop: 'solid 1px #f0f2f5',
    padding: '8px 20px'
  },
  popover: {
    zIndex: 1300,
    pointerEvents: 'none',
    paddingBottom: 12,
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: 2,
      width: 20,
      height: 10,
      left: 'calc(50% - 10px)',
      right: 'calc(50% - 10px)',
      clipPath: 'polygon(0 0, 50% 100%, 50% 100%, 100% 0)',
      background: '#3577d4'
    }
  },
  paper: {
    padding: '22px 14px',
    background: '#3577d4',
    color: '#ffffff',
    borderRadius: 10
  },
  paginationBtn: {
    width: 36,
    minWidth: 36,
    height: 36,
    padding: 8,
    '& svg': {
      width: 20,
      height: 20
    }
  }
};

export default styles;
