import { CHANGE_BAG_OPEN_STATUS } from '../actions/types';

const isBagOpenReducer = (state = false, action) => {
  switch (action.type) {
    case CHANGE_BAG_OPEN_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export default isBagOpenReducer;
