import React, { useState, useEffect } from 'react';
import { Grid, withStyles, TextField } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import NumberFormatCustom from '../UI/Inputs/NumberFormatCustom';
import styles from './styles/ShippingCreditOptions';
import { intStr, moneyStr } from '../../helpers/utils';
import { useCompany } from '../../hooks';
import { useMembership } from '../../contexts/membershipContext';
import BenefitTab from '../global/BenefitTab';

const creditOptions = { recommended: 'recommended', manual: 'manual' };

const ShippingCreditOptions = ({ classes, recommendedCredit, handleCredit, customCreditEmpty, creditChanges }) => {
  const [currentCredit, setCurrentCredit] = useState(recommendedCredit);
  const [selectedCredit, setSelectedCredit] = useState(creditOptions.recommended);

  const isCreditRecommended = selectedCredit === creditOptions.recommended;
  const isCreditManual = selectedCredit === creditOptions.manual;

  useEffect(() => {
    setSelectedCredit(creditOptions.recommended);
    setCurrentCredit(recommendedCredit);
  }, [recommendedCredit]);

  useEffect(() => {
    if (customCreditEmpty) {
      setSelectedCredit(creditOptions.recommended);
      setCurrentCredit(recommendedCredit);
    }
  }, [customCreditEmpty, creditChanges]);

  const handleInputChange = evt => {
    const inputValue = parseFloat(evt.target.value || 0);
    handleCredit(inputValue);
    setCurrentCredit(inputValue);
  };

  const { data: company } = useCompany();
  const affiliateId = company.affiliate_id;
  const isPostalReferral = affiliateId === 'postal.io';
  const { currentMembership } = useMembership();
  const { earlyBirdPromotion } = useFlags();
  return (
    <Grid container>
      <Grid item xs={12}>
        {currentMembership && (!currentMembership.isFreeTier || earlyBirdPromotion) && (
          <BenefitTab
            earlyBirdPromotion={earlyBirdPromotion ? currentMembership : undefined}
            icon="/images/membership/rewards.png"
            text={`Reward Points ${currentMembership.reward_points_rate}X`}
            value={Math.ceil(currentCredit * parseFloat(currentMembership.reward_points_rate))}
            type="reward"
            alone
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <p className={classes.title}>Select an option</p>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <div
              role="presentation"
              className={isCreditRecommended ? classes.inputContainerSelected : classes.inputContainer}
              onClick={() => {
                handleCredit(recommendedCredit);
                setCurrentCredit(recommendedCredit);
                setSelectedCredit(creditOptions.recommended);
              }}
            >
              <p className={isCreditRecommended ? classes.subtitleSelected : classes.subtitle}>Recommended Credits</p>
              <p className={isCreditRecommended ? classes.optionSelected : classes.option}>
                {moneyStr(recommendedCredit)}
              </p>
            </div>
          </Grid>
          {!isPostalReferral && (
            <Grid className={classes.recommendedCredit} item xs={12} sm={6} md={6}>
              <div
                role="presentation"
                className={isCreditManual ? classes.inputContainerSelected : classes.inputContainer}
                onClick={() => {
                  handleCredit(currentCredit);
                  setSelectedCredit(creditOptions.manual);
                }}
              >
                <Grid container>
                  <Grid>
                    <p
                      style={{ paddingRight: 40 }}
                      className={isCreditRecommended ? classes.subtitle : classes.subtitleSelectedCustom}
                    >
                      Custom Credits
                    </p>
                  </Grid>
                  <Grid item>
                    {isCreditManual ? (
                      <TextField
                        id="standard-bare"
                        placeholder={moneyStr(recommendedCredit)}
                        className={classes.textField}
                        defaultValue={recommendedCredit}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          maxLength: 9,
                          disableUnderline: true,
                          className: classes.inputCreditText
                        }}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <p className={classes.option} style={{ fontSize: 16 }}>
                        Enter desired credits
                      </p>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ShippingCreditOptions);
