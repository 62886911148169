import * as React from 'react';
import mergeWith from 'lodash/mergeWith';
import merge from 'lodash/merge';
import mapValues from 'lodash/mapValues';

import { sumByQuantity } from '../../../../../helpers/utils';
import { useOrder } from '../OrderContext';
import { getQuantitiesBreakdown, joinQuantitiesBreakdowns } from '../common/utilsOrder';

const getUnallocatedBreakdown = (totalBreakdown, shipAndWarehouseBreakdown) => {
  const unallocatedBreakdown = { ...totalBreakdown };
  return mergeWith(unallocatedBreakdown, shipAndWarehouseBreakdown, (a, b) => a - b);
};

const useUnallocatedPerProduct = () => {
  const { products, shipmentGroups, warehouseProofs } = useOrder();

  const totalQuantities = React.useMemo(
    () =>
      products
        .map(product => ({
          [product.id]: {
            total: sumByQuantity(product.sizes),
            breakdown: getQuantitiesBreakdown(product.sizes)
          }
        }))
        .reduce(merge, {}),
    [products]
  );

  const shipmentQuantities = React.useMemo(() => {
    const quantities = {};
    shipmentGroups.forEach(shipmentGroup =>
      shipmentGroup.directory_orders.forEach(dOrder =>
        dOrder.proofs.forEach(p => {
          quantities[p.proof.id] = {
            total: (quantities[p.proof.id]?.total ?? 0) + sumByQuantity(p.sizes),
            breakdown: joinQuantitiesBreakdowns(quantities[p.proof.id]?.breakdown, getQuantitiesBreakdown(p.sizes))
          };
        })
      )
    );

    return quantities;
  }, [shipmentGroups]);

  const warehouseQuantities = React.useMemo(
    () =>
      warehouseProofs
        .map(warehouseProof => ({
          [warehouseProof.proof.id]: {
            total: sumByQuantity(warehouseProof.sizes),
            breakdown: getQuantitiesBreakdown(warehouseProof.sizes)
          }
        }))
        .reduce(merge, {}),
    [warehouseProofs]
  );

  const unallocatedPerProduct = React.useMemo(
    () =>
      mapValues(totalQuantities, ({ total, breakdown }, productId) => ({
        total: total - (shipmentQuantities[productId]?.total ?? 0) - (warehouseQuantities[productId]?.total ?? 0),
        breakdown: getUnallocatedBreakdown(
          breakdown,
          joinQuantitiesBreakdowns(shipmentQuantities[productId]?.breakdown, warehouseQuantities[productId]?.breakdown)
        )
      })),
    [totalQuantities, shipmentQuantities, warehouseQuantities]
  );

  return unallocatedPerProduct;
};

export default useUnallocatedPerProduct;
