import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Alert } from '../../../../shared';

const useStyles = makeStyles({
  alertRoot: {
    '& .MuiAlert-standardInfo': {
      backgroundColor: '#fff7f0',
      borderRadius: 10
    }
  },
  infoIcon: {
    fontSize: 16,
    color: '#fa902d',
    alignSelf: 'center'
  }
});

const InactiveSizesAlert = ({ itemText = 'recipients', ...props }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Alert
        showIcon={<InfoOutlined className={classes.infoIcon} />}
        className={classes.alertRoot}
        fontStyles={{
          color: '#fa902d',
          fontSize: 12,
          fontFamily: 'Gilroy-Medium',
          padding: 0
        }}
        {...props}
      >
        <span>You have {itemText} with Out Of Stock sizes selected. Please update.</span>
      </Alert>
    </Grid>
  );
};

export default InactiveSizesAlert;
