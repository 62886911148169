import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { Grid, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { ABARoutingNumberIsValid } from 'bank-routing-number-validator';
import payform, { validateCardNumber, validateCardCVC, validateCardExpiry, parseCardExpiry } from 'payform';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, SelectField } from '../global/reactHookFormFields';
import { formatCreditCardNumber, formatCVC } from '../../helpers/utilsCC';
import { normalizeExDate, normalizeUSZip } from '../../helpers/utils';
import { paymentMethods } from '../../apis/constants';
import { sendDataToAnet, addNewPaymentProfile } from '../../actions';
import { useCompany, useCountries, useMemoizeStateFields, useProfile } from '../../hooks';
import styles from './styles/addPaymentMethodDialog';
import Loader from '../global/Loader';
import ErrorAlert from '../shared/ErrorAlert';
import { getSchema, text, requiredLettersOnly, requiredLettersAndNumbers } from '../global/Forms/commonValidations';
import { defaultErrorMsg } from '../../utils/errors';

const useStyles = makeStyles(styles);

payform.cards = payform.cards.map(card => (card.type === 'visa' ? { ...card, length: [13, 16] } : card));

const validateCCNumber = text.test(
  'card_number',
  'Please, provide a valid credit card number',
  value => value && validateCardNumber(value)
);

const validateCVC = text.test(
  'cvc',
  'Please, provide a valid credit card CVC',
  value => value && validateCardCVC(value)
);

const validateExpiry = text.test(
  'expiration_date',
  'Please, provide a valid Credit Card date',
  value => value && validateCardExpiry(parseCardExpiry(value))
);

const validRoutingNumber = text.test(
  'routing_number',
  'Please, provide a valid routing number',
  routing => routing && ABARoutingNumberIsValid(routing)
);

const validateAccountNumber = text.test(
  'account_number',
  'Please, provide a valid account number',
  account => account && /^[0-9]{7,14}$/g.test(account)
);

const CreditCardDetails = ({ register, errors, getValues }) => {
  const classes = useStyles();

  const handleChangeExDate = value => {
    const prevValue = getValues('expiration_date');
    return normalizeExDate(value ?? '', prevValue);
  };

  return (
    <div className={classes.CardFormContainer}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            placeholder="Credit card number"
            error={errors.card_number?.message}
            {...register('card_number')}
            formatFn={formatCreditCardNumber}
            defaultValue=""
            fullWidth
            data-private
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            placeholder="Name on card"
            error={errors.full_name?.message}
            {...register('full_name')}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} style={{ paddingRight: '10px' }}>
          <TextField
            autoComplete="off"
            placeholder="Expiration date"
            error={errors.expiration_date?.message}
            {...register('expiration_date')}
            formatFn={handleChangeExDate}
            fullWidth
            data-private
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '10px' }}>
          <TextField
            autoComplete="off"
            placeholder="CVC"
            error={errors.cvc?.message}
            {...register('cvc')}
            formatFn={formatCVC}
            fullWidth
            data-private
          />
        </Grid>
      </Grid>
    </div>
  );
};

const BankAccountDetails = ({ register, errors, control }) => {
  const classes = useStyles();

  return (
    <div className={classes.BankAccountDetailsContainer}>
      <Grid container>
        <Grid item xs={12}>
          <SelectField
            name="account_type"
            control={control}
            label="Account Type"
            totalItems={2}
            error={errors.account_type?.message}
            fullWidth
          >
            <MenuItem value="Checking">Checking</MenuItem>
            <MenuItem value="Savings">Savings</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            placeholder="Name on Account"
            error={errors.name_on_account?.message}
            {...register('name_on_account')}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} style={{ paddingRight: '10px' }}>
          <TextField
            autoComplete="off"
            placeholder="Routing number"
            error={errors.routing_number?.message}
            {...register('routing_number')}
            fullWidth
            data-private
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoComplete="off"
            placeholder="Account number"
            error={errors.account_number?.message}
            {...register('account_number')}
            fullWidth
            data-private
          />
        </Grid>
      </Grid>
    </div>
  );
};

const defaultValues = {
  payment_profile_name: '',
  first_name: '',
  last_name: '',
  full_name: '',
  shipping_address1: '',
  shipping_address2: '',
  shipping_city: '',
  shipping_country: 'US',
  billing_state: '',
  billing_zip: '',
  account_type: '',
  name_on_account: '',
  routing_number: '',
  account_number: ''
};

const PaymentMethodForm = ({ isOpen, detailSubtitle, isDefault, onClose, selectedMethod, CustomForm }) => {
  const classes = useStyles();

  const [loading, toggleLoading] = useReducer(prevLoading => !prevLoading, false);

  const { data: countries } = useCountries();
  const { data: profile } = useProfile();
  const { data: company } = useCompany();
  const [errorMessage, setErrorMessage] = useState();
  const [isDefaultPaymentPofile, setIsDefaultPaymentPofile] = useState(isDefault);

  useEffect(() => {
    setIsDefaultPaymentPofile(isDefault);
  }, [isDefault]);

  const requiredFields =
    selectedMethod === paymentMethods.creditCard
      ? {
          card_number: validateCCNumber,
          cvc: validateCVC,
          expiration_date: validateExpiry,
          full_name: requiredLettersOnly.max(64, 'Is too long (64 characters maximum)')
        }
      : {
          routing_number: validRoutingNumber,
          account_number: validateAccountNumber,
          name_on_account: requiredLettersOnly.max(22, 'Is too long (22 characters maximum)'),
          account_type: text.required('Please, provide a valid account type')
        };

  const { control, register, setValue, setError, watch, handleSubmit, trigger, formState, reset, getValues } = useForm({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(
      getSchema(Object.keys(defaultValues), {
        first_name: requiredLettersOnly.max(50, 'Is too long (50 characters maximum)'),
        last_name: requiredLettersOnly.max(50, 'Is too long (50 characters maximum)'),
        shipping_city: requiredLettersAndNumbers.max(50, 'Is too long (50 characters maximum)'),
        payment_profile_name: text.max(50, 'Is too long (50 characters maximum)'),
        ...requiredFields
      })
    )
  });
  const { errors, touchedFields } = formState;

  const country = watch('shipping_country');
  const [onCountryChange, onStateChange, states] = useMemoizeStateFields({
    country,
    change: setValue,
    propertyName: 'billing_state',
    shouldValidate: Boolean(touchedFields.billing_state)
  });

  const handleCountryChange = e => {
    setValue('billing_zip', '');
    if (touchedFields.billing_zip || errors.billing_zip) {
      trigger('billing_zip');
    }
    onCountryChange(e);
  };

  const dispatch = useDispatch();

  const clearForm = useCallback(() => {
    setValue('card_number', '');
    setValue('cvc', '');
    setValue('expiration_date', '');
    setValue('billing_state', '');
    setValue('shipping_country', 'US');
    setValue('account_type', '');
    reset();
  }, [setValue, reset]);

  useEffect(() => {
    if (!isOpen) clearForm();
  }, [clearForm, isOpen]);

  const handleClose = () => {
    clearForm();
    onClose();
  };

  const onSave = async data => {
    if (!loading) toggleLoading();

    const billTo = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: profile.email,
      company: company.name,
      force_address: true,
      address1: data.shipping_address1,
      address2: data.shipping_address2,
      city: data.shipping_city,
      state: data.billing_state,
      zip_code: data.billing_zip,
      country: data.shipping_country
    };

    const dataToAnet =
      selectedMethod === paymentMethods.creditCard
        ? {
            ccnumber: data.card_number,
            cvc: data.cvc,
            exdate: data.expiration_date,
            fullName: data.full_name
          }
        : {
            canumberc: data.account_number,
            rnumber: data.routing_number,
            naccount: data.name_on_account,
            account_type: data.account_type
          };

    const anetResponse = await dispatch(sendDataToAnet(dataToAnet));
    if (anetResponse.resultCode === 'Error') {
      setErrorMessage(anetResponse.message[0]?.text || defaultErrorMsg);
    } else {
      const newProfileResponse = await dispatch(
        addNewPaymentProfile(company.id, {
          default: isDefaultPaymentPofile,
          dataValue: anetResponse.dataValue,
          dataDescriptor: anetResponse.dataDescriptor,
          bill_to: billTo,
          payment_profile_name: data.payment_profile_name
        })
      );
      if (newProfileResponse.result === 'error') {
        if (newProfileResponse.data?.error_code === 'E00015') {
          setError(
            'routing_number',
            { message: 'The length is invalid for ABA Routing Number.' },
            { shouldFocus: true }
          );
        } else if (newProfileResponse.data?.error_code) {
          setErrorMessage(newProfileResponse.data?.error_message);
        } else {
          setErrorMessage(defaultErrorMsg(newProfileResponse.status));
        }
      } else {
        handleClose();
      }
    }
    toggleLoading();
  };

  const handleChangeZip = e => {
    if (country === 'US') {
      const usZip = normalizeUSZip(e?.target?.value ?? '');
      setValue('billing_zip', usZip);
    }
  };

  const provinces = countries.find(c => c.iso2 === country)?.provinces ?? [];

  const Details = selectedMethod === paymentMethods.creditCard ? CreditCardDetails : BankAccountDetails;

  return (
    <form onSubmit={handleSubmit(onSave)}>
      {CustomForm ? (
        <CustomForm
          register={register}
          control={control}
          errors={errors}
          company={company}
          countries={countries}
          handleCountryChange={handleCountryChange}
          provinces={provinces}
          states={states}
          onStateChange={onStateChange}
          handleChangeZip={handleChangeZip}
          getValues={getValues}
          disabled={!formState.isValid || loading}
          isLoading={loading}
          isDefault={isDefaultPaymentPofile}
          setIsDefault={setIsDefaultPaymentPofile}
        />
      ) : (
        <>
          <Typography variant="body1" className={classes.paymentNameHeader}>
            Give this payment method a name so it can easily be identified
          </Typography>
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={errors.payment_profile_name?.message}
              {...register('payment_profile_name')}
              placeholder="Nickname"
              fullWidth
            />
          </Grid>
          <Typography variant="h3" className={classes.FormSubTitle}>
            {detailSubtitle}
          </Typography>
          <Details register={register} errors={errors} control={control} getValues={getValues} />
          <Typography variant="h3" className={classes.FormSubTitle}>
            Billing address
          </Typography>
          <div className={classes.BillingAddressFormContainer}>
            <Grid container>
              <Grid item xs={6} style={{ paddingRight: '10px' }}>
                <TextField
                  placeholder="First name"
                  error={errors.first_name?.message}
                  {...register('first_name')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  placeholder="Last name"
                  error={errors.last_name?.message}
                  {...register('last_name')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  name="shipping_country"
                  label="Country"
                  error={errors.shipping_country?.message}
                  totalItems={countries.length}
                  onSelectChange={handleCountryChange}
                  control={control}
                  fullWidth
                >
                  {countries?.map(c => (
                    <MenuItem key={c.iso2} value={c.iso2}>
                      {c.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="Street address"
                  error={errors.shipping_address1?.message}
                  {...register('shipping_address1')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '10px' }}>
                <TextField
                  placeholder="Floor, suite, unit (optional)"
                  error={errors.shipping_address2?.message}
                  {...register('shipping_address2')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                <TextField
                  placeholder="City"
                  error={errors.shipping_city?.message}
                  {...register('shipping_city')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '10px' }}>
                {provinces.length === 0 ? (
                  <TextField
                    placeholder="State / Province / Region"
                    error={errors.billing_state?.message}
                    defaultValue={states.current[country] ?? ''}
                    {...register('billing_state')}
                    onChange={onStateChange}
                    fullWidth
                  />
                ) : (
                  <SelectField
                    name="billing_state"
                    error={errors.billing_state?.message}
                    defaultValue={states.current[country] ?? ''}
                    onSelectChange={onStateChange}
                    totalItems={provinces.length}
                    control={control}
                    label="State"
                    fullWidth
                    onChange={onStateChange}
                  >
                    {provinces.map(p => (
                      <MenuItem key={p.code} value={p.code}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                )}
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                <TextField
                  placeholder="Postal Code"
                  error={errors.billing_zip?.message}
                  defaultValue={company.shipping_zip}
                  {...register('billing_zip')}
                  onInput={handleChangeZip}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
          {errorMessage && <ErrorAlert error={errorMessage} onError={setErrorMessage} className={classes.errorAlert} />}
          <div className={classes.DialogActions}>
            <Grid container justifyContent="center">
              <Button variant="primary" type="submit" disabled={!formState.isValid || loading} style={{ width: 254 }}>
                Add payment method
              </Button>
            </Grid>
          </div>
          {loading && <Loader />}
        </>
      )}
    </form>
  );
};

export default PaymentMethodForm;
