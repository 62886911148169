import React from 'react';
import {
  Box,
  Checkbox,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TableHead,
  withStyles
} from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { isEmpty } from 'lodash';
import { StylessButton } from '../../buttons';
import { CustomTableRow, getShippingStatus } from '../../storefronts/storeCommon';
import { Link } from '../../shared';
import { formatDate, joinFields, moneyStr } from '../../../helpers/utils';
import TableSortBy from '../../storefronts/TableSortBy';
import { TableCell } from '../../pages/orders/requested/shipments/TableCommon';
import { toSentenceCase } from '../../account/MembershipCommon';

const SmallCheckbox = withStyles({
  root: {
    padding: 0,
    height: 14,
    width: 14,
    color: '#989EA4',
    '&$checked': { color: '#3577d4' },
    '& .MuiSvgIcon-root': {
      height: 14,
      width: 14
    }
  },
  checked: {}
})(Checkbox);

const attachedShippingNote = shipping => {
  switch (shipping.status) {
    case 'Out Of Stock':
      return 'Order more inventory';
    case 'Insufficient Credit':
      return 'Reload credit balance';
    case 'Unexpected Error':
      return 'Try again or contact customer support';
    default:
      return shipping.note;
  }
};

const ShippingNote = ({ shipping, classes }) => {
  const note = ['Failure', 'On Its Way', 'Delivered'].includes(shipping.status) ? (
    <span>
      {shipping.trackingUrl && shipping.trackingNumber ? (
        <>
          See{' '}
          <a target="_blank" rel="noopener noreferrer" className={classes.link} href={shipping.trackingUrl}>
            tracking info
          </a>
        </>
      ) : (
        'No tracking available'
      )}
    </span>
  ) : (
    <>
      {shipping.status === 'Scheduled'
        ? `${
            dayjs(shipping.shippingDate).isAfter('01/01/2022')
              ? `Shipping Date: ${dayjs(shipping.shippingDate).format('MM/DD/YYYY')}`
              : ''
          }`
        : shipping.note}
    </>
  );
  return (
    <Typography variant="body4RegularInter" className={classes.shippingStatusDescription}>
      {note}
    </Typography>
  );
};

const attachShippingNote = shipping => ({ ...shipping, error });

const ShippingStatus = ({ shipping, classes }) => (
  <div className={classes.shippingStatusContainer}>
    <Grid container alignItems="center">
      <Grid item>
        {shipping.status && <div className={classes[`shipping${getShippingStatus(shipping.status)}`]} />}
      </Grid>
      <Grid item xs>
        <Typography variant="body4RegularInter" className={classes.shippingStatusText}>
          {shipping.status}
        </Typography>
      </Grid>
    </Grid>
    <Grid container alignItems="center" title={shipping.error}>
      <Typography variant="body4RegularInter" className={classes.lightErrorText}>
        {shipping.error}
      </Typography>
    </Grid>
  </div>
);

const TableHeaderAutoShip = ({ classes, toogleSelectAll, allSelected }) => (
  <TableHead>
    <CustomTableRow>
      <TableCell scope="row" width="16px">
        <SmallCheckbox checked={allSelected} onChange={toogleSelectAll} />
      </TableCell>
      <TableCell>ID</TableCell>
      <TableCell scope="row" width="20%">
        NAME
      </TableCell>
      <TableCell>STATUS</TableCell>
      <TableCell className={classes.cellCentered}>AUTOMATION</TableCell>
      <TableCell className={classes.cellCentered}>ACTION</TableCell>
      <TableCell className={classes.cellCentered}>
        <Grid container alignItems="center" style={{ minWidth: 110 }}>
          <Grid item xs={2} />
          <Grid item xs>
            DATE ADDED
          </Grid>
          <Grid item>
            <TableSortBy />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell scope="row" width="32px" />
      <TableCell scope="row" width="64px" />
    </CustomTableRow>
  </TableHead>
);

const ActionOrderRow = ({
  classes,
  actionOrder,
  isSelected,
  toogleSelectedItems,
  onApprove,
  onUpdateActionOrder,
  onRetry,
  disabled
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const innerOrder = actionOrder.int_order || {};
  const innerCustomer = actionOrder.int_customer || {};
  const innerAction = actionOrder.action || {};

  const disableEdit = isEmpty(actionOrder.int_order) || disabled;
  const disableRetry = isEmpty(actionOrder.int_order) || isEmpty(actionOrder.int_order?.error_message) || disabled;
  // const disableApprove = actionOrder.approved;
  // const disableCancel = !['Pending Production', 'Scheduled'].includes(actionOrder.shipment?.status);
  const showWarningSign = ['Out Of Stock', 'Insufficient Credit', 'Unexpected Error'].includes(
    actionOrder.shipment?.status
  );
  // const isFailed = id => {
  //   return !!selectedShipmentStatus.find(i => i.failedRedemptionId === id);
  // };
  const openActionlist = e => {
    setOpen(true);
    e.stopPropagation();
  };
  const background = open || isSelected ? 'rgba(53, 119, 212, 0.08)' : undefined;
  return (
    <CustomTableRow
      onMouseLeave={() => setOpen(false)}
      className={classes.shipmentRedemptionRow}
      style={{
        background,
        cursor: 'pointer'
      }}
    >
      <TableCell scope="row" width="16px">
        <SmallCheckbox
          className="small-checkbox"
          checked={isSelected}
          onClick={() => !open && toogleSelectedItems(actionOrder)}
          //   style={{ display: isSelected ? 'inherit' : 'none' }}
        />
      </TableCell>
      <TableCell style={{ position: 'relative', overflowX: 'visible', maxWidth: 210 }}>
        <Typography variant="body4RegularInter" className={classes.cellText}>
          {actionOrder.name}
        </Typography>
      </TableCell>
      <TableCell style={{ position: 'relative', overflowX: 'visible' }}>
        <Grid container alignItems="center">
          <Grid item style={{ height: 12 }}>
            {showWarningSign && <Warning className={classes.warningSign} />}
          </Grid>
          <Grid item xs style={{ cursor: 'text' }}>
            <Typography variant="body3RegularInter" className={classes.cellText}>{`${joinFields(
              [innerCustomer.first_name, innerCustomer.last_name],
              ' '
            )}`}</Typography>
            <Typography variant="body4RegularInter" className={classes.lightText}>
              {innerCustomer.work_email || innerCustomer.personal_email}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={classes.slimCell}>
        <ShippingStatus
          shipping={{
            ...innerOrder.swagup_order,
            status: innerOrder.error_message
              ? 'Failure'
              : innerOrder.swagup_order?.status || (actionOrder.approved ? 'Email Sent' : 'Pending Approval'),
            error: innerOrder.error_message
          }}
          classes={classes}
        />
      </TableCell>
      {/* <TableCell className={classes.cellCentered}>
        <Typography variant="body4RegularInter" className={classes.cellText}>
          {moneyStr(innerOrder.total_price || 0)}
        </Typography>
      </TableCell> */}
      <TableCell className={classes.cellCentered}>
        <Typography variant="body4RegularInter" className={classes.cellText}>
          {actionOrder.trigger}
        </Typography>
      </TableCell>
      <TableCell className={classes.cellCentered}>
        <Typography variant="body4RegularInter" className={classes.cellText}>
          {innerAction.type}
        </Typography>
      </TableCell>
      <TableCell className={classes.cellCentered}>
        <Typography variant="body4RegularInter" className={classes.cellText}>
          {actionOrder.created_at ? formatDate(actionOrder.created_at) : 'XX/XX/XX'}
        </Typography>
      </TableCell>
      <TableCell className={classes.cellCentered}>
        <StylessButton onClick={e => openActionlist(e)}>
          <Box
            ref={anchorRef}
            className={classes.moreOptions}
            style={open ? { backgroundColor: 'rgba(53, 119, 212, 0.32)', borderRadius: '4px' } : null}
          >
            <Typography variant="body4RegularInter">•••</Typography>
          </Box>
        </StylessButton>
        <Popper open={open} anchorEl={anchorRef.current} role="menu" transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.redemptionMenu}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    {/* <MenuItem
                      onClick={() => onApprove(actionOrder)}
                      className={classes.redemptionMenuItem}
                      disabled={disableApprove}
                    >
                      <Typography variant="body4RegularInter">Approve</Typography>
                    </MenuItem>
                    <Divider /> */}
                    <MenuItem
                      onClick={() => onUpdateActionOrder(innerOrder)}
                      className={classes.redemptionMenuItem}
                      disabled={disableEdit}
                    >
                      <Typography variant="body4RegularInter">Edit Recipient</Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={() => onRetry(innerOrder)}
                      className={classes.redemptionMenuItem}
                      disabled={disableRetry}
                    >
                      <Typography variant="body4RegularInter">Retry</Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      //   onClick={() => onApprove(actionOrder)}
                      className={classes.redemptionMenuItem}
                      disabled={
                        innerAction.type !== 'Send Redeem Link' || !innerAction.redeem_page || !innerAction.url_slug
                      }
                    >
                      <Link to={`/redeem-history/${innerAction.url_slug}`}>
                        <Typography variant="body4RegularInter">Go to Redeem Page</Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem
                      //   onClick={() => onApprove(actionOrder)}
                      className={classes.redemptionMenuItem}
                      disabled={
                        innerAction.type !== 'Send Store Credit' || !innerAction.store_id || !innerAction.url_slug
                      }
                    >
                      <Link to={`/shop-details/${innerAction.url_slug}`}>
                        <Typography variant="body4RegularInter">Go to Shop</Typography>
                      </Link>
                    </MenuItem>
                    {/* <Divider />
                      <MenuItem
                        onClick={() => onApprove(actionOrder)}
                        className={classes.redemptionMenuItem}
                        disabled={disableApprove}
                      >
                      <Typography variant="body4RegularInter">Simulate</Typography>
                    </MenuItem> */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </TableCell>
      <TableCell className={classes.cellCentered}>
        {!actionOrder.approved && (
          <Button
            variant="primary"
            size="small"
            style={{ height: 24, borderRadius: 12, width: 68 }}
            onClick={() => onApprove(actionOrder)}
            disabled={disabled}
          >
            <Typography variant="body4RegularInter" style={{ color: '#ffffff' }}>
              Approve
            </Typography>
          </Button>
        )}
      </TableCell>
    </CustomTableRow>
  );
};

export { ActionOrderRow, TableHeaderAutoShip };
