import * as React from 'react';
import { timeDiff } from '../helpers/utils';
import log from '../logger';

const tenMinutes = 10 * 60 * 1000;
const refetchIntervalSequence = [5, 10, 20, 40].map(n => n * 1000);
const intervalTimes = tenMinutes / refetchIntervalSequence.length;
const getRefetchIndex = elapsedTime => Math.ceil(elapsedTime / intervalTimes) - 1;

const useOrderRefetchInterval = (orderInProcess, isFetching) => {
  const [refetchIndex, setRefetchIndex] = React.useState(-1);
  const prevOrderInProcess = React.useRef();
  const hasRefetchStarted = React.useRef(false);

  React.useEffect(() => {
    if (hasRefetchStarted.current && prevOrderInProcess.current?.id !== orderInProcess?.id) {
      log.debug('orderInProcess changed, stopping previous refetch');
      hasRefetchStarted.current = false;
      setRefetchIndex(-1);
    }
    prevOrderInProcess.current = orderInProcess;
  }, [orderInProcess]);

  React.useEffect(() => {
    if (orderInProcess) {
      if (!hasRefetchStarted.current) {
        const elapsedTime = timeDiff(new Date(orderInProcess.created_at));
        const initialIndex = getRefetchIndex(elapsedTime);
        if (initialIndex < refetchIntervalSequence.length) {
          log.debug('elapsedTime (sec), initialIndex:', elapsedTime / 1000, initialIndex);
          hasRefetchStarted.current = true;
          setRefetchIndex(initialIndex);
        }
      } else if (!isFetching) {
        setRefetchIndex(prev => prev + 1);
      }
    }
  }, [orderInProcess, isFetching]);

  React.useEffect(() => {
    if (refetchIndex === refetchIntervalSequence.length) {
      log.debug('resetting refetchIndex & hasRefetchStarted');
      hasRefetchStarted.current = false;
      setRefetchIndex(-1);
    }
  }, [refetchIndex]);

  log.debug('orderInProcess:', orderInProcess, 'isFetching:', isFetching);
  log.debug('refetchIndex:', refetchIndex, 'hasRefetchStarted:', hasRefetchStarted.current);

  const nextRefetchInterval = refetchIntervalSequence[refetchIndex];
  return nextRefetchInterval;
};

export default useOrderRefetchInterval;
